<template>
  <div>
    <b-form-group
      label="Select condition"
      label-class="text-center"
      class="mb-4"
    >
      <b-form-radio-group
        v-model="condition"
        buttons
        class="w-100"
        button-variant="outline-primary"
        name="status"
        @change="onChange"
      >
        <b-form-radio value="Xclean" class="pt-2">
          <span class=""> X Clean </span>
        </b-form-radio>
        <b-form-radio value="Clean" class="pt-2">
          <span class=""> Clean </span>
        </b-form-radio>
        <b-form-radio value="Avg" class="pt-2">
          <span class=""> Avg </span>
        </b-form-radio>
        <b-form-radio value="Rough" class="pt-2">
          <span class=""> Rough </span>
        </b-form-radio>
      </b-form-radio-group>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "QuickConditionRegistration",
  props: {
    vehicleCondition: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      condition: this.vehicleCondition || null,
    };
  },
  methods: {
    onChange() {
      this.$emit("onConditionSelect", this.condition);
    },
  },
};
</script>

<style>
</style>