import buyerStore from '@/modules/buyer/registrationForm/store.js';
import users from '@/modules/buyer/users/store.js';
export default {
  state: {},
  mutations: {},
  actions: {},
  modules: {
    buyerStore,
    users
  }
};
