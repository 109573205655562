import * as constants from "./actions";
import axios from "@/plugins/axios.js";
import notes from "./notes/store";
function getDefaultState() {
  return {
    carfaxs: {
      status: null,
      data: [],
      error: null,
    },

    externalCarfax: {},
  };
}

const actions = {
  [constants.GET_CARFAX]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_CARFAX);
      const response = await axios.get("vehicle/carfax", {
        params: payload,
      });
      commit(constants.GET_CARFAX_SUCCESS, response);
      return response;
    } catch (error) {
      console.log(error);

      commit(constants.GET_CARFAX_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_EXTERNAL_CARFAX]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_EXTERNAL_CARFAX);
      const response = await axios.post("vehicle/external-carfax", payload);
      commit(constants.POST_EXTERNAL_CARFAX_SUCCESS, response);
      return response;
    } catch (error) {
      console.log(error);

      commit(constants.POST_EXTERNAL_CARFAX_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_CARFAX]: (state) => {
    state.carfaxs.status = "fetching";
    state.carfaxs.error = null;
  },
  [constants.GET_CARFAX_SUCCESS]: (state, data) => {
    state.carfaxs.status = "success";
    state.carfaxs.error = null;
    state.carfaxs.data = data;
  },
  [constants.GET_CARFAX_ERROR]: (state, error) => {
    state.carfaxs.status = "error";
    state.carfaxs.error = error;
  },

  [constants.POST_EXTERNAL_CARFAX]: (state) => {
    state.externalCarfax.status = "fetching";
    state.externalCarfax.error = null;
  },
  [constants.POST_EXTERNAL_CARFAX_SUCCESS]: (state, data) => {
    state.externalCarfax.status = "success";
    state.externalCarfax.error = null;
    state.externalCarfax.data = data;
  },
  [constants.POST_EXTERNAL_CARFAX_ERROR]: (state, error) => {
    state.externalCarfax.status = "error";
    state.externalCarfax.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
  modules: {
    notes,
  },
};
