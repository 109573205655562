<template>
  <b-card v-if="workOrder" body-class="pb-0">
    <b-row>
      <b-col cols="9">
        <span>
          Registered date:
          <i
            >{{ workOrder.createdAt | formatDateTime }} -

            <span v-if="workOrder.addonType">
              {{ workOrder.addonType.name }}</span
            >
            <span v-if="workOrder.category">
              {{ workOrder.category.name }}</span
            >
          </i> </span
        ><br />

        <span v-if="workOrder.status == 4 || workOrder.status == 0">
          End date:
          <i>
            {{ workOrder.endDate | formatDateTime }} -
            {{
              workOrder.finalUser
                ? workOrder.finalUser.firstName +
                  " " +
                  workOrder.finalUser.lastName
                : "No one"
            }}
          </i>
        </span>

        <span
          >Status:
          <i> {{ getVariantAndLabel(workOrder.status).label }}</i>
        </span>
      </b-col>
      <b-col class="text-center">
        <div class="text-center">
          <span
            class="dot"
            :style="{
              'background-color': getVariantAndLabel(workOrder.status).variant,
            }"
          />
          <br />
          <!--     <b-icon
            icon="check-square"
            scale="1.3"
            class="m-1 cursor-pointer"
            id="workOrder-status-btn"
          ></b-icon> -->
          <div class="d-flex justify-content-center">
            <b-button
              v-if="workOrder.status == 3"
              variant="outline-success"
              title="Cancel"
              id="cancel-work-order"
              size="sm"
              @click="updateWorkOrderStatus(workOrder.status)"
              >Approuver
            </b-button>
            <br />

            <b-button
              v-if="workOrder.status == 3"
              variant="outline-danger"
              title="Cancel"
              id="cancel-work-order"
              size="sm"
              @click="updateWorkOrderStatus(workOrder.status)"
              >Annuler
            </b-button>
          </div>

          <!--    <b-icon
            icon="x-square"
            class="m-1 cursor-pointer"
            v-b-tooltip.hover
            scale="1.3"
            @click="showCancelNote = true"
          ></b-icon>
          <b-tooltip target="cancel-work-order">Cancel</b-tooltip> -->
          <br />
        </div>
      </b-col>
    </b-row>
    <p v-if="showCancelNote">
      <b-form-group label="Note">
        <b-form-textarea
          v-model="note"
          class="resize-none"
          aria-describedby="input-description-live-feedback"
        />
      </b-form-group>
      <!-- Cancel button and confirm button -->
      <b-row>
        <b-col sm="12" md="12" lg="12" xl="12">
          <div class="float-right">
            <b-btn
              size="lg"
              class="btn btn-danger mr-2"
              type="button"
              @click="showCancelNote = false"
            >
              Cancel
            </b-btn>

            <b-btn
              size="lg"
              class="btn btn-success"
              type="button"
              @click="showCancelNote = false"
            >
              Confirm
            </b-btn>
          </div>
        </b-col>
      </b-row>
    </p>
    <span class="text-justify mt-3">
      Description: <i>{{ workOrder.description }}</i>
    </span>
    <br />

    <span class="text-justify">
      Provider:
      <i v-if="workOrder.supplierCompany != null">{{
        workOrder.supplierCompany.companyName
      }}</i>

      <b-button
        v-if="
          !isProviderEditing &&
          workOrder.supplierCompany == null &&
          workOrder.status == 1
        "
        class="mr-2"
        variant="light"
        size="sm"
        @click="isProviderEditing = true"
      >
        <b-icon icon="pencil" />
      </b-button>
    </span>
    <br />
    <span>
      Cost:
      <i v-if="!isEditing">{{ workOrder.cost | currency }} </i>
      <b-col v-else class="px-0" xs="12" sm="12" md="12" lg="12" xl="4">
        <b-form-group label-size="sm">
          <template slot="label"> </template>
          <Money
            v-model="form.cost"
            class="form-control text-center"
            v-bind="money"
          />
        </b-form-group>
      </b-col>
      <b-button
        v-if="isEditing"
        class="mr-2"
        variant="light"
        size="sm"
        @click="isEditing = false"
      >
        <b-icon icon="x" />
      </b-button>
      <b-button
        v-if="isEditing"
        class="mr-2"
        variant="light"
        size="sm"
        @click="update(workOrder)"
      >
        Save
      </b-button>
      <b-button
        v-if="!isEditing && workOrder.status == 1 && workOrder.cost == 0"
        class="mr-2"
        variant="light"
        size="sm"
        @click="isEditing = true"
      >
        <b-icon icon="pencil" />
      </b-button>
    </span>
    <p v-if="workOrder.note">
      Note: <i>{{ workOrder.note }}</i>
    </p>
  </b-card>
</template>

<script>
import { Money } from "v-money";
import { mapState } from "vuex";

export default {
  name: "WorkOrderCard",
  components: {
    Money,
  },
  props: {
    workOrder: {
      type: Object,
      required: true,
    },
    refreshData: {
      type: Function,
      required: false,
    },
  },

  computed: {
    ...mapState({
      vehicle: (state) => state.superAdminStore.vehicle,
    }),
  },
  data() {
    return {
      isEditing: false,
      form: {
        cost: this.workOrder.cost,
        supplier: null,
      },
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "CAD$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      showCancelNote: false,
      note: "",
      isProviderEditing: false,
      tasks: [],
    };
  },
  methods: {
    getVariantAndLabel(status) {
      switch (status) {
        case 0:
          return {
            variant: "#dc3545", // red
            label: "Rejected",
          };
        case 1:
          return {
            variant: "#f8c12c", //yellow
            label: "Waiting quote",
          };
        case 2:
          return {
            variant: "#fd7e14", // orange
            label: "Active",
          };
        case 3:
          return {
            variant: "#02afaa", // blue
            label: "Waiting for approval",
          };
        case 4:
          return {
            variant: "#54b962", // green
            label: "Completed",
          };
        default:
          return {
            variant: "#f8c12c", // yellow
            label: "Pending",
          };
      }
    },
    updateWorkOrderStatus(status) {
      if (status == 2) {
        status = 4;
      }
      if (status == 1) {
        status = 2;
      }
      if (status == 0) {
        this.showCancelNote = true;
      }
      this.$store
        .dispatch("PUT_WORK_ORDER", {
          id: this.workOrder.id,
          status: status,
          note: this.note,
        })
        .then((response) => {
          if (response.task.length > 0 && status == 4) {
            this.tasks = response.task;
            this.$bvModal.show("task-update-status-modal" + this.workOrder.id);
          } else {
            this.refreshData();
          }

          this.$root.$bvToast.toast("Work order status updated", {
            title: "Success",
            variant: "success",
          });
        })
        .catch(() => {
          this.$root.$bvToast.toast("could not update work order status", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    update(workOrder) {
      this.$store
        .dispatch("POST_WORK_ORDER", {
          id: workOrder.id,
          cost: this.form.cost,
          supplierCompanyId: this.form.supplier ? this.form.supplier.id : null,
        })
        .then(() => {
          this.refreshData();
          this.$root.$bvToast.toast("Work order cost updated", {
            title: "Success",
            variant: "success",
          });
        })
        .catch(() => {
          this.$root.$bvToast.toast("could not update work order cost", {
            title: "Error",
            variant: "warning",
          });
        });
    },

    openEmailBodyModal() {
      this.$root.$bvModal.show("email-body-modal" + this.workOrder.id);
    },
    openFilesModal(id) {
      this.$bvModal.show("work-order-files-modal" + id);
    },
    onSelectProvider(supplier) {
      this.form.supplier = supplier;
    },
    editWorkOrder() {
      this.$emit("update-work-order", this.workOrder);
      this.scrollToAnchor();
    },
    scrollToAnchor() {
      const element = document.getElementById("work-order-form");
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    },
    refreshAfterUpdateTask() {
      this.refreshData();
    },
  },
};
</script>

<style></style>
