import * as constants from "./actions";
import axios from "@/plugins/axios.js";
function getDefaultState() {
  return {
    exportPort: {
      status: null,
      data: {},
      error: null,
    },
    exportPorts: {
      status: null,
      data: [],
      error: null,
    },
    exportPortList: {
      status: null,
      data: [],
      error: null,
    },
  };
}
const actions = {
  [constants.GET_EXPORT_PORT]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_EXPORT_PORT);
      const response = await axios.get("exportLoad/getExportPort", {
        params: payload,
      });
      commit(constants.GET_EXPORT_PORT_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_EXPORT_PORT_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_EXPORT_PORT]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_EXPORT_PORT);
      const response = await axios.post("exportLoad/createExportPort", payload);
      commit(constants.POST_EXPORT_PORT_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_EXPORT_PORT_ERROR, error);
      throw error.response;
    }
  },

  [constants.PUT_EXPORT_PORT]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_EXPORT_PORT);
      const response = await axios.put("exportLoad/updateExportPort", payload);
      commit(constants.PUT_EXPORT_PORT_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_EXPORT_PORT_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_EXPORT_PORT_LIST]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_EXPORT_PORT_LIST);
      const response = await axios.get("exportLoad/listExportPort", {
        params: payload,
      });
      commit(constants.GET_EXPORT_PORT_LIST_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_EXPORT_PORT_LIST_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_EXPORT_PORT]: (state) => {
    state.exportPorts.data = [];
    state.exportPorts.status = "fetching";
  },
  [constants.GET_EXPORT_PORT_SUCCESS]: (state, response) => {
    state.exportPorts.status = "success";
    state.exportPorts.data = response;
  },
  [constants.GET_EXPORT_PORT_ERROR]: (state, response) => {
    state.exportPorts.status = "error";
    state.exportPorts.error = response;
  },
  [constants.POST_EXPORT_PORT]: (state) => {
    state.exportPort.status = "fetching";
    state.exportPort.data = {};
  },
  [constants.POST_EXPORT_PORT_SUCCESS]: (state, response) => {
    state.exportPort.status = "success";
    state.exportPort.data = response;
  },
  [constants.POST_EXPORT_PORT_ERROR]: (state, response) => {
    state.exportPort.status = "error";
    state.exportPort.error = response;
  },

  [constants.PUT_EXPORT_PORT]: (state) => {
    state.exportPort.status = "fetching";
    state.exportPort.data = {};
  },
  [constants.PUT_EXPORT_PORT_SUCCESS]: (state, response) => {
    state.exportPort.status = "success";
    state.exportPort.data = response;
  },
  [constants.PUT_EXPORT_PORT_ERROR]: (state, response) => {
    state.exportPort.status = "error";
    state.exportPort.error = response;
  },
  [constants.GET_EXPORT_PORT_LIST]: (state) => {
    state.exportPortList.data = [];
    state.exportPortList.status = "fetching";
  },
  [constants.GET_EXPORT_PORT_LIST_SUCCESS]: (state, response) => {
    state.exportPortList.status = "success";
    state.exportPortList.data = response;
  },
  [constants.GET_EXPORT_PORT_LIST_ERROR]: (state, response) => {
    state.exportPortList.status = "error";
    state.exportPortList.error = response;
  },
};
export default {
  state: getDefaultState(),
  actions,
  mutations,
};
