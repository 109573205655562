var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"d-flex justify-content-between"},[_vm._m(0),_c('div',[_c('font-awesome-icon',{staticClass:"icon-color cursor-pointer",attrs:{"size":"xl","icon":['far', 'clouds']},on:{"click":function($event){return _vm.openModal()}}})],1)]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v(" Marketplace:")]),_c('span',[_vm._v(_vm._s(_vm.expensesInformation.marketPlace))])]),(_vm.expensesInformation && _vm.expensesInformation.referencePrice)?_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v("Suggested sale price:")]),_c('span',{class:{
        'text-danger':
          _vm.expensesInformation.referencePrice <
          _vm.expensesInformation.startingPrice,
      }},[_vm._v(_vm._s(_vm._f("currency")(_vm.expensesInformation.referencePrice))+" "+_vm._s(_vm.corporation.typeCurrency))])]):_vm._e(),(
      _vm.expensesInformation &&
      _vm.expensesInformation.fx &&
      _vm.expensesInformation.marketPlace == 'CANAM'
    )?_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v("FX:")]),_c('span',[_vm._v(_vm._s(_vm.fxTwoDecimals)+" ")])]):_vm._e(),(_vm.expensesInformation)?_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v("Purchase cost:")]),_c('span',[_vm._v(_vm._s(_vm._f("currency")(_vm.expensesInformation.startingPrice))+" "+_vm._s(_vm.corporation.typeCurrency))])]):_vm._e(),(_vm.expensesInformation)?_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v("Addons:")]),_c('span',[_vm._v(_vm._s(_vm._f("currency")(_vm.expensesInformation.totalAddons))+" "+_vm._s(_vm.corporation.typeCurrency)+" ")])]):_vm._e(),(_vm.expensesInformation)?_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v("Total cost:")]),_c('span',[_vm._v(_vm._s(_vm._f("currency")((_vm.expensesInformation.totalAddons + _vm.expensesInformation.startingPrice)))+" "+_vm._s(_vm.corporation.typeCurrency)+" ")])]):_vm._e(),(_vm.expensesInformation.salePrice)?_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v("Sale price:")]),_c('span',[_vm._v(_vm._s(_vm._f("currency")(_vm.expensesInformation.salePrice))+" "+_vm._s(_vm.corporation.typeCurrency)+" ")])]):_vm._e(),(_vm.expensesInformation.profit)?_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v("Profit:")]),_c('span',[_vm._v(_vm._s(_vm._f("currency")(_vm.expensesInformation.profit))+" "+_vm._s(_vm.corporation.typeCurrency)+" ")])]):_vm._e(),(_vm.expensesInformation.addons)?_c('ExpensesSummaryByAddonType',{staticClass:"mt-2",attrs:{"addons":_vm.expensesInformation.addons}}):_vm._e(),_c('PricesModal')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h5"},[_c('i',[_vm._v("Vehicle information")])])
}]

export { render, staticRenderFns }