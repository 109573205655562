<template>
  <b-card
    class="shadow rounded mb-0"
    :class="isSelected ? 'border border-success' : ''"
  >
    <div class="d-flex justify-content-between text-wrap">
      <div class="h6 text-sm text-wrap">
        <span class="pr-0">
          {{ task.title }}
        </span>
      </div>
      <span class="text-sm cursor-pointer">
        <b-icon
          @click="toggleSelectTask()"
          v-if="isSelected"
          icon="check-circle"
          class="h2"
          variant="success"
          v-b-tooltip.hover
          title="Unselect task"
        ></b-icon>
        <b-icon
          @click="toggleSelectTask()"
          v-else
          icon="circle"
          class="h2"
          v-b-tooltip.hover
          title="Select task"
        >
        </b-icon>
      </span>
    </div>
    <p class="text-wrap">
      <TaskCardDescription
        v-if="task.description"
        :description="task.description"
      />
    </p>
    <!-- task status -->
    <p>
      Status: <strong>{{ getStatusFromId(task.taskBoardsId) }} </strong>
    </p>
  </b-card>
</template>

<script>
import TaskCardDescription from "./TaskCardDescription.vue";
export default {
  name: "TaskUpdateStatusModalListCard",
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  components: {
    TaskCardDescription,
  },
  data() {
    return {
      isSelected: false,
    };
  },
  methods: {
    onSelectTask() {
      this.$emit("onSelectTask", this.task);
    },
    toggleSelectTask() {
      this.isSelected = !this.isSelected;
      this.onSelectTask();
    },
    getStatusFromId(id) {
      switch (id) {
        case 1:
          return "Backlog";
        case 2:
          return "In progress";
        case 3:
          return "Review";
        case 4:
          return "Done";
        default:
          return "To do";
      }
    },
  },
};
</script>

<style>
.card.shadow.border-success {
  border: 1px solid #28a745 !important;
  border-color: #28a745 !important;
}
</style>