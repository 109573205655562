<template>
  <b-card>
    <b-tr> <b-th rowspan="6" class="text-center">Options</b-th> </b-tr>
    <b-row>
      <b-col md="6">
        <b-table
          stacked="md"
          :items="itemsStock1"
          :fields="fieldsStock1"
          head-row-variant="success"
          thead-class="d-none"
        >
        </b-table>
      </b-col>
      <b-col md="6">
        <b-table
          stacked="md"
          :items="itemsStock2"
          :fields="fieldsStock2"
          thead-class="d-none"
        >
        </b-table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  data() {
    return {
      fieldsStock1: [
        { key: "suffix", label: "" },
        {
          key: "value",
          label: "",
          class: "text-center",
        },
      ],
      itemsStock1: [
        {
          suffix: "Purchase Date",
          value: "Thursday, July 21, 2022",
        },
        {
          suffix: "Funds Wired",
          value: "Thursday, July 21, 2022",
        },
        {
          suffix: "Ownership Received",
          value: "Thursday, July 21, 2022",
        },
        {
          suffix: "Shipped to AVI		",
          value: "Thursday, July 21, 2022",
        },
      ],
      fieldsStock2: [
        { key: "suffix", label: "" },
        {
          key: "value",
          label: "",
          class: "text-center",
        },
      ],
      itemsStock2: [
        {
          suffix: "Shipped to Auction		",
          value: null,
        },
        {
          suffix: "Title Received		",
          value: null,
        },
        {
          suffix: "Sold	",
          value: "Friday, September 23, 2022",
        },
        {
          suffix: "Paid",
          value: "Monday, October 03, 2023",
        },
      ],
    };
  },
};
</script>

<style></style>
