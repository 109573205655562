export const GET_RI_LIST = 'GET_RI_LIST';
export const GET_RI_LIST_SUCCESS = 'GET_RI_LIST_SUCCESS';
export const GET_RI_LIST_ERROR = 'GET_RI_LIST_ERROR';

export const POST_RI = 'POST_RI';
export const POST_RI_SUCCESS = 'POST_RI_SUCCESS';
export const POST_RI_ERROR = 'POST_RI_ERROR';

export const GET_RI_NO_PAGINATE = "GET_RI_NO_PAGINATE"
export const GET_RI_NO_PAGINATE_SUCCESS = "GET_RI_NO_PAGINATE_SUCCESS"
export const GET_RI_NO_PAGINATE_ERROR = "GET_RI_NO_PAGINATE_ERROR"
