export const GET_SOLD = "GET_SOLD";
export const GET_SOLD_SUCCESS = "GET_SOLD_SUCCESS";
export const GET_SOLD_ERROR = "GET_SOLD_ERROR";

export const POST_SOLD = "POST_SOLD";
export const POST_SOLD_SUCCESS = "POST_SOLD_SUCCESS";
export const POST_SOLD_ERROR = "POST_SOLD_ERROR";

export const GET_SOLD_PDF = "GET_SOLD_PDF";
export const GET_SOLD_PDF_SUCCESS = "GET_SOLD_PDF_SUCCESS";
export const GET_SOLD_PDF_ERROR = "GET_SOLD_PDF_ERROR";

export const GET_SOLD_PDF_EMAIL = "GET_SOLD_PDF_EMAIL";
export const GET_SOLD_PDF_EMAIL_SUCCESS = "GET_SOLD_PDF_EMAIL_SUCCESS";
export const GET_SOLD_PDF_EMAIL_ERROR = "GET_SOLD_PDF_EMAIL_ERROR";

export const PUT_SOLD = "PUT_SOLD";
export const PUT_SOLD_SUCCESS = "PUT_SOLD_SUCCESS";
export const PUT_SOLD_ERROR = "PUT_SOLD_ERROR";

export const GET_SOLD_TAXES = "GET_SOLD_TAXES";
export const GET_SOLD_TAXES_SUCCESS = "GET_SOLD_TAXES_SUCCESS";
export const GET_SOLD_TAXES_ERROR = "GET_SOLD_TAXES_ERROR";
