/** @format */

import Vue from "vue";

/* add fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMobile, faCheck } from "@fortawesome/free-solid-svg-icons";

/* add some pro styles */
import { faCarCircleBolt } from "@fortawesome/pro-regular-svg-icons";
import { faCameraViewfinder } from "@fortawesome/pro-regular-svg-icons";
import { faFileInvoiceDollar } from "@fortawesome/pro-regular-svg-icons";
import { faMoneyCheckDollar } from "@fortawesome/pro-regular-svg-icons";
import { faCars } from "@fortawesome/pro-regular-svg-icons";
import { faCarWrench } from "@fortawesome/pro-regular-svg-icons";
import { faTruckContainer } from "@fortawesome/pro-regular-svg-icons";
import { faCheckToSlot } from "@fortawesome/pro-regular-svg-icons";
import { faCarWash } from "@fortawesome/pro-regular-svg-icons";
import { faWrench } from "@fortawesome/pro-regular-svg-icons";
import { faPersonDolly } from "@fortawesome/pro-regular-svg-icons";
import { faBuilding } from "@fortawesome/pro-regular-svg-icons";
import { faThLarge } from "@fortawesome/pro-regular-svg-icons";
import { faBoxes } from "@fortawesome/free-solid-svg-icons";
import { faMoneyCheck } from "@fortawesome/pro-regular-svg-icons";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { faIdCard } from "@fortawesome/pro-regular-svg-icons";
import { faTruckLoading } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/pro-regular-svg-icons";
import { faHandHoldingDollar } from "@fortawesome/pro-regular-svg-icons";
import { faSackDollar } from "@fortawesome/pro-regular-svg-icons";
import { faUserGroup } from "@fortawesome/pro-regular-svg-icons";
import { faTag } from "@fortawesome/pro-regular-svg-icons";
import { faFolder } from "@fortawesome/pro-regular-svg-icons";
import { faFileExcel } from "@fortawesome/pro-regular-svg-icons";
import { faFileSlash } from "@fortawesome/pro-regular-svg-icons";
import { faMessageDollar } from "@fortawesome/pro-regular-svg-icons";
import { faOctagonExclamation } from "@fortawesome/pro-regular-svg-icons";
import { faMagnifyingGlassPlus } from "@fortawesome/pro-regular-svg-icons";
import { faFilePdf } from "@fortawesome/pro-regular-svg-icons";
import { faLandmark } from "@fortawesome/pro-regular-svg-icons";
import { faFileMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { faLockKeyhole } from "@fortawesome/pro-regular-svg-icons";
import { faDollarSign } from "@fortawesome/pro-regular-svg-icons";
import { faMemoCircleCheck } from "@fortawesome/pro-regular-svg-icons";
import { faGaugeSimple } from "@fortawesome/pro-regular-svg-icons";
import { faClouds } from "@fortawesome/pro-regular-svg-icons";
import { faPuzzlePiece } from "@fortawesome/pro-regular-svg-icons";
import { faGavel } from "@fortawesome/pro-regular-svg-icons";
import { faMoneyBillTransfer } from "@fortawesome/pro-regular-svg-icons";
import { faMoneyCheckDollarPen } from "@fortawesome/pro-regular-svg-icons";
import { faChartPie } from "@fortawesome/pro-regular-svg-icons";
import { faBookOpenCover } from "@fortawesome/pro-regular-svg-icons";
import { faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { faCartShoppingFast } from "@fortawesome/pro-regular-svg-icons";
import { faCameraRotate } from "@fortawesome/pro-regular-svg-icons";


import {
  faTruckFast,
  faRightFromBracket,
  faFiles,
  faChartLineUp,
  faChartLineDown,
  faFileCsv,
  faGlobe,
  faCar,
  faGears,
  faNotes,
  faCalculator,
  faPlus,
  faListCheck,
  faCalendarClock,
  faCircle,
  faCircleCheck,
  faCircleInfo,
  faFileChartColumn,
  faFloppyDiskCircleArrowRight,
  faTrashCan,
  faTrashCanSlash,
  faCalendarDay,
  faUsers,
  faUser,
  faCircleQuestion,
  faFloppyDisk,
  faTruckRampBox,
  faMobileSignalOut
} from "@fortawesome/pro-regular-svg-icons";

import {
  faCircleRight,
  faCircleLeft
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faMobile);
library.add(faCar);
library.add(faCheck);
library.add(
  faCarCircleBolt,
  faCameraViewfinder,
  faFileInvoiceDollar,
  faFileChartColumn,
  faMoneyCheckDollar,
  faCars,
  faCarWrench,
  faTruckContainer,
  faCheckToSlot,
  faCarWash,
  faWrench,
  faPersonDolly,
  faBuilding,
  faThLarge,
  faBoxes,
  faMoneyCheck,
  faCog,
  faWrench,
  faIdCard,
  faTruckLoading,
  faLocationDot,
  faHandHoldingDollar,
  faSackDollar,
  faUserGroup,
  faTag,
  faFolder,
  faFileExcel,
  faFileSlash,
  faMessageDollar,
  faOctagonExclamation,
  faMagnifyingGlassPlus,
  faFilePdf,
  faLandmark,
  faFileMagnifyingGlass,
  faLockKeyhole,
  faDollarSign,
  faMemoCircleCheck,
  faGaugeSimple,
  faClouds,
  faGavel,
  faPuzzlePiece,
  faMoneyBillTransfer,
  faMoneyCheckDollarPen,
  faChartPie,
  faBookOpenCover,
  faTriangleExclamation,
  faCartShoppingFast,
  faTruckFast,
  faRightFromBracket,
  faFiles,
  faChartLineUp,
  faChartLineDown,
  faFileCsv,
  faGlobe,
  faCar,
  faGears,
  faNotes,
  faCalculator,
  faPlus,
  faListCheck,
  faCalendarClock,
  faCircle,
  faCircleCheck,
  faCircleInfo,
  faFileChartColumn,
  faFloppyDiskCircleArrowRight,
  faTrashCan,
  faTrashCanSlash,
  faCalendarDay,
  faUsers,
  faUser,
  faCircleQuestion,
  faFloppyDisk,
  faCircleRight,
  faCircleLeft,
  faTruckRampBox,
  faMobileSignalOut,
  faCameraRotate
);
Vue.component("font-awesome-icon", FontAwesomeIcon);
