<template>
  <div
    v-touch:touchhold="touchHoldHandler"
    @contextmenu.prevent="
      vehicle.vehicleFlags.carWash == 1
        ? $refs.menuCarwash.open($event, 'Payload')
        : ''
    "
  >
    <slot></slot>
    <ContextMenu v-if="vehicle.vehicleFlags.carWash == 1" ref="menuCarwash">
      <template slot-scope>
        <ContextMenuItem @click.native="markCarwashAsComplete()">
          Mark as complete
        </ContextMenuItem>
      </template>
    </ContextMenu>
    <TaskUpdateStatusModal :tasks="tasks" :id="vehicle.id" />
  </div>
</template>

<script>
import { superAdminMixin } from "../../mixins";
import ContextMenu from "../contextMenu/ContextMenu.vue";
import ContextMenuItem from "../contextMenu/ContextMenuItem.vue";
import { PUT_CARWASH } from "./actions";
import TaskUpdateStatusModal from "../../tasks/components/TaskUpdateStatusModal.vue";

export default {
  name: "TransferContextMenu",
  props: ["vehicle", "refreshData"],
  components: {
    ContextMenu,
    ContextMenuItem,
    TaskUpdateStatusModal,
  },
  mixins: [superAdminMixin],
  data() {
    return {
      tasks: [],
    };
  },

  methods: {
    markCarwashAsComplete() {
      const payload = {
        status: 2,
        vehicleId: this.vehicle.id,
      };

      this.$store
        .dispatch(PUT_CARWASH, payload)
        .then((response) => {
          if (response.task.length > 0) {
            /* show TaskUpdateStatusModal */
            this.tasks = response.task;
            this.$bvModal.show("task-update-status-modal" + this.vehicle.id);
          }
          this.toastMessage(
            "Carwash mark as completed successfully",
            "Success",
            "success"
          );
          this.$refs.menuCarwash.close();
          this.updateVehicleFlag(this.vehicle.id, "carWash", 2);
        })
        .catch(() => {
          this.toastMessage(
            "Carwash not mark as completed",
            "Warning",
            "warning"
          );
        });
    },
    touchHoldHandler($event) {
      this.$refs.menuCarwash.open($event, "Payload");
    },
  },
};
</script>

<style>
</style>