import * as constants from './actions';
import axios from '@/plugins/axios.js';

function getDefaultState() {
  return {
    saveVehicleSaleInfo: {
      status: null,
      error: null
    },
    buyers: {
      status: null,
      data: [],
      error: null,
      filters: {
        buyersClients: true
      }
    }
  };
}

const actions = {
  [constants.POST_SAVE_VEHICLE_SALE_INFO]: async ({ commit }, data) => {
    try {
      commit(constants.POST_SAVE_VEHICLE_SALE_INFO);
      const response = await axios.post('vehicle/sell', data);
      commit(constants.POST_SAVE_VEHICLE_SALE_INFO_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_SAVE_VEHICLE_SALE_INFO_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_BUYERS]: async ({ commit, state }) => {
    try {
      commit(constants.GET_BUYERS);

      const filters = Object.entries(state.buyers.filters).reduce(
        (a, [ k, v ]) => (v === null || v === '' ? a : { ...a, [k]: v }),
        {}
      );
      const response = await axios.get('user', { params: filters });
      commit(constants.GET_BUYERS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_BUYERS_ERROR, error);
      throw error.response;
    }
  }
};
const mutations = {
  [constants.POST_SAVE_VEHICLE_SALE_INFO]: (state) => {
    state.saveVehicleSaleInfo.status = 'fetching';
    state.saveVehicleSaleInfo.error = null;
  },
  [constants.POST_SAVE_VEHICLE_SALE_INFO_SUCCESS]: (state) => {
    state.saveVehicleSaleInfo.status = 'success';
    state.saveVehicleSaleInfo.error = null;
  },
  [constants.POST_SAVE_VEHICLE_SALE_INFO_ERROR]: (state, error) => {
    state.saveVehicleSaleInfo.status = 'error';
    state.saveVehicleSaleInfo.error = error;
  },
  [constants.GET_BUYERS]: (state) => {
    state.buyers.status = 'fetching';
    state.buyers.error = null;
  },
  [constants.GET_BUYERS_SUCCESS]: (state, data) => {
    state.buyers.status = 'success';
    state.buyers.data = data;
    state.buyers.error = null;
  },
  [constants.GET_BUYERS_ERROR]: (state, error) => {
    state.buyers.status = 'error';
    state.buyers.error = error;
  }
};

export default {
  state: getDefaultState(),
  actions,
  mutations
};
