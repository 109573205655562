export const GET_CREDIT_REQUEST_LIST = "GET_CREDIT_REQUEST_LIST";
export const GET_CREDIT_REQUEST_LIST_SUCCESS =
  "GET_CREDIT_REQUEST_LIST_SUCCESS";
export const GET_CREDIT_REQUEST_LIST_ERROR = "GET_CREDIT_REQUEST_LIST_ERROR";

export const POST_CREDIT_REQUEST = "POST_CREDIT_REQUEST";
export const POST_CREDIT_REQUEST_SUCCESS = "POST_CREDIT_REQUEST_SUCCESS";
export const POST_CREDIT_REQUEST_ERROR = "POST_CREDIT_REQUEST_ERROR";

export const PUT_CREDIT_REQUEST = "PUT_CREDIT_REQUEST";
export const PUT_CREDIT_REQUEST_SUCCESS = "PUT_CREDIT_REQUEST_SUCCESS";
export const PUT_CREDIT_REQUEST_ERROR = "PUT_CREDIT_REQUEST_ERROR";

export const GET_VEHICLES_CREDIT_REQUEST = "GET_VEHICLES_CREDIT_REQUEST";
export const GET_VEHICLES_CREDIT_REQUEST_SUCCESS =
  "GET_VEHICLES_CREDIT_REQUEST_SUCCESS";
export const GET_VEHICLES_CREDIT_REQUEST_ERROR =
  "GET_VEHICLES_CREDIT_REQUEST_ERROR";

export const GET_CREDIT_REQUEST_EXCEL = "GET_CREDIT_REQUEST_EXCEL";
export const GET_CREDIT_REQUEST_EXCEL_SUCCESS =
  "GET_CREDIT_REQUEST_EXCEL_SUCCESS";
export const GET_CREDIT_REQUEST_EXCEL_ERROR = "GET_CREDIT_REQUEST_EXCEL_ERROR";

export const DELETE_CREDIT_REQUEST = "DELETE_CREDIT_REQUEST";
export const DELETE_CREDIT_REQUEST_SUCCESS = "DELETE_CREDIT_REQUEST_SUCCESS";
export const DELETE_CREDIT_REQUEST_ERROR = "DELETE_CREDIT_REQUEST_ERROR";