import * as constants from "./actions";
import axios from "@/plugins/axios.js";
import notes from "./notes/store";
function getDefaultState() {
  return {
    registrations: {
      status: null,
      data: [],
      error: null,
    },

    registration: {
      status: null,
      data: {},
      error: null,
    },
    vehicles: {
      status: null,
      data: [],
      error: null,
    },
  };
}
const actions = {
  [constants.GET_REGISTRATIONS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_REGISTRATIONS);

      const response = await axios.get("registration/getRegistration", {
        params: payload,
      });
      commit(constants.GET_REGISTRATIONS_SUCCESS, response);
      commit;
      return response;
    } catch (error) {
      commit(constants.GET_REGISTRATIONS_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_REGISTRATION]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_REGISTRATION);
      const response = await axios.post("registration/create", payload);
      commit(constants.POST_REGISTRATION_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_REGISTRATION_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_VEHICLES_REGISTRATION]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_VEHICLES_REGISTRATION);
      const response = await axios.get("registration/listVehicles", {
        params: payload,
      });
      commit(constants.GET_VEHICLES_REGISTRATION_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLES_REGISTRATION_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_REGISTRATION_STATUS]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_REGISTRATION_STATUS);
      const response = await axios.put("registration/changeStatus", payload);
      commit(constants.PUT_REGISTRATION_STATUS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_REGISTRATION_STATUS_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_REGISTRATIONS]: (state) => {
    state.registrations.data = [];
    state.registrations.status = "fetching";
  },
  [constants.GET_REGISTRATIONS_SUCCESS]: (state, response) => {
    state.registrations.status = "success";
    state.registrations.data = response;
  },
  [constants.GET_REGISTRATIONS_ERROR]: (state, error) => {
    state.registrations.status = "error";
    state.registrations.error = error;
  },
  [constants.POST_REGISTRATION]: (state) => {
    state.registration.data = {};
    state.registration.status = "fetching";
  },
  [constants.POST_REGISTRATION_SUCCESS]: (state, response) => {
    state.registration.status = "success";
    state.registration.data = response;
  },
  [constants.POST_REGISTRATION_ERROR]: (state, error) => {
    state.registration.status = "error";
    state.registration.error = error;
  },
  [constants.GET_VEHICLES_REGISTRATION]: (state) => {
    state.vehicles.data = [];
    state.vehicles.status = "fetching";
  },
  [constants.GET_VEHICLES_REGISTRATION_SUCCESS]: (state, response) => {
    state.vehicles.status = "success";
    state.vehicles.data = response;
  },
  [constants.GET_VEHICLES_REGISTRATION_ERROR]: (state, error) => {
    state.vehicles.status = "error";
    state.vehicles.error = error;
  },
  [constants.PUT_REGISTRATION_STATUS]: (state) => {
    state.registration.data = {};
    state.registration.status = "fetching";
  },
  [constants.PUT_REGISTRATION_STATUS_SUCCESS]: (state, response) => {
    state.registration.status = "success";
    state.registration.data = response;
  },
  [constants.PUT_REGISTRATION_STATUS_ERROR]: (state, error) => {
    state.registration.status = "error";
    state.registration.error = error;
  },
};
export default {
  state: getDefaultState(),
  actions,
  mutations,
  modules: { notes },
};
