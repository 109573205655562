import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    timeline: {
      status: null,
      data: [],
      error: null,
    },
  };
}
const actions = {
  [constants.GET_TIMELINE]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_TIMELINE);
      const response = await axios.get("timeLine/getTimeLine", {
        params: payload,
      });
      commit(constants.GET_TIMELINE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_TIMELINE_ERROR, error);
      throw error.response;
    }
  }
};

const mutations = {
  [constants.GET_TIMELINE]: (state) => {
    state.timeline.data = [];
    state.timeline.status = "fetching";
  },
  [constants.GET_TIMELINE_SUCCESS]: (state, response) => {
    state.timeline.status = "success";
    state.timeline.data = response;
  },
  [constants.GET_TIMELINE_ERROR]: (state, error) => {
    state.timeline.status = "error";
    state.timeline.error = error;
  }
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};

