export const POST_VEHICLE_BUY_NOW = 'POST_VEHICLE_BUY_NOW';
export const POST_VEHICLE_BUY_NOW_SUCCESS = 'POST_VEHICLE_BUY_NOW_SUCCESS';
export const POST_VEHICLE_BUY_NOW_ERROR = 'POST_VEHICLE_BUY_NOW_ERROR';

export const POST_VEHICLE_MAKE_AN_OFFER = 'POST_VEHICLE_MAKE_AN_OFFER';
export const POST_VEHICLE_MAKE_AN_OFFER_SUCCESS =
  'POST_VEHICLE_MAKE_AN_OFFER_SUCCESS';
export const POST_VEHICLE_MAKE_AN_OFFER_ERROR =
  'POST_VEHICLE_MAKE_AN_OFFER_ERROR';

export const SET_VEHICLE_DETAILS_SIDEBAR = 'SET_VEHICLE_DETAILS_SIDEBAR';
export const SET_IS_SHOWING_VEHICLE_DETAILS_SIDEBAR =
  'SET_IS_SHOWING_VEHICLE_DETAILS_SIDEBAR';
