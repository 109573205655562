<template>
  <div>
    <div class="container-fluid">
      <b-row class="my-3">
        <b-col>
          <h4 class="ml-3">Delivered Checks</h4>
        </b-col>
      </b-row>
      <div class="text-right my-2">
        Total: {{ checks.data.sumDelivered | currency }}
      </div>
      <div>
        <b-table
          show-empty
          bordered
          small
          stacked="md"
          head-variant="light"
          :items="checks.data.payments.data || []"
          :fields="fields"
          :busy="checks.status === 'fetching'"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" variant="yellow" />
            </div>
          </template>
          <template #cell(selected)="{ item }">
            <div class="d-flex justify-content-center">
              <b-form-checkbox @change="(val) => onRowSelected(val, item)" />
            </div>
          </template>
          <template v-slot:cell(cost)="row">
            <div class="">
              {{ row.item.cost | currency }}
            </div>
          </template>

          <template v-slot:cell(logisticDeliveryDate)="{ item }">
            <div>
              {{ item.logisticDeliveryDate | formatDateTime }}
            </div>
          </template>
        </b-table>
        <div style="float: right">
          <b-pagination
            :value="checks.data.payments.currentPage"
            :total-rows="checks.data.payments.total"
            :per-page="checks.data.payments.perPage"
            class="mb-2"
            @change="changePage($event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { generalMixin } from "@/modules/mixin.js";
import { mapState } from "vuex";
import { GET_CONFIRMATION_CHECKS, PUT_CONFIRMATION_CHECKS } from "../actions";
export default {
  name: "CheckConfirmationTable",
  mixins: [generalMixin],
  computed: {
    ...mapState({
      checks: (state) => state.superAdminStore.confirmationChecks.checks,
    }),
  },
  data() {
    return {
      fields: [
        { key: "stockNumber", label: "Stock number" },
        { key: "transactionNumber", label: "Transaction Number" },
        { key: "cost", label: "Cost" },
        { key: "logisticDeliveryDate", label: "Logistic Delivery Date" },
      ],
      options: {
        assign: false,
        rolFilter: false,
        add: false,
      },
      rowsSelected: [],
      itemsSelected: [],
      checkStatus: 2,
    };
  },
  methods: {
    getSelectedItems(items) {
      this.rowsSelected = items;
    },
    onRowSelected(val, item) {
      if (val) {
        this.itemsSelected.push(item);
      } else {
        const index = this.itemsSelected.indexOf(item);
        this.itemsSelected.splice(index, 1);
      }
      this.getSelectedItems([...this.itemsSelected]);
    },
    getData() {
      this.$store
        .dispatch(GET_CONFIRMATION_CHECKS, { status: this.checkStatus })
        .then(() => {})
        .catch(() => {
          this.toastMessage("Checks could not be obtain", "Warning", "warning");
        });
    },
    markChecksAsDelivered() {
      this.$store
        .dispatch(PUT_CONFIRMATION_CHECKS, {
          payments: [...this.itemsSelected],
        })
        .then(() => {
          this.toastMessage("Checks marked as delivered", "Success", "success");
          this.getData();
        })
        .catch(() => {
          this.toastMessage(
            "Checks could not be marked as delivered",
            "Warning",
            "warning"
          );
        });
    },
    changePage(page, filter = "") {
      const payload = {
        search: filter,
        page: page,
        status: this.checkStatus,
      };
      this.$store
        .dispatch(GET_CONFIRMATION_CHECKS, payload)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
