export const POST_CARWASH = "POST_CARWASH";
export const POST_CARWASH_SUCCESS = "POST_CARWASH_SUCCESS";
export const POST_CARWASH_ERROR = "POST_CARWASH_ERROR";

export const GET_CARWASH = "GET_CARWASH";
export const GET_CARWASH_SUCCESS = "GET_CARWASH_SUCCESS";
export const GET_CARWASH_ERROR = "GET_CARWASH_ERROR";

export const PUT_CARWASH_INFO = "PUT_CARWASH_INFO";
export const PUT_CARWASH_INFO_SUCCESS = "PUT_CARWASH_INFO_SUCCESS";
export const PUT_CARWASH_INFO_ERROR = "PUT_CARWASH_INFO_ERROR";

export const GET_CARWASH_EXCEL = "GET_CARWASH_EXCEL";
export const GET_CARWASH_EXCEL_SUCCESS = "GET_CARWASH_EXCEL_SUCCESS";
export const GET_CARWASH_EXCEL_ERROR = "GET_CARWASH_EXCEL_ERROR";

export const GET_CARWASH_LIST = "GET_CARWASH_LIST";
export const GET_CARWASH_LIST_SUCCESS = "GET_CARWASH_LIST_SUCCESS";
export const GET_CARWASH_LIST_ERROR = "GET_CARWASH_LIST_ERROR";

export const PUT_CARWASH = "PUT_CARWASH";
export const PUT_CARWASH_SUCCESS = "PUT_CARWASH_SUCCESS";
export const PUT_CARWASH_ERROR = "PUT_CARWASH_ERROR";

export const GET_CARWASH_EMAIL = "GET_CARWASH_EMAIL";
export const GET_CARWASH_EMAIL_SUCCESS = "GET_CARWASH_EMAIL_SUCCESS";
export const GET_CARWASH_EMAIL_ERROR = "GET_CARWASH_EMAIL_ERROR";

export const GET_PROVIDERS_LIST = "GET_PROVIDERS_LIST";
export const GET_PROVIDERS_LIST_SUCCESS = "GET_PROVIDERS_LIST_SUCCESS";
export const GET_PROVIDERS_LIST_ERROR = "GET_PROVIDERS_LIST_ERROR";
