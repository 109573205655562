export const GET_PAYMENTS = "GET_PAYMENTS";
export const GET_PAYMENTS_SUCCESS = "GET_PAYMENTS_SUCCESS";
export const GET_PAYMENTS_ERROR = "GET_PAYMENTS_ERROR";

export const POST_PAYMENT = "POST_PAYMENT";
export const POST_PAYMENT_SUCCESS = "POST_PAYMENT_SUCCESS";
export const POST_PAYMENT_ERROR = "POST_PAYMENT_ERROR";

export const PUT_PAYMENT_DISABLE = "PUT_PAYMENT_DISABLE";
export const PUT_PAYMENT_DISABLE_SUCCESS = "PUT_PAYMENT_DISABLE_SUCCESS";
export const PUT_PAYMENT_DISABLE_ERROR = "PUT_PAYMENT_DISABLE_ERROR";

export const GET_PAYMENTS_TAXES = "GET_PAYMENTS_TAXES";
export const GET_PAYMENTS_TAXES_SUCCESS = "GET_PAYMENTS_TAXES_SUCCESS";
export const GET_PAYMENTS_TAXES_ERROR = "GET_PAYMENTS_TAXES_ERROR";

export const GET_VEHICLE_COST = "GET_VEHICLE_COST";
export const GET_VEHICLE_COST_SUCCESS = "GET_VEHICLE_COST_SUCCESS";
export const GET_VEHICLE_COST_ERROR = "GET_VEHICLE_COST_ERROR";
