export const GET_ADDONS = "GET_ADDONS";
export const GET_ADDONS_SUCCESS = "GET_ADDONS_SUCCESS";
export const GET_ADDONS_ERROR = "GET_ADDONS_ERROR";

export const POST_ADDON = "POST_ADDON";
export const POST_ADDON_SUCCESS = "POST_ADDON_SUCCESS";
export const POST_ADDON_ERROR = "POST_ADDON_ERROR";

export const DELETE_ADDON = "DELETE_ADDON";
export const DELETE_ADDON_SUCCESS = "DELETE_ADDON_SUCCESS";
export const DELETE_ADDON_ERROR = "DELETE_ADDON_ERROR";

export const GET_ADDONS_TAXES = "GET_ADDONS_TAXES";
export const GET_ADDONS_TAXES_SUCCESS = "GET_ADDONS_TAXES_SUCCESS";
export const GET_ADDONS_TAXES_ERROR = "GET_ADDONS_TAXES_ERROR";