export const POST_WORK_ORDER = "POST_WORK_ORDER";
export const POST_WORK_ORDER_SUCCESS = "POST_WORK_ORDER_SUCCESS";
export const POST_WORK_ORDER_ERROR = "POST_WORK_ORDER_ERROR";

export const GET_WORK_ORDERS = "GET_WORK_ORDERS";
export const GET_WORK_ORDERS_SUCCESS = "GET_WORK_ORDERS_SUCCESS";
export const GET_WORK_ORDERS_ERROR = "GET_WORK_ORDERS_ERROR";

export const GET_DAMAGE_PICTURES = "GET_DAMAGE_PICTURES";
export const GET_DAMAGE_PICTURES_SUCCESS = "GET_DAMAGE_PICTURES_SUCCESS";
export const GET_DAMAGE_PICTURES_ERROR = "GET_DAMAGE_PICTURES_ERROR";

export const PUT_WORK_ORDER = "PUT_WORK_ORDER";
export const PUT_WORK_ORDER_SUCCESS = "PUT_WORK_ORDER_SUCCESS";
export const PUT_WORK_ORDER_ERROR = "PUT_WORK_ORDER_ERROR";

export const GET_WORK_ORDER_XLS = "GET_WORK_ORDER_XLS";
export const GET_WORK_ORDER_XLS_SUCCESS = "GET_WORK_ORDER_XLS_SUCCESS";
export const GET_WORK_ORDER_XLS_ERROR = "GET_WORK_ORDER_XLS_ERROR";

export const GET_WORK_ORDER_EMAIL = "GET_WORK_ORDER_EMAIL";
export const GET_WORK_ORDER_EMAIL_SUCCESS = "GET_WORK_ORDER_EMAIL_SUCCESS";
export const GET_WORK_ORDER_EMAIL_ERROR = "GET_WORK_ORDER_EMAIL_ERROR";

export const GET_WORK_ORDERS_NO_PROVIDER = "GET_WORK_ORDERS_NO_PROVIDER";
export const GET_WORK_ORDERS_NO_PROVIDER_SUCCESS =
  "GET_WORK_ORDERS_NO_PROVIDER_SUCCESS";
export const GET_WORK_ORDERS_NO_PROVIDER_ERROR =
  "GET_WORK_ORDERS_NO_PROVIDER_ERROR";

export const POST_WORK_ORDER_ONLY_CATEGORY = "POST_WORK_ORDER_ONLY_CATEGORY";
export const POST_WORK_ORDER_ONLY_CATEGORY_SUCCESS = "POST_WORK_ORDER_ONLY_CATEGORY_SUCCESS";
export const POST_WORK_ORDER_ONLY_CATEGORY_ERROR = "POST_WORK_ORDER_ONLY_CATEGORY_ERROR";

export const PUT_WORK_ORDER_TASK_STATUS = "PUT_WORK_ORDER_TASK_STATUS";
export const PUT_WORK_ORDER_TASK_STATUS_SUCCESS = "PUT_WORK_ORDER_TASK_STATUS_SUCCESS";
export const PUT_WORK_ORDER_TASK_STATUS_ERROR = "PUT_WORK_ORDER_TASK_STATUS_ERROR";

export const GET_WORK_ORDER_COST_HISTORY = "GET_WORK_ORDER_COST_HISTORY";
export const GET_WORK_ORDER_COST_HISTORY_SUCCESS = "GET_WORK_ORDER_COST_HISTORY_SUCCESS";
export const GET_WORK_ORDER_COST_HISTORY_ERROR = "GET_WORK_ORDER_COST_HISTORY_ERROR";