<template>
  <div class="w-100 h-100">
    <b-row>
      <b-col class="pr-0">
        <b-button
          block
          variant="yellow"
          class="text-white"
          @click="type = 'barCode'"
        >
          Bar code
        </b-button>
      </b-col>
      <b-col class="pl-0">
        <b-button block variant="dark-green" @click="type = 'QR code'">
          QR
        </b-button>
      </b-col>
    </b-row>
    <div v-if="type == 'barCode'">
      <StreamBarcodeReader
        @decode="(a, b, c) => onDecode(a, b, c)"
        @loaded="() => onLoaded()"
      />
    </div>
    <div v-else id="camera-container">
      <QrcodeStream @decode="onDecode" @init="onInit" />
    </div>

    <b-button block variant="danger" @click="cancelScan"> Cancel </b-button>
  </div>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  name: "BarCodeReader",
  components: {
    StreamBarcodeReader,
    QrcodeStream,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      text: "",
      id: null,
      type: "barCode",
    };
  },
  methods: {
    onDecode(a, b, c) {
      console.log(a, b, c);
      this.text = a;
      if (this.text != undefined || this.text != "") {
        this.text = a;

        this.$emit("decode", this.text);
      }
      if (this.id) clearTimeout(this.id);
      this.id = setTimeout(() => {
        if (this.text === a) {
          this.text = "";
        }
      }, 5000);
    },
    cancelScan() {
      this.$emit("onCancel", false);
    },

    onLoaded() {
      this.text = "";
    },
    toogleType(type) {
      this.type = type;
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.errorCode = " user denied camera access permission";
        } else if (error.name === "NotFoundError") {
          this.errorCode = " no suitable camera device installed";
        } else if (error.name === "NotSupportedError") {
          this.errorCode = "page is not served over HTTPS";
        } else if (error.name === "NotReadableError") {
          this.errorCode = "maybe camera is already in use";
        } else if (error.name === "OverconstrainedError") {
          this.errorCode =
            "did you requested the front camera although there is none?";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.errorCode = " browser seems to be lacking features";
        }
      }
    },
  },
};
</script>
<style scoped></style>
