<template>
  <b-form @submit.stop.prevent="savePayment()">
    <b-row>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label="Accounting date">
          <template slot="label"> Reference </template>

          <b-form-input v-model="form.reference" type="text" />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label="Accounting date">
          <template slot="label"> Date </template>

          <b-form-input v-model="form.date" type="date" />
        </b-form-group>
      </b-col>

      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label="">
          <template slot="label"> Amount </template>
          <Money
            v-model="form.amount"
            class="form-control"
            v-bind="money"
            aria-describedby="input-amount-live-feedback"
            required
          />
          <b-form-invalid-feedback id="input-amount-live-feedback">
            This is a required field.
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <div class="text-right">
      <b-button
        variant="primary"
        type="submit"
        :disabled="exchangeContractPayment.status == 'fetching'"
      >
        <b-spinner
          v-if="exchangeContractPayment.status === 'fetching'"
          variant="white"
          small
        />

        <span v-else>Submit</span>
      </b-button>
    </div>
  </b-form>
</template>

<script>
import { POST_EXCHANGE_CONTRACT_PAYMENT } from "./actions";
import { Money } from "v-money";
import { mapState } from "vuex";
export default {
  name: "ExchangeContractPaymentForm",
  props: {
    refreshData: {
      type: Function,
      default: () => {},
    },
    contractId: {
      type: Number,
      default: 0,
    },
  },
  components: { Money },
  computed: {
    ...mapState({
      exchangeContractPayment: (state) =>
        state.superAdminStore.exchangeContract.payments.exchangeContractPayment,
    }),
  },
  data() {
    return {
      form: {
        reference: null,
        amount: 0,
        date: null,
      },
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        precision: 2,
        masked: false,
        suffix: " USD",
      },
    };
  },

  methods: {
    savePayment() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_EXCHANGE_CONTRACT_PAYMENT, payload)
        .then(() => {
          this.$bvToast.toast(
            "Exchange contract payment created successfully",
            {
              title: "Success",
              variant: "success",
              solid: true,
            }
          );
          this.refreshData();
          this.$bvModal.hide("exchange-contract-payment-details-modal");
          this.$bvModal.hide("exchange-contract-payment-modal");
        })
        .catch((err) => {
          console.log(err);
          this.$bvToast.toast("Error creating exchange  payment", {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        });
    },
    formatPayload() {
      return {
        reference: this.form.reference,
        amount: this.form.amount,
        date: this.form.date,
        contractId: this.contractId || null,
      };
    },
  },
};
</script>
