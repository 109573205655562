<template>
  <div>
    <b-modal
      :id="'task-update-status-modal' + id"
      :ref="'task-update-status-modal' + id"
      title="Task"
      hide-footer
      body-class="mt-0 pt-0"
    >
      <p class="">
        This vehicles has an active task, do you want to update status?
      </p>
      <div>
        <b-form-group label-cols="3" label-size="sm" label="Status">
          <b-form-select
            v-model="statusToChange"
            :options="[
              { value: 0, text: 'Select status' },
              { value: 2, text: 'In progress' },
              { value: 3, text: 'Review' },
              { value: 4, text: 'Done' },
            ]"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </div>
      <div>
        <span>Select task(s) to change status</span>
        <p v-if="tasksSelected.length > 0">
          {{ tasksSelected.length }} task(s) selected
        </p>
        <TaskUpdateStatusModalCard
          v-for="(task, index) in tasks"
          :key="index"
          :task="task"
          @onSelectTask="onSelectTask"
        />
      </div>

      <div class="text-right mt-2">
        <b-button
          variant="outline-danger"
          @click="
            closeModal();
            resetForm();
          "
          >Cancel</b-button
        >
        <b-button
          variant="success"
          class="mr-2"
          @click="updateTaskStatus()"
          :disabled="statusToChange === 0 || tasksSelected.length === 0"
          >Update</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import TaskUpdateStatusModalCard from "./TaskUpdateStatusModalCard.vue";
import { POST_TASK } from "../actions";
export default {
  name: "TaskUpdateStatusModalVue",
  props: {
    tasks: {
      type: Array,
      default: () => [],
    },
    id: {
      type: Number,
      required: true,
    },
  },
  components: {
    TaskUpdateStatusModalCard,
  },
  data() {
    return {
      tasksSelected: [],
      statusToChange: 0,
    };
  },
  methods: {
    updateTaskStatus() {
      const payload = {
        listIds: this.tasksSelected.map((t) => t.id),
        taskBoardId: this.statusToChange,
      };
      this.$store
        .dispatch(POST_TASK, payload)
        .then(() => {
          this.$bvToast.toast("Task(s) updated successfully", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.resetForm();
          this.closeModal();
          /* emit update */
          this.$emit("update-task");
        })
        .catch(() => {
          this.$bvToast.toast("Task(s) not updated", {
            title: "Warning",
            variant: "warning",
            solid: true,
          });
        });
    },
    onSelectTask(task) {
      const index = this.tasksSelected.findIndex((t) => t.id === task.id);
      if (index === -1) {
        this.tasksSelected.push(task);
      } else {
        this.tasksSelected.splice(index, 1);
      }
    },
    resetForm() {
      this.tasksSelected = [];
      this.statusToChange = 0;
    },
    closeModal() {
      this.$emit("update-task");

      this.$root.$emit("bv::hide::modal", "task-update-status-modal" + this.id);
    },
  },
};
</script>

<style>
</style>