<template>
  <b-card>
    <b-row>
      <b-col lg="3" xl="3" md="6" sm="12">
        Origin agent: <br />
        <i>{{ vehicle.firstName }} {{ vehicle.lastName }} </i>
      </b-col>
      <b-col lg="3" xl="3" md="6" sm="12">
        Cost: <br />
        <i>{{ vehicle.cost | currency }}</i>
      </b-col>
      <b-col lg="3" xl="3" md="6" sm="12">
        Addons: <br />
        <i>{{ vehicle.totalAmountCAD | currency }}</i>
      </b-col>
      <b-col lg="3" xl="3" md="6" sm="12">
        Total: <br />
        <i>{{ total | currency }}</i>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "VehicleInformation",
  props: {
    vehicle: {
      type: Object,
      required: false,
    },
  },
  computed: {
    total() {
      return this.vehicle.cost + this.vehicle.totalAmountCAD;
    },
  },
};
</script>

<style>
</style>