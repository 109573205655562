<template>
  <div>
    <p v-if="!showAll && description.length > maxLength">
      {{ truncatedDescription
      }}<span v-on:click="showAll = true" class="cursor-pointer">...</span>
    </p>
    <p v-else>
      {{ description
      }}<span v-if="showAll" v-on:click="showAll = false">
        <b> Show less</b>
      </span>
    </p>
    <div v-if="price">
      Price: <span>{{ price | currency }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TaskCardDescription",
  props: {
    description: {
      type: String,
      required: true,
    },
    maxLength: {
      type: Number,
      default: 150,
    },
    price: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      showAll: false,
    };
  },
  computed: {
    truncatedDescription() {
      return this.description.substring(0, this.maxLength) + "...";
    },
  },
};
</script>
