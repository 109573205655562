<template>
  <div class="d-flex justify-content-around">
    <multiselect
      v-model="agent"
      :options="agents"
      label="nickname"
      trackBy="id"
      @input="updateAgent"
    ></multiselect>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "AgentSelect",
  components: { Multiselect },
  props: {
    agentSelectedId: {
      type: Number,
      required: false,
    },
  },
  computed: {
    ...mapState({
      agents: (state) => state.vehicle.agents.data,
      agentsStatus: (state) => state.vehicle.agents.status,
    }),
  },

  data() {
    return {
      agent: null,
    };
  },
  created() {
    this.getAgents();
    if (this.agentSelectedId) {
      this.agent = this.agents.find(
        (agent) => agent.id === this.agentSelectedId
      );
    }
  },
  watch: {
    agentSelectedId() {
      this.agent = this.agents.find(
        (agent) => agent.id === this.agentSelectedId
      );
    },
  },

  methods: {
    updateAgent() {
      this.$emit("input", this.agent);
    },
    getAgents() {
      this.$store
        .dispatch("GET_AGENTS")
        .then(() => {
          if (this.agentSelectedId) {
            this.agent = this.agents.find(
              (agent) => agent.id === this.agentSelectedId
            );
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
</style>