<template>
  <b-modal :id="infoModal.id" :title="infoModal.title" hide-footer>
    <b-list-group v-if="exchangeSelected != null">
      <b-list-group-item
        v-for="vehicle in exchangeSelected.listVehicles"
        :key="vehicle.id"
      >
        Stock number:
        {{ vehicle.vehicle.stockNumber }}
        <b-icon
          icon="eye"
          @click="goToVinNumberView(vehicle.vehicle.id)"
          class="ml-2 cursor-pointer"
        ></b-icon>
        <br />
        Vin number:
        {{ vehicle.vehicle.vinNumber }}
      </b-list-group-item>
    </b-list-group>
    <div v-else class="text-center">
      <h6>No vehicles to show</h6>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    exchangeSelected: {
      type: Object,
      default: null,
    },
  },
  name: "ExchangeContractVehiclesModal",
  data() {
    return {
      infoModal: {
        id: "exchange-contract-vehicles-modal",
        title: "Vehicles",
        content: "",
      },
    };
  },
  methods: {
    goToVinNumberView(id) {
      /* encrypt id */
      const encryptedId = this.$CryptoJS.AES.encrypt(
        JSON.stringify(id),
        "Secret Passphrase"
      ).toString();

      this.$router.push({
        name: "superadmin.vehicles.unit",
        params: {
          id: encryptedId,
        },
      });
    },
  },
};
</script>

<style></style>
