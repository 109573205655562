import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    reserveList: {
      status: null,
      data: [],
      error: null,
    },
    vehicles: {
      status: null,
      data: [],
      error: null,

    }

  };
}
const actions = {
  [constants.GET_RESERVE_LIST]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_RESERVE_LIST);
      const response = await axios.get("reserve/getVehiclesReserved", {
        params: payload,
      });
      commit(constants.GET_RESERVE_LIST_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_RESERVE_LIST_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_VEHICLES_NO_RESERVE]: async ({ commit }, payload) => {
    try {
      console.log("payload", payload);
      commit(constants.GET_VEHICLES_NO_RESERVE);
      const response = await axios.get("reserve/listVehiclesNoReserved", {
        params: payload,
      });
      commit(constants.GET_VEHICLES_NO_RESERVE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLES_NO_RESERVE_ERROR, error);
      throw error.response;
    }
  }
};

const mutations = {
  [constants.GET_RESERVE_LIST]: (state) => {
    state.reserveList.status = "fetching";
    state.reserveList.error = null;
  },
  [constants.GET_RESERVE_LIST_SUCCESS]: (state, data) => {
    state.reserveList.status = "success";
    state.reserveList.error = null;
    state.reserveList.data = data;
  },
  [constants.GET_RESERVE_LIST_ERROR]: (state, error) => {
    state.reserveList.status = "error";
    state.reserveList.error = error;
  },
  [constants.GET_VEHICLES_NO_RESERVE]: (state) => {
    state.vehicles.status = "fetching";
    state.vehicles.error = null;
  },
  [constants.GET_VEHICLES_NO_RESERVE_SUCCESS]: (state, data) => {
    state.vehicles.status = "success";
    state.vehicles.error = null;
    state.vehicles.data = data;
  },
  [constants.GET_VEHICLES_NO_RESERVE_ERROR]: (state, error) => {
    state.vehicles.status = "error";
    state.vehicles.error = error;
  }
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};