<template>
  <b-row>
    <b-col xs="12" sm="12" md="6" lg="6" xl="6" class="mt-3">
      Vehicles
      <div>
        <b-form-input
          size="sm"
          aria-describedby="input-tps-live-feedback"
          maxlength="100"
          v-model="search"
          class="mb-1"
          placeholder="Search"
        />
      </div>
      <div class="overflow-auto max-height-35">
        <draggable
          v-if="vehiclesFree.length > 0"
          :list="vehiclesFree"
          group="stockNumber"
          @start="dragging = true"
          @end="dragging = true"
        >
          <VehicleCard
            v-for="vehicle in vehiclesFree"
            :key="vehicle.id"
            :vehicle="vehicle"
            :action="'add'"
            @add="addVehicleToRunList(vehicle)"
          >
          </VehicleCard>
        </draggable>
      </div>
    </b-col>
    <b-col xs="12" sm="12" md="6" lg="6" xl="6" class="mt-3">
      Run list
      <div>
        <b-form-input
          size="sm"
          aria-describedby="input-tps-live-feedback"
          maxlength="100"
          v-model="searchVehiclesSelected"
          class="mb-1"
          placeholder="Search"
        />
      </div>
      <div class="overflow-auto max-height-35">
        <draggable
          v-if="vehiclesSelected.length > 0"
          :list="vehiclesSelectedFilter"
          @start="dragging = true"
          @end="dragging = true"
        >
          <VehicleCard
            v-for="(vehicle, key) in vehiclesSelectedFilter"
            :key="key"
            :vehicle="vehicle"
            :action="'remove'"
            @remove="remove(vehicle)"
          >
          </VehicleCard>
        </draggable>
        <b-card v-else>
          Click <b-icon icon="arrow-right-circle"> </b-icon> to move here
        </b-card>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import draggable from "vuedraggable";
import { mapState } from "vuex";
/* import { GET_VEHICLES_TO_RELEASE_SUCCESS } from "./actions";
 */ import VehicleCard from "./VehicleCard.vue";
export default {
  name: "VehiclesList",
  props: {
    vehiclesPreSelected: {
      type: Array,
      required: false,
    },
  },
  components: {
    draggable,
    VehicleCard,
  },
  computed: {
    ...mapState({
      vehicles: (state) => state.superAdminStore.runList.vehicles,
    }),
    vehiclesFree() {
      let vehicles = [...this.vehicles.data];
      let vehiclesFiltered = vehicles.filter(
        (vehicle) => !this.vehiclesSelected.find((v) => v.id == vehicle.id)
      );
      if (this.search != null && this.search != "") {
        let vehiclesFree = vehicles.filter(
          (vehicle) => !this.vehiclesSelected.find((v) => v.id == vehicle.id)
        );
        let vehiclesFiltered = vehiclesFree.filter((vehicle) => {
          return (
            vehicle.title.toLowerCase().includes(this.search.toLowerCase()) ||
            vehicle.title.toLowerCase().includes(this.search.toLowerCase())
          );
        });
        return vehiclesFiltered;
      }
      return vehiclesFiltered;
    },
    vehiclesSelectedFilter() {
      /* filter vehicles using  searchVehiclesSelected*/
      if (
        this.searchVehiclesSelected != null &&
        this.searchVehiclesSelected != ""
      ) {
        let vehiclesFiltered = this.vehiclesSelected.filter((vehicle) => {
          return (
            vehicle.vinNumber
              .toLowerCase()
              .includes(this.searchVehiclesSelected.toLowerCase()) ||
            vehicle.stockNumber
              .toLowerCase()
              .includes(this.searchVehiclesSelected.toLowerCase())
          );
        });
        return vehiclesFiltered;
      }
      return this.vehiclesSelected;
    },
  },
  data() {
    return {
      vehiclesSelected: [],
      search: null,
      searchVehiclesSelected: null,
      enabled: true,
      list: [
        { name: "John", id: 0 },
        { name: "Joao", id: 1 },
        { name: "Jean", id: 2 },
      ],
      dragging: false,
    };
  },
  watch: {
    vehiclesSelected() {
      this.$emit("onVehiclesSelected", this.vehiclesSelected);
    },
  },
  methods: {
    remove(vehicle) {
      this.vehiclesSelected.splice(this.vehiclesSelected.indexOf(vehicle), 1);
      let newFreeVehicle = this.vehicles.data.find((v) => v.id == vehicle.id);
      if (!newFreeVehicle) {
        let vehiclesNew = this.vehiclesFree;
        vehiclesNew.push(vehicle);
        /*         this.$store.commit(GET_VEHICLES_TO_RELEASE_SUCCESS, vehiclesNew);
         */
      }
    },
    searchVehicles(search) {
      this.vehiclesFree = this.vehicles.data.filter((vehicle) => {
        return vehicle?.title.toLowerCase().includes(search.toLowerCase());
      });
    },
    addVehicleToRunList(vehicle) {
      this.vehiclesSelected.push(vehicle);
      let newFreeVehicle = this.vehiclesFree.find((v) => v.id == vehicle.id);
      if (newFreeVehicle) {
        let vehiclesNew = this.vehiclesFree;
        vehiclesNew.splice(vehiclesNew.indexOf(newFreeVehicle), 1);
        /*         this.$store.commit(GET_VEHICLES_TO_RELEASE_SUCCESS, vehiclesNew);
         */
      }
    },
  },
  mounted() {
    if (this.vehiclesPreSelected.length > 0) {
      this.vehiclesSelected = [...this.vehiclesPreSelected];
    }
  },
};
</script>

<style scoped>
.max-height-35 {
  max-height: 35rem !important;
}
</style>