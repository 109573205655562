<template>
  <div>
    <b-row>
      <b-col cols="11" class="mr-0">
        <vue-single-select
          v-if="!disabled"
          :classes="{ input: 'form-control', dropdown: 'dropdown' }"
          v-model="selected"
          :options="clients"
          optionLabel="companyName"
        >
          <template slot="option" slot-scope="{ option }">
            <div>
              <span
                @click="
                  selected = option;
                  onClickButton();
                "
              >
                {{ option.companyName }}
              </span>
            </div>
          </template>
        </vue-single-select>
        <b-form-group v-else label-size="sm">
          <b-form-input
            :value="selected ? selected.companyName : '---'"
            readonly
            size="sm"
          />
        </b-form-group>
      </b-col>
      <b-col cols="1" class="pl-0">
        <b-icon
          icon="person-plus-fill"
          scale="1.3"
          class="m-2"
          @click="showModal()"
        />
      </b-col>
    </b-row>
    <b-modal
      :id="'clientModal' + type"
      ref="clientModal"
      size="lg"
      hide-footer
      body-class="pl-0 pr-0"
      ok-disabled
    >
      <ClientForm :refresh-data="getClients" :hide-modal="hideModal" />
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ClientForm from "./Form.vue";
import { GET_CLIENTS } from "../../actions";
import store from "@/store";
import VueSingleSelect from "vue-single-select";

export default {
  components: { ClientForm, VueSingleSelect },
  props: ["clientId", "disabled", "type"],
  computed: {
    ...mapState({
      clientsStore: (state) => state.vehicle.clientsList.data,
    }),
    clientName() {
      return this.selected.companyName || "";
    },
  },
  data() {
    return {
      selected: null,
      model: null,
      clients: null,
    };
  },
  watch: {
    selected(val) {
      if (val) {
        this.selected = val;
        this.$emit("input", val);
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    // ...
    next();
  },
  mounted() {
    this.getClients();
  },
  methods: {
    onClickButton() {
      this.$emit("input", this.selected);
    },
    getClients() {
      store
        .dispatch(GET_CLIENTS)
        .then(() => {
          this.filterSelected();
          this.onClickButton();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filterSelected() {
      const clients = [...this.clientsStore];
      this.clients = [...this.clientsStore];
      if (this.clientId == null) {
        return;
      }
      const result = clients.filter((client) => client.id == this.clientId);
      this.selected = result[0];
    },

    showModal() {
      this.$bvModal.show("clientModal" + this.type);
    },
    hideModal() {
      this.$bvModal.hide("clientModal" + this.type);
    },
  },
};
</script>

<style></style>
