<template>
  <b-form>
    <b-form-group label="Description">
      <b-form-textarea
        v-model.trim="$v.form.description.$model"
        class="resize-none"
        :state="validateState('description')"
        aria-describedby="input-description-live-feedback"
        @change="setValue('description', $event)"
      />
      <b-form-invalid-feedback id="input-description-live-feedback">
        This is a required field.
      </b-form-invalid-feedback>
      <b-form-group class="mt-2">
        <template slot="label">
          <div>File</div>
        </template>
        <b-input-group>
          <b-form-file
            v-model="form.file"
            accept=".pdf"
            aria-describedby="input-file-live-feedback"
          />
        </b-input-group>
      </b-form-group>
    </b-form-group>
    <b-form-group label="Note">
      <b-form-textarea
        v-model.trim="$v.form.note.$model"
        class="resize-none"
        :state="validateState('note')"
        aria-describedby="input-note-live-feedback"
        @change="setValue('note', $event)"
      />
      <b-form-invalid-feedback id="input-note-live-feedback">
        This is a required field.
      </b-form-invalid-feedback>
    </b-form-group>

    <div class="text-right mt-2">
      <b-button
        :disabled="$v.form.$invalid || recall === 'fetching'"
        variant="success"
        @click="addRecall()"
      >
        <b-spinner v-if="recall === 'fetching'" variant="white" small />
        <span v-else>Submit</span>
      </b-button>
    </div>
  </b-form>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import { POST_RECALL } from "./actions";
import { superAdminMixin } from "../../mixins";
export default {
  mixins: [superAdminMixin],
  props: ["vehicle", "refreshData"],
  data() {
    return {
      form: {
        description: null,
        vehicleId: null,
        file: null,
        note: null,
      },
    };
  },
  computed: {
    ...mapState({
      recall: (state) =>
        state.superAdminStore.componentStore.recall.recall.status,
    }),
  },
  validations: {
    form: {
      description: {
        required,
      },
      note: {},
    },
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form.description = null;
      this.form.vehicleId = null;
      this.form.file = null;
      this.form.note = null;
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    addRecall() {
      if (this.$v.form.$invalid) return;
      this.form.vehicleId = this.vehicle.id;
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_RECALL, payload)
        .then(() => {
          this.resetForm();
          this.refreshData();
          this.toastMessage("Recall saved", "Success", "success");
        })
        .catch((error) => {
          console.log(error);
          this.toastMessage("Recall could not saved", "Warning", "warning");
        });
    },
    formatPayload() {
      const data = new FormData();
      data.set("description", this.form.description);
      data.set("vehicleId", this.form.vehicleId);
      data.set("file", this.form.file);
      data.set("note", this.form.note);
      return data;
    },
  },
};
</script>

<style></style>
