<template>
  <b-modal
    id="history-modal"
    ref="modal"
    modal-class="modal-fullscreen"
    centered
    scrollable
    size="lg"
    @show="
      getAgentsHistory();
      getMarketplacesHistory();
    "
    title="History"
    hide-footer
  >
    <VehicleDetails
      v-if="vehicle"
      :vehicle="vehicle"
      :color="vehicle.vehicleInformation.color || '----'"
    />

    <div>
      <b-tabs content-class="mt-3">
        <b-tab title="Agents" active>
          <AgentHistory />
        </b-tab>
        <b-tab title="Marketplace"> <MarketplaceHistory /> </b-tab>
      </b-tabs>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import VehicleDetails from "@/modules/superAdmin/components/VehicleDetails.vue";
import AgentHistory from "./AgentHistory.vue";
import MarketplaceHistory from "./MarketplaceHistory.vue";
import { GET_AGENTS_HISTORY, GET_MARKETPLACE_HISTORY } from "./actions";
import { superAdminMixin } from "../../mixins";

export default {
  name: "HistoryModal",
  components: { VehicleDetails, AgentHistory, MarketplaceHistory },
  mixins: [superAdminMixin],
  computed: {
    ...mapState({
      vehicle: (state) => state.superAdminStore.vehicle,
    }),
  },
  methods: {
    getAgentsHistory() {
      this.$store
        .dispatch(GET_AGENTS_HISTORY, { vehicleId: this.vehicle.id })
        .then(() => {});
    },
    getMarketplacesHistory() {
      this.$store
        .dispatch(GET_MARKETPLACE_HISTORY, {
          vehicleId: this.vehicle.id,
        })
        .then(() => {});
    },
  },
};
</script>

<style scoped>
</style>
