import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    runLists: {
      status: null,
      data: [],
      error: null,
    },
    runList: {
      status: null,
      data: {},
      error: null,
    },
    marketplaces: {
      status: null,
      data: [],
      error: null,
    },
    locations: {
      status: null,
      data: [],
      error: null,
    },
    vehicles: {
      status: null,
      data: [],
      error: null,
    },
    runListVehicles: {
      status: null,
      data: [],
      error: null,
    },
  };
}
const actions = {
  [constants.GET_RUN_LIST]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_RUN_LIST);

      const response = await axios.get("accounting/getRunList", {
        params: payload,
      });
      commit(constants.GET_RUN_LIST_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_RUN_LIST_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_RUN_LIST_EXCEL]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_RUN_LIST_EXCEL);

      const response = await axios.get("accounting/generateExcel", {
        params: payload,
        responseType: "blob",
      });
      commit(constants.GET_RUN_LIST_EXCEL_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_RUN_LIST_EXCEL_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_RUN_LIST]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_RUN_LIST);
      const response = await axios.post("accounting/createRunList", payload);
      commit(constants.POST_RUN_LIST_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_RUN_LIST_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_RUN_LIST_CATALOGS]: async ({ commit }) => {
    try {
      commit(constants.GET_RUN_LIST_CATALOGS);
      const response = await axios.get("accounting/getList");
      commit(constants.GET_RUN_LIST_CATALOGS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_RUN_LIST_CATALOGS_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_RUN_LIST_VEHICLES]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_RUN_LIST_VEHICLES);
      const response = await axios.get("accounting/findVehiclesByRunList", {
        params: payload,
      });
      commit(constants.GET_RUN_LIST_VEHICLES_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_RUN_LIST_VEHICLES_ERROR, error);
      throw error.response;
    }
  }

};

const mutations = {
  [constants.GET_RUN_LIST]: (state) => {
    state.runLists.data = [];
    state.runLists.status = "fetching";
  },
  [constants.GET_RUN_LIST_SUCCESS]: (state, response) => {
    state.runLists.status = "success";
    state.runLists.data = response;
  },
  [constants.GET_RUN_LIST_ERROR]: (state, error) => {
    state.runLists.status = "error";
    state.runLists.error = error;
  },
  [constants.GET_RUN_LIST_EXCEL]: (state) => {
    state.runList.data = {};
    state.runList.status = "fetching";
  },
  [constants.GET_RUN_LIST_EXCEL_SUCCESS]: (state, response) => {
    state.runList.status = "success";
    state.runList.data = response.runLists;
  },
  [constants.GET_RUN_LIST_EXCEL_ERROR]: (state, error) => {

    state.runList.status = "error";
    state.runList.error = error;
  },
  [constants.POST_RUN_LIST]: (state) => {
    state.runList.data = {};
    state.runList.status = "fetching";
  },
  [constants.POST_RUN_LIST_SUCCESS]: (state, response) => {
    state.runList.status = "success";
    state.runList.data = response.runList;
  },
  [constants.POST_RUN_LIST_ERROR]: (state, error) => {
    state.runList.status = "error";
    state.runList.error = error;
  },
  [constants.GET_RUN_LIST_CATALOGS]: (state) => {
    state.marketplaces.data = [];
    state.locations.data = [];
    state.vehicles.data = [];
    state.marketplaces.status = "fetching";
    state.locations.status = "fetching";
    state.vehicles.status = "fetching";
  },
  [constants.GET_RUN_LIST_CATALOGS_SUCCESS]: (state, response) => {
    state.marketplaces.status = "success";
    state.locations.status = "success";
    state.vehicles.status = "success";
    state.marketplaces.data = response.marketPlaces;
    state.locations.data = response.locations;
    state.vehicles.data = response.vehicles;
  },
  [constants.GET_RUN_LIST_CATALOGS_ERROR]: (state, error) => {
    state.marketplaces.status = "error";
    state.locations.status = "error";
    state.vehicles.status = "error";
    state.marketplaces.error = error;
    state.locations.error = error;
    state.vehicles.error = error;
  },
  [constants.GET_RUN_LIST_VEHICLES]: (state) => {
    state.runListVehicles.data = [];
    state.runListVehicles.status = "fetching";
  },
  [constants.GET_RUN_LIST_VEHICLES_SUCCESS]: (state, response) => {
    state.runListVehicles.status = "success";
    state.runListVehicles.data = response;
  },
  [constants.GET_RUN_LIST_VEHICLES_ERROR]: (state, error) => {
    state.runListVehicles.status = "error";
    state.runListVehicles.error = error;
  },
};


export default {
  state: getDefaultState(),
  actions,
  mutations,
};
