<template>
  <b-form>
    <b-form-group label="Description">
      <b-form-textarea
        v-model.trim="$v.form.description.$model"
        class="resize-none"
        :state="validateState('description')"
        aria-describedby="input-description-live-feedback"
        @change="setValue('description', $event)"
      />
      <b-form-invalid-feedback id="input-description-live-feedback">
        This is a required field.
      </b-form-invalid-feedback>
    </b-form-group>

    <div class="text-right mt-2">
      <b-button
        v-if="userHasPermission('wo', 'write')"
        :disabled="$v.form.$invalid || saveNote === 'fetching'"
        variant="primary"
        @click="addNote()"
      >
        <b-spinner v-if="saveNote === 'fetching'" variant="white" small />
        <span v-else>Submit</span>
      </b-button>
    </div>
  </b-form>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import { POST_WORK_ORDER_NOTE } from "./actions";
import { superAdminMixin } from "@/modules/superAdmin/mixins.js";
export default {
  mixins: [superAdminMixin],
  props: ["vehicle", "refreshData", "workOrder"],
  data() {
    return {
      form: {
        description: null,
        id: null,
      },
    };
  },
  computed: {
    ...mapState({
      saveNote: (state) =>
        state.superAdminStore.componentStore.workOrder.notes.note,
    }),
  },
  validations: {
    form: {
      description: {
        required,
      },
    },
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form.description = null;
      this.form.id = null;
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    addNote() {
      if (this.$v.form.$invalid) return;
      this.form.id = this.workOrder.id;
      this.form.type = "workOrder";
      this.$store
        .dispatch(POST_WORK_ORDER_NOTE, this.form)
        .then(() => {
          this.resetForm();
          this.toastMessage("Note saved", "Success", "success");
          this.refreshData();
        })
        .catch(() => {
          this.toastMessage("Note could not saved", "Warning", "warning");
        });
    },
  },
};
</script>

<style></style>
