<template>
  <b-container class="mt-3">
    <b-card>
      <b-row align-v="center" slot="header">
        <b-col cols="8">
          <h3 class="mb-0">Edit profile</h3>
        </b-col>
        <!--   <b-col cols="4" class="text-right">
          <a href="#!" class="btn btn-sm btn-primary">Settings</a>
        </b-col> -->
      </b-row>

      <b-form @submit.prevent="updateProfile">
        <h6 class="heading-small text-muted mb-4">User information</h6>
        <div class="pl-lg-4">
          <b-row>
            <b-col lg="6">
              <b-form-group label-size="md">
                <template slot="label"> Nickname </template>

                <b-form-input type="text" placeholder="nickname" v-model="form.nickname">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="6">
              <b-form-group label-size="md">
                <template slot="label"> Email address </template>
                <b-form-input type="email" v-model="form.email" readonly> </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6">
              <b-form-group label-size="md">
                <template slot="label"> First Name </template>
                <b-form-input
                  type="text"
                  label="First Name"
                  placeholder="First Name"
                  v-model="form.firstName"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="6">
              <b-form-group label-size="md">
                <template slot="label"> Last Name </template>
                <b-form-input
                  type="text"
                  label="Last Name"
                  placeholder="Last Name"
                  v-model="form.lastName"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <hr class="my-4" />

        <!-- Address -->
        <h6 class="heading-small text-muted mb-4">Contact information</h6>

        <!--    <div class="pl-lg-4">
          <b-row>
            <b-col md="12">
              <b-form-group label-size="md">
                <template slot="label"> Address </template>
                <b-form-input
                  v-if="isAddressSelected"
                  v-model="address"
                  size="md"
                  name="street"
                  maxlength="65"
                  @focus="isAddressSelected = false"
                />
                <place-autocomplete-field
                  v-else
                  v-model="address"
                  v-place-autofill:street="form.street"
                  v-place-autofill:city="form.city"
                  v-place-autofill:state="form.state"
                  v-place-autofill:zipcode="form.zip"
                  name="address"
                  api-key="AIzaSyBWj-z1916MmpcimZW3xPlRGXMikForpS4"
                  placeholder="Enter an an address, zipcode, or location"
                  country="us"
                  @autocomplete-select="selectAddress"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="12" lg="12" xl="12">
              <b-form-group label-size="md">
                <template slot="label"> Address line 2 </template>
                <b-form-input name="addressLine2" maxlength="200" />
              </b-form-group>
            </b-col>

            <b-col sm="12" md="12" lg="6" xl="6">
              <b-form-group label-size="md">
                <template slot="label"> Street </template>
                <b-form-input
                  v-model="form.street"
                  size="sm"
                  name="street"
                  maxlength="65"
                />
              </b-form-group>
            </b-col>

            <b-col sm="12" md="12" lg="6" xl="6">
              <b-form-group label-size="md">
                <template slot="label"> ZIP postal code </template>
                <b-form-input v-model="form.zip" size="sm" name="zip" maxlength="10" />
              </b-form-group>
            </b-col>
            <b-col sm="12" md="12" lg="6" xl="6">
              <b-form-group label-size="md">
                <template slot="label"> State </template>
                <b-form-input
                  v-model="form.state"
                  disabled
                  size="sm"
                  name="state"
                  maxlength="250"
                />
              </b-form-group>
            </b-col>

            <b-col sm="12" md="12" lg="6" xl="6">
              <b-form-group label-size="md">
                <template slot="label"> City </template>
                <b-form-input
                  v-model="form.city"
                  disabled
                  size="sm"
                  name="city"
                  maxlength="250"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div> -->
        <div class="pl-lg-4">
          <b-row>
            <b-col sm="12" md="6" lg="6" xl="6">
              <!-- Office phone number -->
              <b-form-group label-size="sm">
                <template slot="label">
                  Office phone number <span class="text-danger">*</span>
                </template>
                <b-input-group>
                  <vue-tel-input
                    v-model="form.officePhoneNumber"
                    default-country="us"
                    :input-options="options"
                    :dropdown-options="telDdOptions"
                    :auto-format="true"
                    :valid-characters-only="true"
                    mode="international"
                    style-classes="z-index-2"
                    :value="form.officePhoneNumber"
                  />

                  <b-form-input
                    v-model="form.extension"
                    type="number"
                    maxlength="6"
                    onkeydown="javascript: return event.keyCode == 69 ? false : true"
                    class="append-input"
                    placeholder="ext"
                    name="extension-input"
                    :value="form.extension"
                    size="sm"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col sm="12" md="6" lg="6" xl="6">
              <!-- Mobile phone -->
              <b-form-group label-size="sm" label="Mobile phone">
                <template slot="label"> Mobile phone </template>
                <vue-tel-input
                  size="md"
                  v-model.trim="form.mobileNumber"
                  default-country="us"
                  :input-options="options"
                  :dropdown-options="telDdOptions"
                  :auto-format="true"
                  :valid-characters-only="true"
                  :value="form.mobilePhone"
                  mode="international"
                  style-classes="z-index-2"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <hr class="my-4" />
        <!-- Description -->
      </b-form>
    </b-card>
  </b-container>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "UserProfile",
  computed: {
    ...mapState({
      profile: (state) => state.auth.loginStore.profile.data,
    }),
  },
  data() {
    return {
      isAddressSelected: false,
      address: "",
      form: {
        company: "Creative Code Inc.",
        nickname: "michael23",
        email: null,
        firstName: null,
        lastName: "Andrew",
        address: "Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09",
        city: "New York",
        country: "USA",
        postalCode: "",
        mobileNumber: null,
        officePhoneNumber: null,
        stockCod: null,
      },
      options: {
        type: "tel",
        validCharactersOnly: true,
        maxlength: 14,
      },
      telDdOptions: {
        showDialCodeInList: true,
        showFlags: true,
        showDialCodeInSelection: false,
        size: "sm",
      },
    };
  },

  created() {
    this.$store.commit("setAddFunction", null);
    this.$store.commit("setSearchFunction", false);
    this.formatProfileData();
  },
  methods: {
    updateProfile() {
      alert("Your data: " + JSON.stringify(this.form));
    },
    selectAddress() {
      this.isAddressSelected = true;
    },
    formatProfileData() {
      this.form.email = this.profile?.user?.email;
      this.form.firstName = this.profile?.user.firstName;
      this.form.lastName = this.profile?.user?.lastName;
      this.form.nickname = this.profile?.user?.nickname;
      this.form.stockCode = this.profile?.user?.stockCode;
      this.form.mobileNumber = this.profile?.user?.userDetails?.mobileNumber;
      this.form.officePhoneNumber = this.profile?.user.userDetails?.officePhoneNumber;
      this.form.extension = this.profile?.user.userDetails?.extension;
    },
  },
};
</script>

<style scoped>
.append-input {
  max-width: 50%;
}
</style>
