export const POST_TITLE_FILES = 'POST_TITLE_FILES';
export const POST_TITLE_FILES_SUCCESS = 'POST_TITLE_FILES_SUCCESS';
export const POST_TITLE_FILES_ERROR = 'POST_TITLE_FILES_ERROR';

export const GET_TITLE_FILES = 'GET_TITLE_FILES';
export const GET_TITLE_FILES_SUCCESS = 'GET_TITLE_FILES_SUCCESS';
export const GET_TITLE_FILES_ERROR = 'GET_TITLE_FILES_ERROR';

export const GET_TITLE_FILES_VEHICLES = 'GET_TITLE_FILES_VEHICLES';
export const GET_TITLE_FILES_VEHICLES_SUCCESS = 'GET_TITLE_FILES_VEHICLES_SUCCESS';
export const GET_TITLE_FILES_VEHICLES_ERROR = 'GET_TITLE_FILES_VEHICLES_ERROR';


export const UPDATE_EXPORTED_TITLE = 'UPDATE_EXPORTED_TITLE';
export const UPDATE_EXPORTED_TITLE_SUCCESS = 'UPDATE_EXPORTED_TITLE_SUCCESS';
export const UPDATE_EXPORTED_TITLE_ERROR = 'UPDATE_EXPORTED_TITLE_ERROR';

export const DISCARD_TITLE = 'DISCARD_TITLE';
export const DISCARD_TITLE_SUCCESS = 'DISCARD_TITLE_SUCCESS';
export const DISCARD_TITLE_ERROR = 'DISCARD_TITLE_ERROR';