export const GET_EXCHANGE_CONTRACTS = 'GET_EXCHANGE_CONTRACTS';
export const GET_EXCHANGE_CONTRACTS_SUCCESS = 'GET_EXCHANGE_CONTRACTS_SUCCESS';
export const GET_EXCHANGE_CONTRACTS_ERROR = 'GET_EXCHANGE_CONTRACTS_ERROR';

export const GET_FX_RATE = 'GET_FX_RATE';
export const GET_FX_RATE_SUCCESS = 'GET_FX_RATE_SUCCESS';
export const GET_FX_RATE_ERROR = 'GET_FX_RATE_ERROR';

export const POST_EXCHANGE_CONTRACT = 'POST_EXCHANGE_CONTRACT';
export const POST_EXCHANGE_CONTRACT_SUCCESS = 'POST_EXCHANGE_CONTRACT_SUCCESS';
export const POST_EXCHANGE_CONTRACT_ERROR = 'POST_EXCHANGE_CONTRACT_ERROR';

export const GET_EXCHANGE_CONTRACTS_VEHICLES_XLS = 'GET_EXCHANGE_CONTRACTS_VEHICLES_XLS';
export const GET_EXCHANGE_CONTRACTS_VEHICLES_XLS_SUCCESS = 'GET_EXCHANGE_CONTRACTS_VEHICLES_XLS_SUCCESS';
export const GET_EXCHANGE_CONTRACTS_VEHICLES_XLS_ERROR = 'GET_EXCHANGE_CONTRACTS_VEHICLES_XLS_ERROR';

export const GET_EXCHANGE_CONTRACTS_XLS = 'GET_EXCHANGE_CONTRACTS_XLS';
export const GET_EXCHANGE_CONTRACTS_XLS_SUCCESS = 'GET_EXCHANGE_CONTRACTS_XLS_SUCCESS';
export const GET_EXCHANGE_CONTRACTS_XLS_ERROR = 'GET_EXCHANGE_CONTRACTS_XLS_ERROR';

export const GET_EXCHANGE_XLS_BY_CURRENCY = 'GET_EXCHANGE_XLS_BY_CURRENCY';
export const GET_EXCHANGE_XLS_BY_CURRENCY_SUCCESS = 'GET_EXCHANGE_XLS_BY_CURRENCY_SUCCESS';
export const GET_EXCHANGE_XLS_BY_CURRENCY_ERROR = 'GET_EXCHANGE_XLS_BY_CURRENCY_ERROR';