<template>
  <div>
    <Nav @set-status="setType"></Nav>
    <div class="container mt-3">
      <b-table
        show-empty
        bordered
        small
        stacked="md"
        head-variant="light"
        :items="users.data ? users.data.data : []"
        :fields="fields"
        tbody-tr-class=""
        :busy="users.status == 'fetching'"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" variant="yellow" />
          </div>
        </template>
        <template v-slot:cell(marketPlacePermissions)="{ item }">
          <div>
            <Marketplaces :marketplaces="item.marketPlacePermissions" />
          </div>
        </template>
        <template v-slot:cell(company)="{ item }">
          <!--   <span v-if="item.userDetails.company">{{
            item.userDetails.company.companyName
          }}</span> -->
          <div>
            <CompanySelect
              :refreshData="getData"
              :user="item"
              :companyId="item.userDetails.companiesId"
            />
          </div>
        </template>
      </b-table>
      <b-row>
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="d-flex justify-content-end"
        >
          <b-pagination
            :value="users.data ? users.data.currentPage : 1"
            :total-rows="users.data ? users.data.total : 0"
            :per-page="users.data ? users.data.perPage : 0"
            class="mb-2"
            @change="changePage($event)"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { GET_USER_TO_ASSIGN_COMPANY } from "./actions";
import CompanySelect from "./CompanySelect.vue";
import Nav from "./Nav.vue";
import Marketplaces from "./Marketplaces.vue";
export default {
  components: {
    CompanySelect,
    Nav,
    Marketplaces,
  },
  computed: {
    ...mapState({
      users: (state) => state.adminStore.assignCompany.users || [],
    }),
  },
  name: "AssignCompanyTable",
  data() {
    return {
      fields: [
        {
          key: "createdAt",
          label: "Created at",
          formatter: (date) => this.$options.filters.formatDate(date),
        },
        { key: "firstName", label: "Name" },
        { key: "lastName", label: "Last name" },
        { key: "email", label: "Email" },
        { key: "userDetails.mobileNumber", label: "Mobile number" },
        { key: "userDetails.officePhoneNumber", label: "Office phone number" },

        { key: "marketPlacePermissions", label: "Marketplaces" },
        { key: "company", label: "Company" },
      ],
      type: "",
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData(filter = "") {
      this.$store
        .dispatch(GET_USER_TO_ASSIGN_COMPANY, { filter, type: this.type })
        .then(() => {})
        .catch(() => {});
    },
    setType(type) {
      this.type = type;
      this.getData();
    },
    changePage(page, filter = "") {
      const payload = {
        type: this.type,
        search: filter,
        page: page,
      };
      this.$store
        .dispatch(GET_USER_TO_ASSIGN_COMPANY, payload)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
  },
};
</script>

<style>
</style>