<template>
  <div>
    <b-form @submit.prevent="saveInvoice">
      <b-row>
        <b-col sm="12" md="12" lg="12" xl="12">
          <b-form-group>
            <template slot="label"> vehicle </template>
            <multiselect
              v-model="form.vehicle"
              :options="vehicles"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="false"
              placeholder="Pick some option"
              label="title"
              track-by="title"
              :preselect-first="false"
            >
              <template slot="tag" slot-scope="{ option, remove }"
                ><span class="custom__tag"
                  ><span class="">{{ option.vehicleNumber }}</span
                  ><span class="ml-1 custom__remove" @click="remove(option)"
                    >❌</span
                  ></span
                ></template
              >
            </multiselect>
          </b-form-group>
        </b-col>

        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group>
            <template slot="label"> Customer </template>

            <multiselect
              v-model="form.customer"
              :options="companies"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="false"
              placeholder="Pick some option"
              label="companyName"
              track-by="companyName"
              :preselect-first="false"
            >
              <template slot="tag" slot-scope="{ option, remove }"
                ><span class="custom__tag"
                  ><span class="">{{ option.companyName }}</span
                  ><span class="ml-1 custom__remove" @click="remove(option)"
                    >❌</span
                  ></span
                ></template
              >
            </multiselect>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group label="Currency">
            <b-form-select v-model="form.currency">
              <b-form-select-option value="CAD">CAD</b-form-select-option>
              <b-form-select-option value="USD">USD</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group label="Amount">
            <Money
              v-model="form.amount"
              class="form-control text-center"
              v-bind="money"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-end">
        <b-button
          :disabled="invoice.status == 'fetching' || form.vehicle == null"
          type="submit"
          variant="success"
        >
          <b-spinner
            v-if="invoice.status == 'fetching'"
            variant="white"
            small
          />
          Submit
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { Money } from "v-money";
import { mapState } from "vuex";
import { POST_INVOICE } from "./actions";
export default {
  name: "InvoiceForm",
  props: {
    refreshData: {
      type: Function,
      required: true,
    },
  },
  components: {
    Multiselect,
    Money,
  },
  computed: {
    ...mapState({
      vehicles: (state) => state.superAdminStore.tasks.vehicles.data,
      companies: (state) => state.superAdminStore.tasks.companies.data,
      invoice: (state) => state.superAdminStore.invoice.invoice,
    }),
  },
  data() {
    return {
      form: {
        vehicle: null,
        customer: "",
        amount: 0,
        currency: "CAD",
      },
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "CAD$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
    };
  },
  mounted() {
    this.getVehiclesAndUsers();
  },
  methods: {
    saveInvoice() {
      const payload = this.formPayload();
      this.$store
        .dispatch(POST_INVOICE, payload)
        .then(() => {
          this.$bvToast.toast("Invoice created successfully", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.resetForm();
          this.refreshData();
        })
        .catch((error) => {
          this.$bvToast.toast(error.response.data.message, {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        });
    },
    getVehiclesAndUsers() {
      this.$store
        .dispatch("GET_VEHICLES_AND_USERS", {
          invoiceRequest: 1,
        })
        .then(() => {});
    },
    formPayload() {
      return {
        vehicleId: this.form.vehicle?.id,
        companyId: this.form?.customer?.id,
        price: this.form.amount,
        currency: this.form.currency,
      };
    },
    resetForm() {
      this.form = {
        vehicle: "",
        customer: "",
        amount: 0,
        currency: "CAD",
      };
    },
  },
};
</script>

<style></style>
