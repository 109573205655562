<template>
  <div>
    <ExchangeContractNav />
    <div class="container-fluid mt-3">
      <div>
        <b-table
          class="mt-3"
          show-empty
          bordered
          small
          stacked="md"
          head-variant="light"
          :items="
            exchangeContractPayments.data
              ? exchangeContractPayments.data.data
              : []
          "
          :fields="fields"
          :busy="exchangeContractPaymentsIsLoading"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" variant="yellow" />
            </div>
          </template>
          <template #cell(contracts)="row">
            <div class="text-center">
              <font-awesome-icon
                v-if="row.item.exchangeContract.length > 0"
                class="cursor-pointer"
                :icon="['far', 'sack-dollar']"
                @click="info(row.item, $event.target)"
              />
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <b-modal
      id="exchange-contract-payment-details-modal"
      ref="exchange-contract-payment-details-modal"
      size="lg"
      hide-footer
      body-class=""
      ok-disabled
      @hidden="addonTypeItem = {}"
    >
      <ExchangeContractPaymentForm :refreshData="getData" />
    </b-modal>
    <ExchangeContractPaymentTableContractsModal
      :paymentSelected="paymentSelected"
    />
  </div>
</template>
<script>
import { GET_EXCHANGE_CONTRACT_PAYMENTS } from "./actions";
import { mapState } from "vuex";

import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../../mixins";
import ExchangeContractPaymentForm from "./ExchangeContractPaymentForm.vue";
import ExchangeContractNav from "../ExchangeContractNav.vue";
import ExchangeContractPaymentTableContractsModal from "./ExchangeContractPaymentTableContractsModal.vue";
export default {
  name: "ExchangeContractTable",
  mixins: [generalMixin, superAdminMixin],
  components: {
    ExchangeContractPaymentForm,
    ExchangeContractNav,
    ExchangeContractPaymentTableContractsModal,
  },
  computed: {
    ...mapState({
      exchangeContractPayments: (state) =>
        state.superAdminStore.exchangeContract.payments
          .exchangeContractPayments,
      exchangeContractPaymentsIsLoading: (state) =>
        state.superAdminStore.exchangeContract.payments.exchangeContractPayments
          .status === "fetching",
    }),

    fields() {
      return [
        { key: "reference", label: "Reference", class: "text-center" },
        {
          key: "date",
          label: "Date",
          class: "text-center",
          formatter: (value) => this.$options.filters.formatDate(value),
        },
        {
          key: "amount",
          label: "Amount",
          class: "text-center",
          formatter: (amount) =>
            typeof amount == "number"
              ? this.$options.filters.currency(amount)
              : "-",
        },
        {
          key: "pendingAmount",
          label: "Pending amount",
          class: "text-center",
          formatter: (amount) =>
            typeof amount == "number"
              ? this.$options.filters.currency(amount)
              : "-",
        },
        {
          key: "contracts",
          label: "Contracts",
          class: "text-center",
        },
      ];
    },
  },
  data() {
    return {
      paymentSelected: null,
    };
  },
  mounted() {
    this.$store.commit("setSearchFunction", this.getData);
    this.$store.commit("setAddFunction", this.showModal);

    this.getData();
  },
  methods: {
    getData(filter = "") {
      this.$store
        .dispatch(GET_EXCHANGE_CONTRACT_PAYMENTS, { search: filter })
        .then(() => {})
        .catch(() => {});
    },

    changePage(page, filter = "") {
      const payload = {
        search: filter,
        page: page,
      };
      this.$store
        .dispatch(GET_EXCHANGE_CONTRACT_PAYMENTS, payload)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    info(item) {
      this.paymentSelected = item;
      this.$bvModal.show("exchange-contract-payments-contracts");
    },

    showModal() {
      this.$bvModal.show("exchange-contract-payment-details-modal");
    },
  },
};
</script>
