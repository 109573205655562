<template>
  <b-modal
    :id="modalId"
    ref="modal"
    size="md"
    title="Company validation"
    scrollable
    @show="getCompanyValidationInfo()"
    @hidden="
      resetForm();
      refreshData();
    "
  >
    <Brand />
    <div
      v-if="!ready"
      class="align-self-center text-center"
    >
      <b-spinner
        class="align-self-center"
        label="Busy"
      />
    </div>
    <div
      v-if="ready"
      class="m-1 text-break"
    >
      <b>Company name:</b>
      <b-form-checkbox
        v-if="companyItem.companyName"
        v-model="formValidation.companyName"
        name="checkbox-5"
        :checked="formValidation.companyName"
        :value="1"
        :unchecked-value="0"
      >
        {{ companyItem.companyName }}
      </b-form-checkbox>
      <div v-else>
        No Company name
      </div>
      <br>
      <b>Taxid:</b>
      <b-form-checkbox
        v-if="companyItem.taxid"
        id="checkbox-1"
        v-model="formValidation.taxid"
        name="checkbox-1"
        :value="1"
        :unchecked-value="0"
      >
        {{ companyItem.taxid }}
      </b-form-checkbox>
      <div v-else>
        No taxid
      </div>
      <br>
      <b>Dealer license:</b>
      <b-form-checkbox
        v-if="companyItem.dealerLicense"
        id="checkbox-3"
        v-model="formValidation.dealerLicense"
        name="checkbox-3"
        :value="1"
        :unchecked-value="0"
      >
        {{ companyItem.dealerLicense }}
      </b-form-checkbox>
      <div v-else>
        No Dealer license
      </div>
    </div>
    <template v-slot:modal-footer="{ ok }">
      <b-btn
        :disabled="sendCompanyRegistrationEmailStatus"
        variant="info"
        @click="sendCompleRegistrationEmail()"
      >
        <b-spinner
          v-if="sendCompanyRegistrationEmailStatus"
          variant="white"
          small
        />
        <span v-else>Send complete registration email</span>
      </b-btn>
      <b-btn
        variant="pink"
        @click="changeCompanyStatus(ok, 3)"
      >
        Reject
      </b-btn>
      <b-btn
        v-if="
          formValidation.companyName &&
            formValidation.taxid &&
            formValidation.dealerLicense
        "
        variant="success"
        @click="changeCompanyStatus(ok, 1)"
      >
        <span>Accept</span>
      </b-btn>
      <b-btn
        v-if="
          !(
            formValidation.companyName &&
            formValidation.taxid &&
            formValidation.dealerLicense
          )
        "
        variant="yellow"
        @click="updateValidations(ok)"
      >
        <span>Save</span>
      </b-btn>
    </template>
  </b-modal>
</template>
<script>
import Brand from '@/modules/shared/brand.vue';
import { generalMixin } from '../../mixin';
import { validationMixin } from '@/modules/shared/validation/mixin';
import { mapState } from 'vuex';
import {
  UPDATE_VALIDATIONS,
  PUT_COMPANY_STATUS,
  POST_COMPANY_REGISTRATION_EMAIL
} from './actions';
export default {
  components: { Brand },
  mixins: [ generalMixin, validationMixin ],
  props: [ 'userEmail' ],
  data() {
    return {
      modalId: 'validation-modal',
      userItem: {}
    };
  },
  computed: {
    ...mapState({
      config: (state) => state.validation.config,
      companyItem: (state) => state.validation.companyItem,
      ready: (state) => state.validation.ready,
      refreshData: (state) => state.validation.refreshData,
      sendCompanyRegistrationEmailStatus: (state) =>
        state.validation.sendCompanyRegistrationEmail.status === 'fetching'
          ? true
          : false
    })
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    closeModal(callback) {
      this.refreshData();

      callback();
    },
    formatPayload() {
      const payload = {
        id: this.companyItem.validations.id,
        companyName: this.formValidation.companyName,
        taxid1: this.formValidation.taxid ? this.formValidation.taxid : false,
        dealerLicense: this.formValidation.dealerLicense
          ? this.formValidation.dealerLicense
          : false,

        companyId: this.companyItem.id
      };
      return payload;
    },
    resetForm() {},
    changeCompanyStatus(ok, status) {
      const payload = {
        id: this.companyItem.id,
        status
      };
      this.$store
        .dispatch(PUT_COMPANY_STATUS, payload)
        .then(() => {
          this.makeToast('Success', 'Company status updated', 'success');
          ok();
        })
        .catch(() => {
          this.$root.$bvToast.toast('Warning', {
            title: 'Warning',
            variant: 'warning'
          });
        });
    },
    async updateValidations(ok) {
      const payload = this.formatPayload();

      this.$store
        .dispatch(UPDATE_VALIDATIONS, payload)
        .then(() => {
          this.makeToast(
            'Success',
            'Validations updated succesfully',
            'success'
          );
          ok();
        })
        .catch(() => {
          this.$root.$bvToast.toast('Warning', {
            title: 'Warning',
            variant: 'warning'
          });
        });
    },
    sendCompleRegistrationEmail() {
      this.$store
        .dispatch(POST_COMPANY_REGISTRATION_EMAIL)
        .then(() => {
          this.$root.$bvToast.toast('Email sent', {
            title: 'Success',
            variant: 'success'
          });
        })
        .catch(() => {
          this.$root.$bvToast.toast('The email could not be sent', {
            title: 'Warning',
            variant: 'warning'
          });
        });
    }
  }
};
</script>
