<template>
  <div>
    <b-row class="mx-0">
      <b-col xs="12" md="12" lg="10" class="pr-0 pl-0 mx-0">
        <vue-single-select
          :classes="{
            input: 'form-control',
            dropdown: 'dropdown p-0',
          }"
          v-model="selected"
          :options="companies"
          optionLabel="companyName"
        >
          <template slot="option" class="p-0" slot-scope="{ option }">
            <div class="">
              <!--             <span> {{ option.firstName }} {{ option.lastName }}</span>
 -->
              <span> {{ option.companyName }}</span>
            </div>
          </template>
        </vue-single-select>
      </b-col>
      <b-col md="12" lg="2" class="px-1 mx-0 text-center">
        <b-icon
          @click="assignCompany()"
          icon="check-circle"
          font-scale="1.20"
          class="mr-1 mt-2 cursor-pointer"
          variant="success"
        >
        </b-icon>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { GET_COMPANIES_LIST, PUT_ASSIGN_COMPANY } from "./actions";
import VueSingleSelect from "vue-single-select";

import { mapState } from "vuex";
export default {
  components: { VueSingleSelect },
  props: ["user", "refreshData", "companyId"],
  computed: {
    ...mapState({
      companies: (state) =>
        state.adminStore.assignCompany.companiesToAssign.data || [],
    }),
  },

  data() {
    return {
      selected: null,
    };
  },
  created() {
    this.getUsersPermission();
    this.selectDefaultCompany();
  },
  methods: {
    getUsersPermission() {
      this.$store
        .dispatch(GET_COMPANIES_LIST, {})
        .then(() => {})
        .catch(() => {});
    },
    clearUserSelect() {
      this.selected = null;
    },
    assignCompany() {
      this.$store
        .dispatch(PUT_ASSIGN_COMPANY, {
          userId: this.user.id,
          companyId: this.selected.id,
        })
        .then(() => {
          this.$root.$bvToast.toast("Company assigned successfully", {
            title: "Success",
            variant: "success",
          });
          this.clearUserSelect();
          this.refreshData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectDefaultCompany() {
      if (this.companyId) {
        this.selected = this.companies.find(
          (company) => company.id === this.companyId
        );
      }
    },
  },
};
</script>

<style>
</style>