<!-- @format -->

<template>
  <div>
    <div class="container">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
          <b-form
            class="container mb-3"
            @submit.stop.prevent="form.id ? update() : save()"
          >
            <b-row class="mb-3">
              <b-col>
                <h4 class="text-purple-yellow">Dealer information</h4>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="12" md="12" lg="12" xl="12">
                <b-form-group label-size="md">
                  <template slot="label">
                    Commercial name <span class="text-danger">*</span></template
                  >
                  <b-form-input
                    v-model.trim="$v.form.companyName.$model"
                    size="md"
                    name="companyName"
                    id="input-commercial-name"
                    maxlength="100"
                    :value="form.companyName"
                    :state="validateState('companyName')"
                    @change="setValue('companyName', $event)"
                  />
                </b-form-group>
                <b-form-invalid-feedback id="input-commercial-name-feedback">
                  This field is required
                </b-form-invalid-feedback>
              </b-col>

              <b-col sm="12" md="12" lg="12" xl="12">
                <b-form-group label-size="md">
                  <template slot="label"> Company name </template>
                  <b-form-input
                    v-model.trim="$v.form.commercialName.$model"
                    size="md"
                    name="commercialName"
                    maxlength="100"
                    :value="form.commercialName"
                    :state="validateState('commercialName')"
                    @change="setValue('commercialName', $event)"
                  />
                </b-form-group>
              </b-col>
              <!-- Email -->
              <b-col sm="12" md="6" lg="6" xl="6">
                <b-form-group label-size="sm">
                  <template slot="label"> Email </template>
                  <b-form-input
                    size="md"
                    id="email-input"
                    name="email-input"
                    :formatter="lowerCaseFormatter"
                    @change="setValue('email', $event)"
                    :value="form.email"
                    v-model.trim="$v.form.email.$model"
                    :state="validateState('email')"
                    aria-describedby="input-email-live-feedback"
                    maxlength="255"
                  >
                  </b-form-input>
                  <b-form-invalid-feedback id="input-email-live-feedback">
                    This field must be a valid email.
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <!-- Email -->
              <b-col sm="12" md="6" lg="6" xl="6">
                <b-form-group label-size="sm">
                  <template slot="label"> Secondary email </template>
                  <b-form-input
                    id="email-secondary-input"
                    size="md"
                    :formatter="lowerCaseFormatter"
                    @change="setValue('secondaryEmail', $event)"
                    :value="form.secondaryEmail"
                    v-model.trim="$v.form.secondaryEmail.$model"
                    :state="validateState('secondaryEmail')"
                    maxlength="255"
                    type="email"
                  >
                  </b-form-input>
                  <b-form-invalid-feedback
                    id="input-email-secondary-live-feedback"
                  >
                    This field must be a valid email.
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                <!-- First name -->
                <b-form-group label-size="sm">
                  <template slot="label"> First name </template>
                  <b-form-input
                    size="md"
                    v-model.trim="$v.form.firstName.$model"
                    :state="validateState('firstName')"
                    maxlength="100"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>

              <!-- Last name -->
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group label-size="sm">
                  <template slot="label">Last name </template>
                  <b-form-input
                    size="md"
                    id="lastName-input"
                    name="lastName-input"
                    @change="setValue('lastName', $event)"
                    :value="form.lastName"
                    v-model.trim="$v.form.lastName.$model"
                    :state="validateState('lastName')"
                    aria-describedby="input-lastName-live-feedback"
                    maxlength="100"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>

              <!-- Office phone number -->
              <b-col sm="12" md="6" lg="6" xl="6">
                <b-form-group label-size="sm">
                  <template slot="label"> Office phone number </template>
                  <b-input-group>
                    <vue-tel-input
                      defaultCountry="us"
                      :inputOptions="options"
                      :dropdownOptions="telDdOptions"
                      :autoFormat="true"
                      :validCharactersOnly="true"
                      mode="international"
                      styleClasses="z-index-2"
                      @change="setValue('officePhoneNumber', $event)"
                      v-model.trim="$v.form.officePhoneNumber.$model"
                      :value="form.officePhoneNumber"
                      class="w-75"
                    />
                    <b-form-input
                      size="md"
                      type="tel"
                      maxlength="6"
                      max="999999"
                      onkeydown="javascript: return event.keyCode == 69 ? false : true"
                      class="append-input"
                      id="extension-input"
                      placeholder="ext"
                      name="extension-input"
                      @change="setValue('extension', $event)"
                      :value="form.extension"
                      v-model.trim="$v.form.extension.$model"
                      :state="validateState('extension')"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback> </b-form-invalid-feedback>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <!-- Mobile phone -->
              <b-col sm="12" md="6" lg="6" xl="6">
                <b-form-group label-size="sm" label="Mobile phone">
                  <template slot="label"> Mobile phone </template>
                  <vue-tel-input
                    v-model="form.mobileNumber"
                    defaultCountry="us"
                    :inputOptions="options"
                    :dropdownOptions="telDdOptions"
                    :autoFormat="true"
                    :validCharactersOnly="true"
                    @change="setValue('mobileNumber', $event)"
                    :value="form.mobileNumber"
                    mode="international"
                    styleClasses="z-index-2"
                  />

                  <b-form-invalid-feedback
                    id="input-mobile-phone-live-feedback"
                  >
                    This is a numeric field
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <!--Secondary  Mobile phone -->
              <b-col sm="12" md="6" lg="6" xl="6">
                <b-form-group label-size="sm" label="Mobile phone">
                  <template slot="label">Secondary mobile phone </template>
                  <vue-tel-input
                    v-model="form.secondaryMobileNumber"
                    defaultCountry="us"
                    :inputOptions="options"
                    :dropdownOptions="telDdOptions"
                    :autoFormat="true"
                    :validCharactersOnly="true"
                    @change="setValue('secondaryMobileNumber', $event)"
                    :value="form.secondaryMobileNumber"
                    mode="international"
                    styleClasses="z-index-2"
                  />
                </b-form-group>
              </b-col>

              <!-- Fax -->
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group label-size="sm">
                  <template slot="label">Fax number </template>
                  <b-form-input
                    size="md"
                    id="faxNumber-input"
                    name="faxNumber-input"
                    @change="setValue('faxNumber', $event)"
                    :value="form.faxNumber"
                    v-model.trim="$v.form.faxNumber.$model"
                    :state="validateState('faxNumber')"
                    aria-describedby="input-faxNumber-live-feedback"
                    maxlength="100"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>

              <!-- Code -->
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group label-size="sm">
                  <template slot="label">ID</template>
                  <b-form-input
                    size="md"
                    id="code-input"
                    name="code-input"
                    @change="setValue('companyId', $event)"
                    :value="form.companyId"
                    v-model.trim="$v.form.companyId.$model"
                    :state="validateState('companyId')"
                    aria-describedby="input-companyId-live-feedback"
                    maxlength="100"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <!-- to be deliver -->
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group label-size="sm">
                  <template slot="label">Customer delivers</template>
                  <b-form-checkbox
                    size="md"
                    name="to-be-deliver-input"
                    @change="setValue('toBeDelivered', $event)"
                    v-model="form.toBeDelivered"
                    aria-describedby="input-toBeDelivered-live-feedback"
                    :value="1"
                    :unchecked-value="0"
                  >
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group label-size="sm">
                  <template slot="label">Customer picks up</template>
                  <b-form-checkbox
                    size="md"
                    name="to-be-deliver-input"
                    @change="setValue('toBePickup', $event)"
                    v-model="form.toBePickup"
                    aria-describedby="input-toBePickup-live-feedback"
                    :value="1"
                    :unchecked-value="0"
                  >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="12" xl="12">
                <b-form-group label-size="md">
                  <template slot="label"> Address </template>
                  <b-form-input
                    v-if="isAddressSelected"
                    v-model="address"
                    size="md"
                    name="street"
                    class="mb-3"
                    maxlength="65"
                    @focus="isAddressSelected = false"
                  />
                  <place-autocomplete-field
                    v-else
                    v-model="address"
                    v-place-autofill:street="form.street"
                    v-place-autofill:city="form.city"
                    v-place-autofill:state="form.state"
                    v-place-autofill:zipcode="form.zip"
                    name="address"
                    api-key="AIzaSyBWj-z1916MmpcimZW3xPlRGXMikForpS4"
                    placeholder="Enter an an address, zipcode, or location"
                    country="us"
                    @autocomplete-select="selectAddress"
                  />
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="12" xl="12">
                <b-form-group label-size="md">
                  <template slot="label"> Address line 2 </template>
                  <b-form-input
                    name="addressLine2"
                    maxlength="200"
                    :value="form.addressLine2"
                    @change="setValue('addressLine2', $event)"
                  />
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label"> Street </template>
                  <b-form-input
                    v-model.trim="$v.form.street.$model"
                    size="sm"
                    name="street"
                    maxlength="65"
                    :value="form.street"
                    :state="validateState('street')"
                    @change="setValue('street', $event)"
                  />
                  <b-form-invalid-feedback>
                    <div v-if="!$v.form.street.maxLength">
                      Character limit reached
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label"> ZIP postal code </template>
                  <b-form-input
                    v-model.trim="$v.form.zip.$model"
                    size="sm"
                    name="zip"
                    maxlength="10"
                    :value="form.zip"
                    :state="validateState('zip')"
                    @change="setValue('zip', $event)"
                  />
                  <b-form-invalid-feedback>
                    <div v-if="!$v.form.zip.maxLength">
                      Character limit reached
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label"> State </template>
                  <b-form-input
                    v-model.trim="$v.form.state.$model"
                    disabled
                    size="sm"
                    name="state"
                    maxlength="250"
                    :value="form.state"
                    :state="validateState('state')"
                    @change="setValue('stateId', $event)"
                  />
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label"> City </template>
                  <b-form-input
                    v-model.trim="$v.form.city.$model"
                    disabled
                    size="sm"
                    name="city"
                    maxlength="250"
                    :value="form.city"
                    :state="validateState('city')"
                    @change="setValue('city', $event)"
                  />
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label"> Website </template>
                  <b-form-input
                    v-model.trim="form.webSite"
                    size="sm"
                    name="webSite"
                    placeholder="www.yourSite.com"
                    maxlength="65"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label"> GST/HST </template>
                  <b-form-input
                    v-model.trim="$v.form.gst.$model"
                    size="sm"
                    name="gst"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label"> QST </template>
                  <b-form-input
                    v-model.trim="$v.form.qst.$model"
                    size="sm"
                    name="qst"
                  />
                </b-form-group>
              </b-col>

              <!-- NEQ -->
              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label">
                    <b-form-radio-group
                      id="radio-group-2"
                      v-model="typeNumber"
                      name="radio-sub-component1"
                    >
                      <b-form-radio value="NEQ">NEQ</b-form-radio>
                      <b-form-radio value="BusinessNumber">
                        Business number
                      </b-form-radio>
                    </b-form-radio-group>
                    <!--      NEQ <span class="text-danger">*</span> -->
                  </template>
                  <b-form-input
                    size="sm"
                    name="corporationNumber"
                    maxlength="10"
                    @change="setValue('corporationNumber', $event)"
                    v-model.trim="$v.form.corporationNumber.$model"
                    :state="validateState('corporationNumber')"
                    :value="form.corporationNumber"
                  />
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label"> Dealer license </template>

                  <b-form-input
                    v-model.trim="$v.form.dealerLicense.$model"
                    size="sm"
                    name="dealerLicense"
                    maxlength="50"
                    :value="form.dealerLicense"
                    :state="validateState('dealerLicense')"
                    @change="setValue('dealerLicense', $event)"
                  />
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label"> Billing Contact name </template>
                  <b-form-input
                    v-model.trim="$v.form.billingContactName.$model"
                    size="sm"
                    name="billingContactName"
                    maxlength="100"
                    :value="form.billingContactName"
                    :state="validateState('billingContactName')"
                    @change="setValue('billingContactName', $event)"
                  />
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label"> Billing contact email </template>
                  <b-form-input
                    v-model.trim="$v.form.billingContactEmail.$model"
                    size="sm"
                    name="billingContactEmail"
                    maxlength="100"
                    placeholder="example@gmail.com"
                    :formatter="lowerCaseFormatter"
                    :value="form.billingContactEmail"
                    :state="validateState('billingContactEmail')"
                    @change="setValue('billingContactEmail', $event)"
                  />
                  <b-form-invalid-feedback>
                    <div v-if="!$v.form.billingContactEmail.email">
                      This must be a valid email
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label">
                    Billing contact mobile phone
                  </template>
                  <vue-tel-input
                    v-model="form.billingContactMobilePhone"
                    defaultCountry="us"
                    :inputOptions="options"
                    :dropdownOptions="telDdOptions"
                    :autoFormat="true"
                    :validCharactersOnly="true"
                    @change="setValue('billingContactMobilePhone', $event)"
                    :value="form.billingContactMobilePhone"
                    mode="international"
                    styleClasses="z-index-2"
                  />

                  <b-form-invalid-feedback
                    id="input-mobile-phone-live-feedback"
                  >
                    This is a numeric field
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <!-- isSupplier -->
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group label-size="sm">
                  <template slot="label">Is supplier</template>
                  <b-form-checkbox
                    size="md"
                    name="to-be-deliver-input"
                    v-model="form.isSupplier"
                    :value="1"
                    :unchecked-value="0"
                  >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="12" xl="12" class="mb-4">
                <b-button
                  v-if="userHasPermission('companies', 'write')"
                  class="float-right"
                  variant="success"
                  type="submit"
                  :disabled="$v.form.$invalid || isLoading"
                >
                  <b-spinner v-if="isLoading" small variant="white" />
                  <span v-else>Save</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { maxLength, email, required } from "vuelidate/lib/validators";
import { POST_DEALER, POST_VALIDATE_EMAIL } from "./actions";

import { mapState } from "vuex";
import { superAdminMixin } from "../../../superAdmin/mixins";

export default {
  props: ["refreshData", "hideModal", "userItem"],
  mixins: [superAdminMixin],
  data() {
    return {
      isAddressSelected: false,
      address: null,
      form: {
        id: null,
        firstName: "",
        lastName: "",
        email: "",
        officePhoneNumber: null,
        mobileNumber: null,
        extension: null,
        street: "",
        zip: "",
        state: "",
        city: "",
        webSite: "",
        dealerLicense: "",
        billingContactName: "",
        billingContactEmail: "",
        billingContactMobilePhone: null,
        companyName: "",
        addressLine2: "",
        gst: null,
        qst: "",
        corporationNumber: "",
        secondaryEmail: "",
        secondaryMobileNumber: null,
        faxNumber: null,
        companyId: null,
        commercialName: "",
        toBeDelivered: 1,
        toBePickup: 0,
        isSupplier: 0,
      },
      typeNumber: "NEQ",

      states: [],
      cities: [],
      options: {
        type: "tel",
        validCharactersOnly: true,
        maxlength: 18,
      },
      telDdOptions: {
        showDialCodeInList: true,
        showFlags: true,
        showDialCodeInSelection: false,
      },
    };
  },
  validations: {
    form: {
      firstName: {},
      lastName: {},
      email: {
        email,
      },

      officePhoneNumber: {},
      extension: {},
      mobileNumber: {},
      street: {
        maxLength: maxLength(250),
      },
      zip: {
        maxLength: maxLength(10),
      },
      state: {},
      city: {},

      dealerLicense: {},

      billingContactEmail: {
        email,
      },
      billingContactName: {},
      companyName: {
        required,
      },
      billingContactMobilePhone: {},
      gst: {},
      qst: {},
      corporationNumber: {},
      secondaryEmail: {},
      secondaryMobileNumber: {},
      faxNumber: {},
      companyId: {},
      commercialName: {},
      toBeDelivered: {},
      toBePickup: {},
      isSupplier: {},
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.buyer.buyerStore.user,
      emailValidation: (state) => state.vehicle.clients.emailValidation.data,
      dealer: (state) => state.vehicle.clients.dealer,
    }),
    isLoading() {
      return this.dealer.status == "fetching" ? true : false;
    },
  },
  mounted() {
    if (this.userItem.id) {
      this.formatForm();
    }
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async save() {
      await this.validateRepeatEmail();
      if (this.emailValidation.message === "invalid email") {
        return;
      }
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_DEALER, payload)
        .then(() => {
          this.$root.$bvToast.toast("Dealer successfully registered", {
            title: "Success",
            variant: "success",
          });
          this.refreshData();
          this.hideModal();
        })
        .catch((error) => {
          this.$root.$bvToast.toast(
            error.data.message.charAt(0).toUpperCase() +
              error.data.message.slice(1),
            {
              title: "Error",
              variant: "warning",
            }
          );
        });
    },

    formatPayload() {
      const payload = {
        id: this.form.id ? this.form.id : null,
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        email: this.form.email,
        officePhoneNumber: this.form.officePhoneNumber,
        extension: this.form.extension,
        mobileNumber: this.form.mobileNumber,
        street: this.form.street,
        zip: this.form.zip,
        state: this.form.state,
        webSite: this.form.webSite,
        dealerLicense: this.form.dealerLicense,
        billingContactName: this.form.billingContactName,
        billingContactEmail: this.form.billingContactEmail,
        billingContactMobilePhone: this.form.billingContactMobilePhone,
        city: this.form.city,
        companyName: this.form.companyName,
        addressLine2: this.form.addressLine2,
        qst: this.form.qst,
        gst: this.form.gst,
        secondaryEmail: this.form.secondaryEmail,
        secondaryMobileNumber: this.form.secondaryMobileNumber,
        faxNumber: this.form.faxNumber,
        companyId: this.form.companyId,
        commercialName: this.form.commercialName,
        toBeDelivered: this.form.toBeDelivered,
        toBePickup: this.form.toBePickup,
        isSupplier: this.form.isSupplier,
      };
      if (this.typeNumber === "NEQ") {
        payload.neq = this.form.corporationNumber;
      } else {
        payload.businessNumber = this.form.corporationNumber;
      }
      return payload;
    },
    selectAddress() {
      this.isAddressSelected = true;
    },
    formatForm() {
      this.form.id = this.userItem.id;
      if (this.userItem.user) {
        this.form.firstName = this.userItem.user.firstName;
        this.form.lastName = this.userItem.user.lastName;
        this.form.email = this.userItem.user.email;

        if (this.userItem.user.userDetails) {
          this.form.mobileNumber = this.userItem.user.userDetails.mobileNumber;
          this.form.extension = this.userItem.user.userDetails.extension;
        }
      }
      this.form.officePhoneNumber = this.userItem.officePhoneNumber;
      this.form.street = this.userItem.street;
      this.form.zip = this.userItem.zip;
      this.form.state = this.userItem.state;
      this.form.city = this.userItem.city;
      this.form.webSite = this.userItem.webSite;
      this.form.dealerLicense = this.userItem.dealerLicense;
      this.form.billingContactName = this.userItem.billingContactName;
      this.form.billingContactEmail = this.userItem.billingContactEmail;
      this.form.billingContactMobilePhone =
        this.userItem.billingContactMobilePhone;
      this.form.companyName = this.userItem.companyName;
      this.form.addressLine2 = this.userItem.addressLine2;
      this.form.gst = this.userItem.gstHst;
      this.form.qst = this.userItem.qst;
      this.form.corporationNumber = this.userItem.corporationNumber;
      this.form.secondaryEmail = this.userItem.secondaryEmail;
      this.form.companyId = this.userItem.companyId;
      this.form.commercialName = this.userItem.commercialName;

      this.form.toBeDelivered = this.userItem.toBeDelivered;
      this.form.toBePickup = this.userItem.toBePickup;
      this.form.isSupplier = this.userItem.isSupplier;
      this.form.secondaryMobileNumber = this.userItem.secondaryMobileNumber
        ? this.userItem.secondaryMobileNumber
        : null;
      this.form.faxNumber = this.userItem.faxNumber
        ? this.userItem.faxNumber
        : null;
      if (this.userItem.neq) {
        this.typeNumber = "NEQ";
        this.form.corporationNumber = this.userItem.neq;
      }

      if (this.userItem.businessNumber) {
        this.typeNumber = "BusinessNumber";
        this.form.corporationNumber = this.userItem.businessNumber;
      }
    },
    async update() {
      await this.validateRepeatEmail();
      if (this.emailValidation.message === "invalid email") {
        return;
      }
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_DEALER, payload)
        .then(() => {
          this.$root.$bvToast.toast("Dealer successfully updated", {
            title: "Success",
            variant: "success",
          });
          this.refreshData();
          this.hideModal();
        })
        .catch((error) => {
          this.$root.$bvToast.toast(
            error.data.messages.charAt(0).toUpperCase() +
              error.data.messages.slice(1),
            {
              title: "Error",
              variant: "warning",
            }
          );
        });
    },
    async validateRepeatEmail() {
      const payload = {
        userId: this.userItem?.user?.id ? this.userItem.user.id : null,
        email: this.form.email,
      };
      await this.$store
        .dispatch(POST_VALIDATE_EMAIL, payload)
        .then(() => {
          if (this.emailValidation.message === "invalid email") {
            this.$root.$bvToast.toast("This email has already been taken", {
              title: "Warning",
              variant: "warning",
            });
          }
        })
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
  },
};
</script>

<style></style>
