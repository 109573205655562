<template>
  <b-card
    v-if="car"
    header-bg-variant="dark-green"
    header-text-variant="white"
    class="shadow mb-3"
  >
    <template #header>
      <span class="float-right text-yellow"
        >(VIN) {{ car.vinNumber }} - {{ car.stockNumber }}</span
      >
    </template>
    <b-row>
      <b-col
        class="d-flex justify-content-between"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <small>Year <span class="text-danger">*</span> </small>
        <b-form-group>
          <b-form-input
            v-model.trim="$v.car.year.$model"
            class="text-center"
            :disabled="disabled"
            type="text"
            :value="car.year"
            :state="validateState('year')"
            @change="setValue('year', $event)"
          />
          <b-form-invalid-feedback>
            <div v-if="!$v.car.year.required">This field is required</div>
            <div v-if="!$v.car.year.minValue || !$v.car.year.maxValue">
              Invalid value
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col
        class="d-flex justify-content-between"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <small>Doors </small>
        <b-form-group>
          <b-form-input
            v-model.trim="$v.car.doors.$model"
            class="text-center"
            :disabled="disabled"
            type="number"
            :value="car.doors"
            :state="validateState('doors')"
            @change="setValue('doors', $event)"
          />
          <b-form-invalid-feedback>
            <div v-if="!$v.car.doors.maxLength || !$v.car.doors.minLength">
              Invalid value
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        class="d-flex justify-content-between"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <small>Make <span class="text-danger">*</span></small>
        <b-form-group>
          <b-form-input
            v-model.trim="$v.car.make.$model"
            class="text-center"
            :disabled="disabled"
            type="text"
            :value="car.make"
            :state="validateState('make')"
            @change="setValue('make', $event)"
          />
          <b-form-invalid-feedback>
            <div v-if="!$v.car.make.required">This field is required</div>
            <div v-if="!$v.car.make.maxLength || !$v.car.make.minLength">
              Invalid value
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        class="d-flex justify-content-between"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <small>Model <span class="text-danger">*</span></small>
        <b-form-group>
          <b-form-input
            v-model.trim="$v.car.model.$model"
            class="text-center"
            :disabled="disabled"
            type="text"
            :value="car.model"
            :state="validateState('model')"
            @change="setValue('model', $event)"
          />
          <b-form-invalid-feedback>
            <div v-if="!$v.car.model.required">This field is required</div>
            <div v-if="!$v.car.model.maxLength || !$v.car.model.minLength">
              Invalid value
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        class="d-flex justify-content-between"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <small>Trim <span class="text-danger">*</span></small>
        <b-form-group>
          <b-form-input
            v-model.trim="$v.car.trim.$model"
            class="text-center"
            :disabled="disabled"
            type="text"
            :value="car.trim"
            :state="validateState('trim')"
            @change="setValue('trim', $event)"
          />
          <b-form-invalid-feedback>
            <div v-if="!$v.car.trim.required">This field is required</div>
            <div v-if="!$v.car.trim.maxLength || !$v.car.trim.minLength">
              Invalid value
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        class="d-flex justify-content-between"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <small> Type <span class="text-danger">*</span></small>
        <b-form-group>
          <b-form-input
            v-model.trim="$v.car.vehicleType.$model"
            class="text-center"
            :disabled="disabled"
            type="text"
            :value="car.vehicleType"
            :state="validateState('vehicleType')"
            @change="setValue('vehicleType', $event)"
          />
          <b-form-invalid-feedback>
            <div v-if="!$v.car.vehicleType.required">
              This field is required
            </div>
            <div
              v-if="
                !$v.car.vehicleType.maxLength || !$v.car.vehicleType.minLength
              "
            >
              Invalid value
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        class="d-flex justify-content-between"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <small>Drive Type <span class="text-danger">*</span></small>
        <b-form-group>
          <b-form-input
            v-model.trim="$v.car.driveType.$model"
            class="text-center"
            :disabled="disabled"
            type="text"
            :value="car.driveType"
            :state="validateState('driveType')"
            @change="setValue('driveType', $event)"
          />
          <b-form-invalid-feedback>
            <div v-if="!$v.car.driveType.required">This field is required</div>
            <div
              v-if="!$v.car.driveType.maxLength || !$v.car.driveType.minLength"
            >
              Invalid value
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        class="d-flex justify-content-between"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <small>Style <span class="text-danger">*</span></small>
        <b-form-group>
          <b-form-input
            v-model.trim="$v.car.style.$model"
            class="text-center"
            :disabled="disabled"
            type="text"
            :value="car.style"
            :state="validateState('style')"
            @change="setValue('style', $event)"
          />
          <b-form-invalid-feedback>
            <div v-if="!$v.car.style.required">This field is required</div>
            <div v-if="!$v.car.style.maxLength || !$v.car.style.minLength">
              Invalid value
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        class="d-flex justify-content-between"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <small>Engine <span class="text-danger">*</span></small>
        <b-form-group>
          <b-form-input
            v-model.trim="$v.car.engine.$model"
            class="text-center"
            :disabled="disabled"
            type="text"
            :value="car.engine"
            :state="validateState('engine')"
            @change="setValue('engine', $event)"
          />
          <b-form-invalid-feedback>
            <div v-if="!$v.car.engine.required">This field is required</div>
            <div v-if="!$v.car.engine.maxLength || !$v.car.engine.minLength">
              Invalid value
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        class="d-flex justify-content-between"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <small>Transmission <span class="text-danger">*</span></small>
        <b-input-group v-if="!disabled" class="d-flex justify-content-end">
          <b-form-radio-group
            v-model="car.transmissionType"
            v-model.trim="$v.car.transmissionType.$model"
            style="max-height: 2.3rem"
            class="transmission"
            :options="typeOptions"
            name="transmission"
            button-variant="outline-dark-green"
            buttons
            :state="validateState('transmissionType')"
          />
          <b-form-invalid-feedback>
            <div v-if="!$v.car.transmissionType.required">
              This field is required
            </div>
          </b-form-invalid-feedback>
        </b-input-group>
        <b-form-group v-else>
          <b-form-input
            class="text-center"
            :disabled="disabled"
            type="text"
            :value="car.transmissionType"
          />
        </b-form-group>
      </b-col>

      <b-col
        class="d-flex justify-content-between"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <small>Color </small>
        <b-form-group>
          <vue-single-select
            v-if="car.color == null || car.color == ''"
            :classes="{
              input: 'form-control',
              dropdown: 'dropdown',
            }"
            :options="makesModelsAndColors.color"
            :state="validateState('color')"
            v-model="color"
            @change="setValue('color', $event)"
          >
            <template slot="option" slot-scope="{ option }">
              <div>
                <span>
                  {{ option }}
                </span>
              </div>
            </template>
          </vue-single-select>
          <b-form-input
            v-else
            v-model="color"
            class="text-center"
            :disabled="disabled"
            type="text"
            :value="color"
            :state="validateState('color')"
            @change="setValue('color', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col
        class="d-flex justify-content-between"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <small>Kms <span class="text-danger">*</span></small>
        <b-form-group>
          <b-form-input
            v-model="$v.car.kms.$model"
            class="text-center"
            :disabled="disabled"
            type="number"
            :state="validateState('kms')"
            @change="setValue('kms', $event)"
          />
          <b-form-invalid-feedback>
            <div v-if="!$v.car.kms.required">This field is required</div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        class="d-flex justify-content-between"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <small>Miles </small>
        <b-form-group>
          <b-form-input
            v-model="car.miles"
            class="text-center"
            readonly
            type="number"
          />
        </b-form-group>
      </b-col>

      <b-col
        class="d-flex justify-content-between"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <small>Stock number </small>
        <b-form-group>
          <b-form-input
            v-model="car.stockNumber"
            class="text-center"
            readonly
            type="text"
          />
        </b-form-group>
      </b-col>

      <b-col
        class="d-flex justify-content-between"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <small>Referral price </small>
        <b-form-group>
          <Money
            v-model.trim="car.referralPrice"
            class="form-control text-center"
            readonly
            v-bind="money"
          />
        </b-form-group>
      </b-col>

      <b-col
        class="d-flex justify-content-between"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <small>Cost </small>
        <b-form-group>
          <Money
            v-model.trim="car.cost"
            class="form-control text-center"
            readonly
            v-bind="money"
          />
        </b-form-group>
      </b-col>

      <b-col
        class="d-flex justify-content-between"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <small>Agent </small>
        <b-form-group>
          <b-form-input
            v-model="car.agent"
            class="text-center"
            readonly
            type="text"
          />
        </b-form-group>
      </b-col>
      <b-col
        class="d-flex justify-content-between"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <small>Month/Year of MFG </small>
        <b-form-group>
          <b-form-input
            v-model="car.dateMFG"
            class="text-center"
            type="text"
            @change="setValue('dateMFG', $event)"
            :formatter="monthYearFormatter"
            inputmode="numeric"
          />
        </b-form-group>
      </b-col>
      <b-col
        class="d-flex justify-content-between"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <small>GVWR total (Kg)</small>
        <b-form-group>
          <b-form-input
            v-model="car.gvwKg"
            class="text-center"
            type="number"
            inputmode="numeric"
            @input="car.gvw = car.gvwKg * 2.205"
          />
        </b-form-group>
      </b-col>

      <b-col
        class="d-flex justify-content-between"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <small>GVWR total (LBS)</small>
        <b-form-group>
          <b-form-input
            v-model="car.gvw"
            class="text-center"
            type="text"
            @change="setValue('gvw', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col
        class="d-flex justify-content-between"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <small>GVWR (REAR) Kg</small>
        <b-form-group>
          <b-form-input
            v-model="car.gvwrRearKg"
            class="text-center"
            type="number"
            @input="car.gvwrRear = car.gvwrRearKg * 2.205"
          />
        </b-form-group>
      </b-col>
      <b-col
        class="d-flex justify-content-between"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <small>GVWR (REAR) LBS</small>
        <b-form-group>
          <b-form-input
            v-model="car.gvwrRear"
            class="text-center"
            type="text"
            @change="setValue('gvwrRear', $event)"
          />
        </b-form-group>
      </b-col>

      <b-col
        class="d-flex justify-content-between"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <small>Front tire size</small>
        <b-form-group>
          <b-form-input
            v-model="car.frontTireSize"
            class="text-center"
            type="text"
            @input="
              setValue('frontTireSize', $event);
              car.rearTireSize = car.frontTireSize;
            "
            :formatter="tireFormatter"
            inputmode="numeric"
          />
        </b-form-group>
      </b-col>

      <b-col
        class="d-flex justify-content-between"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <small>Rear tire size</small>
        <b-form-group>
          <b-form-input
            v-model="car.rearTireSize"
            class="text-center"
            type="text"
            @change="setValue('rearTireSize', $event)"
            :formatter="tireFormatter"
            inputmode="numeric"
          />
        </b-form-group>
      </b-col>

      <b-col
        class="d-flex justify-content-between"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <small>GVWR (FRONT) Kg</small>
        <b-form-group>
          <b-form-input
            v-model="car.gvwrFrontKG"
            class="text-center"
            type="text"
            @input="car.gvwrFront = car.gvwrFrontKG * 2.205"
          />
        </b-form-group>
      </b-col>
      <b-col
        class="d-flex justify-content-between"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <small>GVWR (FRONT) LBS</small>
        <b-form-group>
          <b-form-input
            v-model="car.gvwrFront"
            class="text-center"
            type="text"
            @change="setValue('gvwrFront', $event)"
          />
        </b-form-group>
      </b-col>

      <b-col
        class="d-flex justify-content-between"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <small>Front PSI</small>
        <b-form-group>
          <b-form-input
            v-model="car.frontPsi"
            class="text-center"
            type="text"
            @input="
              setValue('frontPsi', $event);
              car.rearPsi = car.frontPsi;
            "
          />
        </b-form-group>
      </b-col>

      <b-col
        class="d-flex justify-content-between"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <small>Rear PSI</small>
        <b-form-group>
          <b-form-input
            v-model="car.rearPsi"
            class="text-center"
            type="text"
            @change="setValue('rearPsi', $event)"
          />
        </b-form-group>
      </b-col>

      <b-col
        class="d-flex justify-content-between"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <small>Rim size</small>
        <b-form-group>
          <b-form-input
            v-model="car.rimSize"
            class="text-center"
            type="text"
            @change="setValue('rimSize', $event)"
            :formatter="rimFormatter"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { Money } from "v-money";
import {
  required,
  maxValue,
  minValue,
  maxLength,
  minLength,
} from "vuelidate/lib/validators";
import { companyCarsMixin } from "@/modules/vehicle/components/visual-inspection/mixin";
import { mapState } from "vuex";
import VueSingleSelect from "vue-single-select";

export default {
  components: { Money, VueSingleSelect },
  mixins: [companyCarsMixin],
  props: ["carProp", "disabled", "reservedPriceProp"],
  computed: {
    ...mapState({
      makesModelsAndColors: (state) => state.vehicle.makesModelsAndColors.data,
    }),
    isVisualInspectionCR() {
      return this.$route.name === "company.cars.exterior.cr";
    },
  },

  data() {
    return {
      car: null,
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "CAD$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      typeOptions: [
        { value: "MANUAL", text: "M" },
        { value: "AUTOMATIC", text: "A" },
        { value: "CVT", text: "CVT" },
      ],
      color: null,
    };
  },

  watch: {
    color() {
      this.car.color = this.color;
      //this.$v.car.color.$touch();
      this.$emit("update", this.car);
    },
  },
  methods: {
    setValue(key, value) {
      this.car[key] = value;
      this.$v.car[key].$touch();
      this.$emit("update", this.car);
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.car[name];
      this.$emit("isInvalid", this.$v.car.$invalid);
      return $dirty ? !$error : null;
    },
    monthYearFormatter(value) {
      let formattedDateMFG = "";
      const input = value.replace(/\D/g, ""); // Remove non-numeric characters
      if (input.length <= 2) {
        return input;
      } else if (input.length <= 4) {
        formattedDateMFG = `${input.slice(0, 2)}/${input.slice(2)}`;
      } else {
        formattedDateMFG = `${input.slice(0, 2)}/${input.slice(2, 4)}`;
      }
      return formattedDateMFG;
    },
    tireFormatter(value) {
      let input = value.replace(/\D/g, ""); // Remove non-numeric characters
      let formattedTireSize = "";
      if (input.length <= 3) {
        formattedTireSize = input;
      } else if (input.length <= 6) {
        formattedTireSize = `${input.slice(0, 3)}/${input.slice(3)}`;
      } else if (input.length <= 8) {
        formattedTireSize = `${input.slice(0, 3)}/${input.slice(
          3,
          5
        )}/${input.slice(5)}`;
      } else {
        formattedTireSize = `${input.slice(0, 3)}/${input.slice(
          3,
          5
        )}/${input.slice(5, 7)}`;
      }
      return formattedTireSize;
    },
    rimFormatter(value) {
      let input = value.toUpperCase(); // Convert input to uppercase for consistency with "J"

      // Remove any characters that aren't numbers, "x", ".", or "J"
      input = input.replace(/[^0-9XJ.]/g, "");

      // Handle basic rim size formatting logic (e.g., 18x8.0)
      const parts = input.split("X");
      let formattedRimSize = "";
      if (parts.length === 1 && parts[0].length <= 2) {
        formattedRimSize = parts[0]; // Num1
      } else if (parts.length === 2) {
        const num1 = parts[0].substring(0, 2); // Num1 part (up to 2 digits)
        let num2 = parts[1].replace(/[^0-9J.]/g, ""); // Remove anything except numbers, ".", or "J" for Num2

        // Ensure that Num2 always has ".0" at the end if it doesn't contain a "J"
        if (num2.length > 0 && !num2.includes(".") && !num2.includes("J")) {
          num2 += ".0";
        }

        formattedRimSize = `${num1}x${num2}`;
      }
      return formattedRimSize;
    },
  },
  validations: {
    car: {
      year: {
        required,
        maxValue: maxValue(2050),
        minValue: minValue(1900),
      },
      doors: {
        maxLength: maxLength(50),
        minLength: minLength(1),
      },
      make: {
        required,
        maxLength: maxLength(50),
        minLength: minLength(2),
      },
      model: {
        required,
        maxLength: maxLength(50),
        minLength: minLength(2),
      },
      trim: {
        required,
        maxLength: maxLength(50),
        minLength: minLength(1),
      },
      vehicleType: {
        required,
        maxLength: maxLength(50),
        minLength: minLength(2),
      },
      driveType: {
        required,
        maxLength: maxLength(50),
        minLength: minLength(2),
      },
      style: {
        required,
        maxLength: maxLength(50),
        minLength: minLength(2),
      },
      transmissionType: {
        required,
      },
      engine: {
        required,
        maxLength: maxLength(250),
        minLength: minLength(2),
      },
      color: {},
      kms: {
        required,
        maxValue: maxValue(1000000),
        minValue: minValue(1),
      },
      odometerMI: {},
      dateMFG: {},
      gvw: {},
      gvwrRear: {},
      frontTireSize: {},
      rearTireSize: {},
      gvwrFront: {},
      frontPsi: {},
      rearPsi: {},
      rimSize: {},
    },
  },
  mounted() {
    this.car = { ...this.carProp };
    this.color = this.car.color;
    this.car.color = null;
    this.car.color = this.color;
  },
};
</script>

<style scoped>
.border-left {
  border-left: 5px black;
}
.font-small {
  font-size: 12px;
}
.border-b {
  border-bottom: 10px black;
}
.transmission {
  width: 12rem;
}
</style>
