import categories from './categories/store';
import providers from './service-providers/store';
function getDefaultState() {
  return {};
}

const actions = {};

export default {
  state: getDefaultState(),
  actions,
  modules: {
    categories,
    providers
  }
};
