import * as constants from './actions';
import axios from '@/plugins/axios.js';

function getDefaultState() {
  return {
    itemUser: {},
    companyItem: {},
    ready: false,
    user: {},
    refreshData: null,
    validations: { status: null, data: null, error: null },
    companyStatus: { status: null, data: null, error: null },
    sendCompanyRegistrationEmail: {
      email: null,
      status: null,
      data: null,
      error: null
    }
  };
}
const actions = {
  [constants.GET_VALIDATIONS]: async ({ commit }, params) => {
    try {
      commit('setCompanyItem', {});
      const response = await axios.get('company', { params: { ...params } });
      commit('setCompanyItem', response.data[0]);
      commit('setReady', true);
      return response;
    } catch (error) {
      commit(constants.GET_VALIDATIONS_ERROR, error);
      throw error.response;
    }
  },
  [constants.UPDATE_VALIDATIONS]: async ({ commit }, params) => {
    try {
      const response = await axios.put('company/validations', params);
      commit(constants.UPDATE_VALIDATIONS_SUCCESS, response);

      return response;
    } catch (error) {
      throw error.response;
    }
  },
  [constants.PUT_COMPANY_STATUS]: async ({ commit }, params) => {
    try {
      commit(constants.PUT_COMPANY_STATUS);
      const response = await axios.put('/company', params);
      commit(constants.PUT_COMPANY_STATUS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_COMPANY_STATUS_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_COMPANY_REGISTRATION_EMAIL]: async ({ state, commit }) => {
    try {
      commit(constants.POST_COMPANY_REGISTRATION_EMAIL);
      const response = await axios.post('/user/send-company-email', {
        email: state.sendCompanyRegistrationEmail.email
      });
      commit(constants.POST_COMPANY_REGISTRATION_EMAIL_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_COMPANY_REGISTRATION_EMAIL_ERROR, error);
      throw error.response;
    }
  }
};

const mutations = {
  setItemUser: (state, item) => {
    state.itemUser = item;
  },
  setConfig: (state, item) => {
    state.config = item;
  },
  setCompanyItem: (state, item) => {
    state.companyItem = item;
  },
  setReady: (state, item) => {
    state.ready = item;
  },
  setRefreshData: (state, item) => {
    state.refreshData = item;
  },

  [constants.GET_VALIDATIONS]: (state) => {
    state.user.status = 'fetching';
    state.user.error = null;
  },

  [constants.GET_VALIDATIONS_SUCCESS]: (state, data) => {
    state.user.status = 'success';
    state.user.error = null;
    state.user.data = data;
  },
  [constants.GET_VALIDATIONS_ERROR]: (state, error) => {
    state.user.status = 'error';
    state.user.error = error;
  },

  [constants.UPDATE_VALIDATIONS]: (state) => {
    state.validations.status = 'fetching';
    state.validations.error = null;
  },

  [constants.UPDATE_VALIDATIONS_SUCCESS]: (state, data) => {
    state.validations.status = 'success';
    state.validations.error = null;
    state.validations.data = data;
  },
  [constants.UPDATE_VALIDATIONS_ERROR]: (state, error) => {
    state.validations.status = 'error';
    state.validations.error = error;
  },
  [constants.PUT_COMPANY_STATUS]: (state) => {
    state.companyStatus.status = 'fetching';
    state.companyStatus.error = null;
  },
  [constants.PUT_COMPANY_STATUS_SUCCESS]: (state, data) => {
    state.companyStatus.status = 'success';
    state.companyStatus.data = data;
    state.companyStatus.error = null;
  },
  [constants.PUT_COMPANY_STATUS_ERROR]: (state, error) => {
    state.companyStatus.status = 'success';
    state.companyStatus.error = error;
  },
  [constants.SET_COMPANY_EMAIL]: (state, email) => {
    state.sendCompanyRegistrationEmail.email = email;
  },
  [constants.POST_COMPANY_REGISTRATION_EMAIL]: (state) => {
    state.sendCompanyRegistrationEmail.status = 'fetching';
    state.sendCompanyRegistrationEmail.error = null;
  },
  [constants.POST_COMPANY_REGISTRATION_EMAIL_SUCCESS]: (state, data) => {
    state.sendCompanyRegistrationEmail.status = 'success';
    state.sendCompanyRegistrationEmail.data = data;
    state.sendCompanyRegistrationEmail.error = null;
  },
  [constants.POST_COMPANY_REGISTRATION_EMAIL_ERROR]: (state, error) => {
    state.sendCompanyRegistrationEmail.status = 'error';
    state.sendCompanyRegistrationEmail.error = error;
  }
};

export default {
  state: getDefaultState(),
  mutations,
  actions
};
