import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    transportationList: {
      status: null,
      data: [],
      error: null,
      filters: {
        driverId: null,
        date: null,
        status: 1,
        page: 1,
        resourcesPerPage: 50,
      }
    },
    transportation: {
      status: null,
      data: {},
      error: null,
    },

    transportationExcel: {
      status: null,
      data: {},
      error: null,
    },
  };
}
const actions = {
  [constants.GET_TRANSPORTATION_LIST]: async ({ commit, state }) => {
    try {
      commit(constants.GET_TRANSPORTATION_LIST);
      const payload = Object.entries(state.transportationList.filters).reduce(
        (a, [k, v]) => (v === null || v === "" ? a : { ...a, [k]: v }),
        {}
      );
      const response = await axios.get("transfer/getTransportation", {
        params: payload,
      });
      commit(constants.GET_TRANSPORTATION_LIST_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_TRANSPORTATION_LIST_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_TRANSPORTATION]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_TRANSPORTATION);
      const response = await axios.post("transfer/createAndUpdateTransportation", payload);
      commit(constants.POST_TRANSPORTATION_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_TRANSPORTATION_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_TRANSPORTATION]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_TRANSPORTATION);
      const response = await axios.put("transportation/putTransportation", payload);
      commit(constants.PUT_TRANSPORTATION_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_TRANSPORTATION_ERROR, error);
      throw error.response;
    }
  },
  [constants.DELETE_TRANSPORTATION]: async ({ commit }, payload) => {
    try {
      commit(constants.DELETE_TRANSPORTATION);
      const response = await axios.delete("transfer/deleteTransportation", {
        data: payload,
      });
      commit(constants.DELETE_TRANSPORTATION_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.DELETE_TRANSPORTATION_ERROR, error);
      throw error.response;
    }
  },
  [constants.MARK_TRANSPORTATION_AS_PAID]: async ({ commit }, payload) => {
    try {
      commit(constants.MARK_TRANSPORTATION_AS_PAID);
      const response = await axios.put("transfer/updateTransportationStatus", payload);
      commit(constants.MARK_TRANSPORTATION_AS_PAID_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.MARK_TRANSPORTATION_AS_PAID_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_TRANSPORTATION_EXCEL]: async ({ commit, state },) => {
    try {
      commit(constants.GET_TRANSPORTATION_EXCEL);
      const payload = Object.entries(state.transportationList.filters).reduce(
        (a, [k, v]) => (v === null || v === "" ? a : { ...a, [k]: v }),
        {}
      );
      const response = await axios.get("transfer/generateTransportationXls", {
        params: payload,
        responseType: "blob",
      });
      commit(constants.GET_TRANSPORTATION_EXCEL_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_TRANSPORTATION_EXCEL_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_TRANSPORTATION_RETURN]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_TRANSPORTATION_RETURN);
      const response = await axios.post("transfer/registerReturn", payload);
      commit(constants.POST_TRANSPORTATION_RETURN_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_TRANSPORTATION_RETURN_ERROR, error);
      throw error.response;
    }
  },
}
const mutations = {
  [constants.GET_TRANSPORTATION_LIST]: (state) => {
    state.transportationList.data = [];
    state.transportationList.status = "fetching";
  },
  [constants.GET_TRANSPORTATION_LIST_SUCCESS]: (state, response) => {
    state.transportationList.status = "success";
    state.transportationList.data = response;
  },
  [constants.GET_TRANSPORTATION_LIST_ERROR]: (state, error) => {
    state.transportationList.status = "error";
    state.transportationList.error = error;
  },
  [constants.POST_TRANSPORTATION]: (state) => {
    state.transportation.data = {};
    state.transportation.status = "fetching";
  },
  [constants.POST_TRANSPORTATION_SUCCESS]: (state, response) => {
    state.transportation.status = "success";
    state.transportation.data = response;
  },
  [constants.POST_TRANSPORTATION_ERROR]: (state, error) => {
    state.transportation.status = "error";
    state.transportation.error = error;
  },
  [constants.PUT_TRANSPORTATION]: (state) => {
    state.transportation.data = {};
    state.transportation.status = "fetching";
  },
  [constants.PUT_TRANSPORTATION_SUCCESS]: (state, response) => {
    state.transportation.status = "success";
    state.transportation.data = response;
  },
  [constants.PUT_TRANSPORTATION_ERROR]: (state, error) => {
    state.transportation.status = "error";
    state.transportation.error = error;
  },
  [constants.DELETE_TRANSPORTATION]: (state) => {
    state.transportation.data = {};
    state.transportation.status = "fetching";
  },
  [constants.DELETE_TRANSPORTATION_SUCCESS]: (state, response) => {
    state.transportation.status = "success";
    state.transportation.data = response;
  },
  [constants.DELETE_TRANSPORTATION_ERROR]: (state, error) => {
    state.transportation.status = "error";
    state.transportation.error = error;
  },
  [constants.MARK_TRANSPORTATION_AS_PAID]: (state) => {
    state.transportation.data = {};
    state.transportation.status = "fetching";
  },
  [constants.MARK_TRANSPORTATION_AS_PAID_SUCCESS]: (state, response) => {
    state.transportation.status = "success";
    state.transportation.data = response;
  },
  [constants.MARK_TRANSPORTATION_AS_PAID_ERROR]: (state, error) => {
    state.transportation.status = "error";
    state.transportation.error = error;
  },
  [constants.SET_TRANSPORTATION_FILTER]: (state, filters) => {
    if (filters.reset) {
      state.transportationList.filters = {
        page: 1,
        search: null,
      };
    }
    if (filters.fields) {
      state.transportationList.filters = {
        ...state.transportationList.filters,
        ...filters.fields,
      };
    }
  },
  [constants.GET_TRANSPORTATION_EXCEL]: (state) => {
    state.transportationExcel.data = {};
    state.transportationExcel.status = "fetching";
  },
  [constants.GET_TRANSPORTATION_EXCEL_SUCCESS]: (state, response) => {
    state.transportationExcel.status = "success";
    state.transportationExcel.data = response;
  },
  [constants.GET_TRANSPORTATION_EXCEL_ERROR]: (state, error) => {
    state.transportationExcel.status = "error";
    state.transportationExcel.error = error;
  },
  [constants.POST_TRANSPORTATION_RETURN]: (state) => {
    state.transportation.data = {};
    state.transportation.status = "fetching";
  },
  [constants.POST_TRANSPORTATION_RETURN_SUCCESS]: (state, response) => {
    state.transportation.status = "success";
    state.transportation.data = response;
  },
  [constants.POST_TRANSPORTATION_RETURN_ERROR]: (state, error) => {
    state.transportation.status = "error";
    state.transportation.error = error;
  },

}

export default {
  state: getDefaultState(),
  actions,
  mutations,
};