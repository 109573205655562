export const GET_TASKS = 'GET_TASKS';
export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS';
export const GET_TASKS_ERROR = 'GET_TASKS_ERROR';

export const POST_TASK = 'POST_TASK';
export const POST_TASK_SUCCESS = 'POST_TASK_SUCCESS';
export const POST_TASK_ERROR = 'POST_TASK_ERROR';

export const GET_VEHICLES_AND_USERS = 'GET_VEHICLES_AND_USERS';
export const GET_VEHICLES_AND_USERS_SUCCESS = 'GET_VEHICLES_AND_USERS_SUCCESS';
export const GET_VEHICLES_AND_USERS_ERROR = 'GET_VEHICLES_AND_USERS_ERROR';

export const DELETE_TASK = 'DELETE_TASK';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export const DELETE_TASK_ERROR = 'DELETE_TASK_ERROR';

export const SET_TASKS_FILTERS = 'SET_TASKS_FILTERS';

export const MARK_TASK_AS_DONE = 'MARK_TASK_AS_DONE';
export const MARK_TASK_AS_DONE_SUCCESS = 'MARK_TASK_AS_DONE_SUCCESS';
export const MARK_TASK_AS_DONE_ERROR = 'MARK_TASK_AS_DONE_ERROR';

export const POST_TASK_NOTE = 'POST_TASK_NOTE';
export const POST_TASK_NOTE_SUCCESS = 'POST_TASK_NOTE_SUCCESS';
export const POST_TASK_NOTE_ERROR = 'POST_TASK_NOTE_ERROR';

export const GET_TASK_LOGS = 'GET_TASK_LOGS';
export const GET_TASK_LOGS_SUCCESS = 'GET_TASK_LOGS_SUCCESS';
export const GET_TASK_LOGS_ERROR = 'GET_TASK_LOGS_ERROR';

export const GET_TASK_TYPES = 'GET_TASK_TYPES';
export const GET_TASK_TYPES_SUCCESS = 'GET_TASK_TYPES_SUCCESS';
export const GET_TASK_TYPES_ERROR = 'GET_TASK_TYPES_ERROR';

export const PUT_TASK_TYPE_BY_USER = 'PUT_TASK_TYPE_BY_USER';
export const PUT_TASK_TYPE_BY_USER_SUCCESS = 'PUT_TASK_TYPE_BY_USER_SUCCESS';
export const PUT_TASK_TYPE_BY_USER_ERROR = 'PUT_TASK_TYPE_BY_USER_ERROR';

export const GET_TASK_LIST_USERS = 'GET_TASK_LIST_USERS';
export const GET_TASK_LIST_USERS_SUCCESS = 'GET_TASK_LIST_USERS_SUCCESS';
export const GET_TASK_LIST_USERS_ERROR = 'GET_TASK_LIST_USERS_ERROR';