<template>
  <div>
    <b-form-group label-size="sm">
      <template slot="label"> Higher than: </template>
      <b-form-input
        v-model="paymentGreaterThan"
        size="sm"
        maxlength="100"
        onkeydown="javascript: return event.keyCode == 69 ? false : true"
        type="number"
        min="0"
        @change="applyFilter()"
      />
    </b-form-group>
    <b-form-group label-size="sm">
      <template slot="label"> Less than: </template>
      <b-form-input
        v-model="paymentSmallerThan"
        size="sm"
        maxlength="100"
        onkeydown="javascript: return event.keyCode == 69 ? false : true"
        type="number"
        min="0"
        @change="applyFilter()"
      />
    </b-form-group>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SET_VEHICLES_INFO_FILTERS } from "../actions";

export default {
  name: "PaymentFilter",
  data() {
    return {
      paymentSmallerThan: 0,
      paymentGreaterThan: 0,
    };
  },
  created() {
    this.getFilters();
  },
  computed: {
    ...mapState({
      filters: (state) => state.superAdminStore.vehiclesInfo.filters,
    }),
  },
  methods: {
    applyFilter() {
      this.$store.commit(SET_VEHICLES_INFO_FILTERS, {
        fields: {
          paymentSmallerThan:
            this.paymentSmallerThan > 0 ? this.paymentSmallerThan : null,
          paymentGreaterThan:
            this.paymentGreaterThan > 0 ? this.paymentGreaterThan : null,
        },
      });
    },
    getFilters() {
      this.paymentSmallerThan = this.filters.paymentSmallerThan
        ? this.filters.paymentSmallerThan
        : 0;
      this.paymentGreaterThan = this.filters.paymentGreaterThan
        ? this.filters.paymentGreaterThan
        : 0;
    },
  },
};
</script>

<style>
</style>