<template>
  <FilterTypeDate fromType="liensFrom" toType="liensTo" />
</template>

<script>
import FilterTypeDate from "./FilterTypeDate.vue";
export default {
  name: "LienFilter",
  components: { FilterTypeDate },
};
</script>

<style>
</style>