import { render, staticRenderFns } from "./VehiclesList.vue?vue&type=template&id=c24716ec&scoped=true&"
import script from "./VehiclesList.vue?vue&type=script&lang=js&"
export * from "./VehiclesList.vue?vue&type=script&lang=js&"
import style0 from "./VehiclesList.vue?vue&type=style&index=0&id=c24716ec&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c24716ec",
  null
  
)

export default component.exports