<template>
  <b-dropdown
    v-model="status"
    :text="this.status ? this.status : 'All files'"
    variant="white"
    class="m-2 mb-2"
    menu-class="w-100"
  >
    <b-dropdown-item v-for="(s, i) of statusList" :key="i" @click="setStatus(s)">
      {{ s }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { GET_ADDONS_FILE_LIST } from "./actions.js";
export default {
  data() {
    return {
      status: "all files",
      statusList: ["all files", "sales", "addons"],
    };
  },

  mounted() {
    this.status = null;
  },

  methods: {
    setStatus(status) {
      this.status = status;
      this.getVehicles(status);
    },
    getVehicles(type) {
      this.$store
        .dispatch(GET_ADDONS_FILE_LIST, {
          type,
        })
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("Could not get files list", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
  },
};
</script>

<style></style>
