<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-white py-5">
      <b-container>
        <div class="header-body text-center mb-7">
          <b-row class="justify-content-center"> </b-row>
        </div>
      </b-container>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-secondary border-0 mb-0">
            <b-card-header class="bg-transparent pb-5 text-center">
              <img
                src="@/assets/img/logos/QAS.png"
                class="img-fluid logo"
                alt="logo"
              />
            </b-card-header>
            <b-card-body class="px-lg-5 py-lg-5">
              <b-form @submit.stop.prevent="onSubmit" class="container">
                <b-row class="justify-content-center">
                  <b-col xl="12" lg="12" md="12" sm="12" class="mt-1">
                    <b-form-input
                      v-model.trim="$v.form.email.$model"
                      size="lg"
                      placeholder="Email"
                      class="form-input"
                      :formatter="lowerCaseFormatter"
                      :state="validateState('email')"
                    />
                  </b-col>
                </b-row>
                <b-row class="justify-content-center">
                  <b-col xl="12" lg="12" md="12" sm="8" class="mt-2">
                    <b-input-group>
                      <b-input
                        v-model.trim="$v.form.password.$model"
                        :type="showPassword ? 'text' : 'password'"
                        size="lg"
                        placeholder="Password"
                        :value="form.password"
                        :state="validateState('password')"
                      />
                      <b-input-group-append>
                        <b-button
                          v-if="showPassword == false"
                          variant="outline-primary"
                          size="sm"
                          @click="hanldePasswordVisibility(true)"
                        >
                          <b-icon icon="eye" class="mt-1" font-scale="0.99" />
                        </b-button>
                        <b-button
                          v-else
                          variant="outline-primary"
                          size="sm"
                          @click="hanldePasswordVisibility(false)"
                        >
                          <b-icon
                            icon="eye-slash"
                            class="mt-1"
                            font-scale="0.99"
                          />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                </b-row>
                <b-row class="d-flex justify-content-center mt-3">
                  <b-col xl="4" lg="4" md="6" sm="8" class="">
                    <b-button
                      block
                      size="lg"
                      :disabled="$v.form.$invalid || loginStatus === 'fetching'"
                      class="btn btn-success block justify-content-center"
                      style="margin-right: 42%"
                      @click="onSubmit()"
                      type="submit"
                    >
                      <b-spinner
                        v-if="loginStatus === 'fetching'"
                        variant="white"
                        label="Spinning"
                      />
                      <span v-else> Log In </span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
          <b-row class="mt-3">
            <b-col cols="6">
              <router-link to="/auth/forgotPassword" class="text-success"
                ><small>Forgot password?</small></router-link
              >
            </b-col>
            <b-col cols="6" class="text-right"> </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { AUTH_LOGIN } from "./actions";
import appRoles from "../../../helpers/appRoles";
import { mapState } from "vuex";
export default {
  name: "LoginForm",
  data() {
    return {
      modalId: "login-modal",
      form: { email: null, password: null },
      showPassword: false,
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.auth.loginStore.profile.data.user,
      loginStatus: (state) => state.auth.loginStore.profile.status,
    }),
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    close() {
      this.$bvModal.hide(this.modalId);
    },
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    hanldePasswordVisibility(isShow) {
      this.showPassword = isShow;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null,
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.form.corporation = true;
      this.$store
        .dispatch(AUTH_LOGIN, this.form)
        .then(async (response) => {
          if (response.user.favorite) {
            this.$cookies.set("favoriteBoard", response.user.favorite);
          }
          this.$root.$bvToast.toast("Welcome to QAS", {
            title: "Login success",
          });
          this.resetForm();
          this.handleRedirect(response.user.roles[0].name);
          this.$bvModal.hide(this.modalId);
        })
        .catch((error) => {
          let message = "Warning";
          if (error.status === 400) {
            switch (error.data.message) {
              case "Authentication failed":
                message = "Invalid login credentials.";
                break;
              case "Company not active yet":
                message = "Company not active yet.";
                break;
              case "Company has been rejected":
                message =
                  "Sorry, your company has been rejected. You don’t have access to this platform.";
                break;
              case "User Inactive":
                message = "User Inactive.";
                break;
            }
          }
          this.$root.$bvToast.toast(message, {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    handleRedirect(authority, favoriteBoard = null) {
      if (favoriteBoard) {
        this.$router.push({ name: favoriteBoard });
        return;
      }
      if (this.profile.corporationPermissions.length > 1) {
        this.$router.push({ name: "auth.corporation" });
      } else {
        localStorage.setItem(
          "corporation",
          this.profile?.corporationPermissions[0]?.id
        );
        this.$store.commit(
          "SET_CORPORATION",
          this.profile?.corporationPermissions[0]
        );
        const userRoles = this.profile.roles.find((r) => r.name === authority);
        const roleAuthority = appRoles.find(
          (role) => role.authority === userRoles.name
        );
        this.$router.push({ name: roleAuthority.mainRoute });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-header {
  border-bottom: 0 none;
}

.logo {
  margin-bottom: -5%;
}
.user-icon {
  margin-top: -10%;
}
.forgot-password {
  margin-left: 41%;
}
@media screen and (max-width: 800px) {
  .user-icon {
    margin-left: 50%;
  }
  .btn-main {
    margin-right: 20%;
  }
  .forgot-password {
    margin-left: 30%;
  }
}

.border-r-purple {
  border-right: none;
}
@media (min-width: 576px) {
  .border-r-purple {
    border-right: 2px solid #1c133e;
  }
}

.mr-4-5 {
  margin-right: 4.5rem !important;
}
</style>
