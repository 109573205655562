<template>
  <div class="container">
    <b-form class="m-4" @submit.stop.prevent="form.id ? update() : save()">
      <h3 class="text-purple-yellow">
        <b-icon icon="person-plus-fill" class="h4 m-1 align-bottom" />
      </h3>

      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
          <!-- NAme -->
          <b-form-group label-size="sm">
            <template slot="label">
              Category name <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="firstName-input"
              v-model.trim="$v.form.name.$model"
              size="md"
              name="firstName-input"
              :value="form.name"
              :state="validateState('name')"
              maxlength="100"
              @change="setValue('name', $event)"
            />
            <b-form-invalid-feedback>
              This is a required field and must at least 2 characters.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <!-- Top amount -->
          <b-form-group label-size="sm">
            <template slot="label">
              Top amount <span class="text-danger">*</span>
            </template>
            <Money
              v-model.trim="$v.form.topAmount.$model"
              size="sm"
              class="form-control"
              :state="validateState('topAmount')"
              v-bind="money"
              @change="setValue('topAmount', $event)"
            />

            <b-form-invalid-feedback>
              This is a required field and must at least 2 characters.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row />
      <b-row />

      <b-row>
        <b-col sm="12" md="6" lg="6" xl="6" />
        <b-col sm="12" md="3" lg="3" xl="3" />
        <b-col sm="12" md="3" lg="3" xl="3">
          <b-btn
            v-if="userHasPermission('categories', 'write')"
            block
            size="lg"
            class="btn btn-success"
            :disabled="$v.form.$invalid || isLoading"
            type="submit"
          >
            Submit
            <b-spinner v-if="isLoading" small variant="white" />
          </b-btn>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { required, minLength, numeric } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import { generalMixin } from "@/modules/mixin.js";
import { POST_CATEGORY } from "./actions";
import { Money } from "v-money";
import { superAdminMixin } from "../../../../superAdmin/mixins";

export default {
  components: { Money },
  mixins: [generalMixin, superAdminMixin],
  props: ["categoryItem", "refreshData", "hideModal"],
  computed: {
    ...mapState({
      category: (state) => state.vehicle.workOrder.categories.category,
    }),
    isLoading() {
      return this.category.status == "fetching" ? true : false;
    },
  },

  data() {
    return {
      status: false,
      form: {
        id: null,
        name: null,
        lastName: null,
      },
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "CAD$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(2),
      },
      topAmount: {
        required,
        numeric,
      },
    },
  },
  mounted() {
    if (this.categoryItem.id) {
      this.formatForm();
    }
  },
  methods: {
    closeModal(callback) {
      callback();
    },
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    save() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_CATEGORY, payload)
        .then(() => {
          this.makeToast("Succes", "Category created succesfully", "success");
          this.refreshData();
          this.hideModal();
        })
        .catch(() => {
          this.$root.$bvToast.toast(this.user.error.response.data.message, {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    update() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_CATEGORY, payload)
        .then(() => {
          this.makeToast("Succes", "Category updated succesfully", "success");
          this.refreshData();
          this.hideModal();
        })
        .catch(() => {
          this.$root.$bvToast.toast(this.category.error.response.data.message, {
            title: "Warning",
            variant: "warning",
          });
        });
    },

    goTo() {
      if (this.$route.path !== "/") this.$router.push({ path: "/" });
    },
    formatPayload() {
      const payload = {
        id: this.categoryItem.id ? this.categoryItem.id : null,

        name: this.form.name,
        topAmount: this.form.topAmount,
      };

      return payload;
    },

    formatForm() {
      this.form.id = this.categoryItem.id;
      this.form.name = this.categoryItem.name;
      this.form.topAmount = this.categoryItem.topAmount;
    },
  },
};
</script>

<style scoped>
.append-input {
  max-width: 40%;
}
</style>
