<template>
  <b-form @submit.stop.prevent="save()">
    <b-row>
      <b-col sm="12" md="6" lg="6" xl="6">
        <b-form-group>
          <template slot="label"> Pad </template>
          <Money
            class="form-control text-center"
            v-bind="money"
            v-model="form.pad"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6" lg="6" xl="6">
        <b-form-group>
          <template slot="label"> Agent </template>
          <AgentSelect
            :agentSelectedId="form.agent ? form.agent.id : null"
            @input="onAgentChange($event)"
          />
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6" lg="6" xl="6">
        <b-form-group>
          <template slot="label"> Company </template>
          <CompanySelect
            :companySelectedId="form.company ? form.company.id : null"
            @updateCompany="onCompanyChange($event)"
          />
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6" lg="6" xl="6">
        <b-form-group>
          <template slot="label"> Marketplace </template>

          <MarketplaceSelect
            :options="marketplaces"
            @onMarketplaceSelect="setMarketplace($event)"
            :modelValue="form.marketPlace"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" class="d-flex justify-content-end">
        <b-button
          type="submit"
          :disabled="padCommissionStatus === 'fetching'"
          variant="success"
        >
          <!-- loader -->
          <b-spinner
            v-if="padCommissionStatus === 'fetching'"
            variant="white"
            small
          />
          <span v-else>
            <slot name="button-text">Submit</slot>
          </span>
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { Money } from "v-money";
import { POST_PAD_COMMISSION } from "../actions";
import { mapState } from "vuex";
import AgentSelect from "../AgentSelect.vue";
import CompanySelect from "../CompanySelect.vue";
import MarketplaceSelect from "../../../vehicle/components/marketplace/MarketplaceSalect.vue";

export default {
  name: "PadCommissionForm",
  components: { Money, AgentSelect, CompanySelect, MarketplaceSelect },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    refresh: {
      type: Function,
      default: () => {},
    },
    hideModal: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      padCommission: (state) =>
        state.superAdminStore.interest.padCommission.data,
      padCommissionStatus: (state) =>
        state.superAdminStore.interest.padCommission.status,
      marketplaces: (state) =>
        state.masterUser.marketplace.marketplaceList.data || [],
    }),
  },

  data() {
    return {
      form: {
        id: null,
        agent: null,
        pad: 0,
        company: null,
        marketPlace: null,
      },
      money: {
        value: 0,
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        suffix: " CAD",
        precision: 2,
      },
    };
  },
  mounted() {
    if (this.item?.id) {
      this.formatForm();
    }
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onAgentChange(agent) {
      this.form.agent = agent;
    },
    save() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_PAD_COMMISSION, payload)
        .then(() => {
          this.refresh();
          this.hideModal();
          this.$bvToast.toast("Pad commission information saved", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.$bvModal.hide("modal-1");
        })
        .catch((error) => {
          this.$bvToast.toast(error.response.data.message, {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        });
    },

    formatPayload() {
      return {
        id: this.form.id,
        usersId: this.form?.agent?.id,
        commission: this.form?.pad,
        companyId: this.form?.company ? this.form?.company?.id : null,
        marketPlaceId: this.form?.marketPlace.id,
        pad: this.form?.pad,
      };
    },
    getMarketPlaces() {
      this.$store.dispatch("GET_MARKETPLACE_LIST").then(() => {});
    },
    setMarketplace(marketplace) {
      this.form.marketPlace = null;
      this.form.marketPlace = marketplace;
    },
    onCompanyChange(company) {
      this.form.company = company;
    },
    formatForm() {
      this.form.id = this.item.id;
      this.form.agent = this.item.user;
      this.form.pad = this.item.pad;
      this.form.company = this.item.company;
      this.form.marketPlace = this.item.marketPlace;
    },
  },
};
</script>

