<template>
  <div>
    <b-form
      class="mb-4 mx-4"
      @submit.stop.prevent="form.id ? update() : save()"
      v-if="typeCarfaxRegistration == 'manualyUpload'"
    >
      <b-row>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group label-size="sm">
            <template slot="label">
              File <span class="text-danger">*</span>
            </template>
            <b-form-file v-model="form.file" accept=".pdf" />
          </b-form-group>
        </b-col>

        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group label-size="sm">
            <template>
              Expiry date <span class="text-danger">*</span>
            </template>
            <input
              v-model="$v.form.expiredDate.$model"
              type="datetime-local"
              class="form-control"
            />
          </b-form-group>
        </b-col>

        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group label-size="sm">
            <template> Type<span class="text-danger">*</span> </template>
            <b-form-select
              v-model="$v.form.type.$model"
              :value="form.type"
              @change="setValue('type', $event)"
              :options="types"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <!-- country -->
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group label-size="sm">
            <template> Country <span class="text-danger">*</span> </template>
            <b-form-select
              v-model="$v.form.country.$model"
              :value="form.country"
              @change="setValue('country', $event)"
              :options="countries"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row />
      <b-row />
      <b-row>
        <b-col sm="12" md="6" lg="6" xl="6" />
        <b-col sm="12" md="3" lg="3" xl="3" />
        <b-col sm="12" md="3" lg="3" xl="3">
          <b-btn
            :disabled="carfax.status == 'fetching' || $v.form.$invalid"
            block
            size="lg"
            class="btn btn-success"
            type="submit"
          >
            Submit
            <b-spinner
              v-if="carfax.status == 'fetching'"
              small
              variant="white"
            />
          </b-btn>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { POST_EXTERNAL_CARFAX } from "./actions";
import { required, requiredIf } from "vuelidate/lib/validators";

import { mapState } from "vuex";
import { generalMixin } from "@/modules/mixin.js";
export default {
  props: ["refreshData"],
  mixins: [generalMixin],
  computed: {
    ...mapState({
      vehicle: (state) => state.vehicle.vehicleByVin.data,
      carfax: (state) => state.vehicle.carfax.carfaxs,
    }),
  },
  data() {
    return {
      form: {
        file: [],
        type: null,
        expiredDate: null,
        country: null,
      },
      typeCarfaxRegistration: "manualyUpload",
      types: [
        {
          value: 1,
          text: "RDPRM",
        },
        { value: 2, text: "Verified" },
        { value: 4, text: "Claims" },
        { value: 3, text: "Carproof" },
      ],
      countries: [
        {
          value: "Canada",
          text: "Canada",
        },
        { value: "United States", text: "United States" },
      ],
    };
  },
  validations: {
    form: {
      file: {
        required: requiredIf(function () {
          return this.form.file.length == 0; // New changes
        }),
      },
      expiredDate: {
        required,
      },
      type: {
        required,
      },
      country: {
        required,
      },
    },
  },
  mounted() {
    this.setDefaultExpiryDate();
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    save() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_EXTERNAL_CARFAX, payload)
        .then(() => {
          this.refreshData();
          this.makeToast(
            "Success",
            "Carfax registered successfully",
            "success"
          );
          this.form = {
            file: [],
            type: null,
            expiredDate: null,
          };

          this.hideModal();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setDefaultExpiryDate() {
      var myCurrentDate = new Date();
      var myFutureDate = new Date(myCurrentDate);
      myFutureDate.setDate(myFutureDate.getDate() + 90); //myFutureDate is now 90 days in the future
      console.log(myFutureDate);
      this.form.expiredDate =
        this.$options.filters.formatDateTimeInput(myFutureDate);
    },
    hideModal() {
      this.$bvModal.hide("carfax-modal");
    },
    update() {},
    formatPayload() {
      const data = new FormData();
      data.set("expiryDate", this.form.expiredDate);
      data.set("reportType", this.form.type);
      data.set("vehiclesId", this.vehicle.data.id);
      data.set("file", this.form.file);
      data.set("country", this.form.country);

      return data;
    },
  },
};
</script>

<style></style>
