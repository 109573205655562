import * as constants from './actions';
import axios from '@/plugins/axios.js';

function getDefaultState() {
  return {
    providers: {
      status: null,
      data: [],
      error: null
    },
    provider: {
      status: null,
      data: {},
      error: null
    }
  };
}

const actions = {
  [constants.GET_PROVIDERS]: async ({ commit }, params) => {
    try {
      commit(constants.GET_PROVIDERS);
      /* 
            const response = await axios.get('serviceProvider/getServiceProvider', {
              params: { ...params }
            }); */
      const response = await axios.get('company/getCompanies', {
        params: { ...params }
      });
      commit(constants.GET_PROVIDERS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_PROVIDERS_ERROR, error);
      throw error.response;
    }
  },

  [constants.POST_PROVIDER]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_PROVIDER);
      const response = await axios.post(
        'suppliers/createAndUpdate',
        payload
      );
      commit(constants.POST_PROVIDER_SUCCESS);
      return response;
    } catch (error) {
      commit(constants.POST_PROVIDER_ERROR, error);
      throw error.response;
    }
  }
};

const mutations = {
  [constants.GET_PROVIDERS]: (state) => {
    state.providers.status = 'fetching';
    state.providers.error = null;
  },
  [constants.GET_PROVIDERS_SUCCESS]: (state, data) => {
    state.providers.status = 'success';
    state.providers.error = null;
    state.providers.data = data;
  },
  [constants.GET_PROVIDERS_ERROR]: (state, error) => {
    state.providers.status = 'error';
    state.providers.error = error;
  },

  [constants.POST_PROVIDER]: (state) => {
    state.provider.status = 'fetching';
    state.provider.error = null;
  },
  [constants.POST_PROVIDER_SUCCESS]: (state, data) => {
    state.provider.status = 'success';
    state.provider.error = null;
    state.provider.data = data;
  },
  [constants.POST_PROVIDER_ERROR]: (state, error) => {
    state.provider.status = 'error';
    state.provider.error = error;
  }
};

export default {
  state: getDefaultState(),
  actions,
  mutations
};
