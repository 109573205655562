<template>
  <div>
    <b-row>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <h2>
          Stock number: <i>{{ vehicle.stockNumber || "----" }} </i>
        </h2>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <h2>
          Vin number: <i>{{ vehicle.vinNumber.vinNumber || "----" }} </i>
        </h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col xs="12" sm="12" md="4" lg="4" xl="4">
        <h3>
          Make: <i>{{ vehicle.vinNumber.make || "----" }}</i>
        </h3>
      </b-col>
      <b-col xs="12" sm="12" md="4" lg="4" xl="4">
        <h3>
          Model: <i>{{ vehicle.vinNumber.model || "----" }}</i>
        </h3>
      </b-col>
      <b-col xs="12" sm="12" md="4" lg="4" xl="4">
        <h3>
          Year: <i>{{ vehicle.vinNumber.year || "----" }}</i>
        </h3>
      </b-col>
      <b-col xs="12" sm="12" md="4" lg="4" xl="4">
        <h3>
          Trim: <i>{{ vehicle.vinNumber.trim || "----" }}</i>
        </h3>
      </b-col>
      <b-col xs="12" sm="12" md="4" lg="4" xl="4">
        <h3>
          Color: <i>{{ color ? color : "----" }}</i>
        </h3>
      </b-col>
      <b-col xs="12" sm="12" md="4" lg="4" xl="4">
        <h3 v-if="vehicle.marketPlace && vehicle.marketPlace.marketPlace">
          Marketplace:
          <i>{{ vehicle.marketPlace.marketPlace.name || "----" }}</i>
        </h3>
        <h3 v-else>
          Marketplace:
          <i>{{ vehicle.marketPlace ? vehicle.marketPlace : "----" }}</i>
        </h3>
      </b-col>
      <b-col
        v-if="vehicle.finalDestination"
        xs="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
      >
        <h3>
          Final destination:
          <i>{{ vehicle.finalDestination.companyName || "----" }}</i>
        </h3>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: ["vehicle", "color"],
};
</script>

<style>
</style>