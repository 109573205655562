<template>
  <div>
    <b-row>
      <b-col lg="6" md="6" sm="12">
        <card>
          <ExpensesAddonsList
            v-if="expensesInfo.status != 'fetching'"
            :addons="expensesInfo.data.addons"
          />
        </card>
      </b-col>
      <b-col lg="6" md="6" sm="12">
        <card>
          <ExpensesSummary
            v-if="expensesInfo.status != 'fetching'"
            :expensesInformation="expensesInfo.data"
          />
          <Pie
            v-if="chartData.labels.length > 0"
            :chart-options="chartOptions"
            :chart-data="chartData"
            class="w-50 mx-auto"
          />
        </card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapState } from "vuex";

import ExpensesAddonsList from "@/modules/superAdmin/components/expenses/ExpensesAddonsList.vue";
import ExpensesSummary from "@/modules/superAdmin/components/expenses/ExpensesSummary.vue";
import { Pie } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";
import { vehicleMixin } from "../../../modules/vehicle/mixin";
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  components: { ExpensesAddonsList, ExpensesSummary, Pie },
  mixins: [vehicleMixin],
  computed: {
    ...mapState({
      expensesInfo: (state) =>
        state.superAdminStore.componentStore.expenses.expensesInfo,
    }),
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.getExpensesInformation();
  },
  methods: {
    getExpensesInformation() {
      this.$store
        .dispatch("GET_EXPENSES", { vehicleId: this.vehicle.id })
        .then(() => {
          this.$store.commit("setNoNameAddons");
          this.formatDataForChart();
        });
    },
    getRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    formatDataForChart() {
      let data = this.expensesInfo.data.addons;
      let labels = [];
      let values = [];
      let colors = [];
      /* unique labels */

      labels = [...new Set(data.map((item) => item.addonType.name))];
      data.labels = labels;
      /* values uniques by label */
      values = labels.map((label) => {
        return data
          .filter((item) => item.addonType.name == label)
          .reduce((acc, item) => acc + item.totalAmountCAD, 0);
      });
      data.values = values;
      /* colors by label */
      colors = labels.map(() => {
        return this.getRandomColor();
      });
      data.colors = colors;

      this.chartData.labels = labels;
      this.chartData.datasets = [
        {
          data: values,
          backgroundColor: colors,
        },
      ];
    },
  },
};
</script>
<style></style>
