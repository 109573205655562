export const POST_WORK_ORDER_NOTE = "POST_WORK_ORDER_NOTE";
export const POST_WORK_ORDER_NOTE_SUCCESS = "POST_WORK_ORDER_NOTE_SUCCESS";
export const POST_WORK_ORDER_NOTE_ERROR = "POST_WORK_ORDER_NOTE_ERROR";

export const DELETE_WORK_ORDER_NOTE = "DELETE_WORK_ORDER_NOTE";
export const DELETE_WORK_ORDER_NOTE_SUCCESS = "DELETE_WORK_ORDER_NOTE_SUCCESS";
export const DELETE_WORK_ORDER_NOTE_ERROR = "DELETE_WORK_ORDER_NOTE_ERROR";

export const GET_WORK_ORDER_NOTES = "GET_WORK_ORDER_NOTES";
export const GET_WORK_ORDER_NOTES_SUCCESS = "GET_WORK_ORDER_NOTES_SUCCESS";
export const GET_WORK_ORDER_NOTES_ERROR = "GET_WORK_ORDER_NOTES_ERROR";
