export const GET_ADDONS_FILE_PROCESSED = 'GET_ADDONS_FILE_PROCESSED';
export const GET_ADDONS_FILE_PROCESSED_SUCCESS = 'GET_ADDONS_FILE_PROCESSED_SUCCESS';
export const GET_ADDONS_FILE_PROCESSED_ERROR = 'GET_ADDONS_FILE_PROCESSED_ERROR';

export const POST_ADDON_FILE = 'POST_ADDON_FILE';
export const POST_ADDON_FILE_SUCCESS = 'POST_ADDON_FILE_SUCCESS';
export const POST_ADDON_FILE_ERROR = 'POST_ADDON_FILE_ERROR';

export const GET_ADDONS_FILE_FAILED = 'GET_ADDONS_FILE_FAILED';
export const GET_ADDONS_FILE_FAILED_SUCCESS = 'GET_ADDONS_FILE_FAILED_SUCCESS';
export const GET_ADDONS_FILE_FAILED_ERROR = 'GET_ADDONS_FILE_FAILED_ERROR';

export const GET_ADDONS_FILE_LIST = 'GET_ADDONS_FILE_LIST';
export const GET_ADDONS_FILE_LIST_SUCCESS = 'GET_ADDONS_FILE_LIST_SUCCESS';
export const GET_ADDONS_FILE_LIST_ERROR = 'GET_ADDONS_FILE_LIST_ERROR';

export const GET_ADDONS_FILE_READ_ERROR = 'GET_ADDONS_FILE_READ_ERROR';
export const GET_ADDONS_FILE_READ_ERROR_SUCCESS = 'GET_ADDONS_FILE_READ_ERROR_SUCCESS';
export const GET_ADDONS_FILE_READ_ERROR_ERROR = 'GET_ADDONS_FILE_READ_ERROR_ERROR';

export const GET_STOCK_NUMBERS = 'GET_STOCK_NUMBERS';
export const GET_STOCK_NUMBERS_SUCCESS = 'GET_STOCK_NUMBERS_SUCCESS';
export const GET_STOCK_NUMBERS_ERROR = 'GET_STOCK_NUMBERS_ERROR';

export const PUT_ADDON_FAIL = 'PUT_ADDON_FAIL';
export const PUT_ADDON_FAIL_SUCCESS = 'PUT_ADDON_FAIL_SUCCESS';
export const PUT_ADDON_FAIL_ERROR = 'PUT_ADDON_FAIL_ERROR';

export const DELETE_ADDON_FAIL = 'DELETE_ADDON_FAIL';
export const DELETE_ADDON_FAIL_SUCCESS = 'DELETE_ADDON_FAIL_SUCCESS';
export const DELETE_ADDON_FAIL_ERROR = 'DELETE_ADDON_FAIL_ERROR';

export const DELETE_ADDON_FILE = 'DELETE_ADDON_FILE';
export const DELETE_ADDON_FILE_SUCCESS = 'DELETE_ADDON_FILE_SUCCESS';
export const DELETE_ADDON_FILE_ERROR = 'DELETE_ADDON_FILE_ERROR';