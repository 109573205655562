<template>
  <div class="mt-3">
    <div class="container-fluid mt-3">
      <div class="d-flex justify-content-end">
        <div class="">
          <b-button
            v-if="status != '3'"
            :disabled="
              transportationSelected.length == 0 ||
              transportation.status == 'fetching'
            "
            variant="success"
            class="mb-2"
            @click="markAsPaid()"
          >
            <span v-if="status == '1'">Mark as validate</span>
            <span v-else>Mark as paid</span>
          </b-button>

          <b-button
            :disabled="transportationExcel.status == 'fetching'"
            variant="yellow"
            class="mb-2 cursor-pointer"
            @click="downloadExcel()"
          >
            Download excel
            <b-icon icon="file-spreadsheet" />
          </b-button>
        </div>
      </div>
      <b-form-checkbox class="mr-sm-2 mb-sm-0" @change="onSelectAll()">
        <span style="font-size: 16px" class="mt-1">
          Select all
        </span></b-form-checkbox
      >
      <span v-if="totalTimeSelected != 0"
        >Total time selected : {{ totalTimeSelected }}</span
      >
      <span v-if="totalValueSelected">
        Total value selected: {{ totalValueSelected | currency }}</span
      >
      <b-tabs content-class="">
        <b-tab
          title="Not validated"
          active
          @click="onChangeStatus('1')"
        ></b-tab>
        <b-tab title="Not paid" @click="onChangeStatus('2')"></b-tab>
        <b-tab title="Paid" @click="onChangeStatus('3')"></b-tab>
      </b-tabs>
      <div class="table-responsive">
        <b-table
          show-empty
          bordered
          small
          stacked="md"
          head-variant="light"
          :items="transportationList.data || []"
          :fields="fields"
          :busy="transportationListStatus == 'fetching'"
        >
          <template #head()="scope">
            <div class="table-head-menu">
              <span class=""> {{ scope.label }} </span>
              <br />
              <div class="text-center">
                <TransportationFilter
                  ref="filter-component"
                  :type="scope.field.filterType"
                  v-if="scope.field.filterType"
                  @applyFilters="applyFilters"
                />
                <div v-else>
                  <br />
                  <span class=""></span>
                </div>
              </div>
            </div>
          </template>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" variant="yellow" />
            </div>
          </template>
          <template #cell(select)="{ item }">
            <b-form-checkbox
              :disabled="item.status == 3"
              @change="onSelectTransportation(item)"
              :checked="isSelected(item)"
            ></b-form-checkbox>
          </template>
          <template #cell(origin)="{ item }">
            {{ item.originLocation.name }}
          </template>
          <template #cell(destination)="{ item }">
            {{ item.destinationLocation.name }}
          </template>
          <template #cell(driver)="{ item }">
            <template v-if="item.drivers.length > 0">
              <span v-for="(driver, key) in item.drivers" :key="key">
                {{ driver.firstName + " " + driver.lastName }}
                <br />
              </span>
            </template>
            <template v-else>
              <span v-for="(company, key) in item.companies" :key="key">
                {{ company.companyName }}
                <br />
              </span>
            </template>
          </template>
          <template #cell(distance)="row">
            {{ row.item.distance }}
          </template>
          <template #cell(waitTime)="row">
            {{ row.item.waitTime }} min
          </template>
          <template #cell(cost)="{ item }">
            {{ item.cost | currency }}
          </template>
          <template #cell(waitingCost)="row">
            {{ row.item.waitingCost }}
          </template>

          <template #cell(actions)="{ item }">
            <div class="d-flex justify-content-around">
              <b-icon
                icon="pencil"
                @click="editItem(item)"
                class="cursor-pointer"
              />

              <b-icon
                icon="arrow-left-right"
                @click="generateReturnTransportation(item.id)"
                class="cursor-pointer"
                v-b-tooltip.hover
                title="Generate return transportation"
              />
              <!-- delete  -->
              <b-icon
                icon="trash"
                @click="deleteItem(item)"
                class="cursor-pointer"
              />
            </div>

            <!-- return icon -->
          </template>
        </b-table>
      </div>

      <b-pagination
        v-if="
          transportationListStatus != 'fetching' &&
          transportationList &&
          transportationList.data
        "
        :value="transportationList ? transportationList.currentPage : 1"
        :total-rows="transportationList ? transportationList.total : 0"
        :per-page="transportationList ? transportationList.perPage : 25"
        class="mb-2"
        align="end"
        @change="changePage($event)"
      />
    </div>

    <b-modal
      id="transportation-form-modal"
      ref="transportation-form-modal"
      size="lg"
      hide-footer
      body-class=""
      ok-disabled
      @hidden="transportationItem = {}"
      title="Transportation"
    >
      <TransportationForm
        :transportationItem="transportationItem"
        :refreshData="getData"
        :hideModal="hideTransportationFormModal"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  GET_TRANSPORTATION_LIST,
  MARK_TRANSPORTATION_AS_PAID,
  SET_TRANSPORTATION_FILTER,
  GET_TRANSPORTATION_EXCEL,
  POST_TRANSPORTATION_RETURN,
} from "./actions.js";
import TransportationForm from "./TransportationForm.vue";
import TransportationFilter from "./filters/TransportationFilter.vue";
export default {
  name: "TransportationTable",
  components: {
    TransportationForm,
    TransportationFilter,
  },
  computed: {
    ...mapState({
      transportationList: (state) =>
        state.superAdminStore.transportation.transportationList.data,
      transportationListStatus: (state) =>
        state.superAdminStore.transportation.transportationList.status,
      transportation: (state) => state.superAdminStore.transportation,
      transportationExcel: (state) =>
        state.superAdminStore.transportation.transportationExcel,
    }),
    totalTimeSelected() {
      if (this.transportationSelected.length == 0) {
        return 0;
      }
      /* return time in hours and min from .minutes */
      const totalSeconds = this.transportationSelected.reduce(
        (total, item) => total + item.minutes,
        0
      );
      const totalWaitingTimeMinutes = this.transportationSelected.reduce(
        (total, item) => total + item.waitTime,
        0
      );

      const totalSecondsWithWaitingTime =
        totalSeconds + totalWaitingTimeMinutes * 60;
      const hours = Math.floor(totalSecondsWithWaitingTime / 3600);
      const minutes = Math.floor((totalSecondsWithWaitingTime % 3600) / 60);
      return `${hours}h ${minutes}min`;
    },
    totalValueSelected() {
      if (this.transportationSelected.length == 0) {
        return 0;
      }
      return this.transportationSelected.reduce(
        (total, item) => total + item.total,
        0
      );
    },
  },
  data() {
    return {
      fields: [
        {
          key: "select",
          label: "Select",
          class: "text-center",
        },
        {
          key: "driver",
          label: "Driver",
          filterType: "driverId",
        },
        {
          key: "startDate",
          label: "Start Date",
          formatter: (date) => this.$options.filters.formatDateTime(date),
          filterType: "date",
        },
        {
          key: "endDate",
          label: "End Date",
          formatter: (date) => this.$options.filters.formatDateTime(date),
        },

        {
          key: "origin",
          label: "Origin",
        },
        {
          key: "destination",
          label: "Destination",
        },
        {
          key: "time",
          label: "Time",
        },
        {
          key: "distance",
          label: "Distance",
        },
        {
          key: "waitTime",
          label: "Waiting time",
        },
        {
          key: "cost",
          label: "Cost",
        },
        {
          key: "cosWaitTime",
          label: "Waiting cost",
          formatter: (value) => this.$options.filters.currency(value),
        },
        {
          key: "total",
          label: "Total",
          formatter: (value) => this.$options.filters.currency(value),
        },
        {
          key: "actions",
          label: "Actions",
          class: "text-center",
        },
      ],
      transportationItem: {},
      transportationSelected: [],
      status: "1",
      isSelectAll: false,
    };
  },
  mounted() {
    this.$store.commit("setAddFunction", this.openTransportationFormModal);
    this.$store.commit("setSearchFunction", this.getData);
    this.getDrivers();
    this.getData();
  },
  methods: {
    getData() {
      this.transportationSelected = [];
      this.$store
        .dispatch(GET_TRANSPORTATION_LIST)
        .then(() => {})
        .catch(() => {});
    },
    openTransportationFormModal() {
      this.$refs["transportation-form-modal"].show();
    },
    hideTransportationFormModal() {
      this.$refs["transportation-form-modal"].hide();
    },
    changePage(page) {
      this.applyFilters({ page: page });
      this.applyFilters({ perPage: 2 });

      this.getData(this.search);
    },
    editItem(item) {
      this.transportationItem = item;
      this.openTransportationFormModal();
    },
    onSelectTransportation(transportation) {
      if (this.isSelected(transportation)) {
        this.transportationSelected = this.transportationSelected.filter(
          (item) => item.id != transportation.id
        );
      } else {
        this.transportationSelected.push(transportation);
      }
    },
    isSelected(transportation) {
      return this.transportationSelected.some(
        (item) => item.id == transportation.id
      );
    },
    markAsPaid() {
      const payload = {
        transportationIds: this.transportationSelected,
        status: this.status == "1" ? 2 : 3,
      };
      this.$store
        .dispatch(MARK_TRANSPORTATION_AS_PAID, payload)
        .then(() => {
          this.$bvToast.toast(
            "Transportation marked as " + this.status == "1"
              ? "Validated"
              : "Paid",
            {
              title: "Success",
              variant: "success",
              solid: true,
            }
          );
          this.clearSelected();
          this.getData();
        })
        .catch(() => {});
    },
    clearSelected() {
      this.transportationSelected = [];
    },
    onChangeStatus(status) {
      this.applyFilters({ status });
      this.status = status;
      this.getData();
    },
    getDrivers() {
      this.$store.dispatch("GET_DRIVER_LIST_NO_PAGINATE");
    },
    applyFilters(filters) {
      this.$store.commit(SET_TRANSPORTATION_FILTER, {
        reset: false,
        fields: {
          ...filters,
        },
      });
      this.getData();
    },
    downloadExcel() {
      this.$store.dispatch(GET_TRANSPORTATION_EXCEL).then((response) => {
        const blob = new Blob([response], {
          type: "application/vnd.ms-excel",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download =
          this.formatFileName("transportation") + ".xls" || "ficher.xls";
        link.click();
      });
    },
    formatFileName(type) {
      const date = new Date();
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${type}-${day}-${month}-${year}`;
    },
    async generateReturnTransportation(id) {
      const confirm = await this.confirmReturnTransportation();
      if (!confirm) return;

      this.$store.dispatch(POST_TRANSPORTATION_RETURN, { id: id }).then(() => {
        this.$bvToast.toast("Return transportation generated", {
          title: "Success",
          variant: "success",
          solid: true,
        });
        this.getData();
      });
    },
    confirmReturnTransportation() {
      return this.$bvModal.msgBoxConfirm(
        "Please confirm that you want to create a return transportation",
        {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okTitle: "YES",
          okVariant: "success",
          cancelTitle: "NO",
          cancelVariant: "danger",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        }
      );
    },

    deleteItem(id) {
      this.$store.dispatch("DELETE_TRANSPORTATION", id).then(() => {
        this.$bvToast.toast("Transportation deleted", {
          title: "Success",
          variant: "success",
          solid: true,
        });
        this.getData();
      });
    },
    onSelectAll() {
      if (this.isSelectAll) {
        this.transportationSelected = [];
      } else {
        this.transportationSelected = this.transportationList.data;
      }
      this.isSelectAll = !this.isSelectAll;
    },
  },
};
</script>

<style></style>
