<template>
  <b-modal
    id="cluster-modal"
    ref="modal"
    centered
    no-close-on-backdrop
    scrollable
    size="lg"
    title="Cluster"
    @show="getClusters()"
    @hide="getVehiclesInfo()"
    hide-footer
  >
    <VehicleDetails
      v-if="vehicle"
      :vehicle="vehicle"
      :color="vehicle.vehicleInformation.color"
    />
    <b-row>
      <b-col sm="12" md="6" lg="6" xl="6">
        <KilometersEditionField :vehicle="vehicle" />
      </b-col>
    </b-row>
    <ClusterRegistrationForm
      v-if="vehicle"
      :vehicle="vehicle"
      :refreshData="getClusters"
    />
    <div>
      <b-list-group v-if="clusters.status == 'fetching'" class="mt-2">
        <b-list-group-item class="text-center">
          <b-spinner variant="yellow" />
        </b-list-group-item>
      </b-list-group>
      <b-list-group v-else class="mt-2 mb-2 note-list" tag="ul">
        <b-list-group-item
          v-if="clusters.data && !clusters.data.length"
          class="text-center"
        >
          <b>No clusters to show</b>
        </b-list-group-item>

        <b-list-group-item
          v-for="(cluster, i) in clusters.data"
          :key="i"
          class="flex-column align-items-start"
          tag="li"
        >
          <ClusterCard :cluster="cluster" :refreshData="getClusters" />
        </b-list-group-item>
      </b-list-group>
    </div>
  </b-modal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import { superAdminMixin } from "../../mixins";
import { GET_CLUSTERS } from "./actions";
import VehicleDetails from "@/modules/superAdmin/components/VehicleDetails.vue";
import ClusterRegistrationForm from "./ClusterRegistrationForm";
import ClusterCard from "./ClusterCard.vue";
import KilometersEditionField from "../kilometers-edition-field/KilometersEditionField.vue";
export default {
  name: "ClusterModal",
  components: {
    VehicleDetails,
    ClusterRegistrationForm,
    ClusterCard,
    KilometersEditionField,
  },
  mixins: [superAdminMixin],
  computed: {
    ...mapState({
      vehicle: (state) => state.superAdminStore.vehicle,
      clusters: (state) =>
        state.superAdminStore.componentStore.cluster.clusters,
      cluster: (state) => state.superAdminStore.componentStore.cluster.cluster,
    }),
  },
  data() {
    return {};
  },
  validations: {
    form: {
      provider: {
        required,
      },
    },
  },

  methods: {
    getClusters() {
      this.$store
        .dispatch(GET_CLUSTERS, { vehicleId: this.vehicle.id })
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("Could not get the cluster information", {
            title: "Error",
            variant: "warning",
          });
        });
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
