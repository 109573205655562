export const icons = {
  vehicle: "car",
  addon: "money-check-dollar-pen",
  registration: "file-magnifying-glass",
  payment: "money-check-dollar",
  carfax: "carfax",
  lien: "file-slash",
  recall: "car-circle-bolt",
  transfer: "truck-container",
  vi: "camera-viewfinder",
  cluster: "gauge-simple",
  carwash: "car-wash",
  workOrder: "car-wrench",
  sold: "file-invoice-dollar",
  sl: "tag",
  files: "folder",
  reserve: "message-dollar",
  general: "notes",
  mechanical: "gears",
  police_book: "file-magnifying-glass",

}

