import * as constants from './actions';
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    invoice: {
      status: null,
      data: {},
      error: null,
    },
    invoices: {
      status: null,
      data: [],
      error: null,
    },
  };
}

const actions = {
  [constants.GET_INVOICE_LIST]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_INVOICE_LIST);
      const response = await axios.get("task/getInvoiceRequest", {
        params: payload,
      });
      commit(constants.GET_INVOICE_LIST_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_INVOICE_LIST_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_INVOICE]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_INVOICE);
      const response = await axios.post("task/createInvoiceRequest", payload);
      commit(constants.POST_INVOICE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_INVOICE_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_INVOICE]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_INVOICE);
      const response = await axios.put("invoice/saveInvoice", payload);
      commit(constants.PUT_INVOICE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_INVOICE_ERROR, error);
      throw error.response;
    }
  }
};

const mutations = {
  [constants.GET_INVOICE_LIST]: (state) => {
    state.invoices.status = "fetching";

  },
  [constants.GET_INVOICE_LIST_SUCCESS]: (state, response) => {
    state.invoices.status = "success";
    state.invoices.data = response;
  },
  [constants.GET_INVOICE_LIST_ERROR]: (state, error) => {
    state.invoices.status = "error";
    state.invoices.error = error;
  },
  [constants.POST_INVOICE]: (state) => {
    state.invoice.status = "fetching";
    state.invoice.error = null;
  },
  [constants.POST_INVOICE_SUCCESS]: (state, response) => {
    state.invoice.status = "success";
    state.invoice.error = null;
    state.invoice.data = response;
  },
  [constants.POST_INVOICE_ERROR]: (state, error) => {
    state.invoice.status = "error";
    state.invoice.error = error;
  },
  [constants.PUT_INVOICE]: (state) => {
    state.invoice.status = "fetching";
    state.invoice.error = null;
  },
  [constants.PUT_INVOICE_SUCCESS]: (state, response) => {
    state.invoice.status = "success";
    state.invoice.error = null;
    state.invoice.data = response;
  },
  [constants.PUT_INVOICE_ERROR]: (state, error) => {
    state.invoice.status = "error";
    state.invoice.error = error;
  }
};
export default {
  state: getDefaultState(),
  actions,
  mutations,
};


