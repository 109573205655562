<template>
  <FilterTypeDate fromType="dateFrom" toType="dateTo" />
</template>

<script>
import FilterTypeDate from "./FilterTypeDate.vue";
export default {
  name: "FilterAccountingDate",
  components: { FilterTypeDate },
};
</script>

<style></style>
