<template>
  <div>
    <div class="container-fluid">
      <div>
        <b-table
          show-empty
          bordered
          small
          stacked="md"
          head-variant="light"
          :items="taxesList"
          :fields="fields"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" variant="yellow" />
            </div>
          </template>
          <template #cell(selected)="{ item }">
            <div class="d-flex justify-content-center">
              <b-form-checkbox
                :disabled="itemsSelected.length > 2 && !itemIsSelected(item)"
                :checked="item.selected"
                @change="(val) => onRowSelected(val, item)"
              />
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddonsFormTaxTAble",
  props: {
    taxes: {
      type: Array,
      default: () => [],
    },
    taxesSelected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        { key: "tps", label: "Tax name" },
        { key: "percent", label: "%" },
        "selected",
      ],
      itemsSelected: [],
      rowsSelected: [],
    };
  },
  computed: {
    taxesList() {
      const list = this.taxes.map((t) => {
        return {
          ...t,
          selected: t.appliesDefault ? true : false,
        };
      });
      return list;
    },
  },

  methods: {
    getSelectedItems(items) {
      this.rowsSelected = items;
      this.$emit("update-select-taxes", this.rowsSelected);
    },
    onRowSelected(val, item) {
      if (val) {
        this.itemsSelected.push(item);
      } else {
        const index = this.itemsSelected.indexOf(item);
        this.itemsSelected.splice(index, 1);
      }
      this.getSelectedItems([...this.itemsSelected]);
    },
    itemIsSelected(item) {
      return this.itemsSelected.indexOf(item) > -1;
    },
    selectDefaultTaxes() {
      this.taxesList.map((tax) => {
        tax.selected = tax.appliesDefault ? true : false;
        if (tax.appliesDefault) {
          this.onRowSelected(true, tax);
        }
      });
    },
    formatTaxesSelectedToEdit(taxes) {
      this.itemsSelected = [];
      this.rowsSelected = [];
      if (taxes.length == 0) {
        this.taxesList.forEach((tax) => {
          tax.selected = false;
        });
        this.selectDefaultTaxes();
      }
      this.taxesList.forEach((tax) => {
        taxes.forEach((taxSelected) => {
          if (tax.id == taxSelected.id) {
            tax.selected = true;
            this.onRowSelected(true, tax);
          }
        });
      });
    },
  },
  created() {
    this.taxes.forEach((tax) => {
      if (tax.appliesDefault == 1) {
        this.onRowSelected(true, tax);
      }
    });
  },
  updated() {
    this.itemsSelected = [];
    let taxDefault = this.taxes.filter((tax) => tax.appliesDefault == 1);
    taxDefault.forEach((tax) => {
      this.onRowSelected(true, tax);
    });
  },
};
</script>

<style>
</style>