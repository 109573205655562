<template>
  <div>
    <b-table
      responsive=""
      show-empty
      bordered
      small
      stacked="md"
      head-variant="light"
      :items="vehicles"
      :fields="fields"
      tbody-tr-class=""
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" variant="yellow" />
        </div>
      </template>
      <template v-slot:cell(description)="{ item }">
        <div>{{ item.year }} {{ item.make }} {{ item.model }}</div>
      </template>
      <template v-slot:cell(costUsd)="{ item }">
        <div>
          {{ item.costUsd | currency }}
        </div>
      </template>
      <template v-slot:cell(port)="{ item }">
        <div>
          <ExportPortSelect
            :portId="item.exportPortsId ? item.exportPortsId : null"
            :vehicleId="item.id"
          />
        </div>
      </template>
      <template v-slot:cell(dateExport)="{ item }">
        <div>{{ item.dateMFG }}</div>
      </template>
    </b-table>
  </div>
</template>

<script>
import ExportPortSelect from "./ExportPortSelect.vue";
export default {
  props: ["vehicles", "dateExport"],
  components: {
    ExportPortSelect,
  },
  name: "VehiclesDeclaration",
  data() {
    return {
      fields: [
        { key: "vinNumber", label: "Vin #" },
        { key: "description", label: "Description of article & quantity" },
        { key: "costUsd", label: "Value in US founds" },
        { key: "port", label: "Port of export" },
        { key: "dateExport", label: "Approx date of export" },
      ],
    };
  },
};
</script>

<style>
</style>