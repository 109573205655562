<template>
  <div>
    <div v-if="taskLogs.length == 0" class="text-center">
      <div class="h2 mt-3">No logs to show</div>
    </div>
    <div v-else>
      <TaskLogsListCard
        v-for="(taskLog, key) in taskLogs"
        :key="key"
        :taskLog="taskLog"
      />
    </div>
  </div>
</template>
<script>
import { GET_TASK_LOGS } from "../actions";
import TaskLogsListCard from "./TaskLogsListCard.vue";
export default {
  name: "TaskLogsList",
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  components: {
    TaskLogsListCard,
  },
  data() {
    return {
      taskLogs: [],
    };
  },
  watch: {
    task() {
      this.getTaskLogs();
    },
  },
  mounted() {
    this.getTaskLogs();
  },
  methods: {
    getTaskLogs() {
      this.$store
        .dispatch(GET_TASK_LOGS, { taskId: this.task.id })
        .then((response) => {
          this.taskLogs = response.task;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
</style>