import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    vehicles: {
      status: null,
      data: [],
      error: null,
    },
    bulkReleaseDate: {
      status: null,
      data: {},
      error: null,
    }
  };
}
const actions = {
  [constants.GET_VEHICLES_TO_RELEASE]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_VEHICLES_TO_RELEASE);
      const response = await axios.get("export/getVehicleList", {
        params: payload,
      });
      commit(constants.GET_VEHICLES_TO_RELEASE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLES_TO_RELEASE_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_VEHICLES_RELEASE_DATE]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_VEHICLES_RELEASE_DATE);
      const response = await axios.post("export/bulkReleaseDate", payload);
      commit(constants.POST_VEHICLES_RELEASE_DATE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_VEHICLES_RELEASE_DATE_ERROR, error);
      throw error.response;
    }
  },

};

const mutations = {
  [constants.GET_VEHICLES_TO_RELEASE]: (state) => {
    state.vehicles.data = [];
    state.vehicles.status = "fetching";
  },
  [constants.GET_VEHICLES_TO_RELEASE_SUCCESS]: (state, response) => {
    state.vehicles.status = "success";
    state.vehicles.data = response.vehicles;
  },
  [constants.GET_VEHICLES_TO_RELEASE_ERROR]: (state, error) => {
    state.vehicles.status = "error";
    state.vehicles.error = error;
  },
  [constants.POST_VEHICLES_RELEASE_DATE]: (state) => {
    state.bulkReleaseDate.data = {};
    state.bulkReleaseDate.status = "fetching";
  },
  [constants.POST_VEHICLES_RELEASE_DATE_SUCCESS]: (state, response) => {
    state.bulkReleaseDate.status = "success";
    state.bulkReleaseDate.data = response;
  },
  [constants.POST_VEHICLES_RELEASE_DATE_ERROR]: (state, error) => {
    state.bulkReleaseDate.status = "error";
    state.bulkReleaseDate.error = error;
  },


};
export default {
  state: getDefaultState(),
  actions,
  mutations,
};
