<template>
  <!-- Card stats -->
  <b-row>
    <b-col xl="3" md="6">
      <stats-card class="mb-4">
        <template slot>
          <div class="d-flex justify-content-between">
            <h5 class="card-title text-uppercase text-muted mb-0">Payments</h5>
            <div class="text-center">
              <i
                v-b-tooltip.click="{ variant: 'white' }"
                title="Warning within 0-5 days, Alert more than 5 days"
              >
                <font-awesome-icon
                  class="cursor-pointer text-blue"
                  :icon="['far', 'circle-question']"
                />
              </i>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <b-row>
              <b-col
                cols="auto"
                @click="applyFilter(paymentFilterWarning)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-yellow"
                  :icon="['far', 'triangle-exclamation']"
                  size="2xl"
                />

                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">
                  {{ admin != null ? admin.paymentsWarning : 0 }}
                </span>
              </b-col>
              <b-col
                cols="auto"
                @click="applyFilter(paymentFilterAlert)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-red"
                  :icon="['far', 'octagon-exclamation']"
                  size="2xl"
                />
                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">{{
                  admin != null ? admin.paymentsAlert : 0
                }}</span>
              </b-col>
            </b-row>
          </div>
        </template>
      </stats-card>
    </b-col>
    <b-col xl="3" md="6">
      <stats-card class="mb-4">
        <template slot>
          <div class="d-flex justify-content-between">
            <h5 class="card-title text-uppercase text-muted mb-0">
              No registration
            </h5>
            <div class="text-center">
              <i
                v-b-tooltip.click="{ variant: 'white' }"
                title="Warning within 3-7 days, Alert more than 7 days"
              >
                <font-awesome-icon
                  class="cursor-pointer text-blue"
                  :icon="['far', 'circle-question']"
                />
              </i>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <b-row>
              <b-col
                cols="auto"
                @click="applyFilter(registrationWarning)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-yellow"
                  :icon="['far', 'triangle-exclamation']"
                  size="2xl"
                />

                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">
                  {{ admin != null ? admin.registrationWarning : 0 }}</span
                >
              </b-col>
              <b-col
                cols="auto"
                @click="applyFilter(registrationAlert)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-red"
                  :icon="['far', 'octagon-exclamation']"
                  size="2xl"
                />
                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">
                  {{ admin != null ? admin.registrationAlert : 0 }}</span
                >
              </b-col>
            </b-row>
          </div>
        </template>
      </stats-card>
    </b-col>
    <b-col xl="3" md="6">
      <stats-card class="mb-4">
        <template slot>
          <div class="d-flex justify-content-between">
            <h5 class="card-title text-uppercase text-muted mb-0">
              Registration Dmv
            </h5>
            <div class="text-center">
              <i
                v-b-tooltip.click="{ variant: 'white' }"
                title="Warning within 0-5 days, Alert more than 5 days"
              >
                <font-awesome-icon
                  class="cursor-pointer text-blue"
                  :icon="['far', 'circle-question']"
                />
              </i>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <b-row>
              <b-col
                cols="auto"
                @click="applyFilter(registrationDmvWarning)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-yellow"
                  :icon="['far', 'triangle-exclamation']"
                  size="2xl"
                />

                <span class="h1 font-weight-bold mb-0 align-middle">
                  {{ admin != null ? admin.registrationDmvWarning : 0 }}</span
                >
              </b-col>

              <b-col
                cols="auto"
                @click="applyFilter(registrationDmvAlert)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-red"
                  :icon="['far', 'octagon-exclamation']"
                  size="2xl"
                />
                <span class="h1 font-weight-bold mb-0 align-middle">
                  {{ admin != null ? admin.registrationDmvAlert : 0 }}</span
                >
              </b-col>
            </b-row>
          </div>
        </template>
        <template slot="footer">
          <span class="text-nowrap"></span>
        </template>
      </stats-card>
    </b-col>
    <b-col xl="3" md="6">
      <stats-card class="mb-4">
        <template slot>
          <div class="d-flex justify-content-between">
            <h5 class="card-title text-uppercase text-muted mb-0">Liens</h5>
            <div class="text-center">
              <i
                v-b-tooltip.click="{ variant: 'white' }"
                title="Warning within 0-5 days, Alert more than 5 days"
              >
                <font-awesome-icon
                  class="cursor-pointer text-blue"
                  :icon="['far', 'circle-question']"
                />
              </i>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <b-row>
              <b-col
                cols="auto"
                @click="applyFilter(liensWarning)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-yellow"
                  :icon="['far', 'triangle-exclamation']"
                  size="2xl"
                />

                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">
                  {{ admin != null ? admin.liensWarning : 0 }}
                </span>
              </b-col>
              <b-col
                cols="auto"
                @click="applyFilter(liensAlert)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-red"
                  :icon="['far', 'octagon-exclamation']"
                  size="2xl"
                />
                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">{{
                  admin != null ? admin.liensAlert : 0
                }}</span>
              </b-col>
            </b-row>
          </div>
        </template>
        <template slot="footer">
          <span class="text-nowrap"></span>
        </template>
      </stats-card>
    </b-col>
    <b-col xl="3" md="6">
      <stats-card class="mb-4">
        <template slot>
          <div class="d-flex justify-content-between">
            <h5 class="card-title text-uppercase text-muted mb-0">Carfax</h5>
            <div class="text-center">
              <i
                v-b-tooltip.click="{ variant: 'white' }"
                title="Warning within 0-5 days, Alert more than 5 days"
              >
                <font-awesome-icon
                  class="cursor-pointer text-blue"
                  :icon="['far', 'circle-question']"
                />
              </i>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <b-row>
              <b-col
                cols="auto"
                @click="applyFilter(carfaxWarning)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-yellow"
                  :icon="['far', 'triangle-exclamation']"
                  size="2xl"
                />

                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">
                  {{ admin != null ? admin.carfaxWarning : 0 }}
                </span>
              </b-col>
              <b-col
                cols="auto"
                @click="applyFilter(carfaxAlert)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-red"
                  :icon="['far', 'octagon-exclamation']"
                  size="2xl"
                />
                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">{{
                  admin != null ? admin.carfaxAlert : 0
                }}</span>
              </b-col>
            </b-row>
          </div>
        </template>
        <template slot="footer">
          <span class="text-nowrap"></span>
        </template>
      </stats-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from "vuex";
import { SET_VEHICLES_INFO_FILTERS } from "@/modules/superAdmin/actions";
import { superAdminMixin } from "../../modules/superAdmin/mixins";
import { GET_BOARD_ADMIN } from "./actions";
export default {
  name: "BoardCardsAdmin",
  mixins: [superAdminMixin],
  computed: {
    ...mapState({
      admin: (state) => state.layout.boards.admin?.data,
      adminStatus: (state) => state.layout.boards.admin?.status,
    }),
  },
  data() {
    return {
      paymentFilterWarning: {
        stockDateFrom: this.getDateXDaysAgo(5),
        stockDateTo: this.getDateXDaysAgo(0),
        payments: 0,
        noConsignment: 1,
      },
      paymentFilterAlert: {
        payments: 0,
        stockDateTo: this.getDateXDaysAgo(5),
        noConsignment: 1,
      },
      registrationWarning: {
        stockDateFrom: this.getDateXDaysAgo(7),
        stockDateTo: this.getDateXDaysAgo(3),
        payments: "1,2",
        registration: 0,
      },
      registrationAlert: {
        registration: 0,
        payments: "1,2",
        stockDateTo: this.getDateXDaysAgo(8),
      },
      liensWarning: {
        liensFrom: this.getDateXDaysAgo(5),
        liensTo: this.getDateXDaysAgo(0),
        status: "InStock",
        lien: "2,3",
      },
      liensAlert: {
        liensTo: this.getDateXDaysAgo(5),
        status: "InStock",
        lien: "2,3",
      },
      registrationDmvWarning: {
        registration: "2,4",
        registrationDateFrom: this.getDateXDaysAgo(5),
        registrationDateTo: this.getDateXDaysAgo(0),
      },

      registrationDmvAlert: {
        registration: "2,4",
        registrationDateTo: this.getDateXDaysAgo(5),
      },
      carfaxWarning: {
        carfaxCheck: 1,
        carfaxDateFrom: this.getDateXDaysAgo(5),
        carfaxDateTo: this.getDateXDaysAgo(0),
      },
      carfaxAlert: {
        carfaxCheck: 1,
        carfaxDateTo: this.getDateXDaysAgo(5),
      },
    };
  },
  mounted() {
    this.getLogisticInformation();
  },
  methods: {
    applyFilter(filters) {
      this.resetFilters(); //Method from superAdminMixin
      this.$store.commit(SET_VEHICLES_INFO_FILTERS, {
        fields: { ...filters },
      });
      this.getVehiclesInfo(); //Method from superAdminMixin
    },
    getDateXDaysAgo(numOfDays, date = new Date()) {
      const daysAgo = new Date(date);
      daysAgo.setDate(date.getDate() - numOfDays);
      return this.formatDateString(daysAgo.toString());
    },
    getLogisticInformation() {
      this.$store.dispatch(GET_BOARD_ADMIN).then(() => {});
    },
    formatDateString(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const shortDate = `${year}-${month}-${day}`;
      return shortDate;
    },
  },
};
</script>

<style></style>
