import * as constants from './actions';
import axios from '@/plugins/axios.js';
import uploadVehiclePhotosStore from './uploadPhotos/store';
import componentsStore from './components/store';

function getDefaultState() {
  return {
    vehicle: {
      status: null,
      data: null,
      error: null
    },
    vehicleItem: {
      status: null,
      data: null,
      error: null,
      filters: {
        vinNumber: null
      }
    },
    uploadFile: {
      status: null,
      data: null,
      error: null
    },
    updateVehicleStatusFromFile: {
      status: null,
      data: null,
      error: null
    },
    usersNicknames: {
      status: null,
      data: null,
      error: null
    }
  };
}

const actions = {
  [constants.POST_VEHICLE]: async ({ commit }, params) => {
    try {
      commit(constants.POST_VEHICLE);
      const response = await axios.post('vehicle', params);
      commit(constants.POST_VEHICLE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_VEHICLE_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_VEHICLE_BY_VIN]: async ({ commit, state }) => {
    try {
      commit(constants.GET_VEHICLE_BY_VIN);
      const payload = Object.entries(state.vehicleItem.filters).reduce(
        (a, [ k, v ]) => (v === null || v === '' ? a : { ...a, [k]: v }),
        {}
      );
      const response = await axios.get('vehicle', { params: payload });
      commit(constants.GET_VEHICLE_BY_VIN_SUCCESS, response.data[0]);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLE_BY_VIN_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_UPDATE_VEHICLE]: async ({ commit }, data) => {
    try {
      commit(constants.POST_UPDATE_VEHICLE);
      const headers = {
        'Content-Type': 'application/form-data'
      };
      const response = await axios.post('vehicle/update', data, { headers });
      commit(constants.POST_UPDATE_VEHICLE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_UPDATE_VEHICLE_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_UPLOAD_FILE]: async ({ commit }, data) => {
    try {
      commit(constants.POST_UPLOAD_FILE);
      const headers = {
        'Content-Type': 'application/form-data'
      };
      const response = await axios.post('vehicle/csv', data, { headers });
      commit(constants.POST_UPLOAD_FILE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_UPLOAD_FILE_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_UPDATE_VEHICLES_STATUS_FROM_FILE]: async (
    { commit },
    data
  ) => {
    try {
      commit(constants.POST_UPDATE_VEHICLES_STATUS_FROM_FILE);
      const headers = {
        'Content-Type': 'application/form-data'
      };
      const response = await axios.post(
        'vehicle/updateVehicleStatusCsv',
        data,
        { headers }
      );
      commit(constants.POST_UPDATE_VEHICLES_STATUS_FROM_FILE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_UPDATE_VEHICLES_STATUS_FROM_FILE_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_USERS_NICKNAMES]: async ({ commit }) => {
    try {
      commit(constants.GET_USERS_NICKNAMES);
      const response = await axios.get('user/listNickname');
      commit(constants.GET_USERS_NICKNAMES_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_USERS_NICKNAMES_ERROR, error);
      throw error.response;
    }
  }
};

const mutations = {
  [constants.POST_VEHICLE]: (state) => {
    state.vehicle.status = 'fetching';
    state.vehicle.error = null;
  },
  [constants.POST_VEHICLE_SUCCESS]: (state, data) => {
    state.vehicle.status = 'success';
    state.vehicle.data = data;
    state.vehicle.error = null;
  },
  [constants.POST_VEHICLE_ERROR]: (state, error) => {
    state.vehicle.status = 'error';
    state.vehicle.error = error;
  },
  [constants.GET_VEHICLE_BY_VIN]: (state) => {
    state.vehicleItem.status = 'fetching';
    state.vehicleItem.error = null;
  },
  [constants.GET_VEHICLE_BY_VIN_SUCCESS]: (state, data) => {
    state.vehicleItem.status = 'success';
    state.vehicleItem.data = data;
    state.vehicleItem.error = null;
  },
  [constants.GET_VEHICLE_BY_VIN_ERROR]: (state, error) => {
    state.vehicleItem.status = 'error';
    state.vehicleItem.error = error;
  },
  [constants.SET_VEHICLE_VIN]: (state, filter) => {
    if (filter.reset) {
      state.vehicleItem.filters = {
        vinNumber: null
      };
    }
    if (filter.fields) {
      state.vehicleItem.filters = {
        ...state.vehicleItem.filters,
        ...filter.fields
      };
    }
  },
  [constants.POST_UPDATE_VEHICLE]: (state) => {
    state.vehicleItem.status = 'fetching';
    state.vehicleItem.error = null;
  },
  [constants.POST_UPDATE_VEHICLE_SUCCESS]: (state, data) => {
    state.vehicleItem.status = 'success';
    state.vehicleItem.data = data;
    state.vehicleItem.error = null;
  },
  [constants.POST_UPDATE_VEHICLE_ERROR]: (state, error) => {
    state.vehicleItem.status = 'error';
    state.vehicleItem.error = error;
  },
  [constants.POST_UPLOAD_FILE]: (state) => {
    state.uploadFile.status = 'fetching';
    state.uploadFile.error = null;
  },
  [constants.POST_UPLOAD_FILE_SUCCESS]: (state, data) => {
    state.uploadFile.status = 'success';
    state.uploadFile.data = data;
    state.uploadFile.error = null;
  },
  [constants.POST_UPLOAD_FILE_ERROR]: (state, error) => {
    state.uploadFile.status = 'error';
    state.uploadFile.error = error;
  },
  [constants.POST_UPDATE_VEHICLES_STATUS_FROM_FILE]: (state) => {
    state.updateVehicleStatusFromFile.status = 'fetching';
    state.updateVehicleStatusFromFile.error = null;
  },
  [constants.POST_UPDATE_VEHICLES_STATUS_FROM_FILE_SUCCESS]: (state, data) => {
    state.updateVehicleStatusFromFile.status = 'success';
    state.updateVehicleStatusFromFile.data = data;
    state.updateVehicleStatusFromFile.error = null;
  },
  [constants.POST_UPDATE_VEHICLES_STATUS_FROM_FILE_ERROR]: (state, error) => {
    state.updateVehicleStatusFromFile.status = 'error';
    state.updateVehicleStatusFromFile.error = error;
  },
  [constants.GET_USERS_NICKNAMES]: (state) => {
    state.usersNicknames.status = 'fetching';
    state.usersNicknames.error = null;
  },
  [constants.GET_USERS_NICKNAMES_SUCCESS]: (state, data) => {
    state.usersNicknames.status = 'success';
    state.usersNicknames.data = data;
    state.usersNicknames.error = null;
  },
  [constants.GET_USERS_NICKNAMES_ERROR]: (state, error) => {
    state.usersNicknames.status = 'error';
    state.usersNicknames.error = error;
  },
  [constants.CLEAR_VEHICLE_TO_EDIT]: (state) => {
    state.vehicleItem.data = null;
  }
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
  modules: {
    uploadVehiclePhotosStore,
    componentsStore
  }
};
