<template>
  <b-modal
    id="consignment-modal"
    ref="consignment-modal"
    hide-footer
    size="lg"
    title="Consignment"
    @show="getConsignment()"
  >
    <div v-if="consignments.status == 'fetching'" class="text-center">
      <b-spinner variant="yellow" />
    </div>
    <div v-else>
      <ConsignmentRegistrationForm
        v-if="userHasPermission('sl', 'write')"
        :refreshData="getConsignment"
      />
      <ConsignmentList />
    </div>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import { GET_CONSIGNMENTS } from "./actions";
import ConsignmentRegistrationForm from "./ConsignmentRegistrationForm";
import ConsignmentList from "./ConsignmentList.vue";
import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../../mixins";

export default {
  name: "ConsignmentModal",
  components: { ConsignmentRegistrationForm, ConsignmentList },
  mixins: [generalMixin, superAdminMixin],
  computed: {
    ...mapState({
      vehicle: (state) => state.superAdminStore.vehicle,
      consignments: (state) =>
        state.superAdminStore.componentStore.consignment.consignments,
    }),
  },
  data() {
    return {};
  },

  methods: {
    getConsignment() {
      this.$store.dispatch(GET_CONSIGNMENTS, { vehicleId: this.vehicle.id }).then(() => {
        this.validateConsignmentCreation();
      });
    },
    validateConsignmentCreation() {
      if (!this.validVehicle()) {
        this.$root.$bvToast.toast(
          "The vehicle does not have the requirements to generate a consignment",
          {
            title: "Error",
            variant: "warning",
          }
        );
        this.closeModal("consignment-modal");
      }
    },
    validVehicle() {
      const { vehicleFlags } = this.vehicle;
      if (vehicleFlags.consignment == 1) return true;
      if (vehicleFlags.exception == 1) return true;

      if (!this.vehicle.releaseDate) return false;

      if (vehicleFlags.stockNumber != 3) return false;

      if (vehicleFlags.payments != 1 && vehicleFlags.payments != 2) return false;

      if (vehicleFlags.carfax != 1 && vehicleFlags.carfax != 2) return false;

      if (vehicleFlags.recall != 1 && vehicleFlags.recall != 0) return false;

      if (vehicleFlags.transfer != 2) return false;

      if (vehicleFlags.vi != 2) return false;

      if (vehicleFlags.wo != 4 && vehicleFlags.wo != 0) return false;

      if (vehicleFlags.carWash != 2 && vehicleFlags.carWash != 0) return false;

      return true;
    },
  },
};
</script>

<style></style>
