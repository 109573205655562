<template>
  <b-modal
    id="bulk-transfer-download-modal"
    ref="bulk-transfer-download-modal"
    modal-class="modal-fullscreen"
    centered
    scrollable
    size="md"
    title="Download excel"
    hide-footer
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
  >
    <span class="h6"
      >Do you want to download the XLS file?
      <span class="text-danger">*</span></span
    >
    <br />
    <div class="float-right">
      <b-button @click="getTransferXLS()" variant="success" class="mx-2"
        >Yes</b-button
      >
      <b-button @click="hideModal()" variant="warning" class="mx-2"
        >No</b-button
      >
      <br />
    </div>
    <br />
    <br />

    <span class="text-danger"
      >*If you don't download the file now, you cant download later</span
    >
  </b-modal>
</template>

<script>
import { generalMixin } from "../../../mixin";
import { POST_BULK_TRANSFER_XLS } from "./actions";

export default {
  mixins: [generalMixin],
  props: {
    vehicles: {
      required: true,
      default: () => [],
    },
  },
  methods: {
    hideModal() {
      this.$bvModal.hide("bulk-transfer-download-modal");
    },
    getTransferXLS() {
      const payload = this.formatPayload(this.vehicles);

      this.$store
        .dispatch(POST_BULK_TRANSFER_XLS, { vehicles: payload })
        .then((response) => {
          const blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = this.formatFileName("transfers");
          link.click();
          this.hideModal();
          this.closeModal("bulk-transfer-modal");
        })
        .catch(() => {
          this.$root.$bvToast.toast("Could not get the excel file ", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    formatFileName(module) {
      let fileName = "";
      const today = new Date();
      fileName = `${module}-${today.getFullYear()}-${today.getMonth()}-${today.getDate()}-${today.getHours()}-${today.getMinutes()}-${today.getSeconds()}`;
      return fileName;
    },
    formatPayload(vehicles) {
      let vehiclesId = [];
      vehicles.forEach((vehicle) => {
        vehiclesId.push(vehicle.id);
      });
      return vehiclesId.join(",");
    },
  },
};
</script>

<style>
</style>