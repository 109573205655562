<template>
  <div>
    <b-navbar toggleable="lg" variant="light" align="end" class="nav mt-3 shadow">
      <b-navbar-toggle target="nav-collapse" />
      <b-collapse id="nav-collapse" is-nav align="end">
        <b-icon
          v-if="$route.name !== 'home'"
          class="my-2 cursor-pointer"
          icon="arrow-left-circle"
          variant="dark"
          scale="1.5"
          @click="$router.go(-1)"
        />
        <div v-else class="m-3"></div>
        <b-navbar-nav class="ml-auto">
          <b-navbar-nav>
            <b-nav-item
              @click="setStatus('success')"
              :active="status == 'success'"
              :class="status == 'success' ? 'border border-success rounded' : ''"
            >
              Success
            </b-nav-item>
            <b-nav-item
              @click="setStatus('failed')"
              :active="status == 'failed'"
              :class="status == 'failed' ? 'border border-success rounded' : ''"
            >
              Failed
            </b-nav-item>
            <b-nav-item
              @click="setStatus('error')"
              :active="status == 'error'"
              :class="status == 'error' ? 'border border-success rounded' : ''"
            >
              Read error
            </b-nav-item>
          </b-navbar-nav>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "AddonFileNav",
  data() {
    return {
      status: "success",
    };
  },
  methods: {
    setStatus(status) {
      this.status = status;
      this.$emit("set-status", status);
    },
  },
};
</script>

<style></style>
