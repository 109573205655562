var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.profile)?_c('side-bar',[_c('template',{slot:"logo"},[_c('CorporationSelect')],1),_c('template',{slot:"links"},[(_vm.profile && _vm.profile.user.roles[0].name == 'ROLE_MASTER')?_c('sidebar-item',{attrs:{"disabled":_vm.companyIsPending,"link":{
         name: 'Marketplace',
         path: {
           name: 'master.marketplace',
         },
         icon: ['far', 'cart-shopping-fast'],
         active: true,
       }}}):_vm._e(),(_vm.profile && _vm.profile.user.roles[0].name == 'ROLE_MASTER')?_c('sidebar-item',{attrs:{"disabled":_vm.companyIsPending,"active":_vm.$route.name && _vm.$route.name.includes('master.corporation'),"link":{
         name: 'Corporations',
         path: {
           name: 'master.corporation',
         },
         icon: ['far', 'building'],
       }}}):_vm._e(),(_vm.profile && _vm.profile.user.roles[0].name == 'ROLE_MASTER')?_c('sidebar-item',{attrs:{"disabled":_vm.companyIsPending,"active":_vm.$route.name && _vm.$route.name.includes('master.super-admin'),"link":{
         name: 'Super admins',
         path: {
           name: 'master.super-admin',
         },
         icon: ['far', 'user-group'],
       }}}):_vm._e(),(_vm.userHasMenuPermission('Logistics'))?_c('sidebar-item',{attrs:{"link":{
         name: 'Logistics',
         icon: ['far', 'truck-fast'],
       }}},[_c('sidebar-item',{attrs:{"link":{
           name: 'Vehicles',
           path: {
             name: 'superadmin.vehicles.table.logistic',
           },
           icon: ['far', 'cars'],
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'cars']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Vehicles ")])])])],2),_c('sidebar-item',{attrs:{"disabled":_vm.companyIsPending,"link":{
           name: 'Release date',
           path: {},
           icon: ['far', 'calendar-day'],
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link",on:{"click":function($event){return _vm.showModal('bulk-release-date-modal')}}},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'calendar-day']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Bulk release date")])])])],2),(_vm.profile && _vm.userHasPermission('buyChecks', 'read'))?_c('sidebar-item',{attrs:{"disabled":_vm.companyIsPending,"link":{
           name: 'Buy checks',
           path: {
             name: 'superadmin.checks.actives',
           },
           icon: ['far', 'money-check'],
           active:
             (_vm.$route.name &&
               _vm.$route.name.includes('superadmin.checks.actives')) ||
             (_vm.$route.name &&
               _vm.$route.name.includes('superadmin.checks.delivered')),
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'money-check']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Buy checks ")])])])],2):_vm._e()],1):_vm._e(),(_vm.userHasMenuPermission('Business'))?_c('sidebar-item',{attrs:{"link":{
         name: 'Business',
         path: {
           name: 'superadmin.vehicles.table.inventory',
         },
         icon: ['far', 'cars'],
         active: _vm.$route.name.includes('superadmin.vehicles.table.inventory'),
       }}},[(_vm.userHasPermission('addons', 'read'))?_c('sidebar-item',{attrs:{"link":{
           name: 'Business',
           path: {
             name: 'superadmin.vehicles.table.inventory',
           },
           icon: ['far', 'cars'],
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'cars']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Business ")])])])],2):_vm._e(),(_vm.userHasPermission('addons', 'read'))?_c('sidebar-item',{attrs:{"link":{
           name: 'Addons type',
           path: {
             name: 'superadmin.addons-type',
           },
           icon: ['far', 'puzzle-piece'],
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'puzzle-piece']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v(" Suppliers category ")])])])],2):_vm._e(),(_vm.userHasPermission('addons', 'read'))?_c('sidebar-item',{attrs:{"link":{
           name: 'Upload txt file',
           path: {
             name: 'superadmin.addons-file',
           },
           icon: ['far', 'file-csv'],
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'file-csv']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Upload txt files ")])])])],2):_vm._e(),(_vm.userHasPermission('fees', 'read'))?_c('sidebar-item',{attrs:{"link":{
           name: 'Fees',
           path: {
             name: 'superadmin.fees',
           },
           icon: ['far', 'hand-holding-dollar'],
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'hand-holding-dollar']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Fees ")])])])],2):_vm._e(),_c('sidebar-item',{attrs:{"link":{
           name: 'Reports',
           path: {
             name: 'superadmin.custom-reports',
           },
           icon: ['far', 'file-chart-column'],
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'file-chart-column']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Reports ")])])])],2)],1):_vm._e(),(_vm.userHasMenuPermission('Accounting'))?_c('sidebar-item',{attrs:{"link":{
         name: 'Accounting',
         path: {
           name: 'superadmin.vehicles.table.inventory',
         },
         icon: ['far', 'calculator'],
         active: _vm.$route.name.includes('superadmin.vehicles.table.inventory'),
       }}},[_c('sidebar-item',{attrs:{"link":{
           name: 'Vehicles',
           path: {
             name: 'superadmin.vehicles.table.accounting',
           },
           icon: ['far', 'cars'],
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'cars']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Vehicles ")])])])],2),_c('sidebar-item',{attrs:{"link":{
           name: 'Run list',
           path: {
             name: 'superadmin.run-list',
           },
           icon: ['far', 'calculator'],
           active: _vm.$route.name && _vm.$route.name.includes('superadmin.run-list'),
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'calculator']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Run list ")])])])],2),_c('sidebar-item',{attrs:{"link":{
           name: 'Transfer between agents',
           path: {
             name: 'superadmin.transfer-between-agent',
           },
           icon: ['far', 'truck-container'],
           active:
             _vm.$route.name &&
             _vm.$route.name.includes('superadmin.transfer-between-agent'),
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'truck-container']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Transfer between agents ")])])])],2),(_vm.userHasPermission('addons', 'read'))?_c('sidebar-item',{attrs:{"link":{
           name: 'Upload text file',
           path: {
             name: 'superadmin.addons-file',
           },
           icon: ['far', 'file-csv'],
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'file-csv']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Upload text file ")])])])],2):_vm._e(),(true)?_c('sidebar-item',{attrs:{"link":{
           name: 'Commissions',
           path: {
             name: 'superadmin.interest',
           },
           icon: ['far', 'money-bill-transfer'],
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'money-bill-transfer']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Commissions ")])])])],2):_vm._e(),(_vm.userHasPermission('taxes', 'read'))?_c('sidebar-item',{attrs:{"link":{
           name: 'Taxes',
           path: {
             name: 'superadmin.taxes',
           },
           icon: ['far', 'sack-dollar'],
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'sack-dollar']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Taxes ")])])])],2):_vm._e(),(_vm.userHasPermission('fx', 'read'))?_c('sidebar-item',{attrs:{"link":{
           name: 'Fx',
           path: {
             name: 'superadmin.fx',
           },
           icon: ['far', 'dollar-sign'],
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'dollar-sign']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Fx ")])])])],2):_vm._e(),_c('sidebar-item',{attrs:{"link":{
           name: 'Exchange rate contract',
           path: {
             name: 'superadmin.exchange-contract',
           },
           icon: ['far', 'sack-dollar'],
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'sack-dollar']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Exchange rate contract ")])])])],2),_c('sidebar-item',{attrs:{"link":{
           name: 'Commissions summary',
           path: {
             name: 'superadmin.commission-summary',
           },
           icon: ['far', 'money-bill-transfer'],
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'money-bill-transfer']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Commissions summary ")])])])],2),_c('sidebar-item',{attrs:{"link":{
           name: 'Driver',
           path: {
             name: 'superadmin.transportation',
           },
           icon: ['far', 'id-card'],
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'id-card']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Drivers ")])])])],2)],1):_vm._e(),(_vm.userHasMenuPermission('InFleet'))?_c('sidebar-item',{attrs:{"link":{
         name: 'In fleet',
         path: {
           name: 'superadmin.vehicles.table.admin',
         },
         icon: ['far', 'files'],
         active: _vm.$route.name.includes('superadmin.vehicles.table.admin'),
       }}},[_c('sidebar-item',{attrs:{"link":{
           name: 'Vehicles',
           path: {
             name: 'superadmin.vehicles.table.admin',
           },
           icon: ['far', 'cars'],
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'cars']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("In fleet")])])])],2),(_vm.profile && _vm.userHasPermission('registration', 'read'))?_c('sidebar-item',{attrs:{"disabled":_vm.companyIsPending,"active":_vm.$route.name && _vm.$route.name.includes('superadmin.registration'),"link":{
           name: 'Registration',
           path: {
             name: 'superadmin.registration',
           },
           icon: ['far', 'file-invoice-dollar'],
           active:
             _vm.$route.name && _vm.$route.name.includes('superadmin.registration'),
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'file-invoice-dollar']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Registration ")])])])],2):_vm._e(),(_vm.profile && _vm.userHasPermission('creditRequest', 'read'))?_c('sidebar-item',{attrs:{"disabled":_vm.companyIsPending,"link":{
           name: 'Credit request',
           path: {
             name: 'superadmin.credit-request',
           },
           icon: ['far', 'file-invoice-dollar'],
           active:
             _vm.$route.name && _vm.$route.name.includes('superadmin.credit-request'),
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'file-invoice-dollar']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Credit request ")])])])],2):_vm._e(),_c('sidebar-item',{attrs:{"disabled":_vm.companyIsPending,"link":{
           name: 'Reserve',
           path: {
             name: 'superadmin.reserve',
           },
           icon: ['far', 'message-dollar'],
           active: _vm.$route.name && _vm.$route.name.includes('superadmin.reserve'),
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'message-dollar']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Reserve")])])])],2)],1):_vm._e(),(_vm.userHasMenuPermission('Export'))?_c('sidebar-item',{attrs:{"link":{
         name: 'Export',
         path: {
           name: 'superadmin.vehicles.table.export',
         },
         icon: ['far', 'globe'],
         active: _vm.$route.name.includes('superadmin.vehicles.table.export'),
       }}},[_c('sidebar-item',{attrs:{"link":{
           name: 'Vehicles',
           path: {
             name: 'superadmin.vehicles.table.export',
           },
           icon: ['far', 'cars'],
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'cars']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Export")])])])],2),(_vm.userHasPermission('exportLocations', 'read'))?_c('sidebar-item',{attrs:{"link":{
           name: 'Export locations',
           path: {
             name: 'superadmin.export-location',
           },
           icon: ['far', 'location-dot'],
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'location-dot']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Export locations ")])])])],2):_vm._e(),(_vm.profile && _vm.userHasPermission('transfer', 'read'))?_c('sidebar-item',{attrs:{"disabled":_vm.companyIsPending,"link":{
           name: 'Transfer',
           path: {},
           icon: ['far', 'truck-container'],
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'truck-container']}})],1),_c('span',{staticClass:"nav-link-text",on:{"click":function($event){return _vm.showModal('bulk-transfer-modal')}}},[_vm._v("Bulk transfers")])])])],2):_vm._e(),(
           _vm.profile &&
           _vm.userHasPermission('exportLoads', 'read') &&
           _vm.userHasMenuPermission('ExportLoads')
         )?_c('sidebar-item',{attrs:{"disabled":_vm.companyIsPending,"link":{
           name: 'Export loads',
           path: {
             name: 'superadmin.export-load',
           },
           icon: ['far', 'truck-container'],
           active:
             _vm.$route.name && _vm.$route.name.includes('superadmin.export-load'),
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'truck-container']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Export loads")])])])],2):_vm._e(),_c('sidebar-item',{attrs:{"link":{
           name: 'Title manager',
           path: {
             name: 'superadmin.title-manager',
           },
           icon: ['far', 'files'],
           active:
             _vm.$route.name && _vm.$route.name.includes('superadmin.title-manager'),
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'files']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Title manager")])])])],2),_c('sidebar-item',{attrs:{"link":{
           name: 'R.I,',
           path: {
             name: 'superadmin.ri-companies',
           },
           icon: ['far', 'users'],
           active:
             _vm.$route.name && _vm.$route.name.includes('superadmin.ri-companies'),
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'users']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("R.I.")])])])],2)],1):_vm._e(),(_vm.userHasMenuPermission('Yard'))?_c('sidebar-item',{attrs:{"link":{
         name: 'Yard',
         path: {
           name: 'superadmin.vehicles.table.yard',
         },
         icon: ['far', 'cars'],
         active: _vm.$route.name.includes('superadmin.vehicles.table.yard'),
       }}}):_vm._e(),(_vm.userHasMenuPermission('Sales'))?_c('sidebar-item',{attrs:{"link":{
         name: 'Sales',
         path: {
           name: 'superadmin.vehicles.table.sales',
         },
         icon: ['far', 'cars'],
         active: _vm.$route.name.includes('superadmin.vehicles.table.sales'),
       }}},[_c('sidebar-item',{attrs:{"link":{
           name: 'Vehicles',
           path: {
             name: 'superadmin.vehicles.table.sales',
           },
           icon: ['far', 'cars'],
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'cars']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Sales")])])])],2),_c('sidebar-item',{attrs:{"disabled":_vm.companyIsPending,"link":{
           name: 'Invoice request',
           path: {
             name: 'superadmin.invoice',
           },
           icon: ['far', 'file-invoice-dollar'],
           active: _vm.$route.name && _vm.$route.name.includes('superadmin.invoice'),
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'file-invoice-dollar']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Invoice request")])])])],2),_c('sidebar-item',{attrs:{"link":{
           name: 'Transfer between agents',
           path: {
             name: 'superadmin.transfer-between-agent',
           },
           icon: ['far', 'truck-container'],
           active:
             _vm.$route.name &&
             _vm.$route.name.includes('superadmin.transfer-between-agent'),
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'truck-container']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Transfer between agents ")])])])],2),_c('sidebar-item',{attrs:{"disabled":_vm.companyIsPending,"link":{
           name: 'Reserve',
           path: {
             name: 'superadmin.reserve',
           },
           icon: ['far', 'message-dollar'],
           active: _vm.$route.name && _vm.$route.name.includes('superadmin.reserve'),
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'message-dollar']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Reserve")])])])],2)],1):_vm._e(),(
         _vm.profile &&
         _vm.userHasPermission('cluster', 'read') &&
         _vm.userHasMenuPermission('Cluster')
       )?_c('sidebar-item',{attrs:{"disabled":_vm.companyIsPending,"link":{
         name: 'Cluster',
         path: {},
         icon: ['far', 'gauge-simple'],
       }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link",on:{"click":function($event){return _vm.showModal('bulk-cluster-modal')}}},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'gauge-simple']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Cluster")])])])],2):_vm._e(),(
         (_vm.profile && _vm.userHasPermission('companies', 'read'),
         _vm.userHasMenuPermission('Companies'))
       )?_c('sidebar-item',{attrs:{"disabled":_vm.companyIsPending,"link":{
         name: 'Companies',
         path: {
           name: 'admin.users.validate',
         },
         icon: ['far', 'user-group'],
         active: _vm.$route.name && _vm.$route.name.includes('admin.users.validate'),
       }}}):_vm._e(),(_vm.userHasMenuPermission('Task'))?_c('sidebar-item',{attrs:{"link":{
         name: 'Tasks',
         path: {
           name: 'superadmin.tasks',
         },
         icon: ['far', 'list-check'],
         active: _vm.$route.name && _vm.$route.name.includes('superadmin.tasks'),
       }}}):_vm._e(),(_vm.profile && _vm.profile.user.roles[0].name == 'ROLE_RI')?_c('sidebar-item',{attrs:{"link":{
         name: 'Vehicles',
         path: {
           name: 'ri.vehicles',
         },
         icon: ['far', 'cars'],
         active: _vm.$route.name && _vm.$route.name.includes('ri.vehicles'),
       }}}):_vm._e(),(_vm.profile && _vm.profile.user.roles[0].name == 'ROLE_RI')?_c('sidebar-item',{attrs:{"link":{
         name: 'Title manager',
         path: {
           name: 'superadmin.title-manager',
         },
         icon: ['far', 'files'],
         active:
           _vm.$route.name && _vm.$route.name.includes('superadmin.title-manager'),
       }}}):_vm._e(),(_vm.profile && _vm.profile.user.roles[0].name == 'ROLE_RI')?_c('sidebar-item',{attrs:{"link":{
         name: 'Release date',
         path: {},
         icon: ['far', 'calendar-day'],
       }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link",on:{"click":function($event){return _vm.showModal('bulk-release-date-modal')}}},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'calendar-day']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Bulk release date")])])])],2):_vm._e(),(
         _vm.userHasMenuPermission('Configuration') ||
         _vm.profile.user.roles[0].name == 'ROLE_MASTER'
       )?_c('sidebar-item',{attrs:{"link":{
         name: 'Configuration ',
         icon: ['far', 'wrench'],
       }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'wrench']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Configuration ")])])]),_c('template',{slot:"default"},[(_vm.profile && _vm.profile.user.roles[0].name == 'ROLE_MASTER')?_c('sidebar-item',{attrs:{"link":{
             name: 'Corporation permissions',
             path: {
               name: 'master.assign.corporation',
             },
             icon: ['far', 'lock-keyhole'],
           }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'lock-keyhole']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Corporation permissions ")])])])],2):_vm._e(),(
             (_vm.profile && _vm.profile.user.roles[0].name == 'ROLE_MASTER') ||
             _vm.profile.user.roles[0].name == 'ROLE_SUPER_ADMIN'
           )?_c('sidebar-item',{attrs:{"link":{
             name: 'Marketplace permissions',
             path: {
               name: 'master.assign.marketplace',
             },
             icon: ['far', 'lock-keyhole'],
           }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'lock-keyhole']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Marketplace permissions ")])])])],2):_vm._e(),(_vm.userHasPermission('permissions', 'read'))?_c('sidebar-item',{attrs:{"link":{
             name: 'Permissions',
             path: {
               name: 'superadmin.permissions',
             },
             icon: ['far', 'lock-keyhole'],
           }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'lock-keyhole']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Permissions ")])])])],2):_vm._e(),(_vm.userHasPermission('permissions', 'read'))?_c('sidebar-item',{attrs:{"link":{
             name: 'Menu permissions',
             path: {
               name: 'superadmin.menu-permissions',
             },
             icon: ['far', 'lock-keyhole'],
           }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'lock-keyhole']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Menu permissions ")])])])],2):_vm._e(),(_vm.userHasPermission('permissions', 'read'))?_c('sidebar-item',{attrs:{"link":{
             name: 'Task types by user',
             path: {
               name: 'superadmin.task-types-by-user',
             },
             icon: ['far', 'lock-keyhole'],
           }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'lock-keyhole']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Task types by user ")])])])],2):_vm._e(),(_vm.userHasPermission('drivers', 'read'))?_c('sidebar-item',{attrs:{"link":{
             name: 'Drivers',
             path: {
               name: 'superadmin.drivers',
             },
             icon: ['far', 'id-card'],
           }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'id-card']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Drivers ")])])])],2):_vm._e(),(_vm.userHasPermission('providers', 'read'))?_c('sidebar-item',{attrs:{"link":{
             name: 'Suppliers',
             path: {
               name: 'work-order.providers',
             },
             icon: ['far', 'building'],
           }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'building']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Suppliers ")])])])],2):_vm._e(),(_vm.userHasPermission('agents', 'read'))?_c('sidebar-item',{attrs:{"link":{
             name: 'Users',
             path: {
               name: 'admin.agent',
             },
             icon: ['far', 'user-group'],
           }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'user-group']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Users ")])])])],2):_vm._e(),(_vm.userHasPermission('companies', 'read'))?_c('sidebar-item',{attrs:{"link":{
             name: 'External users',
             path: {
               name: 'admin.assign-company',
             },
             icon: ['far', 'user-group'],
           }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'user-group']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("External users ")])])])],2):_vm._e(),(_vm.userHasPermission('addons', 'read'))?_c('sidebar-item',{attrs:{"link":{
             name: 'Addons type',
             path: {
               name: 'superadmin.addons-type',
             },
             icon: ['far', 'puzzle-piece'],
           }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'puzzle-piece']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v(" Suppliers category ")])])])],2):_vm._e(),(_vm.userHasPermission('addons', 'read'))?_c('sidebar-item',{attrs:{"link":{
             name: 'Addons file',
             path: {
               name: 'superadmin.addons.assign',
             },
             icon: ['far', 'file-csv'],
           }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'file-csv']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Assign suppliers category ")])])])],2):_vm._e(),_c('sidebar-item',{attrs:{"link":{
             name: 'Drivers price',
             path: {
               name: 'superadmin.driver-price',
             },
             icon: ['far', 'money-check-dollar-pen'],
           }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'money-check-dollar-pen']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Drivers price ")])])])],2)],1)],2):_vm._e()],1),_c('template',{slot:"links-after"},[_c('hr',{staticClass:"my-2"}),_c('b-nav',{staticClass:"navbar-nav mb-md-3"},[_c('sidebar-item',{attrs:{"link":{
           name: 'Logout',
           path: {},
           icon: ['far', 'right-from-bracket'],
         }}},[_c('template',{slot:"title"},[_c('div',{staticClass:"nav-link",on:{"click":function($event){return _vm.logout()}}},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['far', 'right-from-bracket']}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Logout ")])])])],2)],1)],1),_c('BulkClusterModal'),_c('BulkTransferModal'),_c('BulkReleaseDateModal'),_c('b-modal',{ref:"pricing-modal",attrs:{"id":"pricing-modal","size":"md","hide-footer":"","body-class":"pl-0 pr-0","scrollable":"","ok-disabled":""}},[_c('Pricing')],1)],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }