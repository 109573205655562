import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    profile: {
      status: null,
      data: null,
      error: null,
    },
    corporation: {},
    favoriteDashboard: {
      status: null,
      data: [],
      error: null,
    },
  };
}

const actions = {
  [constants.AUTH_LOGIN]: async ({ commit }, params) => {
    try {
      commit(constants.AUTH_LOGIN);
      const response = await axios.post("login", params);
      commit(constants.AUTH_LOGIN_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.AUTH_LOGIN_ERROR, error);
      throw error.response;
    }
  },
  [constants.AUTH_LOGOUT]: async ({ commit }) => {
    try {
      commit(constants.AUTH_LOGOUT);
      const response = await axios.post("/auth/logout");
      commit(constants.AUTH_LOGOUT_SUCCESS);
      return response;
    } catch (error) {
      commit(constants.AUTH_LOGOUT_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_FAVORITE_DASHBOARD]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_FAVORITE_DASHBOARD);
      const response = await axios.put("user", payload);
      commit(constants.PUT_FAVORITE_DASHBOARD_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_FAVORITE_DASHBOARD_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.AUTH_LOGIN]: (state) => {
    state.profile.status = "fetching";
    state.profile.error = null;
  },
  [constants.AUTH_LOGIN_SUCCESS]: (state, data) => {
    localStorage.setItem("access-token", data.auth.accessToken);
    localStorage.setItem("refresh-token", data.auth.refreshToken);
    state.profile.status = "success";
    state.profile.error = null;
    state.profile.data = data;
  },
  [constants.AUTH_LOGIN_ERROR]: (state, error) => {
    state.profile.status = "error";
    state.profile.error = error;
  },
  [constants.AUTH_LOGOUT]: (state) => {
    state.profile.status = "fetching";
    state.profile.error = null;
  },
  [constants.AUTH_LOGOUT_SUCCESS]: (state) => {
    state.profile.status = "success";
    state.profile.error = null;
    state.profile.data = null;
    localStorage.removeItem("access-token");
    localStorage.removeItem("refresh-token");
    localStorage.removeItem("corporation");
    localStorage.removeItem("favoriteBoard");
    state.corporation = null;
  },
  [constants.AUTH_LOGOUT_ERROR]: (state, error) => {
    state.profile.status = "error";
    state.profile.error = error;
  },
  [constants.SET_PROFILE_DATA]: (state, data) => {
    state.profile.data = data;
  },
  [constants.SET_CORPORATION]: (state, data) => {
    state.corporation = data;
  },
  [constants.PUT_FAVORITE_DASHBOARD]: (state) => {
    state.favoriteDashboard.data = [];
    state.favoriteDashboard.status = "fetching";
  },
  [constants.PUT_FAVORITE_DASHBOARD_SUCCESS]: (state, response) => {
    state.favoriteDashboard.status = "success";
    state.favoriteDashboard.data = response;
  },
  [constants.PUT_FAVORITE_DASHBOARD_ERROR]: (state, error) => {
    state.favoriteDashboard.status = "error";
    state.favoriteDashboard.error = error;
  },

};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
