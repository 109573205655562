import { render, staticRenderFns } from "./RegistrationTable.vue?vue&type=template&id=48da7c07&scoped=true&"
import script from "./RegistrationTable.vue?vue&type=script&lang=js&"
export * from "./RegistrationTable.vue?vue&type=script&lang=js&"
import style0 from "./RegistrationTable.vue?vue&type=style&index=0&id=48da7c07&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48da7c07",
  null
  
)

export default component.exports