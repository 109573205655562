<template>
  <b-modal
    id="vehicle-files-modal"
    ref="vehicle-files-modal"
    size="lg"
    hide-footer
    body-class="pl-0 pr-0"
    ok-disabled
    title="Vehicle files"
  >
    <b-container v-if="vehicleFiles">
      <h2>Carfax files:</h2>
      <b-button
        v-for="file in vehicleFiles.carfax"
        :key="file.file"
        variant="success"
        class="mr-2 mb-2"
        @click="openFile(file.file)"
      >
        {{ file.date | formatDateTime }}
      </b-button>

      <div v-if="vehicleFiles.contract">
        <h2 class="mt-2">Contract:</h2>
        <b-button
          variant="success"
          class="mr-2 mb-2"
          @click="openFile(vehicleFiles.contract)"
        >
          See contract
        </b-button>
      </div>

      <h2 class="mt-2">Liens:</h2>
      <b-button
        v-for="file in vehicleFiles.liens"
        :key="file.file"
        variant="success"
        class="mr-2 mb-2"
        @click="openFile(file.file)"
      >
        {{ file.date | formatDateTime }}
      </b-button>
      <h2 class="mt-2">Recalls:</h2>
      <b-button
        v-for="file in vehicleFiles.recalls"
        :key="file.file"
        variant="success"
        class="mr-2 mb-2"
        @click="openFile(file.file)"
      >
        {{ file.date | formatDateTime }}
      </b-button>

      <div v-if="vehicleFiles.registration">
        <h2 class="mt-2">Registration:</h2>
        <b-button
          variant="success"
          class="mr-2 mb-2"
          @click="openFile(vehicleFiles.registration)"
        >
          See registration
        </b-button>
      </div>
    </b-container>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "VehicleFilesModal",
  props: {
    vehicleId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState({
      vehicleFiles: (state) => state.vehicle.vehicleFiles.data,
    }),
  },
  mounted() {
    this.getVehicleFiles();
  },
  methods: {
    getVehicleFiles() {
      this.$store
        .dispatch("GET_VEHICLE_FILES", { vehicleId: this.vehicleId })
        .then((res) => {
          this.files = res;
        });
    },
    openFile(file) {
      window.open(file, "_blank");
    },
  },
};
</script>

<style>
</style>