<template>
  <b-modal
    id="select-vehicle-table-fields"
    title="Select columns to show"
    @show="setData()"
  >
    <b-form-checkbox-group v-model="selected">
      <b-row>
        <b-col cols="6" v-for="(field, index) in fields" :key="index">
          <b-form-checkbox :value="field.key">
            {{ field.label }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-form-checkbox-group>

    <template v-slot:modal-footer="{ ok }">
      <b-button :disabled="isLoading" variant="success" @click="save(ok)">
        Save
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { superAdminMixin } from "../mixins";
import { defaultFields, optionalFields } from "../vehicleTableFields";
export default {
  props: ["fieldsFromTable", "fieldsPermitted"],
  mixins: [superAdminMixin],
  data() {
    return {
      selected: [],
      isLoading: false,
      allFields: [],
    };
  },
  computed: {
    fields() {
      if (this.profileStore.user.roles[0].name == "ROLE_RI") {
        const fieldsByPermission = this.allFields.filter((field) =>
          this.userPermissions.some(
            (permission) =>
              (field.module === permission.module && permission.read === 1) ||
              field.module === ""
          )
        );
        return fieldsByPermission;
      } else {
        return this.allFields;
      }
    },
  },
  mounted() {
    this.allFields = [...defaultFields, ...optionalFields];
  },
  methods: {
    setData() {
      this.selected = [];
      this.fieldsFromTable.filter((field) => {
        this.selected.push(field.key);
      });
    },
    getFieldsFromCookie() {
      let array = [];
      this.selected.forEach((val) => {
        let found = optionalFields.find((field) => field.key === val);
        if (found) array.push(found);
      });
      return array;
    },
    save(callback) {
      const optionalFields = this.orderArray(this.getFieldsFromCookie());
      this.$cookies.set(
        "fields",
        JSON.stringify([...defaultFields, ...optionalFields])
      );
      this.$emit("getCookie");
      callback();
    },
    orderArray(array) {
      let arrayOrdered = [];
      optionalFields.forEach((field) => {
        array.forEach((val) => {
          if (val.key === field.key) arrayOrdered.push(val);
        });
      });
      return arrayOrdered;
    },
  },
};
</script>

<style></style>
