<template>
  <div class="container-fluid mt-3">
    <b-row class="">
      <b-col>
        <CompanySelect @company-select="setCompanySelect($event)" />
      </b-col>
      <b-col>
        <b-button @click="updateAddons()" :disabled="addons.status === 'fetching'"
          >Update categories</b-button
        ></b-col
      >
    </b-row>
    <div class="pt-4">
      <div class="">
        <b-table
          show-empty
          bordered
          small
          stacked="md"
          head-variant="light"
          :items="addonsList"
          :fields="fields"
          :busy="addons.status === 'fetching'"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" variant="yellow" />
            </div>
          </template>
          <template #cell(selected)="{ item }">
            <div class="d-flex justify-content-center">
              <b-form-checkbox
                :checked="item.selected ? true : false"
                @change="(val) => onRowSelected(val, item)"
              />
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { generalMixin } from "../../mixin";
import { GET_ADDON_TYPE_LIST, PUT_ASSIGN_ADDON_TYPE } from "./actions";
import CompanySelect from "./CompanySelect.vue";
export default {
  components: {
    CompanySelect,
  },
  mixins: [generalMixin],
  computed: {
    ...mapState({
      addons: (state) => state.superAdminStore.addonsAssign.addonsList.data,
    }),
    marketplaceFormatted() {
      return this.addonsList;
    },
  },
  data() {
    return {
      fields: [
        { key: "name", label: "Category" },
        { key: "selected", label: "" },
      ],
      companySelected: null,
      addonsList: [],
    };
  },
  created() {
    this.$store.commit("setAddFunction", null);
    this.getData();
  },
  methods: {
    getData() {
      this.$store
        .dispatch(GET_ADDON_TYPE_LIST, { status: 1 })
        .then(() => {
          this.addonsList = this.formatCorporations();
          this.$children[0].getCompanyAddon();
          this.resetAddons();
          this.companySelected = null;
          this.$children[0].clearCompanySelect();
        })
        .catch(() => {});
    },
    setCompanySelect(company) {
      this.companySelected = company;
      this.addonsList = [...this.addons.data];
      this.resetAddons();
      this.setCorporationByUser();
    },

    onRowSelected(val, item) {
      if (val) {
        item.selected = true;
      } else {
        item.selected = false;
      }
      const index = this.addonsList.findIndex((addon) => addon.id === item.id);
      this.addonsList[index] = item;
      this.addonsList = [...this.addonsList];
    },
    formatCorporations() {
      this.addonsList = [];
      let addonsList = [...this.addons.data];
      addonsList.forEach((addon) => {
        addon.selected = false;
      });
      return addonsList;
    },
    setCorporationByUser() {
      this.addonsList.forEach((addon) => {
        this.companySelected.addonTypes.forEach((corp) => {
          if (addon.id === corp.id) {
            addon.selected = true;
          }
        });
      });
      this.addonsList = [...this.addonsList];
    },
    resetAddons() {
      this.addonsList.forEach((addon) => {
        addon.selected = false;
      });
      this.addonsList = [...this.addonsList];
    },
    updateAddons() {
      const itemsSelected = this.addonsList.filter((addon) => addon.selected === true);
      this.$store
        .dispatch(PUT_ASSIGN_ADDON_TYPE, {
          id: this.companySelected.id,
          addonTypes: itemsSelected,
        })
        .then(() => {
          this.makeToast("Success", "Addons updated", "success");
          this.getData();
        })
        .catch(() => {});
    },
  },
};
</script>

<style></style>
