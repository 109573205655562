import * as constants from "./actions";
import axios from "@/plugins/axios.js";
function getDefaultState() {
  return {
    reserve: {
      status: null,
      data: null,
      error: null,
    },
    transfer: {
      status: null,
      data: {},
      error: null,
    },

    origins: {
      status: null,
      data: [],
      error: null,
    },
    kilometers: {
      status: null,
      data: {},
      error: null,
    },
  };
}

const actions = {
  [constants.GET_RESERVE]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_RESERVE);
      const response = await axios.get(`reserve/find`, {
        params: payload,
      });
      commit(constants.GET_RESERVE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_RESERVE_ERROR, error);
      throw error.response;
    }
  },

  [constants.POST_RESERVE]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_RESERVE);
      const response = await axios.post("reserve/createAndUpdate", payload);
      commit(constants.POST_RESERVE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_RESERVE_ERROR, error);
      throw error.response;
    }
  },

  [constants.PUT_RESERVE]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_RESERVE);
      const response = await axios.put("transfer/changeStatus", payload);
      commit(constants.PUT_RESERVE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_RESERVE_ERROR, error);
      throw error.response;
    }
  },
  [constants.DELETE_RESERVE]: async ({ commit }, payload) => {
    try {
      commit(constants.DELETE_RESERVE);
      const response = await axios.delete("reserve/delete", {
        params: payload,
      });
      commit(constants.DELETE_RESERVE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.DELETE_RESERVE_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_RESERVE]: (state) => {
    state.reserve.status = "fetching";
    state.reserve.error = null;
  },
  [constants.GET_RESERVE_SUCCESS]: (state, data) => {
    state.reserve.status = "success";
    state.reserve.error = null;
    state.reserve.data = data.reserve;
  },
  [constants.GET_RESERVE_ERROR]: (state, error) => {
    state.reserve.status = "error";
    state.reserve.error = error;
  },
  [constants.POST_RESERVE]: (state) => {
    state.reserve.status = "fetching";
    state.reserve.error = null;
  },
  [constants.POST_RESERVE_SUCCESS]: (state, data) => {
    state.reserve.status = "success";
    state.reserve.error = null;
    state.reserve.data = data;
  },
  [constants.POST_RESERVE_ERROR]: (state, error) => {
    state.reserve.status = "error";
    state.reserve.error = error;
  },

  [constants.PUT_RESERVE]: (state) => {
    state.reserve.status = "fetching";
    state.reserve.error = null;
  },
  [constants.PUT_RESERVE_SUCCESS]: (state, data) => {
    state.reserve.status = "success";
    state.reserve.error = null;
    state.reserve.data = data;
  },
  [constants.PUT_RESERVE_ERROR]: (state, error) => {
    state.reserve.status = "error";
    state.reserve.error = error;
  },
  [constants.DELETE_RESERVE]: (state) => {
    state.reserve.status = "fetching";
    state.reserve.error = null;
  },
  [constants.DELETE_RESERVE_SUCCESS]: (state, data) => {
    state.reserve.status = "success";
    state.reserve.error = null;
    state.reserve.data = data;
  },
  [constants.DELETE_RESERVE_ERROR]: (state, error) => {
    state.reserve.status = "error";
    state.reserve.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
