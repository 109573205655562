export const GET_SUPER_ADMINS = "GET_SUPER_ADMINS";
export const GET_SUPER_ADMINS_SUCCESS = "GET_SUPER_ADMINS_SUCCESS";
export const GET_SUPER_ADMINS_ERROR = "GET_SUPER_ADMINS_ERROR";

export const GET_SUPER_ADMIN_LIST = "GET_SUPER_ADMIN_LIST";
export const GET_SUPER_ADMIN_LIST_SUCCESS = "GET_SUPER_ADMIN_LIST_SUCCESS";
export const GET_SUPER_ADMIN_LIST_ERROR = "GET_SUPER_ADMIN_LIST_ERROR";

export const POST_SUPER_ADMIN = "POST_SUPER_ADMIN";
export const POST_SUPER_ADMIN_SUCCESS = "POST_SUPER_ADMIN_SUCCESS";
export const POST_SUPER_ADMIN_ERROR = "POST_SUPER_ADMIN_ERROR";
