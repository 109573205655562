<template>
  <div class="d-flex flex-column justify-content-start">
    <canvas id="interior" class="mycanvas car-img" width="300" height="448" />
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import { isMobile } from "mobile-device-detect";
import { mapState } from "vuex";
import CompanyCarsService from "@/services/companyCarsService";
import { superAdminMixin } from "@/modules/superAdmin/mixins.js";

export default {
  props: {
    dotsExterior: Array,
    vehicle: Object,
  },
  mixins: [superAdminMixin],
  data() {
    return {
      context: null,
      x: null,
      y: null,
      form: {
        status: null,
        description: null,
        file: null,
        partId: null,
        damageId: null,
      },
      dotsEx: [],
      showModal: false,
      isSmall: false,

      interiorContex: null,
      exteriorContex: null,
      isRemarkingDot: false,
      currentDamage: null,
      autoParts: [],
      damagesList: [],
    };
  },
  validations: {
    form: {
      description: {
        maxLength: maxLength(255),
      },
      file: {
        required,
      },
    },
  },
  computed: {
    ...mapState({
      damageImageModal: (state) => state.vehicle.visualInspection.damageImageModal,
    }),
  },
  mounted() {
    document
      .getElementById("interior")
      .addEventListener("click", (e) => this.handleClick(e));
    const canvas = document.getElementById("interior");
    const context = canvas.getContext("2d");
    this.context = context;
    setTimeout(() => {
      this.dotsEx = [...this.dotsExterior];
      this.setImg(() => {
        this.drawAllDots();
      });
    }, 700);
    this.getAutoParts();
    this.$store.commit("visualInspection/setDamageImageModal", null);
    this.$store.commit("visualInspection/setRepairImageModal", null);
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    clearCanvas(callback = null) {
      this.context.clearRect(0, 0, 300, 448);
      this.setImg(callback);
    },
    drawAllDots() {
      this.dotsEx.forEach((dot) => {
        this.setDot({ x: dot.x, y: dot.y }, dot.color, dot.status);
      });
    },
    setDot(coordinates, color, status) {
      this.x = coordinates.x;
      this.y = coordinates.y;
      this.context.beginPath();
      this.context.arc(coordinates.x, coordinates.y, 20, 0, 2 * Math.PI, false);
      this.context.fillStyle = color || this.form.status;
      this.context.fill();
      /* draw check icon inside dot */
      if (status == 1) {
        this.context.beginPath();
        this.context.arc(coordinates.x, coordinates.y, 10, 0, 2 * Math.PI, false);
        this.context.fillStyle = "white";
        this.context.fill();
        this.context.beginPath();
        this.context.moveTo(coordinates.x - 5, coordinates.y);
        this.context.lineTo(coordinates.x, coordinates.y + 5);
        this.context.lineTo(coordinates.x + 5, coordinates.y - 5);
        this.context.strokeStyle = "green";
        this.context.stroke();
      }
    },
    setImg(callback = null) {
      const bg = new Image();
      if (!this.vehicle.vinNumber.vehicleType) {
        bg.src = "https://apidev.transcanauto.com/storage/system/inside.png";
      } else {
        bg.src = this.vehicle.vinNumber.vehicleType.includes("SUV")
          ? "https://apidev.transcanauto.com/storage/interior-car.png"
          : "https://apidev.transcanauto.com/storage/system/inside.png";
      }

      bg.onload = () => {
        this.context.drawImage(bg, 0, 0, 300, 448);
        if (callback) callback();
      };
    },
    changeModal(e) {
      if (!e) {
        this.clearCanvas(() => {
          this.drawAllDots();
        });
      }
    },

    handleClick(e) {
      this.isRemarkingDot && this.drawAllDots();
      const x = e.offsetX;
      const y = e.offsetY;
      this.dotsEx.forEach((dot, i) => {
        if (this.validateClickedDot(x, y, dot)) {
          this.clickDot(i, dot);
        }
      });
    },
    validateClickedDot(x, y, dot) {
      return x <= dot.x + 22 && x >= dot.x - 22 && y <= dot.y + 22 && y >= dot.y - 22;
    },
    clickDot(index, dot) {
      this.remarkDot(dot, this.context);

      this.clickDamageDot(this.dotsEx[index]);
    },
    remarkDot(dot, context) {
      context.beginPath();
      context.lineWidth = 5;
      context.arc(dot.x, dot.y, 18, 0, 2 * Math.PI, false);
      context.strokeStyle = "#F7F7F7";
      context.stroke();
      this.isRemarkingDot = true;
    },

    clickDamageDot(dot) {
      this.currentDamage = dot;
      if (isMobile || this.isSmall) {
        if (this.form.status == null) {
          this.$store.commit("visualInspection/setDamageImageModal", dot.damagePhoto);
          this.$store.commit(
            "visualInspection/setRepairImageModal",
            dot.repairPhoto || null
          );
        }
      } else {
        if (this.form.status == null) {
          this.$store.commit("visualInspection/setDamageImageModal", dot.damagePhoto);
          this.$store.commit(
            "visualInspection/setRepairImageModal",
            dot.repairPhoto || null
          );
        }
      }
    },

    changeEventListener(type) {
      document
        .getElementById("interior")
        .removeEventListener("click", (e) => this.handleClick(e));
      if (type === "register") {
        document.getElementById("interior").addEventListener("click", (e) => {
          if (!this.form.status) {
            return;
          }
          this.setDot(e.offsetX, e.offsetY);
          setTimeout(() => {
            this.showModal = true;
          }, 200);
        });
      } else {
        document
          .getElementById("interior")
          .addEventListener("click", (e) => this.handleClick(e, "interior"));
      }
    },

    async getAutoParts() {
      const data = await CompanyCarsService.getAutoParts();
      this.damagesList = data.damage;
      this.autoParts = data.internalDamagePart;
    },
  },
};
</script>

<style>
.mycanvas {
  border: solid #e2e6ea 1px;
  cursor: pointer;
  border-radius: 5px;
}

.opacity {
  opacity: 0.2;
  cursor: not-allowed;
}
</style>
