<template>
  <b-form-group class="mb-2" label-class="pb-1">
    <template slot="label"> <b>Type: </b> </template>
    <Multiselect
      v-model="form.type"
      :options="taskTypes"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="false"
      :preserve-search="true"
      placeholder="Pick some option"
      track-by="label"
      label="label"
      :preselect-first="false"
      :taggable="true"
      @input="setTypes()"
    >
      <template slot="singleLabel" slot-scope="props">
        {{ props }}
      </template>
      <template slot="option" slot-scope="props">
        <b-badge
          pill
          :style="{
            backgroundColor: props.option.color,
          }"
          class=""
        >
          {{ props.option.label }}</b-badge
        >
      </template>

      <template slot="selection" slot-scope="{ values, isOpen }"
        ><span class="multiselect__single" v-if="values.length && !isOpen"
          >{{ values.length }} options selected</span
        ></template
      >
    </Multiselect>
  </b-form-group>
</template>

<script>
/* import { NOTE_TYPES as taskTypes } from "@/modules/superAdmin/components/notes/noteTypes.js";
 */ import { NOTE_TYPES as taskTypes } from "../components/noteTypes";

import Multiselect from "vue-multiselect";
import { SET_TASKS_FILTERS } from "../actions";
import { mapState } from "vuex";
export default {
  name: "TypeFilter",
  components: { Multiselect },
  computed: {
    ...mapState({
      filters: (state) => state.superAdminStore.tasks.tasks.filters,
    }),
  },
  data() {
    return {
      taskTypes,
      form: {
        type: [],
      },
    };
  },
  mounted() {
    this.getFilterFromStore();
  },
  methods: {
    getTypesInAString() {
      return this.form.type.map((type) => type.label).join(", ");
    },
    setTypes() {
      this.$store.commit(SET_TASKS_FILTERS, {
        fields: {
          type: this.getTypesInAString(),
        },
      });
    },
    getFilterFromStore() {
      if (this.filters.type) {
        this.form.type = [];
        taskTypes.forEach((type) => {
          if (this.filters.type.includes(type.label)) {
            this.form.type.push(type);
          }
        });
      }
    },
  },
};
</script>

<style></style>
