export const POST_POLICE_BOOK_NOTE = "POST_POLICE_BOOK_NOTE";
export const POST_POLICE_BOOK_NOTE_SUCCESS = "POST_POLICE_BOOK_NOTE_SUCCESS";
export const POST_POLICE_BOOK_NOTE_ERROR = "POST_POLICE_BOOK_NOTE_ERROR";

export const DELETE_POLICE_BOOK_NOTE = "DELETE_POLICE_BOOK_NOTE";
export const DELETE_POLICE_BOOK_NOTE_SUCCESS = "DELETE_POLICE_BOOK_NOTE_SUCCESS";
export const DELETE_POLICE_BOOK_NOTE_ERROR = "DELETE_POLICE_BOOK_NOTE_ERROR";

export const GET_POLICE_BOOK_NOTES = "GET_POLICE_BOOK_NOTES";
export const GET_POLICE_BOOK_NOTES_SUCCESS = "GET_POLICE_BOOK_NOTES_SUCCESS";
export const GET_POLICE_BOOK_NOTES_ERROR = "GET_POLICE_BOOK_NOTES_ERROR";
