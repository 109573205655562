export const GET_PERMISSIONS = "GET_PERMISSIONS";
export const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS";
export const GET_PERMISSIONS_ERROR = "GET_PERMISSIONS_ERROR";

export const GET_USER_PERMISSIONS = "GET_USER_PERMISSIONS";
export const GET_USER_PERMISSIONS_SUCCESS = "GET_USER_PERMISSIONS_SUCCESS";
export const GET_USER_PERMISSIONS_ERROR = "GET_USER_PERMISSIONS_ERROR";

export const POST_USER_PERMISSIONS = "POST_USER_PERMISSIONS";
export const POST_USER_PERMISSIONS_SUCCESS = "POST_USER_PERMISSIONS_SUCCESS";
export const POST_USER_PERMISSIONS_ERROR = "POST_USER_PERMISSIONS_ERROR";

export const GET_MENU_PERMISSIONS = "GET_MENU_PERMISSIONS";
export const GET_MENU_PERMISSIONS_SUCCESS = "GET_MENU_PERMISSIONS_SUCCESS";
export const GET_MENU_PERMISSIONS_ERROR = "GET_MENU_PERMISSIONS_ERROR";

export const GET_USER_MENU_PERMISSIONS = "GET_USER_MENU_PERMISSIONS";
export const GET_USER_MENU_PERMISSIONS_SUCCESS = "GET_USER_MENU_PERMISSIONS_SUCCESS";
export const GET_USER_MENU_PERMISSIONS_ERROR = "GET_USER_MENU_PERMISSIONS_ERROR";

export const POST_USER_MENU_PERMISSIONS = "POST_USER_MENU_PERMISSIONS";
export const POST_USER_MENU_PERMISSIONS_SUCCESS = "POST_USER_MENU_PERMISSIONS_SUCCESS";
export const POST_USER_MENU_PERMISSIONS_ERROR = "POST_USER_MENU_PERMISSIONS_ERROR";


