<template>
  <span class="d-flex justify-content-between">
    <b-col
      v-if="
        isEditing &&
        (transfer.status == 1 || transfer.status == 3) &&
        userHasPermission('transfer', 'write')
      "
      class="px-0"
      xs="12"
      sm="12"
      md="12"
      lg="12"
      xl="12"
    >
      <b-form-group label-size="sm">
        <input
          v-model="form.arrivalDate"
          type="datetime-local"
          class="form-control mx-2 mr-1"
          size="sm"
        />
      </b-form-group>
    </b-col>

    <b-col v-else>
      <i
        >{{ transfer.deliveryDate | formatDate }}
        <span v-if="transfer.arrivalUpdateUser">
          - {{ transfer.arrivalUpdateUser.firstName }}
          {{ transfer.arrivalUpdateUser.lastName }}
        </span></i
      >
    </b-col>

    <b-button
      v-if="isEditing"
      class="mb-4 ml-3"
      variant="danger"
      size=""
      @click="isEditing = false"
    >
      <b-icon icon="x" />
    </b-button>
    <b-button
      v-if="isEditing && userHasPermission('transfer', 'write')"
      class="mb-4"
      variant="success"
      size="sm"
      @click="saveArrivalDate(transfer)"
    >
      Save
    </b-button>
    <b-button
      v-if="
        !isEditing &&
        transfer.supplierCompany == null &&
        transfer.status != 4 &&
        transfer.status != 2 &&
        transfer.status != 0 &&
        transfer.customer == false &&
        userHasPermission('transfer', 'write')
      "
      class="mr-2"
      variant="primary"
      size="sm"
      @click="isEditing = true"
    >
      <b-icon icon="pencil" />
    </b-button>
  </span>
</template>

<script>
import { superAdminMixin } from "../../mixins";
export default {
  name: "EstimatedArrivalDate",
  props: {
    transfer: {
      type: Object,
      required: true,
    },
  },
  mixins: [superAdminMixin],
  data() {
    return {
      isEditing: false,
      form: { ...this.transfer },
    };
  },
  methods: {
    saveArrivalDate() {
      this.$emit("onSaveArrivalDate", this.form.arrivalDate);
      this.isEditing = false;
    },
  },
};
</script>

<style>
</style>