<template>
  <div>
    <div class="container-fluid mt-3">
      <b-row>
        <b-col>
          <h4 class="ml-3">Suppliers</h4>
        </b-col>
      </b-row>
      <div class="container">
        <!--     <TableBase
          :items="providers"
          :fields="fields"
          :options="options"
          :busy="providers.status === 'fetching' ? true : false"
        >
        </TableBase> -->
        <b-table
          show-empty
          bordered
          small
          stacked="md"
          head-variant="light"
          :items="providers.data || []"
          :fields="fields"
          tbody-tr-class=""
          :busy="providers.status === 'fetching' ? true : false"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" variant="yellow" />
            </div>
          </template>
          <template v-slot:cell(actions)="row">
            <b-icon
              v-b-tooltip.hover
              title="Details"
              icon="eye"
              class="h5 m-2"
              @click="editItem(row.item)"
            />
            <b-icon
              v-b-tooltip.hover
              title="Delete"
              :icon="row.item.status !== 4 ? 'toggle-off' : 'toggle-on'"
              class="h5 m-2"
              @click="
                row.item.status !== 4
                  ? activateItem(row.item)
                  : deleteItem(row.item)
              "
            />
          </template>
        </b-table>
      </div>

      <b-modal
        id="detailsModal"
        ref="detailsModal"
        size="lg"
        hide-footer
        body-class="pl-0 pr-0"
        ok-disabled
        @hidden="providerItem = {}"
      >
        <UserForm
          :provider-item="providerItem"
          :refresh-data="getData"
          :hide-modal="hideModal"
        />
      </b-modal>
    </div>
  </div>
</template>
<script>
/* import TableBase from "@/modules/shared/table.vue";
 */ import UserForm from "./Form";

import { GET_PROVIDERS, POST_PROVIDER } from "./actions";
import { mapState } from "vuex";

import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../../../../superAdmin/mixins";
export default {
  components: { UserForm },
  mixins: [generalMixin, superAdminMixin],
  computed: {
    ...mapState({
      providers: (state) => state.vehicle.workOrder.providers.providers,
    }),
    hasPermission() {
      return this.userHasPermission("providers", "write");
    },
    fields() {
      return [
        { key: "companyName", label: "Company name" },
        { key: "companyId", label: "ID" },
        {
          key: "actions",
          label: "Actions",
          class: "text-center",
          delete: this.hasPermission,
        },
      ];
    },
  },
  data() {
    return {
      options: {
        assign: false,
        rolFilter: false,
        add: false,
        fun: this.getData,
      },
      providerItem: {},
    };
  },
  mounted() {
    this.$store.commit(
      "setAddFunction",
      this.userHasPermission("providers", "write") ? this.showModal : null
    );
    this.getData();
  },
  methods: {
    async getData(filter = "") {
      const payload = {
        search: filter,
        isSupplier: 1,
      };
      this.$store
        .dispatch(GET_PROVIDERS, payload)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    async deleteItem(item) {
      const confirm = await this.showConfirmDeleteBox();
      if (!confirm) return;
      const payload = {
        ...item,
      };
      payload.status = 0;
      this.$store
        .dispatch(POST_PROVIDER, payload)
        .then(() => {
          this.getData();
        })
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    async activateItem(item) {
      const payload = {
        ...item,
      };
      payload.status = 1;
      this.$store
        .dispatch(POST_PROVIDER, payload)
        .then(() => {
          this.makeToast("Success", "Provider updated successfully", "success");
          this.getData();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Internal server error", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    showModal() {
      this.$bvModal.show("detailsModal");
    },
    editItem(item) {
      this.providerItem = item;
      this.showModal();
    },
    changePage(page, filter = "") {
      const payload = {
        search: filter,
        page: page,
      };
      this.$store
        .dispatch(GET_PROVIDERS, payload)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },

    hideModal() {
      this.$bvModal.hide("detailsModal");
    },
  },
};
</script>
