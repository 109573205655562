<template>
  <FilterTypeDate fromType="recallFrom" toType="recallTo" />
</template>

<script>
import FilterTypeDate from "./FilterTypeDate.vue";
export default {
  name: "RecallFilter",
  components: { FilterTypeDate },
};
</script>

<style>
</style>