<template>
  <b-form-group class="mt-1 mb-2" label-class="pb-1">
    <template slot="label">
      <b class="mb-0 pb-0">Board: </b>
    </template>
    <vue-single-select
      :classes="{
        input: 'form-control mt-0',
        dropdown: 'dropdown p-0',
      }"
      v-model="board"
      :options="boards"
      option-label="title"
      class=""
    >
    </vue-single-select>
  </b-form-group>
</template>

<script>
import VueSingleSelect from "vue-single-select";
import { mapState } from "vuex";
import { SET_TASKS_FILTERS } from "../actions";
export default {
  name: "BoardFilter",
  components: {
    VueSingleSelect,
  },
  computed: {
    ...mapState({
      tasks: (state) => state.superAdminStore.tasks.tasks.data,
      filters: (state) => state.superAdminStore.tasks.tasks.filters,
    }),
    boards() {
      return this.tasks.map((task) => {
        return {
          id: task.id,
          title: task.title,
        };
      });
    },
  },
  watch: {
    board() {
      this.setBoard();
    },
  },
  data() {
    return {
      board: null,
    };
  },
  methods: {
    setBoard() {
      this.$store.commit(SET_TASKS_FILTERS, {
        fields: {
          boardId: this.board?.id,
        },
      });
    },
    getFilterFromStore() {
      this.board = this.boards.find((board) => board.id === this.filters.boardId) || null;
    },
  },
};
</script>

<style></style>
