<template>
  <div>
    <b-row>
      <b-col cols="10">
        <span>
          Registration date:
          <i>
            {{ transfer.createdAt | formatDateTime }} -
            {{ transfer.user.firstName }} {{ transfer.user.lastName }}</i
          >
        </span>
        <br />
        <span v-if="transfer.status != 1 && transfer.updateUser != null">
          Last update date:
          <i
            >{{ transfer.updatedAt | formatDateTime }} -
            {{ transfer.updateUser.firstName }}
            {{ transfer.updateUser.lastName }}</i
          >
        </span>
        <br />
        <span v-if="transfer.deliveryDate" class="d-flex">
          Estimated delivery date:
          <EstimatedArrivalDate
            :transfer="transfer"
            @onSaveArrivalDate="updateEstimatedArrivalDate($event)"
          />
        </span>
        <span v-if="transfer.arrivalDate">
          Arrival date:
          <i>
            {{ transfer.arrivalDate | formatDate }}
          </i>
        </span>
      </b-col>
      <b-col>
        <div class="text-right">
          <span v-if="transfer.status === 1" class="dot dot-orange mr-3" />
          <span v-if="transfer.status === 2" class="dot dot-success mr-3" />
          <span v-if="transfer.status === 0" class="dot dot-danger mr-3" />
          <span v-if="transfer.status === 3" class="dot dot-primary mr-3" />
          <span v-if="transfer.status === 4" class="dot dot-success mr-3" />

          <b-form-group
            v-if="
              transfer.status &&
              transfer.status != 2 &&
              transfer.status != 4 &&
              transfer.exportLoadsId == null &&
              userHasPermission('transfer', 'write')
            "
            class="mr-n3"
          >
            <b-form-checkbox
              :checked="true"
              switch
              @change="disableTransfer(transfer)"
            />
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <p class="text-justify mt-3">
      <span>
        Origin: <i>{{ transfer.origin }} </i>
      </span>
      <br />
      <span>
        Destination: <i>{{ transfer.destination }} </i>
      </span>
    </p>
    <p class="text-justify">
      Driver:
      <i v-if="transfer.driver != null">
        {{ transfer.driver.firstName }} {{ transfer.driver.lastName }}
      </i>
      <i v-if="transfer.supplierCompany != null">{{
        transfer.supplierCompany.companyName
      }}</i>
      <i v-if="transfer.customer"> Customer </i>
      <DriverSelect
        v-if="isDriverEditing"
        @select-driver="onSelectDriver($event)"
      />

      <b-button
        v-if="isDriverEditing"
        class="mr-2"
        variant="danger"
        size="sm"
        @click="isDriverEditing = false"
      >
        <b-icon icon="x" />
      </b-button>
      <b-button
        v-if="isDriverEditing"
        class="mr-2"
        variant="success"
        size="sm"
        @click="updateDriver(transfer)"
      >
        Save
      </b-button>
      <b-button
        v-if="
          !isDriverEditing &&
          transfer.status != 4 &&
          transfer.status != 2 &&
          transfer.status != 0 &&
          userHasPermission('transfer', 'write')
        "
        class="mr-2"
        variant="primary"
        size="sm"
        @click="isDriverEditing = true"
      >
        <b-icon icon="pencil" />
      </b-button>
    </p>
    <p class="text-justify">
      <span v-if="transfer.note">
        Note: <i>{{ transfer.note }} </i>
      </span>
    </p>
    <p class="text-justify">
      Cost: <i v-if="!isCostEditing">{{ transfer.cost | currency }} </i>
      <b-col v-else class="px-0" xs="12" sm="12" md="12" lg="12" xl="4">
        <b-form-group label-size="sm">
          <template slot="label"> </template>
          <Money
            v-model="form.cost"
            class="form-control text-center"
            v-bind="money"
          />
        </b-form-group>
      </b-col>
      <b-button
        v-if="isCostEditing"
        class="mr-2"
        variant="danger"
        size="sm"
        @click="isCostEditing = false"
      >
        <b-icon icon="x" />
      </b-button>
      <b-button
        v-if="isCostEditing && userHasPermission('transfer', 'write')"
        class="mr-2"
        variant="success"
        size="sm"
        @click="updateCost(transfer)"
      >
        Save
      </b-button>
      <b-button
        v-if="
          !isCostEditing &&
          transfer.status == 1 &&
          userHasPermission('transfer', 'write')
        "
        class="mr-2"
        variant="primary"
        size="sm"
        @click="isCostEditing = true"
      >
        <b-icon icon="pencil" />
      </b-button>
    </p>

    <p>
      <b-col
        v-if="
          isEditing &&
          (transfer.status == 1 || transfer.status == 3) &&
          userHasPermission('transfer', 'write')
        "
        class="px-0"
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="4"
      >
        <b-form-group label-size="sm">
          <template slot="label">Arrived date </template>
          <input
            v-model="form.arrivalDate"
            type="datetime-local"
            class="form-control"
          />
        </b-form-group>
      </b-col>

      <b-button
        v-if="isEditing"
        class="mr-2"
        variant="danger"
        size="sm"
        @click="isEditing = false"
      >
        <b-icon icon="x" />
      </b-button>
      <b-button
        v-if="isEditing && userHasPermission('transfer', 'write')"
        class="mr-2"
        variant="success"
        size="sm"
        :disabled="isLoadingChangeStatus"
        @click="markTransferAsComplete(transfer)"
      >
        Save
      </b-button>

      <b-button
        v-if="
          !isEditing &&
          (transfer.status == 1 || transfer.status == 3) &&
          userHasPermission('transfer', 'write')
        "
        class="mr-2"
        variant="success"
        size="sm"
        @click="isEditing = true"
        v-b-tooltip.hover
        title="Mark as completed"
      >
        <font-awesome-icon
          size="sm"
          class="cursor-pointer h4 mb-1"
          :icon="['far', 'truck-container']"
        />
      </b-button>
      <span class="text-right">
        <b-button
          variant="yellow"
          size="sm"
          @click="generateTransferXLS(transfer)"
          class="float-right"
          v-b-tooltip.hover
          title="Download XLS"
        >
          <b-icon icon="file-earmark-arrow-down" variant="white"></b-icon>
        </b-button>
        <b-button
          v-if="userHasPermission('transfer', 'write')"
          variant="success"
          size="sm"
          @click="openEmailBodyModal()"
          class="float-right mx-2"
          v-b-tooltip.hover
          title="Send transfer XLS by email"
        >
          <b-icon icon="envelope" variant="white"></b-icon>
        </b-button>
      </span>
    </p>

    <EmailBody @submit="sendTransferXLSByEmail" :id="transfer.id" />
    <TaskUpdateStatusModal
      :tasks="tasks"
      :id="transfer.id"
      @update-task="refreshAfterUpdateTask"
    />
  </div>
</template>

<script>
import {
  PUT_TRANSFER,
  GET_TRANSFER_FILE,
  GET_TRANSFER_EMAIL,
  POST_TRANSFER,
} from "./actions";
import DriverSelect from "./DriverSelect";
import { Money } from "v-money";
import EmailBody from "@/modules/shared/EmailBody";
import { superAdminMixin } from "../../mixins";
import EstimatedArrivalDate from "./EstimatedArrivalDate";
import TaskUpdateStatusModal from "../../tasks/components/TaskUpdateStatusModal.vue";
import { mapState } from "vuex";
export default {
  props: ["transfer", "refreshData"],
  components: {
    DriverSelect,
    Money,
    EmailBody,
    EstimatedArrivalDate,
    TaskUpdateStatusModal,
  },
  computed: {
    ...mapState({
      isLoadingChangeStatus: (state) =>
        state.superAdminStore.componentStore.transfer.transfer.status ===
        "fetching",
    }),
  },
  mixins: [superAdminMixin],
  data() {
    return {
      form: {
        arrivalDate: null,
        cost: this.transfer.cost,
      },
      isEditing: false,
      isDriverEditing: false,
      isCostEditing: false,
      driver: null,
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "USD$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      tasks: [],
    };
  },
  methods: {
    markTransferAsComplete(transfer) {
      const payload = {
        id: transfer.id,
        status: 2,
        arrivalDate: this.form.arrivalDate,
      };
      if (transfer.status == 3) {
        payload.status = 4;
      }
      this.$store
        .dispatch(PUT_TRANSFER, payload)
        .then((response) => {
          if (response.task.length > 0) {
            this.tasks = response.task;
            this.$root.$bvModal.show(
              "task-update-status-modal" + this.transfer.id
            );
          } else {
            this.refreshData();
          }
          this.toastMessage(
            "Transfer mark as completed successfully",
            "Success",
            "success"
          );
          /*           this.refreshData();
           */
        })
        .catch(() => {
          this.toastMessage(
            "Transfer not mark as completed",
            "Warning",
            "warning"
          );
        });
    },
    setArrivalDateNow() {
      const now = new Date();
      now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
      this.form.arrivalDate = now.toISOString().slice(0, -1);
    },
    generateTransferXLS(transfer) {
      this.$store
        .dispatch(GET_TRANSFER_FILE, { transferId: transfer.id })
        .then((response) => {
          const blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = transfer.id + ".xls" || "ficher.xls";
          link.click();
        })
        .catch((error) => {
          console.log(error);
          this.$root.$bvToast.toast("Could not get XLS", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    disableTransfer(transfer) {
      const payload = {
        id: transfer.id,
        status: 0,
      };
      this.$store
        .dispatch(PUT_TRANSFER, payload)
        .then(() => {
          this.toastMessage(
            "Transfer disabled successfully",
            "Success",
            "success"
          );
          this.refreshData();
        })
        .catch(() => {
          this.toastMessage("Transfer not disabled", "Warning", "warning");
        });
    },
    sendTransferXLSByEmail(body) {
      this.$store
        .dispatch(GET_TRANSFER_EMAIL, {
          transferId: this.transfer.id,
          body: body,
        })
        .then(() => {
          this.$root.$bvToast.toast("Transfer XLS sent", {
            title: "Success",
            variant: "success",
          });
        })
        .catch((error) => {
          console.log(error);
          this.$root.$bvToast.toast("Transfer could not be send", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    onSelectDriver(driver) {
      this.driver = driver;
    },
    updateDriver() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_TRANSFER, payload)
        .then(() => {
          this.toastMessage(
            "Transfer driver updated successfully",
            "Success",
            "success"
          );
          /*           this.refreshData();
           */
        })
        .catch(() => {
          this.toastMessage(
            "Transfer driver not updated",
            "Warning",
            "warning"
          );
        });
    },

    formatPayload() {
      const payload = {
        id: this.transfer.id,
      };
      if (this.driver.type == "provider") {
        payload.supplierCompanyId = this.driver.id;
      }

      if (this.driver.type == "driver") {
        payload.driverId = this.driver.id;
      }
      if (this.driver.type == "customer") {
        payload.customer = true;
      }
      return payload;
    },
    updateCost() {
      const payload = {
        id: this.transfer.id,
        cost: this.form.cost,
      };

      this.$store
        .dispatch(POST_TRANSFER, payload)
        .then(() => {
          this.toastMessage(
            "Transfer cost updated successfully",
            "Success",
            "success"
          );
          this.refreshData();
        })
        .catch(() => {
          this.toastMessage("Transfer cost not updated", "Warning", "warning");
        });
    },
    updateEstimatedArrivalDate(estimatedArrivalDate) {
      const payload = {
        id: this.transfer.id,
        deliveryDate: estimatedArrivalDate,
      };

      this.$store
        .dispatch(POST_TRANSFER, payload)
        .then(() => {
          this.toastMessage(
            "Transfer estimated arrival date updated successfully",
            "Success",
            "success"
          );
          this.refreshData();
        })
        .catch(() => {
          this.toastMessage(
            "Transfer estimated arrival date not updated",
            "Warning",
            "warning"
          );
        });
    },
    openEmailBodyModal() {
      this.$root.$bvModal.show("email-body-modal" + this.transfer.id);
    },
    openTaskStatusModal() {
      this.$root.$bvModal.show("task-update-status-modal" + this.transfer.id);
    },
    refreshAfterUpdateTask() {
      this.refreshData();
    },
  },

  mounted() {
    this.setArrivalDateNow();
  },
};
</script>

<style></style>
