<template>
  <div>
    <h2>Sold to information</h2>
    <b-row>
      <b-col xs="12" sm="12" md="8" lg="8" xl="8" cols="12"> </b-col>
      <b-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> Sale date</template>
          <b-form-input v-model="form.registerDate" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> Company/Last</template>
          <b-form-input :value="form.company.companyName" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> First</template>
          <b-form-input v-model="form.first" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> Middle</template>
          <b-form-input v-model="form.middle" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> Address</template>
          <b-form-input v-model="form.company.street" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> Zip</template>
          <b-form-input v-model="form.company.zip" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> City</template>
          <b-form-input v-model="form.company.city" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> State</template>
          <b-form-input v-model="form.company.state" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> County</template>
          <b-form-input v-model="form.county" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> County code</template>
          <b-form-input v-model="form.countyCode" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> Component sold</template>
          <b-form-input value="Entire vehicle" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> Tittle number</template>
          <b-form-input v-model="form.titleMsoOut" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> Status</template>
          <b-form-input v-model="form.titleMsoStatusOut" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> Origin</template>
          <b-form-input v-model="form.titleOriginOut" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> NMVTIS Status</template>
          <b-form-input v-model="form.nmvtisStatusOut" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> Miles at sale</template>
          <b-form-input v-model="form.milesOut" size="sm" readonly />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: ["soldInformation"],
  name: "SoldInformation",
  data() {
    return {
      form: {
        ...this.soldInformation,
      },
    };
  },
};
</script>

<style></style>
