import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    customReports: {
      status: null,
      data: [],
      error: null,
    },
    customReport: {
      status: null,
      data: {},
      error: null,
    },

    columns: {
      status: null,
      data: [],
      error: null,
    },
    customReportInfo: {
      status: null,
      data: [],
      error: null,
      filters: {
        reportId: null,
        resourcesPerPage: 25,
        marketPlace: null,
        stockDaysRange: null,
        make: null,
        model: null,
        yearRange: null,
        miles: null,
        color: null,
        user: null,
        buyFrom: null,
        reservesCompanyName: null,
        soldInfo: null,
        location: null,
        releaseDate: null,
        firstName: null,
        lastName: null,
        reservesPriceRange: null,
        status: null,
      }
    },
    customReportExcel: {
      status: null,
      data: null,
      error: null,
    },
    filtersList: {
      status: null,
      data: [],
      error: null,
    },
    columnsSelected: [],
  };
}
const actions = {
  [constants.GET_CUSTOM_REPORTS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_CUSTOM_REPORTS);

      const response = await axios.get("report/getDynamicReport", {
        params: payload,
      });
      commit(constants.GET_CUSTOM_REPORTS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_CUSTOM_REPORTS_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_COLUMNS_REPORTS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_COLUMNS_REPORTS);

      const response = await axios.get("report/getReportColumns", {
        params: payload,
      });
      commit(constants.GET_COLUMNS_REPORTS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_COLUMNS_REPORTS_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_CUSTOM_REPORT]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_CUSTOM_REPORT);

      const response = await axios.post("report/createDynamicReport", payload);
      commit(constants.POST_CUSTOM_REPORT_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_CUSTOM_REPORT_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_CUSTOM_REPO_INFO]: async ({ commit, state }) => {
    try {
      commit(constants.GET_CUSTOM_REPO_INFO);
      const payload = Object.entries(state.customReportInfo.filters).reduce(
        (a, [k, v]) => (v === null || v === "" ? a : { ...a, [k]: v }),
        {}
      );
      const response = await axios.get("report/getVehiclesByReport", {
        params: payload,
      });
      commit(constants.GET_CUSTOM_REPO_INFO_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_CUSTOM_REPO_INFO_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_CUSTOM_REPORT_EXCEL]: async ({ commit, state }) => {
    try {
      commit(constants.GET_CUSTOM_REPORT_EXCEL);
      const payload = Object.entries(state.customReportInfo.filters).reduce(
        (a, [k, v]) => (v === null || v === "" ? a : { ...a, [k]: v }),
        {}
      );
      const response = await axios.get("report/downloadXls", {
        params: payload,
        responseType: "blob",
      });
      commit(constants.GET_CUSTOM_REPORT_EXCEL_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_CUSTOM_REPORT_EXCEL_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_REPORT_FILTERS_LIST]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_REPORT_FILTERS_LIST);
      const response = await axios.get("report/getFiltersByReport", {
        params: payload,
      });
      commit(constants.GET_REPORT_FILTERS_LIST_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_REPORT_FILTERS_LIST_ERROR, error);
      throw error.response;
    }
  }
}
const mutations = {
  [constants.GET_CUSTOM_REPORTS]: (state) => {
    state.customReports.status = "fetching";
  },
  [constants.GET_CUSTOM_REPORTS_SUCCESS]: (state, response) => {
    state.customReports.status = "success";
    state.customReports.data = response;
  },
  [constants.GET_CUSTOM_REPORTS_ERROR]: (state, error) => {
    state.customReports.status = "error";
    state.customReports.error = error;
  },
  [constants.GET_COLUMNS_REPORTS]: (state) => {
    state.columns.status = "fetching";
  },
  [constants.GET_COLUMNS_REPORTS_SUCCESS]: (state, response) => {
    state.columns.status = "success";
    state.columns.data = response.data;
  },
  [constants.GET_COLUMNS_REPORTS_ERROR]: (state, error) => {
    state.columns.status = "error";
    state.columns.error = error;
  },
  [constants.POST_CUSTOM_REPORT]: (state) => {
    state.customReport.status = "fetching";
  },
  [constants.POST_CUSTOM_REPORT_SUCCESS]: (state, response) => {
    state.customReport.status = "success";
    state.customReport.data = response;
  },
  [constants.POST_CUSTOM_REPORT_ERROR]: (state, error) => {
    state.customReport.status = "error";
    state.customReport.error = error;
  },
  [constants.GET_CUSTOM_REPO_INFO]: (state) => {
    state.customReportInfo.status = "fetching";
  },
  [constants.GET_CUSTOM_REPO_INFO_SUCCESS]: (state, response) => {
    state.customReportInfo.status = "success";
    state.customReportInfo.data = response;
  },
  [constants.GET_CUSTOM_REPO_INFO_ERROR]: (state, error) => {
    state.customReportInfo.status = "error";
    state.customReportInfo.error = error;
  },
  [constants.GET_CUSTOM_REPORT_EXCEL]: (state) => {
    state.customReportExcel.status = "fetching";
  },
  [constants.GET_CUSTOM_REPORT_EXCEL_SUCCESS]: (state, response) => {
    state.customReportExcel.status = "success";
    state.customReportExcel.data = response.data;
  },
  [constants.GET_CUSTOM_REPORT_EXCEL_ERROR]: (state, error) => {
    state.customReportExcel.status = "error";
    state.customReportExcel.error = error;
  },
  [constants.SET_REPORT_FILTERS]: (state, filters) => {
    if (filters.reset) {
      state.customReportInfo.filters = {
        search: null,
      };
    }
    if (filters.fields) {
      state.customReportInfo.filters = {
        ...state.customReportInfo.filters,
        ...filters.fields,
      };
    }
  },
  [constants.GET_REPORT_FILTERS_LIST]: (state) => {
    state.filtersList.status = "fetching";
  },
  [constants.GET_REPORT_FILTERS_LIST_SUCCESS]: (state, response) => {
    state.filtersList.status = "success";
    state.filtersList.data = response;
  },
  [constants.GET_REPORT_FILTERS_LIST_ERROR]: (state, error) => {
    state.filtersList.status = "error";
    state.filtersList.error = error;
  },

  [constants.SET_COLUMNS_SELECTED]: (state, columns) => {
    state.columnsSelected = columns;
  }
}

export default {
  state: getDefaultState(),
  actions,
  mutations,
};



