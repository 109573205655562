<template>
  <b-form-group label-size="md">
    <template slot="label"> Kilometers </template>
    <b-input-group>
      <b-form-input
        v-model="form.kms"
        type="number"
        min="0"
        size="sm"
        :disabled="!isKilometersEditing"
      />

      <template #append>
        <div class="mx-1">
          <b-button
            v-if="isKilometersEditing"
            class="mr-2"
            variant="warning"
            size="sm"
            @click="isKilometersEditing = false"
          >
            <b-icon icon="x" />
          </b-button>
          <b-button
            v-if="isKilometersEditing"
            class="mr-2"
            variant="success"
            size="sm"
            @click="updateKilometers()"
          >
            Save
          </b-button>
          <b-button
            v-if="!isKilometersEditing"
            class="mr-2"
            variant="light"
            size="sm"
            @click="isKilometersEditing = true"
          >
            <b-icon icon="pencil" />
          </b-button>
        </div>
      </template>
    </b-input-group>
  </b-form-group>
</template>

<script>
import { superAdminMixin } from "../../mixins";
export default {
  name: "KilometersEditionField",
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
  },
  mixins: [superAdminMixin],
  data() {
    return {
      isKilometersEditing: false,
      form: {
        kms: this.vehicle.vehicleInformation.kms,
      },
    };
  },
  methods: {
    updateKilometers() {
      const payload = {
        vehicleId: this.vehicle.id,
        kms: this.form.kms,
      };
      this.$store.dispatch("PUT_KILOMETERS", payload).then(() => {
        this.isKilometersEditing = false;
        this.toastMessage("Kilometers updated", "Success", "success");
      });
    },
  },
};
</script>

<style>
</style>