<template>
  <div>
    <div class="container-fluid mt-3">
      <b-row class="">
        <!--  <b-col>
          <h4 class="ml-3">Fees</h4>
        </b-col> -->
      </b-row>
      <div>
        <TableBase
          :items="fees.data"
          :fields="fields"
          :options="options"
          :busy="fees.status === 'fetching' ? true : false"
        >
          <template v-slot:rightSide>
            <div>
              <!--   <b-button
                v-if="userHasPermission('fees', 'write')"
                variant="outline-purple-yellow"
                class="align-bottom"
                @click="showModal()"
              >
                <b-iconstack class="m-1 text-center" font-scale="1">
                  <b-icon icon="cash" font-scale="1.1" />
                  <b-icon
                    icon="plus-circle-fill"
                    shift-h="25"
                    shift-v="4"
                    font-scale="0.55"
                  />
                </b-iconstack>
                <span class="" />
              </b-button> -->
            </div>
          </template>
        </TableBase>
      </div>

      <b-modal
        id="detailsModal"
        ref="detailsModal"
        size="lg"
        hide-footer
        body-class=""
        ok-disabled
        @hidden="feeItem = {}"
      >
        <FeeRegistrationForm
          :feeItem="feeItem"
          :refreshData="getData"
          :hideModal="hideModal"
        />
      </b-modal>
    </div>
  </div>
</template>
<script>
import TableBase from "@/modules/shared/table.vue";
import FeeRegistrationForm from "./FeeRegistrationForm.vue";

import { GET_FEES, POST_FEE } from "./actions";
import { mapState } from "vuex";

import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../mixins";
export default {
  name: "FeesTable",
  components: { TableBase, FeeRegistrationForm },
  mixins: [generalMixin, superAdminMixin],
  computed: {
    ...mapState({
      fees: (state) => state.superAdminStore.fees.fees,
    }),
    hasPermission() {
      return this.userHasPermission("fees", "write");
    },
    fields() {
      return [
        { key: "name", label: "Name" },
        {
          key: "amount",
          label: "Amount",
          class: "text-center",
          formatter: (amount) => this.$options.filters.currency(amount),
        },
        { key: "marketPlace", label: "Market place", class: "text-center" },
        {
          key: "actions",
          label: "Actions",
          note: false,
          details: true,
          validation: false,
          delete: this.hasPermission,
        },
      ];
    },
  },
  data() {
    return {
      options: {
        assign: false,
        rolFilter: false,
        add: false,
        fun: this.getData,
      },
      feeItem: {},
    };
  },
  mounted() {
    this.$store.commit(
      "setAddFunction",
      this.userHasPermission("fees", "write") ? this.showModal : null
    );

    this.getData();
  },
  methods: {
    getData(filter) {
      this.$store
        .dispatch(GET_FEES, { marketPlace: "QAS", search: filter })
        .then(() => {})
        .catch(() => {});
    },
    async deleteItem(item) {
      const confirm = await this.showConfirmDeleteBox();
      if (!confirm) return;
      const payload = { ...item };
      payload.status = 0;
      this.$store
        .dispatch(POST_FEE, payload)
        .then(() => {
          this.getData();
          this.makeToast("Success", "Fee updated successfully", "success");
        })
        .catch(() => {
          this.$root.$bvToast.toast("Fee could not be disabled", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    activateItem(item) {
      const payload = {
        id: item.id,
        status: 1,
      };
      this.$store
        .dispatch(POST_FEE, payload)
        .then(() => {
          this.makeToast("Success", "Fee updated successfully", "success");
          this.getData();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Fee could not be activated", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    showModal() {
      this.$bvModal.show("detailsModal");
    },
    editItem(item) {
      this.feeItem = item;
      this.$bvModal.show("detailsModal");
    },
    changePage(page, filter = "") {
      const payload = {
        search: filter,
        page: page,
      };
      this.$store
        .dispatch(GET_FEES, payload)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },

    hideModal() {
      this.$bvModal.hide("detailsModal");
    },
  },
};
</script>
