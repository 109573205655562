<template>
  <!-- Card stats -->
  <b-row>
    <b-col xl="3" md="6">
      <stats-card class="mb-4">
        <template slot>
          <div class="d-flex justify-content-between">
            <h5 class="card-title text-uppercase text-muted mb-0">Financial</h5>
            <div class="text-center">
              <i
                v-b-tooltip.click="{ variant: 'white' }"
                title="Warning within 1-3 days, Alert more than 3 days"
              >
                <font-awesome-icon
                  class="cursor-pointer text-blue"
                  :icon="['far', 'circle-question']"
                />
              </i>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <b-row>
              <b-col
                cols="auto"
                @click="applyFilter(financialFilterWarning)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-yellow"
                  :icon="['far', 'triangle-exclamation']"
                  size="2xl"
                />

                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">
                  {{ accounting != null ? accounting.creditRequestWarning : 0 }}
                </span>
              </b-col>
              <b-col
                cols="auto"
                @click="applyFilter(financialFilterAlert)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-red"
                  :icon="['far', 'octagon-exclamation']"
                  size="2xl"
                />
                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">{{
                  accounting != null ? accounting.creditRequestAlert : 0
                }}</span>
              </b-col>
            </b-row>
          </div>
        </template>
      </stats-card>
    </b-col>
    <b-col xl="3" md="6"> </b-col>
    <b-col xl="3" md="6"> </b-col>
    <b-col xl="3" md="6"> </b-col>
  </b-row>
</template>

<script>
import { mapState } from "vuex";
import { SET_VEHICLES_INFO_FILTERS } from "@/modules/superAdmin/actions";
import { superAdminMixin } from "../../modules/superAdmin/mixins";
import { GET_BOARD_ACCOUNTING } from "./actions";
export default {
  name: "BoardCardsAccounting",
  mixins: [superAdminMixin],
  computed: {
    ...mapState({
      accounting: (state) => state.layout.boards.accounting?.data,
      accountingStatus: (state) => state.layout.boards.accounting?.status,
    }),
  },
  data() {
    return {
      financialFilterWarning: {
        financingWarning: 1,
      },
      financialFilterAlert: {
        financingAlert: 1,
      },
      saaqFilterWarning: {
        stockDateFrom: this.getDateXDaysAgo(7),
        stockDateTo: this.getDateXDaysAgo(3),
        registration: 0,
      },
      saaqFilterAlert: {
        registration: 0,
        stockDateTo: this.getDateXDaysAgo(7),
      },
      liensFilterWarning: {
        liensFrom: this.getDateXDaysAgo(5),
        liensTo: this.getDateXDaysAgo(3),
        status: "InStock",
        lien: 2,
      },
      liensFilterAlert: {
        liensTo: this.getDateXDaysAgo(5),
        status: "InStock",
        lien: 2,
      },
      odoFilter: {
        registration: 2,
        registrationDate: this.getDateXDaysAgo(7),
      },

      problemFilter: {
        registration: 4,
        registrationDate: this.getDateXDaysAgo(7),
      },
    };
  },
  mounted() {
    this.getLogisticInformation();
  },
  methods: {
    applyFilter(filters) {
      this.resetFilters(); //Method from superAdminMixin
      this.$store.commit(SET_VEHICLES_INFO_FILTERS, {
        fields: { ...filters },
      });
      this.getVehiclesInfo(); //Method from superAdminMixin
    },
    getDateXDaysAgo(numOfDays, date = new Date()) {
      const daysAgo = new Date(date);
      daysAgo.setDate(date.getDate() - numOfDays);
      return this.formatDateString(daysAgo.toString());
    },
    getLogisticInformation() {
      this.$store.dispatch(GET_BOARD_ACCOUNTING).then(() => {});
    },
    formatDateString(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const shortDate = `${year}-${month}-${day}`;
      return shortDate;
    },
  },
};
</script>

<style>
</style>
