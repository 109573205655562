<template>
  <div>
    <h2>Bought information</h2>
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
        <h3>Quebec auto supplier</h3>
        200 4600 boulevard Grande-Allée, Boisbriand, QC J7H 1S7
        <br />
        <br />
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> Company/Last</template>
          <b-form-input v-model="form.companyName" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> First</template>
          <b-form-input v-model="form.stockNumber" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> Middle</template>
          <b-form-input v-model="form.vinNumber" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> Address</template>
          <b-form-input v-model="form.street" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> Zip</template>
          <b-form-input v-model="form.zip" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> City</template>
          <b-form-input v-model="form.city" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> State</template>
          <b-form-input v-model="form.state" size="sm" readonly />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: ["boughtInformation"],
  name: "BoughtInformation",
  data() {
    return {
      form: { ...this.boughtInformation },
    };
  },
};
</script>

<style></style>
