import Vue from "vue";
import Vuex from "vuex";
import auth from "@/modules/auth/store";
import superAdminStore from "@/modules/superAdmin/store";
import adminStore from "@/modules/admin/store";
import buyer from "@/modules/buyer/store.js";
import validation from "@/modules/shared/validation/store";
import home from "@/modules/home/store";
import vehicle from "@/modules/vehicle/store";
import general from "@/modules/store";
import masterUser from "@/modules/masterUser/store";
import pricing from "@/modules/pricing/store";
import layout from "@/layouts/store"
import publicStore from "@/modules/public/store";
import createPersistedState from "vuex-persistedstate";
import client from "@/modules/client/store";
import simpleView from "@/views/Pages/simple-vehicle-view/store";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    superAdminStore,
    buyer,
    auth,
    adminStore,
    validation,
    home,
    vehicle,
    general,
    masterUser,
    pricing,
    layout,
    publicStore,
    client,
    simpleView
  },
  plugins: [
    createPersistedState({
      paths: ["adminStore.vehicles.listStore", "home"],
    }),
  ],
  strict: true,
});
