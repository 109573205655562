<template>
  <div>
    <b-container fluid v-if="report" class="mt-3">
      <div class="d-flex justify-content-between align-items-center">
        <h2>
          <font-awesome-icon
            class="mr-2"
            :icon="['far', report.isPublic == 1 ? 'users' : 'user']"
            size="lg"
            :title="report.isPublic == 1 ? 'Public report' : 'Private report'"
          />
          {{ report.name || "Report name" }}
        </h2>
        <!-- edit report button -->
        <div>
          <b-button variant="yellow" @click="clearFilters" class="mb-2"
            >Clear filters
          </b-button>
          <b-button
            variant="outline-primary"
            @click="openReportFormModal"
            class="mb-2"
            >Edit
          </b-button>
          <b-button
            :disabled="saveFilterIsLoading"
            variant="primary"
            @click="saveFilters"
            class="mb-2"
          >
            <!-- spinner -->
            <b-spinner
              v-if="saveFilterIsLoading"
              class="align-middle"
              variant="white"
              small
            />
            <font-awesome-icon
              v-else
              class="cursor-pointer"
              :icon="['far', 'floppy-disk']"
            />
            {{ saveFilterIsLoading ? "Saving..." : "  Save filters" }}
          </b-button>
          <b-button
            variant="outline-success"
            @click="downloadReport"
            class="mb-2"
            :disabled="reportExcelStatus == 'fetching'"
          >
            <!-- spinner -->
            <b-spinner
              v-if="reportExcelStatus == 'fetching'"
              class="align-middle"
              variant="success"
              small
            />

            <font-awesome-icon
              v-else
              class="cursor-pointer"
              :icon="['far', 'file-excel']"
            />
            {{
              reportExcelStatus == "fetching" ? "Downloading..." : "Download"
            }}
          </b-button>
        </div>
      </div>

      <b-table
        v-if="fields.length > 0"
        show-empty
        bordered
        small
        stacked="md"
        responsive
        head-variant="light"
        :items="customReportInfo.data || []"
        :fields="fields"
        :busy="isLoading"
        :empty-text="
          fields.length === 0 ? 'No fields selected' : 'No matching records'
        "
      >
        <template #head()="scope">
          <div class="table-head-menu">
            <span class=""> {{ scope.label }} </span>
            <br />
            <div class="text-center">
              <FilterColumn
                ref="filter-component"
                :type="scope.field.filterType"
                v-if="scope.field.hasFilter == 1"
                @applyFilters="applyFilters"
              />
              <div v-else>
                <br />
                <span class=""></span>
              </div>
            </div>
          </div>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" variant="yellow" />
          </div>
        </template>

        <template v-slot:cell(stockNumber)="{ item }">
          {{ item.stockNumber }}
        </template>
        <template v-slot:cell(vinNumber)="{ item }">
          {{ item.vinNumber }}
        </template>
        <template v-slot:cell(stockDays)="{ item }">
          {{ item.stockDays }}
        </template>
        <template v-slot:cell(make)="{ item }">
          {{ item.make }}
        </template>
        <template v-slot:cell(model)="{ item }">
          {{ item.model }}
        </template>
        <template v-slot:cell(year)="{ item }">
          {{ item.year }}
        </template>
        <template v-slot:cell(miles)="{ item }">
          {{ item.miles | formatNumber }}
        </template>
        <template v-slot:cell(color)="{ item }">
          {{ item.color }}
        </template>
        <template v-slot:cell(user)="{ item }">
          {{ item.user }}
        </template>
        <template v-slot:cell(buyFrom)="{ item }">
          {{ item.buyFrom }}
        </template>
        <template v-slot:cell(reserves_company_name)="{ item }">
          {{ item.reserves_company_name }}
        </template>
        <template v-slot:cell(reserves_typeCurrency)="{ item }">
          {{ item.reserves_typeCurrency }}
        </template>
        <template v-slot:cell(reserves_price)="{ item }">
          {{ item.reservesPrice | currency }}
        </template>
        <template v-slot:cell(cost)="{ item }">
          {{ item.cost | currency }}
        </template>
        <template v-slot:cell(costUsd)="{ item }">
          {{ item.costUsd | currency }}
        </template>
        <template v-slot:cell(fx)="{ item }">
          {{ item.fx }}
        </template>
        <template v-slot:cell(soldInfo)="{ item }">
          {{ item.soldInfo }}
        </template>
        <template v-slot:cell(location)="{ item }">
          {{ item.location }}
        </template>
        <template v-slot:cell(releaseDate)="{ item }">
          {{ item.releaseDate | formatDate }}
        </template>
        <template v-slot:cell(marketPlace)="{ item }">
          {{ item.marketPlace }}
        </template>
        <template v-slot:cell(salePriceCad)="{ item }">
          {{ item.salePriceCad | currency }}
        </template>
        <template v-slot:cell(salePriceUsd)="{ item }">
          {{ item.salePriceUsd | currency }}
        </template>
        <template v-slot:cell(totalCad)="{ item }">
          {{ item.totalCad | currency }}
        </template>
        <template v-slot:cell(totalUsd)="{ item }">
          {{ item.totalUsd | currency }}
        </template>
        <template v-slot:cell(pnlCad)="{ item }">
          {{ item.pnlCad | currency }}
        </template>
        <template v-slot:cell(pnlUsd)="{ item }">
          {{ item.pnlUsd | currency }}
        </template>
        <template v-slot:cell(status)="{ item }">
          {{ item.status }}
        </template>
        <template v-slot:cell(kms)="{ item }">
          {{ item.kms | formatNumber }}
        </template>
        <template v-slot:cell(reserve)="{ item }">
          {{ item.reserve }}
        </template>
      </b-table>

      <b-pagination
        v-if="!isLoading"
        :value="customReportInfo ? customReportInfo.currentPage : 1"
        :total-rows="customReportInfo ? customReportInfo.total : 0"
        :per-page="customReportInfo ? customReportInfo.perPage : 25"
        class="mb-2"
        align="end"
        @change="changePage($event)"
      />
    </b-container>

    <b-container v-else>
      <div class="text-center mt-5">
        <h2 class="text-muted">Select a report first</h2>
      </div>
    </b-container>
    <ReportFormModal
      :id="'edit-report'"
      :report="report"
      :refresh="getCustomReportInfo"
      @onChangeColumns="onChangeColumns"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import FilterColumn from "./filters/Filter.vue";
import {
  GET_CUSTOM_REPO_INFO,
  GET_CUSTOM_REPORT_EXCEL,
  SET_REPORT_FILTERS,
  GET_REPORT_FILTERS_LIST,
  POST_CUSTOM_REPORT,
} from "./actions";
import ReportFormModal from "./ReportFormModal.vue";
export default {
  name: "ReportTablePreview",
  components: {
    ReportFormModal,
    FilterColumn,
  },
  props: {
    fields: {
      type: Array,
      required: false,
      default: () => [],
    },
    report: {
      type: Object,
      required: false,
      default: () => {
        return {
          name: "Report name",
          type: "public",
          fields: [],
        };
      },
    },
  },
  watch: {
    report: {
      deep: true,
      handler: function () {
        if (this.report.id) {
          this.getReportFilterList();

          this.getCustomReportInfo();
        }
      },
    },
  },
  computed: {
    ...mapState({
      customReportInfo: (state) =>
        state.superAdminStore.customReports.customReportInfo.data,
      isLoading: (state) =>
        state.superAdminStore.customReports.customReportInfo.status ==
        "fetching",
      reportExcelStatus: (state) =>
        state.superAdminStore.customReports.customReportExcel.status,
      filters: (state) =>
        state.superAdminStore.customReports.customReportInfo.filters,
      saveFilterIsLoading: (state) =>
        state.superAdminStore.customReports.customReport.status == "fetching",
    }),
  },
  mounted() {
    this.$store.commit("setSearchFunction", this.search);
  },
  methods: {
    getCustomReportInfo() {
      let filters = {};
      if (this.report.filter != null) {
        filters = JSON.parse(this.report.filter);
      }
      this.$store.commit(SET_REPORT_FILTERS, {
        reset: false,
        fields: {
          reportId: this.report.id,
          ...filters,
        },
      });
      this.$store
        .dispatch(GET_CUSTOM_REPO_INFO)
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    },
    openReportFormModal() {
      this.$bvModal.show("edit-report");
    },
    getFormattedValue(formatter) {
      switch (formatter) {
        case "date":
          return "date";
        case "datetime":
          return "datetime";

        case "currency":
          return "currency";
        case "number":
          return "number";
        default:
          return "";
      }
    },
    downloadReport() {
      const payload = {
        reportId: this.report.id,
      };
      this.$store
        .dispatch(GET_CUSTOM_REPORT_EXCEL, payload)
        .then((response) => {
          const blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = this.report.name + ".xls";
          link.click();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Could not get XLS", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    applyFilters(filters) {
      this.$store.commit(SET_REPORT_FILTERS, {
        reset: false,
        fields: {
          reportId: this.report.id,
          ...filters,
        },
      });
      this.getCustomReportInfo();
    },

    getReportFilterList() {
      this.$store.dispatch(GET_REPORT_FILTERS_LIST, {
        reportId: this.report.id,
      });
    },
    search(search) {
      this.$store.commit(SET_REPORT_FILTERS, {
        reset: false,
        fields: {
          reportId: this.report.id,
          search: search,
        },
      });
      this.getCustomReportInfo();
    },
    clearFilters() {
      this.$store.commit(SET_REPORT_FILTERS, {
        reset: true,
        fields: {
          reportId: this.report.id,
        },
      });
      this.getCustomReportInfo();
    },
    changePage(page) {
      this.$store.commit(SET_REPORT_FILTERS, {
        reset: false,
        fields: {
          reportId: this.report.id,
          page: page,
        },
      });
      this.getCustomReportInfo();
    },
    onChangeColumns(columns) {
      this.$emit("onChangeColumns", columns);
    },
    saveFilters() {
      const filters = JSON.stringify(this.filters);

      const payload = {
        ...this.report,
        filter: filters,
      };
      this.$store.dispatch(POST_CUSTOM_REPORT, payload).then(() => {
        this.$root.$bvToast.toast("Filters saved", {
          title: "Success",
          variant: "success",
        });
      });
    },
  },

  unmounted() {
    this.$refs.$children[0].clearFilter();
  },
};
</script>

<style></style>
