<template>
  <div class="d-flex justify-content-start ml-3 mt-1">
    <div @click="showFilterSideBar()" class="cursor-pointer">
      <b-icon
        class="cursor-pointer"
        icon="filter"
        :variant="hasFilter() ? 'success' : ''"
      ></b-icon>
      <b>
        {{ filterTitle }}
      </b>
    </div>
    <TasksBoardFiltersSideBar />
  </div>
</template>

<script>
import TasksBoardFiltersSideBar from "./TasksBoardFiltersSideBar.vue";
export default {
  components: {
    TasksBoardFiltersSideBar,
  },
  name: "TaskCardDueDate",
  computed: {
    filterTitle() {
      return "Filters";
    },
  },
  data() {
    return {};
  },
  methods: {
    hasFilter() {
      return true;
    },
    showFilterSideBar() {
      this.$store.commit("setFilterSideBar", {
        isShowing: true,
      });
    },
  },
};
</script>

<style scoped>
.menu-min-width {
  min-width: 5rem !important;
  left: 0%;
}
</style>
