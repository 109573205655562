<template>
  <div>
    <div class="container" v-if="addon.taxes">
      <ul class="listSeparateByDots">
        <li>
          <span>Amount:</span>
          <span class="">{{ amount | currency }} </span>
        </li>
        <li class="" v-for="(tax, i) in addon.taxes" :key="i">
          <span>{{ tax.tps }} </span
          ><span> {{ getTaxAmountByPercent(tax) | currency }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    addon: {
      type: Object,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
  },
  name: "AddonsListItemBreakdown",
  methods: {
    getTaxAmountByPercent(tax) {
      return (this.amount / 100) * tax.percent;
    },
  },
};
</script>

<style scoped>
ul.listSeparateByDots {
  max-width: 50em;
  padding: 0;
  overflow-x: hidden;
  list-style: none;
}
ul.listSeparateByDots li:before {
  float: left;
  width: 0;
  white-space: nowrap;
  content: ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . ";
}
ul.listSeparateByDots span:first-child {
  padding-right: 0.33em;
  background: white;
}
ul.listSeparateByDots span + span {
  float: right;
  padding-left: 0.33em;
  background: white;
}
</style>