<template>
  <div>
    <NavBar class="mb-2" />
    <router-view />
  </div>
</template>

<script>
import NavBar from "./NavBar";
export default {
  name: "Default",
  components: { NavBar },
};
</script>

