<template>
  <b-modal
    id="conditional-reports-modal"
    ref="modal"
    modal-class="modal-fullscreen"
    centered
    no-close-on-backdrop
    scrollable
    size="lg"
    title="Condition reports"
    @show="getConditionalReports(true, vehicle)"
    @hidden="resetForm()"
  >
    <VehicleDetails
      v-if="vehicle"
      :vehicle="vehicle"
      :color="vehicle.vehicleInformation.color"
    />
    <CRRegistrationForm
      v-if="userHasPermission('cr', 'write')"
      :vehicle="vehicle"
    />

    <b-list-group v-if="conditionalReports.status == 'fetching'" class="mt-2">
      <b-list-group-item class="text-center">
        <b-spinner variant="yellow" />
      </b-list-group-item>
    </b-list-group>

    <b-list-group v-else class="mt-2 mb-2 note-list" tag="ul">
      <b-list-group-item
        v-if="
          conditionalReports.data.data && !conditionalReports.data.data.length
        "
        class="text-center"
      >
        <b>No Condition reports to show</b>
      </b-list-group-item>
      <b-list-group-item
        v-for="(report, i) in conditionalReports.data.data"
        :key="i"
        class="flex-column align-items-start"
        tag="li"
      >
        <ConditionalReportCard :report="report" :refreshData="getData" />
      </b-list-group-item>
    </b-list-group>

    <template v-slot:modal-footer="{ close }">
      <b-pagination
        :value="conditionalReports.data.currentPage"
        :total-rows="conditionalReports.data.total"
        :per-page="conditionalReports.data.perPage"
        class="mt-2"
        @change="changePage($event)"
      />
      <div class="justify-content-end">
        <b-button
          variant="outline-secondary"
          class="mr-2"
          @click="() => close()"
        >
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import { SET_VEHICLE_CONDITIONAL_REPORTS_FILTERS } from "../actions";
import { superAdminMixin } from "../../mixins";
import CRRegistrationForm from "./RegistrationForm";
import VehicleDetails from "@/modules/superAdmin/components/VehicleDetails.vue";
import ConditionalReportCard from "./ConditionalReportCard.vue";
export default {
  components: { CRRegistrationForm, VehicleDetails, ConditionalReportCard },
  mixins: [superAdminMixin],
  computed: {
    ...mapState({
      vehicle: (state) => state.superAdminStore.vehicle,
      conditionalReports: (state) =>
        state.superAdminStore.componentStore.conditionalReports,
    }),
  },
  methods: {
    resetForm() {},
    showConditionalReport(report) {
      window.open(report, "_blank");
    },
    changePage(page) {
      this.$store.commit(SET_VEHICLE_CONDITIONAL_REPORTS_FILTERS, {
        fields: { page },
      });
      this.getConditionalReports(false, this.vehicle);
    },
    getData(resetFilters = true) {
      this.$store.commit(SET_VEHICLE_CONDITIONAL_REPORTS_FILTERS, {
        reset: resetFilters,
        fields: { vehicle: this.vehicle.id },
      });
      this.$store
        .dispatch("GET_VEHICLE_CONDITIONAL_REPORTS")
        .then(() => {})
        .catch(() => {
          this.toastMessage(
            "Conditional reports could not obtained",
            "Warning",
            "warning"
          );
        });
    },
  },
};
</script>

<style scoped>
@import "../styles/modals.css";
</style>
