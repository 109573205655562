<template>
  <b-form @submit.stop.prevent="saveWorkOrder()">
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <b-form-group label-size="sm">
          <template slot="label"> Cost </template>
          <Money
            v-model="form.cost"
            class="form-control text-center"
            v-bind="money"
          />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label="Category">
          <vue-single-select
            :classes="{ input: 'form-control', dropdown: 'dropdown' }"
            v-model="form.category"
            :options="categories"
            optionLabel="name"
          ></vue-single-select>
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label="Provider">
          <vue-single-select
            :classes="{ input: 'form-control', dropdown: 'dropdown' }"
            v-model="form.supplier"
            :options="suppliers"
            optionLabel="companyName"
          ></vue-single-select>
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <b-form-group>
          <template slot="label">
            <div>Description <span class="text-danger">*</span></div>
          </template>
          <b-form-textarea
            v-model.trim="$v.form.description.$model"
            class="resize-none"
            :state="validateState('description')"
            aria-describedby="input-description-live-feedback"
            @change="setValue('description', $event)"
          />
          <b-form-invalid-feedback id="input-description-live-feedback">
            This is a required field.
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
        v-if="damagePictures.length > 0"
      >
        <VueSelectImage
          :data-images="damagePictures"
          :is-multiple="true"
          w="200rem"
          @onselectmultipleimage="onSelectMultipleImage"
        />
      </b-col>

      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <!--    <RegistrationFormTask
          :tasks="form.items"
          @update-tasks="updateFormItems($event)"
        /> -->
      </b-col>
    </b-row>

    <div class="text-right mt-2">
      <b-button
        :disabled="$v.form.$invalid || workOrder.status === 'fetching'"
        variant="primary"
        type="submit"
      >
        <b-spinner
          v-if="workOrder.status === 'fetching'"
          variant="white"
          small
        />
        <span v-else>Submit</span>
      </b-button>
    </div>
    <TaskUpdateStatusModalVue :tasks="tasks" :id="1" />
  </b-form>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import { superAdminMixin } from "../../mixins";
import { GET_CATEGORIES } from "@/modules/vehicle/components/work-orders/categories/actions";
import { GET_DAMAGE_PICTURES, POST_WORK_ORDER } from "./actions";
import VueSelectImage from "vue-select-image";
import VueSingleSelect from "vue-single-select";
import { Money } from "v-money";
import TaskUpdateStatusModalVue from "../../tasks/components/TaskUpdateStatusModal.vue";

export default {
  components: {
    VueSelectImage,
    Money,
    VueSingleSelect,
    TaskUpdateStatusModalVue,
  },
  mixins: [superAdminMixin],
  props: ["vehicle", "refreshData", "workOrderSelected"],
  data() {
    return {
      categories: [],
      suppliers: [],
      damagePictures: [],
      form: {
        description: null,
        vehicleId: null,
        category: null,
        supplier: null,
        damagePhotos: [],
        items: [],
      },
      tasks: [],
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "CAD$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
    };
  },
  computed: {
    ...mapState({
      workOrder: (state) =>
        state.superAdminStore.componentStore.workOrder.workOrder,
    }),
  },
  watch: {
    workOrderSelected() {
      this.formatForm();
    },
  },
  validations: {
    form: {
      description: {
        required,
      },
    },
  },
  beforeMount() {
    this.getCategories();
    this.getProviders();
    this.getDamagePictures();
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form.id = null;
      this.form.description = null;
      this.form.vehicleId = null;
      this.form.supplier = null;
      this.form.category = null;
      this.form.damagePhotos = [];
      this.form.items = [];

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    getCategories() {
      this.$store
        .dispatch(GET_CATEGORIES)
        .then((response) => {
          this.categories = response.data;
        })
        .catch(() => {
          this.$root.$bvToast.toast("Could not get the category list ", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    getProviders() {
      this.$store
        .dispatch("GET_COMPANIES_LIST", { isSupplier: 1 })
        .then((response) => {
          this.suppliers = response;
        })
        .catch(() => {
          this.$root.$bvToast.toast("Could not get the suppliers list ", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    getDamagePictures() {
      const payload = {
        vehicleId: this.vehicle.id,
      };
      this.$store
        .dispatch(GET_DAMAGE_PICTURES, payload)
        .then((response) => {
          this.damagePictures = this.formatDamagePictures(response.data);
        })
        .catch(() => {
          this.$root.$bvToast.toast("Could not get the damage picture list", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    saveWorkOrder() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_WORK_ORDER, payload)
        .then((response) => {
          if (response.task.length > 0) {
            /* show TaskUpdateStatusModal */
            this.tasks = response.task;
            this.$bvModal.show("task-update-status-modal" + 1);
          }
          this.$root.$bvToast.toast("Work order registered successfully", {
            title: "Success",
            variant: "success",
          });
          this.refreshData();
          this.resetForm();
        })
        .catch(() => {});
    },

    formatPayload() {
      const data = new FormData();
      data.set("cost", this.form.cost);
      data.set("vehicleId", this.vehicle.id);
      data.set("description", this.form.description);
      if (this.form.category) {
        data.set("addonTypeId", this.form.category.id);
      }
      if (this.form.supplier) {
        data.set("supplierCompanyId", this.form.supplier.id);
      }
      data.set("damagePhotos", this.form.damagePhotos);
      data.set("items", this.form.items);
      return data;
    },
    onSelectMultipleImage(images) {
      let imagesId = [];
      images.forEach((image) => {
        imagesId.push(image.id);
      });
      this.form.damagePhotos = imagesId;
    },
    formatDamagePictures(data) {
      data.forEach((item) => {
        item.src = item.damagePhoto;
        item.alt = item.damagePhoto;
      });
      return data;
    },
    updateFormItems(items) {
      this.form.items = items;
    },
    formatForm() {
      if (this.workOrderSelected) {
        this.form.id = this.workOrderSelected.id;
        this.form.description = this.workOrderSelected.description;
        this.form.category = this.workOrderSelected.category;
        this.form.cost = this.workOrderSelected.cost;
        this.form.items = [...this.workOrderSelected.workOrderItems];
        this.form.supplier = this.workOrderSelected.supplierCompany;
      }
    },
  },
};
</script>

<style></style>
