<template>
  <b-sidebar
    id="sidebar-vehicle-details"
    lazy
    :width="widthSidebar"
    :visible="vehicleDetailsSideBar.isShowing"
    shadow
    right
  >
    <b-overlay>
      <VehicleCard
        :vehicle="vehicleDetailsSideBar.data"
        :is-show-full-info="true"
      />
    </b-overlay>
  </b-sidebar>
</template>

<script>
import VehicleCard from '../VehicleCard';
export default {
  name: 'VehicleDetailsSideBar',
  components: {
    VehicleCard
  },
  data() {
    return {
      showing: false
    };
  },
  computed: {
    widthSidebar() {
      return this.$vssWidth > 1280
        ? '25%'
        : this.$vssWidth <= 576
          ? '100%'
          : '50%';
    },
    vehicleDetailsSideBar() {
      return this.$store.state.adminStore.vehicles.listStore.componentsStore
        .vehicleDetailsSideBar;
    },
    vehicleModel() {
      return this.vehicleDetailsSideBar.data
        ? this.vehicleDetailsSideBar.data.vinNumber.model
        : '';
    }
  }
};
</script>

<style></style>
