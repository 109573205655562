<template>
  <b-card>
    <div class="container">
      <b-row>
        <b-col cols="10">
          <span>Stock number:</span>
          <i> {{ vehicle.stockNumber }} </i> <br />
          <span>Vin number:</span>
          <i>{{ vehicle.vinNumber }}</i> <br />
          <span>Status:</span>
          <i>{{ vehicle.status }}</i> <br />
          <div class="d-flex justify-content-around">
            <span
              >Make: <i>{{ vehicle.make ? vehicle.make : "----" }}</i></span
            >
            <span
              >Model: <i>{{ vehicle.model ? vehicle.model : "----" }}</i></span
            >
            <span
              >Year: <i>{{ vehicle.year ? vehicle.year : "----" }}</i></span
            >
            <br />
          </div>
          <span
            >Color: <i>{{ vehicle.color ? vehicle.color : "----" }}</i>
          </span>
        </b-col>
        <b-col cols="2">
          <b-button
            @click="goTo(vehicle.stockNumber)"
            variant="yellow"
            class="m-0 p-0 my-1"
          >
            <b-icon variant="white" icon="arrow-right-circle" font-scale="2" />
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
export default {
  props: ["vehicle"],
  name: "VehicleValidationCard",
  methods: {
    goTo(stockNumber) {
      this.$router.push({ path: "/vehicle-search/stock/" + stockNumber });
    },
  },
};
</script>

<style>
</style>