<template>
  <b-container class="container">
    <b-form class="m-2 pt-0" @submit.stop.prevent="form.id ? update() : save()">
      <h3 class="text-purple-yellow">
        <b-icon icon="inboxes" font-scale="1.20" />
      </h3>
      Credit request

      <b-tabs content-class="">
        <b-tab active title="Credit request" @click="onChangeStatus(1)"></b-tab>
        <b-tab title="Re-financial" @click="onChangeStatus(2)"></b-tab>
      </b-tabs>
      <VehiclesList
        v-if="!isLoadingVehicles"
        @vehicles-selected="setVehiclesSelected"
        :vehiclesPreSelected="vehiclesPreSelected"
        :type="type"
        class="h-25"
      />
      <!-- loader -->
      <b-row v-if="isLoadingVehicles" class="d-flex justify-content-center">
        <b-spinner label="Loading..." variant="yellow" class="mt-2" />
      </b-row>

      <b-row />
      <b-row class="mt-3">
        <b-col sm="12" md="6" lg="6" xl="6" />
        <b-col sm="12" md="3" lg="3" xl="3" />
        <b-col sm="12" md="3" lg="3" xl="3">
          <b-btn
            v-if="userHasPermission('creditRequest', 'write')"
            block
            size="lg"
            class="btn btn-success"
            :disabled="$v.form.$invalid"
            type="submit"
          >
            Submit
            <b-spinner
              v-if="creditRequest.status == 'fetching'"
              small
              variant="white"
            />
          </b-btn>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import { POST_CREDIT_REQUEST, GET_VEHICLES_CREDIT_REQUEST } from "./actions";
import { mapState } from "vuex";
import { generalMixin } from "@/modules/mixin.js";
import VehiclesList from "./VehiclesList.vue";
import { superAdminMixin } from "../mixins";

export default {
  mixins: [generalMixin, superAdminMixin],
  props: ["creditRequestItem", "refreshData", "hideModal"],
  components: {
    VehiclesList,
  },
  computed: {
    ...mapState({
      vehicles: (state) => state.superAdminStore.creditRequest.vehicles,
      creditRequests: (state) =>
        state.superAdminStore.creditRequest.creditRequestList.data,
      creditRequest: (state) =>
        state.superAdminStore.creditRequest.creditRequest,
      isLoadingVehicles: (state) =>
        state.superAdminStore.creditRequest.vehicles.status === "fetching",
    }),
    vehiclesPreSelected() {
      if (this.creditRequestItem.vehicles) {
        return this.creditRequestItem.vehicles;
      }
      return [];
    },
  },

  data() {
    return {
      vehiclesSelected: [],
      form: {
        id: null,
        list: [],
      },
      type: "creditRequest",
    };
  },
  validations: {
    form: {},
  },
  mounted() {
    if (this.creditRequestItem.id) {
      this.formatForm();
    } else {
      this.resetForm();
    }
  },
  watch: {
    creditRequest() {
      if (this.creditRequest) {
        this.form.creditRequest = { ...this.creditRequest };

        this.getVehicles();
      }
    },
  },
  methods: {
    closeModal(callback) {
      callback();
    },
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    save() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_CREDIT_REQUEST, payload)
        .then(() => {
          this.makeToast(
            "Success",
            "Credit request created successfully",
            "success"
          );
          this.form = {};
          this.refreshData();
          this.hideModal();
        })
        .catch((error) => {
          console.log(error);
          this.$root.$bvToast.toast("Internal server error, try again later", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    update() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_CREDIT_REQUEST, payload)
        .then(() => {
          this.makeToast(
            "Success",
            "Credit request updated successfully",
            "success"
          );
          this.refreshData();
          this.hideModal();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Internal server error, try again later", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    editItem(item) {
      this.creditRequestItem = item;
      this.$bvModal.show("detailsModal");
    },

    formatPayload() {
      const payload = {
        id: this.creditRequestItem.id ? this.creditRequestItem.id : null,
        list: this.vehiclesSelected,
      };

      return payload;
    },

    formatForm() {
      if (this.creditRequestItem.id) {
        this.form.id = this.creditRequestItem.id;
      }
    },

    setVehiclesSelected(vehicles) {
      this.vehiclesSelected = vehicles;
    },
    getVehicles(status = 1) {
      this.$store.dispatch(GET_VEHICLES_CREDIT_REQUEST, {
        status: status,
      });
    },
    resetForm() {
      this.form = {
        id: null,
        list: [],
        creditRequest: null,
      };
    },
    onChangeStatus(status) {
      this.type = status == 1 ? "creditRequest" : "reFinancial";
      this.getVehicles(status);
    },
  },
};
</script>

<style scoped>
.append-input {
  max-width: 40%;
}
</style>
