<template>
  <div>
    <div class="container-fluid mt-3">
      <div>
        <TableBase
          :items="addonsType.data"
          :fields="fields"
          :options="options"
          :busy="addonsType.status === 'fetching' ? true : false"
        >
          <template v-slot:rightSide>
            <div>
              <!--    <b-button
                v-if="userHasPermission('addons', 'write')"
                variant="success"
                class="align-bottom"
                @click="showModal()"
              >
                <b-icon icon="plus" class="mt-1" />
              </b-button> -->
            </div>
          </template>
        </TableBase>
      </div>

      <b-modal
        id="detailsModal"
        ref="detailsModal"
        size="lg"
        hide-footer
        body-class=""
        ok-disabled
        @hidden="addonTypeItem = {}"
      >
        <AddonsTypeForm
          :addonTypeItem="addonTypeItem"
          :refreshData="getData"
          :hideModal="hideModal"
        />
      </b-modal>
    </div>
  </div>
</template>
<script>
import TableBase from "@/modules/shared/table.vue";
import AddonsTypeForm from "./AddonsTypeForm.vue";

import { GET_ADDONS_TYPE, POST_ADDON_TYPE } from "./actions";
import { mapState } from "vuex";

import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../mixins";
export default {
  name: "AddonsTypeTable",
  components: { TableBase, AddonsTypeForm },
  mixins: [generalMixin, superAdminMixin],
  computed: {
    ...mapState({
      addonsType: (state) => state.superAdminStore.addonsType.addonsType,
    }),
    hasPermission() {
      return this.userHasPermission("addons", "write");
    },
    fields() {
      return [
        { key: "name", label: "Name" },
        { key: "topAmount", label: "Top amount", formatter: "currency" },

        {
          key: "actions",
          label: "Actions",
          note: false,
          details: true,
          validation: false,
          delete: this.hasPermission,
        },
      ];
    },
  },
  data() {
    return {
      options: {
        assign: false,
        rolFilter: false,
        add: false,
        fun: this.getData,
      },
      addonTypeItem: {},
    };
  },
  mounted() {
    this.$store.commit(
      "setAddFunction",
      this.userHasPermission("addons", "write") ? this.showModal : null
    );
    this.getData();
  },
  methods: {
    getData(filter = "") {
      this.$store
        .dispatch(GET_ADDONS_TYPE, { search: filter })
        .then(() => {})
        .catch(() => {});
    },
    async deleteItem(item) {
      const confirm = await this.showConfirmDeleteBox();
      if (!confirm) return;
      const payload = { ...item };
      payload.status = 0;
      this.$store
        .dispatch(POST_ADDON_TYPE, payload)
        .then(() => {
          this.getData();
          this.makeToast(
            "Success",
            "Addon type updated successfully",
            "success"
          );
        })
        .catch(() => {
          this.$root.$bvToast.toast("Addon type could not be disabled", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    activateItem(item) {
      const payload = {
        id: item.id,
        status: 1,
      };
      this.$store
        .dispatch(POST_ADDON_TYPE, payload)
        .then(() => {
          this.makeToast(
            "Success",
            "Addon type updated successfully",
            "success"
          );
          this.getData();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Addon type could not be activated", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    showModal() {
      this.$bvModal.show("detailsModal");
    },
    editItem(item) {
      this.addonTypeItem = item;
      this.$bvModal.show("detailsModal");
    },
    changePage(page, filter = "") {
      const payload = {
        search: filter,
        page: page,
      };
      this.$store
        .dispatch(GET_ADDONS_TYPE, payload)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },

    hideModal() {
      this.$bvModal.hide("detailsModal");
    },
  },
};
</script>
