<template>
  <b-modal
    id="bulk-release-date-modal"
    ref="modal"
    modal-class="modal-fullscreen"
    centered
    scrollable
    size="lg"
    title="Bulk release date"
    hide-footer
  >
    <ReleaseDateForm />
  </b-modal>
</template>

<script>
import ReleaseDateForm from "./ReleaseDateForm.vue";
export default {
  components: { ReleaseDateForm },
};
</script>

<style>
</style>
