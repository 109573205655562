<template>
  <b-modal
    id="confirm-modal"
    ref="confirm-modal"
    title="Confirm information"
    @show="resetModal()"
    @hidden="resetModal()"
  >
    <div>
      <p>
        Please confirm that you want to create a task with the following
        information:
      </p>
      <ul>
        <li v-if="task.owner">
          <strong>User:</strong>
          <ul>
            <li v-for="(owner, key) in task.owner" :key="key">
              {{ owner.firstName || "----" }}
              {{ owner.lastName || "----" }}
            </li>
          </ul>
        </li>
        <li v-if="task.type">
          <strong>Task:</strong> {{ this.formatTaskType(task.type) }}
        </li>
        <li v-if="task.vehicles">
          <strong>Units: </strong>
          <ul>
            <li
              v-for="(vehicle, key) in task.vehicles"
              :vehicle="vehicle"
              :key="key"
            >
              {{ vehicle.title }}
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <template slot="modal-footer">
      <b-btn size="sm" variant="danger" @click="$refs['confirm-modal'].hide()">
        Cancel
      </b-btn>
      <b-btn
        :disabled="taskStore.status === 'fetching'"
        size="sm"
        variant="success"
        @click="onConfirm()"
      >
        <!--  <span v-if="!confirmUploadFile"
          >You can confirm in {{ leftSeconds }} seconds</span
        > -->
        <span>Confirm </span>
      </b-btn>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "TaskConfirmCreateModal",
  props: {
    task: {
      type: Object,
      default: () => {
        return {};
      },
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      taskStore: (state) => state.superAdminStore.tasks.task,
    }),
  },
  methods: {
    resetModal() {
      this.$refs["confirm-modal"].hide();
    },
    onConfirm() {
      this.callback();
    },
    formatTaskType(types) {
      if (!types || types.length == 0) return "";
      let taskTypes = "";
      types.forEach((type) => {
        taskTypes += type.name + ", ";
      });
      return taskTypes ? taskTypes.slice(0, -2) : "";
    },
  },
};
</script>

<style>
</style>
