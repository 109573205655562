
import { ifRoleAuthenticated } from "@/router/navigationGuards";

import DashboardLayout from '@/layouts/DashboardLayout.vue';
import VehiclesTable from './vehicles-table/VehiclesTable.vue'
const routes = [
  {
    beforeEnter: (to, from, next) => {
      ifRoleAuthenticated(to, from, next, ["ROLE_CLIENT", "ROLE_BUYER"]);
    },
    path: "/client",
    name: "client",
    component: DashboardLayout,
    children: [
      {
        path: "vehicle-list",
        name: "client.vehicle-list",
        component: VehiclesTable,
        meta: {
          name: "Vehicles",
        }
      }
    ],

  },

];

export default routes;
