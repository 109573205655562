<template>
  <b-modal
    id="police-book-modal"
    ref="modal"
    centered
    no-close-on-backdrop
    scrollable
    size="xl"
    title="Police Book"
    hide-footer
    @shown="getPoliceBook"
  >
    <b-row
      v-if="policeBook.status == 'fetching' || policeBook.status == null"
      class="justify-content-center"
    >
      <b-col cols="12" class="text-center">
        <b-spinner label="Loading..."></b-spinner>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <VehicleInformation
          :vehicleInformation="policeBook.data.vehicleInformation"
        />
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <Notes :notes="policeBook.data.notes" />
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
        <Files :exportInfo="policeBook.data.vehicleInformation" />
      </b-col>
      <b-col
        v-if="policeBook.data.boughtFrom"
        xs="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
        cols="12"
      >
        <BoughtInformation
          v-if="policeBook.data.boughtFrom"
          :boughtInformation="policeBook.data.boughtFrom"
        />
      </b-col>
      <b-col
        v-if="policeBook.data.soldInformation"
        xs="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
        cols="12"
      >
        <SoldInformation
          v-if="policeBook.data.soldInformation"
          :soldInformation="policeBook.data.soldInformation"
        />
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import { superAdminMixin } from "../../mixins";
import { GET_POLICE_BOOK } from "./actions";
import VehicleInformation from "./VehicleInformation.vue";
import BoughtInformation from "./BoughtInformation.vue";
import SoldInformation from "./SoldInformation.vue";
import Notes from "./notes/Notes.vue";
import Files from "./Files.vue";
export default {
  name: "PoliceBookModal",
  components: {
    VehicleInformation,
    BoughtInformation,
    SoldInformation,
    Notes,
    Files,
  },
  mixins: [superAdminMixin],
  computed: {
    ...mapState({
      vehicle: (state) => state.superAdminStore.vehicle,

      policeBook: (state) =>
        state.superAdminStore.componentStore.policeBook.policeBook,
    }),
    isLoading() {
      return false;
    },
  },
  data() {
    return {
      providers: [],

      form: {
        id: null,
        vehicleId: null,
        provider: null,
      },
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "CAD$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      carwashesSelectedToEdit: [],
      refreshComponent: true,
    };
  },
  methods: {
    getPoliceBook() {
      this.$store
        .dispatch(GET_POLICE_BOOK, { id: this.vehicle.id })
        .then((response) => console.log(response));
    },
  },
};
</script>

<style scoped></style>
