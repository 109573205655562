export const POST_RECALL_NOTE = "POST_RECALL_NOTE";
export const POST_RECALL_NOTE_SUCCESS = "POST_RECALL_NOTE_SUCCESS";
export const POST_RECALL_NOTE_ERROR = "POST_RECALL_NOTE_ERROR";

export const DELETE_RECALL_NOTE = "DELETE_RECALL_NOTE";
export const DELETE_RECALL_NOTE_SUCCESS = "DELETE_RECALL_NOTE_SUCCESS";
export const DELETE_RECALL_NOTE_ERROR = "DELETE_RECALL_NOTE_ERROR";

export const GET_RECALL_NOTES = "GET_RECALL_NOTES";
export const GET_RECALL_NOTES_SUCCESS = "GET_RECALL_NOTES_SUCCESS";
export const GET_RECALL_NOTES_ERROR = "GET_RECALL_NOTES_ERROR";
