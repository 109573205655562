<template>
  <b-modal
    id="sold-modal"
    size="lg"
    body-class="container"
    title="Sold"
    hide-footer
    @show="setupInformation()"
    @hidden="
      taxesSelected = [];
      getVehiclesInfo();
    "
  >
    <!--   <div v-if="isLoading && !companiesIsLoading" class="text-center">
      <b-spinner variant="yellow" />
    </div> -->

    <div class="container" v-if="isViewReady">
      <VehicleDetails
        v-if="vehicle"
        :vehicle="vehicle"
        :color="vehicle.vehicleInformation.color"
      />
      <b-form @submit.stop.prevent="form.id ? update() : save()">
        <b-row class="mt-5">
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group label="Sell price">
              <Money
                v-model.trim="form.salePrice"
                class="form-control"
                v-bind="money"
                :disabled="isSold"
              />
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group label-size="md">
              <template slot="label">
                Customer <span class="text-danger">*</span>
              </template>

              <vue-single-select
                v-if="!isSold && !isReserve"
                :classes="{ input: 'form-control', dropdown: 'dropdown' }"
                v-model="form.company"
                :options="companies"
                optionLabel="companyName"
              ></vue-single-select>
              <b-form-input
                v-if="form.company && (isSold || isReserve)"
                class=""
                size="md"
                :value="form.company.companyName"
                readonly
              />
            </b-form-group>
          </b-col>

          <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-if="isSold">
            <b-form-group label-size="md">
              <template slot="label"> Date of registration </template>

              <b-form-input
                class=""
                size="md"
                :value="form.registerDate"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6" v-if="isSold">
            <b-form-group label-size="md">
              <template slot="label"> Registered by: </template>
              <b-form-input class="" size="md" :value="form.user" readonly />
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12" class="mb-2">
            <b-button
              class="mx-2"
              variant="success"
              type="button"
              @click="setFastNote('Carfax included')"
              >Carfax included</b-button
            >
            <b-button
              class="mx-2"
              variant="success"
              type="button"
              @click="setFastNote('Transport included')"
              >Transport included</b-button
            >
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12">
            <b-form-group label="Note">
              <b-form-textarea v-model.trim="form.note" class="resize-none" />
            </b-form-group>
          </b-col>
        </b-row>
        <div>
          <TaxTable
            v-if="!isSold"
            :taxes="taxesForTable"
            @update-select-taxes="setTaxesSelected"
          />
        </div>
        <div>
          <Breakdown
            v-if="!isSold"
            :sellPrice="form.salePrice"
            :taxes="taxesSelected"
          >
            <template slot="initial-label">
              <span>Sell price</span>
            </template>
            <template slot="final-label">
              <span>Final price</span>
            </template>
          </Breakdown>
        </div>

        <div>
          <div class="text-right mt-2">
            <b-button-group
              size="sm"
              v-if="isSold && userHasPermission('sold', 'write')"
            >
              <b-dropdown right text="Cancel" variant="danger">
                <b-dropdown-item @click="cancelSold('InStock')"
                  >In stock</b-dropdown-item
                >
              </b-dropdown>
            </b-button-group>

            <b-button
              v-else-if="!isSold && userHasPermission('sold', 'write')"
              :disabled="
                soldStatus === 'fetching' ||
                form.salePrice < 1 ||
                form.company === null
              "
              variant="success"
              type="submit"
              size="md"
              class=""
            >
              <b-spinner
                v-if="soldStatus === 'fetching'"
                variant="white"
                small
              />
              <span v-else>Submit</span>
            </b-button>
            <b-button
              v-if="userHasPermission('sold', 'write')"
              variant="warning"
              size="sm"
              v-b-tooltip.hover
              type="button"
              title="Notes"
              class="float-right mx-2 text-white p-2"
              @click="openSoldNotesModal()"
            >
              <div class="m-1">Notes</div>
            </b-button>

            <b-dropdown
              v-if="isSold && userHasPermission('sold', 'write')"
              variant="yellow"
              size="sm"
              class="float-right mx-2"
            >
              <template #button-content>
                <b-icon
                  icon="file-earmark-arrow-down"
                  class="m-2"
                  variant="white"
                  scale="1.5"
                ></b-icon>
              </template>
              <b-dropdown-item @click="getSoldPDF(sold.sale.id, 1)"
                >Invoice</b-dropdown-item
              >
              <b-dropdown-item @click="getSoldPDF(sold.sale.id, 2)"
                >Invoice with title out</b-dropdown-item
              >
            </b-dropdown>
            <!--      <b-button
              v-if="isSold && userHasPermission('sold', 'write')"
              variant="yellow"
              size="sm"
              @click="getSoldPDF(sold.sale.id)"
              class="float-right mx-2 p-2"
              type="button"
            >
              <b-icon
                icon="file-earmark-arrow-down"
                class="m-1"
                variant="white"
              ></b-icon>
            </b-button> -->

            <b-button
              v-if="isSold && userHasPermission('sold', 'write')"
              :disabled="soldPDF.status == 'fetching'"
              variant="success"
              type="button"
              size="sm"
              @click="openEmailBodyModal()"
              class="float-right mx-2 p-2"
              v-b-tooltip.hover
              title="Send sold PDF by email"
            >
              <b-spinner
                v-if="soldPDF.status == 'fetching'"
                variant="white"
                small
              ></b-spinner>
              <b-icon
                v-else
                icon="envelope"
                variant="white"
                class="m-1"
              ></b-icon>
            </b-button>
          </div>
        </div>
      </b-form>

      <SellChecks
        v-if="isSold && userHasPermission('sold', 'write') && !isLoading"
        :salePrice="form.salePrice"
      />
      <SoldNotesModal />
      <EmailBody
        @submit="sendSoldPDFByEmail"
        :id="1"
        :isLoading="soldPDF.status == 'fetching'"
      />
    </div>
    <div v-else class="text-center">
      <b-spinner variant="yellow" />
    </div>
    <TaskUpdateStatusModal
      :tasks="tasks"
      :id="1"
      @update-task="refreshAfterUpdateTask"
    />
  </b-modal>
</template>

<script>
import { Money } from "v-money";
import { mapState } from "vuex";
import {
  GET_SOLD,
  POST_SOLD,
  GET_SOLD_PDF_EMAIL,
  PUT_SOLD,
  GET_SOLD_TAXES,
} from "./actions";
import TaxTable from "./TaxTable.vue";
import Breakdown from "./Breakdown.vue";
import VueSingleSelect from "vue-single-select";
import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "@/modules/superAdmin/mixins.js";
import SellChecks from "../sellChecks/SellCheckList.vue";
import SoldNotesModal from "./notes/NotesModal.vue";
import VehicleDetails from "@/modules/superAdmin/components/VehicleDetails.vue";

import EmailBody from "@/modules/shared/EmailBody";
import TaskUpdateStatusModal from "../../tasks/components/TaskUpdateStatusModal.vue";

export default {
  components: {
    TaxTable,
    Breakdown,
    Money,
    VueSingleSelect,
    SellChecks,
    SoldNotesModal,
    EmailBody,
    VehicleDetails,
    TaskUpdateStatusModal,
  },
  mixins: [generalMixin, superAdminMixin],
  computed: {
    ...mapState({
      vehicle: (state) => state.superAdminStore.vehicle,

      sold: (state) => state.superAdminStore.componentStore.sold.sold.data,
      soldStatus: (state) =>
        state.superAdminStore.componentStore.sold.soldRegister.status,
      taxesForTable: (state) =>
        state.superAdminStore.componentStore.sold.taxes.data,
      soldPDF: (state) => state.superAdminStore.componentStore.sold.soldPDF,
      isLoading(state) {
        return (
          state.superAdminStore.componentStore.sold.sold.status == "fetching"
        );
      },

      isSold() {
        return (
          this.sold.isNewRegister == false && this.sold.vehicle.status == "Sold"
        );
      },
      profile: (state) => state.auth.loginStore.profile.data,
    }),
    corporation() {
      const corporation = localStorage.getItem("corporation");
      let corporationSelect = this.profile.user.corporationPermissions.find(
        (c) => c.id == corporation
      );

      return corporationSelect
        ? corporationSelect
        : this.profile.user.corporationPermissions[0];
    },
    money() {
      return {
        decimal: ".",
        thousands: ",",
        prefix: this.corporation.typeCurrency + " " + "$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      };
    },
  },
  data() {
    return {
      form: {
        salePrice: 0,
        contractCheck: null,
        registrationCheck: null,
        contractDate: null,
        registrationDate: null,
        note: null,
        company: null,
      },
      taxesSelected: [],
      companies: [],
      isReserve: false,
      companiesIsLoading: false,
      tasks: [],
      isViewReady: false,
    };
  },
  methods: {
    getSoldInformation() {
      return this.$store
        .dispatch(GET_SOLD, {
          vehicleId: this.vehicle.id,
          taskId: this.vehicle?.taskId ? this.vehicle.taskId : null,
        })
        .then(() => {
          this.formatForm();
          /* 
          if (!this.sold.isNewRegister && isSave) {
          } */
        })
        .catch((error) => {
          this.closeModal("sold-modal");
          this.$root.$bvToast.toast(
            error.data.message.charAt(0).toUpperCase() +
              error.data.message.slice(1),
            {
              title: "Error",
              variant: "warning",
            }
          );
        });
    },
    async getReserve() {
      return await this.$store
        .dispatch("GET_RESERVE", { vehiclesId: this.vehicle.id, sold: 1 })
        .then((response) => {
          if (response.reserve) {
            this.isReserve = true;
            this.form.salePrice = response.reserve.price || 0;
            this.form.company = this.companies.find(
              (company) => company.id == response.reserve.companiesId
            );
          }
        });
    },
    setTaxesSelected(taxes) {
      this.taxesSelected = taxes;
    },
    async getCompanies() {
      return await this.$store
        .dispatch("GET_CLIENTS")
        .then((response) => {
          this.companies = response;
          this.companiesIsLoading = false;
        })
        .catch(() => {
          this.companiesIsLoading = false;
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    save() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_SOLD, payload)
        .then((response) => {
          if (response.task.length > 0) {
            this.tasks = response.task;
            this.$root.$bvModal.show("task-update-status-modal" + 1);
          } else {
            this.getSoldInformation(true);
          }
          this.clearSoldInformation();
          this.$root.$bvToast.toast("Sold registered successfully", {
            title: "Success",
            variant: "success",
          });
          this.$emit("refresh");
        })
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },

    formatPayload() {
      const payload = {
        salePrice: this.form.salePrice,
        vehiclesId: this.vehicle.id,
        companiesId: this.form.company.id,
        tax1: this.taxesSelected[0] ? this.taxesSelected[0] : null,
        tax2: this.taxesSelected[1] ? this.taxesSelected[1] : null,
        tax3: this.taxesSelected[2] ? this.taxesSelected[2] : null,
        note: this.form.note,
      };

      return payload;
    },
    update() {},
    clearSoldInformation() {
      this.form.id = null;
      this.form.salePrice = 0;
      this.form.contractCheck = null;
      this.form.registrationCheck = null;
      this.form.contractDate = null;
      this.form.registrationDate = null;
      this.form.note = null;
      this.form.company = null;
      this.isReserve = false;
      this.taxesSelected = [];
    },
    formatForm() {
      if (this.sold.invoice) {
        this.form.salePrice = this.sold.invoice?.price;
        this.form.company = this.companies.find(
          (company) => company.id === this.sold.invoice.companiesId
        );
      }
      if (this.sold.sale) {
        this.form.id = this.sold?.sale?.id || null;

        this.form.salePrice =
          this.corporation.typeCurrency == "CAD"
            ? this.sold?.sale?.salePrice || 0
            : this.sold.sale?.salePriceUsd || 0;
        this.form.company = this.companies.find(
          (company) => company.id === this.sold.sale.companiesId
        );
        this.form.registerDate = this.sold.sale.registerDate;
        if (this.sold.sale.user) {
          this.form.user = this.sold.sale.user;
        }
        this.formatTaxesSelectedAfterSold(this.sold.sale);
        return;
      }
    },
    getSoldPDF(id, type) {
      this.$store
        .dispatch("GET_SOLD_PDF", { id, type })
        .then((response) => {
          const blob = new Blob([response], {
            type: "application/pdf",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = this.formatPDFName("sold", this.vehicle);
          link.click();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Could not get file, try again later", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    formatPDFName() {
      /* retuen last 6 char of vinNumber.vinNumber */
      return this.vehicle.vinNumber.vinNumber.slice(-6) + ".pdf";
    },

    setVehicle() {
      return this.$store.commit("POST_VEHICLE_BY_STOCK_SUCCESS", {
        data: this.vehicle,
      });
    },
    formatTaxesSelectedAfterSold(taxAndAmount) {
      if (taxAndAmount.tax1 != null) {
        let percent = (taxAndAmount.amount1 * 100) / taxAndAmount.salePrice;
        let taxAfterSold = { tps: taxAndAmount.tax1, percent: percent };
        this.taxesSelected.push(taxAfterSold);
      }

      if (taxAndAmount.tax2 != null) {
        let percent = (taxAndAmount.amount2 * 100) / taxAndAmount.salePrice;
        let taxAfterSold = { tps: taxAndAmount.tax2, percent: percent };
        this.taxesSelected.push(taxAfterSold);
      }

      if (taxAndAmount.tax3 != null) {
        let percent = (taxAndAmount.amount3 * 100) / taxAndAmount.salePrice;
        let taxAfterSold = { tps: taxAndAmount.tax3, percent: percent };
        this.taxesSelected.push(taxAfterSold);
      }
      this.$store.commit("GET_SELL_CHECKS_TAXES_SUCCESS", this.taxesSelected);
    },
    sendSoldPDFByEmail(body) {
      this.$store
        .dispatch(GET_SOLD_PDF_EMAIL, { id: this.sold.sale.id, body: body })
        .then(() => {
          this.$root.$bvToast.toast("Email sent successfully", {
            title: "Success",
            variant: "success",
          });
          this.$root.$bvModal.hide("email-body-modal" + 1);
        })

        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    cancelSold(status) {
      const payload = {
        id: this.sold.sale.id,
        status: 0,
        vehicleStatus: status,
      };
      this.$store
        .dispatch(PUT_SOLD, payload)
        .then(() => {
          this.$root.$bvToast.toast("Sold cancelled successfully", {
            title: "Success",
            variant: "success",
          });
          this.getVehiclesInfo();
          this.closeModal("sold-modal");
        })
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    getTaxes() {
      this.$store
        .dispatch(GET_SOLD_TAXES, { id: this.vehicle.id })
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    openSoldNotesModal() {
      this.$bvModal.show("sold-notes-modal");
    },
    setFastNote(note) {
      if (this.form.note == null) {
        this.form.note = note;
      } else {
        this.form.note = this.form.note + " " + note;
      }
    },
    openEmailBodyModal() {
      this.$root.$bvModal.show("email-body-modal" + 1);
    },
    async setupInformation() {
      this.companiesIsLoading = true;
      this.isViewReady = false;
      this.clearSoldInformation();
      try {
        await this.getCompanies();
        await this.getReserve();
        await this.getSoldInformation();
        this.setVehicle();
        if (this.vehicle) {
          this.getTaxes();
        }
        this.isViewReady = true;
      } catch (error) {
        console.log(error);
      }
    },
    refreshAfterUpdateTask() {
      this.getSoldInformation(true);
    },
  },
};
</script>

<style></style>
