<template>
  <b-container class="mt-3" fluid>
    <div class="d-flex justify-content-between">
      <div>
        <span v-if="transfersBetweenAgentSelected.length > 0">
          Rows selected {{ transfersBetweenAgentSelected.length }}</span
        >
      </div>

      <div>
        <b-button
          v-if="transfersBetweenAgentSelected.length > 0"
          variant="success"
          @click="validateTransfer()"
          >Validate selected</b-button
        >
        <b-button
          :disabled="transferBetweenAgent.status == 'fetching'"
          variant="yellow"
          @click="downloadTransferExcel"
          >Download xls</b-button
        >
      </div>
    </div>
    <div class="d-flex justify-content-end"></div>
    <b-tabs content-class="">
      <b-tab title="Not accounted" active @click="onChangeStatus('1')"></b-tab>
      <b-tab title="Accounted" @click="onChangeStatus('2')"></b-tab>
      <b-tab title="All" @click="onChangeStatus('1,2')"></b-tab>
    </b-tabs>
    <div class="table-responsive">
      <b-table
        class=""
        show-empty
        bordered
        small
        stacked="md"
        head-variant="light"
        :items="transfersBetweenAgent.data || []"
        :fields="fields"
        :busy="transfersBetweenAgentStatus == 'fetching'"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" variant="yellow" />
          </div>
        </template>
        <template #cell(select)="{ item }">
          <b-form-checkbox
            :disabled="item.status == 2"
            @change="onSelectTransfer(item)"
            :checked="isSelected(item)"
          ></b-form-checkbox>
        </template>
        <template #cell(total)="{ item }">
          {{ (item.cost + item.vehicleAddons) | currency }}
        </template>
        <template #cell(actions)="{ item }">
          <b-icon
            icon="trash"
            class="cursor-pointer"
            @click="deleteTransfer(item.id)"
          ></b-icon>
        </template>
      </b-table>
    </div>
    <b-pagination
      v-if="
        transfersBetweenAgentStatus != 'fetching' &&
        transfersBetweenAgent &&
        transfersBetweenAgent.data
      "
      :value="transfersBetweenAgent ? transfersBetweenAgent.currentPage : 1"
      :total-rows="transfersBetweenAgent ? transfersBetweenAgent.total : 0"
      :per-page="transfersBetweenAgent ? transfersBetweenAgent.perPage : 25"
      class="mb-2"
      align="end"
      @change="changePage($event)"
    />
    <b-modal
      id="transfer-between-agent-form-modal"
      ref="transfer-between-agent-form-modal"
      size="lg"
      hide-footer
    >
      <TransferBetweenAgentForm :refresh="getTransfersBetweenAgent" />
    </b-modal>
  </b-container>
</template>
<script>
import { mapState } from "vuex";
import {
  GET_TRANSFER_BETWEEN_AGENT_LIST,
  PUT_VALIDATE_TRANSFER_BETWEEN_AGENT,
  DELETE_TRANSFER_BETWEEN_AGENT,
  GET_TRANSFER_BETWEEN_AGENT_EXCEL,
} from "./actions";
import TransferBetweenAgentForm from "./TransferBetweenAgentForm.vue";

export default {
  name: "TransferBetweenAgentTable",
  components: {
    TransferBetweenAgentForm,
  },
  computed: {
    ...mapState({
      transfersBetweenAgent: (state) =>
        state.superAdminStore.transferBetweenAgent.transferBetweenAgents.data,
      transfersBetweenAgentStatus: (state) =>
        state.superAdminStore.transferBetweenAgent.transferBetweenAgents.status,
      transferBetweenAgent: (state) =>
        state.superAdminStore.transferBetweenAgent.transferBetweenAgent,
    }),
  },
  data() {
    return {
      fields: [
        {
          key: "select",
          label: "Select",
          class: "text-center",
        },
        {
          key: "agent",
          label: "Origin gent",
          class: "text-center",
        },
        {
          key: "stockNumber",
          label: "Stock number",
          class: "text-center",
        },
        {
          key: "vinNumber",
          label: "Vin number",
          class: "text-center",
        },
        {
          key: "description",
          label: "Description",
          class: "text-center",
        },
        {
          key: "cost",
          label: "Cost",
          class: "text-right",
          formatter: this.$options.filters.currency,
        },
        {
          key: "vehicleAddons",
          label: "Vehicle Addons",
          class: "text-right",
          formatter: this.$options.filters.currency,
        },
        {
          key: "total",
          label: "Total",
          class: "text-right",
          formatter: this.$options.filters.currency,
        },
        /* {
          key: "totalAmountCAD",
          label: "Total Amount CAD",
          formatter: this.$options.filters.currency,
          class: "text-right",
        },
        {
          key: "totalAmountUSD",
          label: "Total Amount USD",
          formatter: this.$options.filters.currency,
          class: "text-right",
        }, */

        {
          key: "amount",
          label: "Transaction amount",
          class: "text-right",
          formatter: this.$options.filters.currency,
        },

        {
          key: "flat",
          label: "Flat",
          class: "text-right",
          formatter: this.$options.filters.currency,
        },
        {
          key: "addon",
          label: "Addon",
          class: "text-right",
          formatter: this.$options.filters.currency,
        },

        {
          key: "actions",
          label: "Actions",
          class: "text-center",
        },
        /*   {
          key: "createdAt",
          label: "Created At",
          class: "text-center",
          formatter: this.$options.filters.formatDateTime,
        }, */
      ],
      status: "1",
      transfersBetweenAgentSelected: [],
    };
  },
  mounted() {
    this.$store.commit("setAddFunction", this.openFormModal);
    this.$store.commit("setSearchFunction", this.getTransfersBetweenAgent);
    this.getTransfersBetweenAgent();
  },
  methods: {
    getTransfersBetweenAgent(search = "", page = 1) {
      const payload = {
        page: page,
        search: search,
        status: this.status,
      };
      this.$store
        .dispatch(GET_TRANSFER_BETWEEN_AGENT_LIST, payload)
        .then(() => {});
    },
    openFormModal() {
      this.$refs["transfer-between-agent-form-modal"].show();
    },
    onChangeStatus(status) {
      this.resetSelected();
      this.status = status;
      this.getTransfersBetweenAgent("", 1);
    },
    changePage(page) {
      this.getTransfersBetweenAgent("", page);
    },
    isSelected(item) {
      return this.transfersBetweenAgentSelected.some(
        (transferBetweenAgent) => transferBetweenAgent.id == item.id
      );
    },
    onSelectTransfer(item) {
      if (this.isSelected(item)) {
        this.transfersBetweenAgentSelected =
          this.transfersBetweenAgentSelected.filter(
            (transferBetweenAgent) => transferBetweenAgent.id != item.id
          );
      } else {
        this.transfersBetweenAgentSelected.push(item);
      }
    },
    validateTransfer() {
      const payload = {
        listTransfers: this.transfersBetweenAgentSelected,
      };
      this.$store
        .dispatch(PUT_VALIDATE_TRANSFER_BETWEEN_AGENT, payload)
        .then(() => {
          this.$bvToast.toast("Transfer validated", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.getTransfersBetweenAgent();
          this.transfersBetweenAgentSelected = [];
        });
    },
    deleteTransfer(id) {
      const payload = {
        id: id,
      };
      this.$store.dispatch(DELETE_TRANSFER_BETWEEN_AGENT, payload).then(() => {
        this.$bvToast.toast("Transfer deleted", {
          title: "Success",
          variant: "success",
          solid: true,
        });
        this.getTransfersBetweenAgent();
      });
    },
    downloadTransferExcel() {
      const payload = {
        status: this.status,
      };
      this.$store
        .dispatch(GET_TRANSFER_BETWEEN_AGENT_EXCEL, payload)
        .then((response) => {
          const blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = this.formatFileName("transfer-between-agents");
          link.click();
        });
    },

    formatFileName(name) {
      let fileName = "";
      const today = new Date();
      fileName = `${name}-${today.getFullYear()}-${today.getMonth()}-${today.getDate()}-${today.getHours()}-${today.getMinutes()}-${today.getSeconds()}`;
      return fileName;
    },
    resetSelected() {
      this.transfersBetweenAgentSelected = [];
    },
  },
};
</script>

<style></style>
