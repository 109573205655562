<template>
  <b-modal
    id="bulk-transfer-modal"
    ref="modal"
    modal-class="modal-fullscreen"
    centered
    scrollable
    size="lg"
    title="Bulk transfer"
    hide-footer
  >
    <BulkTransferForm />
  </b-modal>
</template>

<script>
import BulkTransferForm from "./BulkTransferForm.vue";
export default {
  components: { BulkTransferForm },
};
</script>

<style>
</style>
