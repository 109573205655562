<template>
  <div>
    <b-alert
      dismissible
      variant="warning"
      :show="
        vinNumbersNotFound.length > 0 ||
          vinNumbersSold.length > 0 ||
          vinNumbersIncomplete > 0
      "
    >
      <div v-if="vinNumbersNotFound.length">
        <strong class="pt-3">Vin numbers not found:</strong>
        <div
          v-for="item in vinNumbersNotFound"
          :key="item"
        >
          <span class="pl-2"> {{ item }} </span>
          <br>
        </div>
      </div>
      <div v-if="vinNumbersSold.length">
        <strong class="pt-3">Vin numbers sold:</strong>
        <div
          v-for="item in vinNumbersSold"
          :key="item"
        >
          <span class="pl-2"> {{ item }} </span>
          <br>
        </div>
      </div>
      <div v-if="vinNumbersIncomplete.length">
        <strong class="pt-3">Vin numbers incomplete register:</strong>
        <div
          v-for="item in vinNumbersIncomplete"
          :key="item"
        >
          <span class="pl-2"> {{ item }} </span>
          <br>
        </div>
      </div>
    </b-alert>
    <b-row class="mt-4">
      <b-col class="col-centered">
        <img
          class="img-logo mb-4 mx-auto d-block"
          src="@/assets/img/archivo.png"
          alt="logo"
          accept=".xls, .xlsx, .csv"
        >
        <b-form-select
          v-if="update"
          v-model="statusSelected"
          :options="statusOptions"
          class="mb-2"
        >
          <template v-slot:first>
            <b-form-select-option :value="null">
              Please select a status
            </b-form-select-option>
          </template>
        </b-form-select>
        <table>
          <tr>
            <th>
              <b-form-file
                v-model="file"
                class="input-border-yellow mb-3"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                plain
              />
            </th>
            <th>
              <b-button
                variant="success"
                class="mb-3 ml-1"
                block
                :disabled="
                  !file ||
                    (update && !statusSelected) ||
                    uploadFileStatus === 'fetching' ||
                    updateVehiclesStatus === 'fetching'
                "
                @click="sendFile"
              >
                <span
                  v-if="
                    uploadFileStatus === 'fetching' ||
                      updateVehiclesStatus === 'fetching'
                  "
                >
                  <b-spinner
                    label="Spinning"
                    small
                  />
                </span>
                <span v-else-if="upload">Upload</span>
                <span v-else>Update</span>
              </b-button>
            </th>
          </tr>
        </table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  POST_UPLOAD_FILE,
  POST_UPDATE_VEHICLES_STATUS_FROM_FILE
} from './actions';
export default {
  props: {
    upload: {
      type: Boolean,
      default: false
    },
    update: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      file: null,
      statusOptions: [
        { value: 'Draft', text: 'Draft' },
        { value: 'ReadyToSell', text: 'Ready To Sell' },
        { value: 'Inactive', text: 'Inactive' }
      ],
      statusSelected: null,
      vinNumbersNotFound: [],
      vinNumbersSold: [],
      vinNumbersIncomplete: []
    };
  },
  computed: {
    ...mapState({
      uploadFileStatus: (state) =>
        state.adminStore.vehicles.carRegistry.uploadFile.status,
      updateVehiclesStatus: (state) =>
        state.adminStore.vehicles.carRegistry.updateVehicleStatusFromFile
          .status
    })
  },
  methods: {
    fileNotUpladed() {
      this.toastMessage('The file could not be uploaded', 'Warning', 'warning');
    },
    sendFile() {
      if (this.upload) {
        this.uploadFile();
      } else if (this.update) {
        this.updateVehicleStatus();
      }
    },
    uploadFile() {
      const data = new FormData();
      data.set('file', this.file);
      this.$store
        .dispatch(POST_UPLOAD_FILE, data)
        .then(() => {
          this.toastMessage(
            'The file uploaded successfully',
            'Success',
            'success'
          );
          this.file = null;
        })
        .catch(() => {
          this.fileNotUpladed();
        });
    },
    updateVehicleStatus() {
      this.vinNumbersNotFound = [];
      this.vinNumbersSold = [];
      const data = new FormData();
      data.set('status', this.statusSelected);
      data.set('file', this.file);
      this.$store
        .dispatch(POST_UPDATE_VEHICLES_STATUS_FROM_FILE, data)
        .then(({ vinNumberNotFound, vinNumberSold, incompleteRegister }) => {
          this.toastMessage('Updated successfully', 'Success', 'success');

          if (vinNumberNotFound.length)
            this.vinNumbersNotFound = vinNumberNotFound;

          if (vinNumberSold.length) this.vinNumbersSold = vinNumberSold;

          if (incompleteRegister)
            this.vinNumbersIncomplete = incompleteRegister;

          this.statusSelected = null;
          this.file = null;
        })
        .catch(() => {
          this.fileNotUpladed();
        });
    }
  }
};
</script>

<style scoped>
.input-border-yellow {
  border: solid #35a4dd 2px;
  border-radius: 6px;
}
.img-logo {
  max-width: 100px;
  max-height: 92;
}
</style>
