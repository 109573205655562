<template>
  <div class="container-fluid mt-3">
    <b-tabs content-class="">
      <b-tab title="Successful" active @click="onChangeStatus(1)">
        <b-table
          show-empty
          bordered
          small
          stacked="md"
          head-variant="light"
          :items="vehicles.data || []"
          :fields="fields"
          :busy="vehiclesStatus == 'fetching'"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" variant="yellow" />
            </div>
          </template>

          <template #cell(actions)="{ item }">
            <b-icon
              class="mx-1 cursor-pointer"
              icon="eye"
              @click="openLink(item)"
              scale="1.3"
            />
          </template>
        </b-table>
        <b-pagination
          :value="vehicles ? vehicles.currentPage : 1"
          :total-rows="vehicles ? vehicles.total : 0"
          :per-page="vehicles ? vehicles.perPage : 1"
          class="mb-2 mt-2"
          align="end"
          @change="changePage($event)"
        />
      </b-tab>
      <b-tab title="Failed" @click="onChangeStatus(0)">
        <b-row v-if="currentStatus == 0">
          <b-col sm="12" md="8" lg="8" xl="8">
            <iframe
              v-if="!isLoadingVehicles"
              ref="pdfViewer"
              :src="vehicles.data[0]?.link"
              width="100%"
              height="750vh"
              frameborder="0"
              scrolling="no"
            ></iframe>
            <!-- loader -->
            <div v-if="isLoadingVehicles" class="text-center">
              <b-spinner
                style="width: 5rem; height: 5rem"
                class="mx-auto"
                variant="yellow"
                size="lg"
              />
            </div>
          </b-col>
          <b-col sm="12" md="4" lg="4" xl="4">
            <b-pagination
              :value="vehicles ? vehicles.currentPage : 1"
              :total-rows="vehicles ? vehicles.total : 0"
              :per-page="vehicles ? vehicles.perPage : 1"
              class="mb-2 mt-2"
              align="end"
              @change="changePage($event)"
            />
            <b-form @submit.stop.prevent="updateExport()">
              <b-form-group>
                <template slot="label">
                  Vehicle <span class="text-danger"> * </span>
                </template>
                <multiselect
                  v-model="form.vehicle"
                  :options="vehiclesList"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="false"
                  placeholder="Pick some option"
                  label="title"
                  track-by="vinNumber"
                  :preselect-first="false"
                  :multiple="vehicles.data[0]?.type == 'file7501'"
                >
                  <template slot="tag" slot-scope="{ option, remove }"
                    ><span class="custom__tag"
                      ><span class="">{{ option.vinNumber }}</span
                      ><span class="ml-1 custom__remove" @click="remove(option)"
                        >❌</span
                      ></span
                    ></template
                  >
                </multiselect>
              </b-form-group>
              <b-form-group>
                <template slot="label"> Received Date </template>
                <b-form-datepicker
                  v-model="form.receivedDate"
                  :max="new Date()"
                ></b-form-datepicker>
              </b-form-group>
              <b-form-group v-if="vehicles.data[0]?.type == 'file7501'">
                <template slot="label"> Stamp date 7501 </template>
                <b-form-datepicker
                  v-model="form.stampDate7501"
                  :max="new Date()"
                ></b-form-datepicker>
              </b-form-group>
              <b-form-group>
                <template slot="label"> Title Number In </template>
                <b-form-input v-model="form.titleNumberIn"></b-form-input>
              </b-form-group>
              <b-form-group>
                <template slot="label"> Title Origin In </template>
                <multiselect
                  v-model="form.titleOriginIn"
                  :options="states"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="false"
                  placeholder="Pick some option"
                  label="name"
                  track-by="name"
                  :preselect-first="false"
                >
                  <template slot="tag" slot-scope="{ option, remove }"
                    ><span class="custom__tag"
                      ><span class="">{{ option.name }}</span
                      ><span class="ml-1 custom__remove" @click="remove(option)"
                        >❌</span
                      ></span
                    ></template
                  >
                </multiselect>
              </b-form-group>
              <b-form-group>
                <template slot="label"> Miles In </template>
                <b-form-input v-model="form.milesIn"></b-form-input>
              </b-form-group>

              <b-row>
                <b-col>
                  <b-button
                    type="button"
                    variant="outline-danger"
                    class="mt-3"
                    block
                    @click="discardTitle(vehicles.data[0].id)"
                    >Discard</b-button
                  >
                </b-col>
                <b-col>
                  <b-button
                    type="submit"
                    variant="success"
                    class="mt-3"
                    block
                    :disabled="isUpdating || !form.vehicle"
                    >Update</b-button
                  >
                </b-col>
              </b-row>
            </b-form>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import { mapState } from "vuex";
import {
  DISCARD_TITLE,
  GET_TITLE_FILES_VEHICLES,
  UPDATE_EXPORTED_TITLE,
} from "./actions";
import Multiselect from "vue-multiselect";

export default {
  name: "TitleManagerVehiclesModal",
  components: {
    Multiselect,
  },
  computed: {
    ...mapState({
      vehicles: (state) =>
        state.superAdminStore.titleManager.titleVehicles.data,
      vehiclesStatus: (state) =>
        state.superAdminStore.titleManager.titleVehicles.status,
      vehiclesList: (state) => state.superAdminStore.tasks.vehicles.data,
      states: (state) =>
        state.superAdminStore.componentStore.exports.states.data,
      isLoadingVehicles: (state) =>
        state.superAdminStore.titleManager.titleVehicles.status === "fetching",
      isUpdating: (state) =>
        state.superAdminStore.titleManager.title.status === "fetching",
    }),
  },
  data() {
    return {
      fields: [
        {
          key: "stockNumber",
          label: "Stock Number",
          class: "text-center",
        },
        {
          key: "vinNumber",
          label: "VIN Number",
          class: "text-center",
        },
        {
          key: "actions",
          label: "Actions",
          class: "text-center",
        },
      ],
      form: {
        vehicle: null,
        receivedDate: null,
        titleNumberIn: null,
        titleOriginIn: null,
        milesIn: null,
        stampDate7501: null,
      },
      currentStatus: 1,
    };
  },
  mounted() {
    this.getStates();
    this.getVehiclesAndUsers();
    this.getVehicles();
  },
  methods: {
    getVehiclesAndUsers() {
      this.$store
        .dispatch("GET_VEHICLES_AND_USERS", {
          vehicle: true,
        })
        .then(() => {});
    },
    getVehicles(status = 1, page = 1) {
      this.$store
        .dispatch(GET_TITLE_FILES_VEHICLES, {
          id: this.$route.params.id,
          status,
          resourcesPerPage: status == 1 ? 20 : 1,
          page,
        })
        .then(() => {
          this.preSelectVehicles();
        });
    },
    openLink(item) {
      window.open(item.link, "_blank");
    },
    onChangeStatus(status) {
      this.currentStatus = status;
      this.getVehicles(status);
    },
    changePage(page) {
      this.getVehicles(0, page);
    },
    getStates() {
      this.$store.dispatch("GET_TITLE_ORIGIN").then(() => {});
    },
    updateExport() {
      const payload = this.formatPayload();
      this.$store.dispatch(UPDATE_EXPORTED_TITLE, payload).then(() => {
        this.$bvToast.toast("Title updated", {
          title: "Success",
          variant: "success",
          solid: true,
        });
        this.resetForm();
        this.getVehicles(0);
      });
    },
    formatPayload() {
      return {
        id: this.vehicles.data[0].id,
        vehicleId:
          this.vehicles.data[0].type == "file7501"
            ? this.form.vehicle
            : this.form.vehicle.id,
        receivedDate: this.form.receivedDate,
        titleNumberIn: this.form.titleNumberIn,
        titleOriginIn: this.form.titleOriginIn.name,
        milesIn: this.form.milesIn,
        stampDate7501: this.form.stampDate7501,
      };
    },
    discardTitle(id) {
      this.discardAlert().then((value) => {
        if (value) {
          this.$store.dispatch(DISCARD_TITLE, { id }).then(() => {
            this.$bvToast.toast("Title discarded", {
              title: "Success",
              variant: "success",
              solid: true,
            });
            this.getVehicles(0);
          });
        }
      });
    },
    discardAlert() {
      return this.$bvModal.msgBoxConfirm(
        "Are you sure to discard this title?",
        {
          title: "Discard",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        }
      );
    },
    resetForm() {
      this.form = {
        vehicle: null,
        receivedDate: null,
        titleNumberIn: null,
        titleOriginIn: null,
        milesIn: null,
      };
    },
    preSelectVehicles() {
      if (this.vehicles.data[0]?.vehicles) {
        this.form.vehicle = this.vehicles.data[0].vehicles.map((vehicle) => {
          return this.vehiclesList.find((v) => v.id == vehicle.id);
        });
      }
    },
  },
};
</script>
