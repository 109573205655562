<template>
  <div>
    <FilterTypeDate fromType="saleDateFrom" toType="saleDateTo" />
  </div>
</template>

<script>
import FilterTypeDate from "./FilterTypeDate.vue";

export default {
  name: "SaleDateFilter",
  components: { FilterTypeDate },
};
</script>

<style></style>
