<template>
  <div class="container">
    <div class="d-flex justify-content-between">
      <div class="h5"><i>Vehicle information</i></div>
      <div>
        <font-awesome-icon
          class="icon-color cursor-pointer"
          size="xl"
          :icon="['far', 'clouds']"
          @click="openModal()"
        />
      </div>
    </div>

    <div class="d-flex justify-content-between">
      <span> Marketplace:</span>
      <span>{{ expensesInformation.marketPlace }}</span>
    </div>
    <div
      v-if="expensesInformation && expensesInformation.referencePrice"
      class="d-flex justify-content-between"
    >
      <span>Suggested sale price:</span>
      <span
        :class="{
          'text-danger':
            expensesInformation.referencePrice <
            expensesInformation.startingPrice,
        }"
        >{{ expensesInformation.referencePrice | currency }}
        {{ corporation.typeCurrency }}</span
      >
    </div>
    <div
      v-if="
        expensesInformation &&
        expensesInformation.fx &&
        expensesInformation.marketPlace == 'CANAM'
      "
      class="d-flex justify-content-between"
    >
      <span>FX:</span>
      <span>{{ fxTwoDecimals }} </span>
    </div>

    <div v-if="expensesInformation" class="d-flex justify-content-between">
      <span>Purchase cost:</span>
      <span
        >{{ expensesInformation.startingPrice | currency }}
        {{ corporation.typeCurrency }}</span
      >
    </div>
    <div v-if="expensesInformation" class="d-flex justify-content-between">
      <span>Addons:</span>

      <span
        >{{ expensesInformation.totalAddons | currency }}
        {{ corporation.typeCurrency }}
      </span>
    </div>
    <!-- total cost -->
    <div v-if="expensesInformation" class="d-flex justify-content-between">
      <span>Total cost:</span>
      <span
        >{{
          (expensesInformation.totalAddons + expensesInformation.startingPrice)
            | currency
        }}
        {{ corporation.typeCurrency }}
      </span>
    </div>
    <div
      v-if="expensesInformation.salePrice"
      class="d-flex justify-content-between"
    >
      <span>Sale price:</span>
      <span
        >{{ expensesInformation.salePrice | currency }}
        {{ corporation.typeCurrency }}
      </span>
    </div>
    <div
      v-if="expensesInformation.profit"
      class="d-flex justify-content-between"
    >
      <span>Profit:</span>
      <span
        >{{ expensesInformation.profit | currency }}
        {{ corporation.typeCurrency }}
      </span>
    </div>

    <ExpensesSummaryByAddonType
      v-if="expensesInformation.addons"
      :addons="expensesInformation.addons"
      class="mt-2"
    />
    <PricesModal />
  </div>
</template>

<script>
import ExpensesSummaryByAddonType from "./ExpensesSummaryByAddonType.vue";
import PricesModal from "../prices/PriceModal.vue";
import { mapState } from "vuex";
export default {
  props: {
    expensesInformation: {
      type: Object,
      required: true,
    },
  },
  components: { ExpensesSummaryByAddonType, PricesModal },
  computed: {
    ...mapState({
      profile: (state) => state.auth.loginStore.profile.data,
    }),
    fxTwoDecimals() {
      /* return using 2 decimals trim*/
      const fx = this.expensesInformation.fx.toString();
      const fxArray = fx.split(".");
      const fxArrayLength = fxArray.length;
      if (fxArrayLength > 1) {
        const fxArrayLast = fxArray[fxArrayLength - 1];
        if (fxArrayLast.length > 2) {
          return fxArray[0] + "." + fxArrayLast.substring(0, 2);
        }
      }
      return fx;
    },
    corporation() {
      const corporation = localStorage.getItem("corporation");
      return this.profile.user.corporationPermissions.find(
        (c) => c.id == corporation
      );
    },
  },
  methods: {
    openModal() {
      this.$bvModal.show("prices-modal");
    },
  },
};
</script>

<style></style>
