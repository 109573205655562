import boards from "./components/store"
function getDefaultState() {
  return {
    searchFunction: null,
    addFunction: null
  };
}

const mutations = {
  setSearchFunction: (state, searchFunction) => {
    state.searchFunction = searchFunction;
  },
  setAddFunction: (state, addFunction) => {
    state.addFunction = addFunction;
  }

};

/* get search function */
const getters = {
  getSearchFunction: state => {
    return state.searchFunction;
  },
  getAddFunction: state => {
    return state.addFunction;
  }

};



export default {
  state: getDefaultState(),
  mutations,
  getters,
  modules: {
    boards
  }
};