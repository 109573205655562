<template>
  <div class="pb-2">
    <b-row class="mb-4 mr-1 ml-1">
      <b-col
        xs="12"
        sm="12"
        md="12"
        lg="4"
        xl="4"
        class="d-flex justify-content-center mt-4"
      >
        <slot name="leftSide" />
      </b-col>

      <b-col xs="12" sm="12" md="12" lg="4" xl="4" class="mt-4">
        <!--         <b-input-group
          v-if="showFilter"
          size="sm"
          class="shadows rounded-right rounded-left align-middle bg-dark-green"
        >
          <b-form-input
            id="filterInput"
            v-model="filter"
            class="filter-input rounded-right rounded-left"
            type="search"
            @change="getDataFilter()"
          />
          <b-input-group-append class="rounded-right rounded-left">
            <b-icon
              icon="search"
              variant="yellow"
              class="h5 m-2 align-middle"
            />
          </b-input-group-append>
        </b-input-group> -->
      </b-col>

      <b-col xs="12" sm="12" md="12" lg="4" xl="4" class="text-center mt-4">
        <slot name="rightSide" />
      </b-col>
    </b-row>
    <div class="container-fluid">
      <b-table
        id="tableMain"
        thead-class="text-center bg-light-grey "
        head-variant="light"
        bordered
        small
        show-empty
        outlined
        stacked="md"
        :fields="fields"
        :items="items.data"
        :busy="busy"
        class="text-break"
      >
        <template #empty>
          <div class="text-center">
            <h6>No records</h6>
          </div>
        </template>
        <template #table-busy>
          <div class="text-center text-1D3030 my-2">
            <b-spinner variant="yellow" class="align-middle" />
            <p>Loading</p>
          </div>
        </template>
        <template #cell(selected)="{ item }">
          <div class="d-flex justify-content-center">
            <b-form-checkbox @change="(val) => onRowSelected(val, item)" />
          </div>
        </template>
        <template v-slot:cell(actions)="row" class="text-center">
          <div class="text-center">
            <b-form-group>
              <b-icon v-if="row.field.telephone" icon="telephone" class="h5 m-2" />
              <b-icon v-if="row.field.mail" icon="envelope" class="h5 m-2" />
              <b-icon
                v-if="row.field.validation"
                icon="card-checklist"
                title="Validations"
                class="h5 m-2"
                @click="openValidationModal(row.item, options.fun)"
              />
              <b-icon
                v-if="row.field.details"
                v-b-tooltip.hover
                title="Details"
                icon="eye"
                class="h5 m-2"
                @click="editItem(row.item)"
              />
              <b-icon
                v-if="row.field.delete"
                v-b-tooltip.hover
                title="Delete"
                :icon="row.item.status !== 1 ? 'toggle-off' : 'toggle-on'"
                class="h5 m-2"
                @click="
                  row.item.status !== 1 ? activateItem(row.item) : deleteItem(row.item)
                "
              />
              <b-icon
                v-if="row.field.hardDelete"
                v-b-tooltip.hover
                title="Delete"
                icon="trash"
                class="h5 m-2"
                @click="deleteItem(row.item)"
              >
              </b-icon>
              <font-awesome-icon
                v-if="row.field.locations"
                :icon="['far', 'location-dot']"
                class="mb-2 mx-1 cursor-pointer"
                @click="goToLocation(row.item)"
              />
              <font-awesome-icon
                v-b-tooltip.hover
                title="See cars"
                v-if="row.field.cars"
                :icon="['far', 'cars']"
                class="mb-2 mx-1 cursor-pointer"
                @click="goToCars(row.item)"
              />
            </b-form-group>
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="d-flex justify-content-between"
        >
          <span>
            <p v-show="itemsSelected.length > 0">Selected: {{ itemsSelected.length }}</p>
          </span>
          <b-pagination
            v-if="items.total > 0"
            v-model="table.currentPage"
            :total-rows="table.totalRows"
            :per-page="table.perPage"
            class="mb-2"
            @change="changePage($event)"
          />
        </b-col>
      </b-row>
    </div>
    <ValidationModal />
  </div>
</template>
<script>
import { generalMixin } from "@/modules/mixin.js";
import { SET_COMPANY_EMAIL } from "./validation/actions";
import store from "@/store";
import ValidationModal from "@/modules/shared/validation/validationModal.vue";
import { validationMixin } from "@/modules/shared/validation/mixin.js";

export default {
  components: { ValidationModal },
  mixins: [generalMixin, validationMixin],
  props: {
    fields: Array,
    items: {},
    options: {},
    selectable: Boolean,
    busy: Boolean,
    showFilter: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      filter: null,
      roles: [
        { text: "agent", value: 1 },
        { text: "representative", value: 2 },
      ],
      roleSelected: null,
      itemsSelected: [],
    };
  },
  computed: {
    table() {
      return {
        totalRows: this.items.total,
        currentPage: this.items.currentPage,
        perPage: this.items.perPage,
      };
    },
  },
  methods: {
    deleteItem(item) {
      this.$parent.deleteItem(item);
    },
    editItem(item) {
      this.$parent.editItem(item);
    },
    activateItem(item) {
      this.$parent.activateItem(item);
    },
    showModal() {
      this.$parent.$refs[store.state.general.modalId].show();
    },

    openValidationModal(item, fun) {
      this.$store.commit("setReady", false);

      if (fun) {
        this.$store.commit("setRefreshData", fun);
      }

      this.$store.commit("setItemUser", item);

      this.$store.commit(SET_COMPANY_EMAIL, item.user.email);

      this.$bvModal.show("validation-modal");
    },
    changePage(page) {
      this.$parent.changePage(page);
    },
    getDataFilter() {
      this.$parent.getData(this.filter);
    },
    goToLocation(item) {
      this.$parent.goToLocation(item);
    },
    goToCars(item){
      this.$parent.goToCars(item);
    },
    onRowSelected(val, item) {
      if (val) {
        this.itemsSelected.push(item);
      } else {
        const index = this.itemsSelected.indexOf(item);
        this.itemsSelected.splice(index, 1);
      }
      this.$parent.getSelectedItems([...this.itemsSelected]);
    },
  },
  mounted() {
    this.$store.commit("setSearchFunction", this.$parent.getData);
  },
};
</script>
<style lang="scss" scoped>
.filter-input {
  background-color: #1d3030;
  opacity: 1;
  color: white;
  border: none;
}
.form-control:focus {
  background-color: #1d3030;
  color: white;
  border-color: #1d3030;
}
.shadows {
  box-shadow: 0 7px 7px 0 rgba(0, 0, 0, 0.5);
}
</style>
