<template>
  <div>
    <br />
    <RegistrationForm
      v-if="userHasPermission('carfax', 'write')"
      :refreshData="getCarfaxs"
    />
    <b-tabs content-class="">
      <b-tab title="Canada" active @click="changeCountry('Canada')"></b-tab>
      <b-tab
        title="United states"
        @click="changeCountry('United states')"
      ></b-tab>
    </b-tabs>
    <b-list-group v-if="carfaxs.status == 'fetching'" class="mt-2">
      <b-list-group-item class="text-center">
        <b-spinner variant="yellow" />
      </b-list-group-item>
    </b-list-group>
    <b-list-group v-else class="mt-2 mb-2 note-list" tag="ul">
      <b-list-group-item
        v-if="carfaxs.data && !carfaxs.data.data.length"
        class="text-center"
      >
        <b>No carfax to show</b>
      </b-list-group-item>
      <b-list-group-item
        v-for="(carfax, i) in carfaxs.data.data"
        :key="i"
        class="flex-column align-items-start p-0"
        tag="li"
      >
        <div class="accordion" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button
                block
                v-b-toggle.accordion-header="'accordion-1' + i"
                class="text-left"
                variant="dark-green"
              >
                <span class="text-left">
                  {{ getReportNameByType(carfax.reportType) }}
                </span>

                <span> </span>
              </b-button>
            </b-card-header>
            <b-collapse
              :id="'accordion-1' + i"
              accordion="my-accordion"
              role="tabpanel"
            >
              <CarfaxCard :carfax="carfax" :refreshData="getCarfaxs" />
              <CarfaxCard
                v-for="(carfaxHistory, i) in carfax.vehicleCarfaxHistory"
                :key="i"
                :carfax="carfaxHistory"
                :refreshData="getCarfaxs"
              />
            </b-collapse>
          </b-card>
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import CarfaxCard from "./CarfaxCard.vue";
import { GET_CARFAX } from "./actions";
import { mapState } from "vuex";
import RegistrationForm from "./RegistrationForm.vue";
import { superAdminMixin } from "../../../superAdmin/mixins";
export default {
  components: {
    CarfaxCard,
    RegistrationForm,
  },
  mixins: [superAdminMixin],
  computed: {
    ...mapState({
      vehicle: (state) => state.vehicle.vehicleByVin.data,
      carfaxs: (state) => state.vehicle.carfax.carfaxs,
    }),
  },
  data() {
    return {
      check: false,
      country: "Canada",
    };
  },
  methods: {
    openCRPageModal(id) {
      this.$bvModal.show("CR-Page-Modal" + id);
    },

    getCarfaxs() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(GET_CARFAX, payload)
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    },
    formatPayload() {
      const payload = {
        id: this.vehicle.data.id,
        check: false,
        country: this.country,
      };
      return payload;
    },
    getReportNameByType(type) {
      switch (type) {
        case 1:
          return "RDPRM";
        case 4:
          return "Claims";
        case 2:
          return "Verified";
        case 3:
          return "Carproof";
        default:
          break;
      }
      return "";
    },
    changeCountry(country) {
      this.country = country;
      this.getCarfaxs();
    },
  },
  mounted() {
    this.getCarfaxs();
  },
};
</script>

<style></style>
