<template>
  <div
    v-touch:touchhold="touchHoldHandler"
    @contextmenu.prevent="$refs.menuReleaseDate.open($event, 'Payload')"
  >
    <slot></slot>
    <ContextMenu ref="menuReleaseDate">
      <template slot-scope>
        <ContextMenuItem @click.native="updateReleaseDate()">
          Set release date as today
        </ContextMenuItem>
      </template>
    </ContextMenu>
  </div>
</template>

<script>
import { superAdminMixin } from "../../mixins";
import ContextMenu from "../contextMenu/ContextMenu.vue";
import ContextMenuItem from "../contextMenu/ContextMenuItem.vue";
export default {
  name: "FilesContextMenu",
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
    refreshData: {
      type: Function,
      required: true,
    },
  },
  mixins: [superAdminMixin],
  components: {
    ContextMenu,
    ContextMenuItem,
  },

  methods: {
    updateReleaseDate() {
      const todayDate = new Date().toISOString().slice(0, 10);

      const payload = {
        releaseDate: todayDate,
        vehicles: [{ id: this.vehicle.id }],
      };

      this.$store
        .dispatch("POST_VEHICLES_RELEASE_DATE", payload)
        .then(() => {
          this.toastMessage(
            "Release date updated successfully",
            "Success",
            "success"
          );
          this.$refs.menuReleaseDate.close();
          this.updateVehicleReleaseDate(this.vehicle.id, payload.releaseDate);
        })
        .catch(() => {
          this.toastMessage("Release date not updated ", "Warning", "warning");
        });
    },
    touchHoldHandler($event) {
      this.$refs.menuReleaseDate.open($event, "Payload");
    },
  },
};
</script>

<style>
</style>