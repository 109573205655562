var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-fluid"},[_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('h4',{staticClass:"ml-3"},[_vm._v("Active Checks")])])],1),(_vm.totalMoneySelected > 0)?_c('div',{staticClass:"text-right"},[_vm._v(" Total selected: "+_vm._s(_vm._f("currency")(_vm.calculateTotal()))+" ")]):_vm._e(),_c('b-row',{staticClass:"my-2"},[_c('b-col',{staticClass:"text-center mt-4",attrs:{"xs":"12","sm":"12","md":"12","lg":"4","xl":"4"}}),_c('b-col',{staticClass:"text-center mt-4",attrs:{"xs":"12","sm":"12","md":"12","lg":"4","xl":"4"}}),_c('b-col',{staticClass:"text-right mt-4",attrs:{"xs":"12","sm":"12","md":"12","lg":"4","xl":"4"}},[(_vm.userHasPermission('buyChecks', 'write'))?_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.markChecksAsDelivered()}}},[_vm._v(" Mark as delivered ")]):_vm._e()],1)],1),_c('div',[_c('b-table',{attrs:{"show-empty":"","bordered":"","small":"","stacked":"md","head-variant":"light","items":(_vm.checks.data &&
            _vm.checks.data.payments &&
            _vm.checks.data.payments.data) ||
          [],"fields":_vm.fields,"busy":_vm.checks.status === 'fetching'},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"variant":"yellow"}})],1)]},proxy:true},{key:"cell(selected)",fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-form-checkbox',{attrs:{"disabled":!_vm.userHasPermission('buyChecks', 'write')},on:{"change":(val) => _vm.onRowSelected(val, item)}})],1)]}},{key:"cell(cost)",fn:function(row){return [_c('div',{},[_vm._v(" "+_vm._s(_vm._f("currency")(row.item.cost))+" ")])]}}])}),_c('div',{staticStyle:{"float":"right"}},[(_vm.checks.status != 'fetching')?_c('b-pagination',{staticClass:"mb-2",attrs:{"value":(_vm.checks.data &&
              _vm.checks.data.payments &&
              _vm.checks.data.payments.currentPage) ||
            1,"total-rows":(_vm.checks.data &&
              _vm.checks.data.payments &&
              _vm.checks.data.payments.total) ||
            0,"per-page":(_vm.checks.data &&
              _vm.checks.data.payments &&
              _vm.checks.data.payments.perPage) ||
            0},on:{"change":function($event){return _vm.changePage($event)}}}):_vm._e()],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }