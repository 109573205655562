<template>
  <div class="container">
    <b-form
      class="m-4"
      @submit.stop.prevent="form.id ? update() : save()"
      v-if="isFirstPart"
    >
      <h3 class="text-purple-yellow">
        <b-icon icon="inboxes" font-scale="1.20" />
      </h3>
      <b-row>
        <!-- Export location -->
        <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
          <b-form-group label-size="md">
            <template slot="label">
              <div>Location</div>
            </template>
            <vue-single-select
              :classes="{ input: 'form-control', dropdown: 'dropdown' }"
              v-model="exportLocation"
              :options="exportLocations.locations"
              optionLabel="name"
            ></vue-single-select>
          </b-form-group>
        </b-col>
        <!-- CArrier -->
        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="md">
            <template slot="label">
              <b-form-radio-group
                id="radio-group-2"
                v-model="typeCarrier"
                name="radio-sub-component"
              >
                <b-form-radio value="supplier">Provider</b-form-radio>
                <b-form-radio value="driver"> Driver </b-form-radio>
              </b-form-radio-group>
            </template>

            <vue-single-select
              v-if="typeCarrier == 'supplier'"
              :classes="{ input: 'form-control', dropdown: 'dropdown' }"
              v-model="form.supplier"
              :options="suppliers"
              optionLabel="companyName"
            ></vue-single-select>
            <vue-single-select
              v-if="typeCarrier == 'driver'"
              :classes="{ input: 'form-control', dropdown: 'dropdown' }"
              v-model="form.driver"
              :options="drivers"
              :getOptionDescription="() => driverName"
            >
              <template slot="option" slot-scope="{ option }">
                <div>{{ option.firstName }} {{ option.lastName }}</div>
              </template>
            </vue-single-select>
          </b-form-group>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
          <b-form-group label-size="md">
            <template slot="label">
              <div>Reference number</div>
              <b-form-input
                required
                :value="form.referenceNumber"
                size="sm"
                name="referenceNumber"
                maxlength="65"
                readonly
              />
            </template>
          </b-form-group>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
          <b-form-group label-size="md">
            <template slot="label">
              <div>Desired date of import</div>
              <b-form-input v-model="form.desiredDate" type="date" size="sm" />
            </template>
          </b-form-group>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
          <b-form-group label-size="md">
            <template slot="label">
              <div>R.I.</div>
              <vue-single-select
                :classes="{ input: 'form-control', dropdown: 'dropdown' }"
                v-model="form.riId"
                :options="riCompanies"
                optionLabel="companyName"
              ></vue-single-select>
            </template>
          </b-form-group>
        </b-col>
      </b-row>
      <VehiclesList
        v-if="form.exportLocation"
        @vehicles-selected="setVehiclesSelected"
        :vehiclesPreSelected="vehiclesPreSelected"
        class="h-25"
      />

      <b-row />
      <b-row class="mt-3">
        <b-col sm="12" md="6" lg="6" xl="6" />
        <b-col sm="12" md="3" lg="3" xl="3" />
        <b-col sm="12" md="3" lg="3" xl="3">
          <b-btn
            v-if="userHasPermission('exportLoads', 'write')"
            block
            size="lg"
            type="submit"
            class="btn btn-success"
            :disabled="$v.form.$invalid"
          >
            Continue
            <b-spinner
              v-if="vehicles.status == 'fetching'"
              small
              variant="white"
            />
          </b-btn>
        </b-col>
      </b-row>
    </b-form>

    <ForeignForm
      v-else
      @back="back()"
      :exportLoad="exportLoadItem.id ? exportLoadItem : exportLoadRegistered"
      :refreshData="refreshData"
      :hideModal="hideModal"
    />
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import {
  POST_LOAD,
  GET_VEHICLES_LOAD,
  GET_EXPORT_LOCATIONS_LIST,
} from "./actions";
import { mapState } from "vuex";
import { generalMixin } from "@/modules/mixin.js";
import VehiclesList from "./VehiclesList.vue";
import VueSingleSelect from "vue-single-select";
import { superAdminMixin } from "../mixins";
import ForeignForm from "./ForeignForm.vue";

export default {
  mixins: [generalMixin, superAdminMixin],
  props: ["exportLoadItem", "refreshData", "hideModal"],
  components: {
    VehiclesList,
    VueSingleSelect,
    ForeignForm,
  },
  computed: {
    ...mapState({
      vehicles: (state) => state.superAdminStore.exportLoad.vehicles,
      exportLoad: (state) => state.superAdminStore.exportLoad.exportLoad,
      exportLocations: (state) =>
        state.superAdminStore.exportLoad.exportLocations.data,
      riCompanies: (state) => state.superAdminStore.riCompanies.companies.data,
    }),
    vehiclesPreSelected() {
      if (this.exportLoadItem) {
        return this.exportLoadItem.vehicles;
      }
      return [];
    },
    driverName() {
      if (this.form.driver) {
        return `${this.form.driver.firstName} ${this.form.driver.lastName}`;
      }
      return "";
    },
  },

  data() {
    return {
      form: {
        id: null,
        list: [],
        exportLocation: null,
        supplier: { test: "a" },
        driver: null,
        referenceNumber: null,
      },
      vehiclesSelected: [],
      typeCarrier: "supplier",
      isFirstPart: true,
      exportLocation: null,
      exportLoadRegistered: {},
      suppliers: [],
      drivers: [],
    };
  },
  validations: {
    form: {
      exportLocation: {
        required,
      },
      supplier: {},
      driver: {},
    },
  },
  created() {
    this.getSuppliers();
    this.getDrivers();
  },
  mounted() {
    this.getRiCompanies();
    if (this.exportLoadItem.id) {
      this.formatForm();
    } else {
      this.resetForm();
      this.form.referenceNumber = this.exportLocations.referenceNumber;
    }
  },
  watch: {
    exportLocation() {
      if (this.exportLocation) {
        this.form.exportLocation = this.exportLocation;

        this.getVehicles();
      }
    },
  },
  methods: {
    back() {
      this.isFirstPart = true;
    },
    closeModal(callback) {
      callback();
    },
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    save() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_LOAD, payload)
        .then((response) => {
          this.exportLoadRegistered = response.exportLoad;
          this.makeToast(
            "Success",
            "Export load created successfully",
            "success"
          );
          this.form = {};
          this.continueModal();
          this.refreshData();
          this.getExportLocations();
          /*           this.hideModal();
           */
        })
        .catch(() => {
          this.$root.$bvToast.toast("Internal server error, try again later", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    update() {
      if (this.form.status == 2 || this.form.status == 3) {
        this.continueModal();
        return;
      }
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_LOAD, payload)
        .then(() => {
          this.makeToast(
            "Success",
            "Export load updated successfully",
            "success"
          );
          this.continueModal();
          this.refreshData();
          this.getExportLocations();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Internal server error, try again later", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    editItem(item) {
      this.exportLoadItem = item;
      this.$bvModal.show("detailsModal");
    },

    formatPayload() {
      const payload = {
        id: this.exportLoadItem.id ? this.exportLoadItem.id : null,
        locationId: this.exportLocation.id,
        list: this.vehiclesSelected,
        referenceNumber: this.form.referenceNumber,
        riId: this.form.riId,
      };
      if (this.form.supplier != null) {
        payload.supplierCompanyId = this.form.supplier.id;
      }

      if (this.form.driver != null) {
        payload.driverId = this.form.driver.id;
      }
      payload.desiredDate = this.form.desiredDate;

      return payload;
    },

    formatForm() {
      if (this.exportLoadItem.id) {
        this.form.id = this.exportLoadItem.id;
        this.exportLocation = this.exportLocations.locations.find(
          (item) => item.id == this.exportLoadItem.locationsId
        );
        if (this.exportLoadItem.supplierCompanyId) {
          this.typeCarrier = "supplier";
          this.form.supplier = this.suppliers.find(
            (item) => item.id == this.exportLoadItem.supplierCompanyId
          );
        }
        this.form.status = this.exportLoadItem.status;
        this.form.referenceNumber = this.exportLoadItem.referenceNumber;
        this.form.desiredDate = this.exportLoadItem.desiredDate;
        this.form.referenceNumber = this.exportLoadItem.referenceNumber;
      }
    },

    setVehiclesSelected(vehicles) {
      this.vehiclesSelected = vehicles;
    },
    getVehicles() {
      this.$store.dispatch(GET_VEHICLES_LOAD, {
        location: this.form.exportLocation.name,
      });
    },
    resetForm() {
      this.form = {
        id: null,
        list: [],
        exportLocation: null,
        supplier: null,
        driver: null,
        referenceNumber: null,
        desiredDate: null,
      };
      this.exportLocation = null;
    },
    getSuppliers() {
      this.$store
        .dispatch("GET_COMPANIES_LIST", { isSupplier: 1 })
        .then((response) => {
          this.suppliers = response;
          if (this.exportLoadItem.supplierCompanyId) {
            this.typeCarrier = "supplier";
            this.form.supplier = this.suppliers.find(
              (item) => item.id == this.exportLoadItem.supplierCompanyId
            );
          }
        })
        .catch(() => {
          this.$root.$bvToast.toast("Could not get the suppliers list ", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    getDrivers() {
      this.$store
        .dispatch("GET_DRIVER_LIST_NO_PAGINATE")
        .then((response) => {
          this.drivers = response.data;
          if (this.exportLoadItem.driversId) {
            this.typeCarrier = "driver";
            this.form.driver = this.drivers.find(
              (item) => item.id == this.exportLoadItem.driversId
            );
          }
        })
        .catch(() => {});
    },
    getExportLocations() {
      this.$store.dispatch(GET_EXPORT_LOCATIONS_LIST).then(() => {});
    },
    continueModal() {
      this.isFirstPart = false;
    },
    getRiCompanies() {
      this.$store.dispatch("GET_RI_NO_PAGINATE");
    },
  },
};
</script>

<style lang="scss" scoped>
.append-input {
  max-width: 40%;
}
</style>
