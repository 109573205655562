import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {

    tasks: {
      status: null,
      data: [],
      error: null,
      filters: {
        ownerId: null,
        vehicleId: null,
        companyId: null,
        dateFrom: null,
        dateTo: null,
        dueDateFrom: null,
        dueDateTo: null,
        search: null,
        status: null,
        filterFastLabel: null,
        type: null,
        userId: null,
        boardId: null,
        personalTask: null,
        deleted: null,
      }
    },

    task: {
      status: null,
      data: {},
      error: null,
    },
    vehicles: {
      status: null,
      data: [],
      error: null,
    },
    users: {
      status: null,
      data: [],
      error: null,
    },
    companies: {
      status: null,
      data: [],
      error: null,
    },
    vehicleDetailsSideBar: {
      stockNumber: null,
      isShowing: false,
    },
    filterSideBar: {
      isShowing: false,
    },
    companyDetailsSideBar: {
      company: null,
      isShowing: false,
    },
    taskDetailsSideBar: {
      task: null,
      isShowing: false,
    },
    note: {
      status: null,
      data: {},
      error: null,
    },
    taskLogs: {
      status: null,
      data: [],
      error: null,
    },
    taskTypes: {
      status: null,
      data: [],
      error: null,
    },
    taskTypeByUser: {
      status: null,
      data: {},
      error: null,
    },
    taskListUsers: {
      status: null,
      data: [],
      error: null,
    }


  };
}
const actions = {

  [constants.GET_TASKS]: async ({ commit, state }) => {
    try {
      commit(constants.GET_TASKS);
      const payload = Object.entries(state.tasks.filters).reduce(
        (a, [k, v]) => (v === null || v === "" ? a : { ...a, [k]: v }),
        {}
      );
      const response = await axios.get("task/getTasksCreated", {
        params: payload,
      });
      commit(constants.GET_TASKS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_TASKS_ERROR, error);
      throw error.response;
    }
  },

  [constants.POST_TASK]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_TASK);
      const response = await axios.post("task/createAndUpdate", payload);
      commit(constants.POST_TASK_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_TASK_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_VEHICLES_AND_USERS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_VEHICLES_AND_USERS);

      const response = await axios.get("task/getLists", {
        params: payload,
      });
      commit(constants.GET_VEHICLES_AND_USERS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLES_AND_USERS_ERROR, error);
      throw error.response;
    }
  },
  [constants.DELETE_TASK]: async ({ commit }, payload) => {
    try {
      commit(constants.DELETE_TASK);

      const response = await axios.delete("task/deleteTask",
        {
          params: payload,
        });
      commit(constants.DELETE_TASK_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.DELETE_TASK_ERROR, error);
      throw error.response;
    }
  },
  [constants.MARK_TASK_AS_DONE]: async ({ commit }, payload) => {
    try {
      commit(constants.MARK_TASK_AS_DONE);

      const response = await axios.put("task/finishTask", payload);
      commit(constants.MARK_TASK_AS_DONE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.MARK_TASK_AS_DONE_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_TASK_NOTE]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_TASK_NOTE);

      const response = await axios.post("vehicle/note", payload);
      commit(constants.POST_TASK_NOTE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_TASK_NOTE_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_TASK_LOGS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_TASK_LOGS);
      const response = await axios.get("task/getHistoryTask", {
        params: payload,
      });
      commit(constants.GET_TASK_LOGS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_TASK_LOGS_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_TASK_TYPES]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_TASK_TYPES);
      const response = await axios.get("task/listTaskType", {
        params: payload,
      });
      commit(constants.GET_TASK_TYPES_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_TASK_TYPES_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_TASK_TYPE_BY_USER]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_TASK_TYPE_BY_USER);
      const response = await axios.put("task/assignTaskTypes", payload);
      commit(constants.PUT_TASK_TYPE_BY_USER_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_TASK_TYPE_BY_USER_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_TASK_LIST_USERS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_TASK_LIST_USERS);
      const response = await axios.get("task/listUsers", {
        params: payload,
      });
      commit(constants.GET_TASK_LIST_USERS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_TASK_LIST_USERS_ERROR, error);
      throw error.response;
    }
  }


};

const mutations = {


  [constants.GET_TASKS]: (state) => {
    state.tasks.status = "fetching";
    state.tasks.error = null;
    state.tasks.data = [];
  },
  [constants.GET_TASKS_SUCCESS]: (state, data) => {
    state.tasks.status = "success";
    state.tasks.error = null;
    state.tasks.data = data.data;
  },
  [constants.GET_TASKS_ERROR]: (state, error) => {
    state.tasks.status = "error";
    state.tasks.error = error;
  },
  [constants.POST_TASK]: (state) => {
    state.task.status = "fetching";
    state.task.error = null;
  },
  [constants.POST_TASK_SUCCESS]: (state, data) => {
    state.task.status = "success";
    state.task.error = null;
    state.task.data = data;
  },
  [constants.POST_TASK_ERROR]: (state, error) => {
    state.task.status = "error";
    state.task.error = error;
  },
  [constants.GET_VEHICLES_AND_USERS]: (state) => {
    state.users.status = "fetching";
    state.users.error = null;
    state.vehicles.status = "fetching";
    state.vehicles.error = null;
    state.companies.status = "fetching";
    state.companies.error = null;
    state.taskTypes.status = "fetching";
    state.taskTypes.error = null;
  },
  [constants.GET_VEHICLES_AND_USERS_SUCCESS]: (state, data) => {
    state.users.status = "success";
    state.users.error = null;
    state.users.data = data.users;
    state.vehicles.status = "success";
    state.vehicles.error = null;
    state.vehicles.data = data.vehicle;
    state.companies.status = "success";
    state.companies.error = null;
    state.companies.data = data.companies;
    state.taskTypes.status = "success";
    state.taskTypes.error = null;
    state.taskTypes.data = data.taskType;
  },
  [constants.GET_VEHICLES_AND_USERS_ERROR]: (state, error) => {
    state.users.status = "error";
    state.users.error = error;
    state.vehicles.status = "error";
    state.vehicles.error = error;
    state.companies.status = "error";
    state.companies.error = error;
    state.taskTypes.status = "error";
    state.taskTypes.error = error;

  },
  [constants.DELETE_TASK]: (state) => {
    state.task.status = "fetching";
    state.task.error = null;
  },
  [constants.DELETE_TASK_SUCCESS]: (state, data) => {
    state.task.status = "success";
    state.task.error = null;
    state.task.data = data;
  },
  [constants.DELETE_TASK_ERROR]: (state, error) => {
    state.task.status = "error";
    state.task.error = error;
  },
  setVehicleDetailsSideBar(state, payload) {
    state.vehicleDetailsSideBar = payload;
  },
  setFilterSideBar(state, payload) {
    state.filterSideBar = payload;
  },
  setCompanyDetailsSideBar(state, payload) {
    state.companyDetailsSideBar = payload;
  },
  setTaskDetailsSideBar(state, payload) {
    state.taskDetailsSideBar = payload;
  },
  [constants.SET_TASKS_FILTERS]: (state, filters) => {
    if (filters.reset) {
      state.tasks.filters = {
        ownerId: null,
        vehicleId: null,
        companyId: null,
        dateFrom: null,
        dateTo: null,
        dueDateFrom: null,
        dueDateTo: null,
        search: null,
        status: null,
        userId: null,
        boardId: null,
      };
    }
    if (filters.fields) {
      state.tasks.filters = {
        ...state.tasks.filters,
        ...filters.fields,
      };
    }
  },
  [constants.MARK_TASK_AS_DONE]: (state) => {
    state.task.status = "fetching";
    state.task.error = null;
  },
  [constants.MARK_TASK_AS_DONE_SUCCESS]: (state, data) => {
    state.task.status = "success";
    state.task.error = null;
    state.task.data = data;
  },
  [constants.MARK_TASK_AS_DONE_ERROR]: (state, error) => {
    state.task.status = "error";
    state.task.error = error;
  },
  [constants.POST_TASK_NOTE]: (state) => {
    state.task.status = "fetching";
    state.task.error = null;
  },
  [constants.POST_TASK_NOTE_SUCCESS]: (state, data) => {
    state.task.status = "success";
    state.task.error = null;
    state.task.data = data;
  },
  [constants.POST_TASK_NOTE_ERROR]: (state, error) => {
    state.task.status = "error";
    state.task.error = error;
  },
  [constants.GET_TASK_LOGS]: (state) => {
    state.taskLogs.status = "fetching";
    state.taskLogs.error = null;
  },
  [constants.GET_TASK_LOGS_SUCCESS]: (state, data) => {
    state.taskLogs.status = "success";
    state.taskLogs.error = null;
    state.taskLogs.data = data;
  },
  [constants.GET_TASK_LOGS_ERROR]: (state, error) => {
    state.taskLogs.status = "error";
    state.taskLogs.error = error;
  },
  [constants.GET_TASK_TYPES]: (state) => {
    state.taskTypes.status = "fetching";
    state.taskTypes.error = null;
  },
  [constants.GET_TASK_TYPES_SUCCESS]: (state, data) => {
    state.taskTypes.status = "success";
    state.taskTypes.error = null;
    state.taskTypes.data = data;
  },
  [constants.GET_TASK_TYPES_ERROR]: (state, error) => {
    state.taskTypes.status = "error";
    state.taskTypes.error = error;
  },
  [constants.PUT_TASK_TYPE_BY_USER]: (state) => {
    state.taskTypeByUser.status = "fetching";
    state.taskTypeByUser.error = null;
  },
  [constants.PUT_TASK_TYPE_BY_USER_SUCCESS]: (state, data) => {
    state.taskTypeByUser.status = "success";
    state.taskTypeByUser.error = null;
    state.taskTypeByUser.data = data;
  },
  [constants.PUT_TASK_TYPE_BY_USER_ERROR]: (state, error) => {
    state.taskTypeByUser.status = "error";
    state.taskTypeByUser.error = error;
  },
  [constants.GET_TASK_LIST_USERS]: (state) => {
    state.taskListUsers.status = "fetching";
    state.taskListUsers.error = null;
  },
  [constants.GET_TASK_LIST_USERS_SUCCESS]: (state, data) => {
    state.taskListUsers.status = "success";
    state.taskListUsers.error = null;
    state.taskListUsers.data = data.data;
  },
  [constants.GET_TASK_LIST_USERS_ERROR]: (state, error) => {
    state.taskListUsers.status = "error";
    state.taskListUsers.error = error;
  }




};
export default {
  state: getDefaultState(),
  actions,
  mutations,

};
