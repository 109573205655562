<template>
  <div class="d-flex justify-content-between align-middle text-wrap">
    <span class="align-middle mt-2" :class="dateClass">
      <font-awesome-icon :icon="['far', 'calendar-clock']" />
      {{ task.dueDate | formatDate }}</span
    >
  </div>
</template>

<script>
export default {
  name: "TaskCardDueDate",
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    dateClass() {
      if (this.task.dueDate) {
        const date = new Date(this.task.dueDate);
        const today = new Date();
        if (date < today) {
          return "text-danger";
        }
        if (date.getDate() == today.getDate() + 1) {
          return "text-yellow";
        }
      }
      return "";
    },
  },
};
</script>

<style></style>
