<template>
  <b-form>
    <b-form-group label="Description">
      <b-form-textarea
        v-model.trim="$v.form.description.$model"
        class="resize-none"
        :state="validateState('description')"
        aria-describedby="input-description-live-feedback"
        @change="setValue('description', $event)"
      />
      <b-form-invalid-feedback id="input-description-live-feedback">
        This is a required field.
      </b-form-invalid-feedback>
    </b-form-group>

    <div class="text-right mt-2">
      <b-button
        :disabled="$v.form.$invalid || saveNote === 'fetching'"
        variant="primary"
        @click="saveException()"
      >
        <b-spinner v-if="saveNote === 'fetching'" variant="white" small />
        <span v-else>Submit</span>
      </b-button>
    </div>
  </b-form>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import { POST_EXCEPTION } from "./actions";
import { superAdminMixin } from "../../mixins";
import { generalMixin } from "@/modules/mixin.js";
export default {
  mixins: [superAdminMixin, generalMixin],
  props: ["vehicle"],
  data() {
    return {
      form: {
        description: null,
        vehicleId: this.vehicle.id,
      },
    };
  },
  computed: {
    ...mapState({
      saveNote: (state) => state.superAdminStore.componentStore.saveNote.status,
    }),
  },
  validations: {
    form: {
      description: {
        required,
      },
    },
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form.description = null;
      this.form.vehicleId = null;
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    saveException() {
      this.$store
        .dispatch(POST_EXCEPTION, this.form)
        .then(() => {
          this.toastMessage(
            "Exception saved successfully",
            "Success",
            "success"
          );
          this.resetForm();
          this.closeModal("exception-modal");
          this.getVehiclesInfo();
        })
        .catch((error) => {
          this.showError(error);
        });
    },
  },
};
</script>

<style></style>
