<template>
  <div>
    <div class="container-fluid mt-3">
      <b-row class="">
        <b-col>
          <!--           <h4 class="ml-3">Taxes</h4>
 -->
        </b-col>
      </b-row>
      <div class="">
        <TableBase
          :items="taxes.data"
          :fields="fields"
          :options="options"
          :busy="taxes.status === 'fetching' ? true : false"
        >
          <template v-slot:rightSide>
            <div>
              <!--               <b-button
                v-if="userHasPermission('taxes', 'write')"
                variant="outline-purple-yellow"
                class="align-bottom"
                @click="showModal()"
              >
                <b-iconstack class="m-1 text-center" font-scale="1">
                  <b-icon icon="cash" font-scale="1.1" />
                  <b-icon
                    icon="plus-circle-fill"
                    shift-h="25"
                    shift-v="4"
                    font-scale="0.55"
                  />
                </b-iconstack>
                <span class="" />
              </b-button> -->
            </div>
          </template>
        </TableBase>
      </div>

      <b-modal
        id="detailsModal"
        ref="detailsModal"
        size="lg"
        hide-footer
        body-class=""
        ok-disabled
        @hidden="taxItem = {}"
      >
        <TaxRegistrationForm
          :taxItem="taxItem"
          :refreshData="getData"
          :hideModal="hideModal"
        />
      </b-modal>
    </div>
  </div>
</template>
<script>
import TableBase from "@/modules/shared/table.vue";
import TaxRegistrationForm from "./TaxRegistrationForm.vue";

import { GET_TAXES_LIST, POST_TAX } from "./actions";
import { mapState } from "vuex";

import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../mixins";
export default {
  name: "TaxesTable",
  components: { TableBase, TaxRegistrationForm },
  mixins: [generalMixin, superAdminMixin],
  computed: {
    ...mapState({
      taxes: (state) => state.superAdminStore.taxes.taxes,
    }),
    hasPermission() {
      return this.userHasPermission("taxes", "write");
    },
    fields() {
      return [
        { key: "tps", label: "Name" },
        { key: "percent", label: "%" },
        { key: "state.name", label: "Province" },
        {
          key: "actions",
          label: "Actions",
          note: false,
          details: true,
          validation: false,
          delete: this.hasPermission,
        },
      ];
    },
  },
  data() {
    return {
      options: {
        assign: false,
        rolFilter: false,
        add: false,
        fun: this.getData,
      },
      taxItem: {},
    };
  },
  mounted() {
    this.$store.commit(
      "setAddFunction",
      this.userHasPermission("taxes", "write") ? this.showModal : null
    );
    this.getData();
  },
  methods: {
    getData(filter = "") {
      this.$store
        .dispatch(GET_TAXES_LIST, { search: filter })
        .then(() => {})
        .catch(() => {});
    },
    async deleteItem(item) {
      const confirm = await this.showConfirmDeleteBox();
      if (!confirm) return;
      const payload = { ...item };
      payload.status = 0;
      this.$store
        .dispatch(POST_TAX, payload)
        .then(() => {
          this.getData();
          this.makeToast("Success", "Tax updated successfully", "success");
        })
        .catch(() => {
          this.$root.$bvToast.toast("Tax could not be disabled", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    activateItem(item) {
      const payload = {
        id: item.id,
        status: 1,
      };
      this.$store
        .dispatch(POST_TAX, payload)
        .then(() => {
          this.makeToast("Success", "Tax updated successfully", "success");
          this.getData();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Tax could not be activated", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    showModal() {
      this.$bvModal.show("detailsModal");
    },
    editItem(item) {
      this.taxItem = item;
      this.$bvModal.show("detailsModal");
    },
    changePage(page, filter = "") {
      const payload = {
        search: filter,
        page: page,
      };
      this.$store
        .dispatch(GET_TAXES_LIST, payload)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },

    hideModal() {
      this.$bvModal.hide("detailsModal");
    },
  },
};
</script>
