<template>
  <div class="container">
    <div v-if="!showTrimList">
      <b-row class="text-center text-white">
        <b-col>
          <b-button
            class="shadow text-white"
            variant="yellow"
            block
            @click="changeCalculatorType('vin')"
            >VIN
          </b-button>
        </b-col>
        <b-col>
          <b-button
            class="shadow text-white"
            variant="yellow"
            block
            @click="changeCalculatorType('drill down')"
            >DRILL DOWN</b-button
          >
        </b-col>
      </b-row>
      <div class="my-3">
        <Vin
          v-if="calculatorType == 'vin'"
          @goToTrimList="goToTrimList($event)"
        />
        <DrillDown v-else @goToTrimList="goToTrimList($event)" />
      </div>
    </div>
    <div v-else>
      <TrimList @bactToCalculator="bactToCalculator($event)" />
    </div>
  </div>
</template>

<script>
import Vin from "./Vin.vue";
import DrillDown from "./DrillDown.vue";
import TrimList from "../trims/TrimList.vue";

export default {
  components: {
    Vin,
    DrillDown,
    TrimList,
  },
  data() {
    return {
      calculatorType: "vin",
      showTrimList: false,
    };
  },
  methods: {
    changeCalculatorType(type) {
      this.calculatorType = type;
    },
    goToTrimList() {
      this.showTrimList = true;
    },
    bactToCalculator() {
      this.showTrimList = false;
    },
  },
};
</script>

<style>
</style>