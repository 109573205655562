export const defaultFields = [
  /*  {
     key: "marketPlace",
     label: "MKT",
     sortable: true,
 
   },
   {
     key: "stockNumber",
     label: "Stock Number",
     class: "text-center",
   }, */
  {
    key: "vinNumber",
    label: "Vin Number",
    class: "text-center",
  },

  {
    key: "year",
    label: "Year",
    class: "text-center",
  },
  {
    key: "model",
    label: "Model",
    class: "text-center",
  },
  {
    key: "saleDate",
    label: "Sale date",
    class: "text-center",
  },
  {
    key: "salePrice",
    label: "Sale price",
    class: "text-center",
  },
  {
    key: "reserveDate",
    label: "Reserve date",
    class: "text-center",
  },
  {
    key: "reservePrice",
    label: "Reserve price",
    class: "text-center",
  },
  /*   {
      key: "user",
      label: "Agent",
    }, */
];

export const optionalFields = [





];
