<template>
  <b-form @submit.stop.prevent="saveAccountPay()">
    <b-row>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label="">
          <template slot="label"> Agent </template>
          <vue-single-select
            :classes="{
              input: 'form-control',
              dropdown: 'dropdown p-0',
            }"
            v-model="form.agent"
            :options="agents"
            optionLabel="nickname"
          >
            <template slot="option" slot-scope="{ option }">
              <div class="">
                <!--             <span> {{ option.firstName }} {{ option.lastName }}</span>
 -->
                <span> {{ option.nickname }}</span>
              </div>
            </template>
          </vue-single-select>
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group>
          <template slot="label"> Date </template>

          <b-form-input v-model="form.date" type="date" />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label="">
          <template slot="label"> Reference </template>
          <b-form-input v-model="form.reference" type="text" />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label="Description">
          <b-form-input v-model="form.description" type="text" />
        </b-form-group>
      </b-col>

      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label="">
          <template slot="label"> Credit </template>
          <Money
            :disabled="
              commissionsSelected.length > 0 &&
              !userHasMenuPermission('CommissionPayment')
            "
            v-model="form.credit"
            class="form-control"
            v-bind="money"
          />
        </b-form-group>
      </b-col>

      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label="">
          <template slot="label"> Debit </template>
          <Money
            :disabled="
              commissionsSelected.length > 0 &&
              !userHasMenuPermission('CommissionPayment')
            "
            v-model="form.debit"
            class="form-control"
            v-bind="money"
          />
        </b-form-group>
      </b-col>

      <b-col
        v-if="commissionsSelected.length > 0"
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
      >
        <CommissionBreakdown :commissionsSelected="commissionsSelected" />
      </b-col>
    </b-row>

    <div class="text-right">
      <b-button
        :disabled="accountStatement.status === 'fetching'"
        variant="primary"
        type="submit"
      >
        <b-spinner
          v-if="accountStatement.status === 'fetching'"
          variant="white"
          small
        />
        <span v-else>Submit</span>
      </b-button>
    </div>
  </b-form>
</template>

<script>
import { mapState } from "vuex";
import { Money } from "v-money";
import { POST_ACCOUNT_STATEMENT } from "./actions";
import CommissionBreakdown from "./CommissionBreakdown.vue";
import { superAdminMixin } from "../mixins";
import VueSingleSelect from "vue-single-select";

export default {
  name: "AccountStatusForm",
  components: {
    Money,
    CommissionBreakdown,
    VueSingleSelect,
  },
  props: {
    refreshData: {
      type: Function,
      required: true,
    },
    hideModal: {
      type: Function,
      required: true,
    },
    commissionsSelected: {
      type: Array,
      default: () => [],
      required: false,
    },
    agentSelected: {
      type: Object,
      default: () => null,
      required: false,
    },
  },
  mixins: [superAdminMixin],
  computed: {
    ...mapState({
      accountStatement: (state) =>
        state.superAdminStore.accountStatement.accountStatement,
    }),
  },
  mounted() {
    this.formatForm();
  },

  data() {
    return {
      form: {
        date: null,
        reference: null,
        description: null,
        credit: 0,
        debit: 0,
        userId: null,
        agent: null,
      },
      optionsType: [
        { value: "Credit", text: "Credit" },
        { value: "Debit", text: "Debit" },
      ],
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "CAD$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      agents: [],
    };
  },
  created() {
    this.getAgents();
  },
  methods: {
    saveAccountPay() {
      const payload = this.formatPayload();
      this.$store.dispatch(POST_ACCOUNT_STATEMENT, payload).then(() => {
        this.$bvToast.toast("Account pay Created", {
          title: "Success",
          variant: "success",
          solid: true,
        });
        this.resetForm();
        this.refreshData();
        this.hideModal();
      });
    },
    formatPayload() {
      const payload = {
        date: this.form.date,
        reference: this.form.reference,
        description: this.form.description,
        credit: this.form.credit,
        debit: this.form.debit,
        userId: this.form.userId,
        listCommission: this.commissionsSelected,
      };
      return payload;
    },
    onAgentChange(agent) {
      if (!agent) return;
      this.form.userId = agent.id;
      this.form.agent = agent;
    },
    getAgents() {
      this.$store
        .dispatch("GET_AGENTS")
        .then((response) => {
          this.agents = response.agent;
          if (this.agentSelected) {
            this.form.agent = null;
            this.form.agent = response.agent.filter(
              (agent) => agent.id == this.agentSelected.id
            )[0];
          }
        })
        .catch(() => {});
    },
    formatForm() {
      if (this.commissionsSelected.length > 0) {
        this.form.credit = this.commissionsSelected.reduce(
          (a, b) => a + b.credit,
          0
        );
        this.form.debit = this.commissionsSelected.reduce(
          (a, b) => a + b.debits,
          0
        );
      }
      if (this.agentSelected) {
        this.form.userId = this.agentSelected.id;
        this.form.agent = this.agentSelected;
      }
    },
    resetForm() {
      this.form = {
        date: null,
        reference: null,
        description: null,
        credit: 0,
        debit: 0,
        userId: null,
        agent: null,
      };
    },
  },
};
</script>

<style></style>
