<template>
  <b-card :class="{ shadow: isShowFullInfo }">
    <div class="text-right">
      <Timer
        :server-time="offer.time.serverTime"
        :expiration-time="offer.time.expirationTime"
      />
    </div>
    <h3>
      <span>
        <i v-if="offer.lastOfferUser">
          <b-row>
            <!-- User offer -->
            <b-col>
              <b-row>
                <b-col>
                  <small class="text-muted userPrice">
                    {{ offer.lastName }} {{ offer.firstName }}
                  </small>
                </b-col>
              </b-row>
              <small>
                <span v-if="userOffer">
                  {{ userOffer | currency }}
                </span>
                <span
                  v-else-if="offer.lastOfferUser"
                  class="offerPrice"
                >
                  <span>
                    {{ offer.lastOfferUser.offerPrice | currency }}
                  </span>
                </span>
                <span v-else> ---- </span>
              </small>
              <b-row v-if="userOffer">
                <b-col>
                  <small class="text-muted userPrice">
                    {{ offerDate | formatDateTime }}
                  </small>
                </b-col>
              </b-row>
              <b-row
                v-else-if="offer.lastOfferUser"
                style="margin-top: -10px"
              >
                <b-col>
                  <small class="text-muted userPrice">
                    {{ offer.lastOfferUser.registerDate | formatDateTime }}
                  </small>
                </b-col>
              </b-row>
            </b-col>
            <!-- Canam offers -->
            <b-col>
              <b-row>
                <b-col>
                  <small class="text-muted userPrice"> Canam </small>
                </b-col>
              </b-row>
              <small>
                <span
                  v-if="canamOffer"
                  class="offerPrice"
                >
                  {{ canamOffer | currency }}
                </span>
                <span
                  v-else-if="offer.lastOfferAdmin"
                  class="offerPrice"
                >
                  <span>
                    {{ offer.lastOfferAdmin.offerPrice | currency }}
                  </span>
                </span>
                <span v-else> ---- </span>
              </small>
              <b-row
                v-if="canamOffer"
                style="margin-top: -10px"
              >
                <b-col>
                  <small class="text-muted userPrice">
                    {{ offerDate | formatDateTime }}
                  </small>
                </b-col>
              </b-row>
              <b-row
                v-else-if="offer.lastOfferAdmin"
                style="margin-top: -10px"
              >
                <b-col>
                  <small class="text-muted userPrice">
                    {{ offer.lastOfferAdmin.registerDate | formatDateTime }}
                  </small>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </i>
        <i v-else>{{ offer.offerPrice | currency }}</i>
      </span>
    </h3>
    <CounterOffer
      class="mt-4"
      :client-id="offer.clientId"
      :vehicle-id="vehicleId"
      :last-offer-user="offer.lastOfferUser"
      :last-offer-admin="offer.lastOfferAdmin"
      :offer-price="offer.offerPrice"
      :offer-chain="offerChain"
      @getOfferChain="getOfferChain()"
      @setUserOffer="setUserOffer($event)"
      @setOfferDate="setOfferDate($event)"
    />
    <b-row class="mt-3 text-right">
      <b-col>
        <b-button
          variant="light"
          size="sm"
          @click="showDetails()"
        >
          <div>
            <b-icon
              :icon="isShowFullInfo ? 'arrow-up-short' : 'arrow-down-short'"
            />
            <small
              class="ml-2"
              style="font-size: 15px"
            >History</small>
          </div>
        </b-button>
      </b-col>
    </b-row>
    <b-collapse v-model="isShowFullInfo">
      <hr>
      <History
        :offer-price="offer.offerPrice"
        :offer-chain="offerChain"
        :user-is-admin="userIsAdmin"
        :user-is-buyer="userIsBuyer"
        :user-is-agent="userIsAgent"
        :user-is-client="userIsClient"
      />
    </b-collapse>
  </b-card>
</template>

<script>
import { vehicleOffersMixins } from './mixins';
import { GET_VEHICLES_OFFER_CHAIN } from './actions';
import History from './components/History.vue';
import CounterOffer from './components/CounterOffer';
import { listVehiclesMixin } from '../list/mixins';
import { generalMixin } from '@/modules/mixin';
import Timer from './components/Timer';

export default {
  components: { History, CounterOffer, Timer },
  mixins: [ vehicleOffersMixins, listVehiclesMixin, generalMixin ],
  props: [ 'vehicleId', 'offer' ],
  data() {
    return {
      isShowFullInfo: false,
      offerChain: [],
      userSubmittedOffer: false,
      userOffer: null,
      canamOffer: null,
      offerDate: null
    };
  },
  methods: {
    getOfferChain() {
      const payload = {
        status: 1,
        vehicleId: this.vehicleId,
        clientId: this.offer.clientId
      };
      this.$store
        .dispatch(GET_VEHICLES_OFFER_CHAIN, payload)
        .then((response) => {
          this.offerChain = response.data;
        })
        .catch(() => {
          this.toastMessage(
            'Counter-Offer history not obtained',
            'Warning',
            'arning'
          );
        });
    },
    showDetails() {
      this.isShowFullInfo = !this.isShowFullInfo;
      if (this.isShowFullInfo) this.getOfferChain();
    },
    setUserOffer(offer) {
      this.userSubmittedOffer = true;
      if (this.userIsAdmin || this.userIsAgent) {
        this.canamOffer = offer;
      } else {
        this.userOffer = offer;
      }
    },
    setOfferDate(date) {
      this.offerDate = date;
    }
  }
};
</script>

<style lang="scss">
.negotiate {
  margin-top: -8px;
  div {
    svg {
      margin-top: 2px;
    }
    display: flex;
  }
}

.userPrice {
  font-size: 13px;
}

@media screen and (min-width: 300px) {
  .negotiate {
    margin-left: 35px;
  }
}

@media screen and (min-width: 1200px) {
  .negotiate {
    margin-left: 200px;
  }
}
@media screen and (max-width: 300px) {
  .offerPrice {
    font-size: 16px;
  }
  .userPrice {
    font-size: 11px;
  }
}
</style>
