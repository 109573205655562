<template>
  <div class="container">
    <ul class="listSeparateByDots">
      <li>
        <slot name="initial-label" />
        <span class="">{{ sellPrice | currency }} </span>
      </li>
      <li class="" v-for="(tax, i) in taxes" :key="i">
        <span>{{ tax.tps }} </span
        ><span> {{ calculateTax(tax) | currency }}</span>
      </li>
    </ul>
    <hr size="5px" color="black" />
    <ul class="listSeparateByDots">
      <li>
        <slot name="final-label" />
        <span class=""
          ><strong> {{ calculateTotalAfterTaxes | currency }} </strong>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["sellPrice", "taxes"],
  data() {
    return {};
  },
  computed: {
    calculateTotalAfterTaxes() {
      let totalAfterTaxes = this.sellPrice;
      this.taxes.forEach((tax) => {
        totalAfterTaxes += this.calculateTax(tax);
      });
      return totalAfterTaxes;
    },
  },
  methods: {
    calculateTax(tax) {
      return (tax.percent / 100) * this.sellPrice;
    },
  },
};
</script>

<style scoped>
ul.listSeparateByDots {
  max-width: 50em;
  padding: 0;
  overflow-x: hidden;
  list-style: none;
}
ul.listSeparateByDots li:before {
  float: left;
  width: 0;
  white-space: nowrap;
  content: ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . ";
}
ul.listSeparateByDots span:first-child {
  padding-right: 0.33em;
  background: white;
}
ul.listSeparateByDots span + span {
  float: right;
  padding-left: 0.33em;
  background: white;
}
</style>