import Vue from "vue";
import "@/sass/main.scss";
import VueProgressBar from "vue-progressbar";
import Vuelidate from "vuelidate";
import VuePlaceAutocomplete from "vue-place-autocomplete";

import Photoswipe from "vue-pswipe";
import VueScreenSize from "vue-screen-size";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import VueCookies from "vue-cookies";
import VueClipboard from "vue-clipboard2";
import VueSelectImage from "vue-select-image";
import Vue2TouchEvents from 'vue2-touch-events'
import VueBarcode from 'vue-barcode';
import VueCryptojs from 'vue-cryptojs'



// add stylesheet
VueClipboard.config.autoSetContainer = true;
require("vue-select-image/dist/vue-select-image.css");
require("./fontawesome");

const progressOptions = {
  color: "#007bff",
  failedColor: "#dc3545",
  thickness: "5px",
};


Vue.use(VueProgressBar, progressOptions);
Vue.use(Vuelidate);
Vue.use(VuePlaceAutocomplete);
Vue.use(Photoswipe);
Vue.use(VueScreenSize);
Vue.use("vue-barcode", VueBarcode);
Vue.use(VueTelInput);
Vue.use(VueCookies);
Vue.use(VueClipboard);
Vue.use(VueSelectImage);
Vue.use(Vue2TouchEvents, {
  disableClick: false,
  touchClass: 'active',
  touchHoldTolerance: 200,
  namespace: 'touch'
})
Vue.use(VueCryptojs)

import EasyCamera from "easy-vue-camera";

Vue.use(EasyCamera);
Vue.mixin({
  computed: {
    witdhResponsive() {
      return this.$vssWidth > 1280
        ? "25%"
        : this.$vssWidth <= 576
          ? "100%"
          : "50%";
    },
  },
  methods: {
    lowerCaseFormatter(value) {
      return value.toLowerCase();
    },
    toastMessage(message, title, variant) {
      this.$root.$bvToast.toast(message, {
        title: title,
        variant: variant,
      });
    },
  },
});
