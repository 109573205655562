<template>
  <div>
    <b-row>
      <b-col cols="10">
        <span>
          Registered date:
          <i
            >{{ lien.date | formatDateTime }} -
            {{ lien.user.lastName }}
            {{ lien.user.firstName }}
          </i>
        </span>
        <br />
        <span v-if="lien.status == 0">
          Cancellation date:
          <i
            >{{ lien.cancellationDate | formatDateTime }} -
            {{ lien.cancellationUser.lastName }}
            {{ lien.cancellationUser.firstName }}
          </i>
        </span>
        <br />
        <span v-if="lien.repairDate != null">
          Release date:
          <i>
            {{ lien.repairDate | formatDateTime }} -
            {{ lien.repairUser.lastName }}
            {{ lien.repairUser.firstName }}
          </i>
        </span>
      </b-col>
      <b-col>
        <div class="text-right">
          <span v-if="lien.status == 0" class="dot dot-danger mr-1" />

          <!--           <span v-if="lien.status == 2" class="dot dot-success mr-3" /> 
 -->
          <!--   <b-form-checkbox
            v-if="lien.status == 1"
            id="checkbox-3"
            v-b-tooltip.hover
            title="Mark as completed"
            class="z-index-0 mt-2 mr-2"
            name="checkbox-3"
            :unchecked-value="false"
            @change="markAsRecallCompleted(lien)"
          /> -->

          <b-form-checkbox
            v-if="
              (lien.status == 1 || lien.status == 2) &&
              userHasPermission('liens', 'write')
            "
            v-b-tooltip.hover
            :title="lien.status == 1 ? 'Mark as completed' : 'Mark as active'"
            switch
            :checked="lien.status == 2"
            @change="toggleLienStatus(lien)"
          />
          <!--   <b-form-group v-if="lien.status == 1" class="ml-1">
            <b-form-checkbox
              v-b-tooltip.hover
              title="Cancel lien"
              :checked="true"
              switch
              @change="disableRecall(lien)"
            />
          </b-form-group> -->
          <b-form-group v-if="lien.status == 1" class="">
            <b-icon
              v-if="userHasPermission('liens', 'write')"
              icon="x"
              v-b-tooltip.hover
              title="Cancel lien"
              scale="1.5"
              class="cursor-pointer mr-3"
              @click="disableLien(lien)"
            ></b-icon>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col xs="12" sm="12" md="9" lg="9">
        <div class="text-justify mb-1">{{ lien.description }}</div>
        <LienQuittanceUpload
          class="w-75"
          :lien="lien"
          :quittanceFile="lien.quittanceFile"
        />
      </b-col>
      <b-col xs="12" sm="12" md="3" lg="3"> </b-col>
    </b-row>
    <div class="d-flex justify-content-end">
      <div></div>
      <a :href="lien.file" v-b-tooltip.hover title="View file" target="_blank">
        <b-icon
          icon="eye"
          class="float-right cursor-pointer mr-3"
          scale="1.5"
        ></b-icon>
      </a>
      <b-button
        v-if="userHasPermission('liens', 'write')"
        variant="warning"
        size="md"
        v-b-tooltip.hover
        type="button"
        title="Notes"
        class="float-right mx-2 text-white"
        @click="openLienNotesModal()"
      >
        Notes
      </b-button>
    </div>
    <b-list-group v-if="notes.status == 'fetching'" class="mt-2">
      <b-list-group-item class="text-center">
        <b-spinner variant="yellow" />
      </b-list-group-item>
    </b-list-group>
    <b-list-group v-else class="mt-2 mb-2 note-list" tag="ul">
      <b-list-group-item
        v-if="notes.data && !notes.data.length"
        class="text-center"
      >
        <b>No notes to show</b>
      </b-list-group-item>

      <b-list-group-item
        v-for="(note, i) in notes.data"
        :key="i"
        class="flex-column align-items-start"
        tag="li"
      >
        <NoteCard :note="note" :refreshData="getLienNotes" />
      </b-list-group-item>
    </b-list-group>
    <LienNotesModal :lien="lien" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { PUT_LIEN } from "./actions";
import LienNotesModal from "./notes/NotesModal.vue";
import NoteCard from "./notes/NoteCard.vue";
import { superAdminMixin } from "../../mixins";
import LienQuittanceUpload from "./LienQuittanceUpload.vue";
export default {
  props: ["lien", "refreshData"],
  components: { LienNotesModal, NoteCard, LienQuittanceUpload },
  mixins: [superAdminMixin],
  computed: {
    ...mapState({
      vehicle: (state) => state.superAdminStore.vehicle,
      notes: (state) => state.superAdminStore.componentStore.liens.notes.notes,
    }),
  },
  created() {
    this.getLienNotes();
  },
  methods: {
    markAsRecallCompleted(lien) {
      const payload = {
        id: lien.id,
        status: 2,
      };
      this.$store
        .dispatch(PUT_LIEN, payload)
        .then(() => {
          this.refreshData();
        })
        .catch(() => {
          this.toastMessage("Lien not mark as completed", "Warning", "warning");
        });
    },
    disableLien(lien) {
      const payload = {
        id: lien.id,
        status: 0,
      };
      this.$store
        .dispatch(PUT_LIEN, payload)
        .then(() => {
          this.refreshData();
          this.toastMessage("Lien cancel successfully ", "Success", "success");
        })
        .catch(() => {
          this.toastMessage("Lien not cancel ", "Warning", "warning");
        });
    },

    toggleLienStatus(lien) {
      const payload = {
        id: lien.id,
        status: lien.status == 1 ? 2 : 1,
      };
      this.$store
        .dispatch(PUT_LIEN, payload)
        .then(() => {
          this.refreshData();
        })
        .catch(() => {
          this.toastMessage(
            "Lien status updated completed",
            "Warning",
            "warning"
          );
        });
    },
    openLienNotesModal() {
      this.$bvModal.show("lien-notes-modal" + this.lien.id);
    },
    getLienNotes() {
      this.$store.dispatch("GET_LIEN_NOTES", {
        id: this.lien.id,
      });
    },
  },
};
</script>

<style scoped></style>
