<template>
  <div>
    <span>
      Date:
      <i>{{ agent.createdAt | formatDateTime }} </i>
    </span>
    <br />
    <span v-if="agent.agent"
      >Agent: <i>{{ agent.agent.firstName }} {{ agent.agent.lastName }}</i> <br
    /></span>
    <span
      >Stock number: <i>{{ agent.stockNumber }}</i></span
    >
  </div>
</template>

<script>
export default {
  props: {
    agent: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>