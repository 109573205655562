<template>
  <div>
    <b-col cols="6">
      <b-form-group label-size="md">
        <template slot="label"> Provider </template>
        <vue-single-select
          v-if="typeCarrier == 'supplier'"
          :classes="{ input: 'form-control', dropdown: 'dropdown' }"
          v-model="form.supplier"
          :options="suppliers"
          optionLabel="companyName"
        ></vue-single-select>
      </b-form-group>
    </b-col>
  </div>
</template>

<script>
import VueSingleSelect from "vue-single-select";

export default {
  components: {
    VueSingleSelect,
  },
  data() {
    return {
      form: {
        supplier: null,
      },
      typeCarrier: "supplier",
      suppliers: [],
    };
  },

  watch: {
    form: {
      deep: true,
      handler() {
        this.onSelectProvider();
      },
    },
  },

  methods: {
    getProviders() {
      this.$store
        .dispatch("GET_COMPANIES_LIST", { isSupplier: 1 })
        .then((response) => {
          this.suppliers = response;
        })
        .catch(() => {
          this.$root.$bvToast.toast("Could not get the suppliers list ", {
            title: "Error",
            variant: "warning",
          });
        });
    },

    onSelectProvider() {
      this.$emit("select-supplier", this.form.supplier);
    },
  },
  mounted() {
    this.getProviders();
  },
};
</script>

<style></style>
