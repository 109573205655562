<template>
  <div>
    <b-row>
      <b-col cols="5" class="mr-0">
        <vue-single-select
          :classes="{
            input: 'form-control',
            dropdown: 'dropdown p-0',
          }"
          v-model="selected"
          :options="users"
          optionLabel="firstName"
          :getOptionDescription="
            () => `${selected.firstName} ${selected.lastName}`
          "
        >
          <template slot="option" slot-scope="{ option }">
            <div class="">
              <!--             <span> {{ option.firstName }} {{ option.lastName }}</span>
 -->
              <span>
                {{ option.firstName }} {{ option.lastName }}
                <span v-if="option.nickname"> ({{ option.nickname }}) </span>
              </span>
            </div>
          </template>
        </vue-single-select>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { GET_SUPER_ADMIN_LIST } from "./actions";
import VueSingleSelect from "vue-single-select";

import { mapState } from "vuex";
export default {
  components: { VueSingleSelect },
  computed: {
    ...mapState({
      users: (state) => state.masterUser.superAdmin.superAdmins.data,
    }),
  },
  watch: {
    selected(val) {
      if (val) {
        this.selected = val;
        this.$emit("user-select", val);
      }
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  created() {
    this.getUsersPermission();
  },
  methods: {
    getUsersPermission() {
      this.$store
        .dispatch(GET_SUPER_ADMIN_LIST, {})
        .then(() => {})
        .catch(() => {});
    },
    clearUserSelect() {
      this.selected = null;
    },
    /*   onUserSelect(user) {
      this.$emit("user-select", user);
    }, */
  },
};
</script>

<style>
</style>