<template>
  <b-container fluid class="mt-3">
    <AgentsCommissionsTable />

    <!--     <b-tabs content-class="">
      <b-tab title="Summary" active @click="onChangeTab(1)">
        <b-row>
          <b-col sm="12" md="3" lg="3">
            <b-form-group label-size="sm">
              <template slot="label"> Agent </template>
              <AgentSelect :agent="agent" @input="setAgent($event)" />
            </b-form-group>
          </b-col>
          <b-col sm="12" md="2" lg="2">
            <b-form-group label-size="sm">
              <template slot="label">From</template>
              <b-form-input
                v-model="dateFrom"
                size="md"
                type="date"
                @change="getAgentsCommissions()"
                :max="dateTo"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12" md="2" lg="2">
            <b-form-group label-size="sm">
              <template slot="label"> To </template>
              <b-form-input
                v-model="dateTo"
                size="md"
                type="date"
                @change="getAgentsCommissions()"
                :min="dateFrom"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12" md="4" lg="4">
            <b-button
              variant="yellow"
              @click="
                resetFilters();
                getVehiclesInfo();
              "
              class="text-white mt-3"
            >
              Clear filters
            </b-button>
       
          </b-col>
        </b-row>
        <div class="d-flex justify-content-between mt-3">
          <b-tabs content-class="">
            <b-tab
              active
              title="Commission"
              @click="onChangeStatus('commission')"
            ></b-tab>

            <b-tab title="Interest" @click="onChangeStatus('interest')"></b-tab>
           
          </b-tabs>
          <div>

          </div>
        </div>
        <div class="table-responsive">
          <b-table
            class=""
            show-empty
            bordered
            small
            stacked="md"
            head-variant="light"
            :items="commissionSummary.data.data || []"
            :fields="fields"
            :busy="isLoading"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle" variant="yellow" />
              </div>
            </template>

            <template #cell(total)="{ item }">
              {{ (item.buyCost + item.addons) | currency }}
            </template>
          </b-table>
        </div>
        <div>
          <b-pagination
            v-if="!isLoading"
            :value="
              commissionSummary.data ? commissionSummary.data.currentPage : 1
            "
            :total-rows="
              commissionSummary.data ? commissionSummary.data.total : 0
            "
            :per-page="
              commissionSummary.data ? commissionSummary.data.perPage : 25
            "
            class="mb-2"
            align="end"
            @change="changePage($event)"
          />
        </div>
      </b-tab>
      <b-tab title="Agents commissions" @click="onChangeTab(2)">
      </b-tab>
    </b-tabs> -->
  </b-container>
</template>
<script>
import { mapState } from "vuex";
/* import AgentSelect from "../../vehicle/components/Agents/AgentSelect.vue";
 */ import AgentsCommissionsTable from "../agent-commissions/AgentCommissionsTable.vue";
import { GET_COMMISSIONS_SUMMARY } from "./actions";
export default {
  name: "CommissionSummaryTable",
  components: {
    /* AgentSelect */
    AgentsCommissionsTable,
  },
  data() {
    return {
      agent: null,
      dateFrom: null,
      dateTo: null,
      type: "commission",
      allFields: [
        {
          key: "saleDate",
          label: "Sale date",
          class: "text-center",
          formatter: (value) => this.$options.filters.formatDate(value),
        },
        {
          key: "paymentDay",
          label: "Payment day",
          class: "text-center",
          formatter: (value) => this.$options.filters.formatDate(value),
        },

        {
          key: "agent",
          label: "Agent",
          class: "text-center",
          formatter: (value) => `${value.firstName} ${value.lastName}`,
        },

        {
          key: "stock",
          label: "Stock number",
          class: "text-center",
        },

        {
          key: "marketPlace",
          label: "Marketplace",
          class: "text-center",
        },
        {
          key: "salePrice",
          label: "Sale price",
          class: "text-right",
          formatter: (value) => this.$options.filters.currency(value),
        },

        {
          key: "buyCost",
          label: "Buy cost",
          class: "text-right",
          formatter: (value) => this.$options.filters.currency(value),
        },
        {
          key: "addons",
          label: "Addons",
          class: "text-right",
          formatter: (value) => this.$options.filters.currency(value),
        },
        {
          key: "total",
          label: "Total cost",
          class: "text-right",
        },

        {
          key: "agentPnl",
          label: "Agent PNL",
          class: "text-right",
          formatter: (value) => this.$options.filters.currency(value),
        },
        {
          key: "interest",
          label: "Interest",
          class: "text-right",
          formatter: (value) => this.$options.filters.currency(value),
        },

        /*   {
          key: "credit",
          label: "Credit",
          class: "text-right",
          formatter: (value) => this.$options.filters.currency(value),
        },
        {
          key: "debits",
          label: "Debits",
          class: "text-right",
          formatter: (value) => this.$options.filters.currency(value),
        }, */
        /*  {
          key: "commissionPayment.date",
          label: "Paid date",
          class: "text-center",
        },
        {
          key: "commissionPayment.reference",
          label: "Reference number",
          class: "text-center",
        }, */
        /*   {
          key: "actions",
          label: "Actions",
          class: "text-center",
        }, */
      ],
      currentTab: 1,
    };
  },
  computed: {
    ...mapState({
      commissionSummary: (state) =>
        state.superAdminStore.commissionSummary.commissionSummary || [],
      fields() {
        if (this.type === "commission") {
          return this.allFields.filter(
            (field) =>
              field.key !== "paymentDay" &&
              field.key !== "interest" &&
              field.key !== "agentInterest"
          );
        }
        if (this.type == "interest") {
          return this.allFields.filter(
            (field) => field.key !== "saleDate" && field.key !== "agentPnl"
          );
        }
        return this.allFields;
      },
    }),
    isLoading() {
      return this.commissionSummary.status === "fetching";
    },
  },
  mounted() {
    this.getCommissionSummary();
  },
  methods: {
    setAgent(agent) {
      this.agent = agent;
      this.getCommissionSummary();
    },
    getCommissionSummary(search = null, page = 1) {
      this.$store
        .dispatch(GET_COMMISSIONS_SUMMARY, {
          search,
          type: this.type,
          agent: this.agent,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          page,
        })
        .then(() => {})
        .catch((error) => {
          console.log("error", error);
        });
    },
    onChangeStatus(type) {
      this.type = type;
      this.getCommissionSummary();
    },
    resetFilters() {
      this.agent = null;
      this.dateFrom = null;
      this.dateTo = null;
    },
    changePage(page) {
      this.getCommissionSummary(null, page);
    },
    onChangeTab(tab) {
      if (tab == 1) {
        this.$store.commit("setSearchFunction", this.getCommissionSummary);
      }
      this.currentTab = tab;
    },
  },
};
</script>
```
