<template>
  <div>
    <b-form-group class="mb-1" label-class="pb-0">
      <template v-slot:label>
        <b> Quick filters: </b>
      </template>
      <b-badge
        v-for="filter in fastFilters"
        :key="filter.label"
        :variant="checkIsFilterActive(filter) ? 'success' : ''"
        class="mr-1 cursor-pointer"
        :class="checkIsFilterActive(filter) ? 'text-black' : ''"
        @click="applyFilter(filter.label, filter.filter)"
      >
        {{ filter.label }}
      </b-badge>
    </b-form-group>
    <!-- iterate fastFilters in badges -->
  </div>
</template>

<script>
import { SET_TASKS_FILTERS } from "../actions";
import { mapState } from "vuex";
export default {
  name: "TasksBoardFiltersFast",

  computed: {
    ...mapState({
      tasksFilters: (state) => state.superAdminStore.tasks.tasks.filters,
    }),
  },
  data() {
    return {
      dateFrom: null,
      dateTo: null,

      fastFilters: [
        {
          label: "Unfinished tasks",
          filter: {
            status: 1,
          },
        },
        {
          label: "Finished tasks",
          filter: {
            status: 2,
          },
        },
        {
          label: "To deliver this week",
          filter: {
            status: 1,
            dueDateTo: this.getLastDayOfTheWeek(),
          },
        },
        {
          label: "To deliver next week",
          filter: {
            status: 1,
            dueDateFrom: this.getDateXDaysAfter(7),
            dueDateTo: this.getDateXDaysAfter(14),
          },
        },
        {
          label: "Only my tasks",
          filter: {
            status: 1,
            ownerId: this.$store.state.auth.loginStore.profile.data.user.id,
          },
        },
        {
          label: "Due soon",
          filter: {
            status: 1,
            dueDateTo: this.getDateXDaysAfter(1),
          },
        },
      ],
    };
  },

  methods: {
    applyFilter(label, value) {
      const payload = {
        reset: true,
        fields: {
          filterFastLabel: label,
          ...value,
        },
      };
      this.$store.commit(SET_TASKS_FILTERS, payload);
    },
    getDateXDaysAgo(numOfDays, date = new Date()) {
      const daysAgo = new Date(date);
      daysAgo.setDate(date.getDate() - numOfDays);
      return this.formatDateString(daysAgo.toString());
    },
    getDateXDaysAfter(numOfDays, date = new Date()) {
      const daysAgo = new Date(date);
      daysAgo.setDate(date.getDate() + numOfDays);
      return this.formatDateString(daysAgo.toString());
    },
    getLastDayOfTheWeek(date = new Date()) {
      const lastDay = new Date(date);
      lastDay.setDate(date.getDate() + (7 - date.getDay()));
      return this.formatDateString(lastDay.toString());
    },
    formatDateString(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const shortDate = `${year}-${month}-${day}`;
      return shortDate;
    },
    checkIsFilterActive(filter) {
      return this.tasksFilters.filterFastLabel == filter.label;
    },
  },
};
</script>

<style></style>
