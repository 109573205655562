<template>
  <b-container fluid class="mt-3">
    <b-row>
      <b-col sm="12" md="3" lg="3">
        <AgentSelect :agent="agent" @input="setAgent($event)" />
        <small v-if="agent == null">Please select an agent</small>
      </b-col>
      <b-col sm="12" md="2" lg="2">
        <b-form-group label-size="sm">
          <template slot="label">From</template>
          <b-form-input
            v-model="dateFrom"
            size="sm"
            type="date"
            @change="getAgentsCommissions()"
            :max="dateTo"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="2" lg="2">
        <b-form-group label-size="sm">
          <template slot="label"> To </template>
          <b-form-input
            v-model="dateTo"
            size="sm"
            type="date"
            @change="getAgentsCommissions()"
            :min="dateFrom"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="4" lg="4">
        <b-button
          variant="yellow"
          @click="
            resetFilters();
            getVehiclesInfo();
          "
          class="text-white mt-2"
        >
          Clear filters
        </b-button>
        <b-button
          variant="success"
          @click="downloadXLS()"
          class="text-white mt-2"
          :disabled="isLoadingVehiclesXLS"
        >
          <b-spinner
            v-if="isLoadingVehiclesXLS"
            class="align-middle"
            variant="white"
            small
          />

          <font-awesome-icon
            v-else
            class="cursor-pointer"
            :icon="['far', 'file-excel']"
          />
        </b-button>
      </b-col>
    </b-row>
    <div
      v-if="commissionSelected.length > 0"
      class="d-flex justify-content-between"
    >
      <span> Rows selected {{ commissionSelected.length }}</span>
      <div>
        Selected total: {{ getTotalSelected | currency }}
        <b-button variant="success" @click="openModal()">Pay total</b-button>
      </div>
    </div>

    <div class="d-flex justify-content-between mt-3">
      <b-tabs content-class="">
        <b-tab title="Unpaid" active @click="onChangeStatus('1')"></b-tab>
        <b-tab title="Paid" @click="onChangeStatus('2')"></b-tab>
        <b-tab title="All" @click="onChangeStatus('1,2')"></b-tab>
      </b-tabs>
      <div>
        <b-form inline>
          <label class="mr-sm-2">Per page</label>
          <b-form-select
            @change="onPerPageChange($event)"
            class="mr-sm-2"
            size="sm"
            v-model="perPage"
            :options="perPageOptions"
          ></b-form-select>
          <b-form-checkbox class="mr-sm-2 mb-sm-0" @change="onSelectAll()">
            <span style="font-size: 16px" class="mt-1">
              Select all
            </span></b-form-checkbox
          >
          <span class="font-weight-bold">
            Total: {{ agentCommissionTotal.data | currency }}</span
          >
        </b-form>
      </div>
    </div>

    <div class="table-responsive">
      <b-table
        class=""
        show-empty
        bordered
        small
        stacked="md"
        head-variant="light"
        :items="commissionsList.data.data || []"
        :fields="fields"
        :busy="isAgentsLoading"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" variant="yellow" />
          </div>
        </template>
        <template #cell(select)="{ item }">
          <b-form-checkbox
            :disabled="item.status == 2"
            @change="onSelectCommission(item)"
            :checked="isSelected(item)"
          ></b-form-checkbox>
        </template>
        <template #cell(agentPnl)="{ item }">
          <span v-if="item.credit > 0"> {{ item.credit | currency }} </span>
          <span v-else> {{ item.debits | currency }} </span>
        </template>
        <template #cell(actions)="row">
          <b-icon icon="eye" @click="row.toggleDetails"></b-icon>
        </template>

        <template #row-details="{ item }">
          <b-card>
            <b-row>
              <b-col sm="12" md="12" lg="12">
                <AddonsResume class="mt-2 px-3" :addons="item.listAddons" />
              </b-col>
              <!--               <b-col sm="12" md="6" lg="6">
                <AddonsBreakdown class="mt-2" :expensesInformation="item" />
              </b-col> -->
            </b-row>
          </b-card>
        </template>
      </b-table>
    </div>
    <div>
      <b-pagination
        v-if="!isAgentsLoading"
        :value="agentsCommissions.data ? agentsCommissions.data.currentPage : 1"
        :total-rows="agentsCommissions.data ? agentsCommissions.data.total : 0"
        :per-page="agentsCommissions.data ? agentsCommissions.data.perPage : 25"
        class="mb-2"
        align="end"
        @change="changePage($event)"
      />
    </div>
    <b-modal
      id="modal-account-status-form"
      ref="modal-account-status-form"
      size="lg"
      hide-footer
      body-class=""
      ok-disabled
    >
      <AccountStatementForm
        :refreshData="getAgentsCommissions"
        :hideModal="hideModal"
        :commissionsSelected="commissionSelected"
        :agentSelected="agent"
      />
    </b-modal>
  </b-container>
</template>

<script>
import { GET_COMMISSIONS_BY_AGENT, GET_COMMISSION_EXCEL } from "./actions";
import { mapState } from "vuex";
import AgentSelect from "../../vehicle/components/Agents/AgentSelect.vue";
import AddonsResume from "./AddonsResume.vue";
import AccountStatementForm from "../account-statement/AccountStatementForm.vue";
/* import AddonsBreakdown from "./AddonsBreakdown.vue";
 */ export default {
  name: "AgentsCommissionsTable",
  components: { AddonsResume, AgentSelect, AccountStatementForm },
  computed: {
    ...mapState({
      agentsCommissions: (state) =>
        state.superAdminStore.agentCommission.agentCommissions || [],

      agentCommissionTotal: (state) =>
        state.superAdminStore.agentCommission.agentCommissionTotal,
    }),
    isAgentsLoading() {
      return this.agentsCommissions.status === "fetching";
    },
    commissionsList() {
      return JSON.parse(JSON.stringify(this.agentsCommissions));
    },
    getTotalSelected() {
      let total = 0;
      this.commissionSelected.forEach((commission) => {
        total += parseFloat(commission.credit);
        total += parseFloat(commission.debits) || 0;
      });
      return total;
    },
    fields() {
      if (this.status === "1,2" || this.status === "2") {
        return this.allFields;
      }
      return this.allFields.filter(
        (field) =>
          field.key !== "commissionPayment.date" &&
          field.key !== "commissionPayment.reference"
      );
    },
  },

  data() {
    return {
      allFields: [
        {
          key: "select",
          label: "Select",
          class: "text-center",
        },
        {
          key: "createdAt",
          label: "Date",
          class: "text-center",
          formatter: (value) => this.$options.filters.formatDate(value),
        },

        {
          key: "concept",
          label: "Concept",
          class: "text-center",
        },
        {
          key: "agent",
          label: "Agent",
          class: "text-center",
          formatter: (value) => `${value.firstName} ${value.lastName}`,
        },

        {
          key: "stock",
          label: "Stock number",
          class: "text-center",
        },
        {
          key: "stockDays",
          label: "Interest days",
          class: "text-center",
        },
        {
          key: "marketPlace",
          label: "Marketplace",
          class: "text-center",
        },
        {
          key: "buyCost",
          label: "Buy cost",
          class: "text-right",
          formatter: (value) => this.$options.filters.currency(value),
        },
        {
          key: "addons",
          label: "Addons",
          class: "text-right",
          formatter: (value) => this.$options.filters.currency(value),
        },

        {
          key: "pad",
          label: "PAD",
          class: "text-right",
          formatter: (value) => this.$options.filters.currency(value),
        },
        {
          key: "salePrice",
          label: "Sale price",
          class: "text-right",
          formatter: (value) => this.$options.filters.currency(value),
        },
        {
          key: "pnl",
          label: "PNL",
          class: "text-right",
          formatter: (value) => this.$options.filters.currency(value),
        },
        {
          key: "interest",
          label: "Interest",
          class: "text-right",
          formatter: (value) => this.$options.filters.currency(value),
        },
        {
          key: "agentPnl",
          label: "Agent PNL",
          class: "text-right",
          formatter: (value) => this.$options.filters.currency(value),
        },

        /* 
        {
          key: "credit",
          label: "Credit",
          class: "text-right",
          formatter: (value) => this.$options.filters.currency(value),
        },
        {
          key: "debits",
          label: "Debits",
          class: "text-right",
          formatter: (value) => this.$options.filters.currency(value),
        }, */
        {
          key: "commissionPayment.date",
          label: "Paid date",
          class: "text-center",
        },
        {
          key: "commissionPayment.reference",
          label: "Reference number",
          class: "text-center",
        },
        {
          key: "sale.company.companyName",
          label: "Sold to",
          class: "text-center",
        },
        {
          key: "actions",
          label: "Actions",
          class: "text-center",
        },
      ],
      agent: null,
      addons: [],
      commissionSelected: [],
      dateFrom: null,
      dateTo: null,
      search: null,
      isLoadingVehiclesXLS: false,
      status: "1",
      perPage: 50,
      perPageOptions: [25, 50, 100, 500, 1000],
      isSelectAll: false,
    };
  },
  beforeMount() {
    this.getAgents();
  },
  created() {
    this.getAgentsCommissions();
  },
  mounted() {
    this.$store.commit("setSearchFunction", this.getAgentsCommissions);
    this.$store.commit("setAddFunction", this.openModal);
  },
  methods: {
    getAgentsCommissions(search = null, page = null, perPage = this.perPage) {
      console.log("getAgentsCommissions");
      this.search = search;
      this.$store
        .dispatch(GET_COMMISSIONS_BY_AGENT, {
          userId: this.agent?.id,
          search,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          page,
          status: this.status,
          perPage,
        })
        .then((response) => {
          this.perPage = response.perPage || this.perPage;
        });
    },

    getValueFromString(string) {
      return Number(string.replace(/[^0-9.-]+/g, ""));
    },

    setAgent(agent) {
      this.agent = agent;
      this.commissionSelected = [];
      this.isSelectAll = false;
      this.getAgentsCommissions();
    },
    getAgents() {
      this.$store
        .dispatch("GET_AGENTS")
        .then(() => {})
        .catch(() => {});
    },
    resetFilters() {
      this.agent = null;
      this.dateFrom = null;
      this.dateTo = null;
      this.isSelectAll = false;
      this.getAgentsCommissions();
    },
    downloadXLS() {
      this.isLoadingVehiclesXLS = true;
      this.$store
        .dispatch(GET_COMMISSION_EXCEL, {
          userId: this.agent?.id,
          search: this.search,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
        })
        .then((response) => {
          const blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          /* name with date */
          let date = new Date();
          let dateStr =
            date.getFullYear() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getDate();
          link.download = `commissions-${dateStr}.xls`;

          link.click();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Could not get XLS", {
            title: "Error",
            variant: "warning",
          });
        })
        .finally(() => {
          this.isLoadingVehiclesXLS = false;
        });
    },
    changePage(page) {
      this.getAgentsCommissions(null, page, this.perPage);
    },
    openModal() {
      this.$bvModal.show("modal-account-status-form");
    },
    hideModal() {
      this.commissionSelected = [];
      this.$bvModal.hide("modal-account-status-form");
    },
    onSelectCommission(commission) {
      const index = this.commissionSelected.findIndex(
        (item) => item.id === commission.id
      );
      if (index === -1) {
        this.commissionSelected.push(commission);
      } else {
        this.commissionSelected.splice(index, 1);
      }
    },
    onChangeStatus(status) {
      this.status = status;
      this.commissionSelected = [];
      this.getAgentsCommissions();
    },
    onPerPageChange(perPage) {
      this.getAgentsCommissions(null, null, perPage);
    },
    onSelectAll() {
      if (this.isSelectAll) {
        this.commissionSelected = [];
      } else {
        this.commissionSelected = this.commissionsList.data.data;
      }
      this.isSelectAll = !this.isSelectAll;
    },
    isSelected(commission) {
      return this.commissionSelected.some((item) => item.id === commission.id);
    },
  },
};
</script>

<style></style>
