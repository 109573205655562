<template>
  <div>
    <b-button
      :disabled="isPrinting"
      type="button"
      variant="yellow"
      class="text-white"
      @click="getAtac()"
    >
      <b-icon icon="printer"></b-icon> Print atac
    </b-button>

    <b-modal
      id="atac-modal"
      ref="modal"
      modal-class="modal-fullscreen"
      centered
      scrollable
      size="sm"
      title="Select printer"
      hide-footer
      @hide="isPrinting = false"
    >
      <b-form-select v-model="printerSelected" class="mb-3">
        <b-form-select-option
          v-for="(printer, i) in printers"
          :key="i"
          :value="printer"
          >{{ printer }}</b-form-select-option
        >
      </b-form-select>

      <button
        :disabled="isPrinting"
        @click="
          printQZ();
          closeModal();
        "
        class="float-right"
      >
        Select printer
      </button>
    </b-modal>
  </div>
</template>

<script>
import qz from "qz-tray";
import { GET_ATAC_FILE } from "./actions";
export default {
  name: "AtacPrintButton",
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      printers: null,
      printerSelected: null,
      fileToPrint: null,
      isPrinting: false,
    };
  },
  mounted() {
    /*   this.certificate();

    this.connectQZ(); */
  },
  methods: {
    certificate() {
      console.log("security", qz);
      qz.security.setCertificatePromise(function (resolve) {
        resolve(`-----BEGIN CERTIFICATE-----
MIIEFTCCAv2gAwIBAgIUBmRREjVGZgjQHd5feAhr2c5vdkwwDQYJKoZIhvcNAQEL
BQAwgagxCzAJBgNVBAYTAlVTMRMwEQYDVQQIEwpDYWxpZm9ybmlhMRYwFAYDVQQH
Ew1TYW4gRnJhbmNpc2NvMRkwFwYDVQQKExBDbG91ZGZsYXJlLCBJbmMuMRswGQYD
VQQLExJ3d3cuY2xvdWRmbGFyZS5jb20xNDAyBgNVBAMTK01hbmFnZWQgQ0EgMTRk
MDE2NDdjOGQ0NTVhYThhZmRkNThkZGRhMjNiNzIwHhcNMjIxMDE3MjE0NzAwWhcN
MzIxMDE0MjE0NzAwWjAiMQswCQYDVQQGEwJVUzETMBEGA1UEAxMKQ2xvdWRmbGFy
ZTCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEBAMC1dslMLM55OivX6OuF
Lv8vZAD+V+1g9dQehE0btRPOkmIkpixHRiC+bBFytu3hgVDZdlLQCDMZgxz5BcjL
pqDVr6o1C6R7Pq0mHbR3NAhKtx1gqZvDosQwbp7nrOzqxwF8uP8Z9cLVtT2NdP2E
PaOGp/cJO1OniTOsJIWKiMObCzY7mxjEFvQQgphPh48ZtNwEML2VErSa4laLLwag
6o2vBpqlrQvzTfKBnB8UtFXHAeRqqt42BX9U3CIm3Ezp7SwqRbaHXOZuD/9ZPrcv
Hgl1w5y0vqDRZnRUzgqKnSdNZDkzbmbxFF0APxNJ9APF8LZbKmU/LZjanThQwUp3
TqUCAwEAAaOBuzCBuDATBgNVHSUEDDAKBggrBgEFBQcDAjAMBgNVHRMBAf8EAjAA
MB0GA1UdDgQWBBRVjVC4/mtt4T34/PDZ/2hVRL/HxzAfBgNVHSMEGDAWgBQOnrWx
juN7lq6q++EnhaMCmi3fCjBTBgNVHR8ETDBKMEigRqBEhkJodHRwOi8vY3JsLmNs
b3VkZmxhcmUuY29tL2ZlNzQ1MWQwLTY4YTEtNDEwNi05MjVhLTA1YzUxM2RhMWYy
Yi5jcmwwDQYJKoZIhvcNAQELBQADggEBAAk3db6Dmec7hAcWGAXG4f9ggMhjDRwi
gUtFRa6QanvvyfUfC85wA25SeCGEdasgf/5mLTTACos0ul7auYtbvUU8wYjHdmCT
kXt+gi/9pDDNKAQMj5+WTlM9ndCdaILAfMv/6HfUnRjP7h00E1Rx6mAlXR9umbXR
3JXajhXZPnQsq+lIGsr889qo9+PB63hZRMEzFRC+3jlGJ8s7rm1JBZxko9wktGV4
ec5Gq9hUzI7wfNhNoH9L5nqZmC+vladK3UdBpok97K8lknoiqjzA0kUxhSeOYsE+
CvFe4s0WR5+e43mQu2CdV3lzte37txzwQg8ErZ/c5f3pWNspEqUg1Gs=
-----END CERTIFICATE-----
`);
      });

      qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
      qz.security.setSignaturePromise(function (toSign) {
        return function (resolve, reject) {
          fetch("/secure/url/for/sign-message?request=" + toSign, {
            cache: "no-store",
            headers: { "Content-Type": "text/plain" },
          }).then(function (data) {
            data.ok ? resolve(data.text()) : reject(data.text());
          });
        };
      });
    },
    connectQZ() {
      console.log(qz.websocket);
      if (!qz.websocket.isActive()) {
        qz.websocket
          .connect()
          .then(() => {
            this.findPrinters();
          })
          .catch(() => {});
      } else {
        this.findPrinters();
      }
    },
    findPrinters() {
      qz.printers
        .find()
        .then((data) => {
          console.log(data);

          this.printers = data;
        })
        .catch(function (e) {
          console.error(e);
        });
    },
    printQZ() {
      this.isPrinting = true;

      var config = qz.configs.create(this.printerSelected);
      var data = [
        {
          type: "pdf",
          data: this.fileToPrint,
        },
      ];
      qz.print(config, data)
        .then((log) => {
          this.isPrinting = false;
          console.log(log);
        })
        .catch((e) => {
          console.error(e);
          this.isPrinting = false;
        });
    },
    getAtac() {
      this.$store
        .dispatch(GET_ATAC_FILE, { vehicleId: this.vehicle.id })
        .then((response) => {
          this.fileToPrint = response.url;
          window.open(this.fileToPrint, "_blank");

          /*           this.openModal();
           */
        });
    },
    openModal() {
      this.$bvModal.show("atac-modal");
    },
    closeModal() {
      this.$bvModal.hide("atac-modal");
    },
  },
};
</script>

<style>
</style>