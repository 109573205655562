<template>
  <div>
    <b-form @submit.stop.prevent="getTrims">
      <b-row>
        <b-col cols="12">
          <b-row class="mb-0">
            <b-col> Vin </b-col>
          </b-row>
          <b-input-group class="mt-1" label="Vin">
            <b-form-input v-model="form.vinNumber" />
            <b-input-group-append>
              <b-button variant="yellow" size="sm" @click="openVinInfo()">
                <b-icon icon="question-circle" variant="white"></b-icon>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col cols="12">
          <label class="mb-0 mt-1" for="">Kms</label>
          <b-input type="number" v-model="form.kms" />
        </b-col>
        <b-col cols="12">
          <label class="mb-0 mt-1" for="">Pc</label>
          <b-input v-model="form.pc" />
        </b-col>
      </b-row>
      <b-col cols="12" class="text-center mt-2">
        <b-button
          :disabled="trims.status == 'fetching'"
          class="shadow"
          variant="success"
          size="lg"
          type="submit"
          >Submit</b-button
        >
      </b-col>
    </b-form>
    <b-modal
      id="vin-info"
      ref="vin-info"
      size="md"
      hide-footer
      body-class="pl-0 pr-0"
      ok-disabled
    >
      <VinNumberInfo />
    </b-modal>
  </div>
</template>

<script>
import { GET_TRIMS_BY_PRICING, SET_TRIMS_FILTER } from "../actions";
import VinNumberInfo from "./VinNumberInfo.vue";
import { mapState } from "vuex";
export default {
  components: {
    VinNumberInfo,
  },
  data() {
    return {
      form: {},
    };
  },
  computed: {
    ...mapState({
      trims: (state) => state.pricing.trims,
    }),
  },
  methods: {
    getTrims() {
      const payload = {
        vinNumber: this.form.vinNumber,
        kms: this.form.kms,
        pc: this.form.pc,
      };
      this.$store.commit(SET_TRIMS_FILTER, { reset: true });

      this.$store.commit(SET_TRIMS_FILTER, { fields: payload });
      this.$store
        .dispatch(GET_TRIMS_BY_PRICING)
        .then(() => {
          this.$emit("goToTrimList", true);
        })
        .catch(() => {
          this.$emit("goToTrimList", true);
        });
    },
    openVinInfo() {
      this.$bvModal.show("vin-info");
    },
  },
};
</script>

<style>
</style>