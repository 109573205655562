var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-form',{staticClass:"container p-4 mb-3 mt-5",attrs:{"container":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"xl":"3"}}),_c('b-col',{attrs:{"sm":"12","md":"12","lg":"4","xl":"6"}},[_c('b-form-group',{attrs:{"label-size":"sm"}},[_c('template',{slot:"label"},[_c('b',[_vm._v("Email:")])]),_c('b-form-input',{attrs:{"size":"sm","autocomplete":"off","type":"email","formatter":_vm.lowerCaseFormatter,"state":_vm.validateState('email')},on:{"change":function($event){return _vm.setValue('email', $event)}},model:{value:(_vm.$v.form.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.email, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.email.$model"}}),_c('small',{class:{
            'text-success': _vm.$v.form.email.required,
            'text-danger': !_vm.$v.form.email.required,
          }},[_vm._v(" This field is required "),_c('br')]),_c('small',{class:{
            'text-success': _vm.form.email && _vm.$v.form.email.email,
            'text-danger': !_vm.form.email || !_vm.$v.form.email.email,
          }},[_vm._v(" This must be a valid email. ")])],2)],1)],1),_c('b-row',[_c('b-col',{attrs:{"xl":"3"}}),_c('b-col',{attrs:{"sm":"12","md":"12","xl":"6"}},[_c('b-button',{staticClass:"float-right btn-block",attrs:{"disabled":_vm.$v.form.$invalid,"variant":"yellow","type":"submit"}},[(_vm.recoverPasswordStatus === 'fetching')?_c('b-spinner',{attrs:{"variant":"white","label":"Spinning","small":""}}):_c('span',[_vm._v(" Recover password ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }