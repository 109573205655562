import loginStore from './login/store';
import checkTokenStore from './checkToken/store';
import forgotPasswordStore from './forgotPassword/store';

export default {
  state: {},
  mutations: {},
  actions: {},
  modules: {
    loginStore,
    checkTokenStore,
    forgotPasswordStore
  }
};
