import * as constants from "./actions";
import axios from "@/plugins/axios.js";
function getDefaultState() {
  return {
    exception: {
      status: null,
      data: {},
      error: null,
    },
  };
}
const actions = {
  [constants.POST_EXCEPTION]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_EXCEPTION);
      const response = await axios.post("vehicle/createException ", payload);
      commit(constants.POST_EXCEPTION_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_EXCEPTION_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.POST_EXCEPTION]: (state) => {
    state.exception.status = "fetching";
    state.exception.data = {};
  },
  [constants.POST_EXCEPTION_SUCCESS]: (state, response) => {
    state.exception.status = "success";
    state.exception.data = response;
  },
  [constants.POST_EXCEPTION_ERROR]: (state, response) => {
    state.exception.status = "error";
    state.exception.error = response;
  },
};
export default {
  state: getDefaultState(),
  actions,
  mutations,
};
