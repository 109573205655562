<template>
  <div>
    <!-- button to add a row -->
    <div class="d-flex justify-content-end">
      <b-button
        @click="travels.push({ origin: null, destination: null, waitTime: 0 })"
        variant="primary"
        class="mb-1"
        size="sm"
      >
        <b-icon icon="plus" scale="1.5" />
      </b-button>
    </div>

    <div class=""></div>
    <b-table
      show-empty
      bordered
      small
      stacked="md"
      head-variant="light"
      :items="travels"
      :fields="fields"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" variant="yellow" />
        </div>
      </template>
      <template #cell(origin)="{ item }">
        <div class="text-center">
          <vue-single-select
            :classes="{ input: 'form-control', dropdown: 'dropdown' }"
            v-model="item.origin"
            :options="origins"
            optionLabel="description"
          >
          </vue-single-select>
        </div>
      </template>
      <template #cell(destination)="{ item }">
        <div class="text-center">
          <vue-single-select
            :classes="{ input: 'form-control', dropdown: 'dropdown' }"
            v-model="item.destination"
            :options="destinations"
            optionLabel="description"
          >
          </vue-single-select>
        </div>
      </template>
      <template #cell(actions)="item">
        <!-- delete icon  -->
        <b-icon
          icon="trash"
          class="mt-3 p-0 ml-3 cursor-pointer"
          scale="1.5"
          @click="travels.splice(travels.indexOf(item), 1)"
        ></b-icon>
      </template>

      <template #cell(waitingTime)="{ item }">
        <div class="text-center">
          <b-form-input v-model="item.waitTime" type="number" min="0" />
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import VueSingleSelect from "vue-single-select";

export default {
  name: "TravelsTable",
  components: {
    VueSingleSelect,
  },
  props: {
    origins: {
      type: Array,
      required: true,
    },
    destinations: {
      type: Array,
      required: true,
    },
  },
  watch: {
    travels: {
      handler: function (val) {
        this.$emit("updateTravels", val);
      },
      deep: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "origin",
          label: "Origin",
          thClass: "w-40",
        },
        {
          key: "destination",
          label: "Destination",
          thClass: "w-40",
        },
        {
          key: "waitingTime",
          label: "Waiting time",
          thClass: "w-10",
        },
        {
          key: "actions",
          label: "",

          thClass: "w-10",
        },
      ],
      travels: [
        {
          origin: null,
          destination: null,
          waitTime: 0,
        },
      ],
      loading: false,
      error: null,
    };
  },
};
</script>
