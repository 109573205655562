
import DashboardLayout from '@/layouts/DashboardLayout.vue';

import Profile from "./profile/ProfileView.vue";
import AccountStatementView from "./account-statement/AccountStatementView.vue";

import { ifAuthenticated } from "@/router/navigationGuards";

const routes = [
  {
    path: "/user",
    component: DashboardLayout,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
    children: [
      {
        path: "profile",
        component: Profile,
        name: "user.profile",
        meta: {
          name: "Profile",
        }

      },
      {
        path: "account-statement",
        component: AccountStatementView,
        name: "user.account-statement",
        meta: {
          name: "Account Statement",
        }
      }
    ],
  },
];

export default routes;
