<template>
  <b-container class="mt-3" fluid>
    <b-form @submit.stop.prevent="updateInterest()">
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-form-group label="">
            <template slot="label"> <h2>Corporation interest</h2> </template>

            <b-input-group append="%">
              <b-form-input
                @change="updateCorporationInterest($event)"
                :value="corporation.interest"
                type="number"
                min="0"
                max="100"
                step="0.1"
              />
            </b-input-group>
          </b-form-group>
          <div class="text-right">
            <b-button
              variant="primary"
              type="submit"
              :disabled="isInterestLoading"
            >
              <b-spinner v-if="isInterestLoading" variant="white" small />

              <span v-else>Submit</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
    <!--     <MarketplaceInterest />
 -->

    <b-tabs content-class="mt-3">
      <b-tab @click="tableType = 'agents'" title="Agent commissions" active>
        <AgentsCommissions v-if="tableType == 'agents'" />
      </b-tab>
      <b-tab @click="tableType = 'pad'" title="Pad commission">
        <PadCommissionTable v-if="tableType == 'pad'" />
      </b-tab>
    </b-tabs>
  </b-container>
</template>

<script>
import { mapState } from "vuex";
import { PUT_INTEREST } from "./actions";
/* import MarketplaceInterest from "./MarketplaceInterest.vue";
 */ import AgentsCommissions from "./AgentsCommissions.vue";
import PadCommissionTable from "./pad-commission/PadCommissionTable.vue";
export default {
  name: "InterestForm",
  components: { AgentsCommissions, PadCommissionTable },
  computed: {
    ...mapState({
      profile: (state) => state.auth.loginStore.profile.data,
      interest: (state) => state.superAdminStore.interest.interest,
    }),
    isInterestLoading() {
      return this.interest.status == "fetching";
    },
    corporation() {
      const corporation = localStorage.getItem("corporation");
      let corporationSelect = this.profile.user.corporationPermissions.find(
        (c) => c.id == corporation
      );

      return corporationSelect
        ? corporationSelect
        : this.profile.user.corporationPermissions[0]
        ? this.profile.user.corporationPermissions[0]
        : { name: "" };
    },
  },
  mounted() {
    this.form.interest = this.corporation.interest;
  },
  data() {
    return {
      form: {
        interest: 0,
      },
      tableType: "agents",
    };
  },
  methods: {
    updateCorporationInterest(event) {
      this.form.interest = event;
    },
    updateInterest() {
      this.$store
        .dispatch(PUT_INTEREST, {
          interest: this.form.interest,
        })
        .then(() => {
          this.$store.dispatch("AUTH_CHECK_TOKEN");
          this.$bvToast.toast("Corporation interest updated", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        });
    },
  },
};
</script>

<style></style>
