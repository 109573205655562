import * as constants from "./actions";
import axios from "@/plugins/axios.js";
import notes from "./notes/store.js";

function getDefaultState() {
  return {
    workOrder: {
      status: null,
      data: {},
      error: null,
    },
    workOrders: {
      status: null,
      data: [],
      error: null,
    },
    damagePictures: {
      status: null,
      data: [],
      error: null,
    },
    workOrderXLS: {
      status: null,
      data: {},
      error: null,
    },
    workOrderEmail: {
      status: null,
      data: {},
      error: null,
    },
    workOrdersNoProvider: {
      status: null,
      data: [],
      error: null,
    },
    workOrderTask: {
      status: null,
      data: {},
      error: null
    },
    workOrderCostHistory: {
      status: null,
      data: [],
      error: null
    }
  };
}

const actions = {
  [constants.POST_WORK_ORDER]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_WORK_ORDER);
      const response = await axios.post("workOrder/create", payload);
      commit(constants.POST_WORK_ORDER_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_WORK_ORDER_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_WORK_ORDERS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_WORK_ORDERS);
      const response = await axios.get("workOrder/list", { params: payload });
      commit(constants.GET_WORK_ORDERS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_WORK_ORDERS_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_WORK_ORDERS_NO_PROVIDER]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_WORK_ORDERS_NO_PROVIDER);
      const response = await axios.get("workOrder/listEditable", {
        params: payload,
      });
      commit(constants.GET_WORK_ORDERS_NO_PROVIDER_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_WORK_ORDERS_NO_PROVIDER_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_DAMAGE_PICTURES]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_DAMAGE_PICTURES);
      const response = await axios.get("workOrder/listDamagePhotos", {
        params: payload,
      });
      commit(constants.GET_DAMAGE_PICTURES_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_DAMAGE_PICTURES_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_WORK_ORDER]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_WORK_ORDER);
      const response = await axios.put("workOrder/changeStatus", payload);
      commit(constants.PUT_WORK_ORDER_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_WORK_ORDER_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_WORK_ORDER_XLS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_WORK_ORDER_XLS);
      const response = await axios.get("workOrder/generateXls", {
        params: payload,
        responseType: "blob",
      });
      commit(constants.GET_WORK_ORDER_XLS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_WORK_ORDER_XLS_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_WORK_ORDER_EMAIL]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_WORK_ORDER_EMAIL);
      const response = await axios.get("workOrder/sendWorkOrder", {
        params: payload,
      });
      commit(constants.GET_WORK_ORDER_EMAIL_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_WORK_ORDER_EMAIL_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_WORK_ORDER_ONLY_CATEGORY]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_WORK_ORDER_ONLY_CATEGORY);
      const response = await axios.get("workOrder/createRightButton", {
        params: payload,
      });
      commit(constants.POST_WORK_ORDER_ONLY_CATEGORY_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_WORK_ORDER_ONLY_CATEGORY_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_WORK_ORDER_TASK_STATUS]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_WORK_ORDER_TASK_STATUS);
      const response = await axios.put("workOrder/itemChangeStatus", payload);
      commit(constants.PUT_WORK_ORDER_TASK_STATUS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_WORK_ORDER_TASK_STATUS_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_WORK_ORDER_COST_HISTORY]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_WORK_ORDER_COST_HISTORY);
      const response = await axios.get("workOrder/getCostHistory", {
        params: payload
      });
      commit(constants.GET_WORK_ORDER_COST_HISTORY_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_WORK_ORDER_COST_HISTORY_ERROR, error);
      throw error.response;
    }
  }
};

const mutations = {
  [constants.POST_WORK_ORDER]: (state) => {
    state.workOrder.status = "fetching";
    state.workOrder.error = null;
  },
  [constants.POST_WORK_ORDER_SUCCESS]: (state, response) => {
    state.workOrder.status = "success";
    state.workOrder.data = response.data;
  },
  [constants.POST_WORK_ORDER_ERROR]: (state, error) => {
    state.workOrder.status = "error";
    state.workOrder.error = error;
  },
  [constants.GET_WORK_ORDERS]: (state) => {
    state.workOrders.status = "fetching";
    state.workOrders.error = null;
  },
  [constants.GET_WORK_ORDERS_SUCCESS]: (state, response) => {
    state.workOrders.status = "success";
    state.workOrders.data = response.data;
  },
  [constants.GET_WORK_ORDERS_ERROR]: (state, error) => {
    state.workOrders.status = "error";
    state.workOrders.error = error;
  },
  [constants.GET_DAMAGE_PICTURES]: (state) => {
    state.damagePictures.status = "fetching";
    state.damagePictures.error = null;
  },
  [constants.GET_DAMAGE_PICTURES_SUCCESS]: (state, response) => {
    state.damagePictures.status = "success";
    state.damagePictures.data = response.data;
  },
  [constants.GET_DAMAGE_PICTURES_ERROR]: (state, error) => {
    state.damagePictures.status = "error";
    state.damagePictures.error = error;
  },
  [constants.PUT_WORK_ORDER]: (state) => {
    state.workOrder.status = "fetching";
    state.workOrder.error = null;
  },
  [constants.PUT_WORK_ORDER_SUCCESS]: (state, response) => {
    state.workOrder.status = "success";
    state.workOrder.data = response.data;
  },
  [constants.PUT_WORK_ORDER_ERROR]: (state, error) => {
    state.workOrder.status = "error";
    state.workOrder.error = error;
  },
  [constants.GET_WORK_ORDER_XLS]: (state) => {
    state.workOrderXLS.status = "fetching";
    state.workOrderXLS.error = null;
  },
  [constants.GET_WORK_ORDER_XLS_SUCCESS]: (state, response) => {
    state.workOrderXLS.status = "success";
    state.workOrderXLS.data = response.data;
  },
  [constants.GET_WORK_ORDER_XLS_ERROR]: (state, error) => {
    state.workOrderXLS.status = "error";
    state.workOrderXLS.error = error;
  },
  [constants.GET_WORK_ORDER_EMAIL]: (state) => {
    state.workOrderEmail.status = "fetching";
    state.workOrderEmail.error = null;
  },
  [constants.GET_WORK_ORDER_EMAIL_SUCCESS]: (state, response) => {
    state.workOrderEmail.status = "success";
    state.workOrderEmail.data = response.data;
  },
  [constants.GET_WORK_ORDER_EMAIL_ERROR]: (state, error) => {
    state.workOrderEmail.status = "error";
    state.workOrderEmail.error = error;
  },
  [constants.GET_WORK_ORDERS_NO_PROVIDER]: (state) => {
    state.workOrdersNoProvider.status = "fetching";
    state.workOrdersNoProvider.error = null;
  },
  [constants.GET_WORK_ORDERS_NO_PROVIDER_SUCCESS]: (state, response) => {
    state.workOrdersNoProvider.status = "success";
    state.workOrdersNoProvider.data = response.data;
  },
  [constants.GET_WORK_ORDERS_NO_PROVIDER_ERROR]: (state, error) => {
    state.workOrdersNoProvider.status = "error";
    state.workOrdersNoProvider.error = error;
  },
  [constants.POST_WORK_ORDER_ONLY_CATEGORY]: (state) => {
    state.workOrder.status = "fetching";
    state.workOrder.error = null;
  },
  [constants.POST_WORK_ORDER_ONLY_CATEGORY_SUCCESS]: (state, response) => {
    state.workOrder.status = "success";
    state.workOrder.data = response.data;
  },
  [constants.POST_WORK_ORDER_ONLY_CATEGORY_ERROR]: (state, error) => {
    state.workOrder.status = "error";
    state.workOrder.error = error;
  },
  [constants.PUT_WORK_ORDER_TASK_STATUS]: (state) => {
    state.workOrderTask.status = "fetching";
    state.workOrderTask.error = null;
  },
  [constants.PUT_WORK_ORDER_TASK_STATUS_SUCCESS]: (state, response) => {
    state.workOrderTask.data = response.data;
    state.workOrderTask.status = "success";
  },
  [constants.PUT_WORK_ORDER_TASK_STATUS_ERROR]: (state, error) => {
    state.workOrderTask.error = error;
    state.workOrderTask.status = "error";
  },
  [constants.GET_WORK_ORDER_COST_HISTORY]: (state) => {
    state.workOrderCostHistory.status = "fetching";
    state.workOrderCostHistory.error = null;
  },
  [constants.GET_WORK_ORDER_COST_HISTORY_SUCCESS]: (state, response) => {
    state.workOrderCostHistory.data = response.data;
    state.workOrderCostHistory.status = "success";
  },
  [constants.GET_WORK_ORDER_COST_HISTORY_ERROR]: (state, error) => {
    state.workOrderCostHistory.error = error;
    state.workOrderCostHistory.status = "error";
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
  modules: {
    notes,
  },
};
