<template>
  <b-dropdown
    ref="filter-dropdown"
    variant="none"
    toggle-class="p-0 text-decoration-none"
    menu-class="menu-min-width p-3  max-width-100"
    no-caret
    boundary="window"
  >
    <template #button-content>
      <b-icon
        class="cursor-pointer"
        icon="filter"
        :variant="hasFilter() ? 'success' : ''"
      ></b-icon>
    </template>

    <b-spinner
      v-if="filtersListStatus == 'fetching'"
      class="align-middle"
      variant="yellow"
      small
    />

    <div v-else style="min-width: 300px" class="p-0">
      <MultipleSelectFilter
        v-if="typeFilter == 'multiple'"
        :type="type"
        :value="filters"
        :options="filtersList ? filtersList[type] : []"
        @input="onChangeFilters"
      />

      <RangeSelectFilter
        v-if="typeFilter == 'range'"
        :type="rangeType"
        :value="filters"
        @input="onChangeFilters"
      />
    </div>
    <b-dropdown-divider></b-dropdown-divider>
    <div class="text-center">
      <div class="float-right">
        <b-button
          size="sm"
          variant="success"
          class="mx-1"
          @click="applyFilters()"
          >Apply</b-button
        >

        <b-button
          size="sm"
          variant="yellow"
          class="mx-1 text-white"
          @click="clearFilter()"
          >Clear</b-button
        >
      </div>
    </div>
  </b-dropdown>
</template>

<script>
import MultipleSelectFilter from "./MultipleSelectFilter.vue";
import RangeSelectFilter from "./RangeSelectFilter.vue";
import { mapState } from "vuex";
export default {
  name: "FilterColum",
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  components: {
    MultipleSelectFilter,
    RangeSelectFilter,
  },

  data() {
    return {
      filters: [],
    };
  },
  computed: {
    ...mapState({
      filtersStore: (state) =>
        state.superAdminStore.customReports.customReportInfo.filters,
      filtersList: (state) =>
        state.superAdminStore.customReports.filtersList.data,
      filtersListStatus: (state) =>
        state.superAdminStore.customReports.filtersList.status,
    }),
    typeFilter() {
      if (
        this.type == "marketPlace" ||
        this.type == "color" ||
        this.type == "user" ||
        this.type == "buyFrom" ||
        this.type == "location" ||
        this.type == "soldInfo" ||
        this.type == "make" ||
        this.type == "model" ||
        this.type == "reservesCompanyName" ||
        this.type == "reservesTypeCurrency" ||
        this.type == "status" ||
        this.type == "reserve"
      ) {
        return "multiple";
      }
      return "range";
    },
    rangeType() {
      if (
        this.type == "stockDaysRange" ||
        this.type == "milesRange" ||
        this.type == "yearRange" ||
        this.type == "priceRange" ||
        this.type == "reservesPriceRange" ||
        this.type == "costRange" ||
        this.type == "costUsdRange" ||
        this.type == "fxRange" ||
        this.type == "salePriceCadRange" ||
        this.type == "salePriceUsdRange" ||
        this.type == "totalCadRange" ||
        this.type == "totalUsdRange" ||
        this.type == "pnlCadRange" ||
        this.type == "pnlUsdRange"
      ) {
        return "number";
      }
      return "date";
    },
  },
  watch: {
    filtersStore: {
      handler() {
        this.checkIfFilterInStore();
      },
      deep: true,
    },
  },

  methods: {
    hasFilter() {
      if (this.filters.length > 0) {
        return true;
      }

      if (this.filtersStore[this.type] != null) {
        return true;
      }
      return false;
    },
    onChangeFilters(value) {
      this.filters = value;
    },
    applyFilters() {
      this.$emit("applyFilters", {
        [this.type]: this.convertArrayToString(this.filters),
      });
    },
    convertArrayToString(array) {
      return array.join(",");
    },
    clearFilter() {
      this.filters = [];
      this.$emit("applyFilters", {
        [this.type]: null,
      });
    },
    checkIfFilterInStore() {
      if (this.filtersStore[this.type] != null) {
        this.filters = this.filtersStore[this.type].split(",");
        console.log("checkIfFilterInStore", this.filters);
      } else {
        this.filters = [];
      }
    },
  },
};
</script>

<style scoped>
.menu-min-width {
  min-width: 6rem !important;
}
.hideOverflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}
.max-width-100 {
  max-width: 3rem;
}
</style>
