export const GET_CORPORATIONS = "GET_CORPORATIONS";
export const GET_CORPORATIONS_SUCCESS = "GET_CORPORATIONS_SUCCESS";
export const GET_CORPORATIONS_ERROR = "GET_CORPORATIONS_ERROR";

export const GET_CORPORATION_LIST = "GET_CORPORATION_LIST";
export const GET_CORPORATION_LIST_SUCCESS = "GET_CORPORATION_LIST_SUCCESS";
export const GET_CORPORATION_LIST_ERROR = "GET_CORPORATION_LIST_ERROR";

export const POST_CORPORATION = "POST_CORPORATION";
export const POST_CORPORATION_SUCCESS = "POST_CORPORATION_SUCCESS";
export const POST_CORPORATION_ERROR = "POST_CORPORATION_ERROR";

export const PUT_CORPORATION = "PUT_CORPORATION";
export const PUT_CORPORATION_SUCCESS = "PUT_CORPORATION_SUCCESS";
export const PUT_CORPORATION_ERROR = "PUT_CORPORATION_ERROR";

export const PUT_ASSIGN_CORPORATION = "PUT_ASSIGN_CORPORATION";
export const PUT_ASSIGN_CORPORATION_SUCCESS = "PUT_ASSIGN_CORPORATION_SUCCESS";
export const PUT_ASSIGN_CORPORATION_ERROR = "PUT_ASSIGN_CORPORATION_ERROR";
