<template>
  <b-card class="shadow h-100">
    <b-row
      v-if="vehicle.new && !viewOffers && !isActiveOffer && !isExpiredOffer"
      class="text-right"
    >
      <b-col class="mr-4">
        <img
          src="@/assets/img/icons/new-1.png"
          alt="star-icon"
          class="img-fluid star-icon"
        />
      </b-col>
    </b-row>
    <div
      v-if="vehicle.status !== 'Sold' && viewOffers && !isActiveOffer"
      class="text-center"
    >
      <b>Highest Offer</b>
      <h1>
        <i class="highest-offer">{{ vehicle.highestOffer | currency }}</i>
      </h1>
    </div>
    <div v-if="vehicle.status == 'Sold' && isExpiredOffer">
      <b-row>
        <b-col class="mt-1 text-center">
          <h5>Final Sale Price</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mt-1 text-center">
          <h1 class="buyer-offer">
            {{ vehicle.finalPrice | currency }}
          </h1>
        </b-col>
      </b-row>
    </div>
    <div
      v-else-if="isActiveOffer || isExpiredOffer"
      class="mt-1 mb-1"
      :class="{ 'text-center': userOffer && !userOffer.lastOfferAdmin }"
    >
      <b-row>
        <b-col v-if="lastOfferUser">
          <span>
            <h5 v-if="isActiveOffer">Current Offer</h5>
          </span>
          <b-row v-if="!isExpiredOffer">
            <b-col>
              <h1>
                <i class="buyer-offer">
                  <strong>
                    {{ lastOfferUser.offerPrice | currency }}
                  </strong>
                </i>
              </h1>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row
        v-if="
          vehicle.status !== 'Sold' &&
          vehicle.offerClient &&
          userOffer &&
          userOffer.lastOfferAdmin
        "
        class="mt-2 mb-2"
      >
        <b-col class="mt-1 text-center">
          <h5>Canam Offer</h5>
          <b-row>
            <b-col>
              <h1>
                <i class="admin-offer">
                  <strong>
                    {{ userOffer.lastOfferAdmin.offerPrice | currency }}
                  </strong>
                </i>
              </h1>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <car-gallery
      v-if="!isActiveOffer && !isExpiredOffer && !viewOffers"
      :exterior="exterior"
      :interior="interior"
      :damages="damages"
      :damages-dots="damagesDots"
      :vehicle-model="vehicle.vinNumber.model"
      :vin-number="vehicle.stockNumber"
      :make="vehicle.vinNumber.make"
      :model="vehicle.vinNumber.model"
      size="width: auto;  height: 4rem!important;"
      class-img="img-thumbnail"
      @onSelectImage="selectImage"
      @onChangeTab="changeTab"
    >
      <template #top>
        <b-row class="mb-3 pl-3 pr-3">
          <b-col class="text-center m-0 p-0">
            <Photoswipe rotate>
              <img
                v-pswp="
                  imgNotFound || imgError
                    ? imgError
                    : tab === 0
                    ? exterior[index]
                    : interior[index]
                "
                style="height: 18rem"
                class="carousel-item active main-img img-thumbnail"
                :src="
                  imgNotFound || imgError
                    ? imgError
                    : tab === 0
                    ? exterior[index]
                    : interior[index]
                "
                alt="car"
                @error="errorLoadImg"
              />
            </Photoswipe>
            <a
              v-if="index > 0"
              class="carousel-control-prev"
              role="button"
              data-slide="prev"
              @click="backImg"
            >
              <b-icon scale="2" icon="arrow-left-circle-fill" />
              <span class="sr-only">Previous</span>
            </a>
            <a
              v-if="index < exterior.length - 1"
              class="carousel-control-next"
              role="button"
              data-slide="next"
              @click="nextImg"
            >
              <b-icon scale="2" icon="arrow-right-circle-fill" />
              <span class="sr-only">Next</span>
            </a>
          </b-col>
        </b-row>
      </template>
    </car-gallery>
    <b-row v-if="!isHome" style="padding-left: -2rem; margin-bottom: -2px">
      <b-col class="mt-3">
        <h6 v-if="vehicle.stockNumber" class="stockNumber-mt">
          Stock:
          <i :class="{ stockNumber: viewOffers || isActiveOffer }">
            {{ vehicle.stockNumber }}
          </i>
        </h6>
      </b-col>
    </b-row>
    <b-row :class="{ 'mt-2': isHome }">
      <b-col>
        <b-row>
          <b-col>
            <b-row>
              <b-col v-if="vehicle.make || vehicle.vinNumber.make">
                <div class="mb-2">
                  Make:
                  <i>{{ vehicle.make || vehicle.vinNumber.make }}</i>
                </div>
              </b-col>
              <b-col v-if="vehicle.vinNumber.year || vehicle.year">
                <div class="ml-2 mb-2">
                  Year:
                  <i>{{ vehicle.vinNumber.year || vehicle.year }}</i>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="mb-2">
              Model:
              <i>
                {{ vehicle.model || vehicle.vinNumber.model }}
              </i>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="
              vehicle.trim ||
              (typeof vehicle.vinNumber === 'object' && vehicle.vinNumber.trim)
            "
          >
            <div class="mb-2">
              <i>{{ vehicle.trim || vehicle.vinNumber.trim }}</i>
            </div>
          </b-col>
          <b-col v-if="vehicle.options || vehicle.vinNumber.options">
            <div
              class="mb-2"
              :class="{
                'ml-2':
                  vehicle.trim ||
                  (typeof vehicle.vinNumber === 'object' &&
                    vehicle.vinNumber.trim),
              }"
            >
              <i>{{ vehicle.options || vehicle.vinNumber.options }}</i>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="vehicle.miles || vehicle.vehicleInformation.miles">
            <div :class="{ 'mb-2': !user && !vehicle.releaseDate }">
              Mileage:
              <i>{{ vehicle.miles || vehicle.vehicleInformation.miles }}</i>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="vehicle.releaseDateInReady" class="mb-2 mt-2">
          <b-col> Release Date: <i>Ready</i></b-col>
        </b-row>
        <b-row v-else-if="vehicle.releaseDate" class="mb-2 mt-2">
          <b-col>
            Release Date:
            <i>
              {{ vehicle.releaseDate | formatDate }}
            </i>
          </b-col>
        </b-row>
        <div v-if="isShowFullInfo">
          <VehicleDetails :vehicle="vehicle" />
        </div>
        <b-row
          v-if="
            !isHome &&
            user &&
            (vehicle.cost ||
              (vehicle.vehicleCostDetail && vehicle.vehicleCostDetail.cost)) &&
            (userIsAdmin || userIsAgent)
          "
          class="mt-2 mb-2"
        >
          <b-col>
            Cost:
            <i v-if="vehicle.cost">{{ vehicle.cost | currency }}</i>
            <i v-else>{{ vehicle.vehicleCostDetail.cost | currency }}</i>
          </b-col>
        </b-row>
        <b-row
          v-if="
            vehicle.status &&
            vehicle.status !== 'Sold' &&
            !isHome &&
            (userIsAdmin || userIsAgent)
          "
        >
          <b-col class="mb-2">
            Status:
            <i>
              {{
                vehicle.status === "ReadyToSell"
                  ? "Ready To Sell"
                  : vehicle.status
              }}
            </i>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col :id="'tooltip-google-maps-' + vehicle.id">
            <img
              src="@/assets/img/mapaIcon.png"
              class="img-responsive"
              style="max-width: 20px"
            />
            Location
            <i>{{ vehicle.location }}</i>
            <b-tooltip
              :target="'tooltip-google-maps-' + vehicle.id"
              triggers="hover"
            >
              <h5 class="mt-2">New York Location</h5>
              <br />
              <h5>Address</h5>
              44951 County Rd 191, Wellesley Island, NY 13640, EE. UU.
              <br />
              <br />
              <a
                href="https://goo.gl/maps/uKtbs1u3K6fmPLZG7"
                target="_blank"
                class="mt-2"
                style="color: white; text-decoration: none"
              >
                View on Google Maps
              </a>
            </b-tooltip>
          </b-col>
        </b-row>
        <div
          v-if="
            (userIsAdmin || userIsAgent) &&
            vehicle.status === 'Sold' &&
            vehicle.vehicleSale
          "
        >
          <b-row class="mb-2">
            <b-col>
              Sale Data:
              <i>
                <b>{{ vehicle.vehicleSale.salePrice | currency }}</b> -
                <b>
                  {{ vehicle.vehicleSale.registerDate | formatDateTime }}
                </b>
              </i>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col>
              Buyer:
              <i>
                <b>
                  {{ vehicle.vehicleSale.user.firstName }}
                  {{ vehicle.vehicleSale.user.lastName }}
                </b>
              </i>
            </b-col>
          </b-row>
        </div>
        <div
          v-else-if="
            (userIsClient || userIsBuyer) &&
            vehicle.status === 'Sold' &&
            isPurchases
          "
        >
          <b-row class="mb-2">
            <b-col>
              Sale price:
              <i>
                <b>
                  {{ vehicle.finalPrice | currency }}
                </b>
              </i>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              Purchase Date:
              <i>
                <b>
                  {{ purchaseDate | formatDateTime }}
                </b>
              </i>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="showCRModal">
      <b-col>
        <b-button variant="success" block @click="openCRPageModal(vehicle.id)">
          <b-row>
            <b-col>
              <img
                src="@/assets/img/609_manheim_identity_ca_3000px.png.png"
                alt="Fluid image"
                class="img-fluid cr-button-img"
              />
            </b-col>
            <b-col class="cr-button-text"> CR and Full Specs </b-col>
          </b-row>
        </b-button>
      </b-col>
    </b-row>
    <b-row
      v-if="
        vehicle.vinNumber && !isHome && $route.name !== 'inventory.vehicles'
      "
      class="mt-2"
    >
      <b-col class="text-center">
        <vue-barcode
          v-if="vehicle.vinNumber.vinNumber"
          style="width: 100%"
          :value="vehicle.vinNumber.vinNumber"
          :options="barcodeOptions"
          class="bar-code"
        />
        <i style="font-size: 13px">
          {{ vehicle.vinNumber.vinNumber || vehicle.vinNumber }}
          <b-button
            v-clipboard:copy="
              vehicle.vinNumber.vinNumber
                ? vehicle.vinNumber.vinNumber
                : vehicle.vinNumber
            "
            v-clipboard:success="copyVinNumber"
            variant="none"
          >
            <b-icon v-if="vinCopied" icon="clipboard-check" />
            <b-icon v-else icon="clipboard" />
          </b-button>
        </i>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="
          !companyIsPending &&
          vehicle.vehicleCostDetail &&
          vehicle.vehicleCostDetail.price
        "
        class="mt-2 text-center"
      >
        <b-row>
          <b-col>
            <b>Selling Price:</b>
            <h1 class="selling-h1">
              <i class="selling-price">
                <strong>
                  {{ vehicle.vehicleCostDetail.price | currency }}
                </strong>
              </i>
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div v-if="vehicleAcceptOffer || vehicle.status === 'Sold'" class="mt-2">
      <img src="@/assets/img/TOOLATE.png" alt="sold-image" class="img-fluid" />
    </div>
    <div v-else>
      <b-row v-if="viewOffers" class="mt-3">
        <b-col class="mb-1">
          <b-button
            :disabled="companyIsPending"
            variant="yellow"
            block
            class="shadow"
            @click="showVehicleOffers()"
          >
            View Offers
          </b-button>
        </b-col>
      </b-row>

      <b-row v-else-if="!isHome && user" class="mt-2">
        <b-col>
          <b-button
            v-if="
              vehicle.status !== 'Inactive' &&
              (userIsAdmin || (userIsAgent && vehicle.usersId === user.id))
            "
            variant="yellow"
            block
            class="mt-1"
            @click="editVehicle(vehicle.vinNumber.vinNumber)"
          >
            <b-icon icon="pencil" />
          </b-button>
        </b-col>
      </b-row>
      <b-row
        v-if="
          !isHome &&
          !isActiveOffer &&
          !isExpiredOffer &&
          (userHasOffer || vehicle.lastOffer)
        "
        class="mt-3"
      >
        <b-col>
          <b-button
            variant="yellow"
            block
            size="sm"
            @click="changeOfferTab(vehicle.id)"
          >
            <h5 class="mt-1">
              Current Offer:
              <span v-if="vehicle.lastOffer">
                {{ vehicle.lastOffer.offerPrice | currency }}
              </span>
              <span v-else-if="userHasOffer">
                {{ currentOffer | currency }}
              </span>
            </h5>
          </b-button>
        </b-col>
      </b-row>
      <MakeAnOffer
        v-if="
          !isHome &&
          !userHasOffer &&
          !vehicle.lastOffer &&
          !isExpiredOffer &&
          !isActiveOffer &&
          (userIsBuyer || userIsClient)
        "
        :vehicle-id="this.vehicle.id"
        :price="this.vehicle.vehicleCostDetail.price"
        :user-pending="companyIsPending"
        @setUserHasOffer="setUserHasOffer($event)"
      />
    </div>
    <CRPageModal
      v-if="showCRModal"
      :id="this.vehicle.id"
      :cr-url="this.vehicle.crUrl"
    />
  </b-card>
</template>

<script>
import CarGallery from "./CarGallery";
import MakeAnOffer from "./components/MakeAnOffer";
import CRPageModal from "./components/CRPageModal";
import VehicleDetails from "./components/VehicleExtraDetails";
import { listVehiclesMixin } from "./mixins";
import { generalMixin } from "@/modules/mixin";
import { SET_VEHICLES_FILTERS, MARK_NOTIFICATION_AS_READ } from "./actions";
import {
  SET_VEHICLE_DETAILS_SIDEBAR,
  SET_IS_SHOWING_VEHICLE_DETAILS_SIDEBAR,
} from "./components/actions";
import {
  SHOW_OFFERS_SIDEBAR,
  SHOW_OFFER_SIDEBAR,
  SET_OFFER_SIDEBAR,
  GET_OFFERS_BY_VEHICLE,
} from "@/modules/admin/vehicles/offers/actions";
export default {
  name: "VehicleCard",
  components: {
    CarGallery,
    MakeAnOffer,
    CRPageModal,
    VehicleDetails,
  },
  mixins: [listVehiclesMixin, generalMixin],
  props: [
    "vehicle",
    "viewOffers",
    "isActiveOffer",
    "isExpiredOffer",
    "isPurchases",
    "isShowFullInfo",
    "purchaseDate",
    "showCRModal",
  ],
  data() {
    return {
      damages: [],
      interior: [],
      exterior: [],
      index: 0,
      damagesDots: [],
      imgError: "",
      imgNotFound: false,
      intervalPhotos: null,
      isGalleryGoingBack: false,
      tab: 0,
      barcodeOptions: {
        format: "CODE39",
        with: 10,
        height: 60,
        displayValue: false,
        marginTop: 10,
      },
      userHasOffer: false,
      currentOffer: null,
      vinCopied: null,
    };
  },
  computed: {
    userOffer() {
      return {};
    },
    lastOfferUser() {
      return this.vehicle.lastOfferUser;
    },
    lastOfferAdmin() {
      return this.vehicle.lastOfferAdmin;
    },
    isHome() {
      return this.$route.name === "home" ? true : false;
    },
    vehicleDetailsSideBar() {
      return this.$store.state.adminStore.vehicles.listStore.componentsStore
        .vehicleDetailsSideBar;
    },
    offerSideBar() {
      return this.$store.state.adminStore.vehicles.offersStore.offerSideBar;
    },
    vehicleAcceptOffer() {
      return this.$store.state.adminStore.vehicles.offersStore.vehiclesSold.vehicles.find(
        (vehicle) => vehicle.vehicleId === this.vehicle.id
      );
    },
  },
  mounted() {
    this.setImages();
  },
  destroyed() {
    this.$store.commit(SET_IS_SHOWING_VEHICLE_DETAILS_SIDEBAR, false);
  },
  methods: {
    selectImage(val) {
      this.imgError = "";
      this.imgNotFound = false;
      this.index = val.index;
      this.tab = val.tab;
      this.imgNotFound = val.imgNotFound;
    },
    changeTab(tab) {
      this.tab = tab;
      this.index = 0;
    },
    setDefaultPhotosState() {
      this.exterior = [];
      this.interior = [];
      this.damagePhotos = [];
      this.damages = [];
    },
    setImages() {
      this.setDefaultPhotosState();
      this.vehicle?.vehicleHasParts?.forEach((p) => {
        this.exterior.push(p.photo);
      });
      if (this.vehicle?.vehicleExternalImages?.length > 0) {
        this.vehicle?.vehicleExternalImages?.forEach((p) => {
          this.exterior.push(p.photo);
        });
      }
      if (this.vehicle?.damagePhotos?.length > 0) {
        this.damagesDots = this.vehicle.damagePhotos;
      }
      this.vehicle?.damagePhotos?.forEach((p) => {
        this.damages.push(p.damagePhoto);
      });
    },
    nextImg() {
      this.index++;
      this.imgNotFound = false;
      this.imgError = "";
    },
    backImg() {
      this.index--;
      this.imgNotFound = false;
      this.imgError = "";
    },
    errorLoadImg() {
      this.imgError = require("@/assets/img/image_not_found.png");
    },
    initIntervalPhotos() {
      this.intervalPhotos = setInterval(this.autoChangePhotos, 4000);
    },
    autoChangePhotos() {
      if (this.isGalleryGoingBack) {
        if (this.index === 0) {
          this.nextImg();
          this.isGalleryGoingBack = false;
        } else {
          this.backImg();
        }
      } else {
        if (this.index === this.exterior.length - 1) {
          this.backImg();
          this.isGalleryGoingBack = true;
        } else {
          this.nextImg();
        }
      }
    },
    openCRPageModal(id) {
      this.$bvModal.show("CR-Page-Modal" + id);
    },
    editVehicle(vin) {
      this.$router.push({
        name: this.userIsAdmin
          ? "admin.vehicles.registry.general"
          : "agent.vehicles.edit.general",
        params: { vin: vin },
      });
    },
    showVehicleOffers() {
      this.$store.commit(SHOW_OFFER_SIDEBAR, false);
      this.$store.commit(SHOW_OFFERS_SIDEBAR, false);
      this.getVehicleOffers();
      setTimeout(() => {
        this.$store.commit(SHOW_OFFER_SIDEBAR, true);
      }, 1000);
    },
    getVehicleOffers() {
      this.$store
        .dispatch(GET_OFFERS_BY_VEHICLE, this.vehicle.vehicleId)
        .then(() => {
          // SET VEHICLE DATA TO SHOW IN SIDEBAR
          this.vehicle.isActiveOffer = this.isActiveOffer;
          this.$store.commit(SET_OFFER_SIDEBAR, this.vehicle);

          this.markNoticationAsRead();
        })

        .catch(() => {
          this.toastMessage(
            "The vehicle offers could not be obtained",
            "Warning",
            "warning"
          );
        });
    },
    changeOfferTab(vehicleId) {
      this.$parent.$emit("resetFilters");
      this.$store.commit(SET_VEHICLES_FILTERS, { fields: { vehicleId } });
      this.$parent.$emit("changeTabNumber");
    },

    openVehicleDetails() {
      let sideBar = this.vehicleDetailsSideBar;
      if (!sideBar.data) {
        this.$store.commit(SET_VEHICLE_DETAILS_SIDEBAR, this.vehicle);
        this.$store.commit(SET_IS_SHOWING_VEHICLE_DETAILS_SIDEBAR, true);
      } else {
        if (sideBar.data.id !== this.vehicle.id) {
          this.$store.commit(SET_VEHICLE_DETAILS_SIDEBAR, this.vehicle);
          this.$store.commit(SET_IS_SHOWING_VEHICLE_DETAILS_SIDEBAR, false);
          setTimeout(() => {
            this.$store.commit(SET_IS_SHOWING_VEHICLE_DETAILS_SIDEBAR, true);
          }, 400);
        } else if (!sideBar.isShowing) {
          this.$store.commit(SET_IS_SHOWING_VEHICLE_DETAILS_SIDEBAR, true);
        }
      }
    },
    setUserHasOffer(offer) {
      this.userHasOffer = true;
      this.currentOffer = offer;
    },
    openGoogleMaps(location) {
      let url =
        location.toLowerCase() === "new york"
          ? "https://goo.gl/maps/uKtbs1u3K6fmPLZG7"
          : "https://goo.gl/maps/uKtbs1u3K6fmPLZG7"; // Change for Michigan location
      window.open(url, "_blank");
    },
    markNoticationAsRead() {
      const payload = {
        id: this.vehicle.vehicleId,
      };
      this.$store.commit(MARK_NOTIFICATION_AS_READ, {});

      this.$store.dispatch(MARK_NOTIFICATION_AS_READ, payload).then(() => {});
    },
    copyVinNumber(vin) {
      this.vinCopied = vin;
    },
    goToVehicleDetails() {
      if (this.viewOffers) {
        const router = this.$router.resolve({
          name: "vehicle",
          params: { vin: this.vehicle.vinNumber },
        });
        window.open(router.href, "_blank");
      }
    },
  },
};
</script>

<style scoped>
.back-gray {
  background-color: #f0f0f0 !important;
}
.nav-link {
  display: block;
  padding: 0.5rem 0.5rem !important;
}
.main-img {
  width: 100%;
  height: 25rem;
  object-fit: cover;
}
.car-fax-logo {
  width: 70%;
}
.transmissionType-text {
  font-size: 15px;
}
.admin-offer {
  color: #44718e;
}
.buyer-offer {
  color: #009688;
}
.cr-button-text {
  padding-top: 4%;
}
.star-icon {
  position: absolute;
  max-width: 50px;
  margin-top: -20px;
  margin-left: -3px;
}
.stockNumber {
  color: #c23a1d;
}
@media (min-width: 600px) and (max-width: 1580px) {
  .cr-button-img {
    padding-top: 5%;
  }
  .cr-button-text {
    padding-top: 0%;
  }
}
@media (max-width: 300px) {
  .main-img {
    height: 13rem !important;
  }
  div,
  i {
    font-size: 15px;
    margin-bottom: -1px;
  }
  .stockNumber-mt {
    margin-top: -15px;
  }
  .buyer-offer {
    font-size: 25px;
  }
  .highest-offer {
    font-size: 25px;
  }
  .cr-button-img {
    width: 80%;
  }
  .cr-button-text {
    display: none;
  }
  .selling-h1 {
    margin: -10px 0 -5px 0;
  }
  .selling-price {
    position: relative;
    font-size: 22px;
  }
}
</style>
