<template>
  <div>
    <div class="container-fluid">
      <div>
        <TableBase
          :items="addonFileList.data"
          :fields="fields"
          :options="options"
          :busy="addonFileList.status == 'fetching' ? true : false"
        >
        </TableBase>
      </div>

      <b-modal
        id="detailsModal"
        ref="detailsModal"
        size="lg"
        hide-footer
        body-class=""
        ok-disabled
        @hidden="addonTypeItem = {}"
      >
        <AddonsFileForm
          :addonTypeItem="addonTypeItem"
          :refreshData="getData"
          :hideModal="hideModal"
        />
      </b-modal>
    </div>
  </div>
</template>
<script>
import TableBase from "@/modules/shared/table.vue";
import AddonsFileForm from "./AddonsFileForm.vue";
import { GET_ADDONS_FILE_READ_ERROR, POST_ADDON_FILE } from "./actions";
import { mapState } from "vuex";

import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../mixins";
export default {
  name: "AddonsFileTableById",
  components: { TableBase, AddonsFileForm },
  mixins: [generalMixin, superAdminMixin],
  computed: {
    ...mapState({
      addonFileList: (state) => state.superAdminStore.addonsFile.addonFileList,
    }),
    hasPermission() {
      return this.userHasPermission("addons", "write");
    },
    fields() {
      return [
        { key: "addon", label: "Addon" },

        {
          key: "createdAt",

          label: "Create at",
          class: "text-center",
          formatter: (date) => this.$options.filters.formatDate(date),
        },

        /*     {
          key: "actions",
          label: "Actions",
          note: false,
          details: true,
          validation: false,
          delete: this.hasPermission,
        }, */
      ];
    },
  },
  data() {
    return {
      options: {
        assign: false,
        rolFilter: false,
        add: false,
        fun: this.getData,
      },
      addonTypeItem: {},
      type: "success",
    };
  },
  mounted() {
    this.$store.commit(
      "setSearchFunction",
      this.userHasPermission("addons", "write") ? this.getData : null
    );
    this.$store.commit(
      "setAddFunction",
      this.userHasPermission("addons", "write") ? this.showModal : null
    );

    this.getData();
  },
  methods: {
    setType(type) {
      this.type = type;

      this.getData();
    },
    getData(filter = "") {
      this.$store
        .dispatch(GET_ADDONS_FILE_READ_ERROR, {
          search: filter,
          id: this.$route.params.id,
        })
        .then(() => {})
        .catch(() => {});
    },
    async deleteItem(item) {
      const confirm = await this.showConfirmDeleteBox();
      if (!confirm) return;
      const payload = { ...item };
      payload.status = 0;
      this.$store
        .dispatch(POST_ADDON_FILE, payload)
        .then(() => {
          this.getData();
          this.makeToast(
            "Success",
            "Addon type updated successfully",
            "success"
          );
        })
        .catch(() => {
          this.$root.$bvToast.toast("Addon type could not be disabled", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    activateItem(item) {
      const payload = {
        id: item.id,
        status: 1,
      };
      this.$store
        .dispatch(POST_ADDON_FILE, payload)
        .then(() => {
          this.makeToast(
            "Success",
            "Addon type updated successfully",
            "success"
          );
          this.getData();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Addon type could not be activated", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    showModal() {
      this.$bvModal.show("detailsModal");
    },
    editItem(item) {
      this.addonTypeItem = item;
      this.$bvModal.show("detailsModal");
    },
    changePage(page, filter = "") {
      this.$store
        .dispatch(GET_ADDONS_FILE_READ_ERROR, {
          id: this.$route.params.id,
          page: page,
          search: filter,
        })
        .then(() => {})
        .catch(() => {});
    },

    hideModal() {
      this.$bvModal.hide("detailsModal");
    },
  },
};
</script>
