import * as constants from "./actions";
import axios from "@/plugins/axios.js";
import notes from "./notes/store";
function getDefaultState() {
  return {
    liens: {
      status: null,
      data: [],
      error: null,
    },
    lien: {
      status: null,
      data: {},
      error: null,
    },
  };
}

const actions = {
  [constants.GET_LIENS]: async ({ commit }, id) => {
    try {
      commit(constants.GET_LIENS);
      const response = await axios.get(`lien/getList?vehicle=${id}`);
      console.log(response);
      commit(constants.GET_LIENS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_LIENS_ERROR, error);
      throw error.response;
    }
  },

  [constants.POST_LIEN]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_LIEN);
      const response = await axios.post("lien/create", payload);
      commit(constants.POST_LIEN_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_LIEN_ERROR, error);
      throw error.response;
    }
  },

  [constants.PUT_LIEN]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_LIEN);
      const response = await axios.post("lien/create", payload);
      commit(constants.PUT_LIEN_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_LIEN_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_LIENS]: (state) => {
    state.liens.status = "fetching";
    state.liens.error = null;
  },
  [constants.GET_LIENS_SUCCESS]: (state, data) => {
    state.liens.status = "success";
    state.liens.error = null;
    state.liens.data = data;
  },
  [constants.GET_LIENS_ERROR]: (state, error) => {
    state.liens.status = "error";
    state.liens.error = error;
  },
  [constants.POST_LIEN]: (state) => {
    state.lien.status = "fetching";
    state.lien.error = null;
  },
  [constants.POST_LIEN_SUCCESS]: (state, data) => {
    state.lien.status = "success";
    state.lien.error = null;
    state.lien.data = data;
  },
  [constants.POST_LIEN_ERROR]: (state, error) => {
    state.lien.status = "error";
    state.lien.error = error;
  },

  [constants.PUT_LIEN]: (state) => {
    state.lien.status = "fetching";
    state.lien.error = null;
  },
  [constants.PUT_LIEN_SUCCESS]: (state, data) => {
    state.lien.status = "success";
    state.lien.error = null;
    state.lien.data = data;
  },
  [constants.PUT_LIEN_ERROR]: (state, error) => {
    state.lien.status = "error";
    state.lien.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
  modules: {
    notes,
  },
};
