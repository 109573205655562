<template>
  <div class="container">
    <b-form @submit.stop.prevent="saveForeignForm()">
      <h3>Foreign shippers declaration</h3>
      <p class="text-justify">
        I.
        <label for="">
          <vue-single-select
            :classes="{ input: 'form-control', dropdown: 'dropdown' }"
            v-model="form.company"
            :options="companies"
            optionLabel="companyName"
            label="Select company"
          ></vue-single-select>
        </label>
        , declare that to the best of my knowledge and belief the articles
        herein specified were exported from the United States, from the port of
        See Below on or about See Below,
        <u>
          <strong>{{ year }}</strong></u
        >
        and that they are returned without having been advanced in value or
        improved in condition by any process of manufacture or other means.
      </p>
      <!--    <b-row class="mt-2">
        <b-col class="m-2">
          <b-form-group label="Approx date of export:">
            <b-form-input
              v-model="form.dateExport"
              class="text-center"
              type="date"
            />
          </b-form-group>
        </b-col>
        <b-col class="m-2"> </b-col>
      </b-row> -->
      <div v-if="exportLoadState.exportLoad">
        <VehiclesDeclaration
          :vehicles="exportLoadState.exportLoad.vehicles || []"
          :dateExport="form.dateExport"
        />
      </div>
      <div v-if="form.company" class="my-2">
        <u> <img src="@/assets/img/signature.jpg" width="15%" alt="" /></u>
        <br />
        <small>(Signature)</small>
        <br />
        <u>
          <strong>
            {{ form.company.companyName }}
          </strong>
        </u>
        <br />
        <small>(Corporation of firm name)</small>
        <br />
        <br />
        <u>
          <strong>
            {{ form.company.street }}, {{ form.company.city }},
            {{ form.company.state }}, {{ form.company.zip }}
          </strong>
        </u>
        <br />
        <small>(Full address)</small>
      </div>
      <b-button
        class="text-white"
        size="lg"
        variant="warning"
        @click="goBack()"
      >
        <b-icon icon="chevron-compact-left" scale="1" />
        Prev
      </b-button>
      <b-button
        :disabled="!form.company"
        type="submit"
        variant="success"
        size="lg"
        class="col-md-3 col-sm-12 offset-7"
      >
        Submit
      </b-button>
    </b-form>
  </div>
</template>
<script>
import { mapState } from "vuex";
import VueSingleSelect from "vue-single-select";
import {
  GET_EXPORT_LOAD_INFO_BY_ID,
  POST_FOREIGN_FORM,
  GET_COMPANIES_FOREIGN,
} from "./actions";
import VehiclesDeclaration from "./VehiclesDeclaration.vue";
export default {
  props: ["exportLoad", "refreshData", "hideModal"],
  components: {
    VueSingleSelect,

    VehiclesDeclaration,
  },
  computed: {
    ...mapState({
      exportLoadState: (state) =>
        state.superAdminStore.exportLoad.exportLoad.data,
    }),
  },
  data() {
    return {
      companies: [],
      form: {
        company: null,
        dateExport: null,
      },
      year: new Date().getFullYear(),
    };
  },

  mounted() {
    this.getCompanies();
    if (this.exportLoad.id) {
      this.getExportLoadInfo();
    }
  },
  methods: {
    submit() {},
    goBack() {
      this.$emit("back");
    },
    getCompanies() {
      this.$store.dispatch(GET_COMPANIES_FOREIGN).then((response) => {
        this.companies = response;
      });
    },
    getExportLoadInfo() {
      this.$store
        .dispatch(GET_EXPORT_LOAD_INFO_BY_ID, { id: this.exportLoad.id })
        .then(() => {});
    },
    saveForeignForm() {
      if (this.exportLoad.status == 2 || this.exportLoad.status == 3) {
        return;
      }
      const payload = this.formatPayload();
      this.$store.dispatch(POST_FOREIGN_FORM, payload).then(() => {
        this.$root.$bvToast.toast("Information save successfully", {
          title: "Success",
          variant: "success",
        });
        this.refreshData();
        this.hideModal();
      });
    },
    formatPayload() {
      const payload = {
        id: this.exportLoad.id,
        companyId: this.form.company.id,
      };
      return payload;
    },
  },
};
</script>
<style>
</style>