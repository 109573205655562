import store from "@/store";
const listVehiclesMixin = {
  data() {
    return {
      client: null,
      agent: null,
      agentId: null,
      location: null,
      search: null,
      make: null,
      model: null,
      trim: null,
      releaseDate1: null,
      releaseDate2: null,
      year1: null,
      year2: null,
      yearRange: [0, 0],
      lastLocation: null,
      finalDestination: null,
      /*     mile1: null,
      mile2: null, */
      transmissionType: null,
      /*       price1: null,
      price2: null, */
      /*       mileageRange: [0, 200000],
      maxMileage: 200000,
      priceRange: [0, 200000],
      maxPrice: 200000, */
      new: null,
      status: null,
      soldTo: null,
      boughtTo: null,
      transferDestinations: null,
      transferOrLocation: null
    };
  },
  computed: {
    filterAction() {
      return store.state.adminStore.vehicles.listStore.filterAction.action;
    },
    allVehicles() {
      return store.state.adminStore.vehicles.listStore.vehicles.data;
    },
    publicVehicles() {
      return store.state.home.vehicles.data;
    },
  },
  methods: {
    getPublicVehicles() {
      this.$store.dispatch("GET_PUBLIC_VEHICLES", true);
    },
    clearElementsInSearch() {
      this.client = null;
      this.agent = null;
      this.agentId = null;
      this.location = null;
      this.search = null;
      this.make = null;
      this.model = null;
      this.trim = null;
      this.releaseDate1 = null;
      this.releaseDate2 = null;
      this.year1 = null;
      this.year2 = null;
      this.yearRange = [this.minYear, this.maxYear];
      /*       this.mile1 = null;
      this.mile2 = null; */
      this.transmissionType = null;
      /*      this.price1 = null;
      this.price2 = null; */
      /*    this.mileageRange = [0, 200000];
      this.maxMileage = 200000;
      this.priceRange = [0, 200000];
      this.maxPrice = 200000; */
      this.new = null;
      this.status = null;
      this.lastLocation = null;
      this.finalDestination = null;
      this.soldTo = null;
      this.boughtTo = null;
      this.transferDestinations = null;
      this.transferOrLocation = null;
    },
    resetFilters() {
      this.clearElementsInSearch();
      this.$store.commit("SET_VEHICLES_INFO_FILTERS", { reset: true });
    },
    searchVehiclesByTabMenu() {
      switch (this.filterAction) {
        case "GET_VEHICLES":
          this.getVehicles();
          break;
        case "PUBLIC_GET_VEHICLES":
          this.getPublicVehicles();
          break;
      }
    },
    getVehicles() {
      this.$store.dispatch("GET_VEHICLES_INFO").then().catch();
    },
  },
};

export { listVehiclesMixin };
