<template>
  <b-form class="" @submit.stop.prevent="save()">
    <b-row>
      <b-col cols="12">
        <div class="mb-2">
          <b-button class="my-auto" type="button" variant="success" pill
            >1</b-button
          >
        </div>
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <b-form-group>
          <template slot="label"> Vehicle </template>
          <multiselect
            v-model="form.vehicle"
            :options="vehicles"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="false"
            placeholder="Pick some option"
            label="title"
            track-by="title"
            :preselect-first="false"
          >
            <template slot="tag" slot-scope="{ option, remove }"
              ><span class="custom__tag"
                ><span class="">{{ option.stockNumber }}</span
                ><span class="ml-1 custom__remove" @click="remove(option)"
                  >❌</span
                ></span
              ></template
            >
          </multiselect>
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group>
          <template slot="label"> Agent </template>
          <multiselect
            v-model="form.agent"
            :options="agents"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="false"
            placeholder="Pick some option"
            :custom-label="agentFullName"
            track-by="title"
            :preselect-first="false"
          >
            <template slot="tag" slot-scope="{ option, remove }"
              ><span class="custom__tag"
                ><span class=""
                  >{{ option.firstName }} {{ option.lastName }}</span
                ><span class="ml-1 custom__remove" @click="remove(option)"
                  >❌</span
                ></span
              ></template
            >
          </multiselect>
        </b-form-group>
      </b-col>
      <b-col cols="12" v-if="form.vehicle">
        <VehicleInformation :vehicle="form.vehicle" />
      </b-col>
      <b-col cols="12">
        <div class="mb-2">
          <b-button class="my-auto" type="button" variant="success" pill
            >2</b-button
          >
        </div>
      </b-col>
      <b-col sm="12" md="6" lg="6" xl="6">
        <b-form-group label="Price">
          <Money
            v-model="form.amount"
            class="form-control text-center"
            v-bind="money"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="9" lg="9" xl="9" />

      <b-col sm="12" md="3" lg="3" xl="3">
        <b-btn
          :disabled="
            transferBetweenAgent.status == 'fetching' ||
            form.amount == null ||
            form.agent == null ||
            form.vehicle == null
          "
          block
          size="lg"
          class="btn btn-success"
          type="submit"
        >
          <b-spinner
            v-if="transferBetweenAgent.status == 'fetching'"
            small
            variant="white"
          />
          <span v-else>Submit</span>
        </b-btn>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import Multiselect from "vue-multiselect";
import {
  GET_TRANSFER_BETWEEN_AGENT_VEHICLES,
  POST_TRANSFER_BETWEEN_AGENT,
} from "./actions";
import { mapState } from "vuex";
import { Money } from "v-money";
import VehicleInformation from "./VehicleInformation.vue";
export default {
  name: "TransferBetweenAgentForm",
  components: {
    Multiselect,
    Money,
    VehicleInformation,
  },
  props: {
    refresh: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapState({
      vehicles: (state) =>
        state.superAdminStore.transferBetweenAgent.vehicles.data,
      agents: (state) => state.superAdminStore.transferBetweenAgent.agents.data,
      transferBetweenAgent: (state) =>
        state.superAdminStore.transferBetweenAgent.transferBetweenAgent,
    }),
  },
  data() {
    return {
      form: {
        vehicle: null,
        agent: null,
        amount: 0,
      },
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "CAD$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
    };
  },

  mounted() {
    this.getVehiclesAndAgents();
  },
  methods: {
    agentFullName(option) {
      return `${option.firstName} ${option.lastName}`;
    },
    getVehiclesAndAgents() {
      this.$store.dispatch(GET_TRANSFER_BETWEEN_AGENT_VEHICLES);
    },
    save() {
      const payload = {
        vehicleId: this.form.vehicle?.id,
        agentId: this.form.agent?.id,
        amount: this.form.amount,
      };
      this.$store.dispatch(POST_TRANSFER_BETWEEN_AGENT, payload).then(() => {
        this.$bvToast.toast(`Transfer between agent created`, {
          title: "Success",
          variant: "success",
          solid: true,
        });
        /* close modal */
        /* this.$root.$emit(
          "bv::hide::modal",
          "transfer-between-agent-form-modal"
        ); */
        this.refresh();
      });
    },
    reset() {
      this.form = {
        vehicle: null,
        agent: null,
        amount: 0,
      };
    },
  },
};
</script>

<style>
</style>