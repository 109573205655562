import CompanyCarsService from "@/services/companyCarsService";
import { generalMixin } from "@/modules/mixin.js";
import { mapState } from "vuex";
const companyCarsMixin = {
  mixins: [generalMixin],
  data() {
    return {
      carId: null,
      reservedPrice: null,
      registryCar: {
        id: null,
        bodySubtype: null,
        bodyType: null,
        brakeSystem: null,
        doors: null,
        driveType: null,
        make: null,
        model: null,
        oemBodyStyle: null,
        packageCode: null,
        packageSummary: null,
        rearAxle: null,
        restraintType: null,
        trim: null,
        vehicleType: null,
        vinNumber: null,
        year: null,
        dateMFG: null,
        odometerMI: null,
        vehicleFlags: {}
      },
      vehicleInformation: {
        kms: null,
        color: null,
        transmission: "Manual",
        summerTires: false,
        winterTires: false,
        tiresType: null,
        mags: null,
        magsRate: null,
        airConditioning: null,
        alarm: null,
        alloyWheels: null,
        bluetooth: null,
        dualClimateControl: null,
        entertainmentPackage: null,
        fogLights: null,
        heatedMirrors: null,
        heatedSeats: null,
        keylessEntry: null,
        leatherSeats: null,
        memorySeats: null,
        navigationSystem: null,
        powerLocks: null,
        powerMirrors: null,
        powerSeats: null,
        powerWindows: null,
        stabilityControl: null,
        sunroof: null,
        towPackage: null,
        xenonHeadlights: null,
      },
      vinDataInvalid: false,
    };
  },
  computed: {
    ...mapState({
      vinData: (state) => state.vehicle.visualInspection.vinData,
      vehicleInformationDatail: (state) =>
        state.vehicle.visualInspection.vehicleInformationDatail,
      vehicleExterior: (state) =>
        state.vehicle.visualInspection.vehicleExterior,
      vehicleInteriors: (state) =>
        state.vehicle.visualInspection.vehicleInteriors,
      vehicleCostDetail: (state) =>
        state.vehicle.visualInspection.vehicleCostDetail,
    }),
  },
  methods: {
    checkIfSomePropertyIsNotNull(ob) {
      if (!ob) return false;
      return Object.values(ob)?.some((attr) => attr);
    },
    checkVinData() {
      if (!this.vinData) {
        this.$router.push({
          name: "company.cars.create",
        });
      }
    },
    formatPayload() {
      const payload = {};

      if (this.checkIfSomePropertyIsNotNull(this.vinData)) {
        payload.vinData = this.vinData;
      }

      if (this.checkIfSomePropertyIsNotNull(this.vehicleInformationDatail)) {
        payload.vehicleInformation = this.vehicleInformationDatail;
      }

      if (this.checkIfSomePropertyIsNotNull(this.vehicleExterior)) {
        payload.vehicleExterior = this.vehicleExterior;
      }

      if (this.checkIfSomePropertyIsNotNull(this.vehicleInteriors)) {
        payload.vehicleInterior = this.vehicleInteriors;
      }

      if (this.checkIfSomePropertyIsNotNull(this.vehicleCostDetail)) {
        payload.vehicleCostDetail = this.vehicleCostDetail;
      }

      return payload;
    },
    async getVin(vinNumber, callback = null, redirect = true, OnError) {
      try {
        this.$store.commit("general/setEndPointUrl", "vehicle");
        const { data, markets } = await CompanyCarsService.getVinByNumber(
          vinNumber
        );
        if (!data && !markets) {
          if (redirect) {
            this.$router.push({
              name: "company.cars.create",
            });
          }
        }
        if (callback) {
          callback(data, markets);
        }
      } catch (error) {
        console.log(error);
        OnError(error);
      }
    },
    async updateCar(callback = null, errorCallback = null) {
      let data = null;
      try {
        if (this.carId) {
          this.$store.commit("general/setEndPointUrl", "vehicle/update");
          data = await CompanyCarsService.putCar(
            this.carId,
            this.formatPayload()
          );
        } else {
          data = await CompanyCarsService.postCar(this.formatPayload());
        }

        if (callback) callback(data);
      } catch (error) {
        errorCallback(error);
      }
    },
    seeFullImage(img, text) {
      this.$store.commit("companyCars/setImageToPreview", { text, img });
      this.showModal("companyCarsPreview");
    },
    showConfirmDeleteBox() {
      return this.$bvModal.msgBoxConfirm(
        "Please confirm that you want to delete the item.",
        {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        }
      );
    },
  },
};

export { companyCarsMixin };
