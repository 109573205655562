<template>
  <!-- Card stats -->
  <b-row>
    <b-col xl="3" md="6">
      <stats-card class="mb-4">
        <template slot>
          <div class="d-flex justify-content-between">
            <h5 class="card-title text-uppercase text-muted mb-0">
              No actions
            </h5>
            <div class="text-center">
              <i
                v-b-tooltip.click="{ variant: 'white' }"
                title="Warning within 1-3 days, Alert more than 3 days"
              >
                <font-awesome-icon
                  class="cursor-pointer text-blue"
                  :icon="['far', 'circle-question']"
                />
              </i>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <b-row>
              <b-col
                cols="auto"
                @click="applyFilter(noActionsWarning)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-yellow"
                  :icon="['far', 'triangle-exclamation']"
                  size="2xl"
                />

                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">
                  {{ yard != null ? yard.noActionsWarning : 0 }}
                </span>
              </b-col>
              <b-col
                cols="auto"
                @click="applyFilter(noActionsAlert)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-red"
                  :icon="['far', 'octagon-exclamation']"
                  size="2xl"
                />
                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">{{
                  yard != null ? yard.noActionsAlert : 0
                }}</span>
              </b-col>
            </b-row>
          </div>
        </template>
      </stats-card>
    </b-col>
    <b-col xl="3" md="6">
      <stats-card class="mb-4">
        <template slot>
          <div class="d-flex justify-content-between">
            <h5 class="card-title text-uppercase text-muted mb-0">W. O.</h5>
            <div class="text-center">
              <i
                v-b-tooltip.click="{ variant: 'white' }"
                title="Warning within 1-4 days, Alert more than 4 days"
              >
                <font-awesome-icon
                  class="cursor-pointer text-blue"
                  :icon="['far', 'circle-question']"
                />
              </i>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <b-row>
              <b-col
                cols="auto"
                @click="applyFilter(workOrdersWarning)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-yellow"
                  :icon="['far', 'triangle-exclamation']"
                  size="2xl"
                />

                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">
                  {{ yard != null ? yard.woWarning : 0 }}</span
                >
              </b-col>
              <b-col
                cols="auto"
                @click="applyFilter(workOrdersAlert)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-red"
                  :icon="['far', 'octagon-exclamation']"
                  size="2xl"
                />
                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">
                  {{ yard != null ? yard.woAlert : 0 }}</span
                >
              </b-col>
            </b-row>
          </div>
        </template>
      </stats-card>
    </b-col>
    <b-col xl="3" md="6">
      <stats-card class="mb-4">
        <template slot>
          <div class="d-flex justify-content-between">
            <h5 class="card-title text-uppercase text-muted mb-0">Carwash</h5>
            <div class="text-center">
              <i
                v-b-tooltip.click="{ variant: 'white' }"
                title="Warning within 1-4 days, Alert more than 4 days"
              >
                <font-awesome-icon
                  class="cursor-pointer text-blue"
                  :icon="['far', 'circle-question']"
                />
              </i>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <b-row>
              <b-col
                cols="auto"
                @click="applyFilter(carwashWarning)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-yellow"
                  :icon="['far', 'triangle-exclamation']"
                  size="2xl"
                />

                <span class="h1 font-weight-bold mb-0 align-middle">
                  {{ yard != null ? yard.carwashWarning : 0 }}</span
                >
              </b-col>

              <b-col
                cols="auto"
                @click="applyFilter(carwashAlert)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-red"
                  :icon="['far', 'octagon-exclamation']"
                  size="2xl"
                />
                <span class="h1 font-weight-bold mb-0 align-middle">
                  {{ yard != null ? yard.carwashAlert : 0 }}</span
                >
              </b-col>
            </b-row>
          </div>
        </template>
        <template slot="footer">
          <span class="text-nowrap"></span>
        </template>
      </stats-card>
    </b-col>
    <b-col xl="3" md="6">
      <stats-card class="mb-4">
        <template slot>
          <div class="d-flex justify-content-between">
            <h5 class="card-title text-uppercase text-muted mb-0">Cluster</h5>
            <div class="text-center">
              <i
                v-b-tooltip.click="{ variant: 'white' }"
                title="Warning within 1-4 days, Alert more than 4 days"
              >
                <font-awesome-icon
                  class="cursor-pointer text-blue"
                  :icon="['far', 'circle-question']"
                />
              </i>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <b-row>
              <b-col
                cols="auto"
                @click="applyFilter(clusterWarning)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-yellow"
                  :icon="['far', 'triangle-exclamation']"
                  size="2xl"
                />

                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">
                  {{ yard != null ? yard.clusterWarning : 0 }}
                </span>
              </b-col>
              <b-col
                cols="auto"
                @click="applyFilter(clusterAlert)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-red"
                  :icon="['far', 'octagon-exclamation']"
                  size="2xl"
                />
                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">{{
                  yard != null ? yard.clusterAlert : 0
                }}</span>
              </b-col>
            </b-row>
          </div>
        </template>
        <template slot="footer">
          <span class="text-nowrap"></span>
        </template>
      </stats-card>
    </b-col>
    <b-col xl="3" md="6">
      <stats-card class="mb-4">
        <template slot>
          <div class="d-flex justify-content-between">
            <h5 class="card-title text-uppercase text-muted mb-0">V. I.</h5>
            <div class="text-center">
              <i
                v-b-tooltip.click="{ variant: 'white' }"
                title="Warning within 0-3 days, Alert more than 3 days"
              >
                <font-awesome-icon
                  class="cursor-pointer text-blue"
                  :icon="['far', 'circle-question']"
                />
              </i>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <b-row>
              <b-col
                cols="auto"
                @click="applyFilter(visualInspectionWarning)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-yellow"
                  :icon="['far', 'triangle-exclamation']"
                  size="2xl"
                />

                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">
                  {{ yard != null ? yard.viWarning : 0 }}
                </span>
              </b-col>
              <b-col
                cols="auto"
                @click="applyFilter(visualInspectionAlert)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-red"
                  :icon="['far', 'octagon-exclamation']"
                  size="2xl"
                />
                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">{{
                  yard != null ? yard.viAlert : 0
                }}</span>
              </b-col>
            </b-row>
          </div>
        </template>
        <template slot="footer">
          <span class="text-nowrap"></span>
        </template>
      </stats-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from "vuex";
import { SET_VEHICLES_INFO_FILTERS } from "@/modules/superAdmin/actions";
import { superAdminMixin } from "../../modules/superAdmin/mixins";
import { GET_BOARD_YARD } from "./actions";
export default {
  name: "BoardCardsYard",
  mixins: [superAdminMixin],
  computed: {
    ...mapState({
      yard: (state) => state.layout.boards.yard?.data,
    }),
  },
  data() {
    return {
      noActionsWarning: {
        /*  carfax: 0,
        workOrders: 0,
        carwash: 0,
        cluster: 0, */
        noActionsWarning: 1,
      },
      noActionsAlert: {
        /*  carfax: 0,
        workOrders: 0,
        carwash: 0,
        cluster: 0, */
        noActionsAlert: 1,
      },
      workOrdersWarning: {
        workOrders: "1,2,3",
        woFrom: this.getDateXDaysAgo(4),
        woTo: this.getDateXDaysAgo(1),
      },
      workOrdersAlert: {
        workOrders: "1,2,3",
        woTo: this.getDateXDaysAgo(4),
      },
      carwashWarning: {
        carwash: 1,
        carwashFrom: this.getDateXDaysAgo(4),
        carwashTo: this.getDateXDaysAgo(1),
      },
      carwashAlert: {
        carwash: 1,
        carwashTo: this.getDateXDaysAgo(4),
      },
      clusterWarning: {
        cluster: 1,
        clusterFrom: this.getDateXDaysAgo(4),
        clusterTo: this.getDateXDaysAgo(1),
      },

      clusterAlert: {
        cluster: 1,
        clusterTo: this.getDateXDaysAgo(4),
      },
      visualInspectionWarning: {
        visualInspection: 0,
        viWarning: 1,
      },
      visualInspectionAlert: {
        visualInspection: 0,
        viAlert: 1,
      },
    };
  },
  mounted() {
    this.getLogisticInformation();
  },
  methods: {
    applyFilter(filters) {
      this.resetFilters(); //Method from superAdminMixin
      this.$store.commit(SET_VEHICLES_INFO_FILTERS, {
        fields: { ...filters },
      });
      this.getVehiclesInfo(); //Method from superAdminMixin
    },
    getDateXDaysAgo(numOfDays, date = new Date()) {
      const daysAgo = new Date(date);
      daysAgo.setDate(date.getDate() - numOfDays);
      return this.formatDateString(daysAgo.toString());
    },
    getLogisticInformation() {
      this.$store.dispatch(GET_BOARD_YARD).then(() => {});
    },
    formatDateString(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const shortDate = `${year}-${month}-${day}`;
      return shortDate;
    },
  },
};
</script>

<style>
</style>
