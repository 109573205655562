import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    titles: {
      status: null,
      data: [],
      error: null,
    },
    title: {
      status: null,
      data: {},
      error: null,
    },
    titleVehicles: {
      status: null,
      data: [],
      error: null,
    },
  };
}
const actions = {
  [constants.POST_TITLE_FILES]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_TITLE_FILES);
      const response = await axios.post("titleManager/uploadFile", payload
      );
      commit(constants.POST_TITLE_FILES_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_TITLE_FILES_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_TITLE_FILES]: async ({ commit }, data) => {
    try {
      commit(constants.GET_TITLE_FILES);
      const response = await axios.get("titleManager/getFiles",
        {
          params: data,
        });
      commit(constants.GET_TITLE_FILES_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_TITLE_FILES_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_TITLE_FILES_VEHICLES]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_TITLE_FILES_VEHICLES);
      const response = await axios.get("titleManager/getVehiclesFiles", {
        params: payload,
      });
      commit(constants.GET_TITLE_FILES_VEHICLES_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_TITLE_FILES_VEHICLES_ERROR, error);
      throw error.response;
    }
  },
  [constants.UPDATE_EXPORTED_TITLE]: async ({ commit }, payload) => {
    try {
      commit(constants.UPDATE_EXPORTED_TITLE);
      const response = await axios.put("titleManager/updateExport", payload);
      commit(constants.UPDATE_EXPORTED_TITLE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.UPDATE_EXPORTED_TITLE_ERROR, error);
      throw error.response;
    }
  },
  [constants.DISCARD_TITLE]: async ({ commit }, payload) => {
    try {
      commit(constants.DISCARD_TITLE);
      const response = await axios.delete("titleManager/discard", {
        data: payload,
      });
      commit(constants.DISCARD_TITLE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.DISCARD_TITLE_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.POST_TITLE_FILES](state) {
    state.title.status = "fetching";
  },
  [constants.POST_TITLE_FILES_SUCCESS](state, response) {
    state.title.status = "success";
    state.title.data = response.data;
  },
  [constants.POST_TITLE_FILES_ERROR](state, error) {
    state.title.status = "error";
    state.title.error = error;
  },
  [constants.GET_TITLE_FILES](state) {
    state.titles.status = "fetching";
  },
  [constants.GET_TITLE_FILES_SUCCESS](state, response) {
    state.titles.status = "success";
    state.titles.data = response;
  },
  [constants.GET_TITLE_FILES_ERROR](state, error) {
    state.titles.status = "error";
    state.titles.error = error;
  },
  [constants.GET_TITLE_FILES_VEHICLES](state) {
    state.titleVehicles.status = "fetching";
  },
  [constants.GET_TITLE_FILES_VEHICLES_SUCCESS](state, response) {
    state.titleVehicles.status = "success";
    state.titleVehicles.data = response;
  },
  [constants.GET_TITLE_FILES_VEHICLES_ERROR](state, error) {
    state.titleVehicles.status = "error";
    state.titleVehicles.error = error;
  },
  [constants.UPDATE_EXPORTED_TITLE](state) {
    state.title.status = "fetching";
  },
  [constants.UPDATE_EXPORTED_TITLE_SUCCESS](state, response) {
    state.title.status = "success";
    state.title.data = response.data;
  },
  [constants.UPDATE_EXPORTED_TITLE_ERROR](state, error) {
    state.title.status = "error";
    state.title.error = error;
  },
  [constants.DISCARD_TITLE](state) {
    state.title.status = "fetching";
  },
  [constants.DISCARD_TITLE_SUCCESS](state, response) {
    state.title.status = "success";
    state.title.data = response.data;
  },
  [constants.DISCARD_TITLE_ERROR](state, error) {
    state.title.status = "error";
    state.title.error = error;
  },



};


export default {
  state: getDefaultState(),
  actions,
  mutations,
};