<template>
  <FilterTypeDate fromType="saleDateFrom" toType="saleDateTo" />
</template>

<script>
import FilterTypeDate from "./FilterTypeDate.vue";
export default {
  name: "SoldFilter",
  components: { FilterTypeDate },
};
</script>

<style>
</style>