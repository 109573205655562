import superAdmin from "./superAdmins/store";
import corporation from "./corporations/store";
import marketplace from "./marketplace/store";
function getDefaultState() {
  return {};
}

export default {
  state: getDefaultState(),
  modules: {
    superAdmin,
    corporation,
    marketplace,
  },
};
