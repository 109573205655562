<template>
  <div>
    <div class="container-fluid">
      <div>
        <AddonsFileFilter class="float-right" />
        <b-table
          class="mt-3"
          show-empty
          bordered
          small
          stacked="md"
          head-variant="light"
          :items="addonsFileList.data.data || []"
          :fields="fields"
          :busy="addonsFileList.status === 'fetching'"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" variant="yellow" />
            </div>
          </template>
          <template v-slot:cell(actions)="{ item }">
            <div class="text-center">
              <b-form-group>
                <!-- loading icon -->

                <b-spinner
                  v-if="item.status == 0"
                  variant="yellow"
                  class="m-2"
                  small
                  v-b-tooltip.hover
                  title="Uploading"
                />

                <b-icon
                  v-else
                  icon="eye"
                  class="h5 m-2"
                  @click="seeDetails(item)"
                />
                <!-- donwnload file -->
                <a :href="item.file" :download="item.name" target="_blank">
                  <b-icon icon="download" class="h5 m-2" />
                </a>
                <b-icon
                  icon="x-circle"
                  class="h5 m-2"
                  v-b-tooltip.hover
                  title="Delete txt file"
                  @click="deleteItem(item)"
                ></b-icon>
              </b-form-group>
            </div>
          </template>
        </b-table>
        <b-pagination
          v-if="addonsFileList.status !== 'fetching'"
          :value="addonsFileList.data ? addonsFileList.data.currentPage : 1"
          :total-rows="addonsFileList.data ? addonsFileList.data.total : 0"
          :per-page="addonsFileList.data ? addonsFileList.data.perPage : 25"
          class="mb-2"
          align="end"
          @change="changePage($event)"
        />
      </div>

      <b-modal
        id="detailsModal"
        ref="detailsModal"
        size="md"
        hide-footer
        body-class=""
        ok-disabled
        @hidden="addonTypeItem = {}"
      >
        <AddonsFileForm
          :addonTypeItem="addonTypeItem"
          :refreshData="getData"
          :hideModal="hideModal"
        />
      </b-modal>
    </div>
  </div>
</template>
<script>
import AddonsFileForm from "./AddonsFileForm.vue";
import {
  POST_ADDON_FILE,
  GET_ADDONS_FILE_LIST,
  DELETE_ADDON_FILE,
} from "./actions";
import { mapState } from "vuex";

import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../mixins";
import AddonsFileFilter from "./AddonsFileFilter";
export default {
  name: "AddonsFileTable",
  components: { AddonsFileForm, AddonsFileFilter },
  mixins: [generalMixin, superAdminMixin],
  computed: {
    ...mapState({
      addonsFileList: (state) =>
        state.superAdminStore.addonsFile.addonsFileList,
    }),
    hasPermission() {
      return this.userHasPermission("addons", "write");
    },
    fields() {
      let addonsFields = [
        { key: "name", label: "Name", class: "text-center" },
        {
          key: "type",
          label: "Type",
          class: "text-center",
        },
        { key: "ids", label: "Ids", class: "text-center" },
        {
          key: "createdAt",

          label: "Create at",
          class: "text-center",
          formatter: (date) => this.$options.filters.formatDate(date),
        },
        {
          key: "actions",
          label: "Actions",
          class: "text-center",
        },

        /*     {
          key: "actions",
          label: "Actions",
          note: false,
          details: true,
          validation: false,
          delete: this.hasPermission,
        }, */
      ];

      return addonsFields;
    },
  },
  data() {
    return {
      options: {
        assign: false,
        rolFilter: false,
        add: false,
        fun: this.getData,
      },
      addonTypeItem: {},
      type: "success",
    };
  },
  mounted() {
    this.$store.commit(
      "setSearchFunction",
      this.userHasPermission("addons", "write") ? this.getData : null
    );
    this.$store.commit(
      "setAddFunction",
      this.userHasPermission("addons", "write") ? this.showModal : null
    );

    this.getData();
  },
  methods: {
    seeDetails(item) {
      this.$router.push({
        name: "superadmin.addons-file.by-id",
        params: { id: item.id },
      });
    },
    setType(type) {
      this.type = type;

      this.getData();
    },
    getData(filter = "") {
      this.$store
        .dispatch(GET_ADDONS_FILE_LIST, {
          search: filter,
          resourcesPerPage: 25,
        })
        .then(() => {})
        .catch(() => {});
    },
    async deleteItem(item) {
      const confirm = await this.showConfirmDeleteBox();
      if (!confirm) return;
      const payload = { id: item.id };
      payload.status = 0;
      this.$store
        .dispatch(DELETE_ADDON_FILE, payload)
        .then(() => {
          this.getData();
          this.makeToast(
            "Success",
            "Addon file deleted successfully",
            "success"
          );
        })
        .catch(() => {
          this.$root.$bvToast.toast("Addon file could not be disabled", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    activateItem(item) {
      const payload = {
        id: item.id,
        status: 1,
      };
      this.$store
        .dispatch(POST_ADDON_FILE, payload)
        .then(() => {
          this.makeToast(
            "Success",
            "Addon type updated successfully",
            "success"
          );
          this.getData();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Addon type could not be activated", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    showModal() {
      this.$bvModal.show("detailsModal");
    },
    editItem(item) {
      this.addonTypeItem = item;
      this.$bvModal.show("detailsModal");
    },
    changePage(page, filter = "") {
      const payload = {
        search: filter,
        page: page,
        resourcesPerPage: 25,
      };
      this.$store
        .dispatch(GET_ADDONS_FILE_LIST, payload)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    downloadFileFromUrl(url, name) {
      const file = URL.createObjectURL(url);
      file.download = name;
      file.click();
    },

    hideModal() {
      this.$bvModal.hide("detailsModal");
    },
  },
};
</script>
