<template>
  <div>
    <b-navbar
      variant="yellow"
      class="p-4 mb-5"
    />

    <b-container class="text-center my-auto h-100">
      <b-container fluid>
        <h4>
          Your notifications via email are
          <span
            v-if="notifications.acceptEmail"
            class="font-weight-bold"
          >enabled</span>
          <span
            v-else
            class="font-weight-bold"
          >disabled</span>, do you want to
          <span
            v-if="notifications.acceptEmail"
            class="font-weight-bold"
          >
            disable
          </span>
          <span
            v-else
            class="font-weight-bold"
          >enable</span> them?
        </h4>
        <br>

        <b-button
          :disabled="disableNotificationsFetching"
          class="w-25 mt-3"
          variant="success"
          @click="disableNotifications()"
        >
          <b-spinner
            v-if="disableNotificationsFetching"
            variant="white"
            small
          />
          <span v-else>Yes</span>
        </b-button>

        <b-button
          :disabled="disableNotificationsFetching"
          class="w-25 mt-3 ml-5"
          variant="danger"
          @click="tokenEmail ? goToHome() : goToCarList()"
        >
          <span>No</span>
        </b-button>
      </b-container>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { PUT_DISABLE_NOTIFICATIONS, GET_NOTIFICATIONS_CONFIG } from './actions';
import { generalMixin } from '@/modules/mixin';

export default {
  mixins: [ generalMixin ],

  data() {
    return {
      notifications: {
        id: null,
        acceptEmail: 0
      }
    };
  },
  computed: {
    ...mapState({
      disableNotificationsFetching: (state) =>
        state.adminStore.users.disableNotifications.status === 'fetching'
          ? true
          : false,
      notificationConfig: (state) =>
        state.adminStore.users.notificationConfig.data
    }),
    tokenEmail() {
      return this.$route.params.token;
    }
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.$store.commit(GET_NOTIFICATIONS_CONFIG, null);
      const payload = this.formatPayload();
      this.$store
        .dispatch(GET_NOTIFICATIONS_CONFIG, payload)
        .then(() => {
          this.formatConfig();
        })
        .catch(() => {});
    },
    disableNotifications() {
      const payload = {
        id: this.notifications.id,
        acceptEmail: !this.notifications.acceptEmail
      };
      this.$store
        .dispatch(PUT_DISABLE_NOTIFICATIONS, payload)
        .then(() => {
          this.toastMessage('Notifications updated', 'Success', 'success');
          this.notifications.acceptEmail = !this.notifications.acceptEmail;
          if (this.$route.params.token) {
            this.goToHome();
          }
        })
        .catch(() => {
          this.toastMessage('Notifications not updated', 'Warning', 'warning');
        });
    },
    formatConfig() {
      this.notifications = { ...this.notificationConfig };
    },
    formatPayload() {
      let payload = {
        token: ''
      };
      if (this.$route.params.token) {
        payload = {
          token: this.$route.params.token
        };
      }

      return payload;
    },
    goToHome() {
      this.$router.push({ path: '/' });
    },
    goToCarList() {
      if (this.userIsBuyer) {
        this.$router.push({ path: '/buyer/buyer-car-list' });
      }
      if (this.userIsAgent) {
        this.$router.push({ path: '/agent/vehicles/list' });
      }
      if (this.userIsClient) {
        this.$router.push({ path: '/buyer/car-list' });
      }
      if (this.userIsAdmin) {
        this.$router.push({ path: '/admin/vehicles/list' });
      }
    }
  }
};
</script>

<style></style>
