<template>
  <b-container class="p-auto">
    <SearchVehicle />
  </b-container>
</template>

<script>
import SearchVehicle from './components/SearchVehicle.vue';
export default {
  components: { SearchVehicle }
};
</script>

<style></style>
