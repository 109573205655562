import VehiclePage from "./vehicle-page/VehiclePage.vue"
const routes = [
  {
    path: '/public/vehicle/:id',
    name: 'public.vehicle',
    component: VehiclePage,

  },
  {
    path: '/agent/vehicle/:id',
    name: 'agent.vehicle',
    component: VehiclePage,

  }
];

export default routes;
