<template>
  <div>
    <div class="mt-2 mb-2 mr-4">
      <div class="text-right">
        <b-button
          :disabled="vehiclesExcel.status == 'fetching'"
          variant="success"
          class="text-white mx-1"
          @click="getVehiclesExcel()"
        >
          <b-spinner
            v-if="vehiclesExcel.status == 'fetching'"
            class="align-middle"
            variant="white"
            small
          />

          <font-awesome-icon
            v-else
            class="cursor-pointer"
            :icon="['far', 'file-excel']"
          />
        </b-button>
      </div>
    </div>
    <div class="container-fluid">
      <div>
        <b-table
          class="mt-3"
          show-empty
          bordered
          small
          stacked="md"
          head-variant="light"
          :items="vehicles.data || []"
          :fields="fields"
          :busy="vehicles.status === 'fetching'"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" variant="yellow" />
            </div>
          </template>
          <template v-slot:cell(actions)="{ item }">
            <div class="text-center">
              <b-form-group>
                <!-- loading icon -->

                <b-spinner
                  v-if="item.status == 0"
                  variant="yellow"
                  class="m-2"
                  small
                  v-b-tooltip.hover
                  title="Uploading"
                />

                <b-icon v-else icon="eye" class="h5 m-2" @click="seeDetails(item)" />
                <!-- donwnload file -->
                <a :href="item.file" :download="item.name" target="_blank">
                  <b-icon icon="download" class="h5 m-2" />
                </a>
              </b-form-group>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>
<script>
import { GET_ACCOUNTING_VEHICLES_LIST, GET_ACCOUNTING_VEHICLES_EXCEL } from "./actions";
import { mapState } from "vuex";

import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../mixins";
export default {
  name: "AccountingVehiclesTable",
  mixins: [generalMixin, superAdminMixin],
  computed: {
    ...mapState({
      vehicles: (state) => state.superAdminStore.accounting.vehicles,
      vehiclesExcel: (state) => state.superAdminStore.accounting.vehiclesExcel,
    }),

    fields() {
      return [
        { key: "stockNumber", label: "Stock", class: "text-center" },
        { key: "vinNumber", label: "Vin number", class: "text-center" },
        { key: "year", label: "Year", class: "text-center" },
        { key: "make", label: "Make", class: "text-center" },
        { key: "model", label: "Model", class: "text-center" },
        {
          key: "color",
          label: "Color",
          class: "text-center",
          formatter: (amount) =>
            typeof amount == "number" ? this.$options.filters.currency(amount) : "-",
        },

        {
          key: "reservedPrice",
          label: "Reserved price",
          class: "text-center",
          formatter: (amount) =>
            typeof amount == "number" ? this.$options.filters.currency(amount) : "-",
        },
        {
          key: "reservedPriceCad",
          label: "Reserved price CAD",
          class: "text-center",
          formatter: (amount) =>
            typeof amount == "number" ? this.$options.filters.currency(amount) : "-",
        },
      ];
    },
  },
  data() {
    return {
      options: {
        assign: false,
        rolFilter: false,
        add: false,
        fun: this.getData,
      },
      addonTypeItem: {},
      type: "success",
    };
  },
  mounted() {
    this.$store.commit(
      "setSearchFunction",
      this.userHasPermission("addons", "write") ? this.getData : null
    );
    this.$store.commit(
      "setAddFunction",
      this.userHasPermission("addons", "write") ? this.showModal : null
    );

    this.getData();
  },
  methods: {
    seeDetails(item) {
      this.$router.push({
        name: "superadmin.addons-file.by-id",
        params: { id: item.id },
      });
    },

    getData() {
      this.$store
        .dispatch(GET_ACCOUNTING_VEHICLES_LIST, {
          companyId: this.$route.params.id,
        })
        .then(() => {})
        .catch(() => {});
    },

    showModal() {
      this.$bvModal.show("detailsModal");
    },
    editItem(item) {
      this.addonTypeItem = item;
      this.$bvModal.show("detailsModal");
    },
    changePage(page, filter = "") {
      const payload = {
        search: filter,
        page: page,
      };
      this.$store
        .dispatch(GET_ACCOUNTING_VEHICLES_LIST, payload)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    getVehiclesExcel() {
      this.$store
        .dispatch(GET_ACCOUNTING_VEHICLES_EXCEL, {
          companyId: this.$route.params.id,
        })
        .then((response) => {
          const blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = this.formatFileName("accounting") + ".xls" || "ficher.xls";
          link.click();
        });
    },
    formatFileName(module) {
      let fileName = "";
      const today = new Date();
      fileName = `${module}-${today.getFullYear()}-${today.getMonth()}-${today.getDate()}-${today.getHours()}-${today.getMinutes()}-${today.getSeconds()}`;
      return fileName;
    },
  },
};
</script>
