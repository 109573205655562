<template>
  <b-form>
    <b-row>
      <b-col cols="6">
        <b-form-group label="Type">
          <b-form-select
            v-model.trim="$v.form.type.$model"
            :state="validateState('type')"
            aria-describedby="input-type-live-feedback"
            @change="setValue('type', $event)"
          >
            <option v-for="type in noteTypes" :key="type.type" :value="type.type">
              {{ type.label }}
            </option>
          </b-form-select>
          <b-form-invalid-feedback id="input-type-live-feedback">
            This is a required field.
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="Description">
          <b-form-textarea
            v-model.trim="$v.form.description.$model"
            class="resize-none"
            :state="validateState('description')"
            aria-describedby="input-description-live-feedback"
            @change="setValue('description', $event)"
          />
          <b-form-invalid-feedback id="input-description-live-feedback">
            This is a required field.
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <div>
      <span>Alert</span>
      <b-form-checkbox
        size="lg"
        switch
        :value="1"
        :unchecked-value="0"
        v-model="alert"
        @change="toggleAlert()"
      />
    </div>
    <div class="text-right mt-2">
      <b-button
        :disabled="$v.form.$invalid || saveNote === 'fetching'"
        variant="primary"
        @click="addNote()"
      >
        <b-spinner v-if="saveNote === 'fetching'" variant="white" small />
        <span v-else>Submit</span>
      </b-button>
    </div>
  </b-form>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import { POST_VEHICLE_NOTE, PUT_ALERT } from "../actions";
import { superAdminMixin } from "../../mixins";
import { NOTE_TYPES } from "./noteTypes";
export default {
  mixins: [superAdminMixin],
  props: ["vehicle"],
  data() {
    return {
      form: {
        description: null,
        vehicleId: null,
        type: null,
      },
      alert: false,
    };
  },
  computed: {
    ...mapState({
      saveNote: (state) => state.superAdminStore.componentStore.saveNote.status,
    }),
    noteTypes() {
      return NOTE_TYPES;
    },
  },
  validations: {
    form: {
      description: {
        required,
      },
      type: {},
    },
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form.description = null;
      this.form.vehicleId = null;
      this.form.type = null;
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    addNote() {
      if (this.$v.form.$invalid) return;
      this.form.vehicleId = this.vehicle.id;

      this.$store
        .dispatch(POST_VEHICLE_NOTE, this.form)
        .then(() => {
          this.resetForm();
          this.getNotes(true, this.vehicle);
          this.toastMessage("Note saved", "Success", "success");
          this.getVehiclesInfo();
        })
        .catch(() => {
          this.toastMessage("Note could not saved", "Warning", "warning");
        });
    },
    toggleAlert() {
      this.$store
        .dispatch(PUT_ALERT, {
          id: this.vehicle.id,
          alert: this.alert,
        })
        .then(() => {
          this.getNotes(true, this.vehicle);
          this.toastMessage("Alert saved", "Success", "success");
          this.getVehiclesInfo();
        })
        .catch(() => {
          this.toastMessage("Alert could not saved", "Warning", "warning");
        });
    },
  },
  mounted() {
    if (this.vehicle) {
      this.alert = this.vehicle.alert;
    }
    this.form.type = this.noteTypes[0].type;
  },
};
</script>

<style></style>
