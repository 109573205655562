<template>
  <div>
    History
    <ul>
      <li v-for="(item, index) in history" :key="index">
        {{ getHistoryCost(item) | currency }} {{ item.date | formatDateTime }} -
        {{ item.name }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    history: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    getHistoryCost(item) {
      item = JSON.parse(item.oldValues);
      return item.cost;
    },
  },
};
</script>
<style></style>
