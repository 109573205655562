var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.workOrder)?_c('b-card',{attrs:{"body-class":"pb-0"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"9"}},[_c('span',[_vm._v(" Registered date: "),_c('i',[_vm._v(_vm._s(_vm._f("formatDateTime")(_vm.workOrder.createdAt))+" - "),(_vm.workOrder.addonType)?_c('span',[_vm._v(" "+_vm._s(_vm.workOrder.addonType.name))]):_vm._e(),(_vm.workOrder.category)?_c('span',[_vm._v(" "+_vm._s(_vm.workOrder.category.name))]):_vm._e()])]),_c('br'),(_vm.workOrder.status == 4 || _vm.workOrder.status == 0)?_c('span',[_vm._v(" End date: "),_c('i',[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(_vm.workOrder.endDate))+" - "+_vm._s(_vm.workOrder.finalUser ? _vm.workOrder.finalUser.firstName + " " + _vm.workOrder.finalUser.lastName : "No one")+" ")])]):_vm._e(),_c('span',[_vm._v("Status: "),_c('i',[_vm._v(" "+_vm._s(_vm.getVariantAndLabel(_vm.workOrder.status).label))])])]),_c('b-col',{staticClass:"text-center"},[_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"dot",style:({
            'background-color': _vm.getVariantAndLabel(_vm.workOrder.status).variant,
          })}),_c('br'),_c('div',{staticClass:"d-flex justify-content-center"},[(_vm.workOrder.status == 3)?_c('b-button',{attrs:{"variant":"outline-success","title":"Cancel","id":"cancel-work-order","size":"sm"},on:{"click":function($event){return _vm.updateWorkOrderStatus(_vm.workOrder.status)}}},[_vm._v("Approuver ")]):_vm._e(),_c('br'),(_vm.workOrder.status == 3)?_c('b-button',{attrs:{"variant":"outline-danger","title":"Cancel","id":"cancel-work-order","size":"sm"},on:{"click":function($event){return _vm.updateWorkOrderStatus(_vm.workOrder.status)}}},[_vm._v("Annuler ")]):_vm._e()],1),_c('br')])])],1),(_vm.showCancelNote)?_c('p',[_c('b-form-group',{attrs:{"label":"Note"}},[_c('b-form-textarea',{staticClass:"resize-none",attrs:{"aria-describedby":"input-description-live-feedback"},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[_c('div',{staticClass:"float-right"},[_c('b-btn',{staticClass:"btn btn-danger mr-2",attrs:{"size":"lg","type":"button"},on:{"click":function($event){_vm.showCancelNote = false}}},[_vm._v(" Cancel ")]),_c('b-btn',{staticClass:"btn btn-success",attrs:{"size":"lg","type":"button"},on:{"click":function($event){_vm.showCancelNote = false}}},[_vm._v(" Confirm ")])],1)])],1)],1):_vm._e(),_c('span',{staticClass:"text-justify mt-3"},[_vm._v(" Description: "),_c('i',[_vm._v(_vm._s(_vm.workOrder.description))])]),_c('br'),_c('span',{staticClass:"text-justify"},[_vm._v(" Provider: "),(_vm.workOrder.supplierCompany != null)?_c('i',[_vm._v(_vm._s(_vm.workOrder.supplierCompany.companyName))]):_vm._e(),(
        !_vm.isProviderEditing &&
        _vm.workOrder.supplierCompany == null &&
        _vm.workOrder.status == 1
      )?_c('b-button',{staticClass:"mr-2",attrs:{"variant":"light","size":"sm"},on:{"click":function($event){_vm.isProviderEditing = true}}},[_c('b-icon',{attrs:{"icon":"pencil"}})],1):_vm._e()],1),_c('br'),_c('span',[_vm._v(" Cost: "),(!_vm.isEditing)?_c('i',[_vm._v(_vm._s(_vm._f("currency")(_vm.workOrder.cost))+" ")]):_c('b-col',{staticClass:"px-0",attrs:{"xs":"12","sm":"12","md":"12","lg":"12","xl":"4"}},[_c('b-form-group',{attrs:{"label-size":"sm"}},[_c('template',{slot:"label"}),_c('Money',_vm._b({staticClass:"form-control text-center",model:{value:(_vm.form.cost),callback:function ($$v) {_vm.$set(_vm.form, "cost", $$v)},expression:"form.cost"}},'Money',_vm.money,false))],2)],1),(_vm.isEditing)?_c('b-button',{staticClass:"mr-2",attrs:{"variant":"light","size":"sm"},on:{"click":function($event){_vm.isEditing = false}}},[_c('b-icon',{attrs:{"icon":"x"}})],1):_vm._e(),(_vm.isEditing)?_c('b-button',{staticClass:"mr-2",attrs:{"variant":"light","size":"sm"},on:{"click":function($event){return _vm.update(_vm.workOrder)}}},[_vm._v(" Save ")]):_vm._e(),(!_vm.isEditing && _vm.workOrder.status == 1 && _vm.workOrder.cost == 0)?_c('b-button',{staticClass:"mr-2",attrs:{"variant":"light","size":"sm"},on:{"click":function($event){_vm.isEditing = true}}},[_c('b-icon',{attrs:{"icon":"pencil"}})],1):_vm._e()],1),(_vm.workOrder.note)?_c('p',[_vm._v(" Note: "),_c('i',[_vm._v(_vm._s(_vm.workOrder.note))])]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }