<template>
  <div class="container">
    <b-form class="" @submit.stop.prevent="form.id ? update() : save()">
      <h3 class="text-purple-yellow">
        <b-iconstack class="m-1 text-center" font-scale="1">
          <b-icon icon="cash" font-scale="0.6" />
          <b-icon
            icon="plus-circle-fill"
            shift-h="25"
            shift-v="4"
            font-scale="0.30"
          />
        </b-iconstack>
      </h3>
      <b-row>
        <!-- First name -->
        <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
          <b-form-group label-size="sm">
            <template slot="label">
              Name <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="name-input"
              v-model.trim="$v.form.name.$model"
              size="sm"
              name="name-input"
              :value="form.name"
              :state="validateState('name')"
              aria-describedby="input-name-live-feedback"
              maxlength="100"
              @change="setValue('name', $event)"
            />
            <b-form-invalid-feedback id="input-name-live-feedback">
              This is a required field and must at least 2 characters.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <!-- Percent -->
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group label-size="sm">
            <template slot="label">
              Amount <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="amount-input"
              v-model="$v.form.amount.$model"
              size="sm"
              name="amount-input"
              :value="form.amount"
              :state="validateState('amount')"
              aria-describedby="input-amount-live-feedback"
              @change="setValue('amount', $event)"
            />
            <b-form-invalid-feedback id="input-amount-live-feedback">
              This is a required field between 0 and 100.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <!-- Market place -->
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group label-size="sm">
            <template slot="label">
              Marketplace <span class="text-danger">*</span>
            </template>
            <b-form-select
              v-model="form.marketPlace"
              :options="markets"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group label-size="sm">
            <template slot="label">
              Default tax?
              <span class="text-danger">*</span>
            </template>
            <b-form-radio-group
              v-model="form.appliesDefault"
              :options="options"
              class="mb-3"
              value-field="item"
              text-field="name"
              disabled-field="notEnabled"
            ></b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row />

      <b-row>
        <b-col sm="12" md="6" lg="6" xl="6" />
        <b-col sm="12" md="3" lg="3" xl="3" />
        <b-col sm="12" md="3" lg="3" xl="3">
          <b-btn
            v-if="userHasPermission('fees', 'write')"
            block
            size="lg"
            class="btn btn-success"
            :disabled="$v.form.$invalid"
            type="submit"
          >
            Submit
            <b-spinner v-if="tax.status == 'fetching'" small variant="white" />
          </b-btn>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import { POST_FEE } from "./actions";
import { mapState } from "vuex";
import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../mixins";

export default {
  mixins: [generalMixin, superAdminMixin],
  props: ["feeItem", "refreshData", "hideModal"],
  computed: {
    ...mapState({
      tax: (state) => state.superAdminStore.fees.fee,
    }),
  },

  data() {
    return {
      form: {
        id: null,
        name: null,
        amount: null,
        statesId: null,
        appliesDefault: 0,
      },
      options: [
        {
          item: 1,
          name: "Yes",
          notEnabled: false,
        },
        {
          item: 0,
          name: "No",
          notEnabled: false,
        },
      ],
      markets: [
        { value: "QAS", text: "QAS" },
        { value: "CANAM", text: "CANAM" },
      ],
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(2),
      },
      amount: {
        required,
      },

      appliesDefault: {
        required,
      },
    },
  },
  mounted() {
    if (this.feeItem.id) {
      this.formatForm();
    }
  },
  methods: {
    closeModal(callback) {
      callback();
    },
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    save() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_FEE, payload)
        .then(() => {
          this.makeToast("Success", "fee created successfully", "success");
          this.refreshData();
          this.hideModal();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Internal server error, try again later", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    update() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_FEE, payload)
        .then(() => {
          this.makeToast("Success", "Fee updated successfully", "success");
          this.refreshData();
          this.hideModal();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Internal server error, try again later", {
            title: "Warning",
            variant: "warning",
          });
        });
    },

    formatPayload() {
      const payload = {
        id: this.feeItem.id ? this.feeItem.id : null,
        name: this.form.name,
        amount: this.form.amount,
        marketPlace: this.form.marketPlace,
        appliesDefault: this.form.appliesDefault,
      };

      return payload;
    },

    formatForm() {
      this.form.id = this.feeItem.id;
      this.form.name = this.feeItem.name;
      this.form.amount = this.feeItem.amount;
      this.form.marketPlace = this.feeItem.marketPlace;
      this.form.appliesDefault = this.feeItem.appliesDefault;
    },
  },
};
</script>

<style scoped>
.append-input {
  max-width: 40%;
}
</style>
