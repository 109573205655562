<template>
  <div class="d-flex justify-content-center p-1">
    <span
      v-if="user"
      class="avatar avatar-sm rounded-circle bg-success text-white"
      v-b-tooltip.hover
      :title="user.firstName + ' ' + user.lastName"
    >
      {{ acronym }}
    </span>
    <b-badge
      v-if="vehicle"
      pill
      class="bg-success text-white mt-2 cursor-pointer"
      v-b-tooltip.hover
      title="See vehicle details"
      @click="openVehicleDetails"
    >
      {{ vehicle.stockNumber }} - {{ vehicle.year }} {{ vehicle.make }}
      {{ vehicle.model }}
    </b-badge>
  </div>
</template>
<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => null,
    },
    vehicle: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    acronym() {
      if (!this.user) {
        return "";
      }
      return this.user.acronym || this.initialsOfProfile;
    },
    initialsOfProfile() {
      if (!this.user) {
        return "";
      }
      return this.user.firstName
        .split(" ")
        .map((word) => word[0])
        .join("");
    },
  },

  methods: {
    openVehicleDetails() {
      if (!this.vehicle) {
        return;
      }
      this.$store.commit("setVehicleDetailsSideBar", {
        stockNumber: this.vehicle.stockNumber,
        isShowing: true,
      });
    },
  },
};
</script>
