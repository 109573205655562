<template>
  <b-row>
    <b-col class="mb-3">
      <Search
        ref="searchVehiclesComponent"
        class="sticky-top"
        :un-auth-user="true"
      />
    </b-col>
    <b-col
      xl="9"
      md="9"
      sm="12"
    >
      <b-row>
        <b-col
          v-if="vehiclesStatus === 'fetching'"
          class="mt-2"
        >
          <b-row>
            <b-col
              v-for="(data, index) in [1, 2, 3, 4, 5, 6]"
              :key="index"
              lg="4"
              md="6"
              sm="12"
            >
              <b-card
                no-body
                img-top
                class="mb-3"
              >
                <b-skeleton-img
                  card-img="top"
                  aspect="3:1"
                />
                <b-card-body>
                  <b-skeleton-table
                    :rows="5"
                    :columns="1"
                    :table-props="{ bordered: true, striped: true }"
                  />
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-else-if="publicVehicles.data && !publicVehicles.data.length"
          class="mt-2 col-lg-4 col-centered"
        >
          <b>No records to show</b>
        </b-col>
        <b-col
          v-for="vehicle in publicVehicles.data"
          v-else
          :key="vehicle.id"
          class="mt-2"
          xl="4"
          md="4"
          sm="12"
        >
          <VehicleCard
            :vehicle="vehicle"
            :show-c-r-modal="true"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from 'vuex';
import Search from './Search';
import VehicleCard from './VehicleCard';
import { listVehiclesMixin } from './mixins';
import { SET_VEHICLE_FILTER_ACTION } from './actions';

export default {
  components: { Search, VehicleCard },
  mixins: [ listVehiclesMixin ],
  computed: {
    ...mapState({
      vehiclesStatus: (state) => state.home.vehicles.status
    })
  },
  mounted() {
    this.$store.commit(SET_VEHICLE_FILTER_ACTION, 'PUBLIC_GET_VEHICLES');
  }
};
</script>

<style></style>
