export const POST_LIEN_NOTE = "POST_LIEN_NOTE";
export const POST_LIEN_NOTE_SUCCESS = "POST_LIEN_NOTE_SUCCESS";
export const POST_LIEN_NOTE_ERROR = "POST_LIEN_NOTE_ERROR";

export const DELETE_LIEN_NOTE = "DELETE_LIEN_NOTE";
export const DELETE_LIEN_NOTE_SUCCESS = "DELETE_LIEN_NOTE_SUCCESS";
export const DELETE_LIEN_NOTE_ERROR = "DELETE_LIEN_NOTE_ERROR";

export const GET_LIEN_NOTES = "GET_LIEN_NOTES";
export const GET_LIEN_NOTES_SUCCESS = "GET_LIEN_NOTES_SUCCESS";
export const GET_LIEN_NOTES_ERROR = "GET_LIEN_NOTES_ERROR";
