<template>
  <div class="container">
    <b-form class="" @submit.stop.prevent="form.id ? update() : save()">
      <h3 class="text-purple-yellow">
        <b-icon icon="geo-alt" scale="1.2" />
      </h3>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
          <b-form-group label-size="md">
            <template slot="label">
              Name
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              v-model.trim="$v.form.name.$model"
              required
              size="sm"
              name="name"
              maxlength="65"
              :value="form.name"
              :state="validateState('name')"
              @change="setValue('name', $event)"
            />
            <b-form-invalid-feedback>
              <div v-if="!$v.form.name.required">This field is required</div>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <!--  Address -->
        <b-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
          <b-form-group label-size="md">
            <template slot="label">
              Address
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              v-if="isAddressSelected"
              v-model="form.address"
              size="md"
              name="street"
              class="mb-3"
              maxlength="65"
              autocomplete="off"
              @focus="isAddressSelected = false"
            />
            <place-autocomplete-field
              v-else
              v-model="form.address"
              v-place-autofill:street="form.street"
              v-place-autofill:city="form.city"
              v-place-autofill:state="form.state"
              v-place-autofill:zipcode="form.zip"
              name="address"
              autocomplete="off"
              api-key="AIzaSyBWj-z1916MmpcimZW3xPlRGXMikForpS4"
              placeholder="Enter an an address, zipcode, or location"
              country="us"
              @autocomplete-select="selectAddress"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="md">
            <template slot="label">
              Street
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              v-model.trim="$v.form.street.$model"
              required
              size="sm"
              name="street"
              maxlength="65"
              :value="form.street"
              :state="validateState('street')"
              @change="setValue('street', $event)"
            />
            <b-form-invalid-feedback>
              <div v-if="!$v.form.street.required">This field is required</div>
              <div v-if="!$v.form.street.maxLength">
                Character limit reached
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="md">
            <template slot="label">
              ZIP postal code
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              v-model.trim="$v.form.zip.$model"
              required
              size="sm"
              name="zip"
              maxlength="10"
              :value="form.zip"
              :state="validateState('zip')"
              @change="setValue('zip', $event)"
            />
            <b-form-invalid-feedback>
              <div v-if="!$v.form.zip.required">This field is required</div>
              <div v-if="!$v.form.zip.maxLength">Character limit reached</div>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="md">
            <template slot="label">
              State
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              v-model.trim="$v.form.state.$model"
              disabled
              required
              size="sm"
              name="state"
              maxlength="250"
              :value="form.state"
              :state="validateState('state')"
              @change="setValue('stateId', $event)"
            />
            <b-form-invalid-feedback>
              <div v-if="!$v.form.state.required">This field is required</div>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="md">
            <template slot="label">
              City
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              v-model.trim="$v.form.city.$model"
              required
              size="sm"
              name="city"
              maxlength="250"
              :value="form.city"
              :state="validateState('city')"
              @change="setValue('city', $event)"
            />
            <b-form-invalid-feedback>
              <div v-if="!$v.form.city.required">This field is required</div>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="md">
            <template slot="label"> Home location? </template>
            <b-form-checkbox
              v-model="form.homeLocation"
              size="xl"
              class="ml-4"
              switch
              :value="1"
              :unchecked-value="0"
            >
              <b> {{ form.homeLocation == 1 ? "Yes" : "No" }}</b>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12" md="6" lg="6" xl="6" />
        <b-col sm="12" md="3" lg="3" xl="3" />
        <b-col sm="12" md="3" lg="3" xl="3">
          <b-btn
            v-if="userHasPermission('locations', 'write')"
            block
            size="lg"
            class="btn btn-success"
            :disabled="$v.form.$invalid"
            type="submit"
          >
            Submit
            <b-spinner
              v-if="location.status == 'fetching'"
              small
              variant="white"
            />
          </b-btn>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import { POST_LOCATION } from "./actions";
import { mapState } from "vuex";
import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../mixins";

export default {
  mixins: [generalMixin, superAdminMixin],
  props: ["locationItem", "refreshData", "hideModal"],
  computed: {
    ...mapState({
      location: (state) => state.superAdminStore.location.location,
    }),
  },

  data() {
    return {
      form: {
        id: null,
        name: null,
        address: null,
        street: "",
        zip: "",
        state: "",
        city: "",
        homeLocation: false,
      },
      isAddressSelected: false,
    };
  },
  validations: {
    form: {
      address: {},
      street: {
        required,
        maxLength: maxLength(250),
      },
      zip: {
        required,
        maxLength: maxLength(10),
      },
      state: {
        required,
      },
      city: {
        required,
      },
      name: {
        required,
      },
      homeLocation: {},
    },
  },
  mounted() {
    if (this.locationItem.id) {
      this.formatForm();
    }
  },
  methods: {
    closeModal(callback) {
      callback();
    },
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    save() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_LOCATION, payload)
        .then(() => {
          this.makeToast("Success", "Location created successfully", "success");
          this.refreshData();
          this.hideModal();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Internal server error, try again later", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    update() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_LOCATION, payload)
        .then(() => {
          this.makeToast("Success", "Location updated successfully", "success");
          this.refreshData();
          this.hideModal();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Internal server error, try again later", {
            title: "Warning",
            variant: "warning",
          });
        });
    },

    formatPayload() {
      const payload = {
        id: this.locationItem.id ? this.locationItem.id : null,
        name: this.form.name,
        address: this.form.address,
        street: this.form.street,
        zip: this.form.zip,
        state: this.form.state,
        city: this.form.city,
        homeLocation: this.form.homeLocation,
      };
      if (this.$route.params.type === "company") {
        payload.companyId = this.$route.params.id;
      } else {
        payload.exportLocationId = this.$route.params.id;
      }

      return payload;
    },

    formatForm() {
      this.form.id = this.locationItem.id;
      this.form.name = this.locationItem.name;
      this.form.address = this.locationItem.address;
      this.form.street = this.locationItem.street;
      this.form.zip = this.locationItem.zip;
      this.form.state = this.locationItem.state;
      this.form.city = this.locationItem.city;
      this.form.homeLocation = this.locationItem.homeLocation;
      this.isAddressSelected = true;
    },
    selectAddress() {
      this.isAddressSelected = true;
    },
  },
};
</script>

<style scoped>
.append-input {
  max-width: 40%;
}
</style>
