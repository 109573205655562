<template>
  <div class="container-fluid mt-3">
    <!--     <h4 class="ml-3 mt-3">Permissions</h4>
 -->
    <b-row class="">
      <b-col>
        <UserSelect @user-select="setUserSelect($event)" type="menu" />
      </b-col>
      <b-col>
        <b-button
          v-if="userHasPermission('permissions', 'write')"
          @click="updatePermissions()"
          :disabled="permissionsStatus === 'fetching'"
          >Update permissions</b-button
        ></b-col
      >
    </b-row>
    <div class="pt-4">
      <div class="">
        <b-table
          show-empty
          bordered
          small
          stacked="md"
          head-variant="light"
          :items="permissionsFormatted"
          :fields="fields"
          :busy="permissionsStatus === 'fetching'"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" variant="yellow" />
            </div>
          </template>
          <template #cell(read)="{ item }">
            <div class="d-flex justify-content-center">
              <b-form-checkbox
                :checked="item.isActive"
                @change="(val) => onRowSelected(val, item, 'read')"
              />
            </div>
          </template>
        </b-table>
        <!--      <b-pagination
          align="end"
          :value="permissions.data.currentPage"
          :total-rows="permissions.data.total"
          :per-page="permissions.data.perPage"
          class="mb-2"
          @change="changePage"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { generalMixin } from "../../mixin";
import { superAdminMixin } from "../mixins";
import { GET_MENU_PERMISSIONS, POST_USER_MENU_PERMISSIONS } from "./actions";
import UserSelect from "./UserSelect.vue";
export default {
  name: "MenuPermissionsTable",
  components: {
    UserSelect,
  },
  mixins: [generalMixin, superAdminMixin],
  computed: {
    ...mapState({
      permissions: (state) =>
        state.superAdminStore.permissions.permissionsList.data,
      permissionsStatus: (state) =>
        state.superAdminStore.permissions.permissionsList.status,
    }),
    permissionsFormatted() {
      return this.permissionsList;
    },
  },
  data() {
    return {
      fields: [
        { key: "title", label: "Name" },
        { key: "read", label: "Read" },
      ],
      userSelected: null,
      permissionsList: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$store
        .dispatch(GET_MENU_PERMISSIONS, {})
        .then(() => {
          this.permissionsList = this.formatPermissions();
          this.$children[0].getUsersPermission();
          this.resetPermissions();
          this.userSelected = null;
          this.$children[0].clearUserSelect();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setUserSelect(user) {
      this.userSelected = user;
      this.permissionsList = [...this.permissions];
      this.resetPermissions();
      this.setPermissionsByUser();
    },
    onRowSelected(val, item) {
      item.isActive = val;
      const index = this.permissionsList.findIndex(
        (permission) => permission.name === item.name
      );
      this.permissionsList[index] = item;
    },
    formatPermissions() {
      this.permissionsList = [];
      let permissionsList = [...this.permissions];
      permissionsList.forEach((permission) => {
        permission.isActive = false;
      });
      return permissionsList;
    },
    setPermissionsByUser() {
      this.permissionsList.forEach((permission) => {
        const index = this.userSelected.menuPermissions.findIndex(
          (userPermission) => userPermission.name === permission.name
        );
        if (index !== -1) {
          permission.isActive =
            this.userSelected.menuPermissions[index].status == 1 ? true : false;
        }
      });
      this.permissionsList = [...this.permissionsList];
    },
    resetPermissions() {
      this.permissionsList.forEach((permission) => {
        permission.isActive = false;
      });
      this.permissionsList = [...this.permissionsList];
    },
    updatePermissions() {
      const permissions = this.permissionsList.filter(
        (permission) => permission.isActive
      );
      this.$store
        .dispatch(POST_USER_MENU_PERMISSIONS, {
          userId: this.userSelected.id,
          permissions: permissions,
        })
        .then(() => {
          this.makeToast("Success", "Permissions updated", "success");
          this.getData();
        })
        .catch(() => {});
    },
  },
};
</script>

<style></style>
