<!-- @format -->

<template>
  <div class="container">
    <b-form
      class="container"
      @submit.stop.prevent="form.id ? update() : save()"
    >
      <b-row>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group label-size="md">
            <template slot="label">
              Name <span class="text-danger">*</span></template
            >
            <b-form-input
              v-model.trim="$v.form.name.$model"
              size="md"
              name="name"
              maxlength="100"
              :value="form.companyName"
              :state="validateState('name')"
              @change="setValue('name', $event)"
            />
          </b-form-group>
          <b-form-invalid-feedback id="input-commercial-name-feedback">
            This field is required
          </b-form-invalid-feedback>
        </b-col>

        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group label-size="md">
            <template slot="label">
              Port <span class="text-danger">*</span></template
            >
            <b-form-input
              v-model.trim="$v.form.port.$model"
              size="md"
              name="name"
              maxlength="100"
              :value="form.port"
              :state="validateState('port')"
              @change="setValue('port', $event)"
            />
          </b-form-group>
          <b-form-invalid-feedback id="input-commercial-name-feedback">
            This field is required
          </b-form-invalid-feedback>
        </b-col>

        <b-col sm="12" md="12" lg="12" xl="12">
          <b-form-group label-size="md">
            <template slot="label"> Address </template>
            <b-form-input
              v-if="isAddressSelected"
              v-model="address"
              size="md"
              name="street"
              class="mb-3"
              maxlength="65"
              @focus="isAddressSelected = false"
            />
            <place-autocomplete-field
              v-else
              v-model="address"
              v-place-autofill:street="form.street"
              v-place-autofill:city="form.city"
              v-place-autofill:state="form.state"
              v-place-autofill:zipcode="form.zip"
              name="address"
              api-key="AIzaSyBWj-z1916MmpcimZW3xPlRGXMikForpS4"
              placeholder="Enter an an address, zipcode, or location"
              country="us"
              @autocomplete-select="selectAddress"
            />
          </b-form-group>
        </b-col>

        <b-col sm="12" md="12" lg="12" xl="12">
          <b-form-group label-size="md">
            <template slot="label"> Address line 2 </template>
            <b-form-input
              name="addressLine2"
              maxlength="200"
              :value="form.addressLine2"
              @change="setValue('addressLine2', $event)"
            />
          </b-form-group>
        </b-col>

        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="md">
            <template slot="label"> Street </template>
            <b-form-input
              v-model.trim="$v.form.street.$model"
              size="sm"
              name="street"
              maxlength="65"
              :value="form.street"
              :state="validateState('street')"
              @change="setValue('street', $event)"
            />
            <b-form-invalid-feedback>
              <div v-if="!$v.form.street.maxLength">
                Character limit reached
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="md">
            <template slot="label"> ZIP postal code </template>
            <b-form-input
              v-model.trim="$v.form.zip.$model"
              size="sm"
              name="zip"
              maxlength="10"
              :value="form.zip"
              :state="validateState('zip')"
              @change="setValue('zip', $event)"
            />
            <b-form-invalid-feedback>
              <div v-if="!$v.form.zip.maxLength">Character limit reached</div>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="md">
            <template slot="label"> State </template>
            <b-form-input
              v-model.trim="$v.form.state.$model"
              disabled
              size="sm"
              name="state"
              maxlength="250"
              :value="form.state"
              :state="validateState('state')"
              @change="setValue('stateId', $event)"
            />
          </b-form-group>
        </b-col>

        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="md">
            <template slot="label"> City </template>
            <b-form-input
              v-model.trim="$v.form.city.$model"
              disabled
              size="sm"
              name="city"
              maxlength="250"
              :value="form.city"
              :state="validateState('city')"
              @change="setValue('city', $event)"
            />
          </b-form-group>
        </b-col>

        <b-col sm="12" md="12" lg="12" xl="12" class="mb-4">
          <b-button
            v-if="userHasPermission('companies', 'write')"
            class="float-right"
            variant="success"
            type="submit"
            :disabled="$v.form.$invalid || isLoading"
          >
            <b-spinner v-if="isLoading" small variant="white" />
            <span v-else>Save</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { maxLength, required } from "vuelidate/lib/validators";
import { POST_RI } from "./actions";

import { mapState } from "vuex";
import { superAdminMixin } from "../../superAdmin/mixins";

export default {
  props: ["refreshData", "hideModal", "userItem"],
  mixins: [superAdminMixin],
  data() {
    return {
      isAddressSelected: false,
      address: null,
      form: {
        name: "",
        id: null,
        street: "",
        zip: "",
        state: "",
        city: "",
        addressLine2: "",
        port: "",
      },
      states: [],
      cities: [],
      options: {
        type: "tel",
        validCharactersOnly: true,
        maxlength: 18,
      },
      telDdOptions: {
        showDialCodeInList: true,
        showFlags: true,
        showDialCodeInSelection: false,
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      port: {
        required,
      },
      street: {
        maxLength: maxLength(250),
      },
      zip: {
        maxLength: maxLength(10),
      },
      state: {},
      city: {},
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.buyer.buyerStore.user,
      emailValidation: (state) => state.vehicle.clients.emailValidation.data,
      dealer: (state) => state.vehicle.clients.dealer,
    }),
    isLoading() {
      return this.dealer.status == "fetching" ? true : false;
    },
  },
  mounted() {
    if (this.userItem.id) {
      this.formatForm();
    }
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async save() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_RI, payload)
        .then(() => {
          this.$root.$bvToast.toast("Ri successfully registered", {
            title: "Success",
            variant: "success",
          });
          this.refreshData();
          this.hideModal();
        })
        .catch((error) => {
          this.$root.$bvToast.toast(
            error.data.message.charAt(0).toUpperCase() +
              error.data.message.slice(1),
            {
              title: "Error",
              variant: "warning",
            }
          );
        });
    },

    formatPayload() {
      const payload = {
        id: this.form.id ? this.form.id : null,
        port: this.form.port,
        name: this.form.name,
        street: this.form.street,
        zip: this.form.zip,
        state: this.form.state,
        city: this.form.city,
        addressLine2: this.form.addressLine2,
      };

      return payload;
    },
    selectAddress() {
      this.isAddressSelected = true;
    },
    formatForm() {
      this.form.id = this.userItem.id;
      this.form.name = this.userItem.name;
      this.form.port = this.userItem.port;
      this.form.street = this.userItem.street;
      this.form.zip = this.userItem.zip;
      this.form.state = this.userItem.state;
      this.form.city = this.userItem.city;
      this.form.addressLine2 = this.userItem.addressLine2;
    },
    async update() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_RI, payload)
        .then(() => {
          this.$root.$bvToast.toast("RI successfully updated", {
            title: "Success",
            variant: "success",
          });
          this.refreshData();
          this.hideModal();
        })
        .catch((error) => {
          this.$root.$bvToast.toast(
            error.data.messages.charAt(0).toUpperCase() +
              error.data.messages.slice(1),
            {
              title: "Error",
              variant: "warning",
            }
          );
        });
    },
  },
};
</script>

<style></style>
