import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    users: {
      status: null,
      data: [],
      error: null,
    },
    disableNotifications: {
      status: null,
      error: null,
    },
    notificationConfig: {
      status: null,
      data: null,
      error: null,
    },
  };
}

const actions = {
  [constants.GET_USERS_COMPANY]: async ({ commit }, params) => {
    try {
      commit(constants.GET_USERS_COMPANY);

      const response = await axios.get("company", { params: { ...params } });
      commit(constants.GET_USERS_COMPANY_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_USERS_COMPANY_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_DISABLE_NOTIFICATIONS]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_DISABLE_NOTIFICATIONS);
      const response = await axios.put("user/disable-notifications", payload);
      commit(constants.PUT_DISABLE_NOTIFICATIONS_SUCCESS);
      return response;
    } catch (error) {
      commit(constants.GET_USERS_COMPANY_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_NOTIFICATIONS_CONFIG]: async ({ commit }, params) => {
    try {
      commit(constants.GET_NOTIFICATIONS_CONFIG);

      const response = await axios.get("getUserNotificationValues", {
        params: { ...params },
      });
      commit(constants.GET_NOTIFICATIONS_CONFIG_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_NOTIFICATIONS_CONFIG_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_USERS_COMPANY]: (state) => {
    state.users.status = "fetching";
    state.users.error = null;
  },
  [constants.GET_USERS_COMPANY_SUCCESS]: (state, data) => {
    state.users.status = "success";
    state.users.error = null;
    state.users.data = data;
  },
  [constants.GET_USERS_COMPANY_ERROR]: (state, error) => {
    state.users.status = "error";
    state.users.error = error;
  },
  [constants.PUT_DISABLE_NOTIFICATIONS]: (state) => {
    state.disableNotifications.status = "fetching";
    state.disableNotifications.error = null;
  },
  [constants.PUT_DISABLE_NOTIFICATIONS_SUCCESS]: (state) => {
    state.disableNotifications.status = "success";
    state.disableNotifications.error = null;
  },
  [constants.PUT_DISABLE_NOTIFICATIONS_ERROR]: (state, error) => {
    state.disableNotifications.status = "error";
    state.disableNotifications.error = error;
  },

  [constants.GET_NOTIFICATIONS_CONFIG]: (state) => {
    state.notificationConfig.status = "fetching";
    state.notificationConfig.error = null;
  },
  [constants.GET_NOTIFICATIONS_CONFIG_SUCCESS]: (state, data) => {
    state.notificationConfig.status = "success";
    state.notificationConfig.error = null;
    state.notificationConfig.data = data;
  },
  [constants.GET_NOTIFICATIONS_CONFIG_ERROR]: (state, error) => {
    state.notificationConfig.status = "error";
    state.notificationConfig.error = error;
  },
  updateUser: (state, data) => {

    state.users.data.data[data.index][data.field] =
      data.value;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
