
import AuthLayout from '@/views/Pages/AuthLayout.vue';

import UserForm from "@/modules/buyer/registrationForm/UserForm.vue";
import CompanyForm from "@/modules/buyer/registrationForm/CompanyForm.vue";
import UserList from "@/modules/buyer/users/List.vue";
import SetPassword from "@/modules/buyer/users/SetPassword";
import { ifRoleAuthenticated } from "@/router/navigationGuards";

const routes = [
  {
    path: "/buyer",
    component: AuthLayout,
    children: [
      {
        path: "create",
        component: UserForm,
        name: "buyer.create",
      },
      {
        path: "company-create/:token",
        component: CompanyForm,
        name: "buyer.company-create",
      },

      {
        path: "users",
        name: "buyer.users",
        component: UserList,
        beforeEnter: (to, from, next) => {
          ifRoleAuthenticated(to, from, next, "ROLE_CLIENT");
        },
      },

      {
        path: "user/confirm/:token",
        name: "buyer.user.confirm",
        component: SetPassword,
      },
    ],
  },
];

export default routes;
