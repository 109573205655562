import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    agents: {
      status: null,
      data: {},
      error: null,
    },
    marketplaces: {
      status: null,
      data: {},
      error: null,
    },


  };
}
const actions = {
  [constants.GET_AGENTS_HISTORY]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_AGENTS_HISTORY);
      const response = await axios.get("vehicle/getStockHistory", {
        params: payload,
      });
      commit(constants.GET_AGENTS_HISTORY_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_AGENTS_HISTORY_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_MARKETPLACE_HISTORY]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_MARKETPLACE_HISTORY);
      const response = await axios.get("marketPlaces/getHistory", {
        params: payload,
      });
      commit(constants.GET_MARKETPLACE_HISTORY_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_MARKETPLACE_HISTORY_ERROR, error);
      throw error.response;
    }
  },


};

const mutations = {
  [constants.GET_AGENTS_HISTORY]: (state) => {
    state.agents.data = [];
    state.agents.status = "fetching";
  },
  [constants.GET_AGENTS_HISTORY_SUCCESS]: (state, response) => {
    state.agents.status = "success";
    state.agents.data = response;
  },
  [constants.GET_AGENTS_HISTORY_ERROR]: (state, error) => {
    state.agents.status = "error";
    state.agents.error = error;
  },
  [constants.GET_MARKETPLACE_HISTORY]: (state) => {
    state.marketplaces.data = [];
    state.marketplaces.status = "fetching";
  },
  [constants.GET_MARKETPLACE_HISTORY_SUCCESS]: (state, response) => {
    state.marketplaces.status = "success";
    state.marketplaces.data = response;
  },
  [constants.GET_MARKETPLACE_HISTORY_ERROR]: (state, error) => {
    state.marketplaces.status = "error";
    state.marketplaces.error = error;
  },



};
export default {
  state: getDefaultState(),
  actions,
  mutations,
};
