<template>
  <!-- Card stats -->
  <b-row>
    <b-col xl="3" md="6">
      <stats-card class="mb-4">
        <template slot>
          <div class="d-flex justify-content-between">
            <h5 class="card-title text-uppercase text-muted mb-0">7501</h5>
            <div class="text-center">
              <i
                v-b-tooltip.click="{ variant: 'white' }"
                title="Warning within 6-11 days, Alert more than 11 days
               "
              >
                <font-awesome-icon
                  class="cursor-pointer text-blue"
                  :icon="['far', 'circle-question']"
                />
              </i>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <b-row>
              <b-col
                cols="auto"
                @click="applyFilter(form7501Warning)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-yellow"
                  :icon="['far', 'triangle-exclamation']"
                  size="2xl"
                />

                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">
                  {{ exportStore != null ? exportStore.form7501Warning : 0 }}
                </span>
              </b-col>
              <b-col
                cols="auto"
                @click="applyFilter(form7501Alert)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-red"
                  :icon="['far', 'octagon-exclamation']"
                  size="2xl"
                />
                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">{{
                  exportStore != null ? exportStore.form7501Alert : 0
                }}</span>
              </b-col>
            </b-row>
          </div>
        </template>
      </stats-card>
    </b-col>
    <b-col xl="3" md="6">
      <stats-card class="mb-4">
        <template slot>
          <div class="d-flex justify-content-between">
            <h5 class="card-title text-uppercase text-muted mb-0">Title</h5>
            <div class="text-center">
              <i
                v-b-tooltip.click="{ variant: 'white' }"
                title="Warning 10-13 days after 7501 stamp, Alert 14 days or more after 7501 stamp date

               "
              >
                <font-awesome-icon
                  class="cursor-pointer text-blue"
                  :icon="['far', 'circle-question']"
                />
              </i>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <b-row>
              <b-col
                cols="auto"
                @click="applyFilter(titleWarning)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-yellow"
                  :icon="['far', 'triangle-exclamation']"
                  size="2xl"
                />

                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">
                  {{ exportStore != null ? exportStore.titleWarning : 0 }}</span
                >
              </b-col>
              <b-col
                cols="auto"
                @click="applyFilter(titleAlert)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-red"
                  :icon="['far', 'octagon-exclamation']"
                  size="2xl"
                />
                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">
                  {{ exportStore != null ? exportStore.titleAlert : 0 }}</span
                >
              </b-col>
            </b-row>
          </div>
        </template>
      </stats-card>
    </b-col>

    <b-col xl="3" md="6">
      <stats-card class="mb-4">
        <template slot>
          <div class="d-flex justify-content-between">
            <h5 class="card-title text-uppercase text-muted mb-0">
              Title after
            </h5>
            <div class="text-center">
              <i
                v-b-tooltip.click="{ variant: 'white' }"
                title="Warning 0-5 after sold, Alert 6 or more
               "
              >
                <font-awesome-icon
                  class="cursor-pointer text-blue"
                  :icon="['far', 'circle-question']"
                />
              </i>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <b-row>
              <b-col
                cols="auto"
                @click="applyFilter(titleAfterWarning)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-yellow"
                  :icon="['far', 'triangle-exclamation']"
                  size="2xl"
                />

                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">
                  {{
                    exportStore != null ? exportStore.titleAfterWarning : 0
                  }}</span
                >
              </b-col>
              <b-col
                cols="auto"
                @click="applyFilter(titleAfterAlert)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-red"
                  :icon="['far', 'octagon-exclamation']"
                  size="2xl"
                />
                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">
                  {{
                    exportStore != null ? exportStore.titleAfterAlert : 0
                  }}</span
                >
              </b-col>
            </b-row>
          </div>
        </template>
      </stats-card>
    </b-col>
    <b-col xl="3" md="6">
      <stats-card class="mb-4">
        <template slot>
          <div class="d-flex justify-content-between">
            <h5 class="card-title text-uppercase text-muted mb-0">
              Released vehicles
            </h5>
            <div class="text-center">
              <i
                v-b-tooltip.click="{ variant: 'white' }"
                title="Warning within 6 days (after or before today), Alert more than 6 days ago
               "
              >
                <font-awesome-icon
                  class="cursor-pointer text-blue"
                  :icon="['far', 'circle-question']"
                />
              </i>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <b-row>
              <b-col
                cols="auto"
                @click="applyFilter(releasedVehiclesWarning)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-yellow"
                  :icon="['far', 'triangle-exclamation']"
                  size="2xl"
                />

                <span class="h1 font-weight-bold mb-0 align-middle">
                  {{
                    exportStore != null
                      ? exportStore.releasedVehiclesWarning
                      : 0
                  }}</span
                >
              </b-col>

              <b-col
                cols="auto"
                @click="applyFilter(releasedVehiclesAlert)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-red"
                  :icon="['far', 'octagon-exclamation']"
                  size="2xl"
                />
                <span class="h1 font-weight-bold mb-0 align-middle">
                  {{
                    exportStore != null ? exportStore.releasedVehiclesAlert : 0
                  }}</span
                >
              </b-col>
            </b-row>
          </div>
        </template>
        <template slot="footer">
          <span class="text-nowrap"></span>
        </template>
      </stats-card>
    </b-col>
    <!--     <b-col xl="3" md="6">
      <stats-card class="mb-4">
        <template slot>
          <div class="d-flex justify-content-between">
            <h5 class="card-title text-uppercase text-muted mb-0">
              On the road
            </h5>
            <div class="text-center">
              <i
                v-b-tooltip.click="{ variant: 'white' }"
                title="Vehicles with an export load active
               "
              >
                <font-awesome-icon
                  class="cursor-pointer text-blue"
                  :icon="['far', 'circle-question']"
                />
              </i>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <b-row @click="applyFilter(onTheRoad)" class="cursor-pointer">
              <b-col cols="auto">
                <font-awesome-icon
                  class="cursor-pointer text-orange"
                  :icon="['far', 'truck-container']"
                  size="2xl"
                />

                <span class="h1 font-weight-bold mb-0 align-middle">
                  {{ exportStore != null ? exportStore.onTheRoad : 0 }}</span
                ></b-col
              >
            </b-row>
          </div>
        </template>
        <template slot="footer">
          <span class="text-nowrap"></span>
        </template>
      </stats-card>
    </b-col>
 -->
    <b-col xl="3" md="6">
      <stats-card class="mb-4">
        <template slot>
          <div class="d-flex justify-content-between">
            <h5 class="card-title text-uppercase text-muted mb-0">
              Ready to sell
            </h5>
            <div class="text-center">
              <i
                v-b-tooltip.click="{ variant: 'white' }"
                title="Warning within 4 before release date or 8 days after, Alert more than 8 days after release date"
              >
                <font-awesome-icon
                  class="cursor-pointer text-blue"
                  :icon="['far', 'circle-question']"
                />
              </i>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <b-row>
              <b-col
                cols="auto"
                @click="applyFilter(readyToSellWarning)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-yellow"
                  :icon="['far', 'triangle-exclamation']"
                  size="2xl"
                />

                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">
                  {{
                    exportStore != null ? exportStore.readyToSellWarning : 0
                  }}</span
                >
              </b-col>
              <b-col
                cols="auto"
                @click="applyFilter(readyToSellAlert)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-red"
                  :icon="['far', 'octagon-exclamation']"
                  size="2xl"
                />
                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">
                  {{
                    exportStore != null ? exportStore.readyToSellAlert : 0
                  }}</span
                >
              </b-col>
            </b-row>
          </div>
        </template>
      </stats-card>
    </b-col>

    <b-col xl="3" md="6">
      <stats-card class="mb-4">
        <template slot>
          <div class="d-flex justify-content-between">
            <h5 class="card-title text-uppercase text-muted mb-0">Boston</h5>
            <div class="text-center">
              <i v-b-tooltip.click="{ variant: 'white' }" id="boston-tooltip"
                ><!-- todo -->
                <font-awesome-icon
                  class="cursor-pointer text-blue"
                  :icon="['far', 'circle-question']"
                />
              </i>

              <b-tooltip target="boston-tooltip" placement="top">
                <span class="text-success">Green</span>: All conditions are
                satisfied, and the vehicle is fully ready for export. <br />
                <span class="text-yellow">Yellow</span>: Vehicle is pending
                Visual Inspection (V.I.). <br />
                <span class="text-red">Red</span>: Vehicle is missing
                registration, has a lien, or has issues with Carfax, Recall,
                Work Orders, or Cluster.
              </b-tooltip>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <b-row>
              <b-col
                cols="auto"
                @click="applyFilter(bostonGreen)"
                class="cursor-pointer"
                ><!-- todo  -->
                <font-awesome-icon
                  class="cursor-pointer text-success"
                  :icon="['far', 'circle-check']"
                  size="2xl"
                />

                <span class="h2 font-weight-bold mb-0 align-middle">
                  {{ exportStore != null ? exportStore.bostonGreen : 0 }}</span
                >
              </b-col>
              <b-col
                cols="auto"
                @click="applyFilter(bostonYellow)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-yellow"
                  :icon="['far', 'triangle-exclamation']"
                  size="2xl"
                />

                <span class="h2 font-weight-bold mb-0 align-middle">
                  {{ exportStore != null ? exportStore.bostonYellow : 0 }}</span
                >
              </b-col>
              <b-col
                cols="auto"
                @click="applyFilter(bostonRed)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-red"
                  :icon="['far', 'octagon-exclamation']"
                  size="2xl"
                />
                <span class="h2 font-weight-bold mb-0 align-middle">
                  {{ exportStore != null ? exportStore.bostonRed : 0 }}</span
                >
              </b-col>
            </b-row>
          </div>
        </template>
      </stats-card>
    </b-col>

    <b-col xl="3" md="6">
      <stats-card class="mb-4">
        <template slot>
          <div class="d-flex justify-content-between">
            <h5 class="card-title text-uppercase text-muted mb-0">New York</h5>
            <div class="text-center">
              <i v-b-tooltip.click="{ variant: 'white' }" id="new-york-tooltip"
                ><!-- todo -->
                <font-awesome-icon
                  class="cursor-pointer text-blue"
                  :icon="['far', 'circle-question']"
                />
              </i>
              <b-tooltip target="new-york-tooltip" placement="top">
                <span class="text-success">Green</span>: All conditions are
                satisfied, and the vehicle is fully ready for export. <br />
                <span class="text-yellow">Yellow</span>: Vehicle is pending
                Visual Inspection (V.I.).<br />
                <span class="text-red">Red</span>: Vehicle is missing
                registration, has a lien, or has issues with Carfax, Recall,
                Work Orders, or Cluster.
              </b-tooltip>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <b-row>
              <b-col
                cols="auto"
                @click="applyFilter(newYorkGreen)"
                class="cursor-pointer"
                ><!-- todo  -->
                <font-awesome-icon
                  class="cursor-pointer text-success"
                  :icon="['far', 'circle-check']"
                  size="2xl"
                />

                <span class="h2 font-weight-bold mb-0 align-middle">
                  {{ exportStore != null ? exportStore.nyGreen : 0 }}</span
                >
              </b-col>
              <b-col
                cols="auto"
                @click="applyFilter(newYorkYellow)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-yellow"
                  :icon="['far', 'triangle-exclamation']"
                  size="2xl"
                />

                <span class="h2 font-weight-bold mb-0 align-middle">
                  {{ exportStore != null ? exportStore.nyYellow : 0 }}</span
                >
              </b-col>
              <b-col
                cols="auto"
                @click="applyFilter(newYorkRed)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-red"
                  :icon="['far', 'octagon-exclamation']"
                  size="2xl"
                />
                <span class="h2 font-weight-bold mb-0 align-middle">
                  {{ exportStore != null ? exportStore.nyRed : 0 }}</span
                >
              </b-col>
            </b-row>
          </div>
        </template>
      </stats-card>
    </b-col>

    <b-col xl="3" md="6">
      <stats-card class="mb-4">
        <template slot>
          <div class="d-flex justify-content-between">
            <h5 class="card-title text-uppercase text-muted mb-0">Michigan</h5>
            <div class="text-center">
              <i v-b-tooltip.click="{ variant: 'white' }" id="michigan-tooltip"
                ><!-- todo -->
                <font-awesome-icon
                  class="cursor-pointer text-blue"
                  :icon="['far', 'circle-question']"
                />
              </i>

              <b-tooltip target="michigan-tooltip" placement="top">
                <span class="text-success">Green</span>: All conditions are
                satisfied, and the vehicle is fully ready for export. <br />
                <span class="text-yellow">Yellow</span>: Vehicle is pending
                Visual Inspection (V.I.).<br />
                <span class="text-red">Red</span>: Vehicle is missing
                registration, has a lien, or has issues with Carfax, Recall,
                Work Orders, or Cluster.
              </b-tooltip>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <b-row>
              <b-col
                cols="auto"
                @click="applyFilter(michiganGreen)"
                class="cursor-pointer"
                ><!-- todo  -->
                <font-awesome-icon
                  class="cursor-pointer text-success"
                  :icon="['far', 'circle-check']"
                  size="2xl"
                />

                <span class="h2 font-weight-bold mb-0 align-middle">
                  {{
                    exportStore != null ? exportStore.michiganGreen : 0
                  }}</span
                >
              </b-col>
              <b-col
                cols="auto"
                @click="applyFilter(michiganYellow)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-yellow"
                  :icon="['far', 'triangle-exclamation']"
                  size="2xl"
                />

                <span class="h2 font-weight-bold mb-0 align-middle">
                  {{
                    exportStore != null ? exportStore.michiganYellow : 0
                  }}</span
                >
              </b-col>
              <b-col
                cols="auto"
                @click="applyFilter(michiganRed)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-red"
                  :icon="['far', 'octagon-exclamation']"
                  size="2xl"
                />
                <span class="h2 font-weight-bold mb-0 align-middle">
                  {{ exportStore != null ? exportStore.michiganRed : 0 }}</span
                >
              </b-col>
            </b-row>
          </div>
        </template>
      </stats-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from "vuex";
import { SET_VEHICLES_INFO_FILTERS } from "@/modules/superAdmin/actions";
import { superAdminMixin } from "../../modules/superAdmin/mixins";
import { GET_BOARD_EXPORT } from "./actions";
export default {
  name: "BoardCardsExport",
  mixins: [superAdminMixin],
  computed: {
    ...mapState({
      exportStore: (state) => state.layout.boards.export?.data,
    }),
  },
  data() {
    return {
      form7501Warning: {
        form7501: 1,
        exportLoadFrom: this.getDateXDaysAgo(10),
        exportLoadTo: this.getDateXDaysAgo(5),
      },
      form7501Alert: {
        form7501: 1,
        exportLoadTo: this.getDateXDaysAgo(10),
      },
      titleWarning: {
        title: 1,
        titleFrom: this.getDateXDaysAgo(13),
        titleTo: this.getDateXDaysAgo(10),
      },

      titleAlert: {
        title: 1,
        titleTo: this.getDateXDaysAgo(14),
      },
      releasedVehiclesWarning: {
        releasedWarning: 1,
      },
      releasedVehiclesAlert: {
        releasedAlert: 1,
      },

      onTheRoad: {
        exportOnTheWay: 1,
      },
      titleAfterWarning: {
        titleAfterWarning: 1,
        marketPlace: 1,
      },
      titleAfterAlert: {
        titleAfterAlert: 1,
        marketPlace: 1,
      },
      readyToSellWarning: {
        readyToSellWarning: 1,
        status: "InStock",
        reserve: 0,
      },
      readyToSellAlert: {
        readyToSellAlert: 1,
        status: "InStock",
        reserve: 0,
      },
      michiganRed: {
        exportLoadRed: 1,
        exportLocation: 1,
        status: "InStock",
      },
      michiganYellow: {
        exportLoadYellow: 1,
        exportLocation: 1,
        status: "InStock",
      },
      michiganGreen: {
        exportLoadGreen: 1,
        exportLocation: 1,
        status: "InStock",
      },
      newYorkRed: {
        exportLoadRed: 1,
        exportLocation: 2,
        status: "InStock",
      },
      newYorkYellow: {
        exportLoadYellow: 1,
        exportLocation: 2,
        status: "InStock",
      },
      newYorkGreen: {
        exportLoadGreen: 1,
        exportLocation: 2,
        status: "InStock",
      },
      bostonRed: {
        exportLoadRed: 1,
        exportLocation: 7,
        status: "InStock",
      },
      bostonYellow: {
        exportLoadYellow: 1,
        exportLocation: 7,
        status: "InStock",
      },
      bostonGreen: {
        exportLoadGreen: 1,
        exportLocation: 7,
        status: "InStock",
      },
    };
  },
  mounted() {
    this.getLogisticInformation();
  },
  methods: {
    applyFilter(filters) {
      this.resetFilters(); //Method from superAdminMixin
      this.$store.commit(SET_VEHICLES_INFO_FILTERS, {
        fields: { ...filters },
      });
      this.getVehiclesInfo(); //Method from superAdminMixin
    },
    getDateXDaysAgo(numOfDays, date = new Date()) {
      const daysAgo = new Date(date);
      daysAgo.setDate(date.getDate() - numOfDays);
      return this.formatDateString(daysAgo.toString());
    },
    getDateXDaysAfter(numOfDays, date = new Date()) {
      const daysAgo = new Date(date);
      daysAgo.setDate(date.getDate() + numOfDays);
      return this.formatDateString(daysAgo.toString());
    },
    getLogisticInformation() {
      this.$store.dispatch(GET_BOARD_EXPORT).then(() => {});
    },
    formatDateString(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const shortDate = `${year}-${month}-${day}`;
      return shortDate;
    },
  },
};
</script>

<style></style>
