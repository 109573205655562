var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',[_vm._v("Agents commissions")]),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6","lg":"3"}},[_c('MarketplaceSelect',{attrs:{"options":_vm.marketplaces,"modelValue":_vm.marketPlace},on:{"onMarketplaceSelect":function($event){return _vm.setMarketplace($event)}}})],1)],1),_c('b-table',{staticClass:"mt-3",attrs:{"show-empty":"","bordered":"","small":"","stacked":"md","head-variant":"light","responsive":"","items":_vm.agentsCommissions,"fields":_vm.fields,"busy":_vm.isAgentsLoading},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"variant":"yellow"}})],1)]},proxy:true},{key:"cell(pad)",fn:function({ item }){return [_c('div',{staticClass:"text-center"},[_c('Money',_vm._b({staticClass:"form-control text-center",attrs:{"value":item.agentConfiguration ? item.agentConfiguration.pad : 0},nativeOn:{"blur":function($event){return _vm.updateAgentCommission(item, 'pad', $event.target.value)}}},'Money',_vm.money,false))],1)]}},{key:"cell(minimalProfit)",fn:function({ item }){return [_c('div',{staticClass:"text-center"},[_c('Money',_vm._b({staticClass:"form-control text-center",attrs:{"value":item.agentConfiguration
              ? item.agentConfiguration.minimalProfit
              : 0},nativeOn:{"blur":function($event){return _vm.updateAgentCommission(item, 'minimalProfit', $event.target.value)}}},'Money',_vm.money,false))],1)]}},{key:"cell(loss)",fn:function({ item }){return [_c('div',{staticClass:"text-center"},[_c('b-input-group',{attrs:{"append":"%"}},[_c('b-form-input',{attrs:{"value":item.agentConfiguration ? item.agentConfiguration.loss : 0,"type":"number","min":"0","max":"100","step":"0.1"},on:{"change":function($event){return _vm.updateAgentCommission(item, 'loss', $event)}}})],1)],1)]}},{key:"cell(profit)",fn:function({ item }){return [_c('div',{staticClass:"text-center"},[_c('b-input-group',{attrs:{"append":"%"}},[_c('b-form-input',{attrs:{"value":item.agentConfiguration ? item.agentConfiguration.profit : 0,"type":"number","min":"0","max":"100","step":"0.1"},on:{"change":function($event){return _vm.updateAgentCommission(item, 'profit', $event)}}})],1)],1)]}},{key:"cell(investmentLimit)",fn:function({ item }){return [_c('div',{staticClass:"text-center"},[_c('Money',_vm._b({staticClass:"form-control text-center",attrs:{"value":item.agentConfiguration && item.agentConfiguration.investmentLimit
              ? item.agentConfiguration.investmentLimit
              : 0},nativeOn:{"blur":function($event){return _vm.updateAgentCommission(
              item,
              'investmentLimit',
              $event.target.value
            )}}},'Money',_vm.money,false))],1)]}},{key:"cell(toleranceDays)",fn:function({ item }){return [_c('div',{staticClass:"text-center"},[_c('b-form-input',{attrs:{"value":item.agentConfiguration
              ? item.agentConfiguration.toleranceDays
              : 0,"type":"number","min":"0","max":"100"},on:{"change":function($event){return _vm.updateAgentCommission(item, 'toleranceDays', $event)}}})],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }