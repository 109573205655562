export const GET_USERS_COMPANY = 'GET_USERS_COMPANY';
export const GET_USERS_COMPANY_SUCCESS = 'POST_USER_SUCCESS';
export const GET_USERS_COMPANY_ERROR = 'POST_USER_ERROR';

export const SET_USERS_COMPANY_FILTERS = 'SER_USERS_COMPANY_FILTERS';

export const PUT_DISABLE_NOTIFICATIONS = 'PUT_DISABLE_NOTIFICATIONS';
export const PUT_DISABLE_NOTIFICATIONS_SUCCESS =
  'PUT_DISABLE_NOTIFICATIONS_SUCCESS';
export const PUT_DISABLE_NOTIFICATIONS_ERROR =
  'PUT_DISABLE_NOTIFICATIONS_ERROR';

export const GET_NOTIFICATIONS_CONFIG = 'GET_NOTIFICATIONS_CONFIG ';
export const GET_NOTIFICATIONS_CONFIG_SUCCESS =
  'GET_NOTIFICATIONS_CONFIG_SUCCESS';
export const GET_NOTIFICATIONS_CONFIG_ERROR = 'GET_NOTIFICATIONS_CONFIG_ERROR';
