<template>
  <div>
    <FilterTypeDate fromType="stockDateFrom" toType="stockDateTo" />
  </div>
</template>

<script>
import FilterTypeDate from "./FilterTypeDate.vue";

export default {
  name: "StockDaysFilter",
  components: { FilterTypeDate },
};
</script>

<style>
</style>