<template>
  <div class="container">
    <b-form @submit.stop.prevent="form.id ? update() : save()">
      <h3 class="text-purple-yellow">
        <b-icon icon="geo-alt" scale="1.2" />
      </h3>
      <b-row>
        <!--  Name -->
        <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
          <b-form-group label-size="sm">
            <template slot="label">
              Name <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="name-input"
              v-model.trim="$v.form.name.$model"
              size="sm"
              name="name-input"
              :value="form.name"
              :state="validateState('name')"
              aria-describedby="input-name-live-feedback"
              maxlength="100"
              @change="setValue('name', $event)"
            />
            <b-form-invalid-feedback id="input-name-live-feedback">
              This is a required field and must at least 2 characters.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <!-- Email -->
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group label-size="sm">
            <template slot="label">
              Email <span class="text-danger"> *</span>
            </template>
            <b-form-input
              id="email-input"
              v-model.trim="$v.form.email.$model"
              size="sm"
              name="email-input"
              :formatter="lowerCaseFormatter"
              :value="form.email"
              :state="validateState('email')"
              aria-describedby="input-email-live-feedback"
              maxlength="255"
              @change="setValue('email', $event)"
            />
            <b-form-invalid-feedback id="input-email-live-feedback">
              This is a required field and must be a valid email.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <!--  Address -->
        <b-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
          <b-form-group label-size="md">
            <template slot="label">
              Address
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              v-if="isAddressSelected"
              v-model="form.address"
              size="md"
              name="street"
              class="mb-3"
              maxlength="65"
              autocomplete="off"
              @focus="isAddressSelected = false"
            />
            <place-autocomplete-field
              v-else
              v-model="form.address"
              v-place-autofill:street="form.street"
              v-place-autofill:city="form.city"
              v-place-autofill:state="form.state"
              v-place-autofill:zipcode="form.zip"
              name="address"
              autocomplete="off"
              api-key="AIzaSyBWj-z1916MmpcimZW3xPlRGXMikForpS4"
              placeholder="Enter an an address, zipcode, or location"
              country="us"
              @autocomplete-select="selectAddress"
            />
          </b-form-group>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
          <b-form-group label-size="sm">
            <template slot="label">
              Link <span class="text-danger">*</span>
            </template>
            <b-form-input
              v-model.trim="$v.form.link.$model"
              size="sm"
              :value="form.link"
              :state="validateState('link')"
              aria-describedby="input-name-live-feedback"
              @change="setValue('link', $event)"
            />
            <b-form-invalid-feedback id="input-name-live-feedback">
              This is a required field and must at least 2 characters.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <!-- Mobile phone -->

        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group label-size="sm" label="Mobile phone">
            <template slot="label"> Phone number </template>
            <vue-tel-input
              v-model.trim="form.phoneNumber"
              default-country="us"
              :input-options="options"
              :dropdown-options="telDdOptions"
              :auto-format="true"
              :valid-characters-only="true"
              :value="form.phoneNumber"
              mode="international"
              style-classes="z-index-2"
            />
            <b-form-invalid-feedback id="input-mobile-phone-live-feedback">
              This is a numeric field
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row />

      <b-row>
        <b-col sm="12" md="6" lg="6" xl="6" />
        <b-col sm="12" md="3" lg="3" xl="3" />
        <b-col sm="12" md="3" lg="3" xl="3">
          <b-btn
            v-if="userHasPermission('exportLocations', 'write')"
            block
            size="lg"
            class="btn btn-success"
            :disabled="$v.form.$invalid"
            type="submit"
          >
            Submit
            <b-spinner
              v-if="exportLocation.status == 'fetching'"
              small
              variant="white"
            />
          </b-btn>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { required, minLength, email } from "vuelidate/lib/validators";
import { POST_EXPORT_LOCATION } from "./actions";
import { mapState } from "vuex";
import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../mixins";

export default {
  mixins: [generalMixin, superAdminMixin],
  props: ["exportLocationItem", "refreshData", "hideModal"],
  computed: {
    ...mapState({
      exportLocation: (state) =>
        state.superAdminStore.exportLocation.exportLocation,
    }),
  },

  data() {
    return {
      form: {
        id: null,
        name: null,
        email: null,
        phoneNumber: null,
        address: null,
        link: null,
        city: null,
        state: null,
        zip: null,
        street: null,
      },
      isAddressSelected: false,
      options: {
        type: "tel",
        validCharactersOnly: true,
        maxlength: 14,
      },
      telDdOptions: {
        showDialCodeInList: true,
        showFlags: true,
        showDialCodeInSelection: false,
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(2),
      },
      email: {
        email,
      },
      address: {
        required,
      },
      link: {
        required,
      },
      phoneNumber: {},
    },
  },
  mounted() {
    if (this.exportLocationItem.id) {
      this.formatForm();
    }
  },
  methods: {
    closeModal(callback) {
      callback();
    },
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    save() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_EXPORT_LOCATION, payload)
        .then(() => {
          this.makeToast(
            "Success",
            "Export location created successfully",
            "success"
          );
          this.refreshData();
          this.hideModal();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Internal server error, try again later", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    update() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_EXPORT_LOCATION, payload)
        .then(() => {
          this.makeToast(
            "Success",
            "Export location updated successfully",
            "success"
          );
          this.refreshData();
          this.hideModal();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Internal server error, try again later", {
            title: "Warning",
            variant: "warning",
          });
        });
    },

    formatPayload() {
      const payload = {
        id: this.exportLocationItem.id ? this.exportLocationItem.id : null,
        name: this.form.name,
        email: this.form.email,
        phoneNumber: this.form.phoneNumber,
        address: this.form.address,
        link: this.form.link,
        city: this.form.city,
        state: this.form.state,
        zip: this.form.zip,
        street: this.form.street,
      };

      return payload;
    },

    formatForm() {
      this.form.id = this.exportLocationItem.id;
      this.form.name = this.exportLocationItem.name;
      this.form.email = this.exportLocationItem.email;
      this.form.phoneNumber = this.exportLocationItem.phoneNumber;
      this.form.address = this.exportLocationItem.address;
      this.form.link = this.exportLocationItem.link;

      this.isAddressSelected = true;
    },
    selectAddress() {
      this.isAddressSelected = true;
    },
  },
};
</script>

<style scoped>
.append-input {
  max-width: 40%;
}
</style>
