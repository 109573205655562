import { POST_VEHICLE_BY_STOCK } from "./actions";
import { mapState } from "vuex";

const vehicleMixin = {
  computed: {
    ...mapState({
      vehicle: (state) => state.vehicle.vehicleByVin.data.data,
    }),
  },

  data() {
    return {};
  },

  methods: {
    searchVinNumber(stock) {
      this.$store
        .dispatch(POST_VEHICLE_BY_STOCK, { stockNumber: stock })
        .then(() => {
          //this.formatForm();
        })
        .catch((error) => {
          console.log("vehicle mixin", error);
          this.toastMessage(
            "The vehicle could not be obtain",
            "Warning",
            "warning"
          );
        });
    },
  },
};
export { vehicleMixin };
