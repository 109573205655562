<template>
  <b-form>
    <b-form-group label="Description">
      <b-form-textarea
        v-model.trim="$v.form.description.$model"
        class="resize-none"
        :state="validateState('description')"
        aria-describedby="input-description-live-feedback"
        @change="setValue('description', $event)"
      />
      <b-form-invalid-feedback id="input-description-live-feedback">
        This is a required field.
      </b-form-invalid-feedback>
    </b-form-group>
    <b-row>
      <b-col xs="12" sm="12" md="6" lg="6">
        <b-form-group class="mt-2">
          <template slot="label">
            <div>File</div>
          </template>
          <b-input-group>
            <b-form-file
              v-model="form.file"
              accept=".pdf"
              aria-describedby="input-file-live-feedback"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6">
        <b-row class="mt-2">
          <b-col cols="11" class="mr-0">
            <b-form-group label="Quittance">
              <b-input-group>
                <b-form-file id="file-quittance" v-model="form.quittanceFile" />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="1" class="pl-0">
            <b-form-checkbox
              id="checkbox-1"
              v-model="form.quittanceCheck"
              name="checkbox-1"
              :unchecked-value="0"
              :value="1"
              class="mt-5"
            >
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-form-group label="Note">
      <b-form-textarea
        v-model.trim="$v.form.note.$model"
        class="resize-none"
        :state="validateState('note')"
        aria-describedby="input-description-live-feedback"
        @change="setValue('note', $event)"
      />
    </b-form-group>

    <div class="text-right mt-2">
      <b-button
        :disabled="$v.form.$invalid || lien === 'fetching'"
        variant="primary"
        @click="addRecall()"
      >
        <b-spinner v-if="lien === 'fetching'" variant="white" small />
        <span v-else>Submit</span>
      </b-button>
    </div>
  </b-form>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import { POST_LIEN } from "./actions";
import { superAdminMixin } from "../../mixins";
export default {
  mixins: [superAdminMixin],
  props: ["vehicle", "refreshData"],

  data() {
    return {
      form: {
        description: null,
        vehicleId: null,
        file: null,
        note: null,
        quittanceCheck: 0,
        quittanceFile: null,
      },
    };
  },
  computed: {
    ...mapState({
      lien: (state) => state.superAdminStore.componentStore.liens.lien.status,
    }),
  },
  validations: {
    form: {
      description: {
        required,
      },
      note: {},
    },
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form.description = null;
      this.form.vehicleId = null;
      this.form.note = null;
      this.form.file = null;
      this.form.quittanceCheck = 0;
      this.form.quittanceFile = null;
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    addRecall() {
      if (this.$v.form.$invalid) return;
      this.form.vehicleId = this.vehicle.id;
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_LIEN, payload)
        .then(() => {
          this.resetForm();
          this.refreshData();
          this.toastMessage("Lien saved", "Success", "success");
        })
        .catch((error) => {
          console.log(error);
          this.toastMessage("Lien could not saved", "Warning", "warning");
        });
    },
    formatPayload() {
      const data = new FormData();
      data.set("description", this.form.description);
      data.set("vehicleId", this.form.vehicleId);
      data.set("file", this.form.file);
      data.set("note", this.form.note);
      data.set("quittanceCheck", this.form.quittanceCheck);
      data.set("quittanceFile", this.form.quittanceFile);
      return data;
    },
  },
};
</script>

<style></style>
