<template>
  <div>
    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12" md="8">
          <VehicleCard></VehicleCard>
        </b-col>
        <b-col xl="12" md="4" class="mt-4">
          <VehicleResume />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import VehicleResume from "./UserProfile/VehicleResume.vue";
import VehicleCard from "./UserProfile/VehicleCard.vue";

export default {
  components: {
    VehicleResume,
    VehicleCard,
  },
};
</script>
<style>
</style>
