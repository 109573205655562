<template>
  <div>
    <b-row class="justify-content-md-center">
      <b-col sm="4" md="2" lg="2" xl="2" class="py-2">
        <b-button
          variant="yellow"
          class="text-white"
          size="lg"
          block
          @click="
            option = 'payments';
            openModal('checks-modal', 'payments');
          "
        >
          Payments
          <font-awesome-icon
            class="icon-color cursor-pointer"
            :icon="['far', 'money-check-dollar']"
            :style="{ color: 'white' }"
          />
        </b-button>
      </b-col>
      <b-col sm="4" md="2" lg="2" xl="2" class="py-2">
        <b-button
          variant="dark-green"
          size="lg"
          block
          @click="
            option = 'carfax';

            openModal('carfax-modal', 'carfax');
          "
        >
          Carfax

          <img
            src="@/assets/svg/CF_grey.svg"
            class="img-responsive"
            style="max-width: 20px"
          />
        </b-button>
      </b-col>
      <!--       <b-col sm="4" md="2" lg="2" xl="2" class="py-2">
        <b-button
          variant="yellow"
          class="text-white"
          size="lg"
          block
          @click="option = 'trims'"
        >
          Trims
          <font-awesome-icon
            class="icon-color cursor-pointer"
            :icon="['far', 'cars']"
            :style="{ color: 'white' }"
          />
        </b-button>
      </b-col> -->
      <b-col sm="4" md="2" lg="2" xl="2" class="py-2">
        <b-button
          variant="yellow"
          class="text-white"
          size="lg"
          block
          @click="
            option = 'registration';
            openModal('registration-modal', 'registration');
          "
        >
          Regi
          <font-awesome-icon
            class="icon-color cursor-pointer"
            :icon="['far', 'file-magnifying-glass']"
            :style="{ color: 'white' }"
          />
        </b-button>
      </b-col>
      <b-col sm="4" md="2" lg="2" xl="2" class="py-2">
        <b-button
          variant="dark-green"
          class="text-white"
          size="lg"
          block
          @click="
            option = 'recalls';
            openModal('recall-modal', 'recall');
          "
        >
          Recalls
          <font-awesome-icon
            class="icon-color cursor-pointer"
            :icon="['far', 'car-circle-bolt']"
            :style="{ color: 'white' }"
          />
        </b-button>
      </b-col>
      <b-col sm="4" md="2" lg="2" xl="2" class="py-2">
        <b-button
          variant="yellow"
          class="text-white"
          size="lg"
          block
          @click="
            option = 'liens';
            openModal('lien-modal', 'liens');
          "
        >
          Liens
          <font-awesome-icon
            class="icon-color cursor-pointer"
            :icon="['far', 'file-slash']"
            :style="{ color: 'white' }"
          />
        </b-button>
      </b-col>
    </b-row>
    <div class="container mt-5 w-100">
      <CarsTable v-if="option === 'trims'" />
    </div>
    <CarfaxModal />
    <ChecksModal />
    <RegistrationModal />
    <RecallModal />
    <LienModal />
  </div>
</template>

<script>
import CarfaxModal from "@/modules/superAdmin/components/carfax/CarfaxModal";
import ChecksModal from "@/modules/superAdmin/components/checks/ChecksModal";
import CarsTable from "@/modules/vehicle/components/CarsTable";
import RegistrationModal from "@/modules/superAdmin/components/registration/RegistrationModal.vue";
import RecallModal from "@/modules/superAdmin/components/recall/RecallModal";
import LienModal from "@/modules/superAdmin/components/liens/LienModal";
import { vehicleMixin } from "../mixin";
import { superAdminMixin } from "../../superAdmin/mixins";
export default {
  components: {
    ChecksModal,
    CarsTable,
    RegistrationModal,
    RecallModal,
    LienModal,
    CarfaxModal,
  },
  mixins: [vehicleMixin, superAdminMixin],
  data() {
    return {
      option: null,
    };
  },
  methods: {
    openModal(modal, module) {
      this.$store.commit("SET_VEHICLE_INFO", this.vehicle);
      if (this.userHasPermission(module, "read")) {
        this.$bvModal.show(modal);
      } else {
        this.toastMessage(
          "You don't have permission to perform this action",
          "Warning",
          "warning"
        );
      }
    },
  },
};
</script>

<style></style>
