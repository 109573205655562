<template>
  <b-modal
    id="addons-modal"
    ref="modal"
    modal-class="modal-fullscreen"
    centered
    scrollable
    size="lg"
    title="Addons"
    @hide="getVehiclesInfo()"
    hide-footer
  >
    <VehicleDetails
      v-if="vehicle"
      :vehicle="vehicle"
      :color="vehicle.vehicleInformation.color || '----'"
    />
    <div class="d-flex justify-content-around h5">
      <span
        >Purchase price:
        <i>{{ addons.startingPrice ? addons.startingPrice : 0 | currency }}</i></span
      >
      <span>
        Addons:
        <i>{{ addons.totalAddons ? addons.totalAddons : 0 | currency }}</i>
      </span>
      <span>
        Total: <i>{{ addons.total ? addons.total : 0 | currency }}</i>
      </span>
    </div>

    <AddonsForm ref="AddonsForm" v-if="vehicle" :vehicle="vehicle" class="mt-2" />
    <b-tabs content-class="mt-3">
      <b-tab title=" Addons" active>
        <AddonsList
          v-if="vehicle"
          :vehicle="vehicle"
          class="mt-2"
          @edit-addon="editAddon"
          type="type"
      /></b-tab>
      <b-tab title="Missing type">
        <AddonsList
          v-if="vehicle"
          :vehicle="vehicle"
          class="mt-2"
          @edit-addon="editAddon"
          type="noType"
      /></b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import VehicleDetails from "@/modules/superAdmin/components/VehicleDetails.vue";
import AddonsForm from "./AddonsForm.vue";
import AddonsList from "./AddonsList.vue";

import { superAdminMixin } from "../../mixins";

export default {
  name: "AddonsModal",
  components: { VehicleDetails, AddonsForm, AddonsList },
  mixins: [superAdminMixin],
  computed: {
    ...mapState({
      addons: (state) => state.superAdminStore.componentStore.addons.addons.data,
      vehicle: (state) => state.superAdminStore.vehicle,
    }),
  },

  methods: {
    editAddon(addon) {
      this.$refs.AddonsForm.setAddonToEdit(addon);
    },
  },
};
</script>

<style scoped></style>
