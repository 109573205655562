<template>
  <b-form @submit.stop.prevent="form.id ? update() : save()">
    <b-row>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-button
          :disabled="transfer.status === 'fetching'"
          variant="yellow"
          class="text-white my-1 px-5 mt-3 mx-2"
          @click="registerAutomaticTransfer('sale')"
          type="button"
        >
          <b-spinner
            v-if="transfer.status === 'fetching'"
            variant="white"
            small
          />
          <span v-else> Drop off </span>
        </b-button>
        <b-button
          :disabled="transfer.status === 'fetching'"
          variant="yellow"
          class="text-white my-1 px-5 mt-3"
          @click="registerAutomaticTransfer('purchase')"
          type="button"
        >
          <b-spinner
            v-if="transfer.status === 'fetching'"
            variant="white"
            small
          />
          <span v-else> Pickup </span>
        </b-button>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label-size="md">
          <template slot="label"> Kilometers </template>
          <b-input-group>
            <b-form-input
              v-model="form.kms"
              type="number"
              min="0"
              size="sm"
              :disabled="!isKilometersEditing"
            />

            <template #append>
              <div class="mx-1">
                <b-button
                  v-if="isKilometersEditing"
                  class="mr-2"
                  variant="light"
                  size="sm"
                  @click="isKilometersEditing = false"
                >
                  <b-icon icon="x" />
                </b-button>
                <b-button
                  v-if="isKilometersEditing"
                  class="mr-2"
                  variant="light"
                  size="sm"
                  @click="updateKilometers()"
                >
                  Save
                </b-button>
                <b-button
                  v-if="!isKilometersEditing"
                  class="mr-2"
                  variant="light"
                  size="sm"
                  @click="isKilometersEditing = true"
                >
                  <b-icon icon="pencil" />
                </b-button>
              </div>
            </template>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <!-- Origin-->
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <b-row>
          <b-col cols="11" class="mr-0">
            <b-form-group label-size="md">
              <template slot="label">
                Origin <span class="text-danger">*</span>
              </template>
              <vue-single-select
                :classes="{ input: 'form-control', dropdown: 'dropdown' }"
                v-model="origin"
                :options="origins"
                optionLabel="description"
              >
              </vue-single-select>
              <b-form-input
                class="mt-3"
                id="firstName-input"
                v-model.trim="$v.form.originAddress.$model"
                size="md"
                placeholder="Origin address"
                :value="form.originAddress"
                :state="validateState('originAddress')"
                aria-describedby="input-firstName-live-feedback"
                maxlength="100"
                @change="setValue('originAddress', $event)"
              />
              <b-form-invalid-feedback id="input-firstName-live-feedback">
                This is a required field and must at least 2 characters.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="1" class="pl-0">
            <b-icon
              icon="person-plus-fill"
              scale="1.5"
              class="ml-2 mt-4 mt-5"
              @click="showModal()"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12"> </b-col>

      <!-- Destination-->
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <b-form-group label-size="sm">
          <template slot="label">
            Destination <span class="text-danger">*</span>
          </template>
          <vue-single-select
            :classes="{ input: 'form-control', dropdown: 'dropdown' }"
            v-model="destination"
            :options="destinations"
            optionLabel="description"
          >
          </vue-single-select>
          <b-form-input
            class="mt-3"
            id="firstName-input"
            v-model.trim="$v.form.destinationAddress.$model"
            size="md"
            :value="form.destinationAddress"
            :state="validateState('destinationAddress')"
            aria-describedby="input-firstName-live-feedback"
            maxlength="100"
            @change="setValue('destinationAddress', $event)"
          />
          <b-form-invalid-feedback id="input-firstName-live-feedback">
            This is a required field and must at least 2 characters.
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <!-- CArrier -->
      <b-col sm="12" md="12" lg="6" xl="6">
        <b-form-group label-size="md">
          <template slot="label">
            <b-form-radio-group
              id="radio-group-2"
              v-model="typeCarrier"
              name="radio-sub-component"
            >
              <b-form-radio value="supplier">Provider</b-form-radio>
              <b-form-radio value="driver"> Driver </b-form-radio>
              <b-form-radio value="customer"> Customer </b-form-radio>
            </b-form-radio-group>
          </template>

          <vue-single-select
            v-if="typeCarrier == 'supplier'"
            :classes="{ input: 'form-control', dropdown: 'dropdown' }"
            v-model="form.supplier"
            :options="providers"
            optionLabel="companyName"
          ></vue-single-select>

          <vue-single-select
            v-if="typeCarrier == 'driver'"
            :classes="{ input: 'form-control', dropdown: 'dropdown' }"
            v-model="form.driver"
            :options="drivers"
            :getOptionDescription="() => driverName"
          >
            <template slot="option" slot-scope="{ option }">
              <div>{{ option.firstName }} {{ option.lastName }}</div>
            </template>
          </vue-single-select>
        </b-form-group>
      </b-col>

      <!-- Type -->
      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <div class="form-group">
          <label for="">Type</label>
          <b-form-select v-model="form.type" :options="types"></b-form-select>
        </div>
      </b-col>

      <!-- cost -->
      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <b-form-group>
          <template slot="label"> Cost </template>
          <Money
            v-model="form.cost"
            class="form-control text-center"
            v-bind="money"
          />
        </b-form-group>
      </b-col>

      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label-size="sm">
          <template> Estimated arrival date</template>
          <input
            v-model="form.deliveryDate"
            type="datetime-local"
            class="form-control"
          />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <b-form-group label="Note">
          <b-form-textarea v-model="form.note" class="resize-none" />
        </b-form-group>
      </b-col>
    </b-row>

    <div class="text-right mt-2">
      <div class="row" align-h="end">
        <b-col>
          <b-form-checkbox
            id="checkbox-1"
            v-model="form.consignment"
            name="checkbox-1"
            class="mx-2 mt-2"
            :value="true"
            :unchecked-value="false"
            :disabled="typeCarrier == 'customer'"
          >
            Consignment
          </b-form-checkbox>
        </b-col>
        <b-col cols="auto">
          <b-button
            :disabled="$v.form.$invalid || transfer.status === 'fetching'"
            variant="primary"
            type="submit"
          >
            <b-spinner
              v-if="transfer.status === 'fetching'"
              variant="white"
              small
            />
            <span v-else>Submit</span>
          </b-button>
        </b-col>
      </div>
    </div>
    <b-modal
      :id="'clientModal' + type"
      ref="clientModal"
      size="lg"
      hide-footer
      body-class="pl-0 pr-0"
      ok-disabled
    >
      <ClientForm :refresh-data="getOrigins" :hide-modal="hideModal" />
    </b-modal>
    <TaskUpdateStatusModal
      :tasks="tasks"
      :id="1"
      @update-task="refreshAfterUpdateTask"
    />
  </b-form>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import {
  POST_TRANSFER,
  GET_ORIGINS,
  POST_QUICK_TRANSFER,
  PUT_KILOMETERS,
} from "./actions";
import { superAdminMixin } from "../../mixins";
import VueSingleSelect from "vue-single-select";
import ClientForm from "@/modules/vehicle/components/Clients/Form.vue";
import TaskUpdateStatusModal from "../../tasks/components/TaskUpdateStatusModal.vue";

import { Money } from "v-money";

export default {
  mixins: [superAdminMixin],
  props: ["vehicle", "refreshData"],
  components: {
    VueSingleSelect,
    Money,
    ClientForm,
    TaskUpdateStatusModal,
  },
  data() {
    return {
      providers: [],
      drivers: [],
      typeCarrier: "supplier",
      origin: null,
      destination: null,

      form: {
        originAddress: null,
        destinationAddress: null,
        vehicleId: null,
        supplier: null,
        driver: null,
        type: null,
        cost: 0,
        deliveryDate: null,
        kms: this.vehicle.vehicleInformation.kms,
        destinationName: null,
        destinationStreet: null,
        destinationZip: null,
        destinationCity: null,
        destinationState: null,
        consignment: false,
        note: null,
      },
      origins: [],
      destinations: [],

      types: [
        { value: null, text: "Please select an option" },
        {
          value: "Internal pick-up transfer",
          text: "Internal pick-up transfer",
        },
        {
          value: "Internal delivery transfer",
          text: "Internal delivery transfer",
        },
        {
          value: "Work order delivery transfer",
          text: "Work order delivery transfer",
        },
        {
          value: "Work order pickup transfer",
          text: "Work order pickup transfer",
        },
      ],
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "USD$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      isKilometersEditing: false,
      type: "origin",
      tasks: [],
    };
  },
  computed: {
    ...mapState({
      recall: (state) =>
        state.superAdminStore.componentStore.recall.recall.status,
      transfer: (state) =>
        state.superAdminStore.componentStore.transfer.transfer,
    }),

    driverName() {
      if (this.form.driver) {
        return `${this.form.driver.firstName} ${this.form.driver.lastName}`;
      }
      return "";
    },
    originName() {
      if (this.origin) {
        return `${this.origin.companyName}, ${this.origin.name}`;
      }
      return "";
    },
    destinationName() {
      if (this.destination) {
        return `${this.destination.companyName}, ${this.destination.name}`;
      }
      return "";
    },
  },
  watch: {
    origin: function () {
      this.onChangeOrigin();
    },
    destination: function () {
      this.onChangeDestination();
    },
  },
  validations: {
    form: {
      originAddress: {
        required,
      },
      destinationAddress: {
        required,
      },
    },
  },

  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form.description = null;
      this.form.vehicleId = null;
      this.form.consignment = false;
      this.form.cost = 0;
      this.form.note = null;
      this.form.originAddress = null;
      this.form.destinationAddress = null;
      this.form.vehicleId = null;
      this.form.supplier = null;
      this.form.driver = null;
      this.form.type = null;
      this.form.cost = 0;
      this.form.deliveryDate = null;
      this.form.kms = this.vehicle.vehicleInformation.kms;
      this.form.destinationName = null;
      this.form.destinationStreet = null;
      this.form.destinationZip = null;
      this.form.destinationCity = null;
      this.form.destinationState = null;
      this.form.consignment = false;
      this.form.note = null;
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    getSuppliers() {
      this.$store
        .dispatch("GET_COMPANIES_LIST", { isSupplier: 1 })
        .then((response) => {
          this.providers = response;
        })
        .catch(() => {
          this.$root.$bvToast.toast("Could not get the providers list ", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    getDrivers() {
      this.$store
        .dispatch("GET_DRIVER_LIST_NO_PAGINATE")
        .then((response) => {
          this.drivers = response.data;
        })
        .catch(() => {});
    },
    onChangeOrigin() {
      this.form.originAddress = null;
      if (!this.origin) {
        return;
      }
      this.form.originAddress = this.origin?.companyName;
      if (this.origin.street) {
        this.form.originAddress += `, ${this.origin.street}`;
      }
      if (this.origin.zip) {
        this.form.originAddress += `, ${this.origin.zip}`;
      }
      if (this.origin.city) {
        this.form.originAddress += `, ${this.origin.city}`;
      }
    },
    onChangeDestination() {
      this.form.destinationAddress = null;
      if (!this.destination) {
        return;
      }
      this.form.destinationAddress = this.destination.companyName;
      if (this.destination.street) {
        this.form.destinationAddress += `, ${this.destination.street}`;
      }
      if (this.destination.zip) {
        this.form.destinationAddress += `, ${this.destination.zip}`;
      }
      if (this.destination.city) {
        this.form.destinationAddress += `, ${this.destination.city}`;
      }
      this.form.destinationName = this.destination.companyName;
      this.form.destinationStreet = this.destination.street;
      this.form.destinationZip = this.destination.zip;
      this.form.destinationCity = this.destination.city;
      this.form.destinationState = this.destination.state;
      this.form.companyId = this.destination.companyId
        ? this.destination.companyId
        : null;
    },

    formatPayload() {
      const payload = {
        originId: this.origin.id,
        destinationId: this.destination.id,
        companyName: this.destination.companyName,
        companyId: this.destination.companyId,

        origin: this.form.originAddress,
        destination: this.form.destinationAddress,
        vehicleId: this.vehicle.id,
        type: this.form.type,
        cost: this.form.cost,
        deliveryDate: this.form.deliveryDate,
        kms: this.form.kms,
        destinationName: this.form.destinationName,
        destinationStreet: this.form.destinationStreet,
        destinationZip: this.form.destinationZip,
        destinationCity: this.form.destinationCity,
        destinationState: this.form.destinationState,
        consignment: this.form.consignment,
        note: this.form.note,
        noteType: "transfer",
      };
      if (this.form.supplier != null) {
        payload.supplierCompanyId = this.form.supplier.id;
      }

      if (this.form.driver != null) {
        payload.driverId = this.form.driver.id;
      }
      if (this.typeCarrier == "customer") {
        payload.customer = true;
      }
      return payload;
    },

    async save() {
      if (this.vehicle.published == 1) {
        if (!(await this.confirmTransferOfCarInMarketplace())) return;
      }
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_TRANSFER, payload)
        .then((response) => {
          if (response.task.length > 0) {
            this.tasks = response.task;
            this.$root.$bvModal.show("task-update-status-modal" + 1);
          } else {
            this.refreshData();
          }

          this.resetForm();
          this.toastMessage("Transfer saved", "Success", "success");
        })
        .catch((error) => {
          this.toastMessage(
            error.data.message.charAt(0).toUpperCase() +
              error.data.message.slice(1),
            "Warning",
            "warning"
          );
        });
    },
    update() {},

    getOrigins(search = "") {
      this.$store
        .dispatch(GET_ORIGINS, { search: search })
        .then((response) => {
          this.origins = response;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDestinations(search) {
      this.$store.dispatch(GET_ORIGINS, { search: search }).then((response) => {
        this.destinations = response;
      });
    },
    registerAutomaticTransfer(action) {
      const payload = {
        vehicleId: this.vehicle.id,
        action: action,
      };
      this.$store.dispatch(POST_QUICK_TRANSFER, payload).then(() => {
        this.refreshData();
        this.toastMessage("Transfer saved", "Success", "success");
      });
    },
    updateKilometers() {
      const payload = {
        vehicleId: this.vehicle.id,
        kms: this.form.kms,
      };
      this.$store.dispatch(PUT_KILOMETERS, payload).then(() => {
        this.refreshData();
        this.isKilometersEditing = false;
        this.toastMessage("Kilometers updated", "Success", "success");
      });
    },
    hideModal() {
      this.$bvModal.hide("clientModal" + this.type);
    },
    showModal() {
      this.$bvModal.show("clientModal" + this.type);
    },
    confirmTransferOfCarInMarketplace() {
      return this.$bvModal.msgBoxConfirm(
        "Do you want to remove from Consignment Canamcar?",
        {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: "YES",
          cancelTitle: "NO",
          cancelVariant: "danger",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        }
      );
    },
    refreshAfterUpdateTask() {
      this.refreshData();
    },
  },
  mounted() {
    this.getSuppliers();
    this.getDrivers();
    this.getOrigins("");
    this.getDestinations("");
  },
};
</script>

<style></style>
