import VehiclePage from '@/modules/vehicle/vehiclePage.vue';
import RegisterVehicle from '@/modules/vehicle/RegisterVehicle.vue';
import InitialInformation from '@/modules/vehicle/components/InitialInformation.vue';
import visualiInspectionRoutes from '@/modules/vehicle/components/visual-inspection/routes.js';
import workOrder from '@/modules/vehicle/components/work-orders/routes.js';
import { ifRoleAuthenticated } from '@/router/navigationGuards';
import DashboardLayout from '@/layouts/DashboardLayout.vue';

const routes = [
  {
    path: '',
    name: 'vehicleMain',
    component: DashboardLayout,
    children: [
      {
        path: '/vehicle/:stock',
        name: 'vehicle',
        component: VehiclePage,
        meta: { name: 'Vehicle' },

        beforeEnter: (to, from, next) => {
          ifRoleAuthenticated(to, from, next, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_AGENT", "ROLE_EXTERNAL_AGENT"]);
        }
      },
      {
        path: '/vehicle-search',
        name: 'vehicle.search',
        component: RegisterVehicle,
        meta: { layout: 'base' },
        beforeEnter: (to, from, next) => {
          ifRoleAuthenticated(to, from, next, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_AGENT", "ROLE_EXTERNAL_AGENT"]);
        }
      },
      {
        path: '/vehicle-search/stock/:stock',
        name: 'vehicle.search.stock',
        component: InitialInformation,
        meta: { name: 'Vehicle' },
        beforeEnter: (to, from, next) => {
          ifRoleAuthenticated(to, from, next, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_AGENT", "ROLE_EXTERNAL_AGENT"]);
        },
      },
      { ...visualiInspectionRoutes },

    ]

  },
  { ...workOrder }

];

export default routes;
