<template>
  <div>
    <b-form
      v-if="!isLoading"
      @submit.stop.prevent="form.id ? update() : save()"
    >
      <b-row>
        <b-col sm="12" md="12" lg="12" xl="12">
          <b-form-group>
            <template slot="label"> Vehicle </template>
            <multiselect
              v-model="form.vehicle"
              :options="vehicles"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="false"
              placeholder="Pick some option"
              label="text"
              track-by="text"
              :preselect-first="false"
            >
              <template slot="tag" slot-scope="{ option, remove }"
                ><span class="custom__tag"
                  ><span class="">{{ option.stockNumber }}</span
                  ><span class="ml-1 custom__remove" @click="remove(option)"
                    >❌</span
                  ></span
                ></template
              >
            </multiselect>
          </b-form-group>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group label-size="md">
            <template slot="label"> Reserve to </template>
            <vue-single-select
              :classes="{ input: 'form-control', dropdown: 'dropdown' }"
              v-model="form.company"
              :options="companies"
              optionLabel="companyName"
            ></vue-single-select>
          </b-form-group>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group label-size="md">
            <template slot="label"> Currency </template>

            <b-form-select
              v-model.trim="form.typeCurrency"
              size="md"
              name="typeCurrency"
              :options="typesOfCurrency"
            />
          </b-form-group>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <b-form-group>
            <template slot="label"> Cost </template>
            <Money
              v-model="form.price"
              class="form-control text-center"
              v-bind="money"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <div class="row" align-h="end">
        <b-col cols="12">
          <b-button
            :disabled="reserve.status == 'fetching'"
            variant="primary"
            class="float-right"
            type="submit"
          >
            <b-spinner
              v-if="reserve.status == 'fetching'"
              variant="white"
              small
            />
            <span v-else>Submit</span>
          </b-button>

          <b-button
            v-if="form.id"
            variant="yellow"
            class="btn float-right mx-3"
            @click="deleteData()"
            :disabled="reserve.status == 'fetching'"
          >
            <b-spinner
              v-if="reserve.status == 'fetching'"
              variant="white"
              small />
            <b-icon
              v-else
              color="white"
              scale="1.3"
              icon="trash"
              class="cursor-pointer"
              v-b-tooltip.hover
              title="Delete"
            ></b-icon
          ></b-button>
        </b-col>
      </div>
    </b-form>
    <div v-else class="text-center">
      <b-spinner class="my-5" label="Loading..."></b-spinner>
    </div>
  </div>
</template>

<script>
import { Money } from "v-money";
import { mapState } from "vuex";
import VueSingleSelect from "vue-single-select";
import Multiselect from "vue-multiselect";
import { GET_VEHICLES_NO_RESERVE } from "./actions";

export default {
  components: { Money, VueSingleSelect, Multiselect },
  props: {
    reserveItem: {
      type: Object,
      default: () => {},
    },
    refreshData: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      reserve: (state) => state.superAdminStore.componentStore.reserve.reserve,
    }),
    isLoading() {
      return this.vehicles.length == 0 || this.companies.length == 0;
    },
  },

  data() {
    return {
      form: {
        id: null,
        typeCurrency: null,
        price: 0,
        company: null,
        vehicle: null,
      },
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      companies: [],
      typesOfCurrency: [
        { value: "USD", text: "USD" },
        { value: "CAD", text: "CAD" },
      ],
      vehicles: [],
    };
  },
  watch: {
    isLoading(value) {
      if (!value) {
        this.formatForm();
      }
    },
  },
  created() {
    this.getCompanies();
    this.getVehicles();
  },

  methods: {
    getCompanies() {
      this.$store
        .dispatch("GET_CLIENTS")
        .then((response) => {
          this.companies = response;
        })
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    deleteData() {
      this.$store
        .dispatch("DELETE_RESERVE", { id: this.form.id })
        .then(() => {
          this.$root.$bvToast.toast("Reserve deleted successfully", {
            title: "Success",
            variant: "success",
          });
          this.refreshData();
          this.hideModal();
          this.resetForm();
        })
        .catch(() => {
          this.$root.$bvToast.toast(
            "We can't delete the data, please try again later",

            {
              title: "Error",
              variant: "warning",
            }
          );
        });
    },
    save() {
      const payload = this.formatPayload();
      this.$store
        .dispatch("POST_RESERVE", payload)
        .then(() => {
          this.$root.$bvToast.toast("Reserve registered successfully", {
            title: "Success",
            variant: "success",
          });
          this.refreshData();
          this.resetForm();
          this.hideModal();
        })
        .catch((error) => {
          this.$root.$bvToast.toast(
            error.data.messages.charAt(0).toUpperCase() +
              error.data.messages.slice(1),
            {
              title: "Error",
              variant: "warning",
            }
          );
        });
    },
    update() {
      const payload = this.formatPayload();
      this.$store
        .dispatch("POST_RESERVE", payload)
        .then(() => {
          this.$root.$bvToast.toast("Reserve updated successfully", {
            title: "Success",
            variant: "success",
          });
          this.refreshData();
          this.resetForm();
          this.hideModal();
        })
        .catch((error) => {
          this.$root.$bvToast.toast(
            error.data.message.charAt(0).toUpperCase() +
              error.data.message.slice(1),
            {
              title: "Error",
              variant: "warning",
            }
          );
        });
    },
    formatPayload() {
      const payload = {
        id: this.form.id ?? null,
        typeCurrency: this.form.typeCurrency,
        price: this.form.price,
        vehiclesId: this.form.vehicle.id,
        companiesId: this.form.company.id,
      };
      return payload;
    },
    setClient(company) {
      if (company) {
        this.form.company = company;
      }
    },
    formatForm() {
      if (!this.reserveItem) return;
      this.form.id = this.reserveItem?.reserves?.id;
      this.form.typeCurrency = this.reserveItem.reserves.typeCurrency;
      this.form.price = this.reserveItem.reserves.price;
      this.form.company = this.companies.find(
        (company) => company.id == this.reserveItem.reserves.companiesId
      );
      this.form.vehicle = this.vehicles.find(
        (vehicle) => vehicle.id == this.reserveItem.id
      );
    },
    resetForm() {
      this.form.id = null;
      this.form.typeCurrency = null;
      this.form.price = 0;
      this.form.company = null;
    },
    hideModal() {
      this.$bvModal.hide("reserve-form-modal");
    },
    getVehicles() {
      this.$store
        .dispatch(GET_VEHICLES_NO_RESERVE, { id: this.reserveItem?.id })
        .then((response) => {
          this.vehicles = response;
        });
    },
  },
};
</script>

<style></style>
