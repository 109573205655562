import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    checks: {
      status: null,
      data: [],
      error: null,
    },
    checksDelivered: {
      status: null,
      data: {},
      error: null,
    },
  };
}

const actions = {
  [constants.GET_CONFIRMATION_CHECKS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_CONFIRMATION_CHECKS);
      const response = await axios.get("payment/getPayments", {
        params: payload,
      });
      commit(constants.GET_CONFIRMATION_CHECKS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_CONFIRMATION_CHECKS_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_CONFIRMATION_CHECKS]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_CONFIRMATION_CHECKS);
      const response = await axios.put(
        "payment/validateMultiplePayments",
        payload
      );
      commit(constants.PUT_CONFIRMATION_CHECKS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_CONFIRMATION_CHECKS_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_CONFIRMATION_CHECKS]: (state) => {
    state.checks.status = "fetching";
    state.checks.error = null;
  },
  [constants.GET_CONFIRMATION_CHECKS_SUCCESS]: (state, data) => {
    state.checks.status = "success";
    state.checks.error = null;
    state.checks.data = data;
  },
  [constants.GET_CONFIRMATION_CHECKS_ERROR]: (state, error) => {
    state.checks.status = "error";
    state.checks.error = error;
  },
  [constants.PUT_CONFIRMATION_CHECKS]: (state) => {
    state.checksDelivered.status = "fetching";
    state.checksDelivered.error = null;
  },
  [constants.PUT_CONFIRMATION_CHECKS_SUCCESS]: (state, data) => {
    state.checksDelivered.status = "success";
    state.checksDelivered.error = null;
    state.checksDelivered.data = data;
  },
  [constants.PUT_CONFIRMATION_CHECKS_ERROR]: (state, error) => {
    state.checksDelivered.status = "error";
    state.checksDelivered.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
