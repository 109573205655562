<template>
  <div>
    <b-row>
      <b-col cols="10">
        <span>
          Registered date:
          <i>{{ addon.createdAt | formatDateTime }} </i>
        </span>
      </b-col>
      <b-col cols="2" class="text-center">
        <b-button
          class="mr-2"
          variant="light"
          v-b-tooltip.hover
          title="Edit"
          size="sm"
          @click="editAddon()"
        >
          <b-icon icon="pencil" />
        </b-button>
        <b-button
          :disabled="isLoading"
          size="sm"
          variant="yellow"
          v-b-tooltip.hover
          title="Delete"
          @click="deleteAddon(addon)"
        >
          <b-icon icon="trash" class="cursor-pointer"> </b-icon>
        </b-button>
      </b-col>
      <b-col cols="12">
        <span v-if="addon.addonType"
          >Addon type: <i>{{ addon.addonType.name }}</i>
        </span>
        <span v-else>
          Txt id: <i>{{ addon.txtId || "-----" }}</i> <br />
          Txt document: <i>{{ addon.txtDocument || "-----" }}</i>
        </span>
        <br />
        <span
          >Service provider:
          <i>{{ addon.company ? addon.company.companyName : "-----" }}</i>
        </span>
        <br />
        <span
          >Currency registered: <i>{{ addon.currency }}</i></span
        >
        <br />
        <span
          >Total amount CAD: <i>{{ addon.totalAmountCAD | currency }}</i></span
        >
        <b-icon
          v-if="!showBreakdown && addon.taxes.length > 0"
          icon="arrow-down-circle"
          class="cursor-pointer ml-1"
          @click="toggleShowBreakdown(addon, 'CAD')"
        ></b-icon>
        <b-icon
          v-else-if="showBreakdown && addonSelected.type == 'CAD'"
          icon="arrow-up-circle"
          class="cursor-pointer ml-1"
          @click="toggleShowBreakdown(addon, 'CAD')"
        ></b-icon>
        <AddonsListItemBreakdown
          v-if="
            showBreakdown && addonSelected.id == addon.id && addonSelected.type == 'CAD'
          "
          :amount="addon.amountCAD"
          :addon="addon"
        />
        <br />
        <span
          >Total amount USD: <i>{{ addon.totalAmountUSD | currency }}</i>
        </span>
        <b-icon
          v-if="!showBreakdown && addon.taxes.length > 0"
          icon="arrow-down-circle"
          class="cursor-pointer ml-1"
          @click="toggleShowBreakdown(addon, 'USD')"
        ></b-icon>
        <b-icon
          v-else-if="showBreakdown && addonSelected.type == 'USD'"
          icon="arrow-up-circle"
          class="cursor-pointer ml-1"
          @click="toggleShowBreakdown(addon, 'USD')"
        ></b-icon>
        <AddonsListItemBreakdown
          v-if="
            showBreakdown && addonSelected.id == addon.id && addonSelected.type == 'USD'
          "
          :amount="addon.amountUSD"
          :addon="addon"
        />
        <br />
      </b-col>
    </b-row>
    <p class="text-justify mb-1">
      <span>Description:</span> <br />
      <i> {{ addon.description }}</i>
    </p>

    <br /><br />
  </div>
</template>

<script>
import { generalMixin } from "../../../mixin";
import { superAdminMixin } from "../../mixins";
import { DELETE_ADDON } from "./actions";
import AddonsListItemBreakdown from "./AddonsListItemBreakdown.vue";
export default {
  props: {
    addon: {
      type: Object,
      required: true,
    },
    refreshData: {
      type: Function,
      required: true,
    },
  },
  components: { AddonsListItemBreakdown },
  mixins: [generalMixin, superAdminMixin],

  data() {
    return {
      isLoading: false,
      showBreakdown: false,
      addonSelected: null,
    };
  },

  methods: {
    async deleteAddon(addon) {
      const confirm = await this.showConfirmDeleteBox();
      if (!confirm) return;
      const payload = {
        id: addon.id,
      };
      this.isLoading = true;
      this.$store
        .dispatch(DELETE_ADDON, payload)
        .then(() => {
          this.refreshData();
          this.$root.$bvToast.toast("Addon deleted successfully", {
            title: "Success",
            variant: "success",
          });
        })
        .catch((error) => {
          this.$root.$bvToast.toast(
            error.data.messages.charAt(0).toUpperCase() + error.data.messages.slice(1),
            {
              title: "Error",
              variant: "warning",
            }
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    toggleShowBreakdown(addon, type) {
      this.addonSelected = addon;
      this.addonSelected.type = type;
      this.showBreakdown = !this.showBreakdown;
    },
    editAddon() {
      /* scroll to form */
      const element = document.getElementById("addonsForm");
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      this.$emit("edit-addon", this.addon);
    },
  },
};
</script>

<style scoped></style>
