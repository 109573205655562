<template>
  <div class="container-fluid mt-3">
    <b-row class="">
      <b-col lg="4">
        <UserSelect
          @user-select="setUserSelect($event)"
          :userSelected="userSelected"
        />
      </b-col>
      <b-col>
        <b-button
          v-if="userHasPermission('permissions', 'write')"
          @click="updatePermissions()"
          :disabled="
            taskTypesStatus === 'fetching' ||
            taskTypesByUser.status === 'fetching'
          "
          variant="outline-success"
          >Update types</b-button
        ></b-col
      >
    </b-row>
    <div class="pt-4">
      <div class="">
        <b-table
          show-empty
          bordered
          small
          stacked="md"
          head-variant="light"
          :items="taskTypesFormatted"
          :fields="fields"
          :busy="taskTypesStatus === 'fetching'"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" variant="yellow" />
            </div>
          </template>
          <template #cell(read)="{ item }">
            <div class="d-flex justify-content-center">
              <b-form-checkbox
                :checked="item.isActive ? true : false"
                @change="(val) => onRowSelected(val, item)"
              />
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { generalMixin } from "../../../mixin";
import { superAdminMixin } from "../../mixins";
import UserSelect from "./UserSelect.vue";
import { mapState } from "vuex";
import { PUT_TASK_TYPE_BY_USER, GET_TASK_TYPES } from "../actions";
export default {
  name: "AssignTypeByUserView",
  components: {
    UserSelect,
  },
  mixins: [generalMixin, superAdminMixin],
  data() {
    return {
      fields: [
        { key: "name", label: "Name" },
        { key: "read", label: "Assigned" },
      ],
      userSelected: null,
      taskTypesList: [],
    };
  },
  computed: {
    ...mapState({
      taskTypes: (state) => state.superAdminStore.tasks.taskTypes.data.data,
      taskTypesStatus: (state) => state.superAdminStore.tasks.taskTypes.status,
      taskTypesByUser: (state) => state.superAdminStore.tasks.taskTypeByUser,
    }),
    taskTypesFormatted() {
      return this.taskTypesList;
    },
  },
  mounted() {
    this.$store.commit("setAddFunction", null);
    this.$store.commit("setSearchFunction", null);
    this.getTaskTypes();
  },
  methods: {
    getTaskTypes() {
      this.$store
        .dispatch(GET_TASK_TYPES)
        .then(() => {})
        .catch(() => {});
    },
    setUserSelect(user) {
      this.userSelected = user;
      this.taskTypesList = [...this.taskTypes];
      this.resetPermissions();
      this.setPermissionsByUser();
    },
    onRowSelected(val, item) {
      item.isActive = val;
      const index = this.taskTypesList.findIndex(
        (taskType) => taskType.name === item.name
      );
      this.taskTypesList[index] = item;
    },
    formatPermissions() {
      this.taskTypesList = [];
      let taskTypesList = [...this.taskTypes];
      taskTypesList.forEach((taskType) => {
        taskType.isActive = false;
      });
      return taskTypesList;
    },
    setPermissionsByUser() {
      this.taskTypesList.forEach((taskType) => {
        const index = this.userSelected.taskTypes.findIndex(
          (userPermission) => userPermission.name === taskType.name
        );
        if (index !== -1) {
          taskType.isActive =
            this.userSelected.taskTypes[index].name == taskType.name; // ? true : false;
        }
      });
      this.taskTypesList = [...this.taskTypesList];
    },
    resetPermissions() {
      this.getTaskTypes();

      this.taskTypesList.forEach((taskType) => {
        taskType.isActive = false;
      });
      this.taskTypesList = [...this.taskTypesList];
    },
    updatePermissions() {
      const taskTypes = this.taskTypesList.filter(
        (taskType) => taskType.isActive
      );
      this.$store
        .dispatch(PUT_TASK_TYPE_BY_USER, {
          userId: this.userSelected.id,
          taskTypes: taskTypes,
        })
        .then(() => {
          this.$children[0].getUsersTypes();
          this.$children[0].clearUserSelect();
          this.makeToast("Success", "Assignments updated", "success");
          this.resetPermissions();
          this.getTaskTypes();
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
</style>