<template>
  <b-list-group v-if="prices.status == 'fetching'" class="mt-2">
    <b-list-group-item class="text-center">
      <b-spinner variant="yellow" />
    </b-list-group-item>
  </b-list-group>
  <b-list-group v-else class="mt-2 mb-2 note-list" tag="ul">
    <b-list-group-item
      v-if="prices.data.postHistory && !prices.data.postHistory.length"
      class="text-center"
    >
      <b>No prices to show</b>
    </b-list-group-item>

    <b-list-group-item
      v-for="(price, i) in prices.data.postHistory"
      :key="i"
      class="flex-column align-items-start"
      tag="li"
    >
      <PriceCard :price="price" :refreshData="getPrices" />
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import PriceCard from "./PriceCard.vue";
import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "@/modules/superAdmin/mixins.js";
import { mapState } from "vuex";
import { GET_PRICES } from "./actions.js";
export default {
  name: "PriceList",
  components: {
    PriceCard,
  },
  mixins: [generalMixin, superAdminMixin],
  computed: {
    ...mapState({
      prices: (state) => state.superAdminStore.componentStore.prices.pricesList,

      vehicle: (state) => state.superAdminStore.vehicle,
    }),
  },
  created() {
    this.getPrices();
  },
  methods: {
    getPrices() {
      this.$store.dispatch(GET_PRICES, { id: this.vehicle.id }).then(() => {});
    },
  },
};
</script>

<style>
</style>