<template>
  <div>
    <div class="container-fluid">
      <b-row class="">
        <b-col>
          <!--     <h4 class="ml-3">Credit request</h4> -->
        </b-col>
      </b-row>
      <b-row class="mb-4 mr-1 ml-1">
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="4"
          xl="4"
          class="d-flex justify-content-center mt-4"
        >
        </b-col>

        <b-col xs="12" sm="12" md="12" lg="4" xl="4" class="mt-4">
          <!--           <b-input-group
            size="sm"
            class="
              shadows
              rounded-right rounded-left
              align-middle
              bg-dark-green
            "
          >
            <b-form-datepicker
              v-if="filter == '' || filter == null"
              v-model="filter"
              id="example-datepicker"
              style="color: white !important"
              class="filter-input rounded-right rounded-left text-white"
              @change="getData($event)"
            />

            <b-form-input
              v-else
              id="filterInput"
              v-model="filter"
              class="filter-input rounded-right rounded-left"
              type="search"
              @change="getData($event)"
            />

            <b-input-group-append class="rounded-right rounded-left">
              <b-icon
                icon="search"
                variant="yellow"
                class="h5 m-2 align-middle cursor-pointer"
              />
            </b-input-group-append>
          </b-input-group> -->
        </b-col>

        <b-col xs="12" sm="12" md="12" lg="4" xl="4" class="text-center mt-4">
          <!--       <b-button
            v-if="userHasPermission('creditRequest', 'write')"
            variant="success"
            @click="showModal()"
          >
            <b-icon icon="plus" class="mt-1" />
          </b-button> -->
        </b-col>
      </b-row>
      <div class="">
        <b-table
          show-empty
          bordered
          small
          stacked="md"
          head-variant="light"
          :items="creditRequestList.data.data"
          :fields="tableFields"
          :busy="creditRequestList.status === 'fetching'"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" variant="yellow" />
            </div>
          </template>

          <template v-slot:cell(actions)="row">
            <div class="text-center">
              <b-form-group>
                <b-icon
                  v-if="
                    row.field.details &&
                    userHasPermission('creditRequest', 'write')
                  "
                  v-b-tooltip.hover
                  title="Details"
                  icon="eye"
                  class="h5 m-2 cursor-pointer"
                  @click="editItem(row.item)"
                />

                <b-icon
                  icon="x-circle"
                  v-if="
                    row.item.status == 1 &&
                    userHasPermission('creditRequest', 'write')
                  "
                  v-b-tooltip.hover
                  title="Cancel"
                  class="h5 m-2 cursor-pointer"
                  @click="updateStatus(row.item.id, 0)"
                ></b-icon>

                <b-icon
                  v-if="row.item.vehicles.length"
                  icon="file-earmark-excel"
                  class="cursor-pointer h5 m-2"
                  @click="getExcel(row.item)"
                ></b-icon>

                <b-icon
                  v-if="
                    row.field.delete &&
                    userHasPermission('creditRequest', 'write')
                  "
                  icon="trash"
                  class="h5 m-2 cursor-pointer"
                  @click="deleteItem(row.item.id)"
                />
              </b-form-group>
            </div>
          </template>
        </b-table>
        <b-pagination
          v-if="creditRequestList.status != 'fetching'"
          align="end"
          :value="creditRequestList.data.currentPage"
          :total-rows="creditRequestList.data.total"
          :per-page="creditRequestList.data.perPage"
          class="mb-2"
          @change="changePage"
        />
      </div>

      <b-modal
        id="vehicles-list-modal"
        ref="vehicles-list-modal"
        size="lg"
        hide-footer
        body-class=" pt-0"
        ok-disabled
        @hide="hideModal"
      >
        <CreditRequestRegistrationForm
          :creditRequestItem="creditRequestItem"
          :refreshData="getData"
          :hideModal="hideModal"
        />
      </b-modal>
      <b-modal
        id="date-modal"
        ref="date-modal"
        size="lg"
        hide-footer
        body-class="pl-0 pr-0"
        ok-disabled
      >
        <LoadDate :load="load" :refreshData="getData" />
      </b-modal>
    </div>
  </div>
</template>
<script>
import CreditRequestRegistrationForm from "./CreditRequestRegistrationForm";
import {
  GET_CREDIT_REQUEST_LIST,
  GET_VEHICLES_CREDIT_REQUEST,
  GET_CREDIT_REQUEST_EXCEL,
  DELETE_CREDIT_REQUEST,
} from "./actions";
import { mapState } from "vuex";

import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../mixins";
export default {
  name: "CreditRequestTable",
  components: { CreditRequestRegistrationForm },
  mixins: [generalMixin, superAdminMixin],
  computed: {
    ...mapState({
      creditRequestList: (state) =>
        state.superAdminStore.creditRequest.creditRequestList,
    }),
    fields() {
      return this.getFieldsToShow();
    },
  },
  /*   watch: {
    filter(value) {
      console.log(value);
      this.filter = value;
      this.getData(value);
    },
  }, */
  data() {
    return {
      vehiclesSelected: [],
      tableFields: [
        {
          key: "createdAt",
          label: "Created at",
          class: "text-center",
          formatter: (date) => this.$options.filters.formatDate(date),
        },

        {
          key: "actions",
          label: "Actions",
          note: false,
          details: true,
          validation: false,
          delete: true,
        },
      ],
      options: {
        assign: false,
        rolFilter: false,
        add: false,
        fun: this.getData,
      },
      status: 1,
      creditRequestItem: {},
      load: {},
      filter: null,
    };
  },
  created() {
    this.$store.commit(
      "setAddFunction",
      this.userHasPermission("creditRequest", "write") ? this.showModal : null
    );
    this.$store.commit("setSearchFunction", this.getData);
    this.getData();
    this.getVehicles();
  },
  methods: {
    getData(filter = null) {
      this.$store
        .dispatch(GET_CREDIT_REQUEST_LIST, { date: filter })
        .then(() => {});
    },

    showModal() {
      this.creditRequestItem = {};
      this.$bvModal.show("vehicles-list-modal");
    },
    editItem(item) {
      this.creditRequestItem = item;
      this.$bvModal.show("vehicles-list-modal");
    },

    hideModal() {
      this.$bvModal.hide("vehicles-list-modal");
    },
    getVehicles() {
      this.$store.dispatch(GET_VEHICLES_CREDIT_REQUEST).then(() => {});
    },

    changePage(page, filter = "") {
      const payload = {
        search: filter,
        page: page,
        status: this.status,
      };
      this.$store
        .dispatch(GET_CREDIT_REQUEST_LIST, payload)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    getExcel(creditRequest) {
      this.$store
        .dispatch(GET_CREDIT_REQUEST_EXCEL, { id: creditRequest.id })
        .then((response) => {
          const blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "credit-request" + ".xls" || "ficher.xls";
          link.click();
        });
    },
    async deleteItem(id) {
      const confirm = await this.showConfirmDeleteBox();
      if (!confirm) return;
      this.$store.dispatch(DELETE_CREDIT_REQUEST, id).then(() => {
        this.$root.$bvToast.toast("Credit request deleted", {
          title: "Success",
          variant: "success",
        });
        this.getData();
      });
    },
  },
};
</script>
<style scoped>
.filter-input {
  background-color: #1d3030 !important;
  opacity: 1;
  color: white !important;
  border: none;
}
.form-control:focus {
  background-color: #1d3030 !important;
  color: white !important;
  border-color: #1d3030;
}
.shadows {
  box-shadow: 0 7px 7px 0 rgba(0, 0, 0, 0.5);
}
</style>
