import * as constants from "./actions";
import axios from "@/plugins/axios.js";
function getDefaultState() {
  return {
    carwash: {
      status: null,
      data: {},
      error: null,
    },
    carwashes: {
      status: null,
      data: [],
      error: null,
    },
    carwashList: {
      status: null,
      data: [],
      error: null,
    },
    providers: {
      status: null,
      data: [],
      error: null,
    },
  };
}

const actions = {
  [constants.POST_CARWASH]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_CARWASH);
      const response = await axios.post("carWash/createAndUpdate", payload);
      commit(constants.POST_CARWASH_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_CARWASH_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_CARWASH]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_CARWASH);
      const response = await axios.get("carWash/getTypeWashes", {
        params: payload,
      });
      commit(constants.GET_CARWASH_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_CARWASH_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_CARWASH_INFO]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_CARWASH_INFO);
      const response = await axios.put("carWash/editTypeWash", payload);
      commit(constants.PUT_CARWASH_INFO_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_CARWASH_INFO_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_CARWASH_EXCEL]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_CARWASH_EXCEL);
      const response = await axios.get("carWash/generateXls", {
        params: payload,
        responseType: "blob",
      });
      commit(constants.GET_CARWASH_EXCEL_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_CARWASH_EXCEL_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_CARWASH_LIST]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_CARWASH_LIST);
      const response = await axios.get("carWash/getCarWash", {
        params: payload,
      });
      commit(constants.GET_CARWASH_LIST_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_CARWASH_LIST_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_CARWASH]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_CARWASH);
      const response = await axios.put("carWash/changeStatus", payload);
      commit(constants.PUT_CARWASH_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_CARWASH_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_CARWASH_EMAIL]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_CARWASH_EMAIL);
      const response = await axios.get("carWash/sendCarWash", {
        params: payload,
      });
      commit(constants.GET_CARWASH_EMAIL_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_CARWASH_EMAIL_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_PROVIDERS_LIST]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_PROVIDERS_LIST);
      const response = await axios.get("serviceProvider/listServiceProvider", {
        params: payload,
      });
      commit(constants.GET_PROVIDERS_LIST_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_PROVIDERS_LIST_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.POST_CARWASH]: (state) => {
    state.carwash.status = "fetching";
    state.carwash.error = null;
  },
  [constants.POST_CARWASH_SUCCESS]: (state, response) => {
    state.carwash.status = "success";
    state.carwash.data = response;
  },
  [constants.POST_CARWASH_ERROR]: (state, error) => {
    state.carwash.status = "error";
    state.carwash.error = error;
  },
  [constants.GET_CARWASH]: (state) => {
    state.carwashes.status = "fetching";
    state.carwashes.error = null;
  },
  [constants.GET_CARWASH_SUCCESS]: (state, response) => {
    state.carwashes.status = "success";
    state.carwashes.data = response.typeWashes;
  },
  [constants.GET_CARWASH_ERROR]: (state, error) => {
    state.carwashes.status = "error";
    state.carwashes.error = error;
  },
  [constants.GET_CARWASH_EXCEL]: (state) => {
    state.carwash.status = "fetching";
    state.carwash.error = null;
  },
  [constants.GET_CARWASH_EXCEL_SUCCESS]: (state, response) => {
    state.carwash.status = "success";
    state.carwash.data = response;
  },
  [constants.GET_CARWASH_EXCEL_ERROR]: (state, error) => {
    state.carwash.status = "error";
    state.carwash.error = error;
  },
  [constants.GET_CARWASH_LIST]: (state) => {
    state.carwashList.status = "fetching";
    state.carwashList.error = null;
  },
  [constants.GET_CARWASH_LIST_SUCCESS]: (state, response) => {
    state.carwashList.status = "success";
    state.carwashList.data = response;
  },
  [constants.GET_CARWASH_LIST_ERROR]: (state, error) => {
    state.carwashList.status = "error";
    state.carwashList.error = error;
  },
  [constants.PUT_CARWASH]: (state) => {
    state.carwash.status = "fetching";
    state.carwash.error = null;
  },
  [constants.PUT_CARWASH_SUCCESS]: (state, response) => {
    state.carwash.status = "success";
    state.carwash.data = response;
  },
  [constants.PUT_CARWASH_ERROR]: (state, error) => {
    state.carwash.status = "error";
    state.carwash.error = error;
  },

  [constants.GET_CARWASH_EMAIL]: (state) => {
    state.carwash.status = "fetching";
    state.carwash.error = null;
  },
  [constants.GET_CARWASH_EMAIL_SUCCESS]: (state, response) => {
    state.carwash.status = "success";
    state.carwash.data = response;
  },
  [constants.GET_CARWASH_EMAIL_ERROR]: (state, error) => {
    state.carwash.status = "error";
    state.carwash.error = error;
  },
  [constants.GET_PROVIDERS_LIST]: (state) => {
    state.providers.status = "fetching";
    state.providers.error = null;
  },
  [constants.GET_PROVIDERS_LIST_SUCCESS]: (state, response) => {
    state.providers.status = "success";
    state.providers.data = response;
  },
  [constants.GET_PROVIDERS_LIST_ERROR]: (state, error) => {
    state.providers.status = "error";
    state.providers.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
