<template>
  <b-modal
    id="expenses-modal"
    ref="modal"
    modal-class="modal-fullscreen"
    centered
    scrollable
    size="lg"
    title="PNL"
    hide-footer
    @show="getExpensesInformation()"
  >
    <VehicleDetails
      v-if="vehicle"
      :vehicle="vehicle"
      :color="vehicle.vehicleInformation.color || '----'"
    />
    <b-row v-if="expensesInfo.status != 'fetching'" class="mt-3">
      <b-col sm="12" md="12" lg="8" xl="8" class="mt-2">
        <ExpensesAddonsList :addons="expensesInfo.data.addons" />
      </b-col>
      <b-col sm="12" md="12" lg="4" xl="4" class="mt-2">
        <ExpensesSummary :expensesInformation="expensesInfo.data" />
      </b-col>
    </b-row>
    <div v-else class="container mt-auto text-center mt-5">
      <b-spinner
        style="width: 10rem; height: 10rem"
        variant="yellow"
        class="align-middle mt-5"
      />
    </div>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import VehicleDetails from "@/modules/superAdmin/components/VehicleDetails.vue";
import ExpensesAddonsList from "./ExpensesAddonsList.vue";
import ExpensesSummary from "./ExpensesSummary.vue";
import { GET_EXPENSES } from "./actions";
import { superAdminMixin } from "../../mixins";

export default {
  name: "ExpensesModal",
  components: { VehicleDetails, ExpensesAddonsList, ExpensesSummary },
  mixins: [superAdminMixin],
  computed: {
    ...mapState({
      vehicle: (state) => state.superAdminStore.vehicle,
      expensesInfo: (state) => state.superAdminStore.componentStore.expenses.expensesInfo,
    }),
  },

  methods: {
    getExpensesInformation() {
      this.$store.dispatch(GET_EXPENSES, { vehicleId: this.vehicle.id }).then(() => {});
    },
  },
};
</script>

<style scoped></style>
