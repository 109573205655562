<template>
  <div>
    <h2>Files</h2>
    <b-row>
      <b-col xs="6" md="3" lg="3">
        <h3>
          Quebec Registration
          <font-awesome-icon
            v-if="exportInfo.quebecRegistrationLink"
            class="icon-color cursor-pointer"
            :icon="['far', 'file-pdf']"
            @click="openLink(exportInfo.registrationLink)"
          />
        </h3>
      </b-col>
      <b-col xs="6" md="3" lg="3">
        <h3>
          Invoice
          <b-spinner v-if="invoiceIsLoading" small variant="yellow" />
          <font-awesome-icon
            v-else-if="exportInfo.saleId"
            class="icon-color cursor-pointer"
            :icon="['far', 'file-pdf']"
            @click="generateInvoice(exportInfo.saleId)"
          />
        </h3>
      </b-col>
      <b-col xs="6" md="3" lg="3">
        <h3>
          Sell Contract
          <font-awesome-icon
            v-if="exportInfo.sellContractLink"
            class="icon-color cursor-pointer"
            :icon="['far', 'file-pdf']"
            @click="openLink(exportInfo.sellContractLink)"
          />
        </h3>
      </b-col>
      <b-col xs="6" md="3" lg="3">
        <h3>
          Export load
          <b-spinner v-if="exportLoadIsLoading" small variant="yellow" />

          <font-awesome-icon
            v-else-if="exportInfo.exportLoadsId"
            class="icon-color cursor-pointer"
            :icon="['far', 'file-excel']"
            @click="generateExportLoad(exportInfo.exportLoadsId)"
          />
        </h3>
      </b-col>
      <b-col xs="6" md="3" lg="3">
        <h3>
          Export jacket
          <font-awesome-icon
            v-if="exportInfo.exportJacketLink"
            class="icon-color cursor-pointer"
            :icon="['far', 'file-pdf']"
            @click="openLink(exportInfo.exportJacketLink)"
          />
        </h3>
      </b-col>
      <b-col xs="6" md="3" lg="3">
        <h3>
          Title before reassignment
          <font-awesome-icon
            v-if="exportInfo.titleBeforeLink"
            class="icon-color cursor-pointer"
            :icon="['far', 'file-pdf']"
            @click="openLink(exportInfo.titleBeforeLink)"
          />
        </h3>
      </b-col>
      <b-col xs="6" md="3" lg="3">
        <h3>
          Title after reassignment
          <font-awesome-icon
            v-if="exportInfo.titleAfterLink"
            class="icon-color cursor-pointer"
            :icon="['far', 'file-pdf']"
            @click="openLink(exportInfo.titleAfterLink)"
          />
        </h3>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { generalMixin } from "../../../mixin";

export default {
  name: "Files",
  props: {
    exportInfo: {
      type: Object,
      required: true,
    },
  },
  mixins: [generalMixin],
  data() {
    return {
      invoiceIsLoading: false,
      exportLoadIsLoading: false,
    };
  },

  methods: {
    openLink(url) {
      window.open(url, "_blank");
    },
    generateInvoice(saleId) {
      this.invoiceIsLoading = true;
      this.$store
        .dispatch("GET_SOLD_PDF", { id: saleId })
        .then((response) => {
          const blob = new Blob([response], {
            type: "application/pdf",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = this.formatFileName("invoice", this.exportInfo);
          link.click();
        })
        .catch((error) => {
          console.log(error);
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        })
        .finally(() => {
          this.invoiceIsLoading = false;
        });
    },
    generateExportLoad(exportLoadsId) {
      this.exportLoadIsLoading = true;
      this.$store
        .dispatch("GET_EXPORT_LOAD_EXCEL", { id: exportLoadsId })
        .then((response) => {
          const blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = this.formatFileName("export-load", this.exportInfo);
          link.click();
        })
        .catch((error) => {
          console.log(error);
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        })
        .finally(() => {
          this.exportLoadIsLoading = false;
        });
    },
  },
};
</script>
