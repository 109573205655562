<template>
  <div
    v-touch:touchhold="touchHoldHandler"
    @contextmenu.prevent="
      vehicle.vehicleFlags.cluster == 1
        ? $refs.menuCluster.open($event, 'Payload')
        : ''
    "
  >
    <slot></slot>
    <ContextMenu v-if="vehicle.vehicleFlags.cluster == 1" ref="menuCluster">
      <template slot-scope>
        <ContextMenuItem @click.native="markClusterAsComplete()">
          Mark as complete
        </ContextMenuItem>
      </template>
    </ContextMenu>
  </div>
</template>

<script>
import { superAdminMixin } from "../../mixins";
import ContextMenu from "../contextMenu/ContextMenu.vue";
import ContextMenuItem from "../contextMenu/ContextMenuItem.vue";
import { PUT_CLUSTER } from "./actions";
export default {
  name: "ClusterContextMenu",
  props: ["vehicle", "refreshData"],
  mixins: [superAdminMixin],
  components: {
    ContextMenu,
    ContextMenuItem,
  },

  methods: {
    markClusterAsComplete() {
      const payload = {
        status: 2,
        vehicleId: this.vehicle.id,
      };

      this.$store
        .dispatch(PUT_CLUSTER, payload)
        .then(() => {
          this.toastMessage(
            "Cluster mark as completed successfully",
            "Success",
            "success"
          );
          this.$refs.menuCluster.close();
          this.updateVehicleFlag(this.vehicle.id, "cluster", 2);
        })
        .catch(() => {
          this.toastMessage(
            "Cluster not mark as completed",
            "Warning",
            "warning"
          );
        });
    },
    touchHoldHandler($event) {
      this.$refs.menuCluster.open($event, "Payload");
    },
  },
};
</script>

<style>
</style>