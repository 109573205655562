<template>
  <div class="container-fluid">
    <b-tabs content-class="">
      <b-tab title="Successful" active @click="onChangeStatus(1)"></b-tab>
      <b-tab title="Failed" @click="onChangeStatus(0)"></b-tab>
    </b-tabs>
    <b-table
      show-empty
      bordered
      small
      stacked="md"
      head-variant="light"
      :items="vehicles || []"
      :fields="fields"
      :busy="vehiclesStatus == 'fetching'"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" variant="yellow" />
        </div>
      </template>

      <template #cell(actions)="{ item }">
        <b-icon
          class="mx-1 cursor-pointer"
          icon="eye"
          @click="openLink(item)"
          scale="1.3"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { GET_TITLE_FILES_VEHICLES } from "./actions";
export default {
  name: "TitleManagerVehiclesModal",
  props: {
    title: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      vehicles: (state) =>
        state.superAdminStore.titleManager.titleVehicles.data,
      vehiclesStatus: (state) =>
        state.superAdminStore.titleManager.titleVehicles.status,
    }),
  },
  data() {
    return {
      fields: [
        {
          key: "stockNumber",
          label: "Stock Number",
          class: "text-center",
        },
        {
          key: "vinNumber",
          label: "VIN Number",
          class: "text-center",
        },
        {
          key: "actions",
          label: "Actions",
          class: "text-center",
        },
      ],
    };
  },
  mounted() {
    if (this.title?.id) {
      this.getVehicles();
    }
  },
  methods: {
    getVehicles(status = 1) {
      this.$store.dispatch(GET_TITLE_FILES_VEHICLES, {
        id: this.title.id,
        status,
      });
    },
    openLink(item) {
      window.open(item.link, "_blank");
    },
    onChangeStatus(status) {
      this.getVehicles(status);
    },
  },
};
</script>

<style></style>
