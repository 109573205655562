<template>
  <div>
    <BoardCardsLogistic v-if="board == 'logistic'" />
    <BoardCardsAdmin v-if="board == 'admin'" />
    <BoardCardsBusiness v-if="board == 'business'" />
    <BoardCardsExport v-if="board == 'export'" />
    <BoardCardsYard v-if="board == 'yard'" />
    <BoardCardsSales v-if="board == 'sales'" />
    <BoardCardsAccounting v-if="board == 'accounting'" />
  </div>
</template>

<script>
import BoardCardsLogistic from "./BoardCardsLogistic.vue";
import BoardCardsAdmin from "./BoardCardsAdmin.vue";
import BoardCardsBusiness from "./BoardCardsBusiness.vue";
import BoardCardsExport from "./BoardCardsExport.vue";
import BoardCardsYard from "./BoardCardsYard.vue";
import BoardCardsSales from "./BoardCardsSales.vue";
import BoardCardsAccounting from "./BoardCardsAccounting.vue";
export default {
  name: "BoardCards",
  components: {
    BoardCardsLogistic,
    BoardCardsAdmin,
    BoardCardsBusiness,
    BoardCardsExport,
    BoardCardsYard,
    BoardCardsSales,
    BoardCardsAccounting,
  },
  computed: {
    board() {
      return this.$route.meta.board;
    },
  },
};
</script>

<style>
</style>