import * as constants from "./actions";
import axios from "@/plugins/axios.js";
function getDefaultState() {
  return {
    payments: {
      status: null,
      data: [],
      error: null,
    },
    payment: {
      status: null,
      data: {},
      error: null,
    },
    taxes: {
      status: null,
      data: [],
      error: null,
    },
    vehicleCost: {
      status: null,
      data: {},
      error: null,
    }

  };
}

const actions = {
  [constants.GET_PAYMENTS]: async ({ commit }, id) => {
    try {
      commit(constants.GET_PAYMENTS);
      const response = await axios.get(`payment/getPayments?id=${id}`);
      commit(constants.GET_PAYMENTS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_PAYMENTS_ERROR, error);
      throw error.response;
    }
  },

  [constants.POST_PAYMENT]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_PAYMENT);
      const response = await axios.post("payment/create", payload);
      commit(constants.POST_PAYMENT_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_PAYMENT_ERROR, error);
      throw error.response;
    }
  },

  [constants.PUT_PAYMENT_DISABLE]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_PAYMENT_DISABLE);
      const response = await axios.put("payment/changePaymentStatus", payload);
      commit(constants.PUT_PAYMENT_DISABLE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_PAYMENT_DISABLE_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_PAYMENTS_TAXES]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_PAYMENTS_TAXES);
      const response = await axios.get(`taxes/getTaxByVehicle`, {
        params: payload,
      });
      commit(constants.GET_PAYMENTS_TAXES_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_PAYMENTS_TAXES_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_VEHICLE_COST]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_VEHICLE_COST);
      const response = await axios.get(`payment/getCost`, {
        params: payload,
      });
      commit(constants.GET_VEHICLE_COST_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLE_COST_ERROR, error);
      throw error.response;
    }
  }
};

const mutations = {
  [constants.GET_PAYMENTS]: (state) => {
    state.payments.status = "fetching";
    state.payments.error = null;
  },
  [constants.GET_PAYMENTS_SUCCESS]: (state, data) => {
    state.payments.status = "success";
    state.payments.error = null;
    state.payments.data = data;
  },
  [constants.GET_PAYMENTS_ERROR]: (state, error) => {
    state.payments.status = "error";
    state.payments.error = error;
  },
  [constants.POST_PAYMENT]: (state) => {
    state.payment.status = "fetching";
    state.payment.error = null;
  },
  [constants.POST_PAYMENT_SUCCESS]: (state, data) => {
    state.payment.status = "success";
    state.payment.error = null;
    state.payment.data = data;
  },
  [constants.POST_PAYMENT_ERROR]: (state, error) => {
    state.payment.status = "error";
    state.payment.error = error;
  },

  [constants.PUT_PAYMENT_DISABLE]: (state) => {
    state.payment.status = "fetching";
    state.payment.error = null;
  },
  [constants.PUT_PAYMENT_DISABLE_SUCCESS]: (state, data) => {
    state.payment.status = "success";
    state.payment.error = null;
    state.payment.data = data;
  },
  [constants.PUT_PAYMENT_DISABLE_ERROR]: (state, error) => {
    state.payment.status = "error";
    state.payment.error = error;
  },

  [constants.GET_PAYMENTS_TAXES]: (state) => {
    state.taxes.status = "fetching";
    state.taxes.error = null;
  },
  [constants.GET_PAYMENTS_TAXES_SUCCESS]: (state, data) => {
    state.taxes.status = "success";
    state.taxes.error = null;
    state.taxes.data = data;
  },
  [constants.GET_PAYMENTS_TAXES_ERROR]: (state, error) => {
    state.taxes.status = "error";
    state.taxes.error = error;
  },
  [constants.GET_VEHICLE_COST]: (state) => {
    state.vehicleCost.status = "fetching";
    state.vehicleCost.error = null;
  },
  [constants.GET_VEHICLE_COST_SUCCESS]: (state, data) => {
    state.vehicleCost.status = "success";
    state.vehicleCost.error = null;
    state.vehicleCost.data = data;
  },
  [constants.GET_VEHICLE_COST_ERROR]: (state, error) => {
    state.vehicleCost.status = "error";
    state.vehicleCost.error = error;
  }
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
