<template>
  <b-list-group v-if="marketplaces.status == 'fetching'" class="mt-2">
    <b-list-group-item class="text-center">
      <b-spinner variant="yellow" />
    </b-list-group-item>
  </b-list-group>
  <b-list-group v-else class="mt-2 mb-2 note-list" tag="ul">
    <b-list-group-item
      v-if="marketplaces.data && !marketplaces.data.length"
      class="text-center"
    >
      <b>No records to show</b>
    </b-list-group-item>

    <b-list-group-item
      v-for="(marketplace, i) in marketplaces.data"
      :key="i"
      class="flex-column align-items-start"
      tag="li"
    >
      <MarketplaceHistoryItem :marketplace="marketplace" />
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import { mapState } from "vuex";
import MarketplaceHistoryItem from "./MarketplaceHistoryItem.vue";
export default {
  components: { MarketplaceHistoryItem },
  computed: {
    ...mapState({
      marketplaces: (state) =>
        state.superAdminStore.componentStore.history.marketplaces,
    }),
  },
};
</script>

<style>
</style>