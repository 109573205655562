<template>
  <b-dropdown
    ref="filter-dropdown"
    variant="none"
    toggle-class="p-0 text-decoration-none"
    menu-class="menu-min-width p-3 "
    no-caret
    boundary="window"
  >
    <template #button-content>
      <b-icon
        class="cursor-pointer"
        icon="filter"
        :variant="hasFilter() ? 'success' : ''"
      ></b-icon>
    </template>

    <b-dropdown-form style="min-width: 240px">
      <FilterAccountingDate v-if="type == 'date'" />
      <AgentsFilter
        v-if="type == 'agent'"
        @selectedAgent="onSelectAgent($event)"
        :agentsSelected="filter"
      />
      <div class="text-nowrap">
        <b-form-checkbox-group
          v-model="filter"
          :options="filterList"
          class="mb-3"
          stacked
          small
        />
      </div>
    </b-dropdown-form>
    <b-dropdown-divider></b-dropdown-divider>
    <div class="text-center">
      <div class="float-right">
        <b-button
          size="sm"
          variant="primary"
          class="mx-1"
          @click="applyFilters()"
          >Apply</b-button
        >

        <b-button
          size="sm"
          variant="danger"
          class="mx-1 text-white"
          @click="clearFilter()"
          >Clear</b-button
        >
      </div>
    </div>
  </b-dropdown>
</template>

<script>
import { filterList } from "./filterList";
import { mapState } from "vuex";
import { SET_ACCOUNT_STATEMENT_FILTERS } from "../actions";
import FilterAccountingDate from "./FilterAccountingDate.vue";
import AgentsFilter from "../../filters/AgentsList.vue";
export default {
  name: "AccountStatementFilter",
  props: {
    type: {
      type: String,
      required: true,
    },
    refreshData: {
      type: Function,
      required: true,
    },
  },
  components: { FilterAccountingDate, AgentsFilter },
  data() {
    return {
      filterList: filterList[this.type],
      filter: [],
    };
  },
  computed: {
    typeFormatted() {
      return filterList[this.type];
    },
    ...mapState({
      dealsFilters: (state) =>
        state.superAdminStore.accountStatement.accountsStatement.filters,
    }),
    accountStatementStore() {
      return this.dealsFilters;
    },
  },
  mounted() {
    this.getFilterFromStore();
  },
  watch: {
    accountStatementStore() {
      this.getFilterFromStore();
    },
  },

  methods: {
    formatTypeToFilter(type) {
      switch (type) {
        case "agent":
          return "agentId";
        case "stockNumber":
          return "stock";
        case "conditionalReports":
          return "cr";
        case "files":
          return "export";
        default:
          return type;
      }
    },
    applyFilters() {
      let arrayToApply = this.convertArrayToString(this.filter);
      this.$store.commit(SET_ACCOUNT_STATEMENT_FILTERS, {
        fields: { [this.formatTypeToFilter(this.type)]: arrayToApply },
      });

      this.closeMenuFilter();
      this.refreshData();
    },
    clearFilter() {
      this.filter = [];

      this.$store.commit(SET_ACCOUNT_STATEMENT_FILTERS, {
        fields: { [this.formatTypeToFilter(this.type)]: [] },
      });

      if (this.getDateFieldByType(this.type)) {
        this.clearDateFilter();
      }
      this.closeMenuFilter();
      this.refreshData();
    },
    clearDateFilter() {
      this.$store.commit(SET_ACCOUNT_STATEMENT_FILTERS, {
        fields: {
          [this.getDateFieldByType(this.type).from]: null,
          [this.getDateFieldByType(this.type).to]: null,
        },
      });
    },
    getDateFieldByType(type) {
      switch (type) {
        case "date":
          return { to: "dateFrom", from: "dateTo" };

        default:
          return null;
      }
    },
    convertArrayToString(array) {
      return array.join(",");
    },
    getFilterFromStore() {
      let filterStore = this.dealsFilters[this.formatTypeToFilter(this.type)];
      if (filterStore && filterStore != "" && typeof filterStore == "string") {
        this.filter = filterStore.split(",");
      } else {
        this.filter = [];
      }
    },
    onSelectAgent(agents) {
      this.filter = agents;
    },

    closeMenuFilter() {
      this.$refs["filter-dropdown"].hide();
    },
    hasFilter() {
      if (this.filter.length > 0) {
        return true;
      }
      if (this.getDateFieldByType(this.type) == null) {
        return false;
      }
      if (
        this.accountStatementStore[this.getDateFieldByType(this.type).to] !=
          null ||
        this.accountStatementStore[this.getDateFieldByType(this.type).from] !=
          null
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.menu-min-width {
  min-width: 5rem !important;
}
.hideOverflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}
</style>
