export const GET_FEES = "GET_FEES";
export const GET_FEES_SUCCESS = "GET_FEES_SUCCESS";
export const GET_FEES_ERROR = "GET_FEES_ERROR";

export const POST_FEE = "POST_FEE";
export const POST_FEE_SUCCESS = "POST_FEE_SUCCESS";
export const POST_FEE_ERROR = "POST_FEE_ERROR";

export const PUT_FEE = "PUT_FEE";
export const PUT_FEE_SUCCESS = "PUT_FEE_SUCCESS";
export const PUT_FEE_ERROR = "PUT_FEE_ERROR";
