<template>
  <div>
    <div class="d-flex justify-content-between">
      <div class="h5"><i>Addons</i></div>
      <span>Total: {{ addonsTotal | currency }}</span>
    </div>
    <div v-if="addons.length == 0" class="card text-center">
      <div class="card-body">This is no records to show.</div>
    </div>
    <template v-else>
      <div v-for="addon in paginatedAddons" :key="addon.id" class="card">
        <div class="card-body d-flex justify-content-between">
          <div>
            <span>{{
              addon.addonType && addon.addonType.name
                ? addon.addonType.name
                : "No category"
            }}</span
            ><br />
            <small class="">{{ addon.createdAt | formatDate }}</small>
          </div>
          {{
            corporation.typeCurrency == "USD"
              ? addon.totalAmountUSD
              : addon.totalAmountCAD | currency
          }}
        </div>
        <b-col class="mb-2">
          <div>{{ addon.description }}</div>
        </b-col>
      </div>
      <!-- pagination -->
      <div class="d-flex justify-content-between mt-2">
        <div>
          <!-- page -->
          <span class="mx-2">Page {{ pagination.page + 1 }}</span>
          <!-- total records -->
          <span class="mx-2">Total records: {{ addons.length }}</span>
        </div>
        <div>
          <button
            class="btn btn-sm btn-outline-primary"
            :disabled="pagination.page == 0"
            @click="pagination.page--"
          >
            Previous
          </button>

          <button
            class="btn btn-sm btn-outline-primary"
            :disabled="isLastPage"
            @click="pagination.page++"
          >
            Next
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    addons: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pagination: {
        page: 0,
        perPage: 5,
      },
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.auth.loginStore.profile.data,
    }),
    addonsTotal() {
      let total = 0;
      this.addons.map(
        (addon) =>
          (total +=
            this.corporation.typeCurrency == "CAD"
              ? addon.totalAmountCAD
              : addon.totalAmountUSD)
      );
      return total;
    },
    /* paginate addons list */
    paginatedAddons() {
      return this.addons.slice(
        this.pagination.page * this.pagination.perPage,
        this.pagination.page * this.pagination.perPage + this.pagination.perPage
      );
    },
    isLastPage() {
      return (
        this.pagination.page ==
        Math.ceil(this.addons.length / this.pagination.perPage - 1)
      );
    },
    corporation() {
      const corporation = localStorage.getItem("corporation");
      return this.profile.user.corporationPermissions.find(
        (c) => c.id == corporation
      );
    },
  },
};
</script>

<style></style>
