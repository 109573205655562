<template>
  <b-form>
    <b-row>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label-size="sm">
          <template>Marketplace:</template>
          <multiselect
            v-model="form.marketplaces"
            :options="marketplaces"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="false"
            placeholder="Pick some option"
            label="name"
            track-by="name"
            :preselect-first="false"
          >
          </multiselect>
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label-size="sm">
          <template>Location:</template>
          <multiselect
            v-model="form.location"
            :options="locations"
            :multiple="false"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="false"
            placeholder="Pick some option"
            label="companyName"
            track-by="id"
            :preselect-first="false"
          >
          </multiselect>
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label-size="sm">
          <template>Date:</template>
          <input v-model="form.auctionDate" type="date" class="form-control" />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <VehiclesList
          v-if="vehicles.data.length > 0"
          :vehiclesPreSelected="vehiclesPreSelected"
          @onVehiclesSelected="form.vehicles = $event"
        />
      </b-col>
      <b-col sm="12" md="12" lg="12" xl="12" class="mt-4">
        <b-btn size="lg" class="btn btn-success float-right" @click="save()">
          Submit
        </b-btn>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import Multiselect from "vue-multiselect";
import VehiclesList from "./VehiclesList.vue";
import { GET_RUN_LIST_CATALOGS, POST_RUN_LIST } from "./actions";
import { mapState } from "vuex";
export default {
  name: "RunListForm",
  components: { Multiselect, VehiclesList },
  props: {
    runList: {
      type: Object,
      default: () => {},
    },
    hideModal: {
      type: Function,
      default: () => {},
    },
    refresh: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      vehicles: (state) => state.superAdminStore.runList.vehicles,
      marketplaces: (state) => state.superAdminStore.runList.marketplaces.data,
      locations: (state) => state.superAdminStore.runList.locations.data,
    }),
    vehiclesPreSelected() {
      if (this.runList) {
        return this.runList.vehicles;
      }
      return this.form.vehicles;
    },
  },
  data() {
    return {
      form: {
        marketplaces: null,
        location: null,
        auctionDate: null,
        vehicles: [],
      },
    };
  },
  mounted() {
    this.getCatalogs();
  },
  methods: {
    getCatalogs() {
      this.$store
        .dispatch(GET_RUN_LIST_CATALOGS)
        .then(() => {
          this.formatForm();
        })
        .catch(() => {});
    },
    setMarketplace(marketplace) {
      this.form.marketplace = marketplace;
    },
    save() {
      const payload = this.formatPayload(this.form);
      this.$store
        .dispatch(POST_RUN_LIST, payload)
        .then(() => {
          this.hideModal();
          this.refresh();
        })
        .catch(() => {});
    },
    formatPayload(payload) {
      return {
        id: this.runList?.id,
        auctionDate: payload?.auctionDate,
        locationId: payload?.location?.id,
        marketPlaces: payload?.marketplaces,
        vehicles: payload.vehicles,
      };
    },
    formatForm() {
      if (this.runList) {
        this.form.marketplaces = this.runList.marketPlace;
        this.form.location = this.locations.find(
          (location) => location.id == this.runList.locationId
        );
        this.form.auctionDate = this.runList.auctionDate;
        this.form.vehicles = this.runList.vehicles;
      }
    },
  },
};
</script>

<style>
</style>