 <template>
  <b-row>
    <b-col cols="12" class="mr-0">
      <multiselect
        v-model="selected"
        :options="users"
        :multiple="false"
        :close-on-select="true"
        :clear-on-select="false"
        :preserve-search="true"
        placeholder="Search user"
        label="nickname"
        track-by="nickname"
        :custom-label="userFullName"
        :preselect-first="false"
        :taggable="true"
      >
      </multiselect>
    </b-col>
  </b-row>
</template>

<script>
import Multiselect from "vue-multiselect";
import { GET_TASK_LIST_USERS } from "../actions";
import { mapState } from "vuex";
export default {
  components: { Multiselect },
  props: {
    type: {
      type: String,
      default: "",
    },
    userSelected: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapState({
      users: (state) => state.superAdminStore.tasks.taskListUsers.data,
    }),
    userName() {
      return `${this.selected.firstName} ${this.selected.lastName} (${this.selected.nickname})`;
    },
  },
  watch: {
    selected(val) {
      if (val) {
        this.selected = val;
        this.$emit("user-select", val);
      }
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  created() {
    this.getUsersTypes();
  },
  methods: {
    userFullName(user) {
      return `${user.firstName} ${user.lastName} (${user.nickname})`;
    },
    getUsersTypes() {
      this.$store
        .dispatch(GET_TASK_LIST_USERS)
        .then(() => {})
        .catch(() => {});
    },
    clearUserSelect() {
      this.selected = null;
    },
  },
};
</script>

<style></style>
