import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {

    accountsStatement: {
      status: null,
      data: [],
      error: null,
      total: 0,
      filters: {
        page: 1,
        resourcesPerPage: 25,
        search: null,
        dateFrom: null,
        dateTo: null,
        agentId: null,
      }
    },

    accountStatement: {
      status: null,
      data: {},
      error: null,
    },

  };
}
const actions = {

  [constants.GET_ACCOUNT_STATEMENT]: async ({ commit, state },) => {
    try {
      commit(constants.GET_ACCOUNT_STATEMENT);
      const payload = Object.entries(state.accountsStatement.filters).reduce(
        (a, [k, v]) => (v === null || v === "" ? a : { ...a, [k]: v }),
        {}
      );
      const response = await axios.get("commission/getCommissionPayment", {
        params: payload,
      });
      commit(constants.GET_ACCOUNT_STATEMENT_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_ACCOUNT_STATEMENT_ERROR, error);
      throw error.response;
    }
  },

  [constants.POST_ACCOUNT_STATEMENT]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_ACCOUNT_STATEMENT);
      const response = await axios.post("commission/createCommissionPayment", payload);
      commit(constants.POST_ACCOUNT_STATEMENT_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_ACCOUNT_STATEMENT_ERROR, error);
      throw error.response;
    }
  },


};

const mutations = {


  [constants.GET_ACCOUNT_STATEMENT]: (state) => {
    state.accountsStatement.status = "fetching";
    state.accountsStatement.error = null;
  },
  [constants.GET_ACCOUNT_STATEMENT_SUCCESS]: (state, data) => {
    state.accountsStatement.status = "success";
    state.accountsStatement.error = null;
    state.accountsStatement.data = data.data;
    state.accountsStatement.total = data.total;
  },
  [constants.GET_ACCOUNT_STATEMENT_ERROR]: (state, error) => {
    state.accountsStatement.status = "error";
    state.accountsStatement.error = error;
  },
  [constants.POST_ACCOUNT_STATEMENT]: (state) => {
    state.accountStatement.status = "fetching";
    state.accountStatement.error = null;
  },
  [constants.POST_ACCOUNT_STATEMENT_SUCCESS]: (state, data) => {
    state.accountStatement.status = "success";
    state.accountStatement.error = null;
    state.accountStatement.data = data;
  },
  [constants.POST_ACCOUNT_STATEMENT_ERROR]: (state, error) => {
    state.accountStatement.status = "error";
    state.accountStatement.error = error;
  },
  [constants.SET_ACCOUNT_STATEMENT_FILTERS]: (state, filters) => {
    if (filters.reset) {
      state.accountsStatement.filters = {
        search: null,
        resourcesPerPage: 25,
        page: 1,
      };
    }
    if (filters.fields) {
      state.accountsStatement.filters = {
        ...state.accountsStatement.filters,
        ...filters.fields,
      };
    }
  },

};
export default {
  state: getDefaultState(),
  actions,
  mutations,
};
