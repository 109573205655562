<template>
  <b-form-group class="mt-3 mb-2" label-class="pb-1">
    <template slot="label">
      <b class="mb-0 pb-0">Assigned to: </b>
    </template>
    <vue-single-select
      :classes="{
        input: 'form-control mt-0',
        dropdown: 'dropdown p-0',
      }"
      v-model="owner"
      :options="users"
      :getOptionDescription="() => userName"
      class=""
    >
      <template slot="option" slot-scope="{ option }">
        <div class="" @click="setOwner()">
          <span>
            {{ option.firstName }} {{ option.lastName }} ({{ option.nickname }})</span
          >
        </div>
      </template>
    </vue-single-select>
  </b-form-group>
</template>

<script>
import { mapState } from "vuex";
import VueSingleSelect from "vue-single-select";
import { SET_TASKS_FILTERS } from "../actions";
export default {
  name: "OwnerFilter",
  components: {
    VueSingleSelect,
  },
  computed: {
    ...mapState({
      users: (state) => state.superAdminStore.tasks.users.data,
      filters: (state) => state.superAdminStore.tasks.tasks.filters,
    }),
    userName() {
      if (this.owner) {
        return `${this.owner.firstName} ${this.owner.lastName}`;
      }
      return "";
    },
  },
  watch: {
    owner() {
      this.setOwner();
    },
  },
  mounted() {
    this.getFilterFromStore();
  },
  data() {
    return {
      owner: null,
    };
  },
  methods: {
    setOwner() {
      this.$store.commit(SET_TASKS_FILTERS, {
        fields: {
          ownerId: this.owner?.id,
        },
      });
    },
    getFilterFromStore() {
      this.owner = this.users.find((user) => user.id === this.filters.ownerId) || null;
    },
  },
};
</script>

<style lang="sass" scoped>
.col-form-label
  padding-bottom: 0
</style>
