<template>
  <b-sidebar
    id="sidebar-task-details"
    lazy
    :width="widthSidebar"
    :visible="taskDetailsSideBar.isShowing"
    shadow
    right
    bg-variant="white"
    no-header-close
    @hidden="
      () =>
        $store.commit('setTaskDetailsSideBar', {
          task: null,
          isShowing: false,
        })
    "
  >
    <template v-slot:title>
      <div class="text-right h2 d-flex justify-content-start">
        <span
          class="cursor-pointer mr-3"
          @click="closeSideBar()"
          v-b-tooltip.hover
          title="Close"
          ><strong>X</strong>
        </span>
        Task details

        <!--         <b-button variant="outline-success" class="text-back" v-if="status == 1">
          <font-awesome-icon
            class="cursor-pointer mr-2"
            :icon="['far', 'check-circle']"
            size="xl"
            v-b-tooltip.hover
            @click="markTaskAsDone()"
          />
          Mark as done
        </b-button>
        <b-button v-else variant="success" disabled> Task done </b-button> -->
      </div>
    </template>
    <b-card body-class="pt-0" class="mb-1">
      <b-container v-if="task" class="text-wrap px-2">
        <h1>{{ task.title }}</h1>
        <b-row>
          <template v-if="task.owner">
            <b-col sm="12" md="3" lg="3" xl="3">
              <label class="mt-1"> Assigned to </label>
            </b-col>
            <b-col sm="12" md="9" lg="9" xl="9" class="align-middle">
              <small
                class="avatar avatar-xs rounded-circle bg-success text-white mr-2"
              >
                {{ task.owner.acronym }}
              </small>
              <label class="mt-1">
                <span class="mt-2">
                  {{ task.owner.firstName }} {{ task.owner.lastName }}</span
                >
                <span class="mb-0" v-if="task.owner.nickname"
                  >({{ task.owner.nickname }})</span
                >
              </label>
            </b-col>
          </template>

          <template v-if="task.dueDate">
            <b-col sm="12" md="3" lg="3" xl="3">
              <label class="mt-2"> Due date </label>
            </b-col>
            <b-col sm="12" md="9" lg="9" xl="9">
              <label class="">
                <TaskCardDueDate :task="task" />
              </label>
            </b-col>
          </template>
          <template v-if="task.vehicle">
            <b-col sm="12" md="3" lg="3" xl="3">
              <label class="mt-2"> Vehicle </label>
            </b-col>
            <b-col sm="12" md="9" lg="9" xl="9">
              <label class="mt-2">
                {{ task.vehicle.year }} - {{ task.vehicle.make }} -
                {{ task.vehicle.model }} - {{ task.vehicle.vinNumber }}
              </label>
            </b-col>
          </template>

          <template v-if="task.company">
            <b-col sm="12" md="3" lg="3" xl="3">
              <label class="mt-2"> Company </label>
            </b-col>
            <b-col sm="12" md="9" lg="9" xl="9">
              <label class="mt-2">
                <task-card-company :task="task" />
              </label>
            </b-col>
          </template>
          <template v-if="task.type">
            <b-col sm="12" md="3" lg="3" xl="3">
              <label class="mt-2"> Type </label>
            </b-col>
            <b-col sm="12" md="9" lg="9" xl="9">
              <TaskCardTypeBadge :types="task.type" />
            </b-col>
          </template>
          <template v-if="task.description">
            <b-col sm="12" md="12" lg="12" xl="12">
              <label class="mt-2"> Description </label>
            </b-col>
            <b-col sm="12" md="12" lg="12" xl="12">
              <p>{{ task.description }}</p>
            </b-col>
          </template>
          <template v-if="task.link">
            <b-col sm="12" md="3" lg="3" xl="3">
              <label class=""> Link </label>
            </b-col>
            <b-col sm="12" md="9" lg="9" xl="9">
              <a :href="task.link" target="_blank" class="mt-3">Open link</a>
            </b-col>
          </template>
        </b-row>
      </b-container>
    </b-card>
    <b-card class="mt-0">
      <b-container>
        <b-tabs content-class="">
          <b-tab title="Notes" active>
            <TaskNotesList v-if="task && task.notes" :notes="task.notes" />
          </b-tab>
          <b-tab title="History">
            <TaskLogsList v-if="task" :task="task" />
          </b-tab>
        </b-tabs>
      </b-container>
    </b-card>

    <template #footer>
      <div class="px-3 py-3 bg-success">
        <TaskNoteForm v-if="task" :task="task" @updateNotes="updateNotes" />
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import { mapState } from "vuex";
import { MARK_TASK_AS_DONE } from "../actions";
import TaskNoteForm from "../notes/TaskNoteForm.vue";
import TaskNotesList from "../notes/TaskNotesList.vue";
import TaskLogsList from "../task-logs/TaskLogsList.vue";
import TaskCardCompany from "./TaskCardCompany.vue";
import TaskCardDueDate from "./TaskCardDueDate.vue";
import TaskCardTypeBadge from "./TaskCardTypeBadge.vue";
export default {
  name: "VehicleDetailsSideBar",
  components: {
    TaskCardDueDate,
    TaskCardCompany,
    TaskCardTypeBadge,
    TaskNoteForm,
    TaskNotesList,
    TaskLogsList,
  },

  computed: {
    widthSidebar() {
      return this.$vssWidth > 1280
        ? "40%"
        : this.$vssWidth <= 576
        ? "100%"
        : "50%";
    },
    ...mapState({
      taskDetailsSideBar: (state) =>
        state.superAdminStore.tasks.taskDetailsSideBar,
      task: (state) => {
        return { ...state.superAdminStore.tasks.taskDetailsSideBar.task };
      },
    }),
  },
  data() {
    return {
      status: this.task ? this.task.status : 1,
    };
  },
  methods: {
    markTaskAsDone() {
      this.isLoading = true;
      this.$store
        .dispatch(MARK_TASK_AS_DONE, { id: this.task.id })
        .then(() => {
          this.makeToast(
            "Success",
            "Task marked as done successfully",
            "success"
          );
          this.status = 2;
          this.isLoading = false;

          //this.refreshData();
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast("Something went wrong", {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        });
    },
    closeSideBar() {
      this.$store.commit("setTaskDetailsSideBar", {
        task: null,
        isShowing: false,
      });
    },
    updateNotes(note) {
      const taskToUpdate = JSON.parse(JSON.stringify(this.task));
      taskToUpdate.notes.unshift(note);
      this.$store.commit("setTaskDetailsSideBar", {
        task: taskToUpdate,
        isShowing: true,
      });
    },
  },
};
</script>

<style></style>
