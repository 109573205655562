export const POST_REGISTRATION_NOTE = "POST_REGISTRATION_NOTE";
export const POST_REGISTRATION_NOTE_SUCCESS = "POST_REGISTRATION_NOTE_SUCCESS";
export const POST_REGISTRATION_NOTE_ERROR = "POST_REGISTRATION_NOTE_ERROR";

export const DELETE_REGISTRATION_NOTE = "DELETE_REGISTRATION_NOTE";
export const DELETE_REGISTRATION_NOTE_SUCCESS =
  "DELETE_REGISTRATION_NOTE_SUCCESS";
export const DELETE_REGISTRATION_NOTE_ERROR = "DELETE_REGISTRATION_NOTE_ERROR";

export const GET_REGISTRATION_NOTES = "GET_REGISTRATION_NOTES";
export const GET_REGISTRATION_NOTES_SUCCESS = "GET_REGISTRATION_NOTES_SUCCESS";
export const GET_REGISTRATION_NOTES_ERROR = "GET_REGISTRATION_NOTES_ERROR";
