<template>
  <div class="d-flex flex-column justify-content-start">
    <div>
      <b-form-group
        label="Select the damage level"
        label-class="text-center"
        class="mb-4"
      >
        <b-form-radio-group
          :disabled="!userHasPermission('vi', 'write')"
          v-model="form.color"
          @change="changeEventListener('register')"
          buttons
          class="w-100"
          button-variant="outline-primary"
          name="status"
        >
          <b-form-radio value="#DC3545">
            <span class="text-danger">
              <b-icon scale="1.5" icon="circle-fill" />
            </span>
          </b-form-radio>
          <b-form-radio value="#FFC106">
            <span class="text-warning">
              <b-icon scale="1.5" icon="circle-fill" />
            </span>
          </b-form-radio>
          <b-form-radio value="#2AC940">
            <span class="text-success">
              <b-icon scale="1.5" icon="circle-fill" />
            </span>
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>
    </div>
    <canvas id="interior" class="mycanvas car-img" width="300" height="448" />
    <b-modal
      id="modal-damage"
      v-model="showModal"
      centered
      hide-footer
      title="New Damage"
      @change="changeModal"
      @hide="form.color = null"
    >
      <form @submit.prevent="saveDamage">
        <b-form-group label="Image">
          <b-form-file
            v-model="form.file"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept="image/*"
          />
        </b-form-group>
        <b-form-group label="Damage">
          <b-form-select v-model="form.damageId" class="mb-3">
            <b-form-select-option
              v-for="(p, i) of damagesList"
              :key="i"
              :value="p.id"
            >
              {{ p.name }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Part">
          <b-form-select v-model="form.partId" class="mb-3">
            <b-form-select-option
              v-for="(p, i) of autoParts"
              :key="i"
              :value="p.id"
            >
              {{ p.name }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Description">
          <b-form-textarea
            v-model.trim="$v.form.description.$model"
            :value="form.description"
            :state="validateState('description')"
            placeholder="Enter something..."
            rows="3"
            max-rows="6"
            @change="setValue('description', $event)"
          />
        </b-form-group>
        <div class="d-flex justify-content-end">
          <b-button
            variant="success"
            :disabled="$v.form.$invalid"
            type="submit"
          >
            <b-icon icon="plus" />
          </b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import { isMobile } from "mobile-device-detect";
import { mapState } from "vuex";
import CompanyCarsService from "@/services/companyCarsService";
import { superAdminMixin } from "../../../../../superAdmin/mixins";
const DOT_ICON_SIZE = 3;
const DOT_SIZE = 10;
const REMARK_DOT_SIZE = 13.5;
const VALIDATE_DOT_AREA = 22;
export default {
  props: {
    url: String,
    dotsExterior: Array,
    vehicle: Object,
  },
  mixins: [superAdminMixin],
  data() {
    return {
      context: null,
      x: null,
      y: null,
      form: {
        status: null,
        description: null,
        file: null,
        partId: null,
        damageId: null,
      },
      dotsEx: [],
      showModal: false,
      isSmall: false,

      interiorContex: null,
      exteriorContex: null,
      isRemarkingDot: false,
      currentDamage: null,
      autoParts: [],
      damagesList: [],
    };
  },
  validations: {
    form: {
      description: {
        maxLength: maxLength(255),
      },
      file: {
        required,
      },
    },
  },
  computed: {
    ...mapState({
      damageImageModal: (state) =>
        state.vehicle.visualInspection.damageImageModal,
    }),
  },
  mounted() {
    document
      .getElementById("interior")
      .addEventListener("click", (e) => this.handleClick(e));
    const canvas = document.getElementById("interior");
    const context = canvas.getContext("2d");
    this.context = context;
    setTimeout(() => {
      this.dotsEx = [...this.dotsExterior];
      this.setImg(() => {
        this.drawAllDots();
      });
    }, 700);
    this.getAutoParts();
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    clearCanvas(callback = null) {
      this.context.clearRect(0, 0, 300, 448);
      this.setImg(callback);
    },
    drawAllDots() {
      this.dotsEx.forEach((dot) => {
        this.setDot({ x: dot.x, y: dot.y }, dot.color, dot.status);
      });
    },
    setDot(coordinates, color, status) {
      this.x = coordinates.x;
      this.y = coordinates.y;
      this.context.beginPath();
      this.context.arc(
        coordinates.x,
        coordinates.y,
        DOT_SIZE,
        0,
        2 * Math.PI,
        false
      );
      this.context.fillStyle = color;
      this.context.fill();
      /* draw check icon inside dot */
      if (status == 1) {
        this.context.beginPath();
        this.context.arc(
          coordinates.x,
          coordinates.y,
          DOT_ICON_SIZE,
          0,
          2 * Math.PI,
          false
        );
        this.context.fillStyle = "white";
        this.context.fill();
        this.context.beginPath();
        this.context.moveTo(coordinates.x - 5, coordinates.y);
        this.context.lineTo(coordinates.x, coordinates.y + 5);
        this.context.lineTo(coordinates.x + 5, coordinates.y - 5);
        this.context.strokeStyle = "green";
        this.context.stroke();
      }
    },
    saveDamage() {
      const dot = {
        x: this.x,
        y: this.y,
        image: this.form.file,
        description: this.form.description,
        color: this.form.color,
        side: "interior",
        damageId: this.form.damageId,
        partId: this.form.partId,
      };
      this.dotsEx = [...this.dotsEx, dot];
      this.clearCanvas(() => {
        this.drawAllDots();
      });
      this.$emit("setDot", dot);
      this.showModal = false;
      this.form = {
        status: null,
        description: null,
        file: null,
        partId: null,
        damageId: null,
      };
      this.changeEventListener("view");
    },
    setImg(callback = null) {
      const bg = new Image();
      bg.src = this.vehicle.vehicleType.includes("SUV")
        ? "https://apidev.transcanauto.com/storage/interior-car.png"
        : "https://apidev.transcanauto.com/storage/system/inside.png";
      bg.onload = () => {
        this.context.drawImage(bg, 0, 0, 300, 448);
        if (callback) callback();
      };
    },
    changeModal(e) {
      if (!e) {
        this.clearCanvas(() => {
          this.drawAllDots();
        });
      }
    },

    handleClick(e) {
      this.isRemarkingDot && this.drawAllDots();
      const x = e.offsetX;
      const y = e.offsetY;
      this.dotsEx.forEach((dot, i) => {
        if (this.validateClickedDot(x, y, dot)) {
          this.clickDot(i, dot);
        }
      });
    },
    validateClickedDot(x, y, dot) {
      return (
        x <= dot.x + VALIDATE_DOT_AREA &&
        x >= dot.x - VALIDATE_DOT_AREA &&
        y <= dot.y + VALIDATE_DOT_AREA &&
        y >= dot.y - VALIDATE_DOT_AREA
      );
    },
    clickDot(index, dot) {
      this.remarkDot(dot, this.context);

      this.clickDamageDot(this.dotsEx[index]);
    },
    remarkDot(dot, context) {
      context.beginPath();
      context.lineWidth = 5;
      context.arc(dot.x, dot.y, REMARK_DOT_SIZE, 0, 2 * Math.PI, false);
      context.strokeStyle = "#F7F7F7";
      context.stroke();
      this.isRemarkingDot = true;
    },

    clickDamageDot(dot) {
      this.currentDamage = dot;
      if (isMobile || this.isSmall) {
        if (this.form.color == null) {
          this.$bvModal.show("damage-modal");
          this.$store.commit(
            "visualInspection/setDamageImageModal",
            dot.damagePhoto
          );
        }
      } else {
        if (this.form.color == null) {
          this.$store.commit(
            "visualInspection/setDamageImageModal",
            dot.damagePhoto
          );

          this.$bvModal.show("damage-modal");
        }
      }
    },

    changeEventListener(type) {
      document
        .getElementById("interior")
        .removeEventListener("click", (e) => this.handleClick(e));
      console.log();
      if (type === "register") {
        document.getElementById("interior").addEventListener("click", (e) => {
          if (!this.form.color) {
            return;
          }
          this.setDot({ x: e.offsetX, y: e.offsetY }, this.form.color, 0);
          setTimeout(() => {
            this.showModal = true;
          }, 200);
        });
      } else {
        document
          .getElementById("interior")
          .addEventListener("click", (e) => this.handleClick(e, "interior"));
      }
    },

    async getAutoParts() {
      const data = await CompanyCarsService.getAutoParts();
      this.damagesList = data.damage;
      this.autoParts = data.internalDamagePart;
    },
  },
};
</script>

<style>
.mycanvas {
  border: solid #e2e6ea 1px;
  cursor: pointer;
  border-radius: 5px;
}

.opacity {
  opacity: 0.2;
  cursor: not-allowed;
}
</style>
