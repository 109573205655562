<template>
  <Multiselect
    v-model="filter"
    :options="options"
    :multiple="true"
    :close-on-select="false"
    :clear-on-select="false"
    :preserve-search="true"
    placeholder="Pick some option"
    :preselect-first="false"
    @input="onSelect"
  >
  </Multiselect>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "MultipleSelectFilter",
  components: {
    Multiselect,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      filter: [],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.filter = value;
      },
    },
  },
  methods: {
    onSelect() {
      this.$emit("input", this.filter);
    },
  },
};
</script>

<style >
.max-width {
  max-width: 600px;
}
.multiselect__tags-wrap {
  display: flex !important;
  flex-direction: column !important;
}
</style>