import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    taxes: {
      status: null,
      data: [],
      error: null,
    },
    states: {
      status: null,
      data: [],
      error: null,
    },
    tax: {
      status: null,
      data: {},
      error: null,
    },
  };
}
const actions = {
  [constants.GET_TAXES_LIST]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_TAXES_LIST);

      const response = await axios.get("taxes/getStatesAndTaxes", {
        params: payload,
      });
      commit(constants.GET_TAXES_LIST_SUCCESS, response);
      commit;
      return response;
    } catch (error) {
      commit(constants.GET_TAXES_LIST_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_TAX]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_TAX);
      const response = await axios.post("taxes/createAndUpdate", payload);
      commit(constants.POST_TAX_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_TAX_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_TAXES_LIST]: (state) => {
    state.taxes.data = [];
    state.taxes.status = "fetching";
  },
  [constants.GET_TAXES_LIST_SUCCESS]: (state, response) => {
    state.taxes.status = "success";
    state.taxes.data = response.taxes;
    state.states.data = response.states;
  },
  [constants.GET_TAXES_LIST_ERROR]: (state, error) => {
    state.taxes.status = "error";
    state.taxes.error = error;
  },
  [constants.POST_TAX]: (state) => {
    state.tax.data = {};
    state.tax.status = "fetching";
  },
  [constants.POST_TAX_SUCCESS]: (state, response) => {
    state.tax.status = "success";
    state.tax.data = response;
  },
  [constants.POST_TAX_ERROR]: (state, error) => {
    state.tax.status = "error";
    state.tax.error = error;
  },
};
export default {
  state: getDefaultState(),
  actions,
  mutations,
};
