<template>
  <div>
    <b-row>
      <b-col cols="12" class="mr-0">
        <vue-single-select
          v-if="!disabled"
          :classes="{ input: 'form-control', dropdown: 'dropdown' }"
          v-model="selected"
          :options="exportPortListStore"
          optionLabel="name"
        >
          <template slot="option" slot-scope="{ option }">
            <div
              @click="
                selected = option;
                onClickButton();
              "
            >
              <span>
                {{ option.name }}
              </span>
            </div>
          </template>
        </vue-single-select>
        <b-form-group v-else label-size="sm">
          <b-form-input
            :value="selected ? selected.companyName : '---'"
            readonly
            size="sm"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VueSingleSelect from "vue-single-select";
import { PUT_VEHICLE_PORT } from "./actions";

export default {
  name: "ExportPortSelect",
  components: { VueSingleSelect },
  props: ["portId", "disabled", "vehicleId"],
  computed: {
    ...mapState({
      exportPortListStore: (state) =>
        state.superAdminStore.exportPort.exportPortList.data,
    }),
    clientName() {
      return this.selected.companyName || "";
    },
  },
  data() {
    return {
      selected: null,
      model: null,
      exportPortList: null,
    };
  },
  watch: {
    selected(val) {
      if (val) {
        this.selected = val;
        this.$emit("input", val);
      }
    },
  },
  mounted() {
    this.getExportPorts();
  },
  created() {},
  methods: {
    onClickButton() {
      this.updateVehiclePort(this.selected);
      this.$emit("input", this.selected);
    },
    getExportPorts() {
      this.$store.dispatch("GET_EXPORT_PORT_LIST").then(() => {
        this.selected = this.exportPortListStore[0];
        this.filterSelected();
        this.onClickButton();
      });
    },
    filterSelected() {
      const exportPortList = [...this.exportPortListStore];
      this.exportPortList = [...this.exportPortListStore];
      if (this.portId == null) {
        return;
      }
      const result = exportPortList.filter(
        (client) => client.id == this.portId
      );

      this.selected = result[0];
    },
    updateVehiclePort(port) {
      this.$store
        .dispatch(PUT_VEHICLE_PORT, {
          vehicleId: this.vehicleId,
          exportPortId: port.id,
        })
        .then(() => {});
    },
  },
};
</script>

<style></style>
