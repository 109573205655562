<template>
  <b-container>
    <b-row class="mt-3">
      <b-col cols="12 mb-3">
        <div class="d-flex flex-row justify-content-end">
          <b-form-radio
            v-model="form.type"
            name="tiresType"
            value="summer"
            :checked="form.type === 'summer'"
          >
            <span class="h4">Summer tires</span>
            <img
              src="@/assets/img/tires.png"
              class="ml-2 img-ico"
              alt="tires"
            />
          </b-form-radio>
          <b-form-radio
            v-model="form.type"
            class="ml-3"
            name="tiresType"
            value="winter"
            :checked="form.type === 'winter'"
          >
            <span class="h4">Winter tires</span>
            <img
              src="@/assets/img/tires.png"
              class="ml-2 img-ico"
              alt="tires"
            />
          </b-form-radio>
        </div>
      </b-col>
      <b-col cols="12" class="text-right">
        <b-button
          v-if="userHasPermission('vi', 'write')"
          variant="outline-yellow"
          class="align-bottom mb-3 mr-5"
          @click="showModal('tireModal')"
        >
          <b-icon icon="plus-circle" class="h4 m-1 align-bottom" />
          <span class="" />
        </b-button>
      </b-col>
      <b-col>
        <b-table
          ref="tiresTable"
          fixed
          small
          hover
          show-empty
          stacked="md"
          :fields="fields"
          :items="tireList"
        >
          <template #empty>
            <div class="text-center">
              <h6>There are no records to show.</h6>
            </div>
          </template>
          <template #cell(tire)="{ item }">
            {{ item.tire }}
          </template>
          <template #cell(treadDepth)="{ item }">
            <input
              v-if="item._isEditing"
              v-model.trim="item.treadDepth"
              class="form-control"
              maxlength="55"
            />
            <span v-else>{{ item.treadDepth || "---" }}</span>
          </template>
          <template #cell(brand)="{ item }">
            <input
              v-if="item._isEditing"
              v-model.trim="item.brand"
              class="form-control"
              maxlength="55"
            />
            <span v-else>{{ item.brand || "---" }}</span>
          </template>
          <template #cell(size)="{ item }">
            <input
              v-if="item._isEditing"
              v-model.trim="item.size"
              class="form-control"
              maxlength="55"
            />
            <span v-else>{{ item.size || "---" }}</span>
          </template>
          <template #cell(actions)="{ item }" class="text-center">
            <div class="d-flex justify-content-center">
              <b-button
                v-if="item._isEditing"
                class="mr-2"
                variant="light"
                size="sm"
                @click="cancelEdit(item)"
              >
                <b-icon icon="x" />
              </b-button>
              <b-button
                v-if="item._isEditing"
                class="mr-2"
                variant="light"
                size="sm"
                @click="addTire(item)"
              >
                Save
              </b-button>
              <b-button
                v-else
                class="mr-2"
                variant="light"
                size="sm"
                @click="item._isEditing = true"
              >
                <b-icon icon="pencil" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-modal
      id="tireModal"
      ref="tireModal"
      size="lg"
      hide-footer
      body-class="container"
      ok-disabled
    >
      <b-form class="m-4" @submit.stop.prevent="registerTire()">
        <b-row>
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label-size="sm">
              <template slot="label">
                Tire <span class="text-danger">*</span>
              </template>
              <b-form-select
                v-model="$v.form.tire.$model"
                :options="tiresOptions"
                :state="validateState('tire')"
                @change="setValue('tire', $event)"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label-size="sm">
              <template slot="label">
                Tread depth <span class="text-danger">*</span>
              </template>
              <b-form-input
                v-model="$v.form.treadDepth.$model"
                :state="validateState('treadDepth')"
                @change="setValue('treadDepth', $event)"
                size="md"
                maxlength="100"
              />
            </b-form-group>
          </b-col>

          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label-size="sm">
              <template slot="label">
                Brand <span class="text-danger">*</span>
              </template>
              <b-form-input
                v-model="$v.form.brand.$model"
                :state="validateState('brand')"
                @change="setValue('brand', $event)"
                size="md"
                maxlength="100"
              />
            </b-form-group>
          </b-col>

          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label-size="sm">
              <template slot="label">
                Size <span class="text-danger">*</span>
              </template>
              <b-form-input
                v-model="$v.form.size.$model"
                :state="validateState('size')"
                @change="setValue('size', $event)"
                size="md"
                maxlength="100"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="6" lg="6" xl="6" />
          <b-col sm="12" md="3" lg="3" xl="3" />
          <b-col sm="12" md="3" lg="3" xl="3">
            <b-btn
              block
              size="lg"
              class="btn btn-success"
              type="submit"
              :disabled="$v.form.$invalid"
            >
              Submit
              <b-spinner v-if="isLoading" small variant="white" />
            </b-btn>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
import CompanyCarsService from "@/services/companyCarsService";
import { generalMixin } from "@/modules/mixin.js";
import { companyCarsMixin } from "@/modules/vehicle/components/visual-inspection/mixin";
import { required } from "vuelidate/lib/validators";
import { superAdminMixin } from "../../../../../superAdmin/mixins";
export default {
  name: "TiresTable",
  mixins: [generalMixin, companyCarsMixin, superAdminMixin],
  props: {
    tires: Array,
    vehiclesId: Number,
  },
  data() {
    return {
      originalTires: [],
      selectedRow: null,
      fields: [
        { label: "Tire", key: "tire" },
        { label: "Tread depth", key: "treadDepth" },
        { label: "Brand", key: "brand" },
        { label: "Size", key: "size" },
        { label: "Actions", key: "actions", class: "text-center" },
      ],
      form: {
        type: "summer",
        treadDepth: null,
        brand: null,
        size: null,
        tire: null,
      },

      tiresOptions: [
        { value: null, text: "Please select an option" },
        { value: "Left front", text: "Left front" },
        { value: "Left rear", text: "Left rear" },
        { value: "Right front", text: "Right front" },
        { value: "Right rear", text: "Right rear" },
        { value: "Spare", text: "Spare" },
      ],
      isLoading: false,
    };
  },
  validations: {
    form: {
      treadDepth: { required },
      brand: { required },
      size: { required },
      tire: { required },
    },
  },
  computed: {
    tireList() {
      const list = this.tires.map((t) => {
        return { ...t, _isEditing: false };
      });
      if (this.form.type === "summer") {
        return list.filter((t) => t.type.toLowerCase() === "summer");
      }
      return list.filter((t) => t.type.toLowerCase() === "winter");
    },
  },
  mounted() {
    this.originalTires = [...this.tires];
  },
  methods: {
    resetForm() {
      this.form.type = "summer";
      this.form.treadDepth = null;
      this.form.brand = null;
      this.form.size = null;
      this.form.tire = null;
    },

    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    cancelEdit(item) {
      item._isEditing = false;
      //this.tires = [...this.originalTires];
    },
    async addTire(tire) {
      tire._isEditing = false;
      try {
        await CompanyCarsService.postTireInCar(this.vehiclesId, tire);
        const indexRemplace = this.tires.findIndex((t) => t.id === tire.id);
        this.tires[indexRemplace] = { ...tire };
        this.makeToast("Success", "Tire saved successfully", "success");
      } catch (error) {
        console.log(error);
        this.makeToast(
          "Error",
          "Internal server error, try again later",
          "danger"
        );
      }
    },
    async registerTire() {
      this.isLoading = true;
      try {
        const response = await CompanyCarsService.postTireInCar(
          this.vehiclesId,
          this.form
        );
        if (response.message == "Vehicle tire has been assigned successfully") {
          this.makeToast("Success", "Tire saved successfully", "success");
          this.formatData();
          this.resetForm();
          this.closeModal("tireModal");
        }
        if (
          response.message == "The registration of that tire already exists"
        ) {
          this.makeToast(
            "Error",
            "The registration of that tire already exists",
            "warning"
          );
        }
      } catch (error) {
        this.isLoading = false;
        console.log(error);
        this.makeToast(
          "Error",
          "Internal server error, try again later",
          "danger"
        );
      } finally {
        this.isLoading = false;
      }
    },
    formatData() {
      this.getVin(this.$route.params.vin, (data) => {
        this.carId = data.id;
        if (data.vinNumber) {
          this.registryCar = data.vinNumber;
        }
        if (data.vehicleInformation) {
          this.registryCar.color = data.vehicleInformation.color;
          this.registryCar.kms = data.vehicleInformation.kms;
          this.registryCar.miles = data.vehicleInformation.miles;
          this.registryCar.stockNumber = data.stockNumber;
          this.registryCar.agent = data.user
            ? data?.user?.firstName + " " + data?.user?.lastName
            : "";
          this.vehicleInformation = data.vehicleInformation;
        }
        if (data.vehicleCostDetail) {
          this.registryCar.cost = data.vehicleCostDetail.cost;
          this.reservedPrice = data.vehicleCostDetail.reservedPrice;
        }
        if (data.vehicleHasTires) {
          this.tires = data.vehicleHasTires;
        }
        /*         this.setModelToOptions();
         */ this.componentKey += 1;
      });
    },
    formatPayload() {
      const payload = {
        tire: this.form.tire,
        treadDepth: this.form.treadDepth,
        brand: this.form.brand,
        size: this.form.size,
        type: this.form.type,
        vehiclesId: 1,
      };
      return payload;
    },
  },
};
</script>
