<template>
  <div class="container">
    <b-form class="m-4" @submit.stop.prevent="form.id ? update() : save()">
      <h3 class="text-purple-yellow">
        <b-icon icon="inboxes" font-scale="1.20" />
      </h3>

      <b-row> </b-row>
      <VehiclesList
        @vehicles-selected="setVehiclesSelected"
        :vehiclesPreSelected="vehiclesPreSelected || []"
        class="h-25"
      />

      <b-row />
      <b-row class="mt-3">
        <b-col sm="12" md="6" lg="6" xl="6" />
        <b-col sm="12" md="3" lg="3" xl="3" />
        <b-col sm="12" md="3" lg="3" xl="3">
          <b-btn
            v-if="userHasPermission('registration', 'write')"
            block
            size="lg"
            class="btn btn-success"
            :disabled="$v.form.$invalid"
            type="submit"
          >
            Submit
            <b-spinner
              v-if="vehicles.status == 'fetching'"
              small
              variant="white"
            />
          </b-btn>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { POST_REGISTRATION, GET_VEHICLES_REGISTRATION } from "./actions";
import { mapState } from "vuex";
import { generalMixin } from "@/modules/mixin.js";
import VehiclesList from "./VehiclesList.vue";
import { superAdminMixin } from "../mixins";

export default {
  name: "RegistrationForm",
  mixins: [generalMixin, superAdminMixin],
  props: ["registrationItem", "refreshData", "hideModal"],
  components: {
    VehiclesList,
  },
  computed: {
    ...mapState({
      vehicles: (state) => state.superAdminStore.registration.vehicles,
      exportLoad: (state) => state.superAdminStore.exportLoad.exportLoad,
    }),
    vehiclesPreSelected() {
      if (this.registrationItem) {
        return this.registrationItem.vehicles;
      }
      return [];
    },
    driverName() {
      if (this.form.driver) {
        return `${this.form.driver.firstName} ${this.form.driver.lastName}`;
      }
      return "";
    },
  },

  data() {
    return {
      vehiclesSelected: [],
      typeCarrier: "provider",

      exportLocation: null,
      form: {
        id: null,
        list: [],
        exportLocation: null,
        provider: null,
        driver: null,
      },
      providers: [],
      drivers: [],
    };
  },
  validations: {
    form: {},
  },
  created() {
    this.getVehicles();
    if (this.registrationItem.id) {
      this.formatForm();
    } else {
      this.resetForm();
    }
  },
  watch: {
    exportLocation() {
      if (this.exportLocation) {
        this.form.exportLocation = this.exportLocation;

        this.getVehicles();
      }
    },
  },
  methods: {
    closeModal(callback) {
      callback();
    },
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    save() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_REGISTRATION, payload)
        .then(() => {
          this.makeToast(
            "Success",
            "Registration created successfully",
            "success"
          );
          this.form = {};
          this.refreshData();
          this.hideModal("detailsModal");
        })
        .catch(() => {
          this.$root.$bvToast.toast("Internal server error, try again later", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    update() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_REGISTRATION, payload)
        .then(() => {
          this.makeToast(
            "Success",
            "Registration updated successfully",
            "success"
          );
          this.refreshData();
          this.hideModal();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Internal server error, try again later", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    editItem(item) {
      this.registrationItem = item;
      this.$bvModal.show("detailsModal");
    },

    formatPayload() {
      const payload = {
        id: this.registrationItem.id ? this.registrationItem.id : null,
        list: this.vehiclesSelected,
      };

      return payload;
    },

    formatForm() {},

    setVehiclesSelected(vehicles) {
      this.vehiclesSelected = vehicles;
    },
    getVehicles() {
      this.$store
        .dispatch(GET_VEHICLES_REGISTRATION, {})
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    },
    resetForm() {
      this.form = {
        id: null,
        list: [],
      };
    },
  },
};
</script>

<style scoped>
.append-input {
  max-width: 40%;
}
</style>
