<template>
  <b-card class="mb-1">
    <b-row>
      <b-col cols="12" class="text-wrap">
        <label class="h6 text-sm">
          <span
            class="avatar avatar-sm rounded-circle bg-success text-white"
            v-b-tooltip.hover
          >
            {{ note.user.acronym }}
          </span>
          {{ note.user.firstName }} {{ note.user.lastName }} -
          <label class="text-muted"> {{ note.date | formatDate }}</label>
        </label>
      </b-col>
      <b-col>
        <p>{{ note.description }}</p>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "TaskNoteCard",
  props: {
    note: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
