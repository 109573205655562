<template>
  <div class="d-flex justify-content-between mt-3">
    <div class="">
      <font-awesome-icon
        v-if="task.taskBoardsId > 1"
        class="cursor-pointer mt-1"
        :icon="['fas', 'circle-left']"
        size="lg"
        v-b-tooltip.hover
        title="Move to previous column"
        @click="moveCardToColumn(task.taskBoardsId - 1)"
      />
    </div>
    <div>
      <font-awesome-icon
        v-if="task.taskBoardsId < totalBoardColumns"
        class="cursor-pointer mt-1"
        :icon="['fas', 'circle-right']"
        size="lg"
        v-b-tooltip.hover
        title="Move to next column"
        @click="moveCardToColumn(task.taskBoardsId + 1)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { generalMixin } from "../../../mixin";
import { POST_TASK } from "../actions";
export default {
  name: "TaskCardMoveColumn",
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [generalMixin],
  computed: {
    ...mapState({
      tasks: (state) => state.superAdminStore.tasks.tasks.data,
    }),
    totalBoardColumns() {
      return this.$store.state.superAdminStore?.tasks?.tasks?.data?.length;
    },
    tasksColumns() {
      return JSON.parse(JSON.stringify(this.tasks));
    },
  },
  methods: {
    moveCardToColumn(column) {
      if (this.userIsAgent || this.userIsExternalAgent) {
        this.$bvToast.toast("You don't have permission to move tasks", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
        return;
      }
      const payload = {
        id: this.task.id,
        taskBoardId: column,
      };
      this.moveTaskToColumn(this.task, column);

      this.$store
        .dispatch(POST_TASK, payload)
        .then(() => {
          this.$bvToast.toast("Task updated successfully", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    moveTaskToColumn(taskToMove, taskBoardId) {
      taskToMove.taskBoardsId = taskBoardId;
      let taskColumnsEditable = JSON.parse(JSON.stringify(this.tasksColumns));
      taskColumnsEditable.forEach((taskColumn) => {
        taskColumn.tasks.forEach((task, index) => {
          if (task.id == taskToMove.id) {
            taskColumn.tasks.splice(index, 1);
          }
        });
      });
      taskColumnsEditable.forEach((taskColumn) => {
        if (taskColumn.id == taskBoardId) {
          taskColumn.tasks.push(taskToMove);
        }
      });
      this.$store.commit("GET_TASKS_SUCCESS", { data: taskColumnsEditable });
    },
  },
};
</script>

<style></style>
