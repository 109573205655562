<template>
  <div v-if="marketplaces">
    <img
      v-if="searchMarketplace('QAS')"
      src="https://apidev.transcanauto.com/storage/system/QAS_marketplace.svg"
      height="40"
      width="55"
      style="max-width: 100px"
    />
    <img
      v-if="searchMarketplace('CANAM')"
      src="https://apidev.transcanauto.com/storage/system/CANAM_marketplace.svg"
      height="40"
      width="55"
      style="max-width: 100px"
    />
  </div>
</template>

<script>
export default {
  props: ["marketplaces"],
  methods: {
    searchMarketplace(marketplace) {
      return this.marketplaces.find(
        (marketplaceItem) => marketplaceItem.name === marketplace
      );
    },
  },
};
</script>

<style>
</style>