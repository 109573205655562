var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-container',{staticClass:"pl-0 pr-0",attrs:{"fluid":""}},[_c('b-row',{},[_c('b-col')],1),_c('Search',{attrs:{"is-mobile":_vm.isMobile}}),_c('div',{staticClass:"text-left"}),_c('div',{staticClass:"mt-2 mb-2",attrs:{"id":"total-rows"}},[_c('div',{staticClass:"float-left"},[_c('b-button',{staticClass:"mt-2",attrs:{"variant":"white"}},[_vm._v(" Total rows: "+_vm._s((_vm.vehiclesInfo.data && _vm.vehiclesInfo.data.paginate && _vm.vehiclesInfo.data.paginate.total) || 0)+" ")])],1),_c('div',{staticClass:"text-right"},[_c('StatusFilter'),_c('b-button',{staticClass:"mx-1",attrs:{"variant":"light"},on:{"click":function($event){return _vm.editColumns()}}},[_vm._v(" Edit view ")]),_c('b-button',{staticClass:"text-white mx-1",attrs:{"variant":"yellow"},on:{"click":function($event){_vm.resetFilters();
             _vm.getVehiclesInfo();}}},[_vm._v(" Clear filters ")]),_c('b-button',{staticClass:"text-white mx-1",attrs:{"disabled":_vm.isLoadingVehiclesXLS,"variant":"success"},on:{"click":function($event){return _vm.getVehiclesExcel()}}},[(_vm.isLoadingVehiclesXLS)?_c('b-spinner',{staticClass:"align-middle",attrs:{"variant":"white","small":""}}):_c('font-awesome-icon',{staticClass:"cursor-pointer",attrs:{"icon":['far', 'file-excel']}})],1),_c('b-button',{on:{"click":function($event){return _vm.copyUrlWithFilters()}}},[_c('font-awesome-icon',{staticClass:"cursor-pointer",attrs:{"icon":['far', 'floppy-disk-circle-arrow-right']}})],1)],1)]),_c('div',{staticClass:"table-responsive"},[_c('b-table',{staticClass:"text-break",class:{ 'text-break': _vm.isMobile },attrs:{"show-empty":"","bordered":"","small":"","stacked":"md","head-variant":"light","items":_vm.vehiclesInfo.data.paginate ? _vm.vehiclesInfo.data.paginate.data : [],"fields":_vm.fieldsByPermission,"busy":_vm.vehiclesInfo.status === 'fetching',"sticky-header":"","tbody-tr-class":_vm.rowClass},scopedSlots:_vm._u([{key:"head()",fn:function(scope){return [_c('div',{staticClass:"table-head-menu"},[_c('span',{},[_vm._v(" "+_vm._s(scope.label)+" ")]),_c('br'),_c('div',{staticClass:"text-center"},[(_vm.hasFilter(scope.field.key))?_c('FilterColum',{ref:"filter-component",attrs:{"type":scope.field.key,"refreshFunction":_vm.getVehiclesInfo}}):_c('div',[_c('br'),_c('span',{})])],1)])]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"variant":"yellow"}})],1)]},proxy:true},{key:"cell(marketPlace)",fn:function({ item }){return [_c('div',{staticClass:"text-center"},[(item.marketPlace)?_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.toggleShowFullExportLocation(item.id)}}},[(
                   _vm.showFullExportLocation.show &&
                   _vm.showFullExportLocation.itemId == item.id
                 )?_c('small',{staticClass:"text-justify"},[_vm._v(" "+_vm._s(item.exportLocations.name)+" ")]):_c('small',[_vm._v(" "+_vm._s(_vm.exportLocationAbbreviation(item))+" ")]),_c('br'),_c('b-img',{staticClass:"mx-auto",staticStyle:{"max-width":"100px"},attrs:{"src":item.marketLogo,"height":"20","width":"30","alt":"marketplace-logo"}}),_c('div',[_c('b-link',{ref:item.vinNumber.vinNumber,attrs:{"id":item.vinNumber.vinNumber,"href":item.vinNumber.vinNumber}}),(item.finalDestination)?_c('small',{staticClass:"mt-5"},[_vm._v(" "+_vm._s(item.finalDestination.companyId)+" ")]):_vm._e(),_c('br')],1)],1):_c('span',{staticClass:"text-danger cursor-pointer",on:{"click":function($event){return _vm.goToInitialInformation(item, {
                   module: 'stock',
                   action: 'read',
                 })}}},[_vm._v(" N. A.")])])]}},{key:"cell(stockNumber)",fn:function({ item }){return [_c('b-link',{ref:item.stockNumber,attrs:{"id":item.stockNumber,"href":item.stockNumber}}),(item.stockNumber)?_c('small',{staticClass:"cursor-pointer",style:({
               color: _vm.getStockNumberVariant(item.vehicleFlags.stockNumber),
             }),on:{"click":function($event){_vm.goToInitialInformation(item, {
                 module: 'stock',
                 action: 'read',
               });
               _vm.setVehicleAnchor(item.stockNumber);}}},[_c('strong',[_vm._v(" "+_vm._s(item.stockNumber)+" ")])]):_vm._e(),_c('br'),_c('b-button',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(
               item.stockNumber ? item.stockNumber : item.stockNumber
             ),expression:"\n               item.stockNumber ? item.stockNumber : item.stockNumber\n             ",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.copyStockNumber),expression:"copyStockNumber",arg:"success"}],staticClass:"p-0",attrs:{"variant":"none"},on:{"click":function($event){_vm.stockCopiedText = item.stockNumber}}},[(_vm.stockCopied && _vm.stockCopiedText == item.stockNumber)?_c('b-icon',{attrs:{"icon":"clipboard-check"}}):_c('b-icon',{attrs:{"icon":"clipboard"}})],1),(
               item.vehicleFlags.consignmentDays || item.vehicleFlags.stockDays
             )?_c('br'):_vm._e(),(
               item.vehicleFlags.consignmentDays || item.vehicleFlags.stockDays
             )?_c('small',[_vm._v(" "+_vm._s(_vm.corporation.id != item.corporationsId ? item.vehicleFlags.consignmentDays : item.vehicleFlags.stockDays)+" days")]):_vm._e(),(!item.stockNumber)?_c('small',{staticClass:"text-danger cursor-pointer",on:{"click":function($event){_vm.goToInitialInformation(item, {
                 module: 'stock',
                 action: 'read',
               });
               _vm.setVehicleAnchor(item.stockNumber);}}},[_vm._v(" N. S.")]):_vm._e(),_c('br')]}},{key:"cell(vinNumber)",fn:function(row){return [_c('small',[_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){_vm.goToVehicleUnitPage(row.item.id);
                 _vm.setVehicleAnchor(row.item.stockNumber);}}},[_vm._v(" "+_vm._s(row.item.vinNumber.vinNumber)+" ")]),_c('br'),_c('b-button',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(
                 row.item.vinNumber.vinNumber
                   ? row.item.vinNumber.vinNumber
                   : row.item.vinNumber.vinNumber
               ),expression:"\n                 row.item.vinNumber.vinNumber\n                   ? row.item.vinNumber.vinNumber\n                   : row.item.vinNumber.vinNumber\n               ",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.copyVinNumber),expression:"copyVinNumber",arg:"success"}],staticClass:"p-0",attrs:{"variant":"none"},on:{"click":function($event){_vm.vinCopiedText = row.item.vinNumber.vinNumber}}},[(
                   _vm.vinCopied && _vm.vinCopiedText == row.item.vinNumber.vinNumber
                 )?_c('b-icon',{attrs:{"icon":"clipboard-check"}}):_c('b-icon',{attrs:{"icon":"clipboard"}})],1),(_vm.isMobile)?_c('b-button',{staticClass:"p-0",on:{"click":function($event){return _vm.goToVehicleUnitPage(row.item.id)}}},[_c('b-icon',{attrs:{"icon":"eye"}})],1):_vm._e()],1)]}},{key:"cell(fx)",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.twoDecimals(item.fx))+" ")]}},{key:"cell(year)",fn:function({ item }){return [_c('small',[_vm._v(" "+_vm._s(item.vinNumber.year)+" ")])]}},{key:"cell(model)",fn:function({ item }){return [_c('small',[_vm._v(" "+_vm._s(item.vinNumber.model)+" ")])]}},{key:"cell(user)",fn:function({ item }){return [(_vm.agentsSelected.find((agent) => agent === item.id))?_c('div',[_c('b-input-group',[_c('b-input',{attrs:{"list":"agents","placeholder":"Type agent name"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-success","size":"sm"},on:{"click":function($event){return _vm.updateAgent(item.id)}}},[_c('b-icon',{staticClass:"mt-1",attrs:{"icon":"check","font-scale":"1.5"}})],1)],1)],1)],1):_c('span',[(item.user)?_c('small',[_vm._v(" "+_vm._s(item.user.acronym)+" "),_c('br'),_c('font-awesome-icon',{staticClass:"icon-color cursor-pointer",attrs:{"icon":['far', 'book-open-cover']},on:{"click":function($event){_vm.openModal(item, 'history-modal', {
                     module: 'sl',
                     action: 'read',
                   });
                   _vm.setVehicleAnchor(item.stockNumber);}}})],1):_c('small',{staticClass:"text-danger"},[_vm._v(" N.A. ")])]),(item.vehicleFlags.marketPlaceDays)?_c('span',[_vm._v(" "+_vm._s(item.vehicleFlags.marketPlaceDays)+" d ")]):_vm._e()]}},{key:"cell(payments)",fn:function(row){return [_c('font-awesome-icon',{staticClass:"icon-color cursor-pointer",style:({
               color: _vm.getVarianPaymentColor(
                 row.item.vehicleFlags ? row.item.vehicleFlags.payments : null,
                 row.item.totalChecks
               ),
             }),attrs:{"size":"xl","icon":['far', 'money-check-dollar']},on:{"click":function($event){_vm.openModal(row.item, 'checks-modal', {
                 module: 'payments',
                 action: 'read',
               });
               _vm.setVehicleAnchor(row.item.stockNumber);}}})]}},{key:"cell(conditionalReports)",fn:function({ item }){return [_c('font-awesome-icon',{staticClass:"icon-color cursor-pointer",style:({ color: _vm.getCRVariant(item.vehicleFlags.cr) }),attrs:{"icon":['far', 'file-chart-column'],"size":"xl"},on:{"click":function($event){_vm.openModal(item, 'conditional-reports-modal', {
                 module: 'cr',
                 action: 'read',
               });
               _vm.setVehicleAnchor(item.stockNumber);}}})]}},{key:"cell(notes)",fn:function({ item }){return [_c('b-icon',{attrs:{"icon":"pencil-square","font-scale":"1.5","variant":item.vehicleFlags.notes == 1 ? 'yellow' : 'grey'},on:{"click":function($event){_vm.openModal(item, 'notes-modal', {
                 module: 'notes',
                 action: 'read',
               });
               _vm.setVehicleAnchor(item.stockNumber);}}})]}},{key:"cell(recall)",fn:function({ item }){return [_c('RecallContextMenu',{attrs:{"vehicle":item,"refreshData":_vm.getVehiclesInfo}},[_c('font-awesome-icon',{staticClass:"icon-color cursor-pointer",style:({ color: _vm.getRecallColor(item.vehicleFlags.recall) }),attrs:{"icon":['far', 'car-circle-bolt'],"size":"xl"},on:{"click":function($event){_vm.openModal(item, 'recall-modal', {
                   module: 'recall',
                   action: 'read',
                 });
                 _vm.setVehicleAnchor(item.stockNumber);}}})],1),(item.vehicleFlags.recallDays)?_c('span',[_vm._v(" "+_vm._s(item.vehicleFlags.recallDays)+" d ")]):_vm._e()]}},{key:"cell(carfax)",fn:function({ item }){return [_c('div',{staticClass:"mt-1"},[(!item.vehicleFlags.carfax)?_c('img',{staticClass:"img-responsive icon-color cursor-pointer",staticStyle:{"max-width":"35px"},attrs:{"src":require("@/assets/svg/CF_grey.svg")},on:{"click":function($event){_vm.openModal(item, 'carfax-modal', {
                   module: 'carfax',
                   action: 'read',
                 });
                 _vm.setVehicleAnchor(item.stockNumber);}}}):_vm._e(),(item.vehicleFlags.carfax == 1)?_c('img',{staticClass:"img-responsive icon-color cursor-pointer",staticStyle:{"max-width":"35px"},attrs:{"src":require("@/assets/svg/CF_success.svg")},on:{"click":function($event){_vm.openModal(item, 'carfax-modal', {
                   module: 'carfax',
                   action: 'read',
                 });
                 _vm.setVehicleAnchor(item.stockNumber);}}}):_vm._e(),(item.vehicleFlags.carfax == 2)?_c('img',{staticClass:"img-responsive icon-color cursor-pointer",staticStyle:{"max-width":"35px"},attrs:{"src":require("@/assets/svg/CF_danger.svg")},on:{"click":function($event){_vm.openModal(item, 'carfax-modal', {
                   module: 'carfax',
                   action: 'read',
                 });
                 _vm.setVehicleAnchor(item.stockNumber);}}}):_vm._e()])]}},{key:"cell(sold)",fn:function({ item }){return [_c('font-awesome-icon',{staticClass:"icon-color cursor-pointer",style:({ color: _vm.getSoldVariant(item.vehicleFlags.sold) }),attrs:{"icon":['far', 'file-invoice-dollar'],"size":"xl"},on:{"click":function($event){_vm.openModal(item, 'sold-modal', {
                 module: 'sold',
                 action: 'read',
               });
               _vm.setVehicleAnchor(item.stockNumber);}}})]}},{key:"cell(visualInspectionCR)",fn:function({ item }){return [_c('font-awesome-icon',{staticClass:"icon-color cursor-pointer",style:({
               color: _vm.getVisualInspectionCRVariant(item.vehicleFlags.vicr),
             }),attrs:{"size":"xl","icon":['far', 'mobile-signal-out']},on:{"click":function($event){_vm.goToVisualInspectionCR(item);
               _vm.setVehicleAnchor(item.stockNumber);}}})]}},{key:"cell(prices)",fn:function({ item }){return [_c('font-awesome-icon',{staticClass:"icon-color cursor-pointer",style:({
               color: _vm.getPriceVariant(item.vehicleFlags.prices),
             }),attrs:{"size":"xl","icon":['far', 'clouds']},on:{"click":function($event){_vm.openModal(item, 'prices-modal', {
                 module: 'prices',
                 action: 'read',
               });
               _vm.setVehicleAnchor(item.stockNumber);}}})]}},{key:"cell(creditRequest)",fn:function({ item }){return [_c('FinancialCreditRequestContextMenu',{attrs:{"vehicle":item,"refreshData":_vm.getVehiclesInfo}},[_c('font-awesome-icon',{staticClass:"icon-color cursor-pointer",style:({
                 color: _vm.getCreditRequestVariant(
                   item.vehicleFlags.creditRequest
                 ),
               }),attrs:{"size":"xl","icon":['far', 'landmark']},on:{"click":function($event){_vm.openModal(item, 'financial-credit-request-modal', {
                   module: 'creditRequest',
                   action: 'read',
                 });
                 _vm.setVehicleAnchor(item.stockNumber);}}})],1)]}},{key:"cell(visualInspection)",fn:function({ item }){return [_c('font-awesome-icon',{staticClass:"icon-color cursor-pointer",style:({
               color: _vm.getVisualInspectionVariant(item.vehicleFlags.vi),
             }),attrs:{"size":"xl","icon":['far', 'camera-viewfinder']},on:{"click":function($event){_vm.goToVisualInspection(item);
               _vm.setVehicleAnchor(item.stockNumber);}}})]}},{key:"cell(cluster)",fn:function({ item }){return [_c('ClusterContextMenu',{attrs:{"vehicle":item,"refreshData":_vm.getVehiclesInfo}},[_c('font-awesome-icon',{staticClass:"icon-color cursor-pointer",style:({
                 color: _vm.getClusterVariant(item.vehicleFlags.cluster),
               }),attrs:{"icon":['far', 'gauge-simple'],"size":"xl"},on:{"click":function($event){_vm.openModal(item, 'cluster-modal', {
                   module: 'cluster',
                   action: 'read',
                 });
                 _vm.setVehicleAnchor(item.stockNumber);}}})],1),(item.vehicleFlags.clusterDays)?_c('span',[_vm._v(" "+_vm._s(item.vehicleFlags.clusterDays)+" d ")]):_vm._e()]}},{key:"cell(carwash)",fn:function({ item }){return [_c('CarwashContextMenu',{attrs:{"vehicle":item,"refreshData":_vm.getVehiclesInfo}},[_c('font-awesome-icon',{staticClass:"icon-color cursor-pointer",style:({
                 color: _vm.getCarwashVariant(item.vehicleFlags.carWash),
               }),attrs:{"icon":['far', 'car-wash'],"size":"xl"},on:{"click":function($event){_vm.openModal(item, 'carwash-modal', {
                   module: 'carwash',
                   action: 'read',
                 });
                 _vm.setVehicleAnchor(item.stockNumber);}}})],1),(item.vehicleFlags.carwashDays)?_c('span',[_vm._v(" "+_vm._s(item.vehicleFlags.carwashDays)+" d ")]):_vm._e()]}},{key:"cell(lien)",fn:function({ item }){return [_c('font-awesome-icon',{staticClass:"icon-color cursor-pointer",style:({ color: _vm.getLienVariant(item.vehicleFlags.lien) }),attrs:{"icon":['far', 'file-slash'],"size":"xl"},on:{"click":function($event){_vm.openModal(item, 'lien-modal', {
                 module: 'liens',
                 action: 'read',
               });
               _vm.setVehicleAnchor(item.stockNumber);}}}),_c('br'),(item.vehicleFlags.liensDays)?_c('span',[_vm._v(_vm._s(item.vehicleFlags.liensDays)+" d")]):_vm._e()]}},{key:"cell(workOrders)",fn:function({ item }){return [_c('WorkOrderContextMenu',{attrs:{"vehicle":item,"refreshData":_vm.getVehiclesInfo}},[_c('font-awesome-icon',{staticClass:"icon-color cursor-pointer",style:({
                 color: _vm.getWorkOrderVariant(item.vehicleFlags.wo),
               }),attrs:{"icon":['far', 'car-wrench'],"size":"xl"},on:{"click":function($event){_vm.openModal(item, 'work-order-modal', {
                   module: 'wo',
                   action: 'read',
                 });
                 _vm.setVehicleAnchor(item.stockNumber);}}})],1),(item.vehicleFlags.woDays)?_c('span',[_vm._v(" "+_vm._s(item.vehicleFlags.woDays)+" d ")]):_vm._e()]}},{key:"cell(transfer)",fn:function({ item }){return [_c('TransferContextMenu',{attrs:{"vehicle":item,"refreshData":_vm.getVehiclesInfo}},[_c('font-awesome-icon',{staticClass:"icon-color cursor-pointer",style:({
                 color: _vm.getTransferVariant(item.vehicleFlags.transfer),
               }),attrs:{"icon":['far', 'truck-container'],"size":"xl"},on:{"click":function($event){_vm.openModal(item, 'transfer-modal', {
                   module: 'transfer',
                   action: 'read',
                 });
                 _vm.setVehicleAnchor(item.stockNumber);}}})],1),(item.vehicleFlags.transferDays)?_c('span',[_vm._v(" "+_vm._s(item.vehicleFlags.transferDays)+" d ")]):_vm._e()]}},{key:"cell(exportLoad)",fn:function({ item }){return [_c('h4',[(item.exportLoad != null)?_c('strong',{style:({ color: _vm.getExportLoadVariant(item.exportLoad.status) })},[_vm._v(" "+_vm._s(item.exportLoad.id)+" ")]):_c('span',{staticClass:"text-danger"},[_vm._v(" N.A. ")])])]}},{key:"cell(releaseDate)",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.releaseDate))+" ")])]}},{key:"cell(consignment)",fn:function({ item }){return [_c('font-awesome-icon',{staticClass:"icon-color cursor-pointer",style:({
               color: _vm.getConsignmentVariant(item.vehicleFlags.consignment),
             }),attrs:{"icon":['far', 'tag'],"size":"xl"},on:{"click":function($event){_vm.openModal(item, 'consignment-modal', {
                 module: 'sl',
                 action: 'read',
               });
               _vm.setVehicleAnchor(item.stockNumber);}}})]}},{key:"cell(exception)",fn:function({ item }){return [_c('font-awesome-icon',{staticClass:"icon-color cursor-pointer",style:({
               color: _vm.getExceptionVariant(item.vehicleFlags.exception),
             }),attrs:{"icon":['far', 'octagon-exclamation'],"size":"xl"},on:{"click":function($event){_vm.openModal(item, 'exception-modal', {
                 module: 'exception',
                 action: 'read',
               });
               _vm.setVehicleAnchor(item.stockNumber);}}})]}},{key:"cell(files)",fn:function({ item }){return [_c('FilesContextMenu',{attrs:{"vehicle":item,"refreshData":_vm.getVehiclesInfo}},[_c('font-awesome-icon',{staticClass:"icon-color cursor-pointer",style:({ color: _vm.getExportVariant(item.vehicleFlags.export) }),attrs:{"icon":['far', 'folder'],"size":"xl"},on:{"click":function($event){_vm.openModal(item, 'files-modal', {
                   module: 'jacket',
                   action: 'read',
                 });
                 _vm.setVehicleAnchor(item.stockNumber);}}}),(item.exportLoadsId)?_c('span',[_vm._v(" "+_vm._s(item.exportLoadsId)+" ")]):_vm._e()],1)]}},{key:"cell(reserve)",fn:function({ item }){return [_c('font-awesome-icon',{staticClass:"icon-color cursor-pointer",style:({
               color: _vm.getConsignmentVariant(item.vehicleFlags.reserve),
             }),attrs:{"icon":['far', 'message-dollar'],"size":"xl"},on:{"click":function($event){item.status == 'Sold' && item.vehicleFlags.reserve != 1
                 ? _vm.showSoldValidation()
                 : _vm.openModal(item, 'reserve-modal', {
                     module: 'reserve',
                     action: 'read',
                   });
               _vm.setVehicleAnchor(item.stockNumber);}}}),_c('br'),(item.vehicleFlags.reserveDays)?_c('span',[_vm._v(" "+_vm._s(item.vehicleFlags.reserveDays)+" d ")]):_vm._e()]}},{key:"cell(buyFrom)",fn:function({ item }){return [_c('div',{staticClass:"text-wrap"},[_c('small',[_vm._v(_vm._s(_vm.corporation.id != item.corporationsId ? item.corporationName : item.buyCompanyName))])])]}},{key:"cell(soldInfo)",fn:function({ item }){return [(item.saleCompanyName)?_c('small',{staticClass:"text-wrap"},[_vm._v(" "+_vm._s(item.saleCompanyName)+" ")]):_vm._e()]}},{key:"cell(location)",fn:function({ item }){return [(item.lastLocation)?_c('div',{staticClass:"text-wrap"},[_c('small',[_vm._v(" "+_vm._s(item.lastLocation)+" ")])]):_vm._e()]}},{key:"cell(offers)",fn:function({ item }){return [_c('font-awesome-icon',{staticClass:"icon-color cursor-pointer",style:({
               color: _vm.getOffersVariant(item.vehicleFlags.offers),
             }),attrs:{"icon":['far', 'gavel'],"size":"xl"},on:{"click":function($event){_vm.openOfferSidebar(item);

               _vm.setVehicleAnchor(item.stockNumber);}}})]}},{key:"cell(registration)",fn:function({ item }){return [_c('font-awesome-icon',{staticClass:"icon-color cursor-pointer",style:({
               color: _vm.getRegistrationVariant(item.vehicleFlags.registration),
             }),attrs:{"icon":['far', 'file-magnifying-glass'],"size":"xl"},on:{"click":function($event){_vm.openModal(item, 'registration-modal', {
                 module: 'registration',
                 action: 'read',
               });
               _vm.setVehicleAnchor(item.stockNumber);}}}),(item.vehicleFlags.registrationDays)?_c('span',[_vm._v(" "+_vm._s(item.vehicleFlags.registrationDays)+" d ")]):_vm._e()]}},{key:"cell(addons)",fn:function({ item }){return [_c('font-awesome-icon',{staticClass:"icon-color cursor-pointer",style:({
               color: _vm.getAddonVariant(item.vehicleFlags.addon),
             }),attrs:{"icon":['far', 'money-check-dollar-pen'],"size":"xl"},on:{"click":function($event){_vm.openModal(item, 'addons-modal', {
                 module: 'addons',
                 action: 'read',
               });
               _vm.setVehicleAnchor(item.stockNumber);}}})]}},{key:"cell(express)",fn:function({ item }){return [_c('font-awesome-icon',{staticClass:"icon-color cursor-pointer",style:({
               color: _vm.getRegistrationVariant(item.vehicleFlags.registration),
             }),attrs:{"icon":['far', 'truck-fast'],"size":"xl"},on:{"click":function($event){_vm.goToSimpleView(item.id);
               _vm.setVehicleAnchor(item.stockNumber);}}})]}},{key:"cell(expenses)",fn:function({ item }){return [_c('font-awesome-icon',{staticClass:"icon-color cursor-pointer",style:({
               color: _vm.getRegistrationVariant(item.vehicleFlags.registration),
             }),attrs:{"icon":['far', 'chart-pie'],"size":"xl"},on:{"click":function($event){_vm.openModal(item, 'expenses-modal', {
                 module: 'addons',
                 action: 'read',
               });
               _vm.setVehicleAnchor(item.stockNumber);}}})]}},{key:"cell(policeBook)",fn:function({ item }){return [_c('font-awesome-icon',{staticClass:"icon-color cursor-pointer",style:({
               color: _vm.getRegistrationVariant(item.vehicleFlags.registration),
             }),attrs:{"icon":['far', 'file-magnifying-glass'],"size":"xl"},on:{"click":function($event){_vm.openModal(item, 'police-book-modal', {
                 module: 'registration',
                 action: 'read',
               });
               _vm.setVehicleAnchor(item.vinNumber.vinNumber);}}})]}}])})],1),_c('div',[_c('b-pagination',{staticClass:"mb-2",attrs:{"value":_vm.vehiclesInfo.data.paginate
             ? _vm.vehiclesInfo.data.paginate.currentPage
             : 1,"total-rows":_vm.vehiclesInfo.data.paginate ? _vm.vehiclesInfo.data.paginate.total : 0,"per-page":_vm.vehiclesInfo.data.paginate ? _vm.vehiclesInfo.data.paginate.perPage : 25,"align":"end"},on:{"change":function($event){return _vm.changePage($event)}}})],1),_c('SelectTableFields',{attrs:{"fields-from-table":_vm.fieldsByPermission,"fieldsPermitted":_vm.fieldsByPermission},on:{"getCookie":function($event){return _vm.getCookie()}}}),_c('ChecksModal'),_c('ConditionalReportsModal'),_c('NotesModal'),_c('CarfaxModal'),_c('RecallModal'),_c('WorkOrderModal'),_c('TransferModal'),_c('SoldModal'),_c('CarwashModal'),_c('ConsignmentModal'),_c('LienModal'),_c('PricesModal'),_c('ExceptionModal'),_c('RegistrationModal'),_c('FilesModal'),_c('ClusterModal'),_c('ReserveModal'),_c('PoliceBookModal'),_c('OfferSideBar'),_c('AddonsModal'),_c('ExpensesModal'),_c('AtacModal'),_c('HistoryModal'),_c('FinancialCreditRequestModal')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }