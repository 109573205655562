<template>
  <b-modal
    :id="'recall-files-modal' + recall.id"
    ref="modal"
    centered
    no-close-on-backdrop
    scrollable
    size="lg"
    title="Recalls"
    hide-footer
  >
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <b-row>
          <b-col cols="12" class="mr-0">
            <b-form-group label="Evidence">
              <b-input-group>
                <b-form-file
                  id="file-contract"
                  v-model="form.evidence"
                  accept=".pdf"
                />
                <b-input-group-append
                  v-if="recall.evidence && form.evidence == null"
                >
                  <b-button variant="none">
                    <a :href="recall.evidence" target="_blank">
                      <b-icon icon="eye" variant="dark-green" />
                    </a>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>

      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <div class="row">
          <b-col cols="12" class="mr-0">
            <b-form-group label="Order">
              <b-input-group>
                <b-form-file
                  id="file-order"
                  v-model="form.order"
                  accept=".pdf"
                />
                <b-input-group-append v-if="recall.order && form.order == null">
                  <b-button variant="none">
                    <a :href="recall.order" target="_blank">
                      <b-icon icon="eye" variant="dark-green" />
                    </a>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </div>
      </b-col>
    </b-row>
    <div class="text-right mt-2">
      <b-button variant="success" @click="save()">
        <b-spinner v-if="recallStatus === 'fetching'" variant="white" small />
        <span v-else>Submit</span>
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import { POST_RECALL } from "./actions";
export default {
  props: ["recall", "refreshData"],
  computed: {
    ...mapState({
      recallStatus: (state) =>
        state.superAdminStore.componentStore.recall.recall.status,
    }),
  },
  data() {
    return {
      form: {
        description: null,
        vehicleId: null,
        evidence: null,
        order: null,
      },
    };
  },
  methods: {
    save() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_RECALL, payload)
        .then(() => {
          this.form = {};
          this.refreshData();
          this.toastMessage(
            "Information updated successfully",
            "Success",
            "success"
          );

          this.$refs.modal.hide();
        })
        .catch(() => {
          this.toastMessage("Information could not saved", "Error", "warning");
        });
    },
    formatPayload() {
      const data = new FormData();
      data.append("evidence", this.form.evidence);
      data.append("order", this.form.order);
      data.set("id", this.recall.id);
      return data;
    },
  },
};
</script>

<style>
</style>