<template>
  <div>
    <div class="container-fluid">
      <div>
        <b-table
          show-empty
          bordered
          small
          stacked="md"
          head-variant="light"
          :items="carwashesList"
          :fields="fields"
          :busy="carwashes.status === 'fetching'"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" variant="yellow" />
            </div>
          </template>
          <template #cell(selected)="{ item }">
            <div class="d-flex justify-content-center">
              <b-form-checkbox
                :checked="item.selected"
                @change="(val) => onRowSelected(val, item)"
              />
            </div>
          </template>
          <template #cell(name)="{ item }">
            <input
              v-if="item._isEditing"
              v-model.trim="item.name"
              class="form-control"
            />
            <span v-else>{{ item.name || "---" }}</span>
          </template>
          <template #cell(cost)="{ item }">
            <input
              v-if="item._isEditing"
              v-model.trim="item.cost"
              class="form-control"
              type="number"
            />
            <span v-else>{{ item.cost || "---" }}</span>
          </template>

          <template #cell(actions)="{ item }" class="text-center">
            <div class="d-flex justify-content-center">
              <b-button
                v-if="item._isEditing"
                class="mr-2"
                variant="light"
                size="sm"
                @click="cancelEdit(item)"
              >
                <b-icon icon="x" />
              </b-button>
              <b-button
                v-if="item._isEditing"
                class="mr-2"
                variant="light"
                size="sm"
                @click="
                  saveChangePrice(item);
                  item._isEditing = false;
                "
              >
                Save
              </b-button>
              <b-button
                v-else
                class="mr-2"
                variant="light"
                size="sm"
                @click="item._isEditing = true"
              >
                <b-icon icon="pencil" />
              </b-button>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { PUT_CARWASH_INFO } from "./actions";
export default {
  name: "CarwashList",
  props: {
    carwashes: {
      type: Array,
      default: () => [],
    },
    carwashesSelected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        { key: "name", label: "Name" },
        { key: "cost", label: "$" },

        "selected",
        { label: "Actions", key: "actions", class: "text-center" },
      ],
      itemsSelected: [],
      rowsSelected: [],
    };
  },
  computed: {
    carwashesList() {
      const list = this.carwashes.map((t) => {
        return { ...t, _isEditing: false, selected: false };
      });
      return list;
    },
  },

  methods: {
    getSelectedItems(items) {
      this.rowsSelected = items;
      this.$emit("update-select-carwash", this.rowsSelected);
    },
    onRowSelected(val, item) {
      if (val) {
        this.itemsSelected.push(item);
        item.selected = true;
      } else {
        const index = this.itemsSelected.indexOf(item);
        item.selected = false;
        this.itemsSelected.splice(index, 1);
      }
      this.getSelectedItems([...this.itemsSelected]);
    },
    saveChangePrice(item) {
      const indexRemplace = this.carwashes.findIndex(
        (carwash) => carwash.id === item.id
      );
      this.carwashes[indexRemplace] = { ...item };
      this.carwashes[indexRemplace]._isEditing = false;
      this.$store.dispatch(PUT_CARWASH_INFO, item);

      if (this.itemsSelected.length > 0) {
        const indexRemplace = this.itemsSelected.findIndex(
          (carwash) => carwash.id === item.id
        );
        if (indexRemplace > -1) {
          this.itemsSelected[indexRemplace] = { ...item };
          this.itemsSelected[indexRemplace]._isEditing = false;
          this.getSelectedItems([...this.itemsSelected]);
        }
      }
    },
    cancelEdit(item) {
      item._isEditing = false;
    },
    setCarwashSelectedToUpdate(carwashSelectedTo) {
      this.itemsSelected = [];
      this.rowsSelected = [];
      if (carwashSelectedTo.length == 0) {
        this.carwashesList.forEach((carwash) => {
          carwash.selected = false;
        });
      }
      this.carwashesList.forEach((carwash) => {
        carwashSelectedTo.forEach((carwashSelected) => {
          if (carwash.id == carwashSelected.id) {
            carwash.selected = true;
            this.onRowSelected(true, carwash);
          }
        });
      });
    },
  },
};
</script>

<style>
</style>