<template>
  <div>
    <b-form @submit.stop.prevent="saveRegistration()">
      <b-container>
        <h2>Registration file</h2>
        <b-row>
          <!-- registration -->
          <b-col xs="12" sm="12" md="12" lg="6" xl="6">
            <div class="row">
              <b-col cols="9" class="mr-0">
                <b-form-group
                  label="Registration"
                  label-cols-lg="3"
                  label-cols-sm="6"
                >
                  <b-input-group>
                    <b-form-file
                      id="file-ownersheet"
                      v-model="form.ownerSheet"
                      accept=".pdf"
                      @change="
                        vehicle.ownerSheet && form.ownerSheet == null
                          ? (registrationCheck = registrationCheck)
                          : (registrationCheck = 5)
                      "
                    />
                    <b-input-group-append
                      v-if="vehicle.ownerSheet && form.ownerSheet == null"
                    >
                      <b-button variant="none" class="pr-0">
                        <a :href="vehicle.ownerSheet" target="_blank">
                          <b-icon icon="eye" variant="dark-green" />
                        </a>
                        <b-icon
                          v-if="userHasPermission('registration', 'write')"
                          icon="trash"
                          class="ml-1 cursor-pointer"
                          @click="deleteFile('registration')"
                        ></b-icon>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </div>
          </b-col>
          <!-- Registration received date -->
          <b-col xs="12" sm="12" md="12" lg="6" xl="6">
            <b-form-group
              label-cols-lg="3"
              label-cols-sm="12"
              label-size="sm"
              label="Registration received date"
            >
              <b-form-datepicker
                id="registration-datepicker"
                v-model="form.registrationReceivedDate"
                class="mb-2"
              />
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="6" xl="6" class="">
            <b-form-group
              label-cols-lg="3"
              label-cols-sm="12"
              label-size="sm"
              label="Status"
            >
              <b-form-radio-group
                id="radio-group-registration-1"
                v-model="registrationCheck"
                :options="optionsRegistration"
                :button-variant="getRegistrationCheckVariant"
                size="sm"
                buttons
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
          <!-- note -->
          <b-col xs="12" sm="12" md="12" lg="6" xl="6">
            <b-form-group
              label-cols-lg="3"
              label-cols-sm="12"
              label-size="sm"
              label="Note"
            >
              <b-form-textarea
                id="registration-note"
                v-model="form.note"
                rows="3"
                class="mb-2"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col sm="12" md="6" lg="6" xl="6" />
          <b-col sm="12" md="3" lg="3" xl="3">
            <b-button
              v-if="userHasPermission('registration', 'write')"
              variant="warning"
              size="lg"
              v-b-tooltip.hover
              type="button"
              title="Notes"
              class="float-right mx-2 text-white"
              @click="openRegistrationNotesModal()"
            >
              Notes
            </b-button>
          </b-col>

          <b-col sm="12" md="3" lg="3" xl="3">
            <b-btn
              v-if="userHasPermission('registration', 'write')"
              block
              size="lg"
              class="btn btn-success"
              type="submit"
            >
              Submit
              <b-spinner v-if="status == 'fetching'" small variant="white" />
            </b-btn>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
    <RegistrationNotesModal :registration="registrationItem" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../mixins";
import RegistrationNotesModal from "./notes/NotesModal.vue";
export default {
  props: ["vehicle", "refreshData", "hideModal", "registrationItem"],
  mixins: [generalMixin, superAdminMixin],
  components: { RegistrationNotesModal },
  computed: {
    ...mapState({
      status: (state) => state.vehicle.vehicleByVin.status,
    }),
    getRegistrationCheckVariant() {
      switch (this.registrationCheck) {
        case 0:
          return "outline-grey";
        case 1:
          return "outline-success";
        case 2:
          return "outline-warning";
        case 3:
          return "outline-orange";
        case 4:
          return "outline-danger";
        case 5:
          return "outline-success";
        default:
          return "outline-grey";
      }
    },
    computedRegistrationCheck() {
      return this.form.registrationCheck;
    },
  },
  data() {
    return {
      form: {
        ownerSheet: null,
        registrationReceivedDate: null,
        registrationCheck: null,
        note: null,
      },
      registrationCheck: 0,
      optionsRegistration: [
        /*         { text: "Received", value: 1 },
         */
        { text: "Clear", value: null },
        { text: "ODO", value: 2 },
        { text: "SAAQ", value: 3 },
        { text: "Problems", value: 4 },
        { text: "✔", value: 5 },
      ],
    };
  },
  mounted() {
    this.setDefaultRegistrationDate();
    if (this.registrationItem?.id) {
      this.formatForm();
    }
  },
  watch: {
    registrationCheck() {
      this.form.registrationCheck = this.registrationCheck;
    },
  },
  methods: {
    saveRegistration() {
      const payload = this.formPayload();
      this.$store
        .dispatch("PUT_VEHICLE_BY_VIN", payload)
        .then(() => {
          this.toastMessage(
            "Vehicle information saved successfully.",
            "Success",

            "success"
          );
          this.refreshData();
          this.hideModal("uploadFileModal");
        })
        .catch(() => {
          this.toastMessage(
            "The vehicle could not be obtain",
            "Warning",
            "warning"
          );
        });
    },
    formPayload() {
      const data = new FormData();
      data.set("id", this.vehicle.id);
      data.set(
        "vinNumber",
        this.vehicle.vinNumber.vinNumber
          ? this.vehicle.vinNumber.vinNumber
          : this.vehicle.vinNumber
      );
      data.set("registrationCheck", this.form.registrationCheck);
      data.set("ownerSheet", this.form.ownerSheet);

      data.set("registrationReceivedDate", this.form.registrationReceivedDate);
      data.set("note", this.form.note);
      return data;
    },

    setDefaultRegistrationDate() {
      var myCurrentDate = new Date();
      var myFutureDate = new Date(myCurrentDate);
      myFutureDate.setDate(myFutureDate.getDate());

      this.form.registrationReceivedDate =
        this.$options.filters.formatDateTimeInput(myFutureDate);
    },
    formatForm() {
      this.form.registrationReceivedDate =
        this.registrationItem.registrationReceivedDate;
      this.form.registrationCheck = this.registrationItem.registrationCheck;
      this.registrationCheck = this.registrationItem?.registration?.status
        ? this.registrationItem.registration.status
        : this.registrationItem.registrationCheck;
      this.form.note = this.registrationItem?.registrationNote[0]?.description;
    },
    async deleteFile(type) {
      const confirm = await this.showConfirmDeleteBox();
      if (!confirm) return;
      this.$store
        .dispatch("PUT_CONTRACT_REGISTRATION_FILE", {
          id: this.vehicle.id,
          type: type,
        })
        .then(() => {
          this.refreshData();

          this.hideModal("uploadFileModal");

          this.toastMessage("File deleted successfully", "Success", "success");
        })
        .catch(() => {
          this.toastMessage("File could not be deleted", "Warning", "warning");
        });
    },
    openRegistrationNotesModal() {
      /*   this.$store.dispatch("GET_REGISTRATION_NOTES", {
        id: this.vehicle.id,
      }); */
      this.showModal("registration-notes-modal");
    },
  },
};
</script>

<style>
</style>