<template>
  <b-container class="mt-3">
    <b-card>
      <b-row align-v="center" slot="header">
        <b-col sm="12" md="8" lg="10" xl="10">
          Name:
          <h3 class="mb-0">{{ profile.user.firstName }} {{ profile.user.lastName }}</h3>
          Nickname:
          <h3 class="mb-0">{{ profile.user.nickname }}</h3>
          Email:
          <h3 class="mb-0">{{ profile.user.email }}</h3>
        </b-col>
        <b-col sm="none" md="4" lg="2" xl="2">
          <!-- corporation logo -->

          <b-img
            v-if="corporation"
            :src="corporation ? corporation.logo : ''"
            :alt="corporation ? corporation.name : ''"
            fluid
            width="100"
            height="80"
          />
        </b-col>
        <!-- user details -->
      </b-row>

      <AccountStatementTable />
    </b-card>
  </b-container>
</template>

<script>
import AccountStatementTable from "../../superAdmin/account-statement/AccountStatementTable.vue";
import { mapState } from "vuex";
export default {
  name: "AccountStatementView",
  components: { AccountStatementTable },
  computed: {
    ...mapState({
      profile: (state) => state.auth.loginStore.profile.data,
    }),
  },
  data() {
    return {
      corporation: null,
    };
  },
  mounted() {
    this.$store.commit("setAddFunction", null);
    this.$store.commit("setSearchFunction", this.searchData);
    const corporation = localStorage.getItem("corporation");
    if (corporation) {
      this.corporation = this.profile.user.corporationPermissions.find(
        (c) => c.id == corporation
      );
    } else {
      this.corporation = null;
    }
  },
  created() {
    this.setUserId(this.profile.user.id);
    this.getAccountStatus();
  },
  methods: {
    setUserId(agentId) {
      this.$store.commit("SET_ACCOUNT_STATEMENT_FILTERS", {
        fields: {
          agentId,
        },
      });
    },
    getAccountStatus() {
      this.$store.dispatch("GET_ACCOUNT_STATEMENT").then(() => {});
    },
    searchData(search) {
      this.$store.commit("SET_ACCOUNT_STATEMENT_FILTERS", {
        fields: {
          search: search,
        },
      });
      this.getAccountStatus();
    },
  },
};
</script>

<style></style>
