<template>
  <b-card class="shadow rounded mb-0">
    <div class="d-flex justify-content-between text-wrap">
      <b-row class="h6 text-sm text-wrap">
        <!--  <template v-if="isLoading">
          <b-spinner
            style="width: 0.9rem; height: 0.9rem"
            variant="yellow"
            class="mr-2"
          />
        </template>
        <template v-else>
          <font-awesome-icon
            v-if="status == 1"
            class="icon-color cursor-pointer mr-2"
            :icon="['far', 'circle']"
            size="xl"
            v-b-tooltip.hover
            title="Mark task as done"
            @click="markTaskAsDone()"
          />
          <font-awesome-icon
            v-if="status == 2"
            class="text-success cursor-pointer mr-2"
            :icon="['far', 'circle-check']"
            size="xl"
          />
        </template> -->
        <b-col cols="12">
          {{ task.title }}
        </b-col>
      </b-row>
      <span
        class="text-sm cursor-pointer"
        @click="deleteTask()"
        v-b-tooltip.hover
        title="Delete task"
        ><strong>X</strong>
      </span>
    </div>
    <p class="text-wrap">
      <TaskCardDescription
        v-if="task.description"
        :description="task.description"
        :price="task.price"
      />
    </p>
    <div v-if="task.type">
      <TaskCardTypeBadge :types="task.type" />
    </div>
    <div class="d-flex justify-content-between align-middle">
      <span class="align-middle mt-2">{{ task.createdAt | formatDate }}</span>
      <Badge v-if="task.user" :user="task.user"></Badge>
    </div>
    <div v-if="task.vehicles" class="mb-1">
      <Badge
        v-for="(vehicle, key) in task.vehicles"
        :vehicle="vehicle"
        :key="key"
      >
      </Badge>
    </div>
    <TaskCardCompany v-if="task.company" :task="task" />

    <div class="d-flex justify-content-between align-middle mt-1">
      <!--       <div><TaskCardDueDate v-if="task.dueDate" :task="task" /></div>
 -->
      <font-awesome-icon
        class="cursor-pointer mt-1"
        :icon="['far', 'circle-info']"
        size="xl"
        v-b-tooltip.hover
        title="Open details"
        @click="openTaskDetails()"
      />
    </div>
    <TaskCardMoveColumn :task="task" />
  </b-card>
</template>
<script>
import { generalMixin } from "../../../mixin";
import { DELETE_TASK, MARK_TASK_AS_DONE } from "../actions";
import Badge from "./Badge.vue";
import TaskCardDescription from "./TaskCardDescription.vue";
/* import TaskCardDueDate from "./TaskCardDueDate.vue";
 */ import TaskCardTypeBadge from "./TaskCardTypeBadge.vue";
import { mapState } from "vuex";
import TaskCardCompany from "./TaskCardCompany.vue";
import TaskCardMoveColumn from "./TaskCardMoveColumn.vue";
export default {
  name: "TaskCard",
  mixins: [generalMixin],
  components: {
    Badge,
    TaskCardTypeBadge,
    /* TaskCardDueDate */
    TaskCardDescription,
    TaskCardCompany,
    TaskCardMoveColumn,
  },
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
    refreshData: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      taskStatus: (state) => state.superAdminStore.tasks.task.status,
    }),
  },

  data() {
    return {
      status: this.task.status,
      isLoading: false,
    };
  },
  methods: {
    async deleteTask() {
      const value = await this.showConfirmDeleteBox();
      if (!value) {
        return;
      }
      this.$store
        .dispatch(DELETE_TASK, { id: this.task.id })
        .then(() => {
          this.makeToast("Success", "Task deleted successfully", "success");

          this.refreshData();
        })
        .catch(() => {
          this.$bvToast.toast("Something went wrong", {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        });
    },
    markTaskAsDone() {
      this.isLoading = true;
      this.$store
        .dispatch(MARK_TASK_AS_DONE, { id: this.task.id })
        .then(() => {
          this.makeToast(
            "Success",
            "Task marked as done successfully",
            "success"
          );
          this.status = 2;
          this.isLoading = false;

          //this.refreshData();
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast("Something went wrong", {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        });
    },
    openTaskDetails() {
      this.$store.commit("setTaskDetailsSideBar", {
        isShowing: true,
        task: this.task,
      });
    },
  },
};
</script>
