<template>
  <div class="container">
    <b-form class="m-4" @submit.stop.prevent="form.id ? update() : save()">
      <h3 class="text-purple-yellow">
        <b-icon icon="person-plus-fill" class="h4 m-1 align-bottom" />
      </h3>
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
          <!-- First name -->
          <b-form-group label-size="sm">
            <template slot="label">
              First name <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="firstName-input"
              v-model.trim="$v.form.name.$model"
              size="sm"
              name="firstName-input"
              :value="form.name"
              :state="validateState('name')"
              aria-describedby="input-firstName-live-feedback"
              maxlength="100"
              @change="setValue('name', $event)"
            />
            <b-form-invalid-feedback id="input-firstName-live-feedback">
              This is a required field and must at least 2 characters.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <!-- Last name -->
          <b-form-group label-size="sm">
            <template slot="label">
              Last name <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="lastName-input"
              v-model.trim="$v.form.lastName.$model"
              size="sm"
              name="lastName-input"
              :value="form.lastName"
              :state="validateState('lastName')"
              aria-describedby="input-lastName-live-feedback"
              maxlength="100"
              @change="setValue('lastName', $event)"
            />
            <b-form-invalid-feedback id="input-lastName-live-feedback">
              This is a required field and must at least 2 characters.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <!-- Mobile phone -->

        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group label-size="sm" label="Mobile phone">
            <template slot="label"> Mobile phone </template>
            <vue-tel-input
              v-model.trim="form.mobileNumber"
              default-country="us"
              :input-options="options"
              :dropdown-options="telDdOptions"
              :auto-format="true"
              :valid-characters-only="true"
              :value="form.mobileNumber"
              mode="international"
              style-classes="z-index-2"
            />
            <b-form-invalid-feedback id="input-mobile-phone-live-feedback">
              This is a numeric field
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <!-- Email -->
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group label-size="sm">
            <template slot="label">
              Email <span class="text-danger"> *</span>
            </template>
            <b-form-input
              id="email-input"
              v-model.trim="$v.form.email.$model"
              size="sm"
              name="email-input"
              :formatter="lowerCaseFormatter"
              :value="form.email"
              :state="validateState('email')"
              aria-describedby="input-email-live-feedback"
              maxlength="255"
              @change="setValue('email', $event)"
            />
            <b-form-invalid-feedback id="input-email-live-feedback">
              This field must be a valid email.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row />
      <b-row />

      <b-row>
        <b-col sm="12" md="6" lg="6" xl="6" />
        <b-col sm="12" md="3" lg="3" xl="3" />
        <b-col sm="12" md="3" lg="3" xl="3">
          <b-btn
            v-if="userHasPermission('drivers', 'write')"
            block
            size="lg"
            class="btn btn-success"
            :disabled="$v.form.$invalid || isLoading"
            type="submit"
          >
            Submit
            <b-spinner v-if="isLoading" small variant="white" />
          </b-btn>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import { POST_DRIVER } from "./actions";
import { mapState } from "vuex";
import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../mixins";

export default {
  mixins: [generalMixin, superAdminMixin],
  props: ["userItem", "refreshData", "hideModal"],
  computed: {
    ...mapState({
      driver: (state) => state.superAdminStore.driver.driver,
    }),
    isLoading() {
      return this.driver.status == "fetching" ? true : false;
    },
  },

  data() {
    return {
      form: {
        id: null,
        name: null,
        lastName: null,
        email: null,

        mobileNumber: null,
      },
      options: {
        type: "tel",
        validCharactersOnly: true,
        maxlength: 14,
      },
      telDdOptions: {
        showDialCodeInList: true,
        showFlags: true,
        showDialCodeInSelection: false,
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(2),
      },
      lastName: {
        required,
        minLength: minLength(2),
      },
      email: {
        email: true,
      },
    },
  },
  mounted() {
    if (this.userItem.id) {
      this.formatForm();
    }
  },
  methods: {
    closeModal(callback) {
      callback();
    },
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    save() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_DRIVER, payload)
        .then(() => {
          this.makeToast("Succes", "Driver created succesfully", "success");
          this.refreshData();
          this.hideModal();
        })
        .catch(() => {
          this.$root.$bvToast.toast(this.user.error.response.data.message, {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    update() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_DRIVER, payload)
        .then(() => {
          this.makeToast("Succes", "Driver updated succesfully", "success");
          this.refreshData();
          this.hideModal();
        })
        .catch(() => {
          this.$root.$bvToast.toast(this.user.error.response.data.message, {
            title: "Warning",
            variant: "warning",
          });
        });
    },

    formatPayload() {
      const payload = {
        id: this.userItem.id ? this.userItem.id : null,
        firstName: this.form.name,
        lastName: this.form.lastName,
        email: this.form.email,
        mobileNumber: this.form.mobileNumber,
      };

      return payload;
    },

    formatForm() {
      this.form.id = this.userItem.id;
      this.form.name = this.userItem.firstName;
      this.form.lastName = this.userItem.lastName;
      this.form.mobileNumber = this.userItem.mobileNumber;
      this.form.email = this.userItem.email;
    },
  },
};
</script>

<style scoped>
.append-input {
  max-width: 40%;
}
</style>
