<template>
  <div class="container-fluid">
    <div class="pt-3">
      <b-table
        show-empty
        bordered
        small
        stacked="md"
        head-variant="light"
        :items="riCompanies.data"
        :fields="fields"
        :busy="companiesStatus === 'fetching'"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" variant="yellow" />
          </div>
        </template>

        <template #cell(actions)="{ item }">
          <div class="d-flex justify-content-center">
            <b-icon
              v-b-tooltip.hover
              title="Details"
              icon="eye"
              class="h5 mx-1 cursor-pointer"
              @click="editItem(item)"
            />
            <b-icon
              v-b-tooltip.hover
              title="Delete"
              :icon="item.status !== 1 ? 'toggle-off' : 'toggle-on'"
              class="h5 cursor-pointer mx-1"
              @click="item.status !== 1 ? activateItem(item) : deleteItem(item)"
            />
          </div>
        </template>
      </b-table>
      <b-pagination
        v-if="companiesStatus !== 'fetching' && riCompanies?.data?.length > 0"
        align="end"
        :value="riCompanies.currentPage"
        :total-rows="riCompanies.total"
        :per-page="riCompanies.perPage"
        class="mb-2"
        @change="changePage"
      />
    </div>

    <b-modal
      id="riModal"
      ref="riModal"
      size="lg"
      hide-footer
      body-class="pl-0 pr-0"
      ok-disabled
      title="RI company"
    >
      <RIForm
        :refresh-data="getData"
        :hide-modal="hideModal"
        :user-item="userItem"
      />
    </b-modal>
  </div>
</template>
<script>
import { mapState } from "vuex";

import { GET_RI_LIST, POST_RI } from "./actions";
import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../../superAdmin/mixins";
import RIForm from "./RIForm.vue";
export default {
  name: "RITable",
  components: { RIForm },
  mixins: [generalMixin, superAdminMixin],
  computed: {
    ...mapState({
      riCompanies: (state) => state.superAdminStore.riCompanies.companies.data,
      companiesStatus: (state) =>
        state.superAdminStore.riCompanies.companies.status,
    }),
  },

  data() {
    return {
      fields: [
        { key: "name", label: "Name" },
        { key: "port", label: "Port" },
        { key: "street", label: "Street" },
        { key: "addressLine2", label: "Address line 2" },
        { key: "zip", label: "Zip" },
        { key: "city", label: "City" },

        { key: "state", label: "State" },

        {
          key: "actions",
          label: "Actions",
          note: false,
          details: true,
          validation: false,
          locations: true,
        },
      ],
      options: {
        assign: true,
        rolFilter: false,
        add: false,
        fun: this.getData,
      },
      userItem: {},
    };
  },
  mounted() {
    this.$store.commit("setAddFunction", this.showModal);
    this.$store.commit("setSearchFunction", this.getData);
    this.getData();
  },
  methods: {
    getData(filter = "") {
      const payload = {
        search: filter,
      };
      console.log("payload", payload);
      this.$store
        .dispatch(GET_RI_LIST, payload)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    editItem(item) {
      this.userItem = item;
      this.$bvModal.show("riModal");
    },
    changePage(page, filter = "") {
      this.isBusy = true;
      const payload = {
        search: filter,
        page: page,
      };
      this.$store
        .dispatch(GET_RI_LIST, payload)
        .then((response) => {
          this.data = response;
          this.isBusy = false;
        })
        .catch(() => {
          this.$root.$bvToast.toast("Warning", {
            title: "Warning",
            variant: "warning",
          });
        });
      this.isBusy = false;
    },
    showModal() {
      this.userItem = {};
      this.$bvModal.show("riModal");
    },
    hideModal() {
      this.$bvModal.hide("riModal");
    },
    activeItem(item) {
      this.$store
        .dispatch(POST_RI, { id: item.id, status: 1 })
        .then(() => {
          this.$root.$bvToast.toast("Ri successfully activated", {
            title: "Success",
            variant: "success",
          });
          this.getData();
        })
        .catch((error) => {
          this.$root.$bvToast.toast(
            error.data.message.charAt(0).toUpperCase() +
              error.data.message.slice(1),
            {
              title: "Error",
              variant: "warning",
            }
          );
        });
    },

    deleteItem(item) {
      this.$store
        .dispatch(POST_RI, { id: item.id, status: 0 })
        .then(() => {
          this.$root.$bvToast.toast("Ri successfully disabled", {
            title: "Success",
            variant: "success",
          });
          this.getData();
        })
        .catch((error) => {
          this.$root.$bvToast.toast(
            error.data.message.charAt(0).toUpperCase() +
              error.data.message.slice(1),
            {
              title: "Error",
              variant: "warning",
            }
          );
        });
    },
  },
};
</script>
