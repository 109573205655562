<template>
  <div>
    <div v-if="isLoading" class="text-center mt-5">
      <b-spinner></b-spinner>
    </div>

    <b-container v-else fluid class="mt-5">
      <b-row>
        <b-col xl="12">
          <VehicleCard v-if="vehicle"></VehicleCard>
        </b-col>
        <b-col xl="12" class="mt-4">
          <VehicleSalesView v-if="vehicle"></VehicleSalesView>
        </b-col>
        <b-col xl="12" class="mt-4">
          <VehicleImages v-if="vehicle" :vehicle="vehicle" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { vehicleMixin } from "../../../modules/vehicle/mixin";
import VehicleSalesView from "./VehicleSalesView";
import VehicleCard from "./VehicleCard.vue";
import VehicleImages from "./vehicle-images/VehicleImages.vue";

export default {
  name: "VehiclePage",
  mixins: [vehicleMixin],
  props: {
    stockNumber: {
      type: String,
      default: null,
    },
  },
  components: {
    VehicleSalesView,
    VehicleCard,
    VehicleImages,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  mounted() {
    this.$store.commit("setAddFunction", null);
    this.searchVehicleByStockNumber(this.stockNumber);
  },
  methods: {
    searchVehicleByStockNumber(stock = null) {
      let decryptedId = null;
      if (this.$route.params.id) {
        decryptedId = this.$CryptoJS.AES.decrypt(
          this.$route.params.id,
          "Secret Passphrase"
        ).toString(this.$CryptoJS.enc.Utf8);
      }

      this.isLoading = true;
      let payload;
      if (stock != null) {
        payload = {
          stockNumber: stock,
        };
      } else {
        payload = {
          id: decryptedId,
        };
      }

      this.$store
        .dispatch("POST_VEHICLE_BY_STOCK", payload)
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.toastMessage(
            "The vehicle could not be obtain",
            "Warning",
            "warning"
          );
        });
    },
  },
};
</script>
<style></style>
