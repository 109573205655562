
<template>
  <div class="d-flex">
    <multiselect
      v-model="selected"
      :options="options"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="false"
      :preserve-search="true"
      placeholder="Pick some option"
      label="text"
      :preselect-first="false"
      track-by="text"
    >
      <template slot="selection" slot-scope="{ values, isOpen }"
        ><span class="multiselect__single" v-if="values.length && !isOpen"
          >{{ values.length }} options selected</span
        ></template
      >
    </multiselect>
    <b-button variant="yellow" class="ml-2" @click="clearSelected()"
      >Clear
    </b-button>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "ExchangeContractTypeSelect",
  components: { Multiselect },
  data() {
    return {
      selected: "",
      options: [
        { value: "", text: "All contract" },
        { value: 0, text: "Not paid" },
        { value: 1, text: "Paid" },
        { value: 2, text: "Partially paid" },
      ],
    };
  },
  mounted() {
    this.selected = "";
    this.updateSelected();
  },
  watch: {
    selected() {
      this.updateSelected();
    },
  },
  methods: {
    updateSelected() {
      if (!Array.isArray(this.selected)) {
        this.$emit("typeChange", this.selected);
        return;
      }
      const string = this.selected.map((item) => item.value).join(",");
      this.$emit("typeChange", string);
    },
    clearSelected() {
      this.selected = null;

      this.$emit("typeChange", "");
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
</style>
