export default [
  {
    value: 5,
    text: "Super Admin",
  },
  {
    value: 1,
    text: "Admin",
  },
  {
    value: 4,
    text: "Agent",
  },
  {
    value: 7,
    text: "External agent",
  },
  {
    value: 8,
    text: "Reviewer",
  },
  {
    value: 9,
    text: "Role RI",
  }
];
