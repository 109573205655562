<template>
  <div>
    <b-container fluid class="pl-0 pr-0">
      <div class="text-left"></div>
      <div class="mt-2 mb-2" id="total-rows">
        <div class="text-right">
          <b-button
            variant="yellow"
            @click="
              resetFilters();
              getVehiclesInfo();
            "
            class="text-white mx-1"
          >
            Clear filters
          </b-button>
        </div>
      </div>
      <div class="table-responsive">
        <b-table
          show-empty
          bordered
          small
          stacked="md"
          head-variant="light"
          :items="
            vehiclesInfo.data.paginate ? vehiclesInfo.data.paginate.data : []
          "
          :fields="fields"
          :busy="vehiclesInfo.status === 'fetching'"
          :class="{ 'text-break': isMobile }"
          sticky-header
          class="text-break"
        >
          <template #head()="scope">
            <div class="table-head-menu">
              <span class=""> {{ scope.label }} </span>
              <br />
              <div class="text-center">
                <FilterColum
                  ref="filter-component"
                  :type="scope.field.key"
                  v-if="hasFilter(scope.field.key)"
                />
                <div v-else>
                  <br />
                  <span class=""></span>
                </div>
              </div>
            </div>
          </template>

          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" variant="yellow" />
            </div>
          </template>
          <!--           <template v-slot:cell(marketPlace)="{ item }">
            <div class="text-center">
              <div
                v-if="item.marketPlace"
                class="cursor-pointer"
                @click="toggleShowFullExportLocation(item.id)"
              >
                <small
                  class="text-justify"
                  v-if="
                    showFullExportLocation.show &&
                    showFullExportLocation.itemId == item.id
                  "
                >
                  {{ item.exportLocations.name }}
                </small>
                <small v-else>
                  {{ exportLocationAbbreviation(item) }}
                </small>
                <br />
                <b-img
                  :src="item.marketLogo"
                  height="20"
                  width="30"
                  class="mx-auto"
                  style="max-width: 100px"
                  alt="marketplace-logo"
                />
                <div>
                  <b-link
                    :id="item.vinNumber.vinNumber"
                    :ref="item.vinNumber.vinNumber"
                    :href="item.vinNumber.vinNumber"
                  ></b-link>
                  <small v-if="item.finalDestination" class="mt-5">
                    {{ item.finalDestination.companyId }}
                  </small>
                  <br />
                </div>
              </div>

              <span
                v-else
                class="text-danger cursor-pointer"
                @click="
                  goToInitialInformation(item, {
                    module: 'stock',
                    action: 'read',
                  })
                "
              >
                N. A.</span
              >
            </div>
          </template> -->
          <!--           <template v-slot:cell(stockNumber)="{ item }">
            <b-link
              :id="item.stockNumber"
              :ref="item.stockNumber"
              :href="item.stockNumber"
            ></b-link>
            <small
              v-if="item.stockNumber"
              class="cursor-pointer"
              :style="{
                color: getStockNumberVariant(item.vehicleFlags.stockNumber),
              }"
              @click="
                goToInitialInformation(item, {
                  module: 'stock',
                  action: 'read',
                });
                setVehicleAnchor(item.stockNumber);
              "
              style=""
            >
              <strong> {{ item.stockNumber }} </strong>
            </small>
            <br />

            <b-button
              v-clipboard:copy="item.stockNumber ? item.stockNumber : item.stockNumber"
              v-clipboard:success="copyStockNumber"
              variant="none"
              @click="stockCopiedText = item.stockNumber"
              class="p-0"
            >
              <b-icon
                v-if="stockCopied && stockCopiedText == item.stockNumber"
                icon="clipboard-check"
              />
              <b-icon v-else icon="clipboard" />
            </b-button>

            <small
              v-if="!item.stockNumber"
              class="text-danger cursor-pointer"
              @click="
                goToInitialInformation(item, {
                  module: 'stock',
                  action: 'read',
                });
                setVehicleAnchor(item.stockNumber);
              "
            >
              N. S.</small
            >
            <br />

          </template> -->
          <template v-slot:cell(vinNumber)="{ item }">
            <small>
              <span
                class="cursor-pointer"
                @click="
                  goToPublicPage(item.code);
                  setVehicleAnchor(item.stockNumber);
                "
              >
                {{ item.vinNumber }}
              </span>
              <br />
              <b-button
                class="p-0"
                v-clipboard:copy="
                  item.vinNumber ? item.vinNumber : item.vinNumber
                "
                v-clipboard:success="copyVinNumber"
                variant="none"
                @click="vinCopiedText = item.vinNumber"
              >
                <b-icon
                  v-if="vinCopied && vinCopiedText == item.vinNumber"
                  icon="clipboard-check"
                />
                <b-icon v-else icon="clipboard" />
              </b-button>
              <b-button
                class="p-0"
                v-if="isMobile"
                @click="goToVehicleUnitPage(row.item.id)"
              >
                <b-icon icon="eye"></b-icon>
              </b-button>
            </small>
          </template>
          <template v-slot:cell(year)="{ item }">
            <div>
              {{ item.year }}
            </div>
          </template>
          <template v-slot:cell(model)="{ item }">
            <div>
              {{ item.model }}
            </div>
          </template>
          <template v-slot:cell(salePrice)="{ item }">
            <div>
              {{ item.salePrice | currency }}
            </div>
          </template>
          <template v-slot:cell(saleDate)="{ item }">
            <div>
              {{ item.saleDate | formatDate }}
            </div>
          </template>
          <template v-slot:cell(reserveDate)="{ item }">
            <div>
              {{ item.reserveDate | formatDate }}
            </div>
          </template>
          <template v-slot:cell(reservePrice)="{ item }">
            <div>
              {{ item.reservePrice | currency }}
            </div>
          </template>
        </b-table>
      </div>

      <div>
        <b-pagination
          :value="
            vehiclesInfo.data.paginate
              ? vehiclesInfo.data.paginate.currentPage
              : 1
          "
          :total-rows="
            vehiclesInfo.data.paginate ? vehiclesInfo.data.paginate.total : 0
          "
          :per-page="
            vehiclesInfo.data.paginate ? vehiclesInfo.data.paginate.perPage : 25
          "
          class="mb-2"
          align="end"
          @change="changePage($event)"
        />
      </div>

      <!--       <SelectTableFields :fields-from-table="this.fields" @getCookie="getCookie()" />
 -->
    </b-container>
  </div>
  <!--   <div v-else>
    <div class="container mt-auto text-center mt-5">
      <b-spinner
        style="width: 10rem; height: 10rem"
        variant="yellow"
        class="align-middle mt-5"
      />
    </div>
  </div> -->
</template>

<script>
import { mapState } from "vuex";
import { defaultFields, optionalFields } from "./vehicleTableFields";
import { clientVehicleMixin } from "./mixins";

import FilterColum from "./filters/Filter";
/* import StatusFilter from "./filters/Status";
 */
export default {
  name: "ClientVehicleTable",
  components: {
    FilterColum,
    /* StatusFilter */
  },
  mixins: [clientVehicleMixin],
  data() {
    return {
      fields: [],
      agentsSelected: [],
      vinCopied: null,
      vinCopiedText: "",
      stockCopied: null,
      stockCopiedText: "",
      prevRoute: {},
      showFullExportLocation: {
        show: false,
        itemId: null,
      },
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  computed: {
    ...mapState({
      vehiclesInfo: (state) => state.client.vehicles.vehicles,
      vehiclesExcel: (state) => state.superAdminStore.vehiclesExcel,
      vehicleAnchor: (state) => state.superAdminStore.vehicleAnchor,
      profile: (state) => state.auth.loginStore.profile.data,
    }),
    isMobile() {
      return this.$vssWidth <= 990 ? true : false;
    },
    corporation() {
      const corporation = localStorage.getItem("corporation");
      let corporationSelect = this.profile.user.corporationPermissions.find(
        (c) => c.id == corporation
      );

      return corporationSelect
        ? corporationSelect
        : this.profile.user.corporationPermissions[0];
    },
  },
  mounted() {
    /*    if (this.prevRoute.name == "superadmin.vehicles.sold-table") {
      this.resetFilters();
    } */
    this.getVehiclesInfo();
    this.saveCookie();
    this.getCategories();
  },

  methods: {
    findAgent(name) {
      console.log("Find agent with name:", name);
    },
    selectAgent(agent) {
      this.agentsSelected.push(agent);
    },
    updateAgent(agent) {
      const index = this.agentsSelected.indexOf(agent);
      this.agentsSelected.splice(index, 1);
    },
    editColumns() {
      this.$bvModal.show("select-vehicle-table-fields");
    },
    getCookie() {
      this.fields = defaultFields;
    },
    saveCookie() {
      if (!this.$cookies.isKey("fields")) {
        this.fields = [...defaultFields, ...optionalFields];
        this.$cookies.set(
          "fields",
          JSON.stringify([...defaultFields, ...optionalFields])
        );
      } else {
        this.getCookie();
      }
    },
    copyVinNumber(vin) {
      this.vinCopied = vin;
    },
    copyStockNumber(stock) {
      this.stockCopied = stock;
    },

    formatFileName(module) {
      let fileName = "";
      const today = new Date();
      fileName = `${module}-${today.getFullYear()}-${today.getMonth()}-${today.getDate()}-${today.getHours()}-${today.getMinutes()}-${today.getSeconds()}`;
      return fileName;
    },

    setVehicleAnchor(anchor) {
      this.$store.commit("SET_VEHICLE_ANCHOR", anchor);
    },
    toggleShowFullExportLocation(itemId) {
      this.showFullExportLocation.show = !this.showFullExportLocation.show;
      this.showFullExportLocation.itemId = itemId;
    },
    exportLocationAbbreviation(item) {
      if (item.exportLocations != null) {
        return item.exportLocations.name.substring(0, 3);
      }
      return "";
    },
    getCategories() {
      this.$store
        .dispatch("GET_CATEGORIES")
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("Could not get the category list ", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    goToPublicPage(code) {
      const link = `${window.location.origin}/#/public/vehicle/${code}`;
      window.open(link, "_blank");
    },
  },
};
</script>

<style>
.icon-color {
  color: #aeaeaf;
}

.color-red td {
  background-color: red !important;
}

.table-head-menu {
  justify-content: space-between !important;
}

.table-sm th,
.table-sm td {
  padding: 0.25rem !important;
}
</style>
