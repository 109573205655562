<template>
  <div>
    <div class="row mx-0">
      <b-col xs="12" md="12" lg="10" class="pr-0 pl-0 mx-0">
        <b-form-group
          id="fieldset-1"
          label-for="input-1"
          invalid-feedback="error as"
          :state="true"
        >
          <b-dropdown
            :text="this.selected != null ? priceName : 'Select'"
            block
            variant="white"
            style="border: 1px solid #ced4da"
            menu-class="scrollable-menu"
            state="is-active"
          >
            <b-dropdown-item
              v-if="vehiclePrice.retailXclean != 0"
              @click="
                formatSelection('X Clean');
                onClickButton();
              "
            >
              X Clean
            </b-dropdown-item>
            <b-dropdown-item
              v-if="vehiclePrice.retailClean != 0"
              @click="
                formatSelection('Clean');
                onClickButton();
              "
            >
              Clean
            </b-dropdown-item>

            <b-dropdown-item
              v-if="vehiclePrice.retailAvg != 0"
              @click="
                formatSelection('Avg');
                onClickButton();
              "
            >
              Avg
            </b-dropdown-item>

            <b-dropdown-item
              v-if="vehiclePrice.retailRough != 0"
              @click="
                formatSelection('Rough');
                onClickButton();
              "
            >
              Rough
            </b-dropdown-item>
          </b-dropdown>
        </b-form-group>
      </b-col>

      <b-col md="12" lg="2" class="px-1 mx-0 text-center">
        <b-icon
          v-if="userHasPermission('vi', 'write')"
          icon="check-circle"
          font-scale="1.20"
          class="mr-1 mt-2 cursor-pointer"
          variant="success"
          @click="updateReferalPrice()"
        >
          Successfully registered vehicle
        </b-icon>
      </b-col>
    </div>
  </div>
</template>

<script>
import { PUT_VEHICLE_BY_VIN } from "../../actions";
import { vehicleMixin } from "../../mixin";
import { mapState } from "vuex";
import { superAdminMixin } from "../../../superAdmin/mixins";

export default {
  mixins: [vehicleMixin, superAdminMixin],
  props: ["vehiclePrice", "refreshData", "vehiclePriceSelected"],
  computed: {
    priceName() {
      return this.selected.label;
    },
    ...mapState({
      vehicle: (state) => state.vehicle.vehicleByVin.data.data,
    }),
    state() {
      return this.vehicle.state;
    },
  },
  data() {
    return {
      selected: null,
      model: null,
      labels: {
        retailXclean: "X Clean",
        retailClean: "Clean",
        retailAvg: "Avg",
        retailRough: "Rough",
      },
    };
  },
  created() {
    this.selected = null;
    if (this.vehiclePriceSelected != null) {
      this.formatSelection(this.labels[this.vehiclePriceSelected]);
    } else {
      this.selected = null;
    }
  },
  methods: {
    onClickButton() {
      this.vehiclePrice.referralPrice = this.selected.price;
      this.vehiclePrice.typePrice = this.formaTypePrice(this.selected.label);
      /*   this.$emit("input", this.vehiclePrice); */
    },
    formatSelection(label) {
      this.selected = {
        label: label,
      };
    },
    formaTypePrice(label) {
      var keys = Object.entries(this.labels);
      let typePrice;
      keys.forEach((key) => {
        if (key[1] == label) {
          typePrice = key[0];
        }
      });
      return typePrice;
    },
    updateReferalPrice() {
      const payload = {
        id: this.vehicle.id,
        typePrice: this.formaTypePrice(this.selected.label),
      };
      this.$store
        .dispatch(PUT_VEHICLE_BY_VIN, payload)
        .then(() => {
          this.toastMessage(
            "Vehicle information saved successfully.",
            "Success",

            "success"
          );
          if (this.refreshData) {
            this.refreshData(this.$route.params.vin);
          } else {
            this.searchVinNumber(this.$route.params.vin);
          }
        })
        .catch(() => {
          this.toastMessage(
            "The vehicle could not be obtain",
            "Warning",
            "warning"
          );
        });
    },
  },
};
</script>

<style scoped></style>
