import * as constants from './actions';
import axios from '@/plugins/axios.js';

function getDefaultState() {
  return {
    vehiclePublicData: {
      status: null,
      data: {},
      error: null,

    },
    publicWorkOrderData: {
      status: null,
      data: {},
      error: null,
    }

  };
}

const actions = {

  [constants.GET_VEHICLE_PUBLIC_DATA]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_VEHICLE_PUBLIC_DATA);
      const response = await axios.get(`vehiclePublicCard`, { params: payload });
      commit(constants.GET_VEHICLE_PUBLIC_DATA_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLE_PUBLIC_DATA_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_WORK_ORDER_PUBLIC_DATA]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_WORK_ORDER_PUBLIC_DATA);
      const response = await axios.put(`workOrder/markAllCanceled`, payload);
      commit(constants.PUT_WORK_ORDER_PUBLIC_DATA_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_WORK_ORDER_PUBLIC_DATA_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {

  [constants.GET_VEHICLE_PUBLIC_DATA]: (state) => {
    state.vehiclePublicData.status = 'loading';
  },
  [constants.GET_VEHICLE_PUBLIC_DATA_SUCCESS]: (state, response) => {
    state.vehiclePublicData.status = 'success';
    state.vehiclePublicData.data = response.data;
  },
  [constants.GET_VEHICLE_PUBLIC_DATA_ERROR]: (state, error) => {
    state.vehiclePublicData.status = 'error';
    state.vehiclePublicData.error = error;
  },
  [constants.PUT_WORK_ORDER_PUBLIC_DATA]: (state) => {
    state.publicWorkOrderData.status = 'loading';
  },
  [constants.PUT_WORK_ORDER_PUBLIC_DATA_SUCCESS]: (state, response) => {
    state.publicWorkOrderData.status = 'success';
    state.publicWorkOrderData.data = response.data;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations
};
