<template>
  <b-modal
    id="atac-modal"
    ref="modal"
    modal-class="modal-fullscreen"
    centered
    scrollable
    size="sm"
    title="Select printer"
    hide-footer
  >
    <b-form-select v-model="printerSelected" class="mb-3">
      <b-form-select-option
        v-for="(printer, i) in printers"
        :key="i"
        :value="printer"
        >{{ printer }}</b-form-select-option
      >
    </b-form-select>

    <button @click="onSelectPrinter()" class="float-right">
      Select printer
    </button>
  </b-modal>
</template>

<script>
import qz from "qz-tray";

import { superAdminMixin } from "../../mixins";

export default {
  name: "AtacModal",
  components: {},
  mixins: [superAdminMixin],

  data() {
    return {
      printers: null,
      printerSelected: null,
    };
  },
  mounted() {},
  methods: {
    findPrinters() {
      qz.printers
        .find()
        .then((data) => {
          console.log(data);

          this.printers = data;
        })
        .catch(function (e) {
          console.error(e);
        });
    },
    onSelectPrinter() {},
  },
};
</script>

<style scoped>
</style>
