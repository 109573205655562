export const GET_DAILY_REPORTS = 'GET_DAILY_REPORTS';
export const GET_DAILY_REPORTS_SUCCESS = 'GET_DAILY_REPORTS_SUCCESS';
export const GET_DAILY_REPORTS_ERROR = 'GET_DAILY_REPORTS_ERROR';

export const POST_DAILY_REPORTS_CONFIGURATION = 'POST_DAILY_REPORTS_CONFIGURATION';
export const POST_DAILY_REPORTS_CONFIGURATION_SUCCESS = 'POST_DAILY_REPORTS_CONFIGURATION_SUCCESS';
export const POST_DAILY_REPORTS_CONFIGURATION_ERROR = 'POST_DAILY_REPORTS_CONFIGURATION_ERROR';

export const GET_DAILY_REPORT_FILE_EXCEL = 'GET_DAILY_REPORT_FILE_EXCEL';
export const GET_DAILY_REPORT_FILE_EXCEL_SUCCESS = 'GET_DAILY_REPORT_FILE_EXCEL_SUCCESS';
export const GET_DAILY_REPORT_FILE_EXCEL_ERROR = 'GET_DAILY_REPORT_FILE_EXCEL_ERROR';