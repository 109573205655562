<template>
  <b-modal
    id="reserve-modal"
    ref="modal"
    centered
    no-close-on-backdrop
    scrollable
    size="lg"
    title="Reserve"
    @show="getInformation()"
    @hidden="getVehiclesInfo()"
    hide-footer
  >
    <VehicleDetails
      v-if="vehicle"
      :vehicle="vehicle"
      :color="vehicle.vehicleInformation.color"
    />
    <!--     TODO: user and create date
 -->
    <b-row v-if="reserveStore && reserveStore.registerUser">
      <b-col sm="12" md="12" lg="12" xl="12">
        <h3>
          Reserved by:
          <i
            >{{ reserveStore.registerUser.firstName || "----" }}
            {{ reserveStore.registerUser.lastName || "----" }} -
            {{ reserveStore.createdAt | formatDate }}</i
          >
        </h3>
      </b-col>
    </b-row>

    <ReserveRegistrationForm />
  </b-modal>
</template>

<script>
import { generalMixin } from "@/modules/mixin.js";
import ReserveRegistrationForm from "./ReserveRegistrationForm.vue";
import { superAdminMixin } from "@/modules/superAdmin/mixins.js";
import { mapState } from "vuex";
import VehicleDetails from "@/modules/superAdmin/components/VehicleDetails.vue";
import { GET_RESERVE } from "./actions";
export default {
  name: "ReserveModal",
  components: {
    ReserveRegistrationForm,
    VehicleDetails,
  },
  mixins: [generalMixin, superAdminMixin],
  computed: {
    ...mapState({
      reserveStore: (state) =>
        state.superAdminStore.componentStore.reserve.reserve.data,
      vehicle: (state) => state.superAdminStore.vehicle,
    }),
  },
  methods: {
    getInformation() {
      this.$store
        .dispatch(GET_RESERVE, { vehiclesId: this.vehicle.id })
        .then(() => {});
    },
  },
};
</script>

<style></style>
 