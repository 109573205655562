export const POST_SOLD_NOTE = "POST_SOLD_NOTE";
export const POST_SOLD_NOTE_SUCCESS = "POST_SOLD_NOTE_SUCCESS";
export const POST_SOLD_NOTE_ERROR = "POST_SOLD_NOTE_ERROR";

export const DELETE_SOLD_NOTE = "DELETE_SOLD_NOTE";
export const DELETE_SOLD_NOTE_SUCCESS = "DELETE_SOLD_NOTE_SUCCESS";
export const DELETE_SOLD_NOTE_ERROR = "DELETE_SOLD_NOTE_ERROR";

export const GET_SOLD_NOTES = "GET_SOLD_NOTES";
export const GET_SOLD_NOTES_SUCCESS = "GET_SOLD_NOTES_SUCCESS";
export const GET_SOLD_NOTES_ERROR = "GET_SOLD_NOTES_ERROR";
