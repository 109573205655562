<template>
  <b-modal
    :id="modalId"
    ref="modal"
    centered
    no-close-on-backdrop
    title="New pic"
    @show="show"
  >
    <div v-if="vehicleParts">
      <div v-if="imageToUpload">
        <label>Preview</label>
        <img
          class="img-thumbnail main-img"
          :src="imageToUpload"
          alt="preview"
        />
      </div>
      <b-form-group label="Image">
        <b-form-file
          v-model="photo"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          accept="image/*"
          @input="onInputImageToUpload"
        />
      </b-form-group>
      {{ vehicleParts }}
      <b-form-group label="Part">
        <b-form-select v-model="partsId">
          <b-form-select-option
            v-for="(p, i) of vehicleParts.data"
            :key="i"
            :value="p.id"
          >
            {{ p.name }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>
    </div>
    <template v-slot:modal-footer="{ ok }">
      <div class="d-flex justify-content-end">
        <b-button
          :disabled="!photo || !partsId"
          variant="success"
          @click="uploadImage(ok)"
        >
          <b-spinner
            v-if="uploadPhotoStatus === 'fetching'"
            small
            variant="white"
          />
          <b-icon v-else icon="plus" />
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import { POST_UPLOAD_PHOTO } from "./actions";
import { registryVehicleMixin } from "../mixins";
export default {
  mixins: [registryVehicleMixin],
  props: ["vehicleId"],
  data() {
    return {
      imageToUpload: "",
      modalId: "modal-add-pic",
      partsId: null,
      photo: null,
    };
  },
  computed: {
    ...mapState({
      vehicleParts: (state) =>
        state.adminStore.vehicles.carRegistry.uploadVehiclePhotosStore.parts
          .data,
      uploadPhotoStatus: (state) =>
        state.adminStore.vehicles.carRegistry.uploadVehiclePhotosStore
          .uploadPhoto.status,
    }),
  },
  methods: {
    show() {
      this.clearForm();
    },
    onInputImageToUpload(file) {
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
      }
      reader.onloadend = () => {
        this.imageToUpload = reader.result;
      };
    },
    clearForm() {
      this.imageToUpload = null;
      this.partsId = null;
    },
    uploadImage(ok) {
      const data = new FormData();
      data.set("vehiclesId", this.vehicleId);
      data.set("partsId", this.partsId);
      data.set("photo", this.photo);
      this.$store
        .dispatch(POST_UPLOAD_PHOTO, data)
        .then(() => {
          this.getVehicleByVin();
          this.$root.$bvToast.toast("Photo assigned successfully", {
            title: "Success",
            variant: "success",
          });
          this.clearForm();
          ok();
        })
        .catch(() => {
          this.$root.$bvToast.toast("The photo could not be uploaded", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
  },
};
</script>

<style></style>
