<template>
  <div>
    <div class="container-fluid mt-3">
      <div>
        <TableBase
          :items="users.data"
          :fields="fields"
          :options="options"
          :busy="users.status === 'fetching' ? true : false"
        >
          <template v-slot:rightSide>
            <div></div>
          </template>
        </TableBase>
      </div>

      <b-modal
        id="detailsModal"
        ref="detailsModal"
        size="lg"
        hide-footer
        body-class=""
        ok-disabled
        @hidden="userItem = {}"
      >
        <Brand />
        <UserForm
          :user-item="userItem"
          :refresh-data="getData"
          :hide-modal="hideModal"
        />
      </b-modal>
    </div>
  </div>
</template>
<script>
import TableBase from "@/modules/shared/table.vue";
import UserForm from "./Form";
import Brand from "@/modules/shared/brand.vue";
import {
  DELETE_USER_BUYER,
  PUT_USER_BUYER,
} from "@/modules/buyer/users/actions";
import { GET_USERS } from "./actions";
import { mapState } from "vuex";

import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../../superAdmin/mixins";
export default {
  components: { TableBase, UserForm, Brand },
  mixins: [generalMixin, superAdminMixin],
  computed: {
    ...mapState({
      users: (state) => state.adminStore.agents.users,
    }),
    hasPermission() {
      return this.userHasPermission("agents", "write");
    },
    fields() {
      return [
        { key: "nickname", label: "Nickname" },

        { key: "firstName", label: "First name" },
        { key: "lastName", label: "Last name" },
        { key: "corporation.stockCode", label: "Stock code" },
        { key: "email", label: "Email" },
        { key: "userDetails.mobileNumber", label: "Phone number" },
        {
          key: "actions",
          label: "Actions",
          note: false,
          details: true,
          validation: false,
          delete: this.hasPermission,
        },
      ];
    },
  },
  data() {
    return {
      options: {
        assign: false,
        rolFilter: false,
        add: false,
        fun: this.getData,
      },
      userItem: {},
    };
  },
  mounted() {
    this.$store.commit(
      "setSearchFunction",
      this.userHasPermission("agents", "read") ? this.getData : null
    );
    this.$store.commit(
      "setAddFunction",
      this.userHasPermission("agents", "write") ? this.showModal : null
    );
    this.getData();
  },
  methods: {
    async getData(filter = "") {
      const payload = {
        roleName: "ROLE_ADMIN,ROLE_SUPER_ADMIN,ROLE_AGENT,ROLE_EXTERNAL_AGENT",
        search: filter,
      };
      this.$store
        .dispatch(GET_USERS, payload)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    async deleteItem(item) {
      const confirm = await this.showConfirmDeleteBox();
      if (!confirm) return;
      const payload = {
        id: item.id,
        status: 0,
      };
      this.$store
        .dispatch(DELETE_USER_BUYER, payload)
        .then(() => {
          this.getData();
        })
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    async activateItem(item) {
      const payload = {
        id: item.id,
        status: 1,
      };
      this.$store
        .dispatch(PUT_USER_BUYER, payload)
        .then(() => {
          this.makeToast("Success", "User updated successfully", "success");
          this.getData();
        })
        .catch(() => {
          this.$root.$bvToast.toast(this.user.error.response.data.message, {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    showModal() {
      this.$bvModal.show("detailsModal");
    },
    editItem(item) {
      this.userItem = item;
      this.$bvModal.show("detailsModal");
    },
    changePage(page, filter = "") {
      const payload = {
        roleName: "ROLE_ADMIN,ROLE_SUPER_ADMIN,ROLE_AGENT,ROLE_EXTERNAL_AGENT",
        search: filter,
        page: page,
      };
      this.$store
        .dispatch(GET_USERS, payload)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },

    hideModal() {
      this.$bvModal.hide("detailsModal");
    },
  },
};
</script>
