<template>
  <div class="d-flex justify-content-around">
    <multiselect
      v-model="company"
      :options="companies"
      label="companyName"
      trackBy="id"
      @input="updateCompany"
    ></multiselect>

    <b-icon
      v-if="companySelectedId"
      icon="x-circle"
      class="my-auto mx-2 cursor-pointer"
      scale="1.7"
      @click="deleteCompany()"
    ></b-icon>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "CompanySelect",
  components: { Multiselect },
  props: {
    companySelectedId: {
      type: Number,
      required: false,
    },
  },
  computed: {
    ...mapState({
      companies: (state) => state.vehicle.clientsList.data,
      companiesStatus: (state) => state.vehicle.clientsList.status,
    }),
  },

  data() {
    return {
      company: null,
    };
  },
  created() {
    if (this.companySelectedId) {
      this.company = this.companies.find(
        (company) => company.id === this.companySelectedId
      );
    }
  },
  watch: {
    companySelectedId() {
      this.company = this.companies.find(
        (company) => company.id === this.companySelectedId
      );
    },
  },

  methods: {
    updateCompany() {
      this.$emit("updateCompany", this.company);
    },
    deleteCompany() {
      this.$emit("updateCompany", null);
    },
  },
};
</script>

<style>
</style>