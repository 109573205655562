<template>
  <b-card>
    <b-row>
      <b-col sm="12" md="6" lg="4" xl="4" class="d-flex justify-content-center mt-3">
        <b-tabs v-if="!loadingDost" content-class="mt-3" justified pills>
          <b-tab
            title="Exterior"
            :active="currentTab === 'exterior'"
            @click="changeTab('exterior')"
          >
            <AutoPartsExterior :dots-exterior="dotsExterior" :vehicle="vehicle" />
          </b-tab>
          <b-tab
            title="Interior"
            :active="currentTab === 'interior'"
            @click="changeTab('interior')"
          >
            <AutoPartsInterior :dots-exterior="dotsInterior" :vehicle="vehicle" />
          </b-tab>
        </b-tabs>
        <div v-else class="text-center text-purple-blue my-2">
          <b-spinner variant="purple-blue" class="align-middle" />
          <p>Loading...</p>
        </div>
      </b-col>
      <b-col sm="12" md="6" lg="4" xl="4" class="mt-3">
        <DamagePhoto />
      </b-col>
      <b-col sm="12" md="6" lg="4" xl="4" class="mt-3">
        <RepairPhoto />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import AutoPartsExterior from "./VehicleImagesExterior.vue";
import AutoPartsInterior from "./VehicleImagesInterior.vue";
import CompanyCarsService from "@/services/companyCarsService";
import DamagePhoto from "./VehicleImageDamage.vue";
import RepairPhoto from "./VehicleImageRepair.vue";
export default {
  props: {
    vehicle: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    AutoPartsExterior,
    AutoPartsInterior,
    DamagePhoto,
    RepairPhoto,
  },
  data() {
    return {
      currentTab: "exterior",
      dotsExterior: [],
      dotsInterior: [],
      loadingDost: true,
    };
  },
  mounted() {
    this.getVehiclesDots();
    this.$store.commit("visualInspection/setDamageImageModal", null);
    this.$store.commit("visualInspection/setRepairImageModal", null);
  },
  methods: {
    changeTab(tab) {
      this.currentTab = tab;
      this.$store.commit("visualInspection/setDamageImageModal", null);
      this.$store.commit("visualInspection/setRepairImageModal", null);
    },
    async getVehiclesDots() {
      this.loadingDost = true;
      this.$store.commit("general/setEndPointUrl", "vehicle");
      const { data } = await CompanyCarsService.getVinByNumber(this.vehicle.stockNumber);
      if (data.damagePhotos) {
        this.listDamage = data.damagePhotos;
        this.dotsExterior = [];
        this.dotsInterior = [];
        for (const damage of data.damagePhotos) {
          if (damage.type === "interior") {
            this.dotsInterior = [
              ...this.dotsInterior,
              {
                x: damage.coordinateX,
                y: damage.coordinateY,
                color: damage.color,
                damagePhoto: damage.damagePhoto,
                status: damage.status,
                repairPhoto: damage.repairPhoto ?? "",
              },
            ];
          } else if (damage.type === "exterior") {
            this.dotsExterior = [
              ...this.dotsExterior,
              {
                x: damage.coordinateX,
                y: damage.coordinateY,
                color: damage.color,
                damagePhoto: damage.damagePhoto,
                status: damage.status,
                repairPhoto: damage.repairPhoto ?? "",
              },
            ];
          }
        }
        this.loadingDost = false;
      }

      this.loadingDost = false;
    },
  },
};
</script>

<style></style>
