<template>
  <div>
    <b-form class="m-4" @submit.stop.prevent="updateStatus()">
      <h3>Register date</h3>
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group label-size="sm">
            <template>
              Account date <span class="text-danger">*</span>
            </template>
            <input
              v-model="form.date"
              type="datetime-local"
              class="form-control"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12" md="6" lg="6" xl="6" />
        <b-col sm="12" md="3" lg="3" xl="3" />
        <b-col sm="12" md="3" lg="3" xl="3">
          <b-btn block size="lg" class="btn btn-success" type="submit">
            Submit
            <b-spinner
              v-if="exportLoad.status == 'fetching'"
              small
              variant="white"
            />
          </b-btn>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { PUT_LOAD_STATUS } from "./actions";
export default {
  name: "LoadDate",
  props: ["load", "refreshData"],
  computed: {
    ...mapState({
      exportLoad: (state) => state.superAdminStore.exportLoad.exportLoad,
    }),
  },
  data() {
    return {
      form: {
        id: null,
        date: null,
      },
    };
  },
  mounted() {
    if (this.load) {
      this.setDefaultDateToday();
    }
  },
  methods: {
    setDefaultDateToday() {
      var myCurrentDate = new Date();

      this.form.date = this.$options.filters.formatDateTimeInput(myCurrentDate);
    },
    updateStatus() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(PUT_LOAD_STATUS, payload)
        .then(() => {
          this.$root.$bvToast.toast("Export load updated successfully", {
            title: "Success",
            variant: "success",
          });
          this.refreshData();
          //close modal
          this.$root.$bvModal.hide("date-modal");
        })
        .catch(() => {
          this.$root.$bvToast.toast("Export load could not be updated", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    formatPayload() {
      let payload = {
        id: this.load.id,
        status: null,
      };
      if (this.load.status == 1) {
        payload.status = 2;
        payload.shippingDate = this.form.date;
      }
      if (this.load.status == 2) {
        payload.status = 3;
        payload.finalDate = this.form.date;
      }
      return payload;
    },
  },
};
</script>

<style>
</style>