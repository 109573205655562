export const GET_LIENS = "GET_LIENS";
export const GET_LIENS_SUCCESS = "GET_LIENS_SUCCESS";
export const GET_LIENS_ERROR = "GET_LIENS_ERROR";

export const POST_LIEN = "POST_LIEN";
export const POST_LIEN_SUCCESS = "POST_LIEN_SUCCESS";
export const POST_LIEN_ERROR = "POST_LIEN_ERROR";

export const PUT_LIEN = "PUT_LIEN";
export const PUT_LIEN_SUCCESS = "PUT_LIEN_SUCCESS";
export const PUT_LIEN_ERROR = "PUT_LIEN_ERROR";
