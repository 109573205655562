var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{ref:"modal",attrs:{"id":_vm.modalId,"size":"md","title":"Company validation","scrollable":""},on:{"show":function($event){return _vm.getCompanyValidationInfo()},"hidden":function($event){_vm.resetForm();
    _vm.refreshData();}},scopedSlots:_vm._u([{key:"modal-footer",fn:function({ ok }){return [_c('b-btn',{attrs:{"disabled":_vm.sendCompanyRegistrationEmailStatus,"variant":"info"},on:{"click":function($event){return _vm.sendCompleRegistrationEmail()}}},[(_vm.sendCompanyRegistrationEmailStatus)?_c('b-spinner',{attrs:{"variant":"white","small":""}}):_c('span',[_vm._v("Send complete registration email")])],1),_c('b-btn',{attrs:{"variant":"pink"},on:{"click":function($event){return _vm.changeCompanyStatus(ok, 3)}}},[_vm._v(" Reject ")]),(
        _vm.formValidation.companyName &&
          _vm.formValidation.taxid &&
          _vm.formValidation.dealerLicense
      )?_c('b-btn',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.changeCompanyStatus(ok, 1)}}},[_c('span',[_vm._v("Accept")])]):_vm._e(),(
        !(
          _vm.formValidation.companyName &&
          _vm.formValidation.taxid &&
          _vm.formValidation.dealerLicense
        )
      )?_c('b-btn',{attrs:{"variant":"yellow"},on:{"click":function($event){return _vm.updateValidations(ok)}}},[_c('span',[_vm._v("Save")])]):_vm._e()]}}])},[_c('Brand'),(!_vm.ready)?_c('div',{staticClass:"align-self-center text-center"},[_c('b-spinner',{staticClass:"align-self-center",attrs:{"label":"Busy"}})],1):_vm._e(),(_vm.ready)?_c('div',{staticClass:"m-1 text-break"},[_c('b',[_vm._v("Company name:")]),(_vm.companyItem.companyName)?_c('b-form-checkbox',{attrs:{"name":"checkbox-5","checked":_vm.formValidation.companyName,"value":1,"unchecked-value":0},model:{value:(_vm.formValidation.companyName),callback:function ($$v) {_vm.$set(_vm.formValidation, "companyName", $$v)},expression:"formValidation.companyName"}},[_vm._v(" "+_vm._s(_vm.companyItem.companyName)+" ")]):_c('div',[_vm._v(" No Company name ")]),_c('br'),_c('b',[_vm._v("Taxid:")]),(_vm.companyItem.taxid)?_c('b-form-checkbox',{attrs:{"id":"checkbox-1","name":"checkbox-1","value":1,"unchecked-value":0},model:{value:(_vm.formValidation.taxid),callback:function ($$v) {_vm.$set(_vm.formValidation, "taxid", $$v)},expression:"formValidation.taxid"}},[_vm._v(" "+_vm._s(_vm.companyItem.taxid)+" ")]):_c('div',[_vm._v(" No taxid ")]),_c('br'),_c('b',[_vm._v("Dealer license:")]),(_vm.companyItem.dealerLicense)?_c('b-form-checkbox',{attrs:{"id":"checkbox-3","name":"checkbox-3","value":1,"unchecked-value":0},model:{value:(_vm.formValidation.dealerLicense),callback:function ($$v) {_vm.$set(_vm.formValidation, "dealerLicense", $$v)},expression:"formValidation.dealerLicense"}},[_vm._v(" "+_vm._s(_vm.companyItem.dealerLicense)+" ")]):_c('div',[_vm._v(" No Dealer license ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }