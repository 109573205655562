<template>
  <b-row>
    <b-col xs="12" sm="12" md="6" lg="6" xl="6" class="mt-3">
      Vehicles
      <div>
        <b-form-input
          size="sm"
          aria-describedby="input-tps-live-feedback"
          maxlength="100"
          v-model="search"
          class="mb-1"
          placeholder="Search"
          @input="searchVehicles(search)"
        />
      </div>
      <div class="overflow-auto max-height-35">
        <draggable
          :list="vehiclesFree"
          group="stockNumber"
          @start="drag = true"
          @end="drag = false"
        >
          <VehicleCard
            v-for="vehicle in vehiclesFree"
            :key="vehicle.id"
            :vehicle="vehicle"
          >
          </VehicleCard>
        </draggable>
      </div>
    </b-col>
    <b-col xs="12" sm="12" md="6" lg="6" xl="6" class="mt-3">
      Bulk transfer
      <div class="overflow-auto max-height-35">
        <draggable
          :list="vehiclesSelected"
          group="stockNumber"
          @start="drag = false"
          @end="drag = false"
        >
          <b-card v-if="vehiclesSelected.length == 0">
            Move vehicles here
          </b-card>
          <b-card v-for="vehicle in vehiclesSelected" :key="vehicle.id">
            <b-row>
              <b-col cols="10">
                <div>
                  {{ vehicle.stockNumber }} - {{ vehicle.vinNumber }} <br />
                  {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model
                  }}<br />
                  {{ vehicle.color }}
                </div>
              </b-col>
              <b-col cols="2">
                <div>
                  <b-icon
                    icon="x-circle"
                    class="cursor-pointer"
                    @click="remove(vehicle)"
                  ></b-icon>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </draggable>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import draggable from "vuedraggable";
import { mapState } from "vuex";
import { GET_VEHICLES_BULK_TRANSFER_SUCCESS } from "./actions";
import VehicleCard from "./VehicleCard.vue";
export default {
  name: "VehiclesList",
  props: ["vehiclesPreSelected"],
  components: {
    draggable,
    VehicleCard,
  },
  computed: {
    ...mapState({
      vehicles: (state) => state.superAdminStore.bulkTransfer.vehicles,
    }),
    vehiclesFree() {
      let vehicles = [...this.vehicles.data];
      let vehiclesFiltered = vehicles.filter(
        (vehicle) => !this.vehiclesSelected.find((v) => v.id == vehicle.id)
      );
      if (this.search != null && this.search != "") {
        let vehiclesFree = vehicles.filter(
          (vehicle) => !this.vehiclesSelected.find((v) => v.id == vehicle.id)
        );
        let vehiclesFiltered = vehiclesFree.filter((vehicle) => {
          return (
            vehicle.stockNumber
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            vehicle.vinNumber.toLowerCase().includes(this.search.toLowerCase())
          );
        });
        return vehiclesFiltered;
      }
      return vehiclesFiltered;
    },
  },
  data() {
    return {
      vehiclesSelected: [],
      search: null,
    };
  },
  watch: {
    vehiclesSelected() {
      this.$emit("vehicles-selected", this.vehiclesSelected);
    },
  },
  methods: {
    remove(vehicle) {
      this.vehiclesSelected.splice(this.vehiclesSelected.indexOf(vehicle), 1);
      let newFreeVehicle = this.vehicles.data.find((v) => v.id == vehicle.id);
      if (!newFreeVehicle) {
        let vehiclesNew = this.vehiclesFree;
        vehiclesNew.push(vehicle);
        this.$store.commit(GET_VEHICLES_BULK_TRANSFER_SUCCESS, vehiclesNew);
      }
    },
    searchVehicles(search) {
      if (search.length > 2) {
        this.$emit("search-vehicles", search);
      }
    },
  },
  mounted() {
    if (this.vehiclesPreSelected?.length > 0) {
      this.vehiclesSelected = [...this.vehiclesPreSelected];
    }
  },
};
</script>

<style scoped>
.max-height-35 {
  max-height: 35rem !important;
}
</style>
