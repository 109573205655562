export const GET_DRIVER_LIST = "GET_DRIVER_LIST";
export const GET_DRIVER_LIST_SUCCESS = "GET_DRIVER_LIST_SUCCESS";
export const GET_DRIVER_LIST_ERROR = "GET_DRIVER_LIST_ERROR";

export const POST_DRIVER = "POST_DRIVER";
export const POST_DRIVER_SUCCESS = "POST_DRIVER_SUCCESS";
export const POST_DRIVER_ERROR = "POST_DRIVER_ERROR";

export const GET_DRIVER_LIST_NO_PAGINATE = "GET_DRIVER_LIST_NO_PAGINATE"
export const GET_DRIVER_LIST_NO_PAGINATE_SUCCESS = "GET_DRIVER_LIST_NO_PAGINATE_SUCCESS";
export const GET_DRIVER_LIST_NO_PAGINATE_ERROR = "GET_DRIVER_LIST_NO_PAGINATE_ERROR";