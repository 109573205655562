<template>
  <div>
    <b-table
      show-empty
      bordered
      small
      stacked="md"
      head-variant="light"
      :items="vehiclesWithVariant"
      :fields="fields"
      tbody-tr-class=""
      :busy="vehicles.status == 'fetching'"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" variant="yellow" />
        </div>
      </template>

      <template v-slot:cell(price)="row">
        <div>
          <PriceSelect
            :vehiclePrice="row.item"
            :refreshData="refreshData ? refreshData : searchVinNumber"
            :vehiclePriceSelected="
              row.item.info.typePrice ? row.item.info.typePrice : null
            "
          />
        </div>
      </template>
      <template v-slot:cell(options)="row">
        <div class="m-2 text-center">
          <b-button
            :disabled="!userHasPermission('vi', 'write')"
            @click="registerVehicle(row.item)"
            variant="yellow"
            class="m-0 p-0"
          >
            <b-icon variant="white" icon="arrow-right-circle" font-scale="2" />
          </b-button>
        </div>
      </template>
    </b-table>

    <b-row>
      <b-col
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
        class="d-flex justify-content-end"
      >
        <b-pagination
          :value="vehicles.data.currentPage"
          :total-rows="vehicles.data.total"
          :per-page="vehicles.data.perPage"
          class="mt-2"
          @change="changePage($event)"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { generalMixin } from "@/modules/mixin.js";
import { mapState } from "vuex";
import PriceSelect from "@/modules/vehicle/components/price/PriceSelect";
import {
  GET_VEHICLES_BY_VIN,
  SET_VEHICLES_BY_VIN_FILTERS,
  PUT_ASSIGN_CANADIAN_INFO_TO_VEHICLE,
} from "../actions";
import { vehicleMixin } from "../mixin";
import { superAdminMixin } from "../../superAdmin/mixins";

export default {
  components: { PriceSelect },
  mixins: [generalMixin, vehicleMixin, superAdminMixin],
  props: ["cars", "refreshData", "vinNumber"],
  computed: {
    isSmallScreen() {
      return this.$vssWidth <= 990;
    },
    ...mapState({
      vehicles: (state) => state.vehicle.vehicles,
      vehicle: (state) => state.vehicle.vehicleByVin.data.data,
    }),
    vehiclesWithVariant() {
      return this.vehicles.data.map((vehicle) => {
        if (vehicle.info.isRegister) {
          vehicle._rowVariant = "success";
        }
        return vehicle;
      });
    },
  },
  data() {
    return {
      fields: [
        {
          key: "trim",
          label: "Trim",
          sortable: true,
        },
        {
          key: "year",
          label: "Year",
          sortable: true,
        },
        {
          key: "make",
          label: "Make",
          sortable: true,
        },
        {
          key: "model",
          label: "Model",
          sortable: true,
        },

        {
          key: "driveType",
          label: "Drive type",
          sortable: true,
        },
        {
          key: "style",
          label: "Style",
          sortable: true,
        },
        {
          key: "engine",
          label: "Engine",
          sortable: true,
        },
        {
          key: "transmissionType",
          label: "Transmission",
          sortable: true,
        },

        {
          key: "transmissionType",
          label: "CBB price",
          sortable: true,
        },
        {
          key: "price",
          label: "Condition",
          sortable: true,
        },
        {
          key: "options",
          label: "Select",
        },
      ],
      referalPrice: 0,
    };
  },
  mounted() {
    if (this.$route.name == "company.cars.general") {
      this.searchVinNumber(this.$route.params.vin);
    }
    this.getVehicles();
  },
  methods: {
    registerVehicle(vehicle) {
      const payload = this.formatPayload(vehicle);
      this.$store
        .dispatch(PUT_ASSIGN_CANADIAN_INFO_TO_VEHICLE, payload)
        .then(() => {
          if (this.refreshData) {
            this.refreshData();
            this.getVehicles();
            this.$root.$emit("bv::toggle::collapse", "accordion-general");
          } else {
            this.searchVinNumber(this.vinNumber);
          }

          this.makeToast(
            "Success",
            "Vehicle information updated successfully",
            "success"
          );
        })
        .catch(() => {
          if (this.refreshData) {
            this.refreshData();
            this.refreshData();
          }
        });
    },

    getVehicles() {
      this.$store.commit(SET_VEHICLES_BY_VIN_FILTERS, {
        fields: {
          vinNumber: this.vinNumber,
        },
      });
      this.$store
        .dispatch(GET_VEHICLES_BY_VIN)
        .then((response) => {
          const isTrimSelected = response.data.some(
            (vehicle) => vehicle.info.isRegister
          );
          if (isTrimSelected) {
            this.$root.$emit("bv::toggle::collapse", "accordion-general");
          }
        })
        .catch(() => {});
    },
    formatPayload(vehicle) {
      const payload = {
        vehicleId: this.vehicle.id,
        canadianId: vehicle.id,
      };
      return payload;
    },
  },
};
</script>

<style scoped>
</style>
