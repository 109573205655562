import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    marketplaces: {
      status: null,
      data: [],
      error: null,
    },
    marketplaceList: {
      status: null,
      data: [],
      error: null,
    },
    marketplace: {
      status: null,
      data: {},
      error: null,
    },
    users: {
      status: null,
      data: [],
      error: null,
    },
  };
}

const actions = {
  [constants.GET_MARKETPLACE]: async ({ commit }, params) => {
    try {
      commit(constants.GET_MARKETPLACE);
      const response = await axios.get("marketPlaces/getMarketPlace", params);
      commit(constants.GET_MARKETPLACE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_MARKETPLACE_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_MARKETPLACE_LIST]: async ({ commit }, params) => {
    try {
      commit(constants.GET_MARKETPLACE_LIST);
      const response = await axios.get("marketPlaces/listMarketPlace", {
        params: { ...params },
      });
      commit(constants.GET_MARKETPLACE_LIST_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_MARKETPLACE_LIST_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_MARKETPLACE]: async ({ commit }, params) => {
    try {
      commit(constants.POST_MARKETPLACE);
      const headers = {
        'Content-Type': 'application/form-data'
      };
      const response = await axios.post(
        "marketPlaces/createAndUpdateMarketPlace", params, { headers }
      );
      commit(constants.POST_MARKETPLACE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_MARKETPLACE_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_ASSIGN_MARKETPLACE]: async ({ commit }, params) => {
    try {
      commit(constants.PUT_ASSIGN_MARKETPLACE);
      const response = await axios.put("marketPlaces/assignPermissions", {
        ...params,
      });
      commit(constants.PUT_ASSIGN_MARKETPLACE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_ASSIGN_MARKETPLACE_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_MARKETPLACE_USERS]: async ({ commit }, params) => {
    try {
      commit(constants.GET_MARKETPLACE_USERS);
      const response = await axios.get("marketPlaces/listUsers", {
        params: { ...params },
      });
      commit(constants.GET_MARKETPLACE_USERS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_MARKETPLACE_USERS_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_MARKETPLACE]: (state) => {
    state.marketplaces.status = "fetching";
    state.marketplaces.error = null;
  },
  [constants.GET_MARKETPLACE_SUCCESS]: (state, data) => {
    state.marketplaces.status = "success";
    state.marketplaces.error = null;
    state.marketplaces.data = data;
  },
  [constants.GET_MARKETPLACE_ERROR]: (state, error) => {
    state.marketplaces.status = "error";
    state.marketplaces.error = error;
  },
  [constants.GET_MARKETPLACE_LIST]: (state) => {
    state.marketplaceList.status = "fetching";
    state.marketplaceList.error = null;
  },
  [constants.GET_MARKETPLACE_LIST_SUCCESS]: (state, data) => {
    state.marketplaceList.status = "success";
    state.marketplaceList.error = null;
    state.marketplaceList.data = data;
  },
  [constants.GET_MARKETPLACE_LIST_ERROR]: (state, error) => {
    state.marketplaceList.status = "error";
    state.marketplaceList.error = error;
  },
  [constants.POST_MARKETPLACE]: (state) => {
    state.marketplace.status = "fetching";
    state.marketplace.error = null;
  },
  [constants.POST_MARKETPLACE_SUCCESS]: (state, data) => {
    state.marketplace.status = "success";
    state.marketplace.error = null;
    state.marketplace.data = data;
  },

  [constants.POST_MARKETPLACE_ERROR]: (state, error) => {
    state.marketplace.status = "error";
    state.marketplace.error = error;
  },
  [constants.PUT_ASSIGN_MARKETPLACE]: (state) => {
    state.marketplace.status = "fetching";
    state.marketplace.error = null;
  },
  [constants.PUT_ASSIGN_MARKETPLACE_SUCCESS]: (state, data) => {
    state.marketplace.status = "success";
    state.marketplace.error = null;
    state.marketplace.data = data;
  },
  [constants.PUT_ASSIGN_MARKETPLACE_ERROR]: (state, error) => {
    state.marketplace.status = "error";
    state.marketplace.error = error;
  },
  [constants.GET_MARKETPLACE_USERS]: (state) => {
    state.users.status = "fetching";
    state.users.error = null;
  },
  [constants.GET_MARKETPLACE_USERS_SUCCESS]: (state, data) => {
    state.users.status = "success";
    state.users.error = null;
    state.users.data = data;
  },
  [constants.GET_MARKETPLACE_USERS_ERROR]: (state, error) => {
    state.users.status = "error";
    state.users.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
