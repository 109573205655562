import * as constants from "./actions";
import axios from "@/plugins/axios.js";
function getDefaultState() {
  return {
    prices: {
      status: null,
      data: {},
      error: null,
    },
    pricesList: {
      status: null,
      data: [],
      error: null,
    },
  };
}

const actions = {
  [constants.PUT_PRICES]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_PRICES);
      const response = await axios.put(
        "vehiclesSales/saveReceivedPrice ",
        payload
      );
      commit(constants.PUT_PRICES_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_PRICES_ERROR, error);
      throw error.response;
    }
  },

  [constants.GET_PRICES]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_PRICES);
      const response = await axios.get("vehiclesSales/getPostHistory", {
        params: payload,
      });
      commit(constants.GET_PRICES_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_PRICES_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.PUT_PRICES]: (state) => {
    state.prices.status = "fetching";
    state.prices.error = null;
  },
  [constants.PUT_PRICES_SUCCESS]: (state, data) => {
    state.prices.status = "success";
    state.prices.error = null;
    state.prices.data = data;
  },
  [constants.PUT_PRICES_ERROR]: (state, error) => {
    state.prices.status = "error";
    state.prices.error = error;
  },
  [constants.GET_PRICES]: (state) => {
    state.pricesList.status = "fetching";
    state.pricesList.error = null;
  },
  [constants.GET_PRICES_SUCCESS]: (state, data) => {
    state.pricesList.status = "success";
    state.pricesList.error = null;
    state.pricesList.data = data;
  },
  [constants.GET_PRICES_ERROR]: (state, error) => {
    state.pricesList.status = "error";
    state.pricesList.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
