import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    companies: {
      status: null,
      data: [],
      error: null,
    },
    vehicles: {
      status: null,
      data: [],
      error: null,
    },
    vehiclesExcel: {
      status: null,
      data: {},
      error: null,
    }

  };
}
const actions = {
  [constants.GET_ACCOUNTING_COMPANIES_LIST]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_ACCOUNTING_COMPANIES_LIST);
      const response = await axios.get("accounting/listCompany", {
        params: payload,
      });
      commit(constants.GET_ACCOUNTING_COMPANIES_LIST_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_ACCOUNTING_COMPANIES_LIST_ERROR, error);
      throw error.response;
    }
  },

  [constants.GET_ACCOUNTING_VEHICLES_LIST]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_ACCOUNTING_VEHICLES_LIST);
      const response = await axios.get("accounting/getConsignmentsByCompany", {
        params: payload,
      });
      commit(constants.GET_ACCOUNTING_VEHICLES_LIST_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_ACCOUNTING_VEHICLES_LIST_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_ACCOUNTING_VEHICLES_EXCEL]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_ACCOUNTING_VEHICLES_EXCEL);
      const response = await axios.get("accounting/generateExcel", {
        params: payload,
        responseType: "blob",
      });
      commit(constants.GET_ACCOUNTING_VEHICLES_EXCEL_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_ACCOUNTING_VEHICLES_EXCEL_ERROR, error);
      throw error.response;
    }
  },





};

const mutations = {
  [constants.GET_ACCOUNTING_COMPANIES_LIST]: (state) => {
    state.companies.data = [];
    state.companies.status = "fetching";
  },
  [constants.GET_ACCOUNTING_COMPANIES_LIST_SUCCESS]: (state, response) => {
    state.companies.status = "success";
    state.companies.data = response;
  },
  [constants.GET_ACCOUNTING_COMPANIES_LIST_ERROR]: (state, error) => {
    state.companies.status = "error";
    state.companies.error = error;
  },
  [constants.GET_ACCOUNTING_VEHICLES_LIST]: (state) => {
    state.vehicles.data = [];
    state.vehicles.status = "fetching";
  },
  [constants.GET_ACCOUNTING_VEHICLES_LIST_SUCCESS]: (state, response) => {
    state.vehicles.status = "success";
    state.vehicles.data = response;
  },
  [constants.GET_ACCOUNTING_VEHICLES_LIST_ERROR]: (state, error) => {
    state.vehicles.status = "error";
    state.vehicles.error = error;
  },
  [constants.GET_ACCOUNTING_VEHICLES_EXCEL]: (state) => {
    state.vehiclesExcel.data = {};
    state.vehiclesExcel.status = "fetching";
  },
  [constants.GET_ACCOUNTING_VEHICLES_EXCEL_SUCCESS]: (state, response) => {
    state.vehiclesExcel.status = "success";
    state.vehiclesExcel.data = response;
  },
  [constants.GET_ACCOUNTING_VEHICLES_EXCEL_ERROR]: (state, error) => {
    state.vehiclesExcel.status = "error";
    state.vehiclesExcel.error = error;
  },



};
export default {
  state: getDefaultState(),
  actions,
  mutations,
};
