<template>
  <b-form @submit.stop.prevent="saveCluster()">
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <b-form-group label="Note">
          <b-form-textarea v-model="form.note" class="resize-none" />
        </b-form-group>
      </b-col>
    </b-row>
    <VehiclesList
      @vehicles-selected="setVehiclesSelected"
      :vehiclesPreSelected="[]"
      class="h-25"
    />
    <div class="text-right mt-2 mr-">
      <b-button
        :disabled="
          cluster.status === 'fetching' || vehiclesSelected.length <= 0
        "
        variant="warning"
        type="submit"
        class="m-2 text-white"
        @click="status = 0"
      >
        <b-spinner v-if="cluster.status === 'fetching'" variant="white" small />
        <span v-else>Mark as cancel</span>
      </b-button>
      <b-button
        :disabled="
          cluster.status === 'fetching' || vehiclesSelected.length <= 0
        "
        variant="success"
        @click="status = 2"
        type="submit"
      >
        <b-spinner v-if="cluster.status === 'fetching'" variant="white" small />
        <span v-else>Mark as complete</span>
      </b-button>
    </div>
  </b-form>
</template>

<script>
import { mapState } from "vuex";
import { superAdminMixin } from "../../mixins";
import { POST_BULK_CLUSTER, GET_VEHICLES_BULK_CLUSTER } from "./actions";
import VehiclesList from "./VehiclesList.vue";
import { generalMixin } from "../../../mixin";

export default {
  components: {
    VehiclesList,
  },
  mixins: [superAdminMixin, generalMixin],
  props: ["vehicle", "refreshData"],
  data() {
    return {
      vehiclesSelected: [],
      form: {
        note: null,
      },
    };
  },
  computed: {
    ...mapState({
      cluster: (state) => state.superAdminStore.bulkCluster.bulkCluster,
    }),
  },

  beforeMount() {
    this.getVehicles();
  },
  methods: {
    resetForm() {
      this.form.note = null;
    },

    saveCluster() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_BULK_CLUSTER, payload)
        .then(() => {
          this.$root.$bvToast.toast("Cluster data updated successfully", {
            title: "Success",
            variant: "success",
          });
          this.resetForm();
          this.closeModal("bulk-cluster-modal");
          this.getVehiclesInfo();
        })
        .catch((error) => {
          this.$root.$bvToast.toast(
            error.data.messages.charAt(0).toUpperCase() +
              error.data.messages.slice(1),
            {
              title: "Error",
              variant: "warning",
            }
          );
        });
    },

    formatPayload() {
      const data = {
        status: this.status,
        note: this.form.note,
        vehicles: this.vehiclesSelected,
      };
      return data;
    },

    getVehicles() {
      this.$store.dispatch(GET_VEHICLES_BULK_CLUSTER);
    },
    setVehiclesSelected(vehicles) {
      this.vehiclesSelected = vehicles;
    },
  },
};
</script>

<style></style>
