<template>
  <div>
    <b-navbar
      toggleable="lg"
      variant="light"
      align="end"
      class="nav mt-4 mb-5 shadow"
    >
      <b-navbar-toggle target="nav-collapse" />
      <b-collapse id="nav-collapse" is-nav align="end">
        <b-navbar-nav class="ml-auto">
          <b-navbar-nav>
            <b-nav-item
              :to="{ name: 'superadmin.checks.actives' }"
              :active="$route.name === 'superadmin.checks.actives'"
            >
              Actives
            </b-nav-item>
            <b-nav-item
              :to="{ name: 'superadmin.checks.delivered' }"
              :active="$route.name === 'superadmin.checks.delivered'"
            >
              Delivered
            </b-nav-item>
          </b-navbar-nav>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view />
  </div>
</template>
<script>
export default {};
</script>
