<template>
  <b-container class="mt-3" fluid>
    <b-tabs content-class="">
      <b-tab title="Active" active @click="onChangeStatus('1')"></b-tab>
      <b-tab title="Done" @click="onChangeStatus('2')"></b-tab>
      <b-tab title="All" @click="onChangeStatus('1,2')"></b-tab>
    </b-tabs>
    <b-table
      show-empty
      bordered
      small
      stacked="md"
      head-variant="light"
      :items="invoices.data.data ? invoices.data.data : []"
      :fields="fields"
      :busy="invoices.status === 'fetching'"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" variant="yellow" />
        </div>
      </template>
      <template #cell(registerBy)="{ item }">
        <span class="text-center">{{ item.createdBy }}</span>
      </template>
      <template #cell(stock)="{ item }">
        <span class="text-center">{{ item.stockNumber }}</span>
      </template>
      <template #cell(customer)="{ item }">
        <span class="text-center">{{ item.companyName }}</span>
      </template>
      <template #cell(actions)="{ item }">
        <font-awesome-icon
          class="icon-color cursor-pointer mx-1"
          :icon="['far', 'file-invoice-dollar']"
          size="xl"
          @click="
            openModal(item, 'sold-modal', {
              module: 'sold',
              action: 'read',
            })
          "
        />
        <font-awesome-icon
          class="icon-color cursor-pointer mx-1"
          :icon="['far', 'message-dollar']"
          size="xl"
          @click="
            item.status == 'Sold' && item.vehicleFlags.reserve != 1
              ? showSoldValidation()
              : openModal(item, 'reserve-modal', {
                  module: 'reserve',
                  action: 'read',
                })
          "
        />
      </template>
    </b-table>
    <div>
      <b-pagination
        v-if="invoices.data"
        :value="invoices.data ? invoices.data.currentPage : 1"
        :total-rows="invoices.data ? invoices.data.total : 0"
        :per-page="invoices.data ? invoices.data.perPage : 25"
        class="mb-2 mt-3"
        align="end"
        @change="changeInvoicePage($event)"
      />
    </div>
    <b-modal
      id="invoice-modal"
      hide-footer
      size="lg"
      centered
      title="Invoice request"
    >
      <InvoiceForm :refreshData="getInvoices" />
    </b-modal>
    <SoldModal @refresh="getInvoices" />
    <ReserveModal @refresh="getInvoices" />
  </b-container>
</template>

<script>
import { mapState } from "vuex";
import { GET_INVOICE_LIST } from "./actions";
import InvoiceForm from "./InvoiceForm.vue";
import SoldModal from "../components/sold/SoldModal.vue";
import ReserveModal from "../components/reserve/ReserveModal.vue";
import { superAdminMixin } from "../mixins";

export default {
  name: "InvoiceTable",
  components: {
    InvoiceForm,
    SoldModal,
    ReserveModal,
  },
  computed: {
    ...mapState({
      invoices: (state) => state.superAdminStore.invoice.invoices,
    }),
    fields() {
      if (this.status === "1,2" || this.status === "2") {
        return this.allFields;
      }
      return this.allFields.filter(
        (field) => field.key !== "finalUser" && field.key !== "updatedAt"
      );
    },
  },

  mixins: [superAdminMixin],
  data() {
    return {
      allFields: [
        {
          key: "createdAt",
          label: "Created date",
          class: "text-center",
          formatter: this.$options.filters.formatDateTime,
        },
        {
          key: "registerBy",
          label: "Register by",
          class: "text-center",
        },
        {
          key: "stock",
          label: "Stock",
          class: "text-center",
        },
        {
          key: "customer",
          label: "Customer",
          class: "text-center",
        },
        {
          key: "currency",
          label: "Currency",
          class: "text-right",
        },
        {
          key: "price",
          label: "Amount",
          class: "text-right",
          formatter: this.$options.filters.currency,
        },
        {
          key: "finalUser",
          label: "Done by",
          class: "text-center",
        },
        {
          key: "updatedAt",
          label: "Done date",
          class: "text-center",
          formatter: this.$options.filters.formatDateTime,
        },

        {
          key: "actions",
          label: "Actions",
          class: "text-center",
        },
      ],
      status: "1",
    };
  },
  mounted() {
    this.$store.commit("setAddFunction", this.openAddModal);
    this.getInvoices();
  },
  methods: {
    getInvoices(page = 1) {
      const payload = {
        page,
        status: this.status,
      };
      this.$store.dispatch(GET_INVOICE_LIST, payload).then(() => {});
    },
    openAddModal() {
      this.$bvModal.show("invoice-modal");
    },
    changeInvoicePage(page) {
      this.$store.dispatch(GET_INVOICE_LIST, page);
    },
    onChangeStatus(status) {
      this.status = status;
      this.getInvoices();
    },
    showSoldValidation() {
      this.$root.$bvToast.toast("This vehicle is already sold", {
        title: "Warning",
        variant: "warning",
      });
    },
  },
};
</script>

<style></style>
