<template>
  <div>
    <b-navbar variant="white" type="light" sticky style="z-index: 900">
      <b-navbar-brand href="#">
        <img src="@/assets/img/logos/QAS.png" width="50%" />
      </b-navbar-brand>
      <h1 class="">{{ vehicleTitle }}</h1>
    </b-navbar>
    <b-container>
      <div class="position-sticky"></div>
      <b-row>
        <b-col v-if="workOrders != null && !isLoading" md="12" class="mt-3">
          <b-card>
            <WorkOrdersList
              :workOrders="workOrders"
              :refreshData="getPublicVehicleData"
              :vehicleId="this.$route.params.id"
              :showButton="sellAsIt"
            />
          </b-card>
        </b-col>
        <b-col md="6">
          <b-skeleton-img v-if="isLoading"></b-skeleton-img>

          <b-card v-else class="mt-3">
            <lingallery
              v-if="images.length > 0"
              :addons="{ enableLargeView: true }"
              :iid.sync="currentId"
              :width="width"
              :height="height"
              :items="images"
            />
            <b-img
              v-else
              :src="'img/image_not_found.png'"
              alt=""
              style="width: 100%; height: 90%"
            />
          </b-card>

          <b-card>
            <h1 class="d-flex justify-content-between">
              <span> Sale price: </span>
              <span v-if="salePrice > 0">
                {{ salePrice | currency }}
                <span v-if="market == 'QAS'">CAD</span>
                <span v-if="market != 'QAS'">USD</span>
              </span>
              <span v-else>Coming soon</span>
            </h1>
          </b-card>
          <b-skeleton-img
            v-if="isLoading && damageImages.length > 0"
          ></b-skeleton-img>

          <b-card v-else>
            <!-- damage images -->
            <h2>Damages</h2>
            <lingallery
              v-if="damageImages.length > 0"
              :addons="{ enableLargeView: true }"
              :iid.sync="currentId"
              :width="width"
              :height="height"
              :items="damageImages"
            />
            <b-img
              v-else
              :src="'img/image_not_found.png'"
              alt=""
              style="width: 100%; height: 90%"
            />
          </b-card>
        </b-col>
        <b-col md="6">
          <h2>Info</h2>

          <b-card>
            <b-table
              :fields="labels"
              thead-class="d-none"
              :items="items"
              class="border-bottom"
              table-class="table table-borderless font-size-1-3 p-0"
              t-body-class="p-0"
            >
              <template #cell(name)="{ item }">
                <b class="font-weight-bold font-size-1-3"> {{ item.name }}</b>
              </template>
              <template #cell(value)="{ item }">
                <span class="font-size-1-3">{{ item.value }}</span>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <ContentFooter />
  </div>
</template>

<script>
import Lingallery from "lingallery";
import { GET_VEHICLE_PUBLIC_DATA } from "./actions";
import ContentFooter from "@/layouts/ContentFooter.vue";
import WorkOrdersList from "./work-order/WorkOrdersList.vue";
export default {
  name: "VehiclePage",
  components: {
    Lingallery,
    ContentFooter,
    WorkOrdersList,
  },
  computed: {
    vehicleTitle() {
      return (
        this.items.find((item) => item.key === "year").value +
        " " +
        this.items.find((item) => item.key === "make").value +
        " " +
        this.items.find((item) => item.key === "model").value +
        " "
      );
    },
  },
  data() {
    return {
      labels: [
        {
          key: "name",
          class: "px-0 border-bottom",
        },
        {
          key: "value",
          class: "px-0 border-bottom",
        },
        {
          key: "key",
          class: "d-none",
        },
      ],
      items: [
        { name: "VIN", value: "", key: "vinNumber" },
        { name: "Stock Number", value: "", key: "stockNumber" },
        { name: "Year", value: "2023", key: "year" },
        { name: "Make", value: "Kia", key: "make" },
        { name: "Model", value: "EV6 Wind", key: "model" },
        { name: "Trim", value: "SEL", key: "trim" },
        { name: "Body Style", value: "SUV", key: "style" },
        { name: "Kilometers", value: "0", key: "kms" },
        { name: "Mileage", value: "0", key: "miles" },
        { name: "Color", value: "Black", key: "color" },
        { name: "Transmission", value: "Automatic", key: "transmissionType" },
        { name: "Drive", value: "AWD", key: "driveType" },
        { name: "Engine", value: "Electric", key: "engine" },
        { name: "Doors", value: "4", key: "doors" },
        { name: "Drive", value: "AWD", key: "driveType" },
        { name: "Condition", value: "New", key: "condition" },
      ],
      width: 600,
      height: 340,

      images: [
        {
          id: "someid1",
          src: "https://picsum.photos/600/340/?image=1",
          thumbnail: "https://picsum.photos/600/340/?image=1",
        },
      ],
      currentId: "someid1",
      isLoading: false,
      salePrice: 0,
      market: "",
      damageImages: [
        {
          id: "someid1",
          src: "https://picsum.photos/600/340/?image=1",
          thumbnail: "https://picsum.photos/600/340/?image=1",
        },
      ],
      workOrders: null,
      sellAsIt: false,
    };
  },
  created() {
    this.getPublicVehicleData();
  },
  methods: {
    getPublicVehicleData() {
      const showWorkOrders = this.$route.path.includes("agent") ? 1 : 0;
      this.isLoading = true;
      this.$store
        .dispatch(GET_VEHICLE_PUBLIC_DATA, {
          code: this.$route.params.id,
          wo: showWorkOrders,
        })
        .then((res) => {
          console.log(res);
          this.market = res.market;
          this.salePrice = res.market == "QAS" ? res.priceCad : res.price;
          this.images = this.formatImages(res.photos);
          this.damageImages = this.formatDamageImages(res.damagePhotos);
          if (res.workOrders.length > 0) {
            this.workOrders = res.workOrders;
          }
          this.sellAsIt = res.sellAsIt;
          this.formatInformation(res);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    formatImages(images) {
      return images.map((image) => {
        return {
          id: image.photo,
          src: image.photo,
          largeViewSrc: image.photo,
          thumbnail: image.photoThumbnail || image.photo,
        };
      });
    },
    formatDamageImages(images) {
      return images.map((image) => {
        return {
          id: image.photo,
          src: image.damagePhoto,
          largeViewSrc: image.damagePhoto,
          thumbnail: image.photoThumbnail || image.photo,
        };
      });
    },
    formatInformation(data) {
      this.items = this.items.map((item) => {
        if (item.key) {
          item.value = data[item.key];
        }
        return item;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.hidden_header {
  display: none !important;
}
.font-size-1-3 {
  font-size: 1.1rem !important;
}
</style>
