<template>
  <div class="timeline-block" :class="{ 'timeline-inverted': inverted }">
    <slot name="badge">
      <span class="timeline-step" :class="`badge-${badgeType}`">
        <img
          v-if="badgeIcon == 'carfax'"
          src="@/assets/svg/CF_grey.svg"
          class="img-responsive cursor-pointer"
          style="max-width: 25px"
        />
        <i v-else>
          <font-awesome-icon :icon="['far', badgeIcon]" />
        </i>
      </span>
    </slot>
    <div class="timeline-content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "time-line-item",
  props: {
    inverted: Boolean,
    title: String,
    badgeType: {
      type: String,
      default: "success",
    },
    badgeIcon: {
      type: String,
      default: "",
    },
  },
};
</script>
<style></style>
