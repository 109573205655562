import VehiclesTable from "./VehiclesTable.vue";
import CheckConfirmationTable from "./checkConfirmation/components/CheckConfirmationTable.vue";
import CheckConfirmationDeliveredTable from "./checkConfirmation/components/CheckConfirmationDeliveredTable.vue";
import NavChecks from "./checkConfirmation/components/Nav.vue";
import Driver from "./driver/DriverTable.vue";
import Taxes from "./taxes/TaxesTable.vue";
import Fees from "./fees/FeesTable.vue";
import ExportLoad from "./exportLoad/ExportLoadTable.vue";
import ExportLocation from "./exportLocation/ExportLocationTable.vue";
import Location from "./location/LocationTable.vue";
import CreditRequest from "./creditRequest/CreditRequestTable.vue";
import Registration from "./registration/RegistrationTable.vue";
import PermissionsTable from "./permissions/PermissionsTable.vue";
import MenuPermissionsTable from "./permissions/MenuPermissionsTable.vue";
import FxForm from "./fx/FxForm.vue";
import ExportPortTable from "./exportPort/ExportPortTable.vue";
import AddonsTypeTable from "./addons-type/AddonsTypeTable.vue"
import VehiclePage from "../../views/Pages/Vehicle/VehiclePage.vue"
import VehicleProfileTable from "../../views/Pages/VehiclePageTable.vue"
import AddonsFileTable from "./addons-file/AddonsFileTable.vue"
import AddonsFileTableById from "./addons-file/AddonsFileTableById.vue"
import AddonsAssignTable from "./addons-type-assign/AddonAssignCompany.vue"
import VehicleTimeline from "./timeline/VehicleTimeline.vue"
import Accounting from "./accounting/CompaniesTable.vue"
import AccountingVehicles from "./accounting/AccountingVehiclesTable.vue"
import ExchangeContract from "./exchange-contract/ExchangeContractTable.vue"
import ExchangeContractPayments from "./exchange-contract/payments/ExchangeContractPaymentTable.vue"
import Interest from "./interest/InterestForm.vue"
import AgentCommission from "./agent-commissions/AgentCommissionsTable.vue"
import AccountStatement from "./account-statement/AccountStatementTable.vue"
import Tasks from "./tasks/TasksBoard.vue"
import DailyReports from "./reports/DailyReports.vue"
import ReportsView from "./custom-reports/ReportsView.vue"
import RunListTable from "./run-list/RunListTable.vue"
import RunListVehiclesTable from "./run-list/RunListVehiclesTable.vue"
import AssignTypeByUserView from "./tasks/configuration/AssignTypeByUserView.vue"
import { ifRoleAuthenticated } from "@/router/navigationGuards";
import DashboardLayout from '@/layouts/DashboardLayout.vue';
import VehicleSimpleView from "../../views/Pages/simple-vehicle-view/SimpleVehicleView.vue"
import Profile from "@/modules/auth/profile/Profile.vue"
import TransferBetweenAgent from "./transfer-between-agent/TransferBetweenAgentTable.vue"
import Invoice from "./invoice/InvoiceTable.vue"
import Transportation from "./transportation/TransportationTable.vue"
import Reserve from "./reserve/ReserveTable.vue"
import TitleManager from "./title-manager/TitleManagerTable.vue"
import TitleManagerVehiclesView from "./title-manager/TitleManagerVehiclesView.vue"
import DriverPrice from "./driver-price/DriverPriceTable.vue"
import CommissionSummary from "./commissions-summary/CommissionSummaryTable.vue"
import RITable from "./ri/RITable.vue";
const routes = [
  {
    path: "/superAdmin",
    name: "superAdmin",

    component: DashboardLayout,
    beforeEnter: (to, from, next) => {
      ifRoleAuthenticated(to, from, next, ["ROLE_SUPER_ADMIN", "ROLE_AGENT", "ROLE_ADMIN", "ROLE_EXTERNAL_AGENT"]);
    },
    redirect: { name: "superadmin.vehicles.table.inventory" },
    children: [

      {
        path: "vehicles/logistic",
        name: "superadmin.vehicles.table.logistic",
        beforeEnter: (to, from, next) => {
          ifRoleAuthenticated(to, from, next, [
            "ROLE_SUPER_ADMIN",
            "ROLE_AGENT",
            "ROLE_ADMIN",
            "ROLE_EXTERNAL_AGENT"

          ]);
        },
        component: VehiclesTable,
        meta: {
          board: 'logistic',
          name: 'Logistic',
        }
      },
      {
        path: "vehicles/business",
        name: "superadmin.vehicles.table.inventory",
        beforeEnter: (to, from, next) => {
          ifRoleAuthenticated(to, from, next, [
            "ROLE_SUPER_ADMIN",
            "ROLE_AGENT",
            "ROLE_ADMIN",
            "ROLE_EXTERNAL_AGENT"


          ]);
        },
        component: VehiclesTable,
        meta: {
          board: 'business',
          name: 'Business',
        },

      },
      {
        path: "vehicles/admin",
        name: "superadmin.vehicles.table.admin",
        beforeEnter: (to, from, next) => {
          ifRoleAuthenticated(to, from, next, [
            "ROLE_SUPER_ADMIN",
            "ROLE_AGENT",
            "ROLE_ADMIN",
            "ROLE_EXTERNAL_AGENT"

          ]);
        },
        component: VehiclesTable,
        meta: {
          board: 'admin',
          name: 'In fleet',
        }
      },
      {
        path: "vehicles/export",
        name: "superadmin.vehicles.table.export",
        beforeEnter: (to, from, next) => {
          ifRoleAuthenticated(to, from, next, [
            "ROLE_SUPER_ADMIN",
            "ROLE_AGENT",
            "ROLE_ADMIN",
            "ROLE_EXTERNAL_AGENT"
          ]);
        },
        component: VehiclesTable,
        meta: {
          board: 'export',
          name: 'Export',
        }
      },
      {
        path: "vehicles/yard",
        name: "superadmin.vehicles.table.yard",
        beforeEnter: (to, from, next) => {
          ifRoleAuthenticated(to, from, next, [
            "ROLE_SUPER_ADMIN",
            "ROLE_AGENT",
            "ROLE_ADMIN",
            "ROLE_EXTERNAL_AGENT"

          ]);
        },
        component: VehiclesTable,
        meta: {
          board: 'yard',
          name: 'Yard',
        }
      },
      {
        path: "vehicles/sales",
        name: "superadmin.vehicles.table.sales",
        beforeEnter: (to, from, next) => {
          ifRoleAuthenticated(to, from, next, [
            "ROLE_SUPER_ADMIN",
            "ROLE_AGENT",
            "ROLE_ADMIN",
            "ROLE_EXTERNAL_AGENT"

          ]);
        },
        component: VehiclesTable,
        meta: {
          board: 'sales',
          name: 'Sales',
        }
      },
      {
        path: "vehicles/accounting",
        name: "superadmin.vehicles.table.accounting",
        beforeEnter: (to, from, next) => {
          ifRoleAuthenticated(to, from, next, [
            "ROLE_SUPER_ADMIN",
            "ROLE_AGENT",
            "ROLE_ADMIN",
            "ROLE_EXTERNAL_AGENT"

          ]);
        },
        component: VehiclesTable,
        meta: {
          board: 'accounting',
          name: 'Accounting',
        }
      },
      {
        path: "vehicle/unit/:id",
        name: "superadmin.vehicles.unit",
        beforeEnter: (to, from, next) => {
          ifRoleAuthenticated(to, from, next, [
            "ROLE_SUPER_ADMIN",
            "ROLE_AGENT",
            "ROLE_ADMIN",
            "ROLE_EXTERNAL_AGENT"
          ]);
        },
        component: VehiclePage,
        meta: { name: "Vehicle" },

      },
      {
        path: "vehicle/unit/table",
        name: "superadmin.vehicles.unit.table",
        beforeEnter: (to, from, next) => {
          ifRoleAuthenticated(to, from, next, [
            "ROLE_SUPER_ADMIN",
            "ROLE_AGENT",
            "ROLE_ADMIN",
            "ROLE_EXTERNAL_AGENT"
          ]);
        },
        component: VehicleProfileTable,

      },
      {
        path: "vehicle/simple-view/:id",
        name: "superadmin.vehicles.simple-view",
        beforeEnter: (to, from, next) => {
          ifRoleAuthenticated(to, from, next, [
            "ROLE_SUPER_ADMIN",
            "ROLE_AGENT",
            "ROLE_ADMIN",
            "ROLE_EXTERNAL_AGENT"
          ]);
        },
        component: VehicleSimpleView,

      },
      {
        path: "checks",
        name: "superadmin.checks",

        component: NavChecks,
        meta: { name: "Buy checks" },
        redirect: { name: "superadmin.checks.actives" },
        children: [
          {
            path: "actives",
            name: "superadmin.checks.actives",
            component: CheckConfirmationTable,
            meta: { name: "Active checks" },
          },
          {
            path: "delivered",
            name: "superadmin.checks.delivered",
            component: CheckConfirmationDeliveredTable,
            meta: { name: "Delivered checks" },
          },
        ],
      },
      {
        path: "drivers",
        name: "superadmin.drivers",
        component: Driver,
        meta: {
          name: 'Drivers',
        }
      },
      {
        path: "taxes",
        name: "superadmin.taxes",
        component: Taxes,
        meta: {
          name: 'Taxes',
        }
      },
      {
        path: "fees",
        name: "superadmin.fees",
        component: Fees,
        meta: {
          name: 'Fees',
        }
      },
      {
        path: "export-load",
        name: "superadmin.export-load",
        component: ExportLoad,
        meta: {
          name: 'Export Load',
        }
      },
      {
        path: "export-location",
        name: "superadmin.export-location",
        component: ExportLocation,
        meta: {
          name: 'Export Location',
        }

      },
      {
        path: "locations/:id/:type",
        name: "superadmin.locations",
        component: Location,
        meta: {
          name: 'Locations',
        }
      },
      {
        path: "credit-request",
        name: "superadmin.credit-request",
        component: CreditRequest,
        meta: {
          name: 'Credit Request',
        }
      },
      {
        path: "registration",
        name: "superadmin.registration",
        component: Registration,
        meta: {
          name: 'Registration',
        }
      },
      {
        path: "permissions",
        name: "superadmin.permissions",
        component: PermissionsTable,
        meta: {
          name: 'Permissions',
        }
      },
      {
        path: "menu-permissions",
        name: "superadmin.menu-permissions",
        component: MenuPermissionsTable,
        meta: {
          name: 'Menu permissions',
        }
      },
      {
        path: "fx",
        name: "superadmin.fx",
        component: FxForm,
        meta: {
          name: 'Fx',
        }
      },
      {
        path: "export-port",
        name: "superadmin.export-port",
        component: ExportPortTable,
        meta: {
          name: 'Export Port',
        }
      },
      {
        path: "addons-type",
        name: "superadmin.addons-type",
        component: AddonsTypeTable,
        meta: {
          name: 'Suppliers category',
        }
      },
      {
        path: "addons-file",
        name: "superadmin.addons-file",
        component: AddonsFileTable,
        meta: {
          name: 'Upload txt file',
        }
      },
      {
        path: "addons-file/:id",
        name: "superadmin.addons-file.by-id",
        component: AddonsFileTableById,
        meta: {
          name: 'Upload txt file',
        }
      },
      {
        path: "addons/assign",
        name: "superadmin.addons.assign",
        component: AddonsAssignTable,
        meta: {
          name: 'Suppliers category',
        }
      },
      {
        path: "timeline/:id",
        name: "superadmin.vehicle.timeline",
        component: VehicleTimeline,
        meta: {
          name: 'Timeline',
        }
      },
      {
        path: "accounting",
        name: "superadmin.accounting",
        component: Accounting,
        meta: {
          name: 'Accounting',
        }
      },
      {
        path: "accounting/vehicles/:id",
        name: "superadmin.accounting.vehicles",
        component: AccountingVehicles,
        meta: {
          name: 'Accounting vehicles',
        }
      },
      {
        path: "profile",
        name: "superadmin.profile",
        component: Profile,
        meta: {
          name: 'Profile',
        }
      },
      {
        path: "exchange-contract",
        name: "superadmin.exchange-contract",
        component: ExchangeContract,
        meta: {
          name: 'Exchange rate contract',
        }
      },
      {
        path: "exchange-contract-payments",
        name: "superadmin.exchange-contract.payments",
        component: ExchangeContractPayments,
        meta: {
          name: 'Exchange rate contract payments',
        }
      },
      {
        path: 'interest',
        name: 'superadmin.interest',
        component: Interest,
        meta: {
          name: 'Commissions',
        }
      },
      {
        path: 'agent-commission',
        name: 'superadmin.agent-commission',
        component: AgentCommission,
        meta: {
          name: 'Agent commissions',
        }
      },
      {
        path: 'account-statement',
        name: 'superadmin.account-statement',
        component: AccountStatement,
        meta: {
          name: 'Account statement',
        }
      },
      {
        path: 'tasks',
        name: 'superadmin.tasks',
        component: Tasks,
        meta: {
          name: 'Tasks',
        }
      },
      {
        path: 'tasks/user',
        name: 'superadmin.tasks-user',
        component: Tasks,
        meta: {
          name: 'User tasks',
        }
      }, {
        path: 'daily-reports',
        name: 'superadmin.daily-reports',
        component: DailyReports,
        meta: {
          name: 'Daily reports',
        }
      },
      {
        path: 'reports',
        name: 'superadmin.custom-reports',
        component: ReportsView,
        meta: {
          name: 'Reports',
        }
      },
      {
        path: 'run-list',
        name: 'superadmin.run-list',
        component: RunListTable,
        meta: {
          name: 'Run list',
        }
      },
      {
        path: 'run-list/vehicles/:id',
        name: 'super-admin-run-list-vehicles',
        component: RunListVehiclesTable,
        meta: {
          name: 'Run list vehicles',
        }
      },
      {
        path: 'task/types',
        name: 'superadmin.task-types-by-user',
        component: AssignTypeByUserView,
        meta: {
          name: 'Task types by user',
        }
      },
      {
        path: 'transfer-between-agent',
        name: 'superadmin.transfer-between-agent',
        component: TransferBetweenAgent,
        meta: {
          name: 'Transfer between agent',
        }
      },
      {
        path: 'invoice',
        name: 'superadmin.invoice',
        component: Invoice,
        meta: {
          name: 'Invoice request',
        }
      },
      {
        path: 'transportation',
        name: 'superadmin.transportation',
        component: Transportation,
        meta: {
          name: 'Transportation',
        }
      },
      {
        path: 'reserve',
        name: 'superadmin.reserve',
        component: Reserve,
        meta: {
          name: 'Reserve',
        }
      },
      {
        path: 'driver-price',
        name: 'superadmin.driver-price',
        component: DriverPrice,
        meta: {
          name: 'Driver price',
        }
      },
      {
        path: 'commission-summary',
        name: 'superadmin.commission-summary',
        component: CommissionSummary,
        meta: {
          name: 'Commission summary',
        }
      },
      {
        path: 'ri-companies',
        name: 'superadmin.ri-companies',
        component: RITable,
        meta: {
          name: 'RI companies',
        }
      }





    ],

  },
  {
    path: "/ri",
    name: "ri",
    component: DashboardLayout,
    beforeEnter: (to, from, next) => {
      ifRoleAuthenticated(to, from, next, ["ROLE_RI", "ROLE_SUPER_ADMIN"]);
    },
    children: [
      {
        path: "vehicles",
        name: "ri.vehicles",
        component: VehiclesTable,
        meta: {
          name: 'Vehicles',
        }
      },
      {
        path: 'title-manager',
        name: 'superadmin.title-manager',
        component: TitleManager,
        meta: {
          name: 'Title manager',
        }
      },
      {
        path: 'title-manager/:id',
        name: 'superadmin.title-manager-vehicles',
        component: TitleManagerVehiclesView,
        meta: {
          name: 'Title manager vehicles',
        }
      }

    ]

  }


];

export default routes;
