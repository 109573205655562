export const PUT_INTEREST = 'PUT_INTEREST';
export const PUT_INTEREST_SUCCESS = 'PUT_INTEREST_SUCCESS';
export const PUT_INTEREST_ERROR = 'PUT_INTEREST_ERROR';

export const GET_MARKETPLACE_INTEREST = 'GET_MARKETPLACE_INTEREST';
export const GET_MARKETPLACE_INTEREST_SUCCESS = 'GET_MARKETPLACE_INTEREST_SUCCESS';
export const GET_MARKETPLACE_INTEREST_ERROR = 'GET_MARKETPLACE_INTEREST_ERROR';

export const PUT_MARKETPLACE_COMMISSION = 'PUT_MARKETPLACE_COMMISSION';
export const PUT_MARKETPLACE_COMMISSION_SUCCESS = 'PUT_MARKETPLACE_COMMISSION_SUCCESS';
export const PUT_MARKETPLACE_COMMISSION_ERROR = 'PUT_MARKETPLACE_COMMISSION_ERROR';

export const GET_AGENT_COMMISSION = 'GET_AGENT_COMMISSION';
export const GET_AGENT_COMMISSION_SUCCESS = 'GET_AGENT_COMMISSION_SUCCESS';
export const GET_AGENT_COMMISSION_ERROR = 'GET_AGENT_COMMISSION_ERROR';

export const PUT_AGENT_COMMISSION = 'PUT_AGENT_COMMISSION';
export const PUT_AGENT_COMMISSION_SUCCESS = 'PUT_AGENT_COMMISSION_SUCCESS';
export const PUT_AGENT_COMMISSION_ERROR = 'PUT_AGENT_COMMISSION_ERROR';

export const GET_PAD_COMMISSIONS = 'GET_PAD_COMMISSIONS';
export const GET_PAD_COMMISSIONS_SUCCESS = 'GET_PAD_COMMISSIONS_SUCCESS';
export const GET_PAD_COMMISSIONS_ERROR = 'GET_PAD_COMMISSIONS_ERROR';

export const POST_PAD_COMMISSION = 'POST_PAD_COMMISSION';
export const POST_PAD_COMMISSION_SUCCESS = 'POST_PAD_COMMISSION_SUCCESS';
export const POST_PAD_COMMISSION_ERROR = 'POST_PAD_COMMISSION_ERROR';

export const DELETE_PAD_COMMISSION = 'DELETE_PAD_COMMISSION';
export const DELETE_PAD_COMMISSION_SUCCESS = 'DELETE_PAD_COMMISSION_SUCCESS';
export const DELETE_PAD_COMMISSION_ERROR = 'DELETE_PAD_COMMISSION_ERROR';