<template>
  <div>
    <b-navbar variant="yellow">
      <b-nav
        v-if="witdhResponsive !== '100%'"
        class="w-100 d-lg-inline-flex"
        align="end"
      >
        <b-row>
          <b-col cols="6" />
          <b-col cols="6">
            <b-pagination
              :value="
                pagination && pagination.currentPage
                  ? pagination.currentPage
                  : 1
              "
              :total-rows="
                pagination && pagination.total ? pagination.total : 1
              "
              :per-page="
                pagination && pagination.perPage ? pagination.perPage : 6
              "
              align="right"
              @change="changePage"
            />
          </b-col>
        </b-row>
      </b-nav>
    </b-navbar>
    <b-container fluid>
      <!-- Pagination on mobile -->
      <b-nav
        v-if="witdhResponsive == '100%'"
        class="w-100 d-lg-inline-flex mt-1"
        align="end"
        style="margin-bottom: -10px"
      >
        <b-row>
          <b-col>
            <b-pagination
              :value="
                pagination && pagination.currentPage
                  ? pagination.currentPage
                  : 1
              "
              :total-rows="
                pagination && pagination.total ? pagination.total : 1
              "
              :per-page="
                pagination && pagination.perPage ? pagination.perPage : 6
              "
              align="right"
              @change="changePage"
            />
          </b-col>
        </b-row>
      </b-nav>
      <div
        v-if="
          this.$route.name === 'buyer.car-list' ||
            this.$route.name === 'client.car-list'
        "
        class="mt-3"
      >
        <b-tabs
          v-model="tab"
          content-class="mt-3"
        >
          <b-tab
            title="Vehicles"
            active
            @click="changeTab(null)"
          >
            <ListComponents
              ref="ListVehicles"
              :company-is-pending="companyIsPending"
              @changeTabNumber="changeTabNumber(1)"
              @resetFilters="resetFilters(false)"
            />
          </b-tab>
          <b-tab
            :disabled="companyIsPending"
            title="Active Offers"
            @click="changeTab('GET_ACTIVE_OFFERS')"
          >
            <template #title>
              Active Offers
              <b-badge
                v-if="typeof notifications === 'number' && notifications > 0"
                variant="danger"
              >
                {{ notifications }}
              </b-badge>
            </template>
            <ActiveOffers ref="ActiveOffers" />
          </b-tab>
          <b-tab
            :disabled="companyIsPending"
            title="Expired Offers"
            @click="changeTab('GET_EXPIRED_OFFERS')"
          >
            <ExpiredOffers ref="ExpiredOffers" />
          </b-tab>
          <b-tab
            :disabled="companyIsPending"
            title="Purchases "
            @click="changeTab('GET_PURCHASES')"
          >
            <Purchases ref="Purchases" />
          </b-tab>

          <b-tab
            :disabled="companyIsPending"
            title-item-class=""
            @click="goToDisableNotifications()"
          >
            <template #title>
              <b-icon
                icon="gear"
                class=""
              />
            </template>
          </b-tab>
        </b-tabs>
      </div>
      <div v-else>
        <ListComponents
          v-if="user"
          :is-buyer-view="true"
          :user-is-admin="userIsAdmin"
          :user-is-agent="userIsAgent"
          :company-is-pending="companyIsPending"
        />
        <PublicVehiclesList v-else />
      </div>
    </b-container>

    <b-navbar
      class="mt-3 py-3 block"
      toggleable="lg"
      variant="yellow"
    >
      <b-nav
        class="w-100 d-lg-inline-flex"
        align="end"
      >
        <b-nav-item>
          <b-row>
            <b-col>
              <b-pagination
                :value="
                  pagination && pagination.currentPage
                    ? pagination.currentPage
                    : 1
                "
                :total-rows="
                  pagination && pagination.total ? pagination.total : 1
                "
                :per-page="
                  pagination && pagination.perPage ? pagination.perPage : 6
                "
                align="right"
                @change="changePage"
              />
            </b-col>
          </b-row>
        </b-nav-item>
      </b-nav>
    </b-navbar>

    <VehicleOffers />
    <OfferSideBar />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  SET_VEHICLE_FILTER_ACTION,
  SET_VEHICLES_FILTERS,
  GET_VEHICLES
} from './actions';
import { listVehiclesMixin } from './mixins';
import { generalMixin } from '@/modules/mixin';
import VehicleOffers from '../offers/VehicleOffers';
import ListComponents from './ListComponents';
import ActiveOffers from '../offers/ActiveOffers';
import ExpiredOffers from '../offers/ExpiredOffers';
import Purchases from '../offers/Purchases';
import OfferSideBar from '@/modules/admin/vehicles/offers/components/SideBar';
import PublicVehiclesList from './PublicList';
import {
  SHOW_OFFERS_SIDEBAR,
  SHOW_OFFER_SIDEBAR,
  SET_OFFER_SIDEBAR
} from '@/modules/admin/vehicles/offers/actions';
import { GET_PUBLIC_VEHICLES } from '@/modules/home/actions';

export default {
  components: {
    ListComponents,
    VehicleOffers,
    ActiveOffers,
    ExpiredOffers,
    Purchases,
    OfferSideBar,
    PublicVehiclesList
  },
  mixins: [ listVehiclesMixin, generalMixin ],
  data() {
    return {
      params: {},
      tab: 0,
      notificationsInterval: null
    };
  },
  computed: {
    ...mapState({
      vehicles: (state) => state.adminStore.vehicles.listStore.vehicles.data,
      vehiclesStatus: (state) =>
        state.adminStore.vehicles.listStore.vehicles.status,
      notifications: (state) =>
        state.adminStore.notifications.notifications.data,
      profile: (state) => state.auth.loginStore.profile.data
    }),
    notificationsCount() {
      if (this.notifications.data != null) {
        let newNotifications = this.notifications.data.filter(
          (notification) => notification.newNotification == 1
        );
        return newNotifications.length;
      } else {
        return 0;
      }
    },
    pagination() {
      let data = [];
      switch (this.filterAction) {
      case 'GET_VEHICLES':
        data = this.allVehicles;
        break;
      case 'PUBLIC_GET_VEHICLES':
        data = this.publicVehicles;
        break;
      case 'GET_ACTIVE_OFFERS':
        data = this.activeOffers;
        break;
      case 'GET_EXPIRED_OFFERS':
        data = this.expiredOffers;
        break;
      case 'GET_PURCHASES':
        data = this.purchases;
        break;
      case 'GET_VEHICLES_WITH_OFFERS':
        data = this.vehiclesWithOffers;
        break;
      }
      return data;
    },
    witdhResponsive() {
      return this.$vssWidth > 1280
        ? '25%'
        : this.$vssWidth <= 576
          ? '100%'
          : '50%';
    }
  },
  mounted() {
    if (this.user) {
      this.getVehicles();
    } else {
      this.getPublicVehicles();
    }
    // Get vehicle filters in list vehicles tab //
    if (this.userIsBuyer || this.userIsClient)
      this.$refs.ListVehicles.$refs.searchVehiclesComponent.getFilters();
  },
  destroyed() {
    this.clearOfferSideBar();
    this.hideOffers();
  },
  methods: {
    showOffers() {
      this.clearOfferSideBar();
      this.showOffersFullScreen();
    },
    showOffersFullScreen() {
      this.user.roles.forEach((value) => {
        switch (value.name) {
        case 'ROLE_ADMIN':
          this.$router.push({ name: 'admin.vehicles.offers' });
          break;
        case 'ROLE_AGENT':
          this.$router.push({ name: 'agent.vehicles.offers' });
          break;
        }
      });
    },
    hideOffers() {
      this.$store.commit(SHOW_OFFERS_SIDEBAR, false);
    },
    clearVehicleFilters() {
      this.$store.commit(SET_VEHICLES_FILTERS, { reset: true });
    },
    getVehicles() {
      /**
       * @description Reset action to get normal vehicle list Instead of to get offers lists
       * (Tab Menu for buyer & agents and view offers in full screen)
       */
      this.$store.commit(SET_VEHICLE_FILTER_ACTION, null);
      // Get normal vehicle list //
      this.$store
        .dispatch(GET_VEHICLES, this.params)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast('Could not get the vehicle list', {
            title: 'Warning',
            variant: 'warning'
          });
        });
    },
    changePage(page) {
      this.$store.commit(SET_VEHICLES_FILTERS, { fields: { page } });
      switch (this.filterAction) {
      case 'GET_VEHICLES':
        this.getVehicles();
        break;
      case 'PUBLIC_GET_VEHICLES':
        this.getPublicVehicles();
        break;
      case 'GET_ACTIVE_OFFERS':
        this.getActiveOffers();
        break;
      case 'GET_EXPIRED_OFFERS':
        this.getExpiredOffers();
        break;
      case 'GET_PURCHASES':
        this.getPurchases();
        break;
      case 'GET_VEHICLES_OFFERS':
        this.$store.dispatch(this.filterAction);
        break;
      }
    },
    changeTabNumber(number) {
      this.clearVehicleFilters();
      this.tab = number;
      switch (number) {
      case 1:
        this.$refs.ActiveOffers.$refs.searchVehiclesComponent.getFilters();
        this.$store.commit(SET_VEHICLE_FILTER_ACTION, 'GET_ACTIVE_OFFERS');
        this.getActiveOffers();
        break;
      }
    },
    changeTab(action) {
      this.resetFilters(false);
      this.$store.commit(SET_VEHICLE_FILTER_ACTION, action);
      this.clearOfferSideBar();
      switch (action) {
      case 'GET_ACTIVE_OFFERS':
        this.$refs.ActiveOffers.$refs.searchVehiclesComponent.clearElementsInSearch();
        this.$refs.ActiveOffers.$refs.searchVehiclesComponent.getFilters();
        this.getActiveOffers();
        break;
      case 'GET_EXPIRED_OFFERS':
        this.$refs.ExpiredOffers.$refs.searchVehiclesComponent.clearElementsInSearch();
        this.$refs.ExpiredOffers.$refs.searchVehiclesComponent.getFilters();
        this.getExpiredOffers();
        break;
      case 'GET_PURCHASES':
        this.$refs.Purchases.$refs.searchVehiclesComponent.clearElementsInSearch();
        this.$refs.Purchases.$refs.searchVehiclesComponent.getFilters();
        this.getPurchases();
        break;
      default:
        this.$refs.ListVehicles.$refs.searchVehiclesComponent.clearElementsInSearch();
        this.$refs.ListVehicles.$refs.searchVehiclesComponent.getFilters();
        this.getVehicles();
        break;
      }
    },
    goTo() {
      if (this.profile.user.roles[0].name == 'ROLE_ADMIN') {
        this.$router.push({ path: '/admin/notifications' });
      } else {
        this.$router.push({ path: '/agent/notifications' });
      }
    },
    goToDisableNotifications() {
      this.$router.push({ path: '/disable/notifications' });
    },
    clearOfferSideBar() {
      this.$store.commit(SHOW_OFFER_SIDEBAR, false);
      this.$store.commit(SET_OFFER_SIDEBAR, null);
    },
    getPublicVehicles() {
      this.$store
        .dispatch(GET_PUBLIC_VEHICLES, true)
        .then(() => {})
        .catch(() => {});
    }
  }
};
</script>

<style>
.tab {
  background-color: #f7f7f7;
  font-weight: bold;
}

.btn.btn-circle {
  border-radius: 1rem !important;
  width: 14% !important;
  height: auto;
}
</style>
