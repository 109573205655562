import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    permissionsList: {
      status: null,
      data: [],
      error: null,
    },

    userPermissions: {
      status: null,
      data: [],
      error: null,
    },
  };
}
const actions = {
  [constants.GET_PERMISSIONS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_PERMISSIONS);

      const response = await axios.get("permissions/listPermissions", {
        params: payload,
      });
      commit(constants.GET_PERMISSIONS_SUCCESS, response);
      commit;
      return response;
    } catch (error) {
      commit(constants.GET_PERMISSIONS_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_USER_PERMISSIONS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_USER_PERMISSIONS);

      const response = await axios.get("permissions/listUsers", {
        params: payload,
      });
      commit(constants.GET_USER_PERMISSIONS_SUCCESS, response);
      commit;
      return response;
    } catch (error) {
      commit(constants.GET_USER_PERMISSIONS_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_USER_PERMISSIONS]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_USER_PERMISSIONS);

      const response = await axios.post(
        "permissions/assignPermissions",
        payload
      );
      commit(constants.POST_USER_PERMISSIONS_SUCCESS, response);
      commit;
      return response;
    } catch (error) {
      commit(constants.POST_USER_PERMISSIONS_ERROR, error);
      throw error.response;
    }
  },

  [constants.GET_MENU_PERMISSIONS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_MENU_PERMISSIONS);

      const response = await axios.get("permissions/listMenuPermissions", {
        params: payload,
      });
      commit(constants.GET_MENU_PERMISSIONS_SUCCESS, response);
      commit;
      return response;
    } catch (error) {
      commit(constants.GET_MENU_PERMISSIONS_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_USER_MENU_PERMISSIONS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_USER_MENU_PERMISSIONS);

      const response = await axios.get("permissions/listUsers", {
        params: payload,
      });
      commit(constants.GET_USER_MENU_PERMISSIONS_SUCCESS, response);
      commit;
      return response;
    } catch (error) {
      commit(constants.GET_USER_MENU_PERMISSIONS_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_USER_MENU_PERMISSIONS]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_USER_MENU_PERMISSIONS);

      const response = await axios.post(
        "permissions/assignMenuPermissions",
        payload
      );
      commit(constants.POST_USER_MENU_PERMISSIONS_SUCCESS, response);
      commit;
      return response;
    } catch (error) {
      commit(constants.POST_USER_MENU_PERMISSIONS_ERROR, error);
      throw error.response;
    }
  },

};

const mutations = {
  [constants.GET_PERMISSIONS]: (state) => {
    state.permissionsList.data = [];
    state.permissionsList.status = "fetching";
  },
  [constants.GET_PERMISSIONS_SUCCESS]: (state, response) => {
    state.permissionsList.status = "success";
    state.permissionsList.data = response.data;
  },
  [constants.GET_PERMISSIONS_ERROR]: (state, error) => {
    state.permissionsList.status = "error";
    state.permissionsList.error = error;
  },
  [constants.GET_USER_PERMISSIONS]: (state) => {
    state.userPermissions.data = [];
    state.userPermissions.status = "fetching";
  },
  [constants.GET_USER_PERMISSIONS_SUCCESS]: (state, response) => {
    state.userPermissions.status = "success";
    state.userPermissions.data = response.data;
    state.userPermissions.error = null;
  },
  [constants.GET_USER_PERMISSIONS_ERROR]: (state, error) => {
    state.userPermissions.status = "error";
    state.userPermissions.error = error;
  },
  [constants.POST_USER_PERMISSIONS]: (state) => {
    state.userPermissions.data = [];
    state.userPermissions.status = "fetching";
  },
  [constants.POST_USER_PERMISSIONS_SUCCESS]: (state, response) => {
    state.userPermissions.status = "success";
    state.userPermissions.data = response.data;
    state.userPermissions.error = null;
  },
  [constants.POST_USER_PERMISSIONS_ERROR]: (state, error) => {
    state.userPermissions.status = "error";
    state.userPermissions.error = error;
  },

  [constants.GET_MENU_PERMISSIONS]: (state) => {
    state.permissionsList.data = [];
    state.permissionsList.status = "fetching";
  },
  [constants.GET_MENU_PERMISSIONS_SUCCESS]: (state, response) => {
    state.permissionsList.status = "success";
    state.permissionsList.data = response.data;
  },
  [constants.GET_MENU_PERMISSIONS_ERROR]: (state, error) => {
    state.permissionsList.status = "error";
    state.permissionsList.error = error;
  },
  [constants.GET_USER_MENU_PERMISSIONS]: (state) => {
    state.userPermissions.data = [];
    state.userPermissions.status = "fetching";
  },
  [constants.GET_USER_MENU_PERMISSIONS_SUCCESS]: (state, response) => {
    state.userPermissions.status = "success";
    state.userPermissions.data = response.data;
    state.userPermissions.error = null;
  },
  [constants.GET_USER_MENU_PERMISSIONS_ERROR]: (state, error) => {
    state.userPermissions.status = "error";
    state.userPermissions.error = error;
  },
  [constants.POST_USER_MENU_PERMISSIONS]: (state) => {
    state.userPermissions.data = [];
    state.userPermissions.status = "fetching";
  },
  [constants.POST_USER_MENU_PERMISSIONS_SUCCESS]: (state, response) => {
    state.userPermissions.status = "success";
    state.userPermissions.data = response.data;
    state.userPermissions.error = null;
  },
  [constants.POST_USER_MENU_PERMISSIONS_ERROR]: (state, error) => {
    state.userPermissions.status = "error";
    state.userPermissions.error = error;
  },

};
export default {
  state: getDefaultState(),
  actions,
  mutations,
};
