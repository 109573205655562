<!-- @format -->

<template>
  <div>
    <div class="container">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
          <b-form class="container mb-3" @submit.stop.prevent="save">
            <b-row>
              <b-col sm="12" md="12" lg="12" xl="12">
                <b-form-group label-size="md">
                  <template slot="label"> Name</template>
                  <b-form-input
                    name="name"
                    maxlength="200"
                    :value="form.name"
                    @change="setValue('name', $event)"
                  />
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="12" xl="12">
                <b-form-group label-size="md">
                  <template slot="label">
                    Address
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    v-if="isAddressSelected"
                    v-model="address"
                    size="md"
                    name="street"
                    class="mb-1"
                    maxlength="65"
                    @focus="isAddressSelected = false"
                  />
                  <place-autocomplete-field
                    v-else
                    v-model="address"
                    v-place-autofill:street="form.street"
                    v-place-autofill:city="form.cityId"
                    v-place-autofill:state="form.state"
                    v-place-autofill:zipcode="form.zip"
                    name="address"
                    api-key="AIzaSyBWj-z1916MmpcimZW3xPlRGXMikForpS4"
                    placeholder="Enter an an address, zipcode, or location"
                    country="us"
                    @autocomplete-select="selectAddress"
                  />
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="12" xl="12">
                <b-form-group label-size="md">
                  <template slot="label"> Address line 2 </template>
                  <b-form-input
                    name="addressLine2"
                    maxlength="200"
                    :value="form.addressLine2"
                    @change="setValue('addressLine2', $event)"
                  />
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label">
                    Street
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    v-model.trim="$v.form.street.$model"
                    required
                    size="sm"
                    name="street"
                    maxlength="65"
                    :value="form.street"
                    :state="validateState('street')"
                    @change="setValue('street', $event)"
                  />
                  <b-form-invalid-feedback>
                    <div v-if="!$v.form.street.required">
                      This field is required
                    </div>
                    <div v-if="!$v.form.street.maxLength">
                      Character limit reached
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label">
                    ZIP postal code
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    v-model.trim="$v.form.zip.$model"
                    required
                    size="sm"
                    name="zip"
                    maxlength="10"
                    :value="form.zip"
                    :state="validateState('zip')"
                    @change="setValue('zip', $event)"
                  />
                  <b-form-invalid-feedback>
                    <div v-if="!$v.form.zip.required">
                      This field is required
                    </div>
                    <div v-if="!$v.form.zip.maxLength">
                      Character limit reached
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label">
                    State
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    v-model.trim="$v.form.state.$model"
                    disabled
                    required
                    size="sm"
                    name="state"
                    maxlength="250"
                    :value="form.state"
                    :state="validateState('state')"
                    @change="setValue('state', $event)"
                  />
                  <b-form-invalid-feedback>
                    <div v-if="!$v.form.state.required">
                      This field is required
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label">
                    City
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    v-model.trim="$v.form.cityId.$model"
                    disabled
                    required
                    size="sm"
                    name="city"
                    maxlength="250"
                    :value="form.cityId"
                    :state="validateState('cityId')"
                    @change="setValue('cityId', $event)"
                  />
                  <b-form-invalid-feedback>
                    <div v-if="!$v.form.cityId.required">
                      This field is required
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label"> Website </template>
                  <b-form-input
                    v-model.trim="form.webSite"
                    size="md"
                    name="webSite"
                    placeholder="www.yourSite.com"
                    maxlength="65"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label"> Logo </template>
                  <b-input-group>
                    <b-form-file
                      v-model.trim="form.logo"
                      size="md"
                      name="webSite"
                      maxlength="65"
                    />
                    <b-input-group-append v-if="marketplaceItem.logo">
                      <b-button variant="none">
                        <a :href="marketplaceItem.logo" target="_blank">
                          <b-icon icon="eye" variant="success" />
                        </a>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label"> Currency type </template>
                  <b-form-select
                    v-model.trim="form.typeCurrency"
                    size="md"
                    name="typeCurrency"
                    :options="typesOfCurrency"
                  />
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label"> No reply email </template>
                  <b-form-input
                    v-model.trim="form.noReplyEmail"
                    size="md"
                    name="noReplyEmail"
                    type="email"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12" md="12" lg="6" xl="6" />
              <b-col sm="12" md="6" lg="6" xl="6" class="mb-4" />
              <b-col sm="12" md="3" lg="3" xl="3" class="mb-4" />
              <b-col sm="12" md="3" lg="3" xl="3" class="mb-4">
                <b-button
                  class="float-right"
                  variant="success"
                  type="submit"
                  block
                  :disabled="$v.form.$invalid"
                >
                  <b-spinner
                    v-if="marketplace.status == 'fetching'"
                    small
                    variant="white"
                  />
                  <span v-else>Save</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import { POST_MARKETPLACE } from "./actions";
import { mapState } from "vuex";
export default {
  props: ["marketplaceItem", "refreshData", "hideModal"],
  data() {
    return {
      isAddressSelected: false,
      address: null,
      form: {
        password: "",
        confirmPassword: "",
        fullName: "",
        street: "",
        zip: "",
        state: "",
        cityId: "",
        webSite: "",
        name: "",
        addressLine2: "",
        logo: null,
        noReplyEmail: "",
        currency: null,
      },
      states: [],
      cities: [],
      showPassword: false,
      showConfirmPassword: false,
      typesOfCurrency: [
        { value: "USD", text: "USD" },
        { value: "CAD", text: "CAD" },
      ],
    };
  },
  validations: {
    form: {
      street: {
        required,
        maxLength: maxLength(250),
      },
      zip: {
        required,
        maxLength: maxLength(10),
      },
      state: {
        required,
      },
      cityId: {
        required,
      },
      logo: {
        required,
      },
    },
  },
  computed: {
    ...mapState({
      marketplace: (state) => state.masterUser.marketplace.marketplace,
    }),
  },
  mounted() {
    if (this.marketplaceItem.id) {
      this.formatForm();
    }
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    save() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_MARKETPLACE, payload)
        .then(async () => {
          await this.$root.$bvToast.toast("Marketplace successfully saved", {
            title: "Success",
            variant: "success",
          });
          this.hideModal();
          this.refreshData();
        })
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },

    formatPayload() {
      const data = new FormData();
      data.set("id", this.marketplaceItem.id ? this.marketplaceItem.id : null);
      data.set("street", this.form.street);
      data.set("zip", this.form.zip);
      data.set("state", this.form.state);
      data.set("webSite", this.form.webSite);
      data.set("city", this.form.cityId);
      data.set("name", this.form.name);
      data.set("addressLine2", this.form.addressLine2);
      data.set("logo", this.form.logo);
      data.set("noReplyEmail", this.form.noReplyEmail);
      data.set("currency", this.form.typeCurrency);
      return data;
    },

    formatForm() {
      this.form.id = this.marketplaceItem.id;
      this.form.street = this.marketplaceItem.street;
      this.form.zip = this.marketplaceItem.zip;
      this.form.state = this.marketplaceItem.state;
      this.form.cityId = this.marketplaceItem.city;
      this.form.webSite = this.marketplaceItem.webSite;
      this.form.name = this.marketplaceItem.name;
      this.form.addressLine2 = this.marketplaceItem.addressLine2;
      this.form.logo = this.marketplaceItem.logo;
      this.form.noReplyEmail = this.marketplaceItem.noReplyEmail;
    },

    selectAddress() {
      this.isAddressSelected = true;
    },
  },
};
</script>

<style></style>
