<template>
  <b-card class="mb-1">
    <b-row>
      <b-col cols="12" class="text-wrap">
        <label class="h6 text-sm">
          <span
            class="avatar avatar-sm rounded-circle bg-success text-white"
            v-b-tooltip.hover
          >
            {{ acronym }}
          </span>
          {{ taskLog.firstName }} {{ taskLog.lastName }} -
          <label class="text-muted">
            {{ taskLog.createdAt | formatDateTime }}</label
          >
        </label>
      </b-col>
      <b-col>
        <p>{{ taskLog.action }}</p>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "TaskLogsListCard",
  props: {
    taskLog: {
      type: Object,
      required: true,
    },
  },
  computed: {
    acronym() {
      return (
        this.taskLog?.firstName.charAt(0) + this.taskLog?.lastName.charAt(0) ||
        ""
      );
    },
  },
};
</script>

<style></style>
