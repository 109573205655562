<template>
  <b-form @submit.stop.prevent="saveContract()">
    <b-row>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label="">
          <template slot="label"> Average fx rate </template>
          <b-form-input :value="fxRate.buy" type="text" readonly />
          <b-form-text
            >Fx days: {{ fxRate.createdAt | formatDate }} -
            {{ getDayAgo(fxRate.createdAt, 7) | formatDate }}</b-form-text
          >
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label="Accounting date">
          <template slot="label">Contract Fx </template>

          <b-form-input v-model="form.fx" type="text" />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label="Accounting date">
          <template slot="label">Vehicle Fx </template>

          <b-form-input v-model="form.vehicleFx" type="text" />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label="Accounting date">
          <template slot="label"> Start date </template>

          <b-form-input v-model="form.startDate" type="date" />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label="Accounting date">
          <template slot="label"> End date </template>

          <b-form-input v-model="form.endDate" type="date" />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label="Accounting date">
          <template slot="label"> Contract number </template>

          <b-form-input v-model="form.contractNumber" type="text" />
        </b-form-group>
      </b-col>

      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label="">
          <template slot="label"> Amount </template>
          <Money
            v-model="form.amount"
            class="form-control"
            v-bind="money"
            aria-describedby="input-amount-live-feedback"
            required
          />
          <b-form-invalid-feedback id="input-amount-live-feedback">
            This is a required field.
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <div class="text-right">
      <b-button
        variant="primary"
        type="submit"
        :disabled="exchangeContract.status == 'fetching'"
      >
        <b-spinner v-if="exchangeContract.status === 'fetching'" variant="white" small />

        <span v-else>Submit</span>
      </b-button>
    </div>
  </b-form>
</template>

<script>
import { GET_FX_RATE, POST_EXCHANGE_CONTRACT } from "./actions";
import { mapState } from "vuex";
import { Money } from "v-money";

export default {
  name: "ExchangeContractForm",
  props: {
    refreshData: {
      type: Function,
      default: () => {},
    },
  },
  components: { Money },
  computed: {
    ...mapState({
      fxRate: (state) => state.superAdminStore.exchangeContract.fxRate.data,
      exchangeContract: (state) =>
        state.superAdminStore.exchangeContract.exchangeContract,
    }),
  },
  data() {
    return {
      form: {
        fx: null,
        amount: 0,
        amountCad: null,
        fxRate: null,
        startDate: null,
        endDate: null,
        contractNumber: null,
        vehicleFx: null,
      },
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        precision: 2,
        masked: false,
        suffix: " USD",
      },
    };
  },

  created() {
    this.getFx();
  },
  methods: {
    getFx() {
      this.$store.dispatch(GET_FX_RATE).then(() => {});
    },
    saveContract() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_EXCHANGE_CONTRACT, payload)
        .then(() => {
          this.$bvToast.toast("Exchange contract created successfully", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.refreshData();
          this.$bvModal.hide("exchange-contract-details-modal");
        })
        .catch((err) => {
          console.log(err);
          this.$bvToast.toast("Error creating exchange contract", {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        });
    },
    formatPayload() {
      return {
        fx: this.form.fx,
        amount: this.form.amount,
        amountCad: this.form.amountCad,
        fxRate: this.form.fx,
        vehicleFx: this.form.vehicleFx,
        startDate: this.form.startDate,
        endDate: this.form.endDate,
        contractNumber: this.form.contractNumber,
      };
    },
    getDayAgo(date, days) {
      const d = new Date(date);
      d.setDate(d.getDate() - days);
      return d;
    },
  },
};
</script>

<style></style>
