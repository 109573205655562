export const GET_RECALLS = 'GET_RECALLS';
export const GET_RECALLS_SUCCESS = 'GET_RECALLS_SUCCESS';
export const GET_RECALLS_ERROR = 'GET_RECALLS_ERROR';

export const POST_RECALL = 'POST_RECALL';
export const POST_RECALL_SUCCESS = 'POST_RECALL_SUCCESS';
export const POST_RECALL_ERROR = 'POST_RECALL_ERROR';

export const PUT_RECALL = 'PUT_RECALL';
export const PUT_RECALL_SUCCESS = 'PUT_RECALL_SUCCESS';
export const PUT_RECALL_ERROR = 'PUT_RECALL_ERROR';

export const POST_CHANGE_STATUS = 'POST_CHANGE_STATUS';
export const POST_CHANGE_STATUS_SUCCESS = 'POST_CHANGE_STATUS_SUCCESS';
export const POST_CHANGE_STATUS_ERROR = 'POST_CHANGE_STATUS_ERROR';