import {
  SET_CLIENT_VEHICLES_INFO_FILTERS,
  GET_CLIENT_VEHICLES_INFO,

} from "./actions";

import { mapState } from "vuex";

const clientVehicleMixin = {
  computed: {
    ...mapState({
      userPermissions: (state) =>
        state.auth.loginStore.profile.data.user.listPermissions,
      userMenuPermissions: (state) =>
        state.auth.loginStore.profile.data.user.menuPermissions,
      vehicleAnchor: (state) => state.superAdminStore.vehicleAnchor,
      vehicles: (state) => state.superAdminStore.vehiclesInfo,
    }),
  },
  data() {
    return {};
  },

  methods: {
    getVehiclesInfo() {
      this.$store
        .dispatch(GET_CLIENT_VEHICLES_INFO)
        .then(() => {
          this.getBoardInformationByRouter()
          this.scrollToAnchor(this.vehicleAnchor);
        })
        .catch();
    },

    getBoardInformationByRouter() {
      switch (this.$route.name) {
        case "superadmin.vehicles.table.logistic":
          this.getLogisticBoardInformation()
          break;
        case "superadmin.vehicles.table.admin":
          this.getAdminBoardInformation()
          break;
        case "superadmin.vehicles.table.inventory":
          this.getBusinessBoardInformation()
          break;
        case "superadmin.vehicles.table.export":
          this.getExportBoardInformation()
          break;
        case "superadmin.vehicles.table.yard":
          this.getYardBoardInformation()
          break;

        default:
          break;
      }
    },

    goToDashBoard(vehicle) {
      this.$router.push({
        name: "superadmin.vehicles.dashboard",
      });
      this.$store.commit(SET_CLIENT_VEHICLES_INFO_FILTERS, vehicle);
    },
    goToVehicleUnitPage(id) {
      /* encrypt id */
      const encryptedId = this.$CryptoJS.AES.encrypt(
        JSON.stringify(id),
        "Secret Passphrase"
      ).toString();

      this.$router.push({
        name: "superadmin.vehicles.unit",
        params: {

          id: encryptedId,
        },
      });
    },
    goToInitialInformation(vehicle, permission) {
      const userHasPermission = this.userHasPermission(
        permission.module,
        permission.action
      );
      if (userHasPermission) {
        this.$router.push({
          name: "vehicle.search.stock",
          params: { stock: vehicle.stockNumber },
        });
      } else {
        this.toastMessage(
          "You don't have permission to perform this action",
          "Warning",
          "warning"
        );
      }
    },
    changePage(page) {
      this.$store.commit(SET_CLIENT_VEHICLES_INFO_FILTERS, {
        fields: { page },
      });
      this.getVehiclesInfo();
    },
    resetFilters() {
      this.$store.commit(SET_CLIENT_VEHICLES_INFO_FILTERS, { reset: true });
    },
    search(search) {
      this.resetFilters();
      this.$store.commit(SET_CLIENT_VEHICLES_INFO_FILTERS, {
        fields: { search, page: 1 },
      });
      this.getVehiclesInfo();
    },

    goToVisualInspection(vehicle) {
      const userHasPermission = this.userHasPermission("vi", "read");
      if (userHasPermission) {
        this.$router.push({
          name: "company.cars.general",
          params: { vin: vehicle.stockNumber },
        });
      } else {
        return this.toastMessage(
          "You don't have permission to perform this action",
          "Warning",
          "warning"
        );
      }
    },



    hasFilter(key) {
      if (
        key == "saleDate" ||
        key == "reserveDate"

      ) {
        return true;
      }
      return false;
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.alert === 1) return "table-danger";
      if (item.stockNumber === this.vehicleAnchor) return "table-info";
    },

    userHasPermission(module, action) {
      const permissions = this.userPermissions;
      const permissionModule = permissions.find((permission) => {
        return permission.module === module;
      });
      if (!permissionModule) return false;

      return permissionModule[action] == 1 ? true : false;
    },
    userHasMenuPermission(name) {
      const permissions = this.userMenuPermissions;
      if (!permissions) return false;
      const menuPermission = permissions.find((permission) => {
        return permission.name == name;
      }
      );
      return menuPermission ? true : false;
    },
    scrollToAnchor(stockNumber) {
      const element = document.getElementById(stockNumber);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    },
    updateVehicleFlag(vehicleId, flag, value) {
      let vehiclesToFindFlag = [...this.vehicles.data.paginate.data];
      const index = vehiclesToFindFlag.findIndex(
        (vehicle) => vehicle.id == vehicleId
      );
      this.$store.commit("UPDATE_VEHICLE_FLAG", {
        index: index,
        flag: flag,
        value: value,
      });
    },
    openOfferSidebar(vehicle) {
      const userHasPermission = this.userHasPermission(
        'offers',
        'read'
      );
      if (userHasPermission) {
        this.$store.commit("SHOW_OFFER_SIDEBAR", false);
        this.$store.commit("SHOW_OFFERS_SIDEBAR", false);
        let vehicleFormatted = this.formatVehicleInformation(vehicle);
        this.getVehicleOffers(vehicleFormatted);
        setTimeout(() => {
          this.$store.commit("SHOW_OFFER_SIDEBAR", true);
        }, 1000);
      } else {
        this.toastMessage(
          "You don't have permission to perform this action",
          "Warning",
          "warning"
        );
      }

    },


    formatVehicleInformation(vehicle) {
      let vehicleFormatted = {
        ...vehicle.vehicleCostDetail, ...vehicle.vinNumber, ...vehicle.vehicleInformation
        , vehicleId: vehicle.id

      }
      return vehicleFormatted

    }

  },
};

export { clientVehicleMixin };
