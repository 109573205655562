import store from "@/store";
import * as constants from "./actions";
import axios from "@/plugins/axios.js";
import { SET_PROFILE_DATA } from "../login/actions";

function getDefaultState() {
  return {
    checkToken: {
      status: null,
      data: null,
      error: null,
    },
  };
}

const actions = {
  [constants.AUTH_CHECK_TOKEN]: async ({ commit }) => {
    try {
      const refreshToken = localStorage.getItem("refresh-token");
      const corporation = localStorage.getItem("corporation");
      commit(constants.AUTH_CHECK_TOKEN);
      const response = await axios.post("auth/checkToken", {
        refreshToken,
        corporation,
      });
      commit(constants.AUTH_CHECK_TOKEN_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.AUTH_CHECK_TOKEN_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.AUTH_CHECK_TOKEN]: (state) => {
    state.checkToken.status = "fetching";
    state.checkToken.error = null;
  },
  [constants.AUTH_CHECK_TOKEN_SUCCESS]: (state, data) => {
    localStorage.setItem("access-token", data.token.accessToken);
    localStorage.setItem("refresh-token", data.token.refreshToken);
    localStorage.setItem('favoriteBoard', data.user.favorite)
    store.commit(SET_PROFILE_DATA, data);
    state.checkToken.status = "success";
    state.checkToken.error = null;
  },
  [constants.AUTH_CHECK_TOKEN_ERROR]: (state, error) => {
    state.checkToken.status = "error";
    state.checkToken.errors = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
