<template>
  <b-table
    class="mt-3"
    show-empty
    bordered
    small
    stacked="md"
    head-variant="light"
    :items="commissionsSelected"
    :fields="fields"
  >
  </b-table>
</template>

<script>
export default {
  name: "CommissionBreakdown",
  props: {
    commissionsSelected: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "concept",
          label: "Concept",
          class: "text-center",
        },
        {
          key: "credit",
          label: "Credit",
          class: "text-right",
          formatter: (value) => this.$options.filters.currency(value),
        },
        {
          key: "debits",
          label: "Debits",
          class: "text-right",
          formatter: (value) => this.$options.filters.currency(value),
        },
      ],
    };
  },
};
</script>

<style>
</style>