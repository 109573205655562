<template>
  <div class="mt-3">
    <div class="d-flex justify-content-end">
      <b-button
        v-if="showButton"
        variant="success"
        class=""
        @click="updateWorkOrdersStatus()"
        >Vendre tel quel
      </b-button>
    </div>

    <b-list-group-item
      v-for="(workOrderProvider, i) in workOrders"
      :key="i"
      class="flex-column align-items-start p-0 mt-2"
      tag="li"
    >
      <div class="accordion" role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-header="'accordion-1' + i"
              class="text-left"
              variant="dark-green"
            >
              <span class="text-left">
                {{ workOrderProvider.companyName }}
              </span>
              <span class="text-right">
                <b-badge pill variant="white" class="text-dark">{{
                  workOrderProvider.workOrders.length
                }}</b-badge>
              </span>
              <span> </span>
            </b-button>
          </b-card-header>
          <b-collapse
            :id="'accordion-1' + i"
            accordion="my-accordion"
            role="tabpanel"
            visible
          >
            <WorkOrderCard
              v-for="(workOrder, i) in workOrderProvider.workOrders"
              :key="i"
              :workOrder="workOrder"
              :refreshData="refreshData"
            />
          </b-collapse>
        </b-card>
      </div>
    </b-list-group-item>
  </div>
</template>

<script>
import WorkOrderCard from "./WorkOrderCard.vue";
import { PUT_WORK_ORDER_PUBLIC_DATA } from "../actions";
export default {
  name: "WorkOrdersList",
  props: {
    workOrders: {
      type: Array,
      required: true,
    },
    refreshData: {
      type: Function,
      required: false,
    },
    vehicleId: {
      type: String,
      required: true,
    },
    showButton: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  components: {
    WorkOrderCard,
  },

  methods: {
    updateWorkOrdersStatus() {
      this.$store
        .dispatch(PUT_WORK_ORDER_PUBLIC_DATA, {
          id: this.vehicle.id,
        })
        .then(() => {
          this.refreshData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
