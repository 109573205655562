export const GET_LOCATIONS = "GET_LOCATIONS";
export const GET_LOCATIONS_SUCCESS = "GET_LOCATIONS_SUCCESS";
export const GET_LOCATIONS_ERROR = "GET_LOCATIONS_ERROR";

export const POST_LOCATION = "POST_LOCATION";
export const POST_LOCATION_SUCCESS = "POST_LOCATION_SUCCESS";
export const POST_LOCATION_ERROR = "POST_LOCATION_ERROR";

export const DELETE_LOCATION = "DELETE_LOCATION";
export const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS";
export const DELETE_LOCATION_ERROR = "DELETE_LOCATION_ERROR";
