<template>
  <div>
    <b-row>
      <b-col cols="12">
        <div class="row">
          <b-col cols="12">
            <span class="text-muted">
              <small>{{ trim.year }}</small>
            </span>
            <h3 class="mb-0">{{ trim.model }}</h3>
            <p class="text-muted">{{ trim.vin }}</p>
          </b-col>
        </div>
        <div class="row justify-content-between text-center align-items-center">
          <div class="col-4 mh-auto">
            <span>{{ trim.trim }}</span>
          </div>
          <div class="col-4 mh-auto">
            <span>{{ trim.color }}</span>
          </div>
          <div class="col-4 mh-auto">
            <span>{{ trim.price | currency }}</span>
          </div>
        </div>
      </b-col>
    </b-row>
    <p class="text-justify mb-1">
      {{ trim.description }}
    </p>

    <p></p>
  </div>
</template>

<script>
export default {
  props: {
    trim: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>