<template>
  <div>
    <b-row>
      <b-col cols="10">
        <small class="text-muted">
          Created at: {{ note.createdAt | formatDateTime }}
        </small>
      </b-col>
      <b-col>
        <div class="text-right">
          <b-icon
            v-if="userHasPermission('carfax', 'write')"
            scale="1.3"
            icon="trash"
            class="cursor-pointer"
            v-b-tooltip.hover
            title="Delete"
            @click="deleteNote(note)"
          ></b-icon>
        </div>
      </b-col>
    </b-row>
    <p class="text-justify mt-3">
      {{ note.description }}
    </p>
  </div>
</template>

<script>
import { superAdminMixin } from "@/modules/superAdmin/mixins.js";
export default {
  props: ["note", "refreshData"],

  mixins: [superAdminMixin],
  methods: {
    deleteNote(note) {
      this.$store
        .dispatch("DELETE_CARFAX_NOTE", { id: note.id })
        .then(() => {
          this.toastMessage("Note deleted successfully", "Success", "success");

          this.refreshData();
        })
        .catch(() => {});
    },
  },
};
</script>

<style></style>
