<template>
  <div>
    <b-row>
      <br />
      <b-col v-if="payment.tax1" cols="12">
        <b>{{ payment.tax1 }}</b> {{ payment.amount1 | currency }}
      </b-col>
      <b-col v-if="payment.tax2" cols="12">
        <b>{{ payment.tax2 }}</b> {{ payment.amount2 | currency }}
      </b-col>
      <b-col v-if="payment.tax3" cols="12">
        <b>{{ payment.tax3 }}</b> {{ payment.amount3 | currency }}
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: ["payment"],
  name: "AmountBreakdown",
};
</script>

<style>
</style>