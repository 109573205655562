<template>
  <div>
    <div class="container-fluid">
      <b-row class="mt-3">
        <b-col>
          <h4 class="ml-3">Export port</h4>
        </b-col>
      </b-row>
      <div class="pt-4">
        <TableBase
          :items="exportPorts.data"
          :fields="fields"
          :options="options"
          :busy="exportPorts.status === 'fetching' ? true : false"
        >
          <template v-slot:rightSide>
            <div>
              <b-button
                v-if="userHasPermission('exportPort', 'write')"
                variant="success"
                class="align-bottom"
                @click="showModal()"
              >
                <b-icon icon="plus" font-scale="1.1" />

                <span class="" />
              </b-button>
            </div>
          </template>
        </TableBase>
      </div>

      <b-modal
        id="detailsModal"
        ref="detailsModal"
        size="lg"
        hide-footer
        body-class="pl-0 pr-0"
        ok-disabled
        title="Export port"
        @hidden="exportPortItem = {}"
      >
        <ExportPortRegistrationForm
          :exportPortItem="exportPortItem"
          :refreshData="getData"
          :hideModal="hideModal"
        />
      </b-modal>
    </div>
  </div>
</template>
<script>
import TableBase from "@/modules/shared/table.vue";
import ExportPortRegistrationForm from "./ExportPortRegistrationForm.vue";

import { GET_EXPORT_PORT, PUT_EXPORT_PORT } from "./actions";
import { mapState } from "vuex";

import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../mixins";
export default {
  name: "ExportPortTable",
  components: { TableBase, ExportPortRegistrationForm },
  mixins: [generalMixin, superAdminMixin],
  computed: {
    ...mapState({
      exportPorts: (state) => state.superAdminStore.exportPort.exportPorts,
    }),
    hasPermission() {
      return this.userHasPermission("exportPort", "write");
    },
    fields() {
      return [
        { key: "name", label: "Name" },

        {
          key: "actions",
          label: "Actions",
          note: false,
          details: true,
          validation: false,
          delete: this.hasPermission,
        },
      ];
    },
  },
  data() {
    return {
      options: {
        assign: false,
        rolFilter: false,
        add: true,
        fun: this.getData,
      },
      exportPortItem: {},
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData(filter) {
      this.$store
        .dispatch(GET_EXPORT_PORT, { search: filter })
        .then(() => {})
        .catch(() => {});
    },
    async deleteItem(item) {
      const confirm = await this.showConfirmDeleteBox();
      if (!confirm) return;
      const payload = { ...item };
      payload.status = 0;
      this.$store
        .dispatch(PUT_EXPORT_PORT, payload)
        .then(() => {
          this.getData();
          this.makeToast(
            "Success",
            "Export port updated successfully",
            "success"
          );
        })
        .catch(() => {
          this.$root.$bvToast.toast("Export port could not be disabled", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    activateItem(item) {
      const payload = {
        id: item.id,
        status: 1,
      };
      this.$store
        .dispatch(PUT_EXPORT_PORT, payload)
        .then(() => {
          this.makeToast(
            "Success",
            "Export port updated successfully",
            "success"
          );
          this.getData();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Export port could not be activated", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    showModal() {
      this.$bvModal.show("detailsModal");
    },
    editItem(item) {
      this.exportPortItem = item;
      this.$bvModal.show("detailsModal");
    },
    changePage(page, filter = "") {
      const payload = {
        search: filter,
        page: page,
      };
      this.$store
        .dispatch(GET_EXPORT_PORT, payload)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },

    hideModal() {
      this.$bvModal.hide("detailsModal");
    },
  },
};
</script>
