<template>
  <div>
    <b-row>
      <b-col md="6" sm="12">
        <label class="mt-1">Note type:</label>

        <multiselect
          v-model="filter"
          :options="filterList"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Pick some option"
          label="label"
          track-by="label"
          :preselect-first="true"
        >
          <template slot="selection" slot-scope="{ values, isOpen }"
            ><span class="multiselect__single" v-if="values.length && !isOpen"
              >{{ values.length }} options selected</span
            ></template
          >
        </multiselect>
        <div class="text-center mt-2">
          <div class="float-right">
            <b-button
              size="sm"
              variant="success"
              class="mx-1"
              @click="applyFilters()"
              >Apply</b-button
            >

            <b-button
              size="sm"
              variant="yellow"
              class="mx-1 text-white"
              @click="clearFilter()"
              >Clear</b-button
            >
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { NOTE_TYPES } from "./noteTypes";
import Multiselect from "vue-multiselect";

export default {
  name: "FilterColum",

  components: { Multiselect },
  computed: {
    filterList() {
      return NOTE_TYPES;
    },
  },
  data() {
    return {
      filter: [],
    };
  },
  mounted() {
    /*     this.filter.push(this.filterList[0]);
     */ this.applyFilters();
  },

  methods: {
    applyFilters() {
      const typeArray = this.filter.map((item) => item.type);
      this.$emit("onChangeType", this.arrayToString(typeArray));
    },
    clearFilter() {
      this.filter = [];
      this.filter.push(this.filterList[0]);

      this.$emit("onChangeType", "");
    },
    hasFilter() {
      return this.filter.length > 0;
    },
    arrayToString(array) {
      return array.map((item) => item).join(", ");
    },
  },
};
</script>

<style scoped>
.menu-min-width {
  min-width: 5rem !important;
}
.hideOverflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}
</style>
