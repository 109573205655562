import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    locations: {
      status: null,
      data: [],
      error: null,
    },

    location: {
      status: null,
      data: {},
      error: null,
    },
  };
}
const actions = {
  [constants.GET_LOCATIONS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_LOCATIONS);

      const response = await axios.get("location/getLocation", {
        params: payload,
      });
      commit(constants.GET_LOCATIONS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_LOCATIONS_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_LOCATION]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_LOCATION);
      const response = await axios.post("location/createAndUpdate", payload);
      commit(constants.POST_LOCATION_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_LOCATION_ERROR, error);
      throw error.response;
    }
  },
  [constants.DELETE_LOCATION]: async ({ commit }, payload) => {
    try {
      commit(constants.DELETE_LOCATION);
      const response = await axios.delete("location/delete", {
        params: payload,
      });
      commit(constants.DELETE_LOCATION_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.DELETE_LOCATION_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_LOCATIONS]: (state) => {
    state.locations.data = [];
    state.locations.status = "fetching";
  },
  [constants.GET_LOCATIONS_SUCCESS]: (state, response) => {
    state.locations.status = "success";
    state.locations.data = response;
    state.states.data = response.states;
  },
  [constants.GET_LOCATIONS_ERROR]: (state, error) => {
    state.locations.status = "error";
    state.locations.error = error;
  },
  [constants.POST_LOCATION]: (state) => {
    state.location.data = {};
    state.location.status = "fetching";
  },
  [constants.POST_LOCATION_SUCCESS]: (state, response) => {
    state.location.status = "success";
    state.location.data = response;
  },
  [constants.POST_LOCATION_ERROR]: (state, error) => {
    state.location.status = "error";
    state.location.error = error;
  },
  [constants.DELETE_LOCATION]: (state) => {
    state.location.data = {};
    state.location.status = "fetching";
  },
  [constants.DELETE_LOCATION_SUCCESS]: (state, response) => {
    state.location.status = "success";
    state.location.data = response;
  },
  [constants.DELETE_LOCATION_ERROR]: (state, error) => {
    state.location.status = "error";
    state.location.error = error;
  },
};
export default {
  state: getDefaultState(),
  actions,
  mutations,
};
