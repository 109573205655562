<template>
  <div
    v-touch:touchhold="touchHoldHandler"
    @contextmenu.prevent="$refs.menuWorkOrder.open($event, 'Payload')"
  >
    <slot> </slot>
    <ContextMenu ref="menuWorkOrder">
      <template slot-scope>
        <ContextMenuItem
          v-for="category in categories"
          @click.native="createCategory(category.id)"
          :key="category.id"
        >
          Create work order for {{ category.name }} category
        </ContextMenuItem>
      </template>
    </ContextMenu>
    <TaskUpdateStatusModalVue :tasks="tasks" :id="vehicle.id" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { superAdminMixin } from "../../mixins";
import ContextMenu from "../contextMenu/ContextMenu.vue";
import ContextMenuItem from "../contextMenu/ContextMenuItem.vue";
import { POST_WORK_ORDER_ONLY_CATEGORY } from "./actions";
import TaskUpdateStatusModalVue from "../../tasks/components/TaskUpdateStatusModal.vue";

export default {
  name: "RecallContextMenu",
  props: ["vehicle", "refreshData"],
  mixins: [superAdminMixin],
  components: {
    ContextMenu,
    ContextMenuItem,
    TaskUpdateStatusModalVue,
  },
  computed: {
    ...mapState({
      categories: (state) =>
        state.vehicle.workOrder.categories.categories.data.data,
    }),
  },
  data() {
    return {
      tasks: [],
    };
  },

  mounted() {},
  methods: {
    createCategory(category) {
      const payload = {
        categoryId: category,
        vehicleId: this.vehicle.id,
      };
      this.$store
        .dispatch(POST_WORK_ORDER_ONLY_CATEGORY, payload)
        .then((response) => {
          if (response.task.length > 0) {
            /* show TaskUpdateStatusModal */
            this.tasks = response.task;
            this.$bvModal.show("task-update-status-modal" + this.vehicle.id);
          }
          this.toastMessage(
            "Work order created successfully",
            "Success",
            "success"
          );
          this.$refs.menuWorkOrder.close();
        })
        .catch((error) => {
          console.log("error", error);
          this.toastMessage("work order no created", "Warning", "warning");
        });
    },

    touchHoldHandler($event) {
      this.$refs.menuWorkOrder.open($event, "Payload");
    },
  },
};
</script>

<style>
</style>