<template>
  <b-sidebar
    id="sidebar-vehicle-details"
    lazy
    :width="widthSidebar"
    :visible="vehicleDetailsSideBar.isShowing"
    shadow
    right
    no-header-close
    @hidden="
      () =>
        $store.commit('setVehicleDetailsSideBar', {
          stockNumber: null,
          isShowing: false,
        })
    "
  >
    <template v-slot:title>
      <div class="text-right h2 d-flex justify-content-start">
        <span
          class="cursor-pointer mr-3"
          @click="closeSideBar()"
          v-b-tooltip.hover
          title="Close"
          ><strong>X</strong>
        </span>

        Vehicle details
      </div>
    </template>

    <VehiclePage
      v-if="vehicleDetailsSideBar.stockNumber"
      :stockNumber="vehicleDetailsSideBar.stockNumber"
    />
  </b-sidebar>
</template>

<script>
import VehiclePage from "@/views/Pages/Vehicle/VehiclePage.vue";
import { mapState } from "vuex";
export default {
  components: { VehiclePage },
  name: "VehicleDetailsSideBar",
  computed: {
    widthSidebar() {
      return this.$vssWidth > 1280
        ? "65%"
        : this.$vssWidth <= 576
        ? "100%"
        : "50%";
    },
    ...mapState({
      vehicleDetailsSideBar: (state) =>
        state.superAdminStore.tasks.vehicleDetailsSideBar,
    }),
  },
  methods: {
    closeSideBar() {
      this.$store.commit("setVehicleDetailsSideBar", {
        stockNumber: null,
        isShowing: false,
      });
    },
  },
};
</script>

<style></style>
