<template>
  <div class="">
    <div class="d-flex justify-content-start">
      <TasksBoardFilters class="ml-2" />
      <div>
        <b-form-checkbox
          @change="toggleShowDeletedTasks()"
          :checked="showDeletedTasks"
          switch
          size="md"
          class="ml-5 mt-1"
        >
          <font-awesome-icon
            v-if="!showDeletedTasks"
            class="cursor-pointer ml-n4"
            :icon="['far', 'trash-can']"
            size="lg"
            v-b-tooltip.hover
            title="Show deleted task"
          />
          <font-awesome-icon
            v-if="showDeletedTasks"
            class="cursor-pointer ml-n4"
            :icon="['far', 'trash-can-slash']"
            size="lg"
            v-b-tooltip.hover
            title="Hide deleted task"
          />
          <span class="">
            {{ showDeletedTasks ? "Hide deleted tasks" : "Show deleted task" }}
          </span>
        </b-form-checkbox>
      </div>
    </div>
    <div v-if="isTaskLoading" class="d-flex justify-content-center">
      <b-spinner
        style="width: 5rem; height: 5rem"
        variant="yellow"
        class="h1 mt-2"
      />
    </div>
    <div v-else class="horizontal-scrollable board-height">
      <b-row>
        <b-col cols="12">
          <div :style="{ width: responsiveColumnWidth + 'px' }" class="mx-3">
            <b-row>
              <b-col
                v-for="(taskColumn, key) in tasksColumns"
                :key="key"
                class="task-width rounded flex-nowrap flex-sm-wrap"
              >
                <div
                  class="d-flex justify-content-between h4 mt-3 sticky-top bg-white"
                  style="z-index: 999"
                >
                  <span class="ml-3">
                    {{ taskColumn.title }}
                  </span>
                  <span class="mr-3">
                    <!--                     <font-awesome-icon
                      @click="openTaskForm(taskColumn.id)"
                      class="cursor-pointer"
                      :icon="['far', 'plus']"
                    /> -->
                  </span>
                </div>
                <div
                  class="max-height board-height"
                  :style="{ 'overflow-x': 'scroll' }"
                  :class="isMobile ? 'scroll-mobile' : ''"
                >
                  <draggable
                    :list="taskColumn.tasks"
                    :animation="200"
                    group="tasks"
                    overflow-auto
                    max-height
                    @change="handleTaskDragEnd"
                  >
                    <TaskCard
                      v-for="task in taskColumn.tasks"
                      :key="task.id"
                      :task="task"
                      class="my-2 cursor-move"
                      :refreshData="getTasks"
                    ></TaskCard>
                  </draggable>
                </div>

                <!--  <AddTask
                  class="mb-3"
                  :taskBoardId="taskColumn.id"
                  @openTaskForm="openTaskForm"
                /> -->
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </div>

    <TaskForm :refreshData="getTasks" :taskBoardId="boardId" />
    <VehicleSideBar />
    <TaskSideBar />
    <CompanySideBar />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import TaskCard from "./components/TaskCard.vue";
import {
  GET_TASKS,
  POST_TASK,
  GET_TASKS_SUCCESS,
  SET_TASKS_FILTERS,
} from "./actions";
/* import AddTask from "./components/AddTask.vue";
 */ import TaskForm from "./components/TaskForm.vue";
import { mapState } from "vuex";
import VehicleSideBar from "./components/VehicleSideBar.vue";
import TasksBoardFilters from "./filters/TasksBoardFilters.vue";
import TaskSideBar from "./components/TaskSideBar.vue";
import CompanySideBar from "./components/CompanySideBar.vue";
import { generalMixin } from "../../mixin";

export default {
  name: "TasksBoard",
  components: {
    TaskCard,
    draggable,
    /* AddTask */
    TaskForm,
    VehicleSideBar,
    TasksBoardFilters,
    TaskSideBar,
    CompanySideBar,
  },
  mixins: [generalMixin],
  computed: {
    ...mapState({
      tasks: (state) => state.superAdminStore.tasks.tasks.data,
      isTaskLoading: (state) =>
        state.superAdminStore.tasks.tasks.status == "fetching",
      showDeleteTaskFromStore: (state) =>
        state.superAdminStore.tasks.tasks.filters.deleted,
    }),
    responsiveColumnWidth() {
      /* count columns and multiply by 330 and add 5 px */
      return this.tasksColumns.length * 330;
    },
    tasksColumns() {
      return JSON.parse(JSON.stringify(this.tasks));
    },
    isMobile() {
      return this.$vssWidth <= 990 ? true : false;
    },
  },
  data() {
    return {
      boardId: null,
      showDeletedTasks: this.showDeleteTaskFromStore || false,
    };
  },

  created() {
    this.$store.commit("setSearchFunction", this.getData);
    this.$store.commit("setAddFunction", this.openTaskForm);
    if (this.$route.name == "superadmin.tasks-user") {
      this.$store.commit(SET_TASKS_FILTERS, {
        fields: {
          personalTask: true,
        },
      });
    } else {
      this.$store.commit(SET_TASKS_FILTERS, {
        fields: {
          personalTask: null,
        },
      });
    }
    this.getTasks();
  },
  methods: {
    getTasks() {
      this.$store.dispatch(GET_TASKS).then(() => {});
    },
    openTaskForm() {
      this.boardId = 1;
      this.$bvModal.show("task-form");
    },
    handleTaskDragEnd(event) {
      if (!event.added) {
        return;
      }
      if (this.userIsAgent || this.userIsExternalAgent) {
        this.$bvToast.toast("You don't have permission to move tasks", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
        return;
      }
      const payload = {
        id: event.added.element.id,
        taskBoardId: this.searchTaskBoardId(event.added.element.id),
      };
      this.moveTaskToColumn(
        event.added.element,
        this.searchTaskBoardId(event.added.element.id)
      );

      this.$store
        .dispatch(POST_TASK, payload)
        .then(() => {
          this.$bvToast.toast("Task updated successfully", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchTaskBoardId(taskId) {
      let taskBoardId = null;
      this.tasksColumns.forEach((taskColumn) => {
        taskColumn.tasks.forEach((task) => {
          if (task.id == taskId) {
            taskBoardId = taskColumn.id;
          }
        });
      });
      return taskBoardId;
    },
    moveTaskToColumn(taskToMove, taskBoardId) {
      let taskColumnsEditable = JSON.parse(JSON.stringify(this.tasksColumns));
      taskColumnsEditable.forEach((taskColumn) => {
        taskColumn.tasks.forEach((task, index) => {
          if (task.id == taskToMove.id) {
            taskColumn.tasks.splice(index, 1);
          }
        });
      });
      taskColumnsEditable.forEach((taskColumn) => {
        if (taskColumn.id == taskBoardId) {
          taskColumn.tasks.push(taskToMove);
        }
      });
      this.$store.commit(GET_TASKS_SUCCESS, { data: taskColumnsEditable });
    },
    toggleShowDeletedTasks() {
      this.showDeletedTasks = !this.showDeletedTasks;
      this.$store.commit(SET_TASKS_FILTERS, {
        fields: {
          deleted: this.showDeletedTasks ? 1 : 0,
        },
      });
      this.getTasks();
    },
  },
};
</script>

<style scoped>
.task-width {
  min-width: 330px !important;
  width: 330px !important;
}

.horizontal-scrollable {
  overflow-x: auto !important;
  white-space: nowrap !important;
}
.horizontal-scrollable > .row > .col-sm-12 {
  display: inline-block !important;
  float: none !important;
}
.max-height {
  height: 100% !important;
}
.board-height {
  height: 76vh !important;
}
/* .scroll-mobile::-webkit-scrollbar {
} */

.scroll-mobile::-webkit-scrollbar {
  width: 20px !important; /* Adjust the width as needed */
}

.scroll-mobile::-webkit-scrollbar-thumb {
  background-color: #a0a0a0 !important; /* Color of the scrollbar thumb */
  border-radius: 10px !important; /* Border radius to round the corners of the thumb */
}

.scroll-mobile::-webkit-scrollbar-track {
  background-color: #f0f0f0 !important; /* Color of the scrollbar track */
  border-radius: 10px !important; /* Border radius to round the corners of the track */
}
</style>
