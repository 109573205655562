<template>
  <b-modal size="lg" :id="infoModal.id" :title="infoModal.title" hide-footer>
    <b-list-group
      v-if="
        paymentSelected != null && paymentSelected.exchangeContract.length > 0
      "
      class="pt-0"
    >
      <b-card
        v-for="contract in paymentSelected.exchangeContract"
        :key="contract.id"
        class="py-0"
      >
        <b-row>
          <b-col lg="6" sm="12" md="6">
            Contract number: <i> {{ contract.contractNumber }} </i></b-col
          >
          <b-col lg="6" sm="12" md="6">
            Contract registration date:
            <i>{{ contract.createdAt | formatDate }}</i>
          </b-col>
          <b-col lg="6" sm="12" md="6">
            Amount <b>USD</b> : <i> {{ contract.amount | currency }} </i></b-col
          >
          <b-col lg="6" sm="12" md="6">
            Amount <b>CAD</b> : <i>{{ contract.amountCad | currency }}</i>
          </b-col>
          <b-col lg="6" sm="12" md="6"> FX : {{ contract.fx }} </b-col>
          <b-col lg="6" sm="12" md="6">
            Amount <b>paid</b> :
            <i>
              <span
                :class="
                  contract.amountPaid == contract.amountCad
                    ? 'text-success'
                    : ''
                "
              >
                {{ contract.amountPaid | currency }}</span
              >
            </i>
          </b-col>
        </b-row>
      </b-card>
    </b-list-group>
    <div v-else class="text-center">
      <h6>No vehicles to show</h6>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    paymentSelected: {
      type: Object,
      default: null,
    },
  },
  name: "ExchangeContractVehiclesModal",
  data() {
    return {
      infoModal: {
        id: "exchange-contract-payments-contracts",
        title: "Contracts",
        content: "",
      },
    };
  },
  methods: {
    goToVinNumberView(id) {
      /* encrypt id */
      const encryptedId = this.$CryptoJS.AES.encrypt(
        JSON.stringify(id),
        "Secret Passphrase"
      ).toString();

      this.$router.push({
        name: "superadmin.vehicles.unit",
        params: {
          id: encryptedId,
        },
      });
    },
  },
};
</script>

<style></style>
