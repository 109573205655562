<template>
  <b-container>
    <!-- img corporation -->
    <img
      :src="corporation.logo"
      alt="corporation"
      class="img-fluid"
      height="20"
      width=""
      style="max-width: 50px"
    />
    {{ corporation.companyName }}
    <b-form @submit.stop.prevent="form.id ? update() : save()">
      <b-row>
        <!-- type radio -->
        <b-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
          <div class="d-flex align-items-center">
            <div class="mr-4">
              <b-button class="my-auto" type="button" variant="success" pill
                >1</b-button
              >
            </div>

            <b-form-group label-size="sm">
              <template slot="label">
                Type <span class="text-danger">*</span>
              </template>
              <b-form-radio-group
                v-model="form.type"
                :options="typeOptions"
                name="radio-type"
                stacked
                @change="onTypeChange"
              />
            </b-form-group>
          </div>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
          <div class="d-flex align-items-center">
            <div class="mr-4">
              <b-button type="button" variant="success" pill>2</b-button>
            </div>
            <b-form-group label-size="sm" class="w-100">
              <template slot="label">
                File <span class="text-danger">*</span>
              </template>

              <b-form-file
                v-model="form.file"
                size="md"
                name="name-input"
                :value="form.name"
                maxlength="100"
                :accept="acceptedFileTypes"
              />
            </b-form-group>
          </div>
        </b-col>

        <b-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
          <div class="d-flex align-items-center">
            <div class="mr-4">
              <b-button type="button" variant="success" pill>3</b-button>
            </div>
            <b-form-group label-size="sm" class="w-100">
              <template slot="label">
                File list <span class="text-danger">*</span>
              </template>
              <multiselect
                v-model="form.list"
                :options="listOptions"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Pick some option"
                label=""
                :preselect-first="false"
              >
                <template slot="selection" slot-scope="{ values, isOpen }"
                  ><span
                    class="multiselect__single"
                    v-if="values.length && !isOpen"
                    >{{ values.length }} options selected</span
                  ></template
                >
              </multiselect>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <b-row />

      <b-row>
        <b-col sm="12" md="9" lg="9" xl="9"> </b-col>

        <b-col sm="12" md="3" lg="3" xl="3">
          <!--           <b-btn @click="showConfirmModal()">confirm</b-btn>
 -->
          <b-btn
            v-if="userHasPermission('addons', 'write')"
            block
            size="lg"
            class="btn btn-success"
            type="button"
            :disabled="addonFile.status === 'fetching' || $v.$invalid"
            @click="showConfirmModal()"
          >
            Submit
            <b-spinner
              v-if="addonFile.status == 'fetching'"
              small
              variant="white"
            />
          </b-btn>
        </b-col>
      </b-row>
    </b-form>
    <b-modal
      id="confirm-modal"
      ref="confirm-modal"
      title="Confirm information"
      @show="resetModal(), startTimer()"
      @hidden="resetModal()"
    >
      <img
        :src="corporation.logo"
        alt="corporation"
        class="img-fluid"
        height="20"
        width=""
        style="max-width: 50px"
      />
      {{ corporation.companyName }}
      <div>
        <p>Please confirm that you want to save the following information:</p>
        <ul>
          <li>
            <strong>File:</strong> {{ form.file ? form.file.name : "----" }}
          </li>
          <li><strong>Type:</strong> {{ form.type }}</li>
          <li><strong>List:</strong> {{ form.list }}</li>
          <li><strong>Corporation:</strong> {{ corporation.companyName }}</li>
        </ul>
      </div>
      <template slot="modal-footer">
        <b-btn
          size="sm"
          variant="danger"
          @click="$refs['confirm-modal'].hide()"
        >
          Cancel
        </b-btn>
        <b-btn
          :disabled="
            !confirmUploadFile || addonFile.status === 'fetching' || $v.$invalid
          "
          size="sm"
          variant="success"
          @click="form.id ? update() : save()"
        >
          <span v-if="!confirmUploadFile"
            >You can confirm in {{ leftSeconds }} seconds</span
          >
          <span v-else>Confirm </span>
        </b-btn>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import { POST_ADDON_FILE } from "./actions";
import { mapState } from "vuex";
import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../mixins";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
export default {
  name: "AddonsTypeForm",
  mixins: [generalMixin, superAdminMixin],
  props: ["addonFileItem", "refreshData", "hideModal"],
  components: { Multiselect },
  computed: {
    ...mapState({
      addonFile: (state) => state.superAdminStore.addonsFile.addonFile,
      profile: (state) => state.auth.loginStore.profile.data,
    }),
    acceptedFileTypes() {
      if (this.form.type === "addons") {
        return ".txt";
      } else if (this.form.type === "salesPayment") {
        return ".txt";
      } else if (this.form.type === "salesPaymentXls") {
        return ".xls, .xlsx";
      } else if (this.form.type === "sales") {
        return ".txt";
      } else if (this.form.type === "csv") {
        return ".csv";
      }

      return ".txt, .xls";
    },
    corporation() {
      const corporation = localStorage.getItem("corporation");
      let corporationSelect = this.profile.user.corporationPermissions.find(
        (c) => c.id == corporation
      );

      return corporationSelect
        ? corporationSelect
        : this.profile.user.corporationPermissions[0]
        ? this.profile.user.corporationPermissions[0]
        : { name: "" };
    },
  },

  data() {
    return {
      form: {
        id: null,
        file: null,
        list: ["A", "B", "D", "G", "R", "S", "U"],
      },
      listOptions: ["A", "B", "C", "D", "G", "L", "R", "S", "U"],
      typeOptions: [
        /*         { text: "Addons (10441)", value: "addons" },
        { text: "Post-sale Addons (5100/6100)", value: "postAddons" }, */
        /*{ text: "Sales (41000)", value: "sales" },
        {
          text: "Sales payments (10100)",
          value: "salesPayment",
        } */ { text: "GL CSV", value: "csv" },
        { text: "Sales payments XLS (10100)", value: "salesPaymentXls" },
      ],
      confirmUploadFile: false,
      leftSeconds: 10,
      countDownTimer: null,
    };
  },
  validations: {
    form: {
      file: {
        required: required,
      },
      type: {
        required: required,
      },
    },
  },
  mounted() {
    if (this.addonFileItem?.id) {
      this.formatForm();
    }
  },

  methods: {
    closeModal(callback) {
      callback();
    },
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async save() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_ADDON_FILE, payload)
        .then(() => {
          this.makeToast("Success", "Addons created successfully", "success");
          this.refreshData();
          this.hideModal();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Internal server error, try again later", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    async update() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_ADDON_FILE, payload)
        .then(() => {
          this.makeToast(
            "Success",
            "Addon file updated successfully",
            "success"
          );
          this.refreshData();
          this.hideModal();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Internal server error, try again later", {
            title: "Warning",
            variant: "warning",
          });
        });
    },

    formatPayload() {
      const formData = new FormData();
      formData.append("fileTxt", this.form.file);
      formData.append("list", this.form.list);
      formData.append("type", this.form.type);
      return formData;
    },

    formatForm() {
      this.form.id = this.addonFileItem.id;
      this.form.file = this.addonFileItem.fileTxt;
      this.form.list = this.addonFileItem.list;
      this.form.type = this.addonFileItem.type;
    },
    onTypeChange(value) {
      if (value == "addons") {
        this.form.list = [];
        this.form.list = ["A", "B", "D", "G", "R", "S", "U"];
        return;
      }
      if (value == "postAddons") {
        this.form.list = [];
        this.form.list = ["A", "B", "D", "G", "R", "S"];
        return;
      }
      if (value === "salesPayment" || value === "salesPaymentXls") {
        this.form.list = [];
        this.form.list.push("R");
        return;
      }
      if (value === "sales") {
        this.form.list = [];
        this.form.list.push("U");
      } else {
        this.form.list = [];
        this.form.list = ["A", "B", "D", "G", "R", "S", "U"];
      }
    },
    showConfirmModal() {
      this.$refs["confirm-modal"].show();
    },
    startTimer() {
      this.countDownTimer = setInterval(() => {
        if (this.leftSeconds === 0) {
          clearInterval(this.countDownTimer);
          this.confirmUploadFile = true;
          return;
        }
        this.leftSeconds--;
      }, 1000);
    },
    resetModal() {
      this.confirmUploadFile = false;
      this.leftSeconds = 10;
      clearInterval(this.countDownTimer);
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.append-input {
  max-width: 40%;
}
</style>
