<template>
  <div>
    <b-form-group label-size="sm">
      <template slot="label">
        <label v-if="type == 'number'"> Higher than: </label>
        <label v-else> From: </label>
      </template>
      <b-form-input
        v-model="greaterThan"
        size="sm"
        maxlength="100"
        onkeydown="javascript: return event.keyCode == 69 ? false : true"
        :type="type"
        min="0"
        @change="onChangeFilter()"
      />
    </b-form-group>
    <b-form-group label-size="sm">
      <template slot="label">
        <label v-if="type == 'number'"> Lower than: </label>
        <label v-else> To: </label>
      </template>
      <b-form-input
        v-model="smallerThan"
        size="sm"
        maxlength="100"
        onkeydown="javascript: return event.keyCode == 69 ? false : true"
        :type="type"
        min="0"
        @change="onChangeFilter()"
      />
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "RangeSelectFilter",
  props: {
    type: {
      type: String,
      default: "number",
    },
    value: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      smallerThan: 0,
      greaterThan: 0,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.greaterThan = value[0];
        this.smallerThan = value[1];
      },
    },
  },

  methods: {
    onChangeFilter() {
      this.$emit("input", [this.greaterThan, this.smallerThan]);
    },
  },
};
</script>

<style></style>
