<template>
  <div>
    <b-container fluid class="pl-0 pr-0">
      <b-row class="">
        <b-col>
          <!--           <TotalDetails v-if="vehiclesInfo.data" :totals="vehiclesInfo.data" />
 -->
        </b-col>
      </b-row>
      <Search :is-mobile="isMobile" />
      <div class="text-left"></div>
      <div class="mt-2 mb-2" id="total-rows">
        <div class="float-left">
          <b-button variant="white" class="mt-2">
            Total rows:
            {{
              (vehiclesInfo.data &&
                vehiclesInfo.data.paginate &&
                vehiclesInfo.data.paginate.total) ||
              0
            }}
          </b-button>
        </div>
        <div class="text-right">
          <StatusFilter />

          <b-button variant="light" @click="editColumns()" class="mx-1">
            Edit view
          </b-button>
          <b-button
            variant="yellow"
            @click="
              resetFilters();
              getVehiclesInfo();
            "
            class="text-white mx-1"
          >
            Clear filters
          </b-button>
          <b-button
            :disabled="isLoadingVehiclesXLS"
            variant="success"
            class="text-white mx-1"
            @click="getVehiclesExcel()"
          >
            <b-spinner
              v-if="isLoadingVehiclesXLS"
              class="align-middle"
              variant="white"
              small
            />

            <font-awesome-icon
              v-else
              class="cursor-pointer"
              :icon="['far', 'file-excel']"
            />
          </b-button>
          <b-button @click="copyUrlWithFilters()">
            <font-awesome-icon
              class="cursor-pointer"
              :icon="['far', 'floppy-disk-circle-arrow-right']"
            />
          </b-button>
        </div>
      </div>
      <div class="table-responsive">
        <b-table
          show-empty
          bordered
          small
          stacked="md"
          head-variant="light"
          :items="
            vehiclesInfo.data.paginate ? vehiclesInfo.data.paginate.data : []
          "
          :fields="fieldsByPermission"
          :busy="vehiclesInfo.status === 'fetching'"
          :class="{ 'text-break': isMobile }"
          sticky-header
          class="text-break"
          :tbody-tr-class="rowClass"
        >
          <template #head()="scope">
            <div class="table-head-menu">
              <span class=""> {{ scope.label }} </span>
              <br />
              <div class="text-center">
                <FilterColum
                  ref="filter-component"
                  :type="scope.field.key"
                  v-if="hasFilter(scope.field.key)"
                  :refreshFunction="getVehiclesInfo"
                />
                <div v-else>
                  <br />
                  <span class=""></span>
                </div>
              </div>
            </div>
          </template>

          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" variant="yellow" />
            </div>
          </template>
          <template v-slot:cell(marketPlace)="{ item }">
            <div class="text-center">
              <div
                v-if="item.marketPlace"
                class="cursor-pointer"
                @click="toggleShowFullExportLocation(item.id)"
              >
                <small
                  class="text-justify"
                  v-if="
                    showFullExportLocation.show &&
                    showFullExportLocation.itemId == item.id
                  "
                >
                  {{ item.exportLocations.name }}
                </small>
                <small v-else>
                  {{ exportLocationAbbreviation(item) }}
                </small>
                <br />
                <b-img
                  :src="item.marketLogo"
                  height="20"
                  width="30"
                  class="mx-auto"
                  style="max-width: 100px"
                  alt="marketplace-logo"
                />
                <div>
                  <b-link
                    :id="item.vinNumber.vinNumber"
                    :ref="item.vinNumber.vinNumber"
                    :href="item.vinNumber.vinNumber"
                  ></b-link>
                  <small v-if="item.finalDestination" class="mt-5">
                    {{ item.finalDestination.companyId }}
                  </small>
                  <br />
                </div>
              </div>

              <span
                v-else
                class="text-danger cursor-pointer"
                @click="
                  goToInitialInformation(item, {
                    module: 'stock',
                    action: 'read',
                  })
                "
              >
                N. A.</span
              >
            </div>
          </template>
          <template v-slot:cell(stockNumber)="{ item }">
            <b-link
              :id="item.stockNumber"
              :ref="item.stockNumber"
              :href="item.stockNumber"
            ></b-link>
            <small
              v-if="item.stockNumber"
              class="cursor-pointer"
              :style="{
                color: getStockNumberVariant(item.vehicleFlags.stockNumber),
              }"
              @click="
                goToInitialInformation(item, {
                  module: 'stock',
                  action: 'read',
                });
                setVehicleAnchor(item.stockNumber);
              "
              style=""
            >
              <strong> {{ item.stockNumber }} </strong>
            </small>
            <br />

            <b-button
              v-clipboard:copy="
                item.stockNumber ? item.stockNumber : item.stockNumber
              "
              v-clipboard:success="copyStockNumber"
              variant="none"
              @click="stockCopiedText = item.stockNumber"
              class="p-0"
            >
              <b-icon
                v-if="stockCopied && stockCopiedText == item.stockNumber"
                icon="clipboard-check"
              />
              <b-icon v-else icon="clipboard" />
            </b-button>
            <br
              v-if="
                item.vehicleFlags.consignmentDays || item.vehicleFlags.stockDays
              "
            />
            <small
              v-if="
                item.vehicleFlags.consignmentDays || item.vehicleFlags.stockDays
              "
            >
              {{
                corporation.id != item.corporationsId
                  ? item.vehicleFlags.consignmentDays
                  : item.vehicleFlags.stockDays
              }}

              days</small
            >
            <small
              v-if="!item.stockNumber"
              class="text-danger cursor-pointer"
              @click="
                goToInitialInformation(item, {
                  module: 'stock',
                  action: 'read',
                });
                setVehicleAnchor(item.stockNumber);
              "
            >
              N. S.</small
            >
            <br />
            <!--    <span v-if="item.finalDestination">
              <small>
                {{ item.finalDestination.companyName || " " }}
              </small>
            </span> -->
          </template>
          <template v-slot:cell(vinNumber)="row">
            <small>
              <span
                class="cursor-pointer"
                @click="
                  goToVehicleUnitPage(row.item.id);
                  setVehicleAnchor(row.item.stockNumber);
                "
              >
                {{ row.item.vinNumber.vinNumber }}
              </span>
              <br />
              <b-button
                class="p-0"
                v-clipboard:copy="
                  row.item.vinNumber.vinNumber
                    ? row.item.vinNumber.vinNumber
                    : row.item.vinNumber.vinNumber
                "
                v-clipboard:success="copyVinNumber"
                variant="none"
                @click="vinCopiedText = row.item.vinNumber.vinNumber"
              >
                <b-icon
                  v-if="
                    vinCopied && vinCopiedText == row.item.vinNumber.vinNumber
                  "
                  icon="clipboard-check"
                />
                <b-icon v-else icon="clipboard" />
              </b-button>
              <b-button
                class="p-0"
                v-if="isMobile"
                @click="goToVehicleUnitPage(row.item.id)"
              >
                <b-icon icon="eye"></b-icon>
              </b-button>
            </small>
          </template>

          <template v-slot:cell(fx)="{ item }">
            {{ twoDecimals(item.fx) }}
          </template>
          <template v-slot:cell(year)="{ item }">
            <small>
              {{ item.vinNumber.year }}
            </small>
          </template>
          <template v-slot:cell(model)="{ item }">
            <small>
              {{ item.vinNumber.model }}
            </small>
          </template>
          <template v-slot:cell(user)="{ item }">
            <div v-if="agentsSelected.find((agent) => agent === item.id)">
              <b-input-group>
                <b-input list="agents" placeholder="Type agent name" />
                <b-input-group-append>
                  <b-button
                    variant="outline-success"
                    size="sm"
                    @click="updateAgent(item.id)"
                  >
                    <b-icon icon="check" class="mt-1" font-scale="1.5" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
            <span v-else>
              <small v-if="item.user">
                {{ item.user.acronym }} <br />
                <font-awesome-icon
                  class="icon-color cursor-pointer"
                  :icon="['far', 'book-open-cover']"
                  @click="
                    openModal(item, 'history-modal', {
                      module: 'sl',
                      action: 'read',
                    });
                    setVehicleAnchor(item.stockNumber);
                  "
                />
              </small>
              <small v-else class="text-danger"> N.A. </small>
            </span>
            <span v-if="item.vehicleFlags.marketPlaceDays">
              {{ item.vehicleFlags.marketPlaceDays }} d
            </span>
          </template>

          <template v-slot:cell(payments)="row">
            <font-awesome-icon
              class="icon-color cursor-pointer"
              size="xl"
              :icon="['far', 'money-check-dollar']"
              :style="{
                color: getVarianPaymentColor(
                  row.item.vehicleFlags ? row.item.vehicleFlags.payments : null,
                  row.item.totalChecks
                ),
              }"
              @click="
                openModal(row.item, 'checks-modal', {
                  module: 'payments',
                  action: 'read',
                });
                setVehicleAnchor(row.item.stockNumber);
              "
            />
          </template>

          <template v-slot:cell(conditionalReports)="{ item }">
            <font-awesome-icon
              class="icon-color cursor-pointer"
              :icon="['far', 'file-chart-column']"
              size="xl"
              :style="{ color: getCRVariant(item.vehicleFlags.cr) }"
              @click="
                openModal(item, 'conditional-reports-modal', {
                  module: 'cr',
                  action: 'read',
                });
                setVehicleAnchor(item.stockNumber);
              "
            />
          </template>

          <template v-slot:cell(notes)="{ item }">
            <b-icon
              icon="pencil-square"
              font-scale="1.5"
              :variant="item.vehicleFlags.notes == 1 ? 'yellow' : 'grey'"
              @click="
                openModal(item, 'notes-modal', {
                  module: 'notes',
                  action: 'read',
                });
                setVehicleAnchor(item.stockNumber);
              "
            />
          </template>

          <template v-slot:cell(recall)="{ item }">
            <RecallContextMenu :vehicle="item" :refreshData="getVehiclesInfo">
              <font-awesome-icon
                class="icon-color cursor-pointer"
                :style="{ color: getRecallColor(item.vehicleFlags.recall) }"
                :icon="['far', 'car-circle-bolt']"
                size="xl"
                @click="
                  openModal(item, 'recall-modal', {
                    module: 'recall',
                    action: 'read',
                  });
                  setVehicleAnchor(item.stockNumber);
                "
              />
            </RecallContextMenu>
            <span v-if="item.vehicleFlags.recallDays">
              {{ item.vehicleFlags.recallDays }} d
            </span>
          </template>

          <template v-slot:cell(carfax)="{ item }">
            <div class="mt-1">
              <img
                v-if="!item.vehicleFlags.carfax"
                src="@/assets/svg/CF_grey.svg"
                class="img-responsive icon-color cursor-pointer"
                style="max-width: 35px"
                @click="
                  openModal(item, 'carfax-modal', {
                    module: 'carfax',
                    action: 'read',
                  });
                  setVehicleAnchor(item.stockNumber);
                "
              />
              <img
                v-if="item.vehicleFlags.carfax == 1"
                src="@/assets/svg/CF_success.svg"
                class="img-responsive icon-color cursor-pointer"
                style="max-width: 35px"
                @click="
                  openModal(item, 'carfax-modal', {
                    module: 'carfax',
                    action: 'read',
                  });
                  setVehicleAnchor(item.stockNumber);
                "
              />

              <img
                v-if="item.vehicleFlags.carfax == 2"
                src="@/assets/svg/CF_danger.svg"
                class="img-responsive icon-color cursor-pointer"
                style="max-width: 35px"
                @click="
                  openModal(item, 'carfax-modal', {
                    module: 'carfax',
                    action: 'read',
                  });
                  setVehicleAnchor(item.stockNumber);
                "
              />
            </div>
          </template>

          <template v-slot:cell(sold)="{ item }">
            <font-awesome-icon
              class="icon-color cursor-pointer"
              :icon="['far', 'file-invoice-dollar']"
              size="xl"
              :style="{ color: getSoldVariant(item.vehicleFlags.sold) }"
              @click="
                openModal(item, 'sold-modal', {
                  module: 'sold',
                  action: 'read',
                });
                setVehicleAnchor(item.stockNumber);
              "
            />
          </template>

          <template v-slot:cell(visualInspectionCR)="{ item }">
            <font-awesome-icon
              class="icon-color cursor-pointer"
              size="xl"
              :style="{
                color: getVisualInspectionCRVariant(item.vehicleFlags.vicr),
              }"
              :icon="['far', 'mobile-signal-out']"
              @click="
                goToVisualInspectionCR(item);
                setVehicleAnchor(item.stockNumber);
              "
            />
          </template>

          <template v-slot:cell(prices)="{ item }">
            <font-awesome-icon
              class="icon-color cursor-pointer"
              :style="{
                color: getPriceVariant(item.vehicleFlags.prices),
              }"
              size="xl"
              :icon="['far', 'clouds']"
              @click="
                openModal(item, 'prices-modal', {
                  module: 'prices',
                  action: 'read',
                });
                setVehicleAnchor(item.stockNumber);
              "
            />
          </template>

          <template v-slot:cell(creditRequest)="{ item }">
            <FinancialCreditRequestContextMenu
              :vehicle="item"
              :refreshData="getVehiclesInfo"
            >
              <font-awesome-icon
                class="icon-color cursor-pointer"
                size="xl"
                :style="{
                  color: getCreditRequestVariant(
                    item.vehicleFlags.creditRequest
                  ),
                }"
                :icon="['far', 'landmark']"
                @click="
                  openModal(item, 'financial-credit-request-modal', {
                    module: 'creditRequest',
                    action: 'read',
                  });
                  setVehicleAnchor(item.stockNumber);
                "
              />
            </FinancialCreditRequestContextMenu>
          </template>

          <template v-slot:cell(visualInspection)="{ item }">
            <font-awesome-icon
              class="icon-color cursor-pointer"
              size="xl"
              :style="{
                color: getVisualInspectionVariant(item.vehicleFlags.vi),
              }"
              :icon="['far', 'camera-viewfinder']"
              @click="
                goToVisualInspection(item);
                setVehicleAnchor(item.stockNumber);
              "
            />
          </template>

          <template v-slot:cell(cluster)="{ item }">
            <ClusterContextMenu :vehicle="item" :refreshData="getVehiclesInfo">
              <font-awesome-icon
                class="icon-color cursor-pointer"
                :icon="['far', 'gauge-simple']"
                size="xl"
                :style="{
                  color: getClusterVariant(item.vehicleFlags.cluster),
                }"
                @click="
                  openModal(item, 'cluster-modal', {
                    module: 'cluster',
                    action: 'read',
                  });
                  setVehicleAnchor(item.stockNumber);
                "
              />
            </ClusterContextMenu>
            <span v-if="item.vehicleFlags.clusterDays">
              {{ item.vehicleFlags.clusterDays }} d
            </span>
          </template>

          <template v-slot:cell(carwash)="{ item }">
            <CarwashContextMenu :vehicle="item" :refreshData="getVehiclesInfo">
              <font-awesome-icon
                class="icon-color cursor-pointer"
                :icon="['far', 'car-wash']"
                size="xl"
                :style="{
                  color: getCarwashVariant(item.vehicleFlags.carWash),
                }"
                @click="
                  openModal(item, 'carwash-modal', {
                    module: 'carwash',
                    action: 'read',
                  });
                  setVehicleAnchor(item.stockNumber);
                "
              />
            </CarwashContextMenu>
            <span v-if="item.vehicleFlags.carwashDays">
              {{ item.vehicleFlags.carwashDays }} d
            </span>
          </template>

          <template v-slot:cell(lien)="{ item }">
            <font-awesome-icon
              class="icon-color cursor-pointer"
              :icon="['far', 'file-slash']"
              size="xl"
              :style="{ color: getLienVariant(item.vehicleFlags.lien) }"
              @click="
                openModal(item, 'lien-modal', {
                  module: 'liens',
                  action: 'read',
                });
                setVehicleAnchor(item.stockNumber);
              "
            />
            <br />
            <span v-if="item.vehicleFlags.liensDays"
              >{{ item.vehicleFlags.liensDays }} d</span
            >
          </template>

          <template v-slot:cell(workOrders)="{ item }">
            <WorkOrderContextMenu
              :vehicle="item"
              :refreshData="getVehiclesInfo"
            >
              <font-awesome-icon
                class="icon-color cursor-pointer"
                :icon="['far', 'car-wrench']"
                size="xl"
                :style="{
                  color: getWorkOrderVariant(item.vehicleFlags.wo),
                }"
                @click="
                  openModal(item, 'work-order-modal', {
                    module: 'wo',
                    action: 'read',
                  });
                  setVehicleAnchor(item.stockNumber);
                "
              />
            </WorkOrderContextMenu>
            <span v-if="item.vehicleFlags.woDays">
              {{ item.vehicleFlags.woDays }} d
            </span>
          </template>

          <template v-slot:cell(transfer)="{ item }">
            <TransferContextMenu :vehicle="item" :refreshData="getVehiclesInfo">
              <font-awesome-icon
                class="icon-color cursor-pointer"
                :icon="['far', 'truck-container']"
                size="xl"
                :style="{
                  color: getTransferVariant(item.vehicleFlags.transfer),
                }"
                @click="
                  openModal(item, 'transfer-modal', {
                    module: 'transfer',
                    action: 'read',
                  });
                  setVehicleAnchor(item.stockNumber);
                "
              />
            </TransferContextMenu>
            <span v-if="item.vehicleFlags.transferDays">
              {{ item.vehicleFlags.transferDays }} d
            </span>
          </template>

          <template v-slot:cell(exportLoad)="{ item }">
            <h4>
              <strong
                v-if="item.exportLoad != null"
                :style="{ color: getExportLoadVariant(item.exportLoad.status) }"
              >
                {{ item.exportLoad.id }}
              </strong>
              <span v-else class="text-danger"> N.A. </span>
            </h4>
          </template>

          <template v-slot:cell(releaseDate)="{ item }">
            <div>
              {{ item.releaseDate | formatDate }}
            </div>
          </template>

          <template v-slot:cell(consignment)="{ item }">
            <font-awesome-icon
              class="icon-color cursor-pointer"
              :icon="['far', 'tag']"
              size="xl"
              :style="{
                color: getConsignmentVariant(item.vehicleFlags.consignment),
              }"
              @click="
                openModal(item, 'consignment-modal', {
                  module: 'sl',
                  action: 'read',
                });
                setVehicleAnchor(item.stockNumber);
              "
            />
          </template>

          <template v-slot:cell(exception)="{ item }">
            <font-awesome-icon
              class="icon-color cursor-pointer"
              :icon="['far', 'octagon-exclamation']"
              size="xl"
              :style="{
                color: getExceptionVariant(item.vehicleFlags.exception),
              }"
              @click="
                openModal(item, 'exception-modal', {
                  module: 'exception',
                  action: 'read',
                });
                setVehicleAnchor(item.stockNumber);
              "
            />
          </template>

          <template v-slot:cell(files)="{ item }">
            <FilesContextMenu :vehicle="item" :refreshData="getVehiclesInfo">
              <font-awesome-icon
                class="icon-color cursor-pointer"
                :icon="['far', 'folder']"
                size="xl"
                :style="{ color: getExportVariant(item.vehicleFlags.export) }"
                @click="
                  openModal(item, 'files-modal', {
                    module: 'jacket',
                    action: 'read',
                  });
                  setVehicleAnchor(item.stockNumber);
                "
              />
              <span v-if="item.exportLoadsId">
                {{ item.exportLoadsId }}
              </span>
            </FilesContextMenu>
          </template>
          <template v-slot:cell(reserve)="{ item }">
            <font-awesome-icon
              class="icon-color cursor-pointer"
              :icon="['far', 'message-dollar']"
              size="xl"
              :style="{
                color: getConsignmentVariant(item.vehicleFlags.reserve),
              }"
              @click="
                item.status == 'Sold' && item.vehicleFlags.reserve != 1
                  ? showSoldValidation()
                  : openModal(item, 'reserve-modal', {
                      module: 'reserve',
                      action: 'read',
                    });
                setVehicleAnchor(item.stockNumber);
              "
            />
            <br />
            <span v-if="item.vehicleFlags.reserveDays">
              {{ item.vehicleFlags.reserveDays }}
              d
            </span>
          </template>
          <template v-slot:cell(buyFrom)="{ item }">
            <div class="text-wrap">
              <small>{{
                corporation.id != item.corporationsId
                  ? item.corporationName
                  : item.buyCompanyName
              }}</small>
            </div>
          </template>
          <template v-slot:cell(soldInfo)="{ item }">
            <small v-if="item.saleCompanyName" class="text-wrap">
              {{ item.saleCompanyName }}
            </small>
          </template>

          <template v-slot:cell(location)="{ item }">
            <div v-if="item.lastLocation" class="text-wrap">
              <small>
                {{ item.lastLocation }}
              </small>
            </div>
          </template>
          <template v-slot:cell(offers)="{ item }">
            <font-awesome-icon
              class="icon-color cursor-pointer"
              :icon="['far', 'gavel']"
              size="xl"
              :style="{
                color: getOffersVariant(item.vehicleFlags.offers),
              }"
              @click="
                openOfferSidebar(item);

                setVehicleAnchor(item.stockNumber);
              "
            />
          </template>
          <template v-slot:cell(registration)="{ item }">
            <font-awesome-icon
              class="icon-color cursor-pointer"
              :icon="['far', 'file-magnifying-glass']"
              size="xl"
              :style="{
                color: getRegistrationVariant(item.vehicleFlags.registration),
              }"
              @click="
                openModal(item, 'registration-modal', {
                  module: 'registration',
                  action: 'read',
                });
                setVehicleAnchor(item.stockNumber);
              "
            />
            <span v-if="item.vehicleFlags.registrationDays">
              {{ item.vehicleFlags.registrationDays }} d
            </span>
          </template>

          <template v-slot:cell(addons)="{ item }">
            <font-awesome-icon
              class="icon-color cursor-pointer"
              :icon="['far', 'money-check-dollar-pen']"
              size="xl"
              :style="{
                color: getAddonVariant(item.vehicleFlags.addon),
              }"
              @click="
                openModal(item, 'addons-modal', {
                  module: 'addons',
                  action: 'read',
                });
                setVehicleAnchor(item.stockNumber);
              "
            />
          </template>

          <template v-slot:cell(express)="{ item }">
            <font-awesome-icon
              class="icon-color cursor-pointer"
              :icon="['far', 'truck-fast']"
              size="xl"
              :style="{
                color: getRegistrationVariant(item.vehicleFlags.registration),
              }"
              @click="
                goToSimpleView(item.id);
                setVehicleAnchor(item.stockNumber);
              "
            />
          </template>

          <template v-slot:cell(expenses)="{ item }">
            <font-awesome-icon
              class="icon-color cursor-pointer"
              :icon="['far', 'chart-pie']"
              size="xl"
              :style="{
                color: getRegistrationVariant(item.vehicleFlags.registration),
              }"
              @click="
                openModal(item, 'expenses-modal', {
                  module: 'addons',
                  action: 'read',
                });
                setVehicleAnchor(item.stockNumber);
              "
            />
          </template>

          <template v-slot:cell(policeBook)="{ item }">
            <font-awesome-icon
              class="icon-color cursor-pointer"
              :icon="['far', 'file-magnifying-glass']"
              size="xl"
              :style="{
                color: getRegistrationVariant(item.vehicleFlags.registration),
              }"
              @click="
                openModal(item, 'police-book-modal', {
                  module: 'registration',
                  action: 'read',
                });
                setVehicleAnchor(item.vinNumber.vinNumber);
              "
            />
          </template>
        </b-table>
      </div>

      <div>
        <b-pagination
          :value="
            vehiclesInfo.data.paginate
              ? vehiclesInfo.data.paginate.currentPage
              : 1
          "
          :total-rows="
            vehiclesInfo.data.paginate ? vehiclesInfo.data.paginate.total : 0
          "
          :per-page="
            vehiclesInfo.data.paginate ? vehiclesInfo.data.paginate.perPage : 25
          "
          class="mb-2"
          align="end"
          @change="changePage($event)"
        />
      </div>

      <SelectTableFields
        :fields-from-table="fieldsByPermission"
        :fieldsPermitted="fieldsByPermission"
        @getCookie="getCookie()"
      />
      <ChecksModal />
      <ConditionalReportsModal />
      <NotesModal />
      <CarfaxModal />
      <RecallModal />
      <WorkOrderModal />
      <TransferModal />
      <SoldModal />
      <CarwashModal />
      <ConsignmentModal />
      <LienModal />
      <PricesModal />
      <ExceptionModal />
      <RegistrationModal />
      <FilesModal />
      <ClusterModal />
      <ReserveModal />
      <PoliceBookModal />
      <OfferSideBar />
      <AddonsModal />
      <ExpensesModal />
      <AtacModal />
      <HistoryModal />
      <FinancialCreditRequestModal />
    </b-container>
  </div>
  <!--   <div v-else>
    <div class="container mt-auto text-center mt-5">
      <b-spinner
        style="width: 10rem; height: 10rem"
        variant="yellow"
        class="align-middle mt-5"
      />
    </div>
  </div> -->
</template>

<script>
import { mapState } from "vuex";
import { defaultFields, optionalFields } from "./vehicleTableFields";
import Search from "./components/Search";
import { superAdminMixin } from "./mixins";
import ChecksModal from "./components/checks/ChecksModal";
import ConditionalReportsModal from "./components/conditionalReports/ConditionalReportsModal";
import NotesModal from "./components/notes/NotesModal";
import CarfaxModal from "./components/carfax/CarfaxModal";
import RecallModal from "./components/recall/RecallModal";
import WorkOrderModal from "./components/workOrders/WorkOrderModal";
import SelectTableFields from "./components/SelectVehicleTableFields";
import TransferModal from "./components/transfer/TransferModal.vue";
import SoldModal from "./components/sold/SoldModal.vue";
import CarwashModal from "./components/carwash/CarwashModal.vue";
import FilterColum from "./filters/Filter";
/* import TotalDetails from "./components/TotalDetails.vue";
 */ import ConsignmentModal from "./components/consignment/ConsignmentModal.vue";
import LienModal from "./components/liens/LienModal.vue";
import PricesModal from "./components/prices/PriceModal.vue";
import ExceptionModal from "./components/exception/ExceptionModal";
import RegistrationModal from "./components/registration/RegistrationModal.vue";
import FilesModal from "./components/files/FilesModal.vue";
import ClusterModal from "./components/cluster/ClusterModal.vue";
import ReserveModal from "./components/reserve/ReserveModal.vue";
import PoliceBookModal from "./components/policeBook/PoliceBookModal.vue";
import AddonsModal from "./components/addons/AddonsModal.vue";
import ExpensesModal from "./components/expenses/ExpensesModal.vue";
import AtacModal from "./components/atac/AtacModal.vue";
import HistoryModal from "./components/history/HistoryModal.vue";
import FinancialCreditRequestModal from "./components/financial-credit-request/FinancialCreditRequestModal.vue";
import StatusFilter from "./filters/Status";
import TransferContextMenu from "./components/transfer/TransferContextMenu.vue";
import CarwashContextMenu from "./components/carwash/CarwashContextMenu.vue";
import ClusterContextMenu from "./components/cluster/ClusterContextMenu.vue";
import RecallContextMenu from "./components/recall/RecallContextMenu.vue";
import WorkOrderContextMenu from "./components/workOrders/WorkOrderContextMenu.vue";
import FinancialCreditRequestContextMenu from "./components/financial-credit-request/FinancialCreditRequestContextMenu.vue";
import OfferSideBar from "@/modules/admin/vehicles/offers/components/SideBar";
import FilesContextMenu from "./components/files/FilesContextMenu.vue";

import { GET_VEHICLES_EXCEL, SET_VEHICLES_INFO_FILTERS } from "./actions";
export default {
  components: {
    Search,
    SelectTableFields,
    ChecksModal,
    ConditionalReportsModal,
    NotesModal,
    CarfaxModal,
    RecallModal,
    WorkOrderModal,
    TransferModal,
    SoldModal,
    CarwashModal,
    FilterColum,
    /* TotalDetails */
    ConsignmentModal,
    LienModal,
    PricesModal,
    ExceptionModal,
    StatusFilter,
    RegistrationModal,
    FilesModal,
    ClusterModal,
    TransferContextMenu,
    CarwashContextMenu,
    ClusterContextMenu,
    FinancialCreditRequestContextMenu,
    ReserveModal,
    PoliceBookModal,
    OfferSideBar,
    AddonsModal,
    ExpensesModal,
    AtacModal,
    HistoryModal,
    RecallContextMenu,
    WorkOrderContextMenu,
    FinancialCreditRequestModal,
    FilesContextMenu,
  },
  mixins: [superAdminMixin],
  data() {
    return {
      fields: [],
      agentsSelected: [],
      vinCopied: null,
      vinCopiedText: "",
      stockCopied: null,
      stockCopiedText: "",
      prevRoute: {},
      showFullExportLocation: {
        show: false,
        itemId: null,
      },
      isLoadingVehiclesXLS: false,
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  computed: {
    ...mapState({
      vehiclesInfo: (state) => state.superAdminStore.vehiclesInfo,
      vehiclesExcel: (state) => state.superAdminStore.vehiclesExcel,
      vehicleAnchor: (state) => state.superAdminStore.vehicleAnchor,
      profile: (state) => state.auth.loginStore.profile.data,
      vehiclesFilters: (state) => state.superAdminStore.vehiclesInfo.filters,
    }),
    isMobile() {
      return this.$vssWidth <= 990 ? true : false;
    },
    corporation() {
      const corporation = localStorage.getItem("corporation");
      let corporationSelect = this.profile.user.corporationPermissions.find(
        (c) => c.id == corporation
      );

      return corporationSelect
        ? corporationSelect
        : this.profile.user.corporationPermissions[0];
    },
    fieldsByPermission() {
      if (this.profile.user.roles[0].name == "ROLE_RI") {
        const fieldsByPermission = this.fields.filter((field) =>
          this.userPermissions.some(
            (permission) =>
              (field.module === permission.module && permission.read === 1) ||
              field.module === ""
          )
        );
        return fieldsByPermission;
      } else {
        return this.fields;
      }
    },
  },

  mounted() {
    if (this.prevRoute.name == "superadmin.vehicles.sold-table") {
      this.resetFilters();
    }
    if (this.$route.query && Object.keys(this.$route.query).length > 0) {
      this.applyFiltersFromRoute(this.$route.query);
    } else {
      this.getVehiclesInfo();
    }
    this.saveCookie();
    this.getCategories();
  },

  methods: {
    selectAgent(agent) {
      this.agentsSelected.push(agent);
    },
    updateAgent(agent) {
      const index = this.agentsSelected.indexOf(agent);
      this.agentsSelected.splice(index, 1);
    },
    editColumns() {
      this.$bvModal.show("select-vehicle-table-fields");
    },
    getCookie() {
      this.fields = this.$cookies.get("fields");
      this.addFxColumn();
    },
    saveCookie() {
      if (!this.$cookies.isKey("fields")) {
        this.fields = [...defaultFields, ...optionalFields];
        this.$cookies.set(
          "fields",
          JSON.stringify([...defaultFields, ...optionalFields])
        );
      } else {
        this.getCookie();
      }
    },
    copyVinNumber(vin) {
      this.vinCopied = vin;
    },
    copyStockNumber(stock) {
      this.stockCopied = stock;
    },
    test() {
      console.log("test");
    },
    getVehiclesExcel(id = null) {
      if (id) {
        this.$store.commit(SET_VEHICLES_INFO_FILTERS, {
          fields: { id },
        });
      }
      this.isLoadingVehiclesXLS = true;

      this.$store
        .dispatch(GET_VEHICLES_EXCEL)
        .then((response) => {
          if (!response.link) {
            setTimeout(() => {
              this.getVehiclesExcel(response.id);
            }, 2000);
          } else {
            this.$store.commit(SET_VEHICLES_INFO_FILTERS, {
              fields: { id: null },
            });
            window.open(response.link, "_blank");

            this.isLoadingVehiclesXLS = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoadingVehiclesXLS = false;
          this.$root.$bvToast.toast("Could not get vehicles excel ", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    formatFileName(module) {
      let fileName = "";
      const today = new Date();
      fileName = `${module}-${today.getFullYear()}-${today.getMonth()}-${today.getDate()}-${today.getHours()}-${today.getMinutes()}-${today.getSeconds()}`;
      return fileName;
    },

    setVehicleAnchor(anchor) {
      this.$store.commit("SET_VEHICLE_ANCHOR", anchor);
    },
    toggleShowFullExportLocation(itemId) {
      this.showFullExportLocation.show = !this.showFullExportLocation.show;
      this.showFullExportLocation.itemId = itemId;
    },
    exportLocationAbbreviation(item) {
      if (item.exportLocations != null) {
        return item.exportLocations.name.substring(0, 3);
      }
      return "";
    },
    getCategories() {
      this.$store
        .dispatch("GET_CATEGORIES")
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("Could not get the category list ", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    goToSimpleView(id) {
      this.$router.push({
        name: "superadmin.vehicles.simple-view",
        params: { id: id },
      });
    },
    copyUrlWithFilters() {
      const query = this.getFiltersWithValue(this.vehiclesFilters);
      const encryptedText = this.$CryptoJS.AES.encrypt(
        JSON.stringify(query),
        "Secret Passphrase"
      ).toString();

      const queryEncrypted = { query: encryptedText };
      const url = `${window.location.href}/?${new URLSearchParams(
        queryEncrypted
      ).toString()}`;

      navigator.clipboard.writeText(url).then(() => {
        this.$root.$bvToast.toast("URL copied to clipboard", {
          title: "Success",
          variant: "success",
        });
      });
    },
    getFiltersWithValue(filters) {
      const query = {};
      for (const key in filters) {
        if (filters[key] != null && filters[key] != "") {
          query[key] = filters[key];
        }
      }
      return query;
    },
    applyFiltersFromRoute(router) {
      const decryptedText = this.$CryptoJS.AES.decrypt(
        router.query,
        "Secret Passphrase"
      ).toString(this.$CryptoJS.enc.Utf8);
      const query = JSON.parse(decryptedText);

      this.$store.commit(SET_VEHICLES_INFO_FILTERS, {
        reset: true,
        fields: { ...query },
      });
      this.getVehiclesInfo();
    },
    showSoldValidation() {
      this.$root.$bvToast.toast("This vehicle is already sold", {
        title: "Warning",
        variant: "warning",
      });
    },
    addFxColumn() {
      if (!this.userHasPermission("fxColumn", "read")) return;

      const fxColumn = {
        key: "fx",
        label: "Fx",
        class: "text-center",
      };
      this.fields.splice(3, 0, fxColumn);
    },
    twoDecimals(value) {
      if (value == null) return "";
      const fx = value.toString();
      const fxArray = fx.split(".");
      const fxArrayLength = fxArray.length;
      if (fxArrayLength > 1) {
        const fxArrayLast = fxArray[fxArrayLength - 1];
        if (fxArrayLast.length > 2) {
          return fxArray[0] + "." + fxArrayLast.substring(0, 2);
        }
      }
      return fx;
    },
  },
};
</script>

<style>
.icon-color {
  color: #aeaeaf;
}

.color-red td {
  background-color: red !important;
}

.table-head-menu {
  justify-content: space-between !important;
}

.table-sm th,
.table-sm td {
  padding: 0.25rem !important;
}
</style>
