<template>
  <header>
    <b-navbar class="text-grey" toggleable="lg" variant="white">
      <b-navbar-brand
        :to="{ name: profile ? 'superadmin.vehicles.table' : 'home' }"
      >
        <img src="@/assets/img/logos/QAS.png" width="110px" height="auto" />
      </b-navbar-brand>

      <b-navbar-nav class="ml-auto">
        <b-nav class="w-100 d-none d-lg-inline-flex" pills align="end">
          <template v-if="hasCorporation">
            <b-nav-item
              v-if="profile && profile.user.roles[0].name != 'ROLE_MASTER'"
              :disabled="companyIsPending"
              @click="openPricingModal()"
            >
              <span>
                <font-awesome-icon :icon="['far', 'dollar-sign']" />
              </span>
              <span class=""> </span>
            </b-nav-item>
            <b-nav-item
              v-if="profile && profile.user.roles[0].name == 'ROLE_MASTER'"
              :disabled="companyIsPending"
              :active="
                $route.name && $route.name.includes('master.marketplace')
              "
              :to="{ name: 'master.marketplace' }"
            >
              <span> </span>
              <span class=""> Marketplace </span>
            </b-nav-item>

            <b-nav-item
              v-if="profile && profile.user.roles[0].name == 'ROLE_MASTER'"
              :disabled="companyIsPending"
              :active="
                $route.name && $route.name.includes('master.corporation')
              "
              :to="{ name: 'master.corporation' }"
            >
              <span>
                <font-awesome-icon :icon="['far', 'building']" class="mr-1" />
              </span>
              <span class=""> Corporations </span>
            </b-nav-item>

            <b-nav-item
              v-if="profile && profile.user.roles[0].name == 'ROLE_MASTER'"
              :disabled="companyIsPending"
              :active="
                $route.name && $route.name.includes('master.super-admin')
              "
              :to="{ name: 'master.super-admin' }"
            >
              <span>
                <font-awesome-icon class="" :icon="['far', 'user-group']" />
              </span>
              <span class=""> Super admins </span>
            </b-nav-item>

            <b-nav-item
              v-if="profile && profile.user.roles[0].name != 'ROLE_MASTER'"
              :disabled="companyIsPending"
              :active="
                $route.name && $route.name.includes('superadmin.vehicles.table')
              "
              :to="{ name: 'superadmin.vehicles.table' }"
            >
              <span>
                <font-awesome-icon class="" :icon="['far', 'cars']" />
              </span>
              <span class=""> Vehicles </span>
            </b-nav-item>

            <b-nav-item
              v-if="profile && userHasPermission('buyChecks', 'read')"
              :disabled="companyIsPending"
              :active="
                ($route.name &&
                  $route.name.includes('superadmin.checks.actives')) ||
                ($route.name &&
                  $route.name.includes('superadmin.checks.delivered'))
              "
              :to="{ name: 'superadmin.checks.actives' }"
            >
              <span>
                <font-awesome-icon class="" :icon="['far', 'money-check']" />
              </span>
              <span class=""> Buy checks </span>
            </b-nav-item>

            <b-nav-item
              v-if="profile && userHasPermission('exportLoads', 'read')"
              :disabled="companyIsPending"
              :active="
                $route.name && $route.name.includes('superadmin.export-load')
              "
              :to="{ name: 'superadmin.export-load' }"
            >
              <span>
                <font-awesome-icon
                  class=""
                  :icon="['far', 'truck-container']"
                />
              </span>
              <span class=""> Export Loads </span>
            </b-nav-item>
            <b-nav-item
              v-if="profile && userHasPermission('creditRequest', 'read')"
              :disabled="companyIsPending"
              :active="
                $route.name && $route.name.includes('superadmin.credit-request')
              "
              :to="{ name: 'superadmin.credit-request' }"
            >
              <span>
                <font-awesome-icon
                  class=""
                  :icon="['far', 'file-invoice-dollar']"
                />
              </span>
              <span class=""> Credit request </span>
            </b-nav-item>
            <b-nav-item
              v-if="profile && userHasPermission('cluster', 'read')"
              :disabled="companyIsPending"
              @click="showModal('bulk-cluster-modal')"
            >
              <span>
                <font-awesome-icon class="" :icon="['far', 'gauge-simple']" />
              </span>
              <span class=""> Cluster </span>
            </b-nav-item>
            <b-nav-item
              v-if="profile && userHasPermission('transfer', 'read')"
              :disabled="companyIsPending"
              @click="showModal('bulk-transfer-modal')"
            >
              <span>
                <font-awesome-icon
                  class=""
                  :icon="['far', 'truck-container']"
                />
              </span>
              <span class=""> Transfer </span>
            </b-nav-item>

            <b-nav-item
              v-if="profile && userHasPermission('registration', 'read')"
              :disabled="companyIsPending"
              :active="
                $route.name && $route.name.includes('superadmin.registration')
              "
              :to="{ name: 'superadmin.registration' }"
            >
              <span>
                <font-awesome-icon
                  class=""
                  :icon="['far', 'file-invoice-dollar']"
                />
              </span>
              <span class=""> Registration </span>
            </b-nav-item>

            <b-nav-item
              v-if="profile && userHasPermission('companies', 'read')"
              :disabled="companyIsPending"
              :active="
                $route.name && $route.name.includes('admin.users.validate')
              "
              :to="{ name: 'admin.users.validate' }"
            >
              <span>
                <font-awesome-icon class="" :icon="['far', 'user-group']" />
              </span>
              <span class=""> Companies </span>
            </b-nav-item>
            <b-nav-item-dropdown
              right
              v-if="profile"
              class="text-left"
              menu-class="justify-content-center text-around"
            >
              <template slot="button-content">
                <div class="pr-2 pb-0">
                  <span>
                    <font-awesome-icon
                      class="float-left pb-0"
                      :icon="['far', 'wrench']"
                    />
                  </span>
                </div>
              </template>

              <b-dropdown-item
                v-if="profile && profile.user.roles[0].name == 'ROLE_MASTER'"
                :to="{ name: 'master.assign.corporation' }"
                @click="goTo('master.assign.corporation')"
              >
                <span>
                  <font-awesome-icon class="" :icon="['far', 'lock-keyhole']" />
                </span>

                Corporation permissions
              </b-dropdown-item>

              <b-dropdown-item
                v-if="
                  (profile && profile.user.roles[0].name == 'ROLE_MASTER') ||
                  profile.user.roles[0].name == 'ROLE_SUPER_ADMIN'
                "
                :to="{ name: 'master.assign.marketplace' }"
                @click="goTo('master.assign.marketplace')"
              >
                <span>
                  <font-awesome-icon class="" :icon="['far', 'lock-keyhole']" />
                </span>

                Marketplace permissions
              </b-dropdown-item>

              <b-dropdown-item
                v-if="userHasPermission('permissions', 'read')"
                :to="{ name: 'superadmin.permissions' }"
                @click="goTo('superadmin.permissions')"
              >
                <span>
                  <font-awesome-icon class="" :icon="['far', 'lock-keyhole']" />
                </span>

                Permissions
              </b-dropdown-item>
              <b-dropdown-item
                v-if="userHasPermission('drivers', 'read')"
                :to="{ name: 'superadmin.drivers' }"
                @click="goTo('superadmin.drivers')"
              >
                <span>
                  <font-awesome-icon class="" :icon="['far', 'id-card']" />
                </span>
                Drivers
              </b-dropdown-item>

              <b-dropdown-item
                v-if="userHasPermission('providers', 'read')"
                class="text-left"
                :to="{ name: 'work-order.providers' }"
                @click="goTo('work-order.providers')"
              >
                <span>
                  <font-awesome-icon :icon="['far', 'building']" class="mr-1" />
                </span>
                Providers
              </b-dropdown-item>

              <b-dropdown-item
                v-if="userHasPermission('categories', 'read')"
                class="float-left"
                :to="{ name: 'work-order.categories' }"
                @click="goTo('work-order.categories')"
              >
                <span>
                  <font-awesome-icon :icon="['fa', 'boxes']" class="mr-1" />
                </span>
                Categories
              </b-dropdown-item>

              <b-dropdown-item
                v-if="userHasPermission('locations', 'read')"
                class="text-left"
                :to="{ name: 'superadmin.locations' }"
                @click="goTo('superadmin.locations')"
              >
                <span>
                  <font-awesome-icon :icon="['far', 'location-dot']" />
                </span>
                Locations
              </b-dropdown-item>

              <b-dropdown-item
                v-if="userHasPermission('fees', 'read')"
                class="text-left"
                :to="{ name: 'superadmin.fees' }"
                @click="goTo('superadmin.fees')"
              >
                <span>
                  <font-awesome-icon :icon="['far', 'hand-holding-dollar']" />
                </span>
                Fees
              </b-dropdown-item>

              <b-dropdown-item
                v-if="userHasPermission('taxes', 'read')"
                class="text-left"
                :to="{ name: 'superadmin.taxes' }"
                @click="goTo('superadmin.taxes')"
              >
                <span>
                  <font-awesome-icon :icon="['far', 'sack-dollar']" />
                </span>
                Taxes
              </b-dropdown-item>
              <b-dropdown-item
                v-if="userHasPermission('exportLocations', 'read')"
                class="text-left"
                :to="{ name: 'superadmin.export-location' }"
                @click="goTo('superadmin.export-location')"
              >
                <span>
                  <font-awesome-icon :icon="['far', 'location-dot']" />
                </span>
                Export locations
              </b-dropdown-item>

              <b-dropdown-item
                v-if="userHasPermission('agents', 'read')"
                class="text-left"
                :to="{ name: 'admin.agent' }"
                @click="goTo('admin.agent')"
              >
                <span>
                  <font-awesome-icon :icon="['far', 'user-group']" />
                </span>
                Users
              </b-dropdown-item>

              <b-dropdown-item
                v-if="userHasPermission('fx', 'read')"
                class="text-left"
                :to="{ name: 'superadmin.fx' }"
                @click="goTo('superadmin.fx')"
              >
                <span>
                  <font-awesome-icon :icon="['far', 'dollar-sign']" />
                </span>
                Fx
              </b-dropdown-item>

              <b-dropdown-item
                v-if="userHasPermission('companies', 'read')"
                class="text-left"
                :to="{ name: 'admin.assign-company' }"
                @click="goTo('admin.assign-company')"
              >
                <span>
                  <font-awesome-icon :icon="['far', 'user-group']" />
                </span>
                External users
              </b-dropdown-item>

              <b-dropdown-item
                v-if="userHasPermission('addons', 'read')"
                class="text-left"
                :to="{ name: 'superadmin.addons-type' }"
                @click="goTo('superadmin.addons-type')"
              >
                <span>
                  <font-awesome-icon :icon="['far', 'puzzle-piece']" />
                </span>
                Suppliers category
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </template>

          <div v-if="!this.profile">
            <!-- Login button -->
            <b-button
              variant="none"
              class="btn btn-success btn-lg ml-3"
              @click="login"
            >
              <span class="mr-3 ml-3">
                <b-icon
                  icon="box-arrow-in-right"
                  variant="white"
                  class="mr-1"
                />
                Log In
              </span>
            </b-button>

            <b-button
              variant="none"
              class="btn btn-outline-yellow btn-lg ml-3"
              @click="goToRegister()"
            >
              <span class="mr-3 ml-3"> Register </span>
            </b-button>
          </div>

          <!-- Logout button -->
          <b-button
            v-else
            variant="none"
            class="btn btn-outline-danger btn-lg ml-3"
            @click="logout"
          >
            <span class="">Logout</span>
          </b-button>
        </b-nav>
      </b-navbar-nav>

      <b-navbar-toggle v-b-toggle.sidebar-menu target="none" />
    </b-navbar>
    <b-sidebar
      id="sidebar-menu"
      :width="widthSidebar"
      title=""
      bg-variant="white"
      text-variant="black"
    >
      <template v-if="profile" v-slot:title class="text-center">
        <img
          src="@/assets/img/logos/QAS.png"
          width="25%"
          height="auto"
          class="image-responsive"
        />
      </template>

      <b-nav vertical class="text-center text-light h4">
        <b-nav-item
          v-if="profile && profile.user.roles[0].name != 'ROLE_MASTER'"
          :disabled="companyIsPending"
          @click="openPricingModal()"
        >
          <span>
            <font-awesome-icon :icon="['far', 'dollar-sign']" />
          </span>
          <span class=""> </span>
        </b-nav-item>
        <b-nav-item
          v-if="profile && profile.user.roles[0].name == 'ROLE_MASTER'"
          :disabled="companyIsPending"
          :active="$route.name && $route.name.includes('master.marketplace')"
          :to="{ name: 'master.marketplace' }"
        >
          <span> </span>
          <span class=""> Marketplace </span>
        </b-nav-item>

        <b-nav-item
          v-if="profile && profile.user.roles[0].name == 'ROLE_MASTER'"
          :disabled="companyIsPending"
          :active="$route.name && $route.name.includes('master.corporation')"
          :to="{ name: 'master.corporation' }"
        >
          <span>
            <font-awesome-icon :icon="['far', 'building']" class="mr-1" />
          </span>
          <span class=""> Corporations </span>
        </b-nav-item>

        <b-nav-item
          v-if="profile && profile.user.roles[0].name == 'ROLE_MASTER'"
          :disabled="companyIsPending"
          :active="$route.name && $route.name.includes('master.super-admin')"
          :to="{ name: 'master.super-admin' }"
        >
          <span>
            <font-awesome-icon class="" :icon="['far', 'user-group']" />
          </span>
          <span class=""> Super admins </span>
        </b-nav-item>

        <b-nav-item
          v-if="profile && profile.user.roles[0].name != 'ROLE_MASTER'"
          :disabled="companyIsPending"
          :active="
            $route.name && $route.name.includes('superadmin.vehicles.table')
          "
          :to="{ name: 'superadmin.vehicles.table' }"
        >
          <span>
            <font-awesome-icon class="" :icon="['far', 'cars']" />
          </span>
          <span class=""> Vehicles </span>
        </b-nav-item>

        <b-nav-item
          v-if="profile && userHasPermission('buyChecks', 'read')"
          :disabled="companyIsPending"
          :active="
            ($route.name &&
              $route.name.includes('superadmin.checks.actives')) ||
            ($route.name && $route.name.includes('superadmin.checks.delivered'))
          "
          :to="{ name: 'superadmin.checks.actives' }"
        >
          <span>
            <font-awesome-icon class="" :icon="['far', 'money-check']" />
          </span>
          <span class=""> Buy checks </span>
        </b-nav-item>

        <b-nav-item
          v-if="profile && userHasPermission('exportLoads', 'read')"
          :disabled="companyIsPending"
          :active="
            $route.name && $route.name.includes('superadmin.export-load')
          "
          :to="{ name: 'superadmin.export-load' }"
        >
          <span>
            <font-awesome-icon class="" :icon="['far', 'truck-container']" />
          </span>
          <span class=""> Export Loads </span>
        </b-nav-item>
        <b-nav-item
          v-if="profile && userHasPermission('creditRequest', 'read')"
          :disabled="companyIsPending"
          :active="
            $route.name && $route.name.includes('superadmin.credit-request')
          "
          :to="{ name: 'superadmin.credit-request' }"
        >
          <span>
            <font-awesome-icon
              class=""
              :icon="['far', 'file-invoice-dollar']"
            />
          </span>
          <span class=""> Credit request </span>
        </b-nav-item>
        <b-nav-item
          v-if="profile && userHasPermission('cluster', 'read')"
          :disabled="companyIsPending"
          @click="showModal('bulk-cluster-modal')"
        >
          <span>
            <font-awesome-icon class="" :icon="['far', 'gauge-simple']" />
          </span>
          <span class=""> Cluster </span>
        </b-nav-item>
        <b-nav-item
          v-if="profile && userHasPermission('transfer', 'read')"
          :disabled="companyIsPending"
          @click="showModal('bulk-transfer-modal')"
        >
          <span>
            <font-awesome-icon class="" :icon="['far', 'truck-container']" />
          </span>
          <span class=""> Transfer </span>
        </b-nav-item>

        <b-nav-item
          v-if="profile && userHasPermission('registration', 'read')"
          :disabled="companyIsPending"
          :active="
            $route.name && $route.name.includes('superadmin.registration')
          "
          :to="{ name: 'superadmin.registration' }"
        >
          <span>
            <font-awesome-icon
              class=""
              :icon="['far', 'file-invoice-dollar']"
            />
          </span>
          <span class=""> Registration </span>
        </b-nav-item>

        <b-nav-item
          v-if="profile && userHasPermission('companies', 'read')"
          :disabled="companyIsPending"
          :active="$route.name && $route.name.includes('admin.users.validate')"
          :to="{ name: 'admin.users.validate' }"
        >
          <span>
            <font-awesome-icon class="" :icon="['far', 'user-group']" />
          </span>
          <span class=""> Companies </span>
        </b-nav-item>

        <b-nav-item-dropdown
          left
          v-if="profile"
          class="text-left"
          menu-class="justify-content-center text-around"
        >
          <template slot="button-content">
            <div class="pr-2 pb-0">
              <span>
                <font-awesome-icon
                  class="float-left pb-0"
                  :icon="['far', 'wrench']"
                />
              </span>
            </div>
          </template>

          <b-dropdown-item
            v-if="profile && profile.user.roles[0].name == 'ROLE_MASTER'"
            :to="{ name: 'master.assign.corporation' }"
            @click="goTo('master.assign.corporation')"
          >
            <span>
              <font-awesome-icon class="" :icon="['far', 'lock-keyhole']" />
            </span>

            Corporation permissions
          </b-dropdown-item>

          <b-dropdown-item
            v-if="
              (profile && profile.user.roles[0].name == 'ROLE_MASTER') ||
              profile.user.roles[0].name == 'ROLE_SUPER_ADMIN'
            "
            :to="{ name: 'master.assign.marketplace' }"
            @click="goTo('master.assign.marketplace')"
          >
            <span>
              <font-awesome-icon class="" :icon="['far', 'lock-keyhole']" />
            </span>

            Marketplace permissions
          </b-dropdown-item>

          <b-dropdown-item
            v-if="userHasPermission('permissions', 'read')"
            :to="{ name: 'superadmin.permissions' }"
            @click="goTo('superadmin.permissions')"
          >
            <span>
              <font-awesome-icon class="" :icon="['far', 'lock-keyhole']" />
            </span>

            Permissions
          </b-dropdown-item>
          <b-dropdown-item
            v-if="userHasPermission('drivers', 'read')"
            :to="{ name: 'superadmin.drivers' }"
            @click="goTo('superadmin.drivers')"
          >
            <span>
              <font-awesome-icon class="" :icon="['far', 'id-card']" />
            </span>
            Drivers
          </b-dropdown-item>

          <b-dropdown-item
            v-if="userHasPermission('providers', 'read')"
            class="text-left"
            :to="{ name: 'work-order.providers' }"
            @click="goTo('work-order.providers')"
          >
            <span>
              <font-awesome-icon :icon="['far', 'building']" class="mr-1" />
            </span>
            Providers
          </b-dropdown-item>

          <b-dropdown-item
            v-if="userHasPermission('categories', 'read')"
            class="float-left"
            :to="{ name: 'work-order.categories' }"
            @click="goTo('work-order.categories')"
          >
            <span>
              <font-awesome-icon :icon="['fa', 'boxes']" class="mr-1" />
            </span>
            Categories
          </b-dropdown-item>

          <b-dropdown-item
            v-if="userHasPermission('locations', 'read')"
            class="text-left"
            :to="{ name: 'superadmin.locations' }"
            @click="goTo('superadmin.locations')"
          >
            <span>
              <font-awesome-icon :icon="['far', 'location-dot']" />
            </span>
            Locations
          </b-dropdown-item>

          <b-dropdown-item
            v-if="userHasPermission('fees', 'read')"
            class="text-left"
            :to="{ name: 'superadmin.fees' }"
            @click="goTo('superadmin.fees')"
          >
            <span>
              <font-awesome-icon :icon="['far', 'hand-holding-dollar']" />
            </span>
            Fees
          </b-dropdown-item>

          <b-dropdown-item
            v-if="userHasPermission('taxes', 'read')"
            class="text-left"
            :to="{ name: 'superadmin.taxes' }"
            @click="goTo('superadmin.taxes')"
          >
            <span>
              <font-awesome-icon :icon="['far', 'sack-dollar']" />
            </span>
            Taxes
          </b-dropdown-item>
          <b-dropdown-item
            v-if="userHasPermission('exportLocations', 'read')"
            class="text-left"
            :to="{ name: 'superadmin.export-location' }"
            @click="goTo('superadmin.export-location')"
          >
            <span>
              <font-awesome-icon :icon="['far', 'location-dot']" />
            </span>
            Export locations
          </b-dropdown-item>

          <b-dropdown-item
            v-if="userHasPermission('agents', 'read')"
            class="text-left"
            :to="{ name: 'admin.agent' }"
            @click="goTo('admin.agent')"
          >
            <span>
              <font-awesome-icon :icon="['far', 'user-group']" />
            </span>
            Users
          </b-dropdown-item>

          <b-dropdown-item
            v-if="userHasPermission('fx', 'read')"
            class="text-left"
            :to="{ name: 'superadmin.fx' }"
            @click="goTo('superadmin.fx')"
          >
            <span>
              <font-awesome-icon :icon="['far', 'dollar-sign']" />
            </span>
            Fx
          </b-dropdown-item>

          <b-dropdown-item
            v-if="userHasPermission('companies', 'read')"
            class="text-left"
            :to="{ name: 'admin.assign-company' }"
            @click="goTo('admin.assign-company')"
          >
            <span>
              <font-awesome-icon :icon="['far', 'user-group']" />
            </span>
            External users
          </b-dropdown-item>

          <b-dropdown-item
            v-if="userHasPermission('addons', 'read')"
            class="text-left"
            :to="{ name: 'superadmin.addons-type' }"
            @click="goTo('superadmin.addons-type')"
          >
            <span>
              <font-awesome-icon :icon="['far', 'puzzle-piece']" />
            </span>
            Suppliers category
          </b-dropdown-item>
          <b-dropdown-item
            v-if="true"
            class="text-left"
            :to="{ name: 'superadmin.interest' }"
            @click="goTo('superadmin.interest')"
          >
            <span>
              <font-awesome-icon :icon="['far', 'money-bill-transfer']" />
            </span>
            Commissions
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-nav>

      <b-container v-if="!profile" fluid class="text-center mt-2 p-4">
        <LoginForm />
      </b-container>

      <template v-slot:footer>
        <div
          v-if="profile"
          class="d-flex bg-white text-light justify-content-between align-items-center px-3 py-2"
        >
          <!-- Logout button -->
          <b-button variant="outline-danger" size="lg" block @click="logout">
            <span class="">Logout</span>
          </b-button>
        </div>
      </template>
    </b-sidebar>
    <Login />
    <BulkClusterModal />
    <BulkTransferModal />

    <b-modal
      id="pricing-modal"
      ref="pricing-modal"
      size="md"
      hide-footer
      body-class="pl-0 pr-0"
      scrollable
      ok-disabled
    >
      <Pricing />
    </b-modal>
  </header>
</template>

<script>
import { mapState } from "vuex";
import appRoles from "../helpers/appRoles";
import Login from "@/modules/auth/login/LoginModal";
import LoginForm from "@/modules/auth/login/LoginForm";
import { AUTH_LOGOUT } from "@/modules/auth/login/actions";
import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../modules/superAdmin/mixins";
import Pricing from "@/modules/pricing/calculator/Calculator.vue";
import BulkClusterModal from "@/modules/superAdmin/bulk-actions/cluster/BulkClusterModal.vue";
import BulkTransferModal from "@/modules/superAdmin/bulk-actions/transfers/BulkTransferModal.vue";

export default {
  components: {
    Login,
    LoginForm,
    Pricing,
    BulkClusterModal,
    BulkTransferModal,
  },
  mixins: [generalMixin, superAdminMixin],
  data() {
    return {
      unAuthenticatedUsersLink: [
        /*   {
          display: "View Inventory",
          routeName: "inventory.vehicles",
          includesActive: "View Inventory",
        }, */
      ],
      superAdminLinks: [
        {
          display: "Vehicles",
          routeName: "superadmin.vehicles.table",
          includesActive: "superadmin.vehicles.table",
        },
        {
          display: "Categories",
          routeName: "work-order.categories",
          includesActive: "work-order.categories",
        },
        {
          display: "Providers",
          routeName: "work-order.providers",
          includesActive: "work-order.providers",
        },
        {
          display: "Buy Checks",
          routeName: "superadmin.checks.actives",
          includesActive: "superadmin.checks.actives",
        },
        {
          display: "Drivers",
          routeName: "superadmin.drivers",
          includesActive: "superadmin.drivers",
        },
      ],
    };
  },
  computed: {
    widthSidebar() {
      return this.$vssWidth > 1280
        ? "25%"
        : this.$vssWidth <= 576
        ? "100%"
        : "50%";
    },
    ...mapState({
      profile: (state) => state.auth.loginStore.profile.data,
      hasCorporation: (state) =>
        state.auth.loginStore.corporation ? true : false,
    }),
    filterLinks() {
      let links = [];
      if (this.profile && this.profile?.user?.roles?.length) {
        const [superAdmin] = appRoles;

        this.profile.user.roles.forEach((value, index) => {
          switch (this.profile.user.roles[index].name) {
            case superAdmin.authority:
              links.push(...this.superAdminLinks); // Add super admin links
              break;

            default:
              links.push();
          }
        });
      } else {
        links = [...this.unAuthenticatedUsersLink];
      }
      return links;
    },
  },
  methods: {
    login() {
      this.goToHome();
    },
    goToHome() {
      this.$router.push({ path: "/" }).catch(() => {});
    },
    logout() {
      this.$store
        .dispatch(AUTH_LOGOUT)
        .then(() => {
          this.$router.push({ name: "home" });
        })
        .catch(() => {
          this.$root.$bvToast.toast("Error to logout", {
            title: "Warning",
            variant: "danger",
          });
        });
    },
    goToRegister() {
      if (this.$route.name !== "buyer.create")
        this.$router.push({ name: "buyer.create" });
    },
    goTo(name) {
      if (this.$route.name !== name) this.$router.push({ name });
    },
    openPricing() {
      window.open(
        "https://amazing-cactus-2eef30.netlify.app/",
        "popup",
        "width=600,height=600"
      );
    },
    openPricingModal() {
      this.$bvModal.show("pricing-modal");
    },
  },
};
</script>

<style scoped>
.icon-text {
  font-size: 25px;
}
a:hover {
  text-decoration: none;
  color: grey;
}
.nav-item-color {
  color: grey;
}
.nav-pills .nav-link.active {
  background-color: #fe4011;
  color: white;
}

nav-link dropdown-toggle .nav-link {
  color: #333333;
}
nav-link dropdown-toggle .show {
  color: #fff;
}
</style>
