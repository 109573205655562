<template>
  <b-row :class="{ 'mt-3': isBuyerView }">
    <b-col
      class="mb-3"
      xl="3"
      md="3"
    >
      <Search
        ref="searchVehiclesComponent"
        class="sticky-top"
      />
    </b-col>
    <b-col
      xl="9"
      md="9"
      sm="12"
    >
      <!-- Sort by most recent -->
      <b-row class="mb-1">
        <b-col class="text-right">
          <b-button
            v-if="userIsAdmin || userIsAgent"
            :disabled="companyIsPending"
            variant="primary"
            class="mr-2 btn-missing-info"
            @click="missingInfo"
          >
            <span v-if="statusFilterInDraft">No</span> Missing Info
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-if="vehiclesStatus === 'fetching'"
          class="mt-2"
        >
          <b-row>
            <b-col
              v-for="(data, index) in [1, 2, 3, 4, 5, 6]"
              :key="index"
              lg="4"
              md="6"
              sm="12"
              class="mt-3"
            >
              <b-card
                no-body
                img-top
              >
                <b-skeleton-img
                  card-img="top"
                  aspect="3:1"
                />
                <b-card-body>
                  <b-skeleton-table
                    :rows="5"
                    :columns="1"
                    :table-props="{ bordered: true, striped: true }"
                  />
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-else-if="vehicles.data && !vehicles.data.length"
          class="mt-2 col-lg-4 col-centered"
        >
          <b>No records to show</b>
        </b-col>
        <b-col
          v-for="vehicle in vehicles.data"
          v-else
          :key="vehicle.id"
          class="mt-2"
          xl="4"
          md="4"
          sm="12"
        >
          <VehicleCard
            :vehicle="vehicle"
            :show-c-r-modal="true"
          />
        </b-col>
      </b-row>
    </b-col>
    <VehicleDetailsSidebar />
  </b-row>
</template>

<script>
import { mapState } from 'vuex';
import Search from './Search';
import VehicleCard from './VehicleCard';
import VehicleDetailsSidebar from './components/VehicleDetailsSidebar';
import { listVehiclesMixin } from './mixins';
import { SET_VEHICLES_FILTERS, GET_VEHICLES } from './actions';

export default {
  components: { Search, VehicleCard, VehicleDetailsSidebar },
  mixins: [ listVehiclesMixin ],
  props: [ 'isBuyerView', 'userIsAdmin', 'userIsAgent', 'companyIsPending' ],
  data() {
    return {
      missingInfoSelected: false
    };
  },
  computed: {
    ...mapState({
      vehicles: (state) => state.adminStore.vehicles.listStore.vehicles.data,
      pagination: (state) => state.adminStore.vehicles.listStore.vehicles.data,
      vehiclesStatus: (state) =>
        state.adminStore.vehicles.listStore.vehicles.status,
      statusFilter: (state) =>
        state.adminStore.vehicles.listStore.vehicles.filters.status,
      statusFilterInDraft: (state) =>
        state.adminStore.vehicles.listStore.vehicles.filters.status === 'Draft'
    })
  },
  beforeMount() {
    if (this.statusFilterInDraft) this.missingInfoSelected = true;
  },
  methods: {
    getVehicles() {
      this.$store
        .dispatch(GET_VEHICLES, {})
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast('Could not get the vehicle list', {
            title: 'Warning',
            variant: 'warning'
          });
        });
    },
    missingInfo() {
      this.missingInfoSelected = !this.statusFilterInDraft
        ? true
        : !this.missingInfoSelected;
      const status = this.missingInfoSelected ? 'Draft' : null;
      this.$store.commit(SET_VEHICLES_FILTERS, {
        reset: true,
        ...{ fields: { status } }
      });
      this.$refs.searchVehiclesComponent.clearElementsInSearch();
      this.searchVehiclesByTabMenu();
    },
    orderVehicles() {
      let filter = { new: true };
      this.$store.commit(SET_VEHICLES_FILTERS, { fields: { ...filter } });
      this.searchVehiclesByTabMenu();
    }
  }
};
</script>

<style>
@media screen and (max-width: 300px) {
  .btn-missing-info {
    margin-bottom: 10px;
  }
}
</style>
