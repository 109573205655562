export const GET_EXPORT = "GET_EXPORT";
export const GET_EXPORT_SUCCESS = "GET_EXPORT_SUCCESS";
export const GET_EXPORT_ERROR = "GET_EXPORT_ERROR";

export const POST_EXPORT = "POST_EXPORT";
export const POST_EXPORT_SUCCESS = "POST_EXPORT_SUCCESS";
export const POST_EXPORT_ERROR = "POST_EXPORT_ERROR";

export const PUT_DELETE_DOCUMENT = "PUT_DELETE_DOCUMENT";
export const PUT_DELETE_DOCUMENT_SUCCESS = "PUT_DELETE_DOCUMENT_SUCCESS";
export const PUT_DELETE_DOCUMENT_ERROR = "PUT_DELETE_DOCUMENT_ERROR";

export const GET_TITLE_ORIGIN = "GET_TITLE_ORIGIN";
export const GET_TITLE_ORIGIN_SUCCESS = "GET_TITLE_ORIGIN_SUCCESS";
export const GET_TITLE_ORIGIN_ERROR = "GET_TITLE_ORIGIN_ERROR";
