var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{staticClass:"shadow h-100"},[(_vm.vehicle.new && !_vm.viewOffers && !_vm.isActiveOffer && !_vm.isExpiredOffer)?_c('b-row',{staticClass:"text-right"},[_c('b-col',{staticClass:"mr-4"},[_c('img',{staticClass:"img-fluid star-icon",attrs:{"src":require("@/assets/img/icons/new-1.png"),"alt":"star-icon"}})])],1):_vm._e(),(_vm.vehicle.status !== 'Sold' && _vm.viewOffers && !_vm.isActiveOffer)?_c('div',{staticClass:"text-center"},[_c('b',[_vm._v("Highest Offer")]),_c('h1',[_c('i',{staticClass:"highest-offer"},[_vm._v(_vm._s(_vm._f("currency")(_vm.vehicle.highestOffer)))])])]):_vm._e(),(_vm.vehicle.status == 'Sold' && _vm.isExpiredOffer)?_c('div',[_c('b-row',[_c('b-col',{staticClass:"mt-1 text-center"},[_c('h5',[_vm._v("Final Sale Price")])])],1),_c('b-row',[_c('b-col',{staticClass:"mt-1 text-center"},[_c('h1',{staticClass:"buyer-offer"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.vehicle.finalPrice))+" ")])])],1)],1):(_vm.isActiveOffer || _vm.isExpiredOffer)?_c('div',{staticClass:"mt-1 mb-1",class:{ 'text-center': _vm.userOffer && !_vm.userOffer.lastOfferAdmin }},[_c('b-row',[(_vm.lastOfferUser)?_c('b-col',[_c('span',[(_vm.isActiveOffer)?_c('h5',[_vm._v("Current Offer")]):_vm._e()]),(!_vm.isExpiredOffer)?_c('b-row',[_c('b-col',[_c('h1',[_c('i',{staticClass:"buyer-offer"},[_c('strong',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.lastOfferUser.offerPrice))+" ")])])])])],1):_vm._e()],1):_vm._e()],1),(
        _vm.vehicle.status !== 'Sold' &&
        _vm.vehicle.offerClient &&
        _vm.userOffer &&
        _vm.userOffer.lastOfferAdmin
      )?_c('b-row',{staticClass:"mt-2 mb-2"},[_c('b-col',{staticClass:"mt-1 text-center"},[_c('h5',[_vm._v("Canam Offer")]),_c('b-row',[_c('b-col',[_c('h1',[_c('i',{staticClass:"admin-offer"},[_c('strong',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.userOffer.lastOfferAdmin.offerPrice))+" ")])])])])],1)],1)],1):_vm._e()],1):_vm._e(),(!_vm.isActiveOffer && !_vm.isExpiredOffer && !_vm.viewOffers)?_c('car-gallery',{attrs:{"exterior":_vm.exterior,"interior":_vm.interior,"damages":_vm.damages,"damages-dots":_vm.damagesDots,"vehicle-model":_vm.vehicle.vinNumber.model,"vin-number":_vm.vehicle.stockNumber,"make":_vm.vehicle.vinNumber.make,"model":_vm.vehicle.vinNumber.model,"size":"width: auto;  height: 4rem!important;","class-img":"img-thumbnail"},on:{"onSelectImage":_vm.selectImage,"onChangeTab":_vm.changeTab},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('b-row',{staticClass:"mb-3 pl-3 pr-3"},[_c('b-col',{staticClass:"text-center m-0 p-0"},[_c('Photoswipe',{attrs:{"rotate":""}},[_c('img',{directives:[{name:"pswp",rawName:"v-pswp",value:(
                _vm.imgNotFound || _vm.imgError
                  ? _vm.imgError
                  : _vm.tab === 0
                  ? _vm.exterior[_vm.index]
                  : _vm.interior[_vm.index]
              ),expression:"\n                imgNotFound || imgError\n                  ? imgError\n                  : tab === 0\n                  ? exterior[index]\n                  : interior[index]\n              "}],staticClass:"carousel-item active main-img img-thumbnail",staticStyle:{"height":"18rem"},attrs:{"src":_vm.imgNotFound || _vm.imgError
                  ? _vm.imgError
                  : _vm.tab === 0
                  ? _vm.exterior[_vm.index]
                  : _vm.interior[_vm.index],"alt":"car"},on:{"error":_vm.errorLoadImg}})]),(_vm.index > 0)?_c('a',{staticClass:"carousel-control-prev",attrs:{"role":"button","data-slide":"prev"},on:{"click":_vm.backImg}},[_c('b-icon',{attrs:{"scale":"2","icon":"arrow-left-circle-fill"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Previous")])],1):_vm._e(),(_vm.index < _vm.exterior.length - 1)?_c('a',{staticClass:"carousel-control-next",attrs:{"role":"button","data-slide":"next"},on:{"click":_vm.nextImg}},[_c('b-icon',{attrs:{"scale":"2","icon":"arrow-right-circle-fill"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Next")])],1):_vm._e()],1)],1)]},proxy:true}],null,false,4197833806)}):_vm._e(),(!_vm.isHome)?_c('b-row',{staticStyle:{"padding-left":"-2rem","margin-bottom":"-2px"}},[_c('b-col',{staticClass:"mt-3"},[(_vm.vehicle.stockNumber)?_c('h6',{staticClass:"stockNumber-mt"},[_vm._v(" Stock: "),_c('i',{class:{ stockNumber: _vm.viewOffers || _vm.isActiveOffer }},[_vm._v(" "+_vm._s(_vm.vehicle.stockNumber)+" ")])]):_vm._e()])],1):_vm._e(),_c('b-row',{class:{ 'mt-2': _vm.isHome }},[_c('b-col',[_c('b-row',[_c('b-col',[_c('b-row',[(_vm.vehicle.make || _vm.vehicle.vinNumber.make)?_c('b-col',[_c('div',{staticClass:"mb-2"},[_vm._v(" Make: "),_c('i',[_vm._v(_vm._s(_vm.vehicle.make || _vm.vehicle.vinNumber.make))])])]):_vm._e(),(_vm.vehicle.vinNumber.year || _vm.vehicle.year)?_c('b-col',[_c('div',{staticClass:"ml-2 mb-2"},[_vm._v(" Year: "),_c('i',[_vm._v(_vm._s(_vm.vehicle.vinNumber.year || _vm.vehicle.year))])])]):_vm._e()],1)],1)],1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"mb-2"},[_vm._v(" Model: "),_c('i',[_vm._v(" "+_vm._s(_vm.vehicle.model || _vm.vehicle.vinNumber.model)+" ")])])])],1),_c('b-row',[(
            _vm.vehicle.trim ||
            (typeof _vm.vehicle.vinNumber === 'object' && _vm.vehicle.vinNumber.trim)
          )?_c('b-col',[_c('div',{staticClass:"mb-2"},[_c('i',[_vm._v(_vm._s(_vm.vehicle.trim || _vm.vehicle.vinNumber.trim))])])]):_vm._e(),(_vm.vehicle.options || _vm.vehicle.vinNumber.options)?_c('b-col',[_c('div',{staticClass:"mb-2",class:{
              'ml-2':
                _vm.vehicle.trim ||
                (typeof _vm.vehicle.vinNumber === 'object' &&
                  _vm.vehicle.vinNumber.trim),
            }},[_c('i',[_vm._v(_vm._s(_vm.vehicle.options || _vm.vehicle.vinNumber.options))])])]):_vm._e()],1),_c('b-row',[(_vm.vehicle.miles || _vm.vehicle.vehicleInformation.miles)?_c('b-col',[_c('div',{class:{ 'mb-2': !_vm.user && !_vm.vehicle.releaseDate }},[_vm._v(" Mileage: "),_c('i',[_vm._v(_vm._s(_vm.vehicle.miles || _vm.vehicle.vehicleInformation.miles))])])]):_vm._e()],1),(_vm.vehicle.releaseDateInReady)?_c('b-row',{staticClass:"mb-2 mt-2"},[_c('b-col',[_vm._v(" Release Date: "),_c('i',[_vm._v("Ready")])])],1):(_vm.vehicle.releaseDate)?_c('b-row',{staticClass:"mb-2 mt-2"},[_c('b-col',[_vm._v(" Release Date: "),_c('i',[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.vehicle.releaseDate))+" ")])])],1):_vm._e(),(_vm.isShowFullInfo)?_c('div',[_c('VehicleDetails',{attrs:{"vehicle":_vm.vehicle}})],1):_vm._e(),(
          !_vm.isHome &&
          _vm.user &&
          (_vm.vehicle.cost ||
            (_vm.vehicle.vehicleCostDetail && _vm.vehicle.vehicleCostDetail.cost)) &&
          (_vm.userIsAdmin || _vm.userIsAgent)
        )?_c('b-row',{staticClass:"mt-2 mb-2"},[_c('b-col',[_vm._v(" Cost: "),(_vm.vehicle.cost)?_c('i',[_vm._v(_vm._s(_vm._f("currency")(_vm.vehicle.cost)))]):_c('i',[_vm._v(_vm._s(_vm._f("currency")(_vm.vehicle.vehicleCostDetail.cost)))])])],1):_vm._e(),(
          _vm.vehicle.status &&
          _vm.vehicle.status !== 'Sold' &&
          !_vm.isHome &&
          (_vm.userIsAdmin || _vm.userIsAgent)
        )?_c('b-row',[_c('b-col',{staticClass:"mb-2"},[_vm._v(" Status: "),_c('i',[_vm._v(" "+_vm._s(_vm.vehicle.status === "ReadyToSell" ? "Ready To Sell" : _vm.vehicle.status)+" ")])])],1):_vm._e(),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"id":'tooltip-google-maps-' + _vm.vehicle.id}},[_c('img',{staticClass:"img-responsive",staticStyle:{"max-width":"20px"},attrs:{"src":require("@/assets/img/mapaIcon.png")}}),_vm._v(" Location "),_c('i',[_vm._v(_vm._s(_vm.vehicle.location))]),_c('b-tooltip',{attrs:{"target":'tooltip-google-maps-' + _vm.vehicle.id,"triggers":"hover"}},[_c('h5',{staticClass:"mt-2"},[_vm._v("New York Location")]),_c('br'),_c('h5',[_vm._v("Address")]),_vm._v(" 44951 County Rd 191, Wellesley Island, NY 13640, EE. UU. "),_c('br'),_c('br'),_c('a',{staticClass:"mt-2",staticStyle:{"color":"white","text-decoration":"none"},attrs:{"href":"https://goo.gl/maps/uKtbs1u3K6fmPLZG7","target":"_blank"}},[_vm._v(" View on Google Maps ")])])],1)],1),(
          (_vm.userIsAdmin || _vm.userIsAgent) &&
          _vm.vehicle.status === 'Sold' &&
          _vm.vehicle.vehicleSale
        )?_c('div',[_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_vm._v(" Sale Data: "),_c('i',[_c('b',[_vm._v(_vm._s(_vm._f("currency")(_vm.vehicle.vehicleSale.salePrice)))]),_vm._v(" - "),_c('b',[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(_vm.vehicle.vehicleSale.registerDate))+" ")])])])],1),_c('b-row',{staticClass:"mb-1"},[_c('b-col',[_vm._v(" Buyer: "),_c('i',[_c('b',[_vm._v(" "+_vm._s(_vm.vehicle.vehicleSale.user.firstName)+" "+_vm._s(_vm.vehicle.vehicleSale.user.lastName)+" ")])])])],1)],1):(
          (_vm.userIsClient || _vm.userIsBuyer) &&
          _vm.vehicle.status === 'Sold' &&
          _vm.isPurchases
        )?_c('div',[_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_vm._v(" Sale price: "),_c('i',[_c('b',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.vehicle.finalPrice))+" ")])])])],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_vm._v(" Purchase Date: "),_c('i',[_c('b',[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(_vm.purchaseDate))+" ")])])])],1)],1):_vm._e()],1)],1),(_vm.showCRModal)?_c('b-row',[_c('b-col',[_c('b-button',{attrs:{"variant":"success","block":""},on:{"click":function($event){return _vm.openCRPageModal(_vm.vehicle.id)}}},[_c('b-row',[_c('b-col',[_c('img',{staticClass:"img-fluid cr-button-img",attrs:{"src":require("@/assets/img/609_manheim_identity_ca_3000px.png.png"),"alt":"Fluid image"}})]),_c('b-col',{staticClass:"cr-button-text"},[_vm._v(" CR and Full Specs ")])],1)],1)],1)],1):_vm._e(),(
      _vm.vehicle.vinNumber && !_vm.isHome && _vm.$route.name !== 'inventory.vehicles'
    )?_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"text-center"},[(_vm.vehicle.vinNumber.vinNumber)?_c('vue-barcode',{staticClass:"bar-code",staticStyle:{"width":"100%"},attrs:{"value":_vm.vehicle.vinNumber.vinNumber,"options":_vm.barcodeOptions}}):_vm._e(),_c('i',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.vehicle.vinNumber.vinNumber || _vm.vehicle.vinNumber)+" "),_c('b-button',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(
            _vm.vehicle.vinNumber.vinNumber
              ? _vm.vehicle.vinNumber.vinNumber
              : _vm.vehicle.vinNumber
          ),expression:"\n            vehicle.vinNumber.vinNumber\n              ? vehicle.vinNumber.vinNumber\n              : vehicle.vinNumber\n          ",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.copyVinNumber),expression:"copyVinNumber",arg:"success"}],attrs:{"variant":"none"}},[(_vm.vinCopied)?_c('b-icon',{attrs:{"icon":"clipboard-check"}}):_c('b-icon',{attrs:{"icon":"clipboard"}})],1)],1)],1)],1):_vm._e(),_c('b-row',[(
        !_vm.companyIsPending &&
        _vm.vehicle.vehicleCostDetail &&
        _vm.vehicle.vehicleCostDetail.price
      )?_c('b-col',{staticClass:"mt-2 text-center"},[_c('b-row',[_c('b-col',[_c('b',[_vm._v("Selling Price:")]),_c('h1',{staticClass:"selling-h1"},[_c('i',{staticClass:"selling-price"},[_c('strong',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.vehicle.vehicleCostDetail.price))+" ")])])])])],1)],1):_vm._e()],1),(_vm.vehicleAcceptOffer || _vm.vehicle.status === 'Sold')?_c('div',{staticClass:"mt-2"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/img/TOOLATE.png"),"alt":"sold-image"}})]):_c('div',[(_vm.viewOffers)?_c('b-row',{staticClass:"mt-3"},[_c('b-col',{staticClass:"mb-1"},[_c('b-button',{staticClass:"shadow",attrs:{"disabled":_vm.companyIsPending,"variant":"yellow","block":""},on:{"click":function($event){return _vm.showVehicleOffers()}}},[_vm._v(" View Offers ")])],1)],1):(!_vm.isHome && _vm.user)?_c('b-row',{staticClass:"mt-2"},[_c('b-col',[(
            _vm.vehicle.status !== 'Inactive' &&
            (_vm.userIsAdmin || (_vm.userIsAgent && _vm.vehicle.usersId === _vm.user.id))
          )?_c('b-button',{staticClass:"mt-1",attrs:{"variant":"yellow","block":""},on:{"click":function($event){return _vm.editVehicle(_vm.vehicle.vinNumber.vinNumber)}}},[_c('b-icon',{attrs:{"icon":"pencil"}})],1):_vm._e()],1)],1):_vm._e(),(
        !_vm.isHome &&
        !_vm.isActiveOffer &&
        !_vm.isExpiredOffer &&
        (_vm.userHasOffer || _vm.vehicle.lastOffer)
      )?_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('b-button',{attrs:{"variant":"yellow","block":"","size":"sm"},on:{"click":function($event){return _vm.changeOfferTab(_vm.vehicle.id)}}},[_c('h5',{staticClass:"mt-1"},[_vm._v(" Current Offer: "),(_vm.vehicle.lastOffer)?_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.vehicle.lastOffer.offerPrice))+" ")]):(_vm.userHasOffer)?_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.currentOffer))+" ")]):_vm._e()])])],1)],1):_vm._e(),(
        !_vm.isHome &&
        !_vm.userHasOffer &&
        !_vm.vehicle.lastOffer &&
        !_vm.isExpiredOffer &&
        !_vm.isActiveOffer &&
        (_vm.userIsBuyer || _vm.userIsClient)
      )?_c('MakeAnOffer',{attrs:{"vehicle-id":this.vehicle.id,"price":this.vehicle.vehicleCostDetail.price,"user-pending":_vm.companyIsPending},on:{"setUserHasOffer":function($event){return _vm.setUserHasOffer($event)}}}):_vm._e()],1),(_vm.showCRModal)?_c('CRPageModal',{attrs:{"id":this.vehicle.id,"cr-url":this.vehicle.crUrl}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }