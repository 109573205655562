var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-container',{staticClass:"pl-0 pr-0",attrs:{"fluid":""}},[_c('div',{staticClass:"text-left"}),_c('div',{staticClass:"mt-2 mb-2",attrs:{"id":"total-rows"}},[_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"text-white mx-1",attrs:{"variant":"yellow"},on:{"click":function($event){_vm.resetFilters();
             _vm.getVehiclesInfo();}}},[_vm._v(" Clear filters ")])],1)]),_c('div',{staticClass:"table-responsive"},[_c('b-table',{staticClass:"text-break",class:{ 'text-break': _vm.isMobile },attrs:{"show-empty":"","bordered":"","small":"","stacked":"md","head-variant":"light","items":_vm.vehiclesInfo.data.paginate ? _vm.vehiclesInfo.data.paginate.data : [],"fields":_vm.fields,"busy":_vm.vehiclesInfo.status === 'fetching',"sticky-header":""},scopedSlots:_vm._u([{key:"head()",fn:function(scope){return [_c('div',{staticClass:"table-head-menu"},[_c('span',{},[_vm._v(" "+_vm._s(scope.label)+" ")]),_c('br'),_c('div',{staticClass:"text-center"},[(_vm.hasFilter(scope.field.key))?_c('FilterColum',{ref:"filter-component",attrs:{"type":scope.field.key}}):_c('div',[_c('br'),_c('span',{})])],1)])]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"variant":"yellow"}})],1)]},proxy:true},{key:"cell(vinNumber)",fn:function({ item }){return [_c('small',[_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){_vm.goToPublicPage(item.code);
                 _vm.setVehicleAnchor(item.stockNumber);}}},[_vm._v(" "+_vm._s(item.vinNumber)+" ")]),_c('br'),_c('b-button',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(
                 item.vinNumber ? item.vinNumber : item.vinNumber
               ),expression:"\n                 item.vinNumber ? item.vinNumber : item.vinNumber\n               ",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.copyVinNumber),expression:"copyVinNumber",arg:"success"}],staticClass:"p-0",attrs:{"variant":"none"},on:{"click":function($event){_vm.vinCopiedText = item.vinNumber}}},[(_vm.vinCopied && _vm.vinCopiedText == item.vinNumber)?_c('b-icon',{attrs:{"icon":"clipboard-check"}}):_c('b-icon',{attrs:{"icon":"clipboard"}})],1),(_vm.isMobile)?_c('b-button',{staticClass:"p-0",on:{"click":function($event){return _vm.goToVehicleUnitPage(_vm.row.item.id)}}},[_c('b-icon',{attrs:{"icon":"eye"}})],1):_vm._e()],1)]}},{key:"cell(year)",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(item.year)+" ")])]}},{key:"cell(model)",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(item.model)+" ")])]}},{key:"cell(salePrice)",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(_vm._f("currency")(item.salePrice))+" ")])]}},{key:"cell(saleDate)",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.saleDate))+" ")])]}},{key:"cell(reserveDate)",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.reserveDate))+" ")])]}},{key:"cell(reservePrice)",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(_vm._f("currency")(item.reservePrice))+" ")])]}}])})],1),_c('div',[_c('b-pagination',{staticClass:"mb-2",attrs:{"value":_vm.vehiclesInfo.data.paginate
             ? _vm.vehiclesInfo.data.paginate.currentPage
             : 1,"total-rows":_vm.vehiclesInfo.data.paginate ? _vm.vehiclesInfo.data.paginate.total : 0,"per-page":_vm.vehiclesInfo.data.paginate ? _vm.vehiclesInfo.data.paginate.perPage : 25,"align":"end"},on:{"change":function($event){return _vm.changePage($event)}}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }