<template>
  <div>
    <b-list-group v-if="carwashList.status == 'fetching'" class="mt-2">
      <b-list-group-item class="text-center">
        <b-spinner variant="yellow" />
      </b-list-group-item>
    </b-list-group>
    <b-list-group v-else class="mt-2 mb-2 note-list" tag="ul">
      <b-list-group-item
        v-if="carwashList.data && !carwashList.data.length"
        class="text-center"
      >
        <b>No orders to show</b>
      </b-list-group-item>

      <b-list-group-item
        v-for="(carwash, i) in carwashList.data"
        :key="i"
        class="flex-column align-items-start"
        tag="li"
      >
        <CarwashCard
          v-on="$listeners"
          :carwash="carwash"
          :refreshData="getCarwashList"
        />
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CarwashCard from "./CarwashCard.vue";
import { GET_CARWASH_LIST } from "./actions";
export default {
  name: "CarwashTable",
  components: { CarwashCard },
  computed: {
    ...mapState({
      vehicle: (state) => state.superAdminStore.vehicle,
      carwashList: (state) =>
        state.superAdminStore.componentStore.carwash.carwashList,
    }),
  },
  data() {
    return {};
  },
  mounted() {
    this.getCarwashList();
  },
  methods: {
    getCarwashList() {
      this.$store.dispatch(GET_CARWASH_LIST, { id: this.vehicle.id });
    },
  },
};
</script>

<style>
</style>