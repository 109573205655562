<template>
  <div>
    <form @submit.stop.prevent="save()">
      <b-row>
        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="md" label="Initial range (Kms)">
            <b-form-input
              type="text"
              v-model.number="form.initialRange"
              onkeydown="javascript: return event.keyCode == 69 ? false : true"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="md" label="Final range (Kms)">
            <b-form-input type="text" v-model.number="form.endRange">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="sm" label="Price">
            <Money
              v-model="form.price"
              class="form-control text-center"
              v-bind="money"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            type="submit"
            variant="success"
            class="float-right"
            :disabled="
              driverPriceStatus == 'fetching' ||
              form.price == 0 ||
              form.initialRange == null ||
              form.endRange == null
            "
          >
            <!-- loader -->
            <b-spinner
              v-if="driverPriceStatus == 'fetching'"
              class="align-middle"
              variant="white"
            />
            <span v-else> Save </span>
          </b-button>
        </b-col>
      </b-row>
    </form>
  </div>
</template>
<script>
import { Money } from "v-money";
import { POST_DRIVER_PRICE } from "./actions";
import { mapState } from "vuex";
export default {
  components: {
    Money,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
    refreshData: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapState({
      driverPrice: (state) =>
        state.superAdminStore.driverPrice.driverPrice.data,
      driverPriceStatus: (state) =>
        state.superAdminStore.driverPrice.driverPrice.status,
    }),
  },
  data() {
    return {
      form: {
        initialRange: null,
        endRange: null,
        price: 0,
      },
      money: {
        value: 0,
        decimal: ".",
        thousands: ",",
        prefix: "CAD $ ",
        suffix: "",
        precision: 2,
      },
    };
  },

  methods: {
    save() {
      const payload = {
        initialRange: this.form.initialRange,
        endRange: this.form.endRange,
        price: this.form.price,
      };

      this.$store
        .dispatch(POST_DRIVER_PRICE, payload)
        .then(() => {
          this.$root.$bvToast.toast("Information updated successfully", {
            title: "Success",
            variant: "success",
          });
          this.refreshData();
          this.closeModal();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Error updating information", {
            title: "Error",
            variant: "danger",
          });
        });
    },
  },
};
</script>
<style></style>
