<template>
  <b-tabs content-class="">
    <b-tab :title="title" active>
      <b-card>
        <b-row>
          <b-col cols="8">
            <h4>Total: $ {{ totalAddons }}</h4>
          </b-col>
          <b-col cols="4" class="text-right mb-4">
            <b-button variant="success" class="">
              <b-icon icon="plus" class="" />
            </b-button> </b-col
        ></b-row>

        <b-table
          show-empty
          stacked="md"
          :items="addons"
          :fields="fields"
          head-row-variant="success"
        >
        </b-table>
      </b-card>
    </b-tab>
  </b-tabs>
</template>

<script>
export default {
  name: "SimpleVehicleViewAddonsTable",
  props: {
    title: {
      type: String,
      default: "Addons",
    },
    addonsInfo: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: "CAD",
    },
    totalAddons: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      fields: [
        { key: "category", label: "Category" },

        { key: "description", label: "Description" },
        {
          key: "amount",
          label: "Amount",
          class: "text-center",
          formatter: (amount) =>
            typeof amount == "number"
              ? this.$options.filters.currency(amount)
              : "-",
        },
        {
          key: "gst",
          label: "GST/HST",
          class: "text-center",
          formatter: (gst) =>
            typeof gst == "number" ? this.$options.filters.currency(gst) : "-",
        },
        {
          key: "qst",
          label: "QST",
          class: "text-center",
          formatter: (qst) =>
            typeof qst == "number" ? this.$options.filters.currency(qst) : "-",
        },
        {
          key: "total",
          label: "Total",
          class: "text-center",
          formatter: (total) =>
            typeof total == "number"
              ? this.$options.filters.currency(total)
              : "-",
        },
        {
          key: "invoice",
          label: "Invoice #",
          class: "text-center",
        },
      ],
      simpleInfo: [
        {
          description: "Purchase Price",
          amount: "-",
          gst: null,
          qst: null,
          total: null,
          invoice: null,
        },
        {
          description: "Carfax",
          amount: 20,
          gst: 2.6,
          qst: null,
          total: 22.6,
          invoice: null,
        },
        {
          description: "Tires",
          amount: null,
          gst: null,
          qst: null,
          total: null,
          invoice: null,
        },
        {
          description: "Body shop",
          amount: null,
          gst: null,
          qst: null,
          total: null,
          invoice: null,
        },
        {
          description: "Lien check",
          amount: 20.0,
          gst: 1.0,
          qst: null,
          total: 21.0,
          invoice: 209,
        },
        {
          description: "Transport 1",
          amount: null,
          gst: null,
          qst: null,
          total: null,
          invoice: null,
        },
        {
          description: "Transport 2",
          amount: 780.0,
          gst: null,
          qst: null,
          total: 780.0,
          invoice: 6456,
        },
        {
          description: "Misc 1",
          amount: null,
          gst: null,
          qst: null,
          total: null,
          invoice: null,
        },
        {
          description: "Misc 2",
          amount: null,
          gst: null,
          qst: null,
          total: null,
          invoice: null,
        },
        {
          description: "Misc 3",
          amount: null,
          gst: null,
          qst: null,
          total: null,
          invoice: null,
        },
        {
          description: "Misc 4",
          amount: null,
          gst: null,
          qst: null,
          total: null,
          invoice: null,
        },
        {
          description: "Other",
          amount: 104.0,
          gst: null,
          qst: null,
          total: 104.0,
          invoice: null,
        },
        {
          description: "Net Canadian cost",
          amount: 924.0,
          gst: 3.6,
          qst: null,
          total: 927.6,
          invoice: null,
        },
      ],
      addons: [],
    };
  },
  watch: {
    addonsInfo() {
      this.formatItems(this.addonsInfo);
    },
  },
  /*  created() {
    this.formatItems(this.addonsInfo);
  }, */
  methods: {
    formatItems(addons) {
      console.log(addons);
      if (addons.length > 0) {
        addons.forEach((addon) => {
          addon.taxes.forEach((tax) => {
            console.log(tax);
            this.addons.push({
              description: addon.description || null,
              amount: addon["amount" + this.currency],
              gst: tax.tps.includes("GST")
                ? this.calculateTaxAmount(
                    addon["amount" + this.currency],
                    tax.percent
                  )
                : null,
              qst: tax.tps.includes("QST")
                ? this.calculateTaxAmount(
                    addon["amount" + this.currency],
                    tax.percent
                  )
                : null,
              total: addon["totalAmount" + this.currency] || null,

              invoice: tax.invoice,
            });
          });
        });
      }
    },
    calculateTaxAmount(amount, percent) {
      return (amount * percent) / 100;
    },
  },
};
</script>

<style></style>
