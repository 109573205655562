<template>
  <b-dropdown
    ref="filter-dropdown"
    variant="none"
    toggle-class="p-0 text-decoration-none"
    menu-class="menu-min-width p-3  max-width-100"
    no-caret
    boundary="window"
  >
    <template #button-content>
      <b-icon
        class="cursor-pointer"
        icon="filter"
        :variant="hasFilter() ? 'success' : ''"
      ></b-icon>
    </template>

    <b-spinner
      v-if="filtersListStatus == 'fetching'"
      class="align-middle"
      variant="yellow"
      small
    />

    <div v-else style="min-width: 300px" class="p-0">
      <DriverSelect
        v-if="type == 'driverId'"
        :value="filters"
        @input="onChangeFilters"
      />
      <RangeSelectFilter
        v-if="type == 'date'"
        :type="'date'"
        :value="filters"
        @input="onChangeFilters"
      />
    </div>
    <b-dropdown-divider></b-dropdown-divider>
    <div class="text-center">
      <div class="float-right">
        <b-button
          size="sm"
          variant="success"
          class="mx-1"
          @click="applyFilters()"
          >Apply</b-button
        >

        <b-button
          size="sm"
          variant="yellow"
          class="mx-1 text-white"
          @click="clearFilter()"
          >Clear</b-button
        >
      </div>
    </div>
  </b-dropdown>
</template>

<script>
import DriverSelect from "./DriverSelect.vue";
import RangeSelectFilter from "./RangeSelectFilter.vue";
import { mapState } from "vuex";

export default {
  name: "FilterColum",
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  components: {
    DriverSelect,
    RangeSelectFilter,
  },

  data() {
    return {
      filters: [],
    };
  },
  computed: {
    ...mapState({
      filtersStore: (state) =>
        state.superAdminStore.customReports.customReportInfo.filters,
      filtersList: (state) =>
        state.superAdminStore.customReports.filtersList.data,
      filtersListStatus: (state) =>
        state.superAdminStore.customReports.filtersList.status,
    }),
  },
  watch: {
    filtersStore: {
      handler() {
        this.checkIfFilterInStore();
      },
      deep: true,
    },
  },

  methods: {
    hasFilter() {
      if (this.filters.length > 0) {
        return true;
      }
      if (this.filtersStore[this.type] != null) {
        return true;
      }
      return false;
    },
    onChangeFilters(value) {
      this.filters = value;
    },
    applyFilters() {
      this.$emit("applyFilters", {
        [this.type]: this.convertArrayToString(this.filters),
        page: 1,
      });
    },
    convertArrayToString(array) {
      return array.join(",");
    },
    clearFilter() {
      this.filters = [];
      this.$emit("applyFilters", {
        [this.type]: null,
        page: 1,
      });
    },
    checkIfFilterInStore() {
      if (this.filtersStore[this.type] != null) {
        this.filters = this.filtersStore[this.type].split(",");
      } else {
        this.filters = [];
      }
    },
  },
};
</script>

<style scoped>
.menu-min-width {
  min-width: 6rem !important;
}
.hideOverflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}
.max-width-100 {
  max-width: 3rem;
}
</style>
