<template>
  <div>
    <b-navbar variant="yellow" class="v-25 text-yellow mb-2"> asg </b-navbar>
    <div class="container mt-3">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="1" cols="12" />
        <b-col xs="12" sm="12" md="12" lg="12" xl="10" cols="12">
          <b-form class="container mb-3" @submit.stop.prevent="save">
            <b-row class="mb-3">
              <b-col>
                <h2 class="text-purple-yellow">Welcome {{ form.fullName }}</h2>

                <h4 class="text-purple-yellow">Company infomation</h4>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="12" md="12" lg="12" xl="12">
                <b-form-group label-size="md">
                  <template slot="label">
                    Address
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    v-if="isAddressSelected"
                    v-model="address"
                    size="md"
                    name="street"
                    class="mb-3"
                    maxlength="65"
                    @focus="isAddressSelected = false"
                  />
                  <place-autocomplete-field
                    v-else
                    v-model="address"
                    v-place-autofill:street="form.street"
                    v-place-autofill:city="form.cityId"
                    v-place-autofill:state="form.state"
                    v-place-autofill:zipcode="form.zip"
                    name="address"
                    api-key="AIzaSyBWj-z1916MmpcimZW3xPlRGXMikForpS4"
                    placeholder="Enter an an address, zipcode, or location"
                    country="us"
                    @autocomplete-select="selectAddress"
                  />
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="12" xl="12">
                <b-form-group label-size="md">
                  <template slot="label"> Address line 2 </template>
                  <b-form-input
                    name="addressLine2"
                    maxlength="200"
                    :value="form.addressLine2"
                    @change="setValue('addressLine2', $event)"
                  />
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label">
                    Street
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    v-model.trim="$v.form.street.$model"
                    required
                    size="sm"
                    name="street"
                    maxlength="65"
                    :value="form.street"
                    :state="validateState('street')"
                    @change="setValue('street', $event)"
                  />
                  <b-form-invalid-feedback>
                    <div v-if="!$v.form.street.required">
                      This field is required
                    </div>
                    <div v-if="!$v.form.street.maxLength">
                      Character limit reached
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label">
                    ZIP postal code
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    v-model.trim="$v.form.zip.$model"
                    required
                    size="sm"
                    name="zip"
                    maxlength="10"
                    :value="form.zip"
                    :state="validateState('zip')"
                    @change="setValue('zip', $event)"
                  />
                  <b-form-invalid-feedback>
                    <div v-if="!$v.form.zip.required">
                      This field is required
                    </div>
                    <div v-if="!$v.form.zip.maxLength">
                      Character limit reached
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label">
                    State
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    v-model.trim="$v.form.state.$model"
                    disabled
                    required
                    size="sm"
                    name="state"
                    maxlength="250"
                    :value="form.state"
                    :state="validateState('state')"
                    @change="setValue('stateId', $event)"
                  />
                  <b-form-invalid-feedback>
                    <div v-if="!$v.form.state.required">
                      This field is required
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label">
                    City
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    v-model.trim="$v.form.cityId.$model"
                    disabled
                    required
                    size="sm"
                    name="city"
                    maxlength="250"
                    :value="form.cityId"
                    :state="validateState('cityId')"
                    @change="setValue('cityId', $event)"
                  />
                  <b-form-invalid-feedback>
                    <div v-if="!$v.form.cityId.required">
                      This field is required
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label"> Website </template>
                  <b-form-input
                    v-model.trim="form.webSite"
                    size="sm"
                    name="webSite"
                    placeholder="www.yourSite.com"
                    maxlength="65"
                  />
                </b-form-group>
              </b-col>
              <hr />
              <b-col sm="12" md="12" lg="6" xl="6" />
              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md" label="Taxid 1 *">
                  <template slot="label">
                    Taxid 1
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    v-model.trim="$v.form.taxidOne.$model"
                    required
                    size="sm"
                    name="taxidOne"
                    maxlength="50"
                    :state="validateState('taxidOne')"
                    :value="form.taxidOne"
                    @change="setValue('taxidOne', $event)"
                  />
                  <b-form-invalid-feedback>
                    <div v-if="!$v.form.taxidOne.required">
                      This field is required
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md" label="Dealer license *">
                  <template slot="label">
                    Dealer license
                    <span class="text-danger">*</span>
                  </template>

                  <b-form-input
                    v-model.trim="$v.form.dealerLicense.$model"
                    required
                    size="sm"
                    name="dealerLicense"
                    maxlength="50"
                    :value="form.dealerLicense"
                    :state="validateState('dealerLicense')"
                    @change="setValue('dealerLicense', $event)"
                  />
                  <b-form-invalid-feedback>
                    <div v-if="!$v.form.dealerLicense.required">
                      This field is required
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label">
                    Reps Name <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    v-model.trim="$v.form.billingContactName.$model"
                    required
                    size="sm"
                    name="billingContactName"
                    maxlength="100"
                    :value="form.billingContactName"
                    :state="validateState('billingContactName')"
                    @change="setValue('billingContactName', $event)"
                  />
                  <b-form-invalid-feedback>
                    <div v-if="!$v.form.billingContactName.required">
                      This field is required
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12" lg="6" xl="6">
                <b-form-group label-size="md">
                  <template slot="label">
                    Billing contact email
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    v-model.trim="$v.form.billingContactEmail.$model"
                    required
                    size="sm"
                    name="billingContactEmail"
                    maxlength="100"
                    placeholder="example@gmail.com"
                    :formatter="lowerCaseFormatter"
                    :value="form.billingContactEmail"
                    :state="validateState('billingContactEmail')"
                    @change="setValue('billingContactEmail', $event)"
                  />
                  <b-form-invalid-feedback>
                    <div v-if="!$v.form.billingContactEmail.required">
                      This field is required
                    </div>
                    <div v-if="!$v.form.billingContactEmail.email">
                      This must be a valid email
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="12" lg="6" xl="6" class="mb-2">
                <b-row class="mb-2">
                  <b-col>
                    Password
                    <span class="text-danger">*</span>
                  </b-col>
                </b-row>
                <b-input-group>
                  <b-input
                    v-model.trim="$v.form.password.$model"
                    size="sm"
                    :type="showPassword ? 'text' : 'password'"
                    name="password-input"
                    :value="form.password"
                    :state="validateState('password')"
                    @change="setValue('password', $event)"
                  />
                  <b-input-group-append>
                    <b-button
                      v-if="showPassword == false"
                      variant="outline-primary"
                      size="sm"
                      @click="hanldePasswordVisibility('showPassword', true)"
                    >
                      <b-icon icon="eye" class="mt-1" font-scale="0.99" />
                    </b-button>
                    <b-button
                      v-else
                      variant="outline-primary"
                      size="sm"
                      @click="hanldePasswordVisibility('showPassword', false)"
                    >
                      <b-icon icon="eye-slash" class="" font-scale="0.99" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>

                <small
                  :class="{
                    'text-danger': !$v.form.password.required,
                    'text-success': !(
                      !$v.form.password.required & !$v.form.password.$dirty
                    ),
                  }"
                >
                  This field is required

                  <br />
                </small>
                <small
                  :class="{
                    'text-success': minLenght,

                    'text-danger': !minLenght,
                  }"
                >
                  This field must have at least eight characters

                  <br />
                </small>
                <small
                  :class="{
                    'text-danger': !$v.form.password.containsUppercase,
                    'text-success': !(
                      !$v.form.password.containsUppercase &
                      !$v.form.password.$dirty
                    ),
                  }"
                >
                  Must have at least one uppercase
                  <br />
                </small>
                <small
                  :class="{
                    'text-danger': !$v.form.password.containsLowercase,
                    'text-success': !(
                      !$v.form.password.containsLowercase &
                      !$v.form.password.$dirty
                    ),
                  }"
                >
                  Must have at least one lowercase
                  <br />
                </small>
                <small
                  :class="{
                    'text-danger': !$v.form.password.containsNumber,
                    'text-success': !(
                      !$v.form.password.containsNumber &
                      !$v.form.password.$dirty
                    ),
                  }"
                >
                  Must have at least one number
                  <br />
                </small>
                <small
                  :class="{
                    'text-danger': !$v.form.password.containsSpecial,
                    'text-success': !(
                      !$v.form.password.containsSpecial &
                      !$v.form.password.$dirty
                    ),
                  }"
                >
                  Must have at least one special character
                  <br />
                </small>
              </b-col>
              <b-col sm="12" md="12" lg="6" xl="6">
                <b-row class="mb-2">
                  <b-col>
                    Confirmation password
                    <span class="text-danger">*</span>
                  </b-col>
                </b-row>
                <b-input-group label-size="md">
                  <b-input
                    v-model.trim="$v.form.confirmPassword.$model"
                    required
                    size="sm"
                    :type="showConfirmPassword ? 'text' : 'password'"
                    autocomplete="off"
                    name="confirmPassword"
                    :value="form.confirmPassword"
                    :state="validateState('confirmPassword')"
                    @change="setValue('confirmPassword', $event)"
                  />

                  <b-input-group-append>
                    <b-button
                      v-if="showConfirmPassword == false"
                      variant="outline-primary"
                      size="sm"
                      @click="
                        hanldePasswordVisibility('showConfirmPassword', true)
                      "
                    >
                      <b-icon icon="eye" class="mt-1" font-scale="0.99" />
                    </b-button>
                    <b-button
                      v-else
                      variant="outline-primary"
                      size="sm"
                      @click="
                        hanldePasswordVisibility('showConfirmPassword', false)
                      "
                    >
                      <b-icon icon="eye-slash" class="" font-scale="0.99" />
                    </b-button>
                  </b-input-group-append>
                  <b-form-invalid-feedback>
                    <div v-if="!$v.form.password.sameAsPassword">
                      Passwords do not match
                    </div>
                  </b-form-invalid-feedback>
                </b-input-group>
              </b-col>
              <b-col sm="12" md="6" lg="6" xl="6" class="mb-4" />
              <b-col sm="12" md="3" lg="3" xl="3" class="mb-4" />
              <b-col sm="12" md="3" lg="3" xl="3" class="mb-4">
                <b-button
                  class="float-right"
                  variant="success"
                  type="submit"
                  block
                  :disabled="$v.form.$invalid || isLoading"
                >
                  <b-spinner v-if="isLoading" small variant="white" />
                  <span v-else>Save</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="1" cols="12" />
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  sameAs,
  maxLength,
  email,
} from "vuelidate/lib/validators";
import { POST_USER_COMPANY, GET_USER_CLIENT } from "./actions";
import { mapState } from "vuex";

export default {
  data() {
    return {
      isAddressSelected: false,
      address: null,
      form: {
        password: "",
        confirmPassword: "",
        fullName: "",
        street: "",
        zip: "",
        state: "",
        cityId: "",
        webSite: "",
        taxidOne: "",
        dealerLicense: "",
        billingContactName: "",
        billingContactEmail: "",
        companyName: "",
        addressLine2: "",
      },
      states: [],
      cities: [],
      showPassword: false,
      showConfirmPassword: false,
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
        containsUppercase: (value) => /[A-Z]/.test(value),
        containsLowercase: (value) => /[a-z]/.test(value),
        containsNumber: (value) => /[0-9]/.test(value),
        containsSpecial: (value) => /[#?!@$%^&*-]/.test(value),
      },
      confirmPassword: {
        sameAsPassword: sameAs("password"),
      },

      street: {
        required,
        maxLength: maxLength(250),
      },
      zip: {
        required,
        maxLength: maxLength(10),
      },
      state: {
        required,
      },
      cityId: {
        required,
      },
      taxidOne: {
        required,
        maxLength: maxLength(50),
      },

      dealerLicense: {
        required,
      },

      billingContactEmail: {
        required,
        email,
      },
      billingContactName: {
        required,
      },
    },
  },
  computed: {
    minLenght() {
      return this.form.password.length > 7 ? true : false;
    },
    ...mapState({
      user: (state) => state.buyer.buyerStore.user,
    }),
    isLoading() {
      return this.user.status == "fetching" ? true : false;
    },
  },
  beforeMount() {
    this.getBuyer();
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    save() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_USER_COMPANY, payload)
        .then(async () => {
          await this.$root.$bvToast.toast("Company successfully registered", {
            title: "Success",
            variant: "success",
          });

          setTimeout(() => {
            this.goTo();
          }, 6000);
        })
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },

    formatPayload() {
      const payload = {
        registrationToken: this.$route.params.token,
        street: this.form.street,
        zip: this.form.zip,
        stateId: this.form.state,
        webSite: this.form.webSite,
        taxid: this.form.taxidOne,
        dealerLicense: this.form.dealerLicense,
        billingContactName: this.form.billingContactName,
        billingContactEmail: this.form.billingContactEmail,
        city: this.form.cityId,
        password: this.form.password,
        companyName: this.form.companyName,
        addressLine2: this.form.addressLine2,
      };
      return payload;
    },
    getBuyer() {
      this.$store
        .dispatch(GET_USER_CLIENT, {
          registrationToken: this.$route.params.token,
        })
        .then((response) => {
          const user = response.data[0];
          this.form.companyName = user.userDetails.company.companyName;
          this.form.fullName = user.firstName + " " + user.lastName;
        })
        .catch(() => {
          this.$root.$bvToast.toast(
            "This link has expired, please contact us to request a new one by email.",
            {
              title: "Warning",
              variant: "warning",
            }
          );
        });
    },
    selectAddress() {
      this.isAddressSelected = true;
    },
    goTo() {
      this.$router.push({ path: "/" });
      this.$bvModal.show("login-modal");
    },
    hanldePasswordVisibility(input, isShow) {
      input === "showPassword"
        ? (this.showPassword = isShow)
        : (this.showConfirmPassword = isShow);
    },
  },
};
</script>

<style></style>
