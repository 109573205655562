<template>
  <b-list-group v-if="workOrders.status == 'fetching'" class="mt-2">
    <b-list-group-item class="text-center">
      <b-spinner variant="yellow" />
    </b-list-group-item>
  </b-list-group>
  <b-list-group v-else class="mt-2 mb-2 note-list" tag="ul">
    <b-list-group-item
      v-if="workOrders.data && !workOrders.data.length"
      class="text-center"
    >
      <b>No work orders to show</b>
    </b-list-group-item>

    <b-list-group-item
      v-for="(workOrder, i) in workOrders.data"
      :key="i"
      class="flex-column align-items-start"
      tag="li"
    >
      <WorkOrderCard
        :workOrder="workOrder"
        :refreshData="getWorkOrderWithNoProviders"
      />
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import WorkOrderCard from "./WorkOrderCard.vue";
import { mapState } from "vuex";
import { GET_WORK_ORDERS_NO_PROVIDER, GET_WORK_ORDERS } from "./actions";
export default {
  components: { WorkOrderCard },
  computed: {
    ...mapState({
      vehicle: (state) => state.superAdminStore.vehicle,

      categories: (state) => state.vehicle.workOrder.categories.categories,
      providers: (state) => state.vehicle.workOrder.providers.providers,
      workOrders: (state) =>
        state.superAdminStore.componentStore.workOrder.workOrdersNoProvider,
    }),
  },
  mounted() {
    this.getWorkOrderWithNoProviders();
  },
  methods: {
    getWorkOrderWithNoProviders() {
      this.$store
        .dispatch(GET_WORK_ORDERS_NO_PROVIDER, { vehicleId: this.vehicle.id })
        .then(() => {});

      this.$store
        .dispatch(GET_WORK_ORDERS, { vehicleId: this.vehicle.id })
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
  },
};
</script>

<style>
</style>