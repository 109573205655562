export const GET_VEHICLES_BULK_TRANSFER = "GET_VEHICLES_BULK_TRANSFER";
export const GET_VEHICLES_BULK_TRANSFER_SUCCESS = "GET_VEHICLES_BULK_TRANSFER_SUCCESS";
export const GET_VEHICLES_BULK_TRANSFER_ERROR = "GET_VEHICLES_BULK_TRANSFER_ERROR";

export const POST_BULK_TRANSFER = "POST_BULK_TRANSFER";
export const POST_BULK_TRANSFER_SUCCESS = "POST_BULK_TRANSFER_SUCCESS";
export const POST_BULK_TRANSFER_ERROR = "POST_BULK_TRANSFER_ERROR";

export const POST_QUICK_BULK_TRANSFER = "POST_QUICK_BULK_TRANSFER";
export const POST_QUICK_BULK_TRANSFER_SUCCESS = "POST_QUICK_BULK_TRANSFER_SUCCESS";
export const POST_QUICK_BULK_TRANSFER_ERROR = "POST_QUICK_BULK_TRANSFER_ERROR";

export const POST_BULK_TRANSFER_XLS = "POST_BULK_TRANSFER_XLS"
export const POST_BULK_TRANSFER_XLS_SUCCESS = "POST_BULK_TRANSFER_XLS_SUCCESS"
export const POST_BULK_TRANSFER_XLS_ERROR = "POST_BULK_TRANSFER_XLS_ERROR"