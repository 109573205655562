<template>
  <div class="container-fluid">
    <b-row>
      <b-col>
        <h4 class="ml-3">
          Pending
        </h4>
      </b-col>
    </b-row>
    <div class="pt-4">
      <TableBase
        :items="data"
        :fields="fields"
        :options="options"
        :busy="users.status === 'fetching' ? true : false"
      />
    </div>

    <b-modal
      id="detailsModal"
      ref="detailsModal"
      size="lg"
      hide-header
      body-class="pl-0 pr-0"
      ok-disabled
    >
      <Brand />
      <UserDetails :user-item="userItem" />
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="danger"
            size="sm"
            class="float-right"
            @click="$bvModal.hide('detailsModal')"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import TableBase from '@/modules/shared/table.vue';
import UserDetails from '@/modules/buyer/Details.vue';
import Brand from '@/modules/shared/brand.vue';
import { GET_USERS_COMPANY } from './actions';
import { mapState } from 'vuex';

import { generalMixin } from '@/modules/mixin.js';
export default {
  components: { TableBase, UserDetails, Brand },
  mixins: [ generalMixin ],
  computed: {
    ...mapState({
      users: (state) => state.adminStore.users.users
    })
  },
  data() {
    return {
      data: [],
      fields: [
        { key: 'user.firstName', label: 'First name' },
        { key: 'user.lastName', label: 'Last name' },
        { key: 'user.email', label: 'Email' },
        { key: 'user.userDetails.mobileNumber', label: 'Phone number' },
        {
          key: 'actions',
          label: 'Actions',
          note: false,
          details: true,
          validation: true
        }
      ],
      options: {
        assign: false,
        rolFilter: false,
        add: false,
        fun: this.getData
      },
      userItem: {}
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(filter = '') {
      const payload = {
        search: filter,
        type: 'pending'
      };
      this.$store
        .dispatch(GET_USERS_COMPANY, payload)
        .then((response) => {
          this.data = response;
        })
        .catch(() => {
          this.$root.$bvToast.toast('Warning', {
            title: 'Warning',
            variant: 'warning'
          });
        });
    },
    editItem(item) {
      this.userItem = item;
      this.$bvModal.show('detailsModal');
    },
    changePage(page, filter = '') {
      const payload = {
        search: filter,
        type: 'registry',
        page: page
      };
      this.$store
        .dispatch(GET_USERS_COMPANY, payload)
        .then((response) => {
          this.data = response;
        })
        .catch(() => {
          this.$root.$bvToast.toast('error', {
            title: 'Error',
            variant: 'warning'
          });
        });
    }
  }
};
</script>
