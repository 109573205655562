<template>
  <div>
    <!--     <VehicleDetails
      v-if="vehicle"
      :vehicle="vehicle"
      :color="vehicle.vehicleInformation.color || '----'"
    /> -->
    <h2>Note</h2>
    <NoteRegistrationForm
      :vehicle="vehicle"
      :refreshData="getPoliceBookNotes"
    />
    <b-list-group v-if="notes.status == 'fetching'" class="mt-2">
      <b-list-group-item class="text-center">
        <b-spinner variant="yellow" />
      </b-list-group-item>
    </b-list-group>
    <b-list-group v-else class="mt-2 mb-2 note-list" tag="ul">
      <b-list-group-item v-if="notes && !notes.length" class="text-center">
        <b>No notes to show</b>
      </b-list-group-item>

      <b-list-group-item
        v-for="(note, i) in notes"
        :key="i"
        class="flex-column align-items-start"
        tag="li"
      >
        <NoteCard :note="note" :refreshData="getPoliceBookNotes" />
      </b-list-group-item>
    </b-list-group>

    <!-- <template v-slot:modal-footer="{ close }">
      <b-pagination
        :value="notes.data.currentPage"
        :total-rows="notes.data.total"
        :per-page="notes.data.perPage"
        class="mt-2"
        @change="changePage($event)"
      />
      <div class="justify-content-end">
        <b-button
          variant="outline-secondary"
          class="mr-2"
          @click="() => close()"
        >
          Cancel
        </b-button>
      </div>
    </template> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { GET_POLICE_BOOK_NOTES } from "./actions";
import { superAdminMixin } from "@/modules/superAdmin/mixins.js";
import NoteRegistrationForm from "./RegistrationForm";
import NoteCard from "./NoteCard.vue";
export default {
  name: "SoldNotesModal",
  components: { NoteRegistrationForm, NoteCard },
  props: {
    notes: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [superAdminMixin],
  computed: {
    ...mapState({
      vehicle: (state) => state.superAdminStore.vehicle,
    }),
  },
  mounted() {
    this.getPoliceBookNotes();
  },
  methods: {
    changePage(page) {
      this.$store.commit(GET_POLICE_BOOK_NOTES, {
        fields: { page },
      });
      this.getNotes();
    },
    getPoliceBookNotes() {
      this.$store.dispatch(GET_POLICE_BOOK_NOTES, {
        id: this.vehicle.id,
      });
    },
  },
};
</script>

<style scoped>
.resize-none {
  resize: none;
}
.note-list {
  max-height: 500px;
  overflow-y: auto;
}
</style>
