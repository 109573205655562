import * as constants from './actions';
import axios from '@/plugins/axios.js';
import store from '@/store';
function getDefaultState() {
  return {
    acceptOffer: {
      status: null,
      data: null,
      error: null
    },
    activeOffers: {
      status: null,
      data: null,
      error: null
    },
    checkHigherOffer: {
      status: null,
      data: null,
      error: null
    },
    counterOffer: {
      status: null,
      data: null,
      error: null
    },
    expiredOffers: {
      status: null,
      data: null,
      error: null
    },
    offers: {
      status: null,
      data: [],
      error: null
    },
    offerChain: {
      status: null,
      data: [],
      error: null
    },
    purchases: {
      status: null,
      data: null,
      error: null
    },
    offersSideBar: {
      show: false
    },
    offerSideBar: {
      show: false,
      data: null
    },
    vehiclesSold: {
      vehicles: []
    },
    vehiclesWithOffers: {
      status: null,
      data: [],
      error: null
    },
    offersByVehicle: {
      status: null,
      data: [],
      error: null
    }
  };
}

const actions = {
  [constants.GET_VEHICLES_OFFERS]: async ({ commit }, sendFilters = null) => {
    try {
      commit(constants.GET_VEHICLES_OFFERS);
      const filters = sendFilters
        ? Object.entries(
          store.state.adminStore.vehicles.listStore.vehicles.filters
        ).reduce(
          (a, [ k, v ]) => (v === null || v === '' ? a : { ...a, [k]: v }),
          {}
        )
        : {};
      const response = await axios.get('vehicle/getVehiclesOffers', {
        params: { status: 1, ...filters }
      });
      commit(constants.GET_VEHICLES_OFFERS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLES_OFFERS_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_VEHICLES_WITH_OFFERS]: async (
    { commit },
    sendFilters = null
  ) => {
    try {
      commit(constants.GET_VEHICLES_WITH_OFFERS);
      const filters = sendFilters
        ? Object.entries(
          store.state.adminStore.vehicles.listStore.vehicles.filters
        ).reduce(
          (a, [ k, v ]) => (v === null || v === '' ? a : { ...a, [k]: v }),
          {}
        )
        : {};
      const response = await axios.get('vehicle/getVehiclesOffers', {
        params: { status: 1, ...filters }
      });
      commit(constants.GET_VEHICLES_WITH_OFFERS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLES_WITH_OFFERS_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_OFFERS_BY_VEHICLE]: async ({ commit }, vehicleId) => {
    try {
      const response = await axios.get('vehicle/getOffersByUser', {
        params: { status: 1, vehicleId }
      });
      commit(constants.GET_OFFERS_BY_VEHICLE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_OFFERS_BY_VEHICLE_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_VEHICLES_OFFER_CHAIN]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_VEHICLES_OFFER_CHAIN);
      const response = await axios.post('vehicle/offerChain', payload);
      commit(constants.POST_VEHICLES_OFFER_CHAIN_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_VEHICLES_OFFER_CHAIN_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_VEHICLES_OFFER_CHAIN]: async ({ commit }, payload) => {
    try {
      const response = await axios.get('vehicle/getOfferHistory', {
        params: payload
      });
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLES_OFFER_CHAIN_ERROR);
      return error.response;
    }
  },
  [constants.GET_CHECK_HIGHER_OFFER]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_CHECK_HIGHER_OFFER);
      const response = await axios.post('vehicle/validateOtherOffer', payload);
      commit(constants.GET_CHECK_HIGHER_OFFER_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_CHECK_HIGHER_OFFER_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_ACCEPT_COUNTER_OFFER]: async ({ commit }, data) => {
    try {
      commit(constants.POST_ACCEPT_COUNTER_OFFER);
      const response = await axios.post('vehicle/acceptOffer', data);
      commit(constants.POST_ACCEPT_COUNTER_OFFER_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_ACCEPT_COUNTER_OFFER_ERROR, error);
      throw error;
    }
  },
  [constants.GET_OFFERS_BY_USER]: async ({ commit }, data) => {
    let action = data.action;
    try {
      commit(constants[action]);

      const filters = Object.entries(
        store.state.adminStore.vehicles.listStore.vehicles.filters
      ).reduce(
        (a, [ k, v ]) => (v === null || v === '' ? a : { ...a, [k]: v }),
        {}
      );

      let status = data.status;
      const response = await axios.get('vehicle/getVehiclesOffers', {
        params: { status, ...filters }
      });
      commit(constants[action + '_SUCCESS'], response);
      return response;
    } catch (error) {
      commit(constants[action + '_ERROR'], error);
      throw error.response;
    }
  },
  [constants.GET_PURCHASES]: async ({ commit }) => {
    try {
      commit(constants.GET_PURCHASES);
      const filters = Object.entries(
        store.state.adminStore.vehicles.listStore.vehicles.filters
      ).reduce(
        (a, [ k, v ]) => (v === null || v === '' ? a : { ...a, [k]: v }),
        {}
      );
      let response = await axios.get('vehiclesSales', { params: filters });
      commit(constants.GET_PURCHASES_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_PURCHASES_ERROR, error);
      throw error.response;
    }
  }
};

const mutations = {
  [constants.GET_VEHICLES_OFFERS]: (state) => {
    state.offers.status = 'fetching';
    state.offers.error = null;
  },
  [constants.GET_VEHICLES_OFFERS_SUCCESS]: (state, data) => {
    state.offers.status = 'success';
    state.offers.data = data;
    state.offers.error = null;
  },
  [constants.GET_VEHICLES_OFFERS_ERROR]: (state, error) => {
    state.offers.status = 'error';
    state.offers.error = error;
  },
  [constants.GET_VEHICLES_WITH_OFFERS]: (state) => {
    state.vehiclesWithOffers.status = 'fetching';
    state.vehiclesWithOffers.error = null;
  },
  [constants.GET_VEHICLES_WITH_OFFERS_SUCCESS]: (state, data) => {
    state.vehiclesWithOffers.status = 'success';
    state.vehiclesWithOffers.data = data;
    state.vehiclesWithOffers.error = null;
  },
  [constants.GET_VEHICLES_WITH_OFFERS_ERROR]: (state, error) => {
    state.vehiclesWithOffers.status = 'error';
    state.vehiclesWithOffers.error = error;
  },
  [constants.CLEAR_VEHICLES_OFFERS]: (state) => {
    state.offers.status = null;
    state.offers.data = [];
    state.offers.error = null;
  },
  [constants.GET_OFFERS_BY_VEHICLE]: (state) => {
    state.offersByVehicle.status = 'fetching';
    state.offersByVehicle.error = null;
  },
  [constants.GET_OFFERS_BY_VEHICLE_SUCCESS]: (state, data) => {
    state.offersByVehicle.status = 'success';
    state.offersByVehicle.data = data;
    state.offersByVehicle.error = null;
  },
  [constants.GET_OFFERS_BY_VEHICLE_ERROR]: (state, error) => {
    state.offersByVehicle.status = 'error';
    state.offersByVehicle.error = error;
  },
  [constants.POST_VEHICLES_OFFER_CHAIN]: (state) => {
    state.offerChain.status = 'fetching';
    state.offerChain.error = null;
  },
  [constants.POST_VEHICLES_OFFER_CHAIN_SUCCESS]: (state, data) => {
    state.offerChain.status = 'success';
    state.offerChain.data = data;
    state.offerChain.error = null;
  },
  [constants.POST_VEHICLES_OFFER_CHAIN_ERROR]: (state, error) => {
    state.offerChain.status = 'error';
    state.offerChain.error = error;
  },
  [constants.GET_VEHICLES_OFFER_CHAIN_ERROR]: (state, error) => {
    state.offerChain.status = 'error';
    state.offerChain.error = error;
  },
  [constants.GET_CHECK_HIGHER_OFFER]: (state) => {
    state.checkHigherOffer.status = 'fetching';
    state.checkHigherOffer.error = null;
  },
  [constants.GET_CHECK_HIGHER_OFFER_SUCCESS]: (state, data) => {
    state.checkHigherOffer.status = 'success';
    state.checkHigherOffer.data = data;
    state.checkHigherOffer.error = null;
  },
  [constants.GET_CHECK_HIGHER_OFFER_ERROR]: (state, error) => {
    state.checkHigherOffer.status = 'error';
    state.checkHigherOffer.error = error;
  },
  [constants.POST_ACCEPT_COUNTER_OFFER]: (state) => {
    state.acceptOffer.status = 'fetching';
    state.acceptOffer.error = null;
  },
  [constants.POST_ACCEPT_COUNTER_OFFER_SUCCESS]: (state, data) => {
    state.acceptOffer.status = 'success';
    state.acceptOffer.data = data;
    state.acceptOffer.status = null;
  },
  [constants.POST_ACCEPT_COUNTER_OFFER_ERROR]: (state, error) => {
    state.acceptOffer.status = 'error';
    state.acceptOffer.error = error;
  },
  [constants.GET_ACTIVE_OFFERS]: (state) => {
    state.activeOffers.status = 'fetching';
    state.activeOffers.error = null;
  },
  [constants.GET_ACTIVE_OFFERS_SUCCESS]: (state, data) => {
    state.activeOffers.status = 'success';
    state.activeOffers.data = data;
    state.activeOffers.error = null;
  },
  [constants.GET_ACTIVE_OFFERS_ERROR]: (state, error) => {
    state.activeOffers.status = 'error';
    state.activeOffers.error = error;
  },
  [constants.GET_EXPIRED_OFFERS]: (state) => {
    state.expiredOffers.status = 'fetching';
    state.expiredOffers.error = null;
  },
  [constants.GET_EXPIRED_OFFERS_SUCCESS]: (state, data) => {
    state.expiredOffers.status = 'success';
    state.expiredOffers.data = data;
    state.expiredOffers.error = null;
  },
  [constants.GET_EXPIRED_OFFERS_ERROR]: (state, error) => {
    state.expiredOffers.status = 'error';
    state.expiredOffers.error = error;
  },
  [constants.GET_PURCHASES]: (state) => {
    state.purchases.status = 'fetching';
    state.purchases.error = null;
  },
  [constants.GET_PURCHASES_SUCCESS]: (state, data) => {
    state.purchases.status = 'success';
    state.purchases.data = data;
    state.purchases.error = null;
  },
  [constants.GET_PURCHASES_ERROR]: (state, error) => {
    state.purchases.status = 'error';
    state.purchases.error = error;
  },
  [constants.SET_OFFER_SIDEBAR]: (state, payload) => {
    state.offerSideBar.data = payload;
  },
  [constants.SHOW_OFFERS_SIDEBAR]: (state, status) => {
    state.offersSideBar.show = status;
  },
  [constants.SHOW_OFFER_SIDEBAR]: (state, status) => {
    state.offerSideBar.show = status;
  },
  [constants.SET_VEHICLE_SOLD]: (state, vehicleId) => {
    state.vehiclesSold.vehicles.push({ vehicleId });
  }
};

export default {
  state: getDefaultState(),
  actions,
  mutations
};
