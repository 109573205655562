<template>
  <div>
    <div class="container-fluid">
      <!--    <b-row class="mt-3">
        <b-col>
          <h4 class="ml-3">Marketplaces</h4>
        </b-col>
      </b-row> -->
      <div class="">
        <TableBase
          :items="marketplaces.data"
          :fields="fields"
          :options="options"
          :busy="marketplaces.status === 'fetching' ? true : false"
        >
          <template v-slot:rightSide>
            <!--   <div>
              <b-button
                variant="success"
                class="align-bottom"
                @click="showModal()"
              >
                <b-icon icon="plus" />
              </b-button>
            </div> -->
          </template>
        </TableBase>
      </div>

      <b-modal
        id="detailsModal"
        ref="detailsModal"
        size="lg"
        hide-footer
        body-class="pl-0 pr-0"
        ok-disabled
        @hidden="marketplaceItem = {}"
      >
        <MarketplaceRegistrationForm
          :marketplaceItem="marketplaceItem"
          :refreshData="getData"
          :hideModal="hideModal"
        />
      </b-modal>
    </div>
  </div>
</template>
<script>
import TableBase from "@/modules/shared/table.vue";
import MarketplaceRegistrationForm from "./MarketplaceRegistrationForm.vue";

import { GET_MARKETPLACE, POST_MARKETPLACE } from "./actions";
import { mapState } from "vuex";

import { generalMixin } from "@/modules/mixin.js";
export default {
  components: { TableBase, MarketplaceRegistrationForm },
  mixins: [generalMixin],
  computed: {
    ...mapState({
      marketplaces: (state) => state.masterUser.marketplace.marketplaces,
    }),

    fields() {
      return [
        { key: "name", label: "Name" },
        { key: "webSite", label: "Website" },
        {
          key: "actions",
          label: "Actions",
          note: false,
          details: true,
          validation: false,
          delete: true,
        },
      ];
    },
  },
  data() {
    return {
      options: {
        assign: false,
        rolFilter: false,
        add: false,
        fun: this.getData,
      },
      marketplaceItem: {},
    };
  },
  mounted() {
    this.$store.commit("setAddFunction", this.showModal);
    this.getData();
  },
  methods: {
    getData(filter = "") {
      this.$store
        .dispatch(GET_MARKETPLACE, { search: filter })
        .then(() => {})
        .catch(() => {});
    },
    async deleteItem(item) {
      const confirm = await this.showConfirmDeleteBox();
      if (!confirm) return;
      const payload = { ...item };
      payload.status = 0;
      this.$store
        .dispatch(POST_MARKETPLACE, payload)
        .then(() => {
          this.getData();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Marketplace could not be deleted", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    activateItem(item) {
      const payload = { ...item };
      payload.status = 1;
      this.$store
        .dispatch(POST_MARKETPLACE, payload)
        .then(() => {
          this.makeToast(
            "Success",
            "Marketplace updated successfully",
            "success"
          );
          this.getData();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Marketplace could not be activated", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    showModal() {
      this.$bvModal.show("detailsModal");
    },
    editItem(item) {
      this.marketplaceItem = item;
      this.$bvModal.show("detailsModal");
    },
    changePage(page, filter = "") {
      const payload = {
        roleName: "ROLE_AGENT",
        search: filter,
        page: page,
      };
      this.$store
        .dispatch(GET_MARKETPLACE, payload)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },

    hideModal() {
      this.$bvModal.hide("detailsModal");
    },
  },
};
</script>
