<template>
  <div>
    <b-dropdown
      variant="white"
      block
      menu-class="w-100 mb-0"
      class="w-100 mb-0 pb-0 rounded"
      :disabled="disabled"
      size="md"
    >
      <template slot="button-content">
        <div v-if="modelValue" class="float-left">
          <span>
            <b-img
              v-if="modelValue.logo"
              :src="modelValue.logo"
              height="20"
              width="40"
              style="max-width: 100px"
            />
          </span>

          {{ modelValue.name }}
        </div>
      </template>
      <b-dropdown-item
        v-for="(option, key) in options"
        :key="key"
        @click="onMarketplaceSelect(option)"
      >
        <span>
          <b-img
            :src="option.logo"
            height="35"
            width="55"
            style="max-width: 130px"
          />
        </span>
        {{ option.name }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: "MarketplaceSelect",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Object,
      default: () => null,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selected: { ...this.modelValue },
    };
  },
  methods: {
    onMarketplaceSelect(marketplace) {
      this.$emit("onMarketplaceSelect", marketplace);
    },
  },
};
</script>

<style></style>
