<template>
  <b-card body-class="" class="mb-1">
    <!--     {{ vehicle.title }}
 -->
    {{ vehicle.stockNumber }} - {{ vehicle.vinNumber }} <br />
    {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
    {{ vehicle.trim }} <br />
    {{ vehicle.color }}
  </b-card>
</template>

<script>
export default {
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
  },
  name: "VehicleCard",
};
</script>

<style>
</style>