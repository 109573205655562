<template>
  <div class="container">
    <b-form class="mb-3" @submit.stop.prevent="form.id ? update() : save()">
      <b-row class="">
        <b-col sm="12" md="12" lg="12" xl="12">
          <b-form-group label-size="md">
            <template slot="label"> Company name </template>
            <b-form-input
              v-model.trim="$v.form.companyName.$model"
              size="sm"
              name="companyName"
              maxlength="100"
              :value="form.companyName"
              :state="validateState('companyName')"
              @change="setValue('companyName', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="12" lg="12" xl="12">
          <b-form-group label-size="md">
            <template slot="label"> Address </template>
            <b-form-input
              v-if="isAddressSelected"
              v-model="address"
              size="md"
              name="street"
              class="mb-3"
              maxlength="65"
              @focus="isAddressSelected = false"
            />
            <place-autocomplete-field
              v-else
              v-model="address"
              v-place-autofill:street="form.street"
              v-place-autofill:city="form.city"
              v-place-autofill:state="form.state"
              v-place-autofill:zipcode="form.zip"
              name="address"
              api-key="AIzaSyBWj-z1916MmpcimZW3xPlRGXMikForpS4"
              placeholder="Enter an an address, zipcode, or location"
              country="us"
              @autocomplete-select="selectAddress"
            />
          </b-form-group>
        </b-col>

        <b-col sm="12" md="12" lg="12" xl="12">
          <b-form-group label-size="md">
            <template slot="label"> Address line 2 </template>
            <b-form-input
              name="addressLine2"
              maxlength="200"
              :value="form.addressLine2"
              @change="setValue('addressLine2', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="6" xl="6">
          <!-- Mobile phone -->
          <b-form-group label-size="md" label="Mobile phone">
            <template slot="label"> Contact mobile phone </template>
            <vue-tel-input
              v-model.trim="form.officePhoneNumber"
              default-country="us"
              :input-options="options"
              :dropdown-options="telDdOptions"
              :auto-format="true"
              :valid-characters-only="true"
              :value="form.officePhoneNumber"
              mode="international"
              style-classes="z-index-2"
            />
            <b-form-invalid-feedback id="input-mobile-phone-live-feedback">
              This is a numeric field
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="md">
            <template slot="label"> Email </template>
            <b-form-input
              v-model.trim="$v.form.email.$model"
              size="sm"
              name="email"
              maxlength="100"
              placeholder="example@gmail.com"
              :formatter="lowerCaseFormatter"
              :value="form.email"
              :state="validateState('email')"
              @change="setValue('email', $event)"
            />
          </b-form-group>
        </b-col>

        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="md">
            <template slot="label"> Secondary email </template>
            <b-form-input
              v-model.trim="$v.form.secondaryEmail.$model"
              size="sm"
              name="email"
              maxlength="100"
              placeholder="example@gmail.com"
              :formatter="lowerCaseFormatter"
              :value="form.secondaryEmail"
              :state="validateState('secondaryEmail')"
              @change="setValue('secondaryEmail', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="md">
            <template slot="label"> Id </template>
            <b-form-input
              v-model.trim="$v.form.companyId.$model"
              size="sm"
              name="companyId"
              maxlength="65"
              :value="form.companyId"
              :state="validateState('companyId')"
              @change="setValue('companyId', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="md">
            <template slot="label"> Street </template>
            <b-form-input
              v-model.trim="$v.form.street.$model"
              size="sm"
              name="street"
              maxlength="65"
              :value="form.street"
              :state="validateState('street')"
              @change="setValue('street', $event)"
            />
          </b-form-group>
        </b-col>

        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="md">
            <template slot="label"> Postal code </template>
            <b-form-input
              v-model.trim="$v.form.zip.$model"
              size="sm"
              name="zip"
              maxlength="10"
              :value="form.zip"
              :state="validateState('zip')"
              @change="setValue('zip', $event)"
            />
          </b-form-group>
        </b-col>

        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="md">
            <template slot="label"> Province </template>
            <b-form-input
              v-model.trim="$v.form.state.$model"
              disabled
              size="sm"
              name="state"
              maxlength="250"
              :value="form.state"
              :state="validateState('state')"
              @change="setValue('stateId', $event)"
            />
          </b-form-group>
        </b-col>

        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="md">
            <template slot="label"> City </template>
            <b-form-input
              v-model.trim="$v.form.city.$model"
              disabled
              size="sm"
              name="city"
              maxlength="250"
              :value="form.city"
              :state="validateState('city')"
              @change="setValue('city', $event)"
            />
          </b-form-group>
        </b-col>

        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="md">
            <template slot="label"> Website </template>
            <b-form-input
              v-model.trim="form.webSite"
              size="sm"
              name="webSite"
              placeholder="www.yourSite.com"
              maxlength="65"
            />
          </b-form-group>
        </b-col>

        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="md">
            <template slot="label"> Contact name </template>
            <b-form-input
              v-model.trim="$v.form.billingContactName.$model"
              size="sm"
              name="billingContactName"
              maxlength="100"
              :value="form.billingContactName"
              :state="validateState('billingContactName')"
              @change="setValue('billingContactName', $event)"
            />
          </b-form-group>
        </b-col>

        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="md">
            <template slot="label"> Contact email </template>
            <b-form-input
              v-model.trim="$v.form.billingContactEmail.$model"
              size="sm"
              name="billingContactEmail"
              maxlength="100"
              placeholder="example@gmail.com"
              :formatter="lowerCaseFormatter"
              :value="form.billingContactEmail"
              :state="validateState('billingContactEmail')"
              @change="setValue('billingContactEmail', $event)"
            />
          </b-form-group>
        </b-col>

        <b-col sm="12" md="6" lg="6" xl="6">
          <!-- Mobile phone -->
          <b-form-group label-size="md" label="Mobile phone">
            <template slot="label"> Contact mobile phone </template>
            <vue-tel-input
              v-model.trim="form.billingContactMobilePhone"
              default-country="us"
              :input-options="options"
              :dropdown-options="telDdOptions"
              :auto-format="true"
              :valid-characters-only="true"
              :value="form.billingContactMobilePhone"
              mode="international"
              style-classes="z-index-2"
            />
            <b-form-invalid-feedback id="input-mobile-phone-live-feedback">
              This is a numeric field
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group label-size="md" label="Categories">
            <multiselect
              v-model="form.categories"
              :options="categories"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Pick some option"
              label="name"
              :preselect-first="false"
              track-by="name"
            >
              <template slot="selection" slot-scope="{ values, isOpen }"
                ><span class="multiselect__single" v-if="values.length && !isOpen"
                  >{{ values.length }} options selected</span
                ></template
              >
            </multiselect></b-form-group
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="6" lg="6" xl="6" class="mb-4" />
        <b-col sm="12" md="3" lg="3" xl="3" class="mb-4" />
        <b-col sm="12" md="3" lg="3" xl="3" class="mb-4">
          <b-button
            v-if="userHasPermission('providers', 'write')"
            class="float-right"
            variant="success"
            type="submit"
            block
            :disabled="isLoading"
          >
            <b-spinner v-if="isLoading" small variant="white" />
            <span v-else>Save</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { email } from "vuelidate/lib/validators";
import { POST_PROVIDER } from "./actions";
import { mapState } from "vuex";
import { superAdminMixin } from "../../../../superAdmin/mixins";
import Multiselect from "vue-multiselect";

export default {
  props: ["providerItem", "refreshData", "hideModal"],
  mixins: [superAdminMixin],
  components: {
    Multiselect,
  },
  data() {
    return {
      isAddressSelected: false,
      address: null,
      form: {
        street: "",
        zip: "",
        state: "",
        city: "",
        webSite: "",
        email: "",
        billingContactName: "",
        billingContactEmail: "",
        companyName: "",
        addressLine2: "",
        officePhoneNumber: null,
        billingContactMobilePhone: null,
        secondaryEmail: null,
      },
      states: [],
      cities: [],
      options: {
        type: "tel",
        validCharactersOnly: true,
        maxlength: 14,
      },
      telDdOptions: {
        showDialCodeInList: true,
        showFlags: true,
        showDialCodeInSelection: false,
      },
      categories: [],
    };
  },
  validations: {
    form: {
      street: {},
      zip: {},
      state: {},
      city: {},

      billingContactEmail: {
        email,
      },
      billingContactName: {},

      companyName: {},
      email: {
        email,
      },
      billingContactMobilePhone: {},
      officePhoneNumber: {},
      secondaryEmail: {},
      companyId: {},
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.buyer.buyerStore.user,
    }),
    isLoading() {
      return this.user.status == "fetching" ? true : false;
    },
  },
  mounted() {
    this.getCategories();
    if (this.providerItem.id) {
      this.formatForm();
    }
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    save() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_PROVIDER, payload)
        .then(() => {
          this.hideModal();
          this.refreshData();
          this.$root.$bvToast.toast("Provider created successfully", {
            title: "Success",
            variant: "success",
          });
        })
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    update() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_PROVIDER, payload)
        .then(() => {
          this.hideModal();
          this.refreshData();
          this.$root.$bvToast.toast("Provider updated successfully", {
            title: "Success",
            variant: "success",
          });
        })
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },

    formatPayload() {
      const payload = {
        id: this.providerItem.id ? this.providerItem.id : null,
        street: this.form.street,
        zip: this.form.zip,
        state: this.form.state,
        webSite: this.form.webSite,
        billingContactName: this.form.billingContactName,
        billingContactEmail: this.form.billingContactEmail,
        city: this.form.city,
        companyName: this.form.companyName,
        addressLine2: this.form.addressLine2,
        officePhoneNumber: this.form.officePhoneNumber,
        billingContactMobilePhone: this.form.billingContactMobilePhone,
        email: this.form.email,
        secondaryEmail: this.form.secondaryEmail,
        companyId: this.form.companyId,
        categories: this.form.categories,
      };
      return payload;
    },
    formatForm() {
      this.form = { ...this.providerItem };
    },
    selectAddress() {
      this.isAddressSelected = true;
    },
    goTo() {
      this.$router.push({ path: "/" });
      this.$bvModal.show("login-modal");
    },
    getCategories() {
      this.$store.dispatch("GET_ADDON_TYPE_LIST").then((response) => {
        this.categories = response.data;
      });
    },
  },
};
</script>

<style scoped></style>
