import Service from "./service";
import store from "@/store";
import urlParams from "url-params";

export default {
  getVinByNumber(stock) {
    return Service.get(
      `/${store.state.general.endPointUrl}/findVehicle?vinNumber=${stock}`
    );
  },
  getVinById(id) {
    return Service.get(
      `/${store.state.general.endPointUrl}/findVehicle?id=${id}`
    );
  },
  postCar(vehicle) {
    return Service.post("/vehicle", { ...vehicle });
  },
  putCar(id, vehicle) {
    const data = new FormData();
    data.set("id", id);
    data.set("vehicle", JSON.stringify(vehicle));
    // Interior check values
    if (vehicle?.vehicleInterior?.board)
      data.append("board", vehicle.vehicleInterior.board);
    if (vehicle?.vehicleInterior?.seating)
      data.append("seating", vehicle.vehicleInterior.seating);
    if (vehicle?.vehicleInterior?.backSeats)
      data.append("backSeats", vehicle.vehicleInterior.backSeats);
    if (vehicle?.vehicleInterior?.trunk)
      data.append("trunk", vehicle.vehicleInterior.trunk);

    // Exterior check values
    if (vehicle?.vehicleExterior?.mainBody)
      data.append("mainBody", vehicle.vehicleExterior.mainBody);
    if (vehicle?.vehicleExterior?.front)
      data.append("front", vehicle.vehicleExterior.front);
    if (vehicle?.vehicleExterior?.back)
      data.append("back", vehicle.vehicleExterior.back);
    if (vehicle?.vehicleExterior?.driverSide)
      data.append("driverSide", vehicle.vehicleExterior.driverSide);
    if (vehicle?.vehicleExterior?.coDriver)
      data.append("coDriver", vehicle.vehicleExterior.coDriver);
    if (vehicle?.vehicleExterior?.passengerLeftSide)
      data.append(
        "passengerLeftSide",
        vehicle.vehicleExterior.passengerLeftSide
      );
    if (vehicle?.vehicleExterior?.passengerRightSide)
      data.append(
        "passengerRightSide",
        vehicle.vehicleExterior.passengerRightSide
      );

    return Service({
      method: "post",
      url: `/${store.state.general.endPointUrl}`,
      data,
      headers: { "Content-Type": "application/form-data" },
    });
  },
  postPic(data) {

    const formData = new FormData();
    if (Array.isArray(data.photo)) {
      data.photo.forEach((element, index) => {
        formData.append("photo" + (index + 1), element);
        formData.set("quantity", data.photo.length);

      });
    } else {
      formData.append("photo1", data.photo);
      formData.set("quantity", 1);
    }
    formData.set("vehiclesId", data.vehiclesId);
    formData.set("partsId", data.partsId);
    formData.set("description", data.description);
    formData.set("vi", data.vi);
    return Service({
      method: "post",
      url: "/vehicle/assignPhoto",
      data: formData,
      headers: { "Content-Type": "multi/form-data" },
    });
  },
  deletePic(id) {
    return Service({
      method: "delete",
      url: "/vehicle/deletePhoto",
      data: { id },
    });
  },
  postDamagePic(data) {
    const formData = new FormData();
    formData.append("damagePhoto", data.damagePhoto);
    formData.set("vehiclesId", data.vehiclesId);
    formData.set("description", data.description);
    formData.set("color", data.color);
    formData.set("coordinateX", data.coordinateX);
    formData.set("coordinateY", data.coordinateY);
    formData.set("type", data.type);
    formData.set("damageId", data.damageId);
    formData.set("partId", data.partId);
    formData.set("vi", data.vi);
    return Service({
      method: "post",
      url: "/vehicle/assignDamagePhoto",
      data: formData,
      headers: { "Content-Type": "application/form-data" },
    });
  },
  deleteDamagePic(id) {
    return Service({
      method: "delete",
      url: "/vehicle/deleteDamagePhoto",
      data: { id },
    });
  },
  getAutoParts() {
    return Service({
      method: "get",
      url: "/vehicle/part",
    });
  },
  getListVehicleByStatus({ page = 1, perPage = 10, filter, search }) {
    let url = "/vehicle/byCompany";
    url = urlParams.set(url, "page", page);
    url = urlParams.set(url, "resourcesPerPage", perPage);
    if (filter) url = urlParams.set(url, "status", filter);
    if (search) url = urlParams.set(url, "search", search);
    return Service({
      method: "get",
      url,
    });
  },
  changeStatusCar(id, status) {
    return Service({
      method: "post",
      url: "/vehicle/update",
      data: {
        id,
        status,
      },
    });
  },
  deleteTire(id) {
    return Service({
      method: "delete",
      url: `vehicle/deleteTire?id=${id}`,
    });
  },
  postTireInCar(vehiclesId, tire) {
    return Service({
      method: "post",
      url: "/vehicle/assignTire",
      data: {
        vehiclesId,
        ...tire,
      },
    });
  },
  uploadRepairImage(data) {
    return Service({
      method: "post",
      url: "/vehicle/assignRepairPhoto",
      data: data,
      headers: { "Content-Type": "application/form-data" },
    });
  },
  deleteRepairImage(id) {
    return Service({
      method: "delete",
      url: "/vehicle/deleteRepairPhoto",
      data: { id },
    });
  }
};
