import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@fortawesome/fontawesome-pro/css/all.css'
import '@/plugins';
import '@/layouts';

import DashboardPlugin from './plugins/dashboard-plugin';

// import global filters
import '@/filters';

Vue.use(DashboardPlugin)
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
