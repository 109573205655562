<template>
  <b-list-group v-if="addons.status == 'fetching'" class="mt-2">
    <b-list-group-item class="text-center">
      <b-spinner variant="yellow" />
    </b-list-group-item>
  </b-list-group>
  <b-list-group v-else class="mt-2 mb-2 note-list" tag="ul">
    <b-list-group-item v-if="addons.data && !addons.data.length" class="text-center">
      <b>No addons to show</b>
    </b-list-group-item>

    <b-list-group-item
      v-for="(addon, i) in addonsToIterate"
      :key="i"
      class="flex-column align-items-start"
      tag="li"
    >
      <AddonsListItem v-on="$listeners" :addon="addon" :refreshData="getAddons" />
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import { mapState } from "vuex";
import { GET_ADDONS } from "./actions";
import AddonsListItem from "./AddonsListItem.vue";
export default {
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },

  components: {
    AddonsListItem,
  },
  computed: {
    ...mapState({
      addons: (state) => state.superAdminStore.componentStore.addons.addons.data,
      addonNoType: (state) =>
        state.superAdminStore.componentStore.addons.addons.data.addonsWithoutType || [],
    }),
    addonsToIterate() {
      return this.type === "noType" ? this.addonNoType : this.addons.data || [];
    },
  },
  created() {
    this.getAddons();
  },
  methods: {
    getAddons() {
      this.$store
        .dispatch(GET_ADDONS, { vehicleId: this.vehicle.id })
        .then(() => {})
        .catch(() => {});
    },
  },
};
</script>

<style></style>
