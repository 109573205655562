<template>
  <b-card>
    <div class="container">
      <b-row>
        <b-col cols="10">
          <small class="text-muted">
            Report date {{ carfax.reportDate | formatDateTime }} <br />
            Expiry data:
            {{ carfax.expiryDate | formatDateTime }}
          </small>
        </b-col>
        <b-col> </b-col>
      </b-row>
      <p class="text-justify my-2">
        Type: <i> {{ getReportNameByType(carfax.reportType) }}</i>
      </p>
      <div class="text-right">
        <span
          class="dot mr-3"
          :class="[carfax.status ? 'dot-success' : 'dot-danger']"
        />
      </div>

      <div>
        <h6>
          <a :href="carfax.reportLink" target="_blank">
            {{ carfax.reportLink }}
          </a>
          <b-button
            v-clipboard:copy="carfax.reportLink"
            v-clipboard:success="copyUrl"
            v-clipboard:error="onError"
            variant="none"
          >
            <b-icon v-if="URLCopied" icon="clipboard-check" />
            <b-icon v-else icon="clipboard" />
          </b-button>
          <!--  <b-button variant="none" @click="openCarfax(carfax.id)">
            <b-icon icon="eye" />
          </b-button> -->
        </h6>
      </div>
      <p>
        <!--    <a :href="carfax.file" target="_blank">
        <b-icon
          icon="file-earmark-text"
          class="float-right cursor-pointer"
          scale="1.5"
        ></b-icon>
      </a> -->
      </p>

      <p>
        <b-button
          v-if="userHasPermission('carfax', 'write')"
          variant="warning"
          size="md"
          v-b-tooltip.hover
          type="button"
          title="Notes"
          class="float-right mx-2 text-white"
          @click="openCarfaxNotesModal()"
        >
          Notes
        </b-button>
      </p>
      <br /><br />

      <b-list-group v-if="!notes" class="mt-2">
        <b-list-group-item class="text-center">
          <b-spinner variant="yellow" />
        </b-list-group-item>
      </b-list-group>

      <b-list-group v-else class="mt-2 mb-2 note-list" tag="ul">
        <b-list-group-item v-if="notes && !notes.length" class="text-center">
          <b>No notes to show</b>
        </b-list-group-item>

        <b-list-group-item
          v-for="(note, i) in notes"
          :key="i"
          class="flex-column align-items-start"
          tag="li"
        >
          <NoteCard :note="note" :refreshData="getCarfaxNotes" />
        </b-list-group-item>
      </b-list-group>
      <CarfaxNotesModal :carfax="carfax" />
      <b-modal
        :id="modalId + carfax.id"
        ref="modal"
        modal-class="modal-fullscreen"
        size="xl"
        centered
        title="Carfax Report"
        hide-footer
      >
        <div v-if="!carfax.reportLink" class="text-center">
          Nothing to show. Call your agent for more information.
        </div>
        <div v-else>
          <b-embed
            type="iframe"
            aspect="16by9"
            :src="carfax.reportLink"
            allowfullscreen
          />
        </div>
      </b-modal>
    </div>
  </b-card>
</template>

<script>
import { POST_EXTERNAL_CARFAX } from "./actions";
import CarfaxNotesModal from "./notes/NotesModal.vue";
import NoteCard from "./notes/NoteCard.vue";
import { superAdminMixin } from "../../../superAdmin/mixins";
import { mapState } from "vuex";
import { GET_CARFAX_NOTES } from "./notes/actions";
export default {
  props: ["carfax", "refreshData"],
  components: {
    CarfaxNotesModal,
    NoteCard,
  },
  mixins: [superAdminMixin],
  computed: {
    ...mapState({
      vehicle: (state) => state.superAdminStore.vehicle,
      /*       notes: (state) => state.vehicle.carfax.notes.notes,
       */
    }),
  },
  data() {
    return {
      modalId: "Carfax-Modal",
      URLCopied: null,
      notes: null,
    };
  },
  created() {
    this.getCarfaxNotes();
  },
  methods: {
    getReportNameByType(type) {
      switch (type) {
        case 1:
          return "RDPRM";
        case 4:
          return "Claims";
        case 2:
          return "Verified";
        default:
          break;
      }
      return "as";
    },
    copyUrl(url) {
      this.URLCopied = url;
    },

    openCarfax(id) {
      this.$bvModal.show("Carfax-Modal" + id);
    },

    openRegisterCarfax(id) {
      this.$bvModal.show("register-carfax-Modal" + id);
    },

    hideModal() {
      this.$bvModal.hide("register-carfax-Modal" + this.type);
    },

    save() {
      const payload = {
        reportLink: this.form.reportLink,
        expiryDate: this.form.expiredDate,
        vehiclesId: this.vehicle.data.id,
        reportType: this.type,
      };

      this.$store
        .dispatch(POST_EXTERNAL_CARFAX, payload)
        .then(() => {
          this.getCarfax();
          this.makeToast(
            "Success",
            "Carfax link registered successfully",
            "success"
          );
          this.hideModal();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onError(error) {
      console.log(error);
    },
    update() {},

    formatPayload() {
      const payload = {
        vin: this.vehicle.data.vinNumber.vinNumber,
        reportType: this.type,
        check: this.check,
      };
      return payload;
    },
    openCarfaxNotesModal() {
      this.$bvModal.show("carfax-notes-modal" + this.carfax.id);
    },
    getCarfaxNotes() {
      this.$store
        .dispatch(GET_CARFAX_NOTES, {
          id: this.carfax.id,
        })
        .then((response) => {
          this.notes = [];
          this.notes = response.carfaxNote;
        })
        .catch(() => {
          this.notes = [];
        });
    },
  },
  mounted() {
    this.URLCopied = null;
  },
};
</script>


<style scoped >
::v-deep .modal-fullscreen .modal {
  padding: 0 !important;
}
::v-deep .modal-fullscreen .modal-dialog {
  width: 100%;
  max-width: none;
  height: 100%;
  margin: 0;
}
::v-deep .modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
::v-deep .modal-fullscreen .modal-body {
  overflow-y: auto;
}
@media screen and (max-width: 600px) {
  ::v-deep .modal-fullscreen .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  ::v-deep .modal-fullscreen .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
  }

  .embed-responsive {
    position: relative;
    padding-top: 150%;
  }
}

@media screen and (max-width: 300px) {
  .embed-responsive {
    padding-top: 170%;
  }
}
</style>
