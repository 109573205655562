<template>
  <div>
    <b-table
      class="mt-3"
      show-empty
      bordered
      small
      stacked="md"
      head-variant="light"
      :items="padCommissions.data"
      :fields="fields"
      :busy="isLoading"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" variant="yellow" />
        </div>
      </template>
      <template #cell(agent)="{ item }">
        <div class="text-center">
          {{ item.user.firstName }} {{ item.user.lastName }}
        </div>
      </template>
      <template #cell(company)="{ item }">
        <div class="text-center">
          {{ item.company.companyName }}
        </div>
      </template>
      <template #cell(marketPlace)="{ item }">
        <div class="text-center">
          {{ item.marketPlace.name }}
        </div>
      </template>
      <template #cell(action)="{ item }">
        <div class="text-center">
          <b-button
            variant="yellow"
            size="sm"
            class="mr-2"
            @click="editItem(item)"
          >
            <b-icon icon="pencil-fill" />
          </b-button>
          <b-button variant="danger" size="sm" @click="deleteItem(item)">
            <b-icon icon="trash-fill" />
          </b-button>
        </div>
      </template>
    </b-table>
    <b-modal
      id="pad-commission-form-modal"
      hide-footer
      size="lg"
      @hidden="itemSelected = null"
    >
      <PadCommissionForm
        :refresh="getData"
        :item="itemSelected"
        :hideModal="hideModal"
      />
    </b-modal>
  </div>
</template>

<script>
import { generalMixin } from "../../../mixin";
import { GET_PAD_COMMISSIONS, DELETE_PAD_COMMISSION } from "../actions";
import PadCommissionForm from "./PadCommissionForm.vue";

import { mapState } from "vuex";
export default {
  name: "PadCommissionTable",
  components: { PadCommissionForm },
  mixins: [generalMixin],
  data() {
    return {
      fields: [
        { key: "agent", label: "Agent" },
        {
          key: "pad",
          label: "Commission",
          class: "text-right",
          formatter: (value) => this.$options.filters.currency(value),
        },
        { key: "company", label: "Company" },
        { key: "marketPlace", label: "Marketplace" },
        { key: "action", label: "Action" },
      ],
      itemSelected: null,
    };
  },
  computed: {
    ...mapState({
      padCommissions: (state) =>
        state.superAdminStore.interest.padCommissions.data,
      isLoading: (state) =>
        state.superAdminStore.interest.padCommissions.status === "fetching",
    }),
  },
  created() {
    this.$store.commit("setAddFunction", this.openModal);
    this.getData();
  },
  methods: {
    getData(search = null, pagination = null) {
      const payload = { search, pagination };
      this.$store.dispatch(GET_PAD_COMMISSIONS, payload);
    },
    editItem(item) {
      this.itemSelected = item;
      this.openModal();
    },
    async deleteItem(item) {
      const confirm = await this.showConfirmDeleteBox();
      if (!confirm) return;
      this.$store.dispatch(DELETE_PAD_COMMISSION, { id: item.id }).then(() => {
        this.$bvToast.toast("Pad commission deleted", {
          title: "Success",
          variant: "success",
          solid: true,
        });
        this.getData();
      });
    },
    changePage(page) {
      this.getData(null, page);
    },
    openModal() {
      this.$bvModal.show("pad-commission-form-modal");
    },
    hideModal() {
      this.$bvModal.hide("pad-commission-form-modal");
    },
  },
};
</script>

<style>
</style>