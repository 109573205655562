import vehicles from "./vehicles/store";
import users from "./users/store";
import agents from "./agent/store";
import assignCompany from "./assignCompany/store";

export default {
  state: {},
  actions: {},
  mutations: {},
  modules: {
    vehicles,
    users,
    agents,
    assignCompany,
  },
};
