<template>
  <b-col cols="5">
    <b-button
      variant="success"
      :disabled="userPending"
      block
      @click="sendPrice"
    >
      <span class="buyNow">Buy Now</span>
    </b-button>
  </b-col>
</template>

<script>
import { mapState } from 'vuex';
import { POST_VEHICLE_BUY_NOW } from './actions';
import { GET_VEHICLES } from '../actions';
import { listVehiclesMixin } from '../mixins';
import { generalMixin } from '@/modules/mixin';
import {
  GET_VEHICLE,
  SET_ONE_VEHICLE_FILTERS
} from '@/modules/vehicle/actions';

export default {
  props: [ 'vehicleId', 'price', 'userPending' ],
  computed: {
    ...mapState({
      buyNowStatus: (state) =>
        state.adminStore.vehicles.listStore.componentsStore.buyNow.status
    })
  },
  mixins: [ listVehiclesMixin, generalMixin ],
  methods: {
    payload() {
      return {
        vehiclesId: this.vehicleId
      };
    },
    getVehicles() {
      this.$store
        .dispatch(GET_VEHICLES, this.params)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast('Could not get the vehicle list', {
            title: 'Warning',
            variant: 'warning'
          });
        });
    },

    getVehicle() {
      this.$store.commit(SET_ONE_VEHICLE_FILTERS, {
        reset: true,
        ...{ fields: { vinNumber: this.$route.params.vin } }
      });

      this.$store
        .dispatch(GET_VEHICLE)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast('Could not get the vehicle ', {
            title: 'Warning',
            variant: 'warning'
          });
        });
    },

    async sendPrice() {
      const confirm = await this.showConfirmBuyCar();
      if (!confirm) return;
      if (this.user != null) {
        if (!this.user) {
          this.$router.push({
            name: 'admin.users.registry'
          });
        } else {
          this.$store
            .dispatch(POST_VEHICLE_BUY_NOW, this.payload())
            .then(() => {
              this.$root.$bvToast.toast(
                'Vehicle purchased! Please, check your email',
                {
                  title: 'Success',
                  variant: 'success'
                }
              );
              this.getVehicles();
              this.getVehicle();
            })
            .catch(() => {
              this.$root.$bvToast.toast('The offer could not be sent', {
                title: 'Warning',
                variant: 'warning'
              });
            });
        }
      } else {
        this.$bvModal.show('login-modal');
      }
    }
  }
};
</script>

<style>
@media (max-width: 1280px) {
  .buyNow {
    font-size: 14px;
  }
}
</style>
