<template>
  <div>
    <b-navbar
      variant="yellow"
      class="v-25 text-yellow mb-2"
    >
      asg
    </b-navbar>

    <div class="container-fluid">
      <b-row>
        <b-col>
          <h4 class="ml-3">
            Buyers
          </h4>
        </b-col>
      </b-row>
      <div class="pt-4">
        <TableBase
          :items="users.data"
          :fields="fields"
          :options="options"
          :busy="users.status === 'fetching' ? true : false"
        >
          <template v-slot:rightSide>
            <div>
              <b-button
                variant="outline-purple-yellow"
                class="align-bottom"
                @click="showModal()"
              >
                <b-icon
                  icon="person-plus-fill"
                  class="h4 m-1 align-bottom"
                />
                <span class="" />
              </b-button>
            </div>
          </template>
        </TableBase>
      </div>

      <b-modal
        id="detailsModal"
        ref="detailsModal"
        size="lg"
        hide-footer
        body-class="pl-0 pr-0"
        ok-disabled
        @hidden="userItem = {}"
      >
        <Brand />
        <UserForm
          :user-item="userItem"
          :refresh-data="getData"
          :hide-modal="hideModal"
        />
      </b-modal>
    </div>
  </div>
</template>
<script>
import TableBase from '@/modules/shared/table.vue';
import UserForm from '@/modules/buyer/users/Form.vue';
import Brand from '@/modules/shared/brand.vue';
import { DELETE_USER_BUYER, GET_USERS_BUYER, PUT_USER_BUYER } from './actions';
import { mapState } from 'vuex';

import { generalMixin } from '@/modules/mixin.js';
export default {
  components: { TableBase, UserForm, Brand },
  mixins: [ generalMixin ],
  computed: {
    ...mapState({
      users: (state) => state.buyer.users.users
    })
  },
  data() {
    return {
      fields: [
        { key: 'firstName', label: 'First name' },
        { key: 'lastName', label: 'Last name' },
        { key: 'email', label: 'Email' },
        { key: 'userDetails.mobileNumber', label: 'Phone number' },
        {
          key: 'actions',
          label: 'Actions',
          note: false,
          details: true,
          validation: false,
          delete: true
        }
      ],
      options: {
        assign: false,
        rolFilter: false,
        add: false,
        fun: this.getData
      },
      userItem: {}
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(filter = '') {
      const payload = {
        search: filter
      };
      this.$store
        .dispatch(GET_USERS_BUYER, payload)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast('error', {
            title: 'Error',
            variant: 'warning'
          });
        });
    },
    async deleteItem(item) {
      const confirm = await this.showConfirmDeleteBox();
      if (!confirm) return;
      const payload = {
        id: item.id,
        status: 0
      };
      this.$store
        .dispatch(DELETE_USER_BUYER, payload)
        .then(() => {
          this.getData();
        })
        .catch(() => {
          this.$root.$bvToast.toast('error', {
            title: 'Error',
            variant: 'warning'
          });
        });
    },
    async activateItem(item) {
      const payload = {
        id: item.id,
        status: 1
      };
      this.$store
        .dispatch(PUT_USER_BUYER, payload)
        .then(() => {
          this.makeToast('Succes', 'User updated succesfully', 'success');
          this.getData();
        })
        .catch(() => {
          this.$root.$bvToast.toast(this.user.error.response.data.message, {
            title: 'Warning',
            variant: 'warning'
          });
        });
    },
    showModal() {
      this.$bvModal.show('detailsModal');
    },
    editItem(item) {
      this.userItem = item;
      this.$bvModal.show('detailsModal');
    },
    changePage(page, filter = '') {
      const payload = {
        search: filter,
        page: page
      };
      this.$store
        .dispatch(GET_USERS_BUYER, payload)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast('error', {
            title: 'Error',
            variant: 'warning'
          });
        });
    },

    hideModal() {
      this.$bvModal.hide('detailsModal');
    }
  }
};
</script>
