import * as constants from './actions';
import axios from '@/plugins/axios.js';

function getDefaultState() {
  return {
    categories: {
      status: null,
      data: [],
      error: null
    },
    category: {
      status: null,
      data: {},
      error: null
    }
  };
}

const actions = {
  [constants.GET_CATEGORIES]: async ({ commit }, params) => {
    try {
      commit(constants.GET_CATEGORIES);
      const response = await axios.get('category/getCategory', {
        params: { ...params }
      });
      commit(constants.GET_CATEGORIES_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_CATEGORIES_ERROR, error);
      throw error.response;
    }
  },

  [constants.POST_CATEGORY]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_CATEGORY);
      const response = await axios.post('category/createAndUpdate', payload);
      commit(constants.POST_CATEGORY_SUCCESS);
      return response;
    } catch (error) {
      commit(constants.POST_CATEGORY_ERROR, error);
      throw error.response;
    }
  }
};

const mutations = {
  [constants.GET_CATEGORIES]: (state) => {
    state.categories.status = 'fetching';
    state.categories.error = null;
  },
  [constants.GET_CATEGORIES_SUCCESS]: (state, data) => {
    state.categories.status = 'success';
    state.categories.error = null;
    state.categories.data = data;
  },
  [constants.GET_CATEGORIES_ERROR]: (state, error) => {
    state.categories.status = 'error';
    state.categories.error = error;
  },

  [constants.POST_CATEGORY]: (state) => {
    state.category.status = 'fetching';
    state.category.error = null;
  },
  [constants.POST_CATEGORY_SUCCESS]: (state, data) => {
    state.category.status = 'success';
    state.category.error = null;
    state.category.data = data;
  },
  [constants.POST_CATEGORY_ERROR]: (state, error) => {
    state.category.status = 'error';
    state.category.error = error;
  }
};

export default {
  state: getDefaultState(),
  actions,
  mutations
};
