<template>
  <div>
    <b-row>
      <b-col cols="10">
        <span>
          Registered date:
          <i
            >{{ recall.date | formatDateTime }} -
            {{ recall.user.lastName }}
            {{ recall.user.firstName }}
          </i>
        </span>
        <br />
        <span v-if="recall.status == 0">
          Cancellation date:
          <i
            >{{ recall.cancellationDate | formatDateTime }} -
            {{ recall.cancellationUser.lastName }}
            {{ recall.cancellationUser.firstName }}
          </i>
        </span>
        <br />
        <span v-if="recall.repairDate != null">
          Repair date:
          <i>
            {{ recall.repairDate | formatDateTime }} -
            {{ recall.repairUser.lastName }}
            {{ recall.repairUser.firstName }}
          </i>
        </span>
        <span v-if="recall.status == 3">
          Mark as WIP date:
          <i
            >{{ recall.workInProgressDate | formatDateTime }} -
            {{ recall.workInProgressUser.lastName }}
            {{ recall.workInProgressUser.firstName }}
          </i>
        </span>
      </b-col>
      <b-col>
        <div class="text-right">
          <span v-if="recall.status == 0" class="dot dot-danger mr-3" />

          <!--       <span v-if="recall.status == 2" class="dot dot-success mr-3" />  -->
          <b-form-checkbox
            v-if="
              (recall.status == 1 ||
                recall.status == 2 ||
                recall.status == 3) &&
              userHasPermission('recall', 'write')
            "
            v-b-tooltip.hover
            :title="recall.status == 1 ? 'Mark as completed' : 'Mark as active'"
            switch
            :checked="recall.status == 2"
            @change="toggleRecallStatus(recall)"
          />
          <!-- 
          <b-form-checkbox
            v-if="recall.status == 1"
            id="checkbox-3"
            v-b-tooltip.hover
            title="Mark as completed"
            class="z-index-0 mt-2 mr-2"
            name="checkbox-3"
            :unchecked-value="false"
            @change="markAsRecallCompleted(recall)"
          /> -->
          <b-form-group
            v-if="recall.status == 1 && userHasPermission('recall', 'write')"
            class=""
          >
            <b-icon
              icon="x"
              v-b-tooltip.hover
              title="Cancel recall"
              scale="1.5"
              class="cursor-pointer mr-3"
              @click="disableRecall(recall)"
            ></b-icon>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <p class="text-justify mb-1">
      {{ recall.description }}
    </p>

    <p>
      <font-awesome-icon
        v-b-tooltip.hover
        title="View files"
        class="icon-color cursor-pointer"
        :icon="['far', 'file-magnifying-glass']"
        size="lg"
        @click="openFilesModal(recall.id)"
      />
      <font-awesome-icon
        v-if="recall.status != 2"
        v-b-tooltip.hover
        title="Mark as WIP"
        class="icon-color cursor-pointer float-right mr-3"
        :icon="['far', 'memo-circle-check']"
        size="lg"
        @click="markAsWIPRecall(recall)"
      />
      <a
        :href="recall.file"
        v-b-tooltip.hover
        title="View file"
        target="_blank"
      >
        <b-icon
          icon="file-earmark-text"
          class="float-right cursor-pointer mr-3"
          scale="1.5"
          :variant="recall.file ? 'success' : 'primary'"
        ></b-icon>
      </a>
    </p>

    <p>
      <b-button
        v-if="userHasPermission('recall', 'write')"
        variant="warning"
        size="md"
        v-b-tooltip.hover
        type="button"
        title="Notes"
        class="float-right mx-2 text-white"
        @click="openRecallNotesModal(recall.id)"
      >
        Notes
      </b-button>
    </p>
    <br /><br />

    <b-list-group v-if="!notes" class="mt-2">
      <b-list-group-item class="text-center">
        <b-spinner variant="yellow" />
      </b-list-group-item>
    </b-list-group>

    <b-list-group v-else class="mt-2 mb-2 note-list" tag="ul">
      <b-list-group-item v-if="notes && !notes.length" class="text-center">
        <b>No notes to show</b>
      </b-list-group-item>

      <b-list-group-item
        v-for="(note, i) in notes"
        :key="i"
        class="flex-column align-items-start"
        tag="li"
      >
        <NoteCard :note="note" :refreshData="getRecallNotes" />
      </b-list-group-item>
    </b-list-group>
    <RecallNotesModal :recall="recall" />

    <FilesModal :recall="recall" :refreshData="refreshData" />
  </div>
</template>

<script>
import { superAdminMixin } from "../../mixins";
import { PUT_RECALL } from "./actions";
import FilesModal from "./FilesModal.vue";
import { GET_RECALL_NOTES } from "./notes/actions";
import RecallNotesModal from "./notes/NotesModal.vue";
import NoteCard from "./notes/NoteCard.vue";
export default {
  props: ["recall", "refreshData"],
  mixins: [superAdminMixin],
  components: {
    FilesModal,
    RecallNotesModal,
    NoteCard,
  },
  data() {
    return {
      notes: null,
    };
  },
  created() {
    this.getRecallNotes();
  },
  methods: {
    markAsRecallCompleted(recall) {
      const payload = {
        id: recall.id,
        status: 2,
      };
      this.$store
        .dispatch(PUT_RECALL, payload)
        .then(() => {
          this.refreshData();
        })
        .catch(() => {
          this.toastMessage(
            "Recall not mark as completed",
            "Warning",
            "warning"
          );
        });
    },
    disableRecall(recall) {
      const payload = {
        id: recall.id,
        status: 0,
      };
      this.$store
        .dispatch(PUT_RECALL, payload)
        .then(() => {
          this.refreshData();
          this.toastMessage(
            "Recall cancel successfully ",
            "Success",
            "success"
          );
        })
        .catch(() => {
          this.toastMessage("Recall not cancel ", "Warning", "warning");
        });
    },
    toggleRecallStatus(recall) {
      const payload = {
        id: recall.id,
        status: recall.status == 1 ? 2 : 1,
      };
      this.$store
        .dispatch(PUT_RECALL, payload)
        .then(() => {
          this.refreshData();
        })
        .catch(() => {
          this.toastMessage(
            "Recall status updated completed",
            "Warning",
            "warning"
          );
        });
    },
    openFilesModal(id) {
      this.$bvModal.show("recall-files-modal" + id);
    },
    markAsWIPRecall(recall) {
      const payload = {
        id: recall.id,
        status: 3,
      };
      this.$store
        .dispatch(PUT_RECALL, payload)
        .then(() => {
          this.refreshData();
          this.toastMessage(
            "Recall updated successfully ",
            "Success",
            "success"
          );
        })
        .catch(() => {
          this.toastMessage("Recall not updated ", "Warning", "warning");
        });
    },
    getRecallNotes() {
      this.$store
        .dispatch(GET_RECALL_NOTES, {
          id: this.recall.id,
        })
        .then((response) => {
          this.notes = [];
          this.notes = response.recallNote;
        })
        .catch(() => {
          this.notes = [];
        });
    },
    openRecallNotesModal(id) {
      this.$bvModal.show("recall-notes-modal" + id);
    },
  },
};
</script>

<style scoped></style>
