import Base from "./Base.vue";

import PendingList from "./users/PendingList.vue";
import ActiveList from "./users/ActiveList.vue";
import RejectedList from "./users/RejectedList.vue";
import Agents from "./agent/List.vue";

import { ifAuthenticated } from "@/router/navigationGuards";
import DisableNotifications from "./users/DisableNotifications";
import { ifRoleAuthenticated } from "@/router/navigationGuards";
import List from "./vehicles/list/List.vue";
import RegistryVehicle from "./vehicles/registry/SearchVehicleByVin.vue";
import GeneralInformation from "./vehicles/registry/GeneralInformation";
import SuccessCarUploaded from "./vehicles/registry/SuccessCarUploaded";
import OffersFullScreen from "./vehicles/offers/OffersFullScreen";
import AssignCompanyTable from "./assignCompany/AssignCompanyTable.vue";
import DashboardLayout from '@/layouts/DashboardLayout.vue';

const routes = [
  {
    beforeEnter: (to, from, next) => {
      ifRoleAuthenticated(to, from, next, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]);
    },
    path: "/admin",
    name: "admin",
    component: DashboardLayout,
    children: [
      {
        path: "vehicles",
        name: "admin.vehicles",
        component: Base,
        children: [
          {
            path: "list",
            name: "admin.vehicles.list",
            component: List,
          },
          {
            path: "registry",
            name: "admin.vehicles.registry",
            component: RegistryVehicle,
          },
          {
            path: "registry/:vin",
            name: "admin.vehicles.registry.general",
            component: GeneralInformation,
          },
          {
            path: "registry/:vin/uploaded",
            name: "admin.vehicles.registry.uploaded",
            component: SuccessCarUploaded,
          },
          {
            path: "offers",
            name: "admin.vehicles.offers",
            component: OffersFullScreen,
          },
        ],
      },

      {
        path: "assign-company",
        name: "admin.assign-company",
        component: AssignCompanyTable,
        meta: {
          name: "Assign Company",
        }
      },
      {
        path: "agent",
        name: "admin.agent",
        component: Agents,
        meta: {
          name: "Users",
        }
      },
    ],

  },
  {
    beforeEnter: (to, from, next) => {
      ifRoleAuthenticated(to, from, next, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]);
    },
    path: "/admin/users",
    name: "admin.users",
    component: DashboardLayout,

    children: [
      {
        path: "registry",
        name: "admin.users.registry",

        component: PendingList,
      },
      {
        path: "validate",
        name: "admin.users.validate",

        component: ActiveList,
        meta: { name: "Companies" }
      },
      {
        path: "rejected",
        name: "admin.users.rejected",

        component: RejectedList,
      },
    ],
  },

  {
    path: "/disable/notifications",
    name: "disable-notifications",
    component: DisableNotifications,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
  },
  {
    path: "/disable/notifications/:token",
    name: "disable-notifications-public",
    component: DisableNotifications,
  },
];

export default routes;
