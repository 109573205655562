<template>
  <b-row>
    <b-col>
      <Search
        ref="searchVehiclesComponent"
        class="search sticky-top"
        :offers-view="true"
      />
    </b-col>
    <b-col
      xl="9"
      md="9"
      sm="12"
    >
      <b-row>
        <b-col v-if="expiredOffersStatus === 'fetching'">
          <Skeleton />
        </b-col>

        <b-col
          v-else-if="
            expiredOffers && expiredOffers.data && !expiredOffers.data.length
          "
          class="mt-2 col-lg-4 col-centered"
        >
          <b>No records to show</b>
        </b-col>
        <b-col
          v-for="offer in expiredOffers.data"
          v-else-if="expiredOffers && expiredOffers.data"
          :key="offer.id"
          class="mt-2"
          xl="4"
          md="4"
          sm="12"
        >
          <VehicleCard
            :vehicle="offer"
            :is-expired-offer="true"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from 'vuex';
import Skeleton from './components/Skeleton';
import Search from '../list/Search';
import VehicleCard from '../list/VehicleCard';

export default {
  components: { VehicleCard, Skeleton, Search },
  computed: {
    ...mapState({
      expiredOffersStatus: (state) =>
        state.adminStore.vehicles.offersStore.expiredOffers.status,
      expiredOffers: (state) =>
        state.adminStore.vehicles.offersStore.expiredOffers.data
    })
  }
};
</script>

<style lang="scss">
@import "./offers.scss";
</style>
