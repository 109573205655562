<template>
  <div>
    Total Non-Allocated CAD:
    <i>
      {{ twoDecimals(valueCadByFx(totalNonAllocatedUsd)) | currency }}
    </i>
    <br />
    Total Non-Allocated USD:
    <i>
      {{ twoDecimals(totalNonAllocatedUsd) | currency }}
    </i>
    <br />
    Total Balance CAD:
    <i>
      {{ twoDecimals(valueCadByFx(totalBalanceUsd)) | currency }}
    </i>
    <br />
    Total Balance USD:
    <i>
      {{ twoDecimals(totalBalanceUsd) | currency }}
    </i>
    <br />
    Total Inventory Value CAD:
    <i>
      {{ twoDecimals(valueCadByFx(totalInventoryValueUsd)) | currency }}
    </i>
    <br />
    Total Inventory Value USD:
    <i>
      {{ twoDecimals(totalInventoryValueUsd) | currency }}
    </i>
    <br />
    Average contract FX
    <i> {{ twoDecimals(averageContractFx) | currency }} </i>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ExchangeContractTotals",
  computed: {
    ...mapState({
      totalNonAllocatedCad: (state) =>
        state.superAdminStore.exchangeContract.totalNonAllocatedCad,
      totalNonAllocatedUsd: (state) =>
        state.superAdminStore.exchangeContract.totalNonAllocatedUsd,
      totalBalanceCad: (state) =>
        state.superAdminStore.exchangeContract.totalBalanceCad,
      totalBalanceUsd: (state) =>
        state.superAdminStore.exchangeContract.totalBalanceUsd,
      totalInventoryValueUsd: (state) =>
        state.superAdminStore.exchangeContract.totalInventoryValueUsd,
      totalInventoryValueCad: (state) =>
        state.superAdminStore.exchangeContract.totalInventoryValueCad,
      averageContractFx: (state) =>
        state.superAdminStore.exchangeContract.averageContractFx,
    }),
  },
  methods: {
    twoDecimals(value) {
      /* value with 2 decimals */
      const fx = value.toString();
      const fxArray = fx.split(".");
      const fxArrayLength = fxArray.length;
      if (fxArrayLength > 1) {
        const fxArrayLast = fxArray[fxArrayLength - 1];
        if (fxArrayLast.length > 2) {
          return fxArray[0] + "." + fxArrayLast.substring(0, 2);
        }
      }
      return fx;
    },
    valueCadByFx(value) {
      return value * this.averageContractFx;
    },
  },
};
</script>

<style>
</style>