<template>
  <b-form @submit.stop.prevent="saveAddon()" id="addonsForm">
    <b-row>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label="Accounting date">
          <template slot="label"> Accounting date </template>

          <b-form-input
            v-model.trim="$v.form.accountingDate.$model"
            type="date"
            :state="validateState('accountingDate')"
            aria-describedby="input-date-live-feedback"
            @change="setValue('accountingDate', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label="">
          <template slot="label">
            Addon type <span class="text-danger">*</span>
          </template>

          <vue-single-select
            :classes="{ input: 'form-control', dropdown: 'dropdown' }"
            v-model="form.addonType"
            :options="addonsType.data || []"
            optionLabel="name"
          ></vue-single-select>
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label="">
          <template slot="label">
            Service provider <span class="text-danger">*</span>
          </template>

          <vue-single-select
            :classes="{ input: 'form-control', dropdown: 'dropdown' }"
            v-model="form.serviceProvider"
            :options="suppliers || []"
            optionLabel="companyName"
          ></vue-single-select>
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label="">
          <template slot="label">
            Amount <span class="text-danger">*</span>
          </template>
          <Money
            v-model.trim="$v.form.amount.$model"
            class="form-control"
            :state="validateState('amount')"
            v-bind="money"
            aria-describedby="input-amount-live-feedback"
            @change="setValue('amount', $event)"
          />
          <b-form-invalid-feedback id="input-amount-live-feedback">
            This is a required field.
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label="Currency">
          <template slot="label">
            Currency <span class="text-danger">*</span>
          </template>
          <b-form-select
            v-model="form.currency"
            :options="currencyOptions"
            :state="validateState('currency')"
            @change="setValue('currency', $event)"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <b-form-group label="Description">
          <b-form-textarea
            v-model.trim="$v.form.description.$model"
            class="resize-none"
            :state="validateState('description')"
            @change="setValue('description', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <div>
      <AddonsFormTaxTable
        ref="AddonsFormTaxTable"
        :taxes="taxes.data || []"
        :taxesSelected="taxesSelected || []"
        @update-select-taxes="setTaxesSelected"
      />
    </div>
    <div>
      <AddonsFormBreakdown :sellPrice="form.amount" :taxes="taxesSelected">
        <template slot="initial-label">
          <span>Price</span>
        </template>
        <template slot="final-label">
          <span>Final price</span>
        </template>
      </AddonsFormBreakdown>
    </div>

    <div class="text-right">
      <b-button
        v-if="isEdition"
        variant="yellow"
        type="button"
        class="mr-2 text-white"
        @click="
          resetForm();
          isEdition = false;
        "
      >
        <b-spinner v-if="addon.status === 'fetching'" variant="white" small />
        <span v-else>Cancel edit</span>
      </b-button>
      <b-button
        :disabled="$v.form.$invalid || addon.status === 'fetching'"
        variant="primary"
        type="submit"
      >
        <b-spinner v-if="addon.status === 'fetching'" variant="white" small />
        <span v-else>Submit</span>
      </b-button>
    </div>
  </b-form>
</template>

<script>
import { mapState } from "vuex";
import { POST_ADDON, GET_ADDONS, GET_ADDONS_TAXES } from "./actions";
import { required } from "vuelidate/lib/validators";
import { Money } from "v-money";
import { superAdminMixin } from "../../mixins";
import { GET_ADDONS_TYPE_LIST } from "../../addons-type/actions";
import VueSingleSelect from "vue-single-select";
import AddonsFormTaxTable from "./AddonsFormTaxTable.vue";
import AddonsFormBreakdown from "./AddonsFormBreakdown.vue";
export default {
  name: "AddonsForm",
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
  },
  components: {
    Money,
    VueSingleSelect,
    AddonsFormTaxTable,
    AddonsFormBreakdown,
  },
  mixins: [superAdminMixin],
  computed: {
    ...mapState({
      addonsType: (state) => state.superAdminStore.addonsType.addonsType,
      addon: (state) => state.superAdminStore.componentStore.addons.addon,
      taxes: (state) => state.superAdminStore.componentStore.addons.taxes,
    }),
  },
  data() {
    return {
      form: {
        accountingDate: null,
        amount: 0,
        vehicleId: null,
        currency: "CAD",
        description: null,
        addonType: null,
        serviceProvider: null,
      },
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      currencyOptions: ["CAD", "USD"],
      taxesSelected: [],
      suppliers: [],
      isEdition: false,
    };
  },

  validations: {
    form: {
      amount: {
        required,
      },
      currency: { required },
      addonType: {
        required,
      },
      accountingDate: {},
      description: {},
      serviceProvider: { required },
    },
  },
  created() {
    this.taxesSelected = [];
    this.getTaxes();
    this.getProviders();
    this.getAddonsType();
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form.id = null;
      this.form.accountingDate = null;
      this.form.amount = 0;
      this.form.currency = "CAD";
      this.form.description = null;
      this.form.addonsType = null;
      this.form.serviceProvider = null;
      this.taxesSelected = [];
      this.form.addonType = null;
      this.$refs.AddonsFormTaxTable.formatTaxesSelectedToEdit([]);
      this.isEdition = false;

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    saveAddon() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_ADDON, payload)
        .then(() => {
          this.resetForm();
          this.getTaxes();
          this.toastMessage(
            "Addon information saved successfully",
            "Success",
            "success"
          );
          this.getAddons();
        })
        .catch((error) => {
          this.toastMessage(
            error.data.message.charAt(0).toUpperCase() +
              error.data.message.slice(1),
            "Warning",
            "warning"
          );
        });
    },
    formatPayload() {
      const payload = {
        id: this.form.id ?? null,
        vehicleId: this.vehicle.id,
        addonTypeId: this.form.addonType.id,
        description: this.form.description,
        accountingDate: this.form.accountingDate,
        currency: this.form.currency,
        taxes: this.taxesSelected,
        companiesId: this.form.serviceProvider.id,
      };
      if (this.form.currency == "CAD") {
        payload.amountCAD = this.form.amount;
      } else {
        payload.amountUSD = this.form.amount;
      }

      return payload;
    },
    getAddonsType() {
      this.$store
        .dispatch(GET_ADDONS_TYPE_LIST)
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    },
    getAddons() {
      this.$store
        .dispatch(GET_ADDONS, { vehicleId: this.vehicle.id })
        .then(() => {})
        .catch(() => {});
    },
    getTaxes() {
      this.$store
        .dispatch(GET_ADDONS_TAXES, { id: this.vehicle.id })
        .then(() => {})
        .catch(() => {});
    },
    setTaxesSelected(taxes) {
      this.taxSelected = [];
      this.taxesSelected = taxes;
    },
    formatTaxes() {
      this.taxesSelected = [];
      return this.taxes.data.map((tax) => {
        tax.appliesDefault == 1;
      });
    },
    setAddonToEdit(addon) {
      this.isEdition = true;
      this.form.accountingDate = addon.accountingDate ?? null;
      this.form.addonType = addon.addonType ?? null;
      if (addon.currency == "CAD") {
        this.form.amount = addon.amountCAD;
      } else {
        this.form.amount = addon.amountUSD;
      }
      this.form.description = addon.description ?? null;
      this.form.currency = addon.currency;
      this.form.id = addon.id;
      this.taxesSelected = addon.taxes;
      this.form.serviceProvider = addon.company;
      this.$refs.AddonsFormTaxTable.formatTaxesSelectedToEdit(addon.taxes);
    },
    getProviders() {
      this.$store
        .dispatch("GET_COMPANIES_LIST", { isSupplier: 1 })
        .then((response) => {
          this.suppliers = response;
        })
        .catch(() => {
          this.$root.$bvToast.toast("Could not get the suppliers list ", {
            title: "Error",
            variant: "warning",
          });
        });
    },
  },
};
</script>

<style></style>
