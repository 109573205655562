export const GET_RESERVE = "GET_RESERVE";
export const GET_RESERVE_SUCCESS = "GET_RESERVE_SUCCESS";
export const GET_RESERVE_ERROR = "GET_RESERVE_ERROR";

export const POST_RESERVE = "POST_RESERVE";
export const POST_RESERVE_SUCCESS = "POST_RESERVE_SUCCESS";
export const POST_RESERVE_ERROR = "POST_RESERVE_ERROR";

export const PUT_RESERVE = "PUT_RESERVE";
export const PUT_RESERVE_SUCCESS = "PUT_RESERVE_SUCCESS";
export const PUT_RESERVE_ERROR = "PUT_RESERVE_ERROR";

export const DELETE_RESERVE = "DELETE_RESERVE";
export const DELETE_RESERVE_SUCCESS = "DELETE_RESERVE_SUCCESS";
export const DELETE_RESERVE_ERROR = "DELETE_RESERVE_ERROR";
