import List from '../admin/vehicles/list/List.vue';
import Base from './Base.vue';

import { ifRoleAuthenticated } from '@/router/navigationGuards';
const routes = [
  {
    path: '/inventory',
    name: 'vehicleInventory',
    component: Base,
    beforeEnter: (to, from, next) => {
      ifRoleAuthenticated(to, from, next, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]);
    },
    children: [
      {
        path: 'vehicles',
        name: 'inventory.vehicles',
        component: List
      }
    ]
  }
];

export default routes;
