<template>
  <div>
    <div v-if="isLoading">
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle" variant="yellow" />
      </div>
    </div>
    <div v-else>
      <b-form-group>
        <b-form-checkbox
          v-for="marketplace in marketplaces"
          v-model="marketplaceFilter"
          :key="marketplace.value"
          :value="marketplace.id"
          name="flavour-3a"
          @change="onSelectMarketplace"
        >
          {{ marketplace.name }}
        </b-form-checkbox>
      </b-form-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "MarketplaceFilter",
  props: {
    marketplacesSelected: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    marketplacesSelected: {
      handler: function (val) {
        this.marketplaceFilter = val;
      },
      deep: true,
    },
  },
  data() {
    return {
      marketplaces: [],
      marketplaceFilter: [],
      isLoading: false,
    };
  },
  methods: {
    getMarketplacesList() {
      this.isLoading = true;
      this.$store
        .dispatch("GET_MARKETPLACE_LIST")
        .then((response) => {
          this.marketplaces = response;
          this.marketplaceFilter = this.marketplacesSelected;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    onSelectMarketplace() {
      this.$emit("selectedMarketplace", this.marketplaceFilter);
    },
  },
  mounted() {
    this.getMarketplacesList();
  },
};
</script>

<style></style>
