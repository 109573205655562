import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    exportLocations: {
      status: null,
      data: [],
      error: null,
    },

    exportLocation: {
      status: null,
      data: {},
      error: null,
    },
  };
}
const actions = {
  [constants.GET_EXPORT_LOCATION]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_EXPORT_LOCATION);

      const response = await axios.get("exportLocation/getExportLocation", {
        params: payload,
      });
      commit(constants.GET_EXPORT_LOCATION_SUCCESS, response);
      commit;
      return response;
    } catch (error) {
      commit(constants.GET_EXPORT_LOCATION_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_EXPORT_LOCATION]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_EXPORT_LOCATION);
      const response = await axios.post(
        "exportLocation/createAndUpdate",
        payload
      );
      commit(constants.POST_EXPORT_LOCATION_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_EXPORT_LOCATION_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_EXPORT_LOCATION]: (state) => {
    state.exportLocations.data = [];
    state.exportLocations.status = "fetching";
  },
  [constants.GET_EXPORT_LOCATION_SUCCESS]: (state, response) => {
    state.exportLocations.status = "success";
    state.exportLocations.data = response;
    state.states.data = response.states;
  },
  [constants.GET_EXPORT_LOCATION_ERROR]: (state, error) => {
    state.exportLocations.status = "error";
    state.exportLocations.error = error;
  },
  [constants.POST_EXPORT_LOCATION]: (state) => {
    state.exportLocation.data = {};
    state.exportLocation.status = "fetching";
  },
  [constants.POST_EXPORT_LOCATION_SUCCESS]: (state, response) => {
    state.exportLocation.status = "success";
    state.exportLocation.data = response;
  },
  [constants.POST_EXPORT_LOCATION_ERROR]: (state, error) => {
    state.exportLocation.status = "error";
    state.exportLocation.error = error;
  },
};
export default {
  state: getDefaultState(),
  actions,
  mutations,
};
