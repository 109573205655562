import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    vehicles: {
      status: null,
      data: [],
      error: null,
    },
    exportLoads: {
      status: null,
      data: [],
      error: null,
    },
    exportLoad: {
      status: null,
      data: {},
      error: null,
    },
    exportLocations: {
      status: null,
      data: [],
      error: null,
    },
    vehiclePort: {
      status: null,
      data: {},
      error: null,
    },
    foreignForm: {
      status: null,
      data: {},
      error: null,
    },
    companies: {
      status: null,
      data: [],
      error: null,
    },
    loadPdf: {
      status: null,
      data: {},
      error: null,
    },
    filesByEmail: {
      status: null,
      data: {},
      error: null,
    },
  };
}
const actions = {
  [constants.GET_VEHICLES_LOAD]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_VEHICLES_LOAD);

      const response = await axios.get("exportLoad/listVehicles", {
        params: payload,
      });
      commit(constants.GET_VEHICLES_LOAD_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLES_LOAD_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_LOAD]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_LOAD);
      const response = await axios.post("exportLoad/createAndUpdate", payload);
      commit(constants.POST_LOAD_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_LOAD_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_EXPORT_LOCATIONS_LIST]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_EXPORT_LOCATIONS_LIST);
      const response = await axios.get("exportLoad/listLocations", {
        params: payload,
      });
      commit(constants.GET_EXPORT_LOCATIONS_LIST_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_EXPORT_LOCATIONS_LIST_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_EXPORT_LOADS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_EXPORT_LOADS);
      const response = await axios.get("exportLoad/getExportLoad", {
        params: payload,
      });
      commit(constants.GET_EXPORT_LOADS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_EXPORT_LOADS_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_LOAD_STATUS]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_LOAD_STATUS);
      const response = await axios.put("exportLoad/changeStatus", payload);
      commit(constants.PUT_LOAD_STATUS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_LOAD_STATUS_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_EXPORT_LOAD_EXCEL]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_EXPORT_LOAD_EXCEL);
      const response = await axios.get("exportLoad/generateXls", {
        params: payload,
        responseType: "blob",
      });
      commit(constants.GET_EXPORT_LOAD_EXCEL_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_EXPORT_LOAD_EXCEL_ERROR, error);
      throw error.response;
    }
  },

  [constants.GET_EXPORT_LOAD_INFO_BY_ID]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_EXPORT_LOAD_INFO_BY_ID);
      const response = await axios.get("exportLoad/findExportLoad", {
        params: payload,
      });
      commit(constants.GET_EXPORT_LOAD_INFO_BY_ID_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_EXPORT_LOAD_INFO_BY_ID_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_VEHICLE_PORT]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_VEHICLE_PORT);
      const response = await axios.put("exportLoad/assignPort", payload);
      commit(constants.PUT_VEHICLE_PORT_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_VEHICLE_PORT_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_FOREIGN_FORM]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_FOREIGN_FORM);
      const response = await axios.post(
        "exportLoad/registerSecondForm",
        payload
      );
      commit(constants.POST_FOREIGN_FORM_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_FOREIGN_FORM_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_COMPANIES_FOREIGN]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_COMPANIES_FOREIGN);
      const response = await axios.get("exportLoad/listCompany", {
        params: payload,
      });
      commit(constants.GET_COMPANIES_FOREIGN_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_COMPANIES_FOREIGN_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_LOAD_PDF]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_LOAD_PDF);
      const response = await axios.get("exportLoad/generatePDF", {
        params: payload,
        responseType: "blob",
      });
      commit(constants.GET_LOAD_PDF_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_LOAD_PDF_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_FILES_BY_EMAIL]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_COMPANIES_FOREIGN);
      const response = await axios.get("exportLoad/sendExportLoad", {
        params: payload,
      });
      commit(constants.GET_COMPANIES_FOREIGN_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_COMPANIES_FOREIGN_ERROR, error);
      throw error.response;
    }
  },

  [constants.GET_EXPORT_LOAD_EXCEL_BY_TYPE]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_EXPORT_LOAD_EXCEL_BY_TYPE);
      const response = await axios.get("exportLoad/generateLandedXls", {
        params: payload,
        responseType: "blob",
      });
      commit(constants.GET_EXPORT_LOAD_EXCEL_BY_TYPE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_EXPORT_LOAD_EXCEL_BY_TYPE_ERROR, error);
      throw error.response;
    }
  }
};

const mutations = {
  [constants.GET_VEHICLES_LOAD]: (state) => {
    state.vehicles.data = [];
    state.vehicles.status = "fetching";
  },
  [constants.GET_VEHICLES_LOAD_SUCCESS]: (state, response) => {
    state.vehicles.status = "success";
    state.vehicles.data = response;
  },
  [constants.GET_VEHICLES_LOAD_ERROR]: (state, error) => {
    state.vehicles.status = "error";
    state.vehicles.error = error;
  },
  [constants.POST_LOAD]: (state) => {
    state.exportLoad.data = {};
    state.exportLoad.status = "fetching";
  },
  [constants.POST_LOAD_SUCCESS]: (state, response) => {
    state.exportLoad.status = "success";
    state.exportLoad.data = response;
  },
  [constants.POST_LOAD_ERROR]: (state, error) => {
    state.exportLoad.status = "error";
    state.exportLoad.error = error;
  },
  [constants.GET_EXPORT_LOCATIONS_LIST]: (state) => {
    state.exportLocations.data = [];
    state.exportLocations.status = "fetching";
  },
  [constants.GET_EXPORT_LOCATIONS_LIST_SUCCESS]: (state, response) => {
    state.exportLocations.status = "success";
    state.exportLocations.data = response;
  },
  [constants.GET_EXPORT_LOCATIONS_LIST_ERROR]: (state, error) => {
    state.exportLocations.status = "error";
    state.exportLocations.error = error;
  },
  [constants.GET_EXPORT_LOADS]: (state) => {
    state.exportLoads.data = [];
    state.exportLoads.status = "fetching";
  },
  [constants.GET_EXPORT_LOADS_SUCCESS]: (state, response) => {
    state.exportLoads.status = "success";
    state.exportLoads.data = response;
  },
  [constants.GET_EXPORT_LOADS_ERROR]: (state, error) => {
    state.exportLoads.status = "error";
    state.exportLoads.error = error;
  },
  [constants.PUT_LOAD_STATUS]: (state) => {
    state.exportLoad.data = {};
    state.exportLoad.status = "fetching";
  },
  [constants.PUT_LOAD_STATUS_SUCCESS]: (state, response) => {
    state.exportLoad.status = "success";
    state.exportLoad.data = response;
  },
  [constants.PUT_LOAD_STATUS_ERROR]: (state, error) => {
    state.exportLoad.status = "error";
    state.exportLoad.error = error;
  },
  [constants.GET_EXPORT_LOAD_EXCEL]: (state) => {
    state.exportLoad.data = {};
    state.exportLoad.status = "fetching";
  },
  [constants.GET_EXPORT_LOAD_EXCEL_SUCCESS]: (state, response) => {
    state.exportLoad.status = "success";
    state.exportLoad.data = response;
  },
  [constants.GET_EXPORT_LOAD_EXCEL_ERROR]: (state, error) => {
    state.exportLoad.status = "error";
    state.exportLoad.error = error;
  },
  [constants.GET_EXPORT_LOAD_PDF]: (state) => {
    state.exportLoad.data = {};
    state.exportLoad.status = "fetching";
  },
  [constants.GET_EXPORT_LOAD_PDF_SUCCESS]: (state, response) => {
    state.exportLoad.status = "success";
    state.exportLoad.data = response;
  },
  [constants.GET_EXPORT_LOAD_PDF_ERROR]: (state, error) => {
    state.exportLoad.status = "error";
    state.exportLoad.error = error;
  },
  [constants.GET_EXPORT_LOAD_INFO_BY_ID]: (state) => {
    state.exportLoad.data = {};
    state.exportLoad.status = "fetching";
  },
  [constants.GET_EXPORT_LOAD_INFO_BY_ID_SUCCESS]: (state, response) => {
    state.exportLoad.status = "success";
    state.exportLoad.data = response;
  },
  [constants.GET_EXPORT_LOAD_INFO_BY_ID_ERROR]: (state, error) => {
    state.exportLoad.status = "error";
    state.exportLoad.error = error;
  },
  [constants.PUT_VEHICLE_PORT]: (state) => {
    state.vehiclePort.data = {};
    state.vehiclePort.status = "fetching";
  },
  [constants.PUT_VEHICLE_PORT_SUCCESS]: (state, response) => {
    state.vehiclePort.status = "success";
    state.vehiclePort.data = response;
  },
  [constants.PUT_VEHICLE_PORT_ERROR]: (state, error) => {
    state.vehiclePort.status = "error";
    state.vehiclePort.error = error;
  },
  [constants.POST_FOREIGN_FORM]: (state) => {
    state.foreignForm.data = {};
    state.foreignForm.status = "fetching";
  },
  [constants.POST_FOREIGN_FORM_SUCCESS]: (state, response) => {
    state.foreignForm.status = "success";
    state.foreignForm.data = response;
  },
  [constants.POST_FOREIGN_FORM_ERROR]: (state, error) => {
    state.foreignForm.status = "error";
    state.foreignForm.error = error;
  },
  [constants.GET_COMPANIES_FOREIGN]: (state) => {
    state.companies.data = [];
    state.companies.status = "fetching";
  },
  [constants.GET_COMPANIES_FOREIGN_SUCCESS]: (state, response) => {
    state.companies.status = "success";
    state.companies.data = response;
  },
  [constants.GET_COMPANIES_FOREIGN_ERROR]: (state, error) => {
    state.companies.status = "error";
    state.companies.error = error;
  },
  [constants.GET_LOAD_PDF]: (state) => {
    state.loadPdf.data = {};
    state.loadPdf.status = "fetching";
  },
  [constants.GET_LOAD_PDF_SUCCESS]: (state, response) => {
    state.loadPdf.status = "success";
    state.loadPdf.data = response;
  },
  [constants.GET_LOAD_PDF_ERROR]: (state, error) => {
    state.loadPdf.status = "error";
    state.loadPdf.error = error;
  },
  [constants.GET_FILES_BY_EMAIL]: (state) => {
    state.filesByEmail.data = {};
    state.filesByEmail.status = "fetching";
  },
  [constants.GET_FILES_BY_EMAIL_SUCCESS]: (state, response) => {
    state.filesByEmail.status = "success";
    state.filesByEmail.data = response;
  },
  [constants.GET_FILES_BY_EMAIL_ERROR]: (state, error) => {
    state.filesByEmail.status = "error";
    state.filesByEmail.error = error;
  },
};
export default {
  state: getDefaultState(),
  actions,
  mutations,
};
