export const NOTE_TYPES = [
  {
    type: 'bodyshopWorkorder ',
    label: 'Bodyshop / Workorder ',
    color: '#FFD1DC'
  },
  {
    type: 'mechanicWorkorder',
    label: 'Mechanic / Workorder',
    color: '#AED6F1'

  },
  {
    type: ' moveToTransfer',
    label: ' Move to / Transfer',
    color: '#98FF98'
  },
  {
    type: 'carwash',
    label: 'Carwash',
    color: '#FFE5B4'
  },
  {
    type: 'setSellingPrice',
    label: 'Set Selling price',
    color: '#B19CD9'
  },
  {
    type: 'invoice',
    label: 'Invoice',
    color: '#ff6961'
  }

]