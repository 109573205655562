<template>
  <b-sidebar
    id="sidebar-filter-details"
    lazy
    :width="widthSidebar"
    :visible="filterSideBar.isShowing"
    shadow
    right
    class="bg-white"
    no-header-close
    header-class="text-left"
    @hidden="
      () =>
        $store.commit('setFilterSideBar', {
          isShowing: false,
        })
    "
  >
    <template v-slot:title>
      <div class="text-right h2 d-flex justify-content-start">
        <span
          class="cursor-pointer mr-3"
          @click="closeSideBar()"
          v-b-tooltip.hover
          title="Close filters"
          ><strong>X</strong>
        </span>

        Filters
      </div>
    </template>
    <b-container v-if="filterSideBar.isShowing">
      <b-card>
        <b-row>
          <b-col cols="12"> <TasksBoardFiltersFast /> </b-col>
          <b-col cols="12"> <OwnerFilter /> </b-col>
          <b-col cols="12"> <VehicleFilter /> </b-col>
          <b-col cols="12"> <CompanyFilter /> </b-col>
          <b-col cols="12"> <UserCreatedFilter /> </b-col>
          <b-col cols="12"> <TypeFilter /> </b-col>
          <b-col cols="12"> <BoardFilter /> </b-col>
        </b-row>
        <div class="d-flex justify-content-end mt-3">
          <b-button size="md" variant="" class="mx-1" @click="clearFilter()"
            >Clear</b-button
          >
          <b-button size="md" variant="success" class="mx-1" @click="applyFilters()"
            >Apply</b-button
          >
        </div>
      </b-card>
    </b-container>
  </b-sidebar>
</template>

<script>
import { mapState } from "vuex";
import OwnerFilter from "./OwnerFilter.vue";
import TasksBoardFiltersFast from "./TasksBoardFiltersFast.vue";
import VehicleFilter from "./VehicleFilter.vue";
import TypeFilter from "./TypeFilter.vue";
import CompanyFilter from "./CompanyFilter.vue";
import UserCreatedFilter from "./UserCreatedFilter.vue";
import BoardFilter from "./BoardFilter.vue";
import { GET_TASKS, SET_TASKS_FILTERS } from "../actions";
export default {
  name: "VehicleDetailsSideBar",

  components: {
    TasksBoardFiltersFast,
    OwnerFilter,
    VehicleFilter,
    TypeFilter,
    CompanyFilter,
    UserCreatedFilter,
    BoardFilter,
  },

  computed: {
    widthSidebar() {
      return this.$vssWidth > 1280 ? "40%" : this.$vssWidth <= 576 ? "100%" : "50%";
    },
    ...mapState({
      filterSideBar: (state) => state.superAdminStore.tasks.filterSideBar,
      filters: (state) => state.superAdminStore.tasks.tasks.filters,
    }),
  },
  methods: {
    applyFilters() {
      this.$store.dispatch(GET_TASKS);
      this.$store.commit("setFilterSideBar", {
        isShowing: false,
      });
    },
    clearFilter() {
      this.$store.commit(SET_TASKS_FILTERS, {
        reset: true,
      });
      this.applyFilters();
      this.$store.commit("setFilterSideBar", {
        isShowing: false,
      });
    },
    closeSideBar() {
      this.$store.commit("setFilterSideBar", {
        isShowing: false,
      });
    },

    hasFilter() {
      return true;
    },
  },
};
</script>

<style></style>
