export const NOTE_TYPES = [
    {
        type: 'general',
        label: 'General',
        color: '#FFD1DC'
    },
    {
        type: 'carfax',
        label: 'Carfax',
        color: '#AED6F1'

    },
    {
        type: 'carwash',
        label: 'Carwash',
        color: '#98FF98'
    },
    {
        type: 'cluster',
        label: 'Cluster',
        color: '#FFE5B4'
    },
    {
        type: 'lien',
        label: 'Lien',
        color: '#B19CD9'
    },
    {
        type: 'recall',
        label: 'Recall',
        color: '#C9C9C9'
    },
    {
        type: 'registration',
        label: 'Registration',
        color: '#00FFFF'
    },
    {
        type: 'transfer',
        label: 'Transfer',
        color: '#FFB347'
    },
    {
        type: 'mechanical',
        label: 'Mechanical',
        color: '#FDFFB^'
    },

    {
        type: 'sales',
        label: 'Sales',
        color: '#EAFFC2'
    },
    {
        type: 'police_book',
        label: 'Police Book',
        color: '#FF94A2'
    },
]