export const GET_TRANSPORTATION_LIST = "GET_TRANSPORTATION_LIST"
export const GET_TRANSPORTATION_LIST_SUCCESS = "GET_TRANSPORTATION_LIST_SUCCESS"
export const GET_TRANSPORTATION_LIST_ERROR = "GET_TRANSPORTATION_LIST_ERROR"

export const POST_TRANSPORTATION = "POST_TRANSPORTATION"
export const POST_TRANSPORTATION_SUCCESS = "POST_TRANSPORTATION_SUCCESS"
export const POST_TRANSPORTATION_ERROR = "POST_TRANSPORTATION_ERROR"

export const PUT_TRANSPORTATION = "PUT_TRANSPORTATION"
export const PUT_TRANSPORTATION_SUCCESS = "PUT_TRANSPORTATION_SUCCESS"
export const PUT_TRANSPORTATION_ERROR = "PUT_TRANSPORTATION_ERROR"

export const DELETE_TRANSPORTATION = "DELETE_TRANSPORTATION"
export const DELETE_TRANSPORTATION_SUCCESS = "DELETE_TRANSPORTATION_SUCCESS"
export const DELETE_TRANSPORTATION_ERROR = "DELETE_TRANSPORTATION_ERROR"

export const MARK_TRANSPORTATION_AS_PAID = "MARK_TRANSPORTATION_AS_PAID"
export const MARK_TRANSPORTATION_AS_PAID_SUCCESS = "MARK_TRANSPORTATION_AS_PAID_SUCCESS"
export const MARK_TRANSPORTATION_AS_PAID_ERROR = "MARK_TRANSPORTATION_AS_PAID_ERROR"


export const SET_TRANSPORTATION_FILTER = "SET_TRANSPORTATION_FILTER"

export const GET_TRANSPORTATION_EXCEL = "GET_TRANSPORTATION_EXCEL"
export const GET_TRANSPORTATION_EXCEL_SUCCESS = "GET_TRANSPORTATION_EXCEL_SUCCESS"
export const GET_TRANSPORTATION_EXCEL_ERROR = "GET_TRANSPORTATION_EXCEL_ERROR"

export const POST_TRANSPORTATION_RETURN = "POST_TRANSPORTATION_RETURN"
export const POST_TRANSPORTATION_RETURN_SUCCESS = "POST_TRANSPORTATION_RETURN_SUCCESS"
export const POST_TRANSPORTATION_RETURN_ERROR = "POST_TRANSPORTATION_RETURN_ERROR"