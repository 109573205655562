import * as constants from "./actions";
import axios from "@/plugins/axios.js";
import notes from "./notes/store.js";
function getDefaultState() {
  return {
    recalls: {
      status: null,
      data: [],
      error: null,
    },
    recall: {
      status: null,
      data: {},
      error: null,
    },
  };
}

const actions = {
  [constants.GET_RECALLS]: async ({ commit }, id) => {
    try {
      commit(constants.GET_RECALLS);
      const response = await axios.get(`recall/getRecalls?vehicle=${id}`);
      commit(constants.GET_RECALLS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_RECALLS_ERROR, error);
      throw error.response;
    }
  },

  [constants.POST_RECALL]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_RECALL);
      const response = await axios.post("recall/create", payload);
      commit(constants.POST_RECALL_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_RECALL_ERROR, error);
      throw error.response;
    }
  },

  [constants.POST_CHANGE_STATUS]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_CHANGE_STATUS);
      const response = await axios.post("recall/editRightButton", payload);
      commit(constants.POST_CHANGE_STATUS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_CHANGE_STATUS_ERROR, error);
      throw error.response;
    }
  },

  [constants.PUT_RECALL]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_RECALL);
      const response = await axios.post("recall/create", payload);
      commit(constants.PUT_RECALL_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_RECALL_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_RECALLS]: (state) => {
    state.recalls.status = "fetching";
    state.recalls.error = null;
  },
  [constants.GET_RECALLS_SUCCESS]: (state, data) => {
    state.recalls.status = "success";
    state.recalls.error = null;
    state.recalls.data = data;
  },
  [constants.GET_RECALLS_ERROR]: (state, error) => {
    state.recalls.status = "error";
    state.recalls.error = error;
  },
  [constants.POST_RECALL]: (state) => {
    state.recall.status = "fetching";
    state.recall.error = null;
  },
  [constants.POST_RECALL_SUCCESS]: (state, data) => {
    state.recall.status = "success";
    state.recall.error = null;
    state.recall.data = data;
  },
  [constants.POST_RECALL_ERROR]: (state, error) => {
    state.recall.status = "error";
    state.recall.error = error;
  },
  [constants.POST_CHANGE_STATUS]: (state) => {
    state.recall.status = "fetching";
    state.recall.error = null;
  },
  [constants.POST_CHANGE_STATUS_SUCCESS]: (state, data) => {
    state.recall.status = "success";
    state.recall.error = null;
    state.recall.data = data;
  },
  [constants.POST_CHANGE_STATUS_ERROR]: (state, error) => {
    state.recall.status = "error";
    state.recall.error = error;
  },

  [constants.PUT_RECALL]: (state) => {
    state.recall.status = "fetching";
    state.recall.error = null;
  },
  [constants.PUT_RECALL_SUCCESS]: (state, data) => {
    state.recall.status = "success";
    state.recall.error = null;
    state.recall.data = data;
  },
  [constants.PUT_RECALL_ERROR]: (state, error) => {
    state.recall.status = "error";
    state.recall.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
  modules: { notes },
};
