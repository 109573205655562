import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    note: {
      status: null,
      data: null,
      error: null,
    },
    notes: {
      status: null,
      data: [],
      error: null,
    },
  };
}

const actions = {
  [constants.POST_WORK_ORDER_NOTE]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_WORK_ORDER_NOTE);
      const response = await axios.post("workOrder/createNote", payload);
      commit(constants.POST_WORK_ORDER_NOTE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_WORK_ORDER_NOTE_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_WORK_ORDER_NOTES]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_WORK_ORDER_NOTES);
      const response = await axios.get("workOrder/getNotes", {
        params: payload,
      });
      commit(constants.GET_WORK_ORDER_NOTES_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_WORK_ORDER_NOTES_ERROR, error);
      throw error.response;
    }
  },
  [constants.DELETE_WORK_ORDER_NOTE]: async ({ commit }, payload) => {
    try {
      commit(constants.DELETE_WORK_ORDER_NOTE);
      const response = await axios.delete("workOrder/deleteNote", {
        params: payload,
      });
      commit(constants.DELETE_WORK_ORDER_NOTE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.DELETE_WORK_ORDER_NOTE_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.POST_WORK_ORDER_NOTE]: (state) => {
    state.note.status = "fetching";
    state.note.data = {};
  },
  [constants.POST_WORK_ORDER_NOTE_SUCCESS]: (state, response) => {
    state.note.status = "success";
    state.note.data = response;
  },
  [constants.POST_WORK_ORDER_NOTE_ERROR]: (state, error) => {
    state.note.status = "error";
    state.note.error = error;
  },
  [constants.GET_WORK_ORDER_NOTES]: (state) => {
    state.notes.status = "fetching";
    state.notes.data = [];
  },
  [constants.GET_WORK_ORDER_NOTES_SUCCESS]: (state, response) => {
    state.notes.status = "success";
    state.notes.data = response.workOrderNote;
  },
  [constants.GET_WORK_ORDER_NOTES_ERROR]: (state, error) => {
    state.notes.status = "error";
    state.notes.error = error;
  },
  [constants.DELETE_WORK_ORDER_NOTE]: (state) => {
    state.note.status = "fetching";
    state.note.data = {};
  },
  [constants.DELETE_WORK_ORDER_NOTE_SUCCESS]: (state, response) => {
    state.note.status = "success";
    state.note.data = response;
  },
  [constants.DELETE_WORK_ORDER_NOTE_ERROR]: (state, error) => {
    state.note.status = "error";
    state.note.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
