import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    vehicles: {
      status: null,
      data: [],
      error: null,
    },

    bulkTransfer: {
      status: null,
      data: {},
      error: null,
    },

    bulkTransferXLS: {
      status: null,
      data: {},
      error: null,
    }

  };
}
const actions = {
  [constants.GET_VEHICLES_BULK_TRANSFER]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_VEHICLES_BULK_TRANSFER);
      const response = await axios.get("transfer/listVehicle", {
        params: payload,
      });
      commit(constants.GET_VEHICLES_BULK_TRANSFER_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLES_BULK_TRANSFER_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_BULK_TRANSFER]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_BULK_TRANSFER);
      const response = await axios.post("transfer/bulkRegister", payload);
      commit(constants.POST_BULK_TRANSFER_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_BULK_TRANSFER_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_QUICK_BULK_TRANSFER]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_QUICK_BULK_TRANSFER);
      const response = await axios.post("transfer/bulkQuickRegister", payload);
      commit(constants.POST_QUICK_BULK_TRANSFER_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_QUICK_BULK_TRANSFER_ERROR, error);
      throw error.response;
    }
  },

  [constants.POST_BULK_TRANSFER_XLS]: async ({ commit }, vehicles) => {
    try {
      commit(constants.POST_BULK_TRANSFER_XLS);
      const response = await axios.get("transfer/bulkgenerateXls", {
        params: vehicles,
        responseType: "blob",

      });
      commit(constants.POST_BULK_TRANSFER_XLS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_BULK_TRANSFER_XLS_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_VEHICLES_BULK_TRANSFER]: (state) => {
    state.vehicles.data = [];
    state.vehicles.status = "fetching";
  },
  [constants.GET_VEHICLES_BULK_TRANSFER_SUCCESS]: (state, response) => {
    state.vehicles.status = "success";
    state.vehicles.data = response.vehicles;
  },
  [constants.GET_VEHICLES_BULK_TRANSFER_ERROR]: (state, error) => {
    state.vehicles.status = "error";
    state.vehicles.error = error;
  },
  [constants.POST_BULK_TRANSFER]: (state) => {
    state.bulkTransfer.data = {};
    state.bulkTransfer.status = "fetching";
  },
  [constants.POST_BULK_TRANSFER_SUCCESS]: (state, response) => {
    state.bulkTransfer.status = "success";
    state.bulkTransfer.data = response;
  },
  [constants.POST_BULK_TRANSFER_ERROR]: (state, error) => {
    state.bulkTransfer.status = "error";
    state.bulkTransfer.error = error;
  },
  [constants.POST_QUICK_BULK_TRANSFER]: (state) => {
    state.bulkTransfer.data = {};
    state.bulkTransfer.status = "fetching";
  },
  [constants.POST_QUICK_BULK_TRANSFER_SUCCESS]: (state, response) => {
    state.bulkTransfer.status = "success";
    state.bulkTransfer.data = response;
  },
  [constants.POST_QUICK_BULK_TRANSFER_ERROR]: (state, error) => {
    state.bulkTransfer.status = "error";
    state.bulkTransfer.error = error;
  },
  [constants.POST_BULK_TRANSFER_XLS]: (state) => {
    state.bulkTransferXLS.data = {};
    state.bulkTransferXLS.status = "fetching";
  },
  [constants.POST_BULK_TRANSFER_XLS_SUCCESS]: (state, response) => {
    state.bulkTransferXLS.status = "success";
    state.bulkTransferXLS.data = response;
  },
  [constants.POST_BULK_TRANSFER_XLS_ERROR]: (state, error) => {
    state.bulkTransferXLS.status = "error";
    state.bulkTransferXLS.error = error;
  },
};
export default {
  state: getDefaultState(),
  actions,
  mutations,
};
