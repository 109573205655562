<template>
  <div
    v-touch:touchhold="touchHoldHandler"
    @contextmenu.prevent="
      vehicle.vehicleFlags.creditRequest == 1
        ? $refs.menuCreditRequest.open($event, 'Payload')
        : ''
    "
  >
    <slot></slot>
    <ContextMenu
      v-if="vehicle.vehicleFlags.creditRequest == 1"
      ref="menuCreditRequest"
    >
      <template slot-scope>
        <ContextMenuItem @click.native="markCreditRequestAsComplete()">
          Mark as paid
        </ContextMenuItem>
      </template>
    </ContextMenu>
  </div>
</template>

<script>
import { superAdminMixin } from "../../mixins";
import ContextMenu from "../contextMenu/ContextMenu.vue";
import ContextMenuItem from "../contextMenu/ContextMenuItem.vue";
import { PUT_MARK_AS_PAID } from "./actions";
export default {
  name: "FinancialCreditRequestContextMenu",
  props: ["vehicle", "refreshData"],
  mixins: [superAdminMixin],
  components: {
    ContextMenu,
    ContextMenuItem,
  },

  methods: {
    markCreditRequestAsComplete() {
      const payload = {
        vehicleId: this.vehicle.id,
        date: Date().toISOString().split("T")[0],
        creditRequestId: this.vehicle.creditRequestsId,
      };

      this.$store
        .dispatch(PUT_MARK_AS_PAID, payload)
        .then(() => {
          this.toastMessage("Credit marked as paid", "Success", "success");
          this.$refs.menuCreditRequest.close();
          this.updateVehicleFlag(this.vehicle.id, "creditRequest", 3);
        })
        .catch(() => {
          this.toastMessage("Credit  not mark as paid", "Warning", "warning");
        });
    },
    touchHoldHandler($event) {
      this.$refs.menuCreditRequest.open($event, "Payload");
    },
  },
};
</script>

<style>
</style>