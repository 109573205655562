<template>
  <b-container class="pt-4">
    <div class="">
      <b-table
        show-empty
        bordered
        small
        stacked="md"
        head-variant="light"
        :items="
          dailyReports.length > 0 ? dailyReports : defaultConfigurationReports
        "
        :fields="fields"
        :busy="isLoading"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" variant="yellow" />
          </div>
        </template>
        <template #cell(receiveDaily)="{ item }">
          <div class="d-flex justify-content-center">
            <b-form-checkbox
              :checked="item.receiveDaily == 1 ? true : false"
              @change="(val) => changeReportConfiguration(val, item)"
            />
          </div>
        </template>
        <template #cell(actions)="{ item }">
          <div class="d-flex justify-content-center">
            <b-spinner v-if="isLoadingExcelReport" variant="yellow" small />
            <font-awesome-icon
              v-else
              class="cursor-pointer mt-1"
              :icon="['far', 'file-excel']"
              size="xl"
              v-b-tooltip.hover
              title="Download excel"
              @click="getDailyReportExcel(getType(item), item)"
            />
          </div>
        </template>
      </b-table>
    </div>
  </b-container>
</template>

<script>
import { mapState } from "vuex";
import { generalMixin } from "../../mixin";
import {
  GET_DAILY_REPORTS,
  GET_DAILY_REPORT_FILE_EXCEL,
  POST_DAILY_REPORTS_CONFIGURATION,
} from "./actions";
export default {
  name: "DailyReports",
  mixins: [generalMixin],
  computed: {
    ...mapState({
      dailyReports: (state) => state.superAdminStore.reports.dailyReports.data,
      isLoading: (state) =>
        state.superAdminStore.reports.dailyReports.status === "fetching",
    }),
  },
  data() {
    return {
      fields: [
        { key: "reportName", label: "Name", sortable: true },
        { key: "receiveDaily", label: "Receive Daily", sortable: true },
        { key: "actions", label: "Actions" },
      ],
      defaultConfigurationReports: [
        {
          reportName: "Inventory",
          receiveDaily: 1,
        },
        {
          reportName: "receivables US",
          receiveDaily: 1,
        },
        {
          reportName: "receivables CA",
          receiveDaily: 1,
        },
      ],
      isLoadingExcelReport: false,
    };
  },
  created() {
    this.$store.commit("setAddFunction", null);
  },
  mounted() {
    this.getDailyReports();
  },
  methods: {
    getDailyReports() {
      this.$store.dispatch(GET_DAILY_REPORTS).then(() => {});
    },
    changeReportConfiguration(val, item) {
      let reports = JSON.parse(JSON.stringify(this.dailyReports));
      reports.forEach((report) => {
        if (report.id == item.id) {
          report.receiveDaily = val ? 1 : 0;
        }
      });
      this.$store
        .dispatch(POST_DAILY_REPORTS_CONFIGURATION, {
          reports,
        })
        .then(() => {
          this.makeToast(
            "Success",
            "Configuration updated successfully",
            "success"
          );
        })
        .catch(() => {
          this.makeToast(
            "Error",
            "Internal server error, try again later",
            "warning"
          );
        });
    },

    getDailyReportExcel(type, item, id = null) {
      this.isLoadingExcelReport = true;
      const payload = {
        type,
      };
      if (id) {
        payload.id = id;
      }

      this.$store
        .dispatch(GET_DAILY_REPORT_FILE_EXCEL, payload)
        .then((response) => {
          if (!response.link) {
            /* wait 2 seconds */
            setTimeout(() => {
              this.getDailyReportExcel(type, item, response.id);
            }, 2000);
          } else {
            window.open(response.link, "_blank");
            this.isLoadingExcelReport = false;
          }
        })
        .catch(() => {
          this.isLoadingExcelReport = false;
          this.makeToast(
            "Error",
            "Internal server error, try again later",
            "warning"
          );
        });
    },
    formatFileName(module) {
      let fileName = "";
      const today = new Date();
      fileName = `${module}-${today.getFullYear()}-${today.getMonth()}-${today.getDate()}-${today.getHours()}-${today.getMinutes()}-${today.getSeconds()}`;
      return fileName;
    },
    getType(report) {
      switch (report.reportName) {
        case "Inventory":
          return 1;
        case "receivables CA":
          return 2;
        case "receivables US":
          return 3;
        default:
          break;
      }
    },
  },
};
</script>

<style>
</style>