<template>
  <b-modal
    id="recall-modal"
    ref="modal"
    centered
    no-close-on-backdrop
    scrollable
    size="lg"
    title="Recalls"
    @show="getRecalls()"
    @hidden="getVehiclesInfo()"
  >
    <VehicleDetails
      v-if="vehicle"
      :vehicle="vehicle"
      :color="vehicle.vehicleInformation.color"
    />
    <RecallRegistrationForm
      v-if="userHasPermission('recall', 'write')"
      :vehicle="vehicle"
      :refreshData="getRecalls"
    />

    <b-list-group v-if="recalls.status == 'fetching'" class="mt-2">
      <b-list-group-item class="text-center">
        <b-spinner variant="yellow" />
      </b-list-group-item>
    </b-list-group>
    <b-list-group v-else class="mt-2 mb-2 note-list" tag="ul">
      <b-list-group-item
        v-if="recalls.data.data && !recalls.data.data.length"
        class="text-center"
      >
        <b>No recalls to show</b>
      </b-list-group-item>

      <b-list-group-item
        v-for="(recall, i) in recalls.data.data"
        :key="i"
        class="flex-column align-items-start"
        tag="li"
      >
        <RecallCard :recall="recall" :refreshData="getRecalls" />
      </b-list-group-item>
    </b-list-group>

    <template v-slot:modal-footer="{ close }">
      <b-pagination
        :value="recalls.data.currentPage"
        :total-rows="recalls.data.total"
        :per-page="recalls.data.perPage"
        class="mt-2"
        @change="changePage($event)"
      />
      <div class="justify-content-end">
        <b-button
          variant="outline-secondary"
          class="mr-2"
          @click="() => close()"
        >
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { generalMixin } from "@/modules/mixin.js";
import { GET_RECALLS } from "./actions";
import RecallRegistrationForm from "./RegistrationForm";
import { superAdminMixin } from "@/modules/superAdmin/mixins.js";
import { mapState } from "vuex";
import RecallCard from "./RecallCard.vue";
import VehicleDetails from "@/modules/superAdmin/components/VehicleDetails.vue";

export default {
  components: { RecallRegistrationForm, RecallCard, VehicleDetails },
  mixins: [generalMixin, superAdminMixin],
  computed: {
    ...mapState({
      recalls: (state) => state.superAdminStore.componentStore.recall.recalls,
      recall: (state) =>
        state.superAdminStore.componentStore.recall.recall.data.data,
      vehicle: (state) => state.superAdminStore.vehicle,
    }),
  },
  data() {
    return {
      fields: [
        { key: "transactionType", label: "Transaction type" },
        { key: "paymentMethod", label: "Payment method" },
        { key: "transactionNumber", label: "Transaction number" },
        { key: "accountTime", label: "Account time" },
        {
          key: "actions",
          label: "Actions",
          note: false,
          details: true,
          validation: false,
        },
      ],
      options: {
        assign: true,
        rolFilter: false,
        add: false,
        fun: this.getData,
      },
      paymentItem: {},
    };
  },
  methods: {
    getRecalls() {
      this.$store
        .dispatch(GET_RECALLS, this.vehicle.id)
        .then(() => {})
        .catch(() => {
          this.toastMessage(
            "Recalls could not be obtain",
            "Warning",
            "warning"
          );
        });
    },
    changePage(page) {
      this.$store
        .dispatch(GET_RECALLS, this.vehicle.id, page)
        .then(() => {})
        .catch(() => {
          this.toastMessage(
            "Recalls could not be obtain",
            "Warning",
            "warning"
          );
        });
    },

    hideModal() {
      this.$bvModal.hide("registerPaymentModal");
    },
  },
};
</script>

<style></style>
