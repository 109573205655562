<template>
  <b-modal
    id="lien-modal"
    ref="modal"
    centered
    no-close-on-backdrop
    scrollable
    size="lg"
    title="Liens"
    @show="getLiens()"
    @hidden="getVehiclesInfo()"
  >
    <VehicleDetails
      v-if="vehicle"
      :vehicle="vehicle"
      :color="vehicle.vehicleInformation.color"
    />
    <LienRegistrationForm
      v-if="userHasPermission('liens', 'write')"
      :vehicle="vehicle"
      :refreshData="getLiens"
    />

    <b-list-group v-if="liens.status == 'fetching'" class="mt-2">
      <b-list-group-item class="text-center">
        <b-spinner variant="yellow" />
      </b-list-group-item>
    </b-list-group>
    <b-list-group v-else class="mt-2 mb-2 note-list" tag="ul">
      <b-list-group-item
        v-if="liens.data.data && !liens.data.data.length"
        class="text-center"
      >
        <b>No liens to show</b>
      </b-list-group-item>

      <b-list-group-item
        v-for="(lien, i) in liens.data.data"
        :key="i"
        class="flex-column align-items-start"
        tag="li"
      >
        <LienCard :lien="lien" :refreshData="getLiens" />
      </b-list-group-item>
    </b-list-group>

    <template v-slot:modal-footer="{ close }">
      <b-pagination
        :value="liens.data.currentPage"
        :total-rows="liens.data.total"
        :per-page="liens.data.perPage"
        class="mt-2"
        @change="changePage($event)"
      />
      <div class="justify-content-end">
        <b-button
          variant="outline-secondary"
          class="mr-2"
          @click="() => close()"
        >
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { generalMixin } from "@/modules/mixin.js";
import { GET_LIENS } from "./actions";
import LienRegistrationForm from "./LienRegistrationForm.vue";
import { superAdminMixin } from "@/modules/superAdmin/mixins.js";
import { mapState } from "vuex";
import LienCard from "./LienCard.vue";
import VehicleDetails from "@/modules/superAdmin/components/VehicleDetails.vue";

export default {
  components: { LienRegistrationForm, LienCard, VehicleDetails },
  mixins: [generalMixin, superAdminMixin],
  computed: {
    ...mapState({
      liens: (state) => state.superAdminStore.componentStore.liens.liens,
      lien: (state) =>
        state.superAdminStore.componentStore.liens.lien.data.data,
      vehicle: (state) => state.superAdminStore.vehicle,
    }),
  },
  data() {
    return {
      fields: [
        { key: "transactionType", label: "Transaction type" },
        { key: "paymentMethod", label: "Payment method" },
        { key: "transactionNumber", label: "Transaction number" },
        { key: "accountTime", label: "Account time" },
        {
          key: "actions",
          label: "Actions",
          note: false,
          details: true,
          validation: false,
        },
      ],
      options: {
        assign: true,
        rolFilter: false,
        add: false,
        fun: this.getData,
      },
      paymentItem: {},
    };
  },
  methods: {
    getLiens() {
      this.$store
        .dispatch(GET_LIENS, this.vehicle.id)
        .then(() => {})
        .catch(() => {
          this.toastMessage("Liens could not be obtain", "Warning", "warning");
        });
    },
    changePage(page) {
      this.$store
        .dispatch(GET_LIENS, this.vehicle.id, page)
        .then(() => {})
        .catch(() => {
          this.toastMessage("Liens could not be obtain", "Warning", "warning");
        });
    },
  },
};
</script>

<style></style>
