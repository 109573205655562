<template>
  <div class="container-fluid">
    <div class="pt-3">
      <b-table
        show-empty
        bordered
        small
        stacked="md"
        head-variant="light"
        :items="users.data"
        :fields="fields"
        :busy="usersStatus === 'fetching'"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" variant="yellow" />
          </div>
        </template>
        <template #cell(customerDelivers)="{ item }">
          <div class="d-flex justify-content-center">
            <b-form-checkbox
              :checked="item.toBeDelivered === 1"
              @change="(val) => toggleCustomerDeliver(item)"
            />
          </div>
        </template>
        <template #cell(customerPicksUp)="{ item }">
          <div class="d-flex justify-content-center">
            <b-form-checkbox
              :checked="item.toBePickup === 1"
              @change="(val) => toggleCustomerPickup(item)"
            />
          </div>
        </template>
        <template #cell(actions)="{ item }">
          <div class="d-flex justify-content-center">
            <b-icon
              v-b-tooltip.hover
              title="Details"
              icon="eye"
              class="h5 mx-1 cursor-pointer"
              @click="editItem(item)"
            />
            <font-awesome-icon
              :icon="['far', 'location-dot']"
              class="mx-1 cursor-pointer"
              @click="goToLocation(item)"
            />
          </div>
        </template>
      </b-table>
      <b-pagination
        v-if="usersStatus !== 'fetching' && users.data.length > 0"
        align="end"
        :value="users.currentPage"
        :total-rows="users.total"
        :per-page="users.perPage"
        class="mb-2"
        @change="changePage"
      />
    </div>

    <b-modal
      id="detailsModal"
      ref="detailsModal"
      size="lg"
      hide-header
      body-class="pl-0 pr-0"
      ok-disabled
    >
      <UserDetails :user-item="userItem" />
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="danger"
            size="sm"
            class="float-right"
            @click="$bvModal.hide('detailsModal')"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="clientModal"
      ref="clientModal"
      size="lg"
      hide-footer
      body-class="pl-0 pr-0"
      ok-disabled
    >
      <ClientForm
        :refresh-data="getData"
        :hide-modal="hideModal"
        :user-item="userItem"
      />
    </b-modal>
  </div>
</template>
<script>
import { mapState } from "vuex";

/* import TableBase from "@/modules/shared/table.vue";
 */ import UserDetails from "@/modules/buyer/Details.vue";
import { GET_USERS_COMPANY } from "./actions";
import ClientForm from "@/modules/vehicle/components/Clients/Form.vue";
import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../../superAdmin/mixins";
export default {
  components: { UserDetails, ClientForm },
  mixins: [generalMixin, superAdminMixin],
  computed: {
    ...mapState({
      users: (state) => state.adminStore.users.users.data,
      usersStatus: (state) => state.adminStore.users.users.status,
    }),
  },

  data() {
    return {
      fields: [
        { key: "companyId", label: "ID" },
        { key: "companyName", label: "Company name" },
        { key: "state", label: "State" },
        { key: "city", label: "City" },
        { key: "officePhoneNumber", label: "Office phone number" },
        { key: "customerDelivers", label: "Customer delivers" },
        { key: "customerPicksUp", label: "Customer picks up" },
        {
          key: "actions",
          label: "Actions",
          note: false,
          details: true,
          validation: false,
          locations: true,
        },
      ],
      options: {
        assign: true,
        rolFilter: false,
        add: false,
        fun: this.getData,
      },
      userItem: {},
    };
  },
  mounted() {
    this.$store.commit(
      "setAddFunction",
      this.userHasPermission("companies", "write") ? this.showModal : null
    );
    this.$store.commit("setSearchFunction", this.getData);
    this.getData();
  },
  methods: {
    getData(filter = "") {
      const payload = {
        search: filter,
        status: 4,
      };
      this.$store
        .dispatch(GET_USERS_COMPANY, payload)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    editItem(item) {
      this.userItem = item;
      this.$bvModal.show("clientModal");
    },
    changePage(page, filter = "") {
      this.isBusy = true;
      const payload = {
        search: filter,
        page: page,
      };
      this.$store
        .dispatch(GET_USERS_COMPANY, payload)
        .then((response) => {
          this.data = response;
          this.isBusy = false;
        })
        .catch(() => {
          this.$root.$bvToast.toast("Warning", {
            title: "Warning",
            variant: "warning",
          });
        });
      this.isBusy = false;
    },
    showModal() {
      this.userItem = {};
      this.$bvModal.show("clientModal");
    },
    hideModal() {
      this.$bvModal.hide("clientModal");
    },
    goToLocation(item) {
      this.$router.push({
        name: "superadmin.locations",
        params: { id: item.id, type: "company" },
      });
    },
    toggleCustomerPickup(item) {
      const payload = {
        ...item,
      };
      payload.toBePickup = item.toBePickup === 1 ? 0 : 1;
      this.updateCompany(payload, "Customer picks up");
      this.updateStore(item.id, "toBePickup", payload.toBePickup);
    },
    toggleCustomerDeliver(item) {
      const payload = {
        ...item,
      };

      payload.toBeDelivered = item.toBeDelivered === 1 ? 0 : 1;
      this.updateCompany(payload, "Customer delivers");
      this.updateStore(item.id, "toBeDelivered", payload.toBeDelivered);
    },
    updateCompany(payload, message = "Success") {
      this.$store.dispatch("POST_DEALER", payload).then(() => {
        this.$root.$bvToast.toast(message + " updated successfully", {
          title: "Success",
          variant: "success",
          duration: 500,
        });
      });
    },
    updateStore(id, field, value) {
      console.log(this.users.data);
      let usersToFindIndex = [...this.users.data];

      const index = usersToFindIndex.findIndex((user) => user.id == id);
      this.$store.commit("updateUser", {
        index: index,
        field: field,
        value: value,
      });
    },
  },
};
</script>
