export const GET_BOARD_LOGISTIC = "GET_BOARD_LOGISTIC"
export const GET_BOARD_LOGISTIC_SUCCESS = "GET_BOARD_LOGISTIC_SUCCESS"
export const GET_BOARD_LOGISTIC_ERROR = "GET_BOARD_LOGISTIC_ERROR"

export const GET_BOARD_ADMIN = "GET_BOARD_ADMIN"
export const GET_BOARD_ADMIN_SUCCESS = "GET_BOARD_ADMIN_SUCCESS"
export const GET_BOARD_ADMIN_ERROR = "GET_BOARD_ADMIN_ERROR"

export const GET_BOARD_BUSINESS = "GET_BOARD_BUSINESS"
export const GET_BOARD_BUSINESS_SUCCESS = "GET_BOARD_BUSINESS_SUCCESS"
export const GET_BOARD_BUSINESS_ERROR = "GET_BOARD_BUSINESS_ERROR"

export const GET_BOARD_EXPORT = "GET_BOARD_EXPORT"
export const GET_BOARD_EXPORT_SUCCESS = "GET_BOARD_EXPORT_SUCCESS"
export const GET_BOARD_EXPORT_ERROR = "GET_BOARD_EXPORT_ERROR"

export const GET_BOARD_YARD = "GET_BOARD_YARD"
export const GET_BOARD_YARD_SUCCESS = "GET_BOARD_YARD_SUCCESS"
export const GET_BOARD_YARD_ERROR = "GET_BOARD_YARD_ERROR"

export const GET_BOARD_SALES = "GET_BOARD_SALES"
export const GET_BOARD_SALES_SUCCESS = "GET_BOARD_SALES_SUCCESS"
export const GET_BOARD_SALES_ERROR = "GET_BOARD_SALES_ERROR"

export const GET_BOARD_ACCOUNTING = "GET_BOARD_ACCOUNTING"
export const GET_BOARD_ACCOUNTING_SUCCESS = "GET_BOARD_ACCOUNTING_SUCCESS"
export const GET_BOARD_ACCOUNTING_ERROR = "GET_BOARD_ACCOUNTING_ERROR"