import { ifAuthenticated } from "@/router/navigationGuards";

import AuthLayout from '@/views/Pages/AuthLayout.vue';
import LoginForm from "./login/LoginForm.vue";
import ForgotPassword from "./forgotPassword/ForgotPassword.vue";
import RecoverPassword from "./forgotPassword/RecoverPassword.vue";
import Corporation from "./corporation/SelectCorporationView.vue";
const routes = [
  {
    path: "",
    name: "",
    component: AuthLayout,
    redirect: "/auth/login"
  },
  {

    path: "/auth",
    name: "auth",
    component: AuthLayout,
    children: [
      {
        path: "login",
        name: "auth.login",
        component: LoginForm,
        beforeEnter: (to, from, next) => {
          !ifAuthenticated(to, from, next);
        },
      },
      {
        path: "forgotPassword",
        name: "auth.forgotPassword",
        component: ForgotPassword,
      },
      {
        path: "password/:token",
        name: "auth.recoverPassword",
        component: RecoverPassword,
      },
      {
        path: "corporation",
        name: "auth.corporation",
        component: Corporation,
        beforeEnter: (to, from, next) => {
          ifAuthenticated(to, from, next);
        },
      },
    ],
  },
];

export default routes;
