var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-alert',{attrs:{"dismissible":"","variant":"warning","show":_vm.vinNumbersNotFound.length > 0 ||
        _vm.vinNumbersSold.length > 0 ||
        _vm.vinNumbersIncomplete > 0}},[(_vm.vinNumbersNotFound.length)?_c('div',[_c('strong',{staticClass:"pt-3"},[_vm._v("Vin numbers not found:")]),_vm._l((_vm.vinNumbersNotFound),function(item){return _c('div',{key:item},[_c('span',{staticClass:"pl-2"},[_vm._v(" "+_vm._s(item)+" ")]),_c('br')])})],2):_vm._e(),(_vm.vinNumbersSold.length)?_c('div',[_c('strong',{staticClass:"pt-3"},[_vm._v("Vin numbers sold:")]),_vm._l((_vm.vinNumbersSold),function(item){return _c('div',{key:item},[_c('span',{staticClass:"pl-2"},[_vm._v(" "+_vm._s(item)+" ")]),_c('br')])})],2):_vm._e(),(_vm.vinNumbersIncomplete.length)?_c('div',[_c('strong',{staticClass:"pt-3"},[_vm._v("Vin numbers incomplete register:")]),_vm._l((_vm.vinNumbersIncomplete),function(item){return _c('div',{key:item},[_c('span',{staticClass:"pl-2"},[_vm._v(" "+_vm._s(item)+" ")]),_c('br')])})],2):_vm._e()]),_c('b-row',{staticClass:"mt-4"},[_c('b-col',{staticClass:"col-centered"},[_c('img',{staticClass:"img-logo mb-4 mx-auto d-block",attrs:{"src":require("@/assets/img/archivo.png"),"alt":"logo","accept":".xls, .xlsx, .csv"}}),(_vm.update)?_c('b-form-select',{staticClass:"mb-2",attrs:{"options":_vm.statusOptions},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v(" Please select a status ")])]},proxy:true}],null,false,1218058925),model:{value:(_vm.statusSelected),callback:function ($$v) {_vm.statusSelected=$$v},expression:"statusSelected"}}):_vm._e(),_c('table',[_c('tr',[_c('th',[_c('b-form-file',{staticClass:"input-border-yellow mb-3",attrs:{"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here...","plain":""},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1),_c('th',[_c('b-button',{staticClass:"mb-3 ml-1",attrs:{"variant":"success","block":"","disabled":!_vm.file ||
                  (_vm.update && !_vm.statusSelected) ||
                  _vm.uploadFileStatus === 'fetching' ||
                  _vm.updateVehiclesStatus === 'fetching'},on:{"click":_vm.sendFile}},[(
                  _vm.uploadFileStatus === 'fetching' ||
                    _vm.updateVehiclesStatus === 'fetching'
                )?_c('span',[_c('b-spinner',{attrs:{"label":"Spinning","small":""}})],1):(_vm.upload)?_c('span',[_vm._v("Upload")]):_c('span',[_vm._v("Update")])])],1)])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }