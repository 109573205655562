import * as constants from "../actions";
import axios from "@/plugins/axios.js";
function getDefaultState() {
  return {
    rotateImage: {
      status: null,
      data: {},
      error: null,
    },
    carHasDamages: {
      status: null,
      data: {},
      error: null,
    }
  };
}
const actions = {
  [constants.PUT_ROTATE_IMAGE]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_ROTATE_IMAGE);
      const response = await axios.put("vehicle/rotateImg", payload);
      commit(constants.PUT_ROTATE_IMAGE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_ROTATE_IMAGE_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_REGISTER_DAMAGE]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_REGISTER_DAMAGE);
      const response = await axios.put("registerDamageStatus", payload);
      commit(constants.PUT_REGISTER_DAMAGE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_REGISTER_DAMAGE_ERROR, error);
      throw error.response;
    }
  }
};

const mutations = {
  [constants.PUT_ROTATE_IMAGE]: (state) => {
    state.rotateImage.status = "fetching";
    state.rotateImage.error = null;
  },
  [constants.PUT_ROTATE_IMAGE_SUCCESS]: (state, data) => {
    state.rotateImage.status = "success";
    state.rotateImage.error = null;
    state.rotateImage.data = data;
  },
  [constants.PUT_ROTATE_IMAGE_ERROR]: (state, error) => {
    state.rotateImage.status = "error";
    state.rotateImage.error = error;
  },
  [constants.PUT_REGISTER_DAMAGE]: (state) => {
    state.carHasDamages.status = "fetching";
    state.carHasDamages.error = null;
  },
  [constants.PUT_REGISTER_DAMAGE_SUCCESS]: (state, data) => {
    state.carHasDamages.status = "success";
    state.carHasDamages.error = null;
    state.carHasDamages.data = data;
  },
  [constants.PUT_REGISTER_DAMAGE_ERROR]: (state, error) => {
    state.carHasDamages.status = "error";
    state.carHasDamages.error = error;
  }
};

export default {
  namespaced: false,
  state: getDefaultState(),
  actions,
  mutations,
};
