<template>
  <div class="container-fluid" v-if="sellChecks">
    <b-row class="mb-2">
      <b-col cols="auto">
        <h3 class="ml-3">Payments</h3>
      </b-col>
      <b-col sm="12" md="" lg="10" xl="10"> </b-col>
    </b-row>

    <b-list-group v-if="sellChecks.status == 'fetching'" class="mt-3">
      <b-list-group-item class="text-center">
        <b-spinner variant="yellow" />
      </b-list-group-item>
    </b-list-group>
    <div v-else>
      <b-row class="my-3">
        <b-col align-self="end">
          <div class="float-right">
            <b-button
              v-if="sold.sale.salePrice > 0 || sold.sale.salePriceUsd > 0"
              variant="outline-yellow"
              class="pb-2 pl-2 pt-2 pr-3 mt-1 mr-4"
              @click="showModal('registerPaymentModal')"
            >
              <b-iconstack class="m-1 text-center" font-scale="1">
                <b-icon icon="cash" font-scale="1.1" />
                <b-icon
                  icon="plus-circle-fill"
                  shift-h="25"
                  shift-v="4"
                  font-scale="0.55"
                />
              </b-iconstack>
              <span class="" />
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-list-group class="mt-2 mb-2 check-list" tag="ul">
        <b-list-group-item
          v-if="sellChecks.data && sellChecks.data.length == 0"
          class="text-center"
        >
          <b>No checks to show</b>
        </b-list-group-item>
        <b-list-group-item
          v-for="(payment, i) in sellChecks.data"
          :key="i"
          class="flex-column align-items-start"
          tag="li"
        >
          <SellCheckCard :payment="payment" />
        </b-list-group-item>
      </b-list-group>
      <b-pagination
        v-if="sellChecks.data"
        :value="sellChecks.data.currentPage"
        :total-rows="sellChecks.data.total"
        :per-page="sellChecks.data.perPage"
        class="mt-2 float-right"
        @change="changePage($event)"
      />
    </div>

    <b-modal
      id="registerPaymentModal"
      ref="registerPaymentModal"
      size="lg"
      hide-footer
      body-class="pl-0 pr-0"
      ok-disabled
      @hidden="payment = {}"
    >
      <SellCheckRegistration
        :payment="this.paymentItem"
        :refresh-data="getPayments"
        :hide-modal="hideModal"
      />
    </b-modal>
  </div>
</template>

<script>
/* import TableBase from "@/modules/shared/table.vue";
 */ import { generalMixin } from "@/modules/mixin.js";
import { GET_SELL_CHECKS, PUT_SELL_CHECK_DISABLE } from "./actions";
import { mapState } from "vuex";
import SellCheckRegistration from "./SellCheckRegistration.vue";
import SellCheckCard from "./SelCheckCard.vue";
export default {
  components: { SellCheckRegistration, SellCheckCard },
  mixins: [generalMixin],
  props: ["taxes"],
  computed: {
    ...mapState({
      vehicle: (state) => state.superAdminStore.vehicle,
      sellChecks: (state) =>
        state.superAdminStore.componentStore.sellChecks.sellChecks.data,
      sold: (state) => state.superAdminStore.componentStore.sold.sold.data,
    }),
  },
  data() {
    return {
      fields: [
        { key: "transactionType", label: "Transaction type" },
        { key: "paymentMethod", label: "Payment method" },
        { key: "transactionNumber", label: "Transaction number" },
        { key: "accountTime", label: "Account time" },
        {
          key: "actions",
          label: "Actions",
          note: false,
          details: true,
          validation: false,
        },
      ],
      options: {
        assign: true,
        rolFilter: false,
        add: false,
        fun: this.getData,
      },
      paymentItem: {},
    };
  },
  mounted() {
    this.getPayments();
  },
  methods: {
    getPayments() {
      this.$store
        .dispatch(GET_SELL_CHECKS, this.vehicle.id)
        .then(() => {})
        .catch(() => {
          this.toastMessage(
            "Payments could not be obtain",
            "Warning",
            "warning"
          );
        });
    },
    editItem(item) {
      this.paymentItem = item;
      this.showModal("registerPaymentModal");
    },
    hideModal() {
      this.$bvModal.hide("registerPaymentModal");
    },
    disablePayment(payment) {
      this.$store
        .dispatch(PUT_SELL_CHECK_DISABLE, payment)
        .then(() => {
          this.getPayments();
          this.toastMessage(
            "Payment disabled successfully",
            "Success",
            "success"
          );
        })
        .catch(() => {
          this.toastMessage(
            "Payments could not be obtain",
            "Warning",
            "warning"
          );
        });
    },

    markChecksAsDelivered(payment) {
      this.$store
        .dispatch("PUT_CONFIRMATION_CHECKS", {
          paymentId: payment.id,
        })
        .then(() => {
          this.toastMessage("Check marked as delivered", "Success", "success");
          this.getPayments();
        })
        .catch((error) => {
          console.log(error);
          this.toastMessage(
            "Checks could not be marked as delivered",
            "Warning",
            "warning"
          );
        });
    },
  },
};
</script>

<style></style>
