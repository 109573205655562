<template>
  <b-modal
    id="prices-modal"
    ref="modal"
    centered
    no-close-on-backdrop
    scrollable
    size="lg"
    title="Prices"
    @hidden="getVehiclesInfo()"
    @show="formatForm()"
    hide-footer
  >
    <VehicleDetails
      v-if="vehicle"
      :vehicle="vehicle"
      :color="vehicle.vehicleInformation.color"
    />
    <b-form v-if="vehicle" @submit.stop.prevent="updatePrices()">
      <b-form-group>
        <b-form-radio-group
          v-model="currency"
          :options="currencyOptions"
          name="radio-inline"
        ></b-form-radio-group>
      </b-form-group>

      <b-row v-if="currency == 'USD'">
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="sm" label="Selling price">
            <Money
              v-model.trim="form.price"
              class="form-control text-center"
              v-bind="moneyUSD"
              :readonly="isSold"
            />
          </b-form-group>
        </b-col>

        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="sm" label="Reserve price">
            <Money
              v-model.trim="form.reservedPrice"
              class="form-control text-center"
              v-bind="moneyUSD"
              :readonly="isSold"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="currency == 'CAD'">
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="sm" label="Selling price CAD">
            <Money
              v-model.trim="form.priceCad"
              class="form-control text-center"
              v-bind="money"
              :readonly="isSold"
            />
          </b-form-group>
        </b-col>

        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="sm" label="Reserve price CAD">
            <Money
              v-model.trim="form.reservedPriceCad"
              class="form-control text-center"
              v-bind="money"
              :readonly="isSold"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="sm">
            <b-form-checkbox
              id="checkbox-1"
              v-model="form.published"
              name="checkbox-1"
              :value="1"
              :unchecked-value="0"
              :disabled="isSold"
            >
              Post on marketplace
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="text-right mt-2">
        <b-button
          v-if="userHasPermission('prices', 'write')"
          variant="success"
          type="submit"
          :disabled="isSold || prices.status == 'fetching'"
        >
          <b-spinner v-if="prices.status == 'fetching'" variant="white" small />
          <span v-else>Submit</span>
        </b-button>
      </div>
    </b-form>
    <PricesList v-if="vehicle" />
    <TaskUpdateStatusModal
      :tasks="tasks"
      :id="1"
      @update-task="refreshAfterUpdateTask"
    />
  </b-modal>
</template>

<script>
import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "@/modules/superAdmin/mixins.js";
import { mapState } from "vuex";
import VehicleDetails from "@/modules/superAdmin/components/VehicleDetails.vue";
import { Money } from "v-money";
import { PUT_PRICES, GET_PRICES } from "./actions";
import PricesList from "./PriceList.vue";
import TaskUpdateStatusModal from "../../tasks/components/TaskUpdateStatusModal.vue";

export default {
  components: { VehicleDetails, Money, PricesList, TaskUpdateStatusModal },
  mixins: [generalMixin, superAdminMixin],
  computed: {
    ...mapState({
      vehicle: (state) => state.superAdminStore.vehicle,
      prices: (state) => state.superAdminStore.componentStore.prices.prices,
    }),
    isSold() {
      return this.vehicle ? this.vehicle.status == "Sold" : false;
    },
  },
  data() {
    return {
      form: {
        price: this.vehicle?.vehicleCostDetail?.price,
        reservedPrice: this.vehicle?.vehicleCostDetail?.reservedPrice,
        priceCad: this.vehicle?.vehicleCostDetail?.priceCad,
        reservedPriceCad: this.vehicle?.vehicleCostDetail?.reservedPriceCad,
        published: this.vehicle?.published,
      },
      money: {
        value: 0,
        decimal: ".",
        thousands: ",",
        prefix: "CAD $ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      moneyUSD: {
        value: 0,
        decimal: ".",
        thousands: ",",
        prefix: "USD $ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      currency: this.vehicle?.vehicleCostDetail?.typeCurrency || "USD",
      currencyOptions: [
        { text: "USD", value: "USD" },
        { text: "CAD", value: "CAD" },
      ],
      tasks: [],
    };
  },

  methods: {
    hideModal() {
      this.$bvModal.hide("prices-modal");
    },
    updatePrices() {
      this.$store
        .dispatch(PUT_PRICES, {
          vehicleId: this.vehicle.id,
          price: this.currency == "USD" ? this.form.price : this.form.priceCad,
          reservedPrice:
            this.currency == "USD"
              ? this.form.reservedPrice
              : this.form.reservedPriceCad,
          published: this.form.published,
          typeCurrency: this.currency,
        })
        .then((response) => {
          if (response.task.length > 0) {
            this.tasks = response.task;
            this.$bvModal.show("task-update-status-modal" + 1);
          } else {
            this.hideModal();
          }

          this.$root.$bvToast.toast("Information updated successfully", {
            title: "Success",
            variant: "success",
          });
        })
        .catch(() => {
          this.$root.$bvToast.toast("Prices could not update", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    formatForm() {
      this.form.price = this.vehicle.vehicleCostDetail.price;
      this.form.reservedPrice = this.vehicle.vehicleCostDetail.reservedPrice;
      this.form.priceCad = this.vehicle.vehicleCostDetail.priceCad;
      this.form.published = this.vehicle.published;
      this.form.reservedPriceCad =
        this.vehicle.vehicleCostDetail.reservedPriceCad;
    },
    getPrices() {
      this.$store
        .dispatch(GET_PRICES, { id: this.vehicle.id })
        .then((response) => {
          console.log(response);
        });
    },
    refreshAfterUpdateTask() {
      this.hideModal();
    },
  },
};
</script>

<style></style>
