<template>
  <div>
    <b-col cols="6">
      <b-form-group label-size="md">
        <template slot="label">
          <b-form-radio-group
            id="radio-group-2"
            v-model="form.typeCarrier"
            name="radio-sub-component"
          >
            <b-form-radio value="provider">Provider</b-form-radio>
            <b-form-radio value="driver"> Driver </b-form-radio>
            <b-form-radio value="customer"> Customer </b-form-radio>
            <span class="text-danger">*</span>
          </b-form-radio-group>
          <!--      NEQ <span class="text-danger">*</span> -->
        </template>
        <vue-single-select
          v-if="form.typeCarrier == 'provider'"
          :classes="{ input: 'form-control', dropdown: 'dropdown' }"
          v-model="form.provider"
          :options="providers"
          optionLabel="companyName"
        ></vue-single-select>
        <vue-single-select
          v-if="form.typeCarrier == 'driver'"
          :classes="{ input: 'form-control', dropdown: 'dropdown' }"
          v-model="form.driver"
          :options="drivers"
          :getOptionDescription="() => driverName"
        >
          <template slot="option" slot-scope="{ option }">
            <div>{{ option.firstName }} {{ option.lastName }}</div>
          </template>
        </vue-single-select>
      </b-form-group>
    </b-col>
  </div>
</template>

<script>
import VueSingleSelect from "vue-single-select";

export default {
  components: { VueSingleSelect },
  computed: {
    driverName() {
      if (this.form.driver) {
        return `${this.form.driver.firstName} ${this.form.driver.lastName}`;
      }
      return "";
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.onSelectDriver();
      },
    },
  },
  data() {
    return {
      form: {
        driver: null,
        provider: null,
        typeCarrier: "provider",
      },

      drivers: [],
      providers: [],
    };
  },

  methods: {
    getProviders() {
      this.$store
        .dispatch("GET_COMPANIES_LIST")
        .then((response) => {
          this.providers = response;
        })
        .catch(() => {
          this.$root.$bvToast.toast("Could not get the providers list ", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    getDrivers() {
      this.$store
        .dispatch("GET_DRIVER_LIST_NO_PAGINATE")
        .then((response) => {
          this.drivers = response.data;
        })
        .catch(() => {});
    },
    onSelectDriver() {
      if (this.form.typeCarrier == "customer") {
        this.$emit("select-driver", {
          id: null,
          type: "customer",
        });
      }
      if (this.form.driver != null && this.form.typeCarrier == "driver") {
        this.$emit("select-driver", {
          id: this.form.driver.id,
          type: "driver",
        });
      }
      if (this.form.provider != null && this.form.typeCarrier == "provider") {
        this.$emit("select-driver", {
          id: this.form.provider.id,
          type: "provider",
        });
      }
    },
  },
  mounted() {
    this.getProviders();
    this.getDrivers();
  },
};
</script>

<style>
</style>