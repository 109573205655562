export const GET_RUN_LIST = "GET_RUN_LIST";
export const GET_RUN_LIST_SUCCESS = "GET_RUN_LIST_SUCCESS";
export const GET_RUN_LIST_ERROR = "GET_RUN_LIST_ERROR";

export const GET_RUN_LIST_EXCEL = "GET_RUN_LIST_EXCEL";
export const GET_RUN_LIST_EXCEL_SUCCESS = "GET_RUN_LIST_EXCEL_SUCCESS";
export const GET_RUN_LIST_EXCEL_ERROR = "GET_RUN_LIST_EXCEL_ERROR";

export const POST_RUN_LIST = "POST_RUN_LIST";
export const POST_RUN_LIST_SUCCESS = "POST_RUN_LIST_SUCCESS";
export const POST_RUN_LIST_ERROR = "POST_RUN_LIST_ERROR";

export const GET_RUN_LIST_CATALOGS = "GET_RUN_LIST_CATALOGS";
export const GET_RUN_LIST_CATALOGS_SUCCESS = "GET_RUN_LIST_CATALOGS_SUCCESS";
export const GET_RUN_LIST_CATALOGS_ERROR = "GET_RUN_LIST_CATALOGS_ERROR";

export const GET_RUN_LIST_VEHICLES = "GET_RUN_LIST_VEHICLES";
export const GET_RUN_LIST_VEHICLES_SUCCESS = "GET_RUN_LIST_VEHICLES_SUCCESS";
export const GET_RUN_LIST_VEHICLES_ERROR = "GET_RUN_LIST_VEHICLES_ERROR";




