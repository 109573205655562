<template>
  <b-modal
    id="modal-repair-pic"
    centered
    hide-footer
    no-close-on-backdrop
    title="Repair photo"
    @hidden="refreshData()"
  >
    <form v-if="repairImage == '' || repairImage == null" @submit.prevent="upload">
      <b-form-group label="">
        <b-form-checkbox v-model="form.isRepair" name="isRepair" :disabled="status == 1">
          Mark as repair
        </b-form-checkbox>
      </b-form-group>
      <b-form-group label="Image" v-if="!form.isRepair && status != 1">
        <b-form-file
          v-model="form.file"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          accept="image/*"
        />
      </b-form-group>

      <div class="d-flex justify-content-end">
        <b-button
          v-if="status == 1"
          variant="danger"
          :disabled="isLoading"
          type="button"
          @click="deleteRepairImage()"
        >
          <b-icon v-if="!isLoading" icon="trash" />
          <b-spinner v-else small></b-spinner>
        </b-button>

        <b-button
          v-if="status != 1"
          variant="success"
          :disabled="isLoading"
          type="submit"
        >
          <b-icon v-if="!isLoading" icon="plus" />
          <b-spinner v-else small></b-spinner>
        </b-button>
      </div>
    </form>
    <div v-else>
      <img
        :src="repairImage || 'img/image_not_found.png'"
        alt="damage"
        class="img-damage img-thumbnail"
      />
      <!-- circle button in top right of the image -->
      <div>
        <b-button
          variant="danger"
          class="position-absolute mt-2 mr-3 rounded-circle"
          style="top: 0; right: 0; z-index: 1"
          size="sm"
          @click="deleteRepairImage()"
          >x</b-button
        >
      </div>
    </div>
  </b-modal>
</template>

<script>
import CompanyCarsService from "@/services/companyCarsService";
import { generalMixin } from "../../../../../mixin";

export default {
  mixins: [generalMixin],
  props: {
    damageId: {
      type: Number,
      required: false,
      default: null,
    },
    repairPhoto: {
      default: "",
      type: String,
      required: false,
    },
    status: {
      type: Number,
      required: false,
      default: null,
    },
  },
  name: "UploadRepairPhotoModal",
  data() {
    return {
      form: {
        file: null,
        isRepair: this.status == 1 ? true : false,
      },
      isLoading: false,
      repairImage: this.repairPhoto,
    };
  },
  watch: {
    repairPhoto: function (val) {
      this.repairImage = val;
    },
  },
  methods: {
    async upload() {
      const formData = new FormData();
      formData.append("repairPhoto", this.form.file);
      formData.append("id", this.damageId);
      try {
        this.isLoading = true;
        await CompanyCarsService.uploadRepairImage(formData);
        this.makeToast("Success", "Photo uploaded successfully", "success");
        this.$bvModal.hide("modal-repair-pic");
      } catch (error) {
        console.log(error);
        this.makeToast("Error", "Internal server error, try again later", "danger");
      } finally {
        this.isLoading = false;
      }
    },
    async deleteRepairImage() {
      const value = await this.showConfirmDeleteBox();
      if (!value) return;
      try {
        this.isLoadingDelete = true;
        await CompanyCarsService.deleteRepairImage(this.damageId);
        this.makeToast("Success", "Photo deleted successfully", "success");
        this.$emit("onChangeStatus", 0);

        this.repairImage = null;
      } catch (error) {
        this.makeToast("Error", "Internal server error, try again later", "danger");
      } finally {
        this.isLoadingDelete = false;
      }
    },
    refreshData() {
      this.$parent.refreshData();
    },
  },
};
</script>

<style></style>
