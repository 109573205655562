<template>
  <div>
    <b-modal
      id="task-form"
      ref="taskForm"
      title="Add task"
      size="lg"
      hide-footer
      hide-header-close
      @hidden="resetForm()"
    >
      <b-form @submit.prevent="showConfirmModal">
        <b-row>
          <b-col cols="12">
            <div class="mb-2">
              <b-button class="my-auto" type="button" variant="success" pill
                >1</b-button
              >
            </div>
          </b-col>
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group>
              <template slot="label"> Vehicle </template>
              <multiselect
                v-model="form.vehicles"
                :options="vehicles"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="false"
                placeholder="Pick some option"
                label="title"
                track-by="title"
                :preselect-first="false"
              >
                <template slot="tag" slot-scope="{ option, remove }"
                  ><span class="custom__tag"
                    ><span class="">{{ option.stockNumber }}</span
                    ><span class="ml-1 custom__remove" @click="remove(option)"
                      >❌</span
                    ></span
                  ></template
                >
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group>
              <template slot="label"> Type </template>
              <multiselect
                v-model="form.type"
                :options="taskTypesList"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Pick some option"
                label="name"
                track-by="name"
                :preselect-first="false"
                :taggable="true"
                @input="getUsersByTaskType"
              >
              </multiselect>
            </b-form-group>
          </b-col>

          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group>
              <template slot="label"> Company </template>
              <vue-single-select
                :classes="{
                  input: 'form-control',
                  dropdown: 'dropdown p-0',
                }"
                v-model="form.company"
                :options="companies"
                optionLabel="companyName"
              >
              </vue-single-select>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div class="mb-2">
              <b-button class="my-auto" type="button" variant="success" pill
                >2</b-button
              >
            </div>
          </b-col>
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group>
              <template slot="label"> User </template>
              <multiselect
                v-model="form.owner"
                :options="users"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Pick some option"
                label="name"
                track-by="nickname"
                :preselect-first="false"
                :taggable="true"
                :custom-label="getUserFullName"
              >
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <template slot="label"> Description </template>
              <b-form-textarea v-model="form.description" class="resize-none" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div class="mb-2">
              <b-button class="my-auto" type="button" variant="success" pill
                >3</b-button
              >
            </div>
          </b-col>

          <b-col sm="12" md="6" lg="6" xl="6" v-if="showPrice">
            <b-form-group label="Price">
              <Money
                v-model="form.price"
                class="form-control text-center"
                v-bind="money"
              />
            </b-form-group>
          </b-col>

          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <template slot="label"> Link </template>
              <b-form-input v-model="form.link" type="url" />
            </b-form-group>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-end">
          <b-button
            :disabled="
              isTaskLoading ||
              form.type == null ||
              form.owner == null ||
              form.owner.length == 0
            "
            type="submit"
            variant="success"
          >
            <b-spinner v-if="isTaskLoading" variant="white" small />
            Submit
          </b-button>
        </div>
      </b-form>
    </b-modal>
    <TaskConfirmCreateModal :callback="saveTask" :task="form" />
  </div>
</template>

<script>
import { POST_TASK, GET_VEHICLES_AND_USERS } from "../actions";

import Multiselect from "vue-multiselect";
import VueSingleSelect from "vue-single-select";
import TaskConfirmCreateModal from "./TaskConfirmCreateModal.vue";
import { mapState } from "vuex";
import { Money } from "v-money";

export default {
  name: "TaskForm",
  props: {
    taskBoardId: {
      type: Number,
      required: false,
    },
    refreshData: {
      type: Function,
      required: false,
    },
  },
  components: {
    Multiselect,
    VueSingleSelect,
    TaskConfirmCreateModal,
    Money,
  },
  computed: {
    ...mapState({
      vehicles: (state) => state.superAdminStore.tasks.vehicles.data,
      companies: (state) => state.superAdminStore.tasks.companies.data,
      task: (state) => state.superAdminStore.tasks.task,
      taskTypesList: (state) => state.superAdminStore.tasks.taskTypes.data,
    }),
    userName() {
      if (this.form.owner) {
        return `${this.form.owner.firstName} ${this.form.owner.lastName}`;
      }
      return "";
    },
    isTaskLoading() {
      return this.task.status == "fetching";
    },
    showPrice() {
      return (
        this.form.type && this.form.type.some((type) => type.name == "Invoice")
      );
    },
  },
  data() {
    return {
      form: {
        type: null,
        owner: null,
        taskBoardId: this.taskBoardId,
        description: null,
        link: null,
        vehicles: null,
        company: null,
        /*         dueDate: null,
         */
      },
      taskTypes: [],
      users: [],
      showConfirm: false,
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "CAD$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
    };
  },
  created() {
    this.getVehiclesAndUsers();
  },
  methods: {
    resetForm() {
      this.form = {
        type: null,
        owner: null,
        taskBoardId: this.taskBoardId,
        vehicle: null,
        description: null,
        link: null,
        company: null,
        price: null,
      };
    },
    saveTask() {
      this.showConfirmModal();
      const payload = {
        title: this.formatTitle(),
        type: this.getTypesInAString(),
        listOwners: this.form?.owner,
        taskBoardId: this.taskBoardId,
        vehicles: this.form?.vehicles,
        description: this.form?.description,
        link: this.form?.link,
        companyId: this.form?.company?.id,
        price: this.form?.price,
      };
      this.$store
        .dispatch(POST_TASK, payload)
        .then(() => {
          this.closeConfirmModal();
          this.$bvToast.toast("Task registered successfully", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.refreshData();
          this.hideModal();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getVehiclesAndUsers() {
      this.$store.dispatch(GET_VEHICLES_AND_USERS).then(() => {});
    },
    hideModal() {
      this.$bvModal.hide("task-form");
    },
    getTypesInAString() {
      if (!this.form?.type) {
        return "";
      }
      return this.form?.type.map((type) => type.name).join(", ") || "";
    },
    getLastSixCharactersOfVinNumber(vinNumber) {
      return vinNumber.slice(-6);
    },
    filterVehicle() {
      if (this.form.vehicle) {
        return this.vehicles.filter((vehicle) => {
          return vehicle.title == this.form.vehicle;
        });
      } else {
        return this.vehicles;
      }
    },
    showConfirmModal() {
      this.showConfirm = true;
      this.$bvModal.show("confirm-modal");
    },
    closeConfirmModal() {
      this.showConfirm = false;
      this.$bvModal.hide("confirm-modal");
    },
    formatTitle() {
      let title = this.formatTaskType(this.form.type);
      if (this.form.vehicles.length > 1) {
        title += " - " + this.getTaskTitleByVehicles(this.form?.vehicles);
      }
      return title;
    },
    formatTaskType(types) {
      if (!types || types.length == 0) {
        return "";
      }
      let taskTypes = "";
      types.forEach((type) => {
        taskTypes += type.name + ", ";
      });
      return taskTypes ? taskTypes.slice(0, -2) : "";
    },
    getTaskTitleByVehicles(vehicles) {
      if (vehicles.length == 0) {
        return "";
      }
      let title = "";
      if (vehicles.length == 1) {
        title = vehicles[0].stockNumber;
      }
      if (vehicles.length > 1) {
        title = vehicles.length + " vehicles";
      }
      return title;
    },

    getUsersByTaskType() {
      this.form.owner = null;
      this.users = [];
      this.form.type.forEach((type) => {
        if (type.users) {
          type.users.forEach((user) => {
            if (this.users.some((u) => u.id == user.id)) {
              return;
            }
            this.users.push(user);
          });
        }
      });
      this.form.owner = this.users;
    },
    getUserFullName(user) {
      return `${user.firstName} ${user.lastName}`;
    },
  },
};
</script>

<style scoped>
.custom__tag {
  display: inline-block !important;
  padding: 3px 12px !important;
  background: #d2d7ff !important;
  margin-right: 8px !important;
  margin-bottom: 8px !important;
  border-radius: 10px !important;
  cursor: pointer !important;
}
</style>
