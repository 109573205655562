import * as constants from "./actions";
import axios from "@/plugins/axios.js";
import payments from "./components/payments/store";
import carfax from "./components/carfax/store";
import clients from "./components/Clients/store";
import visualInspection from "./components/visual-inspection/store";
import workOrder from "./components/work-orders/store";
import imagesVisualInspection from "./components/visual-inspection/components/RegistryCar/store";
function getDefaultState() {
  return {
    vehicle: {
      status: null,
      data: null,
      error: null,
      filters: {
        vinNumber: null,
        location: null,
        search: null,
        make: null,
        model: null,
        trim: null,
        transmissionType: null,
        releaseDate1: null,
        releaseDate2: null,
        year1: null,
        year2: null,
        mile1: null,
        mile2: null,
        price1: null,
        price2: null,
        status: null,
        trackingStatus: null,
        mainOfferId: null,
        new: null,
        lastLocation: null,
        finalDestination: null,
      },
    },
    vehicles: {
      status: null,
      data: [],
      error: null,
      filters: {
        vinNumber: null,
        location: null,
        search: null,
        make: null,
        model: null,
        trim: null,
        transmissionType: null,
        releaseDate1: null,
        releaseDate2: null,
        year1: null,
        year2: null,
        mile1: null,
        mile2: null,
        price1: null,
        price2: null,
        status: null,
        trackingStatus: null,
        mainOfferId: null,
        new: null,
        lastLocation: null,
        finalDestination: null,
      },
    },
    vehicleByVin: {
      status: null,
      data: {},
      error: null,
    },
    agents: {
      status: null,
      data: [],
      error: null,
    },
    clientsList: {
      status: null,
      data: [],
      error: null,
    },
    exportLocations: {
      status: null,
      data: [],
      error: null,
    },
    makesModelsAndColors: {
      status: null,
      data: [],
      error: null,
      filters: { make: null, model: null },
    },
    validateVinNumber: { status: null, data: {}, error: null },
    financialReferralPriceInfo: { status: null, data: {}, error: null },
    financialReferralPricePdf: { status: null, data: {}, error: null },
    vehiclesValidation: {
      status: null,
      data: [],
      error: null
    },
    vehicleLocationInfo: {
      status: null,
      data: {},
      error: null
    },
    vehicleFiles: {
      status: null,
      data: {},
      error: null
    },
    disableVehicle: {
      status: null,
      data: {},
      error: null
    }
  }
}

const actions = {
  [constants.GET_VEHICLE]: async ({ commit, state }) => {
    try {
      commit(constants.GET_VEHICLE);
      const payload = Object.entries(state.vehicle.filters).reduce(
        (a, [k, v]) => (v === null || v === "" ? a : { ...a, [k]: v }),
        {}
      );
      const response = await axios.get("vehicle/findVehicle", {
        params: payload,
      });
      commit(constants.GET_VEHICLE_SUCCESS, response.data);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLE_ERROR, error);
      throw error.response;
    }
  },

  [constants.GET_VEHICLES_BY_VIN]: async ({ commit, state }) => {
    try {
      commit(constants.GET_VEHICLES_BY_VIN);
      const payload = Object.entries(state.vehicles.filters).reduce(
        (a, [k, v]) => (v === null || v === "" ? a : { ...a, [k]: v }),
        {}
      );
      const response = await axios.get("vehicle/getListCanadianBlackBook", {
        params: payload,
      });
      commit(constants.GET_VEHICLES_BY_VIN_SUCCESS, response.data);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLES_BY_VIN_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_VEHICLE_BY_STOCK]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_VEHICLE_BY_STOCK);
      const response = await axios.get(
        "vehicle/searchVehicle",
        { params: payload }
      );
      commit(constants.POST_VEHICLE_BY_STOCK_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_VEHICLE_BY_STOCK_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_AGENTS]: async ({ commit }) => {
    try {
      commit(constants.GET_AGENTS);
      const response = await axios.get("user/listAgent");
      commit(constants.GET_AGENTS_SUCCESS, response.agent);

      return response;
    } catch (error) {
      commit(constants.GET_AGENTS_ERROR, error);
      throw error.response;
    }
  },

  [constants.GET_CLIENTS]: async ({ commit }) => {
    try {
      commit(constants.GET_CLIENTS);
      const response = await axios.get("company/listCompany");
      commit(constants.GET_CLIENTS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_CLIENTS_ERROR, error);
      throw error.response;
    }
  },

  [constants.GET_EXPORT_LOCATIONS]: async ({ commit }) => {
    try {
      commit(constants.GET_EXPORT_LOCATIONS);
      const response = await axios.get("exportLoad/listExportLocation");
      commit(constants.GET_EXPORT_LOCATIONS_SUCCESS, response.exportLocation);
      return response;
    } catch (error) {
      commit(constants.GET_EXPORT_LOCATIONS_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_VEHICLE_BY_VIN]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_VEHICLE_BY_VIN);
      const response = await axios.post("vehicle/updateVehicle", payload);
      commit(constants.PUT_VEHICLE_BY_VIN_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_VEHICLE_BY_VIN_ERROR, error);
      throw error.response;
    }
  },

  [constants.PUT_ASSIGN_CANADIAN_INFO_TO_VEHICLE]: async (
    { commit },
    payload
  ) => {
    try {
      commit(constants.PUT_ASSIGN_CANADIAN_INFO_TO_VEHICLE);
      const response = await axios.put("vehicle/updateVinNumber", payload);
      console.log(response);
      commit(constants.PUT_ASSIGN_CANADIAN_INFO_TO_VEHICLE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_ASSIGN_CANADIAN_INFO_TO_VEHICLE_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_MAKES_AND_MODELS]: async ({ commit, state }) => {
    try {
      commit(constants.GET_MAKES_AND_MODELS);
      const payload = Object.entries(state.makesModelsAndColors.filters).reduce(
        (a, [k, v]) => (v === null || v === "" ? a : { ...a, [k]: v }),
        {}
      );
      const response = await axios.get("vehicle/getCarList", {
        params: payload,
      });
      commit(constants.GET_MAKES_AND_MODELS_SUCCESS, response);
      return response;
    } catch (error) {
      console.log(error);
      commit(constants.GET_MAKES_AND_MODELS_ERROR, error);
      throw error.response;
    }
  },

  [constants.GET_VALIDATE_VIN_NUMBER]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_VALIDATE_VIN_NUMBER);
      const response = await axios.get("vehicle/validateRepeatVin", {
        params: payload,
      });
      commit(constants.GET_VALIDATE_VIN_NUMBER_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VALIDATE_VIN_NUMBER_ERROR, error);
      throw error.response;
    }
  },

  [constants.GET_FINANCIAL_REFERRAL_PRICE_INFO]: async (
    { commit },
    payload
  ) => {
    try {
      commit(constants.GET_FINANCIAL_REFERRAL_PRICE_INFO);
      const response = await axios.get("vehicle/getFRP", {
        params: payload,
      });
      commit(constants.GET_FINANCIAL_REFERRAL_PRICE_INFO_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_FINANCIAL_REFERRAL_PRICE_INFO_ERROR, error);
      throw error.response;
    }
  },

  [constants.GET_FINANCIAL_REFERRAL_PRICE_PDF]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_FINANCIAL_REFERRAL_PRICE_PDF);
      const response = await axios.get("vehicle/generateFRP", {
        params: payload,
        responseType: "blob",
      });
      commit(constants.GET_FINANCIAL_REFERRAL_PRICE_PDF_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_FINANCIAL_REFERRAL_PRICE_PDF_ERROR, error);
      throw error.response;
    }
  },

  [constants.PUT_CONTRACT_REGISTRATION_FILE]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_CONTRACT_REGISTRATION_FILE);
      const response = await axios.put("vehicle/deleteContractDealer", payload);
      commit(constants.PUT_CONTRACT_REGISTRATION_FILE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_CONTRACT_REGISTRATION_FILE_ERROR, error);
      throw error.response;
    }
  },


  [constants.GET_RE_STOCK_VALIDATE]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_RE_STOCK_VALIDATE);
      const response = await axios.get("vehicle/reStockValidate", {
        params: payload,
      });
      commit(constants.GET_RE_STOCK_VALIDATE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_RE_STOCK_VALIDATE_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_NEW_VEHICLE_REGISTRATION]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_NEW_VEHICLE_REGISTRATION);
      const response = await axios.post("vehicle/createVehicle",
        payload,
      );
      commit(constants.POST_NEW_VEHICLE_REGISTRATION_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_NEW_VEHICLE_REGISTRATION_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_VEHICLE_LOCATION_INFO]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_VEHICLE_LOCATION_INFO);
      const response = await axios.get("timeLine/getInfoLocation", {
        params: payload,
      });
      commit(constants.GET_VEHICLE_LOCATION_INFO_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLE_LOCATION_INFO_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_VEHICLE_FILES]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_VEHICLE_FILES);
      const response = await axios.get("timeLine/getVehicleDocuments", {
        params: payload,
      });
      commit(constants.GET_VEHICLE_FILES_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLE_FILES_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_DISABLE_VEHICLE]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_DISABLE_VEHICLE);
      const response = await axios.put("vehicle/disableVehicle", payload);
      commit(constants.PUT_DISABLE_VEHICLE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_DISABLE_VEHICLE_ERROR, error);
      throw error.response;
    }
  }
};

const mutations = {
  [constants.GET_VEHICLE]: (state) => {
    state.vehicle.status = "fetching";
    state.vehicle.error = null;
  },
  [constants.GET_VEHICLE_SUCCESS]: (state, data) => {
    state.vehicle.status = "success";
    state.vehicle.error = null;
    state.vehicle.data = data;
  },
  [constants.GET_VEHICLE_ERROR]: (state, error) => {
    state.vehicle.status = "error";
    state.vehicle.error = error;
  },

  [constants.SET_ONE_VEHICLE_FILTERS]: (state, filters) => {
    if (filters.reset) {
      state.vehicle.filters = {
        vinNumber: null,
        location: null,
        search: null,
        make: null,
        model: null,
        trim: null,
        transmissionType: null,
        releaseDate1: null,
        releaseDate2: null,
        year1: null,
        year2: null,
        mile1: null,
        mile2: null,
        price1: null,
        price2: null,
        status: null,
        trackingStatus: null,
        mainOfferId: null,
        new: null,
      };
    }
    if (filters.fields) {
      state.vehicle.filters = {
        ...state.vehicle.filters,
        ...filters.fields,
      };
    }
  },

  [constants.GET_VEHICLES_BY_VIN]: (state) => {
    state.vehicles.status = "fetching";
    state.vehicles.error = null;
  },

  [constants.GET_VEHICLES_BY_VIN_SUCCESS]: (state, data) => {
    state.vehicles.status = "success";
    state.vehicles.error = null;
    state.vehicles.data = data;
  },

  [constants.GET_VEHICLES_BY_VIN_ERROR]: (state, error) => {
    state.vehicles.status = "error";
    state.vehicles.error = error;
  },

  [constants.SET_VEHICLES_BY_VIN_FILTERS]: (state, filters) => {
    if (filters.reset) {
      state.vehicles.filters = {
        vinNumber: null,
        location: null,
        search: null,
        make: null,
        model: null,
        trim: null,
        transmissionType: null,
        releaseDate1: null,
        releaseDate2: null,
        year1: null,
        year2: null,
        mile1: null,
        mile2: null,
        price1: null,
        price2: null,
        status: null,
        trackingStatus: null,
        mainOfferId: null,
        new: null,
      };
    }
    if (filters.fields) {
      state.vehicles.filters = {
        ...state.vehicles.filters,
        ...filters.fields,
      };
    }
  },

  [constants.POST_VEHICLE_BY_STOCK]: (state) => {
    state.vehicleByVin.status = "fetching";
    state.vehicleByVin.error = null;
  },

  [constants.POST_VEHICLE_BY_STOCK_SUCCESS]: (state, data) => {
    state.vehicleByVin.status = "success";
    state.vehicleByVin.error = null;
    state.vehicleByVin.data = data;
  },

  [constants.POST_VEHICLE_BY_STOCK_ERROR]: (state, error) => {
    state.vehicleByVin.status = "error";
    state.vehicleByVin.error = error;
  },

  [constants.GET_AGENTS]: (state) => {
    state.agents.status = "fetching";
    state.agents.error = null;
  },
  [constants.GET_AGENTS_SUCCESS]: (state, data) => {
    state.agents.status = "success";
    state.agents.error = null;
    state.agents.data = data;
  },
  [constants.GET_AGENTS_ERROR]: (state, error) => {
    state.agents.status = "error";
    state.agents.error = error;
  },
  [constants.GET_CLIENTS]: (state) => {
    state.clientsList.status = "fetching";
    state.clientsList.error = null;
  },
  [constants.GET_CLIENTS_SUCCESS]: (state, data) => {
    state.clientsList.status = "success";
    state.clientsList.error = null;
    state.clientsList.data = data;
  },
  [constants.GET_CLIENTS_ERROR]: (state, error) => {
    state.clientsList.status = "error";
    state.clientsList.error = error;
  },
  [constants.PUT_VEHICLE_BY_VIN]: (state) => {
    state.vehicleByVin.status = "fetching";
    state.vehicleByVin.error = null;
  },
  [constants.PUT_VEHICLE_BY_VIN_SUCCESS]: (state, data) => {
    state.vehicleByVin.status = "success";
    state.vehicleByVin.error = null;
    state.vehicleByVin.data = data;
  },
  [constants.PUT_VEHICLE_BY_VIN_ERROR]: (state, error) => {
    state.vehicleByVin.status = "error";
    state.vehicleByVin.error = error;
  },

  [constants.PUT_ASSIGN_CANADIAN_INFO_TO_VEHICLE]: (state) => {
    state.vehicle.status = "fetching";
    state.vehicle.error = null;
  },
  [constants.PUT_ASSIGN_CANADIAN_INFO_TO_VEHICLE_SUCCESS]: (state, data) => {
    state.vehicle.status = "success";
    state.vehicle.error = null;
    state.vehicle.data = data;
  },
  [constants.PUT_ASSIGN_CANADIAN_INFO_TO_VEHICLE_ERROR]: (state, error) => {
    state.vehicle.status = "error";
    state.vehicle.error = error;
  },
  [constants.GET_MAKES_AND_MODELS]: (state) => {
    state.makesModelsAndColors.status = "fetching";
    state.makesModelsAndColors.error = null;
  },
  [constants.GET_MAKES_AND_MODELS_SUCCESS]: (state, data) => {
    state.makesModelsAndColors.status = "success";
    state.makesModelsAndColors.error = null;
    state.makesModelsAndColors.data = data;
  },
  [constants.GET_MAKES_AND_MODELS_ERROR]: (state, error) => {
    state.makesModelsAndColors.status = "error";
    state.makesModelsAndColors.error = error;
  },
  [constants.SET_MAKES_AND_MODELS_FILTERS]: (state, filters) => {
    if (filters.reset) {
      state.makesModelsAndColors.filters = {
        make: "",
      };
    }
    if (filters.fields) {
      state.makesModelsAndColors.filters = {
        ...state.makesModelsAndColors.filters,
        ...filters.fields,
      };
    }
  },

  [constants.GET_VALIDATE_VIN_NUMBER]: (state) => {
    state.validateVinNumber.status = "fetching";
    state.validateVinNumber.error = null;
  },
  [constants.GET_VALIDATE_VIN_NUMBER_SUCCESS]: (state, data) => {
    state.validateVinNumber.status = "success";
    state.validateVinNumber.error = null;
    state.validateVinNumber.data = data;
  },
  [constants.GET_VALIDATE_VIN_NUMBER_ERROR]: (state, error) => {
    state.validateVinNumber.status = "error";
    state.validateVinNumber.error = error;
  },
  [constants.GET_EXPORT_LOCATIONS]: (state) => {
    state.exportLocations.status = "fetching";
    state.exportLocations.error = null;
  },
  [constants.GET_EXPORT_LOCATIONS_SUCCESS]: (state, data) => {
    state.exportLocations.status = "success";
    state.exportLocations.error = null;
    state.exportLocations.data = data;
  },
  [constants.GET_EXPORT_LOCATIONS_ERROR]: (state, error) => {
    state.exportLocations.status = "error";
    state.exportLocations.error = error;
  },
  [constants.GET_FINANCIAL_REFERRAL_PRICE_INFO]: (state) => {
    state.financialReferralPriceInfo.status = "fetching";
    state.financialReferralPriceInfo.error = null;
  },
  [constants.GET_FINANCIAL_REFERRAL_PRICE_INFO_SUCCESS]: (state, data) => {
    state.financialReferralPriceInfo.status = "success";
    state.financialReferralPriceInfo.error = null;
    state.financialReferralPriceInfo.data = data;
  },
  [constants.GET_FINANCIAL_REFERRAL_PRICE_INFO_ERROR]: (state, error) => {
    state.financialReferralPriceInfo.status = "error";
    state.financialReferralPriceInfo.error = error;
  },
  [constants.GET_FINANCIAL_REFERRAL_PRICE_PDF]: (state) => {
    state.financialReferralPricePdf.status = "fetching";
    state.financialReferralPricePdf.error = null;
  },
  [constants.GET_FINANCIAL_REFERRAL_PRICE_PDF_SUCCESS]: (state, data) => {
    state.financialReferralPricePdf.status = "success";
    state.financialReferralPricePdf.error = null;
    state.financialReferralPricePdf.data = data;
  },
  [constants.GET_FINANCIAL_REFERRAL_PRICE_PDF_ERROR]: (state, error) => {
    state.financialReferralPricePdf.status = "error";
    state.financialReferralPricePdf.error = error;
  },
  [constants.GET_RE_STOCK_VALIDATE]: (state) => {
    state.vehiclesValidation.status = "fetching";
    state.vehiclesValidation.error = null;
  },
  [constants.GET_RE_STOCK_VALIDATE_SUCCESS]: (state, data) => {
    state.vehiclesValidation.status = "success";
    state.vehiclesValidation.error = null;
    state.vehiclesValidation.data = data;
  },
  [constants.GET_RE_STOCK_VALIDATE]: (state, error) => {
    state.vehiclesValidation.status = "error";
    state.vehiclesValidation.error = error;
  },
  [constants.POST_NEW_VEHICLE_REGISTRATION]: (state) => {
    state.vehicleByVin.status = "fetching";
    state.vehicleByVin.error = null;
  },

  [constants.POST_NEW_VEHICLE_REGISTRATION_SUCCESS]: (state, data) => {
    state.vehicleByVin.status = "success";
    state.vehicleByVin.error = null;
    state.vehicleByVin.data = data;
  },

  [constants.POST_NEW_VEHICLE_REGISTRATION_ERROR]: (state, error) => {
    state.vehicleByVin.status = "error";
    state.vehicleByVin.error = error;
  },
  [constants.GET_VEHICLE_LOCATION_INFO]: (state) => {
    state.vehicleLocationInfo.status = "fetching";
    state.vehicleLocationInfo.error = null;
  },
  [constants.GET_VEHICLE_LOCATION_INFO_SUCCESS]: (state, data) => {
    state.vehicleLocationInfo.status = "success";
    state.vehicleLocationInfo.error = null;
    state.vehicleLocationInfo.data = data;
  },
  [constants.GET_VEHICLE_LOCATION_INFO_ERROR]: (state, error) => {
    state.vehicleLocationInfo.status = "error";
    state.vehicleLocationInfo.error = error;
  },
  [constants.GET_VEHICLE_FILES]: (state) => {
    state.vehicleFiles.status = "fetching";
    state.vehicleFiles.error = null;
  },
  [constants.GET_VEHICLE_FILES_SUCCESS]: (state, data) => {
    state.vehicleFiles.status = "success";
    state.vehicleFiles.error = null;
    state.vehicleFiles.data = data;
  },
  [constants.GET_VEHICLE_FILES_ERROR]: (state, error) => {
    state.vehicleFiles.status = "error";
    state.vehicleFiles.error = error;
  },
  [constants.PUT_DISABLE_VEHICLE]: (state) => {
    state.disableVehicle.status = "fetching";
    state.disableVehicle.error = null;
  },
  [constants.PUT_DISABLE_VEHICLE_SUCCESS]: (state, data) => {
    state.disableVehicle.status = "success";
    state.disableVehicle.error = null;
    state.disableVehicle.data = data;
  },
  [constants.PUT_DISABLE_VEHICLE_ERROR]: (state, error) => {
    state.disableVehicle.status = "error";
    state.disableVehicle.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
  modules: {
    payments,
    carfax,
    clients,
    visualInspection,
    workOrder,
    imagesVisualInspection,
  },
};
