<template>
  <b-row v-if="vehicle">
    <b-col cols="8">
      <b-card no-body class="overflow-hidden">
        <b-row no-gutters>
          <b-col md="4">
            <b-card-img
              src="img/ar_11.png"
              alt="Image"
              class="rounded-0"
              style="width: 100%; height: 100%"
            ></b-card-img>
            <div class="text-center">
              <!--  <a
                href="https://mmsc400.manheim.com/MABEL/ECR2I.PGM?&SAUCI=MTRL&SWO=2267581&CLIENT=AUCTN&CONFIGID=BUYER&ECRTYPE=**&Submit=Submit&=Reset"
                >Open conditional report</a
              > -->
            </div>
          </b-col>
          <b-col md="8">
            <b-card-body class="pt-0">
              <div class="text-justify">
                <h5 class="h2 mt-1 justify-content-between">
                  <div class="justify-content-between">
                    <span>{{
                      vehicle.vinNumber ? vehicle.vinNumber.model : "----"
                    }}</span>
                    <span class="float-right">{{
                      vehicle.vinNumber ? vehicle.vinNumber.vinNumber : "----"
                    }}</span>
                  </div>
                </h5>
                <b-row>
                  <b-col>
                    <div class="h4 mt-3">
                      Make:
                      <small>{{
                        vehicle.vinNumber ? vehicle.vinNumber.make : "----"
                      }}</small>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="h4 mt-3">
                      Model:
                      <small>{{
                        vehicle.vinNumber ? vehicle.vinNumber.model : "----"
                      }}</small>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="h4 mt-3">
                      Trim:
                      <small>{{
                        vehicle.vinNumber ? vehicle.vinNumber.trim : "----"
                      }}</small>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="h4 mt-3">
                      Year:
                      <small>{{
                        vehicle.vinNumber ? vehicle.vinNumber.year : "----"
                      }}</small>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="h4 mt-3">
                      Color:
                      <small>
                        {{
                          vehicle.vinNumber ? vehicle.vinNumber.color : "----"
                        }}</small
                      >
                    </div>
                  </b-col>
                  <b-col>
                    <div class="h4 mt-3">Kilometer: <small>2000</small></div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="h4 mt-3">
                      Milles:
                      <small>{{ vehicle.vehicleInformation.miles }}</small>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="h4 mt-3">
                      Engine:
                      <small
                        >{{
                          vehicle.vinNumber
                            ? vehicle.vinNumber.vinNumber
                            : "----"
                        }}
                      </small>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="h4 mt-3">
                      Stock: <small>{{ vehicle.stockNumber }} </small>
                    </div>
                  </b-col>
                </b-row>

                <!--                 <hr class="my-4" />
 -->
              </div>
              <!--               <b-row>
                <b-col>
                  <div class="card-profile-stats d-flex justify-content-center">
                    <div>
                      <small class="heading">{{ 500 | currency }}</small>
                      <small class="description">USD</small>
                    </div>

                    <div>
                      <small class="heading">{{ 550 | currency }}</small>
                      <small class="description">CAD</small>
                    </div>
                  </div>
                </b-col>
              </b-row> -->
              <div class="text-right mt-3">
                <b-button variant="success">Open CR</b-button>
              </div>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col cols="4">
      <card>
        <b-col cols="12">
          <h4>
            Bough from: <small>AUTOMOBILES REJEAN LAPORTE (3d ago) </small>
          </h4>
          <br />
          <h4>
            Location:
            <small>Montreal Ca. <b-icon icon="geo-alt" scale="1.2" /> </small>
          </h4>
          <h4>
            Export location:
            <small
              >Miami, Florida USA. <b-icon icon="geo-alt" scale="1.2" />
            </small>
          </h4>
          <h4>
            Last location:
            <small>ADESA MONTREAL <b-icon icon="geo-alt" scale="1.2" /> </small>
          </h4>
        </b-col>

        <div class="text-right mt-3">
          <b-button variant="success"
            >Time table
            <b-icon
              class="ml-1"
              icon="clock
"
            ></b-icon>
          </b-button>
        </div>
      </card>
    </b-col>
  </b-row>
</template>
<script>
import { vehicleMixin } from "../../../modules/vehicle/mixin";
export default {
  mixins: [vehicleMixin],
};
</script>
<style></style>
