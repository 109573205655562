var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{ref:'task-update-status-modal' + _vm.id,attrs:{"id":'task-update-status-modal' + _vm.id,"title":"Task","hide-footer":"","body-class":"mt-0 pt-0"}},[_c('p',{},[_vm._v(" This vehicles has an active task, do you want to update status? ")]),_c('div',[_c('b-form-group',{attrs:{"label-cols":"3","label-size":"sm","label":"Status"}},[_c('b-form-select',{attrs:{"options":[
            { value: 0, text: 'Select status' },
            { value: 2, text: 'In progress' },
            { value: 3, text: 'Review' },
            { value: 4, text: 'Done' },
          ],"size":"sm"},model:{value:(_vm.statusToChange),callback:function ($$v) {_vm.statusToChange=$$v},expression:"statusToChange"}})],1)],1),_c('div',[_c('span',[_vm._v("Select task(s) to change status")]),(_vm.tasksSelected.length > 0)?_c('p',[_vm._v(" "+_vm._s(_vm.tasksSelected.length)+" task(s) selected ")]):_vm._e(),_vm._l((_vm.tasks),function(task,index){return _c('TaskUpdateStatusModalCard',{key:index,attrs:{"task":task},on:{"onSelectTask":_vm.onSelectTask}})})],2),_c('div',{staticClass:"text-right mt-2"},[_c('b-button',{attrs:{"variant":"outline-danger"},on:{"click":function($event){_vm.closeModal();
          _vm.resetForm();}}},[_vm._v("Cancel")]),_c('b-button',{staticClass:"mr-2",attrs:{"variant":"success","disabled":_vm.statusToChange === 0 || _vm.tasksSelected.length === 0},on:{"click":function($event){return _vm.updateTaskStatus()}}},[_vm._v("Update")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }