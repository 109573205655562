<template>
  <b-modal
    id="financial-credit-request-modal"
    ref="modal"
    modal-class="modal-fullscreen"
    centered
    scrollable
    size="lg"
    @show="getData()"
    title="Financial Credit Request"
    hide-footer
    @hide="getVehiclesInfo()"
  >
    <VehicleDetails
      v-if="vehicle"
      :vehicle="vehicle"
      :color="vehicle.vehicleInformation.color || '----'"
    />
    <b-row>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label-size="sm">
          <template>Registration date</template>
          <input
            disabled
            v-model="form.registrationDate"
            type="date"
            class="form-control"
          />
        </b-form-group>
      </b-col>

      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label-size="sm">
          <template>Registered by:</template>
          <input
            disabled
            v-model="form.registerUser"
            type="text"
            class="form-control"
          />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label-size="sm">
          <template>Date of pay<span class="text-danger">*</span> </template>
          <input
            :disabled="financialCreditRequest.status == 2"
            v-model="form.date"
            type="date"
            class="form-control"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="financialCreditRequest.status == 2"
        xs="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
      >
        <b-form-group label-size="sm">
          <template>Marked as paid by:</template>
          <input
            :disabled="financialCreditRequest.status == 2"
            v-model="form.userPaid"
            type="text"
            class="form-control"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-button
      v-if="financialCreditRequest.status != 2"
      @click="markCreditAsPaid()"
      class="float-right"
      variant="success"
    >
      Mark as reimbursement
    </b-button>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import VehicleDetails from "@/modules/superAdmin/components/VehicleDetails.vue";
import { GET_FINANCIAL_CREDIT_REQUEST_INFO, PUT_MARK_AS_PAID } from "./actions";
import { superAdminMixin } from "../../mixins";

export default {
  name: "FinancialCreditRequestModal",
  components: { VehicleDetails },
  mixins: [superAdminMixin],
  computed: {
    ...mapState({
      vehicle: (state) => state.superAdminStore.vehicle,
      financialCreditRequest: (state) =>
        state.superAdminStore.componentStore.financialCreditRequest
          .financialCreditRequest.data,
    }),
  },
  data() {
    return {
      form: {
        date: null,
        registrationDate: null,
        registerUser: null,
      },
    };
  },
  methods: {
    getData() {
      this.$store
        .dispatch(GET_FINANCIAL_CREDIT_REQUEST_INFO, {
          vehicleId: this.vehicle.id,
        })
        .then(() => {
          this.formatForm();
        });
    },
    markCreditAsPaid() {
      this.$store
        .dispatch(PUT_MARK_AS_PAID, {
          vehicleId: this.vehicle.id,
          creditRequestId: this.financialCreditRequest.creditRequestsId,
          date: this.form.date,
        })
        .then(() => {
          this.$bvModal.hide("financial-credit-request-modal");

          /* toast success */
          this.$bvToast.toast("Credit marked as reimbursement", {
            title: "Success",
            variant: "success",
          });
        });
    },
    formatForm() {
      /* set today as default */
      this.form.date = new Date().toISOString().split("T")[0];
      this.form.registrationDate = new Date(
        this.financialCreditRequest.registerDate
      )
        .toISOString()
        .split("T")[0];
      this.form.registerUser =
        this.financialCreditRequest.registerUser.firstName +
        " " +
        this.financialCreditRequest.registerUser.lastName;
      if (this.financialCreditRequest.status == 2) {
        this.form.date = new Date(this.financialCreditRequest.validationDate)
          .toISOString()
          .split("T")[0];
        this.form.userPaid =
          this.financialCreditRequest.updaterUser.firstName +
          " " +
          this.financialCreditRequest.updaterUser.lastName;
      }
    },
  },
};
</script>

<style scoped>
</style>
