<template>
  <!-- Card stats -->
  <b-row>
    <b-col xl="3" md="6">
      <stats-card class="mb-4">
        <template slot>
          <div class="d-flex justify-content-between">
            <h5 class="card-title text-uppercase text-muted mb-0">
              To be picked up
            </h5>
            <div class="text-center">
              <i
                v-b-tooltip.click="{ variant: 'white' }"
                title="Warning within 0-3 days, Alert more than 3 days"
              >
                <font-awesome-icon
                  class="cursor-pointer text-blue"
                  :icon="['far', 'circle-question']"
                />
              </i>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <b-row>
              <b-col
                cols="auto"
                @click="applyFilter(toBePickupFilterWarning)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-yellow"
                  :icon="['far', 'triangle-exclamation']"
                  size="2xl"
                />

                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">
                  {{ logistic != null ? logistic.pickedUpWarning : 0 }}
                </span>
              </b-col>
              <b-col
                cols="auto"
                @click="applyFilter(toBePickupFilterAlert)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-red"
                  :icon="['far', 'octagon-exclamation']"
                  size="2xl"
                />
                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">{{
                  logistic != null ? logistic.pickedUpAlert : 0
                }}</span>
              </b-col>
            </b-row>
          </div>
        </template>
      </stats-card>
    </b-col>
    <b-col xl="3" md="6">
      <stats-card class="mb-4">
        <template slot>
          <div class="d-flex justify-content-between">
            <h5 class="card-title text-uppercase text-muted mb-0">
              To be drop-off by customer
            </h5>
            <div class="text-center">
              <i
                v-b-tooltip.click="{ variant: 'white' }"
                title="Warning within 0-5 days, Alert more than 5 days"
              >
                <font-awesome-icon
                  class="cursor-pointer text-blue"
                  :icon="['far', 'circle-question']"
                />
              </i>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <b-row>
              <b-col
                cols="auto"
                @click="applyFilter(dropOffByCustomerWarning)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-yellow"
                  :icon="['far', 'triangle-exclamation']"
                  size="2xl"
                />

                <span class="h1 font-weight-bold mb-0 align-middle">
                  {{
                    logistic != null ? logistic.dropOffByCustomerWarning : 0
                  }}</span
                >
              </b-col>
              <b-col
                cols="auto"
                @click="applyFilter(dropOffByCustomerAlert)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-red"
                  :icon="['far', 'octagon-exclamation']"
                  size="2xl"
                />
                <span class="h1 font-weight-bold mb-0 align-middle">
                  {{
                    logistic != null ? logistic.dropOffByCustomerAlert : 0
                  }}</span
                >
              </b-col>
            </b-row>
          </div>
        </template>
        <template slot="footer">
          <span class="text-nowrap"></span>
        </template>
      </stats-card>
    </b-col>

    <b-col xl="3" md="6">
      <stats-card class="mb-4">
        <template slot>
          <div class="d-flex justify-content-between">
            <h5 class="card-title text-uppercase text-muted mb-0">Delayed</h5>
            <div class="text-center">
              <i
                v-b-tooltip.click="{ variant: 'white' }"
                title="Warning within 0-3 days, Alert more than 3 days"
              >
                <font-awesome-icon
                  class="cursor-pointer text-blue"
                  :icon="['far', 'circle-question']"
                />
              </i>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <b-row>
              <b-col
                cols="auto"
                @click="applyFilter(delayedWarning)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-yellow"
                  :icon="['far', 'triangle-exclamation']"
                  size="2xl"
                />

                <span class="h1 font-weight-bold mb-0 align-middle">
                  {{ logistic != null ? logistic.delayedWarning : 0 }}</span
                >
              </b-col>
              <b-col
                cols="auto"
                @click="applyFilter(delayedAlert)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-red"
                  :icon="['far', 'octagon-exclamation']"
                  size="2xl"
                />
                <span class="h1 font-weight-bold mb-0 align-middle">
                  {{ logistic != null ? logistic.delayedAlert : 0 }}</span
                >
              </b-col>
            </b-row>
          </div>
        </template>
        <template slot="footer">
          <span class="text-nowrap"></span>
        </template>
      </stats-card>
    </b-col>
    <b-col xl="3" md="6">
      <stats-card class="mb-4">
        <template slot>
          <div class="d-flex justify-content-between">
            <h5 class="card-title text-uppercase text-muted mb-0">
              On the road
            </h5>
            <div class="text-center">
              <i
                v-b-tooltip.click="{ variant: 'white' }"
                title="Vehicles with a transfer open "
              >
                <font-awesome-icon
                  class="cursor-pointer text-blue"
                  :icon="['far', 'circle-question']"
                />
              </i>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <b-row @click="applyFilter(onTheRoad)" class="cursor-pointer">
              <b-col cols="auto">
                <font-awesome-icon
                  class="cursor-pointer text-orange"
                  :icon="['far', 'truck-container']"
                  size="2xl"
                />

                <span class="h1 font-weight-bold mb-0 align-middle">
                  {{ logistic != null ? logistic.onTheRoad : 0 }}</span
                ></b-col
              >
            </b-row>
          </div>
        </template>
        <template slot="footer">
          <span class="text-nowrap"></span>
        </template>
      </stats-card>
    </b-col>
    <b-col xl="3" md="6">
      <stats-card class="mb-4">
        <template slot>
          <div class="d-flex justify-content-between">
            <h5 class="card-title text-uppercase text-muted mb-0">
              To be delivered
            </h5>
            <div class="text-center">
              <i
                v-b-tooltip.click="{ variant: 'white' }"
                title="Warning within 0-3 days, Alert more than 3 days"
              >
                <font-awesome-icon
                  class="cursor-pointer text-blue"
                  :icon="['far', 'circle-question']"
                />
              </i>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <b-row>
              <b-col
                cols="auto"
                @click="applyFilter(toBeDeliverWarning)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-yellow"
                  :icon="['far', 'triangle-exclamation']"
                  size="2xl"
                />

                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">
                  {{ logistic != null ? logistic.deliveredWarning : 0 }}</span
                >
              </b-col>
              <b-col
                cols="auto"
                @click="applyFilter(toBeDeliverAlert)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-red"
                  :icon="['far', 'octagon-exclamation']"
                  size="2xl"
                />
                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">
                  {{ logistic != null ? logistic.deliveredAlert : 0 }}</span
                >
              </b-col>
            </b-row>
          </div>
        </template>
      </stats-card>
    </b-col>
    <b-col xl="3" md="6">
      <stats-card class="mb-4">
        <template slot>
          <div class="d-flex justify-content-between">
            <h5 class="card-title text-uppercase text-muted mb-0">
              To be picked up by customer
            </h5>
            <div class="text-center">
              <i
                v-b-tooltip.click="{ variant: 'white' }"
                title="Warning within 0-5 days, Alert more than 3 days"
              >
                <font-awesome-icon
                  class="cursor-pointer text-blue"
                  :icon="['far', 'circle-question']"
                />
              </i>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <b-row>
              <b-col
                cols="auto"
                @click="applyFilter(pickedUpBycustomerWarning)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-yellow"
                  :icon="['far', 'triangle-exclamation']"
                  size="2xl"
                />

                <span class="h1 font-weight-bold mb-0 align-middle">
                  {{
                    logistic != null ? logistic.pickedUpBycustomerWarning : 0
                  }}</span
                >
              </b-col>
              <b-col
                cols="auto"
                @click="applyFilter(pickedUpBycustomerAlert)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-red"
                  :icon="['far', 'octagon-exclamation']"
                  size="2xl"
                />
                <span class="h1 font-weight-bold mb-0 align-middle">
                  {{
                    logistic != null ? logistic.pickedUpBycustomerAlert : 0
                  }}</span
                >
              </b-col>
            </b-row>
          </div>
        </template>
        <template slot="footer">
          <span class="text-nowrap"></span>
        </template>
      </stats-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from "vuex";
import { SET_VEHICLES_INFO_FILTERS } from "@/modules/superAdmin/actions";
import { superAdminMixin } from "../../modules/superAdmin/mixins";
import { GET_BOARD_LOGISTIC } from "./actions";
export default {
  name: "BoardCardsLogistic",
  mixins: [superAdminMixin],
  computed: {
    ...mapState({
      logistic: (state) => state.layout.boards.logistic?.data,
      logisticStatus: (state) => state.layout.boards.logistic?.status,
    }),
  },
  data() {
    return {
      toBePickupFilterWarning: {
        // Vehicles bought  without transfer warning 0-3 dias alert >3 dias
        notPickedUp: 1,

        stockDateFrom: this.getDateXDaysAgo(3),
      },
      toBePickupFilterAlert: {
        notPickedUp: 1,

        stockDateTo: this.getDateXDaysAgo(3),
      },
      toBeDeliverWarning: {
        //Vehicles sold  without transfer since sold warning 0-3 dias alert >3 dias
        status: "Sold",
        transfer: 4,
        saleDateFrom: this.getDateXDaysAgo(3),
      },
      toBeDeliverAlert: {
        status: "Sold",
        transfer: 4,
        saleDateTo: this.getDateXDaysAgo(3),
      },
      delayedWarning: {
        transfersOverWarning: 1,
      },
      delayedAlert: {
        transfersOverAlert: 1,
      },
      onTheRoad: {
        transfer: "1,4",
        onRoad: 1,
      },
      pickedUpBycustomerWarning: {
        pickedUpBycustomerWarning: 1,
      },
      pickedUpBycustomerAlert: {
        pickedUpBycustomerAlert: 1,
      },
      dropOffByCustomerWarning: {
        dropOffByCustomerWarning: 1,
      },
      dropOffByCustomerAlert: {
        dropOffByCustomerAlert: 1,
      },
    };
  },
  mounted() {
    this.getLogisticInformation();
  },
  methods: {
    applyFilter(filters) {
      this.resetFilters(); //Method from superAdminMixin
      this.$store.commit(SET_VEHICLES_INFO_FILTERS, {
        fields: { ...filters },
      });
      this.getVehiclesInfo(); //Method from superAdminMixin
    },
    getDateXDaysAgo(numOfDays, date = new Date()) {
      const daysAgo = new Date(date);
      daysAgo.setDate(date.getDate() - numOfDays);
      return this.formatDateString(daysAgo.toString());
    },
    getLogisticInformation() {
      this.$store.dispatch(GET_BOARD_LOGISTIC).then(() => {});
    },
    formatDateString(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const shortDate = `${year}-${month}-${day}`;
      return shortDate;
    },
  },
};
</script>

<style></style>
