export const GET_REGISTRATIONS = "GET_REGISTRATIONS";
export const GET_REGISTRATIONS_SUCCESS = "GET_REGISTRATIONS_SUCCESS";
export const GET_REGISTRATIONS_ERROR = "GET_REGISTRATIONS_ERROR";

export const POST_REGISTRATION = "POST_REGISTRATION";
export const POST_REGISTRATION_SUCCESS = "POST_REGISTRATION_SUCCESS";
export const POST_REGISTRATION_ERROR = "POST_REGISTRATION_ERROR";

export const GET_VEHICLES_REGISTRATION = "GET_VEHICLES_REGISTRATION";
export const GET_VEHICLES_REGISTRATION_SUCCESS =
  "GET_VEHICLES_REGISTRATION_SUCCESS";
export const GET_VEHICLES_REGISTRATION_ERROR =
  "GET_VEHICLES_REGISTRATION_ERROR";

export const PUT_REGISTRATION_STATUS = "PUT_REGISTRATION_STATUS";
export const PUT_REGISTRATION_STATUS_SUCCESS =
  "PUT_REGISTRATION_STATUS_SUCCESS";
export const PUT_REGISTRATION_STATUS_ERROR = "PUT_REGISTRATION_STATUS_ERROR";
