<template>
  <FilterTypeDate fromType="viFrom" toType="viTo" />
</template>

<script>
import FilterTypeDate from "./FilterTypeDate.vue";
export default {
  name: "VisualInspectionFilter",
  components: { FilterTypeDate },
};
</script>

<style>
</style>