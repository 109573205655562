<template>
  <b-row class="mt-3">
    <b-col>
      <b-button
        v-if="!offerInput"
        variant="yellow"
        :disabled="userPending"
        block
        @click="showOfferInput()"
      >
        <span style="font-size: 15px">Make an Offer</span>
      </b-button>
      <b-input-group v-if="offerInput">
        <Money
          v-model="offer"
          class="form-control text-center"
          v-bind="money"
        />
        <b-input-group-append>
          <b-button
            variant="yellow"
            size="sm"
            :disabled="!offer || makeAnOfferStatus === 'fetching'"
            @click="sendOffer"
          >
            <b-spinner
              v-if="makeAnOfferStatus === 'fetching'"
              variant="white"
              small
            />
            <span v-else>Send</span>
          </b-button>
        </b-input-group-append>
        <b-input-group-append>
          <b-button
            variant="danger"
            size="sm"
            @click="closeOfferInput"
          >
            <b-icon icon="x" />
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-col>
    <BuyNow
      v-if="!offerInput && !offer"
      :vehicle-id="vehicleId"
      :price="this.price"
      :user-pending="userPending"
    />
  </b-row>
</template>

<script>
import { mapState } from 'vuex';
import { POST_VEHICLE_MAKE_AN_OFFER } from './actions';
import BuyNow from './BuyNow';
import { Money } from 'v-money';
import { generalMixin } from '@/modules/mixin';
export default {
  components: { BuyNow, Money },
  mixins: [ generalMixin ],
  props: [ 'vehicleId', 'price', 'userPending' ],
  computed: {
    ...mapState({
      makeAnOfferStatus: (state) =>
        state.adminStore.vehicles.listStore.componentsStore.makeAnOffer.status
    })
  },
  data() {
    return {
      offerInput: false,
      offer: 0.0,
      money: {
        decimal: '.',
        thousands: ',',
        prefix: 'CAD$ ',
        suffix: '',
        precision: 2,
        masked: false /* doesn't work with directive */
      }
    };
  },
  methods: {
    showOfferInput() {
      if (this.user != null) {
        this.offerInput = !this.offerInput;
      } else {
        this.$bvModal.show('login-modal');
      }
    },
    closeOfferInput() {
      this.offer = null;
      this.offerInput = false;
    },
    payload() {
      return {
        vehiclesId: this.vehicleId,
        clientId: this.user.id,
        offerPrice: this.offer
      };
    },
    sendOffer() {
      this.$store
        .dispatch(POST_VEHICLE_MAKE_AN_OFFER, this.payload())
        .then(() => {
          this.$root.$bvToast.toast('Offer sent', {
            title: 'Success',
            variant: 'success'
          });
          this.$emit('setUserHasOffer', this.offer);
          this.closeOfferInput();
        })
        .catch(() => {
          this.$root.$bvToast.toast('The offer could not be sent', {
            title: 'Warning',
            variant: 'warning'
          });
        });
    }
  }
};
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
