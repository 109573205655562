export const GET_EXPORT_PORT = "GET_EXPORT_PORT";
export const GET_EXPORT_PORT_SUCCESS = "GET_EXPORT_PORT_SUCCESS";
export const GET_EXPORT_PORT_ERROR = "GET_EXPORT_PORT_ERROR";

export const POST_EXPORT_PORT = "POST_EXPORT_PORT";
export const POST_EXPORT_PORT_SUCCESS = "POST_EXPORT_PORT_SUCCESS";
export const POST_EXPORT_PORT_ERROR = "POST_EXPORT_PORT_ERROR";

export const PUT_EXPORT_PORT = "PUT_EXPORT_PORT";
export const PUT_EXPORT_PORT_SUCCESS = "PUT_EXPORT_PORT_SUCCESS";
export const PUT_EXPORT_PORT_ERROR = "PUT_EXPORT_PORT_ERROR";

export const GET_EXPORT_PORT_LIST = "GET_EXPORT_PORT_LIST";
export const GET_EXPORT_PORT_LIST_SUCCESS = "GET_EXPORT_PORT_LIST_SUCCESS";
export const GET_EXPORT_PORT_LIST_ERROR = "GET_EXPORT_PORT_LIST_ERROR";
