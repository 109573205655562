<template>
  <div>
    <div class="container-fluid mt-3">
      <b-row>
        <b-col>
          <!--           <h4 class="ml-3">Corporations</h4>
 -->
        </b-col>
      </b-row>
      <div class="pt-4">
        <TableBase
          :items="corporations.data"
          :fields="fields"
          :options="options"
          :busy="corporations.status === 'fetching' ? true : false"
        >
          <template v-slot:rightSide>
            <div>
              <b-button
                variant="success"
                class="align-bottom"
                @click="showModal()"
              >
                <b-icon icon="plus" />
                <span class="" />
              </b-button>
            </div>
          </template>
        </TableBase>
      </div>

      <b-modal
        id="detailsModal"
        ref="detailsModal"
        size="lg"
        hide-footer
        body-class=""
        ok-disabled
        @hidden="corporationItem = {}"
      >
        <CorporationRegistrationForm
          :corporationItem="corporationItem"
          :refreshData="getData"
          :hideModal="hideModal"
        />
      </b-modal>
    </div>
  </div>
</template>
<script>
import TableBase from "@/modules/shared/table.vue";
import CorporationRegistrationForm from "./CorporationRegistrationForm.vue";

import { GET_CORPORATIONS, POST_CORPORATION } from "./actions";
import { mapState } from "vuex";

import { generalMixin } from "@/modules/mixin.js";
export default {
  components: { TableBase, CorporationRegistrationForm },
  mixins: [generalMixin],
  computed: {
    ...mapState({
      corporations: (state) => state.masterUser.corporation.corporations,
    }),

    fields() {
      return [
        { key: "companyName", label: "Company name" },
        { key: "commercialName", label: "Commercial name" },
        { key: "state", label: "State" },
        {
          key: "actions",
          label: "Actions",
          note: false,
          details: true,
          validation: false,
          delete: false,
        },
      ];
    },
  },
  data() {
    return {
      options: {
        assign: false,
        rolFilter: false,
        add: false,
        fun: this.getData,
      },
      corporationItem: {},
    };
  },
  mounted() {
    this.$store.commit("setAddFunction", this.showModal);
    this.getData();
  },
  methods: {
    getData(filter = "") {
      this.$store
        .dispatch(GET_CORPORATIONS, { search: filter })
        .then(() => {})
        .catch(() => {});
    },
    async deleteItem(item) {
      const confirm = await this.showConfirmDeleteBox();
      if (!confirm) return;
      const payload = { ...item };
      payload.status = 0;
      this.$store
        .dispatch(POST_CORPORATION, payload)
        .then(() => {
          this.getData();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Corporation could not be deleted", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    activateItem(item) {
      const payload = { ...item };
      payload.status = 1;
      this.$store
        .dispatch(POST_CORPORATION, payload)
        .then(() => {
          this.makeToast(
            "Success",
            "Corporation updated successfully",
            "success"
          );
          this.getData();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Corporation could not be activated", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    showModal() {
      this.$bvModal.show("detailsModal");
    },
    editItem(item) {
      this.corporationItem = item;
      this.$bvModal.show("detailsModal");
    },
    changePage(page, filter = "") {
      const payload = {
        roleName: "ROLE_AGENT",
        search: filter,
        page: page,
      };
      this.$store
        .dispatch(GET_CORPORATIONS, payload)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },

    hideModal() {
      this.$bvModal.hide("detailsModal");
    },
  },
};
</script>
