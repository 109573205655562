<template>
  <div>
    <div>
      <ul class="listSeparateByDots">
        <li v-for="(typeWash, key) in typeWashes.typeWashes" :key="key">
          <span> {{ typeWash.name }}</span>
          <span>{{ typeWash.cost }}</span>
        </li>
        <div v-if="typeWashes.extra">
          Extras:
          <li>
            <span> {{ typeWashes.extra }}</span>
            <span>{{ typeWashes.extraCost }}</span>
          </li>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ["typeWashes"],
};
</script>

<style scoped>
ul.listSeparateByDots {
  max-width: 50em;
  padding: 0;
  overflow-x: hidden;
  list-style: none;
}
ul.listSeparateByDots li:before {
  float: left;
  width: 0;
  white-space: nowrap;
  content: ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . ";
}
ul.listSeparateByDots span:first-child {
  padding-right: 0.33em;
  background: white;
}
ul.listSeparateByDots span + span {
  float: right;
  padding-left: 0.33em;
  background: white;
}
</style>