<template>
  <div>
    <div v-if="notes.length == 0" class="text-center">
      <div class="h2 mt-3">No notes found</div>
    </div>
    <div v-else>
      <TaskNoteCard v-for="note in notes" :key="note.id" :note="note" />
    </div>
  </div>
</template>

<script>
import TaskNoteCard from "./TaskNoteCard.vue";
export default {
  components: { TaskNoteCard },
  name: "TaskNotesList",
  props: {
    notes: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style></style>
