<template>
  <div>
    <Nav @set-status="setStatus" />
    <div class="container">
      <b-row class="">
        <!--   <b-col>
          <h4 class="ml-3">Registration</h4>
        </b-col> -->
      </b-row>
      <b-row class="mb-4 mr-1 ml-1">
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="4"
          xl="4"
          class="d-flex justify-content-center mt-4"
        >
        </b-col>

        <b-col xs="12" sm="12" md="12" lg="4" xl="4" class="mt-4">
          <!--           <b-input-group
            size="sm"
            class="
              shadows
              rounded-right rounded-left
              align-middle
              bg-dark-green
            "
          >
            <b-form-input
              id="filterInput"
              class="filter-input rounded-right rounded-left"
              type="search"
              v-model="filter"
              @change="getData($event)"
            />
            <b-input-group-append class="rounded-right rounded-left">
              <b-icon
                icon="search"
                variant="yellow"
                class="h5 m-2 align-middle"
              />
            </b-input-group-append>
          </b-input-group> -->
        </b-col>

        <b-col xs="12" sm="12" md="12" lg="4" xl="4" class="text-center mt-4">
          <!--      <b-button
            v-if="userHasPermission('registration', 'write')"
            variant="success"
            @click="showModal()"
          >
            <b-icon icon="plus" class="mt-1" />
          </b-button> -->
        </b-col>
      </b-row>
      <div class="pt-4">
        <b-button
          v-if="userHasPermission('registration', 'write')"
          size="md"
          @click="selectAllRows"
          variant="success"
          class="mb-2"
        >
          Select all</b-button
        >
        <b-button
          v-if="userHasPermission('registration', 'write')"
          size="md"
          @click="clearSelected"
          variant="warning"
          class="mb-2 mx-2 text-white"
        >
          Unselect all</b-button
        >

        <b-button-group
          class="mb-2"
          v-if="userHasPermission('registration', 'write')"
        >
          <b-dropdown right text="Choose status" variant="success">
            <!--             <b-dropdown-item
              v-if="status != 1"
              @click="changeRegistrationStatus(1)"
            >
              Received
            </b-dropdown-item> -->
            <b-dropdown-item
              v-if="status != 2"
              @click="changeRegistrationStatus(2)"
              >ODO</b-dropdown-item
            >
            <b-dropdown-item
              v-if="status != 3"
              @click="changeRegistrationStatus(3)"
            >
              SAAQ</b-dropdown-item
            >
            <b-dropdown-item
              v-if="status != 4"
              @click="changeRegistrationStatus(4)"
            >
              Problems</b-dropdown-item
            >
            <b-dropdown-item
              v-if="status != 5"
              @click="changeRegistrationStatus(5)"
            >
              ✔</b-dropdown-item
            >
          </b-dropdown>
        </b-button-group>

        <b-table
          thead-class="text-center bg-light-grey "
          head-variant="light"
          bordered
          small
          show-empty
          outlined
          stacked="md"
          class="text-break"
          :items="registrations.data.data"
          :fields="fields"
          :busy="registrations.status == 'fetching'"
          :select-mode="selectMode"
          responsive="sm"
          ref="selectableTable"
          selectable
          @row-selected="onRowSelected"
        >
          <template #table-busy>
            <div class="text-center text-1D3030 my-2">
              <b-spinner variant="yellow" class="align-middle" />
              <p>Loading...</p>
            </div>
          </template>
          <!-- Example scoped slot for select state illustrative purposes -->
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <div class="text-center">
                <span aria-hidden="true">&check;</span>
                <span class="sr-only">Selected</span>
              </div>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
          <template v-slot:cell(actions)="{ item }">
            <div class="text-center">
              <b-icon
                icon="cloud-upload
                "
                @click="openUploadModal(item)"
              ></b-icon>
            </div>
          </template>
        </b-table>
        <b-row>
          <b-col
            xs="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            class="d-flex justify-content-between"
          >
            <span>
              <p v-show="itemsSelected.length > 0">
                Selected: {{ itemsSelected.length }}
              </p>
            </span>
            <b-pagination
              v-if="registrations.status != 'fetching'"
              :value="registrations.data.currentPage"
              :total-rows="registrations.data.total"
              :per-page="registrations.data.perPage"
              class="mb-2"
              @change="changePage($event)"
            />
          </b-col>
        </b-row>
      </div>
    </div>
    <b-modal
      id="detailsModal"
      ref="detailsModal"
      size="lg"
      hide-footer
      body-class="pl-0 pr-0"
      ok-disabled
      @hide="hideModal('detailsModal')"
    >
      <RegistrationForm
        :registrationItem="registrationItem"
        :refreshData="getData"
        :hideModal="hideModal"
      />
    </b-modal>

    <b-modal
      id="uploadFileModal"
      ref="uploadFileModal"
      size="xl"
      hide-footer
      body-class="pl-0 pr-0"
      ok-disabled
      @hide="hideModal('uploadFileModal')"
    >
      <UploadFile
        :vehicle="vehicle ? vehicle : null"
        :refreshData="getData"
        :hideModal="hideModal"
        :registrationItem="registrationItem"
      />
    </b-modal>
  </div>
</template>

<script>
import { GET_REGISTRATIONS, PUT_REGISTRATION_STATUS } from "./actions";
import { mapState } from "vuex";
import { generalMixin } from "@/modules/mixin.js";
import RegistrationForm from "./RegistrationForm.vue";
import UploadFile from "./UploadFile.vue";
import Nav from "./Nav.vue";
import { superAdminMixin } from "../mixins";
export default {
  name: "RegistrationTable",
  mixins: [generalMixin, superAdminMixin],
  components: { Nav, RegistrationForm, UploadFile },
  computed: {
    ...mapState({
      registrations: (state) =>
        state.superAdminStore.registration.registrations,
    }),
  },
  data() {
    return {
      modes: ["multi", "single", "range"],
      fields: [
        "selected",
        { key: "stockNumber", label: "Stock number" },

        { key: "vinNumber", label: "Vin number" },

        { key: "make", label: "Make" },
        { key: "model", label: "Model" },
        { key: "year", label: "Year" },
        { key: "color", label: "Color" },
        {
          key: "registration.createdAt",
          label: "Created at",
          formatter: (date) => this.$options.filters.formatDate(date),
        },
        {
          key: "registration.updatedAt",
          label: "Updated at",
          formatter: (date) => this.$options.filters.formatDate(date),
        },
        { key: "actions", label: "Actions" },
      ],
      filter: null,
      selectMode: "multi",
      selected: [],
      itemsSelected: [],
      status: 2,
      registrationItem: {},
      vehicle: {},
    };
  },
  created() {
    this.$store.commit(
      "setAddFunction",
      this.userHasPermission("registration", "write") ? this.showModal : null
    );

    this.$store.commit("setSearchFunction", this.getData);
    this.getData();
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    changePage(page) {
      const payload = {
        search: this.filter,
        page: page,
        status: this.status,
      };
      this.$store
        .dispatch(GET_REGISTRATIONS, payload)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    setStatus(status) {
      this.status = status;
      this.filter = null;
      this.getData();
    },
    openUploadModal(item) {
      this.vehicle = item;
      this.registrationItem = item;
      this.$bvModal.show("uploadFileModal");
    },

    getData(filter = null) {
      this.$store
        .dispatch(GET_REGISTRATIONS, { status: this.status, search: filter })
        .then(() => {});
    },
    showModal() {
      this.registrationItem = {};
      this.$bvModal.show("detailsModal");
    },
    hideModal(modal) {
      this.$bvModal.hide(modal);
    },
    changeRegistrationStatus(status) {
      this.$store
        .dispatch(PUT_REGISTRATION_STATUS, {
          status: status,
          list: this.selected,
        })
        .then(() => {
          this.$root.$bvToast.toast("Registration status change successfully", {
            title: "Success",
            variant: "success",
          });
          this.getData();
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-input {
  background-color: #1d3030;
  opacity: 1;
  color: white;
  border: none;
}
.form-control:focus {
  background-color: #1d3030;
  color: white;
  border-color: #1d3030;
}
.shadows {
  box-shadow: 0 7px 7px 0 rgba(0, 0, 0, 0.5);
}
</style>