import * as constants from "./actions";
import axios from "@/plugins/axios.js";
import notes from "./notes/store.js";
function getDefaultState() {
  return {
    sold: {
      status: null,
      data: [],
      error: null,
    },
    soldRegister: {
      status: null,
      data: {},
      error: null,
    },
    soldPDF: {
      status: null,
      data: {},
      error: null,
    },
    taxes: {
      status: null,
      data: [],
      error: null,
    },
  };
}
const actions = {
  [constants.GET_SOLD]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_SOLD);

      const response = await axios.get("vehiclesSales/findSale", {
        params: payload,
      });
      commit(constants.GET_SOLD_SUCCESS, response);

      return response;
    } catch (error) {
      commit(constants.GET_SOLD_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_SOLD]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_SOLD);
      const response = await axios.post("vehiclesSales/registerSale", payload);
      commit(constants.POST_SOLD_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_SOLD_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_SOLD_PDF]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_SOLD_PDF);
      const response = await axios.get("vehiclesSales/generatePDF", {
        params: payload,
        responseType: "blob",
      });
      commit(constants.GET_SOLD_PDF_SUCCESS, response);
      return response;
    } catch (error) {
      console.log(error);
      commit(constants.GET_SOLD_PDF_ERROR, error);
      throw error;
    }
  },
  [constants.GET_SOLD_PDF_EMAIL]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_SOLD_PDF_EMAIL);
      const response = await axios.get("vehiclesSales/sendPurchaseOrder", {
        params: payload,
      });
      commit(constants.GET_SOLD_PDF_EMAIL_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_SOLD_PDF_EMAIL_ERROR, error);
      throw error;
    }
  },
  [constants.PUT_SOLD]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_SOLD);
      const response = await axios.put("vehiclesSales/changeStatus", payload);
      commit(constants.PUT_SOLD_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_SOLD_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_SOLD_TAXES]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_SOLD_TAXES);
      const response = await axios.get("taxes/getTaxByVehicle", {
        params: payload,
      });
      commit(constants.GET_SOLD_TAXES_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_SOLD_TAXES_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_SOLD]: (state) => {
    state.sold.data = {};
    state.sold.status = "fetching";
  },
  [constants.GET_SOLD_SUCCESS]: (state, response) => {
    state.sold.status = "success";
    state.sold.data = response;
  },
  [constants.GET_SOLD_ERROR]: (state, error) => {
    state.sold.status = "error";
    state.sold.error = error;
  },
  [constants.POST_SOLD]: (state) => {
    state.soldRegister.data = {};
    state.soldRegister.status = "fetching";
  },
  [constants.POST_SOLD_SUCCESS]: (state, response) => {
    state.soldRegister.status = "success";
    state.soldRegister.data = response;
  },
  [constants.POST_SOLD_ERROR]: (state, error) => {
    state.soldRegister.status = "error";
    state.soldRegister.error = error;
  },
  [constants.GET_SOLD_PDF]: (state) => {
    state.soldPDF.data = {};
    state.soldPDF.status = "fetching";
  },
  [constants.GET_SOLD_PDF_SUCCESS]: (state, response) => {
    state.soldPDF.status = "success";
    state.soldPDF.data = response;
  },
  [constants.GET_SOLD_PDF_ERROR]: (state, error) => {
    state.soldPDF.status = "error";
    state.soldPDF.error = error;
  },
  [constants.GET_SOLD_PDF_EMAIL]: (state) => {
    state.soldPDF.data = {};
    state.soldPDF.status = "fetching";
  },
  [constants.GET_SOLD_PDF_EMAIL_SUCCESS]: (state, response) => {
    state.soldPDF.status = "success";
    state.soldPDF.data = response;
  },
  [constants.GET_SOLD_PDF_EMAIL_ERROR]: (state, error) => {
    state.soldPDF.status = "error";
    state.soldPDF.error = error;
  },
  [constants.PUT_SOLD]: (state) => {
    state.sold.data = {};
    state.sold.status = "fetching";
  },
  [constants.PUT_SOLD_SUCCESS]: (state, response) => {
    state.sold.status = "success";
    state.sold.data = response;
  },
  [constants.PUT_SOLD_ERROR]: (state, error) => {
    state.sold.status = "error";
    state.sold.error = error;
  },
  [constants.GET_SOLD_TAXES]: (state) => {
    state.taxes.data = [];
    state.taxes.status = "fetching";
  },
  [constants.GET_SOLD_TAXES_SUCCESS]: (state, response) => {
    state.taxes.status = "success";
    state.taxes.data = response.taxes;
  },
  [constants.GET_SOLD_TAXES_ERROR]: (state, error) => {
    state.taxes.status = "error";
    state.taxes.error = error;
  },
};
export default {
  state: getDefaultState(),
  actions,
  mutations,
  modules: {
    notes,
  },
};
