import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    vehicles: {
      status: null,
      data: [],
      error: null,
      filters: {
        search: null,
        resourcesPerPage: 25,
        page: 1,
        status: null,
        saleDateFrom: null,
        saleDateTo: null,
        reserveDateFrom: null,
        reserveDateTo: null,
      },
    },

  };
}

const actions = {
  [constants.GET_CLIENT_VEHICLES_INFO]: async ({ commit, state }) => {
    try {
      commit(constants.GET_CLIENT_VEHICLES_INFO);
      const payload = Object.entries(state.vehicles.filters).reduce(
        (a, [k, v]) => (v === null || v === "" ? a : { ...a, [k]: v }),
        {}
      );
      const response = await axios.get("dashboard/getDashboardClient", {
        params: payload,
      });
      commit(constants.GET_CLIENT_VEHICLES_INFO_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_CLIENT_VEHICLES_INFO_ERROR, error);
      throw error.response;
    }
  },

};

const mutations = {
  [constants.GET_CLIENT_VEHICLES_INFO]: (state) => {
    state.vehicles.status = "fetching";
    state.vehicles.error = null;
  },
  [constants.GET_CLIENT_VEHICLES_INFO_SUCCESS]: (state, data) => {
    state.vehicles.status = "success";
    state.vehicles.error = null;
    state.vehicles.data = data;
  },
  [constants.GET_CLIENT_VEHICLES_INFO_ERROR]: (state, error) => {
    state.vehicles.status = "error";
    state.vehicles.error = error;
  },

  [constants.SET_CLIENT_VEHICLES_INFO_FILTERS]: (state, filters) => {

    if (filters.reset) {
      state.vehicles.filters = {
        search: null,
        resourcesPerPage: 25,
        page: 1,
      };
    }
    if (filters.fields) {
      state.vehicles.filters = {
        ...state.vehicles.filters,
        ...filters.fields,
      };
    }
  },


};

/* const getters = {
  getClientsFilters() {
    return (state) => state.vehicles.filters;
  },
}; */

export default {
  state: getDefaultState(),
  actions,

  mutations,


};
