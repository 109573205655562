<template>
  <div>
    <div class="container-fluid">
      <b-row class="mt-3">
        <b-col>
          <h4 class="ml-3">History</h4>
        </b-col>
      </b-row>
      <div class="pt-4">
        <TableBase :items="fx" :fields="fields" :options="options" :busy="isLoading">
        </TableBase>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TableBase from "@/modules/shared/table.vue";
export default {
  name: "FxHistoryTable",
  components: { TableBase },
  computed: {
    ...mapState({
      fx: (state) => state.superAdminStore.fx.fx.data.data || {},
      isLoading: (state) =>
        state.superAdminStore.fx.fx.status === "fetching" ? true : false,
    }),
    fields() {
      return [
        {
          key: "value",
          label: "FX",
          class: "text-center",
          formatter: (value) => this.$options.filters.currency(value),
        },
        {
          key: "buy",
          label: "Buy",
          class: "text-center",

          formatter: (value) => this.$options.filters.currency(value),
        },
        {
          key: "sale",
          label: "Sale",
          class: "text-center",

          formatter: (value) => this.$options.filters.currency(value),
        },
        {
          key: "createdAt",
          label: "Date",
          formatter: (value) => this.$options.filters.formatDate(value),
        },
      ];
    },
  },
  data() {
    return {
      options: {
        assign: false,
        rolFilter: false,
        add: false,
        fun: this.getData,
      },
    };
  },
  methods: {
    changePage(page) {
      this.$store.dispatch("GET_CURRENCY_CONVERSION", {
        page: page,
      });
    },
  },
};
</script>

<style></style>
