export const GET_ADDONS_TYPE = "GET_ADDONS_TYPE";
export const GET_ADDONS_TYPE_SUCCESS = "GET_ADDONS_TYPE_SUCCESS";
export const GET_ADDONS_TYPE_ERROR = "GET_ADDONS_TYPE_ERROR";

export const POST_ADDON_TYPE = "POST_ADDON_TYPE";
export const POST_ADDON_TYPE_SUCCESS = "POST_ADDON_TYPE_SUCCESS";
export const POST_ADDON_TYPE_ERROR = "POST_ADDON_TYPE_ERROR";


export const GET_ADDONS_TYPE_LIST = "GET_ADDONS_TYPE_LIST";
export const GET_ADDONS_TYPE_LIST_SUCCESS = "GET_ADDONS_TYPE_LIST_SUCCESS";
export const GET_ADDONS_TYPE_LIST_ERROR = "GET_ADDONS_TYPE_LIST_ERROR";

