<template>
  <div>
    <b-row>
      <b-col :cols="!showTextArea && form.description != '' ? 10 : 12">
        <b-form-input
          v-if="!showTextArea"
          v-model="form.description"
          placeholder="Add new note"
          @focus.native="toggleTextArea"
          class="text-dark"
        ></b-form-input>
      </b-col>
      <b-col cols="12" v-if="showTextArea">
        <b-form-textarea
          id="textarea"
          v-model="form.description"
          placeholder=""
          rows="3"
          max-rows="6"
          class="mb-2 text-dark"
          @blur="toggleTextArea()"
          autofocus
        ></b-form-textarea>
      </b-col>
      <b-col :cols="showTextArea ? 12 : 2">
        <div class="d-flex justify-content-end">
          <b-button
            v-if="showTextArea || form.description != ''"
            @click="addNewNote()"
            variant="primary"
            :disabled="isLoading"
          >
            <b-spinner v-if="isLoading" variant="white" small />
            <span v-else> Submit </span>
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { POST_TASK_NOTE } from "../actions";
export default {
  name: "TaskNoteForm",
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      taskNote: (state) => state.superAdminStore.tasks.note,
    }),
    isLoading() {
      return this.taskNote.status == "fetching";
    },
    showTextArea() {
      return this.isShowTextArea;
    },
  },
  data() {
    return {
      form: {
        description: "",
      },
      isShowTextArea: false,
    };
  },

  methods: {
    toggleTextArea() {
      this.isShowTextArea = !this.isShowTextArea;
    },
    addNewNote() {
      this.$store
        .dispatch(POST_TASK_NOTE, this.formatPayload())
        .then((response) => {
          this.$emit("updateNotes", response.note);
          this.resetForm();
          this.$bvToast.toast("Note registered successfully", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        });
    },
    resetForm() {
      this.form = {
        description: "",
      };
    },
    formatPayload() {
      return {
        description: this.form.description,
        taskId: this.task.id,
      };
    },
  },
};
</script>

<style></style>
