import * as constants from "./actions";
import axios from "@/plugins/axios.js";
import payments from "./payments/store"
function getDefaultState() {
  return {
    exchangeContracts: {
      status: null,
      data: null,
      error: null,
    },
    fxRate: {
      status: null,
      data: null,
      error: null,
    },
    exchangeContract: {
      status: null,
      data: null,
      error: null,
    },

    exchangeContractsXls: {
      status: null,
      data: null,
      error: null,
    },
    exchangeContractExcelByCurrency: {
      status: null,
      data: null,
      error: null,
    },
    totalNonAllocatedCad: 0,
    totalNonAllocatedUsd: 0,
    totalBalanceCad: 0,
    totalBalanceUsd: 0,
    totalInventoryValueCad: 0,
    totalInventoryValueUsd: 0,
    averageContractFx: 0,
  };
}
const actions = {
  [constants.GET_EXCHANGE_CONTRACTS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_EXCHANGE_CONTRACTS);
      const response = await axios.get("exchangeContract/getExchangeContract", {
        params: payload,
      });
      commit(constants.GET_EXCHANGE_CONTRACTS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_EXCHANGE_CONTRACTS_ERROR, error);
      throw error.response;
    }
  },

  [constants.GET_FX_RATE]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_FX_RATE);
      const response = await axios.get("exchangeContract/getFx", {
        params: payload,
      });
      commit(constants.GET_FX_RATE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_FX_RATE_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_EXCHANGE_CONTRACT]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_EXCHANGE_CONTRACT);
      const response = await axios.post("exchangeContract/create", payload);
      commit(constants.POST_EXCHANGE_CONTRACT_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_EXCHANGE_CONTRACT_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_EXCHANGE_CONTRACTS_VEHICLES_XLS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_EXCHANGE_CONTRACTS_VEHICLES_XLS);
      const response = await axios.get("exchangeContract/downloadXls", {
        params: payload,
        responseType: 'blob',
      });
      commit(constants.GET_EXCHANGE_CONTRACTS_VEHICLES_XLS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_EXCHANGE_CONTRACTS_VEHICLES_XLS_ERROR, error);
      throw error.response;
    }
  },

  [constants.GET_EXCHANGE_CONTRACTS_XLS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_EXCHANGE_CONTRACTS_XLS);
      const response = await axios.get("exchangeContract/downloadExchangeContractXls", {
        params: payload,
        responseType: 'blob',
      });
      commit(constants.GET_EXCHANGE_CONTRACTS_XLS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_EXCHANGE_CONTRACTS_XLS_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_EXCHANGE_XLS_BY_CURRENCY]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_EXCHANGE_XLS_BY_CURRENCY);
      const response = await axios.get("downloadInventoryValueXls", {
        params: payload,
        responseType: 'blob',
      });
      commit(constants.GET_EXCHANGE_XLS_BY_CURRENCY_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_EXCHANGE_XLS_BY_CURRENCY_ERROR, error);
      throw error.response;
    }
  }


};

const mutations = {
  [constants.GET_EXCHANGE_CONTRACTS]: (state) => {
    state.exchangeContracts.data = [];
    state.exchangeContracts.status = "fetching";
    state.totalNonAllocatedCad = 0
    state.totalNonAllocatedUsd = 0
    state.totalBalanceCad = 0
    state.totalBalanceUsd = 0
    state.totalInventoryValueCad = 0
    state.totalInventoryValueUsd = 0
    state.averageContractFx = 0
  },
  [constants.GET_EXCHANGE_CONTRACTS_SUCCESS]: (state, response) => {
    state.exchangeContracts.status = "success";

    state.exchangeContracts.data = response.data;
    state.totalNonAllocatedCad = response.totalNonAllocatedCad
    state.totalNonAllocatedUsd = response.totalNonAllocatedUsd
    state.totalBalanceCad = response.totalBalanceCad
    state.totalBalanceUsd = response.totalBalanceUsd
    state.totalInventoryValueCad = response.totalInventoryValueCad
    state.totalInventoryValueUsd = response.totalInventoryValueUsd
    state.averageContractFx = response.averageContractFx

  },
  [constants.GET_EXCHANGE_CONTRACTS_ERROR]: (state, error) => {
    state.exchangeContracts.status = "error";
    state.exchangeContracts.error = error;
  },
  [constants.GET_FX_RATE]: (state) => {
    state.fxRate.data = [];
    state.fxRate.status = "fetching";
  },
  [constants.GET_FX_RATE_SUCCESS]: (state, response) => {
    state.fxRate.status = "success";

    state.fxRate.data = response;
  },
  [constants.GET_FX_RATE_ERROR]: (state, error) => {
    state.fxRate.status = "error";
    state.fxRate.error = error;
  },
  [constants.POST_EXCHANGE_CONTRACT]: (state) => {
    state.exchangeContract.data = {};
    state.exchangeContract.status = "fetching";
  },
  [constants.POST_EXCHANGE_CONTRACT_SUCCESS]: (state, response) => {
    state.exchangeContract.status = "success";
    state.exchangeContract.data = response;
  },
  [constants.POST_EXCHANGE_CONTRACT_ERROR]: (state, error) => {
    state.exchangeContract.status = "error";
    state.exchangeContract.error = error;
  },
  [constants.GET_EXCHANGE_CONTRACTS_VEHICLES_XLS]: (state) => {
    state.exchangeContractsXls.data = [];
    state.exchangeContractsXls.status = "fetching";
  },
  [constants.GET_EXCHANGE_CONTRACTS_VEHICLES_XLS_SUCCESS]: (state, response) => {
    state.exchangeContractsXls.status = "success";

    state.exchangeContractsXls.data = response;
  },
  [constants.GET_EXCHANGE_CONTRACTS_VEHICLES_XLS_ERROR]: (state, error) => {
    state.exchangeContractsXls.status = "error";
    state.exchangeContractsXls.error = error;
  },
  [constants.GET_EXCHANGE_XLS_BY_CURRENCY]: (state) => {
    state.exchangeContractExcelByCurrency.data = {}
    state.exchangeContractExcelByCurrency.status = "fetching";
  },
  [constants.GET_EXCHANGE_XLS_BY_CURRENCY_SUCCESS]: (state, response) => {
    state.exchangeContractExcelByCurrency.status = "success";
    state.exchangeContractExcelByCurrency.data = response;
  },
  [constants.GET_EXCHANGE_XLS_BY_CURRENCY_ERROR]: (state, error) => {
    state.exchangeContractExcelByCurrency.status = "error";
    state.exchangeContractExcelByCurrency.error = error;
  },



};
export default {
  state: getDefaultState(),
  actions,
  mutations,
  modules: {
    payments
  }
};
