import * as constants from "./actions";
import axios from "@/plugins/axios.js";
function getDefaultState() {
  return {
    export: {
      status: null,
      data: {},
      error: null,
    },
    states: {
      status: null,
      data: [],
      error: null
    }
  };
}

const actions = {
  [constants.GET_EXPORT]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_EXPORT);
      const response = await axios.get(`export/findExport`, {
        params: payload,
      });
      commit(constants.GET_EXPORT_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_EXPORT_ERROR, error);
      throw error.response;
    }
  },

  [constants.POST_EXPORT]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_EXPORT);
      const response = await axios.post("export/createAndUpdate ", payload);
      commit(constants.POST_EXPORT_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_EXPORT_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_DELETE_DOCUMENT]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_DELETE_DOCUMENT);
      const response = await axios.put(`export/deleteDocument`, payload);
      commit(constants.PUT_DELETE_DOCUMENT_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_DELETE_DOCUMENT_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_TITLE_ORIGIN]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_TITLE_ORIGIN);
      const response = await axios.get(`vehiclesSales/listStates`, {
        params: payload,
      });
      commit(constants.GET_TITLE_ORIGIN_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_TITLE_ORIGIN_ERROR, error);
      throw error.response;
    }
  },

};

const mutations = {
  [constants.GET_EXPORT]: (state) => {
    state.export.status = "fetching";
    state.export.error = null;
  },
  [constants.GET_EXPORT_SUCCESS]: (state, data) => {
    state.export.status = "success";
    state.export.error = null;
    state.export.data = data.export;
  },
  [constants.GET_EXPORT_ERROR]: (state, error) => {
    state.export.status = "error";
    state.export.error = error;
  },
  [constants.POST_EXPORT]: (state) => {
    state.export.status = "fetching";
    state.export.error = null;
  },
  [constants.POST_EXPORT_SUCCESS]: (state, data) => {
    state.export.status = "success";
    state.export.error = null;
    state.export.data = data;
  },
  [constants.POST_EXPORT_ERROR]: (state, error) => {
    state.export.status = "error";
    state.export.error = error;
  },
  [constants.PUT_DELETE_DOCUMENT]: (state) => {
    state.export.status = "fetching";
    state.export.error = null;
  },
  [constants.PUT_DELETE_DOCUMENT_SUCCESS]: (state, data) => {
    state.export.status = "success";
    state.export.error = null;
    state.export.data = data;
  },
  [constants.PUT_DELETE_DOCUMENT_ERROR]: (state, error) => {
    state.export.status = "error";
    state.export.error = error;
  },
  [constants.GET_TITLE_ORIGIN]: (state) => {
    state.states.status = "fetching";
    state.states.error = null;
  },
  [constants.GET_TITLE_ORIGIN_SUCCESS]: (state, data) => {
    state.states.status = "success";
    state.states.error = null;
    state.states.data = data.states;
  },
  [constants.GET_TITLE_ORIGIN_ERROR]: (state, error) => {
    state.states.status = "error";
    state.states.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
