<template>
  <div>
    <div>
      <span>
        Registered date:
        <i>
          {{ payment.createdAt | formatDateTime }} -
          {{ payment.user.firstName }}
          {{ payment.user.lastName }}
        </i>
      </span>
    </div>

    <div class="">
      <span
        >Accounting date:
        <i
          >{{ payment.accountTime | formatDateTime }} -
          {{ payment.user.firstName }}
          {{ payment.user.lastName }}</i
        >
      </span>
    </div>
    <div>
      <span v-if="payment.logisticDeliveryDate != null">
        Delivered date
        <i
          >{{ payment.logisticDeliveryDate | formatDateTime }} -
          {{ payment.logisticDeliveryUser.firstName }}
          {{ payment.logisticDeliveryUser.lastName }}</i
        >
      </span>
    </div>
    <div class="mt-1 mb-1">
      <b-row>
        <b-col> <b>Method:</b> {{ payment.paymentMethod }} </b-col>
        <b-col>
          <div class="text-right">
            <span
              class="dot mr-3"
              :class="[payment.status ? 'dot-success' : 'dot-danger']"
            />
            <b-form-group v-if="payment.status" class="ml-1">
              <b-form-checkbox
                :checked="true"
                switch
                @change="disablePayment(payment)"
              />
            </b-form-group>

            <div />
          </div>
        </b-col>
      </b-row>
    </div>

    <h3 class="mb-1">
      <i class="mr-3">{{ payment.transactionNumber }} </i>
      <i
        >{{
          corporation.typeCurrency == "CAD"
            ? payment.price
            : payment.priceUsd | currency
        }}
      </i>
    </h3>
    <p>
      <font-awesome-icon
        v-if="payment.logisticDeliveryDate == null && payment.status != 0"
        class="icon-color cursor-pointer float-right h2"
        :icon="['far', 'check-to-slot']"
        @click="markChecksAsDelivered(payment)"
      />
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SellCheckCard",
  props: ["payment"],
  computed: {
    ...mapState({
      profile: (state) => state.auth.loginStore.profile.data,
    }),
    corporation() {
      const corporation = localStorage.getItem("corporation");
      let corporationSelect = this.profile.user.corporationPermissions.find(
        (c) => c.id == corporation
      );

      return corporationSelect
        ? corporationSelect
        : this.profile.user.corporationPermissions[0];
    },
  },
};
</script>

<style>
</style>