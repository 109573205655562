export default [
  {
    authority: "ROLE_SUPER_ADMIN",
    mainRoute: "superadmin.vehicles.table.inventory",
  },
  {
    authority: "ROLE_ADMIN",
    mainRoute: "superadmin.vehicles.table.inventory",
  },
  {
    authority: "ROLE_BUYER",
    mainRoute: "client.vehicle-list",
  },
  {
    authority: "ROLE_CLIENT",
    mainRoute: "client.vehicle-list",
  },
  {
    authority: "ROLE_MASTER",
    mainRoute: "master.assign.corporation",
  },
  {
    authority: "ROLE_AGENT",
    mainRoute: "superadmin.vehicles.table.sales",
  },
  {
    authority: "ROLE_EXTERNAL_AGENT",
    mainRoute: "superadmin.vehicles.table.sales",
  },
  {
    authority: "ROLE_RI",
    mainRoute: "ri.vehicles",
  }
];
