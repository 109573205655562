<template>
  <div class="container-fluid">
    <div class="pt-2">
      <TableBase
        :items="companies"
        :fields="fields"
        :options="options"
        :busy="companies.status === 'fetching' ? true : false"
      >
      </TableBase>
    </div>

    <b-modal
      id="detailsModal"
      ref="detailsModal"
      size="lg"
      hide-header
      body-class="pl-0 pr-0"
      ok-disabled
    >
      <UserDetails :user-item="userItem" />
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="danger"
            size="sm"
            class="float-right"
            @click="$bvModal.hide('detailsModal')"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapState } from "vuex";

import TableBase from "@/modules/shared/table.vue";
import UserDetails from "@/modules/buyer/Details.vue";
import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../../superAdmin/mixins";
export default {
  name: "AccountingCompaniesTable",
  components: { TableBase, UserDetails },
  mixins: [generalMixin, superAdminMixin],
  computed: {
    ...mapState({
      companies: (state) => state.superAdminStore.accounting.companies,
    }),
  },

  data() {
    return {
      fields: [
        { key: "companyName", label: "Company name" },

        {
          key: "actions",
          label: "Actions",
          note: false,
          details: false,
          validation: false,
          locations: false,
          cars: true,
        },
      ],
      options: {
        assign: false,
        rolFilter: false,
        add: false,
        fun: this.getData,
      },
      userItem: {},
    };
  },
  mounted() {
    this.$store.commit("setAddFunction", null);

    this.getData();
  },
  methods: {
    async getData(filter = "") {
      const payload = {
        search: filter,
      };
      this.$store
        .dispatch("GET_ACCOUNTING_COMPANIES_LIST", payload)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    editItem(item) {
      this.userItem = item;
      this.$bvModal.show("clientModal");
    },
    changePage(page, filter = "") {
      this.isBusy = true;
      const payload = {
        search: filter,
        page: page,
      };
      this.$store
        .dispatch("GET_USERS_COMPANY", payload)
        .then((response) => {
          this.data = response;
          this.isBusy = false;
        })
        .catch(() => {
          this.$root.$bvToast.toast("Warning", {
            title: "Warning",
            variant: "warning",
          });
        });
      this.isBusy = false;
    },

    goToCars(item) {
      this.$router.push({
        name: "superadmin.accounting.vehicles",
        params: { id: item.id, type: "company" },
      });
    },
  },
};
</script>
