<template>
  <b-row>
    <b-col>
      <Search
        ref="searchVehiclesComponent"
        class="search sticky-top"
      />
    </b-col>
    <b-col
      xl="9"
      md="9"
      sm="12"
    >
      <b-row>
        <b-col v-if="purchasesStatus === 'fetching'">
          <Skeleton />
        </b-col>

        <b-col
          v-else-if="purchases && purchases.data && !purchases.data.length"
          class="mt-2 col-lg-4 col-centered"
        >
          <b>No records to show</b>
        </b-col>
        <b-col
          v-for="purchase in purchases.data"
          v-else-if="purchases && purchases.data"
          :key="purchase.id"
          class="mt-2"
          xl="4"
          md="4"
          sm="12"
        >
          <VehicleCard
            :vehicle="purchase.vehicles"
            :is-purchases="true"
            :purchase-date="purchase.registerDate"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from 'vuex';
import Skeleton from './components/Skeleton';
import Search from '../list/Search';
import VehicleCard from '../list/VehicleCard';

export default {
  components: { VehicleCard, Skeleton, Search },
  computed: {
    ...mapState({
      purchasesStatus: (state) =>
        state.adminStore.vehicles.offersStore.purchases.status,
      purchases: (state) =>
        state.adminStore.vehicles.offersStore.purchases.data
    })
  }
};
</script>

<style lang="scss">
@import "./offers.scss";
</style>
