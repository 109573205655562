<template>
  <div>
    <b-list-group class="mt-2 mb-2 note-list" tag="ul">
      <b-list-group-item
        v-for="(vehicle, i) in vehiclesToValidate"
        :key="i"
        class="flex-column align-items-start p-0"
        tag="li"
      >
        <VehicleValidationCard :vehicle="vehicle" />
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import VehicleValidationCard from "./VehicleValidationCard.vue";
export default {
  props: ["vehiclesToValidate"],
  name: "VehiclesValidationList",
  components: { VehicleValidationCard },
};
</script>

<style>
</style>