<template>
  <b-card v-if="workOrder">
    <b-row>
      <b-col cols="10">
        <span>
          Registered date:
          <i
            >{{ workOrder.createdAt | formatDateTime }} -

            <span v-if="workOrder.addonType">
              {{ workOrder.addonType.name }}</span
            >
            <span v-if="workOrder.category">
              {{ workOrder.category.name }}</span
            >
          </i> </span
        ><br />

        <span v-if="workOrder.status == 4 || workOrder.status == 0">
          End date:
          <i>
            {{ workOrder.endDate | formatDateTime }} -
            {{
              workOrder.finalUser
                ? workOrder.finalUser.firstName +
                  " " +
                  workOrder.finalUser.lastName
                : "No one"
            }}
          </i>
        </span>

        <br />
        <span
          >Status:
          <i> {{ getVariantAndLabel(workOrder.status).label }}</i>
        </span>
      </b-col>
      <b-col class="text-center">
        <div class="text-center">
          <span
            class="dot"
            :style="{
              'background-color': getVariantAndLabel(workOrder.status).variant,
            }"
          >
          </span>
          <br />
          <b-icon
            v-if="
              workOrder.status != 4 &&
              workOrder.status != 0 &&
              workOrder.status != 1 &&
              userHasPermission('wo', 'write')
            "
            icon="check-square"
            scale="1.3"
            class="m-1 cursor-pointer"
            id="workOrder-status-btn"
            @click="updateWorkOrderStatus(workOrder.status)"
          ></b-icon>
          <b-tooltip target="workOrder-status-btn">
            <span v-if="workOrder.status == 2">Mark as complete</span>
            <span v-if="workOrder.status == 3">Approve</span>
          </b-tooltip>

          <b-icon
            v-if="workOrder.status != 4 && workOrder.status != 0"
            icon="x-square"
            class="m-1 cursor-pointer"
            v-b-tooltip.hover
            title="Cancel"
            id="cancel-work-order"
            scale="1.3"
            @click="showCancelNote = true"
          ></b-icon>
          <b-tooltip target="cancel-work-order">Cancel</b-tooltip>
          <br />
          <!--           <b-icon icon="pencil" @click="editWorkOrder()" class="cursor-pointer"></b-icon>
 -->
        </div>
      </b-col>
    </b-row>
    <p v-if="showCancelNote">
      <b-form-group label="Note">
        <b-form-textarea
          v-model="note"
          class="resize-none"
          aria-describedby="input-description-live-feedback"
        />
      </b-form-group>
      <!-- Cancel button and confirm button -->
      <b-row>
        <b-col sm="12" md="12" lg="12" xl="12">
          <div class="float-right">
            <b-btn
              size="lg"
              class="btn btn-danger mr-2"
              type="button"
              @click="showCancelNote = false"
            >
              Cancel
            </b-btn>

            <b-btn
              size="lg"
              class="btn btn-success"
              type="button"
              @click="updateWorkOrderStatus(0)"
            >
              Confirm
            </b-btn>
          </div>
        </b-col>
      </b-row>
    </p>
    <p class="text-justify mt-3">
      Description: <i>{{ workOrder.description }}</i>
    </p>
    <p class="text-justify"></p>

    <p class="text-justify">
      Provider:
      <i v-if="workOrder.supplierCompany != null">{{
        workOrder.supplierCompany.companyName
      }}</i>
      <ProviderSelect
        v-if="isProviderEditing"
        @select-supplier="onSelectProvider($event)"
      />

      <b-button
        v-if="isProviderEditing"
        class="mr-2"
        variant="light"
        size="sm"
        @click="isProviderEditing = false"
      >
        <b-icon icon="x" />
      </b-button>
      <b-button
        v-if="isProviderEditing"
        class="mr-2"
        variant="light"
        size="sm"
        @click="update(workOrder)"
      >
        Save
      </b-button>
      <b-button
        v-if="
          !isProviderEditing &&
          workOrder.supplierCompany == null &&
          workOrder.status == 1 &&
          userHasPermission('wo', 'write')
        "
        class="mr-2"
        variant="light"
        size="sm"
        @click="isProviderEditing = true"
      >
        <b-icon icon="pencil" />
      </b-button>
    </p>
    <p>
      Cost:
      <i v-if="!isEditing">{{ workOrder.cost | currency }} </i>
      <b-col v-else class="px-0" xs="12" sm="12" md="12" lg="12" xl="4">
        <b-form-group label-size="sm">
          <template slot="label"> </template>
          <Money
            v-model="form.cost"
            class="form-control text-center"
            v-bind="money"
          />
        </b-form-group>
      </b-col>
      <b-button
        v-if="isEditing"
        class="mr-2"
        variant="light"
        size="sm"
        @click="isEditing = false"
      >
        <b-icon icon="x" />
      </b-button>
      <b-button
        v-if="isEditing"
        class="mr-2"
        variant="light"
        size="sm"
        @click="update(workOrder)"
      >
        Save
      </b-button>
      <b-button
        v-if="
          !isEditing &&
          workOrder.status != 4 &&
          userHasPermission('wo', 'write')
        "
        class="mr-2"
        variant="light"
        size="sm"
        @click="isEditing = true"
      >
        <b-icon icon="pencil" />
      </b-button>

      <b-icon
        v-if="!showCostHistory && !isCostHistoryLoading"
        @click="getCostHistory()"
        icon="arrow-down-circle"
        variant="success"
        class="cursor-pointer"
      ></b-icon>

      <b-icon
        v-if="showCostHistory && !isCostHistoryLoading"
        @click="showCostHistory = false"
        icon="arrow-up-circle"
        variant="success"
        class="cursor-pointer"
      ></b-icon>

      <b-spinner
        v-if="isCostHistoryLoading"
        variant="yellow"
        class="align-middle"
        small
      />
    </p>
    <small v-if="workOrder.cost && workOrder.costUpdateUser != null">
      {{ workOrder.costUpdateDate | formatDateTime }} -
      {{
        workOrder.costUpdateUser.firstName +
        " " +
        workOrder.costUpdateUser.lastName
      }}
    </small>
    <WorkOrderCostHistory v-if="showCostHistory" :history="costHistory" />
    <p v-if="workOrder.note">
      Note: <i>{{ workOrder.note }}</i>
    </p>
    <!--    <WorkOrderCardTasksList
      v-if="workOrder.workOrderItems"
      :tasks="workOrder.workOrderItems"
      :refreshData="refreshData"
      class="mb-2"
    /> -->
    <p>
      <b-button
        v-if="userHasPermission('wo', 'write')"
        variant="success"
        size="sm"
        @click="openEmailBodyModal()"
        class="float-right mx-2"
        v-b-tooltip.hover
        title="Send work order XLS by email"
      >
        <b-icon icon="envelope" variant="white"></b-icon>
      </b-button>
      <font-awesome-icon
        v-b-tooltip.hover
        title="View files"
        class="icon-color cursor-pointer"
        :icon="['far', 'file-magnifying-glass']"
        size="lg"
        @click="openFilesModal(workOrder.id)"
      />
      <span class="text-right" v-if="workOrder.carWash == 1">
        <b-button
          variant="yellow"
          size="sm"
          @click="generateCarwashXLs(workOrder)"
          class="float-right"
          v-b-tooltip.hover
          title="Download carwash XLS"
        >
          <b-icon icon="file-earmark-arrow-down" variant="white"></b-icon>
        </b-button>
      </span>
    </p>
    <p>
      <b-button
        v-if="userHasPermission('wo', 'write')"
        variant="warning"
        size="md"
        v-b-tooltip.hover
        type="button"
        title="Notes"
        class="float-right mx-2 text-white"
        @click="openWorkOrderNotesModal(workOrder.id)"
      >
        Notes
      </b-button>
    </p>
    <br /><br />

    <b-list-group v-if="!notes" class="mt-2">
      <b-list-group-item class="text-center">
        <b-spinner variant="yellow" />
      </b-list-group-item>
    </b-list-group>

    <b-list-group v-else class="mt-2 mb-2 note-list" tag="ul">
      <b-list-group-item v-if="notes && !notes.length" class="text-center">
        <b>No notes to show</b>
      </b-list-group-item>

      <b-list-group-item
        v-for="(note, i) in notes"
        :key="i"
        class="flex-column align-items-start"
        tag="li"
      >
        <NoteCard :note="note" :refreshData="getWorkOrderNotes" />
      </b-list-group-item>
    </b-list-group>
    <WorkOrderNotesModal :workOrder="workOrder" />
    <EmailBody @submit="sendWordOrderXLSByEmail" :id="workOrder.id" />
    <FilesModal :workOrder="workOrder" :refreshData="refreshData" />
    <TaskUpdateStatusModalVue
      :tasks="tasks"
      :id="workOrder.id"
      @update-task="refreshAfterUpdateTask"
    />
  </b-card>
</template>

<script>
import {
  PUT_WORK_ORDER,
  POST_WORK_ORDER,
  GET_WORK_ORDER_EMAIL,
  GET_WORK_ORDER_COST_HISTORY,
} from "./actions";
import { Money } from "v-money";
import { mapState } from "vuex";
import { generalMixin } from "@/modules/mixin.js";
import EmailBody from "@/modules/shared/EmailBody";
import { superAdminMixin } from "../../mixins";
import FilesModal from "./FilesModal.vue";
import ProviderSelect from "./ProviderSelect.vue";
import TaskUpdateStatusModalVue from "../../tasks/components/TaskUpdateStatusModal.vue";
import WorkOrderNotesModal from "./notes/NotesModal.vue";
import { GET_WORK_ORDER_NOTES } from "./notes/actions.js";
import NoteCard from "./notes/NoteCard";
import WorkOrderCostHistory from "./WorkOrderCostHistory.vue";
/* import WorkOrderCardTasksList from "./WorkOrderCardTasksList";
 */ export default {
  components: {
    Money,
    EmailBody,
    FilesModal,
    ProviderSelect,
    TaskUpdateStatusModalVue,
    WorkOrderNotesModal,
    NoteCard,
    WorkOrderCostHistory,
  },
  props: ["workOrder", "refreshData"],
  mixins: [generalMixin, superAdminMixin],

  computed: {
    ...mapState({
      vehicle: (state) => state.superAdminStore.vehicle,
      isCostHistoryLoading: (state) =>
        state.superAdminStore.componentStore.workOrder.workOrderCostHistory
          .status == "fetching",
    }),
  },
  data() {
    return {
      isEditing: false,
      form: {
        cost: this.workOrder.cost,
        supplier: null,
      },
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "CAD$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      showCancelNote: false,
      note: "",
      isProviderEditing: false,
      tasks: [],
      notes: [],
      showCostHistory: false,
      costHistory: [],
    };
  },
  created() {
    this.getWorkOrderNotes();
  },
  methods: {
    getVariantAndLabel(status) {
      switch (status) {
        case 0:
          return {
            variant: "#dc3545", // red
            label: "Rejected",
          };
        case 1:
          return {
            variant: "#f8c12c", //yellow
            label: "Waiting quote",
          };
        case 2:
          return {
            variant: "#fd7e14", // orange
            label: "Active",
          };
        case 3:
          return {
            variant: "#02afaa", // blue
            label: "Waiting for approval",
          };
        case 4:
          return {
            variant: "#54b962", // green
            label: "Completed",
          };
        default:
          return {
            variant: "#f8c12c", // yellow
            label: "Pending",
          };
      }
    },
    updateWorkOrderStatus(status) {
      if (status == 2) {
        status = 4;
      }
      if (status == 1) {
        status = 2;
      }
      if (status == 0) {
        this.showCancelNote = true;
      }
      this.$store
        .dispatch(PUT_WORK_ORDER, {
          id: this.workOrder.id,
          status: status,
          note: this.note,
        })
        .then((response) => {
          if (response.task.length > 0 && status == 4) {
            this.tasks = response.task;
            this.$bvModal.show("task-update-status-modal" + this.workOrder.id);
          } else {
            this.refreshData();
          }

          this.$root.$bvToast.toast("Work order status updated", {
            title: "Success",
            variant: "success",
          });
        })
        .catch(() => {
          this.$root.$bvToast.toast("could not update work order status", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    update(workOrder) {
      this.$store
        .dispatch(POST_WORK_ORDER, {
          id: workOrder.id,
          cost: this.form.cost,
          supplierCompanyId: this.form.supplier ? this.form.supplier.id : null,
        })
        .then(() => {
          this.refreshData();
          this.$root.$bvToast.toast("Work order cost updated", {
            title: "Success",
            variant: "success",
          });
        })
        .catch(() => {
          this.$root.$bvToast.toast("could not update work order cost", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    generateCarwashXLs(workOrder) {
      this.$store
        .dispatch("GET_CARWASH_EXCEL", { id: workOrder.id })
        .then((response) => {
          const blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = this.formatFileName("carwash-order", this.vehicle);
          link.click();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Could not get XLS", {
            title: "Error",
            variant: "warning",
          });
        });
    },

    sendWordOrderXLSByEmail(body) {
      this.$store
        .dispatch(GET_WORK_ORDER_EMAIL, {
          supplierCompanyId: this.workOrder.supplierCompanyId,
          vehicleId: this.workOrder.vehiclesId,
          body: body ? body : "",
        })
        .then(() => {
          this.$root.$bvToast.toast("Work order XLS sent by email", {
            title: "Success",
            variant: "success",
          });
          this.$root.$bvModal.hide("email-body-modal");
        })
        .catch(() => {
          this.$root.$bvToast.toast("Could not send XLS by email", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    openEmailBodyModal() {
      this.$root.$bvModal.show("email-body-modal" + this.workOrder.id);
    },
    openFilesModal(id) {
      this.$bvModal.show("work-order-files-modal" + id);
    },
    onSelectProvider(supplier) {
      this.form.supplier = supplier;
    },
    editWorkOrder() {
      this.$emit("update-work-order", this.workOrder);
      this.scrollToAnchor();
    },
    scrollToAnchor() {
      const element = document.getElementById("work-order-form");
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    },
    refreshAfterUpdateTask() {
      this.refreshData();
    },
    getWorkOrderNotes() {
      this.$store
        .dispatch(GET_WORK_ORDER_NOTES, {
          id: this.workOrder.id,
        })
        .then((response) => {
          this.notes = [];
          this.notes = response.workOrder;
        })
        .catch(() => {
          this.notes = [];
        });
    },
    openWorkOrderNotesModal(id) {
      this.$bvModal.show("workOrder-notes-modal" + id);
    },
    getCostHistory() {
      this.$store
        .dispatch(GET_WORK_ORDER_COST_HISTORY, {
          id: this.workOrder.id,
        })
        .then((response) => {
          this.costHistory = response;
          this.showCostHistory = true;
        });
    },
  },
};
</script>

<style></style>
