var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',{staticClass:"justify-content-md-center"},[_c('b-col',{staticClass:"py-2",attrs:{"sm":"4","md":"2","lg":"2","xl":"2"}},[_c('b-button',{staticClass:"text-white",attrs:{"variant":"yellow","size":"lg","block":""},on:{"click":function($event){_vm.option = 'payments';
          _vm.openModal('checks-modal', 'payments');}}},[_vm._v(" Payments "),_c('font-awesome-icon',{staticClass:"icon-color cursor-pointer",style:({ color: 'white' }),attrs:{"icon":['far', 'money-check-dollar']}})],1)],1),_c('b-col',{staticClass:"py-2",attrs:{"sm":"4","md":"2","lg":"2","xl":"2"}},[_c('b-button',{attrs:{"variant":"dark-green","size":"lg","block":""},on:{"click":function($event){_vm.option = 'carfax';

          _vm.openModal('carfax-modal', 'carfax');}}},[_vm._v(" Carfax "),_c('img',{staticClass:"img-responsive",staticStyle:{"max-width":"20px"},attrs:{"src":require("@/assets/svg/CF_grey.svg")}})])],1),_c('b-col',{staticClass:"py-2",attrs:{"sm":"4","md":"2","lg":"2","xl":"2"}},[_c('b-button',{staticClass:"text-white",attrs:{"variant":"yellow","size":"lg","block":""},on:{"click":function($event){_vm.option = 'registration';
          _vm.openModal('registration-modal', 'registration');}}},[_vm._v(" Regi "),_c('font-awesome-icon',{staticClass:"icon-color cursor-pointer",style:({ color: 'white' }),attrs:{"icon":['far', 'file-magnifying-glass']}})],1)],1),_c('b-col',{staticClass:"py-2",attrs:{"sm":"4","md":"2","lg":"2","xl":"2"}},[_c('b-button',{staticClass:"text-white",attrs:{"variant":"dark-green","size":"lg","block":""},on:{"click":function($event){_vm.option = 'recalls';
          _vm.openModal('recall-modal', 'recall');}}},[_vm._v(" Recalls "),_c('font-awesome-icon',{staticClass:"icon-color cursor-pointer",style:({ color: 'white' }),attrs:{"icon":['far', 'car-circle-bolt']}})],1)],1),_c('b-col',{staticClass:"py-2",attrs:{"sm":"4","md":"2","lg":"2","xl":"2"}},[_c('b-button',{staticClass:"text-white",attrs:{"variant":"yellow","size":"lg","block":""},on:{"click":function($event){_vm.option = 'liens';
          _vm.openModal('lien-modal', 'liens');}}},[_vm._v(" Liens "),_c('font-awesome-icon',{staticClass:"icon-color cursor-pointer",style:({ color: 'white' }),attrs:{"icon":['far', 'file-slash']}})],1)],1)],1),_c('div',{staticClass:"container mt-5 w-100"},[(_vm.option === 'trims')?_c('CarsTable'):_vm._e()],1),_c('CarfaxModal'),_c('ChecksModal'),_c('RegistrationModal'),_c('RecallModal'),_c('LienModal')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }