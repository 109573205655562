<template>
  <div class="row">
    <b-col cols="11" class="mr-0">
      <vue-single-select
        :classes="{
          input: 'form-control',
          dropdown: 'dropdown p-0',
        }"
        v-model="selected"
        :options="agents"
        optionLabel="nickname"
      >
        <template slot="option" slot-scope="{ option }">
          <div class="">
            <!--             <span> {{ option.firstName }} {{ option.lastName }}</span>
 -->
            <span> {{ option.nickname }}</span>
          </div>
        </template>
      </vue-single-select>
    </b-col>
    <b-col cols="1" class="pl-0">
      <b-icon
        icon="person-plus-fill"
        scale="1.3"
        class="m-2 align-middle"
        @click="showModal()"
      />
    </b-col>

    <b-modal
      id="detailsModal"
      ref="detailsModal"
      size="lg"
      hide-footer
      body-class="pl-0 pr-0"
      ok-disabled
      @hidden="userItem = {}"
    >
      <UserForm :user-item="userItem" :refreshData="getAgents" :hide-modal="hideModal" />
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import UserForm from "@/modules/admin/agent/Form";
import VueSingleSelect from "vue-single-select";

export default {
  components: { UserForm, VueSingleSelect },
  props: {
    agent: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapState({
      agents: (state) => state.vehicle.agents.data,
    }),
    agentName() {
      return this.selected.firstName + " " + this.selected.lastName;
    },
  },
  watch: {
    selected() {
      this.$emit("input", this.selected);
    },
  },
  data() {
    return {
      selected: null,
      model: null,
      userItem: {},
    };
  },

  created() {
    this.addCompleteName();
    this.filterSelected();
    this.onClickButton();
  },
  methods: {
    onClickButton() {
      this.$emit("input", this.selected);
    },
    getAgents() {
      this.$store
        .dispatch("GET_AGENTS")
        .then(() => {})
        .catch(() => {});
    },
    filterSelected() {
      if (this.agent == null) {
        return;
      }
      const result = this.agents.filter((agent) => agent.id == this.agent.id);
      this.selected = result[0];
    },
    addCompleteName() {
      this.agents.forEach((agent) => {
        agent.completeName = agent.firstName + " " + agent.lastName;
      });
    },
    showModal() {
      this.$bvModal.show("detailsModal");
    },
    hideModal() {
      this.$bvModal.hide("detailsModal");
    },
  },
};
</script>

<style></style>
