export const GET_COMPANIES_LIST = "GET_COMPANIES_LIST";
export const GET_COMPANIES_LIST_SUCCESS = "GET_COMPANIES_LIST_SUCCESS";
export const GET_COMPANIES_LIST_ERROR = "GET_COMPANIES_LIST_ERROR";

export const GET_USER_TO_ASSIGN_COMPANY = "GET_USER_TO_ASSIGN_COMPANY";
export const GET_USER_TO_ASSIGN_COMPANY_SUCCESS =
  "GET_USER_TO_ASSIGN_COMPANY_SUCCESS";
export const GET_USER_TO_ASSIGN_COMPANY_ERROR =
  "GET_USER_TO_ASSIGN_COMPANY_ERROR";

export const PUT_ASSIGN_COMPANY = "PUT_ASSIGN_COMPANY";
export const PUT_ASSIGN_COMPANY_SUCCESS = "PUT_ASSIGN_COMPANY_SUCCESS";
export const PUT_ASSIGN_COMPANY_ERROR = "PUT_ASSIGN_COMPANY_ERROR";
