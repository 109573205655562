import * as constants from "./actions";
import axios from "@/plugins/axios.js";
function getDefaultState() {
  return {
    jacketInfo: {
      status: null,
      data: {},
      error: null,
    },
    jacketInternalStatus: {
      status: null,
      data: {},
      error: null,
    },
  };
}

const actions = {
  [constants.GET_JACKETS_INFO]: async ({ commit }, params) => {
    try {
      commit(constants.GET_JACKETS_INFO);
      const response = await axios.get("vehiclesSales/findJacket", {
        params: { ...params },
      });
      commit(constants.GET_JACKETS_INFO_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_JACKETS_INFO_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_JACKET_INTERNAL_STATUS]: async ({ commit }, params) => {
    try {
      commit(constants.PUT_JACKET_INTERNAL_STATUS);
      const response = await axios.put("registration/changeInternalStatus", {
        ...params,
      });
      commit(constants.PUT_JACKET_INTERNAL_STATUS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_JACKET_INTERNAL_STATUS_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_JACKETS_INFO]: (state) => {
    state.jacketInfo.status = "fetching";
    state.jacketInfo.error = null;
  },
  [constants.GET_JACKETS_INFO_SUCCESS]: (state, response) => {
    state.jacketInfo.status = "success";
    state.jacketInfo.data = response;
  },
  [constants.GET_JACKETS_INFO_ERROR]: (state, error) => {
    state.jacketInfo.status = "error";
    state.jacketInfo.error = error;
  },
  [constants.PUT_JACKET_INTERNAL_STATUS]: (state) => {
    state.jacketInternalStatus.status = "fetching";
    state.jacketInternalStatus.error = null;
  },
  [constants.PUT_JACKET_INTERNAL_STATUS_SUCCESS]: (state, response) => {
    state.jacketInternalStatus.status = "success";
    state.jacketInternalStatus.data = response;
  },
  [constants.PUT_JACKET_INTERNAL_STATUS_ERROR]: (state, error) => {
    state.jacketInternalStatus.status = "error";
    state.jacketInternalStatus.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
