<template>
  <div v-if="damageImageModal">
    <h5>Damage photo</h5>
    <img :src="damageImageModal || 'img/image_not_found.png'" alt="damage" class="img-damage img-thumbnail">
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      damageImageModal: (state) =>
        state.vehicle.visualInspection.damageImageModal,
    })
  },
}
</script>

<style scoped>
.img-damage {
  max-height: 100%;
  width: auto;
  display: inline-block;
  float: none;
}
</style>