<template>
  <div>
    <h2>Vehicle information</h2>
    <b-row>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> Purchase date </template>
          <b-form-input v-model="form.buyingDate" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> Stock</template>
          <b-form-input v-model="form.stockNumber" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> VIN</template>
          <b-form-input v-model="form.vinNumber" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> Year</template>
          <b-form-input v-model="form.year" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> Make</template>
          <b-form-input v-model="form.make" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> Model</template>
          <b-form-input v-model="form.model" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> Body style</template>
          <b-form-input v-model="form.vehicleType" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> Color</template>
          <b-form-input v-model="form.color" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> Miles</template>
          <b-form-input v-model="form.miles" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> Cost</template>
          <b-form-input v-model="form.cost" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> Tittle number</template>
          <b-form-input v-model="form.titleMsoIn" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> Status</template>
          <b-form-input v-model="form.titleMsoStatusIn" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> Origin</template>
          <b-form-input v-model="form.titleOriginIn" size="sm" readonly />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> NMVTIS Status</template>
          <b-form-input v-model="form.nmvtisStatusIn" size="sm" readonly />
        </b-form-group>
      </b-col>
      <!--     <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
        <b-form-group label-size="sm">
          <template slot="label"> Miles</template>
          <b-form-input v-model="form.milesIn" size="sm" readonly />
        </b-form-group>
      </b-col> -->
    </b-row>
  </div>
</template>

<script>
export default {
  props: ["vehicleInformation"],
  data() {
    return {
      form: { ...this.vehicleInformation },
    };
  },
};
</script>

<style></style>
