<template>
  <b-modal
    id="exception-modal"
    ref="modal"
    modal-class="modal-fullscreen"
    centered
    no-close-on-backdrop
    scrollable
    title="Exceptions"
    size="lg"
    hide-footer
    @show="getException(vehicle)"
    @hidden="resetForm()"
  >
    <VehicleDetails
      v-if="vehicle"
      :vehicle="vehicle"
      :color="
        vehicle.vehicleInformation ? vehicle.vehicleInformation.color : '----'
      "
    />
    <ExceptionRegistrationForm
      v-if="userHasPermission('exception', 'write')"
      :vehicle="vehicle"
    />
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import { superAdminMixin } from "../../mixins";
import ExceptionRegistrationForm from "./ExceptionRegistrationForm";
import VehicleDetails from "@/modules/superAdmin/components/VehicleDetails.vue";
export default {
  name: "ExceptionModal",
  components: { ExceptionRegistrationForm, VehicleDetails },
  mixins: [superAdminMixin],
  computed: {
    ...mapState({
      vehicle: (state) => state.superAdminStore.vehicle,
      notes: (state) => state.superAdminStore.componentStore.notes,
    }),
  },
  methods: {
    resetForm() {
      console.log("Reset form");
    },

    getException() {},
  },
};
</script>

<style scoped>
.resize-none {
  resize: none;
}
.note-list {
  max-height: 500px;
  overflow-y: auto;
}
</style>
