import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    addonFileList: {
      status: null,
      data: [],
      error: null,
    },

    addonFile: {
      status: null,
      data: {},
      error: null,
    },
    addonsFileList: {
      status: null,
      data: [],
      error: null,
    },
    stockNumbers: {
      status: null,
      data: [],
      error: null,
    },
    addonFail: {
      status: null,
      data: [],
      error: null,
    },
  };
}
const actions = {
  [constants.GET_ADDONS_FILE_PROCESSED]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_ADDONS_FILE_PROCESSED);
      const response = await axios.get("addon/getSuccessfulAddon", {
        params: payload,
      });
      commit(constants.GET_ADDONS_FILE_PROCESSED_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_ADDONS_FILE_PROCESSED_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_ADDONS_FILE_FAILED]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_ADDONS_FILE_FAILED);
      const response = await axios.get("addon/getFailAddon", {
        params: payload,
      });
      commit(constants.GET_ADDONS_FILE_FAILED_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_ADDONS_FILE_FAILED_ERROR, error);
      throw error.response;
    }
  },
  /* Read error */
  [constants.GET_ADDONS_FILE_READ_ERROR]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_ADDONS_FILE_READ_ERROR);
      const response = await axios.get("addon/getReadErrorAddon", {
        params: payload,
      });
      commit(constants.GET_ADDONS_FILE_READ_ERROR_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_ADDONS_FILE_READ_ERROR_ERROR, error);
      throw error.response;
    }
  },


  [constants.POST_ADDON_FILE]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_ADDON_FILE);
      const response = await axios.post("addon/readTxt", payload);
      commit(constants.POST_ADDON_FILE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_ADDON_FILE_ERROR, error);
      throw error.response;
    }
  },

  [constants.GET_ADDONS_FILE_LIST]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_ADDONS_FILE_LIST);
      const response = await axios.get("addon/getAddonTxtFiles", {
        params: payload,
      });
      commit(constants.GET_ADDONS_FILE_LIST_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_ADDONS_FILE_LIST_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_STOCK_NUMBERS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_STOCK_NUMBERS);
      const response = await axios.get("addon/getStocks", {
        params: payload,
      });
      commit(constants.GET_STOCK_NUMBERS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_STOCK_NUMBERS_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_ADDON_FAIL]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_ADDON_FAIL);
      const response = await axios.put("addon/editFailAddons", payload);
      commit(constants.PUT_ADDON_FAIL_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_ADDON_FAIL_ERROR, error);
      throw error.response;
    }
  },
  [constants.DELETE_ADDON_FAIL]: async ({ commit }, payload) => {
    try {
      commit(constants.DELETE_ADDON_FAIL);
      const response = await axios.delete("addon/deleteFailAddon", {
        params: payload,
      });
      commit(constants.DELETE_ADDON_FAIL_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.DELETE_ADDON_FAIL_ERROR, error);
      throw error.response;
    }
  },
  [constants.DELETE_ADDON_FILE]: async ({ commit }, payload) => {
    try {
      commit(constants.DELETE_ADDON_FILE);
      const response = await axios.delete("addon/revertFile", {
        params: payload,
      });
      commit(constants.DELETE_ADDON_FILE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.DELETE_ADDON_FILE_ERROR, error);
      throw error.response;
    }
  }

};

const mutations = {
  [constants.GET_ADDONS_FILE_PROCESSED]: (state) => {
    state.addonFileList.data = [];
    state.addonFileList.status = "fetching";
  },
  [constants.GET_ADDONS_FILE_PROCESSED_SUCCESS]: (state, response) => {
    state.addonFileList.status = "success";
    state.addonFileList.data = response;
  },
  [constants.GET_ADDONS_FILE_PROCESSED_ERROR]: (state, error) => {
    state.addonFileList.status = "error";
    state.addonFileList.error = error;
  },
  [constants.GET_ADDONS_FILE_FAILED]: (state) => {
    state.addonFileList.data = [];
    state.addonFileList.status = "fetching";
  },
  [constants.GET_ADDONS_FILE_FAILED_SUCCESS]: (state, response) => {
    state.addonFileList.status = "success";
    state.addonFileList.data = response;
  },
  [constants.GET_ADDONS_FILE_FAILED_ERROR]: (state, error) => {
    state.addonFileList.status = "error";
    state.addonFileList.error = error;
  },
  [constants.POST_ADDON_FILE]: (state) => {
    state.addonFile.data = {};
    state.addonFile.status = "fetching";
  },
  [constants.POST_ADDON_FILE_SUCCESS]: (state, response) => {
    state.addonFile.status = "success";
    state.addonFile.data = response;
  },
  [constants.POST_ADDON_FILE_ERROR]: (state, error) => {
    state.addonFile.status = "error";
    state.addonFile.error = error;
  },

  [constants.GET_ADDONS_FILE_LIST]: (state) => {
    state.addonsFileList.data = [];
    state.addonsFileList.status = "fetching";
  },
  [constants.GET_ADDONS_FILE_LIST_SUCCESS]: (state, response) => {
    state.addonsFileList.status = "success";
    state.addonsFileList.data = response;
  },
  [constants.GET_ADDONS_FILE_LIST_ERROR]: (state, error) => {
    state.addonsFileList.status = "error";
    state.addonsFileList.error = error;
  },
  /* read error */
  [constants.GET_ADDONS_FILE_READ_ERROR]: (state) => {
    state.addonFileList.data = [];
    state.addonFileList.status = "fetching";
  },
  [constants.GET_ADDONS_FILE_READ_ERROR_SUCCESS]: (state, response) => {
    state.addonFileList.status = "success";
    state.addonFileList.data = response;
  },
  [constants.GET_ADDONS_FILE_READ_ERROR_ERROR]: (state, error) => {
    state.addonFileList.status = "error";
    state.addonFileList.error = error;
  },

  [constants.GET_STOCK_NUMBERS]: (state) => {
    state.stockNumbers.data = [];
    state.stockNumbers.status = "fetching";
  },
  [constants.GET_STOCK_NUMBERS_SUCCESS]: (state, response) => {
    state.stockNumbers.status = "success";
    state.stockNumbers.data = response;
  },
  [constants.GET_STOCK_NUMBERS_ERROR]: (state, error) => {
    state.stockNumbers.status = "error";
    state.stockNumbers.error = error;
  },
  [constants.PUT_ADDON_FAIL]: (state) => {
    state.addonFail.data = [];
    state.addonFail.status = "fetching";
  },
  [constants.PUT_ADDON_FAIL_SUCCESS]: (state, response) => {
    state.addonFail.status = "success";
    state.addonFail.data = response;
  },
  [constants.PUT_ADDON_FAIL_ERROR]: (state, error) => {
    state.addonFail.status = "error";
    state.addonFail.error = error;
  },
  [constants.DELETE_ADDON_FAIL]: (state) => {
    state.addonFile.data = [];
    state.addonFile.status = "fetching";
  },
  [constants.DELETE_ADDON_FAIL_SUCCESS]: (state, response) => {
    state.addonFile.status = "success";
    state.addonFile.data = response;
  },
  [constants.DELETE_ADDON_FAIL_ERROR]: (state, error) => {
    state.addonFile.status = "error";
    state.addonFile.error = error;
  },
  [constants.DELETE_ADDON_FILE]: (state) => {
    state.addonFile.data = [];
    state.addonFile.status = "fetching";
  },
  [constants.DELETE_ADDON_FILE_SUCCESS]: (state, response) => {
    state.addonFile.status = "success";
    state.addonFile.data = response;
  },
  [constants.DELETE_ADDON_FILE_ERROR]: (state, error) => {
    state.addonFile.status = "error";
    state.addonFile.error = error;
  }

};
export default {
  state: getDefaultState(),
  actions,
  mutations,
};
