import Login from "@/modules/auth/login/LoginForm";
const routes = [
  {
    path: "/",
    name: "home",
    component: Login,
    meta: {
      layout: "base",
    },
  },
];
export default routes;
