<template>
  <div>
    <b-row>
      <b-col cols="10">
        <span>
          Registration date:
          <i>
            {{ price.createdAt | formatDateTime }} - {{ price.user.firstName }}
            {{ price.user.lastName }}</i
          >
        </span>
        <br />
        <span v-if="price.status != 1 && price.updateUser != null">
          Last update date:
          <i
            >{{ price.updatedAt | formatDateTime }} -
            {{ price.updateUser.firstName }}
            {{ price.updateUser.lastName }}</i
          >
        </span>
        <br />
        <span v-if="price.deliveryDate">
          Estimated delivery date:
          <i>
            {{ price.deliveryDate | formatDate }}
          </i>
        </span>
      </b-col>
      <b-col>
        <div class="text-right">
          <span
            v-if="price.action === 'publish'"
            class="dot dot-success mr-3"
          />

          <span v-if="price.action != 'publish'" class="dot dot-danger mr-3" />
        </div>
      </b-col>
    </b-row>
    <p class="text-justify mt-3">
      <span>
        Marketplace: <i>{{ price.marketPlace }} </i>
      </span>
      <br />
      <span>
        Action: <i>{{ price.action }} </i>
      </span>
    </p>
  </div>
</template>

<script>
export default {
  props: ["price"],
};
</script>

<style></style>
