<template>
  <div class="mt-3">
    <div class="container-fluid mt-3">
      <b-table
        class="mt-3"
        show-empty
        bordered
        small
        stacked="md"
        head-variant="light"
        :items="reserveList.data || []"
        :fields="fields"
        :busy="reserveListStatus == 'fetching'"
      >
        <template #cell(reserveDate)="{ item }">
          {{ item.reserves.createdAt | formatDate }}
        </template>
        <template #cell(vehicle)="{ item }">
          {{ item.stockNumber }} - {{ item.vinNumber }} - {{ item.year }}-
          {{ item.make }}
          {{ item.model }}
        </template>
        <template #cell(reservedBy)="{ item }">
          {{
            item.reserves.registerUser
              ? item.reserves.registerUser.firstName
              : ""
          }}
          {{
            item.reserves.registerUser
              ? item.reserves.registerUser.lastName
              : ""
          }}
        </template>
        <template #cell(reserveTo)="{ item }">
          {{ item.reserves.company.companyName }}
        </template>
        <template #cell(currency)="{ item }">
          {{ item.reserves.typeCurrency }}
        </template>
        <template #cell(amount)="{ item }">
          {{ item.reserves.price | currency }}
        </template>

        <template #cell(actions)="{ item }">
          <b-icon icon="pencil" @click="editItem(item)" />
        </template>
      </b-table>
      <b-pagination
        v-if="
          reserveListStatus != 'fetching' && reserveList && reserveList.data
        "
        :value="reserveList ? reserveList.currentPage : 1"
        :total-rows="reserveList ? reserveList.total : 0"
        :per-page="reserveList ? reserveList.perPage : 25"
        class="mb-2"
        align="end"
        @change="changePage($event)"
      />
    </div>

    <b-modal
      id="reserve-form-modal"
      ref="reserve-form-modal"
      size="lg"
      hide-footer
      body-class=""
      ok-disabled
      @hidden="
        reserveItem = {};
        resetForm();
      "
      title="Reserve"
    >
      <ReserveForm
        ref="reserve-form"
        :reserveItem="reserveItem"
        :refreshData="getData"
        :hideModal="hideReserveFormModal"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { GET_RESERVE_LIST } from "./actions";
import ReserveForm from "./ReserveForm.vue";
export default {
  name: "TransportationTable",
  components: {
    ReserveForm,
  },
  computed: {
    ...mapState({
      reserveList: (state) => state.superAdminStore.reserve.reserveList.data,
      reserveListStatus: (state) =>
        state.superAdminStore.reserve.reserveList.status,
    }),
  },
  data() {
    return {
      fields: [
        {
          key: "reserveDate",
          label: "Reserve Date",
          formatter: (date) => this.$options.filters.formatDateTime(date),
        },
        {
          key: "vehicle",
          label: "Vehicle",
        },

        {
          key: "reservedBy",
          label: "Reserved by",
        },
        {
          key: "reserveTo",
          label: "Reserve to",
        },
        {
          key: "currency",
          label: "Currency",
          class: "text-center",
        },
        {
          key: "amount",
          label: "Amount",
          class: "text-right",
        },
        {
          key: "actions",
          label: "Actions",
          class: "text-center",
        },
      ],
      reserveItem: {},
    };
  },
  mounted() {
    this.$store.commit("setAddFunction", this.openReserveFormModal);
    this.$store.commit("setSearchFunction", this.getData);
    this.getData();
  },
  methods: {
    getData(search = "", page = 1) {
      const params = {
        search,
        page,
      };
      this.$store
        .dispatch(GET_RESERVE_LIST, params)
        .then((response) => {
          console.log(response);
        })
        .catch(() => {});
    },
    openReserveFormModal() {
      this.$refs["reserve-form-modal"].show();
    },
    hideReserveFormModal() {
      this.$refs["reserve-form-modal"].hide();
    },
    changePage(page) {
      this.getData(this.search, page);
    },
    editItem(item) {
      this.reserveItem = item;
      this.openReserveFormModal();
    },
    resetForm() {
      this.reserveItem = {};
      this.$refs["reserve-form"].resetForm();
    },
  },
};
</script>

<style></style>
