<template>
  <div class="container">
    <b-form class="m-4" @submit.stop.prevent="update()">
      <h3 class="text-purple-yellow"></h3>
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
          <!-- value-->
          <b-form-group label-size="md">
            <template slot="label"> Fx <span class="text-danger">*</span> </template>
            <Money
              id="fx-input-value"
              v-model.trim="form.value"
              class="form-control text-center"
              v-bind="money"
            />
            <div
              class="error text-danger"
              v-if="!$v.form.value.greaterThan & $v.form.value.$dirty"
            >
              This field must be greater than zero
            </div>
          </b-form-group>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
          <!-- buy-->
          <b-form-group label-size="md">
            <template slot="label"> Buy <span class="text-danger">*</span> </template>
            <Money
              v-model.trim="form.buy"
              class="form-control text-center"
              v-bind="money"
            />
          </b-form-group>
          <div
            class="error text-danger"
            v-if="!$v.form.buy.greaterThan & $v.form.buy.$dirty"
          >
            This field must be greater than zero
          </div>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
          <!-- sale-->
          <b-form-group label-size="md">
            <template slot="label"> Sale <span class="text-danger">*</span> </template>
            <Money
              v-model.trim="form.sale"
              class="form-control text-center"
              v-bind="money"
            />
          </b-form-group>
          <div
            class="error text-danger"
            v-if="!$v.form.sale.greaterThan & $v.form.sale.$dirty"
          >
            This field must be greater than zero
          </div>
        </b-col>
      </b-row>
      <b-row />
      <b-row />

      <b-row>
        <b-col sm="12" md="6" lg="6" xl="6" />
        <b-col sm="12" md="3" lg="3" xl="3" />
        <b-col sm="12" md="3" lg="3" xl="3">
          <b-btn
            block
            size="lg"
            class="btn btn-success"
            :disabled="$v.form.$invalid"
            type="submit"
          >
            Submit
            <b-spinner v-if="isLoading" small variant="white" />
          </b-btn>
        </b-col>
      </b-row>
    </b-form>
    <FxHistoryTable />
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { GET_CURRENCY_CONVERSION, PUT_CURRENCY_CONVERSION } from "./actions";
import { Money } from "v-money";
import { mapState } from "vuex";
import FxHistoryTable from "./FxHistoryTable";
const greaterThan = (value) => value > 0;
export default {
  components: {
    Money,
    FxHistoryTable,
  },
  data() {
    return {
      form: {
        value: 0,
        buy: 0,
        sale: 0,
      },
      money: {
        value: 0,
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        suffix: "",
        precision: 4,
        masked: false /* doesn't work with directive */,
      },
    };
  },
  computed: {
    ...mapState({
      fx: (state) => state.superAdminStore.fx.fx.data.current || {},
      isLoading: (state) =>
        state.superAdminStore.fx.fx.status === "fetching" ? true : false,
    }),
  },
  validations: {
    form: {
      value: {
        required,
        greaterThan,
      },
      buy: {
        required,
        greaterThan,
      },
      sale: {
        required,
        greaterThan,
      },
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$store
        .dispatch(GET_CURRENCY_CONVERSION)
        .then(() => {
          this.formatForm();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatForm() {
      this.form.value = this.fx.value;
      this.form.id = this.fx.id;
      this.form.buy = this.fx.buy;
      this.form.sale = this.fx.sale;
    },
    update() {
      this.$store
        .dispatch(PUT_CURRENCY_CONVERSION, this.form)
        .then(() => {
          this.toastMessage("Fx for today updated successfully", "Success", "success");
        })
        .catch(() => {
          this.toastMessage("Fx for today could not be updated", "Error", "error");
        });
    },
  },
};
</script>

<style></style>
