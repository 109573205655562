export const GET_MARKETPLACE = "GET_MARKETPLACE";
export const GET_MARKETPLACE_SUCCESS = "GET_MARKETPLACE_SUCCESS";
export const GET_MARKETPLACE_ERROR = "GET_MARKETPLACE_ERROR";

export const GET_MARKETPLACE_LIST = "GET_MARKETPLACE_LIST";
export const GET_MARKETPLACE_LIST_SUCCESS = "GET_MARKETPLACE_LIST_SUCCESS";
export const GET_MARKETPLACE_LIST_ERROR = "GET_MARKETPLACE_LIST_ERROR";

export const POST_MARKETPLACE = "POST_MARKETPLACE";
export const POST_MARKETPLACE_SUCCESS = "POST_MARKETPLACE_SUCCESS";
export const POST_MARKETPLACE_ERROR = "POST_MARKETPLACE_ERROR";

export const PUT_ASSIGN_MARKETPLACE = "PUT_ASSIGN_MARKETPLACE";
export const PUT_ASSIGN_MARKETPLACE_SUCCESS = "PUT_ASSIGN_MARKETPLACE_SUCCESS";
export const PUT_ASSIGN_MARKETPLACE_ERROR = "PUT_ASSIGN_MARKETPLACE_ERROR";

export const GET_MARKETPLACE_USERS = "GET_MARKETPLACE_USERS";
export const GET_MARKETPLACE_USERS_SUCCESS = "GET_MARKETPLACE_USERS_SUCCESS";
export const GET_MARKETPLACE_USERS_ERROR = "GET_MARKETPLACE_USERS_ERROR";
