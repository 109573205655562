<template>
  <div class="mb-5">
    <div v-if="status == 'fetching'" class="mt-5">
      <div class="container mt-auto text-center mt-5">
        <b-spinner
          style="width: 10rem; height: 10rem"
          variant="yellow"
          class="align-middle mt-5"
        />
      </div>
    </div>
    <div v-else>
      <div v-if="vehicle.stockNumber" class="my-auto mt-5 container">
        <b-form @submit.stop.prevent="registerVehicle()">
          <b-row class="justify-content-center mt-2">
            <b-col class="col-lg-8 my-2" xs="12" sm="12" md="8" lg="8" xl="8">
              <div>
                <b-row>
                  <!-- Year -->
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <div class="mb-2">
                      <h2 v-if="vehicle.vinNumber.year">
                        Year:
                        <i>{{ vehicle.vinNumber.year }}</i>
                      </h2>

                      <b-form-group
                        v-else
                        label-cols-lg="4"
                        label-cols-sm="12"
                        label-size="sm"
                      >
                        <template slot="label">
                          <div>
                            <h2>Year:</h2>
                          </div>
                        </template>

                        <vue-single-select
                          :classes="{
                            input: 'form-control',
                            dropdown: 'dropdown',
                          }"
                          :options="years"
                          v-model="year"
                        >
                          <template slot="option" slot-scope="{ option }">
                            <div>
                              <span>
                                {{ option }}
                              </span>
                            </div>
                          </template>
                        </vue-single-select>
                      </b-form-group>
                    </div>
                  </b-col>
                  <!-- Make -->
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <div class="mb-2">
                      <h2 v-if="vehicle.vinNumber.make">
                        Make:
                        <i>{{ vehicle.vinNumber.make }}</i>
                      </h2>

                      <b-form-group
                        v-else
                        label-cols-lg="4"
                        label-cols-sm="12"
                        label-size="sm"
                      >
                        <template slot="label">
                          <div>
                            <h2>Make:</h2>
                          </div>
                        </template>

                        <vue-single-select
                          :classes="{
                            input: 'form-control',
                            dropdown: 'dropdown',
                          }"
                          :options="makesModelsAndColors.make"
                          v-model="make"
                        >
                          <template slot="option" slot-scope="{ option }">
                            <div>
                              <span
                                @click="
                                  getMakeAnddModels(option);
                                  model = null;
                                "
                              >
                                {{ option }}
                              </span>
                            </div>
                          </template>
                        </vue-single-select>
                      </b-form-group>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <div class="mb-2">
                      <h2 v-if="vehicle.vinNumber.model">
                        Model:
                        <i> {{ vehicle.vinNumber.model }}</i>
                      </h2>

                      <b-form-group
                        v-else
                        label-cols-lg="4"
                        label-cols-sm="12"
                        label-size="sm"
                      >
                        <template slot="label">
                          <div>
                            <h2>Model:</h2>
                          </div>
                        </template>
                        <vue-single-select
                          :classes="{
                            input: 'form-control',
                            dropdown: 'dropdown',
                          }"
                          :options="makesModelsAndColors.model"
                          v-model="model"
                        >
                          <template slot="option" slot-scope="{ option }">
                            <div>
                              <span>
                                {{ option }}
                              </span>
                            </div>
                          </template>
                        </vue-single-select>
                      </b-form-group>
                    </div>
                  </b-col>
                  <!-- color -->
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <div class="mb-2">
                      <b-form-group
                        label-cols-lg="4"
                        label-cols-sm="12"
                        label-size="sm"
                        class=""
                      >
                        <template slot="label">
                          <div>
                            <h2>Color:</h2>
                          </div>
                        </template>
                        <vue-single-select
                          v-if="!vehicle.vehicleInformation.color || color == ''"
                          :classes="{
                            input: 'form-control',
                            dropdown: 'dropdown',
                          }"
                          :options="makesModelsAndColors.color"
                          v-model="color"
                        >
                          <template slot="option" slot-scope="{ option }">
                            <div>
                              <span>
                                {{ option }}
                              </span>
                            </div>
                          </template>
                        </vue-single-select>

                        <b-form-input v-else v-model="color" size="md" class="" />
                      </b-form-group>
                    </div>
                  </b-col>
                </b-row>

                <b-row class="justify-content-center">
                  <b-col>
                    <b-col class="text-center">
                      <vue-barcode
                        v-if="vehicle.vinNumber.vinNumber"
                        :value="vehicle.vinNumber.vinNumber"
                        :options="barcodeOptions"
                        class="bar-code"
                        :height="50"
                        :width="1.3"
                        :displayValue="false"
                      />
                      <br />
                      <i style="font-size: 13px">
                        {{ vehicle.vinNumber.vinNumber || vehicle.vinNumber }}
                        <b-button
                          v-clipboard:copy="
                            vehicle.vinNumber.vinNumber
                              ? vehicle.vinNumber.vinNumber
                              : vehicle.vinNumber
                          "
                          v-clipboard:success="copyVinNumber"
                          variant="none"
                        >
                          <b-icon v-if="vinCopied" icon="clipboard-check" />
                          <b-icon v-else icon="clipboard" />
                        </b-button>
                      </i>
                    </b-col>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <div class="text-center">
              <b-icon
                v-if="isRegister"
                icon="check-circle"
                font-scale="10"
                variant="success"
              >
                Successfully registered vehicle
              </b-icon>
            </div>
          </b-row>
          <b-row class="justify-content-center mt-2">
            <b-col class="col-lg-8 my-2" xs="12" sm="12" md="10" lg="10" xl="10">
              <b-row>
                <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                  <b-form-group
                    label-cols-lg="3"
                    label-cols-sm="12"
                    label-size="sm"
                    label="Vin number"
                  >
                    <b-form-input v-model="form.vinNumber" size="sm" />
                  </b-form-group>
                </b-col>

                <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                  <b-row>
                    <b-col cols="11" class="mr-0">
                      <b-form-group
                        label-cols-lg="3"
                        label-cols-sm="12"
                        label-size="sm"
                        label="Stock number"
                      >
                        <b-form-input v-model="form.stockNumber" size="sm" />
                      </b-form-group>
                    </b-col>
                    <b-col cols="1" class="p-0">
                      <b-button
                        class="px-0 pb-0"
                        v-clipboard:copy="form.stockNumber"
                        v-clipboard:success="copyStockNumber"
                        variant="none"
                      >
                        <b-icon v-if="stockCopied" icon="clipboard-check" />
                        <b-icon v-else icon="clipboard" />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>

                <!-- Buying date -->
                <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                  <b-form-group
                    label-cols-lg="3"
                    label-cols-sm="12"
                    label-size="sm"
                    label="Buying date"
                  >
                    <b-form-datepicker
                      id="example-datepicker"
                      v-model="form.buyingDate"
                      class="mb-2"
                    />
                  </b-form-group>
                </b-col>
                <!-- kilometer -->
                <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                  <b-form-group
                    label-cols-lg="3"
                    label-cols-sm="12"
                    label-size="sm"
                    label="Kilometers "
                  >
                    <b-form-input
                      v-model="form.kilometers"
                      type="number"
                      min="0"
                      size="sm"
                    />
                    <template v-if="mileageHistory" slot="description">
                      {{ mileageHistory.nickname }} -
                      {{ mileageHistory.createdAt | formatDate }} -
                      {{ mileageHistory.action }}
                    </template>
                  </b-form-group>
                </b-col>
                <!-- Contract -->
                <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                  <b-row>
                    <b-col cols="11" class="mr-0">
                      <b-form-group label="Contract" label-cols-lg="3">
                        <b-input-group>
                          <b-form-file
                            id="file-contract"
                            v-model="form.contract"
                            accept=".pdf"
                          />
                          <b-input-group-append
                            v-if="vehicle.contract && form.contract == null"
                          >
                            <b-button variant="none" class="pr-0">
                              <a :href="vehicle.contract" target="_blank">
                                <b-icon icon="eye" variant="dark-green" />
                              </a>
                              <b-icon
                                v-if="userHasPermission('stock', 'write')"
                                icon="trash"
                                class="ml-1 cursor-pointer"
                                @click="deleteFile('contract')"
                              ></b-icon>
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>

                    <b-col cols="1" class="pl-0">
                      <b-form-checkbox
                        id="checkbox-1"
                        v-model="form.contractCheck"
                        name="checkbox-1"
                        class="mt-1"
                        :value="1"
                        :unchecked-value="0"
                      >
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-col>
                <!-- Contract received date -->
                <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                  <b-form-group
                    label-cols-lg="3"
                    label-cols-sm="12"
                    label-size="sm"
                    label="Contract received date"
                  >
                    <b-form-datepicker
                      id="contract-datepicker"
                      v-model="form.contractReceivedDate"
                      class="mb-2"
                    />
                  </b-form-group>
                </b-col>

                <!-- Referral price -->
                <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                  <b-row>
                    <b-col cols="11">
                      <b-form-group label-cols-lg="3" label-size="sm" label="FRP. CAD">
                        <template slot="label">
                          FRP. {{ corporation.typeCurrency }}
                        </template>
                        <Money
                          v-model.trim="form.referralPrice"
                          class="form-control text-center"
                          v-bind="corporation.typeCurrency == 'CAD' ? money : moneyUSD"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="1" class="p-0">
                      <b-icon
                        @click="showModal('referral-price-modal')"
                        id="financial-referral-price-tooltip"
                        icon="info-circle"
                        variant="dark-green"
                        scale="1.3"
                        class="mt-2 align-middle cursor-pointer"
                      />
                    </b-col>
                  </b-row>
                </b-col>
                <!-- Consigment -->
                <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                  <b-form-group
                    label-cols-lg="3"
                    label-cols-sm="12"
                    label-size="sm"
                    label="Mark as consignment"
                  >
                    <b-form-checkbox
                      id="checkbox-isConsignment"
                      v-model="form.isConsignment"
                      name="checkbox-isConsignment"
                      class="mt-1"
                      :value="1"
                      :unchecked-value="0"
                    >
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <!-- Buying cost -->
                <b-col
                  v-if="corporation.typeCurrency == 'CAD'"
                  xs="12"
                  sm="12"
                  md="12"
                  lg="6"
                  xl="6"
                >
                  <b-form-group
                    label-cols-lg="3"
                    label-cols-sm="12"
                    label-size="sm"
                    label="Buying cost"
                  >
                    <Money
                      v-model.trim="form.price"
                      class="form-control text-center"
                      v-bind="money"
                    />
                  </b-form-group>
                </b-col>

                <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                  <b-form-group
                    label-cols-lg="3"
                    label-cols-sm="12"
                    label-size="sm"
                    label="Buying cost USD"
                  >
                    <Money
                      :readonly="corporation.typeCurrency == 'CAD'"
                      v-model="form.priceUsd"
                      class="form-control text-center"
                      v-bind="moneyUSD"
                    />
                  </b-form-group>
                </b-col>
                <!-- Agent -->
                <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                  <b-form-group
                    label-cols-lg="3"
                    label-cols-sm="12"
                    label-size="sm"
                    label="Agent"
                  >
                    <AgentSelect
                      v-if="vehicle.stockNumber"
                      :agent="vehicle.user"
                      @input="setAgent($event)"
                    />
                  </b-form-group>
                </b-col>
                <!-- Client -->
                <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                  <b-form-group
                    label-cols-lg="3"
                    label-cols-sm="12"
                    label-size="sm"
                    label="Dealer"
                  >
                    <ClientSelect
                      v-if="vehicle.stockNumber"
                      :client-id="vehicle.client"
                      @input="setClient($event)"
                      :disabled="hasPayment"
                      type="client"
                    />
                  </b-form-group>
                </b-col>
                <!-- Market place -->
                <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                  <b-form-group
                    label-cols-lg="3"
                    label-cols-sm="12"
                    label-size="sm"
                    label="Marketplace"
                  >
                    <!--   <b-form-select v-model="marketPlace" :options="markets">
                  </b-form-select> -->
                    <MarketplaceSelect
                      :options="marketplaces"
                      :disabled="vehicle.exportLoadsId ? true : false"
                      @onMarketplaceSelect="setMarketplace($event)"
                      :modelValue="marketPlace"
                    />
                  </b-form-group>
                </b-col>
                <!-- Export location -->
                <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                  <b-form-group
                    label-cols-lg="3"
                    label-cols-sm="12"
                    label-size="sm"
                    label="Export location"
                  >
                    <b-form-select
                      v-model="exportLocation"
                      :options="exportLocationList"
                      text-field="name"
                      label-field="id"
                      value-field="id"
                      :disabled="vehicle.exportLoadsId ? true : false"
                    >
                    </b-form-select>
                  </b-form-group>
                </b-col>

                <!-- Final destination -->
                <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                  <b-form-group
                    label-cols-lg="3"
                    label-cols-sm="12"
                    label-size="sm"
                    label="Final destination"
                  >
                    <ClientSelect
                      :client-id="form.finalDestinationId"
                      @input="setFinalDestination($event)"
                      :type="'finalDestination'"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <!--    {{ agent }}
        <br />
        {{ form.agent }} -->
          <b-row class="justify-content-between" xs="12" sm="12" md="10" lg="10" xl="10">
            <b-col class="mt-3" sm="12" md="8">
              <AtacPrintButton :vehicle="vehicle" />
            </b-col>
            <b-col
              sm="12"
              md="4"
              align-self="end"
              class="mt-3 d-flex justify-content-between"
            >
              <b-button
                v-if="userHasPermission('stock', 'write')"
                type="button"
                class=""
                variant="danger"
                @click="disableVehicle()"
              >
                Disable vehicle
                <b-spinner v-if="status == 'fetching'" small variant="white" />
              </b-button>

              <b-button
                v-if="userHasPermission('stock', 'write')"
                type="submit"
                class=""
                variant="success"
              >
                {{ isRegister ? "Update vehicle" : "Register vehicle" }}
                <b-spinner v-if="status == 'fetching'" small variant="white" />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>

      <b-navbar class="v-25 text-dark-grey shadows mt-5 gradient-grey">
        <div class="m-3"></div>
      </b-navbar>

      <div class="container mt-5">
        <Menu />
      </div>
    </div>
    <b-modal
      id="referral-price-modal"
      ref="referral-price-modal"
      hide-footer
      size="lg"
      title="Financial referral price"
    >
      <FinancialReferralPriceTable
        :financialReferralPriceInfo="financialReferralPriceInfo.list"
        :trimSelected="financialReferralPriceInfo.canadianId"
      />
    </b-modal>
  </div>
</template>

<script>
import { Money } from "v-money";
import AgentSelect from "./Agents/AgentSelect.vue";
import ClientSelect from "./Clients/ClientSelect.vue";
import FinancialReferralPriceTable from "./FinancialReferralPriceTable.vue";
import AtacPrintButton from "../../superAdmin/components/atac/AtacPrintButton.vue";
import { mapState } from "vuex";
import { vehicleMixin } from "../mixin";
import VueSingleSelect from "vue-single-select";
import MarketplaceSelect from "./marketplace/MarketplaceSalect.vue";
import { GET_MARKETPLACE_LIST } from "@/modules/masterUser//marketplace/actions.js";
import {
  PUT_VEHICLE_BY_VIN,
  POST_VEHICLE_BY_STOCK,
  GET_AGENTS,
  GET_MAKES_AND_MODELS,
  SET_MAKES_AND_MODELS_FILTERS,
  GET_FINANCIAL_REFERRAL_PRICE_INFO,
  PUT_CONTRACT_REGISTRATION_FILE,
  PUT_DISABLE_VEHICLE,
} from "../actions";
import Menu from "./Menu.vue";
import store from "@/store";
import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../../superAdmin/mixins";
import VueBarcode from "vue-barcode";

export default {
  components: {
    Money,
    AgentSelect,
    ClientSelect,
    Menu,
    FinancialReferralPriceTable,
    VueSingleSelect,
    AtacPrintButton,
    MarketplaceSelect,
    "vue-barcode": VueBarcode,
  },
  mixins: [vehicleMixin, generalMixin, superAdminMixin],
  name: "InitialInformation",
  computed: {
    ...mapState({
      status: (state) => state.vehicle.vehicleByVin.status,

      isRegister: (state) => state.vehicle.vehicleByVin.data.isRegister,
      hasPayment: (state) => state.vehicle.vehicleByVin.data.havePayment,
      makesModelsAndColors: (state) => state.vehicle.makesModelsAndColors.data,
      financialReferralPriceInfo: (state) =>
        state.vehicle.financialReferralPriceInfo.data,
      marketplaces: (state) => state.masterUser.marketplace.marketplaceList.data,
      profile: (state) => state.auth.loginStore.profile.data,
    }),
    corporation() {
      const corporation = localStorage.getItem("corporation");
      let corporationSelect = this.profile.user.corporationPermissions.find(
        (c) => c.id == corporation
      );

      return corporationSelect
        ? corporationSelect
        : this.profile.user.corporationPermissions[0];
    },
    stockNumber() {
      return this.vehicle ? this.vehicle.stockNumber : "";
    },
    isSmallScreen() {
      return this.$vssWidth <= 990;
    },
    exportLocationList() {
      return this.exportLocations;
    },
    getRegistrationCheckVariant() {
      switch (this.registrationCheck) {
        case 0:
          return "outline-grey";
        case 1:
          return "outline-success";
        case 2:
          return "outline-warning";
        default:
          return "outline-grey";
      }
    },
    computedRegistrationCheck() {
      return this.form.registrationCheck;
    },
  },

  watch: {
    status() {
      if (this.status == "success") {
        this.form = {};
        this.formatForm();
      }
    },
    marketPlace() {
      this.form.marketPlace = this.marketPlace;
    },
    exportLocation() {
      this.form.exportLocation = this.exportLocation;
    },
    year() {
      this.form.year = this.year;
    },
    make() {
      this.form.make = this.make;
    },
    model() {
      this.form.model = this.model;
    },
    color() {
      this.form.color = this.color;
    },
    registrationCheck() {
      this.form.registrationCheck = this.registrationCheck;
    },
  },
  data() {
    return {
      form: {
        agent: null,
        stockNumber: null,
        price: 0,
        client: null,
        kilometers: 0,
        contract: null,
        ownerSheet: null,
        buyingDate: null,
        make: null,
        model: null,
        year: null,
        color: null,
        referralPrice: 0,
        marketPlace: null,
        registrationCheck: null,
        contractCheck: false,
        vinNumber: null,
        exportLocation: null,
        contractReceivedDate: null,
        registrationReceivedDate: null,
        finalDestinationId: null,
        fx: null,
        priceUsd: null,
        isConsignment: null,
      },
      marketPlace: "",
      exportLocation: null,
      barcodeOptions: {
        format: "CODE39",
        with: 10,
        height: 50,
        displayValue: false,
        marginTop: 10,
      },

      success: false,
      money: {
        value: 0,
        decimal: ".",
        thousands: ",",
        prefix: "CAD $ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      moneyUSD: {
        value: 0,
        decimal: ".",
        thousands: ",",
        prefix: "USD $ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      year: null,
      make: null,
      model: null,
      color: null,
      years: [],
      vinCopied: null,
      stockCopied: null,
      isAgentLoading: true,
      agent: null,
      fxCAD: null,
      markets: [
        { value: null, text: "Please select an option" },
        { value: "QAS", text: "QAS" },
        { value: "CANAM", text: "CANAM" },
      ],
      exportLocations: [],
      showReferralPriceTable: false,
      optionsRegistration: [
        { text: "X", value: 0, variant: "text-grey" },
        { text: "✓", value: 1, variant: "text-success" },
        { text: "ODO", value: 2, variant: "text-warning" },
      ],
      registrationCheck: 0,
      mileageHistory: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    store
      .dispatch(GET_AGENTS)
      .then(() => {
        next();
      })
      .catch(() => {});
  },
  beforeMount() {
    this.searchVehicleByStockNumber();
    this.getMakeAnddModels();
    this.getAgentClientExportLocations();
    this.setYears();
    this.getMarketPlaces();
  },
  methods: {
    setAgent(agent) {
      this.agent = { ...agent };
      this.form.agent = this.agent;
    },
    setClient(client) {
      this.form.client = client;
    },
    setFinalDestination(client) {
      if (client) {
        this.form.finalDestinationId = client.id;
      } else {
        this.form.finalDestinationId = null;
      }
    },
    registerVehicle() {
      const payload = this.formPayload();
      this.$store
        .dispatch(PUT_VEHICLE_BY_VIN, payload)
        .then(() => {
          this.toastMessage(
            "Vehicle information saved successfully.",
            "Success",

            "success"
          );

          this.$router
            .replace({
              name: "vehicle.search.stock",
              params: { stock: this.form.stockNumber },
            })
            .catch(() => {});

          this.searchVehicleByStockNumber(this.form.stockNumber);
          this.getFinancialReferralPriceInfo();
        })
        .catch((error) => {
          if (error.data.message == "already existing vinNumber") {
            this.toastMessage("The VIN Number already exists", "Warning", "warning");
            return;
          }

          this.toastMessage("The vehicle could not be obtain", "Warning", "warning");
        });
    },
    formPayload() {
      const data = new FormData();
      data.set("id", this.vehicle.id);
      data.set("vinNumber", this.form.vinNumber);
      data.set("agentId", this.form.agent ? this.form.agent.id : null);
      data.set("clientId", this.form.client ? this.form.client.id : null);
      data.set("price", this.form.price == 0 ? null : this.form.price);
      data.set("kilometers", this.form.kilometers);
      data.set("contract", this.form.contract);
      data.set("ownerSheet", this.form.ownerSheet);
      data.set("buyingDate", this.form.buyingDate);
      data.set("make", this.form.make);
      data.set("model", this.form.model);
      data.set("year", this.form.year);
      data.set("color", this.form.color ? this.form.color : "");
      data.set(
        "referralPrice",
        this.form.referralPrice == 0 ? null : this.form.referralPrice
      );
      data.set("marketPlaceId", this.form.marketPlace ? this.form.marketPlace.id : null);
      data.set("contractCheck", this.form.contractCheck);
      data.set("locationId", this.form.exportLocation);
      data.set("contractReceivedDate", this.form.contractReceivedDate);
      data.set("finalDestinationId", this.form.finalDestinationId);
      data.set("stockNumber", this.form.stockNumber);
      data.set("isConsignment", this.form.isConsignment);
      data.set("priceUsd", this.form.priceUsd);
      return data;
    },

    searchVehicleByStockNumber(stock = null) {
      this.$store
        .dispatch(POST_VEHICLE_BY_STOCK, {
          stockNumber: stock != null ? stock : this.$route.params.stock,
        })
        .then((response) => {
          this.mileageHistory = response.data.mileageHistory;
          this.formatForm();
          this.getFinancialReferralPriceInfo();
        })
        .catch(() => {
          this.toastMessage("The vehicle could not be obtain", "Warning", "warning");
        });
    },
    getMakeAnddModels(make = "") {
      this.$store.commit(SET_MAKES_AND_MODELS_FILTERS, { fields: { make } });

      this.$store
        .dispatch(GET_MAKES_AND_MODELS)
        .then(() => {})
        .catch(() => {
          this.toastMessage("Makes could not be obtain", "Warning", "warning");
        });
    },

    copyVinNumber(vin) {
      this.vinCopied = vin;
    },
    copyStockNumber(stock) {
      this.stockCopied = stock;
    },

    getFinancialReferralPriceInfo() {
      this.$store
        .dispatch(GET_FINANCIAL_REFERRAL_PRICE_INFO, {
          vinNumber: this.form.vinNumber,
        })
        .then(() => {})
        .catch(() => {});
    },
    formatForm() {
      this.form.price = this.vehicle.vehicleCostDetail.cost
        ? this.vehicle.vehicleCostDetail.cost
        : 0;
      this.form.kilometers = this.vehicle.vehicleInformation.kms;
      this.form.buyingDate = this.vehicle.buyingDate;
      this.form.make = this.vehicle.vinNumber.make ? this.vehicle.vinNumber.make : null;
      this.form.model = this.vehicle.vinNumber.model
        ? this.vehicle.vinNumber.model
        : null;
      this.form.year = this.vehicle.vinNumber.year ? this.vehicle.vinNumber.year : null;
      this.form.color = this.vehicle.vehicleInformation.color;
      this.form.referralPrice = this.vehicle.vinNumber.referralPrice
        ? this.vehicle.vinNumber.referralPrice
        : 0;
      this.form.marketPlace = this.vehicle.marketPlace
        ? this.vehicle.marketPlace.marketPlace
        : null;
      this.form.registrationCheck = this.vehicle.registrationCheck;
      this.registrationCheck = this.vehicle.registrationCheck;
      this.form.contractCheck = this.vehicle.contractCheck ? 1 : 0;
      this.form.vinNumber = this.vehicle.vinNumber.vinNumber
        ? this.vehicle.vinNumber.vinNumber
        : null;
      this.form.exportLocation = this.vehicle.exportLocationsId
        ? this.vehicle.exportLocationsId
        : null;
      this.marketPlace = this.vehicle.marketPlace
        ? this.vehicle.marketPlace.marketPlace
        : null;
      this.exportLocation = this.vehicle.exportLocationsId;
      this.form.contractReceivedDate = this.vehicle.contractReceivedDate
        ? this.vehicle.contractReceivedDate
        : null;
      this.form.registrationReceivedDate = this.vehicle.registrationReceivedDate
        ? this.vehicle.registrationReceivedDate
        : null;
      this.form.finalDestinationId = this.vehicle.finalDestinationId
        ? this.vehicle.finalDestinationId
        : null;
      this.form.stockNumber = this.vehicle.stockNumber;
      this.form.priceUsd = this.vehicle.vehicleCostDetail.costUsd
        ? this.vehicle.vehicleCostDetail.costUsd
        : 0;
      this.color = this.vehicle.vehicleInformation.color;
      this.form.isConsignment = this.vehicle.isConsignment;
    },

    getAgentClientExportLocations() {
      this.$store
        .dispatch("GET_EXPORT_LOCATIONS")
        .then((response) => {
          this.exportLocations = response.exportLocations;
        })
        .catch(() => {
          this.toastMessage("Export locations could not be obtain", "Warning", "warning");
        });
    },
    async deleteFile(type) {
      const confirm = await this.showConfirmDeleteBox();
      if (!confirm) return;
      this.$store
        .dispatch(PUT_CONTRACT_REGISTRATION_FILE, {
          id: this.vehicle.id,
          type: type,
        })
        .then(() => {
          this.searchVehicleByStockNumber(this.form.stockNumber);
          this.toastMessage("File deleted successfully", "Success", "success");
        })
        .catch(() => {
          this.toastMessage("File could not be deleted", "Warning", "warning");
        });
    },
    setYears() {
      this.years = [];
      for (let i = new Date().getFullYear() + 1; i >= 2000; i--) {
        this.years.push(i);
      }
    },

    onRegistrationCheckChange(value) {
      this.form.registrationCheck = value;
    },
    getMarketPlaces() {
      this.$store.dispatch(GET_MARKETPLACE_LIST);
    },
    setMarketplace(marketPlace) {
      this.marketPlace = marketPlace;
    },
    async disableVehicle() {
      const confirm = await this.showConfirmDeleteBox();
      if (!confirm) return;
      this.$store
        .dispatch(PUT_DISABLE_VEHICLE, {
          id: this.vehicle.id,
        })
        .then(() => {
          this.toastMessage("Vehicle disabled successfully", "Success", "success");
          this.$router.go(-1);
        })
        .catch(() => {
          this.toastMessage("Vehicle could not be disabled", "Warning", "warning");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
i {
  font-weight: bold !important;
}

::v-deep .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
</style>
