<template>
  <b-card v-if="carwash">
    <b-row>
      <b-col cols="10">
        <span>
          Registered date:
          <i
            >{{ carwash.createdAt | formatDateTime }} -
            {{ carwash.user.firstName }} {{ carwash.user.lastName }}</i
          > </span
        ><br />

        <span v-if="carwash.status == 2">
          End date:
          <i>
            {{ carwash.finalDate | formatDateTime }} -
            {{
              carwash.finalUser
                ? carwash.finalUser.firstName + " " + carwash.finalUser.lastName
                : "No one"
            }}
          </i> </span
        ><br />
        <span v-if="carwash.supplierCompany">
          Supplier: <i> {{ carwash.supplierCompany.companyName }} </i>
        </span>
        <br />
        <br />
        <span
          >Status:
          <i> {{ getVariantAndLabel(carwash.status).label }}</i>
        </span>
      </b-col>
      <b-col class="text-center">
        <div class="text-center">
          <span
            class="dot"
            :style="{
              'background-color': getVariantAndLabel(carwash.status).variant,
            }"
          />
          <br />
          <b-button
            v-if="carwash.status == 1 && userHasPermission('carwash', 'write')"
            class=""
            variant="light"
            size="sm"
            @click="editCarwash()"
          >
            <b-icon icon="pencil" />
          </b-button>
          <br />
          <b-icon
            v-if="
              carwash.status != 0 &&
              carwash.status != 2 &&
              userHasPermission('carwash', 'write')
            "
            icon="check-square"
            scale="1.3"
            class="m-1 cursor-pointer"
            id="carwash-status-btn"
            @click="updateCarwashStatus(2)"
          ></b-icon>
          <b-tooltip target="carwash-status-btn">Mark as complete</b-tooltip>

          <b-icon
            v-if="carwash.status == 1 && userHasPermission('carwash', 'write')"
            icon="x-square"
            class="m-1 cursor-pointer"
            v-b-tooltip.hover
            title="Cancel"
            id="cancel-work-order"
            scale="1.3"
            @click="showCancelNote = true"
          ></b-icon>
          <b-tooltip target="cancel-work-order">Cancel</b-tooltip>
        </div>
      </b-col>
    </b-row>
    <p v-if="showCancelNote">
      <b-form-group label="Note">
        <b-form-textarea
          v-model="note"
          class="resize-none"
          aria-describedby="input-description-live-feedback"
        />
      </b-form-group>
      <!-- Cancel button and confirm button -->
      <b-row>
        <b-col sm="12" md="12" lg="12" xl="12" class="">
          <div class="float-right">
            <b-btn
              size="lg"
              class="btn btn-danger mr-2"
              type="button"
              @click="showCancelNote = false"
            >
              Cancel
            </b-btn>

            <b-btn
              size="lg"
              class="btn btn-success"
              type="button"
              @click="updateCarwashStatus(0)"
            >
              Confirm
            </b-btn>
          </div>
        </b-col>
      </b-row>
    </p>
    <div>
      <p v-if="carwash.note">
        Note: <i>{{ carwash.note }}</i>
      </p>
    </div>
    <p>
      <TypeWashes :typeWashes="carwash" class="mb-3" />
      <span class="text-right">
        <b-button
          variant="yellow"
          size="sm"
          @click="generateCarwashXLs(carwash)"
          class="float-right"
          v-b-tooltip.hover
          title="Download carwash XLS"
        >
          <b-icon icon="file-earmark-arrow-down" variant="white"></b-icon>
        </b-button>
        <b-button
          variant="success"
          size="sm"
          @click="openEmailBodyModal(carwash.id)"
          class="float-right mx-2"
          v-b-tooltip.hover
          title="Send carwash XLS by email"
        >
          <b-icon icon="envelope" variant="white"></b-icon>
        </b-button>
      </span>
    </p>
    <EmailBody @submit="sendCarwashByEmail" :id="carwash.id" />
    <TaskUpdateStatusModal
      :tasks="tasks"
      :id="carwash.id"
      @update-task="refreshAfterUpdateTask"
    />
  </b-card>
</template>

<script>
import { PUT_CARWASH, GET_CARWASH_EMAIL } from "./actions";
import TypeWashes from "./TypeWashes.vue";
import { generalMixin } from "@/modules/mixin.js";
import { mapState } from "vuex";
import EmailBody from "@/modules/shared/EmailBody";
import { superAdminMixin } from "../../mixins";
import TaskUpdateStatusModal from "../../tasks/components/TaskUpdateStatusModal.vue";

export default {
  components: {
    TypeWashes,
    EmailBody,
    TaskUpdateStatusModal,
  },
  props: ["carwash", "refreshData"],
  mixins: [generalMixin, superAdminMixin],

  data() {
    return {
      isEditing: false,
      form: {
        cost: this.carwash.cost,
      },
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "CAD$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      showCancelNote: false,
      note: "",
      tasks: [],
    };
  },
  computed: {
    ...mapState({
      vehicle: (state) => state.superAdminStore.vehicle,
    }),
  },
  methods: {
    getVariantAndLabel(status) {
      switch (status) {
        case 0:
          return {
            variant: "#dc3545", // red
            label: "Canceled",
          };
        case 1:
          return {
            variant: "#fd7e14", //yellow
            label: "Active",
          };

        case 2:
          return {
            variant: "#54b962", // green
            label: "Completed",
          };
      }
    },
    updateCarwashStatus(status) {
      this.$store
        .dispatch(PUT_CARWASH, {
          id: this.carwash.id,
          status: status,
          note: this.note,
          type: "carwash",
        })
        .then((response) => {
          if (response.task.length > 0 && status == 2) {
            this.tasks = response.task;
            this.$root.$bvModal.show(
              "task-update-status-modal" + this.carwash.id
            );
          } else {
            this.refreshData();
          }

          this.$root.$bvToast.toast("Carwash status updated", {
            title: "Success",
            variant: "success",
          });
        })
        .catch((error) => {
          console.log(error);
          this.$root.$bvToast.toast("Carwash could not update", {
            title: "Error",
            variant: "warning",
          });
        });
    },

    generateCarwashXLs(carwash) {
      this.$store
        .dispatch("GET_CARWASH_EXCEL", { id: carwash.id })
        .then((response) => {
          const blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = this.formatFileName("carwash", this.vehicle);
          link.click();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Could not get XLS", {
            title: "Error",
            variant: "warning",
          });
        });
    },

    sendCarwashByEmail(body) {
      this.$store
        .dispatch(GET_CARWASH_EMAIL, { id: this.carwash.id, body: body })
        .then(() => {
          this.$root.$bvToast.toast("Carwash XLS sent", {
            title: "Success",
            variant: "success",
          });
          this.$root.$bvModal.hide("email-body-modal");
        })
        .catch(() => {
          this.$root.$bvToast.toast("Carwash XLS could not be sent", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    editCarwash() {
      this.$emit("update-carwash", this.carwash);
      /* scroll to form */
      this.scrollToAnchor("carwash-form");
    },
    scrollToAnchor(stockNumber) {
      const element = document.getElementById(stockNumber);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    },
    openEmailBodyModal(id) {
      this.$root.$bvModal.show("email-body-modal" + id);
    },
    refreshAfterUpdateTask() {
      this.refreshData();
    },
  },
};
</script>

<style></style>
