<template>
  <b-form @submit.stop.prevent="saveInfo()">
    <b-row>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label="Release date">
          <b-form-input
            v-model="form.releaseDate"
            class="resize-none"
            type="date"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <VehiclesList
      @vehicles-selected="setVehiclesSelected"
      :vehiclesPreSelected="[]"
      class="h-25"
    />
    <div class="text-right mt-2 mr-">
      <b-button
        :disabled="
          releaseDate.status === 'fetching' || vehiclesSelected.length <= 0
        "
        variant="success"
        type="submit"
        class="m-2 text-white"
      >
        <b-spinner
          v-if="releaseDate.status === 'fetching'"
          variant="white"
          small
        />
        <span v-else>Submit</span>
      </b-button>
    </div>
  </b-form>
</template>

<script>
import { mapState } from "vuex";
import { superAdminMixin } from "../../mixins";
import { POST_VEHICLES_RELEASE_DATE, GET_VEHICLES_TO_RELEASE } from "./actions";
import VehiclesList from "./VehiclesList.vue";
import { generalMixin } from "../../../mixin";

export default {
  name: "ReleaseDateForm",
  components: {
    VehiclesList,
  },

  mixins: [superAdminMixin, generalMixin],

  data() {
    return {
      vehiclesSelected: [],
      form: {
        releaseDate: new Date().toISOString().substr(0, 10),
      },
    };
  },
  computed: {
    ...mapState({
      releaseDate: (state) =>
        state.superAdminStore.bulkReleaseDate.bulkReleaseDate,
    }),
  },

  beforeMount() {
    this.getVehicles();
  },
  methods: {
    resetForm() {
      this.form.releaseDate = null;
    },

    saveInfo() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_VEHICLES_RELEASE_DATE, payload)
        .then(() => {
          this.$root.$bvToast.toast("Release date updated successfully", {
            title: "Success",
            variant: "success",
          });
          this.resetForm();
          this.closeModal("bulk-release-date-modal");
          this.getVehiclesInfo();
        })
        .catch((error) => {
          this.$root.$bvToast.toast(
            error.data.messages.charAt(0).toUpperCase() +
              error.data.messages.slice(1),
            {
              title: "Error",
              variant: "warning",
            }
          );
        });
    },

    formatPayload() {
      const data = {
        releaseDate: this.form.releaseDate,
        vehicles: this.vehiclesSelected,
      };
      return data;
    },

    getVehicles() {
      this.$store.dispatch(GET_VEHICLES_TO_RELEASE);
    },
    setVehiclesSelected(vehicles) {
      this.vehiclesSelected = vehicles;
    },
  },
};
</script>

<style></style>
