<template>
  <div class="container justify-content-center">
    <b-card class="my-auto mx-auto" style="max-width: 40rem">
      <div class="text-center">
        <h2>Select corporation</h2>
      </div>
      <b-card
        v-for="(corporation, i) in profile.user.corporationPermissions"
        :key="i"
      >
        <b-row>
          <b-col cols="2">
            <b-img :src="corporation.logo" fluid alt="Responsive image"></b-img>
          </b-col>
          <b-col cols="8">
            <h3>{{ corporation.companyName }}</h3>
            <p>
              {{
                corporation.commercialName != null
                  ? corporation.commercialName
                  : ""
              }}
            </p></b-col
          >
          <b-col cols="2">
            <b-button
              variant="success"
              class="text-white float-right mt-1"
              @click="selectCorporation(corporation)"
            >
              Select</b-button
            >
          </b-col>
        </b-row>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import appRoles from "../../../helpers/appRoles";

export default {
  name: "SelectCorporationView",
  computed: {
    ...mapState({
      profile: (state) => state.auth.loginStore.profile.data,
    }),
  },
  methods: {
    selectCorporation(corporation) {
      localStorage.setItem("corporation", corporation.id);
      this.$store.commit("SET_CORPORATION", corporation);
      if (this.profile.user.favorite != null) {
        this.$router.push({ name: this.profile.user.favorite });
        return;
      }
      const userRoles = this.profile.user.roles.find(
        (r) => r.name === this.profile.user.roles[0].name
      );
      const roleAuthority = appRoles.find(
        (role) => role.authority === userRoles.name
      );
      this.$router.push({ name: roleAuthority.mainRoute });
    },
  },
};
</script>

<style>
</style>