<template>
  <b-modal
    id="carwash-modal"
    ref="modal"
    centered
    no-close-on-backdrop
    scrollable
    size="lg"
    title="Carwash"
    @show="
      getCarwashList();
      getProviders();
    "
    @hide="
      getVehiclesInfo();
      resetForm();
    "
    hide-footer
  >
    <VehicleDetails
      v-if="vehicle"
      :vehicle="vehicle"
      :color="vehicle.vehicleInformation.color"
    />
    <b-form
      @submit.stop.prevent="saveCarwash()"
      v-if="userHasPermission('carwash', 'write')"
      id="carwash-form"
    >
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group>
            <template slot="label">
              Provider <span class="text-danger">*</span>
            </template>

            <vue-single-select
              :classes="{ input: 'form-control', dropdown: 'dropdown' }"
              v-model="form.supplier"
              :options="suppliers"
              optionLabel="companyName"
            ></vue-single-select>
          </b-form-group>
        </b-col>
      </b-row>
      <CarwashList
        ref="CarwashList"
        class="mt-4"
        :carwashes="carwashes.data"
        @update-select-carwash="updateSelectCarwash"
        :carwashesSelected="carwashesSelectedToEdit"
        v-if="refreshComponent"
      />
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group>
            <template slot="label"> Extras </template>
            <b-form-input size="md" v-model="form.extraName" />
          </b-form-group>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group>
            <template slot="label"> Cost </template>
            <Money
              v-model="form.extraCost"
              class="form-control text-center"
              v-bind="money"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <div class="text-right mt-2">
        <b-button
          :disabled="$v.form.$invalid || carwash === 'fetching'"
          variant="primary"
          type="submit"
        >
          <b-spinner v-if="carwash === 'fetching'" variant="white" small />
          <span v-else>Submit</span>
        </b-button>
      </div>
    </b-form>
    <CarwashTable @update-carwash="editCarwash" />
    <TaskUpdateStatusModal :tasks="tasks" :id="1" />
  </b-modal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import CarwashList from "@/modules/superAdmin/components/carwash/CarwashList.vue";
import { superAdminMixin } from "../../mixins";
import { GET_CARWASH, POST_CARWASH, GET_CARWASH_LIST } from "./actions";
import { Money } from "v-money";
import CarwashTable from "./CarwashTable";
import VehicleDetails from "@/modules/superAdmin/components/VehicleDetails.vue";
import VueSingleSelect from "vue-single-select";
import TaskUpdateStatusModal from "../../tasks/components/TaskUpdateStatusModal.vue";

export default {
  name: "CarwashModal",
  components: {
    CarwashList,
    Money,
    CarwashTable,
    VehicleDetails,
    VueSingleSelect,
    TaskUpdateStatusModal,
  },
  mixins: [superAdminMixin],
  computed: {
    ...mapState({
      vehicle: (state) => state.superAdminStore.vehicle,
      carwashes: (state) =>
        state.superAdminStore.componentStore.carwash.carwashes,
      carwash: (state) => state.superAdminStore.componentStore.carwash.carwash,
    }),
  },
  data() {
    return {
      suppliers: [],

      form: {
        id: null,
        vehicleId: null,
        supplier: null,
      },
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "CAD$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      carwashesSelectedToEdit: [],
      refreshComponent: true,
      tasks: [],
    };
  },
  validations: {
    form: {
      supplier: {
        required,
      },
    },
  },

  methods: {
    getCarwashList() {
      this.$store.dispatch(GET_CARWASH);
    },

    getProviders() {
      this.$store
        .dispatch("GET_COMPANIES_LIST", { isSupplier: 1 })
        .then((response) => {
          this.suppliers = response;
        })
        .catch(() => {
          this.$root.$bvToast.toast("Could not get the suppliers list ", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    updateSelectCarwash(carwashesSelected) {
      this.carwashesSelected = [...carwashesSelected];
    },
    saveCarwash() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_CARWASH, payload)
        .then((response) => {
          if (response.task.length > 0) {
            /* show TaskUpdateStatusModal */
            this.tasks = response.task;

            this.$bvModal.show("task-update-status-modal" + 1);
          }
          this.$root.$bvToast.toast("Carwash save successfully", {
            title: "Success",
            variant: "success",
          });

          this.resetForm();
          this.getCarwashTable();
        })
        .catch((error) => {
          this.$root.$bvToast.toast(
            error.data.messages.charAt(0).toUpperCase() +
              error.data.messages.slice(1),
            {
              title: "Error",
              variant: "warning",
            }
          );
        });
    },

    formatPayload() {
      const payload = {
        id: this.form.id || null,
        vehiclesId: this.vehicle.id,
        supplierCompanyId: this.form.supplier.id,
        extraCost: this.form.extraCost,
        extra: this.form.extraName,
        list: this.carwashesSelected,
      };
      return payload;
    },
    getCarwashTable() {
      this.$store.dispatch(GET_CARWASH_LIST, { id: this.vehicle.id });
    },
    resetForm() {
      this.form.id = null;
      this.form.supplier = null;
      this.form.extraName = null;
      this.form.extraCost = 0;
      this.carwashesSelected = [];
      this.carwashesSelectedToEdit = [];
      this.$refs.CarwashList.setCarwashSelectedToUpdate([]);
      this.refreshComponent = false;
      this.refreshComponent = true;
    },
    editCarwash(carwashToEdit) {
      this.form.supplier = this.suppliers.find(
        (supplier) => supplier.id === carwashToEdit.supplierCompanyId
      );
      this.form.extraName = carwashToEdit.extra;
      this.form.extraCost = carwashToEdit.extraCost;
      this.form.id = carwashToEdit.id;
      this.carwashesSelectedToEdit = carwashToEdit.typeWashes;
      this.$refs.CarwashList.setCarwashSelectedToUpdate(
        carwashToEdit.typeWashes
      );
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
