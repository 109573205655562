<template>
  <div>
    <div v-if="filtersStatus === 'fetching'" class="body-color shadow m-2">
      <b-row>
        <b-col v-for="(data, index) in [1]" :key="index">
          <b-card no-body img-top>
            <b-card-body>
              <b-skeleton-table
                :rows="5"
                :columns="1"
                :table-props="{ bordered: true, striped: true }"
              />
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div v-else-if="filtersStatus === 'success'">
      <div class="shadow">
        <!-- Export location -->
        <b-dropdown
          v-model="location"
          :text="this.location ? this.location : 'Any export location'"
          variant="white"
          block
          class="m-2 mb-2"
          menu-class="w-100"
          style="padding-top: 25px"
        >
          <b-dropdown-item @click="location = null">
            Any export location
          </b-dropdown-item>
          <b-dropdown-item
            v-for="(l, i) of filters.location"
            :key="i"
            @click="location = l"
          >
            {{ l }}
          </b-dropdown-item>
        </b-dropdown>
        <br />

        <!-- Last location -->
        <!--   <vue-single-select
          :classes="{ input: 'form-control my-2 mt-4', dropdown: 'dropdown' }"
          v-model="lastLocation"
          menu-class="w-100"
          :options="filters.lastLocation"
          :placeholder="
            this.lastLocation ? this.lastLocation : 'Any last location'
          "
        >
          <template slot="option" slot-scope="{ option }">
            <div>
              <span @click="lastLocation = option">
                {{ option }}
              </span>
            </div>
          </template>
        </vue-single-select> -->

        <label class="">Last location:</label>
        <multiselect
          v-model="lastLocation"
          :options="filters.lastLocation"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Pick some option"
          label=""
          :preselect-first="false"
        >
          <template slot="selection" slot-scope="{ values, isOpen }"
            ><span class="multiselect__single" v-if="values.length && !isOpen"
              >{{ values.length }} options selected</span
            ></template
          >
        </multiselect>

        <!-- Final destination -->
        <!--     <vue-single-select
          :classes="{ input: 'form-control my-2 mt-4', dropdown: 'dropdown' }"
          v-model="finalDestination"
          menu-class="w-100"
          :options="filters.finalDestination"
          :placeholder="
            this.finalDestination
              ? this.finalDestination
              : 'Any final destination'
          "
        >
          <template slot="option" slot-scope="{ option }">
            <div>
              <span @click="finalDestination = option">
                {{ option }}
              </span>
            </div>
          </template>
        </vue-single-select>
 -->
        <label class="mt-1">Final destination:</label>
        <multiselect
          v-model="finalDestination"
          :options="filters.finalDestination"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Pick some option"
          label=""
          :preselect-first="false"
        >
          <template slot="selection" slot-scope="{ values, isOpen }"
            ><span class="multiselect__single" v-if="values.length && !isOpen"
              >{{ values.length }} options selected</span
            ></template
          >
        </multiselect>

        <label class="mt-1">Bought from:</label>
        <multiselect
          v-model="boughtTo"
          :options="filters.boughtTo"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Pick some option"
          label=""
          :preselect-first="false"
        >
          <template slot="selection" slot-scope="{ values, isOpen }"
            ><span class="multiselect__single" v-if="values.length && !isOpen"
              >{{ values.length }} options selected</span
            ></template
          >
        </multiselect>

        <label class="mt-1">Sold to:</label>
        <multiselect
          v-model="soldTo"
          :options="filters.soldTo"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Pick some option"
          label=""
          :preselect-first="false"
        >
          <template slot="selection" slot-scope="{ values, isOpen }"
            ><span class="multiselect__single" v-if="values.length && !isOpen"
              >{{ values.length }} options selected</span
            ></template
          >
        </multiselect>
        <label class="mt-1">In transit to:</label>
        <multiselect
          v-model="transferDestinations"
          :options="filters.transferDestinations"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Pick some option"
          label=""
          :preselect-first="false"
        >
          <template slot="selection" slot-scope="{ values, isOpen }"
            ><span class="multiselect__single" v-if="values.length && !isOpen"
              >{{ values.length }} options selected</span
            ></template
          >
        </multiselect>

        <label class="mt-1">Transfer or location:</label>
        <multiselect
          v-model="transferOrLocation"
          :options="filters.transferOrLocation"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Pick some option"
          label=""
          :preselect-first="false"
        >
          <template slot="selection" slot-scope="{ values, isOpen }"
            ><span class="multiselect__single" v-if="values.length && !isOpen"
              >{{ values.length }} options selected</span
            ></template
          >
        </multiselect>
        <!-- Stock / Vin Number -->
        <b-row class="mt-4">
          <b-col class="text-center">
            <strong>Stock / Vin Number</strong>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col class="ml-2 mr-1">
            <b-input v-model="search" />
          </b-col>
        </b-row>

        <!-- makes -->
        <vue-single-select
          :classes="{
            input: 'form-control my-2 mt-4 mr-1',
            dropdown: 'dropdown',
          }"
          v-model="make"
          menu-class="w-100"
          :options="filters.make"
          :placeholder="this.make ? this.make : 'Any make'"
        >
          <template slot="option" slot-scope="{ option }">
            <div
              @click="
                make = option;
                searchModel(option);
              "
            >
              <span>
                {{ option }}
              </span>
            </div>
          </template>
        </vue-single-select>

        <!-- Models -->
        <vue-single-select
          :classes="{ input: 'form-control my-2 mt-4', dropdown: 'dropdown' }"
          v-model="model"
          menu-class="w-100"
          :options="vehicleModels"
          :placeholder="this.model ? this.model : 'Any model'"
        >
          <template slot="option" slot-scope="{ option }">
            <div @click="model = option">
              <span>
                {{ option }}
              </span>
            </div>
          </template>
        </vue-single-select>

        <!-- Release Dates -->
        <div v-if="user">
          <b-row class="mt-4">
            <b-col class="text-center">
              <strong>Release Date</strong>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="m-2">
              <b-form-input
                v-model="releaseDate1"
                class="text-center"
                type="date"
              />
            </b-col>
            <b-col class="m-2">
              <b-form-input
                v-model="releaseDate2"
                :disabled="!releaseDate1"
                :min="releaseDate1"
                class="text-center"
                type="date"
              />
            </b-col>
          </b-row>
        </div>

        <!-- Years -->
        <b-row :class="[user ? 'mt-2' : 'mt-4']">
          <b-col class="text-center">
            <strong>Year</strong>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col class="ml-3 mr-3">
            <b-row class="mt-3">
              <b-col cols="6">
                <span class="range-slider-value">{{ yearRange[0] }}</span>
              </b-col>
              <b-col cols="6" class="text-right">
                <span class="range-slider-value value-high">{{
                  yearRange[1]
                }}</span>
              </b-col>
            </b-row>
            <SliderBase
              class="mt-3"
              v-model="yearRange"
              :range="{ min: minYear, max: maxYear }"
              :step="1"
            ></SliderBase>
          </b-col>
        </b-row>

        <!-- Status -->
        <b-row v-if="!offersView && (userIsAdmin || userIsAgent)" class="mt-2">
          <b-col class="ml-3 mr-3">
            <b-dropdown
              :text="
                this.status
                  ? this.status === 'READYTOSELL' ||
                    this.status === 'ReadyToSell'
                    ? 'READY TO SELL'
                    : this.status
                  : 'Any Status'
              "
              block
              variant="white"
              class="m-2 mt-3"
              menu-class="w-100"
            >
              <b-dropdown-item @click="status = null">
                Any Status
              </b-dropdown-item>
              <b-dropdown-item
                v-for="(s, i) of filters.status"
                :key="i"
                @click="status = s"
              >
                {{
                  s === "READYTOSELL" || s === "ReadyToSell"
                    ? "Ready To Sell"
                    : s | toUpperCase
                }}
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>

        <!-- Client/Buyer -->
        <div v-if="offersView">
          <div v-if="userIsAdmin || userIsAgent">
            <b-row class="mt-4">
              <b-col class="text-center">
                <strong>Client</strong>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col class="ml-2 mr-1">
                <b-form-input v-model="client" />
              </b-col>
            </b-row>
          </div>

          <!-- Agent --->
          <b-dropdown
            v-if="userIsAdmin"
            :text="this.agent ? this.agent : 'Any Agent'"
            block
            variant="white"
            class="m-2 mb-4"
            menu-class="w-100"
            style="padding-top: 25px"
          >
            <b-dropdown-item
              @click="
                agent = null;
                agentId = null;
              "
            >
              Any Agent
            </b-dropdown-item>
            <b-dropdown-item
              v-for="(a, i) of filters.agents"
              :key="i"
              @click="
                agent = a.firstName + ' ' + a.lastName;
                agentId = a.id;
              "
            >
              {{ a.firstName }} {{ a.lastName }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <!-- Actions -->
        <b-row style="padding-bottom: 25px" class="ml-1">
          <b-col>
            <b-button
              variant="yellow"
              class="mt-4 shadow text-white"
              size="lg"
              block
              @click="resetFilters()"
            >
              Reset
            </b-button>
          </b-col>
          <b-col class="mr-2">
            <b-button
              class="mt-4 shadow"
              variant="success"
              size="lg"
              block
              @click="searchVehicles"
              v-b-toggle.advance-search-sidebar
            >
              Search
            </b-button>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";

import Multiselect from "vue-multiselect";

import { listVehiclesMixin } from "./mixins";
import { generalMixin } from "@/modules/mixin";
import VueSingleSelect from "vue-single-select";
import SliderBase from "../../../../components/BaseSlider.vue";
import {
  GET_VEHICLE_FILTERS,
  GET_VEHICLE_MODELS,
  GET_VEHICLE_TRIMS,
  CLEAR_VEHICLE_MODELS,
  CLEAR_VEHICLE_TRIMS,
} from "./actions";

export default {
  components: {
    SliderBase,
    VueSingleSelect,
    Multiselect,
  },
  mixins: [listVehiclesMixin, generalMixin],
  props: {
    unAuthUser: {
      type: Boolean,
      default: false,
    },
    offersView: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      vehiclesFilters: (state) => state.superAdminStore.vehiclesInfo.filters,
    }),
    filtersStatus() {
      return store.state.adminStore.vehicles.listStore.vehicleFilters.status;
    },
    searchFilters() {
      return this.vehiclesFilters;
    },
    filters() {
      return store.state.adminStore.vehicles.listStore.vehicleFilters.data;
    },
    vehicleModels() {
      return store.state.adminStore.vehicles.listStore.vehicleFilters.models
        .data;
    },
    vehicleTrims() {
      return store.state.adminStore.vehicles.listStore.vehicleFilters.trims
        .data;
    },
    minYear() {
      return parseInt(this.filters.year[0]);
    },
    maxYear() {
      return parseInt(this.filters.year[this.filters.year.length - 1]) ===
        this.minYear
        ? this.minYear + 1
        : parseInt(this.filters.year[this.filters.year.length - 1]);
    },
  },
  created() {
    this.getFilters();
    this.getSearchFilters();
  },
  methods: {
    getFilters() {
      this.$store
        .dispatch(GET_VEHICLE_FILTERS)
        .then(() => {
          const minY = this.searchFilters.year1
            ? this.searchFilters.year1
            : this.minYear;

          const maxY = this.searchFilters.year2
            ? this.searchFilters.year2
            : this.maxYear;

          this.yearRange = [minY, maxY];
          if (this.searchVehicles.make)
            this.$store.commit(CLEAR_VEHICLE_MODELS);
          this.$store.commit(CLEAR_VEHICLE_TRIMS);
        })
        .catch(() => {});
    },
    payload() {
      const payload = {
        location: this.location,
        search: this.search,
        make: this.make,
        model: this.model,
        trim: this.trim,
        releaseDate1: this.releaseDate1,
        releaseDate2: this.releaseDate2,
        year1: this.yearRange[0],
        year2: this.yearRange[1],
        /*     mile1: this.mileageRange[0],
        mile2: this.mileageRange[1], */
        transmissionType: this.transmissionType,
        /*    price1: this.priceRange[0],
        price2: this.priceRange[1], */
        new: null,
        status: this.status,
        client: this.client,
        agent: this.agentId,
        page: 1,
        lastLocation: this.convertArrayToString(this.lastLocation),
        finalDestination: this.convertArrayToString(this.finalDestination),
        boughtTo: this.convertArrayToString(this.boughtTo),
        soldTo: this.convertArrayToString(this.soldTo),
        transferDestinations: this.convertArrayToString(
          this.transferDestinations
        ),
        transferOrLocation: this.convertArrayToString(this.transferOrLocation),
      };
      return payload;
    },
    getVehicles() {
      this.$store
        .dispatch("GET_VEHICLES_INFO", {})
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("Could not get the vehicle list", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    searchModel(make) {
      if (!make) {
        this.$store.commit(CLEAR_VEHICLE_MODELS);
        this.$store.commit(CLEAR_VEHICLE_TRIMS);
        this.make = make;
        this.model = null;
      } else {
        this.$store.dispatch(GET_VEHICLE_MODELS, { make }).then(() => {
          this.make = make;
        });
      }
    },
    searchTrims(model) {
      if (!model) {
        this.$store.commit(CLEAR_VEHICLE_TRIMS);
        this.model = model;
        this.trim = null;
      } else {
        this.$store
          .dispatch(GET_VEHICLE_TRIMS, { make: this.make, model })
          .then(() => {
            this.model = model;
            this.trim = null;
          });
      }
    },
    searchVehicles() {
      this.$store.commit("SET_VEHICLES_INFO_FILTERS", { reset: true });

      this.$store.commit("SET_VEHICLES_INFO_FILTERS", {
        fields: this.payload(),
      });
      this.getVehicles();
      /* hide search bar */
    },
    getSearchFilters() {
      this.location = this.searchFilters.location;
      this.lastLocation = this.searchFilters.lastLocation;
      this.search = this.searchFilters.search;
      this.make = this.searchFilters.make;
      this.model = this.searchFilters.model;
      this.trim = this.searchFilters.trim;
      this.releaseDate1 = this.searchFilters.releaseDate1;
      this.releaseDate2 = this.searchFilters.releaseDate2;
      this.year1 = this.searchFilters.year1;
      this.year2 = this.searchFilters.year2;

      this.transmissionType = this.searchFilters.transmissionType;

      this.status = this.searchFilters.status;
      this.lastLocation = this.convertStringToArray(
        this.searchFilters.lastLocation
      );
      this.finalDestination = this.convertStringToArray(
        this.searchFilters.finalDestination
      );
      this.boughtTo = this.convertStringToArray(this.searchFilters.boughtTo);
      this.soldTo = this.convertStringToArray(this.filtersStatus.soldTo);
      this.transferDestinations = this.convertStringToArray(
        this.searchFilters.transferDestinations
      );
      this.transferOrLocation = this.convertStringToArray(
        this.searchFilters.transferOrLocation
      );
    },
    convertArrayToString(array) {
      if (array) return array.join(",");
      else return null;
    },
    convertStringToArray(string) {
      if (string) return string.split(",");
      else return [];
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="@vueform/slider/themes/default.css"></style>

<style lang="scss">
$yellow: #35a4dd;
.slider-connect {
  background: $yellow;
}

.slider-tooltip {
  border: 1px solid $yellow;
  background: $yellow;
}

.body-color {
  background-color: #f2f2f2;
}

.dropdown-border-color {
  border-color: black;
}
.min-w {
  min-width: 100% !important;
}

.max-h {
  height: auto;
  max-height: 30vh;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
