<template>
  <b-modal
    id="carfax-modal"
    ref="carfax-modal"
    hide-footer
    size="lg"
    title="Carfax"
    @show="setVehicle()"
    @hide="getVehiclesInfo()"
  >
    <VehicleDetails
      v-if="vehicle"
      :vehicle="vehicle"
      :color="vehicle.vehicleInformation.color"
    />
    <Carfax />
  </b-modal>
</template>

<script>
import Carfax from "@/modules/vehicle/components/carfax/Carfax.vue";
import { mapState } from "vuex";
import { POST_VEHICLE_BY_STOCK_SUCCESS } from "@/modules/vehicle/actions";
import VehicleDetails from "@/modules/superAdmin/components/VehicleDetails.vue";
import { superAdminMixin } from "@/modules/superAdmin/mixins.js";

export default {
  components: { Carfax, VehicleDetails },
  computed: {
    ...mapState({
      vehicle: (state) => state.superAdminStore.vehicle,
    }),
  },
  mixins: [superAdminMixin],
  methods: {
    setVehicle() {
      this.$store.commit(POST_VEHICLE_BY_STOCK_SUCCESS, { data: this.vehicle });
    },
  },
};
</script>

<style></style>
