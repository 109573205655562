<template>
  <b-modal
    id="login-modal"
    ref="modal"
    hide-footer
    size="lg"
    centered
    @show="show"
  >
    <LoginForm />
  </b-modal>
</template>

<script>
import LoginForm from './LoginForm.vue';
export default {
  components: { LoginForm },
  methods: {
    show() {}
  }
};
</script>

<style></style>
