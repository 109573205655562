export const GET_SELL_CHECKS = "GET_SELL_CHECKS";
export const GET_SELL_CHECKS_SUCCESS = "GET_SELL_CHECKS_SUCCESS";
export const GET_SELL_CHECKS_ERROR = "GET_SELL_CHECKS_ERROR";

export const POST_SELL_CHECK = "POST_SELL_CHECK";
export const POST_SELL_CHECK_SUCCESS = "POST_SELL_CHECK_SUCCESS";
export const POST_SELL_CHECK_ERROR = "POST_SELL_CHECK_ERROR";

export const PUT_SELL_CHECK_DISABLE = "PUT_SELL_CHECK_DISABLE";
export const PUT_SELL_CHECK_DISABLE_SUCCESS = "PUT_SELL_CHECK_DISABLE_SUCCESS";
export const PUT_SELL_CHECK_DISABLE_ERROR = "PUT_SELL_CHECK_DISABLE_ERROR";

export const GET_SELL_CHECKS_TAXES = "GET_SELL_CHECKS_TAXES";
export const GET_SELL_CHECKS_TAXES_SUCCESS = "GET_SELL_CHECKS_TAXES_SUCCESS";
export const GET_SELL_CHECKS_TAXES_ERROR = "GET_SELL_CHECKS_TAXES_ERROR";
