import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
	return {
		expensesInfo: {
			status: null,
			data: {},
			error: null,
		},
	};
}
const actions = {
	[constants.GET_EXPENSES]: async ({ commit }, payload) => {
		try {
			commit(constants.GET_EXPENSES);
			const response = await axios.get("addon/getSummary", {
				params: payload,
			});
			commit(constants.GET_EXPENSES_SUCCESS, response);
			return response;
		} catch (error) {
			commit(constants.GET_EXPENSES_ERROR, error);
			throw error.response;
		}
	},


};

const mutations = {
	[constants.GET_EXPENSES]: (state) => {
		state.expensesInfo.data = {};
		state.expensesInfo.status = "fetching";
	},
	[constants.GET_EXPENSES_SUCCESS]: (state, response) => {
		state.expensesInfo.status = "success";
		state.expensesInfo.data = response;
	},
	[constants.GET_EXPENSES_ERROR]: (state, error) => {
		state.expensesInfo.status = "error";
		state.expensesInfo.error = error;
	},
	setNoNameAddons: (state) => {
		let data = { ...state.expensesInfo.data };
		data.addons.forEach((element) => {
			if (element.addonType == null) {
				element.addonType = {
					name: "No category",
				};
			}
		});
		state.expensesInfo.data = data;
	}





};
export default {
	state: getDefaultState(),
	actions,
	mutations,
};
