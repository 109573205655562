import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    interest: {
      status: null,
      data: {},
      error: null,
    },
    marketplaceInterests: {
      status: null,
      data: [],
      error: null,
    },
    marketplaceCommission: {
      status: null,
      data: {},
      error: null,
    },
    agentsCommission: {
      status: null,
      data: [],
      error: null,
    },
    agentCommission: {
      status: null,
      data: {},
      error: null,
    },
    padCommissions: {
      status: null,
      data: [],
      error: null,
    },
    padCommission: {
      status: null,
      data: {},
      error: null,
    },
  };
}
const actions = {

  [constants.PUT_INTEREST]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_INTEREST);
      const response = await axios.put("commission/saveInterest", payload);
      commit(constants.PUT_INTEREST_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_INTEREST_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_MARKETPLACE_INTEREST]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_MARKETPLACE_INTEREST);
      const response = await axios.get("commission/getMarketPlaceConfiguration", {
        params: payload,
      });
      commit(constants.GET_MARKETPLACE_INTEREST_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_MARKETPLACE_INTEREST_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_MARKETPLACE_COMMISSION]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_MARKETPLACE_COMMISSION);
      const response = await axios.put("commission/saveMarketPlaceConfiguration", payload);
      commit(constants.PUT_MARKETPLACE_COMMISSION_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_MARKETPLACE_COMMISSION_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_AGENT_COMMISSION]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_AGENT_COMMISSION);
      const response = await axios.get("commission/listAgentClient", {
        params: payload,
      });
      commit(constants.GET_AGENT_COMMISSION_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_AGENT_COMMISSION_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_AGENT_COMMISSION]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_AGENT_COMMISSION);
      const response = await axios.post("commission/saveAgentConfiguration", payload);
      commit(constants.PUT_AGENT_COMMISSION_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_AGENT_COMMISSION_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_PAD_COMMISSIONS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_PAD_COMMISSIONS);
      const response = await axios.get("commission/getPadCommission", {
        params: payload,
      });
      commit(constants.GET_PAD_COMMISSIONS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_PAD_COMMISSIONS_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_PAD_COMMISSION]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_PAD_COMMISSION);
      const response = await axios.post("commission/createPadCommission", payload);
      commit(constants.POST_PAD_COMMISSION_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_PAD_COMMISSION_ERROR, error);
      throw error.response;
    }
  },
  [constants.DELETE_PAD_COMMISSION]: async ({ commit }, payload) => {
    try {
      commit(constants.DELETE_PAD_COMMISSION);
      const response = await axios.delete("commission/deletePadCommission", {
        params: payload,
      });
      commit(constants.DELETE_PAD_COMMISSION_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.DELETE_PAD_COMMISSION_ERROR, error);
      throw error.response;
    }
  }
};

const mutations = {

  [constants.PUT_INTEREST]: (state) => {
    state.interest.status = "fetching";
    state.interest.error = null;
  },
  [constants.PUT_INTEREST_SUCCESS]: (state, data) => {
    state.interest.status = "success";
    state.interest.error = null;
    state.interest.data = data;
  },
  [constants.PUT_INTEREST_ERROR]: (state, error) => {
    state.interest.status = "error";
    state.interest.error = error;
  },
  [constants.GET_MARKETPLACE_INTEREST]: (state) => {
    state.marketplaceInterests.status = "fetching";
    state.marketplaceInterests.error = null;
  },
  [constants.GET_MARKETPLACE_INTEREST_SUCCESS]: (state, data) => {
    state.marketplaceInterests.status = "success";
    state.marketplaceInterests.error = null;
    state.marketplaceInterests.data = data;
  },
  [constants.GET_MARKETPLACE_INTEREST_ERROR]: (state, error) => {
    state.marketplaceInterests.status = "error";
    state.marketplaceInterests.error = error;
  },
  [constants.PUT_MARKETPLACE_COMMISSION]: (state) => {
    state.marketplaceCommission.status = "fetching";
    state.marketplaceCommission.error = null;
  },
  [constants.PUT_MARKETPLACE_COMMISSION_SUCCESS]: (state, data) => {
    state.marketplaceCommission.status = "success";
    state.marketplaceCommission.error = null;
    state.marketplaceCommission.data = data;
  },
  [constants.PUT_MARKETPLACE_COMMISSION_ERROR]: (state, error) => {
    state.marketplaceCommission.status = "error";
    state.marketplaceCommission.error = error;
  },
  [constants.GET_AGENT_COMMISSION]: (state) => {
    state.agentsCommission.status = "fetching";
    state.agentsCommission.error = null;
  },
  [constants.GET_AGENT_COMMISSION_SUCCESS]: (state, data) => {
    state.agentsCommission.status = "success";
    state.agentsCommission.error = null;
    state.agentsCommission.data = data;
  },
  [constants.GET_AGENT_COMMISSION_ERROR]: (state, error) => {
    state.agentsCommission.status = "error";
    state.agentsCommission.error = error;
  },
  [constants.PUT_AGENT_COMMISSION]: (state) => {
    state.agentCommission.status = "fetching";
    state.agentCommission.error = null;
  },
  [constants.PUT_AGENT_COMMISSION_SUCCESS]: (state, data) => {
    state.agentCommission.status = "success";
    state.agentCommission.error = null;
    state.agentCommission.data = data;
  },
  [constants.PUT_AGENT_COMMISSION_ERROR]: (state, error) => {
    state.agentCommission.status = "error";
    state.agentCommission.error = error;
  },
  [constants.GET_PAD_COMMISSIONS]: (state) => {
    state.padCommissions.status = "fetching";
    state.padCommissions.error = null;
  },
  [constants.GET_PAD_COMMISSIONS_SUCCESS]: (state, data) => {
    state.padCommissions.status = "success";
    state.padCommissions.error = null;
    state.padCommissions.data = data;
  },
  [constants.GET_PAD_COMMISSIONS_ERROR]: (state, error) => {
    state.padCommissions.status = "error";
    state.padCommissions.error = error;
  },
  [constants.POST_PAD_COMMISSION]: (state) => {
    state.padCommission.status = "fetching";
    state.padCommission.error = null;
  },
  [constants.POST_PAD_COMMISSION_SUCCESS]: (state, data) => {
    state.padCommission.status = "success";
    state.padCommission.error = null;
    state.padCommission.data = data;
  },
  [constants.POST_PAD_COMMISSION_ERROR]: (state, error) => {
    state.padCommission.status = "error";
    state.padCommission.error = error;
  },
  [constants.DELETE_PAD_COMMISSION]: (state) => {
    state.padCommission.status = "fetching";
    state.padCommission.error = null;
  },
  [constants.DELETE_PAD_COMMISSION_SUCCESS]: (state, data) => {
    state.padCommission.status = "success";
    state.padCommission.error = null;
    state.padCommission.data = data;
  },
  [constants.DELETE_PAD_COMMISSION_ERROR]: (state, error) => {
    state.padCommission.status = "error";
    state.padCommission.error = error;
  }
};
export default {
  state: getDefaultState(),
  actions,
  mutations,
};
