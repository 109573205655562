import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    users: {
      status: null,
      data: null,
      error: null,
    },
    assignCompany: {
      status: null,
      error: null,
    },
    companiesToAssign: {
      status: null,
      data: [],
      error: null,
    },
  };
}

const actions = {
  [constants.GET_USER_TO_ASSIGN_COMPANY]: async ({ commit }, params) => {
    try {
      commit(constants.GET_USER_TO_ASSIGN_COMPANY);

      const response = await axios.get(`company/listUserWithoutCompany`, {
        params: { ...params },
      });
      commit(constants.GET_USER_TO_ASSIGN_COMPANY_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_USER_TO_ASSIGN_COMPANY_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_ASSIGN_COMPANY]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_ASSIGN_COMPANY);
      const response = await axios.put(`company/assignCompany`, payload);
      commit(constants.PUT_ASSIGN_COMPANY_SUCCESS);
      return response;
    } catch (error) {
      commit(constants.PUT_ASSIGN_COMPANY_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_COMPANIES_LIST]: async ({ commit }, params) => {
    try {
      commit(constants.GET_COMPANIES_LIST);
      const response = await axios.get(`company/listCompany`, { params: { ...params } });
      commit(constants.GET_COMPANIES_LIST_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_COMPANIES_LIST_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_USER_TO_ASSIGN_COMPANY]: (state) => {
    state.users.status = "fetching";
    state.users.error = null;
  },
  [constants.GET_USER_TO_ASSIGN_COMPANY_SUCCESS]: (state, data) => {
    state.users.status = "success";
    state.users.error = null;
    state.users.data = data;
  },
  [constants.GET_USER_TO_ASSIGN_COMPANY_ERROR]: (state, error) => {
    state.users.status = "error";
    state.users.error = error;
  },
  [constants.PUT_ASSIGN_COMPANY]: (state) => {
    state.assignCompany.status = "fetching";
    state.assignCompany.error = null;
  },
  [constants.PUT_ASSIGN_COMPANY_SUCCESS]: (state) => {
    state.assignCompany.status = "success";
    state.assignCompany.error = null;
  },
  [constants.PUT_ASSIGN_COMPANY_ERROR]: (state, error) => {
    state.assignCompany.status = "error";
    state.assignCompany.error = error;
  },

  [constants.GET_COMPANIES_LIST]: (state) => {
    state.companiesToAssign.status = "fetching";
    state.companiesToAssign.error = null;
  },
  [constants.GET_COMPANIES_LIST_SUCCESS]: (state, data) => {
    state.companiesToAssign.status = "success";
    state.companiesToAssign.error = null;
    state.companiesToAssign.data = data;
  },
  [constants.GET_COMPANIES_LIST_ERROR]: (state, error) => {
    state.companiesToAssign.status = "error";
    state.companiesToAssign.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
