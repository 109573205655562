import Base from "@/modules/auth/Base.vue";
import GeneralInformation from "@/modules/vehicle/components/visual-inspection/components/RegistryCar/GeneralInformation.vue";
import PhotosExterior from "@/modules/vehicle/components/visual-inspection/components/RegistryCar/PhotosExterior.vue";
import SuccessCarUploaded from "@/modules/vehicle/components/visual-inspection/components/RegistryCar/SuccessCarUploaded.vue";
/* import CaptureImage from "@/modules/vehicle/components/visual-inspection/components/automaticInspection/CaptureImage.vue";
 *//* import ListCarsByStatus from "@/modules/vehicle/components/visual-inspection/components/ListCars/ListCarsByStatus.vue";
*/
/* import CarDetails from "@/modules/vehicle/components/visual-inspection/components/ListCars/components/CarDetails/CarDetails.vue";
 */
import { ifRoleAuthenticated } from "@/router/navigationGuards";
import CaptureImageBetterResolution from "./components/automaticInspection/CaptureImageBetterResolution.vue";

const routes = {
  path: "/vin",
  component: Base,
  beforeEnter: (to, from, next) => {
    ifRoleAuthenticated(to, from, next, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_AGENT"]);
  },
  children: [
    {
      path: "create/:vin",
      component: GeneralInformation,
      meta: { name: "General Information" },
      name: "company.cars.general",
    },
    {
      path: "create/:vin/exterior",
      component: PhotosExterior,
      meta: { name: "Photos" },
      name: "company.cars.exterior",
    },
    {
      path: "create/:vin/exterior/cr",
      component: PhotosExterior,
      meta: { name: "Visual Inspection, Condition Report" },
      name: "company.cars.exterior.cr",
    },
    {
      path: "create/:vin/uploaded",
      component: SuccessCarUploaded,
      meta: { layout: "base" },
      name: "company.cars.uploaded",
    },
    {
      path: "create/:vin/automatic-inspection",
      /*   component: CaptureImage, */
      component: CaptureImageBetterResolution,
      meta: { layout: "base" },
      name: "company.cars.automaticInspection",
    },
    {
      path: "create/:vin/automatic-inspection/cr",
      /*   component: CaptureImage, */
      component: CaptureImageBetterResolution,
      meta: { layout: "base" },
      name: "company.cars.automaticInspection.cr",
    },
    {
      path: "create/:vin/automatic-inspection/easy",
      /*  component: CaptureImage, old component*/
      component: CaptureImageBetterResolution,
      meta: { layout: "base" },
      name: "company.cars.automaticInspection.easy",
    },
    {
      path: "create/:vin/automatic-inspection/easy/cr",
      /*  component: CaptureImage, old component*/
      component: CaptureImageBetterResolution,
      meta: { layout: "base" },
      name: "company.cars.automaticInspection.easy.cr",
    },

    {
      path: "create/:vin/automatic-inspection/web-cam",
      component: CaptureImageBetterResolution,
      meta: { layout: "base" },
      name: "company.cars.automaticInspection.web-cam",
    },
    /*   {
      path: "list-cars",
      component: ListCarsByStatus,
      meta: { layout: "card-no-brand" },
      name: "company.cars.list"
    }, */
    /*  {
      path: "car-details/:vin",
      component: CarDetails,
      meta: { layout: "card-no-brand" },
      name: "company.cars.details"
    } */
  ],
};

export default routes;
