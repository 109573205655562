<template>
  <b-modal
    id="files-modal"
    ref="modal"
    centered
    no-close-on-backdrop
    scrollable
    size="lg"
    title="Police Book"
    @hidden="getVehiclesInfo()"
    hide-footer
  >
    <VehicleDetails
      v-if="vehicle"
      :vehicle="vehicle"
      :color="vehicle.vehicleInformation.color"
    />
    <JacketModal v-if="vehicle" />
    <br />
    <h3>Export</h3>
    <ExportModal v-if="vehicle" />
  </b-modal>
</template>

<script>
import ExportModal from "../exports/ExportModal.vue";
import JacketModal from "../jacket/JacketModal.vue";
import { mapState } from "vuex";
import { superAdminMixin } from "../../mixins";
import VehicleDetails from "@/modules/superAdmin/components/VehicleDetails.vue";

export default {
  name: "FilesModal",
  mixins: [superAdminMixin],
  components: {
    ExportModal,
    JacketModal,
    VehicleDetails,
  },
  computed: {
    ...mapState({
      vehicle: (state) => state.superAdminStore.vehicle || false,
    }),
  },
};
</script>

<style></style>
