<template>
  <FilterTypeDate fromType="woFrom" toType="woTo" />
</template>

<script>
import FilterTypeDate from "./FilterTypeDate.vue";
export default {
  name: "WorkOrderFilter",
  components: { FilterTypeDate },
};
</script>

<style>
</style>