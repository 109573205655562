<template>
  <div>
    <div class="container-fluid">
      <div class="accordion" role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-header="'accordion-trims'"
              class="text-left"
              variant="dark-green"
            >
              Trims
            </b-button>
          </b-card-header>
          <b-collapse
            visible
            id="accordion-trims"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card class="shadow mb-3 p-0" body-class="p-0">
              <CarsTable
                v-if="registryCar.vinNumber"
                :refresh-data="formatData"
                :vinNumber="registryCar.vinNumber ? registryCar.vinNumber : ''"
              />
            </b-card>
          </b-collapse>
        </b-card>
      </div>
    </div>
    <div class="container-fluid">
      <div class="accordion" role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-header="'accordion-general'"
              class="text-left"
              variant="dark-green"
            >
              General information
            </b-button>
          </b-card-header>
          <b-collapse
            id="accordion-general"
            accordion="my-accordion"
            role="tabpanel"
          >
            <VinData
              v-if="registryCar.id"
              :key="componentKey"
              :car-prop="registryCar"
              :reserved-price-prop="reservedPrice"
              :disabled="false"
              @isInvalid="(val) => (vinDataInvalid = val)"
              @update="(c) => (registryCar = c)"
            />
          </b-collapse>
        </b-card>
      </div>
    </div>

    <form ref="form" class="container-fluid" @submit.stop.prevent="nextPage()">
      <div class="accordion" role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-header="'accordion-tires'"
              class="text-left"
              variant="dark-green"
            >
              Tires
            </b-button>
          </b-card-header>
          <b-collapse
            id="accordion-tires"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card class="shadow mb-3">
              <TiresTable :tires="tires" :vehicles-id="carId" />
            </b-card>
          </b-collapse>
        </b-card>
      </div>

      <div class="accordion" role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-header="'accordion-options'"
              class="text-left"
              variant="dark-green"
            >
              Options
            </b-button>
          </b-card-header>
          <b-collapse
            id="accordion-options"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card class="shadow">
              <div class="box-info">
                <b-card-text>
                  <b-row class="main-cont">
                    <b-col
                      v-for="(check, index) in allCheckboxes"
                      :key="index"
                      sm="12"
                      md="6"
                      lg="6"
                      xl="6"
                    >
                      <b-form-checkbox
                        :disabled="!userHasPermission('vi', 'write')"
                        v-model="check.isChecked"
                        :name="check.name"
                      >
                        {{ check.text }}
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-card-text>
              </div>
            </b-card>
          </b-collapse>
        </b-card>
      </div>

      <div class="mt-3 d-flex justify-content-end">
        <b-button
          size="lg"
          type="submit"
          variant="primary"
          :disabled="vinDataInvalid"
        >
          Next
          <b-icon icon="chevron-compact-right" scale="1" />
        </b-button>
      </div>
      <br />
    </form>
  </div>
</template>

<script>
import TiresTable from "./TiresTable";
import { generalMixin } from "@/modules/mixin.js";
import VinData from "@/modules/vehicle/components/visual-inspection/components/RegistryCar/VinData.vue";
import { companyCarsMixin } from "@/modules/vehicle/components/visual-inspection/mixin";
import CarsTable from "@/modules/vehicle/components/CarsTable";
import { superAdminMixin } from "../../../../../superAdmin/mixins";
export default {
  components: {
    VinData,
    TiresTable,
    CarsTable,
  },
  mixins: [companyCarsMixin, generalMixin, superAdminMixin],
  data() {
    return {
      componentKey: "update",
      colorOptions: [
        { value: null, text: "Color", disabled: true },
        { value: "Black", text: "Black" },
        { value: "White", text: "White" },
        { value: "Red", text: "Red" },
        { value: "Orange", text: "Gray" },
        { value: "Pink", text: "Pink" },
        { value: "Brown", text: "Brown" },
        { value: "Yellow", text: "Yellow" },
        { value: "Blue", text: "Blue" },
      ],
      tires: [],
      typeOptions: [
        { value: "Manual", text: "Manual" },
        { value: "Automatic", text: "Automatic" },
      ],
      allCheckboxes: [
        {
          text: "Air conditioning",
          name: "airConditioning",
          isChecked: false,
        },
        { text: "Memory seats", name: "memorySeats", isChecked: false },
        { text: "Alarm", name: "alarm", isChecked: false },
        {
          text: "Navigation system",
          name: "navigationSystem",
          isChecked: false,
        },
        { text: "Alloys wheels", name: "alloyWheels", isChecked: false },
        { text: "Power locks", name: "powerLocks", isChecked: false },
        { text: "Bluetooth", name: "bluetooth", isChecked: false },
        {
          text: "Power Mirrors",
          name: "powerMirrors",
          isChecked: false,
        },
        {
          text: "Dual climate control",
          name: "dualClimateControl",
          isChecked: false,
        },
        { text: "Power Seats", name: "powerSeats", isChecked: false },
        {
          text: "Entertainment package",
          name: "entertainmentPackage",
          isChecked: false,
        },
        {
          text: "Power windows",
          name: "powerWindows",
          isChecked: false,
        },
        { text: "Fog lights", name: "fogLights", isChecked: false },
        {
          text: "Stability control",
          name: "stabilityControl",
          isChecked: false,
        },
        {
          text: "Heated mirrors",
          name: "heatedMirrors",
          isChecked: false,
        },
        { text: "Sunroof", name: "sunroof", isChecked: false },
        { text: "Heated seats", name: "heatedSeats", isChecked: false },
        { text: "Tow package", name: "towPackage", isChecked: false },
        {
          text: "Keyles entry",
          name: "keylessEntry",
          isChecked: false,
        },
        {
          text: "Xenon headlights",
          name: "xenonHeadlights",
          isChecked: false,
        },
        {
          text: "Leather seats",
          name: "leatherSeats",
          isChecked: false,
        },
      ],
      isLoading: false,
    };
  },
  validations: {
    vehicleInformation: {},
  },
  mounted() {
    this.$store.commit("visualInspection/resetStore");
    this.formatData();
    this.getColors();
  },
  methods: {
    setValue(key, value) {
      this.vehicleInformation[key] = value;
      this.$v.vehicleInformation[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.vehicleInformation[name];
      return $dirty ? !$error : null;
    },
    nextPage() {
      this.setOptinosToModel();
      if (this.userHasPermission("vi", "write")) {
        this.uploadCompanyCars();
      } else {
        this.setInformationToStore();
        this.$router.push({
          name: "company.cars.exterior",
          params: { vin: this.$route.params.vin },
        });
      }
    },
    setModelToOptions() {
      this.allCheckboxes.forEach((check) => {
        if (this.vehicleInformation[check.name]) {
          check.isChecked = true;
        }
      });
    },
    setOptinosToModel() {
      this.allCheckboxes.forEach((check) => {
        this.vehicleInformation[check.name] = check.isChecked;
      });
    },
    async uploadCompanyCars() {
      this.$store.commit("visualInspection/setVinData", {
        ...this.registryCar,
      });
      this.$store.commit("visualInspection/setVehicleInformation", {
        ...this.vehicleInformation,
        color: this.registryCar.color,
        kms: this.registryCar.kms,
        dateMFG: this.registryCar.dateMFG,
        gvw: this.registryCar.gvw,
      });

      this.updateCar(
        () => {
          this.$router.push({
            name: "company.cars.exterior",
            params: { vin: this.$route.params.vin },
          });
        },
        (error) => {
          this.makeToast("Error", error.response.data.message, "danger");
        }
      );
    },
    formatData() {
      this.getVin(this.$route.params.vin, (data) => {
        this.carId = data.id;
        if (data.vinNumber) {
          this.registryCar = data.vinNumber;
        }
        if (data.vehicleInformation) {
          this.registryCar.color = data.vehicleInformation.color;
          this.registryCar.kms = data.vehicleInformation.kms;
          this.registryCar.miles = data.vehicleInformation.miles;
          this.registryCar.stockNumber = data.stockNumber;
          this.registryCar.agent = data.user
            ? data?.user?.firstName + " " + data?.user?.lastName
            : "";
          this.vehicleInformation = data.vehicleInformation;
        }
        if (data.vehicleCostDetail) {
          this.registryCar.cost = data.vehicleCostDetail.cost;
          this.reservedPrice = data.vehicleCostDetail.reservedPrice;
        }
        if (data.vehicleHasTires) {
          this.tires = data.vehicleHasTires;
        }
        this.setModelToOptions();
        this.componentKey += 1;
      });
    },
    getColors() {
      this.$store
        .dispatch("GET_MAKES_AND_MODELS")
        .then(() => {})
        .catch(() => {
          this.toastMessage("Colors could not be obtain", "Warning", "warning");
        });
    },
    setInformationToStore() {
      this.$store.commit("visualInspection/setVinData", {
        ...this.registryCar,
      });
      this.$store.commit("visualInspection/setVehicleInformation", {
        ...this.vehicleInformation,
        color: this.registryCar.color,
        kms: this.registryCar.kms,
        dateMFG: this.registryCar.dateMFG,
        gvw: this.registryCar.gvw,
      });
    },
  },
};
</script>

<style scoped>
@media screen and (min-width: 1000px) {
  .main-cont {
    padding-left: 30%;
    padding-right: 30%;
  }
}
</style>
