import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    companies: {
      status: null,
      data: [],
      error: null,
    },
    company: {
      status: null,
      data: {},
      error: null,
    },
  }
}

const actions = {
  [constants.GET_RI_LIST]: async ({ commit }) => {
    try {
      commit(constants.GET_RI_LIST);
      const response = await axios.get("ri/getRi");
      commit(constants.GET_RI_LIST_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_RI_LIST_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_RI_NO_PAGINATE]: async ({ commit }) => {
    try {
      commit(constants.GET_RI_NO_PAGINATE);
      const response = await axios.get("ri/listRi");
      commit(constants.GET_RI_NO_PAGINATE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_RI_NO_PAGINATE_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_RI]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_RI);
      const response = await axios.post("ri/createAndUpdateRi", payload);
      commit(constants.POST_RI_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_RI_ERROR, error);
      throw error.response;
    }
  }

};

const mutations = {
  [constants.GET_RI_LIST]: (state) => {
    state.companies.status = "fetching";
    state.companies.error = null;
  },
  [constants.GET_RI_LIST_SUCCESS]: (state, data) => {
    state.companies.status = "success";
    state.companies.error = null;
    state.companies.data = data;
  },
  [constants.GET_RI_LIST_ERROR]: (state, error) => {
    state.companies.status = "error";
    state.companies.error = error;
  },
  [constants.POST_RI]: (state) => {
    state.company.status = "fetching"
    state.company.error = null
  },
  [constants.POST_RI_SUCCESS]: (state, data) => {
    state.company.status = "success"
    state.company.error = null
    state.company.data = data
  },
  [constants.POST_RI_ERROR]: (state, error) => {
    state.company.status = "error"
    state.company.error = error
  },

  [constants.GET_RI_NO_PAGINATE]: (state) => {
    state.companies.status = "fetching";
    state.companies.error = null;
  },
  [constants.GET_RI_NO_PAGINATE_SUCCESS]: (state, data) => {
    state.companies.status = "success";
    state.companies.error = null;
    state.companies.data = data;
  },
  [constants.GET_RI_NO_PAGINATE_ERROR]: (state, error) => {
    state.companies.status = "error";
    state.companies.error = error;

  }

};


export default {
  state: getDefaultState(),
  actions,
  mutations,
};