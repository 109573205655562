import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    vehicles: {
      status: null,
      data: [],
      error: null,
    },

    bulkCluster: {
      status: null,
      data: {},
      error: null,
    }

  };
}
const actions = {
  [constants.GET_VEHICLES_BULK_CLUSTER]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_VEHICLES_BULK_CLUSTER);
      const response = await axios.get("cluster/listVehicle", {
        params: payload,
      });
      commit(constants.GET_VEHICLES_BULK_CLUSTER_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLES_BULK_CLUSTER_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_BULK_CLUSTER]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_BULK_CLUSTER);
      const response = await axios.post("cluster/bulkRegister", payload);
      commit(constants.POST_BULK_CLUSTER_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_BULK_CLUSTER_ERROR, error);
      throw error.response;
    }
  },

};

const mutations = {
  [constants.GET_VEHICLES_BULK_CLUSTER]: (state) => {
    state.vehicles.data = [];
    state.vehicles.status = "fetching";
  },
  [constants.GET_VEHICLES_BULK_CLUSTER_SUCCESS]: (state, response) => {
    state.vehicles.status = "success";
    state.vehicles.data = response.vehicles;
  },
  [constants.GET_VEHICLES_BULK_CLUSTER_ERROR]: (state, error) => {
    state.vehicles.status = "error";
    state.vehicles.error = error;
  },
  [constants.POST_BULK_CLUSTER]: (state) => {
    state.bulkCluster.data = {};
    state.bulkCluster.status = "fetching";
  },
  [constants.POST_BULK_CLUSTER_SUCCESS]: (state, response) => {
    state.bulkCluster.status = "success";
    state.bulkCluster.data = response;
  },
  [constants.POST_BULK_CLUSTER_ERROR]: (state, error) => {
    state.bulkCluster.status = "error";
    state.bulkCluster.error = error;
  },


};
export default {
  state: getDefaultState(),
  actions,
  mutations,
};
