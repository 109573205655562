<template>
  <div>
    <b-row>
      <b-col cols="8">
        <card>
          <b-row>
            <b-col></b-col>
            <b-col></b-col>
            <b-col></b-col>
            <b-col></b-col> <b-col></b-col>
            <b-col> </b-col>
            <b-col align-self="end">
              <b-button variant="success" class="mb-3 float-" align-self="end">
                <b-icon icon="plus" class="mt-1" />
              </b-button>
            </b-col>
          </b-row>

          <!--           <ExpensesAddonsList :addons="expensesInfo.data.addons" />
 -->

          <div>
            <b-tabs content-class="mt-3">
              <b-tab title="ALL" active> <VehicleExpensesTable /> </b-tab>

              <b-tab title="USD"> <VehicleExpensesTable /> </b-tab>
              <b-tab title="CAD"> <VehicleExpensesTable /> </b-tab>
            </b-tabs>
          </div>
        </card>
      </b-col>
      <b-col cols="4">
        <card>
          <ExpensesSummary :expensesInformation="expensesInfo.data" />
          <div class="text-right mt-3">
            <b-button @click="toggleShowChart()" variant="success">
              <b-icon
                icon="pie-chart
"
              ></b-icon>
            </b-button>
          </div>
          <Pie
            v-if="showChart"
            :chart-options="chartOptions"
            :chart-data="chartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :styles="styles"
          />
        </card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapState } from "vuex";

/* import ExpensesAddonsList from "@/modules/superAdmin/components/expenses/ExpensesAddonsList.vue";
 */ import ExpensesSummary from "@/modules/superAdmin/components/expenses/ExpensesSummary.vue";
import VehicleExpensesTable from "./VehicleExpensesTable.vue";
import { Pie } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  components: {
    /* ExpensesAddonsList */ ExpensesSummary,
    Pie,
    VehicleExpensesTable,
  },
  computed: {
    ...mapState({
      vehicle: (state) => state.superAdminStore.vehicle,
      expensesInfo: (state) => state.superAdminStore.componentStore.expenses.expensesInfo,
    }),
  },
  name: "VehicleResume",
  data() {
    return {
      chartData: {
        labels: ["Carwash", "Cluster", "Work order"],
        datasets: [
          {
            backgroundColor: ["#41B883", "#E46651", "#00D8FF"],
            data: [1050, 10, 100.1],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      showChart: false,
    };
  },
  mounted() {
    this.getExpensesInformation();
  },
  methods: {
    getExpensesInformation() {
      this.$store.dispatch("GET_EXPENSES", { vehicleId: 20496 }).then(() => {});
    },
    toggleShowChart() {
      this.showChart = !this.showChart;
    },
  },
};
</script>
<style></style>
