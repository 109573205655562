<template>
  <div>
    <b-dropdown v-if="modelValue" variant="white" class="" menu-class="w-100">
      <template slot="button-content">
        <div v-if="modelValue" class="float-left navbar-brand-img">
          <img
            v-if="modelValue.logo"
            :src="modelValue.logo"
            height="30"
            width=""
            style="max-width: 100px"
          />
        </div>
      </template>

      <b-dropdown-item
        v-for="(option, key) in profile.user.corporationPermissions"
        :key="key"
        @click="onCorporationSelect(option)"
      >
        <img
          :src="option.logo"
          class="navbar-brand-img"
          height="30"
          width=""
          style="max-width: 130px"
        />
        <span class="text-wrap">
          {{ option.companyName }}
        </span>
      </b-dropdown-item>
    </b-dropdown>

    <img
      v-else
      :src="'img/QAS.png'"
      class="navbar-brand-img"
      alt="Sidebar logo"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "MarketplaceSelect",
  data() {
    return {
      modelValue: null,
      favoriteBoard: null,
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.auth.loginStore.profile.data,
    }),
  },
  mounted() {
    const corporation = localStorage.getItem("corporation");
    if (corporation) {
      this.modelValue = this.profile.user.corporationPermissions.find(
        (c) => c.id == corporation
      );
    } else {
      this.modelValue = null;
    }
  },
  methods: {
    onCorporationSelect(corporation) {
      this.modelValue = corporation;
      /* reload page */
      this.selectCorporation(corporation);
      this.getFavoriteBoardFromCookie();
      /* reload page */
      window.location.reload();
      /*   if (
        this.$route.name === this.favoriteBoard ||
        this.favoriteBoard === null
      ) {
        this.$router.go();
      } else {
        this.$router.push({ name: this.favoriteBoard });
      } */
    },
    selectCorporation(corporation) {
      localStorage.setItem("corporation", corporation.id);
      this.$store.commit("SET_CORPORATION", corporation);
    },
    getFavoriteBoardFromCookie() {
      const favoriteBoards = this.$cookies.get("favoriteBoard");
      if (favoriteBoards) {
        this.favoriteBoard = favoriteBoards;
      }
    },
  },
};
</script>

<style></style>
