import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    vehicles: {
      status: null,
      data: [],
      error: null,
    },
    creditRequestList: {
      status: null,
      data: [],
      error: null,
    },
    creditRequest: {
      status: null,
      data: {},
      error: null,
    },
    creditRequestExcel: {
      status: null,
      data: {},
      error: null,
    },
  };
}
const actions = {
  [constants.GET_VEHICLES_CREDIT_REQUEST]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_VEHICLES_CREDIT_REQUEST);

      const response = await axios.get("creditRequest/listVehicles",
        {
          params: payload,
        });
      commit(constants.GET_VEHICLES_CREDIT_REQUEST_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLES_CREDIT_REQUEST_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_CREDIT_REQUEST]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_CREDIT_REQUEST);
      const response = await axios.post(
        "creditRequest/createAndUpdate",
        payload
      );
      commit(constants.POST_CREDIT_REQUEST_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_CREDIT_REQUEST_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_CREDIT_REQUEST_LIST]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_CREDIT_REQUEST_LIST);
      const response = await axios.get("creditRequest/getCreditRequest", {
        params: payload,
      });
      commit(constants.GET_CREDIT_REQUEST_LIST_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_CREDIT_REQUEST_LIST_ERROR, error);
      throw error.response;
    }
  },

  [constants.GET_CREDIT_REQUEST_EXCEL]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_CREDIT_REQUEST_EXCEL);
      const response = await axios.get("creditRequest/generateXls", {
        params: payload,
        responseType: "blob",
      });
      commit(constants.GET_CREDIT_REQUEST_EXCEL_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_CREDIT_REQUEST_EXCEL_ERROR, error);
      throw error.response;
    }
  },
  [constants.DELETE_CREDIT_REQUEST]: async ({ commit }, payload) => {
    try {
      commit(constants.DELETE_CREDIT_REQUEST);
      const response = await axios.delete(`creditRequest/delete/${payload}`);
      commit(constants.DELETE_CREDIT_REQUEST_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.DELETE_CREDIT_REQUEST_ERROR, error);
      throw error.response;
    }
  }
};

const mutations = {
  [constants.GET_VEHICLES_CREDIT_REQUEST]: (state) => {
    state.vehicles.data = [];
    state.vehicles.status = "fetching";
  },
  [constants.GET_VEHICLES_CREDIT_REQUEST_SUCCESS]: (state, response) => {
    state.vehicles.status = "success";
    state.vehicles.data = response;
  },
  [constants.GET_VEHICLES_CREDIT_REQUEST_ERROR]: (state, error) => {
    state.vehicles.status = "error";
    state.vehicles.error = error;
  },
  [constants.POST_CREDIT_REQUEST]: (state) => {
    state.creditRequest.data = {};
    state.creditRequest.status = "fetching";
  },
  [constants.POST_CREDIT_REQUEST_SUCCESS]: (state, response) => {
    state.creditRequest.status = "success";
    state.creditRequest.data = response;
  },
  [constants.POST_CREDIT_REQUEST_ERROR]: (state, error) => {
    state.creditRequest.status = "error";
    state.creditRequest.error = error;
  },
  [constants.GET_CREDIT_REQUEST_LIST]: (state) => {
    state.creditRequestList.data = [];
    state.creditRequestList.status = "fetching";
  },
  [constants.GET_CREDIT_REQUEST_LIST_SUCCESS]: (state, response) => {
    state.creditRequestList.status = "success";
    state.creditRequestList.data = response;
  },
  [constants.GET_CREDIT_REQUEST_LIST_ERROR]: (state, error) => {
    state.creditRequestList.status = "error";
    state.creditRequestList.error = error;
  },

  [constants.PUT_CREDIT_REQUEST]: (state) => {
    state.creditRequest.data = {};
    state.creditRequest.status = "fetching";
  },
  [constants.PUT_CREDIT_REQUEST_SUCCESS]: (state, response) => {
    state.creditRequest.status = "success";
    state.creditRequest.data = response;
  },
  [constants.PUT_CREDIT_REQUEST_ERROR]: (state, error) => {
    state.creditRequest.status = "error";
    state.creditRequest.error = error;
  },
  [constants.GET_CREDIT_REQUEST_EXCEL]: (state) => {
    state.creditRequestExcel.data = {};
    state.creditRequestExcel.status = "fetching";
  },
  [constants.GET_CREDIT_REQUEST_EXCEL_SUCCESS]: (state, response) => {
    state.creditRequestExcel.status = "success";
    state.creditRequestExcel.data = response;
  },
  [constants.GET_CREDIT_REQUEST_EXCEL_ERROR]: (state, error) => {
    state.creditRequestExcel.status = "error";
    state.creditRequestExcel.error = error;
  },
  [constants.DELETE_CREDIT_REQUEST]: (state) => {
    state.creditRequest.data = {};
    state.creditRequest.status = "fetching";
  },
  [constants.DELETE_CREDIT_REQUEST_SUCCESS]: (state, response) => {
    state.creditRequest.status = "success";
    state.creditRequest.data = response;
  },
  [constants.DELETE_CREDIT_REQUEST_ERROR]: (state, error) => {
    state.creditRequest.status = "error";
    state.creditRequest.error = error;
  },
};
export default {
  state: getDefaultState(),
  actions,
  mutations,
};
