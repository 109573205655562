import * as constants from './actions';
import axios from '@/plugins/axios.js';

function getDefaultState() {
  return {
    user: {
      status: null,
      data: null,
      error: null
    },

    emailValidation: {
      status: null,
      data: null,
      error: null,
      message: ''
    }
  };
}

const actions = {
  [constants.POST_USER]: async ({ commit }, params) => {
    try {
      commit(constants.POST_USER);
      const response = await axios.post('user/create', params);
      commit(constants.POST_USER_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_USER_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_USER_CLIENT]: async ({ commit }, params) => {
    try {
      commit(constants.GET_USER_CLIENT);
      const response = await axios.get('user', { params: { ...params } });
      commit(constants.GET_USER_CLIENT_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_USER_CLIENT_ERROR, error);
      throw error.response;
    }
  },

  [constants.POST_USER_COMPANY]: async ({ commit }, params) => {
    try {
      commit(constants.POST_USER_COMPANY);
      const response = await axios.put('company', params);
      commit(constants.POST_USER_COMPANY_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_USER_COMPANY_ERROR, error);
      throw error.response;
    }
  },

  [constants.POST_EMAIL_VALIDATION]: async ({ commit }, params) => {
    try {
      commit(constants.POST_EMAIL_VALIDATION);
      const response = await axios.post('user/validate-repeat-email ', params);
      commit(constants.POST_EMAIL_VALIDATION_SUCCESS, response);
      return response;
    } catch (error) {
      if (error.response.status == 400) {
        commit(constants.POST_EMAIL_VALIDATION_SUCCESS, error.response.data);
        return;
      }
      commit(constants.POST_EMAIL_VALIDATION_ERROR, error);
      throw error.response;
    }
  }
};

const mutations = {
  [constants.POST_USER]: (state) => {
    state.user.status = 'fetching';
    state.user.error = null;
  },
  [constants.POST_USER_SUCCESS]: (state, data) => {
    state.user.status = 'success';
    state.user.error = null;
    state.user.data = data;
  },
  [constants.POST_USER_ERROR]: (state, error) => {
    state.user.status = 'error';
    state.user.error = error;
  },

  [constants.POST_USER_COMPANY]: (state) => {
    state.user.status = 'fetching';
    state.user.error = null;
  },
  [constants.POST_USER_COMPANY_SUCCESS]: (state, data) => {
    state.user.status = 'success';
    state.user.error = null;
    state.user.data = data;
  },
  [constants.POST_USER_COMPANY_ERROR]: (state, error) => {
    state.user.status = 'error';
    state.user.error = error;
  },

  [constants.GET_USER_CLIENT]: (state) => {
    state.user.status = 'fetching';
    state.user.error = null;
  },
  [constants.GET_USER_CLIENT_SUCCESS]: (state, data) => {
    state.user.status = 'success';
    state.user.error = null;
    state.user.data = data;
  },
  [constants.GET_USER_CLIENT_ERROR]: (state, error) => {
    state.user.status = 'error';
    state.user.error = error;
  },

  [constants.POST_EMAIL_VALIDATION]: (state) => {
    state.emailValidation.status = 'fetching';
    state.emailValidation.error = null;
  },
  [constants.POST_EMAIL_VALIDATION_SUCCESS]: (state, data) => {
    state.emailValidation.status = 'success';
    state.emailValidation.error = null;
    state.emailValidation.data = data;
  },
  [constants.POST_EMAIL_VALIDATION_ERROR]: (state, error) => {
    state.emailValidation.status = 'error';
    state.emailValidation.error = error;
  }
};

export default {
  state: getDefaultState(),
  actions,
  mutations
};
