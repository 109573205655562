function getDefaultState() {
  return {
    endPointUrl: "",
    modalId: "",
    usdPrice: null,
    preferredCurrency: null,
  };
}

const mutations = {
  setEndPointUrl: (state, url) => {
    state.endPointUrl = url;
  },
  setModalId: (state, modalId) => {
    state.modalId = modalId;
  },
  setUsdPrice: (state, price) => {
    state.usdPrice = price;
  },
  setPreferredCurrency: (state, value) => {
    state.preferredCurrency = value;
  },
};

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations,
};
