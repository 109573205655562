<template>
  <div class="container">
    <b-col cols="12">
      <h2>
        <b-icon
          class="mr-1 cursor-pointer"
          icon="arrow-left-circle"
          variant="yellow"
          scale="0.8"
          @click="backToCalculator()"
        />Pricing
      </h2>
    </b-col>
    <b-col cols="12">
      <div class="text-center" v-if="trims.length == 0">
        <h5>NOT FOUND VEHICLES</h5>
      </div>
      <b-list-group v-else class="mt-2 mb-2 note-list" tag="ul">
        <b-list-group-item
          v-for="(trim, i) in trims.data"
          :key="i"
          class="flex-column align-items-start"
          tag="li"
        >
          <TrimCard :trim="trim" />
        </b-list-group-item>
      </b-list-group>
    </b-col>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TrimCard from "./TrimCard.vue";
export default {
  name: "VinList",
  components: { TrimCard },
  computed: {
    ...mapState({
      trims: (state) => state.pricing.trims.data,
    }),
  },
  methods: {
    backToCalculator() {
      this.$emit("backToCalculator", true);
    },
  },
};
</script>

<style>
</style>