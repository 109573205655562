<template>
  <b-modal
    :id="'email-body-modal' + id"
    :ref="'email-body-modal' + id"
    modal-class="modal-fullscreen"
    centered
    no-close-on-backdrop
    scrollable
    hide-footer
    title="Email"
    size="lg"
  >
    <slot name="top"></slot>
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12" class="mb-2">
        <b-form-group label="Email body">
          <b-form-textarea v-model="body" class="resize-none" />
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12" class="mb-2">
        <div class="text-right">
          <b-button :disabled="isLoading" variant="success" @click="onSubmit()">
            <b-spinner v-if="isLoading" variant="white"></b-spinner>
            <span v-else>Send</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      body: null,
    };
  },
  methods: {
    onSubmit() {
      this.$emit("submit", this.body);
    },
  },
  mounted() {},
};
</script>

<style>
</style>