<template>
  <b-row v-if="vehicle">
    <b-col md="8" sm="12">
      <b-card no-body class="overflow-hidden">
        <b-row no-gutters>
          <b-col md="4" sm="12">
            <lingallery
              v-if="images.length > 0 && !imagesIsLoading"
              :id.sync="currentId"
              :width="600"
              :height="height"
              :items="images"
              :addons="{ enableLargeView: true }"
            />
          </b-col>
          <b-col md="8" sm="12">
            <b-card-body class="pt-0 text-wrap">
              <div class="text-justify">
                <h5 class="h2 mt-1 justify-content-between">
                  <div class="justify-content-between">
                    <span>{{
                      vehicle.vinNumber ? vehicle.vinNumber.model : "----"
                    }}</span>
                    <span class="float-right">{{
                      vehicle.vinNumber ? vehicle.vinNumber.vinNumber : "----"
                    }}</span>
                  </div>
                </h5>
                <b-row>
                  <b-col>
                    <div class="h4 mt-3">
                      Make:
                      <small>{{
                        vehicle.vinNumber ? vehicle.vinNumber.make : "----"
                      }}</small>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="h4 mt-3">
                      Model:
                      <small>{{
                        vehicle.vinNumber ? vehicle.vinNumber.model : "----"
                      }}</small>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="h4 mt-3">
                      Trim:
                      <small>{{
                        vehicle.vinNumber ? vehicle.vinNumber.trim : "----"
                      }}</small>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="h4 mt-3">
                      Year:
                      <small>{{
                        vehicle.vinNumber ? vehicle.vinNumber.year : "----"
                      }}</small>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="h4 mt-3">
                      Color:
                      <small>
                        {{
                          vehicle.vehicleInformation
                            ? vehicle.vehicleInformation.color
                            : "----"
                        }}</small
                      >
                    </div>
                  </b-col>
                  <b-col>
                    <div class="h4 mt-3">
                      Kilometer:
                      <small>{{
                        vehicle.vehicleInformation.kms
                          ? vehicle.vehicleInformation.kms
                          : "----"
                      }}</small>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="h4 mt-3">
                      Milles:
                      <small>{{
                        vehicle.vehicleInformation.miles
                          ? vehicle.vehicleInformation.miles
                          : "----"
                      }}</small>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="h4 mt-3">
                      Engine:
                      <small
                        >{{
                          vehicle.vinNumber && vehicle.vinNumber.engine
                            ? vehicle.vinNumber.engine
                            : "----"
                        }}
                      </small>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="h4 mt-3">
                      Stock: <small>{{ vehicle.stockNumber }} </small>
                    </div>
                  </b-col>
                </b-row>
              </div>

              <div class="d-flex justify-content-around mt-3">
                <b-button
                  @click="openVehicleFilesModal()"
                  variant="success"
                  size="md "
                >
                  <b-icon icon="folder"></b-icon> Files
                </b-button>
                <b-button
                  v-if="vehicleFiles.cr"
                  @click="openCRFile(vehicleFiles.cr)"
                  variant="success"
                  size="md "
                >
                  <font-awesome-icon
                    class="cursor-pointer mr-2"
                    :icon="['far', 'file-chart-column']"
                    size="lg"
                  />C.R.
                </b-button>
                <b-button @click="generateLink()" variant="success"
                  >{{ isLinkCopied ? "Link copied!" : "Copy link" }}
                </b-button>
              </div>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </b-col>

    <b-col md="4" sm="12">
      <card v-if="vehicleLocationInfo" class="text-wrap">
        <b-col cols="12">
          <h4>
            Bough from:
            <small>{{
              vehicleLocationInfo.company
                ? vehicleLocationInfo.company.companyName
                : "----"
            }}</small>
          </h4>
          <br />
          <h4>
            Location:
            <small
              >{{
                vehicleLocationInfo.company
                  ? vehicleLocationInfo.company.city
                  : "---"
              }}
              <b-icon icon="geo-alt" scale="1.2" />
            </small>
          </h4>
          <h4>
            Export location:
            <small
              >{{
                vehicleLocationInfo && vehicleLocationInfo.exportLocation
                  ? vehicleLocationInfo.exportLocation.name
                  : "----"
              }}<b-icon
                v-if="vehicleLocationInfo.exportLocation"
                icon="geo-alt"
                scale="1.2"
              />
            </small>
          </h4>
          <h4>
            Last location:
            <small
              >{{
                vehicleLocationInfo && vehicleLocationInfo.lastLocation
                  ? vehicleLocationInfo.lastLocation.location
                  : "----"
              }}
              <b-icon
                v-if="vehicleLocationInfo.lastLocation"
                icon="geo-alt"
                scale="1.2"
              />
            </small>
          </h4>
        </b-col>

        <div class="text-right mt-3">
          <b-button variant="success" @click="goToTimeline"
            >Time table
            <b-icon class="ml-1" icon="clock"></b-icon>
          </b-button>
          <!--           <b-button variant="success" @click="goToSimpleView">SimpleView </b-button>
 -->
        </div>
      </card>
      <b-card v-else>
        <b-skeleton animation="wave" width="85%"></b-skeleton>
        <b-skeleton animation="wave" width="55%"></b-skeleton>
        <b-skeleton animation="wave" width="70%"></b-skeleton>
        <b-skeleton animation="wave" width="85%"></b-skeleton>
        <b-skeleton animation="wave" width="55%"></b-skeleton>
        <b-skeleton animation="wave" width="70%"></b-skeleton>
      </b-card>
    </b-col>
    <VehicleFilesModal :vehicleId="vehicle.id" />
  </b-row>
</template>
<script>
import { vehicleMixin } from "../../../modules/vehicle/mixin";
import CompanyCarsService from "@/services/companyCarsService";
import VehicleFilesModal from "./VehicleFilesModal.vue";
import { mapState } from "vuex";
export default {
  name: "VehicleCard",
  components: {
    VehicleFilesModal,
  },
  mixins: [vehicleMixin],
  computed: {
    ...mapState({
      vehicleFiles: (state) => state.vehicle.vehicleFiles.data,
    }),
  },
  data() {
    return {
      vehicleLocationInfo: null,
      isLinkCopied: false,
      width: "100%",
      height: 160,
      currentId: "someid1",
      imagesIsLoading: false,
      images: [
        {
          id: "someid1",
          src: "https://picsum.photos/600/340/?image=1",
          thumbnail: "https://picsum.photos/600/340/?image=1",
        },
      ],
    };
  },
  mounted() {
    this.getVehicleImages();
    this.getVehicleLocationInfo();
  },
  methods: {
    goToTimeline() {
      this.$router.push({
        name: "superadmin.vehicle.timeline",
        params: { id: this.vehicle.id },
      });
    },
    getVehicleLocationInfo() {
      this.$store
        .dispatch("GET_VEHICLE_LOCATION_INFO", { id: this.vehicle.id })
        .then((res) => {
          this.vehicleLocationInfo = res;
        });
    },
    async getVehicleImages() {
      this.imagesIsLoading = true;
      this.$store.commit("general/setEndPointUrl", "vehicle");
      const { data } = await CompanyCarsService.getVinByNumber(
        this.vehicle.stockNumber
      );
      this.images = this.formatImages(data.vehicleHasParts);
      this.imagesIsLoading = false;
    },
    generateLink() {
      this.isLinkCopied = true;
      const link = `${window.location.origin}/#/public/vehicle/${this.vehicleLocationInfo.id}`;
      navigator.clipboard.writeText(link);
    },
    formatImages(images) {
      return images.map((image) => {
        return {
          id: image.photo,
          src: image.photo,
          thumbnail: image.photoThumbnail || image.photo,
          largeViewSrc: image.photo,
        };
      });
    },
    goToSimpleView() {
      this.$router.push({
        name: "superadmin.vehicles.simple-view",
      });
    },
    openCRFile(file) {
      window.open(file, "_blank");
    },
    openVehicleFilesModal() {
      this.$bvModal.show("vehicle-files-modal");
    },
  },
};
</script>
<style></style>
