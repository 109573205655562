<template>
  <b-form-group class="mb-2" label-class="pb-1">
    <template slot="label"> <b>Vehicle: </b> </template>
    <vue-single-select
      :classes="{
        input: 'form-control',
        dropdown: 'dropdown p-0',
      }"
      v-model="vehicle"
      :options="vehicles"
      :getOptionDescription="
        () =>
          `${vehicle.year} - ${vehicle.make} - ${
            vehicle.model
          } - ${getLastSixCharactersOfVinNumber(vehicle.vinNumber)}`
      "
    >
      <template slot="option" slot-scope="{ option }">
        <div class="">
          <span>
            {{ option.year }} - {{ option.make }} - {{ option.model }} -
            {{ getLastSixCharactersOfVinNumber(option.vinNumber) }}</span
          >
        </div>
      </template>
    </vue-single-select>
  </b-form-group>
</template>

<script>
import { mapState } from "vuex";
import VueSingleSelect from "vue-single-select";
import { SET_TASKS_FILTERS } from "../actions";
export default {
  name: "VehicleFilter",
  components: {
    VueSingleSelect,
  },
  computed: {
    ...mapState({
      vehicles: (state) => state.superAdminStore.tasks.vehicles.data,
    }),
  },
  watch: {
    vehicle() {
      this.setVehicle();
    },
  },
  data() {
    return {
      vehicle: null,
    };
  },
  methods: {
    setVehicle() {
      this.$store.commit(SET_TASKS_FILTERS, {
        fields: {
          vehicleId: this.vehicle?.id,
        },
      });
    },
    getLastSixCharactersOfVinNumber(vinNumber) {
      return vinNumber.slice(-6);
    },
  },
};
</script>

<style lang="sass" scoped>
.col-form-label
  padding-bottom: 0
</style>
