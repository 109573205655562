var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"10"}},[_c('span',[_vm._v(" Registration date: "),_c('i',[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(_vm.transfer.createdAt))+" - "+_vm._s(_vm.transfer.user.firstName)+" "+_vm._s(_vm.transfer.user.lastName))])]),_c('br'),(_vm.transfer.status != 1 && _vm.transfer.updateUser != null)?_c('span',[_vm._v(" Last update date: "),_c('i',[_vm._v(_vm._s(_vm._f("formatDateTime")(_vm.transfer.updatedAt))+" - "+_vm._s(_vm.transfer.updateUser.firstName)+" "+_vm._s(_vm.transfer.updateUser.lastName))])]):_vm._e(),_c('br'),(_vm.transfer.deliveryDate)?_c('span',{staticClass:"d-flex"},[_vm._v(" Estimated delivery date: "),_c('EstimatedArrivalDate',{attrs:{"transfer":_vm.transfer},on:{"onSaveArrivalDate":function($event){return _vm.updateEstimatedArrivalDate($event)}}})],1):_vm._e(),(_vm.transfer.arrivalDate)?_c('span',[_vm._v(" Arrival date: "),_c('i',[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.transfer.arrivalDate))+" ")])]):_vm._e()]),_c('b-col',[_c('div',{staticClass:"text-right"},[(_vm.transfer.status === 1)?_c('span',{staticClass:"dot dot-orange mr-3"}):_vm._e(),(_vm.transfer.status === 2)?_c('span',{staticClass:"dot dot-success mr-3"}):_vm._e(),(_vm.transfer.status === 0)?_c('span',{staticClass:"dot dot-danger mr-3"}):_vm._e(),(_vm.transfer.status === 3)?_c('span',{staticClass:"dot dot-primary mr-3"}):_vm._e(),(_vm.transfer.status === 4)?_c('span',{staticClass:"dot dot-success mr-3"}):_vm._e(),(
            _vm.transfer.status &&
            _vm.transfer.status != 2 &&
            _vm.transfer.status != 4 &&
            _vm.transfer.exportLoadsId == null &&
            _vm.userHasPermission('transfer', 'write')
          )?_c('b-form-group',{staticClass:"mr-n3"},[_c('b-form-checkbox',{attrs:{"checked":true,"switch":""},on:{"change":function($event){return _vm.disableTransfer(_vm.transfer)}}})],1):_vm._e()],1)])],1),_c('p',{staticClass:"text-justify mt-3"},[_c('span',[_vm._v(" Origin: "),_c('i',[_vm._v(_vm._s(_vm.transfer.origin)+" ")])]),_c('br'),_c('span',[_vm._v(" Destination: "),_c('i',[_vm._v(_vm._s(_vm.transfer.destination)+" ")])])]),_c('p',{staticClass:"text-justify"},[_vm._v(" Driver: "),(_vm.transfer.driver != null)?_c('i',[_vm._v(" "+_vm._s(_vm.transfer.driver.firstName)+" "+_vm._s(_vm.transfer.driver.lastName)+" ")]):_vm._e(),(_vm.transfer.supplierCompany != null)?_c('i',[_vm._v(_vm._s(_vm.transfer.supplierCompany.companyName))]):_vm._e(),(_vm.transfer.customer)?_c('i',[_vm._v(" Customer ")]):_vm._e(),(_vm.isDriverEditing)?_c('DriverSelect',{on:{"select-driver":function($event){return _vm.onSelectDriver($event)}}}):_vm._e(),(_vm.isDriverEditing)?_c('b-button',{staticClass:"mr-2",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){_vm.isDriverEditing = false}}},[_c('b-icon',{attrs:{"icon":"x"}})],1):_vm._e(),(_vm.isDriverEditing)?_c('b-button',{staticClass:"mr-2",attrs:{"variant":"success","size":"sm"},on:{"click":function($event){return _vm.updateDriver(_vm.transfer)}}},[_vm._v(" Save ")]):_vm._e(),(
        !_vm.isDriverEditing &&
        _vm.transfer.status != 4 &&
        _vm.transfer.status != 2 &&
        _vm.transfer.status != 0 &&
        _vm.userHasPermission('transfer', 'write')
      )?_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){_vm.isDriverEditing = true}}},[_c('b-icon',{attrs:{"icon":"pencil"}})],1):_vm._e()],1),_c('p',{staticClass:"text-justify"},[(_vm.transfer.note)?_c('span',[_vm._v(" Note: "),_c('i',[_vm._v(_vm._s(_vm.transfer.note)+" ")])]):_vm._e()]),_c('p',{staticClass:"text-justify"},[_vm._v(" Cost: "),(!_vm.isCostEditing)?_c('i',[_vm._v(_vm._s(_vm._f("currency")(_vm.transfer.cost))+" ")]):_c('b-col',{staticClass:"px-0",attrs:{"xs":"12","sm":"12","md":"12","lg":"12","xl":"4"}},[_c('b-form-group',{attrs:{"label-size":"sm"}},[_c('template',{slot:"label"}),_c('Money',_vm._b({staticClass:"form-control text-center",model:{value:(_vm.form.cost),callback:function ($$v) {_vm.$set(_vm.form, "cost", $$v)},expression:"form.cost"}},'Money',_vm.money,false))],2)],1),(_vm.isCostEditing)?_c('b-button',{staticClass:"mr-2",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){_vm.isCostEditing = false}}},[_c('b-icon',{attrs:{"icon":"x"}})],1):_vm._e(),(_vm.isCostEditing && _vm.userHasPermission('transfer', 'write'))?_c('b-button',{staticClass:"mr-2",attrs:{"variant":"success","size":"sm"},on:{"click":function($event){return _vm.updateCost(_vm.transfer)}}},[_vm._v(" Save ")]):_vm._e(),(
        !_vm.isCostEditing &&
        _vm.transfer.status == 1 &&
        _vm.userHasPermission('transfer', 'write')
      )?_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){_vm.isCostEditing = true}}},[_c('b-icon',{attrs:{"icon":"pencil"}})],1):_vm._e()],1),_c('p',[(
        _vm.isEditing &&
        (_vm.transfer.status == 1 || _vm.transfer.status == 3) &&
        _vm.userHasPermission('transfer', 'write')
      )?_c('b-col',{staticClass:"px-0",attrs:{"xs":"12","sm":"12","md":"12","lg":"12","xl":"4"}},[_c('b-form-group',{attrs:{"label-size":"sm"}},[_c('template',{slot:"label"},[_vm._v("Arrived date ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.arrivalDate),expression:"form.arrivalDate"}],staticClass:"form-control",attrs:{"type":"datetime-local"},domProps:{"value":(_vm.form.arrivalDate)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "arrivalDate", $event.target.value)}}})],2)],1):_vm._e(),(_vm.isEditing)?_c('b-button',{staticClass:"mr-2",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){_vm.isEditing = false}}},[_c('b-icon',{attrs:{"icon":"x"}})],1):_vm._e(),(_vm.isEditing && _vm.userHasPermission('transfer', 'write'))?_c('b-button',{staticClass:"mr-2",attrs:{"variant":"success","size":"sm","disabled":_vm.isLoadingChangeStatus},on:{"click":function($event){return _vm.markTransferAsComplete(_vm.transfer)}}},[_vm._v(" Save ")]):_vm._e(),(
        !_vm.isEditing &&
        (_vm.transfer.status == 1 || _vm.transfer.status == 3) &&
        _vm.userHasPermission('transfer', 'write')
      )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-2",attrs:{"variant":"success","size":"sm","title":"Mark as completed"},on:{"click":function($event){_vm.isEditing = true}}},[_c('font-awesome-icon',{staticClass:"cursor-pointer h4 mb-1",attrs:{"size":"sm","icon":['far', 'truck-container']}})],1):_vm._e(),_c('span',{staticClass:"text-right"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"float-right",attrs:{"variant":"yellow","size":"sm","title":"Download XLS"},on:{"click":function($event){return _vm.generateTransferXLS(_vm.transfer)}}},[_c('b-icon',{attrs:{"icon":"file-earmark-arrow-down","variant":"white"}})],1),(_vm.userHasPermission('transfer', 'write'))?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"float-right mx-2",attrs:{"variant":"success","size":"sm","title":"Send transfer XLS by email"},on:{"click":function($event){return _vm.openEmailBodyModal()}}},[_c('b-icon',{attrs:{"icon":"envelope","variant":"white"}})],1):_vm._e()],1)],1),_c('EmailBody',{attrs:{"id":_vm.transfer.id},on:{"submit":_vm.sendTransferXLSByEmail}}),_c('TaskUpdateStatusModal',{attrs:{"tasks":_vm.tasks,"id":_vm.transfer.id},on:{"update-task":_vm.refreshAfterUpdateTask}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }