<template>
  <b-form class="" @submit.stop.prevent="save()">
    <b-row v-if="vehicle">
      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <b-row>
          <b-col cols="11" class="mr-0">
            <b-form-group label="Sell contract">
              <b-input-group>
                <b-form-file
                  id="file-contract"
                  v-model="form.contract"
                  accept=".pdf"
                />
                <b-input-group-append
                  v-if="jacket.data.sale && jacket.data.sale.contract"
                >
                  <b-button variant="none">
                    <a :href="jacket.data.sale.contract" target="_blank">
                      <b-icon icon="eye" variant="dark-green" />
                    </a>
                  </b-button>
                </b-input-group-append>
              </b-input-group>

              <h5 v-if="exportInfo?.vehicleSalesId">
                Contract/Invoice
                <b-spinner v-if="isPDFLoading" small variant="yellow" />

                <font-awesome-icon
                  v-else
                  class="icon-color cursor-pointer"
                  :icon="['far', 'file-pdf']"
                  @click="getPurchaseOrderPDF()"
                />
              </h5>
            </b-form-group>
          </b-col>

          <b-col cols="1" class="pl-0">
            <br />
            <b-form-checkbox
              id="checkbox-1"
              v-model="form.contractCheck"
              name="checkbox-1"
              class="mt-2"
              :value="1"
              :unchecked-value="0"
            >
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-col>
      <!-- Contract received date -->
      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <b-form-group label-size="md" label="Sell contract received date">
          <b-form-datepicker
            id="contract-datepicker"
            v-model="form.contractDate"
            type="date"
          />
          <h5 v-if="jacket.data.sale && jacket.data.sale.registerDate">
            Sale date: {{ jacket.data.sale.registerDate | formatDate }}
          </h5>
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <div class="row">
          <b-col cols="11" class="mr-0">
            <b-form-group label="Selling  registration">
              <b-input-group>
                <b-form-file
                  id="file-ownersheet"
                  v-model="form.registration"
                  accept=".pdf"
                />
                <b-input-group-append
                  v-if="jacket.data.sale && jacket.data.sale.registration"
                >
                  <b-button variant="none">
                    <a :href="jacket.data.sale.registration" target="_blank">
                      <b-icon icon="eye" variant="dark-green" />
                    </a>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="1" class="pl-0">
            <br />
            <b-form-checkbox
              id="checkbox-2"
              v-model="form.registrationCheck"
              name="checkbox-1"
              class="mt-2"
              :value="1"
              :unchecked-value="0"
            >
            </b-form-checkbox>
          </b-col>
        </div>
      </b-col>
      <!-- Registration received date -->
      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <b-form-group
          label-size="md"
          label="Selling registration received date"
        >
          <b-form-datepicker
            id="registration-datepicker"
            v-model="form.registrationDate"
            class="mb-2"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <div class="row">
          <b-col cols="11" class="mr-0">
            <b-form-group
              label="Quebec registration"
              label-cols-lg="12"
              label-cols-sm="12"
            >
              <b-input-group>
                <b-form-file disabled v-model="form.ownerSheet" accept=".pdf" />
                <b-input-group-append
                  v-if="exportStore.data && exportStore.data.ownerSheet"
                >
                  <b-button variant="none">
                    <a :href="exportStore.data.ownerSheet" target="_blank">
                      <b-icon icon="eye" variant="success" />
                    </a>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </div>
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <b-row>
          <b-col cols="11">
            <b-form-group label-size="sm" class="mt-3">
              <template> Send to agency date </template>
              <input
                v-model="form.sendAgency"
                type="date"
                class="form-control"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <div class="row">
          <b-col cols="11" class="mr-0">
            <b-form-group
              label="7501 form"
              label-cols-lg="12"
              label-cols-sm="12"
            >
              <b-input-group>
                <b-form-file v-model="form.form7501" accept=".pdf" />
                <b-input-group-append
                  v-if="exportStore.data && exportStore.data.form7501"
                >
                  <b-button variant="none">
                    <a :href="exportStore.data.form7501" target="_blank">
                      <b-icon icon="eye" variant="success" />
                    </a>
                    <b-icon
                      icon="trash"
                      class="ml-1 cursor-pointer"
                      @click="deleteFile('form7501')"
                    ></b-icon>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </div>
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <b-row>
          <b-col cols="11">
            <b-form-group label-size="sm" class="mt-3">
              <template> 7501 stamp date </template>
              <input
                v-model="form.stampDate7501"
                type="date"
                class="form-control"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>

      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <b-row>
          <b-col cols="11">
            <b-form-group label-size="sm" class="mt-3">
              <template> Receive date </template>
              <input
                v-model="form.receivedDate"
                type="date"
                class="form-control"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>

      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <div class="row">
          <b-col cols="11" class="mr-0">
            <b-form-group
              label="US title before reassignment"
              label-cols-lg="12"
              label-cols-sm="12"
            >
              <b-input-group>
                <b-form-file v-model="form.beforeReassignment" accept=".pdf" />
                <b-input-group-append
                  v-if="exportStore.data && exportStore.data.beforeReassignment"
                >
                  <b-button variant="none">
                    <a
                      :href="exportStore.data.beforeReassignment"
                      target="_blank"
                    >
                      <b-icon icon="eye" variant="success" />
                    </a>
                    <b-icon
                      icon="trash"
                      class="ml-1 cursor-pointer"
                      @click="deleteFile('beforeReassignment')"
                    ></b-icon>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </div>
      </b-col>

      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <div class="row">
          <b-col cols="11" class="mr-0">
            <b-form-group
              label="US title After reassignment"
              label-cols-lg="12"
              label-cols-sm="12"
            >
              <b-input-group>
                <b-form-file v-model="form.afterReassignment" accept=".pdf" />
                <b-input-group-append
                  v-if="exportStore.data && exportStore.data.afterReassignment"
                >
                  <b-button variant="none">
                    <a
                      :href="exportStore.data.afterReassignment"
                      target="_blank"
                    >
                      <b-icon icon="eye" variant="success" />
                    </a>
                    <b-icon
                      icon="trash"
                      class="ml-1 cursor-pointer"
                      @click="deleteFile('afterReassignment')"
                    ></b-icon>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </div>
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="6" xl="6"> </b-col>

      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <b-row>
          <b-col cols="11" class="mr-0">
            <b-form-group
              label="Sent to customer"
              label-cols-lg="12"
              label-cols-sm="12"
            >
              <b-form-input v-model="form.sentToCustomer" type="date" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>

      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <b-row>
          <b-col cols="10" class="mr-0">
            <b-form-group
              label="Tracking number"
              label-cols-lg="12"
              label-cols-sm="12"
            >
              <b-form-input v-model="form.trackingNumber" type="text" />
            </b-form-group>
          </b-col>
          <b-col cols="2" v-if="form.trackingNumber">
            <b-icon
              icon="eye"
              class="mt-5 cursor-pointer"
              scale="1.5"
              @click="openTrackingPage(form.trackingNumber)"
            ></b-icon
          ></b-col>
        </b-row>
      </b-col>

      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <div class="row">
          <b-col cols="11" class="mr-0">
            <b-form-group
              label="Fixed / Duplicate"
              label-cols-lg="12"
              label-cols-sm="12"
            >
              <b-form-checkbox
                id="checkbox-1"
                v-model="form.fixedDuplicate"
                name="checkbox-1"
                :value="1"
                :unchecked-value="0"
              >
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </div>
      </b-col>

      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <div class="row">
          <b-col cols="11" class="mr-0">
            <b-form-group
              label="Title number in"
              label-cols-lg="12"
              label-cols-sm="12"
            >
              <b-form-input
                v-model="form.titleMsoIn"
                type="text"
                aria-describedby="input-date-live-feedback"
              />
            </b-form-group>
          </b-col>
        </div>
      </b-col>

      <b-col sm="12" md="6" lg="6" xl="6">
        <div class="row">
          <b-col cols="11" class="mr-0">
            <b-form-group
              label-size="md"
              label="Title status in"
              label-cols-lg="12"
              label-cols-sm="12"
            >
              <b-form-select
                v-model="form.titleMsoStatusIn"
                :options="titleStatus"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </div>
      </b-col>
      <b-col sm="12" md="6" lg="6" xl="6">
        <b-form-group label="Title origin in">
          <b-row>
            <b-col cols="11">
              <vue-single-select
                :classes="{
                  input: 'form-control',
                  dropdown: 'dropdown p-0',
                }"
                v-model="form.titleOriginIn"
                :options="states"
                optionLabel="name"
              >
                <template slot="option" slot-scope="{ option }">
                  <div class="">
                    <span> {{ option.name }}</span>
                  </div>
                </template>
              </vue-single-select>
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6" lg="6" xl="6">
        <b-form-group label="NMVTIS status in">
          <b-row>
            <b-col cols="11">
              <b-form-select
                v-model="form.nmvtisStatusIn"
                :options="nmvtisStatusList"
              >
              </b-form-select>
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>

      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <div class="row">
          <b-col cols="11" class="mr-0">
            <b-form-group
              label="Title number out"
              label-cols-lg="12"
              label-cols-sm="12"
            >
              <b-form-input
                v-model="form.titleMsoOut"
                type="text"
                aria-describedby="input-date-live-feedback"
              />
            </b-form-group>
          </b-col>
        </div>
      </b-col>

      <b-col sm="12" md="6" lg="6" xl="6">
        <div class="row">
          <b-col cols="11" class="mr-0">
            <b-form-group
              label-size="md"
              label="Title status out"
              label-cols-lg="12"
              label-cols-sm="12"
            >
              <b-form-select
                v-model="form.titleMsoStatusOut"
                :options="titleStatus"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </div>
      </b-col>
      <b-col sm="12" md="6" lg="6" xl="6">
        <b-form-group label="Title origin out">
          <b-row>
            <b-col cols="11">
              <vue-single-select
                :classes="{
                  input: 'form-control',
                  dropdown: 'dropdown p-0',
                }"
                v-model="form.titleOriginOut"
                :options="states"
                optionLabel="name"
              >
                <template slot="option" slot-scope="{ option }">
                  <div class="p-0">
                    <span> {{ option.name }}</span>
                  </div>
                </template>
              </vue-single-select>
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6" lg="6" xl="6">
        <b-form-group label="NMVTIS status out">
          <b-row>
            <b-col cols="11">
              <b-form-select
                v-model="form.nmvtisStatusOut"
                :options="nmvtisStatusList"
              >
              </b-form-select>
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>

      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <div class="row">
          <b-col cols="11" class="mr-0">
            <b-form-group
              label="Miles in"
              label-cols-lg="12"
              label-cols-sm="12"
            >
              <b-form-input
                v-model="form.milesIn"
                type="number"
                min="0"
                aria-describedby="input-date-live-feedback"
              />
            </b-form-group>
          </b-col>
        </div>
      </b-col>

      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <div class="row">
          <b-col cols="11" class="mr-0">
            <b-form-group
              label="Miles out"
              label-cols-lg="12"
              label-cols-sm="12"
            >
              <b-form-input
                v-model="form.milesOut"
                type="number"
                min="0"
                aria-describedby="input-date-live-feedback"
              />
            </b-form-group>
          </b-col>
        </div>
      </b-col>

      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <div class="row">
          <b-col cols="11" class="mr-0">
            <b-form-group
              label="Deposit check"
              label-cols-lg="12"
              label-cols-sm="12"
            >
              <b-input-group>
                <b-form-file v-model="form.depositCheck" accept=".pdf" />
                <b-input-group-append
                  v-if="exportStore.data && exportStore.data.depositCheck"
                >
                  <b-button variant="none">
                    <a :href="exportStore.data.depositCheck" target="_blank">
                      <b-icon icon="eye" variant="success" />
                    </a>
                    <b-icon
                      icon="trash"
                      class="ml-1 cursor-pointer"
                      @click="deleteFile('depositCheck')"
                    ></b-icon>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </div>
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <b-row>
          <b-col cols="11">
            <b-form-group label-size="sm" class="mt-3">
              <template> Release date </template>

              <b-input-group>
                <input
                  v-model="form.releaseDate"
                  type="date"
                  class="form-control"
                />
                <b-input-group-append>
                  <b-button variant="none" @click="form.releaseDate = null">
                    <b-icon icon="trash" class="cursor-pointer"></b-icon>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" md="6" lg="6" xl="6" />
      <b-col sm="12" md="3" lg="3" xl="3" />
      <b-col sm="12" md="3" lg="3" xl="3">
        <b-btn
          v-if="userHasPermission('export', 'write')"
          block
          size="lg"
          class="btn btn-success"
          :disabled="isLoading"
          type="submit"
        >
          Submit
          <b-spinner v-if="isLoading" small variant="white" />
        </b-btn>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { mapState } from "vuex";
import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "@/modules/superAdmin/mixins.js";
import VueSingleSelect from "vue-single-select";

import {
  GET_EXPORT,
  POST_EXPORT,
  PUT_DELETE_DOCUMENT,
  GET_TITLE_ORIGIN,
} from "./actions";
export default {
  name: "ExportModal",
  mixins: [generalMixin, superAdminMixin],
  components: { VueSingleSelect },
  computed: {
    ...mapState({
      exportStore: (state) =>
        state.superAdminStore.componentStore.exports.export,
      vehicle: (state) => state.superAdminStore.vehicle,
      states: (state) =>
        state.superAdminStore.componentStore.exports.states.data,
      jacket: (state) => state.superAdminStore.componentStore.jacket.jacketInfo,
    }),
    exportInfo() {
      return this.jacket?.data?.export;
    },
    isLoading() {
      return this.exportStore.status == "fetching";
    },
  },
  data() {
    return {
      form: {
        ownerSheet: null,
        form7501: null,
        beforeReassignment: null,
        afterReassignment: null,
        depositCheck: null,
        releaseDate: null,
        milesIn: null,
        milesOut: null,
        titleMsoIn: null,
        titleSold: null,
        nmvtisStatus: null,
        sendAgency: null,
        receivedDate: null,
        titleMsoStatusIn: null,
        titleOriginIn: null,
        nmvtisStatusIn: null,
        titleMsoOut: null,
        titleMsoStatusOut: null,
        titleOriginOut: null,
        nmvtisStatusOut: null,
        registration: null,
        registrationCheck: null,
        registrationDate: null,
        contract: null,
        contractCheck: null,
        contractDate: null,
        sentToCustomer: null,
        trackingNumber: null,
        stampDate7501: null,
        fixedDuplicate: null,
      },
      nmvtisStatusList: [
        "Scrap",
        "Sold",
        "Crush",
        "Ignore",
        "Junked",
        "LA1012",
      ],
      titleStatus: ["Flood damage", "MSO", "Regular", "Salvage", "Scrap"],
      saleInformation: null,
      isPDFLoading: false,
    };
  },
  methods: {
    getData() {
      this.$store
        .dispatch(GET_EXPORT, {
          vehicleId: this.vehicle.id,
        })
        .then(() => {
          this.form.releaseDate = this.vehicle?.releaseDate;
          if (this.exportStore.data) {
            this.formatForm();
          }
        });
    },
    formatForm() {
      this.form.id = this.exportStore.data.id;
      this.form.releaseDate = this.exportStore.data.releaseDate;
      this.form.milesIn = this.exportStore.data.milesIn;
      this.form.milesOut = this.exportStore.data.milesOut;
      this.form.titleMsoIn = this.exportStore.data.titleMsoIn;
      this.form.titleSold = this.exportStore.data.titleSold;
      this.form.nmvtisStatus = this.exportStore.data.nmvtisStatus;
      this.form.titleMsoStatusIn = this.exportStore.data.titleMsoStatusIn;
      this.form.titleOriginIn = this.states.find(
        (state) => state.name == this.exportStore.data.titleOriginIn
      );
      this.form.nmvtisStatusIn = this.exportStore.data.nmvtisStatusIn;
      this.form.titleMsoOut = this.exportStore.data.titleMsoOut;
      this.form.titleMsoStatusOut = this.exportStore.data.titleMsoStatusOut;
      this.form.titleOriginOut = this.states.find(
        (state) => state.name == this.exportStore.data.titleOriginOut
      );
      this.form.nmvtisStatusOut = this.exportStore.data.nmvtisStatusOut;
      this.form.registrationCheck = this.jacket.data.sale
        ? this.jacket.data.sale.registrationCheck
        : null;
      this.form.registrationDate = this.jacket.data.sale
        ? this.jacket.data.sale.registrationDate
        : null;

      this.form.contractCheck = this.jacket.data.sale
        ? this.jacket.data.sale.contractCheck
        : null;
      this.form.contractDate = this.jacket.data.sale
        ? this.jacket.data.sale.contractDate
        : null;
      this.form.receivedDate = this.exportStore.data.receivedDate;
      this.form.sendAgency = this.exportStore.data.sendAgency;
      this.form.sentToCustomer = this.exportStore.data.sentToCustomer;
      this.form.trackingNumber = this.exportStore.data.trackingNumber;
      this.form.stampDate7501 = this.exportStore.data.stampDate7501;
      this.form.fixedDuplicate = this.exportStore.data.fixedDuplicate;
    },
    save() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_EXPORT, payload)
        .then(() => {
          this.toastMessage("Export saved successfully", "Success", "success");
          this.getData();
          this.getJacketInformation();
          this.closeModal();
        })
        .catch(() => {
          this.toastMessage("Error saving export", "Error", "warning");
        });
    },

    update(type) {
      const payload = {
        id: this.exportStore.data.id,
        type: type,
        vehicleId: this.vehicle.id,
      };
      this.$store
        .dispatch(POST_EXPORT, payload)
        .then(() => {
          this.toastMessage("Export saved successfully", "Success", "success");
          this.getData();
          this.getJacketInformation();
          this.closeModal();
        })
        .catch(() => {
          this.toastMessage("Error saving export", "Error", "warning");
        });
    },
    async deleteFile(type) {
      const confirm = await this.showConfirmDeleteBox();
      if (!confirm) return;
      this.$store
        .dispatch(PUT_DELETE_DOCUMENT, {
          id: this.exportStore.data.id,

          type: type,
        })
        .then(() => {
          this.getData();
          this.toastMessage("File deleted successfully", "Success", "success");
        })
        .catch(() => {
          this.toastMessage("File could not be deleted", "Warning", "warning");
        });
    },
    formatPayload() {
      const data = new FormData();

      data.set("vehicleId", this.vehicle.id);
      data.set("ownerSheet", this.form.ownerSheet);
      data.set("form7501", this.form.form7501);
      data.set("stampDate7501", this.form.stampDate7501);
      data.set("beforeReassignment", this.form.beforeReassignment);
      data.set("afterReassignment", this.form.afterReassignment);
      data.set("depositCheck", this.form.depositCheck);
      data.set("releaseDate", this.form.releaseDate);
      data.set("milesIn", this.form.milesIn);
      data.set("milesOut", this.form.milesOut);
      data.set("titleMsoIn", this.form.titleMsoIn);
      data.set("titleSold", this.form.titleSold);
      data.set("nmvtisStatus", this.form.nmvtisStatus);
      data.set("sendAgency", this.form.sendAgency);
      data.set("receivedDate", this.form.receivedDate);
      data.set("titleMsoStatusIn", this.form.titleMsoStatusIn);
      data.set(
        "titleOriginIn",
        this.form.titleOriginIn ? this.form.titleOriginIn.name : ""
      );
      data.set("nmvtisStatusIn", this.form.nmvtisStatusIn);
      data.set("titleMsoOut", this.form.titleMsoOut);
      data.set("titleMsoStatusOut", this.form.titleMsoStatusOut);
      data.set(
        "titleOriginOut",
        this.form.titleOriginOut ? this.form.titleOriginOut.name : ""
      );
      data.set("nmvtisStatusOut", this.form.nmvtisStatusOut);

      data.set(
        "vehicleSaleid",
        this.saleInformation ? this.saleInformation.id : null
      );
      data.set("registration", this.form.registration);
      data.set("registrationCheck", this.form.registrationCheck);
      data.set("registrationDate", this.form.registrationDate);
      data.set("contract", this.form.contract);
      data.set("contractCheck", this.form.contractCheck);
      data.set("contractDate", this.form.contractDate);
      data.set("sentToCustomer", this.form.sentToCustomer);
      data.set("trackingNumber", this.form.trackingNumber);
      data.set("fixedDuplicate", this.form.fixedDuplicate);
      return data;
    },
    getStates() {
      this.$store.dispatch(GET_TITLE_ORIGIN).then(() => {});
    },
    getJacketInformation() {
      this.$store
        .dispatch("GET_JACKETS_INFO", { vehicleId: this.vehicle.id })
        .then(({ sale }) => {
          this.saleInformation = sale;
        });
    },
    openTrackingPage(trackingNumber) {
      window.open(
        `https://parcelsapp.com/en/tracking/${trackingNumber}`,
        "_blank"
      );
    },

    getPurchaseOrderPDF() {
      this.isPDFLoading = true;
      this.$store
        .dispatch("GET_SOLD_PDF", { id: this.exportInfo.vehicleSalesId })
        .then((response) => {
          const blob = new Blob([response], {
            type: "application/pdf",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = this.formatFileName(
            "jacket-purchase-order",
            this.vehicle
          );
          link.click();
        })
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        })
        .finally(() => {
          this.isPDFLoading = false;
        });
    },
  },
  mounted() {
    this.getData();
    this.getStates();
    this.getJacketInformation();
  },
};
</script>

<style></style>
