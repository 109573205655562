<template>
  <div>
    <small>Select report</small>
    <vue-single-select
      :classes="{
        input: 'form-control',
        dropdown: 'dropdown p-0',
      }"
      v-model="selected"
      :options="reports.data"
      optionLabel="name"
    >
    </vue-single-select>
  </div>
</template>

<script>
import VueSingleSelect from "vue-single-select";
import { GET_CUSTOM_REPORTS } from "./actions";
import { mapState } from "vuex";
export default {
  name: "ReportSelect",
  components: { VueSingleSelect },
  computed: {
    ...mapState({
      reports: (state) => state.superAdminStore.customReports.customReports,
    }),
  },
  data() {
    return {
      selected: null,
    };
  },
  mounted() {
    this.getReports();
  },
  watch: {
    selected: {
      deep: true,
      handler: function () {
        this.onReportSelect(this.selected);
      },
    },
  },
  methods: {
    onReportSelect(val) {
      if (val) {
        this.selected = val;
        this.$emit("onReportSelect", val);
      }
    },
    getReports() {
      this.$store
        .dispatch(GET_CUSTOM_REPORTS)
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>