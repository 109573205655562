import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    addons: {
      status: null,
      data: [],
      error: null,
    },
    addonsList: {
      status: null,
      data: [],
      error: null,
    },
    addon: {
      status: null,
      data: {},
      error: null,
    },
    companies: {
      status: null,
      data: [],
      error: null,
    },
  };
}

const actions = {
  [constants.GET_ADDON_TYPE]: async ({ commit }, params) => {
    try {
      commit(constants.GET_ADDON_TYPE);
      const response = await axios.get("marketPlaces/getMarketPlace", params);
      commit(constants.GET_ADDON_TYPE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_ADDON_TYPE_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_ADDON_TYPE_LIST]: async ({ commit }, params) => {
    try {
      commit(constants.GET_ADDON_TYPE_LIST);
      const response = await axios.get("addon/getAddonType", {
        params: { ...params },
      });
      commit(constants.GET_ADDON_TYPE_LIST_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_ADDON_TYPE_LIST_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_ADDON_TYPE]: async ({ commit }, params) => {
    try {
      commit(constants.POST_ADDON_TYPE);
      const response = await axios.post(
        "marketPlaces/createAndUpdateMarketPlace",
        {
          ...params,
        }
      );
      commit(constants.POST_ADDON_TYPE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_ADDON_TYPE_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_ASSIGN_ADDON_TYPE]: async ({ commit }, params) => {
    try {
      commit(constants.PUT_ASSIGN_ADDON_TYPE);
      const response = await axios.put("company/assignAddonType", {
        ...params,
      });
      commit(constants.PUT_ASSIGN_ADDON_TYPE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_ASSIGN_ADDON_TYPE_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_ADDON_TYPE_COMPANIES]: async ({ commit }, params) => {
    try {
      commit(constants.GET_ADDON_TYPE_COMPANIES);
      const response = await axios.get("company/getCompanies", {
        params: { ...params },
      });
      commit(constants.GET_ADDON_TYPE_COMPANIES_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_ADDON_TYPE_COMPANIES_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_ADDON_TYPE]: (state) => {
    state.addons.status = "fetching";
    state.addons.error = null;
  },
  [constants.GET_ADDON_TYPE_SUCCESS]: (state, data) => {
    state.addons.status = "success";
    state.addons.error = null;
    state.addons.data = data;
  },
  [constants.GET_ADDON_TYPE_ERROR]: (state, error) => {
    state.addons.status = "error";
    state.addons.error = error;
  },
  [constants.GET_ADDON_TYPE_LIST]: (state) => {
    state.addonsList.status = "fetching";
    state.addonsList.error = null;
  },
  [constants.GET_ADDON_TYPE_LIST_SUCCESS]: (state, data) => {
    state.addonsList.status = "success";
    state.addonsList.error = null;
    state.addonsList.data = data;
  },
  [constants.GET_ADDON_TYPE_LIST_ERROR]: (state, error) => {
    state.addonsList.status = "error";
    state.addonsList.error = error;
  },
  [constants.POST_ADDON_TYPE]: (state) => {
    state.addons.status = "fetching";
    state.addons.error = null;
  },
  [constants.POST_ADDON_TYPE_SUCCESS]: (state, data) => {
    state.addons.status = "success";
    state.addons.error = null;
    state.addons.data = data;
  },

  [constants.POST_ADDON_TYPE_ERROR]: (state, error) => {
    state.addons.status = "error";
    state.addons.error = error;
  },
  [constants.PUT_ASSIGN_ADDON_TYPE]: (state) => {
    state.addons.status = "fetching";
    state.addons.error = null;
  },
  [constants.PUT_ASSIGN_ADDON_TYPE_SUCCESS]: (state, data) => {
    state.addons.status = "success";
    state.addons.error = null;
    state.addons.data = data;
  },
  [constants.PUT_ASSIGN_ADDON_TYPE_ERROR]: (state, error) => {
    state.addons.status = "error";
    state.addons.error = error;
  },
  [constants.GET_ADDON_TYPE_COMPANIES]: (state) => {
    state.companies.status = "fetching";
    state.companies.error = null;
  },
  [constants.GET_ADDON_TYPE_COMPANIES_SUCCESS]: (state, data) => {
    state.companies.status = "success";
    state.companies.error = null;
    state.companies.data = data;
  },
  [constants.GET_ADDON_TYPE_COMPANIES_ERROR]: (state, error) => {
    state.companies.status = "error";
    state.companies.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
