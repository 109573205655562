<template>
  <b-container>
    <b-row>
      <b-col md="8" class="mt-3">
        <b-card>
          <div class="text-right">
            <b-button variant="success"
              >Time table
              <b-icon class="ml-1" icon="clock"></b-icon>
            </b-button>
          </div>
          <b-tr> <b-th rowspan="6" class="text-center">Stock Card</b-th> </b-tr>
          <b-row>
            <b-col md="6">
              <b-table
                stacked="md"
                :items="stockCardLeft"
                :fields="fieldsStock1"
                head-row-variant="success"
                thead-class="d-none"
                tdClass="p-0"
              >
              </b-table>
            </b-col>
            <b-col md="6">
              <b-table
                stacked="md"
                :items="stockCardRight"
                :fields="fieldsStock2"
                thead-class="d-none"
              >
              </b-table>
            </b-col>
          </b-row> </b-card
      ></b-col>
      <b-col md="4" class="mt-3">
        <SimpleViewProfitTable :profitInfo="profitInfo" />
      </b-col>
    </b-row>

    <SimpleVehicleViewAddonsTable
      :title="'Canadian expenses'"
      :addonsInfo="addonsInfo"
      :currency="'CAD'"
      :totalAddons="totalAddonsCAD"
    />
    <b-tabs>
      <b-tab title="US expenses">
        <b-card>
          <b-row>
            <b-col cols="8"> <h4>Total: $ 1,996.66</h4> </b-col>
            <b-col cols="4" class="text-right mb-4">
              <b-button variant="success" class="">
                <b-icon icon="plus" class="" />
              </b-button> </b-col
          ></b-row>

          <b-table
            stacked="md"
            :items="simpleInfoUS"
            :fields="fieldsUS"
            head-row-variant="success"
          >
          </b-table>
        </b-card>
      </b-tab>
    </b-tabs>

    <SimpleViewOptionsTable />
  </b-container>
</template>

<script>
import SimpleViewProfitTable from "./SimpleVehicleViewProfitTable.vue";
import SimpleViewOptionsTable from "./SimpleVehicleViewOptionsTable.vue";
import { GET_SIMPLE_VIEW_VEHICLE } from "./actions";
import SimpleVehicleViewAddonsTable from "./SimpleVehicleViewAddonsTable.vue";
export default {
  name: "SimpleView",
  components: {
    SimpleViewProfitTable,
    SimpleViewOptionsTable,
    SimpleVehicleViewAddonsTable,
  },
  data() {
    return {
      addonsInfo: [],
      totalAddonsCAD: 0,
      totalAddonsUSD: 0,
      fieldsStock1: [
        { key: "suffix", label: "", class: " py-1" },
        {
          key: "value",
          label: "",
          class: "text-center py-1",
        },
      ],
      stockCardLeft: [
        {
          suffix: "Make",
          value: null,
          key: "make",
        },
        {
          suffix: "Model",
          value: null,
          key: "model",
        },
        {
          suffix: "VIN",
          value: null,
          key: "vinNumber",
        },
        {
          suffix: "Year",
          value: null,
          key: "year",
        },
        {
          suffix: "Color",
          value: null,
          key: "color",
        },
      ],
      fieldsStock2: [
        { key: "suffix", label: "", class: " py-1" },
        {
          key: "value",
          label: "",
          class: "text-center py-1",
        },
      ],
      stockCardRight: [
        {
          suffix: "Trim",
          value: null,
          key: "trim",
        },
        {
          suffix: "Engine",
          value: null,
          key: "engine",
        },
        {
          suffix: "Kms",
          value: null,
          key: "kms",
        },
        {
          suffix: "Miles",
          value: null,
          key: "miles",
        },
      ],
      fieldsUS: [
        { key: "suffix", label: "Description" },
        {
          key: "amount",
          label: "Amount",
          class: "text-center",
          formatter: (amount) =>
            typeof amount == "number"
              ? this.$options.filters.currency(amount)
              : "-",
        },
        {
          key: "invoice",
          label: "Invoice #",
          class: "text-center",
        },
        {
          key: "gst",
          label: "",
          class: "text-center",
        },
        {
          key: "qst",
          label: "",
          class: "text-center",
        },
        {
          key: "total",
          label: "",
          class: "text-center",
        },
      ],
      simpleInfoUS: [
        {
          suffix: "RI Fee	",
          amount: 220.0,
          invoice: "15-22517",
          gst: null,
          qst: null,
          total: null,
        },
        {
          suffix: "Title",
          amount: 75.0,
          invoice: "15-22664",
          gst: null,
          qst: null,
          total: null,
        },
        {
          suffix: "Speedo",
          amount: 465.0,
          invoice: "15-22517",
          gst: null,
          qst: null,
          total: null,
        },
        {
          suffix: "Auction fee",
          amount: 180.0,
          invoice: null,
          gst: null,
          qst: null,
          total: null,
        },
        {
          suffix: "Transport",
          amount: 666.66,
          invoice: "922123F",
          gst: null,
          qst: null,
          total: null,
        },
        {
          suffix: "Detail	",
          amount: 125.0,
          invoice: null,
          gst: null,
          qst: null,
          total: null,
        },
        {
          suffix: "State Fee",
          amount: 20.0,
          invoice: null,
          gst: null,
          qst: null,
          total: null,
        },
        {
          suffix: "Canam Fee",
          amount: 200.0,
          invoice: null,
          gst: null,
          qst: null,
          total: null,
        },
        {
          suffix: "Other",
          amount: 45.0,
          invoice: "15-22517",
          gst: null,
          qst: null,
          total: null,
        },
        {
          suffix: "Total US Expenses",
          amount: 1996.66,
          invoice: null,
          gst: null,
          qst: null,
          total: null,
        },
      ],
      profitInfo: [
        {
          suffix: "Sale Price",
          amount: null,
          key: "salePrice",
        },

        {
          suffix: "Rate	",
          amount: "Rate unknown",
          key: "rate",
        },
        {
          suffix: "Gross Canadian",
          amount: "-",
          key: "grossCanadian",
        },

        {
          suffix: "Profit",
          amount: -924.0,
          key: "profit",
        },
        {
          suffix: "Aging (Days)",
          amount: "74",
          key: "aging",
        },
        {
          suffix: "Converted Funds	",
          amount: 46003.34,
          key: "convertedFunds",
        },
      ],
    };
  },
  created() {
    this.getSimpleVehicleInfo();
  },
  methods: {
    getSimpleVehicleInfo() {
      this.$store
        .dispatch(GET_SIMPLE_VIEW_VEHICLE, { id: this.$route.params.id })
        .then((response) => {
          console.log(response);
          this.addonsInfo = response.addons;
          this.totalAddonsUSD = response.totalAddonsUsd;
          this.totalAddonsCAD = response.totalAddonsCad;
          this.formatStockCards(response.stockCard);
          this.formatProfitInfo(response.profit);
        });
    },
    formatStockCards(stockCarInfo) {
      this.stockCardLeft.forEach((item) => {
        item.value = stockCarInfo[item.key];
      });
      this.stockCardRight.forEach((item) => {
        item.value = stockCarInfo[item.key];
      });
    },
    formatProfitInfo(profitInfo) {
      this.profitInfo.forEach((item) => {
        item.value = profitInfo[item.key];
      });
    },
  },
};
</script>

<style></style>
