<template>
  <b-form @submit.stop.prevent="saveAddon()" id="addonsForm">
    <b-row>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label="">
          <template slot="label">
            Stock number <span class="text-danger">*</span>
          </template>

          <vue-single-select
            :classes="{ input: 'form-control', dropdown: 'dropdown' }"
            v-model="form.stockNumber"
            :options="stockNumbers.data || []"
            optionLabel="stockNumber"
          ></vue-single-select>
        </b-form-group>
      </b-col>
      <b-col v-if="form.type == 'addons'" xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label="Accounting date">
          <template slot="label"> Accounting date </template>

          <b-form-input
            v-model.trim="$v.form.accountingDate.$model"
            type="date"
            :state="validateState('accountingDate')"
            aria-describedby="input-date-live-feedback"
            @change="setValue('accountingDate', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="form.type == 'addons'" xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label="">
          <template slot="label">
            Addon type <span class="text-danger">*</span>
          </template>

          <vue-single-select
            :classes="{ input: 'form-control', dropdown: 'dropdown' }"
            v-model="form.addonsType"
            :options="addonsType.data || []"
            optionLabel="name"
          ></vue-single-select>
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label="">
          <template slot="label">
            {{ form.type == "sales" ? "Company" : "Service provider" }}
            <span class="text-danger">*</span>
          </template>

          <vue-single-select
            :classes="{ input: 'form-control', dropdown: 'dropdown' }"
            v-model="form.serviceProvider"
            :options="suppliers || []"
            optionLabel="companyName"
          ></vue-single-select>
        </b-form-group>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label="">
          <template slot="label">
            {{ form.type == "sales" ? "Price" : "Amount" }}
            <span class="text-danger">*</span>
          </template>
          <Money
            v-model="$v.form.amount.$model"
            class="form-control"
            :state="validateState('amount')"
            v-bind="money"
            aria-describedby="input-amount-live-feedback"
            @change="setValue('amount', $event)"
          />
          <b-form-invalid-feedback id="input-amount-live-feedback">
            This is a required field.
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <b-form-group label="Description">
          <b-form-textarea
            v-model.trim="$v.form.description.$model"
            class="resize-none"
            :state="validateState('description')"
            @change="setValue('description', $event)"
          />
        </b-form-group>
      </b-col>

      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <b-form-group label="Txt line">
          <b-form-textarea v-model="form.txtLine" class="resize-none" readonly />
        </b-form-group>
      </b-col>
    </b-row>

    <div class="text-right">
      <b-button
        v-if="isEdition"
        variant="yellow"
        type="button"
        class="mr-2 text-white"
        @click="
          resetForm();
          isEdition = false;
        "
      >
        <b-spinner v-if="addon.status === 'fetching'" variant="white" small />
        <span v-else>Cancel edit</span>
      </b-button>
      <b-button
        :disabled="$v.form.$invalid || addon.status === 'fetching'"
        variant="primary"
        type="submit"
      >
        <b-spinner v-if="addon.status === 'fetching'" variant="white" small />
        <span v-else>Submit</span>
      </b-button>
    </div>
  </b-form>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import { Money } from "v-money";
import { superAdminMixin } from "../mixins";
import { GET_ADDONS_TYPE_LIST } from "@/modules/superAdmin/addons-type/actions";
import VueSingleSelect from "vue-single-select";
import { GET_STOCK_NUMBERS } from "./actions";
export default {
  name: "AddonsForm",
  props: {
    addonItem: {
      type: Object,
      default: () => ({}),
    },
    refreshData: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    Money,
    VueSingleSelect,
  },
  mixins: [superAdminMixin],
  computed: {
    ...mapState({
      addonsType: (state) => state.superAdminStore.addonsType.addonsType,
      addon: (state) => state.superAdminStore.componentStore.addons.addon,
      taxes: (state) => state.superAdminStore.componentStore.addons.taxes,
      stockNumbers: (state) => state.superAdminStore.addonsFile.stockNumbers,
    }),
  },
  data() {
    return {
      form: {
        accountingDate: null,
        amount: 0,
        vehicleId: null,
        currency: "CAD",
        description: null,
        addonType: null,
        serviceProvider: null,
        stockNumber: null,
      },
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      currencyOptions: ["CAD", "USD"],
      taxesSelected: [],
      suppliers: [],
      isEdition: false,
    };
  },

  validations: {
    form: {
      amount: {
        required,
      },
      addonType: {},
      accountingDate: {},
      description: {},
      serviceProvider: { required },
      stockNumber: {},
    },
  },
  created() {
    this.taxesSelected = [];
    this.getProviders();
    this.getAddonsType();
  },
  mounted() {
    this.formatForm();
    this.getStockNumbers();
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form.id = null;
      this.form.accountingDate = null;
      this.form.amount = 0;
      this.form.currency = "CAD";
      this.form.description = null;
      this.form.addonsType = null;
      this.form.serviceProvider = null;
      this.taxesSelected = [];
      this.isEdition = false;

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    saveAddon() {
      const payload = this.formatPayload();
      this.$store
        .dispatch("PUT_ADDON_FAIL", payload)
        .then(() => {
          this.resetForm();
          this.toastMessage("Addon information saved successfully", "Success", "success");
          this.refreshData();
          this.$bvModal.hide("addons-form-modal");
        })
        .catch((error) => {
          this.toastMessage(
            error.data.message.charAt(0).toUpperCase() + error.data.message.slice(1),
            "Warning",
            "warning"
          );
        });
    },
    formatPayload() {
      if (this.addonItem.type == "addons") {
        const payload = {
          id: this.form.id ?? null,
          addonTypeId: this.form.addonType.id,
          description: this.form.description,
          accountingDate: this.form.accountingDate,
          currency: this.form.currency,
          companiesId: this.form.serviceProvider.id,
          amountCAD: this.form.amount,
          failId: this.form.id,
          stockNumber: this.form.stockNumber.stockNumber,
        };
        return payload;
      } else {
        const payload = {
          id: this.form.id ?? null,
          amountCAD: this.form.amount,
          companiesId: this.form.serviceProvider.id,
          stockNumber: this.form.stockNumber.stockNumber,
        };
        return payload;
      }
    },
    getAddonsType() {
      this.$store
        .dispatch(GET_ADDONS_TYPE_LIST)
        .then(() => {
          this.form.addonsType = this.addonsType.data.find(
            (type) => type.id == this.addonItem.addonTypesId
          );
        })
        .catch(() => {});
    },

    setTaxesSelected(taxes) {
      this.taxesSelected = taxes;
    },
    formatTaxes() {
      this.taxesSelected = [];
      return this.taxes.data.map((tax) => {
        tax.appliesDefault == 1;
      });
    },
    setAddonToEdit(addon) {
      this.isEdition = true;
      this.form.accountingDate = addon.accountingDate ?? null;
      this.form.addonType = addon.addonType ?? null;
      this.form.amount = addon.txtAmount;

      this.form.description = addon.description ?? null;
      this.form.id = addon.id;
      this.taxesSelected = addon.taxes;
      this.form.serviceProvider = addon.company;
      this.$refs.AddonsFormTaxTable.formatTaxesSelectedToEdit(addon.taxes);
    },
    getProviders() {
      this.$store
        .dispatch("GET_COMPANIES_LIST", {
          isSupplier: this.addonItem.type == "addons" ? 1 : 0,
        })
        .then((response) => {
          this.suppliers = response;
        })
        .catch(() => {
          this.$root.$bvToast.toast("Could not get the suppliers list ", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    formatForm() {
      this.form.id = this.addonItem.id;
      this.form.accountingDate = this.addonItem.accountingDate;
      this.form.amount = parseInt(this.addonItem.txtAmount);

      this.form.currency = this.addonItem.currency;
      this.form.description = this.addonItem.description;
      if (this.addonsType.addonsType) {
        this.form.addonType = {
          id: this.addonItem.addonType.id,
          name: this.addonItem.addonType.name,
        };
      }
      this.form.serviceProvider = this.addonItem.company;
      this.form.type = this.addonItem.type;
      this.form.stockNumber = this.addonItem.stockNumber;
      this.form.txtLine = this.addonItem.txtLine;
    },
    getStockNumbers() {
      this.$store
        .dispatch(GET_STOCK_NUMBERS)
        .then(() => {})
        .catch(() => {});
    },
  },
};
</script>

<style></style>
