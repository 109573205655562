export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_ERROR = "AUTH_LOGIN_ERROR";

export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_LOGOUT_SUCCESS = "AUTH_LOGOUT_SUCCESS";
export const AUTH_LOGOUT_ERROR = "AUTH_LOGOUT_ERROR";

export const SET_PROFILE_DATA = "SET_PROFILE_DATA";

export const SET_CORPORATION = "SET_CORPORATION";


export const PUT_FAVORITE_DASHBOARD = "PUT_FAVORITE_DASHBOARD";
export const PUT_FAVORITE_DASHBOARD_SUCCESS = "PUT_FAVORITE_DASHBOARD_SUCCESS";
export const PUT_FAVORITE_DASHBOARD_ERROR = "PUT_FAVORITE_DASHBOARD_ERROR";