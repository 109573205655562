import * as constants from "./actions";
import axios from "@/plugins/axios.js";
function getDefaultState() {
  return {
    transfers: {
      status: null,
      data: [],
      error: null,
    },
    transfer: {
      status: null,
      data: {},
      error: null,
    },

    origins: {
      status: null,
      data: [],
      error: null,
    },
    kilometers: {
      status: null,
      data: {},
      error: null,
    },
  };
}

const actions = {
  [constants.GET_TRANSFERS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_TRANSFERS);
      const response = await axios.get(`transfer/getTransfers`, {
        params: payload,
      });
      commit(constants.GET_TRANSFERS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_TRANSFERS_ERROR, error);
      throw error.response;
    }
  },

  [constants.POST_TRANSFER]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_TRANSFER);
      const response = await axios.post("transfer/createAndUpdate", payload);
      commit(constants.POST_TRANSFER_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_TRANSFER_ERROR, error);
      throw error.response;
    }
  },

  [constants.GET_ORIGINS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_ORIGINS);
      const response = await axios.get("transfer/listCompanyAndProvider", {
        params: payload,
      });
      commit(constants.GET_ORIGINS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_ORIGINS_ERROR, error);
      throw error.response;
    }
  },

  [constants.PUT_TRANSFER]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_TRANSFER);
      const response = await axios.put("transfer/changeStatus", payload);
      commit(constants.PUT_TRANSFER_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_TRANSFER_ERROR, error);
      throw error.response;
    }
  },

  [constants.GET_TRANSFER_FILE]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_TRANSFER_FILE);
      const response = await axios.get("transfer/generateXls", {
        params: payload,
        responseType: "blob",
      });
      commit(constants.GET_TRANSFER_FILE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_TRANSFER_FILE_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_TRANSFER_EMAIL]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_TRANSFER_EMAIL);
      const response = await axios.get("transfer/sendTransfers", {
        params: payload,
      });
      commit(constants.GET_TRANSFER_EMAIL_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_TRANSFER_EMAIL_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_QUICK_TRANSFER]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_QUICK_TRANSFER);
      const response = await axios.post("transfer/quickRegister", payload);
      commit(constants.POST_QUICK_TRANSFER_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_QUICK_TRANSFER_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_KILOMETERS]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_KILOMETERS);
      const response = await axios.put("transfer/updateKms", payload);
      commit(constants.PUT_KILOMETERS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_KILOMETERS_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_TRANSFERS]: (state) => {
    state.transfers.status = "fetching";
    state.transfers.error = null;
  },
  [constants.GET_TRANSFERS_SUCCESS]: (state, data) => {
    state.transfers.status = "success";
    state.transfers.error = null;
    state.transfers.data = data;
  },
  [constants.GET_TRANSFERS_ERROR]: (state, error) => {
    state.transfers.status = "error";
    state.transfers.error = error;
  },
  [constants.POST_TRANSFER]: (state) => {
    state.transfer.status = "fetching";
    state.transfer.error = null;
  },
  [constants.POST_TRANSFER_SUCCESS]: (state, data) => {
    state.transfer.status = "success";
    state.transfer.error = null;
    state.transfer.data = data;
  },
  [constants.POST_TRANSFER_ERROR]: (state, error) => {
    state.transfer.status = "error";
    state.transfer.error = error;
  },

  [constants.GET_ORIGINS]: (state) => {
    state.origins.status = "fetching";
    state.origins.error = null;
  },
  [constants.GET_ORIGINS_SUCCESS]: (state, data) => {
    state.origins.status = "success";
    state.origins.error = null;
    state.origins.data = data;
  },
  [constants.GET_ORIGINS_ERROR]: (state, error) => {
    state.origins.status = "error";
    state.origins.error = error;
  },
  [constants.PUT_TRANSFER]: (state) => {
    state.transfer.status = "fetching";
    state.transfer.error = null;
  },
  [constants.PUT_TRANSFER_SUCCESS]: (state, data) => {
    state.transfer.status = "success";
    state.transfer.error = null;
    state.transfer.data = data;
  },
  [constants.PUT_TRANSFER_ERROR]: (state, error) => {
    state.transfer.status = "error";
    state.transfer.error = error;
  },
  [constants.GET_TRANSFER_FILE]: (state) => {
    state.transfer.status = "fetching";
    state.transfer.error = null;
  },
  [constants.GET_TRANSFER_FILE_SUCCESS]: (state, data) => {
    state.transfer.status = "success";
    state.transfer.error = null;
    state.transfer.data = data;
  },
  [constants.GET_TRANSFER_FILE_ERROR]: (state, error) => {
    state.transfer.status = "error";
    state.transfer.error = error;
  },
  [constants.GET_TRANSFER_EMAIL]: (state) => {
    state.transfer.status = "fetching";
    state.transfer.error = null;
  },
  [constants.GET_TRANSFER_EMAIL_SUCCESS]: (state, data) => {
    state.transfer.status = "success";
    state.transfer.error = null;
    state.transfer.data = data;
  },
  [constants.GET_TRANSFER_EMAIL_ERROR]: (state, error) => {
    state.transfer.status = "error";
    state.transfer.error = error;
  },
  [constants.POST_QUICK_TRANSFER]: (state) => {
    state.transfer.status = "fetching";
    state.transfer.error = null;
  },
  [constants.POST_QUICK_TRANSFER_SUCCESS]: (state, data) => {
    state.transfer.status = "success";
    state.transfer.error = null;
    state.transfer.data = data;
  },
  [constants.POST_QUICK_TRANSFER_ERROR]: (state, error) => {
    state.transfer.status = "error";
    state.transfer.error = error;
  },
  [constants.PUT_KILOMETERS]: (state) => {
    state.kilometers.status = "fetching";
    state.kilometers.error = null;
  },
  [constants.PUT_KILOMETERS_SUCCESS]: (state, data) => {
    state.kilometers.status = "success";
    state.kilometers.error = null;
    state.kilometers.data = data;
  },
  [constants.PUT_KILOMETERS_ERROR]: (state, error) => {
    state.kilometers.status = "error";
    state.kilometers.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
