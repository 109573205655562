export const GET_TRANSFERS = "GET_TRANSFERS";
export const GET_TRANSFERS_SUCCESS = "GET_TRANSFERS_SUCCESS";
export const GET_TRANSFERS_ERROR = "GET_TRANSFERS_ERROR";

export const POST_TRANSFER = "POST_TRANSFER";
export const POST_TRANSFER_SUCCESS = "POST_TRANSFER_SUCCESS";
export const POST_TRANSFER_ERROR = "POST_TRANSFER_ERROR";

export const PUT_TRANSFER = "PUT_TRANSFER";
export const PUT_TRANSFER_SUCCESS = "PUT_TRANSFER_SUCCESS";
export const PUT_TRANSFER_ERROR = "PUT_TRANSFER_ERROR";

export const GET_ORIGINS = "GET_ORIGINS";
export const GET_ORIGINS_SUCCESS = "GET_ORIGINS_SUCCESS";
export const GET_ORIGINS_ERROR = "GET_ORIGINS_ERROR";

export const GET_DESTINATIONS = "GET_DESTINATIONS";

export const GET_TRANSFER_FILE = "GET_TRANSFER_FILE";
export const GET_TRANSFER_FILE_SUCCESS = "GET_TRANSFER_FILE_SUCCESS";
export const GET_TRANSFER_FILE_ERROR = "GET_TRANSFER_FILE_ERROR";

export const GET_TRANSFER_EMAIL = "GET_TRANSFER_EMAIL";
export const GET_TRANSFER_EMAIL_SUCCESS = "GET_TRANSFER_EMAIL_SUCCESS";
export const GET_TRANSFER_EMAIL_ERROR = "GET_TRANSFER_EMAIL_ERROR";

export const POST_QUICK_TRANSFER = "POST_QUICK_TRANSFER";
export const POST_QUICK_TRANSFER_SUCCESS = "POST_QUICK_TRANSFER_SUCCESS";
export const POST_QUICK_TRANSFER_ERROR = "POST_QUICK_TRANSFER_ERROR";

export const PUT_KILOMETERS = "PUT_KILOMETERS";
export const PUT_KILOMETERS_SUCCESS = "PUT_KILOMETERS_SUCCESS";
export const PUT_KILOMETERS_ERROR = "PUT_KILOMETERS_ERROR";
