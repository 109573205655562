<template>
  <b-modal
    :id="'work-order-files-modal' + workOrder.id"
    ref="modal"
    centered
    no-close-on-backdrop
    scrollable
    size="lg"
    title="Work order"
    hide-footer
  >
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <b-row>
          <b-col cols="12" class="mr-0">
            <b-form-group label="Quote">
              <b-input-group>
                <b-form-file
                  id="file-contract"
                  v-model="form.quote"
                  accept=".pdf"
                />
                <b-input-group-append
                  v-if="workOrder.quote && form.quote == null"
                >
                  <b-button variant="none">
                    <a :href="workOrder.quote" target="_blank">
                      <b-icon icon="eye" variant="dark-green" />
                    </a>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>

      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <div class="row">
          <b-col cols="12" class="mr-0">
            <b-form-group label="Bill">
              <b-input-group>
                <b-form-file id="file-bill" v-model="form.bill" accept=".pdf" />
                <b-input-group-append
                  v-if="workOrder.bill && form.bill == null"
                >
                  <b-button variant="none">
                    <a :href="workOrder.bill" target="_blank">
                      <b-icon icon="eye" variant="dark-green" />
                    </a>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </div>
      </b-col>
    </b-row>
    <div class="text-right mt-2">
      <b-button variant="success" @click="save()">
        <b-spinner
          v-if="workOrderStatus === 'fetching'"
          variant="white"
          small
        />
        <span v-else>Submit</span>
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import { POST_WORK_ORDER } from "./actions";
export default {
  props: ["workOrder", "refreshData"],
  computed: {
    ...mapState({
      workOrderStatus: (state) =>
        state.superAdminStore.componentStore.workOrder.workOrder.status,
    }),
  },
  data() {
    return {
      form: {
        vehicleId: null,
        quote: null,
        bill: null,
      },
    };
  },
  methods: {
    save() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_WORK_ORDER, payload)
        .then(() => {
          this.form = {};
          this.refreshData();
          this.toastMessage(
            "Information updated successfully",
            "Success",
            "success"
          );

          this.$refs.modal.hide();
        })
        .catch(() => {
          this.toastMessage("Information could not saved", "Error", "warning");
        });
    },
    formatPayload() {
      const data = new FormData();
      data.append("quote", this.form.quote);
      data.append("bill", this.form.bill);
      data.set("id", this.workOrder.id);
      return data;
    },
  },
};
</script>

<style>
</style>