<template>
  <FilterTypeDate fromType="paymentsFrom" toType="paymentsTo" />
</template>

<script>
import FilterTypeDate from "./FilterTypeDate.vue";
export default {
  name: "PaymentsDaysFilter",
  components: { FilterTypeDate },
};
</script>

<style>
</style>