import * as constants from "./actions";
import axios from "@/plugins/axios.js";
import notes from "./notes/store"
function getDefaultState() {
  return {
    policeBook: {
      status: null,
      data: {},
      error: null,
    },
  };
}

const actions = {
  [constants.GET_POLICE_BOOK]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_POLICE_BOOK);
      const response = await axios.get(`vehiclesSales/policeBook`, {
        params: payload,
      });
      commit(constants.GET_POLICE_BOOK_SUCCESS, response);
      return response;
    } catch (error) {
      console.log("error", error);
      commit(constants.GET_POLICE_BOOK_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_POLICE_BOOK]: (state) => {
    state.policeBook.status = "fetching";
    state.policeBook.error = null;
  },
  [constants.GET_POLICE_BOOK_SUCCESS]: (state, data) => {
    state.policeBook.status = "success";
    state.policeBook.error = null;
    state.policeBook.data = data;
  },
  [constants.GET_POLICE_BOOK_ERROR]: (state, error) => {
    state.policeBook.status = "error";
    state.policeBook.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
  modules: { notes }
};
