<template>
  <div>
    <b-form @submit.stop.prevent="getTrims">
      <!--       {{ makesModelsAndColors }}
 -->
      <b-row>
        <b-col cols="12">
          <label class="mb-0 mt-1" for="">year</label>
          <b-input type="number" v-model="form.year" />
        </b-col>
        <b-col cols="12">
          <!-- makes -->
          <label for="" class="mt-1 mb-0">Make</label>
          <vue-single-select
            :classes="{ input: 'form-control ', dropdown: 'dropdown' }"
            v-model="form.make"
            menu-class="w-100"
            :options="makesModelsAndColors.make"
          >
            <template slot="option" slot-scope="{ option }">
              <div
                @click="
                  make = option;
                  searchModel(option);
                "
              >
                <span>
                  {{ option }}
                </span>
              </div>
            </template>
          </vue-single-select>
        </b-col>

        <b-col cols="12">
          <!-- Models -->
          <label for="" class="mt-1 mb-0">Model</label>

          <vue-single-select
            :classes="{ input: 'form-control', dropdown: 'dropdown' }"
            v-model="form.model"
            menu-class="w-100"
            :options="makesModelsAndColors.model"
          >
            <template slot="option" slot-scope="{ option }">
              <div @click="form.model = option">
                <span>
                  {{ option }}
                </span>
              </div>
            </template>
          </vue-single-select>
        </b-col>

        <b-col cols="12">
          <label class="mb-0 mt-1" for="">Kms</label>
          <b-input type="number" v-model="form.kms" />
        </b-col>
        <b-col cols="12">
          <label class="mb-0 mt-1" for="">Pc</label>
          <b-input v-model="form.pc" />
        </b-col>
      </b-row>
      <b-col cols="12" class="text-center mt-2">
        <b-button
          :disabled="trims.status == 'fetching'"
          class="shadow"
          variant="success"
          size="lg"
          type="submit"
          >Submit</b-button
        >
      </b-col>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VueSingleSelect from "vue-single-select";
import { GET_TRIMS_BY_PRICING, SET_TRIMS_FILTER } from "../actions";

export default {
  name: "DrillDown",
  components: { VueSingleSelect },
  computed: {
    ...mapState({
      makesModelsAndColors: (state) => state.vehicle.makesModelsAndColors.data,
      trims: (state) => state.pricing.trims,
    }),
  },
  data() {
    return {
      form: {},
    };
  },
  created() {
    this.getInformation();
  },
  methods: {
    getInformation() {
      this.$store.dispatch("GET_MAKES_AND_MODELS").then(() => {});
    },
    searchModel(option) {
      this.$store.commit("SET_MAKES_AND_MODELS_FILTERS", {
        fields: { make: option },
      });
      this.$store
        .dispatch("GET_MAKES_AND_MODELS", { make: option })
        .then(() => {});
    },
    getTrims() {
      const payload = {
        year: this.form.year,
        make: this.form.make,
        model: this.form.model,
        kms: this.form.kms,
        pc: this.form.pc,
      };
      this.$store.commit(SET_TRIMS_FILTER, { reset: true });

      this.$store.commit(SET_TRIMS_FILTER, { fields: payload });
      this.$store
        .dispatch(GET_TRIMS_BY_PRICING)
        .then(() => {
          this.$emit("goToTrimList", true);
        })
        .catch(() => {
          this.$emit("goToTrimList", true);
        });
    },
  },
};
</script>

<style>
</style>