import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    dailyReports: {
      status: null,
      data: null,
      error: null,
    },

    dailyReportConfiguration: {
      status: null,
      data: {},
      error: null,
    },
    dailyReportExcel: {
      status: null,
      data: {},
      error: null,
    },
  };
}
const actions = {
  [constants.GET_DAILY_REPORTS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_DAILY_REPORTS);

      const response = await axios.get("report/getUserConfiguration", {
        params: payload,
      });
      commit(constants.GET_DAILY_REPORTS_SUCCESS, response);
      commit;
      return response;
    } catch (error) {
      commit(constants.GET_DAILY_REPORTS_ERROR, error);
      throw error.response;
    }
  },

  [constants.POST_DAILY_REPORTS_CONFIGURATION]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_DAILY_REPORTS_CONFIGURATION);

      const response = await axios.post(
        "report/saveUserConfiguration",
        payload
      );
      commit(constants.POST_DAILY_REPORTS_CONFIGURATION_SUCCESS, response);
      commit;
      return response;
    } catch (error) {
      commit(constants.POST_DAILY_REPORTS_CONFIGURATION_ERROR, error);
      throw error.response;
    }
  },

  [constants.GET_DAILY_REPORT_FILE_EXCEL]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_DAILY_REPORT_FILE_EXCEL);

      const response = await axios.get("report/generateAgentInventoryXls", {
        params: payload,
      });
      commit(constants.GET_DAILY_REPORT_FILE_EXCEL_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_DAILY_REPORT_FILE_EXCEL_ERROR, error);
      throw error.response;
    }
  },


};

const mutations = {
  [constants.GET_DAILY_REPORTS]: (state) => {
    state.dailyReports.data = null;
    state.dailyReports.status = "fetching";
  },
  [constants.GET_DAILY_REPORTS_SUCCESS]: (state, response) => {
    state.dailyReports.status = "success";
    state.dailyReports.data = response.data;
  },
  [constants.GET_DAILY_REPORTS_ERROR]: (state, error) => {
    state.dailyReports.status = "error";
    state.dailyReports.error = error;
  },

  [constants.POST_DAILY_REPORTS_CONFIGURATION]: (state) => {
    state.dailyReportConfiguration.data = [];
    state.dailyReportConfiguration.status = "fetching";
  },
  [constants.POST_DAILY_REPORTS_CONFIGURATION_SUCCESS]: (state, response) => {
    state.dailyReportConfiguration.status = "success";
    state.dailyReportConfiguration.data = response.data;
    state.dailyReportConfiguration.error = null;
  },
  [constants.POST_DAILY_REPORTS_CONFIGURATION_ERROR]: (state, error) => {
    state.dailyReportConfiguration.status = "error";
    state.dailyReportConfiguration.error = error;
  },
  [constants.GET_DAILY_REPORT_FILE_EXCEL]: (state) => {
    state.dailyReportExcel.data = null;
    state.dailyReportExcel.status = "fetching";
  },
  [constants.GET_DAILY_REPORT_FILE_EXCEL_SUCCESS]: (state, response) => {
    state.dailyReportExcel.status = "success";
    state.dailyReportExcel.data = response.data;
    state.dailyReportExcel.error = null;
  },
  [constants.GET_DAILY_REPORT_FILE_EXCEL_ERROR]: (state, error) => {
    state.dailyReportExcel.status = "error";
    state.dailyReportExcel.error = error;
  },



};
export default {
  state: getDefaultState(),
  actions,
  mutations,
};
