<template>
  <div>
    <div class="container-fluid mt-3">
      <b-row>
        <!--   <b-col>
          <h4 class="ml-3">Export location</h4>
        </b-col> -->
      </b-row>
      <div>
        <TableBase
          :items="exportLocations.data"
          :fields="fields"
          :options="options"
          :busy="exportLocations.status === 'fetching' ? true : false"
        >
          <template v-slot:rightSide>
            <div>
              <!--  <b-button
                v-if="userHasPermission('exportLocations', 'write')"
                variant="success"
                class="align-bottom"
                @click="showModal()"
              >
                <b-icon icon="plus" class="mt-1" />
              </b-button> -->
            </div>
          </template>
        </TableBase>
      </div>

      <b-modal
        id="detailsModal"
        ref="detailsModal"
        size="lg"
        hide-footer
        body-class=""
        ok-disabled
        @hidden="exportLocationItem = {}"
      >
        <ExportLocationRegistrationForm
          :exportLocationItem="exportLocationItem"
          :refreshData="getData"
          :hideModal="hideModal"
        />
      </b-modal>
    </div>
  </div>
</template>
<script>
import TableBase from "@/modules/shared/table.vue";
import ExportLocationRegistrationForm from "./ExportLocationRegistrationForm.vue";

import { GET_EXPORT_LOCATION, POST_EXPORT_LOCATION } from "./actions";
import { mapState } from "vuex";

import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../mixins";
export default {
  name: "ExportLocationTable",
  components: { TableBase, ExportLocationRegistrationForm },
  mixins: [generalMixin, superAdminMixin],
  computed: {
    ...mapState({
      exportLocations: (state) => state.superAdminStore.exportLocation.exportLocations,
    }),
    hasPermission() {
      return this.userHasPermission("exportLocations", "write");
    },
    fields() {
      return [
        { key: "name", label: "Name" },
        { key: "address", label: "Address" },
        {
          key: "actions",
          label: "Actions",
          note: false,
          details: true,
          validation: false,
          delete: this.hasPermission,
          locations: true,
        },
      ];
    },
  },
  data() {
    return {
      options: {
        assign: false,
        rolFilter: false,
        add: false,
        fun: this.getData,
      },
      exportLocationItem: {},
    };
  },
  mounted() {
    this.$store.commit(
      "setAddFunction",
      this.userHasPermission("exportLocations", "write") ? this.showModal : null
    );
    this.getData();
  },
  methods: {
    getData(filter = "") {
      this.$store
        .dispatch(GET_EXPORT_LOCATION, { search: filter })
        .then(() => {})
        .catch(() => {});
    },
    async deleteItem(item) {
      const confirm = await this.showConfirmDeleteBox();
      if (!confirm) return;
      const payload = { ...item };
      payload.status = 0;
      this.$store
        .dispatch(POST_EXPORT_LOCATION, payload)
        .then(() => {
          this.getData();
          this.makeToast("Success", "Fee updated successfully", "success");
        })
        .catch(() => {
          this.$root.$bvToast.toast("Fee could not be disabled", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    activateItem(item) {
      const payload = {
        id: item.id,
        status: 1,
      };
      this.$store
        .dispatch(POST_EXPORT_LOCATION, payload)
        .then(() => {
          this.makeToast("Success", "Fee updated successfully", "success");
          this.getData();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Fee could not be activated", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    showModal() {
      this.$bvModal.show("detailsModal");
    },
    editItem(item) {
      this.exportLocationItem = item;
      this.$bvModal.show("detailsModal");
    },
    changePage(page, filter = "") {
      const payload = {
        search: filter,
        page: page,
      };
      this.$store
        .dispatch(GET_EXPORT_LOCATION, payload)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },

    hideModal() {
      this.$bvModal.hide("detailsModal");
    },
    goToLocation(item) {
      this.$router.push({
        name: "superadmin.locations",
        params: { id: item.id, type: "export" },
      });
    },
  },
};
</script>
