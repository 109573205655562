<template>
  <b-form-group class="mb-2" label-class="pb-1">
    <template slot="label"> <b>Company: </b> </template>
    <vue-single-select
      :classes="{
        input: 'form-control',
        dropdown: 'dropdown p-0',
      }"
      v-model="company"
      :options="companies"
      optionLabel="companyName"
    >
    </vue-single-select>
  </b-form-group>
</template>

<script>
import { mapState } from "vuex";
import { SET_TASKS_FILTERS } from "../actions";
import VueSingleSelect from "vue-single-select";

export default {
  props: {
    filter: {
      type: Number,
      default: null,
    },
  },
  components: {
    VueSingleSelect,
  },

  name: "CompanyFilter",
  computed: {
    ...mapState({
      companies: (state) => state.superAdminStore.tasks.companies.data,
      filters: (state) => state.superAdminStore.tasks.tasks.filters,
    }),
  },
  watch: {
    company() {
      this.setCompany();
    },
  },
  data() {
    return {
      company: null,
    };
  },
  mounted() {
    this.getFilterFromStore();
  },
  methods: {
    setCompany() {
      this.$store.commit(SET_TASKS_FILTERS, {
        fields: {
          companyId: this.company?.id,
        },
      });
    },
    getFilterFromStore() {
      this.company =
        this.companies.find((company) => company.id === this.filters.companyId) || null;
    },
  },
};
</script>

<style></style>
