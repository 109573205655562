<template>
  <b-form
    container
    class="container p-4 mb-3 mt-5"
    @submit.stop.prevent="onSubmit"
  >
    <b-row>
      <b-col xl="3" />
      <b-col sm="12" md="12" lg="4" xl="6">
        <b-form-group label-size="sm">
          <template slot="label">
            <b>Email:</b>
          </template>
          <b-form-input
            v-model="form.email"
            v-model.trim="$v.form.email.$model"
            size="sm"
            autocomplete="off"
            type="email"
            :formatter="lowerCaseFormatter"
            :state="validateState('email')"
            @change="setValue('email', $event)"
          />
          <small
            :class="{
              'text-success': $v.form.email.required,
              'text-danger': !$v.form.email.required,
            }"
          >
            This field is required
            <br />
          </small>
          <small
            :class="{
              'text-success': form.email && $v.form.email.email,
              'text-danger': !form.email || !$v.form.email.email,
            }"
          >
            This must be a valid email.
          </small>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="3" />
      <b-col sm="12" md="12" xl="6">
        <b-button
          :disabled="$v.form.$invalid"
          class="float-right btn-block"
          variant="yellow"
          type="submit"
        >
          <b-spinner
            v-if="recoverPasswordStatus === 'fetching'"
            variant="white"
            label="Spinning"
            small
          />
          <span v-else> Recover password </span>
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { POST_FORGOT_PASSWORD } from "./actions";
import { required, email } from "vuelidate/lib/validators";
import { mapState } from "vuex";
export default {
  data() {
    return {
      form: {
        email: null,
      },
    };
  },
  computed: {
    ...mapState({
      recoverPasswordStatus: (state) =>
        state.auth.forgotPasswordStore.forgotPassword.status,
    }),
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    formatPayload() {
      const payload = {
        email: this.form.email,
        transcanada: true,
      };
      return payload;
    },
    resetForm() {
      this.form = {
        email: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$store
        .dispatch(POST_FORGOT_PASSWORD, this.formatPayload())
        .then(() => {
          this.resetForm();
          this.$root.$bvToast.toast("Recover password email sent", {
            title: "Success",
            variant: "success",
          });
          this.$router.push({ name: "home" });
        })
        .catch(() => {
          this.$root.$bvToast.toast("Email not found", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
  },
};
</script>

<style></style>
