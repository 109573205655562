<template>
  <div class="container-fluid" v-if="payments">
    <b-list-group v-if="isLoading" class="mt-3">
      <b-list-group-item class="text-center">
        <b-spinner variant="yellow" />
      </b-list-group-item>
    </b-list-group>
    <div v-else>
      <b-row class="my-3">
        <b-col align-self="end">
          <div class="float-right">
            <b-button
              v-if="
                (vehicle.vehicleCostDetail.cost > 0 ||
                  vehicle.vehicleCostDetail.costUsd) &&
                userHasPermission('payments', 'write')
              "
              variant="outline-yellow"
              class="pb-2 pl-2 pt-2 pr-3 mt-1 mr-4"
              @click="showModal('registerPaymentModal')"
            >
              <b-iconstack class="m-1 text-center" font-scale="1">
                <b-icon icon="cash" font-scale="1.1" />
                <b-icon
                  icon="plus-circle-fill"
                  shift-h="25"
                  shift-v="4"
                  font-scale="0.55"
                />
              </b-iconstack>
              <span class="" />
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-list-group class="mt-2 mb-2 check-list" tag="ul">
        <b-list-group-item
          v-if="payments && !payments.payments.data.length"
          class="text-center"
        >
          <b>No checks to show</b>
        </b-list-group-item>

        <b-list-group-item
          v-for="(payment, i) in payments.payments.data"
          :key="i"
          class="flex-column align-items-start"
          tag="li"
        >
          <div>
            <span>
              Registered date:
              <i>
                {{ payment.createdAt | formatDateTime }} -
                {{ payment.user.firstName }}
                {{ payment.user.lastName }}
              </i>
            </span>
          </div>

          <div class="">
            <span
              >Accounting date:
              <i
                >{{ payment.accountTime | formatDateTime }} -
                {{ payment.user.firstName }}
                {{ payment.user.lastName }}</i
              >
            </span>
          </div>
          <div>
            <span v-if="payment.logisticDeliveryDate != null">
              Delivered date
              <i
                >{{ payment.logisticDeliveryDate | formatDateTime }} -
                {{ payment.logisticDeliveryUser.firstName }}
                {{ payment.logisticDeliveryUser.lastName }}</i
              >
            </span>
          </div>
          <div class="mt-1 mb-1">
            <b-row>
              <b-col cols="10">
                <b-col> <b>Method:</b> {{ payment.paymentMethod }} </b-col>
                <b-col cols="12">
                  <b>Number:</b> {{ payment.transactionNumber }}
                </b-col>
                <b-col cols="12">
                  <b>Amount :</b> {{ totalByPayment(payment) | currency }}
                  <b-icon
                    v-if="!showBreakdown"
                    icon="arrow-down-circle"
                    class="cursor-pointer"
                    @click="toggleShowBreakdown(payment)"
                  ></b-icon>
                  <b-icon
                    v-else
                    icon="arrow-up-circle"
                    class="cursor-pointer"
                    @click="toggleShowBreakdown(payment)"
                  ></b-icon>

                  <AmountBreakdown
                    v-if="showBreakdown && paymentSelected.id == payment.id"
                    :payment="payment"
                  />
                </b-col>
              </b-col>

              <b-col cols="2">
                <div class="text-right">
                  <span
                    class="dot mr-3"
                    :class="[payment.status ? 'dot-success' : 'dot-danger']"
                  />
                  <b-form-group
                    v-if="
                      payment.status && userHasPermission('payments', 'write')
                    "
                    class="ml-1"
                  >
                    <b-form-checkbox
                      :checked="true"
                      switch
                      @change="disablePayment(payment)"
                    />
                  </b-form-group>

                  <div />
                </div>
              </b-col>
            </b-row>
          </div>
          <!-- 
          <h3 class="mb-1">
            <i>{{ payment.transactionNumber }} </i>-
            <i>{{ payment.cost | currency }} </i>
          </h3> -->
          <p v-if="userHasPermission('payments', 'write')">
            <font-awesome-icon
              v-if="payment.logisticDeliveryDate == null && payment.status != 0"
              class="icon-color cursor-pointer float-right h2"
              :icon="['far', 'check-to-slot']"
              @click="markChecksAsDelivered(payment)"
            />
          </p>
        </b-list-group-item>
      </b-list-group>
      <b-pagination
        :value="payments.payments.data.currentPage"
        :total-rows="payments.payments.data.total"
        :per-page="payments.payments.data.perPage"
        class="mt-2 float-right"
        @change="changePage($event)"
      />
    </div>

    <b-modal
      id="registerPaymentModal"
      ref="registerPaymentModal"
      size="lg"
      hide-footer
      body-class="pl-0 pr-0"
      ok-disabled
      @hidden="payment = {}"
    >
      <RegisterPayment
        :payment="this.paymentItem"
        :refresh-data="getPayments"
        :hide-modal="hideModal"
      />
    </b-modal>
  </div>
</template>

<script>
/* import TableBase from "@/modules/shared/table.vue";
 */ import { generalMixin } from "@/modules/mixin.js";
import { GET_PAYMENTS, PUT_PAYMENT_DISABLE } from "./actions";
import { mapState } from "vuex";
import RegisterPayment from "@/modules/vehicle/components/payments/Register.vue";
import AmountBreakdown from "./AmountBreakdown.vue";
import { superAdminMixin } from "../../../superAdmin/mixins";
export default {
  components: { RegisterPayment, AmountBreakdown },
  mixins: [generalMixin, superAdminMixin],
  computed: {
    ...mapState({
      vehicle: (state) => state.vehicle.vehicleByVin.data.data,
      payments: (state) => state.vehicle.payments.payments.data,
      isLoading: (state) =>
        state.vehicle.payments.payments.status == "fetching",
    }),
  },
  data() {
    return {
      fields: [
        { key: "transactionType", label: "Transaction type" },
        { key: "paymentMethod", label: "Payment method" },
        { key: "transactionNumber", label: "Transaction number" },
        { key: "accountTime", label: "Account time" },
        {
          key: "actions",
          label: "Actions",
          note: false,
          details: true,
          validation: false,
        },
      ],
      options: {
        assign: true,
        rolFilter: false,
        add: false,
        fun: this.getData,
      },
      paymentItem: {},
      showBreakdown: false,
      paymentSelected: null,
    };
  },
  mounted() {
    this.getPayments();
  },
  methods: {
    getPayments() {
      this.$store
        .dispatch(GET_PAYMENTS, this.vehicle.id)
        .then(() => {})
        .catch(() => {
          this.toastMessage(
            "Payments could not be obtain",
            "Warning",
            "warning"
          );
        });
    },
    editItem(item) {
      this.paymentItem = item;
      this.showModal("registerPaymentModal");
    },
    hideModal() {
      this.$bvModal.hide("registerPaymentModal");
    },
    disablePayment(payment) {
      this.$store
        .dispatch(PUT_PAYMENT_DISABLE, payment)
        .then(() => {
          this.getPayments();
          this.toastMessage(
            "Payment disabled successfully",
            "Success",
            "success"
          );
        })
        .catch(() => {
          this.toastMessage(
            "Payments could not be obtain",
            "Warning",
            "warning"
          );
        });
    },

    markChecksAsDelivered(payment) {
      this.$store
        .dispatch("PUT_CONFIRMATION_CHECKS", {
          paymentId: payment.id,
        })
        .then(() => {
          this.toastMessage("Check marked as delivered", "Success", "success");
          this.getPayments();
        })
        .catch((error) => {
          console.log(error);
          this.toastMessage(
            "Checks could not be marked as delivered",
            "Warning",
            "warning"
          );
        });
    },
    totalByPayment(payment) {
      return payment.amount1 + payment.amount2 + payment.amount3 + payment.cost;
    },
    toggleShowBreakdown(payment) {
      this.paymentSelected = payment;
      this.showBreakdown = !this.showBreakdown;
    },
  },
};
</script>

<style></style>
