<template>
  <div
    v-touch:touchhold="touchHoldHandler"
    @contextmenu.prevent="
      vehicle.vehicleFlags.recall == 2
        ? $refs.menuTransfer.open($event, 'Payload')
        : ''
    "
  >
    <slot></slot>
    <ContextMenu v-if="vehicle.vehicleFlags.recall == 2" ref="menuTransfer">
      <template slot-scope>
        <ContextMenuItem @click.native="markTransferAsComplete()">
          Mark as complete
        </ContextMenuItem>
      </template>
    </ContextMenu>
  </div>
</template>

<script>
import { superAdminMixin } from "../../mixins";
import ContextMenu from "../contextMenu/ContextMenu.vue";
import ContextMenuItem from "../contextMenu/ContextMenuItem.vue";
import { POST_CHANGE_STATUS } from "./actions";
export default {
  name: "RecallContextMenu",
  props: ["vehicle", "refreshData"],
  mixins: [superAdminMixin],
  components: {
    ContextMenu,
    ContextMenuItem,
  },

  methods: {
    markTransferAsComplete() {
      const payload = {
        status: 2,
        vehicleId: this.vehicle.id,
      };

      this.$store
        .dispatch(POST_CHANGE_STATUS, payload)
        .then(() => {
          this.toastMessage(
            "Recall mark as completed successfully",
            "Success",
            "success"
          );
          this.$refs.menuTransfer.close();
          this.updateVehicleFlag(this.vehicle.id, "recall", 1);
        })
        .catch(() => {
          this.toastMessage(
            "recall not mark as completed",
            "Warning",
            "warning"
          );
        });
    },
    touchHoldHandler($event) {
      this.$refs.menuTransfer.open($event, "Payload");
    },
  },
};
</script>

<style>
</style>