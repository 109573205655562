<template>
  <div>
    <form @submit.stop.prevent="saveFiles()">
      <b-row>
        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="md">
            <template slot="label">
              Type <span class="text-danger">*</span>
            </template>
            <b-form-select
              id="radio-group-2"
              v-model="form.type"
              name="radio-sub-component"
              :options="types"
              required
            >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group>
            <template slot="label">
              File <span class="text-danger">*</span>
            </template>
            <b-form-file id="file" v-model="form.file" accept=".pdf" required />
          </b-form-group>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group label="Stamp date 7501">
            <b-form-input
              v-model="form.stampDate7501"
              class="text-center"
              type="date"
            />
          </b-form-group>
        </b-col>

        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group label="Received date">
            <b-form-input
              v-model="form.receivedDate"
              class="text-center"
              type="date"
            />
          </b-form-group>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group>
            <template slot="label"> Title Origin In </template>
            <multiselect
              v-model="form.titleOriginIn"
              :options="states"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="false"
              placeholder="Pick some option"
              label="name"
              track-by="name"
              :preselect-first="false"
            >
              <template slot="tag" slot-scope="{ option, remove }"
                ><span class="custom__tag"
                  ><span class="">{{ option.name }}</span
                  ><span class="ml-1 custom__remove" @click="remove(option)"
                    >❌</span
                  ></span
                ></template
              >
            </multiselect>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            type="submit"
            variant="success"
            class="float-right"
            :disabled="
              !form.type || !form.file || titleFilesStatus == 'fetching'
            "
          >
            <!-- loader -->
            <b-spinner
              v-if="titleFilesStatus == 'fetching'"
              class="align-middle"
              variant="white"
            />
            <span v-else> Save </span>
          </b-button>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { POST_TITLE_FILES } from "./actions";
import Multiselect from "vue-multiselect";

export default {
  name: "TitleManagerForm",
  components: {
    Multiselect,
  },
  props: {
    refreshData: {
      type: Function,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapState({
      titleFilesStatus: (state) =>
        state.superAdminStore.titleManager.title.status,
      states: (state) =>
        state.superAdminStore.componentStore.exports.states.data,
    }),
  },
  data() {
    return {
      form: {
        type: null,
        file: null,
        stampDate7501: null,
        receivedDate: null,
        titleOriginIn: null,
      },
      types: [
        { value: "file7501", text: "File 7501" },
        { value: "titleFile", text: "Title " },
        { value: "titleFileOut", text: "Title Reassigned" },
        { value: "sellContract", text: "Sell contract" },
        { value: "auctionSales", text: "Auction Sales" },
      ],
    };
  },
  mounted() {
    this.getStates();
  },

  methods: {
    saveFiles() {
      const payload = this.formatPayload();
      this.$store.dispatch(POST_TITLE_FILES, payload).then(() => {
        this.resetForm();
        this.refreshData();
        this.closeModal();
        this.$bvToast.toast("Files uploaded successfully", {
          title: "Success",
          variant: "success",
          solid: true,
        });
      });
    },
    formatPayload() {
      const formData = new FormData();
      formData.set("type", this.form.type);
      formData.append("file", this.form.file);
      formData.set("stampDate7501", this.form.stampDate7501);
      formData.set("receivedDate", this.form.receivedDate);
      formData.set("titleOriginIn", this.form?.titleOriginIn?.name);

      return formData;
    },

    resetForm() {
      this.form = {
        type: null,
        file: null,
        stampDate7501: null,
        receivedDate: null,
        titleOriginIn: null,
      };
    },
    getStates() {
      this.$store.dispatch("GET_TITLE_ORIGIN").then(() => {});
    },
  },
};
</script>

<style></style>
