<template>
  <b-container fluid>
    <!-- back button -->
    <div class="d-flex justify-content-between mb-3">
      <b-button
        variant="outline-secondary"
        @click="$router.push({ name: 'superadmin.run-list' })"
      >
        <i class="fas fa-arrow-left"></i> Back
      </b-button>
      <!-- download xls button -->
      <b-button
        variant="success"
        @click="downloadXLS()"
        class="text-white mt-2"
        :disabled="isLoadingVehiclesXLS"
      >
        <b-spinner
          v-if="isLoadingVehiclesXLS"
          class="align-middle"
          variant="white"
          small
        />
        <b-icon
          v-else
          icon="file-earmark-excel"
          class="mx-2 cursor-pointer"
        ></b-icon>
      </b-button>
    </div>
    <div class="table-responsive">
      <b-table
        class="mt-3"
        show-empty
        bordered
        small
        stacked="md"
        head-variant="light"
        :items="runListsVehicles.data || []"
        :fields="fields"
        :busy="runListsStatus == 'fetching'"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" variant="yellow" />
          </div>
        </template>
      </b-table>
    </div>
    <b-pagination
      v-if="
        runListsStatus != 'fetching' &&
        runListsVehicles &&
        runListsVehicles.data.length > 0
      "
      :value="runListsVehicles ? runListsVehicles.currentPage : 1"
      :total-rows="runListsVehicles ? runListsVehicles.total : 0"
      :per-page="runListsVehicles ? runListsVehicles.perPage : 25"
      class="mb-2"
      align="end"
      @change="changePage($event)"
    />
  </b-container>
</template>

<script>
import { mapState } from "vuex";
import { GET_RUN_LIST_VEHICLES, GET_RUN_LIST_EXCEL } from "./actions";
export default {
  name: "RunListVehiclesTable",
  computed: {
    ...mapState({
      runListsVehicles: (state) =>
        state.superAdminStore.runList.runListVehicles.data,
      runListsStatus: (state) =>
        state.superAdminStore.runList.runListVehicles.status,
    }),
  },
  data() {
    return {
      fields: [
        { key: "marketPlace", label: "Marketplace", class: "text-center" },
        { key: "agent", label: "Agent", class: "text-center" },
        { key: "vinNumber", label: "VIN", class: "text-center" },
        { key: "stockNumber", label: "Stock ", class: "text-center" },
        {
          key: "stockDays",
          label: "Stock days ",
          class: "text-center",
          formatter: (value) => this.$options.filters.formatNumber(value),
        },

        { key: "year", label: "Year", class: "text-center" },
        { key: "make", label: "Make", class: "text-center" },
        { key: "model", label: "Model", class: "text-center" },
        { key: "color", label: "Color", class: "text-center" },
        {
          key: "kms",
          label: "KMS",
          class: "text-right",
          formatter: (value) => this.$options.filters.formatNumber(value),
        },
        {
          key: "miles",
          label: "Miles",
          class: "text-right",
          formatter: (value) => this.$options.filters.formatNumber(value),
        },
        {
          key: "cost",
          label: "Cost CAD",
          class: "text-right",

          formatter: (value) => this.$options.filters.currency(value),
        },
        {
          key: "totalAddonsCAD",
          label: "Addons CAD",
          class: "text-right",
          formatter: (value) => this.$options.filters.currency(value),
        },
        {
          key: "fx",
          label: "FX",
          class: "text-center",
        },
        {
          key: "costUsd",
          label: "Cost USD",
          class: "text-right",

          formatter: (value) => this.$options.filters.currency(value),
        },
        {
          key: "totalAddonsUSD",
          label: "Addons USD",
          class: "text-right",
          formatter: (value) => this.$options.filters.currency(value),
        },
      ],
      isLoadingVehiclesXLS: false,
    };
  },
  mounted() {
    this.$store.commit("setAddFunction", null);
    this.$store.commit("setSearchFunction", this.getData);
    this.getData();
  },
  methods: {
    getData(search = "", page = 1) {
      const params = {
        search,
        page,
        runListId: this.$route.params.id,
      };
      this.$store.dispatch(GET_RUN_LIST_VEHICLES, params);
    },
    changePage(page) {
      this.$store.dispatch(GET_RUN_LIST_VEHICLES, {
        page,
      });
    },
    downloadXLS() {
      this.isLoadingVehiclesXLS = true;
      this.$store
        .dispatch(GET_RUN_LIST_EXCEL, {
          runListId: this.$route.params.id,
        })
        .then((response) => {
          const blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          /* name with date */
          let date = new Date();
          let dateStr =
            date.getFullYear() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getDate();
          link.download = `run-list-${dateStr}.xls`;

          link.click();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Could not get XLS", {
            title: "Error",
            variant: "warning",
          });
        })
        .finally(() => {
          this.isLoadingVehiclesXLS = false;
        });
    },
  },
};
</script>

<style>
</style>