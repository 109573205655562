<template>
  <b-modal
    :id="id"
    ref="report-form-modal"
    hide-footer
    size="xl"
    class=""
    :title="report ? 'Edit report' : 'Create report'"
    body-class="mt-0 pt-0"
    @hidden="resetModal"
  >
    <b-container>
      <b-row>
        <b-col sm="12" md="3" lg="3" xl="3" class="border-right mh-75">
          <ReportColumns
            class="mt-3 border-top"
            @onChangeColumns="onChangeColumns"
            :report="report"
          />
        </b-col>
        <b-col sm="12" md="9" lg="9" xl="9" class="">
          <b-form>
            <b-form-group
              label="Report name"
              label-for="report-name"
              label-cols-sm="12"
              label-cols-lg="12"
              label-cols-xl="12"
              label-align-sm="top"
              label-align-lg="top"
              label-align-xl="top"
              class="mb-0"
            >
              <b-form-input
                id="report-name"
                v-model="form.name"
                placeholder="Enter report name"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label="Report type"
              label-for="report-type"
              label-cols-sm="12"
              label-cols-lg="12"
              label-cols-xl="12"
              label-align-sm="top"
              label-align-lg="top"
              label-align-xl="top"
              class="mb-0"
            >
              <b-form-select
                id="report-type"
                v-model="form.isPublic"
                :options="[
                  { value: 0, text: 'Private' },
                  { value: 1, text: 'Public' },
                ]"
                required
              ></b-form-select>
            </b-form-group>
          </b-form>
          <!--           <ReportTablePreview :fields="form.columns" :report="form" />
 -->
        </b-col>
        <b-col cols="12">
          <b-button variant="primary" class="float-right" @click="saveReport()">
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import { POST_CUSTOM_REPORT } from "./actions";
import ReportColumns from "./ReportColumns.vue";
/* import ReportTablePreview from "./ReportTablePreview.vue";
 */ export default {
  name: "ReportFormModal",
  components: {
    /* ReportTablePreview */
    ReportColumns,
  },
  props: {
    id: {
      type: String,
      default: "report-form-modal",
    },
    report: {
      type: Object,
      default: null,
    },
    refresh: {
      type: Function,
      default: function () {},
    },
  },
  watch: {
    report: {
      handler(report) {
        if (report) {
          this.formatForm();
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      form: {
        name: null,
        isPublic: 0,
        columns: [],
      },
    };
  },

  methods: {
    onChangeColumns(columns) {
      this.form.columns = columns;
    },
    saveReport() {
      const payload = { ...this.form, id: this.report ? this.report.id : null };
      this.$store
        .dispatch(POST_CUSTOM_REPORT, payload)
        .then(() => {
          this.refresh();

          this.$bvToast.toast("Report created successfully", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.$emit("onChangeColumns", this.form.columns);
          this.$bvModal.hide(this.id);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetModal() {
      this.form = {
        name: null,
        isPublic: 0,
        columns: [],
      };
    },
    formatForm() {
      this.form = {
        name: this.report.name,
        isPublic: this.report.isPublic,
        columns: this.report.columns,
      };
    },
  },
};
</script>

<style scoped>
.mh-75 {
  max-height: 75vh;
}
</style>