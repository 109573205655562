import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    superAdmins: {
      status: null,
      data: [],
      error: null,
    },

    superAdmin: {
      status: null,
      data: {},
      error: null,
    },
  };
}

const actions = {
  [constants.GET_SUPER_ADMINS]: async ({ commit }, params) => {
    try {
      commit(constants.GET_SUPER_ADMINS);
      const response = await axios.get("corporation/getSuperAdmins", params);
      commit(constants.GET_SUPER_ADMINS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_SUPER_ADMINS_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_SUPER_ADMIN_LIST]: async ({ commit }, params) => {
    try {
      commit(constants.GET_SUPER_ADMIN_LIST);
      const response = await axios.get("corporation/listSuperAdmins", params);
      commit(constants.GET_SUPER_ADMIN_LIST_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_SUPER_ADMIN_LIST_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_SUPER_ADMIN]: async ({ commit }, params) => {
    try {
      commit(constants.POST_SUPER_ADMIN);
      const response = await axios.post(
        "corporation/createAndUpdateUser",
        params
      );
      commit(constants.POST_SUPER_ADMIN_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_SUPER_ADMIN_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_SUPER_ADMINS]: (state) => {
    state.superAdmins.status = "fetching";
    state.superAdmins.error = null;
  },
  [constants.GET_SUPER_ADMINS_SUCCESS]: (state, data) => {
    state.superAdmins.status = "success";
    state.superAdmins.error = null;
    state.superAdmins.data = data;
  },
  [constants.GET_SUPER_ADMINS_ERROR]: (state, error) => {
    state.superAdmins.status = "error";
    state.superAdmins.error = error;
  },
  [constants.GET_SUPER_ADMIN_LIST]: (state) => {
    state.superAdmins.status = "fetching";
    state.superAdmins.error = null;
  },
  [constants.GET_SUPER_ADMIN_LIST_SUCCESS]: (state, data) => {
    state.superAdmins.status = "success";
    state.superAdmins.error = null;
    state.superAdmins.data = data;
  },
  [constants.GET_SUPER_ADMIN_LIST_ERROR]: (state, error) => {
    state.superAdmins.status = "error";
    state.superAdmins.error = error;
  },

  [constants.POST_SUPER_ADMIN]: (state) => {
    state.superAdmin.status = "fetching";
    state.superAdmin.error = null;
  },
  [constants.POST_SUPER_ADMIN_SUCCESS]: (state, data) => {
    state.superAdmin.status = "success";
    state.superAdmin.error = null;
    state.superAdmin.data = data;
  },
  [constants.POST_SUPER_ADMIN_ERROR]: (state, error) => {
    state.superAdmin.status = "error";
    state.superAdmin.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
