<template>
  <div class="container">
    <div>
      <b-form class="m-4" @submit.stop.prevent="form.id ? update() : save()">
        <h3>Register payment</h3>
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
            <!-- Payment Method -->
            <b-form-group label-size="sm">
              <template slot="label">
                Payment Method <span class="text-danger">*</span>
              </template>
              <b-form-select
                v-model="form.paymentMethod"
                :disabled="payment.id"
                class="mb-3"
              >
                <b-form-select-option :value="null">
                  Please select an option
                </b-form-select-option>
                <b-form-select-option value="Check"> Check </b-form-select-option>
                <b-form-select-option value="Wire"> Wire </b-form-select-option>
                <b-form-select-option value="ETF"> ETF </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group label-size="sm">
              <template> Account date <span class="text-danger">*</span> </template>
              <input
                v-if="!payment.id"
                v-model="form.accountTime"
                type="datetime-local"
                class="form-control"
              />
              <span v-else>
                <br class="mt-2" />
                {{ form.accountTime | formatDateTime }}</span
              >
            </b-form-group>
          </b-col>

          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label-size="sm">
              <template slot="label">
                Transaction number <span class="text-danger">*</span>
              </template>
              <b-form-input
                v-model.trim="form.transactionNumber"
                size="md"
                :readonly="payment.id"
                aria-describedby="input-firstName-live-feedback"
                maxlength="100"
              />
              <b-form-invalid-feedback id="input-firstName-live-feedback">
                This is a required field and must at least 2 characters.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label-size="sm">
              <template slot="label"> Cost </template>
              <Money
                v-model="form.cost"
                :readonly="vehicle.vehicleCostDetail.cost != 0"
                class="form-control text-center"
                v-bind="money"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div>
          <TaxTable :taxes="taxes.taxes" @update-select-taxes="setTaxesSelected" />
        </div>
        <div>
          <Breakdown :sellPrice="form.cost" :taxes="taxesSelected">
            <template slot="initial-label">
              <span>Initial cost</span>
            </template>
            <template slot="final-label">
              <span>Final cost</span>
            </template>
          </Breakdown>
        </div>
        <b-row v-if="!payment.id">
          <b-col sm="12" md="6" lg="6" xl="6" />
          <b-col sm="12" md="3" lg="3" xl="3" />
          <b-col sm="12" md="3" lg="3" xl="3">
            <b-btn block size="lg" class="btn btn-success" type="submit">
              Submit
              <b-spinner v-if="isLoading" small variant="white" />
            </b-btn>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <div />
  </div>
</template>

<script>
import { POST_PAYMENT, GET_PAYMENTS_TAXES, GET_VEHICLE_COST } from "./actions";
import { mapState } from "vuex";
import { Money } from "v-money";
import TaxTable from "@/modules/superAdmin/components/sold/TaxTable.vue";
import Breakdown from "@/modules/superAdmin/components/sold/Breakdown.vue";

export default {
  components: { Money, TaxTable, Breakdown },
  props: ["payment", "refreshData", "hideModal"],
  computed: {
    ...mapState({
      vehicle: (state) => state.vehicle.vehicleByVin.data.data,
      paymentState: (state) => state.vehicle.payments.payment.data,
      taxes: (state) => state.vehicle.payments.taxes.data,
      profile: (state) => state.auth.loginStore.profile.data,
    }),
    isLoading() {
      return this.paymentState.status == "fetching" ? true : false;
    },
    corporation() {
      const corporation = localStorage.getItem("corporation");
      let corporationSelect = this.profile.user.corporationPermissions.find(
        (c) => c.id == corporation
      );

      return corporationSelect
        ? corporationSelect
        : this.profile.user.corporationPermissions[0];
    },
    money() {
      return {
        decimal: ".",
        thousands: ",",
        prefix: this.corporation.typeCurrency + " " + "$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      };
    },
  },
  data() {
    return {
      form: {
        paymentMethod: "",
        transactionType: "incoming",
        accountTime: null,
        transactionNumber: "",
        cost: 0,
      },
      taxesSelected: [],
    };
  },
  mounted() {
    if (this.payment) {
      this.formatForm();
      this.setDefaultExpirationDate();
    }
    this.getTaxes();
    this.getVehicleCost();
  },
  methods: {
    formatForm() {
      this.form.paymentMethod = this.payment.paymentMethod;
      this.form.transactionNumber = this.payment.transactionNumber;
      this.form.accountTime = new Date(this.payment.accountTime);
      this.form.cost = this.vehicle.vehicleCostDetail.cost;
    },
    save() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_PAYMENT, payload)
        .then(() => {
          this.toastMessage("Payment registered successfully", "Success", "success");

          this.hideModal();
          this.refreshData();
        })
        .catch(() => {
          this.toastMessage("The payment could not be registered", "Warning", "warning");
        });
    },
    formatPayload() {
      const payload = {
        paymentMethod: this.form.paymentMethod,
        transactionType: "incoming",
        accountTime: this.form.accountTime,
        transactionNumber: this.form.transactionNumber,
        id: this.vehicle.id,
        cost: this.form.cost,
        tax1: this.taxesSelected[0] ? this.taxesSelected[0] : null,
        tax2: this.taxesSelected[1] ? this.taxesSelected[1] : null,
        tax3: this.taxesSelected[2] ? this.taxesSelected[2] : null,
      };
      return payload;
    },
    getTaxes() {
      this.$store.dispatch(GET_PAYMENTS_TAXES, { id: this.vehicle.id }).then(() => {});
    },
    setTaxesSelected(taxes) {
      this.taxesSelected = taxes;
    },
    setDefaultExpirationDate() {
      var myCurrentDate = new Date();
      var myFutureDate = new Date(myCurrentDate);
      myFutureDate.setDate(myFutureDate.getDate());

      this.form.accountTime = this.$options.filters.formatDateTimeInput(myFutureDate);
    },
    getVehicleCost() {
      this.$store.dispatch(GET_VEHICLE_COST, { id: this.vehicle.id }).then((response) => {
        this.form.cost = response.cost;
      });
    },
  },
};
</script>

<style></style>
