<template>
  <div>
    <b-form-group label-size="sm">
      <template slot="label"> From </template>
      <b-form-input
        v-model="from"
        size="sm"
        type="date"
        @change="applyFilter()"
        :max="to"
      />
    </b-form-group>
    <b-form-group label-size="sm">
      <template slot="label"> To: </template>
      <b-form-input
        v-model="to"
        size="sm"
        type="date"
        @change="applyFilter()"
        :min="from"
      />
    </b-form-group>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SET_VEHICLES_INFO_FILTERS } from "../actions";

export default {
  props: {
    fromType: {
      required: true,
    },
    toType: {
      required: true,
    },
  },
  data() {
    return {
      from: null,
      to: null,
    };
  },
  created() {
    this.getFilters();
  },
  computed: {
    ...mapState({
      filters: (state) => state.superAdminStore.vehiclesInfo.filters,
    }),
  },
  watch: {
    filters() {
      this.getFilters();
    },
  },
  methods: {
    applyFilter() {
      this.$store.commit(SET_VEHICLES_INFO_FILTERS, {
        fields: {
          [this.fromType]: this.from ? this.from : null,
          [this.toType]: this.to ? this.to : null,
        },
      });
    },
    getFilters() {
      this.from = this.filters[this.fromType]
        ? this.filters[this.fromType]
        : null;
      this.to = this.filters[this.toType] ? this.filters[this.toType] : null;
    },
  },
};
</script>

<style>
</style>