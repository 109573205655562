<template>
  <div>
    <div class="container-fluid">
      <b-row class="mt-3">
        <b-col>
          <h4 class="ml-3">Active Checks</h4>
        </b-col>
      </b-row>
      <div v-if="totalMoneySelected > 0" class="text-right">
        Total selected: {{ calculateTotal() | currency }}
      </div>
      <b-row class="my-2">
        <b-col xs="12" sm="12" md="12" lg="4" xl="4" class="text-center mt-4">
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="4" xl="4" class="text-center mt-4">
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="4" xl="4" class="text-right mt-4">
          <b-button
            v-if="userHasPermission('buyChecks', 'write')"
            variant="success"
            @click="markChecksAsDelivered()"
          >
            Mark as delivered
          </b-button>
        </b-col>
      </b-row>
      <div>
        <b-table
          show-empty
          bordered
          small
          stacked="md"
          head-variant="light"
          :items="
            (checks.data &&
              checks.data.payments &&
              checks.data.payments.data) ||
            []
          "
          :fields="fields"
          :busy="checks.status === 'fetching'"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" variant="yellow" />
            </div>
          </template>
          <template #cell(selected)="{ item }">
            <div class="d-flex justify-content-center">
              <b-form-checkbox
                :disabled="!userHasPermission('buyChecks', 'write')"
                @change="(val) => onRowSelected(val, item)"
              />
            </div>
          </template>
          <template v-slot:cell(cost)="row">
            <div class="">
              {{ row.item.cost | currency }}
            </div>
          </template>
        </b-table>
        <div style="float: right">
          <b-pagination
            v-if="checks.status != 'fetching'"
            :value="
              (checks.data &&
                checks.data.payments &&
                checks.data.payments.currentPage) ||
              1
            "
            :total-rows="
              (checks.data &&
                checks.data.payments &&
                checks.data.payments.total) ||
              0
            "
            :per-page="
              (checks.data &&
                checks.data.payments &&
                checks.data.payments.perPage) ||
              0
            "
            class="mb-2"
            @change="changePage($event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { generalMixin } from "@/modules/mixin.js";
import { mapState } from "vuex";
import { GET_CONFIRMATION_CHECKS, PUT_CONFIRMATION_CHECKS } from "../actions";
import { superAdminMixin } from "../../mixins";
export default {
  name: "CheckConfirmationTable",
  mixins: [generalMixin, superAdminMixin],
  computed: {
    ...mapState({
      checks: (state) => state.superAdminStore.confirmationChecks.checks,
    }),
    totalMoneySelected() {
      return this.calculateTotal();
    },
  },
  data() {
    return {
      fields: [
        "selected",
        { key: "stockNumber", label: "Stock number" },
        { key: "transactionNumber", label: "Transaction Number" },
        { key: "cost", label: "Cost" },
      ],
      options: {
        assign: false,
        rolFilter: false,
        add: false,
      },
      rowsSelected: [],
      itemsSelected: [],
      checkStatus: 1,
    };
  },
  methods: {
    getSelectedItems(items) {
      this.rowsSelected = items;
    },
    onRowSelected(val, item) {
      if (val) {
        this.itemsSelected.push(item);
      } else {
        const index = this.itemsSelected.indexOf(item);
        this.itemsSelected.splice(index, 1);
      }
      this.getSelectedItems([...this.itemsSelected]);
    },
    getData() {
      this.$store
        .dispatch(GET_CONFIRMATION_CHECKS, { status: this.checkStatus })
        .then(() => {})
        .catch(() => {
          this.toastMessage("Checks could not be obtain", "Warning", "warning");
        });
    },
    markChecksAsDelivered() {
      this.$store
        .dispatch(PUT_CONFIRMATION_CHECKS, {
          payments: [...this.itemsSelected],
        })
        .then(() => {
          this.toastMessage("Checks marked as delivered", "Success", "success");
          this.getData();
        })
        .catch(() => {
          this.toastMessage(
            "Checks could not be marked as delivered",
            "Warning",
            "warning"
          );
        });
    },
    changePage(page, filter = "") {
      const payload = {
        search: filter,
        page: page,
        status: this.checkStatus,
      };
      this.$store
        .dispatch(GET_CONFIRMATION_CHECKS, payload)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    calculateTotal() {
      let total = 0;
      this.itemsSelected.forEach((item) => {
        total += item.cost;
      });
      return total;
    },
  },
  mounted() {
    this.$store.commit("setAddFunction", null);
    this.getData();
  },
};
</script>
