<template>
  <div>
    <b-form-group label-size="sm">
      <b-form-group>
        <b-form-radio
          v-model="hasPrice"
          name="some-radios"
          :value="false"
          @change="applyFilter()"
          >No price</b-form-radio
        >
        <b-form-radio
          v-model="hasPrice"
          name="some-radios"
          :value="true"
          @change="applyFilter()"
          >Price</b-form-radio
        >
      </b-form-group>
    </b-form-group>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SET_VEHICLES_INFO_FILTERS } from "../actions";

export default {
  data() {
    return {
      hasPrice: null,
    };
  },
  created() {
    this.getFilters();
  },
  computed: {
    ...mapState({
      filters: (state) => state.superAdminStore.vehiclesInfo.filters,
    }),
  },
  methods: {
    applyFilter() {
      this.$store.commit(SET_VEHICLES_INFO_FILTERS, {
        fields: {
          prices: this.hasPrice ?? this.hasPrice,
        },
      });
    },
    getFilters() {
      this.hasPrice = this.filters.prices;
    },
  },
};
</script>

<style>
</style>