<template>
  <div>
    <div class="my-5">
      <b-container>
        <b-row v-if="!showBarCodeReader" class="mt-5 justify-content-md-center">
          <b-col
            class="col-lg-6"
            :class="{
              'justify-content-end': !isSmallScreen,
              'justify-content-center': isSmallScreen,
            }"
          >
            <!--<hr v-if="!isSmallScreen" id="divider" class="shadow" />-->

            <div class="mt-3">
              <b-row>
                <b-col class="d-flex justify-content-center">
                  <img
                    class="img-logo mb-5 img-fluid"
                    src="@/assets/img/logos/QAS.png"
                    alt="logo"
                  />
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col class="d-flex justify-content-center">
                  <b-form @submit.stop.prevent="searchVehiclesValidation()">
                    <table>
                      <tr>
                        <th>
                          <b-form-input
                            v-model="vinNumber"
                            class="input-border-yellow"
                            type="text"
                            placeholder="Vin Number"
                            required
                          />
                        </th>
                        <th>
                          <b-button
                            :disabled="
                              postVehicleStatus === 'fetching' ||
                              vehiclesToValidateStatus == 'fetching' ||
                              vehicleCreationStatus == 'fetching' ||
                              isLoading
                            "
                            type="submit"
                            variant="yellow"
                          >
                            <b-spinner
                              v-if="
                                postVehicleStatus === 'fetching' ||
                                vehiclesToValidateStatus == 'fetching' ||
                                vehicleCreationStatus == 'fetching' ||
                                isLoading
                              "
                              variant="white"
                              label="Spinning"
                              small
                            />
                            <span v-else class="text-white">Go</span>
                          </b-button>
                        </th>
                        <th>
                          <b-button
                            variant="dark-green"
                            @click="toggleBarCodeReader(true)"
                          >
                            <b-icon icon="camera" variant="" />
                          </b-button>
                        </th>
                      </tr>
                    </table>
                  </b-form>
                  <br />
                </b-col>
                <b-col cols="12" class="">
                  <div v-if="vehiclesToValidate" class="float-right my-2">
                    <b-button
                      variant="success"
                      class="text-white"
                      size="sm"
                      block
                      @click="createNewVehicleRegistration()"
                    >
                      Create a new record
                    </b-button>
                  </div>
                  <br />
                  <br />
                  <VehiclesValidationList
                    class="mt-2"
                    v-if="vehiclesToValidate"
                    :vehiclesToValidate="vehiclesToValidate.vehicles"
                  />
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>

      <BarCodeReader
        v-if="showBarCodeReader"
        @decode="onDecode($event)"
        @onCancel="toggleBarCodeReader($event)"
      />
      <!--   <div class="mt-5">
        <CarsTable :vehicles="vehicles" v-if="vehicles.length > 0" />
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { vehicleMixin } from "../mixin";
import { generalMixin } from "@/modules/mixin.js";
import BarCodeReader from "@/modules/shared/BarCodeReader";
import {
  GET_RE_STOCK_VALIDATE,
  GET_RE_STOCK_VALIDATE_SUCCESS,
  POST_NEW_VEHICLE_REGISTRATION,
} from "../actions";
import VehiclesValidationList from "./vehicles-validation/VehiclesValidationList.vue";
/* import CarsTable from "./CarsTable.vue";
 */ export default {
  components: {
    BarCodeReader,
    VehiclesValidationList,
  },
  mixins: [generalMixin, vehicleMixin],
  props: {
    showProgressBar: {
      type: Boolean,
      default: true,
    },
    redirectToTable: {
      type: Boolean,
      default: false,
    },
    closeUploadVehicleModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vinNumber: "",
      showBarCodeReader: false,
      isLoading: false,
    };
  },
  computed: {
    isSmallScreen() {
      return this.$vssWidth <= 990;
    },
    ...mapState({
      postVehicleStatus: (state) =>
        state.adminStore.vehicles.carRegistry.vehicle.status,
      vehicles: (state) => state.vehicle.vehicles.data,
      vehiclesToValidate: (state) => state.vehicle.vehiclesValidation.data,
      vehiclesToValidateStatus: (state) =>
        state.vehicle.vehiclesValidation.status,
      vehicleCreationStatus: (state) => state.vehicle.vehicleByVin.status,
    }),
  },
  created() {
    this.clearVehiclesToValidate();
  },
  methods: {
    goTo(stock) {
      this.$router.push({ path: "/vehicle-search/stock/" + stock });
    },
    searchVehiclesValidation() {
      this.isLoading = true;
      const payload = {
        vinNumber: this.vinNumber,
      };
      this.$store
        .dispatch(GET_RE_STOCK_VALIDATE, payload)
        .then((response) => {
          this.isLoading = false;

          if (!response.exists) {
            this.createNewVehicleRegistration();
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    createNewVehicleRegistration() {
      this.isLoading = true;
      this.$store
        .dispatch(POST_NEW_VEHICLE_REGISTRATION, {
          vinNumber: this.vinNumber,
        })
        .then((response) => {
          this.isLoading = false;
          this.goTo(response.data.stockNumber);
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.$bvToast.toast(
            error.data.message.charAt(0).toUpperCase() +
              error.data.message.slice(1),
            {
              title: "Error",
              variant: "warning",
            }
          );
        });
    },

    onDecode(code) {
      this.vinNumber = code;
      this.toggleBarCodeReader(false);
    },
    toggleBarCodeReader(show) {
      this.showBarCodeReader = show;
    },
    clearVehiclesToValidate() {
      this.$store.commit(GET_RE_STOCK_VALIDATE_SUCCESS, null);
    },
  },
};
</script>

<style scoped>
#divider {
  border: none;
  border-left: 1px solid hsla(200, 10%, 50%, 100);
  height: 27vh;
  width: 1px;
  background-color: #f2f2f2;
}
.small-container-pink {
  border: solid #df0632 1px;
  border-radius: 10px;
}
.small-container-yellow {
  border: solid #f39220 1px;
  border-radius: 15px;
}
.input-border-pink {
  border: solid #df0632 2px;
  border-radius: 6px;
}

.input-border-yellow {
  border: solid #f39220 2px;
  border-radius: 6px;
}
.img-logo {
  max-width: 18rem;
  max-height: 92;
}
@media screen and (min-width: 1000px) {
  .main-cont {
    padding-left: 30%;
    padding-right: 30%;
  }
}
</style>
