import SuperAdminTable from "./superAdmins/SuperAdminTable.vue";
import CorporationTable from "./corporations/CorporationsTable.vue";
import CorporationAssignTable from "./corporations/CorporationAssignUser.vue";
import MarketplaceTable from "./marketplace/MarketplaceTable.vue";
import MarketplaceAssignUser from "./marketplace/MarketplaceAssignUser.vue";
import { ifRoleAuthenticated } from "@/router/navigationGuards";
import DashboardLayout from '@/layouts/DashboardLayout.vue';

const routes = [
  {
    path: "/master",
    name: "master",

    component: DashboardLayout,
    beforeEnter: (to, from, next) => {
      ifRoleAuthenticated(to, from, next, ["ROLE_MASTER", "ROLE_SUPER_ADMIN"]);
    },
    children: [
      {
        path: "super-admin",
        name: "master.super-admin",
        component: SuperAdminTable,
        meta: {
          name: 'Super Admins',
        },
      },
      {
        path: "corporations",
        name: "master.corporation",
        component: CorporationTable,
        meta: {
          name: 'Corporations',
        },
      },
      {
        path: "corporations/assign",
        name: "master.assign.corporation",
        component: CorporationAssignTable,
        meta: {
          name: 'Assign Corporation',
        },
      },
      {
        path: "marketplace",
        name: "master.marketplace",
        component: MarketplaceTable,
        meta: {
          name: "Marketplace",
        },
      },
      {
        path: "marketplace/assign",
        name: "master.assign.marketplace",
        component: MarketplaceAssignUser,
        meta: { name: "Assign marketplace" },
      },
    ],
  },
];

export default routes;
