import * as constants from "./actions";
import axios from "@/plugins/axios.js";
function getDefaultState() {
  return {
    transferBetweenAgent: {
      status: null,
      data: {},
      error: null,
    },
    transferBetweenAgents: {
      status: null,
      data: [],
      error: null,
    },
    vehicles: {
      status: null,
      data: [],
      error: null,
    },
    agents: {
      status: null,
      data: [],
      error: null,
    },
  };
}
const actions = {
  [constants.GET_TRANSFER_BETWEEN_AGENT_LIST]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_TRANSFER_BETWEEN_AGENT_LIST);
      const response = await axios.get("addon/getTransferBetweenAgents", {
        params: payload,
      });
      commit(constants.GET_TRANSFER_BETWEEN_AGENT_LIST_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_TRANSFER_BETWEEN_AGENT_LIST_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_TRANSFER_BETWEEN_AGENT_VEHICLES]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_TRANSFER_BETWEEN_AGENT_VEHICLES);
      const response = await axios.get("addon/listVehiclesAgents", {
        params: payload,
      });
      commit(constants.GET_TRANSFER_BETWEEN_AGENT_VEHICLES_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_TRANSFER_BETWEEN_AGENT_VEHICLES_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_TRANSFER_BETWEEN_AGENT]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_TRANSFER_BETWEEN_AGENT);
      const response = await axios.post("addon/createTransferBetweenAgents", payload);
      commit(constants.POST_TRANSFER_BETWEEN_AGENT_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_TRANSFER_BETWEEN_AGENT_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_VALIDATE_TRANSFER_BETWEEN_AGENT]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_VALIDATE_TRANSFER_BETWEEN_AGENT);
      const response = await axios.put("addon/validateTransferBetweenAgent", payload);
      commit(constants.PUT_VALIDATE_TRANSFER_BETWEEN_AGENT_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_VALIDATE_TRANSFER_BETWEEN_AGENT_ERROR, error);
      throw error.response;
    }
  },
  [constants.DELETE_TRANSFER_BETWEEN_AGENT]: async ({ commit }, payload) => {
    try {
      commit(constants.DELETE_TRANSFER_BETWEEN_AGENT);
      const response = await axios.delete("addon/deleteTransferBetweenAgents", {
        params: payload,
      });
      commit(constants.DELETE_TRANSFER_BETWEEN_AGENT_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.DELETE_TRANSFER_BETWEEN_AGENT_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_TRANSFER_BETWEEN_AGENT_EXCEL]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_TRANSFER_BETWEEN_AGENT_EXCEL);
      const response = await axios.get("addon/generateTransferXls", {
        params: payload,
        responseType: "blob",
      });
      commit(constants.GET_TRANSFER_BETWEEN_AGENT_EXCEL_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_TRANSFER_BETWEEN_AGENT_EXCEL_ERROR, error);
      throw error.response;
    }
  }
};
const mutations = {
  [constants.GET_TRANSFER_BETWEEN_AGENT_LIST]: (state) => {
    state.transferBetweenAgents.data = [];
    state.transferBetweenAgents.status = "fetching";
  },
  [constants.GET_TRANSFER_BETWEEN_AGENT_LIST_SUCCESS]: (state, response) => {
    state.transferBetweenAgents.status = "success";
    state.transferBetweenAgents.data = response;
  },
  [constants.GET_TRANSFER_BETWEEN_AGENT_LIST_ERROR]: (state, error) => {
    state.transferBetweenAgents.status = "error";
    state.transferBetweenAgents.error = error;
  },
  [constants.GET_TRANSFER_BETWEEN_AGENT_VEHICLES]: (state) => {
    state.vehicles.data = [];
    state.agents.data = [];
    state.vehicles.status = "fetching";
    state.agents.status = "fetching";
  },
  [constants.GET_TRANSFER_BETWEEN_AGENT_VEHICLES_SUCCESS]: (state, response) => {
    state.vehicles.status = "success";
    state.agents.status = "success";
    state.vehicles.data = response.vehicle;
    state.agents.data = response.users;
  },
  [constants.GET_TRANSFER_BETWEEN_AGENT_VEHICLES_ERROR]: (state, error) => {
    state.vehicles.status = "error";
    state.agents.status = "error";
    state.vehicles.error = error;
    state.agents.error = error;
  },
  [constants.POST_TRANSFER_BETWEEN_AGENT]: (state) => {
    state.transferBetweenAgent.status = "fetching";
  },
  [constants.POST_TRANSFER_BETWEEN_AGENT_SUCCESS]: (state, response) => {
    state.transferBetweenAgent.status = "success";
    state.transferBetweenAgent.data = response;
  },
  [constants.POST_TRANSFER_BETWEEN_AGENT_ERROR]: (state, error) => {
    state.transferBetweenAgent.status = "error";
    state.transferBetweenAgent.error = error;
  },
  [constants.PUT_VALIDATE_TRANSFER_BETWEEN_AGENT]: (state) => {
    state.transferBetweenAgent.status = "fetching";
  },
  [constants.PUT_VALIDATE_TRANSFER_BETWEEN_AGENT_SUCCESS]: (state, response) => {
    state.transferBetweenAgent.status = "success";
    state.transferBetweenAgent.data = response;
  },
  [constants.PUT_VALIDATE_TRANSFER_BETWEEN_AGENT_ERROR]: (state, error) => {
    state.transferBetweenAgent.status = "error";
    state.transferBetweenAgent.error = error;
  },
  [constants.DELETE_TRANSFER_BETWEEN_AGENT]: (state) => {
    state.transferBetweenAgent.status = "fetching";
  },
  [constants.DELETE_TRANSFER_BETWEEN_AGENT_SUCCESS]: (state, response) => {
    state.transferBetweenAgent.status = "success";
    state.transferBetweenAgent.data = response;
  },
  [constants.DELETE_TRANSFER_BETWEEN_AGENT_ERROR]: (state, error) => {
    state.transferBetweenAgent.status = "error";
    state.transferBetweenAgent.error = error;
  },
  [constants.GET_TRANSFER_BETWEEN_AGENT_EXCEL]: (state) => {
    state.transferBetweenAgent.status = "fetching";
  },
  [constants.GET_TRANSFER_BETWEEN_AGENT_EXCEL_SUCCESS]: (state, response) => {
    state.transferBetweenAgent.status = "success";
    state.transferBetweenAgent.data = response;
  },
  [constants.GET_TRANSFER_BETWEEN_AGENT_EXCEL_ERROR]: (state, error) => {
    state.transferBetweenAgent.status = "error";
    state.transferBetweenAgent.error = error;
  },

}

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
