export const GET_DRIVER_PRICE = 'GET_DRIVER_PRICE';
export const GET_DRIVER_PRICE_SUCCESS = 'GET_DRIVER_PRICE_SUCCESS';
export const GET_DRIVER_PRICE_ERROR = 'GET_DRIVER_PRICE_ERROR';

export const POST_DRIVER_PRICE = 'POST_DRIVER_PRICE';
export const POST_DRIVER_PRICE_SUCCESS = 'POST_DRIVER_PRICE_SUCCESS';
export const POST_DRIVER_PRICE_ERROR = 'POST_DRIVER_PRICE_ERROR';

export const DELETE_DRIVER_PRICE = 'DELETE_DRIVER_PRICE';
export const DELETE_DRIVER_PRICE_SUCCESS = 'DELETE_DRIVER_PRICE_SUCCESS';
export const DELETE_DRIVER_PRICE_ERROR = 'DELETE_DRIVER_PRICE_ERROR';