<template>
  <div class="mt-3">
    <div class="container-fluid">
      <b-table
        show-empty
        bordered
        small
        stacked="md"
        head-variant="light"
        :items="titleFiles.data.data || []"
        :fields="fields"
        :busy="titleFilesStatus == 'fetching'"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" variant="yellow" />
          </div>
        </template>

        <template #cell(actions)="{ item }">
          <!-- loader -->
          <b-spinner
            v-if="item.status == 0"
            class="align-middle mx-1"
            variant="yellow"
            small
          />
          <font-awesome-icon
            v-else
            class="mx-1 cursor-pointer"
            :icon="['far', 'cars']"
            @click="goToVehiclesView(item)"
          />

          <b-icon
            class="mx-1 cursor-pointer"
            icon="eye"
            @click="openLink(item)"
            scale="1.3"
          />
        </template>
      </b-table>

      <b-pagination
        v-if="titleFilesStatus != 'fetching' && titleFiles && titleFiles.data"
        :value="titleFiles ? titleFiles.data.currentPage : 1"
        :total-rows="titleFiles ? titleFiles.data.total : 0"
        :per-page="titleFiles ? titleFiles.data.perPage : 25"
        class="mb-2"
        align="end"
        @change="changePage($event)"
      />
    </div>
    <b-modal
      id="title-manager-form-modal"
      title="Upload Files"
      size="lg"
      class="modal-dialog-centered"
      hide-footer
    >
      <TitleManagerForm
        :refreshData="getTitleFiles"
        :closeModal="closeFormModal"
      />
    </b-modal>
    <b-modal
      id="title-manager-vehicles-modal"
      title="Vehicles"
      size="lg"
      class="modal-dialog-centered"
      hide-footer
    >
      <TitleManagerVehiclesModal :title="titleSelected" />
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { GET_TITLE_FILES } from "./actions";
import TitleManagerForm from "./TitleManagerForm.vue";
import TitleManagerVehiclesModal from "./TitleManagerVehiclesModal.vue";
export default {
  name: "TitleManagerTable",
  components: {
    TitleManagerForm,
    TitleManagerVehiclesModal,
  },
  computed: {
    ...mapState({
      titleFiles: (state) => state.superAdminStore.titleManager.titles,
      titleFilesStatus: (state) =>
        state.superAdminStore.titleManager.titles.status,
      vehicles: (state) => state.superAdminStore.tasks.vehicles.data,
    }),
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Name",
          class: "text-center",
        },
        {
          key: "type",
          label: "Type",
          class: "text-center",
          formatter: (value) => {
            return this.getLabelByType(value);
          },
        },
        {
          key: "createdAt",
          label: "Created At",
          class: "text-center",
          formatter: (value) => {
            return this.$options.filters.formatDate(value);
          },
        },
        {
          key: "actions",
          label: "Actions",
          class: "text-center",
        },
      ],
      titleSelected: {},
    };
  },

  mounted() {
    this.$store.commit("setAddFunction", this.openFormModal);
    this.$store.commit("setSearchFunction", this.getTitleFiles);
    this.getTitleFiles();
  },

  methods: {
    openFormModal() {
      this.$bvModal.show("title-manager-form-modal", {
        title: "Upload Files",
        size: "xl",
        class: "modal-dialog-centered",
        hideFooter: true,
      });
    },
    getTitleFiles(search = null, page = 1) {
      this.$store
        .dispatch(GET_TITLE_FILES, {
          search,
          page,
        })
        .then(() => {});
    },

    getLabelByType(type) {
      switch (type) {
        case "file7501":
          return "File 7501";
        case "titleFile":
          return "Title file";
        case "bulkFile":
        case "titleFileOut":
          return "Title Out";
        case "sellContract":
          return "Sell contract";
        default:
          return "Bulk file";
      }
    },
    openLink(item) {
      window.open(item.file, "_blank");
    },
    openVehiclesModal(item) {
      this.titleSelected = item;
      this.$bvModal.show("title-manager-vehicles-modal");
    },
    goToVehiclesView(item) {
      this.$router.push({
        name: "superadmin.title-manager-vehicles",
        params: { id: item.id },
      });
    },
    closeFormModal() {
      this.$bvModal.hide("title-manager-form-modal");
    },
    changePage(page) {
      this.getTitleFiles(null, page);
    },
  },
};
</script>

<style></style>
