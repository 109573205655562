<template>
  <div>
    <div
      @click="openCompanyDetails()"
      class="d-flex justify-content-center text-wrap cursor-pointer"
    >
      <badge v-b-tooltip.hover title="Open company details"
        >{{ task.company.companyName }}
      </badge>
    </div>
  </div>
</template>

<script>
export default {
  name: "TaskCardCompany",
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    openCompanyDetails() {
      console.log("openCompanyDetails");
      this.$store.commit("setCompanyDetailsSideBar", {
        company: this.task.company,
        isShowing: true,
      });
    },
    open() {
      console.log("open");
    },
  },
};
</script>

<style></style>
