<template>
  <div>
    <b-tabs
      v-model="tab"
      justified
      lazy
      @input="changeTab"
    >
      <b-tab>
        <template #title>
          <router-link
            v-if="vinNumber"
            :to="vehiclePageURL"
          >
            {{ vehicleModel }}
          </router-link>

          <span v-else> {{ vehicleModel }}</span>
        </template>
        <slot name="top" />
        <div class="scroll">
          <img
            v-for="(src, index) in exterior"
            :key="`auto-exterior-${index}`"
            :src="
              imgError && imgsNotFound.find((e) => e === index) ? imgError : src
            "
            class="gallery-img img-thumbnail"
            :style="size ? size : 'width: auto'"
            @click="selectImage(index, src)"
            @error="errorLoadImg(index)"
          >
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
export default {
  name: 'CarGallery',
  components: {},
  props: [
    'mainPhoto',
    'exterior',
    'interior',
    'damages',
    'size',
    'classImg',
    'damagesDots',
    'vehicleModel',
    'vinNumber',
    'make',
    'model'
  ],
  data() {
    return {
      tab: 0,
      interiorDots: [],
      exteriorDots: [],
      currentDamage: null,
      isSmall: false,
      imgError: '',
      imgsNotFound: []
    };
  },
  computed: {
    vehiclePageURL() {
      return '/vehicle/' + this.vinNumber + '-' + this.make + '-' + this.model;
    }
  },
  mounted() {
    window.addEventListener('resize', (e) => {
      e.target.innerWidth <= 1648
        ? (this.isSmall = true)
        : (this.isSmall = false);
    });
    setTimeout(() => {
      this.damagesDots?.forEach((dot) => {
        if (dot.type === 'interior') {
          this.interiorDots.push(dot);
        } else {
          this.exteriorDots.push(dot);
        }
      });
    }, 500);
  },

  methods: {
    selectImage(index, src) {
      if (!src) return;
      let imgNotFound = this.imgsNotFound.filter((i) => i === index);
      this.$emit('onSelectImage', {
        index,
        src,
        tab: this.tab,
        imgNotFound: imgNotFound.length ? true : false
      });
    },
    changeTab(tab) {
      this.$emit('onChangeTab', tab);
    },
    clickDamageDot(dot) {
      this.currentDamage = dot;
      if (isMobile || this.isSmall) {
        this.$bvModal.show('damage-modal');
        this.$store.commit('companyCars/setDamageImageModal', dot.damagePhoto);
      }
    },
    vehicleDetails() {
      this.$parent.openVehicleDetails(this.vehicle);
      this.$parent.setImages();
    },
    errorLoadImg(index) {
      this.imgsNotFound.push(index);
      this.imgError = require('@/assets/img/image_not_found.png');
    }
  }
};
</script>
<style scoped>
.img-damage {
  max-height: 100%;
  width: auto;
  display: inline-block;
  float: none;
}
.scroll {
  overflow-x: auto;
  white-space: nowrap;
}
img {
  cursor: pointer;
}
@media (max-width: 300px) {
  .gallery-img {
    height: 10px !important;
  }
}
</style>
