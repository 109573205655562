var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"d-flex justify-content-between"},[(
      _vm.isEditing &&
      (_vm.transfer.status == 1 || _vm.transfer.status == 3) &&
      _vm.userHasPermission('transfer', 'write')
    )?_c('b-col',{staticClass:"px-0",attrs:{"xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',{attrs:{"label-size":"sm"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.arrivalDate),expression:"form.arrivalDate"}],staticClass:"form-control mx-2 mr-1",attrs:{"type":"datetime-local","size":"sm"},domProps:{"value":(_vm.form.arrivalDate)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "arrivalDate", $event.target.value)}}})])],1):_c('b-col',[_c('i',[_vm._v(_vm._s(_vm._f("formatDate")(_vm.transfer.deliveryDate))+" "),(_vm.transfer.arrivalUpdateUser)?_c('span',[_vm._v(" - "+_vm._s(_vm.transfer.arrivalUpdateUser.firstName)+" "+_vm._s(_vm.transfer.arrivalUpdateUser.lastName)+" ")]):_vm._e()])]),(_vm.isEditing)?_c('b-button',{staticClass:"mb-4 ml-3",attrs:{"variant":"danger","size":""},on:{"click":function($event){_vm.isEditing = false}}},[_c('b-icon',{attrs:{"icon":"x"}})],1):_vm._e(),(_vm.isEditing && _vm.userHasPermission('transfer', 'write'))?_c('b-button',{staticClass:"mb-4",attrs:{"variant":"success","size":"sm"},on:{"click":function($event){return _vm.saveArrivalDate(_vm.transfer)}}},[_vm._v(" Save ")]):_vm._e(),(
      !_vm.isEditing &&
      _vm.transfer.supplierCompany == null &&
      _vm.transfer.status != 4 &&
      _vm.transfer.status != 2 &&
      _vm.transfer.status != 0 &&
      _vm.transfer.customer == false &&
      _vm.userHasPermission('transfer', 'write')
    )?_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){_vm.isEditing = true}}},[_c('b-icon',{attrs:{"icon":"pencil"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }