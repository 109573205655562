<template>
  <b-container>
    <div class="table-responsive">
      <b-table
        class="mt-3"
        show-empty
        bordered
        small
        stacked="md"
        head-variant="light"
        :items="runLists.data || []"
        :fields="fields"
        :busy="runListsStatus == 'fetching'"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" variant="yellow" />
          </div>
        </template>
        <template #cell(actions)="{ item }">
          <b-icon
            icon="eye"
            @click="editItem(item)"
            class="mx-2 cursor-pointer"
          ></b-icon>

          <font-awesome-icon
            :icon="['far', 'cars']"
            class="mx-1 cursor-pointer"
            @click="viewVehicles(item)"
          />

          <b-spinner
            v-if="isLoadingVehiclesXLS && runListSelected.id == item.id"
            class="align-middle"
            variant="yellow"
            small
          />
          <b-icon
            v-else
            icon="file-earmark-excel"
            @click="downloadXLS(item)"
            class="mx-2 cursor-pointer"
          ></b-icon>
        </template>
      </b-table>
    </div>
    <b-pagination
      v-if="runListsStatus != 'fetching' && runLists && runLists.data"
      :value="runLists ? runLists.currentPage : 1"
      :total-rows="runLists ? runLists.total : 0"
      :per-page="runLists ? runLists.perPage : 25"
      class="mb-2"
      align="end"
      @change="changePage($event)"
    />
    <b-modal
      id="run-list-form-modal"
      ref="run-list-form-modal"
      size="lg"
      hide-footer
    >
      <RunListForm
        :runList="runListSelected"
        :hideModal="hideModal"
        :refresh="getData"
      />
    </b-modal>
  </b-container>
</template>

<script>
import { mapState } from "vuex";
import { GET_RUN_LIST, GET_RUN_LIST_EXCEL } from "./actions";
import RunListForm from "./RunListForm.vue";
export default {
  name: "RunListTable",
  components: { RunListForm },
  data() {
    return {
      fields: [
        { key: "location.name", label: "Location" },
        { key: "marketPlace.name", label: "Marketplace" },
        { key: "auctionDate", label: "Auction date" },

        {
          key: "actions",
          label: "Actions",
          class: "text-center",
        },
      ],
      runListSelected: null,
      isLoadingVehiclesXLS: false,
    };
  },
  computed: {
    ...mapState({
      runLists: (state) => state.superAdminStore.runList.runLists.data,
      runListsStatus: (state) => state.superAdminStore.runList.runLists.status,
    }),
  },
  mounted() {
    this.$store.commit("setAddFunction", this.addRunList);
    this.$store.commit("setSearchFunction", this.getData);
    this.getData();
  },
  methods: {
    getData(search = "", page = 1) {
      const params = {
        search,
        page,
      };
      this.$store
        .dispatch(GET_RUN_LIST, params)
        .then(() => {})
        .catch(() => {});
    },
    changePage(page) {
      this.getData(page);
    },
    addRunList() {
      this.runListSelected = null;
      this.openModal();
    },
    openModal() {
      this.$refs["run-list-form-modal"].show();
    },
    hideModal() {
      this.$refs["run-list-form-modal"].hide();
    },
    editItem(item) {
      this.runListSelected = item;
      this.openModal();
    },
    viewVehicles(item) {
      this.$router.push({
        name: "super-admin-run-list-vehicles",
        params: { id: item.id },
      });
    },

    downloadXLS(item) {
      this.isLoadingVehiclesXLS = true;
      this.runListSelected = item;
      this.$store
        .dispatch(GET_RUN_LIST_EXCEL, {
          runListId: item.id,
        })
        .then((response) => {
          const blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          /* name with date */
          let date = new Date();
          let dateStr =
            date.getFullYear() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getDate();
          link.download = `run-list-${dateStr}.xls`;

          link.click();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Could not get XLS", {
            title: "Error",
            variant: "warning",
          });
        })
        .finally(() => {
          this.isLoadingVehiclesXLS = false;
        });
    },
  },
};
</script>

<style>
</style>