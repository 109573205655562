var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"my-5"},[_c('b-container',[(!_vm.showBarCodeReader)?_c('b-row',{staticClass:"mt-5 justify-content-md-center"},[_c('b-col',{staticClass:"col-lg-6",class:{
            'justify-content-end': !_vm.isSmallScreen,
            'justify-content-center': _vm.isSmallScreen,
          }},[_c('div',{staticClass:"mt-3"},[_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('img',{staticClass:"img-logo mb-5 img-fluid",attrs:{"src":require("@/assets/img/logos/QAS.png"),"alt":"logo"}})])],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.searchVehiclesValidation()}}},[_c('table',[_c('tr',[_c('th',[_c('b-form-input',{staticClass:"input-border-yellow",attrs:{"type":"text","placeholder":"Vin Number","required":""},model:{value:(_vm.vinNumber),callback:function ($$v) {_vm.vinNumber=$$v},expression:"vinNumber"}})],1),_c('th',[_c('b-button',{attrs:{"disabled":_vm.postVehicleStatus === 'fetching' ||
                            _vm.vehiclesToValidateStatus == 'fetching' ||
                            _vm.vehicleCreationStatus == 'fetching' ||
                            _vm.isLoading,"type":"submit","variant":"yellow"}},[(
                              _vm.postVehicleStatus === 'fetching' ||
                              _vm.vehiclesToValidateStatus == 'fetching' ||
                              _vm.vehicleCreationStatus == 'fetching' ||
                              _vm.isLoading
                            )?_c('b-spinner',{attrs:{"variant":"white","label":"Spinning","small":""}}):_c('span',{staticClass:"text-white"},[_vm._v("Go")])],1)],1),_c('th',[_c('b-button',{attrs:{"variant":"dark-green"},on:{"click":function($event){return _vm.toggleBarCodeReader(true)}}},[_c('b-icon',{attrs:{"icon":"camera","variant":""}})],1)],1)])])]),_c('br')],1),_c('b-col',{attrs:{"cols":"12"}},[(_vm.vehiclesToValidate)?_c('div',{staticClass:"float-right my-2"},[_c('b-button',{staticClass:"text-white",attrs:{"variant":"success","size":"sm","block":""},on:{"click":function($event){return _vm.createNewVehicleRegistration()}}},[_vm._v(" Create a new record ")])],1):_vm._e(),_c('br'),_c('br'),(_vm.vehiclesToValidate)?_c('VehiclesValidationList',{staticClass:"mt-2",attrs:{"vehiclesToValidate":_vm.vehiclesToValidate.vehicles}}):_vm._e()],1)],1)],1)])],1):_vm._e()],1),(_vm.showBarCodeReader)?_c('BarCodeReader',{on:{"decode":function($event){return _vm.onDecode($event)},"onCancel":function($event){return _vm.toggleBarCodeReader($event)}}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }