<template>
  <div>
    <form v-if="!isLoading" @submit.stop.prevent="save()">
      <b-row>
        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="md">
            <template slot="label">
              <b-form-radio-group
                id="radio-group-2"
                v-model="typeCarrier"
                name="radio-sub-component"
              >
                <b-form-radio value="supplier">Provider</b-form-radio>
                <b-form-radio value="driver"> Driver </b-form-radio>
              </b-form-radio-group>
            </template>
            <Multiselect
              v-if="typeCarrier == 'supplier'"
              v-model="form.suppliers"
              :options="providers"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Pick some option"
              track-by="companyName"
              label="companyName"
              :preselect-first="false"
              :taggable="true"
              @input="setTypes()"
            >
              <template slot="singleLabel" slot-scope="props">
                {{ props }}
              </template>
              <template slot="option" slot-scope="props">
                {{ props.option.companyName }}
              </template>

              <template slot="selection" slot-scope="{ values, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length && !isOpen"
                  >{{ values.length }} options selected</span
                ></template
              >
            </Multiselect>
            <!--        <vue-single-select
              v-if="typeCarrier == 'supplier'"
              :classes="{ input: 'form-control', dropdown: 'dropdown' }"
              v-model="form.supplier"
              :options="providers"
              optionLabel="companyName"
            ></vue-single-select>
 -->

            <Multiselect
              v-if="typeCarrier == 'driver'"
              v-model="form.drivers"
              :options="drivers"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Pick some option"
              track-by="id"
              :custom-label="fullDriverName"
              :preselect-first="false"
              :taggable="true"
              @input="setTypes()"
            >
              <template slot="singleLabel" slot-scope="props">
                {{ props }}
              </template>
              <template slot="option" slot-scope="props">
                {{ props.option.firstName }} {{ props.option.lastName }}
              </template>

              <template slot="selection" slot-scope="{ values, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length && !isOpen"
                  >{{ values.length }} options selected</span
                ></template
              >
            </Multiselect>
            <!--      <vue-single-select
              v-if="typeCarrier == 'driver'"
              :classes="{ input: 'form-control', dropdown: 'dropdown' }"
              v-model="form.driver"
              :options="drivers"
              :getOptionDescription="() => driverName"
            >
              <template slot="option" slot-scope="{ option }">
                <div>{{ option.firstName }} {{ option.lastName }}</div>
              </template>
            </vue-single-select> -->
          </b-form-group>
        </b-col>
        <b-col></b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group label="Start Date">
            <input
              type="datetime-local"
              class="form-control"
              v-model="form.startDate"
            />
          </b-form-group>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group label="End Date">
            <input
              type="datetime-local"
              class="form-control"
              v-model="form.endDate"
            />
          </b-form-group>
        </b-col>

        <!-- Origin-->
        <!--     <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <b-form-group label-size="md">
            <template slot="label">
              Origin <span class="text-danger">*</span>
            </template>
            <vue-single-select
              :classes="{ input: 'form-control', dropdown: 'dropdown' }"
              v-model="form.origin"
              :options="origins"
              optionLabel="description"
            >
            </vue-single-select>
          </b-form-group>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12"> </b-col> -->

        <!-- Destination-->
        <!--   <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <b-form-group label-size="md">
            <template slot="label">
              Destination <span class="text-danger">*</span>
            </template>
            <vue-single-select
              :classes="{ input: 'form-control', dropdown: 'dropdown' }"
              v-model="form.destination"
              :options="destinations"
              optionLabel="description"
            >
            </vue-single-select>
          </b-form-group>
        </b-col> -->
        <!--       <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group label="Waiting time (minutes)">
            <b-form-input v-model="form.waitingTime" size="md" type="number" />
          </b-form-group>
        </b-col> -->
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <TravelsTable
            :origins="origins"
            :destinations="destinations"
            @updateTravels="onUpdateTravels"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            type="submit"
            variant="success"
            class="float-right"
            :disabled="form.travels.length == 0"
          >
            Save
          </b-button>
        </b-col>
      </b-row>
    </form>
    <div v-else class="text-center">
      <b-spinner class="my-5" label="Loading..."></b-spinner>
    </div>
  </div>
</template>

<script>
import { POST_TRANSPORTATION } from "./actions";
import Multiselect from "vue-multiselect";
import TravelsTable from "./TravelsTable.vue";
export default {
  name: "TransportationForm",
  components: {
    Multiselect,
    TravelsTable,
  },
  props: {
    refreshData: {
      type: Function,
      default: () => {},
    },
    hideModal: {
      type: Function,
      default: () => {},
    },
    transportationItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        startDate: null,
        endDate: null,
        origin: null,
        destination: null,
        waitingTime: null,
        suppliers: null,
        drivers: null,
        travels: [],
      },
      providers: [],
      drivers: [],
      origins: [],
      destinations: [],
      typeCarrier: "supplier",
      travels: [],
    };
  },
  created() {
    this.getSuppliers();
    this.getDrivers();
    this.getOriginsAndDestinations();
  },
  computed: {
    isLoading() {
      return (
        this.providers.length == 0 ||
        this.drivers.length == 0 ||
        this.origins.length == 0 ||
        this.destinations.length == 0
      );
    },
  },
  watch: {
    isLoading(val) {
      if (!val) {
        this.formatForm();
      }
    },
  },
  mounted() {
    /*     if (this.transportationItem) {
      this.formatForm();
    } */
  },
  methods: {
    getSuppliers() {
      this.$store
        .dispatch("GET_COMPANIES_LIST", { isSupplier: 1 })
        .then((response) => {
          this.providers = response;
        })
        .catch(() => {
          this.$root.$bvToast.toast("Could not get the providers list ", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    getDrivers() {
      this.$store
        .dispatch("GET_DRIVER_LIST_NO_PAGINATE")
        .then((response) => {
          this.drivers = response.data;
        })
        .catch(() => {});
    },
    getOriginsAndDestinations(search = "") {
      this.$store
        .dispatch("GET_ORIGINS", { search: search })
        .then((response) => {
          this.origins = response;
          this.destinations = response;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatPayload() {
      const payload = {
        id: this.form.id,
        startDate: this.form.startDate,
        endDate: this.form.endDate,
        originId: this.form.origin?.id,
        destinationId: this.form.destination?.id,
        waitTime: this.form.waitingTime,
        travels: this.form.travels,
      };
      if (this.typeCarrier == "supplier") {
        payload.companies = this.form.suppliers;
      } else {
        payload.drivers = this.form.drivers;
      }
      return payload;
    },
    save() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_TRANSPORTATION, payload)
        .then(() => {
          this.$root.$bvToast.toast("Transportation saved successfully", {
            title: "Success",
            variant: "success",
          });
          this.refreshData();
          this.hideModal();
          this.resetForm();
        })
        .catch((error) => {
          console.log(error);
          this.$root.$bvToast.toast("Could not save the transportation", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    resetForm() {
      this.form = {
        startDate: null,
        endDate: null,
        origin: null,
        destination: null,
        waitingTime: null,
      };
    },
    formatForm() {
      this.form.id = this.transportationItem.id;
      this.form.startDate = this.transportationItem.startDate;
      this.form.endDate = this.transportationItem.endDate;
      /*    this.form.origin = this.origins.find(
        (origin) => origin.id == this.transportationItem.originLocationId
      );
      this.form.destination = this.destinations.find(
        (destination) =>
          destination.id == this.transportationItem.destinationLocationId
      ); */
      /*       this.form.waitingTime = this.transportationItem.waitTime;
       */ if (this.transportationItem.supplierCompany) {
        this.form.suppliers = this.transportationItem.companies;
        this.typeCarrier = "supplier";
      } else {
        this.form.drivers = this.transportationItem.drivers;
        this.typeCarrier = "driver";
      }
    },
    fullDriverName({ firstName, lastName }) {
      return `${firstName} ${lastName}`;
    },
    onUpdateTravels(travels) {
      this.travels = travels;
      if (this.travels.length == 0) {
        this.form.travels = [];
        return;
      }
      this.formatTravels();
    },
    formatTravels() {
      this.form.travels = this.travels.map((travel) => {
        return {
          originId: travel?.origin?.id,
          destinationId: travel?.destination?.id,
          waitTime: travel?.waitTime,
        };
      });
    },
  },
};
</script>

<style></style>
