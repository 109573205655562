
import * as constants from './actions';
import axios from '@/plugins/axios.js';

function getDefaultState() {
  return {
    logistic: {
      status: null,
      data: null,
      error: null
    },
    admin: {
      status: null,
      data: null,
      error: null
    },
    business: {
      status: null,
      data: null,
      error: null
    },
    export: {
      status: null,
      data: null,
      error: null
    },
    yard: {
      status: null,
      data: null,
      error: null
    },
    sales: {
      status: null,
      data: null,
      error: null
    },
    accounting: {
      status: null,
      data: null,
      error: null
    }
  };
}

const actions = {
  [constants.GET_BOARD_LOGISTIC]: async ({ commit }) => {
    try {
      commit(constants.GET_BOARD_LOGISTIC);
      const response = await axios.get('dashboard/getDashboardTransfer')
      commit(constants.GET_BOARD_LOGISTIC_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_BOARD_LOGISTIC_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_BOARD_ADMIN]: async ({ commit }) => {
    try {
      commit(constants.GET_BOARD_ADMIN);
      const response = await axios.get('dashboard/getDashboardAdmin')
      commit(constants.GET_BOARD_ADMIN_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_BOARD_ADMIN_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_BOARD_BUSINESS]: async ({ commit }) => {
    try {
      commit(constants.GET_BOARD_BUSINESS);
      const response = await axios.get('dashboard/getDashboardBusiness')
      commit(constants.GET_BOARD_BUSINESS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_BOARD_BUSINESS_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_BOARD_EXPORT]: async ({ commit }) => {
    try {
      commit(constants.GET_BOARD_EXPORT);
      const response = await axios.get('dashboard/getDashboardExport')
      commit(constants.GET_BOARD_EXPORT_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_BOARD_EXPORT_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_BOARD_YARD]: async ({ commit }) => {
    try {
      commit(constants.GET_BOARD_YARD);
      const response = await axios.get('dashboard/getDashboardYard')
      commit(constants.GET_BOARD_YARD_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_BOARD_YARD_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_BOARD_SALES]: async ({ commit }) => {
    try {
      commit(constants.GET_BOARD_SALES);
      const response = await axios.get('dashboard/getDashboardSale')
      commit(constants.GET_BOARD_SALES_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_BOARD_SALES_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_BOARD_ACCOUNTING]: async ({ commit }) => {
    try {
      commit(constants.GET_BOARD_ACCOUNTING);
      const response = await axios.get('dashboard/getDashboardFinancing')
      commit(constants.GET_BOARD_ACCOUNTING_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_BOARD_ACCOUNTING_ERROR, error);
      throw error.response;
    }
  }

};

const mutations = {


  [constants.GET_BOARD_LOGISTIC]: (state) => {
    state.logistic.status = 'fetching';
    state.logistic.error = null;
  },
  [constants.GET_BOARD_LOGISTIC_SUCCESS]: (state, data) => {
    state.logistic.status = 'success';
    state.logistic.error = null;
    state.logistic.data = data;
  },
  [constants.GET_BOARD_LOGISTIC_ERROR]: (state, error) => {
    state.logistic.status = 'error';
    state.logistic.error = error;
  },

  [constants.GET_BOARD_ADMIN]: (state) => {
    state.admin.status = 'fetching';
    state.admin.error = null;
  },
  [constants.GET_BOARD_ADMIN_SUCCESS]: (state, data) => {
    state.admin.status = 'success';
    state.admin.error = null;
    state.admin.data = data;
  },
  [constants.GET_BOARD_ADMIN_ERROR]: (state, error) => {
    state.admin.status = 'error';
    state.admin.error = error;
  },
  [constants.GET_BOARD_BUSINESS]: (state) => {
    state.business.status = 'fetching';
    state.business.error = null;
  },
  [constants.GET_BOARD_BUSINESS_SUCCESS]: (state, data) => {
    state.business.status = 'success';
    state.business.error = null;
    state.business.data = data;
  },
  [constants.GET_BOARD_BUSINESS_ERROR]: (state, error) => {
    state.business.status = 'error';
    state.business.error = error;
  },
  [constants.GET_BOARD_EXPORT]: (state) => {
    state.export.status = 'fetching';
    state.export.error = null;
  },
  [constants.GET_BOARD_EXPORT_SUCCESS]: (state, data) => {
    state.export.status = 'success';
    state.export.error = null;
    state.export.data = data;
  },
  [constants.GET_BOARD_EXPORT_ERROR]: (state, error) => {
    state.export.status = 'error';
    state.export.error = error;
  },
  [constants.GET_BOARD_YARD]: (state) => {
    state.yard.status = 'fetching';
    state.yard.error = null;
  },
  [constants.GET_BOARD_YARD_SUCCESS]: (state, data) => {
    state.yard.status = 'success';
    state.yard.error = null;
    state.yard.data = data;
  },
  [constants.GET_BOARD_YARD_ERROR]: (state, error) => {
    state.yard.status = 'error';
    state.yard.error = error;
  },
  [constants.GET_BOARD_SALES]: (state) => {
    state.sales.status = 'fetching';
    state.sales.error = null;
  },
  [constants.GET_BOARD_SALES_SUCCESS]: (state, data) => {
    state.sales.status = 'success';
    state.sales.error = null;
    state.sales.data = data;
  },
  [constants.GET_BOARD_SALES_ERROR]: (state, error) => {
    state.sales.status = 'error';
    state.sales.error = error;
  },
  [constants.GET_BOARD_ACCOUNTING]: (state) => {
    state.accounting.status = 'fetching';
    state.accounting.error = null;
  },
  [constants.GET_BOARD_ACCOUNTING_SUCCESS]: (state, data) => {
    state.accounting.status = 'success';
    state.accounting.error = null;
    state.accounting.data = data;
  },
  [constants.GET_BOARD_ACCOUNTING_ERROR]: (state, error) => {
    state.accounting.status = 'error';
    state.accounting.error = error;
  },



};

export default {
  state: getDefaultState(),
  actions,
  mutations
};
