<template>
  <div>
    <b-navbar
      toggleable="lg"
      variant="light"
      align="end"
      class="nav mt-4 mb-5 shadow"
    >
      <b-navbar-toggle target="nav-collapse" />
      <b-collapse id="nav-collapse" is-nav align="end">
        <b-navbar-nav class="ml-auto">
          <b-navbar-nav>
            <b-nav-item @click="setStatus('')" :active="status == ''">
              Without company
            </b-nav-item>
            <b-nav-item
              @click="setStatus('update')"
              :active="status == 'update'"
            >
              Editable
            </b-nav-item>
          </b-navbar-nav>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      status: 1,
    };
  },
  methods: {
    setStatus(status) {
      this.status = status;
      this.$emit("set-status", status);
    },
  },
};
</script>

<style>
</style>