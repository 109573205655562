import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    corporations: {
      status: null,
      data: [],
      error: null,
    },
    corporationList: {
      status: null,
      data: [],
      error: null,
    },
    corporation: {
      status: null,
      data: {},
      error: null,
    },
  };
}

const actions = {
  [constants.GET_CORPORATIONS]: async ({ commit }, params) => {
    try {
      commit(constants.GET_CORPORATIONS);
      const response = await axios.get("corporation/getCorporation", {
        params: { ...params },
      });
      commit(constants.GET_CORPORATIONS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_CORPORATIONS_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_CORPORATION_LIST]: async ({ commit }, params) => {
    try {
      commit(constants.GET_CORPORATION_LIST);
      const response = await axios.get("corporation/listCorporation", {
        params: { ...params },
      });
      commit(constants.GET_CORPORATION_LIST_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_CORPORATION_LIST_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_CORPORATION]: async ({ commit }, params) => {
    try {
      commit(constants.POST_CORPORATION);
      const response = await axios.post(
        "corporation/createAndUpdateCorporation",
        params
      );
      commit(constants.POST_CORPORATION_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_CORPORATION_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_ASSIGN_CORPORATION]: async ({ commit }, params) => {
    try {
      commit(constants.PUT_ASSIGN_CORPORATION);
      const response = await axios.put("corporation/assignPermissions", {
        ...params,
      });
      commit(constants.PUT_ASSIGN_CORPORATION_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_ASSIGN_CORPORATION_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_CORPORATIONS]: (state) => {
    state.corporations.status = "fetching";
    state.corporations.error = null;
  },
  [constants.GET_CORPORATIONS_SUCCESS]: (state, data) => {
    state.corporations.status = "success";
    state.corporations.error = null;
    state.corporations.data = data;
  },
  [constants.GET_CORPORATIONS_ERROR]: (state, error) => {
    state.corporations.status = "error";
    state.corporations.error = error;
  },
  [constants.GET_CORPORATION_LIST]: (state) => {
    state.corporationList.status = "fetching";
    state.corporationList.error = null;
  },
  [constants.GET_CORPORATION_LIST_SUCCESS]: (state, data) => {
    state.corporationList.status = "success";
    state.corporationList.error = null;
    state.corporationList.data = data;
  },
  [constants.GET_CORPORATION_LIST_ERROR]: (state, error) => {
    state.corporationList.status = "error";
    state.corporationList.error = error;
  },
  [constants.POST_CORPORATION]: (state) => {
    state.corporation.status = "fetching";
    state.corporation.error = null;
  },
  [constants.POST_CORPORATION_SUCCESS]: (state, data) => {
    state.corporation.status = "success";
    state.corporation.error = null;
    state.corporation.data = data;
  },

  [constants.POST_CORPORATION_ERROR]: (state, error) => {
    state.corporation.status = "error";
    state.corporation.error = error;
  },
  [constants.PUT_ASSIGN_CORPORATION]: (state) => {
    state.corporation.status = "fetching";
    state.corporation.error = null;
  },
  [constants.PUT_ASSIGN_CORPORATION_SUCCESS]: (state, data) => {
    state.corporation.status = "success";
    state.corporation.error = null;
    state.corporation.data = data;
  },
  [constants.PUT_ASSIGN_CORPORATION_ERROR]: (state, error) => {
    state.corporation.status = "error";
    state.corporation.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
