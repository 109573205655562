import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    trims: {
      status: null,
      data: [],
      error: null,
      filters: {
        year: null,
        vinNumber: null,
        make: null,
        model: null,
        kms: null,
        pc: null,
      },
    },
    user: {
      status: null,
      data: null,
      error: null,
    },
  };
}

const actions = {
  [constants.GET_TRIMS_BY_PRICING]: async ({ commit, state }) => {
    try {
      commit(constants.GET_TRIMS_BY_PRICING);
      const payload = Object.entries(state.trims.filters).reduce(
        (a, [k, v]) => (v === null || v === "" ? a : { ...a, [k]: v }),
        {}
      );
      const response = await axios.get("getCanadianBlackBook", {
        params: payload,
      });
      commit(constants.GET_TRIMS_BY_PRICING_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_TRIMS_BY_PRICING_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_TRIMS_BY_PRICING]: (state) => {
    state.trims.status = "fetching";
    state.trims.error = null;
  },
  [constants.GET_TRIMS_BY_PRICING_SUCCESS]: (state, data) => {
    state.trims.status = "success";
    state.trims.error = null;
    state.trims.data = data;
  },
  [constants.GET_TRIMS_BY_PRICING_ERROR]: (state, error) => {
    state.trims.status = "error";
    state.trims.error = error;
  },

  [constants.SET_TRIMS_FILTER]: (state, filters) => {
    if (filters.reset) {
      state.trims.filters = {
        year: null,
        vinNumber: null,
        make: null,
        model: null,
        kms: null,
        pc: null,
      };
    }
    if (filters.fields) {
      state.trims.filters = {
        ...state.trims.filters,
        ...filters.fields,
      };
    }
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
