<template>
  <b-sidebar
    id="sidebar-company-details"
    lazy
    :width="widthSidebar"
    :visible="companySideBar.isShowing"
    shadow
    right
    no-header-close
    @hidden="
      () =>
        $store.commit('setCompanyDetailsSideBar', {
          company: null,
          isShowing: false,
        })
    "
  >
    <template v-slot:title>
      <div class="text-right h2 d-flex justify-content-start">
        <span
          class="cursor-pointer mr-3"
          @click="closeSideBar()"
          v-b-tooltip.hover
          title="Close"
          ><strong>X</strong>
        </span>

        Company details
      </div>
    </template>
    <b-container v-if="companySideBar.company">
      <b-card class="text-wrap">
        <b-row>
          <b-col sm="12" md="6" lg="6" xl="6">
            <label for="">
              <b>Commercial name:</b> <br />
              <i>
                <i> {{ companySideBar.company.commercialName || "----" }}</i>
              </i>
            </label>
          </b-col>
          <b-col sm="12" md="6" lg="6" xl="6">
            <label for="">
              <b>Company name:</b> <br />
              <i
                ><i> {{ companySideBar.company.companyName || "----" }}</i>
              </i>
            </label>
          </b-col>
          <!-- Fax -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <label for="">
              <b>Fax number:</b> <br />
              <i> {{ companySideBar.company.faxNumber || "----" }}</i>
            </label>
          </b-col>

          <!-- Code -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <label for="">
              <b>Company ID:</b> <br />
              <i> {{ companySideBar.company.companyId || "----" }}</i>
            </label>
          </b-col>
          <b-col sm="12" md="12" lg="12" xl="12">
            <label for="">
              <b>Address line 2:</b> <br />
              <i> {{ companySideBar.company.addressLine2 || "----" }}</i>
            </label>
          </b-col>

          <b-col sm="12" md="12" lg="6" xl="6">
            <label for="">
              <b>Street:</b> <br />
              <i> {{ companySideBar.company.street || "----" }}</i>
            </label>
          </b-col>

          <b-col sm="12" md="12" lg="6" xl="6">
            <label for="">
              <b>ZIP postal code:</b> <br />
              <i> {{ companySideBar.company.zip || "----" }}</i>
            </label>
          </b-col>
          <b-col sm="12" md="12" lg="6" xl="6">
            <label for="">
              <b>State:</b> <br />
              <i> {{ companySideBar.company.state || "----" }}</i>
            </label>
          </b-col>
          <b-col sm="12" md="12" lg="6" xl="6">
            <label for="">
              <b>City:</b> <br />
              <i> {{ companySideBar.company.city || "----" }}</i>
            </label>
          </b-col>
          <b-col sm="12" md="12" lg="6" xl="6">
            <label for="">
              <b>Website:</b> <br />
              <i> {{ companySideBar.company.webSite || "----" }}</i>
            </label>
          </b-col>
          <b-col sm="12" md="12" lg="6" xl="6">
            <label for="">
              <b>GST/HST:</b> <br />
              <i> {{ companySideBar.company.gst || "----" }}</i>
            </label>
          </b-col>
          <b-col sm="12" md="12" lg="6" xl="6">
            <label for="">
              <b>QST:</b> <br />
              <i> {{ companySideBar.company.qst || "----" }}</i>
            </label>
          </b-col>

          <!-- NEQ -->
          <b-col sm="12" md="12" lg="6" xl="6">
            <label v-if="companySideBar.company.neq" for="">
              <b>NEQ:</b> <br />
              <i> {{ companySideBar.company.corporationNumber || "----" }}</i>
            </label>
            <label v-else for="">
              <b>Business number:</b> <br />
              <i> {{ companySideBar.company.corporationNumber || "----" }}</i>
            </label>
          </b-col>

          <b-col sm="12" md="12" lg="6" xl="6">
            <label for="">
              <b>Dealer license:</b> <br />
              <i> {{ companySideBar.company.dealerLicense || "----" }}</i>
            </label>
          </b-col>

          <b-col sm="12" md="12" lg="6" xl="6">
            <label for="">
              <b>Billing Contact name :</b> <br />
              <i> {{ companySideBar.company.billingContactName || "----" }}</i>
            </label>
          </b-col>

          <b-col sm="12" md="12" lg="6" xl="6">
            <label for="">
              <b>Billing contact email :</b> <br />
              <i> {{ companySideBar.company.billingContactEmail || "----" }}</i>
            </label>
          </b-col>

          <b-col sm="12" md="12" lg="6" xl="6">
            <label for="">
              <b>Billing contact phone :</b> <br />
              <i> {{ companySideBar.company.billingContactMobilePhone || "----" }}</i>
            </label>
          </b-col>
        </b-row>
      </b-card>
    </b-container>
  </b-sidebar>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "CompanySideBar",
  computed: {
    widthSidebar() {
      return this.$vssWidth > 1280 ? "40%" : this.$vssWidth <= 576 ? "100%" : "50%";
    },
    ...mapState({
      companySideBar: (state) => state.superAdminStore.tasks.companyDetailsSideBar,
    }),
  },
  methods: {
    closeSideBar() {
      this.$store.commit("setCompanyDetailsSideBar", {
        company: null,
        isShowing: false,
      });
    },
  },
};
</script>

<style></style>
