<template>
  <div class="wrapper">
    <SideBarMenu />
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <base-header class="pb-6 pb-1 pt-5 pt-md-8 bg-gradient-dark">
          <BoardCards />
        </base-header>
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->

          <router-view :key="$router.meta"></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import ContentFooter from "./ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";
import DashboardNavbar from "./DashboardNavbar.vue";
import SideBarMenu from "./SideBarMenu.vue";
import BoardCards from "./components/BoardCards.vue";
export default {
  components: {
    ContentFooter,

    FadeTransition,
    DashboardNavbar,
    BoardCards,
    SideBarMenu,
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
  },
  mounted() {
    this.initScrollbar();
    this.$sidebar.toggleMinimize();
  },
};
</script>
<style lang="scss">
</style>
