import * as constants from "./actions";
import axios from "@/plugins/axios.js";
function getDefaultState() {
  return {
    sellChecks: {
      status: null,
      data: [],
      error: null,
    },
    sellCheck: {
      status: null,
      data: {},
      error: null,
    },
    taxes: {
      status: null,
      data: [],
      error: null,
    },
  };
}

const actions = {
  [constants.GET_SELL_CHECKS]: async ({ commit }, id) => {
    try {
      commit(constants.GET_SELL_CHECKS);
      const response = await axios.get(`sellCheck/getSellCheck?id=${id}`);
      commit(constants.GET_SELL_CHECKS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_SELL_CHECKS_ERROR, error);
      throw error.response;
    }
  },

  [constants.POST_SELL_CHECK]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_SELL_CHECK);
      const response = await axios.post("sellCheck/create", payload);
      commit(constants.POST_SELL_CHECK_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_SELL_CHECK_ERROR, error);
      throw error.response;
    }
  },

  [constants.PUT_SELL_CHECK_DISABLE]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_SELL_CHECK_DISABLE);
      const response = await axios.put(
        "sellCheck/changePaymentStatus",
        payload
      );
      commit(constants.PUT_SELL_CHECK_DISABLE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_SELL_CHECK_DISABLE_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_SELL_CHECKS_TAXES]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_SELL_CHECKS_TAXES);
      const response = await axios.get(`taxes/getTaxByVehicle`, {
        params: payload,
      });
      commit(constants.GET_SELL_CHECKS_TAXES_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_SELL_CHECKS_TAXES_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_SELL_CHECKS]: (state) => {
    state.sellChecks.status = "fetching";
    state.sellChecks.error = null;
  },
  [constants.GET_SELL_CHECKS_SUCCESS]: (state, data) => {
    state.sellChecks.status = "success";
    state.sellChecks.error = null;
    state.sellChecks.data = data;
  },
  [constants.GET_SELL_CHECKS_ERROR]: (state, error) => {
    state.sellChecks.status = "error";
    state.sellChecks.error = error;
  },
  [constants.POST_SELL_CHECK]: (state) => {
    state.sellCheck.status = "fetching";
    state.sellCheck.error = null;
  },
  [constants.POST_SELL_CHECK_SUCCESS]: (state, data) => {
    state.sellCheck.status = "success";
    state.sellCheck.error = null;
    state.sellCheck.data = data;
  },
  [constants.POST_SELL_CHECK_ERROR]: (state, error) => {
    state.sellCheck.status = "error";
    state.sellCheck.error = error;
  },

  [constants.PUT_SELL_CHECK_DISABLE]: (state) => {
    state.sellCheck.status = "fetching";
    state.sellCheck.error = null;
  },
  [constants.PUT_SELL_CHECK_DISABLE_SUCCESS]: (state, data) => {
    state.sellCheck.status = "success";
    state.sellCheck.error = null;
    state.sellCheck.data = data;
  },
  [constants.PUT_SELL_CHECK_DISABLE_ERROR]: (state, error) => {
    state.sellCheck.status = "error";
    state.sellCheck.error = error;
  },

  [constants.GET_SELL_CHECKS_TAXES]: (state) => {
    state.taxes.status = "fetching";
    state.taxes.error = null;
  },
  [constants.GET_SELL_CHECKS_TAXES_SUCCESS]: (state, data) => {
    state.taxes.status = "success";
    state.taxes.error = null;
    state.taxes.data = data;
  },
  [constants.GET_SELL_CHECKS_TAXES_ERROR]: (state, error) => {
    state.taxes.status = "error";
    state.taxes.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
