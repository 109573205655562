<template>
  <bread-crumb list-classes="breadcrumb-links breadcrumb-dark">
    <BreadCrumbItem>
      <router-link
        :to="{
          name: favoriteBoard
            ? favoriteBoard
            : 'superadmin.vehicles.table.inventory',
        }"
      >
        <i class="fas fa-home text-success"></i>
      </router-link>
    </BreadCrumbItem>
    <BreadCrumbItem
      v-for="(route, index) in breadCrumbList"
      :key="route.name"
      :active="index === $route.matched.length - 1"
      style="display: inline-block"
    >
      <router-link
        :to="{ name: route.name }"
        v-if="index < $route.matched.length - 1"
      >
        {{ route.meta.name ? route.meta.name : "" }}
      </router-link>
      <span v-else>{{ route.meta.name ? route.meta.name : "" }}</span>
    </BreadCrumbItem>
    <template v-if="isBoard">
      <b-icon
        v-if="isBoardFavorite"
        class="ml-2"
        icon="star-fill"
        variant="yellow"
      ></b-icon>

      <b-icon
        v-else
        class="ml-2 cursor-pointer"
        icon="star"
        variant="white"
        v-b-tooltip.hover
        title="Mark as favorite"
        @click="setSingleFavoriteBoardToCookie(true)"
      ></b-icon>
    </template>
  </bread-crumb>
</template>

<script>
import BreadCrumb from "./Breadcrumb";
import BreadCrumbItem from "./BreadcrumbItem";
import { mapState } from "vuex";
export default {
  name: "route-breadcrumb",
  components: {
    BreadCrumb,
    BreadCrumbItem,
  },
  data() {
    return {
      favoriteBoard: "",
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.auth.loginStore.profile.data,
    }),
    isBoardFavorite() {
      return this.favoriteBoard.includes(this.$route.name);
    },
    breadCrumbList() {
      let routes = [...this.$route.matched.slice()];
      return this.deleteFirstRoute(routes);
    },
    isBoard() {
      return this.$route.name.includes("superadmin.vehicles.table");
    },
  },
  mounted() {
    this.setSingleFavoriteBoardToCookie();
  },
  methods: {
    deleteFirstRoute(routes) {
      routes.shift();
      return routes;
    },
    getBreadName(route) {
      return route.name;
    },

    getFavoriteBoardFromCookie() {
      const favoriteBoards = this.$cookies.get("favoriteBoard");

      if (favoriteBoards) {
        this.favoriteBoard = favoriteBoards;
      }
    },

    setSingleFavoriteBoardToCookie(isChangingByClick = false) {
      if (
        !this.$cookies.get("favoriteBoard") ||
        this.$cookies.get("favoriteBoard") !== this.$route.name
      ) {
        if (!this.profile.user.favorite || isChangingByClick) {
          this.$cookies.set("favoriteBoard", this.$route.name);
          this.updateFavoriteBoard(this.$route.name);
        } else {
          this.$cookies.set("favoriteBoard", this.profile.user.favorite);
        }
      }

      this.getFavoriteBoardFromCookie();
    },
    updateFavoriteBoard(board) {
      this.$store
        .dispatch("PUT_FAVORITE_DASHBOARD", {
          id: this.profile.user.id,
          favorite: board,
        })
        .then(() => {})
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style scoped></style>
