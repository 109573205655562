<template>
  <b-card>
    <b-tr> <b-th rowspan="6" class="text-center">Profit</b-th> </b-tr>
    <b-row>
      <b-col md="6">
        <b-table
          stacked="md"
          :items="profitInfo"
          :fields="fields"
          head-row-variant="success"
          thead-class="d-none"
        >
        </b-table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "SimpleVehicleViewProfitTable",
  props: {
    profitInfo: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        { key: "suffix", label: "", class: "py-1" },
        {
          key: "amount",
          label: "Amount",
          class: "text-center py-1",
          formatter: (amount) =>
            typeof amount == "number"
              ? this.$options.filters.currency(amount)
              : amount,
        },
      ],
    };
  },
};
</script>

<style></style>
