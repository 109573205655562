<template>
  <b-modal
    id="transfer-modal"
    ref="modal"
    centered
    no-close-on-backdrop
    scrollable
    size="lg"
    title="Transfers"
    @show="getTransfers()"
    @hidden="getVehiclesInfo()"
  >
    <VehicleDetails
      v-if="vehicle"
      :vehicle="vehicle"
      :color="vehicle.vehicleInformation.color"
    />
    <RegistrationForm
      v-if="userHasPermission('transfer', 'write')"
      :vehicle="vehicle"
      :refreshData="getTransfers"
    />

    <b-list-group v-if="transfers.status == 'fetching'" class="mt-2">
      <b-list-group-item class="text-center">
        <b-spinner variant="yellow" />
      </b-list-group-item>
    </b-list-group>
    <b-list-group v-else class="mt-2 mb-2 note-list" tag="ul">
      <b-list-group-item
        v-if="transfers.data.data && !transfers.data.data.length"
        class="text-center"
      >
        <b>No transfers to show</b>
      </b-list-group-item>

      <b-list-group-item
        v-for="(transfer, i) in transfers.data.data"
        :key="i"
        class="flex-column align-items-start"
        tag="li"
      >
        <TransferCard :transfer="transfer" :refreshData="getTransfers" />
      </b-list-group-item>
    </b-list-group>

    <template v-slot:modal-footer="{ close }">
      <b-pagination
        :value="transfers.data.currentPage"
        :total-rows="transfers.data.total"
        :per-page="transfers.data.perPage"
        class="mt-2"
        @change="changePage($event)"
      />
      <div class="justify-content-end">
        <b-button
          variant="outline-secondary"
          class="mr-2"
          @click="() => close()"
        >
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { generalMixin } from "@/modules/mixin.js";
import { GET_TRANSFERS } from "./actions";
import RegistrationForm from "./RegistrationForm";
import TransferCard from "./TransferCard";
import { superAdminMixin } from "@/modules/superAdmin/mixins.js";
import VehicleDetails from "@/modules/superAdmin/components/VehicleDetails.vue";

import { mapState } from "vuex";
export default {
  name: "TransferModal",
  components: {
    RegistrationForm,
    TransferCard,
    VehicleDetails,
  },
  mixins: [generalMixin, superAdminMixin],
  computed: {
    ...mapState({
      transfers: (state) =>
        state.superAdminStore.componentStore.transfer.transfers,
      transfer: (state) =>
        state.superAdminStore.componentStore.transfer.transfers,
      vehicle: (state) => state.superAdminStore.vehicle,
    }),
  },
  data() {
    return {};
  },
  methods: {
    getTransfers() {
      this.$store
        .dispatch(GET_TRANSFERS, {
          vehicleId: this.vehicle.id,
        })
        .then(() => {})
        .catch(() => {
          this.toastMessage(
            "transfers could not be obtain",
            "Warning",
            "warning"
          );
        });
    },
    changePage(page) {
      this.$store
        .dispatch(GET_TRANSFERS, {
          vehicleId: this.vehicle.id,
          page: page,
        })
        .then(() => {})
        .catch(() => {
          this.toastMessage(
            "transfers could not be obtain",
            "Warning",
            "warning"
          );
        });
    },

    hideModal() {
      this.$bvModal.hide("registerPaymentModal");
    },
  },
};
</script>

<style scoped></style>
