import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {

    agentCommissions: {
      status: null,
      data: [],
      error: null,
    },
    commissionExcel: {
      status: null,
      data: null,
      error: null,
    },
    agentCommissionTotal: {
      status: null,
      data: 0,
      error: null,
    },


  };
}
const actions = {


  [constants.GET_COMMISSIONS_BY_AGENT]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_COMMISSIONS_BY_AGENT);
      const response = await axios.get("commission/getAgentCommission", {
        params: payload,
      });
      commit(constants.GET_COMMISSIONS_BY_AGENT_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_COMMISSIONS_BY_AGENT_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_COMMISSION_EXCEL]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_COMMISSION_EXCEL);
      const response = await axios.get("commission/downloadXls", {
        params: payload,
        responseType: "blob",
      });
      commit(constants.GET_COMMISSION_EXCEL_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_COMMISSION_EXCEL_ERROR, error);
      throw error.response;
    }
  }


};

const mutations = {
  [constants.GET_COMMISSIONS_BY_AGENT]: (state) => {
    state.agentCommissions.status = "fetching";
    state.agentCommissions.error = null;
    state.agentCommissions.data = [];
    state.agentCommissionTotal.data = 0;
  },
  [constants.GET_COMMISSIONS_BY_AGENT_SUCCESS]: (state, data) => {
    state.agentCommissions.status = "success";
    state.agentCommissions.error = null;
    state.agentCommissions.data = data.data;
    state.agentCommissionTotal.data = data.total;
  },
  [constants.GET_COMMISSIONS_BY_AGENT_ERROR]: (state, error) => {
    state.agentCommissions.status = "error";
    state.agentCommissions.error = error;
  },
  [constants.GET_COMMISSION_EXCEL]: (state) => {
    state.commissionExcel.status = "fetching";
    state.commissionExcel.error = null;
  },
  [constants.GET_COMMISSION_EXCEL_SUCCESS]: (state, data) => {
    state.commissionExcel.status = "success";
    state.commissionExcel.error = null;
    state.commissionExcel.data = data;
  },
  [constants.GET_COMMISSION_EXCEL_ERROR]: (state, error) => {
    state.commissionExcel.status = "error";
    state.commissionExcel.error = error;
  }

};
export default {
  state: getDefaultState(),
  actions,
  mutations,
};
