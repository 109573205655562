export const GET_INVOICE_LIST = "GET_INVOICE_LIST";
export const GET_INVOICE_LIST_SUCCESS = "GET_INVOICE_LIST_SUCCESS";
export const GET_INVOICE_LIST_ERROR = "GET_INVOICE_LIST_ERROR";

export const POST_INVOICE = "POST_INVOICE";
export const POST_INVOICE_SUCCESS = "POST_INVOICE_SUCCESS";
export const POST_INVOICE_ERROR = "POST_INVOICE_ERROR";

export const PUT_INVOICE = "PUT_INVOICE";
export const PUT_INVOICE_SUCCESS = "PUT_INVOICE_SUCCESS";
export const PUT_INVOICE_ERROR = "PUT_INVOICE_ERROR"; 