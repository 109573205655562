<template>
  <!-- Card stats -->
  <b-row>
    <b-col xl="3" md="6">
      <stats-card class="mb-4">
        <template slot>
          <div class="d-flex justify-content-between">
            <h5 class="card-title text-uppercase text-muted mb-0">
              Incomplete stock
            </h5>
            <div class="text-center">
              <i
                v-b-tooltip.click="{ variant: 'white' }"
                title="Warning within 2-3 days, Alert more than 3 days"
              >
                <font-awesome-icon
                  class="cursor-pointer text-blue"
                  :icon="['far', 'circle-question']"
                />
              </i>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <b-row>
              <b-col
                cols="auto"
                @click="applyFilter(inventoryFilterWarning)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-yellow"
                  :icon="['far', 'triangle-exclamation']"
                  size="2xl"
                />

                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">
                  {{ business != null ? business.inventoryWarning : 0 }}
                </span>
              </b-col>
              <b-col
                cols="auto"
                @click="applyFilter(inventoryFilterAlert)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-red"
                  :icon="['far', 'octagon-exclamation']"
                  size="2xl"
                />
                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">{{
                  business != null ? business.inventoryAlert : 0
                }}</span>
              </b-col>
            </b-row>
          </div>
        </template>
      </stats-card>
    </b-col>
    <b-col xl="3" md="6">
      <stats-card class="mb-4">
        <template slot>
          <div class="d-flex justify-content-between">
            <h5 class="card-title text-uppercase text-muted mb-0">Payments</h5>
            <div class="text-center">
              <i
                v-b-tooltip.click="{ variant: 'white' }"
                title="Warning within 2-4 days, Alert more than 4 days"
              >
                <font-awesome-icon
                  class="cursor-pointer text-blue"
                  :icon="['far', 'circle-question']"
                />
              </i>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <b-row>
              <b-col
                cols="auto"
                @click="applyFilter(paymentsFilterWarning)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-yellow"
                  :icon="['far', 'triangle-exclamation']"
                  size="2xl"
                />

                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">
                  {{ business != null ? business.paymentsWarning : 0 }}</span
                >
              </b-col>
              <b-col
                cols="auto"
                @click="applyFilter(paymentsFilterAlert)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-red"
                  :icon="['far', 'octagon-exclamation']"
                  size="2xl"
                />
                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">
                  {{ business != null ? business.paymentsAlert : 0 }}</span
                >
              </b-col>
            </b-row>
          </div>
        </template>
      </stats-card>
    </b-col>
    <b-col xl="3" md="6">
      <stats-card class="mb-4">
        <template slot>
          <div class="d-flex justify-content-between">
            <h5 class="card-title text-uppercase text-muted mb-0">
              Receivables
            </h5>
            <div class="text-center">
              <i
                v-b-tooltip.click="{ variant: 'white' }"
                title="Warning within 3-7 days, Alert more than 7 days"
              >
                <font-awesome-icon
                  class="cursor-pointer text-blue"
                  :icon="['far', 'circle-question']"
                />
              </i>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <b-row>
              <b-col
                cols="auto"
                @click="applyFilter(receivablesFilterWarning)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-yellow"
                  :icon="['far', 'triangle-exclamation']"
                  size="2xl"
                />

                <span class="h1 font-weight-bold mb-0 align-middle">
                  {{ business != null ? business.receivablesWarning : 0 }}</span
                >
              </b-col>

              <b-col
                cols="auto"
                @click="applyFilter(receivablesFilterAlert)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-red"
                  :icon="['far', 'octagon-exclamation']"
                  size="2xl"
                />
                <span class="h1 font-weight-bold mb-0 align-middle">
                  {{ business != null ? business.receivablesAlert : 0 }}</span
                >
              </b-col>
            </b-row>
          </div>
        </template>
        <template slot="footer">
          <span class="text-nowrap"></span>
        </template>
      </stats-card>
    </b-col>
    <b-col xl="3" md="6">
      <stats-card class="mb-4">
        <template slot>
          <div class="d-flex justify-content-between">
            <h5 class="card-title text-uppercase text-muted mb-0">PNL</h5>
            <div class="text-center">
              <i
                v-b-tooltip.click="{ variant: 'white' }"
                title="Vehicles with profit positive and negative"
              >
                <font-awesome-icon
                  class="cursor-pointer text-blue"
                  :icon="['far', 'circle-question']"
                />
              </i>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <b-row>
              <b-col
                cols="auto"
                @click="applyFilter(pnlProfit)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-success"
                  :icon="['far', 'chart-line-up']"
                  size="2xl"
                />

                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">
                  {{ business != null ? business.pnlProfit : 0 }}
                </span>
              </b-col>
              <b-col
                cols="auto"
                @click="applyFilter(pnlLoss)"
                class="cursor-pointer"
              >
                <font-awesome-icon
                  class="cursor-pointer text-red"
                  :icon="['far', 'chart-line-down']"
                  size="2xl"
                />
                <span class="h1 font-weight-bold mb-0 ml-1 align-middle">{{
                  business != null ? business.pnlLoss : 0
                }}</span>
              </b-col>
            </b-row>
          </div>
        </template>
        <template slot="footer">
          <span class="text-nowrap"></span>
        </template>
      </stats-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from "vuex";
import { SET_VEHICLES_INFO_FILTERS } from "@/modules/superAdmin/actions";
import { superAdminMixin } from "../../modules/superAdmin/mixins";
import { GET_BOARD_BUSINESS } from "./actions";
export default {
  name: "BoardCardsBusiness",
  mixins: [superAdminMixin],
  computed: {
    ...mapState({
      business: (state) => state.layout.boards.business?.data,
    }),
  },
  data() {
    return {
      inventoryFilterWarning: {
        stock: "0, 1",
        status: "InStock",
        vehicleDateFrom: this.getDateXDaysAgo(3),
        vehicleDateTo: this.getDateXDaysAgo(2),
      },
      inventoryFilterAlert: {
        stock: "0,1",
        status: "InStock",
        vehicleDateTo: this.getDateXDaysAgo(3),
      },
      paymentsFilterWarning: {
        stockDateFrom: this.getDateXDaysAgo(4),
        stockDateTo: this.getDateXDaysAgo(2),
        status: "InStock",
        payments: 0,
        noConsignment: 1,
      },
      paymentsFilterAlert: {
        stockDateTo: this.getDateXDaysAgo(4),
        status: "InStock",
        payments: 0,
        noConsignment: 1,
      },
      receivablesFilterWarning: {
        saleDateFrom: this.getDateXDaysAgo(7),
        saleDateTo: this.getDateXDaysAgo(3),
        status: "Sold",
        sold: 1,
      },
      receivablesFilterAlert: {
        saleDateTo: this.getDateXDaysAgo(7),
        status: "Sold",
        sold: 1,
      },
      pnlProfit: {
        pnlProfit: 1,
      },

      pnlLoss: {
        pnlLoss: 1,
      },
    };
  },
  mounted() {
    this.getLogisticInformation();
  },
  methods: {
    applyFilter(filters) {
      this.resetFilters(); //Method from superAdminMixin
      this.$store.commit(SET_VEHICLES_INFO_FILTERS, {
        fields: { ...filters },
      });
      this.getVehiclesInfo(); //Method from superAdminMixin
    },
    getDateXDaysAgo(numOfDays, date = new Date()) {
      const daysAgo = new Date(date);
      daysAgo.setDate(date.getDate() - numOfDays);
      return this.formatDateString(daysAgo.toString());
    },
    getLogisticInformation() {
      this.$store.dispatch(GET_BOARD_BUSINESS).then(() => {});
    },
    formatDateString(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const shortDate = `${year}-${month}-${day}`;
      return shortDate;
    },
  },
};
</script>

<style>
</style>
