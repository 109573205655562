<template>
  <div>
    <b-container class="mt-3">
      <b-card footer-tag="footer">
        <b-card-text class="text-center p-4">
          <div class="mb-4">
            <b-icon icon="check-circle" scale="4" variant="success" />
          </div>

          <h2>Car uploaded successfully</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          <p>
            <small>
              Vin number: <b>{{ $route.params.vin }}</b>
            </small>
          </p>
        </b-card-text>
        <template #footer>
          <div class="d-flex justify-content-between">
            <div>
              <b-button variant="secondary" @click="goBack">
                <b-icon icon="chevron-compact-left" />
                Back
              </b-button>
            </div>
          </div>
        </template>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import CompanyCarsService from "@/services/companyCarsService";
import { companyCarsMixin } from "@/modules/vehicle/components/visual-inspection/mixin";
export default {
  name: "SuccessCarUploaded",

  mixins: [companyCarsMixin],
  data() {
    return {
      isLoading: false,
    };
  },
  mounted() {
    this.getVin(this.$route.params.vin, (data) => {
      this.carId = data.id;
    });
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "company.cars.exterior",
        params: { vin: this.$route.params.vin },
      });
    },
    async completeCar() {
      this.isLoading = true;
      try {
        await this.uploadStatus("setToBid");
        this.makeToast(this.$t("success"), this.$t("carUpdated"), "success");
        this.$router.push({
          name: "company.cars.list",
          params: { vin: this.$route.params.vin },
        });
      } catch {
        this.makeToast(this.$t("error"), this.$t("errorMessage"), "danger");
      } finally {
        this.isLoading = false;
      }
    },
    async keepCarAsDraft() {
      this.isLoading = true;
      try {
        await this.uploadStatus("draft");
        this.makeToast(this.$t("success"), this.$t("carUpdated"), "success");
        this.$router.push({
          name: "company.cars.list",
          params: { vin: this.$route.params.vin },
        });
      } catch {
        this.makeToast(this.$t("error"), this.$t("errorMessage"), "danger");
      } finally {
        this.isLoading = false;
      }
    },
    uploadStatus(status) {
      return CompanyCarsService.changeStatusCar(this.carId, status);
    },
  },
};
</script>
