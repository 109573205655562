<template>
  <div>
    <small for="">Title received date</small>
    <FilterTypeDate fromType="exportReceivedDateFrom" toType="exportReceivedDateTo" />
  </div>
</template>

<script>
import FilterTypeDate from "./FilterTypeDate.vue";
export default {
  name: "ExportReceivedDateFilter",
  components: { FilterTypeDate },
};
</script>

<style></style>
