import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    fx: {
      status: null,
      data: {},
      error: null,
    },
  };
}
const actions = {
  [constants.GET_CURRENCY_CONVERSION]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_CURRENCY_CONVERSION);

      const response = await axios.get("currencyConverter/list", {
        params: payload,
      });
      commit(constants.GET_CURRENCY_CONVERSION_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_CURRENCY_CONVERSION_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_CURRENCY_CONVERSION]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_CURRENCY_CONVERSION);
      const response = await axios.put("currencyConverter/update", payload);
      commit(constants.PUT_CURRENCY_CONVERSION_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_CURRENCY_CONVERSION_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_CURRENCY_CONVERSION]: (state) => {
    state.fx.status = "fetching";
    state.fx.error = null;
  },
  [constants.GET_CURRENCY_CONVERSION_SUCCESS]: (state, data) => {
    state.fx.status = "success";
    state.fx.error = null;
    state.fx.data = data;
  },
  [constants.GET_CURRENCY_CONVERSION_ERROR]: (state, error) => {
    state.fx.status = "error";
    state.fx.error = error;
  },
  [constants.PUT_CURRENCY_CONVERSION]: (state) => {
    state.fx.status = "fetching";
    state.fx.error = null;
  },
  [constants.PUT_CURRENCY_CONVERSION_SUCCESS]: (state, data) => {
    state.fx.status = "success";
    state.fx.error = null;
    state.fx.data = data;
  },
  [constants.PUT_CURRENCY_CONVERSION_ERROR]: (state, error) => {
    state.fx.status = "error";
    state.fx.error = error;
  },
};
export default {
  state: getDefaultState(),
  actions,
  mutations,
};
