var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-form-group',{staticClass:"mb-2",attrs:{"label-class":"pb-1"}},[_c('template',{slot:"label"},[_c('b',[_vm._v("Vehicle: ")])]),_c('vue-single-select',{attrs:{"classes":{
      input: 'form-control',
      dropdown: 'dropdown p-0',
    },"options":_vm.vehicles,"getOptionDescription":() =>
        `${_vm.vehicle.year} - ${_vm.vehicle.make} - ${
          _vm.vehicle.model
        } - ${_vm.getLastSixCharactersOfVinNumber(_vm.vehicle.vinNumber)}`},scopedSlots:_vm._u([{key:"option",fn:function({ option }){return [_c('div',{},[_c('span',[_vm._v(" "+_vm._s(option.year)+" - "+_vm._s(option.make)+" - "+_vm._s(option.model)+" - "+_vm._s(_vm.getLastSixCharactersOfVinNumber(option.vinNumber)))])])]}}]),model:{value:(_vm.vehicle),callback:function ($$v) {_vm.vehicle=$$v},expression:"vehicle"}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }