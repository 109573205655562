<template>
  <div>
    <ExchangeContractNav />

    <div class="container-fluid mt-3">
      <ExchangeContractTotals />
      <b-row align-h="end">
        <b-col lg="3" md="3" sm="12">
          <ExchangeContractTypeSelect
            class="w-100"
            @typeChange="onTypeChange"
          />
        </b-col>
        <b-col lg="2" md="2" sm="12">
          <b-button
            :disabled="exchangeContractsXlsIsLoading"
            variant="success"
            @click="downloadXLS()"
            block
          >
            <b-spinner
              v-if="exchangeContractsXlsIsLoading"
              class="align-middle"
              variant="white"
              small
            />
            <span> Vehicles XLS </span>
          </b-button>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <b-button
            :disabled="exchangeContractsXlsIsLoading"
            variant="success"
            @click="downloadExchangeContractXLS()"
          >
            <b-spinner
              v-if="exchangeContractsXlsIsLoading"
              class="align-middle"
              variant="white"
              small
            />
            <span> Exchange contract XLS </span>
          </b-button>

          <b-dropdown
            variant="success"
            :disable="exchangeContractExcelByCurrencyIsLoading"
          >
            <template #button-content>
              <font-awesome-icon
                class="cursor-pointer"
                :icon="['far', 'file-excel']"
              />
            </template>
            <b-dropdown-item @click="downloadExchangeExcelByCurrency('cad')"
              >CAD</b-dropdown-item
            >
            <b-dropdown-item @click="downloadExchangeExcelByCurrency('usd')"
              >USD</b-dropdown-item
            >
          </b-dropdown>
        </b-col>
      </b-row>
      <div class="table-responsive">
        <b-table
          class="mt-3"
          show-empty
          bordered
          small
          stacked="md"
          head-variant="light"
          :items="exchangeContracts.data ? exchangeContracts.data.data : []"
          :fields="fields"
          :busy="exchangeContractIsLoading"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" variant="yellow" />
            </div>
          </template>
          <template #cell(vehicleFx)="{ item }">
            <span>{{ twoDecimals(item.vehicleFx) }}</span>
          </template>
          <template #cell(fx)="{ item }">
            <span>{{ item.fx }}</span>
          </template>
          <template #cell(allocatedAmount)="{ item }">
            <span>{{ calculateAllocatedAmount(item) | currency }}</span>
          </template>
          <template #cell(payments)="{ item }">
            <span>{{ (item.amount - item.pendingPayment) | currency }}</span>
          </template>
          <template #cell(show_details)="row">
            <div class="text-center">
              <font-awesome-icon
                v-if="row.item.listVehicles.length > 0"
                class="cursor-pointer mx-2"
                :icon="['far', 'cars']"
                @click="info(row.item, $event.target)"
              />
              <font-awesome-icon
                v-if="
                  row.item.paymentStatus == 0 || row.item.paymentStatus == 2
                "
                class="cursor-pointer mx-2"
                :icon="['far', 'sack-dollar']"
                @click="openPaymentModal(row.item, $event.target)"
              />
            </div>
          </template>
        </b-table>
        <b-pagination
          v-if="!exchangeContractIsLoading"
          :value="
            exchangeContracts.data ? exchangeContracts.data.currentPage : 1
          "
          :total-rows="
            exchangeContracts.data ? exchangeContracts.data.total : 0
          "
          :per-page="
            exchangeContracts.data ? exchangeContracts.data.perPage : 25
          "
          class="mb-2 mt-3"
          align="end"
          @change="changePage($event)"
        />
      </div>
    </div>
    <ExchangeContractVehiclesModal :exchangeSelected="exchangeSelected" />
    <b-modal
      id="exchange-contract-details-modal"
      ref="exchange-contract-details-modal"
      size="lg"
      hide-footer
      body-class=""
      ok-disabled
    >
      <ExchangeContractForm :refreshData="getData" />
    </b-modal>
    <b-modal
      id="exchange-contract-payment-modal"
      ref="exchange-contract-payment-modal"
      size="lg"
      hide-footer
      body-class=""
      ok-disabled
    >
      <ExchangeContractPaymentForm
        :refreshData="getData"
        :contractId="exchangeSelected ? exchangeSelected.id : null"
      />
    </b-modal>
  </div>
</template>
<script>
import { mapState } from "vuex";
import {
  GET_EXCHANGE_CONTRACTS,
  GET_EXCHANGE_CONTRACTS_VEHICLES_XLS,
  GET_EXCHANGE_CONTRACTS_XLS,
  GET_EXCHANGE_XLS_BY_CURRENCY,
} from "./actions";
import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../mixins";
import ExchangeContractVehiclesModal from "./ExchangeContractVehiclesModal.vue";
import ExchangeContractForm from "./ExchangeContractForm.vue";
import ExchangeContractNav from "./ExchangeContractNav.vue";
import ExchangeContractTypeSelect from "./ExchangeContractTypeSelect.vue";
import ExchangeContractTotals from "./ExchangeContractTotals.vue";
import ExchangeContractPaymentForm from "./payments/ExchangeContractPaymentForm.vue";
export default {
  name: "ExchangeContractTable",
  mixins: [generalMixin, superAdminMixin],
  components: {
    ExchangeContractVehiclesModal,
    ExchangeContractForm,
    ExchangeContractNav,
    ExchangeContractTypeSelect,
    ExchangeContractTotals,
    ExchangeContractPaymentForm,
  },
  computed: {
    ...mapState({
      exchangeContracts: (state) =>
        state.superAdminStore.exchangeContract.exchangeContracts,
      exchangeContractIsLoading: (state) =>
        state.superAdminStore.exchangeContract.exchangeContracts.status ==
        "fetching",
      totalCAD: (state) => state.superAdminStore.exchangeContract.totalCad,
      totalContractCad: (state) =>
        state.superAdminStore.exchangeContract.totalContractCad,
      totalContractUsd: (state) =>
        state.superAdminStore.exchangeContract.totalContractUsd,
      averageFx: (state) => state.superAdminStore.exchangeContract.averageFx,
      exchangeContractsXlsIsLoading: (state) =>
        state.superAdminStore.exchangeContract.exchangeContractsXls.status ==
        "fetching",
      exchangeContractExcelByCurrencyIsLoading: (state) =>
        state.superAdminStore.exchangeContract.exchangeContractExcelByCurrency
          .status == "fetching",
    }),
    fxTwoDecimals() {
      /* return using 2 decimals trim*/
      const fx = this.averageFx.toString();
      const fxArray = fx.split(".");
      const fxArrayLength = fxArray.length;
      if (fxArrayLength > 1) {
        const fxArrayLast = fxArray[fxArrayLength - 1];
        if (fxArrayLast.length > 2) {
          return fxArray[0] + "." + fxArrayLast.substring(0, 2);
        }
      }
      return fx;
    },

    fields() {
      return [
        {
          key: "fx",
          label: "Contract FX",
          class: "text-center",
        },
        {
          key: "vehicleFx",
          label: "Vehicle FX",
          class: "text-center",
        },
        {
          key: "contractNumber",
          label: "Contract number",
          class: "text-center",
        },
        {
          key: "createdAt",
          label: "Registered date",
          class: "text-center",
          formatter: (value) => this.$options.filters.formatDate(value),
        },
        {
          key: "startDate",
          label: "Start date",
          class: "text-center",
          formatter: (value) => this.$options.filters.formatDate(value),
        },
        {
          key: "endDate",
          label: "End date",
          class: "text-center",
          formatter: (value) => this.$options.filters.formatDate(value),
        },

        {
          key: "amount",
          label: "USD",
          class: "text-center",
          formatter: (amount) =>
            typeof amount == "number"
              ? this.$options.filters.currency(amount)
              : "-",
        },
        {
          key: "amountCad",
          label: "CAD",
          class: "text-center",
          formatter: (amount) =>
            typeof amount == "number"
              ? this.$options.filters.currency(amount)
              : "-",
        },
        {
          key: "missingDays",
          label: "Missing days",
          class: "text-center",
        },
        /*     {
          key: "allocatedAmount",
          label: "ALLOCATED AMOUNT ",
          class: "text-center",
          formatter: (amount) =>
            typeof amount == "number"
              ? this.$options.filters.currency(amount)
              : "-",
        }, */
        /*     {
          key: "pendingAmount",
          label: "AVAILABLE AMOUNT ",
          class: "text-center",
          formatter: (amount) =>
            typeof amount == "number"
              ? this.$options.filters.currency(amount)
              : "-",
        }, */
        {
          key: "pendingPayment",
          label: "Balance USD",
          class: "text-center",
          formatter: (amount) =>
            typeof amount == "number"
              ? this.$options.filters.currency(amount)
              : "-",
        },
        {
          key: "payments", // amounCcad - pendingAmount
          label: "Payments ",
          class: "text-center",
          formatter: (amount) =>
            typeof amount == "number"
              ? this.$options.filters.currency(amount)
              : "-",
        },
        {
          key: "show_details",
          label: "Actions",
          class: "text-center",
        },
      ];
    },
  },
  data() {
    return {
      exchangeSelected: null,
      type: 0,
    };
  },
  mounted() {
    this.$store.commit("setSearchFunction", this.getData);
    this.$store.commit("setAddFunction", this.showModal);

    this.getData();
  },
  methods: {
    getData(filter = "") {
      this.$store
        .dispatch(GET_EXCHANGE_CONTRACTS, {
          search: filter,
          paymentStatus: this.type,
        })
        .then(() => {})
        .catch(() => {});
    },

    changePage(page, filter = "") {
      const payload = {
        search: filter,
        page: page,
        paymentStatus: this.type,
      };
      this.$store
        .dispatch(GET_EXCHANGE_CONTRACTS, payload)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    info(item) {
      this.exchangeSelected = item;
      this.$bvModal.show("exchange-contract-vehicles-modal");
    },

    goToVinNumberView(id) {
      /* encrypt id */
      const encryptedId = this.$CryptoJS.AES.encrypt(
        JSON.stringify(id),
        "Secret Passphrase"
      ).toString();

      this.$router.push({
        name: "superadmin.vehicles.unit",
        params: {
          id: encryptedId,
        },
      });
    },
    showModal() {
      this.$bvModal.show("exchange-contract-details-modal");
    },
    onTypeChange(type) {
      this.type = type;
      this.getData();
    },
    openPaymentModal(contract) {
      this.exchangeSelected = contract;

      this.$bvModal.show("exchange-contract-payment-modal");
    },
    calculateAllocatedAmount(contract) {
      const amount = contract.amountCad;
      const pendingAmount = contract.pendingAmount;

      return amount - pendingAmount;
    },
    twoDecimals(value) {
      const fx = value.toString();
      const fxArray = fx.split(".");
      const fxArrayLength = fxArray.length;
      if (fxArrayLength > 1) {
        const fxArrayLast = fxArray[fxArrayLength - 1];
        if (fxArrayLast.length > 2) {
          return fxArray[0] + "." + fxArrayLast.substring(0, 2);
        }
      }
      return fx;
    },
    downloadXLS() {
      this.$store
        .dispatch(GET_EXCHANGE_CONTRACTS_VEHICLES_XLS, {
          paymentStatus: this.type,
        })
        .then((response) => {
          const blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            this.formatFileName("exchange-rate-contract") + ".xls" ||
            "ficher.xls";
          link.click();
        });
    },
    downloadExchangeContractXLS() {
      this.$store
        .dispatch(GET_EXCHANGE_CONTRACTS_XLS, {
          paymentStatus: this.type,
        })
        .then((response) => {
          const blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            this.formatFileName("exchange-rate-contract") + ".xls" ||
            "ficher.xls";
          link.click();
        });
    },
    downloadExchangeExcelByCurrency(currency) {
      this.$store
        .dispatch(GET_EXCHANGE_XLS_BY_CURRENCY, {
          type: currency,
        })
        .then((response) => {
          const blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            this.formatFileName("exchange-contract-" + currency) + ".xls" ||
            "ficher.xls";
          link.click();
        });
    },
    formatFileName(module) {
      let fileName = "";
      const today = new Date();
      fileName = `${module}-${today.getFullYear()}-${today.getMonth()}-${today.getDate()}-${today.getHours()}-${today.getMinutes()}-${today.getSeconds()}`;
      return fileName;
    },
  },
};
</script>
