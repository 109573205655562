<template>
  <footer class="footer px-4 mx-2 pt-5">
    <b-row align-v="center" class="justify-content-lg-between">
      <b-col lg="6">
        <p class="copyright text-center text-xl-left text-muted">
          Copyright 2021 QAS Powered by
          <a href="https://propulsoft.ca/" class="font-weight-bold ml-1">
            Propulsoft.ca</a
          >
        </p>
      </b-col>
    </b-row>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style></style>
