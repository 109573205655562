<template>
  <div>
    <FilterTypeDate fromType="reserveDateFrom" toType="reserveDateTo" />
  </div>
</template>

<script>
import FilterTypeDate from "./FilterTypeDate.vue";

export default {
  name: "ReserveDateFilter",
  components: { FilterTypeDate },
};
</script>

<style></style>
