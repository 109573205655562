<template>
  <div>
    <b-container fluid class="mt-5">
      <b-row>
        <b-col sm="12" lg="6">
          <card header-classes="bg-transparent">
            <h3 slot="header" class="mb-0">
              <!-- Return bottom -->
              <b-button class="p-0" @click="goBack()">
                <b-icon icon="arrow-left-circle" font-scale="1.7" />
              </b-button>
              Timeline
            </h3>
            <b-spinner v-if="timeline.status == 'fetching'" class="mx-auto"></b-spinner>
            <time-line v-else type="one-side">
              <time-line-item
                v-for="(timelineItem, key) in timeline.data.timeLine"
                :key="key"
                :badge-type="getVariantByAction(timelineItem.action)"
                :badge-icon="getBadgeIconByModule(timelineItem.module)"
              >
                <small class="text-muted font-weight-bold"
                  >{{ timelineItem.date || dateTime }} -
                  {{ timelineItem.nickname }}</small
                >
                <h5 class="mt-3 mb-0">{{ timelineItem.action }}</h5>
                <p class="text-sm mt-1 mb-0">
                  {{ timelineItem.description }}
                </p>
                <!--                 <div class="mt-3">
                   <badge rounded type="success">design</badge>&nbsp;
                  <badge rounded type="success">system</badge>&nbsp;
                  <badge rounded type="success">creative</badge>
                </div> -->
              </time-line-item>
            </time-line>
          </card>
        </b-col>
        <b-col sm="12" md="6">
          <VehicleTimelineNotes :notes="timeline.data.notes || []" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { TimeLine, TimeLineItem } from "@/components";
import { GET_TIMELINE } from "./actions";
import { mapState } from "vuex";
import { icons } from "@/helpers/iconByModule.js";
import VehicleTimelineNotes from "./VehicleTemelineNotes.vue";
export default {
  name: "VehicleTimeline",
  components: {
    TimeLine,
    TimeLineItem,
    VehicleTimelineNotes,
  },
  computed: {
    ...mapState({
      timeline: (state) => state.superAdminStore.vehicleTimeline.timeline,
    }),
  },
  mounted() {
    this.getTimelineHistory();
  },
  methods: {
    getBadgeIconByModule(module) {
      return icons[module];
    },
    separateActionAndModule(action) {
      let actionAndModule = action.split(" ");
      return actionAndModule;
    },
    getTimelineHistory() {
      this.$store.dispatch(GET_TIMELINE, { id: this.$route.params.id }).then(() => {});
    },
    getVariantByAction(action) {
      switch (action) {
        case "create":
          return "success";
        case "update":
          return "yellow";
        case "delete":
          return "danger";
        default:
          return "success";
      }
    },
    isCarfax(action) {
      return action.includes("carfax");
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>
