<template>
  <b-modal
    id="checks-modal"
    ref="modal"
    modal-class="modal-fullscreen"
    centered
    scrollable
    size="lg"
    title="Payments"
    @show="setVehicle()"
    @hide="getVehiclesInfo()"
  >
    <VehicleDetails
      v-if="vehicle"
      :vehicle="vehicle"
      :color="vehicle.vehicleInformation.color || '----'"
    />
    <Payments />

    <template v-slot:modal-footer="{ close }">
      <div class="justify-content-end">
        <b-button
          variant="outline-secondary"
          class="mr-2"
          @click="() => close()"
        >
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
/* import CheckRegistrationForm from "./RegistrationForm";
 */ import Payments from "@/modules/vehicle/components/payments/Table.vue";

import { SET_VEHICLES_CHECKS_FILTERS } from "../actions";
import { POST_VEHICLE_BY_STOCK_SUCCESS } from "@/modules/vehicle/actions";
import { superAdminMixin } from "../../mixins";
import VehicleDetails from "@/modules/superAdmin/components/VehicleDetails.vue";
export default {
  components: { Payments, VehicleDetails },
  mixins: [superAdminMixin],
  computed: {
    ...mapState({
      checks: (state) => state.superAdminStore.componentStore.checks,
      vehicle: (state) => state.superAdminStore.vehicle,
    }),
  },
  methods: {
    changePage(page) {
      this.$store.commit(SET_VEHICLES_CHECKS_FILTERS, {
        fields: { page },
      });
      this.getChecks(false, this.vehicle);
    },
    resetCheckForm() {
      console.log(this.$refs.modal.$refs);
    },
    setVehicle() {
      this.$store.commit(POST_VEHICLE_BY_STOCK_SUCCESS, { data: this.vehicle });
    },
  },
};
</script>

<style scoped>
.check-list {
  max-height: 500px;
  overflow-y: auto;
}
.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
}
.dot-danger {
  background-color: #ca0707;
}
.dot-success {
  background-color: #19c631;
}
@import "../styles/modals.css";
</style>
