<template>
  <div>
    <b-navbar
      toggleable="lg"
      variant="light"
      align="end"
      class="nav mt-4 mb-5 shadow"
    >
      <b-navbar-toggle target="nav-collapse" />
      <b-collapse id="nav-collapse" is-nav align="end">
        <b-navbar-nav class="ml-auto">
          <b-navbar-nav>
            <b-nav-item
              @click="goTo('superadmin.exchange-contract')"
              :active="isRouteActive('superadmin.exchange-contract')"
            >
              Contracts
            </b-nav-item>
            <b-nav-item
              @click="goTo('superadmin.exchange-contract.payments')"
              :active="isRouteActive('superadmin.exchange-contract.payments')"
            >
              Payments
            </b-nav-item>
          </b-navbar-nav>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <!--     <router-view />
 -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      route: "superadmin.exchange-contract",
    };
  },
  computed: {},
  methods: {
    goTo(route) {
      this.route = route;
      if (this.$route.name === route) return;
      this.$router.push({ name: route });
    },
    isRouteActive(route) {
      return this.$route.name === route;
    },
  },
};
</script>

<style></style>
