export const GET_VEHICLE = "GET_VEHICLE";
export const GET_VEHICLE_SUCCESS = "GET_VEHICLE_SUCCESS";
export const GET_VEHICLE_ERROR = "GET_VEHICLE_ERROR";

export const SET_ONE_VEHICLE_FILTERS = "SET_ONE_VEHICLE_FILTERS";

export const GET_VEHICLES_BY_VIN = "GET_VEHICLES_BY_VIN";
export const GET_VEHICLES_BY_VIN_SUCCESS = "GET_VEHICLES_BY_VIN_SUCCESS";
export const GET_VEHICLES_BY_VIN_ERROR = "GET_VEHICLES_BY_VIN_ERROR";
export const SET_VEHICLES_BY_VIN_FILTERS = "SET_VEHICLES_BY_VIN_FILTERS";

export const POST_VEHICLE_BY_STOCK = "POST_VEHICLE_BY_STOCK";
export const POST_VEHICLE_BY_STOCK_SUCCESS = "POST_VEHICLE_BY_STOCK_SUCCESS";
export const POST_VEHICLE_BY_STOCK_ERROR = "POST_VEHICLE_BY_STOCK_ERROR";

export const GET_AGENTS = "GET_AGENTS";
export const GET_AGENTS_SUCCESS = "GET_AGENTS_SUCCESS";
export const GET_AGENTS_ERROR = "GET_AGENTS_ERROR";

export const GET_CLIENTS = "GET_CLIENTS";
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";
export const GET_CLIENTS_ERROR = "GET_CLIENTS_ERROR";

export const PUT_VEHICLE_BY_VIN = "PUT_VEHICLE_BY_VIN";
export const PUT_VEHICLE_BY_VIN_SUCCESS = "PUT_VEHICLE_BY_VIN_SUCCESS";
export const PUT_VEHICLE_BY_VIN_ERROR = "PUT_VEHICLE_BY_VIN_ERROR";

export const PUT_ASSIGN_CANADIAN_INFO_TO_VEHICLE =
  "PUT_ASSIGN_CANADIAN_INFO_TO_VEHICLE";
export const PUT_ASSIGN_CANADIAN_INFO_TO_VEHICLE_SUCCESS =
  "PUT_ASSIGN_CANADIAN_INFO_TO_VEHICLE_SUCCESS";
export const PUT_ASSIGN_CANADIAN_INFO_TO_VEHICLE_ERROR =
  "PUT_ASSIGN_CANADIAN_INFO_TO_VEHICLE_ERROR";

export const GET_MAKES_AND_MODELS = "GET_MAKES_AND_MODELS";
export const GET_MAKES_AND_MODELS_SUCCESS = "GET_MAKES_AND_MODELS_SUCCESS";
export const GET_MAKES_AND_MODELS_ERROR = "GET_MAKES_AND_MODELS_ERROR";
export const SET_MAKES_AND_MODELS_FILTERS = "SET_MAKES_AND_MODELS_FILTERS";

export const GET_VALIDATE_VIN_NUMBER = "GET_VALIDATE_VIN_NUMBER";
export const GET_VALIDATE_VIN_NUMBER_SUCCESS =
  "GET_VALIDATE_VIN_NUMBER_SUCCESS";
export const GET_VALIDATE_VIN_NUMBER_ERROR = "GET_VALIDATE_VIN_NUMBER_ERROR";

export const GET_EXPORT_LOCATIONS = "GET_EXPORT_LOCATIONS";
export const GET_EXPORT_LOCATIONS_SUCCESS = "GET_EXPORT_LOCATIONS_SUCCESS";
export const GET_EXPORT_LOCATIONS_ERROR = "GET_EXPORT_LOCATIONS_ERROR";

export const GET_FINANCIAL_REFERRAL_PRICE_INFO =
  "GET_FINANCIAL_REFERRAL_PRICE_INFO";
export const GET_FINANCIAL_REFERRAL_PRICE_INFO_SUCCESS =
  "GET_FINANCIAL_REFERRAL_PRICE_INFO_SUCCESS";
export const GET_FINANCIAL_REFERRAL_PRICE_INFO_ERROR =
  "GET_FINANCIAL_REFERRAL_PRICE_INFO_ERROR";

export const GET_FINANCIAL_REFERRAL_PRICE_PDF =
  "GET_FINANCIAL_REFERRAL_PRICE_PDF";
export const GET_FINANCIAL_REFERRAL_PRICE_PDF_SUCCESS =
  "GET_FINANCIAL_REFERRAL_PRICE_PDF_SUCCESS";
export const GET_FINANCIAL_REFERRAL_PRICE_PDF_ERROR =
  "GET_FINANCIAL_REFERRAL_PRICE_PDF_ERROR";

export const PUT_CONTRACT_REGISTRATION_FILE = "PUT_CONTRACT_REGISTRATION_FILE";
export const PUT_CONTRACT_REGISTRATION_FILE_SUCCESS =
  "PUT_CONTRACT_REGISTRATION_FILE_SUCCESS";
export const PUT_CONTRACT_REGISTRATION_FILE_ERROR =
  "PUT_CONTRACT_REGISTRATION_FILE_ERROR";

export const GET_RE_STOCK_VALIDATE = "GET_RE_STOCK_VALIDATE"
export const GET_RE_STOCK_VALIDATE_SUCCESS = "GET_RE_STOCK_VALIDATE_SUCCESS"
export const GET_RE_STOCK_VALIDATE_ERROR = "GET_RE_STOCK_VALIDATE_ERROR"

export const POST_NEW_VEHICLE_REGISTRATION = "POST_NEW_VEHICLE_REGISTRATION";
export const POST_NEW_VEHICLE_REGISTRATION_SUCCESS = "POST_NEW_VEHICLE_REGISTRATION_SUCCESS";
export const POST_NEW_VEHICLE_REGISTRATION_ERROR = "POST_NEW_VEHICLE_REGISTRATION_ERROR";

export const GET_VEHICLE_LOCATION_INFO = "GET_VEHICLE_LOCATION_INFO";
export const GET_VEHICLE_LOCATION_INFO_SUCCESS = "GET_VEHICLE_LOCATION_INFO_SUCCESS";
export const GET_VEHICLE_LOCATION_INFO_ERROR = "GET_VEHICLE_LOCATION_INFO_ERROR";

export const GET_VEHICLE_FILES = "GET_VEHICLE_FILES";
export const GET_VEHICLE_FILES_SUCCESS = "GET_VEHICLE_FILES_SUCCESS";
export const GET_VEHICLE_FILES_ERROR = "GET_VEHICLE_FILES_ERROR";

export const PUT_DISABLE_VEHICLE = "PUT_DISABLE_VEHICLE";
export const PUT_DISABLE_VEHICLE_SUCCESS = "PUT_DISABLE_VEHICLE_SUCCESS";
export const PUT_DISABLE_VEHICLE_ERROR = "PUT_DISABLE_VEHICLE_ERROR";