<template>
  <b-card v-if="cluster">
    <b-row>
      <b-col cols="10">
        <span>
          Registered date:
          <i
            >{{ cluster.createdAt | formatDateTime }} - {{ cluster.user.firstName }}
            {{ cluster.user.lastName }}</i
          > </span
        ><br />

        <span v-if="cluster.status == 2">
          End date:
          <i>
            {{ cluster.finalDate | formatDateTime }} -
            {{
              cluster.finalUser
                ? cluster.finalUser.firstName + " " + cluster.finalUser.lastName
                : "No one"
            }}
          </i>
        </span>
        <br />
        <br />
        <span v-if="cluster.supplierCompany"
          >Supplier: <i> {{ cluster.supplierCompany.companyName }} </i>
        </span>
        <br />
        <br />
        <span
          >Status:
          <i> {{ getVariantAndLabel(cluster.status).label }}</i>
        </span>
      </b-col>
      <b-col class="text-center">
        <div class="text-center">
          <span
            class="dot"
            :style="{
              'background-color': getVariantAndLabel(cluster.status).variant,
            }"
          />
          <br />

          <b-icon
            v-if="cluster.status == 1 && userHasPermission('cluster', 'write')"
            icon="check-square"
            scale="1.3"
            class="m-1 cursor-pointer"
            id="cluster-status-btn-4"
            @click="updateClusterStatus(2)"
          ></b-icon>
          <b-tooltip target="cluster-status-btn-4">Mark as completed</b-tooltip>
          <b-icon
            v-if="cluster.status == 1 && userHasPermission('cluster', 'write')"
            icon="x-square"
            class="m-1 cursor-pointer"
            v-b-tooltip.hover
            title="Cancel"
            id="cancel-work-order"
            scale="1.3"
            @click="showCancelNote = true"
          ></b-icon>
          <b-tooltip target="cancel-work-order">Cancel</b-tooltip>
        </div>
      </b-col>
      <b-col v-if="showCancelNote">
        <b-form-group label="Note">
          <b-form-textarea
            v-model="note"
            class="resize-none"
            aria-describedby="input-description-live-feedback"
          />
        </b-form-group>
        <!-- Cancel button and confirm button -->
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12" class="">
            <div class="float-right">
              <b-btn
                size="lg"
                class="btn btn-danger mr-2"
                type="button"
                @click="showCancelNote = false"
              >
                Cancel
              </b-btn>

              <b-btn
                size="lg"
                class="btn btn-success"
                type="button"
                @click="updateClusterStatus(0)"
              >
                Confirm
              </b-btn>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col v-if="cluster.note">
        <span>
          Note: <i>{{ cluster.note }}</i>
        </span>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { PUT_CLUSTER } from "./actions";
import { generalMixin } from "@/modules/mixin.js";
import { mapState } from "vuex";
import { superAdminMixin } from "../../mixins";

export default {
  components: {},
  props: ["cluster", "refreshData"],
  mixins: [generalMixin, superAdminMixin],

  data() {
    return {
      isEditing: false,
      form: {
        cost: this.cluster.cost,
      },
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "CAD$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      showCancelNote: false,
      note: "",
    };
  },
  computed: {
    ...mapState({
      vehicle: (state) => state.superAdminStore.vehicle,
    }),
  },
  methods: {
    getVariantAndLabel(status) {
      switch (status) {
        case 0:
          return {
            variant: "#dc3545", // red
            label: "Canceled",
          };
        case 1:
          return {
            variant: "#fd7e14", //yellow
            label: "Waiting for quote",
          };

        case 2:
          return {
            variant: "#fd7e14", // orange
            label: "Validated and quoted",
          };
        case 3:
          return {
            variant: "#02afaa", // blue
            label: "Waiting for validation",
          };
        case 4:
          return {
            variant: "#54b962", // green
            label: "Completed",
          };
      }
    },
    updateClusterStatus(status) {
      this.$store
        .dispatch(PUT_CLUSTER, {
          id: this.cluster.id,
          status: status,
          note: this.note,
        })
        .then(() => {
          this.refreshData();
          this.$root.$bvToast.toast("Cluster status updated", {
            title: "Success",
            variant: "success",
          });
        })
        .catch(() => {
          this.$root.$bvToast.toast("Cluster could not update", {
            title: "Error",
            variant: "warning",
          });
        });
    },

    openEmailBodyModal(id) {
      this.$root.$bvModal.show("email-body-modal" + id);
    },
  },
};
</script>

<style></style>
