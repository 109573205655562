<template>
  <b-modal
    id="work-order-modal"
    ref="modal"
    centered
    no-close-on-backdrop
    scrollable
    size="lg"
    title="Work orders"
    @show="getWorkOrders()"
    @hide="getVehiclesInfo()"
    hide-footer
  >
    <VehicleDetails
      v-if="vehicle"
      :vehicle="vehicle"
      :color="vehicle.vehicleInformation.color"
    />
    <RegistrationForm
      v-if="userHasPermission('wo', 'write')"
      :vehicle="vehicle"
      :refreshData="getWorkOrders"
      :workOrderSelected="workOrderSelected"
    />
    <div>
      <b-tabs content-class="mt-3">
        <b-tab title="With provider" active>
          <b-list-group v-if="workOrders.status == 'fetching'" class="mt-2">
            <b-list-group-item class="text-center">
              <b-spinner variant="yellow" />
            </b-list-group-item>
          </b-list-group>
          <b-list-group v-else class="mt-2 mb-2 note-list" tag="ul">
            <b-list-group-item
              v-if="workOrders.data && !workOrders.data.length"
              class="text-center"
            >
              <b>No work orders to show</b>
            </b-list-group-item>
            <b-list-group-item
              v-for="(workOrderProvider, i) in workOrders.data"
              :key="i"
              class="flex-column align-items-start p-0"
              tag="li"
            >
              <div class="accordion" role="tablist">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button
                      block
                      v-b-toggle.accordion-header="'accordion-1' + i"
                      class="text-left"
                      variant="dark-green"
                    >
                      <span class="text-left">
                        {{ workOrderProvider.companyName }}
                      </span>
                      <span class="text-right">
                        <b-badge pill variant="white" class="text-dark">{{
                          workOrderProvider.workOrders.length
                        }}</b-badge>
                      </span>
                      <span>
                        <b-button
                          variant="yellow"
                          size="sm"
                          @click="getWorkOrderXLS(workOrderProvider.id)"
                          class="float-right mr-4"
                        >
                          <b-icon
                            icon="file-earmark-arrow-down"
                            variant="black"
                          ></b-icon>
                        </b-button>
                      </span>
                    </b-button>
                  </b-card-header>
                  <b-collapse
                    :id="'accordion-1' + i"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <WorkOrderCard
                      v-for="(workOrder, i) in workOrderProvider.workOrders"
                      :key="i"
                      :workOrder="workOrder"
                      :refreshData="getWorkOrders"
                      @update-work-order="editWorkOrder"
                    />
                  </b-collapse>
                </b-card>
              </div>
            </b-list-group-item>
          </b-list-group>
          <b-pagination
            v-if="workOrders.data && workOrders.data.length"
            :value="workOrders.data.currentPage"
            :total-rows="workOrders.data.total"
            :per-page="workOrders.data.perPage"
            class="mt-2"
            align="right"
            @change="changePage($event)"
          />
        </b-tab>
        <b-tab title="No provider">
          <WorkOrderListNoProvider />
        </b-tab>
      </b-tabs>
    </div>

    <template v-slot:modal-footer=""> </template>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import { GET_CATEGORIES } from "@/modules/vehicle/components/work-orders/categories/actions";
import {
  GET_WORK_ORDERS,
  GET_WORK_ORDER_XLS,
  GET_WORK_ORDERS_NO_PROVIDER,
} from "./actions";
import WorkOrderCard from "./WorkOrderCard.vue";
import RegistrationForm from "./RegistrationForm";
import { superAdminMixin } from "../../mixins";
import VehicleDetails from "@/modules/superAdmin/components/VehicleDetails.vue";
import WorkOrderListNoProvider from "./WorkOrderListNoProvider.vue";
import { generalMixin } from "../../../mixin";

export default {
  components: {
    WorkOrderCard,
    RegistrationForm,
    VehicleDetails,
    WorkOrderListNoProvider,
  },
  mixins: [generalMixin, superAdminMixin],

  computed: {
    ...mapState({
      vehicle: (state) => state.superAdminStore.vehicle,

      categories: (state) => state.vehicle.workOrder.categories.categories,
      providers: (state) => state.vehicle.workOrder.providers.providers,
      workOrders: (state) =>
        state.superAdminStore.componentStore.workOrder.workOrders,
    }),
  },
  data() {
    return {
      workOrderSelected: null,
    };
  },
  methods: {
    getCategories() {
      this.$store
        .dispatch(GET_CATEGORIES)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    getProviders() {
      this.$store
        .dispatch("GET_COMPANIES_LIST", { isSupplier: 1 })
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    getWorkOrders() {
      console.log(this.vehicle.id);
      this.$store
        .dispatch(GET_WORK_ORDERS, { vehicleId: this.vehicle.id })
        .then((response) => {
          console.log(response);
        })
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });

      this.$store
        .dispatch(GET_WORK_ORDERS_NO_PROVIDER, { vehicleId: this.vehicle.id })
        .then(() => {});
    },
    getWorkOrderXLS(supplierCompanyId) {
      this.$store
        .dispatch(GET_WORK_ORDER_XLS, {
          vehicleId: this.vehicle.id,
          supplierCompanyId: supplierCompanyId,
        })
        .then((response) => {
          const blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = this.formatFileName("work-order", this.vehicle);
          link.click();
        })
        .catch((error) => {
          console.log(error);
          this.$root.$bvToast.toast("Could not get XLS", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    editWorkOrder(workOrder) {
      this.workOrderSelected = workOrder;
      console.log(workOrder);
    },
  },
};
</script>

<style></style>
