export const GET_ADDON_TYPE = "GET_ADDON_TYPE";
export const GET_ADDON_TYPE_SUCCESS = "GET_ADDON_TYPE_SUCCESS";
export const GET_ADDON_TYPE_ERROR = "GET_ADDON_TYPE_ERROR";

export const GET_ADDON_TYPE_LIST = "GET_ADDON_TYPE_LIST";
export const GET_ADDON_TYPE_LIST_SUCCESS = "GET_ADDON_TYPE_LIST_SUCCESS";
export const GET_ADDON_TYPE_LIST_ERROR = "GET_ADDON_TYPE_LIST_ERROR";

export const POST_ADDON_TYPE = "POST_ADDON_TYPE";
export const POST_ADDON_TYPE_SUCCESS = "POST_ADDON_TYPE_SUCCESS";
export const POST_ADDON_TYPE_ERROR = "POST_ADDON_TYPE_ERROR";

export const PUT_ASSIGN_ADDON_TYPE = "PUT_ASSIGN_ADDON_TYPE";
export const PUT_ASSIGN_ADDON_TYPE_SUCCESS = "PUT_ASSIGN_ADDON_TYPE_SUCCESS";
export const PUT_ASSIGN_ADDON_TYPE_ERROR = "PUT_ASSIGN_ADDON_TYPE_ERROR";

export const GET_ADDON_TYPE_COMPANIES = "GET_ADDON_TYPE_COMPANIES";
export const GET_ADDON_TYPE_COMPANIES_SUCCESS = "GET_ADDON_TYPE_COMPANIES_SUCCESS";
export const GET_ADDON_TYPE_COMPANIES_ERROR = "GET_ADDON_TYPE_COMPANIES_ERROR";
