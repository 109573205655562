<template>
  <div class="container-fluid mt-3">
    <!--     <h4 class="ml-3 mt-3">Assign marketplaces</h4>
 -->
    <b-row class="">
      <b-col>
        <UserSelect @user-select="setUserSelect($event)" />
      </b-col>
      <b-col>
        <b-button
          @click="updatePermissions()"
          :disabled="marketplaces.status === 'fetching'"
          >Update marketplaces</b-button
        ></b-col
      >
    </b-row>
    <div class="pt-4">
      <div class="">
        <b-table
          show-empty
          bordered
          small
          stacked="md"
          head-variant="light"
          :items="marketplaceList"
          :fields="fields"
          :busy="marketplaces.status === 'fetching'"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" variant="yellow" />
            </div>
          </template>
          <template #cell(selected)="{ item }">
            <div class="d-flex justify-content-center">
              <b-form-checkbox
                :checked="item.selected ? true : false"
                @change="(val) => onRowSelected(val, item)"
              />
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { generalMixin } from "../../mixin";
import { GET_MARKETPLACE_LIST, PUT_ASSIGN_MARKETPLACE } from "./actions";
import UserSelect from "./UserSelect.vue";
export default {
  components: {
    UserSelect,
  },
  mixins: [generalMixin],
  computed: {
    ...mapState({
      marketplaces: (state) =>
        state.masterUser.marketplace.marketplaceList.data,
    }),
    marketplaceFormatted() {
      return this.marketplaceList;
    },
  },
  data() {
    return {
      fields: [
        { key: "name", label: "Name" },
        { key: "selected", label: "Permission" },
      ],
      userSelected: null,
      marketplaceList: [],
    };
  },
  created() {
    this.$store.commit("setAddFunction", null);
    this.getData();
  },
  methods: {
    getData() {
      this.$store
        .dispatch(GET_MARKETPLACE_LIST)
        .then(() => {
          this.marketplaceList = this.formatCorporations();
          this.$children[0].getUsersPermission();
          this.resetPermissions();
          this.userSelected = null;
          this.$children[0].clearUserSelect();
        })
        .catch(() => {});
    },
    setUserSelect(user) {
      this.userSelected = user;
      this.marketplaceList = [...this.marketplaces];
      this.resetPermissions();
      this.setCorporationByUser();
    },

    onRowSelected(val, item) {
      if (val) {
        item.selected = true;
      } else {
        item.selected = false;
      }
      const index = this.marketplaceList.findIndex(
        (permission) => permission.id === item.id
      );
      this.marketplaceList[index] = item;
      this.marketplaceList = [...this.marketplaceList];
    },
    formatCorporations() {
      this.marketplaceList = [];
      let marketplaceList = [...this.marketplaces];
      marketplaceList.forEach((permission) => {
        permission.selected = false;
      });
      return marketplaceList;
    },
    setCorporationByUser() {
      this.marketplaceList.forEach((permission) => {
        this.userSelected.marketPlacePermissions.forEach((corp) => {
          if (permission.id === corp.id) {
            permission.selected = true;
          }
        });
      });
      this.marketplaceList = [...this.marketplaceList];
    },
    resetPermissions() {
      this.marketplaceList.forEach((permission) => {
        permission.selected = false;
      });
      this.marketplaceList = [...this.marketplaceList];
    },
    updatePermissions() {
      const itemsSelected = this.marketplaceList.filter(
        (permission) => permission.selected === true
      );
      this.$store
        .dispatch(PUT_ASSIGN_MARKETPLACE, {
          userId: this.userSelected.id,
          marketPlaces: itemsSelected,
        })
        .then(() => {
          this.makeToast("Success", "Permissions updated", "success");
          this.getData();
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
</style>