import { render, staticRenderFns } from "./VinData.vue?vue&type=template&id=cbaa6994&scoped=true&"
import script from "./VinData.vue?vue&type=script&lang=js&"
export * from "./VinData.vue?vue&type=script&lang=js&"
import style0 from "./VinData.vue?vue&type=style&index=0&id=cbaa6994&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbaa6994",
  null
  
)

export default component.exports