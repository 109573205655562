import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
    return {
        addonsType: {
            status: null,
            data: [],
            error: null,
        },

        addonType: {
            status: null,
            data: {},
            error: null,
        },
    };
}
const actions = {
    [constants.GET_ADDONS_TYPE]: async ({ commit }, payload) => {
        try {
            commit(constants.GET_ADDONS_TYPE);
            const response = await axios.get("addon/getAddonType", {
                params: payload,
            });
            commit(constants.GET_ADDONS_TYPE_SUCCESS, response);
            return response;
        } catch (error) {
            commit(constants.GET_ADDONS_TYPE_ERROR, error);
            throw error.response;
        }
    },
    [constants.POST_ADDON_TYPE]: async ({ commit }, payload) => {
        try {
            commit(constants.POST_ADDON_TYPE);
            const response = await axios.post("addon/createAndUpdateAddonType", payload);
            commit(constants.POST_ADDON_TYPE_SUCCESS, response);
            return response;
        } catch (error) {
            commit(constants.POST_ADDON_TYPE_ERROR, error);
            throw error.response;
        }
    },

    [constants.GET_ADDONS_TYPE_LIST]: async ({ commit }, payload) => {
        try {
            commit(constants.GET_ADDONS_TYPE_LIST);

            const response = await axios.get("addon/listAddonType", {
                params: payload,
            });
            commit(constants.GET_ADDONS_TYPE_LIST_SUCCESS, response);
            return response;
        } catch (error) {
            commit(constants.GET_ADDONS_TYPE_LIST_ERROR, error);
            throw error.response;
        }
    },
};

const mutations = {
    [constants.GET_ADDONS_TYPE]: (state) => {
        state.addonsType.data = [];
        state.addonsType.status = "fetching";
    },
    [constants.GET_ADDONS_TYPE_SUCCESS]: (state, response) => {
        state.addonsType.status = "success";
        state.addonsType.data = response;
    },
    [constants.GET_ADDONS_TYPE_ERROR]: (state, error) => {
        state.addonsType.status = "error";
        state.addonsType.error = error;
    },
    [constants.POST_ADDON_TYPE]: (state) => {
        state.addonType.data = {};
        state.addonType.status = "fetching";
    },
    [constants.POST_ADDON_TYPE_SUCCESS]: (state, response) => {
        state.addonType.status = "success";
        state.addonType.data = response;
    },
    [constants.POST_ADDON_TYPE_ERROR]: (state, error) => {
        state.addonType.status = "error";
        state.addonType.error = error;
    },
    [constants.GET_ADDONS_TYPE_LIST]: (state) => {
        state.addonsType.data = [];
        state.addonsType.status = "fetching";
    },
    [constants.GET_ADDONS_TYPE_LIST_SUCCESS]: (state, response) => {
        state.addonsType.status = "success";
        state.addonsType.data = response;
    },
    [constants.GET_ADDONS_TYPE_LIST_ERROR]: (state, error) => {
        state.addonsType.status = "error";
        state.addonsType.error = error;
    },

};
export default {
    state: getDefaultState(),
    actions,
    mutations,
};
