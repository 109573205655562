<template>
  <div>
    <Multiselect
      v-model="model"
      :options="drivers.data || []"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="false"
      :preserve-search="true"
      placeholder="Pick some option"
      :custom-label="fullDriverName"
      :preselect-first="false"
      track-by="id"
      @input="onSelect"
    >
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { mapState } from "vuex";
export default {
  name: "DriverSelect",
  components: {
    Multiselect,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      filter: [],
      model: null,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value.length > 0 && typeof value[0] === "object") {
          this.model = value;
        } else {
          if (this.drivers?.data) {
            this.model = this.drivers?.data.filter((driver) =>
              value.includes(driver.id)
            );
          } else {
            this.model = [];
          }
        }
      },
    },
  },
  computed: {
    ...mapState({
      drivers: (state) => state.superAdminStore.driver.drivers.data,
    }),
  },
  methods: {
    onSelect() {
      const driversIds = this.model.map((driver) => driver.id);
      this.$emit("input", driversIds);
    },
    fullDriverName(driver) {
      return `${driver.firstName} ${driver.lastName}`;
    },
  },
};
</script>

<style></style>
