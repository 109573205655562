<template>
  <b-modal
    :id="'registration-notes-modal'"
    :ref="'registration-notes-modal'"
    modal-class="modal-fullscreen"
    centered
    no-close-on-backdrop
    scrollable
    title="Registration notes"
    size="lg"
    hide-footer
    @show="getRegistrationNotes()"
    @hidden="resetForm()"
  >
    <VehicleDetails
      v-if="vehicle"
      :vehicle="vehicle"
      :color="vehicle.vehicleInformation.color || '----'"
    />
    <NoteRegistrationForm
      v-if="userHasPermission('registration', 'write')"
      :vehicle="vehicle"
      :refreshData="getRegistrationNotes"
      :registration="registration"
    />
    <b-list-group v-if="!notes" class="mt-2">
      <b-list-group-item class="text-center">
        <b-spinner variant="yellow" />
      </b-list-group-item>
    </b-list-group>
    <b-list-group v-else class="mt-2 mb-2 note-list" tag="ul">
      <b-list-group-item v-if="notes && !notes.length" class="text-center">
        <b>No notes to show</b>
      </b-list-group-item>

      <b-list-group-item
        v-for="(note, i) in notes"
        :key="i"
        class="flex-column align-items-start"
        tag="li"
      >
        <NoteCard :note="note" :refreshData="getRegistrationNotes" />
      </b-list-group-item>
    </b-list-group>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import { GET_REGISTRATION_NOTES } from "./actions";
import { superAdminMixin } from "@/modules/superAdmin/mixins.js";
import NoteRegistrationForm from "./RegistrationForm";
import VehicleDetails from "@/modules/superAdmin/components/VehicleDetails.vue";
import NoteCard from "./NoteCard.vue";
export default {
  name: "RegistrationNotesModal",
  components: { NoteRegistrationForm, VehicleDetails, NoteCard },
  mixins: [superAdminMixin],
  props: ["registration"],
  computed: {
    ...mapState({
      vehicle: (state) => state.superAdminStore.vehicle,
      /*   notes: (state) => state.vehicle.registration.notes.notes, */
    }),
  },
  data() {
    return {
      notes: null,
    };
  },
  mounted() {},
  methods: {
    resetForm() {},
    changePage(page) {
      this.$store.commit(GET_REGISTRATION_NOTES, {
        fields: { page },
      });
      this.getNotes();
    },
    getRegistrationNotes() {
      this.$store
        .dispatch(GET_REGISTRATION_NOTES, {
          id: this.registration.id,
        })
        .then((response) => {
          this.notes = [];
          this.notes = response.registrationNote;
          this.$parent.getRegistrationNotes();
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.resize-none {
  resize: none;
}
.note-list {
  max-height: 500px;
  overflow-y: auto;
}
</style>
