<template>
  <div v-if="addonsType.length > 0" class="">
    <div class="h5"><i>Cost per addon type</i></div>
    <div
      v-for="(addonType, i) in addonsType"
      :key="i"
      class="d-flex justify-content-between"
    >
      <span>{{ addonType }}:</span>
      <span>{{ getTotalByAddonType(addonType) | currency }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    addons: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      addonsType: [],
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.auth.loginStore.profile.data,
    }),
    corporation() {
      const corporation = localStorage.getItem("corporation");
      return this.profile.user.corporationPermissions.find(
        (c) => c.id == corporation
      );
    },
  },
  methods: {
    getAddonsType() {
      this.addonsType = [];
      this.addons.map((addon) => {
        if (addon.addonType == null || addon.addonType.name == "No category") {
          if (!this.addonsType.includes("No category")) {
            this.addonsType.push("No category");
          }
        } else {
          if (!this.addonsType.includes(addon.addonType.name)) {
            this.addonsType.push(addon.addonType.name);
          }
        }
      });
    },
    getTotalByAddonType(addonType) {
      let total = 0;
      this.addons.map((addon) => {
        if (addon.addonType && addonType == addon.addonType.name) {
          total +=
            this.corporation.typeCurrency == "CAD"
              ? addon.totalAmountCAD
              : addon.totalAmountUSD;
        }
      });
      return total;
    },
  },
  mounted() {
    this.getAddonsType();
  },
};
</script>

<style></style>
