<template>
  <div>
    <b-form-group label="Quittance">
      <b-input-group class="mb-0">
        <b-form-file v-if="lien.status != 0" v-model="form.quittanceFile" />

        <b-button v-if="quittanceFile" variant="none" class="">
          <a :href="quittanceFile" target="_blank">
            <b-icon icon="eye" variant="green" />
          </a>
        </b-button>
        <b-col cols="1" class="pl-0">
          <b-form-checkbox
            id="checkbox-1"
            v-model="form.quittanceCheck"
            name="checkbox-1"
            :unchecked-value="0"
            :value="1"
            class="mt-2"
          >
          </b-form-checkbox>
        </b-col>
        <b-button v-if="lien.status != 0" class="ml-3">
          <b-icon icon="upload" variant="green" @click="uploadQuittance" />
        </b-button>
      </b-input-group>
      <small v-if="lien.quittanceDate" class="text-muted"
        >{{ lien.quittanceDate | formatDate }} -
        {{ lien.quittanceUser.firstName }}
        {{ lien.quittanceUser.lastName }}</small
      >
    </b-form-group>
  </div>
</template>
<script>
import { superAdminMixin } from "../../mixins";
import { POST_LIEN } from "./actions";

export default {
  mixins: [superAdminMixin],

  props: {
    lien: {
      type: Object,
      required: false,
    },

    quittanceFile: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      form: {
        quittanceFile: null,
        quittanceCheck: this.lien.quittanceCheck,
      },
    };
  },
  methods: {
    uploadQuittance() {
      const formData = new FormData();
      formData.append("id", this.lien.id);
      formData.append("quittanceFile", this.form.quittanceFile);
      formData.set("quittanceCheck", this.form.quittanceCheck);

      this.$store.dispatch(POST_LIEN, formData).then(() => {
        this.toastMessage("Quittance saved", "Success", "success");
      });
    },
  },
};
</script>
