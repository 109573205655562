<template>
  <div class="container-fluid">
    <div v-if="financialReferralPriceInfo.length > 0" class="">
      <b-tabs>
        <b-tab
          v-for="(financialInfo, key) in financialReferralPriceInfo"
          :key="key"
          @click="financialSelected = financialInfo"
        >
          <template #title>
            <strong
              >{{ financialInfo.trim }}
              <b-icon
                v-if="trimSelected == financialInfo.id"
                icon="check-circle"
                variant="success"
              ></b-icon>
            </strong>
          </template>
          <p class="mt-3">
            Trim: <strong>{{ financialInfo.trim }}</strong> <br />

            Style: <strong>{{ financialInfo.style }}</strong> <br />
            Kms:
            <strong> {{ financialInfo.kms }}</strong>
          </p>
          <b-table
            stacked="md"
            :items="financialInformationList"
            :fields="fields"
            head-row-variant="success"
          >
          </b-table>
          <b-button
            variant="yellow"
            size="sm"
            @click="getFinancialReferralPricePDF()"
            class="float-right mx-2"
          >
            <b-icon
              icon="file-earmark-arrow-down"
              class="m-1"
              variant="white"
            ></b-icon>
          </b-button>
        </b-tab>
      </b-tabs>
    </div>
    <div v-else class="text-center">
      <span class="h3">No information to show </span>
    </div>
  </div>
</template>

<script>
import { GET_FINANCIAL_REFERRAL_PRICE_PDF } from "../actions";
export default {
  name: "FinancialReferralPriceTable",
  props: ["financialReferralPriceInfo", "trimSelected"],
  computed: {
    financialInformationList() {
      const financialInformationForma = this.formatFinancialInformation();
      return financialInformationForma;
    },
  },
  data() {
    return {
      fields: [
        { key: "suffix", label: "", variant: "success" },
        {
          key: "extraClean",
          label: "Extra clean",
          class: "text-center",
          formatter: (extraClean) => this.$options.filters.currency(extraClean),
        },
        {
          key: "clean",
          label: "Clean",
          class: "text-center",
          formatter: (clean) => this.$options.filters.currency(clean),
        },
        {
          key: "average",
          label: "Average",
          class: "text-center",
          formatter: (average) => this.$options.filters.currency(average),
        },
        {
          key: "rough",
          label: "Rough",
          class: "text-center",
          formatter: (rough) => this.$options.filters.currency(rough),
        },
      ],
      financialSelected: this.financialReferralPriceInfo[0],
    };
  },
  methods: {
    formatFinancialInformation() {
      let data = [
        {
          suffix: "Base Wholesale",
          extraClean: this.financialSelected.baseWholeXclean,
          clean: this.financialSelected.baseWholeClean,
          average: this.financialSelected.baseWholeAvg,
          rough: this.financialSelected.baseWholeRough,
        },
        {
          suffix: "Add/Deduct (+/-)",
          extraClean: this.financialSelected.addDeductWholeXclean,
          clean: this.financialSelected.addDeductWholeClean,
          average: this.financialSelected.addDeductWholeAvg,
          rough: this.financialSelected.addDeductWholeRough,
        },
        {
          suffix: "Mileage adjust (-)",
          extraClean: this.financialSelected.mileageWholeXclean,
          clean: this.financialSelected.mileageWholeClean,
          average: this.financialSelected.mileageWholeAvg,
          rough: this.financialSelected.mileageWholeRough,
        },
        {
          suffix: "Region Adjust (+/-)",
          extraClean: this.financialSelected.regionalWholeXclean,
          clean: this.financialSelected.regionalWholeClean,
          average: this.financialSelected.regionalWholeAvg,
          rough: this.financialSelected.regionalWholeRough,
        },
        {
          suffix: "Total Wholesale",
          extraClean: this.financialSelected.wholeXclean,
          clean: this.financialSelected.wholeClean,
          average: this.financialSelected.wholeAvg,
          rough: this.financialSelected.wholeRough,
        },
      ];
      return data;
    },
    getFinancialReferralPricePDF() {
      this.$store
        .dispatch(GET_FINANCIAL_REFERRAL_PRICE_PDF, {
          id: this.financialSelected.id,
        })
        .then((response) => {
          const blob = new Blob([response], {
            type: "application/pdf",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = this.vinNumber + ".pdf" || "file.pdf";
          link.click();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Could not get file", {
            title: "Error",
            variant: "warning",
          });
        });
    },
  },
};
</script>

<style>
</style>