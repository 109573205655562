<template>
  <div>
    <b-form @submit.stop.prevent="form.id ? update() : save()">
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group label="Type">
            <b-form-radio-group
              v-model="form.type"
              @change="getDestinations()"
              :options="consignmentTypes"
            ></b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group label-size="sm">
            <template slot="label">
              Destination
              <b-form-radio-group
                v-model="form.destinationType"
                :options="destinationTypes"
              ></b-form-radio-group>
            </template>
            <vue-single-select
              v-if="form.destinationType == 'company'"
              :classes="{ input: 'form-control', dropdown: 'dropdown' }"
              v-model="form.destination"
              :options="destinations"
              optionLabel="companyName"
            ></vue-single-select>
            <vue-single-select
              v-else
              :classes="{ input: 'form-control', dropdown: 'dropdown' }"
              v-model="form.destination"
              :options="corporations"
              optionLabel="companyName"
            ></vue-single-select>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="text-right mt-2">
        <b-button variant="primary" type="submit">
          <b-spinner v-if="false" variant="white" small />
          <span v-else>Submit</span>
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { GET_CONSIGNMENT_DESTINATION_LIST, GET_CORPORATIONS_LIST } from "./actions";
import { mapState } from "vuex";
import VueSingleSelect from "vue-single-select";
import { generalMixin } from "@/modules/mixin.js";
export default {
  components: { VueSingleSelect },
  mixins: [generalMixin],
  props: {
    refreshData: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapState({
      vehicle: (state) => state.superAdminStore.vehicle,
    }),
  },
  data() {
    return {
      consignmentTypes: [
        { text: "Internal", value: "internal" },
        { text: "External", value: "external" },
      ],
      destinationTypes: [
        { text: "Company", value: "company" },
        { text: "Corporation", value: "corporation" },
      ],
      destinations: [],
      corporations: [],
      form: {
        id: null,
        type: "internal",
        destination: null,
        cost: 0,
        destinationType: "company",
      },
    };
  },
  mounted() {
    this.getDestinations();
    this.getCorporations();
  },
  methods: {
    formatPayload() {
      let data = {
        id: this.form.id ? this.form.id : null,
        vehicleId: this.vehicle.id,
        type: this.form.type,
        price: this.form.cost,
      };
      if (this.form.destinationType == "company") {
        data.companyId = this.form.destination.id;
        data.corporationId = null;
      } else {
        data.companyId = null;
        data.corporationId = this.form.destination.id;
      }
      return data;
    },
    resetForm() {
      this.form = {
        id: null,
        type: "internal",
        destinationType: "company",
        destination: null,
        cost: 0,
      };
    },
    save() {
      const payload = this.formatPayload();
      this.$store
        .dispatch("POST_CONSIGNMENT", payload)
        .then(() => {
          this.makeToast("Success", "Consignment created successfully", "success");
          this.refreshData();
        })
        .catch((error) => {
          this.$root.$bvToast.toast(
            error.data.message.charAt(0).toUpperCase() + error.data.message.slice(1),
            {
              title: "Warning",
              variant: "warning",
            }
          );
        });
    },
    update() {},
    getDestinations() {
      let search = this.form.type === "internal" ? "canam" : "";
      return this.$store
        .dispatch(GET_CONSIGNMENT_DESTINATION_LIST, { search: search })
        .then((response) => {
          this.destinations = response;
        });
    },
    getCorporations() {
      this.$store.dispatch(GET_CORPORATIONS_LIST).then((response) => {
        this.corporations = response;
      });
    },
  },
};
</script>

<style></style>
