<template>
  <b-form @submit.stop.prevent="saveCluster()">
    <b-row>
      <!-- show cluster and odometer image -->
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <Photoswipe>
          <img
            v-if="odometerImage"
            class="img-fluid"
            :src="odometerImage"
            alt="odometer"
            v-pswp="odometerImage"
          />
        </Photoswipe>
      </b-col>

      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <Photoswipe>
          <img
            v-if="clusterImage"
            class="img-fluid"
            :src="clusterImage"
            alt="cluster"
            v-pswp="clusterImage"
          />
        </Photoswipe>
      </b-col>

      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label-size="md">
          <template slot="label"> Cost </template>
          <Money
            v-model="form.cost"
            class="form-control text-center"
            v-bind="money"
          />
        </b-form-group>
      </b-col>

      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label="Provider">
          <vue-single-select
            :classes="{ input: 'form-control', dropdown: 'dropdown' }"
            v-model="form.supplier"
            :options="suppliers"
            optionLabel="companyName"
          ></vue-single-select>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6" lg="6" xl="6">
        <b-form-group label-size="md">
          <template slot="label">
            Kilometers <span class="text-danger">*</span>
          </template>
          <b-form-input
            v-model="form.kilometers"
            @change="
              setValue('kilometers', $event);
              changeKilometerToMiles();
            "
            size="md"
            min="0"
            required
          />
          <b-form-invalid-feedback id="input-firstName-live-feedback">
            This is a required field
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6" lg="6" xl="6">
        <b-form-group label-size="md">
          <template slot="label">
            Miles <span class="text-danger">*</span>
          </template>
          <b-form-input
            v-model.trim="form.miles"
            @change="
              setValue('miles', $event);
              changeMilesToKilometers();
            "
            size="md"
            min="0"
            required
          />
          <b-form-invalid-feedback id="input-firstName-live-feedback">
            This is a required field
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="text-right mt-2">
      <b-button
        :disabled="$v.form.$invalid || cluster.status === 'fetching'"
        variant="primary"
        type="submit"
      >
        <b-spinner v-if="cluster.status === 'fetching'" variant="white" small />
        <span v-else>Submit</span>
      </b-button>
    </div>
  </b-form>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import { superAdminMixin } from "../../mixins";
import { POST_CLUSTER, GET_CLUSTER_INFO } from "./actions";
import VueSingleSelect from "vue-single-select";

import { Money } from "v-money";

export default {
  components: {
    Money,
    VueSingleSelect,
  },
  mixins: [superAdminMixin],
  props: ["vehicle", "refreshData"],
  data() {
    return {
      suppliers: [],
      form: {
        description: null,
        vehicleId: null,
        category: null,
        supplier: null,
        kilometers: null,
        miles: null,
      },
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "CAD$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      odometerImage: null,
      clusterImage: null,
    };
  },
  computed: {
    ...mapState({
      cluster: (state) => state.superAdminStore.componentStore.cluster.cluster,
    }),
  },
  validations: {
    form: {
      kilometers: { required },
      miles: {},
      description: {},
    },
  },
  beforeMount() {
    this.getSuppliers();
    this.getClusterInfo();
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form.description = null;
      this.form.vehicleId = null;
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    getSuppliers() {
      this.$store
        .dispatch("GET_COMPANIES_LIST", { isSupplier: 1 })
        .then((response) => {
          this.suppliers = response;
          this.form.supplier = this.suppliers.find(
            (supplier) => supplier.companyName == "LES TECHNOLOGIES TRAXX INC."
          );
        })
        .catch(() => {
          this.$root.$bvToast.toast("Could not get the suppliers list ", {
            title: "Error",
            variant: "warning",
          });
        });
    },

    saveCluster() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_CLUSTER, payload)
        .then(() => {
          this.$root.$bvToast.toast("Cluster registered successfully", {
            title: "Success",
            variant: "success",
          });
          this.refreshData();
          this.resetForm();
        })
        .catch((error) => {
          this.$root.$bvToast.toast(
            error.data.messages.charAt(0).toUpperCase() +
              error.data.messages.slice(1),
            {
              title: "Error",
              variant: "warning",
            }
          );
        });
    },
    getClusterInfo() {
      this.$store
        .dispatch(GET_CLUSTER_INFO, { vehicleId: this.vehicle.id })
        .then((response) => {
          this.formatForm(response);
        });
    },
    formatForm(data) {
      const clusterInfo = data.vehicle;
      this.form.kilometers = clusterInfo.kms;
      this.form.miles = clusterInfo.miles;
      if (clusterInfo.vehicleHasParts.length > 0) {
        this.clusterImage =
          clusterInfo.vehicleHasParts.find((part) => part.partsId == 16)
            ?.photo || null;
        this.odometerImage =
          clusterInfo.vehicleHasParts.find((part) => part.partsId == 10)
            ?.photo || null;
      }
    },

    formatPayload() {
      const data = {
        vehicleId: this.vehicle.id,
        supplierCompanyId: this.form.supplier ? this.form.supplier.id : null,
        cost: this.form.cost,
        description: this.form.description,
        kms: this.form.kilometers,
        miles: this.form.miles,
      };
      return data;
    },
    changeKilometerToMiles() {
      this.form.miles = 0;
      this.form.miles = this.form.kilometers / 1.609;
      this.form.miles = this.form.miles.toFixed(0);
    },
    changeMilesToKilometers() {
      this.form.kilometers = 0;
      this.form.kilometers = this.form.miles * 1.609;
      this.form.kilometers = this.form.kilometers.toFixed(0);
    },
  },
};
</script>

<style></style>
