import vehicles from "./vehicles-table/store";


export default {
  state: {},
  actions: {},
  mutations: {},
  modules: {
    vehicles,

  },
};
