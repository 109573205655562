<template>
  <div>
    <h2>Agents commissions</h2>

    <b-row>
      <b-col sm="12" md="6" lg="3">
        <MarketplaceSelect
          :options="marketplaces"
          @onMarketplaceSelect="setMarketplace($event)"
          :modelValue="marketPlace"
        />
      </b-col>
    </b-row>
    <b-table
      class="mt-3"
      show-empty
      bordered
      small
      stacked="md"
      head-variant="light"
      responsive
      :items="agentsCommissions"
      :fields="fields"
      :busy="isAgentsLoading"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" variant="yellow" />
        </div>
      </template>
      <template #cell(pad)="{ item }">
        <div class="text-center">
          <Money
            class="form-control text-center"
            v-bind="money"
            @blur.native="
              updateAgentCommission(item, 'pad', $event.target.value)
            "
            :value="item.agentConfiguration ? item.agentConfiguration.pad : 0"
          />
        </div>
      </template>
      <template #cell(minimalProfit)="{ item }">
        <div class="text-center">
          <Money
            class="form-control text-center"
            v-bind="money"
            @blur.native="
              updateAgentCommission(item, 'minimalProfit', $event.target.value)
            "
            :value="
              item.agentConfiguration
                ? item.agentConfiguration.minimalProfit
                : 0
            "
          />
        </div>
      </template>
      <template #cell(loss)="{ item }">
        <div class="text-center">
          <b-input-group append="%">
            <b-form-input
              @change="updateAgentCommission(item, 'loss', $event)"
              :value="
                item.agentConfiguration ? item.agentConfiguration.loss : 0
              "
              type="number"
              min="0"
              max="100"
              step="0.1"
            />
          </b-input-group>
        </div>
      </template>
      <template #cell(profit)="{ item }">
        <div class="text-center">
          <b-input-group append="%">
            <b-form-input
              @change="updateAgentCommission(item, 'profit', $event)"
              :value="
                item.agentConfiguration ? item.agentConfiguration.profit : 0
              "
              type="number"
              min="0"
              max="100"
              step="0.1"
            />
          </b-input-group>
        </div>
      </template>
      <template #cell(investmentLimit)="{ item }">
        <div class="text-center">
          <Money
            class="form-control text-center"
            v-bind="money"
            @blur.native="
              updateAgentCommission(
                item,
                'investmentLimit',
                $event.target.value
              )
            "
            :value="
              item.agentConfiguration && item.agentConfiguration.investmentLimit
                ? item.agentConfiguration.investmentLimit
                : 0
            "
          />
        </div>
      </template>

      <template #cell(toleranceDays)="{ item }">
        <div class="text-center">
          <b-form-input
            @change="updateAgentCommission(item, 'toleranceDays', $event)"
            :value="
              item.agentConfiguration
                ? item.agentConfiguration.toleranceDays
                : 0
            "
            type="number"
            min="0"
            max="100"
          />
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { GET_AGENT_COMMISSION, PUT_AGENT_COMMISSION } from "./actions";
import { mapState } from "vuex";
import MarketplaceSelect from "../../vehicle/components/marketplace/MarketplaceSalect.vue";
import { Money } from "v-money";

export default {
  components: { MarketplaceSelect, Money },
  name: "AgentsCommissions",
  computed: {
    ...mapState({
      agentCommission: (state) =>
        state.superAdminStore.interest.agentCommission,
      agentsCommission: (state) =>
        state.superAdminStore.interest.agentsCommission,
      marketplaces: (state) =>
        state.masterUser.marketplace.marketplaceList.data || [],
    }),
    isAgentsLoading() {
      return this.agentsCommission.status === "fetching";
    },
  },
  data() {
    return {
      agentsCommissions: null,
      fields: [
        {
          key: "firstName",
          label: "First Name",
          class: "align-middle",
        },
        {
          key: "lastName",
          label: "Last Name",
          class: "align-middle",
        },

        {
          key: "pad",
          label: "Pad",
        },

        {
          key: "minimalProfit",
          label: "Minimal Profit",
        },
        {
          key: "loss",
          label: "Loss",
        },
        {
          key: "profit",
          label: "Profit",
        },
        {
          key: "investmentLimit",
          label: "Invest limit",
        },
        {
          key: "toleranceDays",
          label: "Tolerance Days",
        },
      ],
      money: {
        value: 0,
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        suffix: " CAD",
        precision: 2,
      },
      moneyByCurrency: {
        value: 0,
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        suffix: " USD",
        precision: 2,
      },
      marketPlace: null,
    };
  },
  beforeMount() {
    this.getCompanies();
    this.getMarketPlaces();
    this.$store.commit("setSearchFunction", this.getAgentsCommissions);
  },
  created() {
    this.$store.commit("setAddFunction", null);
  },
  methods: {
    getCompanies() {
      this.$store
        .dispatch("GET_CLIENTS")
        .then(() => {
          this.getAgentsCommissions();
        })
        .catch(() => {
          this.$root.$bvToast.toast("We can't get company list", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    getAgentsCommissions(search = "", marketPlace = 2) {
      this.$store
        .dispatch(GET_AGENT_COMMISSION, {
          search: search,
          marketPlace: marketPlace,
        })
        .then((response) => {
          this.agentsCommissions = response.data;
        });
    },
    updateAgentCommission(item, type, value) {
      const payload = { ...item };
      const agentConfiguration = {
        id: item.agentConfiguration?.id || null,
        [type]: this.getValueFromString(value),
      };
      payload.agentConfiguration = { ...agentConfiguration };
      payload.marketplaceId = this.marketPlace.id;
      this.$store.dispatch(PUT_AGENT_COMMISSION, payload).then(() => {
        this.getAgentsCommissions("", this.marketPlace.id);
        this.$bvToast.toast("Marketplace commission updated", {
          title: "Success",
          variant: "success",
          solid: true,
        });
      });
    },
    getValueFromString(string) {
      return Number(string.replace(/[^0-9.-]+/g, ""));
    },
    getMarketPlaces() {
      this.$store.dispatch("GET_MARKETPLACE_LIST").then((response) => {
        this.marketPlace = response.find((marketplace) => {
          return marketplace.name == "QAS";
        });
      });
    },
    setMarketplace(marketplace) {
      this.marketPlace = marketplace;
      this.getAgentsCommissions(null, marketplace.id);
      this.moneyByCurrency.suffix = ` ${marketplace.currency}`;
    },
  },
};
</script>

<style></style>
