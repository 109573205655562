export const GET_VEHICLE_PARTS = 'GET_VEHICLE_PARTS';
export const GET_VEHICLE_PARTS_SUCCESS = 'GET_VEHICLE_PARTS_SUCCESS';
export const GET_VEHICLE_PARTS_ERROR = 'GET_VEHICLE_PARTS_ERROR';

export const POST_UPLOAD_PHOTO = 'POST_UPLOAD_PHOTO';
export const POST_UPLOAD_PHOTO_SUCCESS = 'POST_UPLOAD_PHOTO_SUCCESS';
export const POST_UPLOAD_PHOTO_ERROR = 'POST_UPLOAD_PHOTO_ERROR';

export const DELETE_PHOTO = 'DELETE_PHOTO';
export const DELETE_PHOTO_SUCCESS = 'DELETE_PHOTO_SUCCESS';
export const DELETE_PHOTO_ERROR = 'DELETE_PHOTO_ERROR';

export const DELETE_EXTERNAL_PHOTO = 'DELETE_EXTERNAL_PHOTO';
export const DELETE_EXTERNAL_PHOTO_SUCCESS = 'DELETE_EXTERNAL_PHOTO_SUCCESS';
export const DELETE_EXTERNAL_PHOTO_ERROR = 'DELETE_EXTERNAL_PHOTO_ERROR';
