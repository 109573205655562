
import * as constant from "./actions"
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    simpleView: {
      status: null,
      data: null,
      error: null,
    },
  };
}


const actions = {
  [constant.GET_SIMPLE_VIEW_VEHICLE]: async ({ commit }, payload) => {
    try {
      commit(constant.GET_SIMPLE_VIEW_VEHICLE);
      const response = await axios.get("timeLine/getSimpleView", {
        params: payload,
      });
      commit(constant.GET_SIMPLE_VIEW_VEHICLE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constant.GET_SIMPLE_VIEW_VEHICLE_ERROR, error);
      throw error.response;
    }
  }
}
const mutations = {
  [constant.GET_SIMPLE_VIEW_VEHICLE](state) {
    state.simpleView = "loading";
  },
  [constant.GET_SIMPLE_VIEW_VEHICLE_SUCCESS](state, response) {
    state.simpleView = "success";
    state.simpleView = response;
  },
  [constant.GET_SIMPLE_VIEW_VEHICLE_ERROR](state, error) {
    state.simpleView = "error";
    state.simpleView = error;
  }

};




export default {
  state: getDefaultState(),
  actions,
  mutations,


};