<template>
  <div
    v-touch:touchhold="touchHoldHandler"
    @contextmenu.prevent="
      vehicle.vehicleFlags.transfer == 1 || vehicle.vehicleFlags.transfer == 4
        ? $refs.menuTransfer.open($event, 'Payload')
        : ''
    "
  >
    <slot></slot>
    <ContextMenu
      v-if="
        vehicle.vehicleFlags.transfer == 1 || vehicle.vehicleFlags.transfer == 4
      "
      ref="menuTransfer"
    >
      <template slot-scope>
        <ContextMenuItem @click.native="markTransferAsComplete()">
          Mark as complete
        </ContextMenuItem>
      </template>
    </ContextMenu>
    <TaskUpdateStatusModalVue :tasks="tasks" :id="vehicle.id" />
  </div>
</template>

<script>
import { superAdminMixin } from "../../mixins";
import ContextMenu from "../contextMenu/ContextMenu.vue";
import ContextMenuItem from "../contextMenu/ContextMenuItem.vue";
import TaskUpdateStatusModalVue from "../../tasks/components/TaskUpdateStatusModal.vue";
import { PUT_TRANSFER } from "./actions";
export default {
  name: "TransferContextMenu",
  props: ["vehicle", "refreshData"],
  mixins: [superAdminMixin],
  components: {
    ContextMenu,
    ContextMenuItem,
    TaskUpdateStatusModalVue,
  },
  data() {
    return {
      tasks: [],
    };
  },

  methods: {
    markTransferAsComplete() {
      const payload = {
        status: 2,
        vehicleId: this.vehicle.id,
      };
      if (this.vehicle.vehicleFlags.transfer == 4) {
        payload.status = 4;
      }
      this.$store
        .dispatch(PUT_TRANSFER, payload)
        .then((response) => {
          if (response.task.length > 0) {
            /* show TaskUpdateStatusModal */
            this.tasks = response.task;
            this.$bvModal.show("task-update-status-modal" + this.vehicle.id);
          }
          this.toastMessage(
            "Transfer mark as completed successfully",
            "Success",
            "success"
          );
          this.$refs.menuTransfer.close();
          this.updateVehicleFlag(this.vehicle.id, "transfer", 2);

          if (this.vehicle.vehicleFlags.transfer == 4) {
            this.updateVehicleFlag(this.vehicle.id, "transfer", 4);
          }
        })
        .catch(() => {
          this.toastMessage(
            "Transfer not mark as completed",
            "Warning",
            "warning"
          );
        });
    },
    touchHoldHandler($event) {
      this.$refs.menuTransfer.open($event, "Payload");
    },
  },
};
</script>

<style>
</style>