<template>
  <b-container class="mt-3">
    <div class="d-flex justify-content-between align-middle">
      <div class="h2 align-bottom">
        Total: {{ accountsStatement.total | currency }}
      </div>
      <b-button
        variant="yellow"
        @click="clearFilters()"
        class="text-white mx-1"
      >
        Clear filters
      </b-button>
    </div>

    <b-table
      class="mt-3"
      show-empty
      bordered
      small
      stacked="md"
      head-variant="light"
      :items="accountsStatement.data.data"
      :fields="fields"
      :busy="isAccountsLoading"
    >
      <template #head()="scope">
        <div class="table-head-menu text-center">
          <span class=""> {{ scope.label }} </span>
          <br />
          <div class="text-center">
            <AccountStatementFilter
              ref="filter-component"
              :type="scope.field.key"
              v-if="scope.field.hasFilter"
              :refreshData="getAccountsStatus"
            />
            <div v-else>
              <span><br /></span>
            </div>
          </div>
        </div>
      </template>

      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" variant="yellow" />
        </div>
      </template>
      <template v-slot:cell(agent)="{ item }">
        <span>{{ item.firstName }} {{ item.lastName }}</span>
      </template>
    </b-table>
    <div>
      <b-pagination
        v-if="accountsStatement.data"
        :value="accountsStatement.data ? accountsStatement.data.currentPage : 1"
        :total-rows="accountsStatement.data ? accountsStatement.data.total : 0"
        :per-page="accountsStatement.data ? accountsStatement.data.perPage : 25"
        class="mb-2 mt-3"
        align="end"
        @change="changePage($event)"
      />
    </div>

    <b-modal
      id="modal-account-status-form"
      ref="modal-account-status-form"
      size="lg"
      hide-footer
      body-class=""
      ok-disabled
    >
      <AccountStatusForm
        :refreshData="getAccountsStatus"
        :hideModal="hideModal"
      />
    </b-modal>
  </b-container>
</template>

<script>
import { mapState } from "vuex";
import {
  GET_ACCOUNT_STATEMENT,
  SET_ACCOUNT_STATEMENT_FILTERS,
} from "./actions";
import AccountStatusForm from "./AccountStatementForm.vue";
import AccountStatementFilter from "./filters/AccountStatementFilter.vue";
export default {
  name: "AccountStatementTable",
  components: { AccountStatusForm, AccountStatementFilter },
  computed: {
    ...mapState({
      accountsStatement: (state) =>
        state.superAdminStore.accountStatement.accountsStatement,
    }),
    isAccountsLoading() {
      return this.accountsStatement.status == "fetching";
    },
  },
  data() {
    return {
      search: "",
      fields: [
        {
          key: "agent",
          label: "Agent",
          class: "text-center",
          hasFilter:
            this.$route.name == "superadmin.account-statement" ? true : false,
        },
        /*    {
          key: "amount",
          label: "Amount",
          class: "text-right",

          formatter: (amount) => this.$options.filters.currency(amount),
        }, */
        { key: "concept", label: "Concept" },

        {
          key: "date",
          label: "Date",
          class: "text-center",
          hasFilter: true,
        },
        {
          key: "reference",
          class: "text-center",

          label: "Reference",
        },
        {
          key: "description",
          class: "text-center",

          label: "Description",
        },
        {
          key: "credit",
          label: "Credit",
          class: "text-right",

          formatter: (amount) => this.$options.filters.currency(amount),
        },
        {
          key: "debit",
          label: "Debit",
          class: "text-right",
          formatter: (amount) => this.$options.filters.currency(amount),
        },
      ],
    };
  },
  created() {
    this.$store.commit("setAddFunction", this.openModal);
    this.$store.commit("setSearchFunction", this.searchData);
    this.getAccountsStatus();
  },
  methods: {
    getAccountsStatus() {
      this.$store.dispatch(GET_ACCOUNT_STATEMENT).then(() => {});
    },
    searchData(search) {
      this.$store.commit(SET_ACCOUNT_STATEMENT_FILTERS, {
        fields: {
          search: search,
        },
      });
      this.getAccountsStatus();
    },
    clearFilters() {
      this.$store.commit(SET_ACCOUNT_STATEMENT_FILTERS, {
        reset: true,
      });
      this.getAccountsStatus();
    },
    changePage($event) {
      this.$store.commit(SET_ACCOUNT_STATEMENT_FILTERS, {
        fields: {
          page: $event,
        },
      });
      this.getAccountsStatus();
    },
    openModal() {
      this.$bvModal.show("modal-account-status-form");
    },
    hideModal() {
      this.$bvModal.hide("modal-account-status-form");
    },
  },
};
</script>

<style></style>
