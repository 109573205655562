<template>
  <div>
    <b-navbar variant="yellow">
      <b-navbar-brand>
        <span class="text-yellow"> a</span>
        <b-button
          variant="yellow"
          class="p-0"
          @click="goBack()"
        >
          <b-icon
            icon="arrow-left-circle"
            variant="white"
            font-scale="1.7"
          />
        </b-button>
      </b-navbar-brand>
      <b-nav
        v-if="witdhResponsive !== '100%'"
        class="w-100 d-lg-inline-flex"
        align="end"
      />
    </b-navbar>
    <b-container class="mt-5 mb-5">
      <b-row class="mx-0 px-0">
        <b-col
          v-if="vehicle"
          sm="12"
          md="6"
          lg="6"
          xl="6"
        >
          <car-gallery
            :exterior="exterior"
            :interior="interior"
            :damages="damages"
            :damages-dots="damagesDots"
            :vehicle-model="vehicle.vinNumber.model"
            size="width: auto;  height: 6rem!important;"
            class-img="img-thumbnail"
            @onSelectImage="selectImage"
            @onChangeTab="changeTab"
          >
            <template #top>
              <b-row class="mb-3 pl-3 pr-3">
                <b-col class="text-center m-0 p-0">
                  <Photoswipe rotate>
                    <img
                      v-pswp="
                        imgNotFound || imgError
                          ? imgError
                          : tab === 0
                            ? exterior[index]
                            : interior[index]
                      "
                      style="height: 22rem !important"
                      class="carousel-item active main-img img-thumbnail"
                      :src="
                        imgNotFound || imgError
                          ? imgError
                          : tab === 0
                            ? exterior[index]
                            : interior[index]
                      "
                      alt="car"
                      @error="errorLoadImg"
                    >
                  </Photoswipe>
                  <a
                    v-if="index > 0"
                    class="carousel-control-prev"
                    role="button"
                    data-slide="prev"
                    @click="backImg"
                  >
                    <b-icon
                      scale="2"
                      icon="arrow-left-circle-fill"
                    />
                    <span class="sr-only">Previous</span>
                  </a>
                  <a
                    v-if="index < exterior.length - 1"
                    class="carousel-control-next"
                    role="button"
                    data-slide="next"
                    @click="nextImg"
                  >
                    <b-icon
                      scale="2"
                      icon="arrow-right-circle-fill"
                    />
                    <span class="sr-only">Next</span>
                  </a>
                </b-col>
              </b-row>
            </template>
          </car-gallery>

          <b-row class="mt-3">
            <b-col>
              <b-button
                variant="success"
                block
                @click="openCRPageModal(vehicle.id)"
              >
                <b-row>
                  <b-col>
                    <img
                      src="@/assets/img/609_manheim_identity_ca_3000px.png.png"
                      alt="Fluid image"
                      class="img-fluid cr-button-img"
                    >
                  </b-col>
                  <b-col class="m-auto">
                    CR and Full Specs
                  </b-col>
                </b-row>
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-if="vehicle"
          sm="12"
          md="6"
          lg="6"
          xl="6"
          class="text-break mt-2"
        >
          <h1>
            {{ vehicle.vinNumber.make }} {{ vehicle.vinNumber.model }}
            {{ vehicle.vinNumber.trim }}
            {{ vehicle.vinNumber.options }}
          </h1>
          <h2>
            US-landed Canadian vehicle
            <span
              v-if="readyToSell"
            >release date: {{ vehicle.releaseDate }}
            </span>
            <span v-else> ready to sell</span>
          </h2>
          <b-row>
            <b-col>
              <b-row>
                <b-col>
                  <div class="mb-2">
                    Make:
                    <i>{{ vehicle.vinNumber.make }}</i>
                  </div>
                </b-col>
                <b-col>
                  <div class="ml-2 mb-2">
                    Year:
                    <i>{{ vehicle.vinNumber.year }}</i>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="mb-2">
                Model:
                <i>
                  {{ vehicle.vinNumber.model }} {{ vehicle.vinNumber.trim }}
                  {{ vehicle.vinNumber.options }}
                </i>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Mileage:
              <i>{{ vehicle.vehicleInformation.miles }}</i>
            </b-col>
          </b-row>
          <b-row v-if="vehicle.releaseDate">
            <b-col class="mb-2 mt-2">
              Release Date:
              <i>{{ vehicle.releaseDate | formatDate }} </i>
            </b-col>
          </b-row>
          <b-row class="mt-1 mb-2">
            <b-col
              v-if="vehicle.vinNumber.style"
              class="ml-1"
            >
              Style:
              <i>{{ vehicle.vinNumber.style }}</i>
            </b-col>
            <b-col
              v-if="vehicle.vinNumber.doors"
              class="ml-1"
            >
              Doors:
              <i>{{ vehicle.vinNumber.doors }}</i>
            </b-col>
          </b-row>
          <b-row
            v-if="vehicle.vehicleInformation.color"
            class="mt-2 mb-2"
          >
            <b-col class="ml-1">
              Color:
              <i>{{ vehicle.vehicleInformation.color }}</i>
            </b-col>
          </b-row>
          <b-row
            v-if="vehicle.vinNumber.vehicleType"
            class="mt-2 mb-2"
          >
            <b-col class="ml-1">
              Vehicle Type:
              <i>
                {{ vehicle.vinNumber.vehicleType }}
              </i>
            </b-col>
          </b-row>
          <b-row
            v-if="vehicle.vinNumber.driveType"
            class="mt-2 mb-2"
          >
            <b-col class="ml-1">
              Drive Type:
              <i>
                {{ vehicle.vinNumber.driveType }}
              </i>
            </b-col>
          </b-row>
          <b-row
            v-if="vehicle.vinNumber.driveType"
            class="mt-2 mb-2"
          >
            <b-col class="ml-1">
              Engine:
              <i>
                {{ vehicle.vinNumber.driveType }}
              </i>
            </b-col>
          </b-row>

          <!--   <b-row
          v-if="
            !isHome &&
            vehicle.vehicleCostDetail.cost &&
            user &&
            (userIsAdmin || userIsAgent)
          "
        >
          <b-col class="mb-2">
            Status:
            <strong>
              {{
                vehicle.status === "ReadyToSell"
                  ? "Ready To Sell"
                  : vehicle.status
              }}
            </strong>
          </b-col>
        </b-row> -->
          <b-row class="mb-1">
            <b-col>
              <img
                :id="'tooltip-google-maps-' + vehicle.id"
                src="@/assets/img/mapaIcon.png"
                class="img-responsive"
                style="max-width: 20px"
              >
              Location
              <i>{{ vehicle.location }}</i>
              <b-tooltip
                :target="'tooltip-google-maps-' + vehicle.id"
                triggers="hover"
              >
                <h5 class="mt-2">
                  {{ vehicle.location }}
                </h5>
                <br>
                <h5>Address</h5>
                44951 County Rd 191, Wellesley Island, NY 13640, EE. UU.
                <br>
                <br>
                <a
                  href="https://goo.gl/maps/uKtbs1u3K6fmPLZG7"
                  target="_blank"
                  class="mt-2"
                  style="color: white; text-decoration: none"
                >
                  View on Google Maps
                </a>
              </b-tooltip>
            </b-col>
          </b-row>

          <b-row
            v-if="vehicle.vinNumber && vehicle.vinNumber.vinNumber"
            class="mt-2"
          >
            <b-col class="text-center">
              <vue-barcode
                style="width: 100%"
                :value="vehicle.vinNumber.vinNumber"
                :options="barcodeOptions"
                class="bar-code"
              />
              <i style="font-size: 13px">{{ vehicle.vinNumber.vinNumber }}</i>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-if="
                !companyIsPending &&
                  vehicle.vehicleCostDetail &&
                  vehicle.vehicleCostDetail.price &&
                  user != null
              "
              class="mt-2 text-center"
            >
              <b-row>
                <b-col>
                  <b>Selling Price:</b>
                  <h1>
                    <i>
                      <strong>
                        {{ this.vehicle.vehicleCostDetail.price | currency }}
                      </strong>
                    </i>
                  </h1>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <div
            v-if="vehicleAcceptOffer || vehicle.status === 'Sold'"
            class="mt-2"
          >
            <img
              src="@/assets/img/TOOLATE.png"
              alt="sold-image"
              class="img-fluid"
            >
          </div>
          <div v-else>
            <b-row
              v-if="viewOffers"
              class="mt-3"
            >
              <b-col class="mb-1">
                <b-button
                  :disabled="companyIsPending"
                  variant="yellow"
                  block
                  class="shadow"
                  @click="showVehicleOffers()"
                >
                  View Offers
                </b-button>
              </b-col>
            </b-row>

            <b-row
              v-else-if="!isHome && user"
              class="mt-2"
            >
              <b-col>
                <b-button
                  v-if="
                    vehicle.status !== 'Inactive' &&
                      (userIsAdmin ||
                        (userIsAgent && vehicle.usersId === user.id))
                  "
                  variant="yellow"
                  block
                  class="mt-1"
                  @click="editVehicle(vehicle.vinNumber.vinNumber)"
                >
                  <b-icon icon="pencil" />
                </b-button>
              </b-col>
            </b-row>

            <b-row
              v-if="
                !isHome &&
                  !isActiveOffer &&
                  !isExpiredOffer &&
                  (userHasOffer || vehicle.offerClient)
              "
              class="mt-3"
            >
              <b-col>
                <b-button
                  variant="yellow"
                  block
                  size="sm"
                  @click="changeOfferTab(vehicle.offerClient.mainOffer)"
                >
                  <h5 class="mt-1">
                    Current Offer:
                    <span v-if="vehicle.offerClient">
                      {{ vehicle.offerClient.offerPrice | currency }}
                    </span>
                    <span v-else-if="userHasOffer">
                      {{ currentOffer | currency }}
                    </span>
                  </h5>
                </b-button>
              </b-col>
            </b-row>
            <MakeAnOffer
              v-if="
                !isHome &&
                  !userHasOffer &&
                  !vehicle.offerClient &&
                  !isExpiredOffer &&
                  !isActiveOffer &&
                  (userIsBuyer || userIsClient)
              "
              :vehicle-id="this.vehicle.id"
              :price="this.vehicle.vehicleCostDetail.price"
              :user-pending="companyIsPending"
              @setUserHasOffer="setUserHasOffer($event)"
            />
          </div>
        </b-col>
      </b-row>
      <CRPageModal
        v-if="vehicle"
        :id="this.vehicle.id"
        :cr-url="this.vehicle.crUrl"
      />
    </b-container>
    <b-navbar
      class="mt-3 py-3 block"
      toggleable="lg"
      variant="yellow"
    >
      <b-nav
        class="w-100 d-lg-inline-flex"
        align="end"
      />
    </b-navbar>
  </div>
</template>

<script>
import { GET_VEHICLE, SET_ONE_VEHICLE_FILTERS } from "./actions";
import { SET_VEHICLES_FILTERS } from "@/modules/admin/vehicles/list/actions";

import CarGallery from "@/modules/admin/vehicles/list/CarGallery";
import CRPageModal from "@/modules/admin/vehicles/list/components/CRPageModal";
import MakeAnOffer from "@/modules/admin/vehicles/list/components/MakeAnOffer";
import { generalMixin } from "@/modules/mixin";
import { mapState } from "vuex";

export default {
  components: { CarGallery, CRPageModal, MakeAnOffer },
  mixins: [generalMixin],

  data() {
    return {
      damages: [],
      interior: [],
      exterior: [],
      index: 0,
      damagesDots: [],
      imgError: "",
      imgNotFound: false,
      intervalPhotos: null,
      isGalleryGoingBack: false,
      tab: 0,
      barcodeOptions: {
        format: "CODE39",
        with: 10,
        height: 60,
        displayValue: false,
        marginTop: 10,
      },
      userHasOffer: false,
      currentOffer: null,
    };
  },
  computed: {
    ...mapState({
      vehicle: (state) => state.vehicle.vehicle.data,
      vehicleStatus: (state) => state.vehicle.vehicle.status,
      profile: (state) => state.auth.loginStore.profile.data,
    }),
    isHome() {
      return this.$route.name === "home" ? true : false;
    },
    readyToSell() {
      let today = new Date().toLocaleDateString();
      let release = new Date(this.vehicle.releaseDate);

      return release > today;
    },
    vehicleAcceptOffer() {
      return this.$store.state.adminStore.vehicles.offersStore.vehiclesSold.vehicles.find(
        (vehicle) => vehicle.vehicleId === this.vehicle.id
      );
    },
  },
  beforeMount() {
    this.getVehicle();
  },
  mounted() {},

  methods: {
    selectImage(val) {
      this.imgError = "";
      this.imgNotFound = false;
      this.index = val.index;
      this.tab = val.tab;
      this.imgNotFound = val.imgNotFound;
    },
    changeTab(tab) {
      this.tab = tab;
      this.index = 0;
    },
    setDefaultPhotosState() {
      this.exterior = [];
      this.interior = [];
      this.damagePhotos = [];
      this.damages = [];
    },
    setImages() {
      this.setDefaultPhotosState();
      this.vehicle?.vehicleHasParts?.forEach((p) => {
        this.exterior.push(p.photo);
      });
      if (this.vehicle?.vehicleExternalImages?.length > 0) {
        this.vehicle?.vehicleExternalImages?.forEach((p) => {
          this.exterior.push(p.photo);
        });
      }
      if (this.vehicle?.damagePhotos?.length > 0) {
        this.damagesDots = this.vehicle.damagePhotos;
      }
      this.vehicle?.damagePhotos?.forEach((p) => {
        this.damages.push(p.damagePhoto);
      });
    },
    nextImg() {
      this.index++;
      this.imgNotFound = false;
      this.imgError = "";
    },
    backImg() {
      this.index--;
      this.imgNotFound = false;
      this.imgError = "";
    },
    errorLoadImg() {
      this.imgError = require("@/assets/img/image_not_found.png");
    },
    initIntervalPhotos() {
      this.intervalPhotos = setInterval(this.autoChangePhotos, 4000);
    },
    autoChangePhotos() {
      if (this.isGalleryGoingBack) {
        if (this.index === 0) {
          this.nextImg();
          this.isGalleryGoingBack = false;
        } else {
          this.backImg();
        }
      } else {
        if (this.index === this.exterior.length - 1) {
          this.backImg();
          this.isGalleryGoingBack = true;
        } else {
          this.nextImg();
        }
      }
    },
    getVehicle() {
      let vimNumber = this.$route.params.vin.split("-");
      this.$store.commit(SET_ONE_VEHICLE_FILTERS, {
        reset: true,
        ...{ fields: { vinNumber: vimNumber[0] } },
      });

      this.$store
        .dispatch(GET_VEHICLE)
        .then(() => {
          this.setImages();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Could not get the vehicle ", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    changeOfferTab(mainOfferId) {
      this.$parent.$emit("resetFilters");
      this.$store.commit(SET_VEHICLES_FILTERS, { fields: { mainOfferId } });
      this.$parent.$emit("changeTabNumber");
    },

    openCRPageModal(id) {
      this.$bvModal.show("CR-Page-Modal" + id);
    },
    setUserHasOffer(offer) {
      this.userHasOffer = true;
      this.currentOffer = offer;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
i {
  font-weight: bold !important;
}
.main-img {
  width: 100%;
  height: 25rem;
  object-fit: cover;
}
</style>
