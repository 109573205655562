export const GET_ACCOUNTING_COMPANIES_LIST = "GET_ACCOUNTING_COMPANIES_LIST";
export const GET_ACCOUNTING_COMPANIES_LIST_SUCCESS = "GET_ACCOUNTING_COMPANIES_LIST_SUCCESS";
export const GET_ACCOUNTING_COMPANIES_LIST_ERROR = "GET_ACCOUNTING_COMPANIES_LIST_ERROR";

export const GET_ACCOUNTING_VEHICLES_LIST = "GET_ACCOUNTING_VEHICLES_LIST";
export const GET_ACCOUNTING_VEHICLES_LIST_SUCCESS = "GET_ACCOUNTING_VEHICLES_LIST_SUCCESS";
export const GET_ACCOUNTING_VEHICLES_LIST_ERROR = "GET_ACCOUNTING_VEHICLES_LIST_ERROR";

export const GET_ACCOUNTING_VEHICLES_EXCEL = "GET_ACCOUNTING_VEHICLES_EXCEL";
export const GET_ACCOUNTING_VEHICLES_EXCEL_SUCCESS = "GET_ACCOUNTING_VEHICLES_EXCEL_SUCCESS";
export const GET_ACCOUNTING_VEHICLES_EXCEL_ERROR = "GET_ACCOUNTING_VEHICLES_EXCEL_ERROR";