<template>
  <b-card class="mb-0">
    {{ vehicle.stockNumber }} - {{ vehicle.vinNumber }} <br />
    {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
    {{ vehicle.trim }} <br />
    {{ vehicle.color }}
  </b-card>
</template>

<script>
export default {
  props: ["vehicle"],
  name: "VehicleCard",
};
</script>

<style></style>
