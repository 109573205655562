<template>
  <div class="container">
    <b-form class="m-4" @submit.stop.prevent="form.id ? update() : save()">
      <b-row>
        <!-- name -->
        <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
          <b-form-group label-size="sm">
            <template slot="label">
              Name <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="name-input"
              v-model.trim="$v.form.name.$model"
              size="sm"
              name="name-input"
              :value="form.name"
              :state="validateState('name')"
              aria-describedby="input-name-live-feedback"
              maxlength="100"
              @change="setValue('name', $event)"
            />
            <b-form-invalid-feedback id="input-name-live-feedback">
              This is a required field and must at least 2 characters.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row />

      <b-row>
        <b-col sm="12" md="6" lg="6" xl="6" />
        <b-col sm="12" md="3" lg="3" xl="3" />
        <b-col sm="12" md="3" lg="3" xl="3">
          <b-btn
            v-if="userHasPermission('fees', 'write')"
            block
            size="lg"
            class="btn btn-success"
            :disabled="$v.form.$invalid"
            type="submit"
          >
            Submit
            <b-spinner v-if="tax.status == 'fetching'" small variant="white" />
          </b-btn>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import { POST_EXPORT_PORT, PUT_EXPORT_PORT } from "./actions";
import { mapState } from "vuex";
import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../mixins";

export default {
  mixins: [generalMixin, superAdminMixin],
  props: ["exportPortItem", "refreshData", "hideModal"],
  computed: {
    ...mapState({
      tax: (state) => state.superAdminStore.fees.fee,
    }),
  },

  data() {
    return {
      form: {
        id: null,
        name: null,
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(2),
      },
    },
  },
  mounted() {
    if (this.exportPortItem.id) {
      this.formatForm();
    }
  },
  methods: {
    closeModal(callback) {
      callback();
    },
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    save() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_EXPORT_PORT, payload)
        .then(() => {
          this.makeToast(
            "Success",
            "Export port created successfully",
            "success"
          );
          this.refreshData();
          this.hideModal();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Internal server error, try again later", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    update() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(PUT_EXPORT_PORT, payload)
        .then(() => {
          this.makeToast("Success", "Fee updated successfully", "success");
          this.refreshData();
          this.hideModal();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Internal server error, try again later", {
            title: "Warning",
            variant: "warning",
          });
        });
    },

    formatPayload() {
      const payload = {
        id: this.exportPortItem.id ? this.exportPortItem.id : null,
        name: this.form.name,
        status: this.exportPortItem.status,
      };

      return payload;
    },

    formatForm() {
      this.form.id = this.exportPortItem.id;
      this.form.name = this.exportPortItem.name;
      this.form.status = this.exportPortItem.status;
    },
  },
};
</script>

<style scoped>
.append-input {
  max-width: 40%;
}
</style>
