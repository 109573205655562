<template>
  <div class="container">
    <b-row>
      <b-col cols="12" class="text-center">
        <h4>Where is my VN?</h4>
      </b-col>
      <b-col cols="12">
        <p class="text-justify">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores quasi
          blanditiis perspiciatis voluptates labore odio quos explicabo, ipsam
          autem alias provident hic aliquam distinctio eos earum impedit, amet
          architecto incidunt.
        </p>
      </b-col>
    </b-row>
    <div class="text-center">
      <img src="@/assets/img/where_is_my_vn_vehicle.png" class="mx-auto" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>