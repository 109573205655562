<template>
  <b-modal
    id="registration-modal"
    ref="modal"
    centered
    no-close-on-backdrop
    scrollable
    size="xl"
    title="Registration"
    hide-footer
    @hidden="getVehiclesInfo()"
  >
    <VehicleDetails
      v-if="vehicle"
      :vehicle="vehicle"
      :color="vehicle.vehicleInformation.color"
    />
    <RegistrationForm
      :vehicle="vehicle"
      :refreshData="getVehiclesInfo"
      :hideModal="hideModal"
      :registrationItem="vehicle"
    />
  </b-modal>
</template>

<script>
import RegistrationForm from "@/modules/superAdmin/registration/UploadFile.vue";

import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "@/modules/superAdmin/mixins.js";
import { mapState } from "vuex";
import VehicleDetails from "@/modules/superAdmin/components/VehicleDetails.vue";

export default {
  components: { RegistrationForm, VehicleDetails },
  mixins: [generalMixin, superAdminMixin],
  computed: {
    ...mapState({
      liens: (state) => state.superAdminStore.componentStore.liens.liens,
      lien: (state) =>
        state.superAdminStore.componentStore.liens.lien.data.data,
      vehicle: (state) => state.superAdminStore.vehicle,
    }),
  },
  data() {
    return {};
  },
  methods: {
    hideModal() {
      this.$bvModal.hide("registration-modal");
    },
  },
};
</script>

<style></style>
