<template>
  <div class="bg-white py-2">
    <b-row class="px-0 mx-0">
      <b-icon
        icon="skip-end-fill"
        scale="1.5"
        class="m-2"
        @click="skipAutoPart()"
        variant="success"
      ></b-icon>

      <b-col class="scroll-horizontal w-75 px-0">
        <b-button
          :id="'btn-work-order-' + autoPart.name"
          :ref="autoPart.name"
          v-for="(autoPart, i) in autoParts"
          :key="i"
          :variant="getItemMenuVariant(autoPart)"
          @click="selectAutoPart(autoPart)"
        >
          {{ autoPart.name }}
        </b-button>
      </b-col>
      <div>
        <b-icon
          icon="x"
          scale="1.5"
          class="m-2"
          variant="danger"
          @click="stopCamera"
        ></b-icon>
      </div>
    </b-row>
  </div>
</template>

<script>
import CompanyCarsService from "@/services/companyCarsService";

export default {
  name: "AutoParts",
  data() {
    return {
      autoParts: [],
      autoPartSelected: null,
    };
  },
  props: ["vin"],
  methods: {
    async getAutoParts() {
      const data = await CompanyCarsService.getAutoParts();
      if (this.$route.name == "company.cars.automaticInspection.easy") {
        this.autoParts = this.sortAutoPartArray();
      } else {
        this.autoParts = data.externalPart;
      }

      this.autoPartSelected = this.autoParts[0];
      this.$emit("select-auto-part", this.autoParts[0]);
    },
    selectAutoPart(autoPart) {
      this.$emit("select-auto-part", autoPart);
      this.autoPartSelected = autoPart;
    },
    getItemMenuVariant(autoPart) {
      if (this.autoPartSelected != null) {
        return autoPart.id === this.autoPartSelected.id
          ? "success"
          : "outline-success";
      } else {
        return "outline-success";
      }
    },
    skipAutoPart() {
      let autoPartSelectedIndex = this.autoParts.indexOf(this.autoPartSelected);
      if (autoPartSelectedIndex === this.autoParts.length - 1) {
        this.selectAutoPart(this.autoParts[0]);
      } else {
        this.selectAutoPart(this.autoParts[autoPartSelectedIndex + 1]);
        location.href =
          "#btn-work-order-" + this.autoParts[autoPartSelectedIndex + 1].name;
      }
    },
    stopCamera() {
      this.$emit("stop-camera");
    },
    sortAutoPartArray() {
      let orderedArray = [
        { id: 10, name: "Odometer", type: "internal" },
        { id: 8, name: "VIN Sticker", type: "internal" },
        { id: 17, name: "Tires Label", type: "external" },
        { id: 7, name: "Front Driver Door", type: "external" }, //Interior
        { id: 5, name: "Roof", type: "external" },
        { id: 2, name: "Rear Driver Seat", type: "external" }, //Left
        { id: 1, name: "Front Driver Seat", type: "external" }, //Front
        { id: 4, name: "Front Passenger Seat", type: "external" }, //Right
        { id: 3, name: "Rear Passenger Seat", type: "external" }, //Rear
        { id: 12, name: "Cargo Area", type: "internal" },
        { id: 16, name: "Cluster", type: "internal" },
        { id: 89, name: "Front Passenger Door", type: "external" }, //CAB
        { id: 90, name: "Driver Front", type: "internal" },
        { id: 91, name: "Passenger Front", type: "internal" },
        { id: 92, name: "Driver Rear", type: "internal" },
        { id: 93, name: "Passenger Rear", type: "internal" },
        { id: 15, name: "Roof Inside", type: "external" },
        { id: 6, name: "Wheel/Tire", type: "external" },
      ];
      return orderedArray;
    },
  },
  mounted() {
    this.$store.commit("visualInspection/resetStore");
    this.getAutoParts();
  },
};
</script>

<style scoped>
.scroll-horizontal {
  overflow-x: auto;
  white-space: nowrap;
}
</style>
