import * as constants from "./actions";
import axios from "@/plugins/axios.js";
function getDefaultState() {
  return {
    destinations: {
      status: null,
      data: [],
      error: null,
    },
    consignment: {
      status: null,
      data: {},
      error: null,
    },
    consignments: {
      status: null,
      data: [],
      error: null,
    },
    corporations: {
      status: null,
      data: [],
      error: null,
    },
  };
}
const actions = {
  [constants.GET_CONSIGNMENT_DESTINATION_LIST]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_CONSIGNMENT_DESTINATION_LIST);
      const response = await axios.get("consignment/listCompany", {
        params: payload,
      });
      commit(constants.GET_CONSIGNMENT_DESTINATION_LIST_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_CONSIGNMENT_DESTINATION_LIST_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_CONSIGNMENT]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_CONSIGNMENT);
      const response = await axios.post(
        "consignment/createAndUpdate ",
        payload
      );
      commit(constants.POST_CONSIGNMENT_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_CONSIGNMENT_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_CONSIGNMENTS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_CONSIGNMENTS);
      const response = await axios.get("consignment/getConsignments", {
        params: payload,
      });
      commit(constants.GET_CONSIGNMENTS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_CONSIGNMENTS_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_CONSIGNMENT]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_CONSIGNMENT);
      const response = await axios.put("consignment/changeStatus", payload);
      commit(constants.PUT_CONSIGNMENT_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_CONSIGNMENT_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_CORPORATIONS_LIST]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_CORPORATIONS_LIST);
      const response = await axios.get("consignment/listCorporation", {
        params: payload,
      });
      commit(constants.GET_CORPORATIONS_LIST_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_CORPORATIONS_LIST_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_CONSIGNMENT_DESTINATION_LIST]: (state) => {
    state.destinations.data = [];
    state.destinations.status = "fetching";
  },
  [constants.GET_CONSIGNMENT_DESTINATION_LIST_SUCCESS]: (state, response) => {
    state.destinations.status = "success";
    state.destinations.data = response;
  },
  [constants.GET_CONSIGNMENT_DESTINATION_LIST_ERROR]: (state, response) => {
    state.destinations.status = "error";
    state.destinations.error = response;
  },
  [constants.POST_CONSIGNMENT]: (state) => {
    state.consignment.status = "fetching";
    state.consignment.data = {};
  },
  [constants.POST_CONSIGNMENT_SUCCESS]: (state, response) => {
    state.consignment.status = "success";
    state.consignment.data = response;
  },
  [constants.POST_CONSIGNMENT_ERROR]: (state, response) => {
    state.consignment.status = "error";
    state.consignment.error = response;
  },
  [constants.GET_CONSIGNMENTS]: (state) => {
    state.consignments.status = "fetching";
    state.consignments.data = {};
  },
  [constants.GET_CONSIGNMENTS_SUCCESS]: (state, response) => {
    state.consignments.status = "success";
    state.consignments.data = response;
  },
  [constants.GET_CONSIGNMENTS_ERROR]: (state, response) => {
    state.consignments.status = "error";
    state.consignments.error = response;
  },
  [constants.PUT_CONSIGNMENT]: (state) => {
    state.consignment.status = "fetching";
    state.consignment.data = {};
  },
  [constants.PUT_CONSIGNMENT_SUCCESS]: (state, response) => {
    state.consignment.status = "success";
    state.consignment.data = response;
  },
  [constants.PUT_CONSIGNMENT_ERROR]: (state, response) => {
    state.consignment.status = "error";
    state.consignment.error = response;
  },
  [constants.GET_CORPORATIONS_LIST]: (state) => {
    state.corporations.status = "fetching";
    state.corporations.data = {};
  },
  [constants.GET_CORPORATIONS_LIST_SUCCESS]: (state, response) => {
    state.corporations.status = "success";
    state.corporations.data = response;
  },
  [constants.GET_CORPORATIONS_LIST_ERROR]: (state, response) => {
    state.corporations.status = "error";
    state.corporations.error = response;
  },
};
export default {
  state: getDefaultState(),
  actions,
  mutations,
};
