<template>
  <b-modal
    id="bulk-cluster-modal"
    ref="modal"
    modal-class="modal-fullscreen"
    centered
    scrollable
    size="lg"
    title="Bulk Cluster"
    hide-footer
  >
    <BulkClusterForm />
  </b-modal>
</template>

<script>
import BulkClusterForm from "./BulkClusterForm.vue";
export default {
  components: { BulkClusterForm },
};
</script>

<style>
</style>
