<template>
  <div>
    <AddonFileNav @set-status="setType" />
    <AddonsFileTableByIdReadError v-if="type == 'error'" />
    <div v-else class="container-fluid">
      <div>
        <TableBase
          :items="addonFileList.data"
          :fields="fields"
          :options="options"
          :busy="addonFileList.status == 'fetching' ? true : false"
        >
        </TableBase>
      </div>

      <b-modal
        id="addons-form-modal"
        ref="addons-form-modal"
        size="lg"
        hide-footer
        body-class=""
        ok-disabled
        @hidden="addonTypeItem = {}"
      >
        <AddonForm
          v-if="addonTypeItem.id"
          :addonItem="addonTypeItem"
          :refreshData="getData"
          :hideModal="hideModal"
          :vehiclesId="addonTypeItem.vehiclesId"
        />
      </b-modal>
    </div>
  </div>
</template>
<script>
import TableBase from "@/modules/shared/table.vue";
import AddonForm from "./AddonForm.vue";
import AddonFileNav from "./AddonsFileNav.vue";
import AddonsFileTableByIdReadError from "./AddonsFileTableByIdReadError.vue";
import { GET_ADDONS_FILE_FAILED, GET_ADDONS_FILE_PROCESSED } from "./actions";
import { mapState } from "vuex";

import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../mixins";
export default {
  name: "AddonsFileTableById",
  components: {
    TableBase,
    AddonForm,
    AddonFileNav,
    AddonsFileTableByIdReadError,
  },
  mixins: [generalMixin, superAdminMixin],
  computed: {
    ...mapState({
      addonFileList: (state) => state.superAdminStore.addonsFile.addonFileList,
    }),
    hasPermission() {
      return this.userHasPermission("addons", "write");
    },
    fields() {
      let addonsFields = [
        { key: "txtId", label: "Txt id", class: "text-center" },
        { key: "description", label: "Description", class: "text-center" },
        { key: "txtStock", label: "Txt stock", class: "text-center" },
        { key: "txtDocument", label: "# document", class: "text-center" },
        { key: "txtAmount", label: "Amount", class: "text-center" },
        { key: "addonType.name", label: "Addon type", class: "text-center" },
        {
          key: "accountingDate",
          label: "Accounting date",
          class: "text-center",
        },
        {
          key: "createdAt",

          label: "Create at",
          class: "text-center",
          formatter: (date) => this.$options.filters.formatDate(date),
        },

        {
          key: "actions",
          label: "Actions",
          note: false,
          details: true,
          validation: false,
          delete: false,
          hardDelete: true,
        },
      ];
      if (this.type == "failed") {
        /* add before last column */
        addonsFields.splice(addonsFields.length - 1, 0, {
          key: "txtError",
          label: "Error",
          class: "",
        });
      }
      return addonsFields;
    },
  },
  data() {
    return {
      options: {
        assign: false,
        rolFilter: false,
        add: false,
        fun: this.getData,
      },
      addonTypeItem: {},
      type: "success",
    };
  },
  mounted() {
    this.$store.commit(
      "setSearchFunction",
      this.userHasPermission("addons", "write") ? this.getData : null
    );
    this.$store.commit(
      "setAddFunction",
      this.userHasPermission("addons", "write") ? this.showModal : null
    );

    this.getData();
  },
  methods: {
    setType(type) {
      this.type = type;

      this.getData();
    },
    getData(filter = "") {
      if (this.type == "success") {
        this.$store
          .dispatch(GET_ADDONS_FILE_PROCESSED, {
            search: filter,
            id: this.$route.params.id,
          })
          .then(() => {})
          .catch(() => {});
      }
      if (this.type == "failed") {
        this.$store
          .dispatch(GET_ADDONS_FILE_FAILED, {
            search: filter,
            id: this.$route.params.id,
          })
          .then(() => {})
          .catch(() => {});
      }
    },

    async deleteItem(addon) {
      const confirm = await this.showConfirmDeleteBox();
      if (!confirm) return;
      const payload = {
        id: addon.id,
      };
      this.isLoading = true;
      this.$store
        .dispatch("DELETE_ADDON_FAIL", payload)
        .then(() => {
          this.getData();
          this.$root.$bvToast.toast("Addon deleted successfully", {
            title: "Success",
            variant: "success",
          });
        })
        .catch((error) => {
          this.$root.$bvToast.toast(
            error.data.messages.charAt(0).toUpperCase() + error.data.messages.slice(1),
            {
              title: "Error",
              variant: "warning",
            }
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showModal() {
      this.$bvModal.show("addons-form-modal");
    },
    editItem(item) {
      this.addonTypeItem = item;
      this.$bvModal.show("addons-form-modal");
    },
    changePage(page, filter = "") {
      if (this.type == "success") {
        this.$store
          .dispatch(GET_ADDONS_FILE_PROCESSED, {
            id: this.$route.params.id,
            page: page,
            search: filter,
          })
          .then(() => {})
          .catch(() => {});
      }
      if (this.type == "failed") {
        this.$store
          .dispatch(GET_ADDONS_FILE_FAILED, {
            id: this.$route.params.id,
            page: page,
            search: filter,
          })
          .then(() => {})
          .catch(() => {});
      }
    },

    hideModal() {
      this.$bvModal.hide("addons-form-modal");
    },
  },
};
</script>
