export const POST_CARFAX_NOTE = "POST_CARFAX_NOTE";
export const POST_CARFAX_NOTE_SUCCESS = "POST_CARFAX_NOTE_SUCCESS";
export const POST_CARFAX_NOTE_ERROR = "POST_CARFAX_NOTE_ERROR";

export const DELETE_CARFAX_NOTE = "DELETE_CARFAX_NOTE";
export const DELETE_CARFAX_NOTE_SUCCESS = "DELETE_CARFAX_NOTE_SUCCESS";
export const DELETE_CARFAX_NOTE_ERROR = "DELETE_CARFAX_NOTE_ERROR";

export const GET_CARFAX_NOTES = "GET_CARFAX_NOTES";
export const GET_CARFAX_NOTES_SUCCESS = "GET_CARFAX_NOTES_SUCCESS";
export const GET_CARFAX_NOTES_ERROR = "GET_CARFAX_NOTES_ERROR";
