import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    drivers: {
      status: null,
      data: [],
      error: null,
    },
    driver: {
      status: null,
      data: {},
      error: null,
    },
  };
}
const actions = {
  [constants.GET_DRIVER_LIST]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_DRIVER_LIST);

      const response = await axios.get("driver/getDrivers", {
        params: payload,
      });
      commit(constants.GET_DRIVER_LIST_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_DRIVER_LIST_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_DRIVER]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_DRIVER);
      const response = await axios.post("driver/createAndUpdate", payload);
      commit(constants.POST_DRIVER_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_DRIVER_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_DRIVER_LIST_NO_PAGINATE]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_DRIVER_LIST_NO_PAGINATE);
      const response = await axios.get("driver/listDrivers", {
        params: payload,
      });
      commit(constants.GET_DRIVER_LIST_NO_PAGINATE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_DRIVER_LIST_NO_PAGINATE_ERROR, error);
      throw error.response;
    }
  }
};

const mutations = {
  [constants.GET_DRIVER_LIST]: (state) => {
    state.drivers.status = "fetching";
    state.drivers.error = null;
  },
  [constants.GET_DRIVER_LIST_SUCCESS]: (state, data) => {
    state.drivers.status = "success";
    state.drivers.error = null;
    state.drivers.data = data;
  },
  [constants.GET_DRIVER_LIST_ERROR]: (state, error) => {
    state.drivers.status = "error";
    state.drivers.error = error;
  },
  [constants.POST_DRIVER]: (state) => {
    state.driver.status = "fetching";
    state.driver.error = null;
  },
  [constants.POST_DRIVER_SUCCESS]: (state, data) => {
    state.driver.status = "success";
    state.driver.error = null;
    state.driver.data = data;
  },
  [constants.POST_DRIVER_ERROR]: (state, error) => {
    state.driver.status = "error";
    state.driver.error = error;
  },
  [constants.GET_DRIVER_LIST_NO_PAGINATE]: (state) => {
    state.drivers.status = "fetching";
    state.drivers.error = null;
  },
  [constants.GET_DRIVER_LIST_NO_PAGINATE_SUCCESS]: (state, data) => {
    state.drivers.status = "success";
    state.drivers.error = null;
    state.drivers.data = data;
  },
  [constants.GET_DRIVER_LIST_NO_PAGINATE_ERROR]: (state, error) => {
    state.drivers.status = "error";
    state.drivers.error = error;
  }
};
export default {
  state: getDefaultState(),
  actions,
  mutations,
};
