<template>
  <b-table
    responsive
    stacked="md"
    :items="vehicleExpenses"
    :fields="fields"
    head-row-variant="success"
    class="text-nowrap"
  >
    <template v-slot:cell(id)="{ item }">
      <div class="text-wrap">
        <small>{{ item.id }}</small>
      </div>
    </template>
    <template v-slot:cell(description)="{ item }">
      <div class="text-wrap">
        <small>{{ item.description }}</small>
      </div>
    </template>
    <template v-slot:cell(invoice)="{ item }">
      <div class="text-wrap">
        <a href="www.google.com">
          <u>{{ item.invoice }}</u>
        </a>
      </div>
    </template>
  </b-table>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "ID",
          class: "text-center text-wrap",
        },
        {
          key: "description",
          label: "Description",
          class: "text-center",
        },
        {
          key: "currency",
          label: "Currency",
          class: "text-center px-0",
        },
        {
          key: "amount",
          label: "Amount",
          class: "text-center",
          formatter: (amount) => this.$options.filters.currency(amount),
        },
        {
          key: "gst",
          label: "GST/HST",
          class: "text-center",
          formatter: (gst) => this.$options.filters.currency(gst),
        },
        {
          key: "taxes",
          label: "QST",
          class: "text-center",
          formatter: (taxes) => this.$options.filters.currency(taxes),
        },
        {
          key: "totalAmount",
          label: "Total amount",
          class: "text-center",
          formatter: (totalAmount) =>
            this.$options.filters.currency(totalAmount),
        },
        {
          key: "invoice",
          label: "Invoice #",
          class: "text-center",
        },
      ],
      vehicleExpenses: [
        {
          id: 1,
          description: "Taxes",
          currency: "CAD",
          amount: 1000,
          gst: 200,
          taxes: 100,
          totalAmount: 1100,
          invoice: 23,
        },
        {
          id: 2,
          description: "Carfax",
          currency: "CAD",
          amount: 1000,
          gst: 200,

          taxes: 100,
          totalAmount: 1100,
        },
        {
          id: 3,
          description: "Tires",
          currency: "CAD",
          amount: 1000,
          gst: 200,

          taxes: 100,
          totalAmount: 1100,
          invoice: 51,
        },
        {
          id: 4,
          description: "Bodyshop",
          currency: "USD",
          amount: 1000,
          gst: 0,

          taxes: 0,
          totalAmount: 1000,
        },
        {
          id: 5,
          description: "Lien check",
          currency: "CAD",
          amount: 1000,
          gst: 200,

          taxes: 100,
          totalAmount: 1100,
        },
        {
          id: 6,
          description: "Transport 1",
          currency: "CAD",
          amount: 1000,
          gst: 200,

          taxes: 100,
          totalAmount: 1100,
          invoice: 12,
        },
        {
          id: 7,
          description: "Transport 2",
          currency: "CAD",
          amount: 1000,
          gst: 200,

          taxes: 100,
          totalAmount: 1100,
        },
        {
          id: 8,
          description: "Misc 1",
          currency: "CAD",
          amount: 1000,
          gst: 200,

          taxes: 100,
          totalAmount: 1100,
        },
        {
          id: 9,
          description: "Misc 2",
          currency: "CAD",
          amount: 1000,
          gst: 200,

          taxes: 100,
          totalAmount: 1100,
        },
        {
          id: 10,
          description: "Other",
          currency: "CAD",
          amount: 1000,
          gst: 200,

          taxes: 100,
          totalAmount: 1100,
        },
        {
          id: 11,
          description: "Net Canadian cost",
          currency: "CAD",
          amount: 1000,
          gst: 200,

          taxes: 100,
          totalAmount: 1100,
        },
      ],
    };
  },
};
</script>

<style>
</style>