import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    exchangeContractPayments: {
      status: null,
      data: null,
      error: null,
    },
    exchangeContractPayment: {
      status: null,
      data: null,
      error: null,
    }
  };
}

const actions = {
  [constants.GET_EXCHANGE_CONTRACT_PAYMENTS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_EXCHANGE_CONTRACT_PAYMENTS);
      const response = await axios.get("exchangeContract/getExchangeContractPayment", {
        params: payload,
      });
      commit(constants.GET_EXCHANGE_CONTRACT_PAYMENTS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_EXCHANGE_CONTRACT_PAYMENTS_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_EXCHANGE_CONTRACT_PAYMENT]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_EXCHANGE_CONTRACT_PAYMENT);
      const response = await axios.post("exchangeContract/createPayment", payload);
      commit(constants.POST_EXCHANGE_CONTRACT_PAYMENT_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_EXCHANGE_CONTRACT_PAYMENT_ERROR, error);
      throw error.response;
    }
  }
};

const mutations = {

  [constants.GET_EXCHANGE_CONTRACT_PAYMENTS]: (state) => {
    state.exchangeContractPayments.status = "fetching";
  },
  [constants.GET_EXCHANGE_CONTRACT_PAYMENTS_SUCCESS]: (state, response) => {
    state.exchangeContractPayments.status = "success";
    state.exchangeContractPayments.data = response;
  },
  [constants.GET_EXCHANGE_CONTRACT_PAYMENTS_ERROR]: (state, error) => {
    state.exchangeContractPayments.status = "error";
    state.exchangeContractPayments.error = error;
  },
  [constants.POST_EXCHANGE_CONTRACT_PAYMENT]: (state) => {
    state.exchangeContractPayment.status = "fetching";
  },
  [constants.POST_EXCHANGE_CONTRACT_PAYMENT_SUCCESS]: (state, response) => {
    state.exchangeContractPayment.status = "success";
    state.exchangeContractPayment.data = response;
  },
  [constants.POST_EXCHANGE_CONTRACT_PAYMENT_ERROR]: (state, error) => {
    state.exchangeContractPayment.status = "error";
    state.exchangeContractPayment.error = error;
  }
};
export default {
  state: getDefaultState(),
  actions,
  mutations,

};