import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {

    commissionSummary: {
      status: null,
      data: [],
      error: null,
    },



  };
}
const actions = {


  [constants.GET_COMMISSIONS_SUMMARY]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_COMMISSIONS_SUMMARY);
      const response = await axios.get("commission/getCommissionAndInterest", {
        params: payload,
      });
      commit(constants.GET_COMMISSIONS_SUMMARY_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_COMMISSIONS_SUMMARY_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_COMMISSION_SUMMARY_EXCEL]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_COMMISSION_SUMMARY_EXCEL);
      const response = await axios.get("commission/downloadXls", {
        params: payload,
        responseType: "blob",
      });
      commit(constants.GET_COMMISSION_SUMMARY_EXCEL_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_COMMISSION_SUMMARY_EXCEL_ERROR, error);
      throw error.response;
    }
  }


};

const mutations = {
  [constants.GET_COMMISSIONS_SUMMARY]: (state) => {
    state.commissionSummary.status = "fetching";
    state.commissionSummary.error = null;
    state.commissionSummary.data = [];
  },
  [constants.GET_COMMISSIONS_SUMMARY_SUCCESS]: (state, data) => {
    state.commissionSummary.status = "success";
    state.commissionSummary.error = null;
    state.commissionSummary.data = data.data;
  },
  [constants.GET_COMMISSIONS_SUMMARY_ERROR]: (state, error) => {
    state.commissionSummary.status = "error";
    state.commissionSummary.error = error;
  },

};
export default {
  state: getDefaultState(),
  actions,
  mutations,
};
