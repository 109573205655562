<template>
  <b-row>
    <b-col
      v-for="(data, index) in [1, 2, 3, 4, 5, 6]"
      :key="index"
      lg="4"
      md="6"
      sm="12"
    >
      <b-card
        no-body
        img-top
        class="mt-2"
      >
        <b-skeleton-img
          card-img="top"
          aspect="3:1"
        />
        <b-card-body>
          <b-skeleton-table
            :rows="5"
            :columns="1"
            :table-props="{ bordered: true, striped: true }"
          />
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {};
</script>

<style></style>
