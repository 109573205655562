<template>
  <div class="mt-3">
    <div class="container-fluid">
      <b-table
        show-empty
        bordered
        small
        stacked="md"
        head-variant="light"
        :items="driversPrice.data || []"
        :fields="fields"
        :busy="driversPriceStatus == 'fetching'"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" variant="yellow" />
          </div>
        </template>

        <template #cell(actions)="{ item }">
          <b-icon
            class="mx-1 cursor-pointer"
            icon="trash"
            @click="deleteDriverPrice(item.id)"
            scale="1.3"
          />
        </template>
      </b-table>
      <b-pagination
        v-if="
          driversPriceStatus != 'fetching' && driversPrice && driversPrice.data
        "
        :value="driversPrice ? driversPrice.currentPage : 1"
        :total-rows="driversPrice ? driversPrice.total : 0"
        :per-page="driversPrice ? driversPrice.perPage : 25"
        class="mb-2"
        align="end"
        @change="changePage($event)"
      />
    </div>
    <b-modal
      id="driver-price-form-modal"
      title="Driver price"
      size="lg"
      class="modal-dialog-centered"
      hide-footer
    >
      <DriverPriceForm
        :refreshData="getDriversPrice"
        :closeModal="closeFormModal"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { GET_DRIVER_PRICE, DELETE_DRIVER_PRICE } from "./actions";
import DriverPriceForm from "./DriverPriceForm.vue";
export default {
  components: {
    DriverPriceForm,
  },
  computed: {
    ...mapState({
      driversPrice: (state) =>
        state.superAdminStore.driverPrice.driversPrice.data,
      driversPriceStatus: (state) =>
        state.superAdminStore.driverPrice.driverPrice.status,
    }),
  },
  data() {
    return {
      fields: [
        {
          key: "initialRange",
          label: "Initial Range",
          formatter: (value) => `${value} kms`,
        },
        {
          key: "endRange",
          label: "Final  Range",
          formatter: (value) => `${value} kms`,
        },
        {
          key: "price",
          label: "Price",
          formatter: (amount) => this.$options.filters.currency(amount),
        },
        {
          key: "actions",
          label: "Actions",
          class: "text-center",
        },
      ],
    };
  },
  mounted() {
    this.$store.commit("setAddFunction", this.openFormModal);
    this.$store.commit("setSearchFunction", this.getTitleFiles);
    this.getDriversPrice();
  },
  methods: {
    getDriversPrice(search = null, page = 1) {
      const params = {
        status: 1,
        search,
        page,
      };
      this.$store.dispatch(GET_DRIVER_PRICE, params);
    },
    changePage(page) {
      this.getDriversPrice(null, page);
    },
    deleteDriverPrice(id) {
      this.$store.dispatch(DELETE_DRIVER_PRICE, { id }).then(() => {
        this.$bvToast.toast("Driver price deleted successfully", {
          title: "Success",
          variant: "success",
          solid: true,
        });
        this.getDriversPrice();
      });
    },

    openFormModal() {
      this.$bvModal.show("driver-price-form-modal");
    },
    closeFormModal() {
      this.$bvModal.hide("driver-price-form-modal");
    },
  },
};
</script>
