function getDefaultState() {
  return {
    carId: null,
    vinData: null,
    vehicleInformationDatail: null,
    imageToPreview: {
      text: null,
      img: null,
    },
    vehicleExterior: {
      mainBody: null,
      front: null,
      back: null,
      driverSide: null,
      coDriver: null,
      passengerLeftSide: null,
      passengerRightSide: null,
      checkDamages: null,
      generalRate: null,
    },
    vehicleInteriors: {
      board: null,
      seating: null,
      backSeats: null,
      trunk: null,
      checkDamages: null,
      generalRate: null,
    },
    vehicleCostDetail: {
      carproof: false,
      reparationRequired: null,
      estimatedRepairCost: null,
      cost: null,
    },
    vehiclePartsPhotos: null,
    vehicleDamagePhotos: null,
    damageImageModal: null,
    repairImageModal: null,
  };
}

const mutations = {
  resetStore: (state) => {
    state.vehicleCostDetail = {
      carproof: false,
      reparationRequired: null,
      estimatedRepairCost: null,
      cost: null,
    };
  },
  setVinData: (state, vinData) => {
    state.vinData = vinData;
  },
  setVehicleInformation: (state, info) => {
    state.vehicleInformationDatail = info;
  },
  setVehicleExterior: (state, vehicleExterior) => {
    state.vehicleExterior = vehicleExterior;
  },
  setVehicleInteriors: (state, interior) => {
    state.vehicleInteriors = interior;
  },
  setVehicleCostDatail: (state, cost) => {
    state.vehicleCostDetail = cost;
  },
  setImageToPreview: (state, img) => {
    state.imageToPreview = img;
  },
  setVehicleParts: (state, info) => {
    state.vehiclePartsPhotos = info;
  },
  setVehicleDamages: (state, info) => {
    state.vehicleDamagePhotos = info;
  },
  setDamageImageModal: (state, image) => {
    state.damageImageModal = image;
  },
  setRepairImageModal: (state, image) => {
    state.repairImageModal = image;
  }
};

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations,
};
