<template>
  <div class="h-100 overflow-auto">
    <!-- iterate bootstrap vue checkbox  -->
    <div v-if="isLoading" class="text-center">
      <b-spinner variant="success" class="mt-3"></b-spinner>
    </div>
    <b-form-group v-else label="Columns">
      <b-form-checkbox
        v-for="option in columns"
        v-model="selected"
        :key="option.id"
        :value="option"
        @change="onChangeColumns"
      >
        {{ option.label }}
      </b-form-checkbox>
    </b-form-group>
  </div>
</template>

<script>
import { GET_COLUMNS_REPORTS } from "./actions";
import { mapState } from "vuex";
export default {
  name: "ReportSelect",
  props: {
    report: {
      type: Object,
      required: false,
      default: () => {
        return {
          name: "Report name",
          type: "public",
          fields: [],
        };
      },
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) =>
        state.superAdminStore.customReports.columns.status == "fetching",
    }),
  },
  data() {
    return {
      selected: [],
      columns: [],
    };
  },
  watch: {
    report: {
      deep: true,
      handler: function () {
        if (this.report.columns) {
          this.formatColumns();
        }
      },
    },
  },
  mounted() {
    this.getColumns();
  },

  methods: {
    getColumns() {
      this.$store
        .dispatch(GET_COLUMNS_REPORTS)
        .then((response) => {
          this.columns = response;
          if (this.report) {
            this.formatColumns();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formatColumns() {
      this.selected = this.report.columns.map((field) => {
        return this.columns.find((column) => column.key === field.key);
      });
    },
    onChangeColumns() {
      this.$emit("onChangeColumns", this.selected);
    },
  },
};
</script>

<style>
</style>