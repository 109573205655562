<template>
  <b-container class="mt-3">
    <EditProfileForm />
    <card class="mt-3">
      <RecoverPassword />
    </card>
  </b-container>
</template>

<script>
import EditProfileForm from "./EditProfileForm.vue";
import RecoverPassword from "../forgotPassword/RecoverPassword.vue";
export default {
  components: {
    EditProfileForm,
    RecoverPassword,
  },
};
</script>

<style>
</style>