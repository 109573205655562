<template>
  <b-modal
    :id="modalId + this.id"
    ref="modal"
    modal-class="modal-fullscreen"
    size="lg"
    centered
    title="Condition Report"
    hide-footer
  >
    <div
      v-if="!crUrl"
      class="text-center"
    >
      Nothing to show. Call your agent for more information.
    </div>
    <div v-else>
      <b-embed
        type="iframe"
        aspect="16by9"
        :src="crUrl"
        allowfullscreen
      />
    </div>
  </b-modal>
</template>

<script>
export default {
  props: [ 'id', 'crUrl' ],
  data() {
    return {
      modalId: 'CR-Page-Modal'
    };
  },
  methods: {}
};
</script>

<style scoped>
::v-deep .modal-fullscreen .modal {
  padding: 0 !important;
}
::v-deep .modal-fullscreen .modal-dialog {
  width: 100%;
  max-width: none;
  height: 100%;
  margin: 0;
}
::v-deep .modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
::v-deep .modal-fullscreen .modal-body {
  overflow-y: auto;
}
@media screen and (max-width: 600px) {
  ::v-deep .modal-fullscreen .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  ::v-deep .modal-fullscreen .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
  }

  .embed-responsive {
    position: relative;
    padding-top: 150%;
  }
}

@media screen and (max-width: 300px) {
  .embed-responsive {
    padding-top: 170%;
  }
}
</style>
