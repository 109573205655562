<template>
  <b-card body-class="" class="mb-1">
    <b-row>
      <b-col cols="10">
        <div>
          {{ vehicle.stockNumber }} - {{ vehicle.vinNumber }} <br />
          {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}<br />
          {{ vehicle.color }}
        </div>
      </b-col>
      <b-col cols="2">
        <b-icon
          v-if="action == 'add'"
          icon="arrow-right-circle"
          class="cursor-pointer"
          @click="add(vehicle)"
        >
        </b-icon>
        <b-icon
          v-if="action == 'remove'"
          icon="x-circle"
          class="cursor-pointer"
          @click="remove(vehicle)"
        ></b-icon>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
  },
  name: "VehicleCard",
  methods: {
    remove(vehicle) {
      this.$emit("remove", vehicle);
    },
    add(vehicle) {
      this.$emit("add", vehicle);
    },
  },
};
</script>

<style>
</style>