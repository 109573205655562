<template>
  <div>
    <b-list-group v-if="consignments.status == 'fetching'" class="mt-2">
      <b-list-group-item class="text-center">
        <b-spinner variant="yellow" />
      </b-list-group-item>
    </b-list-group>
    <b-list-group v-else class="mt-2 mb-2 note-list" tag="ul">
      <b-list-group-item
        v-if="consignments.data.data && !consignments.data.data.length"
        class="text-center"
      >
        <b>No consignments to show</b>
      </b-list-group-item>

      <b-list-group-item
        v-for="(consignment, i) in consignments.data.data"
        :key="i"
        class="flex-column align-items-start"
        tag="li"
      >
        <ConsignmentCard
          :consignment="consignment"
          :refreshData="getConsignments"
        />
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ConsignmentCard from "./ConsignmentCard.vue";
export default {
  components: { ConsignmentCard },
  computed: {
    ...mapState({
      consignments: (state) =>
        state.superAdminStore.componentStore.consignment.consignments,
      vehicle: (state) => state.superAdminStore.vehicle,
    }),
  },
  methods: {
    getConsignments() {
      this.$store.dispatch("GET_CONSIGNMENTS", {
        vehicleId: this.vehicle.id,
      });
    },
  },
};
</script>

<style>
</style>