import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    atac: {
      status: null,
      data: {},
      error: null,
    },

  };
}
const actions = {
  [constants.GET_ATAC_FILE]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_ATAC_FILE);
      const response = await axios.get("vehicle/generateAtac", {
        params: payload,

      });
      commit(constants.GET_ATAC_FILE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_ATAC_FILE_ERROR, error);
      throw error.response;
    }
  },


};

const mutations = {
  [constants.GET_ATAC_FILE]: (state) => {
    state.atac.data = [];
    state.atac.status = "fetching";
  },
  [constants.GET_ATAC_FILE_SUCCESS]: (state, response) => {
    state.atac.status = "success";
    state.atac.data = response;
  },
  [constants.GET_ATAC_FILE_ERROR]: (state, error) => {
    state.atac.status = "error";
    state.atac.error = error;
  },



};
export default {
  state: getDefaultState(),
  actions,
  mutations,
};
