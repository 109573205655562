<template>
  <b-list-group v-if="agents.status == 'fetching'" class="mt-2">
    <b-list-group-item class="text-center">
      <b-spinner variant="yellow" />
    </b-list-group-item>
  </b-list-group>
  <b-list-group v-else class="mt-2 mb-2 note-list" tag="ul">
    <b-list-group-item
      v-if="agents.data && !agents.data.length"
      class="text-center"
    >
      <b>No records to show</b>
    </b-list-group-item>

    <b-list-group-item
      v-for="(agent, i) in agents.data"
      :key="i"
      class="flex-column align-items-start"
      tag="li"
    >
      <AgentHistoryItem :agent="agent" />
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import { mapState } from "vuex";
import AgentHistoryItem from "./AgentHistoryItem.vue";
export default {
  components: { AgentHistoryItem },
  computed: {
    ...mapState({
      agents: (state) => state.superAdminStore.componentStore.history.agents,
    }),
  },
};
</script>

<style>
</style>