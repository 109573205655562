<template>
  <div>
    <card header-classes="bg-transparent">
      <h3 slot="header" class="mb-0">
        <!-- Return bottom -->
        <b-button class="p-0" @click="goBack()">
          <b-icon icon="arrow-left-circle" font-scale="1.7" />
        </b-button>
        Notes
      </h3>
      <b-card v-if="notes.length == 0">
        <p class="text-justify mt-3">No notes</p>
      </b-card>
      <time-line v-else type="one-side">
        <time-line-item
          v-for="(note, key) in notes"
          :key="key"
          :badge-type="'success'"
          :badge-icon="getBadgeIconByModule(note.type)"
        >
          <small class="text-muted font-weight-bold">
            {{ note.date | formatDateTime }} - {{ note.nickname }}</small
          >
          <h5 class="mt-3 mb-0">{{ note.action }}</h5>
          <p class="text-sm mt-1 mb-0">
            {{ note.description }}
          </p>
          <!--                 <div class="mt-3">
                   <badge rounded type="success">design</badge>&nbsp;
                  <badge rounded type="success">system</badge>&nbsp;
                  <badge rounded type="success">creative</badge>
                </div> -->
        </time-line-item>
      </time-line>
    </card>

    <!--  <b-card v-if="notes.length == 0">
      <p class="text-justify mt-3">No notes</p>
    </b-card>
    <template v-else>
      <b-card v-for="(note, key) in notes" :key="key">
        <b-row>
          <b-col cols="10">
            <small class="text-muted">
              {{ note.date | formatDateTime }} - {{ note.nickname }}
            </small>
          </b-col>
          <b-col> </b-col>
        </b-row>
        <p class="text-justify mt-3">
          {{ note.description }}
        </p>
      </b-card>
    </template> -->
  </div>
</template>

<script>
import { TimeLine, TimeLineItem } from "@/components";

import { icons } from "@/helpers/iconByModule.js";

export default {
  props: {
    notes: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    TimeLine,
    TimeLineItem,
  },
  name: "VehicleTimelineNotes",
  methods: {
    getBadgeIconByModule(module) {
      if (!module) return "";
      const type = module.toLowerCase();
      return icons[type];
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>
