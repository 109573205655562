import DashboardLayout from '@/layouts/DashboardLayout.vue';

import CategoriesTable from "./categories/CategoriesTable.vue";
import ProvidersTable from "./service-providers/ProvidersTable.vue";
import { ifRoleAuthenticated } from "@/router/navigationGuards";

const routes = {
  path: "/work-order",

  name: "work-order",
  component: DashboardLayout,

  beforeEnter: (to, from, next) => {
    ifRoleAuthenticated(to, from, next, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]);
  },
  children: [
    {
      path: "categories",
      name: "work-order.categories",
      meta: {
        layout: "base",
        name: "Categories"
      },
      component: CategoriesTable,
    },
    {
      path: "providers",
      name: "work-order.providers",
      meta: {
        layout: "base",
        name: "Suppliers"
      },
      component: ProvidersTable,
    },
  ],
};

export default routes;
