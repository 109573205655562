<template>
  <div>
    <span>
      Date:
      <i>{{ marketplace.createdAt | formatDateTime }} </i>
    </span>
    <br />
    <span
      >Marketplace: <i>{{ marketplace.marketPlace.name }}</i></span
    >
  </div>
</template>

<script>
export default {
  props: {
    marketplace: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>