import * as constants from "./actions";
import axios from "@/plugins/axios.js";
function getDefaultState() {
  return {
    cluster: {
      status: null,
      data: {},
      error: null,
    },
    clusters: {
      status: null,
      data: [],
      error: null,
    },
    clusterInfo: {
      status: null,
      data: {},
      error: null,
    },
  };
}

const actions = {
  [constants.POST_CLUSTER]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_CLUSTER);
      const response = await axios.post("cluster/create", payload);
      commit(constants.POST_CLUSTER_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_CLUSTER_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_CLUSTERS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_CLUSTERS);
      const response = await axios.get("cluster/list", {
        params: payload,
      });
      commit(constants.GET_CLUSTERS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_CLUSTERS_ERROR, error);
      throw error.response;
    }
  },

  [constants.PUT_CLUSTER]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_CLUSTER);
      const response = await axios.put("cluster/changeStatus", payload);
      commit(constants.PUT_CLUSTER_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_CLUSTER_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_CLUSTER_INFO]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_CLUSTER_INFO);
      const response = await axios.get("cluster/getVehicleInfo", {
        params: payload,
      });
      commit(constants.GET_CLUSTER_INFO_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_CLUSTER_INFO_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.POST_CLUSTER]: (state) => {
    state.cluster.status = "fetching";
    state.cluster.error = null;
  },
  [constants.POST_CLUSTER_SUCCESS]: (state, response) => {
    state.cluster.status = "success";
    state.cluster.data = response;
  },
  [constants.POST_CLUSTER_ERROR]: (state, error) => {
    state.cluster.status = "error";
    state.cluster.error = error;
  },
  [constants.GET_CLUSTERS]: (state) => {
    state.clusters.status = "fetching";
    state.clusters.error = null;
  },
  [constants.GET_CLUSTERS_SUCCESS]: (state, response) => {
    state.clusters.status = "success";
    state.clusters.data = response.data;
  },
  [constants.GET_CLUSTERS_ERROR]: (state, error) => {
    state.clusters.status = "error";
    state.clusters.error = error;
  },

  [constants.PUT_CLUSTER]: (state) => {
    state.cluster.status = "fetching";
    state.cluster.error = null;
  },
  [constants.PUT_CLUSTER_SUCCESS]: (state, response) => {
    state.cluster.status = "success";
    state.cluster.data = response;
  },
  [constants.PUT_CLUSTER_ERROR]: (state, error) => {
    state.cluster.status = "error";
    state.cluster.error = error;
  },

  [constants.GET_CLUSTER_INFO]: (state) => {
    state.clusterInfo.status = "fetching";
    state.clusterInfo.error = null;
  },
  [constants.GET_CLUSTER_INFO_SUCCESS]: (state, response) => {
    state.clusterInfo.status = "success";
    state.clusterInfo.data = response.data;
  },
  [constants.GET_CLUSTER_INFO_ERROR]: (state, error) => {
    state.clusterInfo.status = "error";
    state.clusterInfo.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
