<template>
  <div class="row">
    <b-badge
      v-for="type in typesArray"
      :key="type"
      pill
      :style="{
        backgroundColor: getTaskTypeColor(type),
      }"
      class="mx-1 my-1"
    >
      {{ type }}</b-badge
    >
  </div>
</template>

<script>
/* import { NOTE_TYPES as typesColors } from "@/modules/superAdmin/components/notes/noteTypes.js";
 */ import { NOTE_TYPES as typesColors } from "./noteTypes";
export default {
  name: "TaskCardTypeBadge",
  props: {
    types: {
      type: String,
      default: "",
    },
  },
  computed: {
    typesArray() {
      return this.stringToArray(this.types);
    },
  },
  methods: {
    getTaskTypeColor(type) {
      return typesColors.map((typeColor) => {
        if (typeColor.label === type.trim()) {
          return typeColor.color;
        }
      });
    },
    stringToArray(string) {
      return string.split(", ");
    },
  },
};
</script>

<style></style>
