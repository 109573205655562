export const GET_VEHICLES_OFFERS = 'GET_VEHICLES_OFFERS';
export const GET_VEHICLES_OFFERS_SUCCESS = 'GET_VEHICLES_OFFERS_SUCCESS';
export const GET_VEHICLES_OFFERS_ERROR = 'GET_VEHICLES_OFFERS_ERROR';

export const GET_VEHICLES_WITH_OFFERS = 'GET_VEHICLES_WITH_OFFERS';
export const GET_VEHICLES_WITH_OFFERS_SUCCESS =
  'GET_VEHICLES_WITH_OFFERS_SUCCESS';
export const GET_VEHICLES_WITH_OFFERS_ERROR = 'GET_VEHICLES_WITH_OFFERS_ERROR';

export const CLEAR_VEHICLES_OFFERS = '';

export const GET_VEHICLES_OFFER_CHAIN = 'GET_VEHICLES_OFFER_CHAIN';
export const GET_VEHICLES_OFFER_CHAIN_SUCCESS =
  'GET_VEHICLES_OFFER_CHAIN_SUCCESS';
export const GET_VEHICLES_OFFER_CHAIN_ERROR = 'GET_VEHICLES_OFFER_CHAIN_ERROR';

export const GET_OFFERS_BY_VEHICLE = 'GET_OFFERS_BY_VEHICLE';
export const GET_OFFERS_BY_VEHICLE_SUCCESS = 'GET_OFFERS_BY_VEHICLE_SUCCESS';
export const GET_OFFERS_BY_VEHICLE_ERROR = 'GET_OFFERS_BY_VEHICLE_ERROR';

export const POST_VEHICLES_OFFER_CHAIN = 'POST_VEHICLES_OFFER_CHAIN';
export const POST_VEHICLES_OFFER_CHAIN_SUCCESS =
  'POST_VEHICLES_OFFER_CHAIN_SUCCESS';
export const POST_VEHICLES_OFFER_CHAIN_ERROR =
  'POST_VEHICLES_OFFER_CHAIN_ERROR';

export const GET_CHECK_HIGHER_OFFER = 'GET_CHECK_HIGHER_OFFER';
export const GET_CHECK_HIGHER_OFFER_SUCCESS = 'GET_CHECK_HIGHER_OFFER_SUCCESS';
export const GET_CHECK_HIGHER_OFFER_ERROR = 'GET_CHECK_HIGHER_OFFER_ERROR';

export const POST_ACCEPT_COUNTER_OFFER = 'POST_ACCEPT_COUNTER_OFFER';
export const POST_ACCEPT_COUNTER_OFFER_SUCCESS =
  'POST_ACCEPT_COUNTER_OFFER_SUCCESS';
export const POST_ACCEPT_COUNTER_OFFER_ERROR =
  'POST_ACCEPT_COUNTER_OFFER_ERROR';

export const GET_OFFERS_BY_USER = 'GET_OFFERS_BY_USER';

export const GET_ACTIVE_OFFERS = 'GET_ACTIVE_OFFERS';
export const GET_ACTIVE_OFFERS_SUCCESS = 'GET_ACTIVE_OFFERS_SUCCESS';
export const GET_ACTIVE_OFFERS_ERROR = 'GET_ACTIVE_OFFERS_ERROR';

export const GET_EXPIRED_OFFERS = 'GET_EXPIRED_OFFERS';
export const GET_EXPIRED_OFFERS_SUCCESS = 'GET_EXPIRED_OFFERS_SUCCESS';
export const GET_EXPIRED_OFFERS_ERROR = 'GET_EXPIRED_OFFERS_ERROR';

export const GET_PURCHASES = 'GET_PURCHASES';
export const GET_PURCHASES_SUCCESS = 'GET_PURCHASES_SUCCESS';
export const GET_PURCHASES_ERROR = 'GET_PURCHASES_ERROR';

export const SET_OFFER_SIDEBAR = 'SET_OFFER_SIDEBAR';
export const SHOW_OFFERS_SIDEBAR = 'SET_OFFERS_SIDEBAR';
export const SHOW_OFFER_SIDEBAR = 'SHOW_OFFER_SIDEBAR';

export const SET_VEHICLE_SOLD = 'SET_VEHICLE_SOLD';
