export const GET_TRANSFER_BETWEEN_AGENT_LIST = 'GET_TRANSFER_BETWEEN_AGENT_LIST';
export const GET_TRANSFER_BETWEEN_AGENT_LIST_SUCCESS = 'GET_TRANSFER_BETWEEN_AGENT_LIST_SUCCESS';
export const GET_TRANSFER_BETWEEN_AGENT_LIST_ERROR = 'GET_TRANSFER_BETWEEN_AGENT_LIST_ERROR';

export const GET_TRANSFER_BETWEEN_AGENT_VEHICLES = 'GET_TRANSFER_BETWEEN_AGENT_DETAILS_VEHICLES';
export const GET_TRANSFER_BETWEEN_AGENT_VEHICLES_SUCCESS = 'GET_TRANSFER_BETWEEN_AGENT_DETAILS_VEHICLES_SUCCESS';
export const GET_TRANSFER_BETWEEN_AGENT_VEHICLES_ERROR = 'GET_TRANSFER_BETWEEN_AGENT_DETAILS_VEHICLES_ERROR';

export const POST_TRANSFER_BETWEEN_AGENT = 'POST_TRANSFER_BETWEEN_AGENT';
export const POST_TRANSFER_BETWEEN_AGENT_SUCCESS = 'POST_TRANSFER_BETWEEN_AGENT_SUCCESS';
export const POST_TRANSFER_BETWEEN_AGENT_ERROR = 'POST_TRANSFER_BETWEEN_AGENT_ERROR';

export const PUT_VALIDATE_TRANSFER_BETWEEN_AGENT = 'PUT_VALIDATE_TRANSFER_BETWEEN_AGENT';
export const PUT_VALIDATE_TRANSFER_BETWEEN_AGENT_SUCCESS = 'PUT_VALIDATE_TRANSFER_BETWEEN_AGENT_SUCCESS';
export const PUT_VALIDATE_TRANSFER_BETWEEN_AGENT_ERROR = 'PUT_VALIDATE_TRANSFER_BETWEEN_AGENT_ERROR';

export const DELETE_TRANSFER_BETWEEN_AGENT = 'DELETE_TRANSFER_BETWEEN_AGENT';
export const DELETE_TRANSFER_BETWEEN_AGENT_SUCCESS = 'DELETE_TRANSFER_BETWEEN_AGENT_SUCCESS';
export const DELETE_TRANSFER_BETWEEN_AGENT_ERROR = 'DELETE_TRANSFER_BETWEEN_AGENT_ERROR';

export const GET_TRANSFER_BETWEEN_AGENT_EXCEL = 'GET_TRANSFER_BETWEEN_AGENT_EXCEL';
export const GET_TRANSFER_BETWEEN_AGENT_EXCEL_SUCCESS = 'GET_TRANSFER_BETWEEN_AGENT_EXCEL_SUCCESS';
export const GET_TRANSFER_BETWEEN_AGENT_EXCEL_ERROR = 'GET_TRANSFER_BETWEEN_AGENT_EXCEL_ERROR';