export const GET_VEHICLES_LOAD = "GET_VEHICLES_LOAD";
export const GET_VEHICLES_LOAD_SUCCESS = "GET_VEHICLES_LOAD_SUCCESS";
export const GET_VEHICLES_LOAD_ERROR = "GET_VEHICLES_LOAD_ERROR";

export const POST_LOAD = "POST_LOAD";
export const POST_LOAD_SUCCESS = "POST_LOAD_SUCCESS";
export const POST_LOAD_ERROR = "POST_LOAD_ERROR";

export const GET_EXPORT_LOCATIONS_LIST = "GET_EXPORT_LOCATIONS_LIST";
export const GET_EXPORT_LOCATIONS_LIST_SUCCESS =
  "GET_EXPORT_LOCATIONS_LIST_SUCCESS";
export const GET_EXPORT_LOCATIONS_LIST_ERROR =
  "GET_EXPORT_LOCATIONS_LIST_ERROR";

export const GET_EXPORT_LOADS = "GET_EXPORT_LOADS";
export const GET_EXPORT_LOADS_SUCCESS = "GET_EXPORT_LOADS_SUCCESS";
export const GET_EXPORT_LOADS_ERROR = "GET_EXPORT_LOADS_ERROR";

export const PUT_LOAD_STATUS = "PUT_LOAD_STATUS";
export const PUT_LOAD_STATUS_SUCCESS = "PUT_LOAD_STATUS_SUCCESS";
export const PUT_LOAD_STATUS_ERROR = "PUT_LOAD_STATUS_ERROR";

export const GET_EXPORT_LOAD_EXCEL = "GET_EXPORT_LOAD_EXCEL";
export const GET_EXPORT_LOAD_EXCEL_SUCCESS = "GET_EXPORT_LOAD_EXCEL_SUCCESS";
export const GET_EXPORT_LOAD_EXCEL_ERROR = "GET_EXPORT_LOAD_EXCEL_ERROR";

export const GET_EXPORT_LOAD_PDF = "GET_EXPORT_LOAD_PDF";
export const GET_EXPORT_LOAD_PDF_SUCCESS = "GET_EXPORT_LOAD_PDF_SUCCESS";
export const GET_EXPORT_LOAD_PDF_ERROR = "GET_EXPORT_LOAD_PDF_ERROR";
export const GET_EXPORT_LOAD_INFO_BY_ID = "GET_EXPORT_LOAD_INFO_BY_ID";
export const GET_EXPORT_LOAD_INFO_BY_ID_SUCCESS =
  "GET_EXPORT_LOAD_INFO_BY_ID_SUCCESS";
export const GET_EXPORT_LOAD_INFO_BY_ID_ERROR =
  "GET_EXPORT_LOAD_INFO_BY_ID_ERROR";

export const PUT_VEHICLE_PORT = "PUT_VEHICLE_PORT";
export const PUT_VEHICLE_PORT_SUCCESS = "PUT_VEHICLE_PORT_SUCCESS";
export const PUT_VEHICLE_PORT_ERROR = "PUT_VEHICLE_PORT_ERROR";

export const POST_FOREIGN_FORM = "POST_FOREIGN_FORM";
export const POST_FOREIGN_FORM_SUCCESS = "POST_FOREIGN_FORM_SUCCESS";
export const POST_FOREIGN_FORM_ERROR = "POST_FOREIGN_FORM_ERROR";

export const GET_COMPANIES_FOREIGN = "GET_COMPANIES_FOREIGN";
export const GET_COMPANIES_FOREIGN_SUCCESS = "GET_COMPANIES_FOREIGN_SUCCESS";
export const GET_COMPANIES_FOREIGN_ERROR = "GET_COMPANIES_FOREIGN_ERROR";

export const GET_LOAD_PDF = "GET_LOAD_PDF";
export const GET_LOAD_PDF_SUCCESS = "GET_LOAD_PDF_SUCCESS";
export const GET_LOAD_PDF_ERROR = "GET_LOAD_PDF_ERROR";

export const GET_FILES_BY_EMAIL = "GET_FILES_BY_EMAIL";
export const GET_FILES_BY_EMAIL_SUCCESS = "GET_FILES_BY_EMAIL_SUCCESS";
export const GET_FILES_BY_EMAIL_ERROR = "GET_FILES_BY_EMAIL_ERROR";

export const GET_EXPORT_LOAD_EXCEL_BY_TYPE = "GET_EXPORT_LOAD_EXCEL_BY_TYPE";
export const GET_EXPORT_LOAD_EXCEL_BY_TYPE_SUCCESS =
  "GET_EXPORT_LOAD_EXCEL_BY_TYPE_SUCCESS";
export const GET_EXPORT_LOAD_EXCEL_BY_TYPE_ERROR =
  "GET_EXPORT_LOAD_EXCEL_BY_TYPE_ERROR";