export const POST_CLUSTER = "POST_CLUSTER";
export const POST_CLUSTER_SUCCESS = "POST_CLUSTER_SUCCESS";
export const POST_CLUSTER_ERROR = "POST_CLUSTER_ERROR";

export const GET_CLUSTERS = "GET_CLUSTERS";
export const GET_CLUSTERS_SUCCESS = "GET_CLUSTERS_SUCCESS";
export const GET_CLUSTERS_ERROR = "GET_CLUSTERS_ERROR";

export const PUT_CLUSTER = "PUT_CLUSTER";
export const PUT_CLUSTER_SUCCESS = "PUT_CLUSTER_SUCCESS";
export const PUT_CLUSTER_ERROR = "PUT_CLUSTER_ERROR";

export const GET_CLUSTER_INFO = "GET_CLUSTER_INFO";
export const GET_CLUSTER_INFO_SUCCESS = "GET_CLUSTER_INFO_SUCCESS";
export const GET_CLUSTER_INFO_ERROR = "GET_CLUSTER_INFO_ERROR";
