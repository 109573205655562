<template>
  <div>
    <b-row>
      <b-col cols="10">
        <small class="text-muted">
          {{ report.date | formatDateTime }} - {{ report.user.lastName }}
          {{ report.user.firstName }}
        </small>
      </b-col>
      <b-col cols="2">
        <div class="text-right">
          <span
            class="dot"
            :class="[report.status ? 'dot-success' : 'dot-danger']"
          />
          <br />
          <b-icon
            v-if="userHasPermission('cr', 'write')"
            icon="trash"
            class="cursor-pointer"
            @click="deleteConditionalReport(report)"
          ></b-icon>
        </div>
      </b-col>
    </b-row>
    <p class="mt-2 mb-1">
      <span
        class="mt-2 mb-1 cursor-pointer"
        @click="showConditionalReport(report.url)"
      >
        Open Condition Report
      </span>
    </p>
  </div>
</template>

<script>
import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../../mixins";

export default {
  props: ["report", "refreshData"],
  mixins: [generalMixin, superAdminMixin],
  methods: {
    showConditionalReport(report) {
      window.open(report, "_blank");
    },
    deleteConditionalReport(report) {
      this.$store
        .dispatch("DELETE_CONDITIONAL_REPORT", report.id)
        .then(() => {
          this.makeToast("Success", "Report deleted successfully", "success");
          this.refreshData();
        })
        .catch(() => {});
    },
  },
};
</script>

<style></style>
