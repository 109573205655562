export const GET_CONSIGNMENT_DESTINATION_LIST =
  "GET_CONSIGNMENT_DESTINATION_LIST";
export const GET_CONSIGNMENT_DESTINATION_LIST_SUCCESS =
  "GET_CONSIGNMENT_DESTINATION_LIST_SUCCESS";
export const GET_CONSIGNMENT_DESTINATION_LIST_ERROR =
  "GET_CONSIGNMENT_DESTINATION_LIST_ERROR";

export const POST_CONSIGNMENT = "POST_CONSIGNMENT";
export const POST_CONSIGNMENT_SUCCESS = "POST_CONSIGNMENT_SUCCESS";
export const POST_CONSIGNMENT_ERROR = "POST_CONSIGNMENT_ERROR";

export const GET_CONSIGNMENTS = "GET_CONSIGNMENTS";
export const GET_CONSIGNMENTS_SUCCESS = "GET_CONSIGNMENTS_SUCCESS";
export const GET_CONSIGNMENTS_ERROR = "GET_CONSIGNMENTS_ERROR";

export const PUT_CONSIGNMENT = "PUT_CONSIGNMENT";
export const PUT_CONSIGNMENT_SUCCESS = "PUT_CONSIGNMENT_SUCCESS";
export const PUT_CONSIGNMENT_ERROR = "PUT_CONSIGNMENT_ERROR";

export const GET_CORPORATIONS_LIST = "GET_CORPORATIONS_LIST";
export const GET_CORPORATIONS_LIST_SUCCESS = "GET_CORPORATIONS_LIST_SUCCESS";
export const GET_CORPORATIONS_LIST_ERROR = "GET_CORPORATIONS_LIST_ERROR";
