import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    financialCreditRequest: {
      status: null,
      data: {},
      error: null,
    },



  };
}
const actions = {
  [constants.GET_FINANCIAL_CREDIT_REQUEST_INFO]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_FINANCIAL_CREDIT_REQUEST_INFO);
      const response = await axios.get("creditRequest/findCreditRequest", {
        params: payload,
      });
      commit(constants.GET_FINANCIAL_CREDIT_REQUEST_INFO_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_FINANCIAL_CREDIT_REQUEST_INFO_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_MARK_AS_PAID]: async ({ commit }, payload) => {
    try {
      commit(constants.PUT_MARK_AS_PAID);
      const response = await axios.put("creditRequest/markAsPaid", payload);
      commit(constants.PUT_MARK_AS_PAID_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_MARK_AS_PAID_ERROR, error);
      throw error.response;
    }
  }


};

const mutations = {
  [constants.GET_FINANCIAL_CREDIT_REQUEST_INFO]: (state) => {
    state.financialCreditRequest.data = {};
    state.financialCreditRequest.status = "fetching";
  },
  [constants.GET_FINANCIAL_CREDIT_REQUEST_INFO_SUCCESS]: (state, response) => {
    state.financialCreditRequest.status = "success";
    state.financialCreditRequest.data = response;
  },
  [constants.GET_FINANCIAL_CREDIT_REQUEST_INFO_ERROR]: (state, error) => {
    state.financialCreditRequest.status = "error";
    state.financialCreditRequest.error = error;
  },
  [constants.PUT_MARK_AS_PAID]: (state) => {
    state.financialCreditRequest.data = {};
    state.financialCreditRequest.status = "fetching";
  },
  [constants.PUT_MARK_AS_PAID_SUCCESS]: (state, response) => {
    state.financialCreditRequest.status = "success";
    state.financialCreditRequest.data = response;
  },
  [constants.PUT_MARK_AS_PAID_ERROR]: (state, error) => {
    state.financialCreditRequest.status = "error";
    state.financialCreditRequest.error = error;
  }



};
export default {
  state: getDefaultState(),
  actions,
  mutations,
};
