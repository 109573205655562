<template>
  <div>
    <div v-if="jacket.status == 'fetching'">
      <div class="container mt-auto text-center mt-5">
        <b-spinner
          style="width: 10rem; height: 10rem"
          variant="yellow"
          class="align-middle mt-5"
        />
      </div>
    </div>
    <div v-else>
      <b-row v-if="exportInfo" class="ml-1">
        <b-col cols="12"> <h3>Jacket</h3> </b-col>

        <b-col
          v-if="exportInfo.exportLoadsId"
          xs="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
        >
          <h5>
            Export load

            <font-awesome-icon
              class="icon-color cursor-pointer"
              :icon="['far', 'file-excel']"
              @click="getExcel()"
            />
          </h5>
        </b-col>

        <!--     <b-col
          v-if="exportInfo.vehicleSalesId"
          xs="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
        >
          <h5>
            Purchase order
            <b-spinner v-if="isPDFLoading" small variant="yellow" />

            <font-awesome-icon
              v-else
              class="icon-color cursor-pointer"
              :icon="['far', 'file-pdf']"
              @click="getPurchaseOrderPDF()"
            />
          </h5>
        </b-col> -->
      </b-row>

      <b-row v-if="registrationInfo">
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <b-form-checkbox
            v-if="!registrationInfo.clientUpdateDate"
            id="checkbox-client"
            name="checkbox-client"
            class="mt-2"
            :value="1"
            :unchecked-value="0"
            @change="updateInternalStatus(registrationInfo.id, 2)"
            >Delivered to client
          </b-form-checkbox>
          <b-form-group v-else label-size="md" label="Delivered to client date">
            <b-form-input
              readonly
              :value="registrationInfo.clientUpdateDate"
              class="text-center"
            />
          </b-form-group>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <b-form-checkbox
            v-if="!registrationInfo.internUpdateDate"
            id="checkbox-internal-user"
            name="checkbox-internal-user"
            class="mt-2"
            :value="1"
            :unchecked-value="0"
            @change="updateInternalStatus(registrationInfo.id, 1)"
            >Delivered to internal user
          </b-form-checkbox>
          <b-form-group
            v-else
            label-size="md"
            label="Delivered to internal user date"
          >
            <b-form-input
              readonly
              :value="registrationInfo.internUpdateDate"
              class="text-center"
            />
          </b-form-group>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <label for="">Bought From: </label>
          <h4>Quebec Auto Suppliers</h4>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <label for="">Sold To: </label>
          <h4 v-if="sale">
            {{ sale.company.commercialName }} <br />
            {{ sale.company.street }}, {{ sale.company.city }},
            {{ sale.company.zip }},
            {{ sale.company.state }}
          </h4>
          <h4 v-else>-----</h4>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { GET_JACKETS_INFO, PUT_JACKET_INTERNAL_STATUS } from "./actions";
import { generalMixin } from "@/modules/mixin.js";

export default {
  mixins: [generalMixin],
  computed: {
    ...mapState({
      vehicle: (state) => state.superAdminStore.vehicle,
      jacket: (state) => state.superAdminStore.componentStore.jacket.jacketInfo,
    }),
    sale() {
      return this.jacket.data.sale;
    },
  },
  data() {
    return {
      form: {
        contract: null,
        contractCheck: 0,
        contractReceivedDate: null,
        ownerSheet: null,
        registrationCheck: 0,
        registrationReceivedDate: null,
      },
      exportInfo: {},
      registrationInfo: null,
      sold: null,
      isPDFLoading: false,
    };
  },
  methods: {
    getJacketInformation() {
      this.$store
        .dispatch(GET_JACKETS_INFO, { vehicleId: this.vehicle.id })
        .then((response) => {
          this.exportInfo = response.export;
          this.registrationInfo = response.registration;
          this.sold = response.sold;
        });
    },
    getExcel() {
      this.$store
        .dispatch("GET_EXPORT_LOAD_EXCEL", {
          id: this.exportInfo.exportLoadsId,
        })
        .then((response) => {
          const blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = this.formatFileName("jacket", this.vehicle);
          link.click();
        });
    },
    getPurchaseOrderPDF() {
      this.isPDFLoading = true;
      this.$store
        .dispatch("GET_SOLD_PDF", { id: this.exportInfo.vehicleSalesId })
        .then((response) => {
          const blob = new Blob([response], {
            type: "application/pdf",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = this.formatFileName(
            "jacket-purchase-order",
            this.vehicle
          );
          link.click();
        })
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        })
        .finally(() => {
          this.isPDFLoading = false;
        });
    },
    updateInternalStatus(id, status) {
      this.$store
        .dispatch(PUT_JACKET_INTERNAL_STATUS, {
          id,
          internalStatus: status,
        })
        .then(() => {
          this.$root.$bvToast.toast("success", {
            title: "Success",
            variant: "success",
          });
        });
    },
  },
  mounted() {
    this.getJacketInformation();
  },
};
</script>

<style></style>
