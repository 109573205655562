<template>
  <div class="container">
    <b-form class="" @submit.stop.prevent="form.id ? update() : save()">
      <h3 class="text-purple-yellow">
        <b-iconstack class="m-1 text-center" font-scale="1">
          <b-icon icon="cash" font-scale="0.6" />
          <b-icon
            icon="plus-circle-fill"
            shift-h="25"
            shift-v="4"
            font-scale="0.30"
          />
        </b-iconstack>
      </h3>
      <b-row>
        <!-- First name -->
        <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
          <b-form-group label-size="sm">
            <template slot="label">
              Name <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="tps-input"
              v-model.trim="$v.form.tps.$model"
              size="sm"
              name="tps-input"
              :value="form.tps"
              :state="validateState('tps')"
              aria-describedby="input-tps-live-feedback"
              maxlength="100"
              @change="setValue('tps', $event)"
            />
            <b-form-invalid-feedback id="input-tps-live-feedback">
              This is a required field and must at least 2 characters.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <!-- Percent -->
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group label-size="sm">
            <template slot="label">
              Percent <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="percent-input"
              v-model.trim="$v.form.percent.$model"
              size="sm"
              name="percent-input"
              :value="form.percent"
              :state="validateState('percent')"
              aria-describedby="input-percent-live-feedback"
              maxlength="100"
              @change="setValue('percent', $event)"
            />
            <b-form-invalid-feedback id="input-percent-live-feedback">
              This is a required field beetwen 0 and 100.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <!-- Province -->
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group label-size="sm">
            <template slot="label">
              Province <span class="text-danger">*</span>
            </template>
            <b-form-select
              value-field="id"
              text-field="name"
              v-model="form.statesId"
              :options="states"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group label-size="sm">
            <template slot="label">
              Default tax?
              <span class="text-danger">*</span>
            </template>
            <b-form-radio-group
              v-model="form.appliesDefault"
              :options="options"
              class="mb-3"
              value-field="item"
              text-field="name"
              disabled-field="notEnabled"
            ></b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row />

      <b-row>
        <b-col sm="12" md="6" lg="6" xl="6" />
        <b-col sm="12" md="3" lg="3" xl="3" />
        <b-col sm="12" md="3" lg="3" xl="3">
          <b-btn
            v-if="userHasPermission('taxes', 'write')"
            block
            size="lg"
            class="btn btn-success"
            :disabled="$v.form.$invalid"
            type="submit"
          >
            Submit
            <b-spinner v-if="tax.status == 'fetching'" small variant="white" />
          </b-btn>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import { POST_TAX } from "./actions";
import { mapState } from "vuex";
import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../mixins";

export default {
  mixins: [generalMixin, superAdminMixin],
  props: ["taxItem", "refreshData", "hideModal"],
  computed: {
    ...mapState({
      states: (state) => state.superAdminStore.taxes.states.data,
      tax: (state) => state.superAdminStore.taxes.tax,
    }),
  },

  data() {
    return {
      form: {
        id: null,
        tps: null,
        percent: null,
        statesId: null,
        appliesDefault: 0,
      },
      provinces: [],
      options: [
        {
          item: 1,
          name: "Yes",
          notEnabled: false,
        },
        {
          item: 0,
          name: "No",
          notEnabled: false,
        },
      ],
    };
  },
  validations: {
    form: {
      tps: {
        required,
        minLength: minLength(2),
      },
      percent: {
        required,
      },
      statesId: {
        required,
      },
      appliesDefault: {
        required,
      },
    },
  },
  mounted() {
    if (this.taxItem.id) {
      console.log(this.taxItem);
      this.formatForm();
    }
  },
  methods: {
    closeModal(callback) {
      callback();
    },
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    save() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_TAX, payload)
        .then(() => {
          this.makeToast("Succes", "Tax created succesfully", "success");
          this.refreshData();
          this.hideModal();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Internal server error, try again later", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    update() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_TAX, payload)
        .then(() => {
          this.makeToast("Succes", "Tax updated succesfully", "success");
          this.refreshData();
          this.hideModal();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Internal server error, try again later", {
            title: "Warning",
            variant: "warning",
          });
        });
    },

    formatPayload() {
      const payload = {
        id: this.taxItem.id ? this.taxItem.id : null,
        tps: this.form.tps,
        percent: this.form.percent,
        statesId: this.form.statesId,
        appliesDefault: this.form.appliesDefault,
      };

      return payload;
    },

    formatForm() {
      this.form.id = this.taxItem.id;
      this.form.tps = this.taxItem.tps;
      this.form.percent = this.taxItem.percent;
      this.form.appliesDefault = this.taxItem.appliesDefault;
      this.form.statesId = this.taxItem.statesId;
    },
  },
};
</script>

<style scoped>
.append-input {
  max-width: 40%;
}
</style>
