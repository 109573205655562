<template>
  <div>
    <b-row>
      <b-col cols="10">
        <span>
          Registration date:
          <i>
            {{ consignment.createdAt | formatDateTime }} -
            {{ consignment.user.firstName }}
            {{ consignment.user.lastName }}</i
          >
        </span>
        <br />
        <span v-if="consignment.status != 1 && consignment.updateUser != null">
          Last update date:
          <i
            >{{ consignment.updatedAt | formatDateTime }} -
            {{ consignment.updateUser.firstName }}
            {{ consignment.updateUser.lastName }}</i
          >
        </span>
      </b-col>
      <b-col>
        <div class="text-right">
          <span v-if="consignment.status === 1" class="dot dot-success mr-3" />
          <span v-if="consignment.status === 0" class="dot dot-danger mr-3" />
          <br />
          <b-icon
            v-if="consignment.status === 1"
            icon="x-square"
            class="cursor-pointer mr-3"
            v-b-tooltip.hover
            title="Cancel consignment"
            @click="cancelConsignment(consignment)"
          ></b-icon>
        </div>
      </b-col>
    </b-row>
    <p class="text-justify mt-3">
      <span>
        Type:
        <i>{{ consignment.type == "internal" ? "Internal" : "External" }} </i>
      </span>
      <br />
      <span v-if="consignment.company">
        Destination: <i>{{ consignment.company.companyName }} </i>
      </span>
      <span v-if="consignment.corporation">
        Corporation: <i>{{ consignment.corporation.companyName }}</i>
      </span>
    </p>

    <!--  <p class="text-justify">
      Cost: <i>{{ consignment.vehicle.vehicleCostDetail.price }}</i>
    </p> -->
    <p></p>
  </div>
</template>

<script>
import { PUT_CONSIGNMENT } from "./actions";
import { generalMixin } from "@/modules/mixin.js";
export default {
  props: ["consignment", "refreshData"],
  mixins: [generalMixin],
  data() {
    return {
      isEditing: false,
    };
  },
  methods: {
    cancelConsignment(consignment) {
      this.$store
        .dispatch(PUT_CONSIGNMENT, {
          id: consignment.id,
          status: 0,
        })
        .then(() => {
          this.refreshData();
          this.makeToast(
            "Success",
            "Consignment updated successfully",
            "success"
          );
        })
        .catch((error) => {
          console.log(error);
          this.$root.$bvToast.toast(
            error.data.message.charAt(0).toUpperCase() +
              error.data.message.slice(1),
            {
              title: "Error",
              variant: "warning",
            }
          );
        });
    },
  },
};
</script>

<style>
</style>