<template>
  <card>
    <b-row align-v="center" slot="header">
      <b-col cols="8">
        <h3 class="mb-0">Edit profile</h3>
      </b-col>
      <b-col cols="4" class="text-right">
        <a href="#!" class="btn btn-sm btn-primary">Settings</a>
      </b-col>
    </b-row>

    <b-form @submit.prevent="updateProfile">
      <h6 class="heading-small text-muted mb-4">User information</h6>

      <div class="pl-lg-4">
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
            <!-- First name -->
            <b-form-group label-size="sm">
              <template slot="label">
                First name <span class="text-danger">*</span>
              </template>
              <b-form-input
                id="firstName-input"
                v-model.trim="$v.form.name.$model"
                size="sm"
                name="firstName-input"
                :value="form.name"
                :state="validateState('name')"
                aria-describedby="input-firstName-live-feedback"
                maxlength="100"
                @change="setValue('name', $event)"
              />
              <b-form-invalid-feedback id="input-firstName-live-feedback">
                This is a required field and must at least 2 characters.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <!-- Last name -->
            <b-form-group label-size="sm">
              <template slot="label">
                Last name <span class="text-danger">*</span>
              </template>
              <b-form-input
                id="lastName-input"
                v-model.trim="$v.form.lastName.$model"
                size="sm"
                name="lastName-input"
                :value="form.lastName"
                :state="validateState('lastName')"
                aria-describedby="input-lastName-live-feedback"
                maxlength="100"
                @change="setValue('lastName', $event)"
              />
              <b-form-invalid-feedback id="input-lastName-live-feedback">
                This is a required field and must at least 2 characters.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
            <!-- nickname -->
            <b-form-group label-size="sm">
              <template slot="label"> Nickname </template>
              <b-form-input
                id="nickname-input"
                size="sm"
                name="nickname-input"
                :value="form.nickname"
                aria-describedby="input-firstName-live-feedback"
                maxlength="100"
                @change="setValue('nickname', $event)"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6" lg="6" xl="6">
            <!-- Email -->
            <b-form-group label-size="sm">
              <template slot="label">
                Email <span class="text-danger"> *</span>
              </template>
              <b-form-input
                id="email-input"
                v-model.trim="$v.form.email.$model"
                size="sm"
                name="email-input"
                :formatter="lowerCaseFormatter"
                :value="form.email"
                :state="validateState('email')"
                aria-describedby="input-email-live-feedback"
                maxlength="255"
                @change="setValue('email', $event)"
              />
              <b-form-invalid-feedback id="input-email-live-feedback">
                This is a required field and must be a valid email.
              </b-form-invalid-feedback>
              <!--       <SendPasswordEmail
              v-if="
                !$v.form.email.$invalid &&
                this.userItem.id &&
                userHasPermission('agents', 'write')
              "
              :email="form.email"
            /> -->
            </b-form-group>
          </b-col>
          <!-- Stock code -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
            <b-form-group label-size="sm">
              <template slot="label">
                Stock code <span class="text-danger"> *</span>
              </template>
              <b-form-input
                id="stockCode-input"
                size="sm"
                name="stockCode-input"
                :value="form.stockCode"
                aria-describedby="input-firstName-live-feedback"
                maxlength="5"
                @change="setValue('stockCode', $event)"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6" lg="6" xl="6">
            <!-- Office phone number -->
            <b-form-group label-size="sm">
              <template slot="label">
                Office phone number <span class="text-danger">*</span>
              </template>
              <b-input-group>
                <vue-tel-input
                  v-model.trim="$v.form.officePhoneNumber.$model"
                  size="sm"
                  default-country="us"
                  :input-options="options"
                  :dropdown-options="telDdOptions"
                  :auto-format="true"
                  :valid-characters-only="true"
                  mode="international"
                  style-classes="z-index-2"
                  :value="form.officePhoneNumber"
                  @change="setValue('officePhoneNumber', $event)"
                />

                <b-form-input
                  id="extension-input"
                  v-model.trim="$v.form.extension.$model"
                  size="md"
                  type="number"
                  maxlength="6"
                  onkeydown="javascript: return event.keyCode == 69 ? false : true"
                  class="append-input"
                  placeholder="ext"
                  name="extension-input"
                  :value="form.extension"
                  :state="validateState('extension')"
                  @change="setValue('extension', $event)"
                />
                <b-form-invalid-feedback id="input-office-number-live-feedback">
                  This field must at least contain 10 digits.
                </b-form-invalid-feedback>
                <b-form-invalid-feedback id="input-extension-live-feedback">
                  This is a numeric field
                </b-form-invalid-feedback>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6" lg="6" xl="6">
            <!-- Mobile phone -->
            <b-form-group label-size="sm" label="Mobile phone">
              <template slot="label"> Mobile phone </template>
              <vue-tel-input
                v-model.trim="form.mobilePhone"
                default-country="us"
                :input-options="options"
                :dropdown-options="telDdOptions"
                :auto-format="true"
                :valid-characters-only="true"
                :value="form.mobilePhone"
                mode="international"
                style-classes="z-index-2"
              />
              <b-form-invalid-feedback id="input-mobile-phone-live-feedback">
                This is a numeric field
              </b-form-invalid-feedback>
              <!--    <b-alert show variant="info" class="mt-2">
              Mobile Phone will be used for notifications
            </b-alert> -->
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <hr class="my-4" />

      <!-- Address -->
      <h6 class="heading-small text-muted mb-4">Contact information</h6>

      <div class="pl-lg-4">
        <b-row>
          <b-col md="12">
            <b-form-group>
              <b-form-input
                label="Address"
                placeholder="Home Address"
                v-model="form.address"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4">
            <b-form-group>
              <b-form-input
                label="City"
                placeholder="City"
                v-model="form.city"
              />
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group>
              <b-form-input
                label="Country"
                placeholder="Country"
                v-model="form.country"
              />
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group>
              <b-form-input
                label="Postal code"
                placeholder="Postal code"
                v-model="form.postal_code"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-form>
  </card>
</template>
<script>
import { required, minLength, email, numeric } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      status: false,
      isAccepTerms: false,
      form: {
        id: null,
        name: null,
        lastName: null,
        email: null,
        officePhoneNumber: null,
        extension: null,
        mobilePhone: null,
        nickname: null,
        stockCode: null,
      },
      options: {
        type: "tel",
        validCharactersOnly: true,
        maxlength: 14,
      },
      telDdOptions: {
        showDialCodeInList: true,
        showFlags: true,
        showDialCodeInSelection: false,
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(2),
      },
      lastName: {
        required,
        minLength: minLength(2),
      },
      email: {
        required,
        email,
      },
      extension: {
        numeric,
      },
      officePhoneNumber: {
        required,
        minLength: minLength(10),
      },
      stockCode: {
        required,
      },
    },
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    updateProfile() {
      alert("Your data: " + JSON.stringify(this.form));
    },
    formatForm() {},
  },
};
</script>
<style scoped>
.append-input {
  max-width: 40%;
}
</style>
